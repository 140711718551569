export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_CASHING: {
      DEPOSIT_TITLE: "Nouvelle encaissement - Dépôt",
      DEPOSIT_EDIT_TITLE: "Modifier encaissement - Dépôt",
      INVOICE_TITLE: "Nouvelle encaissement - Facture",
      INVOICE_EDIT_TITLE: "Modifier encaissement - Facture",
      MESSAGE_TITLE: "Encaissement",
      MESSAGE_MESSAGE: "Vous n’avez pas de demande de dépôt disponible à l’encaissement, ni de facture",
      PAYMENT_MODES: "Mode de paiement",
      REFERENCE_NUMBER: "Numéro de référence",
      DEPOSIT_REQUEST: "Demande de dépôt",
      ACCOUNT: "Compte",
      UDEM_PROJECT: "UM Projet",
      ACTIVITY: "Activité",
      TYPE_SRCE: "Type Srce",
    },
  },
};
