<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ dialogTitle }}</span>
      <button
        mat-button
        class="mat-icon-button"
        (click)="dialogRef.close()"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
    <form
      name="eventForm"
      [formGroup]="eventForm"
      class="event-form w-100-p"
      fxLayout="column"
      fxFlex
    >
      <mat-form-field class="w-100-p">
        <mat-label>Title</mat-label>
        <input
          matInput
          name="title"
          formControlName="title"
          placeholder="Title"
          required
        />
      </mat-form-field>

      <div class="py-16" fxFlex="1 0 auto" fxLayout="row">
        <mat-slide-toggle
          name="allDay"
          formControlName="allDay"
          class="mr-24"
          aria-label="All day"
        >
          All Day
        </mat-slide-toggle>
      </div>

      <div
        class="py-16"
        fxFlex="1 0 auto"
        fxLayout="column"
        fxLayout.gt-xs="row"
        formGroupName="color"
      >
        <mat-form-field class="mr-sm-24" fxFlex>
          <!-- <input
            matInput
            class="primary-color-input"
            name="primary color"
            formControlName="primary"
            placeholder="Primary color"
            [(colorPicker)]="event.color.primary"
            cpWidth="290px"
            [cpPresetColors]="presetColors"
            [style.background]="event.color.primary"
            (colorPickerChange)="
              event.color.primary = $event;
              eventForm.patchValue({ color: { primary: $event } })
            "
          /> -->
        </mat-form-field>

        <mat-form-field fxFlex>
          <!-- <input
            matInput
            class="secondary-color-input"
            name="secondary color"
            formControlName="secondary"
            placeholder="Secondary color"
            [(colorPicker)]="event.color.secondary"
            cpWidth="290px"
            [cpPresetColors]="presetColors"
            [style.background]="event.color.secondary"
            (colorPickerChange)="
              event.color.secondary = $event;
              eventForm.patchValue({ color: { secondary: $event } })
            "
          /> -->
        </mat-form-field>
      </div>

      <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">
        <mat-form-field class="mr-sm-24" fxFlex>
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="startDatePicker"
            placeholder="Start Date"
            name="start"
            formControlName="start"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="no-errors-spacer" fxFlex mat-no-float>
          <mat-label>Start Time</mat-label>
          <input matInput placeholder="Start Time" />
        </mat-form-field>
      </div>

      <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">
        <mat-form-field class="mr-sm-24" fxFlex>
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="endDatePicker"
            placeholder="End Date"
            name="end"
            formControlName="end"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="no-errors-spacer" fxFlex mat-no-float>
          <mat-label>End Time</mat-label>
          <input matInput placeholder="End Time" />
        </mat-form-field>
      </div>

      <mat-form-field formGroupName="meta" class="w-100-p">
        <mat-label>Location</mat-label>
        <input
          matInput
          name="location"
          formControlName="location"
          placeholder="Location"
        />
      </mat-form-field>

      <mat-form-field formGroupName="meta" class="w-100-p">
        <textarea
          matInput
          formControlName="notes"
          placeholder="Notes"
          mat-maxlength="250"
          max-rows="4"
        >
        </textarea>
      </mat-form-field>
    </form>
  </div>

  <div
    mat-dialog-actions
    class="m-0 p-16"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <button
      *ngIf="action !== 'edit'"
      mat-raised-button
      (click)="dialogRef.close(eventForm)"
      class="save-button mat-accent"
      [disabled]="eventForm.invalid"
      aria-label="SAVE"
    >
      SAVE
    </button>

    <button
      *ngIf="action === 'edit'"
      mat-raised-button
      (click)="dialogRef.close(['save', eventForm])"
      class="save-button mat-accent"
      [disabled]="eventForm.invalid"
      aria-label="SAVE"
    >
      SAVE
    </button>

    <button
      *ngIf="action === 'edit'"
      mat-button
      class="mat-icon-button"
      (click)="dialogRef.close(['delete', eventForm])"
      aria-label="Delete"
      matTooltip="Delete"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
