import { RichTextsValidator } from './../../utils/RichTextsValidator';
import { ICanDeactivateComponent } from './../../gards/can-deactivate-guard.service';
import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatTableModule,
} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay,
} from 'angular-calendar';
import { CreateOrderComponent } from '../../components/dialogs/create-order/create-order.component';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { isSameDay, isSameMonth, startOfDay } from 'date-fns';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor, EditorModule } from 'primeng/editor';
import { Subject, of, merge, Subscription } from 'rxjs';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
import { FileService } from '../../../services/file.service';
import { PrintService } from '../../../services/print.service';
import { ClientsCategoriesService } from '../../api/clients-categories.service';
import { ClientsService } from '../../api/clients.service';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { ClientsModelData } from '../../api/models/ClientsModel';
import { SimpleEnterpriseModel } from '../../api/models/EnterprisesModel';
import { PersonnalTitlesService } from '../../api/personnal-titles.service';
import { AddressComponent } from '../../components/address/address.component';
import { ContactComponent } from '../../components/contact/contact.component';
import { CreateClientComponent } from '../../components/dialogs/create-client/create-client.component';
import { CreateEnterpriseForClientComponent } from '../../components/dialogs/create-enterprise-for-client/create-enterprise-for-client.component';
import { IDynamicTableItemAction } from '../../components/dynamic-table/dynamic-table.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { TagsComponent } from '../../components/tags/tags.component';
import { AppRoutes } from '../../const/AppRoutes';
import { RolesService } from '../../services/roles.service';
import { Settings } from '../../settings/settings';
import { GT2FormPageAbstract } from '../abstract/GT2FormPageAbstract';
import { CustomDateFormatter } from '../calendar/CustomDateFormatter';
import { FuseCalendarEventFormDialogComponent } from '../calendar/event-form/event-form.component';
import { locale as english } from '../clients/i18n/en-CA';
import { locale as french } from '../clients/i18n/fr-CA';
import { CreateEventComponent } from '../../components/dialogs/create-event/create-event.component';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { Gt2LocaleService } from '../../../localization/gt2-locale.service';
import { PaymentDeadlineService } from '../../api/payment-deadline.service';
import { ApiRoutes } from '../../api/ApiRoutes';
import { MailComposeDialogComponent } from '../compose/compose.component';
import { ClientReceiptComposeService } from '../../api/client-receipt-compose.service';
import { Title } from '@angular/platform-browser';
import { FuseSidebarService } from '../../../../@fuse/components/sidebar/sidebar.service';
import moment from 'moment';
import { GT2CalendarUtils } from '../../utils/GT2CalendarUtils';
import { ModulesService } from '../../services/modules.service';
import { CreateCongresDialogComponent } from '../congresses/create-congres-dialog/create-congres-dialog.component';
import { ClientAttachEnterpriseTablePaginatedComponent } from '../../components/dialogs/client-attach-enterprise-table-paginated/client-attach-enterprise-table-paginated.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { startWith, switchMap, catchError, map } from 'rxjs/operators';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { TagsByModelComponent } from '../../components/tags-by-model/tags-by-model.component';
import { CommentSectionComponent } from '../../components/comment-section/comment-section.component';
import { CreatedUpdatedAtComponent } from '../../components/created-updated-at/created-updated-at.component';
import { CreateCongressesButtonComponent } from '../../components/create-congresses-button/create-congresses-button.component';
import { SectionHistoryPanelComponent } from '../../components/section-history-panel/section-history-panel.component';
import { Gt2SelectComponent } from '../../components/gt2-select/gt2-select.component';
import { CollectionTableComponent } from '../../components/collection-table/collection-table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TableSizePipeModule } from '../../modules/table-size.module';
import { FuseSidebarModule } from '../../../../@fuse/components';
import { CreateOrderButtonsComponent } from '../../components/create-order-buttons/create-order-buttons.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatMenuModule,
    CreateOrderButtonsComponent,
    CollectionTableComponent,
    MatSortModule,
    FlexLayoutModule,
    SectionHistoryPanelComponent,
    TableSizePipeModule,
    NgxPermissionsModule,
    CreatedUpdatedAtComponent,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    Gt2SelectComponent,
    FuseSidebarModule,
    CreateCongressesButtonComponent,
    CommentSectionComponent,
    MatPaginatorModule,
    AddressComponent,
    TagsByModelComponent,
    ContactComponent,
    EditorModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    CdkTableModule,
    MatFormFieldModule,
    TranslateModule,
    MatButtonModule,
    MatInputModule,
  ],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class ClientComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  static DATE_FROM_KEY: string = 'clientsDateFrom';
  static DATE_TO_KEY: string = 'clientsDateTo';
  //
  languages: any;
  selectedLanguage: any;
  loading: boolean = false;
  editMode: boolean = false;
  clientID!: string;
  client!: ClientsModelData | any; // EnterprisesModelData;
  clientCalendar: any;
  form: UntypedFormGroup;
  showEditButton: boolean = true;
  @ViewChild('addressComponent')
  addressComponent!: AddressComponent;
  @ViewChild('userAddressComponent')
  userAddressComponent!: AddressComponent;
  @ViewChild('contactComponent')
  contactComponent!: ContactComponent | any;
  @ViewChild('noteEditor') noteEditor!: Editor;
  @ViewChild('tags') tags!: TagsComponent;
  noteEditordebounceTimeValue: number = 1500;
  noteInvalid: boolean = false;
  editable: boolean = true;
  isSmallScreen!: boolean;

  enterprisesTableItemAction!: EnterprisesTableItemAction;
  enterprisesTableItemActionEnd!: EnterprisesTableItemActionEnd;
  // eventsItemAction: EventsItemAction;

  // --o EVENTS DATA TABLE
  dataSource = new MatTableDataSource();
  displayedColumns = [
    'name',
    'event_number',
    'event_date',
    'status',
    'shop_name',
    'btn',
  ];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  // --o CALENDAR
  view: string;
  viewDate: Date;
  events!: CalendarEvent[];
  public actions: CalendarEventAction[];
  activeDayIsOpen: boolean;
  refresh: Subject<any> = new Subject();
  declare dialogRef: any;
  confirmDialogRef!: MatDialogRef<GenericAlertComponent>;
  selectedDay: any;
  //
  formDates: UntypedFormGroup;
  dateRange1: any;

  // userAddressComponent
  //
  clientCategories!: any[];
  selectedClientCategoryUUID!: string;
  selectedClientCategory: any = {
    object: 'ClientCategory',
    uuid: 'e778b527-577d-42b3-90a1-034b39a7e80a',
    name: 'Administration / gouvernement ',
    slug: 'administration-gouvernement',
  };

  // --o Subscribe
  clientMatTabGroupSubscription!: Subscription;

  // langSubscription: Subscription;
  richTextsValidator!: RichTextsValidator;
  isCTEditable: boolean = false;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public personnalTitlesService: PersonnalTitlesService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,
    public location: Location,
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    public gt2LocalService: Gt2LocaleService,
    public print: PrintService,
    public fileService: FileService,
    private api: Gt2ApiService,
    public toastr: ToastrService,
    public rolesService: RolesService,
    public clientsCategoriesService: ClientsCategoriesService,
    public datatableHelperService: DatatableHelperService,
    private clientsService: ClientsService,
    private clientReceiptComposeService: ClientReceiptComposeService,
    private titleService: Title,
    private _fuseSidebarService: FuseSidebarService,
    public paymentDealinesService: PaymentDeadlineService,
    private adapter: DateAdapter<any>,
    public moduleService: ModulesService,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      first_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      last_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      client_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      personal_title: '',
      communication_local: '',
      client_category: '',
    });

    //
    this.formDates = this.formBuilder.group({
      date_from1: ['', [Validators.required]],
      date_to1: ['', [Validators.required]],
      // date_from2: ['', [Validators.required]],
      // date_to2: ['', [Validators.required]],
      // date_from3: ['', [Validators.required]],
      // date_to3: ['', [Validators.required]],
    });
    //
    this.adapter.setLocale(this.translate.currentLang);

    const dateFrom1 = localStorage.getItem(
      ClientComponent.DATE_FROM_KEY + '_1',
    );
    const dateTo1 = localStorage.getItem(ClientComponent.DATE_TO_KEY + '_1');
    this.dateRange1 = {
      date_from: dateFrom1
        ? moment(dateFrom1).format('YYYY-MM-DD')
        : moment().startOf('isoWeek').format('YYYY-MM-DD'),
      date_to: dateTo1
        ? moment(dateTo1).format('YYYY-MM-DD')
        : moment().endOf('isoWeek').format('YYYY-MM-DD'),
    };

    // --o Calendar
    this.view = 'month';
    this.viewDate = new Date();
    this.activeDayIsOpen = false;
    this.selectedDay = { date: startOfDay(new Date()) };

    this.actions = [
      {
        label: '<i class="material-icons s-16">edit</i>',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          // TODO:
          this.editEvent('edit', event);
        },
      },
      {
        label: '<i class="material-icons s-16">delete</i>',
        // TODO:
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.deleteEvent(event);
        },
      },
    ];
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 600px)'])
      .subscribe((result) => {
        this.isSmallScreen = result.matches;
      });

    //this.titleService.setTitle(this.translate.instant("GENERIC.CLIENT"));

    // language
    this.languages = LocalizationConfig.LANGUAGES;
    this.selectedLanguage = LocalizationConfig.getCurrentLanguage();

    this.route.params.subscribe((params: any) => {
      this.clientID = params.id;
      this.loading = true;
      this.loadClient();

      // if (this.enterprisesTableItemActionEnd) {
      //     this.enterprisesTableItemActionEnd.clientUUID = this.clientID;
      // }
    });

    this.enterprisesTableItemAction = new EnterprisesTableItemAction(
      this.router,
    );
    this.enterprisesTableItemAction.itemClick.subscribe((item) => {
      this.onEnterprise(item);
    });
    //
    // //this.logger.info("enterprisesTableItemActionEnd instanciated!");
    this.enterprisesTableItemActionEnd = new EnterprisesTableItemActionEnd(
      this.clientsService,
    );
    this.enterprisesTableItemActionEnd.itemClick.subscribe((item) => {
      this.onDetachEnterprise(item);
    });
    //

    this.richTextsValidator = new RichTextsValidator();

    // this.eventsItemAction = new EventsItemAction(this.router);
    // this.eventsItemAction.itemClick.subscribe( item =>
    // {
    //     this.onEvent(item);
    // });

    this.moduleService.modulesSet.subscribe((areSet) => {
      this.isCTEditable = this.moduleService.hasCTReport();
    });
  }

  public onDateFromChange1(event: any): void {
    // //this.logger.info(
    //     "CrudEmployeeComponent.onDateFromChange() -> this.dateRange1: " +
    //         JSON.stringify(this.dateRange1)
    // );
    // GT2CalendarUtils.formatDateForAPI(event);
    this.dateRange1.date_from = event;
    // //this.logger.info(
    //     "CrudEmployeeComponent.onDateFromChange() -> this.dateRange1: " +
    //         JSON.stringify(this.dateRange1)
    // );
    this.setDateFrom1(this.dateRange1.date_from);
    // this.setEmployeeReportModel();
    this.reloadTable();
  }

  public setDateFrom1(dateFrom: string): void {
    const formatedDate = moment(dateFrom).format('YYYY-MM-DD');
    localStorage.setItem(
      ClientComponent.DATE_FROM_KEY + '_1',
      formatedDate.toString(),
    );
  }

  public setDateTo1(dateTo: string): void {
    const formatedDate = moment(dateTo).format('YYYY-MM-DD');
    localStorage.setItem(
      ClientComponent.DATE_TO_KEY + '_1',
      formatedDate.toString(),
    );
  }

  public reloadTable(): void {
    // //this.logger.info("CrudEmployeeComponent.reloadAssignmentsTable() -> *** this.paginator: " + this.paginator);
    // if (this.paginator) {
    //     // //this.logger.info("CrudEmployeeComponent.reloadAssignmentsTable() -> *** this.paginator EXIST ");
    //     this.paginator._changePageSize(this.paginator.pageSize);
    // }
  }
  public onDateToChange1(event: any): void {
    // //this.logger.info(
    //     "CrudEmployeeComponent.onDateToChange() -> event: " + event
    // );

    // //this.logger.info(
    //     "CrudEmployeeComponent.onDateToChange() -> this.dateRange1: " +
    //         JSON.stringify(this.dateRange1)
    // );
    // //this.logger.info(
    //     "CrudEmployeeComponent.onDateToChange() -> event: " + event
    // );

    // //this.logger.info("CrudEmployeeComponent.onDateToChange1() " + this.sort);
    // //this.logger.info("CrudEmployeeComponent.onDateToChange() -> this.dateRange1: " + JSON.stringify(this.dateRange1));
    this.dateRange1.date_to = GT2CalendarUtils.formatDateForAPI(event);
    this.setDateTo1(this.dateRange1.date_to);
    // this.setEmployeeReportModel();
    this.reloadTable();
  }

  public onSelectToday1(): void {
    //this.logger.info("CrudEmployeeComponent.onSelectToday1() " + this.sort);
    const today: any = moment().format('YYYY-MM-DD');
    this.dateRange1.date_from = today;
    this.dateRange1.date_to = today;
    // //this.logger.info("CrudEmployeeComponent.onSelectToday() -> totday: " + today);
    this.reloadTable();
  }

  public onSelectThisWeek1(): void {
    // //this.logger.info(
    //     "CrudEmployeeComponent.onSelectThisWeek() " + this.sort
    // );
    const dateFrom: any = moment().startOf('isoWeek').format('YYYY-MM-DD');
    const dateTo: any = moment().endOf('isoWeek').format('YYYY-MM-DD');
    this.dateRange1.date_from = dateFrom;
    this.dateRange1.date_to = dateTo;
    this.reloadTable();
  }

  public onSelectThisMonth1(): void {
    // //this.logger.info("CrudEmployeeComponent.onSelectThisMonth() ");
    const dateFrom: any = moment().startOf('month').format('YYYY-MM-DD');
    const dateTo: any = moment().endOf('month').format('YYYY-MM-DD');
    this.dateRange1.date_from = dateFrom;
    this.dateRange1.date_to = dateTo;
    // this.reloadAssignmentsTable();
  }

  public initRichText(): void {
    this.richTextsValidator?.registerRichText(this.noteEditor, 'noteEditor');
  }

  ngAfterViewInit(): void {
    if (this.editMode) {
      this.setControlsChanges(this.form);
    }
    // this.setMatTabListener();

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.isLoadingResults = true;

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.clientsService.getAllClientEvents(
            this.sort?.active,
            this.sort?.direction,
            this.paginator?.pageIndex,
            this.paginator?.pageSize,
            this.clientID,
          );
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe((data) => (this.dataSource.data = data));
  }

  ngOnDestroy(): void {
    if (this.clientMatTabGroupSubscription) {
      this.clientMatTabGroupSubscription.unsubscribe();
    }

    if (this.richTextsValidator) {
      this.richTextsValidator.destroy();
    }
  }

  public canDeactivate(): boolean {
    // //this.logger.info(
    //     "ClientComponent.loadClient() -> canDeactivate: " + this.form.dirty
    // );
    if (this.addressComponent && this.addressComponent.form.dirty) {
      return false;
    }

    if (this.contactComponent && this.contactComponent.form.dirty) {
      return false;
    }
    return !this.form.dirty && !this.richTextsValidator?.dirty();
  }

  public loadClient(): void {
    this.loading = true;
    this.clientsService.getClient(this.clientID).subscribe(
      (response) => {
        // //this.logger.info("ClientComponent.loadClient() -> SUCCESS: " + JSON.stringify(response));
        //this.logger.info("ClientComponent.loadClient() -> shop_user: " + JSON.stringify(response.data.shop_user));
        const isFirstLoad: boolean =
          this.client === null || this.client == undefined;
        this.client = response.data;
        this.loading = false;
        this.editable = !this.client?.isArchive;

        //this.titleService.setTitle(this.translate.instant("GENERIC.CLIENT") + " " + this.client.name_label);

        //
        if (isFirstLoad) {
          setTimeout(() => {
            this.initRichText();
          }, 500);
        }
      },
      (error) => {
        this.logger.error(
          'ClientComponent.loadClient() -> ERROR: ' + JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    );

    // this.clientsService.getClientCalendar(this.clientID).subscribe(response => {
    //         // //this.logger.info("ClientComponent.loadClient().getClientCalendar() -> SUCCESS: " + JSON.stringify(response));
    //         this.clientCalendar = response.data;
    //         this.events = this.clientCalendar.map(item => {
    //             item.actions = this.actions;
    //             return new CalendarEventModel(item);
    //         });
    //         // //this.logger.info("ClientComponent.loadEnterprise().getEnterpriseCalendar() -> this.events: " + JSON.stringify(this.events));
    //     },
    //     error => {
    //         //this.logger.info("ClientComponent.loadClient().getClientCalendar() -> ERROR: " + JSON.stringify(error));
    //         this.handleAPIError(error, this.dialog);
    //     }
    // );
  }

  public deleteClient(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clientsService.deleteClient(this.clientID).subscribe(
          (response) => {
            // //this.logger.info("ClientComponent.deleteEnterprise() -> SUCCESS: " + JSON.stringify(response));
            this.toastr.success(
              '',
              this.translate.instant('GENERIC.DELETE_SUCCESS'),
            );
            this.router.navigateByUrl('/' + AppRoutes.CLIENTS);
          },
          (error) => {
            this.logger.error(
              'ClientComponent.deleteClient() -> ERROR: ' +
                JSON.stringify(error),
            );
            // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
            this.loading = false;
            this.handleAPIError(
              error,
              this.dialog,
              this.toastr,
              this.translate.instant('GENERIC.ERRORS.GENERIC'),
            );
          },
        );
      }
    });
  }

  public onCreateOrder(): void {
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '45%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        subType: CreateEventComponent.ORDER_BY_CLIENT_SUBTYPE,
        client_uuid: this.client?.uuid,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      // //this.logger.info('ClientComponent.onNewEvent() -> result: ' + JSON.stringify(result));
      if (result) {
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }

      this.dialogRef = null;
    });
  }

  public onNewProposal(): void {
    // //this.logger.info("ClientComponent.onNewProposal()");
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '45%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        subType: CreateEventComponent.PROPOSAL_BY_CLIENT_SUBTYPE,
        client_uuid: this.client?.uuid,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      // //this.logger.info('ClientComponent.onNewEvent() -> result: ' + JSON.stringify(result));
      if (result) {
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }

      this.dialogRef = null;
    });
  }

  public setMatTabListener(): void {
    // this.clientMatTabGroupSubscription = this.clientMatTabGroup.selectedIndexChange.subscribe(index => {
    //     //this.logger.info("ClientComponent.ngAfterViewInit() -> clientMatTabGroup.selectedIndexChange: " + index);
    //     this.showEditButton = index === 0;
    // });
  }

  // public onPrint(): void
  // {
  //     const url: string = this.api.createUrl('/reports/clients/html/') + this.client.uuid;
  //     this.print.printHTML(url);
  // }

  public onOpenInNewTab(): void {
    this.openInNewTab(this.router.url);
  }

  public onOpenEventInNewTab(event: any): void {
    //this.logger.info("onOpenEventInNewTab() -> " + JSON.stringify(event));
    this.openInNewTab(ItemUrlFinder.getItemURL(event));
  }

  public onNavigateToItem(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  //
  public doPrint(url: string, report_mode: string): void {
    this.dateRange1.report_mode = report_mode;
    // const dateRange = {
    //     date_from: GT2CalendarUtils.formatDateForAPI(
    //         this.dateRange1.date_from
    //     ),
    //     date_to: GT2CalendarUtils.formatDateForAPI(this.dateRange1.date_to),
    // };
    this.print.postPrintHTML(url, this.dateRange1).subscribe(
      (response: any) => {
        // //this.logger.info("ClientComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      (error: any) => {
        this.logger.error(
          'ClientComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public doDownload(url: string, report_mode: string): void {
    this.dateRange1.report_mode = report_mode;
    this.fileService.postDownloadFile(url, this.dateRange1).subscribe(
      (response) => {
        // //this.logger.info("ClientComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      (error) => {
        this.logger.error(
          'ClientComponent.doDownload() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  // EVENT REPORT
  public onPrintEventsReport(report_mode: string): void {
    // //this.logger.info("ClientComponent.onPrintEventsReport()");
    // reports/client/report-client-statement-client/html/{uuid_client}
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PRINT,
        'client/report-client-statement-client',
        'fr-CA',
      ) +
      '/' +
      this.clientID;
    // this.print.printHTML(url);
    this.doPrint(url, report_mode);
  }

  public onDowloadEventsReport(report_mode: string): void {
    // //this.logger.info("ClientComponent.onDowloadEventsReport()");
    // reports/client/report-client-statement-client/pdf/{uuid_client}
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        'client/report-client-statement-client',
        'fr-CA',
      ) +
      '/' +
      this.clientID;
    // this.fileService.downloadFile(url);
    this.doDownload(url, report_mode);
  }

  public onSendEventsReport(report_mode: string): void {
    // //this.logger.info("ClientComponent.onSendEventsReport()");

    // IMPORTANT
    this.clientReceiptComposeService.parentUUID = this.clientID;
    this.dateRange1.report_mode = report_mode;

    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: this.clientReceiptComposeService,
        postData: this.dateRange1,
      },
    });
    this.dialogRef.afterClosed().subscribe((response: any) => {
      if (!response) {
        return;
      }
      this.dialogRef = null;
    });
  }

  public onDownloadPDF(): void {
    let report_mode = '';
    const url: string =
      this.api.createUrl('/reports/clients/pdf/') + this.client?.uuid;
    // const fileName: string = this.fileService.createFileName("client", this.client.slug, "pdf");
    // this.fileService.downloadFile(url, fileName);
    this.doDownload(url, report_mode);
  }

  public onDownloadVCard(): void {
    // //this.logger.info("onDownloadVCard() ");
    let report_mode = '';
    const url: string =
      this.api.createUrl('/reports/clients/vcard/') + this.client?.uuid;
    // const fileName: string = this.fileService.createFileName("client", this.client.slug, "vcf");
    // this.fileService.downloadFile(url, fileName);
    this.doDownload(url, report_mode);
  }

  public onEnterprise(enterprise: SimpleEnterpriseModel): void {
    // //this.logger.info("ClientComponent.onEnterprise() -> enterprise: " + JSON.stringify(enterprise));
    // const url: string = '/enterprises/' + enterprise.slug + "/" + enterprise.uuid;
  }

  public onDetachEnterprise(enterprise: SimpleEnterpriseModel): void {
    //this.logger.info("ClientComponent.onDetachEnterprise() -> enterprise: " + JSON.stringify(enterprise));

    this.loading = true;
    this.clientsService.detachClientToEnterprise(this.clientID).subscribe(
      (response) => {
        //this.logger.info("ClientComponent.onDetachEnterprise() -> SUCCESS: " + JSON.stringify(response));
        this.loadClient();
      },
      (error) => {
        this.loading = false;
        this.logger.error(
          'ClientComponent.onDetachEnterprise() -> ERROR: ' +
            JSON.stringify(error),
        );
      },
    );
    // const url: string = '/enterprises/' + enterprise.slug + "/" + enterprise.uuid;
  }

  public onEvent(event: any): void {
    // //this.logger.info("ClientComponent.onEvent() -> event: " + JSON.stringify(event));
  }

  public onEdit($event: any): void {
    this.editMode = !this.editMode;
    //this.logger.info("this.editMode: " + this.editMode);

    if (this.addressComponent) {
      this.addressComponent.editMode = this.editMode;
      if (!this.editMode) {
        this.addressComponent.onManualSave();
      }
    }

    if (this.userAddressComponent) {
      // this.userAddressComponent.editMode = this.editMode;
      if (!this.editMode) {
        // CT only
        this.userAddressComponent.onManualSave();
      }
    }

    if (this.contactComponent) {
      this.contactComponent.editMode = this.editMode;
      if (!this.editMode) {
        this.contactComponent.onManualSave();
      }
    }

    if (this.editMode) {
      //
    } else {
      this.loading = true;
      this.clientsService.updateClient(this.client, this.clientID).subscribe(
        (response) => {
          // //this.logger.info("ClientComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.loadClient();
          this.form.markAsPristine();
          this.form.markAsUntouched();

          //
          if (this.addressComponent && this.addressComponent.form.dirty) {
            this.addressComponent.form.markAsPristine();
            this.addressComponent.form.markAsUntouched();
          }

          if (this.contactComponent && this.contactComponent.form.dirty) {
            this.contactComponent.form.markAsPristine();
            this.contactComponent.form.markAsUntouched();
          }

          if (this.richTextsValidator) {
            this.richTextsValidator.clean();
          }
        },
        (error) => {
          this.loading = false;
          this.logger.error(
            'ClientComponent.autosaveDataChange() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
    }
  }

  public onCancelEdit(event: any): void {
    this.editMode = false;
    if (this.addressComponent) {
      this.addressComponent.editMode = this.editMode;
    }

    if (this.contactComponent) {
      this.contactComponent.editMode = this.editMode;
    }

    if (this.noteEditor) {
      this.noteEditor.readonly = !this.editMode;
    }

    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.loadClient();
  }

  public onClientCategoryChange(event: any): void {
    // this.logger.debug('ClientComponent.onClientCategoryChange()');
    // //this.logger.info("ClientComponent.onClientCategoryChange() -> value: " + event.value);
    // //this.logger.info("ClientComponent.onClientCategoryChange() -> title: " + JSON.stringify(event));
    // this.autosaveDataChange({ client_category: event });
    this.client.client_category = event;
  }

  public onChange($event: any): void {
    this.loadClient();
  }

  public override autosaveDataChange(data: any): void {}

  // TEST
  public onCreateClient(): void {
    this.logger.debug('ClientComponent.onCreateClient()');

    this.dialogRef = this.dialog.open(CreateClientComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // //this.logger.info("POP UP Closed!");
      }
      this.dialogRef = null;
    });
  }

  public onCreateClientAndEnterprise(): void {
    this.logger.debug('ClientComponent.onCreateClientAndEnterprise()');
  }

  public onAddEnterprise(): void {
    // //this.logger.info("ClientComponent.onAddEnterprise()");
    this.dialogRef = this.dialog.open(CreateEnterpriseForClientComponent, {
      width: '340px',
      disableClose: false,
      data: { uuid: this.client?.uuid },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // //this.logger.info("POP UP Closed! -> result: " + JSON.stringify(result));
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(result.data));
      }
      this.dialogRef = null;
    });
  }

  public onOpenObjectPage(item: any): void {
    // //this.logger.info("ClientComponent.onOpenObjectPage() -> item: " + JSON.stringify(item));
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public onLocalSelected(event: any): void {
    this.client.communication_local = event;
    // this.autosaveDataChange({
    //     communication_local: this.client.communication_local,
    // });
  }

  public onPaymentDealinesSelected(event: any): void {
    this.client.payment_deadline = event;
    // this.autosaveDataChange({
    //     payment_deadline: this.client.payment_deadline,
    // });
  }

  // --o CALENDAR

  /**
   * Before View Renderer
   * @param {any} header
   * @param {any} body
   */
  beforeMonthViewRender({ header, body }: { header: any; body: any }) {
    // console.info('beforeMonthViewRender');
    /**
     * Get the selected day
     */
    const _selectedDay = body.find((_day: any) => {
      return _day.date.getTime() === this.selectedDay.date.getTime();
    });

    if (_selectedDay) {
      /**
       * Set selectedday style
       * @type {string}
       */
      _selectedDay.cssClass = 'mat-elevation-z3';
    }
  }

  /**
   * Day clicked
   * @param {MonthViewDay} day
   */
  dayClicked(day: CalendarMonthViewDay): void {
    const date: Date = day.date;
    const events: CalendarEvent[] = day.events;

    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
    this.selectedDay = day;
    this.refresh.next(undefined);
  }

  /**
   * Event times changed
   * Event dropped or resized
   * @param {CalendarEvent} event
   * @param {Date} newStart
   * @param {Date} newEnd
   */
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    // console.warn('Dropped or resized', event);
    this.refresh.next(true);
  }

  /**
   * Delete Event
   * @param event
   */
  deleteEvent(event: any) {
    // this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
    //     disableClose: false
    // });
    //
    // this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    //
    // this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if ( result )
    //     {
    //         const eventIndex = this.events.indexOf(event);
    //         this.events.splice(eventIndex, 1);
    //         this.refresh.next(true);
    //     }
    //     this.confirmDialogRef = null;
    // });
  }

  /**
   * Edit Event
   * @param {string} action
   * @param {CalendarEvent} event
   */
  editEvent(action: string, event: CalendarEvent) {
    // const eventIndex = this.events.indexOf(event);
    //
    // this.dialogRef = this.dialog.open(FuseCalendarEventFormDialogComponent, {
    //     panelClass: 'event-form-dialog',
    //     data      : {
    //         event : event,
    //         action: action
    //     }
    // });
    //
    // this.dialogRef.afterClosed()
    //     .subscribe(response => {
    //         if ( !response )
    //         {
    //             return;
    //         }
    //         const actionType: string = response[0];
    //         const formData: FormGroup = response[1];
    //         switch ( actionType )
    //         {
    //             /**
    //              * Save
    //              */
    //             case 'save':
    //
    //                 this.events[eventIndex] = Object.assign(this.events[eventIndex], formData.getRawValue());
    //                 this.refresh.next(true);
    //
    //                 break;
    //             /**
    //              * Delete
    //              */
    //             case 'delete':
    //
    //                 this.deleteEvent(event);
    //
    //                 break;
    //         }
    //     });
  }

  /**
   * Add Event
   */
  addEvent(): void {
    this.dialogRef = this.dialog.open(FuseCalendarEventFormDialogComponent, {
      panelClass: 'event-form-dialog',
      data: {
        action: 'new',
        date: this.selectedDay.date,
      },
    });
    this.dialogRef.afterClosed().subscribe((response: UntypedFormGroup) => {
      if (!response) {
        return;
      }
      const newEvent = response.getRawValue();
      newEvent.actions = this.actions;
      this.events?.push(newEvent);
      this.refresh.next(true);
    });
  }

  public onPersonnalTitleSelected(title: any): void {
    // //this.logger.info("ClientComponent.onPersonnalTitleSelected() -> title: " + JSON.stringify(title));
    // this.autosaveDataChange({ personal_title: title });
    this.client.personal_title = title;
  }

  public onNewOrder(): void {
    // //this.logger.info("ClientComponent.onNewOrder()");
    // this.dialogRef = this.dialog.open(GenericAlertComponent, {
    //     width: '45%',
    //     disableClose: false
    // });
    // this.dialogRef.componentInstance.alertMessage = "Fonction à venir"; // this.translate.instant("CLIENTS_CATEGORIES.DELETE_CONFIRM", {value: selected.name});
    // // this.dialogRef.componentInstance.showConfirmButton = false;

    // this.dialogRef.afterClosed().subscribe(result => {
    //     this.dialogRef = null;
    // });

    this.dialogRef = this.dialog.open(CreateOrderComponent, {
      width: '45%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        client_uuid: this.clientID,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      // //this.logger.info('ClientComponent.onCreateOrder() -> result: ' + JSON.stringify(result));
      if (result) {
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }

      this.dialogRef = null;
    });
  }

  public onAttachEnterprise() {
    // //this.logger.info("ClientComponent.onAttachEnterprise()");
    // ClientAttachEnterpriseComponent
    this.dialogRef = this.dialog.open(
      ClientAttachEnterpriseTablePaginatedComponent,
      {
        width: '80%',
        minWidth: 350,
        maxHeight: '98vh',
        panelClass: 'table-dialog-container',
        disableClose: false,
        data: { client: this.client },
      },
    );
    this.dialogRef.componentInstance.alertMessage = 'Fonction à venir'; // this.translate.instant("CLIENTS_CATEGORIES.DELETE_CONFIRM", {value: selected.name});
    // this.dialogRef.componentInstance.showConfirmButton = false;

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // //this.logger.info("ATTACH SUCCESS -> NEW CLIENT MODEL RECEIVED!");
        this.client = result.data;
      }
      this.dialogRef = null;
    });
  }

  public onGENERATE_ACCOUNT_STATEMENTInfo(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useHTML = true;
    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('REPORTS_HELP.TITLE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('REPORTS_HELP.REPORT_CLIENT_STATEMENT_CLIENT');

    this.confirmDialogRef.afterClosed().subscribe((result) => {});
  }

  public toggleHistoricSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
  public onVisitEnterprise(): void {
    // //this.logger.info("OrderComponent.onVisitEvent() - > " + ItemUrlFinder.getItemURL(this.order.event));
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL(this.client?.enterprise),
    );
  }

  public onCreateNewCongress(event: any): void {
    //this.logger.info("CongressesComponent.onCreateNewCongress() -> event: " + event);
    this.dialogRef = this.dialog.open(CreateCongresDialogComponent, {
      width: '440px',
      minWidth: 350,
      maxHeight: '96vh',
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: { autoRedirect: true },
    });
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.reloadTable();
      }
      this.dialogRef = null;
    });
  }

  public onGoToCongresses(): void {
    this.router.navigateByUrl('/' + AppRoutes.CONGRESSES);
  }
}

export class EnterprisesTableItemAction implements IDynamicTableItemAction {
  itemClick: Subject<any> = new Subject();

  constructor(public router: Router) {}

  onItemClick(item: any): void {
    // const url: string = '/' + AppRoutes.ENTERPRISES + "/" + item.slug + "/" + item.uuid;
    const url: string = ItemUrlFinder.getItemURL(item);
    // this.itemClick.next(item);
    this.itemClick.next(item);
    this.router.navigateByUrl(url);
  }
}

export class EnterprisesTableItemActionEnd implements IDynamicTableItemAction {
  itemClick: Subject<any> = new Subject();

  public clientUUID?: string;
  constructor(public clientsService: ClientsService) {}

  onItemClick(item: any): void {
    // const url: string = '/' + AppRoutes.ENTERPRISES + "/" + item.slug + "/" + item.uuid;
    //     const url: string = ItemUrlFinder.getItemURL(item);
    //     // this.itemClick.next(item);
    this.itemClick.next(item);
    //     this.router.navigateByUrl(url);
  }
}

// export class EventsItemAction implements IDynamicTableItemAction
// {

//     itemClick: Subject<any> = new Subject();

//     constructor(public router: Router)
//     {
//     }

//     onItemClick( item: any ): void
//     {
//         // const url: string = '/' + AppRoutes.CLIENTS + "/" + item.slug + "/" + item.uuid;
//         const url: string = ItemUrlFinder.getItemURL(item);
//         this.itemClick.next(item);
//         this.router.navigateByUrl(url);
//     }
// }
