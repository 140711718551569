import { ReportVOModel } from './../../app/gt2/reports/reports-factory';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reportButtonLabel',
  pure: false,
})
export class ReportButtonLabelPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} value
   * @param {number} id
   * @param {string} property
   * @returns {any}
   */
  transform(vo: ReportVOModel): string {
    // GENERIC.DOWNLOAD_PDF;
    // GENERIC.DOWNLOAD_XLS;
    // GENERIC.PRINT
    // GENERIC.PREVIEW
    const prefix: string = 'GENERIC.';
    if (vo.export_to === 'PREVIEW') {
      return prefix + 'PREVIEW';
    } else if (vo.export_to === 'PRINT') {
      return prefix + 'PRINT';
    } else if (vo.export_to === 'DOWNLOAD') {
      if (vo.export_format === 'XLSX') {
        return prefix + 'DOWNLOAD_XLS';
      } else if (vo.export_format === 'PDF') {
        return prefix + 'DOWNLOAD_PDF';
      }
    }
    return '';
  }
}
