import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Editor } from "primeng/editor";
export class RichTextsValidator {
  noteEditors = {};

  constructor() {}

  public registerRichText(noteEditor: Editor, id: string): void {
    this.noteEditors[id] = {
      id: id,
      editor: noteEditor,
      oldValue: noteEditor.value,
      subscription: null,
      dirty: false,
    };

    this.observeEditor(this.noteEditors[id]);
  }

  public clean(): void {
    for (let key in this.noteEditors) {
      this.noteEditors[key].dirty = false;
      this.noteEditors[key].oldValue = this.noteEditors[key].editor.value;
    }
  }

  public dirty(): boolean {
    for (let key in this.noteEditors) {
      if (this.noteEditors[key].dirty) {
        return true;
      }
    }
    return false;
  }

  public destroy(): void {
    for (let key in this.noteEditors) {
      this.noteEditors[key].subscription.unsubscribe();
      this.noteEditors[key] = null;
    }
    this.noteEditors = null;
  }

  private observeEditor(model: NoteEditorModel): void {
    model.subscription = model.editor.onTextChange
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe((data) => {
        if (data.htmlValue) {
          model.dirty = data.htmlValue !== model.oldValue;
        }
      });
  }
}

export interface NoteEditorModel {
  id: string;
  editor: Editor;
  oldValue: string;
  subscription: Subscription;
  dirty: boolean;
}
