import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { IComposeService } from '../content/compose/compose.component';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import { GT2FirebaseService } from '../../services/gt2-firebase.service';

@Injectable()
export class InvoiceComposeService implements IComposeService {
  parentUUID: any;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getData(): Observable<any> {
    //this.logger.info("InvoiceComposeService.getData()");
    //
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.INVOICE_COMPOSER_GET) + this.parentUUID
    );
  }

  public saveData(data: any): Observable<any> {
    //this.logger.info("InvoiceComposeService.saveData()");
    // return this.http.post<any>(this.api.createUrl(ApiRoutes.INVOICE_COMPOSER_SAVE) + this.parentUUID, data);
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.INVOICE_COMPOSER_SAVE) +
        this.parentUUID +
        '/' +
        GT2FirebaseService.CLIENT_KEY,
      data
    );
  }
}
