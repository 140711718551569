export const locale = {
  lang: "en-CA",
  data: {
    CLIENTS_CATEGORIES: {
      SETTINGS: "Settings",
      CREATE_NEW_CATEGORY: "New category",
      CREATE_SUCCESS: "Category created successfully",
      EDIT_SUCCESS: "Category modified successfully",
      DELETE_SUCCESS: "Category deleted successfully",
      DELETE_CONFIRM: "Are you sure you want to delete category {{value}}?",
      NEW_CATEGORY_NAME: "Name of new category",
      CATEGORY_NAME: "Category name",
    },
  },
};
