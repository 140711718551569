<mat-form-field
  class="example-chip-list"
  appearance="outline"
  *ngIf="tagsModel"
  floatLabel="always"
>
  <mat-label>{{ tagPlaceholder }}</mat-label>
  <mat-chip-grid #chipList>
    <mat-chip
      *ngFor="let tag of tags"
      (selectionChange)="tagInput.blur()"
      [ngClass]="getTagColor(tag, tagsModel)"
      [removable]="removable"
      (removed)="remove(tag)"
    >
      {{ tag }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      #tagInput
      [disabled]="disabled"
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete
    #auto="matAutocomplete"
    #tagsMatAutocomplete
    (optionSelected)="selected($event)"
  >
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div
          style="width: 22px; height: 22px"
          [ngClass]="getTagColor(tag, autoCompleteTags)"
          class="mr-8"
        ></div>
        <div>{{ tag }}</div>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
