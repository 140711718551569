import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "googleLinkPipe",
})
export class GoogleLinkPipe implements PipeTransform {
  static GOOGLE_LINK: string = "http://www.google.com/maps/place/";

  transform(value: any, args?: any): string {
    if (!value.lat && !value.long) {
      return "GoogleLinkPipe: no lat or long params found";
    }
    return GoogleLinkPipe.GOOGLE_LINK + value.lat + "," + value.long;
  }
}
