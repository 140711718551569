export const locale = {
  lang: "en-CA",
  data: {
    CREATE_ENTERPRISE: {
      TITLE: "New enterprise",
      TITLE_BRANCH: "New branch",
      CREATE_SUCCESS: "Succursale créée avec succès",
    },
  },
};
