<!-- HINT TEMPLATES -->
<ng-template #startMessageHintTemplate>
  <p>{{ "SHOPS.START_MESSAGE_HINT" | translate }}</p>
</ng-template>

<ng-template #noServiceMessageHintTemplate>
  <p>{{ "SHOPS.NO_SERVICES_MESSAGE_HINT" | translate }}</p>
</ng-template>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
  <div *ngIf="shopMessage" class="info-line" fxFlex="100%">
    <div class="title-big mb-12" fxLayoutAlign="start center">
      {{ "SHOPS.START_MESSAGE" | translate }}
      <button
        class="mb-4"
        mat-icon-button
        matTooltip="startMessageHintTemplate"
        content-type="template"
        type="button"
      >
        <mat-icon class="s-20" color="">help</mat-icon>
      </button>
    </div>
    <div class="title">{{ "GENERIC.fr-CA" | translate }}</div>
    <app-rich-text-locale
      #messageFR
      [(richText)]="shopMessage.message_local"
      [localKey]="'message_fr_CA'"
      [maxLength]="2048"
      [editMode]="editMode"
      [showClearButton]="false"
      [showSaveButton]="false"
      (changeEvent)="messageFRInvalid = $event.invalid"
      [invalid]="messageFRInvalid || messageENInvalid || !editMode"
    >
    </app-rich-text-locale>

    <div class="title mt-16">{{ "GENERIC.en-CA" | translate }}</div>
    <app-rich-text-locale
      #messageEN
      [(richText)]="shopMessage.message_local"
      [localKey]="'message_en_CA'"
      [maxLength]="2048"
      [editMode]="editMode"
      [invalid]="messageFRInvalid || messageENInvalid || !editMode"
      (changeEvent)="messageENInvalid = $event.invalid"
      (clearEvent)="onClearMessage()"
      (saveEvent)="onSaveMessage($event)"
    >
    </app-rich-text-locale>

    <!-- <div class="title-big mb-12 mt-16">
            {{ 'SHOPS.NO_SERVICES_MESSAGE' | translate }}
            <button class="mb-4" mat-icon-button
                matTooltip="noServiceMessageHintTemplate" content-type="template"
                type="button">
                <mat-icon class="s-20" color="">help</mat-icon>
            </button>
        </div> -->

    <!-- <div class="title">{{ 'GENERIC.fr-CA' | translate }}</div>
        <app-rich-text-locale #messageUnavailableFR
            [(richText)]="shop.shop_message_unavailable.message_local"
            [localKey]="'message_fr_CA'" [maxLength]="2048" [editMode]="editMode"
            [invalid]="messageUnavailableFRInvalid || messageUnavailableENInvalid || !editMode"
            (changeEvent)="messageUnavailableFRInvalid = $event.invalid"
            [showSaveButton]='false' [showClearButton]='false'>
        </app-rich-text-locale>

        <div class="title mt-16">{{ 'GENERIC.en-CA' | translate }}</div>
        <app-rich-text-locale #messageUnavailableEN
            [(richText)]="shop.shop_message_unavailable.message_local"
            [localKey]="'message_en_CA'" [maxLength]="2048" [editMode]="editMode"
            [invalid]="messageUnavailableFRInvalid || messageUnavailableENInvalid || !editMode"
            (changeEvent)="messageUnavailableENInvalid = $event.invalid"
            (clearEvent)="onClearUnavailableMessage()"
            (saveUnavailableMessage)="onSaveUnavailableMessage($event)">
        </app-rich-text-locale> -->
  </div>
</div>
