export const locale = {
  lang: "en-CA",
  data: {
    DASHBOARD: {
      LATEST: "Latest",
      NO_ITEMS: "No item",
      NO_ORDERS: "There are no orders for that day",
      NO_PRODUCTS_PZ: "There are no products fot that day",
      CREATE_ORDER: "Create an order",
      CREATE_EVENT: "Create an event",
      CREATE_PROPOSAL: "Create a proposal",
      CREATE_INVOICE: "Create an invoice",
      ROOM_MANAGEMENT: "Room management",
      DETAILED_CALENDAR: "Detailed calendar",
      INCLUDE_CANCEL_ABORT: "Include canceled and abandoned events",
      CHOOSE_A_DATE: "Choose a date range",
      FROM: "FROM",
      TO: "TO",
      YESTERDAY: "Yesterday",
      LAST_7_DAYS: "Last 7 days",
      LAST_30_DAYS: "Last 30 days",
      THIS_WEEK: "This week",
      NEXT_2_WEEK: "Next 2 weeks",
      THIS_MONTH: "This month",
      NEXT_MONTH: "Next month",
      LAST_MONTH: "Last month",
      GUEST_COUNT: "Guest count:",
      EVENTS_TOTAL: "Events total:",
      NO_RECORDS: "No records found for those dates",
      LOADING: "Loading...",
      ALL_DELIVERY_TYPE: "All delivery type",
      ALL_STATUS_TYPE: "All status",
      ALL_ORDER_TYPE: "All order status",
      DELIVERY_ONLY: "Delivery only",
      DELIVER_INSTALL: "Deliver and install",
      DELIVERY_EMPLOYEES: "With staff",
      DELIVERY_PICKUP: "Cueillette au comptoir",
      EVENT_OPEN: "Open",
      EVENT_IN_ORDER: "En commande",
      EVENT_IN_INVOICE: "En facturation",
      EVENT_IN_PROPOSAL: "En proposition",
      EVENT_CANCEL: "Canceled",
      EVENT_ABORT: "Abandonné",
      EVENT_COMPLETE: "Completed",
      EVENT_PAST_EVENT: "Past event",

      ORDER_OPEN: "Order in preparation",
      ORDER_SEND: "Order sent",
      ORDER_CANCEL: "Order cancelled",
      ORDER_CONFIRM: "Order confirmed",
      ORDER_TO_COMPLETE: "Commande a complété",
      ORDER_TO_INVOICE: "Commande à facturer",
      ORDER_COMPLETE: "Commande complété",

      PROPOSAL_OPEN: "Proposal in preparation",
      PROPOSAL_PROPOSE: "Proposal sent",
      PROPOSAL_REFUSE: "Proposal refused",
      PROPOSAL_EXPIRE: "Proposition expiré",
      PROPOSAL_DISCARD: "Proposition écarté",
      PROPOSAL_ACCEPT: "Proposition accepté",

      INVOICE_OPEN: "Invoice issued",
      INVOICE_SEND: "Invoice sent",
      INVOICE_PAID: "Invoice paid",
      INVOICE_OVER_DUE: "Facture en retard",
      INVOICE_CANCEL: "Invoice cancelled",

      ALL_ORDERS: "All orders",
      ALL_PROPOSALS: "All proposals",
      ALL_INVOICES: "All invoices",

      RESET_TABLE: "Reset table",
      RESET_TABLE_MESSAGE: "Resetting the table will not delete any of your data. Only information relating to column width, filters, displayed columns, etc, will be reset to their default values.",

      SLIDER_LABEL: "Valeur > ",
      CHOOSE_COLUMNS: "Choose columns",
      SELECTED_COLUMNS: "{0} selected columns",
      EXPORT_XLS: "Toutes les données",
      EXPORT_FILTERES_XLS: "Données filtrées",
      TOTAL_SELLING: "Total selling",
      INCLUDE_CANCEL_ABORT_EVENTS: "Include canceled and abandoned events",
    },
    ROOM_MANAGEMENT: {
      TITLE: "Room management",
      CREATE_TITLE: "Create new event",
      ORDER_TYPE: "Order type",
      ROOM_NAME: "Room name",
      PLACE_NAME: "Place name",
      NOTE: "Note: There are no internal places",
    },
    DASHBOARD_DETAILED: {
      EVENT_TYPE: "Event type",
      ORDER_NUMBER: "Order number",
      ORDER_CLIENT_NAME: "Order customer name",
      ENTERPRISE_NAME: "Enterprise name",
      ENTERPRISE_DELIVERED_TO: "Enterprise delivered to",
      EVENT_NUMBER: "Event number",
      ORDER_NAME: "Order name",
      PURCHASE_ORDER_NUMBER: "Purchase order number",
      INVOICE_CUSTOMER_NAME: "Invoice customer name",
      INVOICE_NUMBER: "Invoice number",
      BALANCE: "Remnant pay",
      NET_AMOUNT: "Net amount",
      AMOUNT_WITH_TAXES: "Amount with taxes",
      EVENT_STATUS: "Event status",
    },
  },
};
