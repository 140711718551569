<div
  id="order"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <!-- HEADER -->
  <div class="gt2-order-theme">
    <div
      [ngClass]="orderTypeHeaderColor || 'accent'"
      class="header p-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="start center">
          <button class="mr-0 white-fg" mat-icon-button>
            <!-- (click)="onPageBack(location, router, '/products')" -->
            <mat-icon class="icon-mat">arrow_back</mat-icon>
          </button>
          <div *ngIf="order" class="mr-8 pt-8">
            <mat-icon *ngIf="order.type === 'ORDER'" class="s-52 icon-mat">{{
              getIcon("ORDER")
            }}</mat-icon>
            <mat-icon *ngIf="order.type === 'PROPOSAL'" class="s-52 icon-mat">{{
              getIcon("PROPOSAL")
            }}</mat-icon>
            <mat-icon *ngIf="order.type === 'INVOICE'" class="s-52 icon-mat">{{
              getIcon("INVOICE")
            }}</mat-icon>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center start">
          <div
            *ngIf="order"
            class="user-info"
            fxLayout="row"
            fxLayoutAlign="start start"
          >
            <div class="order-title text-truncate">
              <div class="text-truncate">
                <span *ngIf="order.type === 'ORDER'" class="text-truncate">
                  {{ "ORDERS.ORDER" | translate }} - {{ order.name }}
                </span>
                <span *ngIf="order.type === 'PROPOSAL'" class="text-truncate">
                  {{ "ORDERS.PROPOSAL" | translate }} - {{ order.name }}
                </span>
                <span *ngIf="order.type === 'INVOICE'" class="text-truncate">
                  {{ "ORDERS.INVOICE" | translate }} - {{ order.name }}
                </span>
              </div>
            </div>
            <div *ngIf="order.order_number" class="order-title text-truncate">
              <div class="text-truncate">
                <span class="text-truncate"> - {{ order.order_number }} </span>
              </div>
            </div>
            <div *ngIf="order.invoice_number" class="order-title text-truncate">
              <div class="text-truncate">
                <span class="text-truncate">
                  &nbsp;- {{ order.invoice_number }}
                </span>
              </div>
            </div>
            <div
              *ngIf="order.proposal_number"
              class="order-title text-truncate"
            >
              <div class="text-truncate">
                <span class="text-truncate">
                  &nbsp;- {{ order.proposal_number }}
                </span>
              </div>
            </div>
          </div>

          <div
            *ngIf="order"
            fxLayout="row"
            fxLayoutAlign="start start"
            class=""
          >
            <div *ngIf="order && order.status" class="">
              <span class="icon-mat">
                {{ "GENERIC.STATUS" | translate }}:
                {{ order.status_label }}
              </span>
            </div>
            <div class="">
              <span class="icon-mat">
                &nbsp; - {{ "GENERIC.GUEST_S" | translate }}(s):
                {{ order.guest_count }}
              </span>
            </div>
            <div
              class=""
              matTooltip="{{ 'GENERIC.RESPONSIBLE_EMPLOYEE' | translate }}"
              *ngIf="order && order.responsible_employee"
            >
              <span class="icon-mat">
                &nbsp; - {{ order.responsible_employee.name }}</span
              >
            </div>
            <div *ngIf="event && event.congress_number && event.is_ctm">
              <span class="icon-mat">
                &nbsp; - {{ "GENERIC.SPCM_NUMBER" | translate }} :
                {{ event.congress_number }}
              </span>
            </div>
            <div *ngIf="event && event.congress_number && !event.is_ctm">
              <span class="icon-mat">
                &nbsp; - # {{ "GENERIC.CONGRESS" | translate }}:
                {{ event.congress_number }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center">
        <div
          *ngIf="order && order.event && order.event.delivery_type"
          matTooltip="{{
            'GENERIC.DELIVERY_TYPE' + order.event.delivery_type.delivery_type_id
              | translate
          }}"
        >
          <mat-icon
            class="mx-12 fa-lg icon-mat"
            *ngIf="order.event.delivery_type.delivery_type_id === 1"
            fontSet="fa"
            fontIcon="fa-shipping-fast"
          >
          </mat-icon>
          <mat-icon
            class="mx-12 fa-lg icon-mat"
            *ngIf="order.event.delivery_type.delivery_type_id === 2"
            fontSet="fa"
            fontIcon="fa-utensils"
          >
          </mat-icon>
          <mat-icon
            class="mx-12 fa-lg icon-mat"
            *ngIf="order.event.delivery_type.delivery_type_id === 3"
            fontSet="fa"
            fontIcon="fa-users"
          >
          </mat-icon>
          <mat-icon
            class="mx-12 fa-lg icon-mat"
            *ngIf="order.event.delivery_type.delivery_type_id === 4"
            fontSet="fa"
            fontIcon="fa-concierge-bell"
          >
          </mat-icon>
        </div>

        <div
          *ngIf="
            order &&
            order.event &&
            order.event.informations &&
            order.event.informations.organization_identity
          "
        >
          <img
            *ngIf="event.informations.organization_identity.logo"
            class="pr-12 pt-4"
            src="{{ order.event.informations.organization_identity.logo }}"
          />
        </div>
        <div class="user-info">
          <div *ngIf="order && order.event" class="event-date mr-8">
            {{ order.event.event_time_label }}
          </div>
          <div *ngIf="order && order.event" class="event-date mr-8">
            {{ order.event.event_date_label }}
          </div>
        </div>

        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          *ngxPermissionsOnly="rolesService.SALE_ROLES"
          (click)="onOpenInNewTab(order)"
          class="mat-icon-button"
        >
          <mat-icon class="s-28 icon-mat">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    *ngIf="order"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="content-subheader primary mat-elevation-z1"
  >
    <!-- SUB HEADER - LEFT -->
    <div fxLayout="row" fxLayoutAlign="start center">
      <!-- TEAM_LEAD_ROLES -->
      <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
        <button
          mat-button
          *ngIf="
            event &&
            moduleService.modulesAreSet &&
            moduleService.moduleExist('MODULE_STAFF_ASSIGNMENT')
          "
          (click)="onAffectations()"
          class=""
        >
          <mat-icon
            fontSet="fa"
            fontIcon="{{ getIcon('ASSIGNMENT_USER') }}"
            class="fa-lg fa-icon-btn icon-mat"
          >
          </mat-icon>
          <span class="ml-8 icon-mat">{{
            "ORDERS.ASSIGNMENTS" | translate
          }}</span>
        </button>
      </div>

      <button mat-button (click)="onVisitEvent()" class="">
        <mat-icon class="s-20 icon-mat">{{ getIcon("EVENT") }}</mat-icon>
        <span class="ml-8 icon-mat">{{
          "ORDERS.VISIT_EVENT" | translate
        }}</span>
      </button>

      <button mat-button (click)="onVisitDeposit()" class="">
        <mat-icon class="s-20 icon-mat">{{ getIcon("ORDER") }}</mat-icon>
        <span class="ml-8 icon-mat">{{
          "ORDERS.VISIT_DEPOSITS" | translate
        }}</span>
      </button>

      <div *ngIf="event && event.congress_uuid && event.congress_slug" class="">
        <button mat-button (click)="onNavigateToCongress()" class="">
          <mat-icon class="s-24 icon-mat">{{ getIcon("CONGRESS") }}</mat-icon>
          <span class="ml-8 icon-mat">{{
            "GENERIC.CONGRESS" | translate
          }}</span>
        </button>
      </div>

      <div *ngIf="event && event.project_uuid && event.project_slug" class="">
        <button mat-button (click)="onNavigateToProject()" class="">
          <mat-icon class="s-24 icon-mat">{{ getIcon("PROJECT") }}</mat-icon>
          <span class="ml-8 icon-mat">{{ "GENERIC.PROJECT" | translate }}</span>
        </button>
      </div>
    </div>
    <!-- / SUB HEADER - LEFT -->

    <!-- SUB HEADER - RIGHT -->
    <div fxLayout="row" fxLayoutAlign="end center" class="mr-8">
      <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
        <button
          *ngIf="order && editable && order.type === 'PROPOSAL'"
          mat-button
          (click)="onCloneProposition()"
          class=""
        >
          <mat-icon class="s-20 icon-mat">{{ getIcon("CLONE") }}</mat-icon>
          <span class="ml-8 icon-mat">{{
            "ORDERS.CLONE_PROPOSITION" | translate
          }}</span>
        </button>
      </div>

      <div *ngxPermissionsOnly="rolesService.SALE_ROLES" class="mr-20">
        <button
          *ngIf="order && order.type !== 'INVOICE'"
          mat-button
          class="primary"
          [matMenuTriggerFor]="menuClone"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="s-20 icon-mat">
              {{ getIcon("CLONE") }}
            </mat-icon>
            <span class="icon-mat ml-8">
              {{ "ORDERS.CLONE_INTO_EVENT" | translate }}
            </span>
            <mat-icon class="icon-mat">more_vert</mat-icon>
          </div>
        </button>
        <mat-menu #menuClone="matMenu">
          <button
            *ngIf="order && order.type === 'PROPOSAL'"
            mat-menu-item
            (click)="onClonePropositionIntoNewEvent(false)"
            class=""
          >
            {{ "ORDERS.CLONE_PROPOSITION_INTO_EVENT" | translate }}
          </button>
          <button
            *ngIf="order && order.type === 'PROPOSAL'"
            mat-menu-item
            (click)="onClonePropositionIntoNewEvent(true)"
            class=""
          >
            {{ "ORDERS.CLONE_PROPOSITION_INTO_EVENT_KEEP_INFO" | translate }}
          </button>

          <button
            *ngIf="
              order && order.type !== 'PROPOSAL' && order.type !== 'INVOICE'
            "
            mat-menu-item
            (click)="onCloneOrderIntoNewEvent(false)"
            class=""
          >
            {{ "ORDERS.CLONE_ORDER_INTO_EVENT" | translate }}
          </button>
          <button
            *ngIf="
              order && order.type !== 'PROPOSAL' && order.type !== 'INVOICE'
            "
            mat-menu-item
            (click)="onCloneOrderIntoNewEvent(true)"
            class=""
          >
            {{ "ORDERS.CLONE_ORDER_INTO_EVENT_KEEP_INFO" | translate }}
          </button>
        </mat-menu>
      </div>

      <div fxLayout="row" class="mr-8">
        <!-- PROPOSAL PRINT IN STANDARD MODE -->
        <button
          mat-icon-button
          *ngIf="
            order.type === 'PROPOSAL' &&
            modulesService.modulesAreSet &&
            !modulesService.hasLoLReport() &&
            !modulesService.hasDANReport() &&
            !modulesService.hasCTReport() &&
            fileService.printEnabled()
          "
          class="primary mr-8"
          matTooltip="{{ 'GENERIC.PRINT_PROPOSAL' | translate }}"
          (click)="
            menuReportsService.buildAndRunVo(
              ReportsEnum.REPORT_CLIENT_PROPOSAL,
              DistributionModeEnum.FILE,
              ExportFormatEnum.PDF,
              ExportToEnum.PRINT,
              LocaleEnum.FR_CA,
              PaperEnum.LETTER,
              {
                uuid_order: this.order.uuid,
              }
            )
          "
        >
          <mat-icon class="s-28 subheader-menu-icon p-12 icon-mat">{{
            getIcon("PRINT")
          }}</mat-icon>
        </button>

        <button
          mat-icon-button
          *ngIf="
            order.type === 'PROPOSAL' &&
            modulesService.modulesAreSet &&
            modulesService.hasLoLReport() &&
            fileService.printEnabled()
          "
          class="primary mr-8"
          matTooltip="{{ 'GENERIC.PRINT_PROPOSAL' | translate }}"
          (click)="
            menuReportsService.buildAndRunVo(
              ReportsEnum.REPORT_CLIENT_SERVICE_OFFER_LOL,
              DistributionModeEnum.FILE,
              ExportFormatEnum.PDF_STACK,
              ExportToEnum.PRINT,
              LocaleEnum.FR_CA,
              PaperEnum.LETTER,
              { uuid_order: this.order.uuid }
            )
          "
        >
          <mat-icon class="s-28 icon-mat p-12 subheader-menu-icon">{{
            getIcon("PRINT")
          }}</mat-icon>
        </button>

        <!-- ORDER PRINT MENU -->
        <button
          mat-icon-button
          *ngIf="
            order.type === 'ORDER' ||
            (order.type === 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasDANReport() &&
              fileService.printEnabled())
          "
          class="primary mr-24"
          [matMenuTriggerFor]="isCanada ? menuPrintWithFormat : menuPrint"
          matTooltip="{{ 'GENERIC.PRINT_ORDER' | translate }}"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="s-28 p-12 icon-mat subheader-menu-icon">{{
              getIcon("PRINT")
            }}</mat-icon>
            <mat-icon class="icon-mat">more_vert</mat-icon>
          </div>
        </button>

        <mat-menu #menuPrint="matMenu">
          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasUDEMReport()
            "
            (click)="onPrintProposalReport()"
          >
            {{ "GENERIC.PROPOSAL" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasGABReport() &&
              !modulesService.hasJLReport() &&
              !modulesService.hasUQAMReport()
            "
            (click)="onPrintOrder()"
          >
            {{ "GENERIC.CONTRAT_GAB" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_JUL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            Commande - JUL
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION_JUL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            Bon de commande - JUL
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasUQAMReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_UQAM,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            Commande - UQAM
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_ORDER_CT_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.CONTRAT_BANQUET" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              !modulesService.hasGABReport() &&
              !modulesService.hasCTReport() &&
              !modulesService.hasUQAMReport() &&
              !modulesService.hasJLReport()
            "
            (click)="onPrintOrder()"
          >
            {{ "GENERIC.ORDER" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_SERVICE" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_CHEF" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_HOT" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_COLD" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_HOD_AND_COLD" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_PASTRY" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasGABReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_PURCHASE_EVENT_PRODUCTION_GAB,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.ORDER_GAB" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{
              "ORDERS.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_STAFF"
                | translate
            }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT" | translate }}
          </button>

          <button mat-menu-item (click)="onPrintPurchaseOrder()">
            {{ "ORDERS.PURCHASE_ORDER" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasUQAMReport()
            "
            mat-menu-item
            (click)="onPrintPurchaseOrderKitchen()"
          >
            {{ "ORDERS.PURCHASE_ORDER_KITCHEN" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasUQAMReport()
            "
            mat-menu-item
            (click)="onPrintPurchaseOrderClient()"
          >
            {{ "ORDERS.PURCHASE_ORDER_CLIENT" | translate }}
          </button>
          <button mat-menu-item (click)="onPrintPurchaseOrderMaterial()">
            {{ "ORDERS.PURCHASE_ORDER_MATERIAL" | translate }}
          </button>
          <button mat-menu-item (click)="onPrintPurchaseOrderOnlyMaterial()">
            {{ "ORDERS.PURCHASE_ORDER_ONLY_MATERIAL" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasJLReport()
            "
            [matMenuTriggerFor]="print_en"
          >
            Anglais
          </button>
        </mat-menu>
        <!--            CANADA SUB-MENU-->
        <mat-menu #menuPrintWithFormat="matMenu">
          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasUDEMReport()
            "
            (click)="onPrintProposalReport()"
          >
            {{ "GENERIC.PROPOSAL" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasGABReport() &&
              !modulesService.hasJLReport() &&
              !modulesService.hasUQAMReport()
            "
            (click)="onPrintOrder()"
          >
            {{ "ORDERS.CONTRAT_GAB" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_JUL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            Commande - JUL
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION_JUL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            Bon de commande - JUL
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasUQAMReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_UQAM,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            Commande - UQAM
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_ORDER_CT_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.CONTRAT_BANQUET" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_SERVICE" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_CHEF" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_HOT" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_COLD" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_HOD_AND_COLD" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_PASTRY" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              !modulesService.hasGABReport() &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport() &&
              !modulesService.hasUQAMReport()
            "
            (click)="onPrintOrder()"
          >
            {{ "GENERIC.ORDER" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasGABReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_PURCHASE_EVENT_PRODUCTION_GAB,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.ORDER_GAB" | translate }}
          </button>

          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasUQAMReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="receiptNoticeMenuPrint"
          >
            {{ "ORDERS.REPORT_CLIENT_RECEIPT_NOTICE_UQAM" | translate }}
          </button>
          <mat-menu #receiptNoticeMenuPrint="matMenu">
            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_RECEIPT_NOTICE_AND_PURCHASE_ORDERS_UQAM_STACK,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF_STACK,
                  ExportToEnum.PRINT,
                  LocaleEnum.FR_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              Permis pour servir
            </button>
            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_RECEIPT_NOTICE_SELL_ONLY_UQAM,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF,
                  ExportToEnum.PRINT,
                  LocaleEnum.FR_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              Permis pour vendre
            </button>
            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_STORAGE_NOTICE_UQAM,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF,
                  ExportToEnum.PRINT,
                  LocaleEnum.FR_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              Avis d’entreposage
            </button>
          </mat-menu>

          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printDanPurchaseOrderStackFormat"
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printPurchaseOrderKitchenStaffFormat"
          >
            {{
              "ORDERS.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_STAFF"
                | translate
            }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printDanPurchaseOrderDeliveryFormat"
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printDanPurchaseOrderClientFormat"
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT" | translate }}
          </button>

          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printPurchaseOrder"
          >
            {{ "ORDERS.PURCHASE_ORDER" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport() &&
              !modulesService.hasUQAMReport() &&
              !modulesService.hasUQAMReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printPurchaseOrderKitchen"
          >
            {{ "ORDERS.PURCHASE_ORDER_KITCHEN" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport() &&
              !modulesService.hasUQAMReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printPurchaseOrderClient"
          >
            {{ "ORDERS.PURCHASE_ORDER_CLIENT" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printPurchaseOrderMaterial"
          >
            {{ "ORDERS.PURCHASE_ORDER_MATERIAL" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printPurchaseOrderOnlyMaterial"
          >
            {{ "ORDERS.PURCHASE_ORDER_ONLY_MATERIAL" | translate }}
          </button>
          <!-- AVERY -->
          <div
            *ngIf="modulesService.modulesAreSet && modulesService.hasJLReport()"
          >
            <button
              *ngIf="order.wrapping && order.wrapping.in_rpt_plate_buffet_lbl"
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT2_MGNT_ORDER_PLATE_BUFFET_LABEL_5963,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF,
                  ExportToEnum.PRINT,
                  LocaleEnum.FR_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              {{ "ORDERS.BUFFET_LABEL_5963" | translate }}
            </button>

            <button
              *ngIf="order.wrapping && order.wrapping.in_rpt_box_lbl"
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT2_MNGT_ORDER_BOX_LABEL_55164,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF,
                  ExportToEnum.PRINT,
                  LocaleEnum.FR_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              {{ "ORDERS.BOX_LABEL_55164" | translate }}
            </button>
          </div>

          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="isCanada ? print_canada_en : print_en"
          >
            Anglais
          </button>
        </mat-menu>

        <mat-menu #printPurchaseOrder="matMenu">
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrder('fr-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrder('fr-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printPurchaseOrderKitchen="matMenu">
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderKitchen('fr-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderKitchen('fr-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <mat-menu #printPurchaseOrderKitchenStaffFormat="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printDanPurchaseOrderDeliveryFormat="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printDanPurchaseOrderClientFormat="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printDanPurchaseOrderStackFormat="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <mat-menu #printPurchaseOrderClient="matMenu">
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderClient('fr-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderClient('fr-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printPurchaseOrderMaterial="matMenu">
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderMaterial('fr-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderMaterial('fr-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printPurchaseOrderOnlyMaterial="matMenu">
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderOnlyMaterial('fr-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderOnlyMaterial('fr-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <mat-menu #print_en="matMenu">
          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_JUL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            Commande - JUL
          </button>
          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasUQAMReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_UQAM,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            Commande - UQAM
          </button>

          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{
              "ORDERS.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_STAFF"
                | translate
            }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT" | translate }}
          </button>

          <button mat-menu-item (click)="onPrintPurchaseOrder('en-CA')">
            {{ "ORDERS.PURCHASE_ORDER" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasUQAMReport()
            "
            mat-menu-item
            (click)="onPrintPurchaseOrderKitchen('en-CA')"
          >
            {{ "ORDERS.PURCHASE_ORDER_KITCHEN" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasUQAMReport()
            "
            mat-menu-item
            (click)="onPrintPurchaseOrderClient('en-CA')"
          >
            {{ "ORDERS.PURCHASE_ORDER_CLIENT" | translate }}
          </button>
          <button mat-menu-item (click)="onPrintPurchaseOrderMaterial('en-CA')">
            {{ "ORDERS.PURCHASE_ORDER_MATERIAL" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderOnlyMaterial('en-CA')"
          >
            {{ "ORDERS.PURCHASE_ORDER_ONLY_MATERIAL" | translate }}
          </button>
        </mat-menu>

        <mat-menu #print_canada_en="matMenu">
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printDanPurchaseOrderStackFormatEN"
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printPurchaseOrderKitchenStaffFormatEN"
          >
            {{
              "ORDERS.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_STAFF"
                | translate
            }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printDanPurchaseOrderDeliveryFormatEN"
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printDanPurchaseOrderClientFormatEN"
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT" | translate }}
          </button>

          <button mat-menu-item [matMenuTriggerFor]="printPurchaseOrderEN">
            {{ "ORDERS.PURCHASE_ORDER" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasUQAMReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printPurchaseOrderKitchenEN"
          >
            {{ "ORDERS.PURCHASE_ORDER_KITCHEN" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasUQAMReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="printPurchaseOrderClientEN"
          >
            {{ "ORDERS.PURCHASE_ORDER_CLIENT" | translate }}
          </button>
          <button
            mat-menu-item
            [matMenuTriggerFor]="printPurchaseOrderMaterialEN"
          >
            {{ "ORDERS.PURCHASE_ORDER_MATERIAL" | translate }}
          </button>

          <button
            mat-menu-item
            [matMenuTriggerFor]="printPurchaseOrderMaterialEN"
          >
            {{ "ORDERS.PURCHASE_ORDER_ONLY_MATERIAL" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_JUL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            Commande - JUL
          </button>

          <div
            *ngIf="modulesService.modulesAreSet && modulesService.hasJLReport()"
          >
            <button
              mat-menu-item
              *ngIf="order.wrapping && order.wrapping.in_rpt_plate_buffet_lbl"
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT2_MGNT_ORDER_PLATE_BUFFET_LABEL_5963,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF,
                  ExportToEnum.PRINT,
                  LocaleEnum.EN_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              {{ "ORDERS.BUFFET_LABEL_5963" | translate }}
            </button>

            <button
              *ngIf="order.wrapping && order.wrapping.in_rpt_box_lbl"
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT2_MNGT_ORDER_BOX_LABEL_55164,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF,
                  ExportToEnum.PRINT,
                  LocaleEnum.EN_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              {{ "ORDERS.BOX_LABEL_55164" | translate }}
            </button>
          </div>
        </mat-menu>
        <mat-menu #printPurchaseOrderEN="matMenu">
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrder('en-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrder('en-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printPurchaseOrderKitchenEN="matMenu">
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderKitchen('en-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderKitchen('en-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <mat-menu #printPurchaseOrderKitchenStaffFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printDanPurchaseOrderDeliveryFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printDanPurchaseOrderClientFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printDanPurchaseOrderStackFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.PRINT,
                LocaleEnum.EN_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <mat-menu #printPurchaseOrderClientEN="matMenu">
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderClient('en-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderClient('en-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printPurchaseOrderMaterialEN="matMenu">
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderMaterial('en-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onPrintPurchaseOrderMaterial('en-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <!-- PROPOSAL DOWNLOAD IN STANDARD MODE -->
        <button
          mat-icon-button
          *ngIf="
            order.type === 'PROPOSAL' &&
            modulesService.modulesAreSet &&
            !modulesService.hasLoLReport() &&
            !modulesService.hasCTReport() &&
            fileService.pdfEnabled()
          "
          class="primary mr-8"
          matTooltip="{{ 'GENERIC.DOWNLOAD_PROPOSAL' | translate }}"
          (click)="
            menuReportsService.buildAndRunVo(
              ReportsEnum.REPORT_CLIENT_PROPOSAL,
              DistributionModeEnum.FILE,
              ExportFormatEnum.PDF,
              ExportToEnum.DOWNLOAD,
              LocaleEnum.FR_CA,
              PaperEnum.LETTER,
              {
                uuid_order: this.order.uuid,
              }
            )
          "
        >
          <mat-icon class="s-28 p-12 icon-mat subheader-menu-icon">{{
            getIcon("PDF")
          }}</mat-icon>
        </button>

        <button
          mat-icon-button
          *ngIf="
            order.type === 'PROPOSAL' &&
            modulesService.modulesAreSet &&
            modulesService.hasLoLReport() &&
            fileService.pdfEnabled()
          "
          class="primary mr-8"
          matTooltip="{{ 'GENERIC.DOWNLOAD_PROPOSAL' | translate }}"
          (click)="
            menuReportsService.buildAndRunVo(
              ReportsEnum.REPORT_CLIENT_SERVICE_OFFER_LOL,
              DistributionModeEnum.FILE,
              ExportFormatEnum.PDF_STACK,
              ExportToEnum.DOWNLOAD,
              LocaleEnum.FR_CA,
              PaperEnum.LETTER,
              {
                uuid_order: this.order.uuid,
              }
            )
          "
        >
          <mat-icon class="p-12 icon-mat s-28 subheader-menu-icon">{{
            getIcon("PDF")
          }}</mat-icon>
        </button>

        <button
          mat-icon-button
          *ngIf="
            order.type === 'ORDER' ||
            (order.type === 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasDANReport() &&
              fileService.pdfEnabled())
          "
          class="primary mr-8"
          [matMenuTriggerFor]="isCanada ? menuDownloadWithFormat : menuDownload"
          matTooltip="{{ 'GENERIC.DOWNLOAD_ORDER' | translate }}"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("PDF")
            }}</mat-icon>
            <mat-icon class="icon-mat">more_vert</mat-icon>
          </div>
        </button>

        <!-- DOWNLOAD NOT CANADA - NO PAPER CHOICE -->
        <mat-menu #menuDownload="matMenu">
          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_JUL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            Commande - JUL
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION_JUL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            Bon de commande - JUL
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasUQAMReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_UQAM,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            Commande - UQAM
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_ORDER_CT_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.CONTRAT_BANQUET" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasUDEMReport()
            "
            (click)="onDowloadProposalReport()"
          >
            {{ "GENERIC.PROPOSAL" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasGABReport() &&
              !modulesService.hasJLReport()
            "
            (click)="onDownloadOrder()"
          >
            {{ "ORDERS.CONTRAT_GAB" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="order.type !== 'PROPOSAL' && modulesService.hasUDEMReport()"
            (click)="onDowloadProposalReport()"
          >
            {{ "GENERIC.PROPOSAL" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              !modulesService.hasGABReport() &&
              !modulesService.hasCTReport() &&
              !modulesService.hasUQAMReport() &&
              !modulesService.hasJLReport()
            "
            (click)="onDownloadOrder()"
          >
            {{ "GENERIC.ORDER" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_SERVICE" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_CHEF" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_HOT" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_COLD" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_HOD_AND_COLD" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_PASTRY" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasGABReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_PURCHASE_EVENT_PRODUCTION_GAB,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.ORDER_GAB" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{
              "ORDERS.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_STAFF"
                | translate
            }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT" | translate }}
          </button>

          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport()
            "
            mat-menu-item
            (click)="onDownloadPurchaseOrder()"
          >
            {{ "ORDERS.PURCHASE_ORDER" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport() &&
              !modulesService.hasUQAMReport()
            "
            mat-menu-item
            (click)="onDownloadPurchaseOrderKitchen()"
          >
            {{ "ORDERS.PURCHASE_ORDER_KITCHEN" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport() &&
              !modulesService.hasUQAMReport()
            "
            mat-menu-item
            (click)="onDownloadPurchaseOrderClient()"
          >
            {{ "ORDERS.PURCHASE_ORDER_CLIENT" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport()
            "
            mat-menu-item
            (click)="onDownloadPurchaseOrderMaterial()"
          >
            {{ "ORDERS.PURCHASE_ORDER_MATERIAL" | translate }}
          </button>
          <!--<button mat-menu-item (click)="onDownloadPurchaseOrderStaff()">{{ "ORDERS.PURCHASE_ORDER_STAFF" | translate }}</button>-->
          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="download_en"
          >
            Anglais
          </button>
        </mat-menu>

        <!-- DOWNLOAD CANADA - WITH PAPER CHOICE -->
        <mat-menu #menuDownloadWithFormat="matMenu">
          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_JUL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            Commande - JUL
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION_JUL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            Bon de commande - JUL
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasUQAMReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_UQAM,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            Commande - UQAM
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_ORDER_CT_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.CONTRAT_BANQUET" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_SERVICE" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasUDEMReport()
            "
            (click)="onDowloadProposalReport()"
          >
            {{ "GENERIC.PROPOSAL" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasGABReport() &&
              !modulesService.hasJLReport()
            "
            (click)="onDownloadOrder()"
          >
            {{ "ORDERS.CONTRAT_GAB" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              !modulesService.hasGABReport() &&
              !modulesService.hasCTReport() &&
              !modulesService.hasUQAMReport() &&
              !modulesService.hasJLReport()
            "
            (click)="onDownloadOrder()"
          >
            {{ "GENERIC.ORDER" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_CHEF" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_HOT" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_COLD" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_HOD_AND_COLD" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.PURCHASE_ORDER_PASTRY" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasGABReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_PURCHASE_EVENT_PRODUCTION_GAB,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.ORDER_GAB" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasUQAMReport()
            "
            [matMenuTriggerFor]="receiptNoticeMenuDownload"
          >
            {{ "ORDERS.REPORT_CLIENT_RECEIPT_NOTICE_UQAM" | translate }}
          </button>

          <mat-menu #receiptNoticeMenuDownload="matMenu">
            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_RECEIPT_NOTICE_AND_PURCHASE_ORDERS_UQAM_STACK,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF_STACK,
                  ExportToEnum.DOWNLOAD,
                  LocaleEnum.FR_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              Permis pour servir
            </button>
            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_RECEIPT_NOTICE_SELL_ONLY_UQAM,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF,
                  ExportToEnum.DOWNLOAD,
                  LocaleEnum.FR_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              Permis pour vendre
            </button>
            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_STORAGE_NOTICE_UQAM,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF,
                  ExportToEnum.DOWNLOAD,
                  LocaleEnum.FR_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              Avis d’entreposage
            </button>
          </mat-menu>

          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadDanPurchaseOrderStackFormat"
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadPurchaseOrderKitchenStaffFormat"
          >
            {{
              "ORDERS.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_STAFF"
                | translate
            }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadDanPurchaseOrderDeliveryFormat"
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadDanPurchaseOrderClientFormat"
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT" | translate }}
          </button>

          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadPurchaseOrderFormat"
          >
            {{ "ORDERS.PURCHASE_ORDER" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport() &&
              !modulesService.hasUQAMReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadPurchaseOrderKitchenFormat"
          >
            {{ "ORDERS.PURCHASE_ORDER_KITCHEN" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport() &&
              !modulesService.hasUQAMReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadPurchaseOrderClientFormat"
          >
            {{ "ORDERS.PURCHASE_ORDER_CLIENT" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadPurchaseOrderMaterialFormat"
          >
            {{ "ORDERS.PURCHASE_ORDER_MATERIAL" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadPurchaseOrderOnlyMaterialFormat"
          >
            {{ "ORDERS.PURCHASE_ORDER_ONLY_MATERIAL" | translate }}
          </button>

          <div
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
          >
            <button
              mat-menu-item
              *ngIf="order.wrapping && order.wrapping.in_rpt_plate_buffet_lbl"
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT2_MGNT_ORDER_PLATE_BUFFET_LABEL_5963,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF,
                  ExportToEnum.DOWNLOAD,
                  LocaleEnum.FR_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              {{ "ORDERS.BUFFET_LABEL_5963" | translate }}
            </button>

            <button
              mat-menu-item
              *ngIf="order.wrapping && order.wrapping.in_rpt_box_lbl"
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT2_MNGT_ORDER_BOX_LABEL_55164,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF,
                  ExportToEnum.DOWNLOAD,
                  LocaleEnum.FR_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              {{ "ORDERS.BOX_LABEL_55164" | translate }}
            </button>
          </div>

          <button
            *ngIf="
              modulesService.modulesAreSet &&
              !modulesService.hasCTReport() &&
              !modulesService.hasJLReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="isCanada ? download_canada_en : download_en"
          >
            Anglais
          </button>
        </mat-menu>

        <mat-menu #downloadPurchaseOrderFormat="matMenu">
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrder('fr-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrder('fr-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadPurchaseOrderKitchenFormat="matMenu">
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderKitchen('fr-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderKitchen('fr-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <mat-menu #downloadPurchaseOrderKitchenStaffFormat="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <mat-menu #downloadDanPurchaseOrderDeliveryFormat="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadDanPurchaseOrderClientFormat="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadDanPurchaseOrderStackFormat="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <mat-menu #downloadPurchaseOrderClientFormat="matMenu">
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderClient('fr-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderClient('fr-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadPurchaseOrderMaterialFormat="matMenu">
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderMaterial('fr-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderMaterial('fr-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadPurchaseOrderOnlyMaterialFormat="matMenu">
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderOnlyMaterial('fr-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderOnlyMaterial('fr-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <!--            CANADA SUB-MENU EN-->
        <mat-menu #download_canada_en="matMenu">
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadDanPurchaseOrderStackFormatEN"
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadPurchaseOrderKitchenStaffFormatEN"
          >
            {{
              "ORDERS.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_STAFF"
                | translate
            }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadDanPurchaseOrderDeliveryFormatEN"
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY" | translate }}
          </button>

          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadDanPurchaseOrderClientFormatEN"
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT" | translate }}
          </button>

          <button
            mat-menu-item
            [matMenuTriggerFor]="downloadPurchaseOrderFormatEN"
          >
            {{ "ORDERS.PURCHASE_ORDER" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasUQAMReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadPurchaseOrderKitchenFormatEN"
          >
            {{ "ORDERS.PURCHASE_ORDER_KITCHEN" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasUQAMReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="downloadPurchaseOrderClientFormatEN"
          >
            {{ "ORDERS.PURCHASE_ORDER_CLIENT" | translate }}
          </button>
          <button
            mat-menu-item
            [matMenuTriggerFor]="downloadPurchaseOrderMaterialFormatEN"
          >
            {{ "ORDERS.PURCHASE_ORDER_MATERIAL" | translate }}
          </button>
          <button
            mat-menu-item
            [matMenuTriggerFor]="downloadPurchaseOrderOnlyMaterialFormatEN"
          >
            {{ "ORDERS.PURCHASE_ORDER_ONLY_MATERIAL" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="
              order.type !== 'PROPOSAL' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT2_CLIENT_ORDER_JUL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            Commande - JUL
          </button>

          <div
            *ngIf="modulesService.modulesAreSet && modulesService.hasJLReport()"
          >
            <button
              mat-menu-item
              *ngIf="order.wrapping && order.wrapping.in_rpt_plate_buffet_lbl"
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT2_MGNT_ORDER_PLATE_BUFFET_LABEL_5963,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF,
                  ExportToEnum.DOWNLOAD,
                  LocaleEnum.EN_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              {{ "ORDERS.BUFFET_LABEL_5963" | translate }}
            </button>

            <button
              mat-menu-item
              *ngIf="order.wrapping && order.wrapping.in_rpt_box_lbl"
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT2_MNGT_ORDER_BOX_LABEL_55164,
                  DistributionModeEnum.FILE,
                  ExportFormatEnum.PDF,
                  ExportToEnum.DOWNLOAD,
                  LocaleEnum.EN_CA,
                  PaperEnum.LETTER,
                  { uuid_order: this.order.uuid }
                )
              "
            >
              {{ "ORDERS.BOX_LABEL_55164" | translate }}
            </button>
          </div>
        </mat-menu>

        <mat-menu #downloadPurchaseOrderFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrder('en-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrder('en-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadPurchaseOrderKitchenFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderKitchen('en-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderKitchen('en-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadPurchaseOrderClientFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderClient('en-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderClient('en-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadPurchaseOrderMaterialFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderMaterial('en-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderMaterial('en-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <mat-menu #downloadPurchaseOrderOnlyMaterialFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderOnlyMaterial('en-CA', 'letter')"
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderOnlyMaterial('en-CA', 'legal')"
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <mat-menu #downloadPurchaseOrderKitchenStaffFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadDanPurchaseOrderDeliveryFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadDanPurchaseOrderClientFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadDanPurchaseOrderStackFormatEN="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LEGAL,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <!--            NON CANADA SUB-MENU EN-->
        <mat-menu #download_en="matMenu">
          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_STACK" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{
              "ORDERS.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_STAFF"
                | translate
            }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY" | translate }}
          </button>
          <button
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasDANReport()
            "
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.EN_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "ORDERS.REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT" | translate }}
          </button>

          <button mat-menu-item (click)="onDownloadPurchaseOrder('en-CA')">
            {{ "ORDERS.PURCHASE_ORDER" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasUQAMReport()
            "
            mat-menu-item
            (click)="onDownloadPurchaseOrderKitchen('en-CA')"
          >
            {{ "ORDERS.PURCHASE_ORDER_KITCHEN" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasUQAMReport()
            "
            mat-menu-item
            (click)="onDownloadPurchaseOrderClient('en-CA')"
          >
            {{ "ORDERS.PURCHASE_ORDER_CLIENT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="onDownloadPurchaseOrderMaterial('en-CA')"
          >
            {{ "ORDERS.PURCHASE_ORDER_MATERIAL" | translate }}
          </button>
        </mat-menu>

        <!-- ORDER SEND REPORT -->
        <button
          mat-icon-button
          *ngIf="
            order &&
            order.type === 'ORDER' &&
            modulesService.modulesAreSet &&
            !modulesService.hasUDEMReport()
          "
          [matMenuTriggerFor]="orderSendOrderMenu"
          matTooltip="{{ 'ORDERS.SEND_ORDER_TOOLTIP' | translate }}"
          placement="top"
          class=""
        >
          <mat-icon class="s-24 mr-4 p-12 icon-mat">{{
            getIcon("MAIL")
          }}</mat-icon>
        </button>

        <!-- SEND ORDER WITH PROPOSAL ONLY FOR UDEM -->
        <button
          mat-icon-button
          *ngIf="
            order &&
            order.type === 'ORDER' &&
            modulesService.modulesAreSet &&
            modulesService.hasUDEMReport()
          "
          matTooltip="{{ 'ORDERS.SEND_ORDER_PROPOSAL_TOOLTIP' | translate }}"
          placement="top"
          class=""
          [matMenuTriggerFor]="sendReportMenuUDEM"
        >
          <mat-icon class="s-24 mr-4 p-12 icon-mat">{{
            getIcon("MAIL")
          }}</mat-icon>
        </button>
        <mat-menu #sendReportMenuUDEM="matMenu">
          <button mat-menu-item (click)="onSendPropsalReport()">
            {{ "GENERIC.SEND_PROPOSAL" | translate }}
          </button>
          <button mat-menu-item (click)="onSendOrder()">
            {{ "ORDERS.SEND_ORDER" | translate }}
          </button>
        </mat-menu>

        <mat-menu #orderSendOrderMenu>
          <button mat-menu-item (click)="onSendOrder()">
            {{ "ORDERS.ORDER" | translate }}
          </button>
          <button
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasUQAMReport()
            "
            mat-menu-item
            [matMenuTriggerFor]="receiptNoticeMenuSend"
          >
            {{ "ORDERS.REPORT_CLIENT_RECEIPT_NOTICE_UQAM" | translate }}
          </button>
          <mat-menu #receiptNoticeMenuSend="matMenu">
            <button (click)="onSendReceiptNotice()" mat-menu-item>
              Permis pour servir
            </button>
            <button (click)="onSendReceiptNoticeSellOnly()" mat-menu-item>
              Permis pour vendre
            </button>
            <button (click)="onSendStorageNotice()" mat-menu-item>
              Avis d’entreposage
            </button>
          </mat-menu>
          <button
            *ngIf="
              event &&
              event.ubr &&
              modulesService.modulesAreSet &&
              modulesService.hasUQAMReport()
            "
            mat-menu-item
            (click)="onSendEmailToMangerUBR()"
          >
            {{ "ORDERS.UBR_MANAGER" | translate }}
          </button>
        </mat-menu>
        <!-- ORDER SEND REPORT END -->

        <!-- PROPOSAL REPORT MENU -->
        <button
          mat-button
          *ngIf="
            order.type === 'PROPOSAL' &&
            moduleService.modulesAreSet &&
            moduleService.moduleExist('MODULE_EVENT_PROGRAMS') &&
            moduleService.hasRPReport()
          "
          [matMenuTriggerFor]="proposalReportMenuRP"
          matTooltip="{{ 'GENERIC.PRINT_DOWNLOAD_PROPOSAL' | translate }}"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="s-28 icon-mat">{{ getIcon("REPORT") }}</mat-icon>
            <span class="ml-8 icon-mat">{{
              "GENERIC.REPORT" | translate
            }}</span>
            <mat-icon class="icon-mat">more_vert</mat-icon>
          </div>
        </button>

        <button
          mat-button
          *ngIf="
            order.type === 'PROPOSAL' &&
            moduleService.modulesAreSet &&
            !moduleService.moduleExist('MODULE_EVENT_PROGRAMS') &&
            (moduleService.hasRPReport() || moduleService.hasCTReport())
          "
          [matMenuTriggerFor]="proposalReportMenu"
          matTooltip="{{ 'GENERIC.PRINT_DOWNLOAD_PROPOSAL' | translate }}"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="s-28 icon-mat">{{ getIcon("REPORT") }}</mat-icon>
            <span class="icon-mat ml-8">{{
              "GENERIC.REPORT" | translate
            }}</span>
            <mat-icon class="icon-mat">more_vert</mat-icon>
          </div>
        </button>

        <mat-menu #proposalReportMenu="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_PROPOSAL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            1234 {{ "GENERIC.DOWNLOAD" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_PROPOSAL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            2345 {{ "GENERIC.PRINT" | translate }}
          </button>
        </mat-menu>

        <!-- MENU FOR RP -->
        <mat-menu #proposalReportMenuRP="matMenu">
          <div>
            <button
              mat-menu-item
              [matMenuTriggerFor]="proposalReportRPContractMenu"
              *ngIf="fileService.pdfEnabled()"
            >
              {{ "ORDERS.RP_CONTRACT_FULL" | translate }}
            </button>
          </div>

          <button
            mat-menu-item
            [matMenuTriggerFor]="proposalReportRPContractAccommodationsMenu"
            *ngIf="fileService.pdfEnabled()"
          >
            {{ "ORDERS.RP_CONTRACT_HOSTING" | translate }}
          </button>

          <button
            mat-menu-item
            [matMenuTriggerFor]="proposalReportRPContractRoomsMenu"
            *ngIf="fileService.pdfEnabled()"
          >
            {{ "ORDERS.RP_CONTRACT_ROOMS" | translate }}
          </button>
        </mat-menu>

        <mat-menu #proposalReportRPContractMenu="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_CONTRACT_FULL_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "GENERIC.PRINT" | translate }}
          </button>

          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_CONTRACT_FULL_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "GENERIC.DOWNLOAD" | translate }}
          </button>
        </mat-menu>

        <mat-menu #proposalReportRPContractRoomsMenu="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_CONTRACT_ROOMS_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "GENERIC.PRINT" | translate }}
          </button>

          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_CONTRACT_ROOMS_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "GENERIC.DOWNLOAD" | translate }}
          </button>
        </mat-menu>

        <mat-menu #proposalReportRPContractAccommodationsMenu="matMenu">
          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_CONTRACT_ACCOMODATIONS_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "GENERIC.PRINT" | translate }}
          </button>

          <button
            mat-menu-item
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_CONTRACT_ACCOMODATIONS_STACK,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF_STACK,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            {{ "GENERIC.DOWNLOAD" | translate }}
          </button>
        </mat-menu>

        <!-- INVOICE PRINT BUTTON -->
        <div
          *ngIf="
            order.type === 'INVOICE' &&
            modulesService.modulesAreSet &&
            fileService.printEnabled()
          "
        >
          <!-- GENERIC-->
          <button
            mat-icon-button
            *ngIf="
              !modulesService.hasLoLReport() &&
              !modulesService.hasAlexReport() &&
              !modulesService.hasCTReport()
            "
            class="primary mr-8"
            matTooltip="{{ 'GENERIC.PRINT_INVOICE' | translate }}"
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_INVOICE,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("PRINT")
            }}</mat-icon>
          </button>
          <!-- LOL -->
          <button
            mat-icon-button
            *ngIf="modulesService.hasLoLReport()"
            class="primary mr-8"
            matTooltip="{{ 'GENERIC.PRINT_INVOICE' | translate }}"
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_INVOICE_LOL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("PRINT")
            }}</mat-icon>
          </button>
          <!-- CT -->
          <button
            mat-icon-button
            *ngIf="modulesService.hasCTReport()"
            class="primary mr-8"
            matTooltip="{{ 'GENERIC.PRINT_INVOICE' | translate }}"
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_INVOICE_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("PRINT")
            }}</mat-icon>
          </button>
          <!-- ALEX -->
          <button
            mat-icon-button
            *ngIf="modulesService.hasAlexReport()"
            class="primary mr-8"
            matTooltip="{{ 'GENERIC.PRINT_INVOICE' | translate }}"
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_INVOICE_ALEX_DATA,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.PRINT,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("PRINT")
            }}</mat-icon>
          </button>
        </div>

        <!-- INVOICE DOWNLOAD BUTTON -->
        <div
          *ngIf="
            order.type === 'INVOICE' &&
            modulesService.modulesAreSet &&
            fileService.pdfEnabled()
          "
        >
          <!-- GENERIC-->
          <button
            mat-icon-button
            *ngIf="
              !modulesService.hasLoLReport() &&
              !modulesService.hasAlexReport() &&
              !modulesService.hasCTReport()
            "
            class="primary mr-8"
            matTooltip="{{ 'GENERIC.DOWNLOAD_INVOICE' | translate }}"
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_INVOICE,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("PDF")
            }}</mat-icon>
          </button>
          <!-- LOL -->
          <button
            mat-icon-button
            *ngIf="modulesService.hasLoLReport()"
            class="primary mr-8"
            matTooltip="{{ 'GENERIC.DOWNLOAD_INVOICE' | translate }}"
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_INVOICE_LOL,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("PDF")
            }}</mat-icon>
          </button>
          <!-- CT -->
          <button
            mat-icon-button
            *ngIf="modulesService.hasCTReport()"
            class="primary mr-8"
            matTooltip="{{ 'GENERIC.DOWNLOAD_INVOICE' | translate }}"
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_INVOICE_CT,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                {
                  uuid_order: this.order.uuid,
                }
              )
            "
          >
            <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("PDF")
            }}</mat-icon>
          </button>
          <!-- ALEX -->
          <button
            mat-icon-button
            *ngIf="modulesService.hasAlexReport()"
            class="primary mr-8"
            matTooltip="{{ 'GENERIC.DOWNLOAD_INVOICE' | translate }}"
            (click)="
              menuReportsService.buildAndRunVo(
                ReportsEnum.REPORT_CLIENT_INVOICE_ALEX_DATA,
                DistributionModeEnum.FILE,
                ExportFormatEnum.PDF,
                ExportToEnum.DOWNLOAD,
                LocaleEnum.FR_CA,
                PaperEnum.LETTER,
                { uuid_order: this.order.uuid }
              )
            "
          >
            <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("PDF")
            }}</mat-icon>
          </button>
        </div>

        <!-- INVOICE SEND BUTTON -->
        <div *ngIf="order.type === 'INVOICE' && modulesService.modulesAreSet">
          <!-- GENERIC-->
          <button
            mat-icon-button
            *ngIf="
              !modulesService.hasLoLReport() &&
              !modulesService.hasAlexReport() &&
              !modulesService.hasCTReport()
            "
            class="primary mr-8"
            matTooltip="{{ 'GENERIC.SEND_INVOICE' | translate }}"
            (click)="onSendInvoiceReport()"
          >
            <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("MAIL")
            }}</mat-icon>
          </button>
          <!-- LOL -->
          <button
            mat-icon-button
            *ngIf="modulesService.hasLoLReport()"
            class="primary mr-8"
            matTooltip="{{ 'GENERIC.SEND_INVOICE' | translate }}"
            (click)="onSendInvoiceReport()"
          >
            <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("MAIL")
            }}</mat-icon>
          </button>
          <!-- CT -->
          <button
            mat-icon-button
            *ngIf="modulesService.hasCTReport()"
            class="primary mr-8"
            matTooltip="{{ 'GENERIC.SEND_INVOICE' | translate }}"
            (click)="onSendInvoiceReport()"
          >
            <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("MAIL")
            }}</mat-icon>
          </button>
          <!-- ALEX -->
          <button
            mat-icon-button
            *ngIf="modulesService.hasAlexReport()"
            class="primary mr-8"
            matTooltip="{{ 'GENERIC.SEND_INVOICE' | translate }}"
            (click)="onSendInvoiceReport()"
          >
            <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
              getIcon("MAIL")
            }}</mat-icon>
          </button>
        </div>
        <button
          mat-icon-button
          *ngIf="
            modulesService.modulesAreSet &&
            modulesService.hasUQAMReport() &&
            modulesService.hasGlobalPayments() &&
            event &&
            !event.ubr
          "
          class="primary mr-8"
          matTooltip="{{ 'ORDERS.INVOICE_GLOBAL_PAYMENTS' | translate }}"
          (click)="onSendInvoiceGlobalPaymentsReport()"
        >
          <mat-icon color="" class="s-28 subheader-menu-icon p-12 icon-mat">{{
            getIcon("MAIL_GP")
          }}</mat-icon>
        </button>

        <!--
                  <button mat-button
                      *ngIf="order.type === 'INVOICE' && (modulesService.modulesAreSet && !modulesService.hasLoLReport() && !modulesService.hasCTReport() && !modulesService.hasAlexReport())"
                      [matMenuTriggerFor]="invoiceReportMenu">
                      <mat-icon class="s-28 mr-4">{{ getIcon("REPORT") }}</mat-icon>
                      <span>{{ 'GENERIC.REPORT' | translate }}</span>
                      <mat-icon class="">more_vert</mat-icon>
                  </button>
              -->
        <!--
                  <button mat-button
                      *ngIf="order.type === 'INVOICE' && (modulesService.modulesAreSet && modulesService.hasCTReport())"
                      [matMenuTriggerFor]="invoiceReportMenuCT">
                      <mat-icon class="s-28 mr-4">{{ getIcon("REPORT") }}</mat-icon>
                      <span>{{ 'GENERIC.REPORT' | translate }}</span>
                      <mat-icon class="">more_vert</mat-icon>
                  </button> -->
        <!--
                  <button mat-button
                      *ngIf="order.type === 'INVOICE' && (modulesService.modulesAreSet && modulesService.hasLoLReport())"
                      [matMenuTriggerFor]="invoiceReportMenuLoL">
                      <mat-icon class="s-28 mr-4">{{ getIcon("REPORT") }}</mat-icon>
                      <span>{{ 'GENERIC.REPORT' | translate }}</span>
                      <mat-icon class="">more_vert</mat-icon>
                  </button> -->
        <!--
                  <button mat-button
                      *ngIf="order.type === 'INVOICE' && (modulesService.modulesAreSet && modulesService.hasAlexReport())"
                      [matMenuTriggerFor]="invoiceReportMenuAlex">
                      <mat-icon class="s-28 mr-4">{{ getIcon("REPORT") }}</mat-icon>
                      <span>{{ 'GENERIC.REPORT' | translate }}</span>
                      <mat-icon class="">more_vert</mat-icon>
                  </button>-->

        <!-- invoiceReportMenuCT 
                  <mat-menu #invoiceReportMenuCT="matMenu">
                      <button mat-menu-item (click)="menuReportsService.buildAndRunVo(
                                                  ReportsEnum.REPORT_CLIENT_INVOICE_CT,
                                                  DistributionModeEnum.FILE,
                                                  ExportFormatEnum.PDF,
                                                  ExportToEnum.PRINT,
                                                  LocaleEnum.FR_CA,
                                                  PaperEnum.LETTER,
                                                  { uuid_order: this.order.uuid})">
                          {{ "GENERIC.PRINT" | translate }}
                      </button>
                      <button mat-menu-item (click)="menuReportsService.buildAndRunVo(
                                                  ReportsEnum.REPORT_CLIENT_INVOICE_CT,
                                                  DistributionModeEnum.FILE,
                                                  ExportFormatEnum.PDF,
                                                  ExportToEnum.DOWNLOAD,
                                                  LocaleEnum.FR_CA,
                                                  PaperEnum.LETTER,
                                                  { uuid_order: this.order.uuid})">
                          {{ "GENERIC.DOWNLOAD" | translate }}
                      </button>
  
                      <button mat-menu-item (click)="onSendInvoiceReport()">{{ "GENERIC.SEND" | translate }}</button>
  
                  </mat-menu>-->

        <!-- invoiceReportMenuLoL
                  <mat-menu #invoiceReportMenuLoL="matMenu">
                      <button mat-menu-item (click)="menuReportsService.buildAndRunVo(
                                                                  ReportsEnum.REPORT_CLIENT_INVOICE_LOL,
                                                                  DistributionModeEnum.FILE,
                                                                  ExportFormatEnum.PDF,
                                                                  ExportToEnum.PRINT,
                                                                  LocaleEnum.FR_CA,
                                                                  PaperEnum.LETTER,
                                                                  { uuid_order: this.order.uuid})">
                          {{ "GENERIC.PRINT" | translate }}
                      </button>
                      <button mat-menu-item (click)="menuReportsService.buildAndRunVo(
                                                                  ReportsEnum.REPORT_CLIENT_INVOICE_LOL,
                                                                  DistributionModeEnum.FILE,
                                                                  ExportFormatEnum.PDF,
                                                                  ExportToEnum.DOWNLOAD,
                                                                  LocaleEnum.FR_CA,
                                                                  PaperEnum.LETTER,
                                                                  { uuid_order: this.order.uuid})">
                          {{ "GENERIC.DOWNLOAD" | translate }}
                      </button>
  
                      <button mat-menu-item (click)="onSendInvoiceReport()">{{ "GENERIC.SEND" | translate }}</button>
  
                  </mat-menu> -->

        <!--
                  <mat-menu #invoiceReportMenuAlex="matMenu">
                      <button mat-menu-item (click)="menuReportsService.buildAndRunVo(
                                                              ReportsEnum.REPORT_CLIENT_INVOICE_ALEX_DATA,
                                                              DistributionModeEnum.FILE,
                                                              ExportFormatEnum.PDF,
                                                              ExportToEnum.PRINT,
                                                              LocaleEnum.FR_CA,
                                                              PaperEnum.LETTER,
                                                              { uuid_order: this.order.uuid})">
                          {{ "GENERIC.PRINT" | translate }}
                      </button>
                      <button mat-menu-item (click)="menuReportsService.buildAndRunVo(
                                                              ReportsEnum.REPORT_CLIENT_INVOICE,
                                                              DistributionModeEnum.FILE,
                                                              ExportFormatEnum.PDF,
                                                              ExportToEnum.DOWNLOAD,
                                                              LocaleEnum.FR_CA,
                                                              PaperEnum.LETTER,
                                                              { uuid_order: this.order.uuid})">
                          {{ "GENERIC.DOWNLOAD" | translate }}
                      </button>
  
                      <button mat-menu-item (click)="onSendInvoiceReport()">{{ "GENERIC.SEND" | translate }}</button>
                  </mat-menu>
                  -->
        <!--
                  <mat-menu #invoiceReportMenu="matMenu">
                      <button mat-menu-item *ngIf="fileService.printEnabled()" (click)="onPrintInvoiceReport()">{{
                          "GENERIC.PRINT" | translate }}</button>
                      <button mat-menu-item (click)="onDowloadInvoiceReport()">{{ "GENERIC.DOWNLOAD" | translate
                          }}</button>
                      <button mat-menu-item *ngIf="modulesService.modulesAreSet && !modulesService.hasGlobalPayments()"
                          (click)="onSendInvoiceReport()">{{ "GENERIC.SEND" | translate }}</button>
                      <button mat-menu-item *ngIf="modulesService.modulesAreSet && modulesService.hasGlobalPayments()"
                          [matMenuTriggerFor]="menuSendInvoice">{{ "GENERIC.SEND" | translate }}</button>
                  </mat-menu>
                  -->

        <!--<mat-menu #menuSendInvoice="matMenu">
                      <button *ngIf="modulesService.modulesAreSet && modulesService.hasUQAMReport() && event && event.ubr"
                          mat-menu-item (click)="onSendInvoiceReport()">{{ "ORDERS.INVOICE_UBR" | translate }}</button>
                      <button *ngIf="modulesService.modulesAreSet && !modulesService.hasUQAMReport()" mat-menu-item
                          (click)="onSendInvoiceReport()">{{ "ORDERS.INVOICE" | translate }}</button>
                      <button *ngIf="event && !event.ubr" mat-menu-item (click)="onSendInvoiceGlobalPaymentsReport()">{{
                          "ORDERS.INVOICE_GLOBAL_PAYMENTS" | translate }}</button>
                  </mat-menu>-->

        <button
          mat-icon-button
          *ngIf="
            order && order.type === 'PROPOSAL' && moduleService.modulesAreSet
          "
          (click)="onSendPropsalReport()"
          matTooltip="{{ 'GENERIC.SEND_PROPOSAL' | translate }}"
          class=""
        >
          <mat-icon class="s-24 mr-4 p-12 icon-mat">{{
            getIcon("MAIL")
          }}</mat-icon>
        </button>

        <div fxLayout="row" fxLayoutAlign="start center">
          <app-report-button
            *ngIf="
              order &&
              order.type === 'ORDER' &&
              modulesService.modulesAreSet &&
              modulesService.hasJLReport()
            "
            [vo]="
              reportFactoryService.getVO(
                'REPORT2_CLIENT_ORDER_JUL',
                DistributionModeEnum.RAW_HTML,
                ExportFormatEnum.HTML,
                ExportToEnum.PREVIEW,
                { uuid_order: this.order.uuid }
              )
            "
          >
          </app-report-button>

          <app-report-button
            *ngIf="
              order &&
              order.type === 'ORDER' &&
              modulesService.modulesAreSet &&
              modulesService.hasUQAMReport()
            "
            [vo]="
              reportFactoryService.getVO(
                'REPORT2_CLIENT_ORDER_UQAM',
                DistributionModeEnum.RAW_HTML,
                ExportFormatEnum.HTML,
                ExportToEnum.PREVIEW,
                { uuid_order: this.order.uuid }
              )
            "
          >
          </app-report-button>

          <app-report-button
            *ngIf="
              order &&
              order.type === 'ORDER' &&
              moduleService.modulesAreSet &&
              !modulesService.hasLoLReport() &&
              !modulesService.hasUQAMReport() &&
              !moduleService.hasCTReport() &&
              !modulesService.hasJLReport()
            "
            [vo]="
              reportFactoryService.getVO(
                'REPORT_CLIENT_ORDER',
                DistributionModeEnum.RAW_HTML,
                ExportFormatEnum.HTML,
                ExportToEnum.PREVIEW,
                { uuid_order: this.order.uuid }
              )
            "
          >
          </app-report-button>

          <app-report-button
            *ngIf="
              order &&
              order.type === 'ORDER' &&
              moduleService.modulesAreSet &&
              moduleService.hasLoLReport()
            "
            [vo]="
              reportFactoryService.getVO(
                'REPORT_CLIENT_CONTRACT_LOL',
                DistributionModeEnum.RAW_HTML,
                ExportFormatEnum.HTML,
                ExportToEnum.PREVIEW,
                { uuid_order: this.order.uuid }
              )
            "
          >
          </app-report-button>
          <app-report-button
            *ngIf="
              order &&
              order.type === 'ORDER' &&
              moduleService.modulesAreSet &&
              moduleService.hasCTReport()
            "
            [vo]="
              reportFactoryService.getVO(
                'REPORT_CLIENT_ORDER_CT',
                DistributionModeEnum.RAW_HTML,
                ExportFormatEnum.HTML,
                ExportToEnum.PREVIEW,
                { uuid_order: this.order.uuid }
              )
            "
          >
          </app-report-button>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          *ngIf="order && order.type === 'PROPOSAL'"
        >
          <app-report-button
            *ngIf="
              moduleService.modulesAreSet &&
              !moduleService.moduleExist('MODULE_EVENT_PROGRAMS') &&
              !moduleService.hasLoLReport()
            "
            [vo]="
              reportFactoryService.getVO(
                'REPORT_CLIENT_PROPOSAL',
                DistributionModeEnum.RAW_HTML,
                ExportFormatEnum.HTML,
                ExportToEnum.PREVIEW,
                { uuid_order: this.order.uuid }
              )
            "
          >
          </app-report-button>
          <!--           <app-report-button
              *ngIf="moduleService.modulesAreSet && !moduleService.moduleExist('MODULE_EVENT_PROGRAMS') && moduleService.hasLoLReport()"
              [vo]="reportFactoryService.getVO('REPORT_CLIENT_SERVICE_OFFER_LOL', DistributionModeEnum.RAW_HTML, ExportFormatEnum.HTML, ExportToEnum.PREVIEW, { uuid_order: this.order.uuid })"
            > -->
          <!-- </app-report-button> -->
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
          <app-report-button
            *ngIf="
              order &&
              order.type === 'INVOICE' &&
              moduleService.modulesAreSet &&
              !moduleService.hasCTReport() &&
              !moduleService.hasLoLReport()
            "
            [vo]="
              reportFactoryService.getVO(
                'REPORT_CLIENT_INVOICE',
                DistributionModeEnum.RAW_HTML,
                ExportFormatEnum.HTML,
                ExportToEnum.PREVIEW,
                { uuid_order: this.order.uuid }
              )
            "
          >
          </app-report-button>
          <app-report-button
            *ngIf="
              order &&
              order.type === 'INVOICE' &&
              moduleService.modulesAreSet &&
              moduleService.hasCTReport()
            "
            [vo]="
              reportFactoryService.getVO(
                'REPORT_CLIENT_INVOICE_CT',
                DistributionModeEnum.RAW_HTML,
                ExportFormatEnum.HTML,
                ExportToEnum.PREVIEW,
                { uuid_order: this.order.uuid }
              )
            "
            >!LOL!
          </app-report-button>
          <app-report-button
            *ngIf="
              order &&
              order.type === 'INVOICE' &&
              moduleService.modulesAreSet &&
              moduleService.hasLoLReport()
            "
            [vo]="
              reportFactoryService.getVO(
                'REPORT_CLIENT_INVOICE_LOL',
                DistributionModeEnum.RAW_HTML,
                ExportFormatEnum.HTML,
                ExportToEnum.PREVIEW,
                { uuid_order: this.order.uuid }
              )
            "
            >OLA
          </app-report-button>
        </div>
      </div>
    </div>
    <!-- / SUB HEADER - RIGHT -->
  </div>
  <!-- / SUB HEADER -->

  <div *ngIf="order">
    <div
      *ngIf="
        (order && !editable && order.event.status === 'EVENT_CANCEL') ||
        order.event.status === 'EVENT_ABORT'
      "
      fxLayout="row"
      fxLayoutAlign="center center"
      style="height: 38px; min-height: 38px; max-height: 38px"
      class="gt2-gray mat-elevation-z1 py-8"
    >
      <div class="text-white font-weight-600">
        <b>{{ "GENERIC.READ_ONLY_MODE" | translate }}</b> :
        {{ order.event.status_label }}
      </div>
    </div>

    <div
      *ngIf="
        order &&
        !editable &&
        order.event.status !== 'EVENT_CANCEL' &&
        order.event.status !== 'EVENT_ABORT'
      "
      fxLayout="row"
      fxLayoutAlign="center center"
      style="height: 38px; min-height: 38px; max-height: 38px"
      class="gt2-gray mat-elevation-z1 py-8"
    >
      <div class="text-white font-weight-600">
        <b>{{ "GENERIC.READ_ONLY_MODE" | translate }}</b> :
        {{ order.status_label }}
      </div>
    </div>
  </div>

  <!--HISTORY SIDEBAR-->
  <div *ngIf="!isSmallScreen" class="container-history-panel">
    <fuse-sidebar
      name="orderHistoryPanel"
      position="right"
      class="section-history-panel"
    >
      <app-section-history-panel
        panelName="orderHistoryPanel"
        section="orders"
        sectionUUID="{{ orderUUDID }}"
      >
      </app-section-history-panel>
    </fuse-sidebar>
  </div>

  <div class="content">
    <!--HISTORY SIDEBAR BUTTON-->
    <div *ngIf="!isSmallScreen">
      <button
        mat-icon-button
        class="warn mat-elevation-z2 historic-button"
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        (click)="toggleHistoricSidebarOpen('orderHistoryPanel')"
      >
        <mat-icon class="icon-mat">history</mat-icon>
      </button>
    </div>

    <!-- CENTER -->
    <div class="center p-24 pb-56 pr-sm-72">
      <div fxLayou="column">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
          <div
            *ngIf="order"
            class="mb-16"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
              <button
                mat-raised-button
                *ngIf="
                  order &&
                  editable &&
                  order.type === 'ORDER' &&
                  order.status !== 'ORDER_CANCEL' &&
                  order.status !== 'ORDER_COMPLETE'
                "
                (click)="onCancelOrder()"
                [disabled]="loading"
                class="mr-12 accent"
              >
                <mat-icon class="s-20 icon-mat">{{
                  getIcon("CANCEL")
                }}</mat-icon>
                <span class="ml-8 icon-mat">{{
                  "ORDERS.CANCEL_ORDER" | translate
                }}</span>
              </button>
            </div>

            <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
              <button
                mat-raised-button
                *ngIf="
                  order &&
                  editable &&
                  order.type === 'ORDER' &&
                  order.status !== 'ORDER_CONFIRM' &&
                  order.status !== 'ORDER_CANCEL' &&
                  order.status !== 'ORDER_COMPLETE'
                "
                (click)="onConfirmOrder()"
                [disabled]="loading"
                class="mr-12 accent"
              >
                <mat-icon class="s-20 icon-mat">{{
                  getIcon("CHECK")
                }}</mat-icon>
                <span class="ml-8 icon-mat">{{
                  "ORDERS.CONFIRM_ORDER" | translate
                }}</span>
              </button>
            </div>

            <!-- Creer facture -->
            <div *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES">
              <button
                mat-raised-button
                *ngIf="
                  order &&
                  editable &&
                  order.type === 'ORDER' &&
                  order.status === 'ORDER_CONFIRM' &&
                  moduleService.hasCTReport()
                "
                (click)="openDrinksValidationAlert()"
                [disabled]="loading"
                class="accent"
              >
                <mat-icon
                  fontSet="fa"
                  fontIcon="fa-calendar-check"
                  class="fa-lg icon-mat"
                ></mat-icon>
                <span class="ml-8 icon-mat">{{
                  "ORDERS.COMPLETE_ORDER" | translate
                }}</span>
              </button>
              <button
                mat-raised-button
                *ngIf="
                  order &&
                  editable &&
                  order.type === 'ORDER' &&
                  order.status === 'ORDER_CONFIRM' &&
                  !moduleService.hasCTReport()
                "
                (click)="onCompleteOrder()"
                [disabled]="loading"
                class="accent"
              >
                <mat-icon
                  fontSet="fa"
                  fontIcon="fa-calendar-check"
                  class="fa-lg icon-mat"
                ></mat-icon>
                <span class="ml-8 icon-mat">{{
                  "ORDERS.COMPLETE_ORDER" | translate
                }}</span>
              </button>
            </div>

            <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
              <button
                *ngIf="
                  order &&
                  editable &&
                  order.type === 'PROPOSAL' &&
                  order.status !== 'PROPOSAL_ACCEPT' &&
                  order.status !== 'PROPOSAL_REFUSE'
                "
                mat-raised-button
                (click)="onRefuseProposal()"
                [disabled]="loading"
                class="mr-12 accent"
              >
                <mat-icon class="s-20 icon-mat">{{
                  getIcon("CANCEL")
                }}</mat-icon>
                <span class="ml-8 icon-mat">{{
                  "ORDERS.REFUSE_PROPOSAL" | translate
                }}</span>
              </button>
            </div>

            <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
              <button
                mat-raised-button
                *ngIf="
                  order &&
                  editable &&
                  order.type === 'PROPOSAL' &&
                  order.status !== 'PROPOSAL_ACCEPT'
                "
                (click)="onAcceptProposal()"
                [disabled]="loading"
                class="accent"
              >
                <mat-icon class="s-20 icon-mat">{{
                  getIcon("CHECK")
                }}</mat-icon>
                <span class="ml-8 icon-mat">{{
                  "ORDERS.ACCEPT_PROPOSAL" | translate
                }}</span>
              </button>
            </div>

            <div *ngxPermissionsOnly="rolesService.ADMIN_ROLES">
              <button
                *ngIf="
                  order &&
                  editable &&
                  order.type === 'INVOICE' &&
                  order.status !== 'INVOICE_PAID' &&
                  order.status !== 'INVOICE_CANCEL'
                "
                mat-raised-button
                (click)="onCancelInvoice()"
                [disabled]="loading"
                matTooltip="Annuler la facture"
                class="accent mr-12"
              >
                <mat-icon class="s-20 icon-mat">{{
                  getIcon("CANCEL")
                }}</mat-icon>
                <span class="ml-8 icon-mat">{{
                  "ORDERS.CANCEL_INVOICE" | translate
                }}</span>
              </button>
              <button
                *ngIf="
                  order &&
                  editable &&
                  order.type === 'INVOICE' &&
                  order.status !== 'INVOICE_PAID' &&
                  order.status !== 'INVOICE_SEND' &&
                  order.status !== 'INVOICE_CANCEL'
                "
                mat-raised-button
                (click)="onConfirmSendInvoice()"
                [disabled]="loading"
                matTooltip="Confirmer que la facture a été envoyée"
                class="accent mr-12"
              >
                <mat-icon class="s-20 icon-mat">{{ getIcon("MAIL") }}</mat-icon>
                <span class="ml-8 icon-mat">Facture envoyée</span>
              </button>
            </div>

            <div *ngxPermissionsOnly="rolesService.ADMIN_ROLES">
              <button
                *ngIf="
                  event &&
                  event.is_uqam &&
                  event.ubr &&
                  order &&
                  editable &&
                  order.type === 'INVOICE' &&
                  order.status !== 'INVOICE_PAID' &&
                  order.status !== 'INVOICE_CANCEL'
                "
                mat-raised-button
                (click)="onCloseInvoice()"
                [disabled]="loading"
                class="accent mr-12"
              >
                <mat-icon class="s-20 icon-mat">{{
                  getIcon("CANCEL")
                }}</mat-icon>
                <span class="ml-8 icon-mat">{{
                  "ORDERS.CLOSE_INVOICE" | translate
                }}</span>
              </button>
            </div>

            <div *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES">
              <button
                *ngIf="
                  order &&
                  editable &&
                  order.type === 'INVOICE' &&
                  order.status !== 'INVOICE_PAID' &&
                  order.status !== 'INVOICE_CANCEL' &&
                  order.event.balance <= 0
                "
                mat-raised-button
                (click)="onCompleteInvoicePaid()"
                [disabled]="loading"
                class="accent mr-12"
              >
                <mat-icon class="s-20 icon-mat">{{
                  getIcon("CHECK")
                }}</mat-icon>
                <span class="ml-8 icon-mat">{{
                  "ORDERS.COMPLETE_INVOICE_PAID" | translate
                }}</span>
              </button>
            </div>

            <!-- Button changelogs -->
            <div *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES">
              <button
                mat-raised-button
                color="accent"
                [disabled]="loading"
                class="accent ml-12"
                *ngIf="
                  order &&
                  editable &&
                  order.type === 'ORDER' &&
                  order.status === 'ORDER_CONFIRM' &&
                  moduleService.hasChangelogsModule()
                "
                (click)="openChangelogDialog()"
              >
                {{ "ORDERS.CHANGELOG" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- CONTENT -->
      <div class="order-content">
        <div
          *ngIf="order"
          class="profile-box info-box general"
          style="min-width: 1023px"
          fxLayout="column"
        >
          <div class="gt2-order-theme">
            <header [ngClass]="orderTypeHeaderColor || 'accent'" class="">
              <div fxLayout="row" fxLayoutAlign="space-between">
                <div class="title p-16 icon-mat">
                  {{ "GENERIC.INFORMATION" | translate }}
                </div>
                <div
                  *ngIf="event && event.code_ubr"
                  class="title p-16 icon-mat"
                >
                  {{ event.code_ubr }}
                </div>
              </div>
            </header>
          </div>

          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
          >
            <mat-tab label="{{ 'GENERIC.GENERAL' | translate }}">
              <div class="content" fxLayout="column">
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">{{ "GENERIC.NAME" | translate }}</div>
                    <div class="info text-truncate">{{ order.name }}</div>
                    <div *ngIf="!order.name" class="info empty-value"></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field class="" fxFlex="100%">
                          <mat-label>{{
                            "GENERIC.NAME" | translate
                          }}</mat-label>
                          <input
                            matInput
                            placeholder="{{ 'GENERIC.NAME' | translate }}"
                            formControlName="name"
                            type="text"
                            [(ngModel)]="order.name"
                            autocomplete="off"
                            required
                          />
                          <mat-error *ngIf="form.controls['name'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">
                      {{ "ORDERS.GUESS_COUNT" | translate }}
                    </div>
                    <div class="info text-truncate">
                      {{ order.guest_count }}
                    </div>
                    <div
                      *ngIf="order.guest_count === null"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field class="" fxFlex="100%">
                          <mat-label>{{
                            "ORDERS.GUESS_COUNT" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="guest_count"
                            type="text"
                            [(ngModel)]="order.guest_count"
                            autocomplete="off"
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            required
                          />
                          <mat-error
                            *ngIf="form.controls['guest_count'].invalid"
                          >
                            {{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">
                      {{ "ORDERS.RESPONSIBLE_EMPLOYEE" | translate }}
                    </div>
                    <div
                      *ngIf="
                        order.responsible_employee &&
                        order.responsible_employee.name
                      "
                      class="info text-truncate"
                    >
                      {{ order.responsible_employee.name }}
                    </div>
                    <div
                      *ngIf="
                        !order.responsible_employee ||
                        !order.responsible_employee.name
                      "
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <app-gt2-select
                          [service]="conseillersService"
                          [placeHolder]="
                            'ORDERS.SELECT_RESPONSIBLE_EMPLOYEE' | translate
                          "
                          [label]="'Personnal title'"
                          [selectedModel]="order.responsible_employee"
                          (changeEvent)="onResponsibleEmployeeSelected($event)"
                          [noResultsLabel]="
                            translate.instant('GENERIC.NO_RESULTS')
                          "
                          [nullableUseNone]="false"
                          class=""
                        ></app-gt2-select>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div *ngIf="order && order.type !== 'INVOICE'">
                      <div class="title">
                        {{ "ORDERS.WRAPPING" | translate }}
                      </div>
                      <div *ngIf="order.wrapping" class="info">
                        {{ order.wrapping.name }}
                      </div>
                      <div
                        *ngIf="!order.wrapping"
                        class="info empty-value"
                      ></div>
                    </div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div *ngIf="order && order.type !== 'INVOICE'" class="mr-4">
                      <app-gt2-select
                        #wrapping
                        [service]="wrappingService"
                        [nullableUseNone]="false"
                        [placeHolder]="'ORDERS.WRAPPING_SELECT' | translate"
                        [label]="'ORDERS.WRAPPING' | translate"
                        [selectedModel]="order.wrapping"
                        (changeEvent)="onWrappingSelected($event)"
                        [noResultsLabel]="
                          translate.instant('GENERIC.NO_RESULTS')
                        "
                        fxFlex="100%"
                        class=""
                      ></app-gt2-select>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="
                    moduleService.modulesAreSet &&
                    moduleService.moduleExist('MODULE_ROOM_MNGT')
                  "
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div
                    *ngIf="
                      !editMode &&
                      event &&
                      event.room_reservation &&
                      event.room_reservation.status
                    "
                    class="info-line"
                    fxFlex="50%"
                  >
                    <div class="title">
                      {{ "GENERIC.ROOM_STATUS" | translate }}
                    </div>

                    <div
                      *ngIf="
                        moduleService.modulesAreSet &&
                        moduleService.hasRPReport()
                      "
                    >
                      <div
                        *ngIf="
                          event.informations &&
                          event.informations.room_reservation_status
                        "
                        class="info text-truncate"
                      >
                        {{
                          "GENERIC.RP_" +
                            event.informations.room_reservation_status
                            | translate
                        }}
                      </div>
                    </div>
                    <div
                      *ngIf="
                        !moduleService.modulesAreSet ||
                        !moduleService.hasRPReport()
                      "
                    >
                      <div
                        *ngIf="
                          event.informations &&
                          event.informations.room_reservation_status
                        "
                        class="info text-truncate"
                      >
                        {{
                          "GENERIC." +
                            event.informations.room_reservation_status
                            | translate
                        }}
                      </div>
                    </div>

                    <div
                      *ngIf="
                        !event.informations ||
                        !event.informations.room_reservation_status
                      "
                      class="info empty-value"
                    ></div>
                  </div>

                  <div
                    *ngIf="
                      editMode &&
                      event &&
                      event.room_reservation &&
                      event.room_reservation.status
                    "
                    class="info-line"
                    fxFlex="50%"
                  >
                    <mat-form-field class="" style="width: 100%">
                      <mat-select
                        *ngIf="
                          moduleService.modulesAreSet &&
                          moduleService.hasRPReport()
                        "
                        placeholder="{{ 'GENERIC.ROOM_STATUS' | translate }}"
                        (selectionChange)="onRoomStatusChange($event)"
                        [(ngModel)]="event.informations.room_reservation_status"
                      >
                        <mat-option
                          *ngFor="
                            let reservation of event.room_reservation_list
                          "
                          [value]="reservation"
                        >
                          <div>
                            {{ "GENERIC.RP_" + reservation | translate }}
                          </div>
                        </mat-option>
                      </mat-select>

                      <mat-select
                        *ngIf="
                          !moduleService.modulesAreSet ||
                          !moduleService.hasRPReport()
                        "
                        placeholder="{{ 'GENERIC.ROOM_STATUS' | translate }}"
                        (selectionChange)="onRoomStatusChange($event)"
                        [(ngModel)]="event.informations.room_reservation_status"
                      >
                        <mat-option
                          *ngFor="
                            let reservation of event.room_reservation_list
                          "
                          [value]="reservation"
                        >
                          {{ "GENERIC." + reservation | translate }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="info-line" fxFlex="50%">&nbsp;</div>
                </div>

                <div
                  *ngIf="orderType === 'INVOICE'"
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">
                      {{ "ORDERS.NON_TAXABLE_TIP" | translate }}
                    </div>
                    <div class="info text-truncate">
                      {{ order.non_taxable_tip }}
                    </div>
                    <div
                      *ngIf="order.non_taxable_tip === null"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field class="" fxFlex="100%">
                          <mat-label>{{
                            "ORDERS.NON_TAXABLE_TIP" | translate
                          }}</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="non_taxable_tip"
                            autocomplete="off"
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            [(ngModel)]="order.non_taxable_tip"
                            required
                          />
                          <mat-error
                            *ngIf="form.controls['non_taxable_tip'].invalid"
                            >{{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
                            {{
                              "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL"
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">
                      {{ "ORDERS.INVOICED_AT" | translate }}
                    </div>
                    <div class="info text-truncate">
                      {{ order.invoiced_at_label }}
                    </div>
                    <div
                      *ngIf="order.invoiced_at === null"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field class="" fxFlex="100%">
                          <mat-label>{{
                            "ORDERS.INVOICED_AT" | translate
                          }}</mat-label>
                          <input
                            matInput
                            [matDatepicker]="invoicingAt"
                            [ngModel]="order.invoiced_at"
                            min="1900-01-01"
                            max="2099-01-01"
                            (ngModelChange)="onInvoicedDateChange($event)"
                            autocomplete="nope"
                            formControlName="invoiced_at"
                            required
                          />
                          <mat-datepicker-toggle matSuffix [for]="invoicingAt">
                          </mat-datepicker-toggle>
                          <mat-datepicker #invoicingAt></mat-datepicker>
                          <mat-error
                            *ngIf="
                              form.controls['invoiced_at'].invalid ||
                              order.invoiced_at === null
                            "
                            >{{
                              "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="orderType === 'PROPOSAL'"
                  fxLayout="row"
                  fxLayout.xs="colomn"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">
                      {{ "ORDERS.PROPOSAL_EXPIRED_AT" | translate }}
                    </div>
                    <div
                      *ngIf="order.proposal_expired_at !== null"
                      class="info text-truncate"
                    >
                      {{ order.proposal_expired_at }}
                    </div>
                    <div
                      *ngIf="order.proposal_expired_at === null"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field class="" fxFlex="100%">
                          <mat-label>{{
                            "ORDERS.PROPOSAL_EXPIRED_AT" | translate
                          }}</mat-label>
                          <input
                            matInput
                            [matDatepicker]="expiredAt"
                            [(ngModel)]="order.proposal_expired_at"
                            min="1900-01-01"
                            autocomplete="off"
                            formControlName="proposal_expired_at"
                            required
                          />
                          <mat-datepicker-toggle matSuffix [for]="expiredAt">
                          </mat-datepicker-toggle>
                          <mat-datepicker #expiredAt></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="{{ 'GENERIC.VENUES' | translate }}">
              <div *ngIf="event" class="content" fxLayout="column" fxFlex="100">
                <!-- IS A PICK UP -->
                <div
                  *ngIf="
                    event.delivery_type && !event.delivery_type.need_delivery
                  "
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  class=""
                >
                  <div class="font-size-20">
                    {{ "ORDERS.DOES_NOT_NEED_DELIVERY" | translate }}
                  </div>
                </div>

                <div
                  *ngIf="
                    !event.spot.enterprise &&
                    !event.spot.private_client &&
                    !event.spot.place &&
                    !event.spot.room
                  "
                  fxFlex="100"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <div class="font-size-20 my-16">
                    {{ "ORDERS.NO_VENUES" | translate }}
                  </div>
                </div>

                <div
                  *ngIf="
                    event.delivery_type &&
                    event.delivery_type.need_delivery &&
                    (event.spot.enterprise ||
                      event.spot.private_client ||
                      event.spot.place ||
                      event.spot.room)
                  "
                  fxLayout="column"
                  class="mb-16"
                >
                  <!-- IS ENTERPRISE -->
                  <div
                    *ngIf="event.spot.enterprise"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    style="width: 100%"
                  >
                    <div fxLayout="column" fxLayoutAlign="start start">
                      <div
                        class="spot-name"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                      >
                        {{ "GENERIC.ENTERPRISE" | translategt }}:
                        {{ event.spot.enterprise.name }}
                        <button
                          mat-icon-button
                          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                          (click)="onOpenItemInNewTab(event.spot.enterprise)"
                          style="width: 30px; height: 30px"
                          class="mat-icon-button"
                        >
                          <mat-icon class="s-16 icon-mat">{{
                            getIcon("NEW_TAB")
                          }}</mat-icon>
                        </button>
                      </div>
                      <div
                        *ngIf="event.spot.room"
                        class="spot-primary"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                      >
                        {{ "GENERIC.ROOM" | translate }}:
                        {{ event.spot.room.name }}
                        <button
                          mat-icon-button
                          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                          (click)="onOpenItemInNewTab(event.spot.room)"
                          style="width: 30px; height: 30px"
                          class="mat-icon-button"
                        >
                          <mat-icon class="s-16 icon-mat">{{
                            getIcon("NEW_TAB")
                          }}</mat-icon>
                        </button>
                      </div>
                      <div *ngIf="event.spot.room" class="spot-primary">
                        {{ "ORDERS.CAPACITY" | translate }}:
                        {{ event.spot.room.max_place }}
                      </div>
                      <div
                        class="spot-address"
                        *ngIf="event.spot.enterprise.address"
                      >
                        <div class="">
                          {{ event.spot.enterprise.address.line_street }}
                        </div>
                        <div class="">
                          {{ event.spot.enterprise.address.line_city }}
                        </div>
                        <div class="">
                          {{ event.spot.enterprise.address.line_country }}
                        </div>
                        <div class="">
                          {{ event.spot.enterprise.address.line2 }}
                        </div>
                      </div>
                      <div
                        *ngIf="
                          event.spot.enterprise.address &&
                          event.spot.enterprise.address.sector
                        "
                        class="spot-primary"
                      >
                        {{ "GENERIC.SECTOR" | translate }}:
                        {{ event.spot.enterprise.address.sector.name }},
                        &nbsp;{{
                          event.spot.enterprise.address.sector.price_label
                        }}
                      </div>

                      <div
                        *ngIf="
                          event.spot.enterprise.address &&
                          event.spot.enterprise.address?.long &&
                          event.spot.enterprise.address?.lat
                        "
                      >
                        <a
                          href="{{
                            event.spot.enterprise.address | googleLinkPipe
                          }}"
                          target="_blank"
                          class="mat-caption"
                        >
                          <span class="mt-8">{{
                            "GENERIC.SEE_ON_GOOGLE_MAP" | translate
                          }}</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <!-- IS PLACE -->
                  <div
                    *ngIf="event.spot.place"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    style="width: 100%"
                  >
                    <div fxLayout="column" fxLayoutAlign="start start">
                      <div
                        class="spot-name"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                      >
                        {{ "GENERIC.VENUE" | translate }}:
                        {{ event.spot.place.name }}
                        <button
                          mat-icon-button
                          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                          (click)="onOpenObjectPage(event.spot.place)"
                          style="width: 30px; height: 30px"
                          class="mat-icon-button"
                        >
                          <mat-icon class="s-16 icon-mat">{{
                            getIcon("NEW_TAB")
                          }}</mat-icon>
                        </button>
                      </div>
                      <div
                        *ngIf="event.spot.room"
                        class="spot-primary"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                      >
                        {{ "GENERIC.ROOM" | translate }}:
                        {{ event.spot.room.name }}
                        <button
                          mat-icon-button
                          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                          (click)="onOpenObjectPage(event.spot.room)"
                          style="width: 30px; height: 30px"
                          class="mat-icon-button"
                        >
                          <mat-icon class="s-16 icon-mat">{{
                            getIcon("NEW_TAB")
                          }}</mat-icon>
                        </button>
                      </div>
                      <div *ngIf="event.spot.room" class="spot-primary">
                        {{ "ORDERS.CAPACITY" | translate }}:
                        {{ event.spot.room.max_place }}
                      </div>

                      <div *ngIf="event.spot.room" class="spot-primary">
                        {{ "ORDERS.ROYALTY" | translate }}:
                        {{ event.royalty_percent_label }}
                      </div>
                      <div
                        class="spot-address"
                        *ngIf="event.spot.place.address"
                      >
                        <div class="">
                          {{ event.spot.place.address.line_street }}
                        </div>
                        <div class="">
                          {{ event.spot.place.address.line_city }}
                        </div>
                        <div class="">
                          {{ event.spot.place.address.line_country }}
                        </div>
                        <div class="">{{ event.spot.place.address.line2 }}</div>
                      </div>
                      <div
                        *ngIf="
                          event.spot.place.address &&
                          event.spot.place.address.sector
                        "
                        class="spot-primary"
                      >
                        {{ "GENERIC.SECTOR" | translate }}:
                        {{ event.spot.place.address.sector.name }}, &nbsp;{{
                          event.spot.place.address.sector.price_label
                        }}
                      </div>

                      <div
                        *ngIf="
                          event.spot.place.address &&
                          event.spot.place.address?.long &&
                          event.spot.place.address?.lat
                        "
                      >
                        <a
                          href="{{ event.spot.place.address | googleLinkPipe }}"
                          target="_blank"
                          class="mat-caption"
                        >
                          <span class="mt-8">{{
                            "GENERIC.SEE_ON_GOOGLE_MAP" | translate
                          }}</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <!-- IS PRIVATE CLIENT -->
                  <div
                    *ngIf="event.spot.private_client"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    style="width: 100%"
                  >
                    <div fxLayout="column" fxLayoutAlign="start start">
                      <div
                        class="spot-name"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                      >
                        {{ "GENERIC.CLIENT" | translategt }}:
                        {{ event.spot.private_client.name }}
                        <button
                          mat-icon-button
                          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                          style="width: 30px; height: 30px"
                          (click)="onOpenInNewTab(event.spot.private_client)"
                          class="mat-icon-button"
                        >
                          <mat-icon class="s-16 icon-mat">{{
                            getIcon("NEW_TAB")
                          }}</mat-icon>
                        </button>
                      </div>

                      <div
                        *ngIf="
                          event.spot.private_client.address &&
                          !event.spot.event_address
                        "
                        class="spot-address"
                      >
                        <div class="">
                          {{ event.spot.private_client.address.line_street }}
                        </div>
                        <div class="">
                          {{ event.spot.private_client.address.line_city }}
                        </div>
                        <div class="">
                          {{ event.spot.private_client.address.line_country }}
                        </div>
                        <div class="">
                          {{ event.spot.private_client.address.line2 }}
                        </div>
                      </div>
                      <div
                        class="spot-address"
                        *ngIf="event.spot.event_address"
                      >
                        <div class="">
                          {{ event.spot.event_address.street_number }},
                          {{ event.spot.event_address.street }}
                        </div>
                        <div class="">
                          {{ event.spot.event_address.city }},
                          {{ event.spot.event_address.province }}
                        </div>
                        <div class="">
                          {{ event.spot.event_address.postal_code }},
                          {{ event.spot.event_address.country }}
                        </div>
                        <div class="">{{ event.spot.event_address.line2 }}</div>
                      </div>

                      <div
                        *ngIf="
                          event.spot.private_client.address &&
                          event.spot.private_client.address.sector
                        "
                        class="spot-primary"
                      >
                        {{ "GENERIC.SECTOR" | translate }}:
                        {{ event.spot.private_client.address.sector.name }},
                        &nbsp;{{
                          event.spot.private_client.address.sector.price_label
                        }}
                      </div>
                      <div
                        *ngIf="
                          event.spot.private_client.address &&
                          event.spot.private_client.address?.long &&
                          event.spot.private_client.address?.lat
                        "
                      >
                        <a
                          href="{{
                            event.spot.private_client.address | googleLinkPipe
                          }}"
                          target="_blank"
                          class="mat-caption"
                        >
                          <span class="mt-8">{{
                            "GENERIC.SEE_ON_GOOGLE_MAP" | translate
                          }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="{{ 'GENERIC.DELIVERY' | translate }}">
              <div class="" fxLayout="column" fxFlex="100">
                <div
                  fxLayout="row"
                  fxLayout="start center"
                  class="section-list-header"
                >
                  <div
                    fxFlex="25"
                    class="section-list-item-border-right pl-8 py-4"
                  >
                    {{ "GENERIC.DATE" | translate }}
                  </div>
                  <div
                    fxFlex="25"
                    class="section-list-item section-list-item-border-right py-4"
                  >
                    {{ "GENERIC.NAME" | translate }}
                  </div>
                  <div
                    fxFlex="15"
                    class="section-list-item section-list-item-border-right py-4"
                  >
                    {{ "GENERIC.CONTENT" | translate }}
                  </div>
                  <div
                    fxFlex="15"
                    class="section-list-item section-list-item-border-right py-4"
                  >
                    {{ "GENERIC.TYPE" | translate }}
                  </div>
                  <div
                    fxFlex="20"
                    class="section-list-item section-list-item-border-right py-4"
                  >
                    {{ "GENERIC.WRAPPING" | translate }}
                  </div>
                  <!-- <div fxFlex="13" class="section-list-item section-list-item-border-right py-4"></div> -->
                </div>

                <div *ngIf="event" class="section-list-body">
                  <div
                    *ngIf="
                      !event.delivery_requests ||
                      event.delivery_requests.length === 0
                    "
                    fxFlex="100"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                  >
                    <div class="font-size-20 my-16">
                      {{ "ORDERS.NO_DELIVERY_REQUEST" | translate }}
                    </div>
                  </div>

                  <div
                    *ngIf="
                      event.delivery_requests &&
                      event.delivery_requests.length > 0
                    "
                    fxFlex="100"
                  >
                    <div
                      *ngFor="let item of event.delivery_requests"
                      fxLayout="row"
                      fxLayout="start center"
                      class="child-item-no-cursor"
                    >
                      <div
                        fxFlex="25"
                        fxLayout="column"
                        fxLayout="start start"
                        class="section-list-item-border-right pl-8 py-8"
                      >
                        <div fxLayout="row">
                          <div class="font-weight-500">
                            {{ "GENERIC.START" | translate }}:
                          </div>
                          &nbsp;{{ item.delivery_from_date_label }}
                          {{ item.delivery_from_time_label }}
                        </div>
                        <div fxLayout="row">
                          <div class="font-weight-500">
                            {{ "GENERIC.END" | translate }}:
                          </div>
                          &nbsp;{{ item.delivery_to_date_label }}
                          {{ item.delivery_to_time_label }}
                        </div>
                      </div>
                      <div
                        fxFlex="25"
                        class="section-list-item section-list-item-border-right py-8"
                      >
                        <div class="">
                          <span class="">{{ item.name }}</span>
                        </div>
                      </div>
                      <div
                        fxFlex="15"
                        class="section-list-item section-list-item-border-right py-8"
                      >
                        {{ item.content.name }}
                      </div>
                      <div
                        fxFlex="15"
                        class="section-list-item section-list-item-border-right text-truncate py-8"
                      >
                        <span class="text-truncate px-8">{{
                          item.type.name
                        }}</span>
                      </div>
                      <div
                        fxFlex="20"
                        class="section-list-item section-list-item-border-right text-truncate py-8"
                      >
                        <span class="text-truncate px-8">{{
                          item.wrapping.name
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div
          *ngIf="order"
          class="profile-box info-box general"
          style="min-width: 1023px"
          fxLayout="column"
        >
          <div class="gt2-order-theme">
            <header
              [ngClass]="orderTypeHeaderColor || 'accent'"
              class=""
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div class="title p-16 icon-mat">
                {{ "GENERIC.ORDER" | translate }}
              </div>
              <button
                class="expand-button"
                mat-icon-button
                (click)="productsExpand = !productsExpand"
              >
                <mat-icon *ngIf="!productsExpand" class="icon-mat"
                  >expand_more</mat-icon
                >
                <mat-icon *ngIf="productsExpand" class="icon-mat"
                  >expand_less</mat-icon
                >
              </button>
            </header>
          </div>

          <!-- PRODUCTS -->
          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
          >
            <mat-tab label="{{ 'GENERIC.FOOD_DRINK' | translate }}">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="100"
                class="section-list-header"
              >
                <div
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxFlex
                  class="section-list-item-border-right"
                >
                  <div fxLayoutAlign="space-between center" class="p-4 pl-12">
                    <mat-checkbox
                      [ngModel]="productAllCheckboxes"
                      (ngModelChange)="onSelectedAllChange()"
                      (click)="$event.stopPropagation()"
                      tabIndex="-1"
                      class="mr-32"
                    >
                    </mat-checkbox>
                    {{ "GENERIC.EXTERNAL_NAME" | translate }}
                  </div>

                  <div class="product-group-action-menu">
                    <span
                      *ngIf="selectedProducts.length > 0"
                      class="selected-contacts-count"
                    >
                      <span class="mr-4">{{ selectedProducts.length }}</span>
                      <span
                        >{{ "ORDERS.GROUP_ACTION_SELECTED" | translate
                        }}<span *ngIf="selectedProducts.length > 1">{{
                          "ORDERS.GROUP_ACTION_SELECTED_POSTFIX" | translate
                        }}</span>
                      </span>
                    </span>
                    <button
                      mat-stroked-button
                      matTooltip="{{
                        'ORDERS.GROUP_ACTION_TOOLTIP' | translate
                      }}"
                      [disabled]="!editable"
                      [matTooltipPosition]="'right'"
                      [disabled]="!editable"
                      [matMenuTriggerFor]="selectProductMenu"
                      class="mx-8"
                    >
                      Actions
                    </button>
                    <mat-menu #selectProductMenu="matMenu">
                      <button
                        mat-menu-item
                        [disabled]="selectedProducts.length === 0"
                        (click)="groupModifyQty('products')"
                      >
                        {{ "ORDERS.GROUP_ACTION_MODIFY_QTY" | translate }}
                      </button>
                      <button
                        mat-menu-item
                        [disabled]="selectedProducts.length === 0"
                        (click)="groupModifyPrice('products')"
                      >
                        {{ "ORDERS.GROUP_ACTION_MODIFY_PRICE" | translate }}
                      </button>
                      <button
                        mat-menu-item
                        [disabled]="selectedProducts.length === 0"
                        (click)="groupModifyPriceFree('products')"
                      >
                        {{ "ORDERS.GROUP_ACTION_MODIFY_FREE" | translate }}
                      </button>
                      <button
                        mat-menu-item
                        [disabled]="selectedProducts.length === 0"
                        (click)="groupDelete('products')"
                      >
                        {{ "GENERIC.DELETE" | translate }}
                      </button>
                    </mat-menu>
                  </div>
                </div>

                <div
                  fxFlex="13"
                  class="section-list-item section-list-item-border-right"
                >
                  <div class="p-4">{{ "GENERIC.QUANTITY" | translate }}</div>
                </div>

                <div
                  fxFlex="12"
                  class="section-list-item section-list-item-border-right"
                >
                  <div class="p-4">{{ "GENERIC.PRICE" | translate }}</div>
                </div>
                <div
                  fxFlex="13"
                  class="section-list-item section-list-item-border-right"
                >
                  <div class="p-4">
                    {{ "ORDERS.PRICE_SUBTOTAL" | translate }}
                  </div>
                </div>

                <div
                  fxFlex="230px"
                  class=""
                  fxLayout="row"
                  fxLayoutAlign="end start"
                  style="height: 42px"
                >
                  <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
                    <div
                      *ngIf="editable"
                      class="gt2-light-green"
                      style="width: 42px; height: 42px"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                    >
                      <button
                        type="button"
                        matTooltip="{{ 'ORDERS.ADD_PRODUCT' | translate }}"
                        mat-icon-button
                        class="add-product"
                        (click)="onAddProduct()"
                      >
                        <mat-icon class="icon-mat-black" color="">{{
                          getIcon("ADD")
                        }}</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                [ngClass]="productsExpand ? '' : 'section-list'"
                class=""
                fxLayout="column"
              >
                <div
                  *ngIf="
                    !order ||
                    !order.product_category_collection ||
                    order.product_category_collection.length === 0
                  "
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  class="font-size-20 p-16"
                >
                  {{ "ORDERS.PRODUCTS_NO_RESULTS" | translate }}
                </div>

                <!--                                NO DRAG AND DROP-->
                <div *ngIf="!editable">
                  <div class="">
                    <div
                      class="product-container-item"
                      *ngFor="
                        let product_category of order.product_category_collection;
                        let i = index
                      "
                    >
                      <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        class="category-title px-8 py-4"
                      >
                        <mat-icon class="mr-8 s-24">drag_handle</mat-icon>
                        <div class="">{{ product_category.name }}</div>
                      </div>

                      <div class="">
                        <div
                          class="product-child-item"
                          *ngFor="
                            let product of product_category.product_collection
                          "
                        >
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div
                              fxLayout="row"
                              fxLayoutAlign="start center"
                              fxFlex="100"
                              [ngClass]="
                                productCheckboxes[product.uuid] &&
                                'selected-row'
                              "
                            >
                              <div
                                fxFlex
                                fxLayout="row"
                                fxLayoutAlign="start center"
                                class="px-16 py-4 section-list-item-border-right"
                              >
                                <mat-icon class="mr-8 s-20"
                                  >drag_handle</mat-icon
                                >
                                <mat-checkbox
                                  [ngModel]="productCheckboxes[product.uuid]"
                                  (ngModelChange)="
                                    onSelectedChange(product.uuid)
                                  "
                                  (click)="$event.stopPropagation()"
                                  tabIndex="-1"
                                  class="mr-4"
                                >
                                </mat-checkbox>
                                <div
                                  *ngIf="
                                    !product.combo ||
                                    product.combo.length === 0 ||
                                    !product.combo_show ||
                                    !product.combo_show.combo_show_child_name
                                  "
                                  matTooltip="{{ product.name }}"
                                  [matTooltipPosition]="'right'"
                                >
                                  {{ product.name }}
                                </div>
                                <div
                                  *ngIf="
                                    product.combo &&
                                    product.combo.length > 0 &&
                                    product.combo_show &&
                                    product.combo_show.combo_show_child_name
                                  "
                                >
                                  <div
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                    matTooltip="{{ product.name }}"
                                    [matTooltipPosition]="'right'"
                                  >
                                    {{ product.name_internal }}
                                    <mat-chip-listbox
                                      [selectable]="false"
                                      tabIndex="-1"
                                      class="ml-8"
                                    >
                                      <mat-chip tabIndex="-1">
                                        {{ product.combo.length }}
                                      </mat-chip>
                                    </mat-chip-listbox>
                                  </div>
                                </div>
                                <div
                                  *ngIf="
                                    product.combo &&
                                    product.combo.length > 0 &&
                                    product.combo_show &&
                                    product.combo_show.combo_show_child_name
                                  "
                                >
                                  <button
                                    tabindex="-1"
                                    class="ml-8"
                                    mat-icon-button
                                    (click)="onToggleComboOpen(product)"
                                  >
                                    <mat-icon
                                      *ngIf="!product.comboToggleOpen"
                                      class="s-16 secondary-text icon-mat"
                                    >
                                      keyboard_arrow_down
                                    </mat-icon>
                                    <mat-icon
                                      tabindex="-1"
                                      *ngIf="product.comboToggleOpen"
                                      class="s-16 secondary-text icon-mat"
                                    >
                                      keyboard_arrow_up
                                    </mat-icon>
                                  </button>
                                </div>
                              </div>

                              <div
                                *ngxPermissionsOnly="rolesService.SALE_ROLES"
                                fxFlex="13"
                                fxLayout="row"
                                fxLayoutAlign="center center"
                                class="section-list-item section-list-item-border-right"
                              >
                                <div
                                  *ngIf="!editMode"
                                  fxFlex="100"
                                  fxLayout="row"
                                  fxLayoutAlign="center start"
                                >
                                  {{ product.qty }}
                                </div>
                                <div
                                  *ngIf="editMode"
                                  fxFlex="100"
                                  fxLayout="row"
                                  fxLayoutAlign="center center"
                                >
                                  <input
                                    matInput
                                    class="text-center no-spinners color-qty-input"
                                    type="number"
                                    fxFlex="76px"
                                    pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                                    [ngModel]="product.qty"
                                    [disabled]="!editable"
                                    autocomplete="off"
                                    (ngModelChange)="
                                      onProductQtyChange(product)
                                    "
                                    required
                                  />
                                </div>
                              </div>
                              <div
                                *ngxPermissionsExcept="rolesService.SALE_ROLES"
                                fxFlex="13"
                                fxLayout="row"
                                fxLayoutAlign="center center"
                                class="section-list-item section-list-item-border-right"
                              >
                                {{ product.qty }}
                              </div>

                              <div
                                fxFlex="12"
                                *ngIf="
                                  modulesService.modulesAreSet &&
                                  !moduleService.hasJLReport()
                                "
                                class="section-list-item section-list-item-border-right"
                                (dblclick)="
                                  onEditProduct(product, product_category)
                                "
                              >
                                {{ product.unit_price_label }}
                              </div>

                              <div
                                fxFlex="12"
                                *ngIf="
                                  modulesService.modulesAreSet &&
                                  moduleService.hasJLReport()
                                "
                                class="section-list-item section-list-item-border-right"
                                (dblclick)="
                                  onEditProduct(product, product_category)
                                "
                              >
                                ${{ product.charged_price_label }}
                              </div>

                              <div
                                fxFlex="13"
                                class="section-list-item section-list-item-border-right"
                              >
                                {{ product.sub_total_item_price_label }}
                              </div>

                              <div fxFlex="230px" class="section-list-item">
                                <button
                                  [ngStyle]="{
                                    visibility: product.combo
                                      ? 'visible'
                                      : 'hidden',
                                  }"
                                  class=""
                                  mat-icon-button
                                  *ngxPermissionsOnly="rolesService.SALE_ROLES"
                                  matTooltip="{{
                                    'GENERIC.EDIT_COMBO_ACTION' | translate
                                  }}"
                                  (click)="onEditCombo(product)"
                                  tabindex="-1"
                                >
                                  <mat-icon
                                    fontSet="fa"
                                    fontIcon="{{ getIcon('COMBO') }}"
                                    class="fa-lg icon-mat"
                                  >
                                  </mat-icon>
                                </button>

                                <button
                                  class=""
                                  mat-icon-button
                                  [disabled]="!isCTEditable"
                                  *ngxPermissionsOnly="rolesService.SALE_ROLES"
                                  (click)="
                                    onEditProduct(product, product_category)
                                  "
                                  tabindex="-1"
                                >
                                  <mat-icon class="s-16">{{
                                    getIcon("EDIT")
                                  }}</mat-icon>
                                </button>
                                <button
                                  class=""
                                  mat-icon-button
                                  [disabled]="!editable || loading"
                                  *ngxPermissionsOnly="rolesService.SALE_ROLES"
                                  (click)="onDeleteProduct(product)"
                                  tabindex="-1"
                                >
                                  <mat-icon class="s-16">{{
                                    getIcon("DELETE")
                                  }}</mat-icon>
                                </button>
                                <button
                                  mat-icon-button
                                  [disabled]="!editable"
                                  *ngxPermissionsOnly="rolesService.SALE_ROLES"
                                  matTooltip="{{
                                    'GENERIC.OPEN_NEW_TAB' | translate
                                  }}"
                                  (click)="onOpenProductInNewTab(product)"
                                  class="mat-icon-button"
                                  tabindex="-1"
                                >
                                  <mat-icon class="s-16 icon-mat"
                                    >{{ getIcon("NEW_TAB") }}
                                  </mat-icon>
                                </button>

                                <button
                                  mat-icon-button
                                  [disabled]="!editable"
                                  *ngxPermissionsOnly="rolesService.SALE_ROLES"
                                  matTooltip="{{
                                    'GENERIC.OPEN_SAME_TAB' | translate
                                  }}"
                                  (click)="onOpenObjectPage(product)"
                                  class="mat-icon-button"
                                  tabindex="-1"
                                >
                                  <mat-icon
                                    fontSet="fa"
                                    fontIcon="{{ getIcon('SAME_TAB') }}"
                                    class="icon-mat"
                                  >
                                  </mat-icon>
                                </button>

                                <button
                                  [ngStyle]="{
                                    visibility: product.combo
                                      ? 'visible'
                                      : 'hidden',
                                  }"
                                  class=""
                                  mat-icon-button
                                  *ngxPermissionsOnly="rolesService.GOD_ROLES"
                                  (click)="onReattachProduct(product)"
                                  tabindex="-1"
                                  matTooltip="GOD ONLY: detach and reattach"
                                >
                                  <mat-icon class="s-16">refresh</mat-icon>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div
                            *ngIf="
                              product.combo &&
                              product.comboToggleOpen &&
                              product.combo.length > 0 &&
                              product.combo_show &&
                              product.combo_show.combo_show_child_name
                            "
                            fxLayout="column"
                            fxLayoutAlign="0 1 auto"
                            class="combo-container"
                          >
                            <div
                              *ngFor="let comboItem of product.combo"
                              fxFlex="100"
                            >
                              <div
                                fxLayout="row"
                                fxLayoutAlign="start center"
                                fxFlex="100"
                                class="product-child-item"
                              >
                                <mat-checkbox
                                  [ngModel]="productCheckboxes[comboItem.uuid]"
                                  (ngModelChange)="
                                    onSelectedChange(comboItem.uuid)
                                  "
                                  (click)="$event.stopPropagation()"
                                  tabIndex="-1"
                                  class="mr-4"
                                >
                                </mat-checkbox>

                                <div
                                  fxFlex
                                  fxLayout="row"
                                  fxLayoutAlign="start center"
                                  class="pl-60 py-4 section-list-item-border-right"
                                >
                                  <div
                                    matTooltip="{{ comboItem.name }}"
                                    [matTooltipPosition]="'right'"
                                  >
                                    {{ comboItem.name_internal }}123
                                  </div>
                                </div>

                                <div fxFlex="13" class="section-list-item">
                                  <div
                                    *ngIf="
                                      product.combo_show.combo_show_child_qty
                                    "
                                  >
                                    {{ comboItem.qty * product.qty }}
                                  </div>
                                  <div
                                    *ngIf="
                                      !product.combo_show.combo_show_child_qty
                                    "
                                  >
                                    &nbsp;
                                  </div>
                                </div>

                                <div
                                  fxFlex="12"
                                  class="section-list-item section-list-item-border"
                                >
                                  &nbsp;
                                </div>

                                <div
                                  fxFlex="13"
                                  class="section-list-item section-list-item-border-right"
                                >
                                  <div
                                    *ngIf="
                                      product.combo_show.combo_show_child_price
                                    "
                                  >
                                    {{ comboItem.unit_price_label }}
                                  </div>
                                  <div
                                    *ngIf="
                                      !product.combo_show.combo_show_child_price
                                    "
                                  >
                                    &nbsp;
                                  </div>
                                </div>

                                <div
                                  fxFlex="230px"
                                  class="section-list-item section-list-item-border-right"
                                >
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div tabindex="0">&nbsp;</div>
                  </div>
                </div>

                <!--                                WITH DRAG AND DROP-->
                <div *ngIf="editable">
                  <!-- HACK! display: none-->
                  <!-- <ngx-dnd-container [(ngModel)]="['Item 1']" style="display: none">
                  </ngx-dnd-container> -->

                  <div
                    class=""
                    cdkDropList
                    (cdkDropListDropped)="onProductCategoryDrop($event)"
                  >
                    @for (
                      product_category of order.product_category_collection;
                      track product_category
                    ) {
                      <div
                        class="product-container-item"
                        matRipple
                        cdkDrag
                        [cdkDragData]="product_category"
                      >
                        <div
                          fxLayout="row"
                          fxLayoutAlign="start center"
                          class="category-title px-8 py-4"
                        >
                          <mat-icon cdkDragHandle class="mr-8 s-24"
                            >drag_handle</mat-icon
                          >
                          <div class="">{{ product_category.name }}</div>
                        </div>

                        <div
                          class=""
                          cdkDropList
                          (cdkDropListDropped)="
                            onProductChildDrop($event, product_category)
                          "
                        >
                          @for (
                            product of product_category.product_collection;
                            track product
                          ) {
                            <div
                              class="product-child-item"
                              cdkDrag
                              [cdkDragData]="product"
                            >
                              <div fxLayout="row" fxLayoutAlign="start center">
                                <div
                                  fxLayout="row"
                                  fxLayoutAlign="start center"
                                  fxFlex="100"
                                  [ngClass]="
                                    productCheckboxes[product.uuid] &&
                                    'selected-row'
                                  "
                                >
                                  <div
                                    fxFlex
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                    class="px-16 py-4 section-list-item-border-right"
                                    (dblclick)="
                                      onEditProduct(product, product_category)
                                    "
                                  >
                                    <mat-icon cdkDragHandle class="mr-8 s-20"
                                      >drag_handle</mat-icon
                                    >
                                    <mat-checkbox
                                      [ngModel]="
                                        productCheckboxes[product.uuid]
                                      "
                                      (ngModelChange)="
                                        onSelectedChange(product.uuid)
                                      "
                                      (click)="$event.stopPropagation()"
                                      tabIndex="-1"
                                      class="mr-4"
                                    >
                                    </mat-checkbox>
                                    <div
                                      *ngIf="
                                        !product.combo ||
                                        product.combo.length === 0 ||
                                        !product.combo_show ||
                                        !product.combo_show
                                          .combo_show_child_name
                                      "
                                      matTooltip="{{ product.name }}"
                                      [matTooltipPosition]="'right'"
                                    >
                                      {{ product.name }}
                                    </div>
                                    <div
                                      *ngIf="
                                        product.combo &&
                                        product.combo.length > 0 &&
                                        product.combo_show &&
                                        product.combo_show.combo_show_child_name
                                      "
                                    >
                                      <div
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        matTooltip="{{ product.name }}"
                                        [matTooltipPosition]="'right'"
                                      >
                                        {{ product.name }}
                                        <mat-chip-listbox
                                          [selectable]="false"
                                          tabIndex="-1"
                                          class="ml-8"
                                        >
                                          <mat-chip tabIndex="-1">
                                            {{ product.combo.length }}
                                          </mat-chip>
                                        </mat-chip-listbox>
                                      </div>
                                    </div>
                                    <div
                                      *ngIf="
                                        product.combo &&
                                        product.combo.length > 0 &&
                                        product.combo_show &&
                                        product.combo_show.combo_show_child_name
                                      "
                                    >
                                      <button
                                        tabindex="-1"
                                        class="ml-8"
                                        mat-icon-button
                                        (click)="onToggleComboOpen(product)"
                                      >
                                        <mat-icon
                                          *ngIf="!product.comboToggleOpen"
                                          class="s-16 secondary-text"
                                        >
                                          keyboard_arrow_down
                                        </mat-icon>
                                        <mat-icon
                                          tabindex="-1"
                                          *ngIf="product.comboToggleOpen"
                                          class="s-16 secondary-text"
                                        >
                                          keyboard_arrow_up
                                        </mat-icon>
                                      </button>
                                    </div>
                                  </div>

                                  <div
                                    *ngxPermissionsOnly="
                                      rolesService.SALE_ROLES
                                    "
                                    fxFlex="13"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                    class="section-list-item section-list-item-border-right"
                                  >
                                    <div
                                      *ngIf="!editMode"
                                      fxFlex="100"
                                      fxLayout="row"
                                      fxLayoutAlign="center start"
                                    >
                                      {{ product.qty }}
                                    </div>
                                    <div
                                      *ngIf="editMode"
                                      fxFlex="100"
                                      fxLayout="row"
                                      fxLayoutAlign="center center"
                                    >
                                      <input
                                        matInput
                                        class="text-center no-spinners color-qty-input"
                                        type="number"
                                        fxFlex="76px"
                                        pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                                        [ngModel]="product.qty"
                                        [disabled]="!editable"
                                        autocomplete="off"
                                        (ngModelChange)="
                                          onProductQtyChange(product)
                                        "
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div
                                    *ngxPermissionsExcept="
                                      rolesService.SALE_ROLES
                                    "
                                    fxFlex="13"
                                    fxLayout="row"
                                    fxLayoutAlign="center center"
                                    class="section-list-item section-list-item-border-right"
                                  >
                                    {{ product.qty }}
                                  </div>

                                  <div
                                    fxFlex="12"
                                    *ngIf="
                                      modulesService.modulesAreSet &&
                                      !moduleService.hasJLReport()
                                    "
                                    class="section-list-item section-list-item-border-right"
                                    (dblclick)="
                                      onEditProduct(product, product_category)
                                    "
                                  >
                                    {{ product.unit_price_label }}
                                  </div>

                                  <div
                                    fxFlex="12"
                                    *ngIf="
                                      modulesService.modulesAreSet &&
                                      moduleService.hasJLReport()
                                    "
                                    class="section-list-item section-list-item-border-right"
                                    (dblclick)="
                                      onEditProduct(product, product_category)
                                    "
                                  >
                                    ${{ product.charged_price_label }}
                                  </div>

                                  <div
                                    fxFlex="13"
                                    class="section-list-item section-list-item-border-right"
                                    (dblclick)="
                                      onEditProduct(product, product_category)
                                    "
                                  >
                                    {{ product.sub_total_item_price_label }}
                                  </div>
                                  <!-- SAID -->
                                  <div
                                    fxFlex="230px"
                                    fxLayoutAlign="center center"
                                    class="section-list-item"
                                  >
                                    <button
                                      [ngStyle]="{
                                        visibility: product.combo
                                          ? 'visible'
                                          : 'hidden',
                                      }"
                                      class=""
                                      mat-icon-button
                                      *ngxPermissionsOnly="
                                        rolesService.SALE_ROLES
                                      "
                                      matTooltip="{{
                                        'GENERIC.EDIT_COMBO_ACTION' | translate
                                      }}"
                                      (click)="onEditCombo(product)"
                                      tabindex="-1"
                                    >
                                      <mat-icon
                                        fontSet="fa"
                                        fontIcon="{{ getIcon('COMBO') }}"
                                        class="fa-lg"
                                      >
                                      </mat-icon>
                                    </button>
                                    <button
                                      class=""
                                      mat-icon-button
                                      [disabled]="!isCTEditable"
                                      *ngxPermissionsOnly="
                                        rolesService.SALE_ROLES
                                      "
                                      (click)="
                                        onEditProduct(product, product_category)
                                      "
                                      tabindex="-1"
                                    >
                                      <mat-icon class="s-16">{{
                                        getIcon("EDIT")
                                      }}</mat-icon>
                                    </button>
                                    <button
                                      class=""
                                      mat-icon-button
                                      [disabled]="!editable || loading"
                                      *ngxPermissionsOnly="
                                        rolesService.SALE_ROLES
                                      "
                                      (click)="onDeleteProduct(product)"
                                      tabindex="-1"
                                    >
                                      <mat-icon class="s-16">{{
                                        getIcon("DELETE")
                                      }}</mat-icon>
                                    </button>
                                    <button
                                      mat-icon-button
                                      [disabled]="!editable"
                                      *ngxPermissionsOnly="
                                        rolesService.SALE_ROLES
                                      "
                                      matTooltip="{{
                                        'GENERIC.OPEN_NEW_TAB' | translate
                                      }}"
                                      (click)="onOpenProductInNewTab(product)"
                                      class=""
                                      tabindex="-1"
                                    >
                                      <mat-icon class="s-16"
                                        >{{ getIcon("NEW_TAB") }}
                                      </mat-icon>
                                    </button>

                                    <button
                                      mat-icon-button
                                      [disabled]="!editable"
                                      *ngxPermissionsOnly="
                                        rolesService.SALE_ROLES
                                      "
                                      matTooltip="{{
                                        'GENERIC.OPEN_SAME_TAB' | translate
                                      }}"
                                      (click)="onOpenObjectPage(product)"
                                      class=""
                                      tabindex="-1"
                                    >
                                      <mat-icon
                                        fontSet="fa"
                                        fontIcon="{{ getIcon('SAME_TAB') }}"
                                      >
                                      </mat-icon>
                                    </button>

                                    <button
                                      [ngStyle]="{
                                        visibility: product.combo
                                          ? 'visible'
                                          : 'hidden',
                                      }"
                                      class=""
                                      mat-icon-button
                                      *ngxPermissionsOnly="
                                        rolesService.GOD_ROLES
                                      "
                                      (click)="onReattachProduct(product)"
                                      tabindex="-1"
                                      matTooltip="GOD ONLY: detach and reattach"
                                    >
                                      <mat-icon class="s-16">refresh</mat-icon>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div
                                *ngIf="
                                  product.combo &&
                                  product.comboToggleOpen &&
                                  product.combo.length > 0 &&
                                  product.combo_show &&
                                  product.combo_show.combo_show_child_name
                                "
                                fxLayout="column"
                                fxLayoutAlign="0 1 auto"
                                class="combo-container"
                              >
                                <div
                                  *ngFor="let comboItem of product.combo"
                                  fxFlex="100"
                                >
                                  <div
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                    fxFlex="100"
                                    class="product-child-item"
                                  >
                                    <mat-checkbox
                                      *ngIf="!product.multiply_combo_item_price"
                                      [ngModel]="
                                        productCheckboxes[comboItem.uuid]
                                      "
                                      (ngModelChange)="
                                        onSelectedChange(comboItem.uuid)
                                      "
                                      (click)="$event.stopPropagation()"
                                      tabIndex="-1"
                                      class="ml-60 mr-4"
                                    >
                                    </mat-checkbox>

                                    <div
                                      fxFlex
                                      fxLayout="row"
                                      fxLayoutAlign="start center"
                                      class="py-4 section-list-item-border-right"
                                      [ngClass]="
                                        !product.multiply_combo_item_price
                                          ? 'pl-4'
                                          : 'pl-60'
                                      "
                                    >
                                      <div
                                        matTooltip="{{ comboItem.name }}"
                                        [matTooltipPosition]="'right'"
                                      >
                                        {{ comboItem.name }}
                                      </div>
                                    </div>

                                    <div fxFlex="13" class="section-list-item">
                                      <div
                                        *ngIf="!editMode"
                                        fxFlex="100"
                                        fxLayout="row"
                                        fxLayoutAlign="center start"
                                      >
                                        <div
                                          *ngIf="
                                            product.multiply_combo_item_price
                                          "
                                        >
                                          {{ comboItem.qty * product.qty }}
                                        </div>
                                        <div
                                          *ngIf="
                                            !product.multiply_combo_item_price
                                          "
                                        >
                                          {{ comboItem.qty }}
                                        </div>
                                      </div>
                                      <div
                                        *ngIf="editMode"
                                        fxFlex="100"
                                        fxLayout="row"
                                        fxLayoutAlign="center center"
                                      >
                                        <div
                                          *ngIf="
                                            product.multiply_combo_item_price
                                          "
                                        >
                                          {{ comboItem.qty * product.qty }}
                                        </div>
                                        <div
                                          *ngIf="
                                            !product.multiply_combo_item_price
                                          "
                                        >
                                          <input
                                            matInput
                                            class="text-center no-spinners color-qty-input"
                                            type="number"
                                            fxFlex="76px"
                                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                                            [disabled]="!editable"
                                            autocomplete="off"
                                            (ngModelChange)="
                                              onProductQtyChange(comboItem)
                                            "
                                            required
                                            [ngModel]="comboItem.qty"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      fxFlex="12"
                                      class="section-list-item section-list-item-border"
                                    >
                                      &nbsp;
                                    </div>

                                    <div
                                      fxFlex="13"
                                      class="section-list-item section-list-item-border-right"
                                    >
                                      <div
                                        *ngIf="
                                          product.combo_show
                                            .combo_show_child_price
                                        "
                                      >
                                        {{ comboItem.unit_price_label }}
                                      </div>
                                      <div
                                        *ngIf="
                                          !product.combo_show
                                            .combo_show_child_price
                                        "
                                      >
                                        &nbsp;
                                      </div>
                                    </div>

                                    <div
                                      fxFlex="230px"
                                      class="section-list-item"
                                    >
                                      <button
                                        style="visibility: hidden"
                                        class=""
                                        mat-icon-button
                                        tabindex="-1"
                                      >
                                        &nbsp;
                                      </button>
                                      <button
                                        class=""
                                        mat-icon-button
                                        [disabled]="!isCTEditable"
                                        *ngxPermissionsOnly="
                                          rolesService.SALE_ROLES
                                        "
                                        (click)="
                                          onEditProductCombo(
                                            comboItem,
                                            product_category,
                                            product.uuid
                                          )
                                        "
                                        tabindex="-1"
                                      >
                                        <mat-icon class="s-16">{{
                                          getIcon("EDIT")
                                        }}</mat-icon>
                                      </button>
                                      <button
                                        class=""
                                        mat-icon-button
                                        [disabled]="!editable || loading"
                                        *ngxPermissionsOnly="
                                          rolesService.SALE_ROLES
                                        "
                                        (click)="
                                          onRemoveComboItem(product, comboItem)
                                        "
                                        tabindex="-1"
                                      >
                                        <mat-icon class="s-16">{{
                                          getIcon("DELETE")
                                        }}</mat-icon>
                                      </button>
                                      <button
                                        mat-icon-button
                                        [disabled]="!editable"
                                        *ngxPermissionsOnly="
                                          rolesService.SALE_ROLES
                                        "
                                        matTooltip="{{
                                          'GENERIC.OPEN_NEW_TAB' | translate
                                        }}"
                                        (click)="
                                          onOpenProductInNewTab(comboItem)
                                        "
                                        class="mat-icon-button"
                                        tabindex="-1"
                                      >
                                        <mat-icon class="s-16 icon-mat"
                                          >{{ getIcon("NEW_TAB") }}
                                        </mat-icon>
                                      </button>

                                      <button
                                        mat-icon-button
                                        [disabled]="!editable"
                                        *ngxPermissionsOnly="
                                          rolesService.SALE_ROLES
                                        "
                                        matTooltip="{{
                                          'GENERIC.OPEN_SAME_TAB' | translate
                                        }}"
                                        (click)="onOpenObjectPage(comboItem)"
                                        class="mat-icon-button"
                                        tabindex="-1"
                                      >
                                        <mat-icon
                                          fontSet="fa"
                                          fontIcon="{{ getIcon('SAME_TAB') }}"
                                          class="icon-mat"
                                        >
                                        </mat-icon>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    }
                  </div>
                  <div tabindex="0">&nbsp;</div>
                </div>

                <div *ngIf="dndLoading" class="product-list-spinner-container">
                  <mat-spinner
                    [diameter]="28"
                    class="product-list-spinner"
                  ></mat-spinner>
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                class="px-12 sub-total"
              >
                <div>
                  <div
                    *ngIf="
                      order.product_allergen_collection &&
                      order.product_allergen_collection.length > 0
                    "
                  >
                    <div
                      fxLayout="row"
                      matTooltip="{{ allergensTooltip }}"
                      matTooltipClass="custom-tooltip"
                      fxLayoutAlign="start center"
                    >
                      <mat-icon class="bold" color="warn">{{
                        getIcon("WARNING")
                      }}</mat-icon>
                      <span class="ml-8">{{
                        "GENERIC.ALLERGENS" | translate
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="
                    order.product_subtotal_raw !== 0 && order.guest_count !== 0
                  "
                  class="py-4"
                >
                  {{ "ORDERS.SUB_TOTAL_PER_PERSON" | translate }}
                  <span class="total-number"
                    >${{
                      order.product_subtotal_raw / order.guest_count
                        | numberNotInfinityPipe
                        | number: "1.2-2"
                    }}</span
                  >
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {{ "ORDERS.SUB_TOTAL_PRODUCTS" | translate }}
                  <span class="total-number">{{ order.product_subtotal }}</span>
                </div>
                <div
                  *ngIf="
                    order.product_subtotal_raw === 0 || order.guest_count === 0
                  "
                  class="py-4"
                >
                  {{ "ORDERS.SUB_TOTAL_PER_PERSON" | translate }}
                  <span class="total-number">$0.00</span>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {{ "ORDERS.SUB_TOTAL_PRODUCTS" | translate }}
                  <span class="total-number">{{ order.product_subtotal }}</span>
                </div>
              </div>
            </mat-tab>

            <!-- MATERIALS -->
            <mat-tab label="{{ 'GENERIC.MATERIAL' | translate }}">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="100"
                class="section-list-header"
              >
                <div
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxFlex
                  class="section-list-item-border-right"
                >
                  <div fxLayoutAlign="space-between center" class="p-4 pl-32">
                    <mat-checkbox
                      [ngModel]="materialAllCheckboxes"
                      (ngModelChange)="onSelectedMaterialAllChange()"
                      (click)="$event.stopPropagation()"
                      tabIndex="-1"
                      class="mr-4"
                    >
                    </mat-checkbox>
                    {{ "GENERIC.EXTERNAL_NAME" | translate }}
                  </div>

                  <div class="product-group-action-menu">
                    <span
                      *ngIf="selectedMaterials.length > 0"
                      class="selected-contacts-count"
                    >
                      <span class="mr-4">{{ selectedMaterials.length }}</span>
                      <span
                        >{{ "ORDERS.GROUP_ACTION_SELECTED" | translate
                        }}<span *ngIf="selectedMaterials.length > 1">{{
                          "ORDERS.GROUP_ACTION_SELECTED_POSTFIX" | translate
                        }}</span></span
                      >
                    </span>
                    <button
                      mat-stroked-button
                      matTooltip="{{
                        'ORDERS.GROUP_ACTION_TOOLTIP' | translate
                      }}"
                      [matTooltipPosition]="'right'"
                      [disabled]="!editable"
                      [matMenuTriggerFor]="selectMenu"
                      class="mx-8"
                    >
                      Actions
                    </button>
                    <mat-menu #selectMenu="matMenu">
                      <button
                        mat-menu-item
                        [disabled]="selectedMaterials.length === 0"
                        (click)="groupModifyQty('materials')"
                      >
                        {{ "ORDERS.GROUP_ACTION_MODIFY_QTY" | translate }}
                      </button>
                      <button
                        mat-menu-item
                        [disabled]="selectedMaterials.length === 0"
                        (click)="groupModifyPrice('materials')"
                      >
                        {{ "ORDERS.GROUP_ACTION_MODIFY_PRICE" | translate }}
                      </button>
                      <button
                        mat-menu-item
                        [disabled]="selectedMaterials.length === 0"
                        (click)="groupModifyPriceFree('materials')"
                      >
                        {{ "ORDERS.GROUP_ACTION_MODIFY_FREE" | translate }}
                      </button>
                      <button
                        mat-menu-item
                        [disabled]="selectedMaterials.length === 0"
                        (click)="groupDelete('materials')"
                      >
                        {{ "GENERIC.DELETE" | translate }}
                      </button>
                      <button
                        mat-menu-item
                        [disabled]="selectedMaterials.length === 0"
                        (click)="groupVisibleToClient(false)"
                      >
                        {{ "ORDERS.GROUP_ACTION_CLIENT_VISIBLE" | translate }}
                      </button>
                      <button
                        mat-menu-item
                        [disabled]="selectedMaterials.length === 0"
                        (click)="groupVisibleToClient(true)"
                      >
                        {{ "ORDERS.GROUP_ACTION_CLIENT_INVISIBLE" | translate }}
                      </button>
                      <button
                        mat-menu-item
                        [disabled]="selectedMaterials.length === 0"
                        (click)="groupShowPriceToClient(true)"
                      >
                        {{
                          "ORDERS.GROUP_ACTION_SHOW_CLIENT_PRICE" | translate
                        }}
                      </button>
                      <button
                        mat-menu-item
                        [disabled]="selectedMaterials.length === 0"
                        (click)="groupShowPriceToClient(false)"
                      >
                        {{
                          "ORDERS.GROUP_ACTION_SHOW_CLIENT_PRICE_NOT"
                            | translate
                        }}
                      </button>
                    </mat-menu>
                  </div>
                </div>
                <div
                  fxFlex="8"
                  class="section-list-item section-list-item-border-right"
                >
                  <div class="p-4">{{ "ORDERS.VISIBILITY" | translate }}</div>
                </div>

                <div
                  fxFlex="13"
                  class="section-list-item section-list-item-border-right"
                >
                  <div class="p-4">{{ "GENERIC.QUANTITY" | translate }}</div>
                </div>

                <div
                  fxFlex="12"
                  class="section-list-item section-list-item-border-right"
                >
                  <div class="p-4">{{ "GENERIC.PRICE" | translate }}</div>
                </div>
                <div
                  fxFlex="13"
                  class="section-list-item section-list-item-border-right"
                >
                  <div class="p-4">
                    {{ "ORDERS.PRICE_SUBTOTAL" | translate }}
                  </div>
                </div>

                <div
                  fxFlex="230px"
                  class=""
                  fxLayout="row"
                  fxLayoutAlign="end start"
                  style="height: 42px"
                >
                  <div *ngIf="order.type === 'INVOICE'">
                    <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
                      <div
                        *ngIf="editable"
                        class="gt2-light-green"
                        style="width: 42px; height: 42px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{ 'ORDERS.ADD_MATERIAL' | translate }}"
                          mat-icon-button
                          class="add-product"
                          (click)="onAddMaterial()"
                        >
                          <mat-icon class="icon-mat-black" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="order.type !== 'INVOICE'">
                    <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
                      <div
                        *ngIf="editable"
                        class="gt2-light-green"
                        style="width: 42px; height: 42px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{ 'ORDERS.ADD_MATERIAL' | translate }}"
                          mat-icon-button
                          class="add-product"
                          (click)="onAddMaterial()"
                        >
                          <mat-icon class="icon-mat-black" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                [ngClass]="productsExpand ? '' : 'section-list'"
                class=""
                fxLayout="column"
              >
                <div
                  *ngIf="
                    !order ||
                    !order.material_category_collection ||
                    order.material_category_collection.length === 0
                  "
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  class="font-size-20 p-16"
                >
                  {{ "ORDERS.MATERIALS_NO_RESULTS" | translate }}
                </div>

                <!--                                NO DRAG AND DROP-->
                <div *ngIf="!editable">
                  <div class="">
                    <div
                      class="product-container-item"
                      *ngFor="
                        let material_category of order.material_category_collection;
                        let i = index
                      "
                    >
                      <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        class="category-title px-8 py-4"
                      >
                        <mat-icon class="mr-8 s-24">drag_handle</mat-icon>
                        <div class="">{{ material_category.name }}</div>
                      </div>

                      <div class="">
                        <div
                          class="product-child-item"
                          *ngFor="
                            let material of material_category.material_collection
                          "
                        >
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div
                              fxLayout="row"
                              fxLayoutAlign="start center"
                              fxFlex="100"
                              [ngClass]="{
                                'accent-100': materialCheckboxes[material.uuid],
                              }"
                            >
                              <div
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                                class="px-16 py-4 section-list-item-border-right"
                                fxFlex="100"
                              >
                                <div
                                  fxLayout="row"
                                  fxLayoutAlign="start center"
                                >
                                  <mat-icon class="mr-8 s-20"
                                    >drag_handle</mat-icon
                                  >
                                  <mat-checkbox
                                    [ngModel]="
                                      materialCheckboxes[material.uuid]
                                    "
                                    (ngModelChange)="
                                      onSelectedMaterialsChange(material.uuid)
                                    "
                                    (click)="$event.stopPropagation()"
                                    tabIndex="-1"
                                    class="mr-4"
                                  >
                                  </mat-checkbox>
                                  <div
                                    [class.internal_view_only]="
                                      material.internal_view_only
                                    "
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                    matTooltip="{{ material.name }}"
                                    [matTooltipPosition]="'right'"
                                  >
                                    {{ material.name_internal }}
                                    <mat-chip-listbox
                                      [selectable]="false"
                                      tabIndex="-1"
                                      *ngIf="material.combo"
                                      class="ml-8"
                                    >
                                      <mat-chip tabIndex="-1">
                                        {{ material.combo.length }}
                                      </mat-chip>
                                    </mat-chip-listbox>
                                  </div>

                                  <div
                                    *ngIf="
                                      material.combo &&
                                      material.combo.length > 0
                                    "
                                  >
                                    <button
                                      tabindex="-1"
                                      class="ml-8"
                                      mat-icon-button
                                      (click)="onToggleComboOpen(material)"
                                    >
                                      <mat-icon
                                        *ngIf="!material.comboToggleOpen"
                                        class="s-16 secondary-text icon-mat"
                                      >
                                        keyboard_arrow_down
                                      </mat-icon>
                                      <mat-icon
                                        tabindex="-1"
                                        *ngIf="material.comboToggleOpen"
                                        class="s-16 secondary-text icon-mat"
                                      >
                                        keyboard_arrow_up
                                      </mat-icon>
                                    </button>
                                  </div>
                                </div>

                                <div>
                                  <mat-icon
                                    matTooltip="{{
                                      'ORDERS.WARNING_COMBO_1' | translate
                                    }}"
                                    *ngIf="material.internal_view_only"
                                    [matTooltipPosition]="'right'"
                                    class="bold"
                                    color="warn"
                                    >{{ getIcon("WARNING") }}</mat-icon
                                  >

                                  <mat-icon
                                    matTooltip="{{
                                      'ORDERS.WARNING_COMBO_2' | translate
                                    }}"
                                    *ngIf="
                                      !material.internal_view_only &&
                                      !material.show_unit_price
                                    "
                                    [matTooltipPosition]="'right'"
                                    class="bold"
                                    color="warn"
                                    >{{ getIcon("WARNING") }}</mat-icon
                                  >
                                </div>
                              </div>

                              <!-- EYE & MONEY-->
                              <div
                                *ngIf="material.combo && material.combo.length"
                                fxFlex="8"
                                class="section-list-item section-list-item-border-right"
                              >
                                <button
                                  mat-icon-button
                                  [disabled]="!editable || loading"
                                  matTooltip="{{
                                    'ORDERS.APPLY_CHILDREN' | translate
                                  }}"
                                  (click)="
                                    onToggleComboVisibility(
                                      material,
                                      material.internal_view_only
                                    )
                                  "
                                  class="mat-icon-button"
                                  tabindex="-1"
                                >
                                  <mat-icon
                                    *ngIf="!material.internal_view_only"
                                    class="s-16"
                                    >{{ getIcon("VISIBLILITY") }}
                                  </mat-icon>
                                  <mat-icon
                                    *ngIf="material.internal_view_only"
                                    class="s-16"
                                    >{{ getIcon("VISIBLILITY_OFF") }}
                                  </mat-icon>
                                </button>

                                <button
                                  mat-icon-button
                                  [disabled]="!editable || loading"
                                  matTooltip="{{
                                    'ORDERS.APPLY_CHILDREN' | translate
                                  }}"
                                  (click)="
                                    onToggleComboMoney(
                                      material,
                                      material.show_unit_price
                                    )
                                  "
                                  class="mat-icon-button"
                                  tabindex="-1"
                                >
                                  <mat-icon
                                    *ngIf="!material.show_unit_price"
                                    class="s-16"
                                    >{{ getIcon("MONEY_OFF") }}
                                  </mat-icon>
                                  <mat-icon
                                    *ngIf="material.show_unit_price"
                                    class="s-16"
                                  >
                                    {{ getIcon("ATTACH_MONEY") }}
                                  </mat-icon>
                                </button>
                              </div>

                              <!-- SINGLE ITEM EYE & MONEY-->
                              <div
                                *ngIf="!material.combo"
                                fxFlex="8"
                                class="section-list-item section-list-item-border-right"
                              >
                                <button
                                  mat-icon-button
                                  [disabled]="!editable || loading"
                                  matTooltip=""
                                  (click)="onToggleItemVisibility(material)"
                                  class="mat-icon-button"
                                  tabindex="-1"
                                >
                                  <mat-icon
                                    matTooltip="{{
                                      'ORDERS.INTERNAL_VIEW_FALSE' | translate
                                    }}"
                                    [matTooltipPosition]="'right'"
                                    *ngIf="!material.internal_view_only"
                                    class="s-16"
                                    >{{ getIcon("VISIBLILITY") }}
                                  </mat-icon>
                                  <mat-icon
                                    matTooltip="{{
                                      'ORDERS.INTERNAL_VIEW_TRUE' | translate
                                    }}"
                                    [matTooltipPosition]="'right'"
                                    *ngIf="material.internal_view_only"
                                    class="s-16"
                                    >{{ getIcon("VISIBLILITY_OFF") }}
                                  </mat-icon>
                                </button>

                                <button
                                  mat-icon-button
                                  [disabled]="!editable || loading"
                                  matTooltip=""
                                  (click)="onToggleItemMoney(material)"
                                  class="mat-icon-button"
                                  tabindex="-1"
                                >
                                  <mat-icon
                                    matTooltip="{{
                                      'ORDERS.SHOW_UNIT_PRICE_FALSE' | translate
                                    }}"
                                    [matTooltipPosition]="'right'"
                                    *ngIf="!material.show_unit_price"
                                    class="s-16"
                                  >
                                    {{ getIcon("MONEY_OFF") }}</mat-icon
                                  >
                                  <mat-icon
                                    matTooltip="{{
                                      'ORDERS.SHOW_UNIT_PRICE_TRUE' | translate
                                    }}"
                                    [matTooltipPosition]="'right'"
                                    *ngIf="material.show_unit_price"
                                    class="s-16"
                                    >{{ getIcon("ATTACH_MONEY") }}</mat-icon
                                  >
                                </button>
                              </div>

                              <div
                                fxFlex="13"
                                fxLayout="row"
                                *ngxPermissionsOnly="
                                  rolesService.TEAM_LEAD_ROLES
                                "
                                fxLayoutAlign="center center"
                                class="section-list-item section-list-item-border-right"
                              >
                                <div
                                  *ngIf="!editMode"
                                  fxFlex="100"
                                  fxLayout="row"
                                  fxLayoutAlign="center center"
                                >
                                  {{ material.qty }}
                                </div>
                                <div
                                  *ngIf="editMode"
                                  fxFlex="100"
                                  fxLayout="row"
                                  fxLayoutAlign="center center"
                                >
                                  <input
                                    matInput
                                    class="text-center no-spinners color-qty-input"
                                    type="number"
                                    fxFlex="76px"
                                    pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                                    [ngModel]="material.qty"
                                    [disabled]="!editable"
                                    autocomplete="off"
                                    (ngModelChange)="
                                      onMaterialQtyChange(material)
                                    "
                                    required
                                  />
                                </div>
                              </div>
                              <div
                                fxFlex="13"
                                fxLayout="row"
                                *ngxPermissionsExcept="
                                  rolesService.TEAM_LEAD_ROLES
                                "
                                fxLayoutAlign="center center"
                                class="section-list-item section-list-item-border-right"
                              >
                                {{ material.qty }}
                              </div>

                              <div
                                fxFlex="12"
                                (dblclick)="
                                  onEditMaterial(material, material_category)
                                "
                                [class.internal_view_only]="
                                  !material.show_unit_price
                                "
                                class="section-list-item section-list-item-border-right"
                              >
                                {{ material.unit_price_label }}
                              </div>

                              <div
                                fxFlex="13"
                                (dblclick)="
                                  onEditMaterial(material, material_category)
                                "
                                class="section-list-item section-list-item-border-right"
                              >
                                {{ material.sub_total_item_price_label }}
                              </div>

                              <div fxFlex="230px" class="section-list-item">
                                <button
                                  class=""
                                  mat-icon-button
                                  [disabled]="!isCTEditable"
                                  *ngxPermissionsOnly="
                                    rolesService.TEAM_LEAD_ROLES
                                  "
                                  (click)="
                                    onEditMaterial(material, material_category)
                                  "
                                  tabindex="-1"
                                >
                                  <mat-icon class="s-16">{{
                                    getIcon("EDIT")
                                  }}</mat-icon>
                                </button>
                                <button
                                  class=""
                                  mat-icon-button
                                  *ngxPermissionsOnly="
                                    rolesService.TEAM_LEAD_ROLES
                                  "
                                  [disabled]="!editable || loading"
                                  (click)="onDeleteMaterial(material)"
                                  tabindex="-1"
                                >
                                  <mat-icon class="s-16">{{
                                    getIcon("DELETE")
                                  }}</mat-icon>
                                </button>
                                <button
                                  mat-icon-button
                                  [disabled]="!editable || loading"
                                  *ngxPermissionsOnly="
                                    rolesService.TEAM_LEAD_ROLES
                                  "
                                  matTooltip="{{
                                    'GENERIC.OPEN_NEW_TAB' | translate
                                  }}"
                                  (click)="onOpenProductInNewTab(material)"
                                  class="mat-icon-button"
                                  tabindex="-1"
                                >
                                  <mat-icon class="s-16 icon-mat"
                                    >{{ getIcon("NEW_TAB") }}
                                  </mat-icon>
                                </button>

                                <button
                                  mat-icon-button
                                  [disabled]="!editable || loading"
                                  *ngxPermissionsOnly="
                                    rolesService.TEAM_LEAD_ROLES
                                  "
                                  matTooltip="{{
                                    'GENERIC.OPEN_SAME_TAB' | translate
                                  }}"
                                  (click)="onOpenObjectPage(material)"
                                  class="mat-icon-button"
                                  tabindex="-1"
                                >
                                  <mat-icon
                                    fontSet="fa"
                                    fontIcon="{{ getIcon('SAME_TAB') }}"
                                    class="icon-mat"
                                  >
                                  </mat-icon>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div
                            *ngIf="
                              material.comboToggleOpen &&
                              material.combo &&
                              material.combo.length
                            "
                            fxLayout="column"
                            fxLayoutAlign="0 1 auto"
                            class="combo-container"
                          >
                            <div
                              *ngFor="let comboItem of material.combo"
                              fxFlex="100"
                            >
                              <div
                                fxLayout="row"
                                fxLayoutAlign="start center"
                                fxFlex="100"
                                class="product-child-item"
                              >
                                <div
                                  fxFlex
                                  fxLayout="row"
                                  fxLayoutAlign="start center"
                                  class="pl-60 py-4 section-list-item-border-right"
                                >
                                  <div
                                    [class.internal_view_only]="
                                      comboItem.internal_view_only
                                    "
                                    matTooltip="{{ comboItem.name }}"
                                    [matTooltipPosition]="'right'"
                                  >
                                    {{ comboItem.name_internal }}
                                  </div>
                                </div>

                                <div fxFlex="8" class="section-list-item">
                                  <button
                                    mat-icon-button
                                    [disabled]="!editable || loading"
                                    matTooltip=""
                                    (click)="
                                      onToggleComboItemVisibility(
                                        comboItem,
                                        material
                                      )
                                    "
                                    class="mat-icon-button"
                                    tabindex="-1"
                                  >
                                    <mat-icon
                                      matTooltip="{{
                                        'ORDERS.INTERNAL_VIEW_TRUE' | translate
                                      }}"
                                      [matTooltipPosition]="'right'"
                                      *ngIf="comboItem.internal_view_only"
                                      class="s-16"
                                      >{{
                                        getIcon("VISIBLILITY_OFF")
                                      }}</mat-icon
                                    >
                                    <mat-icon
                                      matTooltip="{{
                                        'ORDERS.INTERNAL_VIEW_FALSE' | translate
                                      }}"
                                      [matTooltipPosition]="'right'"
                                      *ngIf="!comboItem.internal_view_only"
                                      class="s-16"
                                      >{{ getIcon("VISIBLILITY") }}
                                    </mat-icon>
                                  </button>

                                  <button
                                    mat-icon-button
                                    [disabled]="!editable || loading"
                                    matTooltip=""
                                    (click)="
                                      onToggleComboItemMoney(
                                        comboItem,
                                        material
                                      )
                                    "
                                    class="mat-icon-button"
                                    tabindex="-1"
                                  >
                                    <mat-icon
                                      matTooltip="{{
                                        'ORDERS.SHOW_UNIT_PRICE_TRUE'
                                          | translate
                                      }}"
                                      [matTooltipPosition]="'right'"
                                      *ngIf="comboItem.show_unit_price"
                                      class="s-16"
                                    >
                                      {{ getIcon("ATTACH_MONEY") }}
                                    </mat-icon>
                                    <mat-icon
                                      matTooltip="{{
                                        'ORDERS.SHOW_UNIT_PRICE_FALSE'
                                          | translate
                                      }}"
                                      [matTooltipPosition]="'right'"
                                      *ngIf="!comboItem.show_unit_price"
                                      class="s-16"
                                    >
                                      {{ getIcon("MONEY_OFF") }}
                                    </mat-icon>
                                  </button>
                                </div>

                                <div
                                  fxFlex="13"
                                  class="section-list-item section-list-item-border"
                                >
                                  {{ comboItem.qty }}
                                </div>

                                <div
                                  fxFlex="12"
                                  class="section-list-item section-list-item-border"
                                >
                                  <div
                                    [class.item_show_no_unit_price]="
                                      !comboItem.show_unit_price
                                    "
                                  >
                                    {{ comboItem.price_label }}
                                  </div>
                                </div>

                                <div
                                  fxFlex="13"
                                  class="section-list-item section-list-item-border-right"
                                ></div>

                                <div
                                  fxFlex="230px"
                                  class="section-list-item section-list-item-border-right"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div tabindex="0">&nbsp;</div>
                </div>

                <!--                                WITH DRAG AND DROP-->
                <div *ngIf="editable">
                  <!-- HACK! display: none-->
                  <!-- <ngx-dnd-container [(ngModel)]="['Item 1']" style="display: none">
                  </ngx-dnd-container> -->

                  <div
                    class=""
                    cdkDropList
                    (cdkDropListDropped)="onMaterialCategoryDrop($event)"
                  >
                    @for (
                      material_category of order.material_category_collection;
                      track material_category
                    ) {
                      <div
                        class="product-container-item"
                        matRipple
                        cdkDrag
                        [cdkDragData]="material_category"
                      >
                        <div
                          fxLayout="row"
                          fxLayoutAlign="start center"
                          class="category-title px-8 py-4"
                        >
                          <mat-icon cdkDragHandle class="mr-8 s-24"
                            >drag_handle</mat-icon
                          >
                          <div class="">{{ material_category.name }}</div>
                        </div>

                        <div
                          class=""
                          cdkDropList
                          (cdkDropListDropped)="
                            onMaterialChildDrop($event, material_category)
                          "
                        >
                          @for (
                            material of material_category.material_collection;
                            track material
                          ) {
                            <div
                              class="product-child-item"
                              cdkDrag
                              [cdkDragData]="material"
                            >
                              <div fxLayout="row" fxLayoutAlign="start center">
                                <div
                                  fxLayout="row"
                                  fxLayoutAlign="start center"
                                  fxFlex="100"
                                  [ngClass]="{
                                    'accent-100':
                                      materialCheckboxes[material.uuid],
                                  }"
                                >
                                  <div
                                    fxLayout="row"
                                    fxLayoutAlign="space-between center"
                                    (dblclick)="
                                      onEditMaterial(
                                        material,
                                        material_category
                                      )
                                    "
                                    class="px-16 py-4 section-list-item-border-right"
                                    fxFlex="100"
                                  >
                                    <div
                                      fxLayout="row"
                                      fxLayoutAlign="start center"
                                    >
                                      <mat-icon cdkDragHandle class="mr-8 s-20"
                                        >drag_handle</mat-icon
                                      >
                                      <mat-checkbox
                                        [ngModel]="
                                          materialCheckboxes[material.uuid]
                                        "
                                        (ngModelChange)="
                                          onSelectedMaterialsChange(
                                            material.uuid
                                          )
                                        "
                                        (click)="$event.stopPropagation()"
                                        tabIndex="-1"
                                        class="mr-4"
                                      >
                                      </mat-checkbox>

                                      <div
                                        [class.internal_view_only]="
                                          material.internal_view_only
                                        "
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        matTooltip="{{ material.name }}"
                                        [matTooltipPosition]="'right'"
                                      >
                                        {{ material.name_internal }}
                                        <mat-chip-listbox
                                          [selectable]="false"
                                          tabIndex="-1"
                                          *ngIf="material.combo"
                                          class="ml-8"
                                        >
                                          <mat-chip tabIndex="-1">
                                            {{ material.combo.length }}
                                          </mat-chip>
                                        </mat-chip-listbox>
                                      </div>

                                      <div
                                        *ngIf="
                                          material.combo &&
                                          material.combo.length > 0
                                        "
                                      >
                                        <button
                                          tabindex="-1"
                                          class="ml-8"
                                          mat-icon-button
                                          (click)="onToggleComboOpen(material)"
                                        >
                                          <mat-icon
                                            *ngIf="!material.comboToggleOpen"
                                            class="s-16 secondary-text"
                                          >
                                            keyboard_arrow_down
                                          </mat-icon>
                                          <mat-icon
                                            tabindex="-1"
                                            *ngIf="material.comboToggleOpen"
                                            class="s-16 secondary-text"
                                          >
                                            keyboard_arrow_up
                                          </mat-icon>
                                        </button>
                                      </div>
                                    </div>

                                    <div>
                                      <mat-icon
                                        matTooltip="{{
                                          'ORDERS.WARNING_COMBO_1' | translate
                                        }}"
                                        *ngIf="material.internal_view_only"
                                        [matTooltipPosition]="'right'"
                                        class="bold"
                                        color="warn"
                                        >{{ getIcon("WARNING") }}</mat-icon
                                      >

                                      <mat-icon
                                        matTooltip="{{
                                          'ORDERS.WARNING_COMBO_2' | translate
                                        }}"
                                        *ngIf="
                                          !material.internal_view_only &&
                                          !material.show_unit_price
                                        "
                                        [matTooltipPosition]="'right'"
                                        class="bold"
                                        color="warn"
                                        >{{ getIcon("WARNING") }}</mat-icon
                                      >
                                    </div>
                                  </div>

                                  <!-- EYE & MONEY-->
                                  <div
                                    *ngIf="
                                      material.combo && material.combo.length
                                    "
                                    fxFlex="8"
                                    class="section-list-item section-list-item-border-right"
                                  >
                                    <button
                                      mat-icon-button
                                      [disabled]="!editable || loading"
                                      matTooltip="{{
                                        'ORDERS.APPLY_CHILDREN' | translate
                                      }}"
                                      (click)="
                                        onToggleComboVisibility(
                                          material,
                                          material.internal_view_only
                                        )
                                      "
                                      class="mat-icon-button"
                                      tabindex="-1"
                                    >
                                      <mat-icon
                                        *ngIf="!material.internal_view_only"
                                        class="s-16"
                                        >{{ getIcon("VISIBLILITY") }}
                                      </mat-icon>
                                      <mat-icon
                                        *ngIf="material.internal_view_only"
                                        class="s-16"
                                        >{{ getIcon("VISIBLILITY_OFF") }}
                                      </mat-icon>
                                    </button>

                                    <button
                                      mat-icon-button
                                      [disabled]="!editable || loading"
                                      matTooltip="111{{
                                        'ORDERS.APPLY_CHILDREN' | translate
                                      }}"
                                      (click)="
                                        onToggleComboMoney(
                                          material,
                                          material.show_unit_price
                                        )
                                      "
                                      class="mat-icon-button"
                                      tabindex="-1"
                                    >
                                      <mat-icon
                                        *ngIf="!material.show_unit_price"
                                        class="s-16"
                                        >{{ getIcon("MONEY_OFF") }}
                                      </mat-icon>
                                      <mat-icon
                                        *ngIf="material.show_unit_price"
                                        class="s-16"
                                      >
                                        {{ getIcon("ATTACH_MONEY") }}
                                      </mat-icon>
                                    </button>
                                  </div>

                                  <!-- SINGLE ITEM EYE & MONEY-->
                                  <div
                                    *ngIf="!material.combo"
                                    fxFlex="8"
                                    class="section-list-item section-list-item-border-right"
                                  >
                                    <button
                                      mat-icon-button
                                      [disabled]="!editable || loading"
                                      matTooltip=""
                                      (click)="onToggleItemVisibility(material)"
                                      class="mat-icon-button"
                                      tabindex="-1"
                                    >
                                      <mat-icon
                                        matTooltip="{{
                                          'ORDERS.INTERNAL_VIEW_FALSE'
                                            | translate
                                        }}"
                                        [matTooltipPosition]="'right'"
                                        *ngIf="!material.internal_view_only"
                                        class="s-16"
                                        >{{ getIcon("VISIBLILITY") }}
                                      </mat-icon>
                                      <mat-icon
                                        matTooltip="{{
                                          'ORDERS.INTERNAL_VIEW_TRUE'
                                            | translate
                                        }}"
                                        [matTooltipPosition]="'right'"
                                        *ngIf="material.internal_view_only"
                                        class="s-16"
                                        >{{ getIcon("VISIBLILITY_OFF") }}
                                      </mat-icon>
                                    </button>

                                    <button
                                      mat-icon-button
                                      [disabled]="!editable || loading"
                                      matTooltip=""
                                      (click)="onToggleItemMoney(material)"
                                      class="mat-icon-button"
                                      tabindex="-1"
                                    >
                                      <mat-icon
                                        matTooltip="{{
                                          'ORDERS.SHOW_UNIT_PRICE_FALSE'
                                            | translate
                                        }}"
                                        [matTooltipPosition]="'right'"
                                        *ngIf="!material.show_unit_price"
                                        class="s-16"
                                      >
                                        {{ getIcon("MONEY_OFF") }}</mat-icon
                                      >
                                      <mat-icon
                                        matTooltip="{{
                                          'ORDERS.SHOW_UNIT_PRICE_TRUE'
                                            | translate
                                        }}"
                                        [matTooltipPosition]="'right'"
                                        *ngIf="material.show_unit_price"
                                        class="s-16"
                                        >{{ getIcon("ATTACH_MONEY") }}</mat-icon
                                      >
                                    </button>
                                  </div>

                                  <div
                                    fxFlex="13"
                                    fxLayout="row"
                                    *ngxPermissionsOnly="
                                      rolesService.TEAM_LEAD_ROLES
                                    "
                                    fxLayoutAlign="center center"
                                    class="section-list-item section-list-item-border-right"
                                  >
                                    <div
                                      *ngIf="!editMode"
                                      fxFlex="100"
                                      fxLayout="row"
                                      fxLayoutAlign="center center"
                                    >
                                      {{ material.qty }}
                                    </div>
                                    <div
                                      *ngIf="editMode"
                                      fxFlex="100"
                                      fxLayout="row"
                                      fxLayoutAlign="center center"
                                    >
                                      <input
                                        matInput
                                        class="text-center no-spinners color-qty-input"
                                        type="number"
                                        fxFlex="76px"
                                        pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                                        [ngModel]="material.qty"
                                        [disabled]="!editable"
                                        autocomplete="off"
                                        (ngModelChange)="
                                          onMaterialQtyChange(material)
                                        "
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div
                                    fxFlex="13"
                                    fxLayout="row"
                                    *ngxPermissionsExcept="
                                      rolesService.TEAM_LEAD_ROLES
                                    "
                                    fxLayoutAlign="center center"
                                    class="section-list-item section-list-item-border-right"
                                  >
                                    {{ material.qty }}
                                  </div>

                                  <div
                                    fxFlex="12"
                                    (dblclick)="
                                      onEditMaterial(
                                        material,
                                        material_category
                                      )
                                    "
                                    [class.internal_view_only]="
                                      !material.show_unit_price
                                    "
                                    class="section-list-item section-list-item-border-right"
                                  >
                                    {{ material.unit_price_label }}
                                  </div>

                                  <div
                                    fxFlex="13"
                                    (dblclick)="
                                      onEditMaterial(
                                        material,
                                        material_category
                                      )
                                    "
                                    class="section-list-item section-list-item-border-right"
                                  >
                                    {{ material.sub_total_item_price_label }}
                                  </div>

                                  <div fxFlex="230px" class="section-list-item">
                                    <button
                                      class=""
                                      mat-icon-button
                                      [disabled]="!isCTEditable"
                                      *ngxPermissionsOnly="
                                        rolesService.TEAM_LEAD_ROLES
                                      "
                                      (click)="
                                        onEditMaterial(
                                          material,
                                          material_category
                                        )
                                      "
                                      tabindex="-1"
                                    >
                                      <mat-icon class="s-16">{{
                                        getIcon("EDIT")
                                      }}</mat-icon>
                                    </button>
                                    <button
                                      class=""
                                      mat-icon-button
                                      *ngxPermissionsOnly="
                                        rolesService.TEAM_LEAD_ROLES
                                      "
                                      [disabled]="!editable || loading"
                                      (click)="onDeleteMaterial(material)"
                                      tabindex="-1"
                                    >
                                      <mat-icon class="s-16">{{
                                        getIcon("DELETE")
                                      }}</mat-icon>
                                    </button>
                                    <button
                                      mat-icon-button
                                      [disabled]="!editable || loading"
                                      *ngxPermissionsOnly="
                                        rolesService.TEAM_LEAD_ROLES
                                      "
                                      matTooltip="{{
                                        'GENERIC.OPEN_NEW_TAB' | translate
                                      }}"
                                      (click)="onOpenProductInNewTab(material)"
                                      class="mat-icon-button"
                                      tabindex="-1"
                                    >
                                      <mat-icon class="s-16"
                                        >{{ getIcon("NEW_TAB") }}
                                      </mat-icon>
                                    </button>

                                    <button
                                      mat-icon-button
                                      [disabled]="!editable || loading"
                                      *ngxPermissionsOnly="
                                        rolesService.TEAM_LEAD_ROLES
                                      "
                                      matTooltip="{{
                                        'GENERIC.OPEN_SAME_TAB' | translate
                                      }}"
                                      (click)="onOpenObjectPage(material)"
                                      class="mat-icon-button"
                                      tabindex="-1"
                                    >
                                      <mat-icon
                                        fontSet="fa"
                                        fontIcon="{{ getIcon('SAME_TAB') }}"
                                        class=""
                                      >
                                      </mat-icon>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div
                                *ngIf="
                                  material.comboToggleOpen &&
                                  material.combo &&
                                  material.combo.length
                                "
                                fxLayout="column"
                                fxLayoutAlign="0 1 auto"
                                class="combo-container"
                              >
                                <div
                                  *ngFor="let comboItem of material.combo"
                                  fxFlex="100"
                                >
                                  <div
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                    fxFlex="100"
                                    class="product-child-item"
                                  >
                                    <div
                                      fxFlex
                                      fxLayout="row"
                                      fxLayoutAlign="start center"
                                      class="pl-60 py-4 section-list-item-border-right"
                                    >
                                      <div
                                        [class.internal_view_only]="
                                          comboItem.internal_view_only
                                        "
                                        matTooltip="{{ comboItem.name }}"
                                        [matTooltipPosition]="'right'"
                                      >
                                        {{ comboItem.name_internal }}
                                      </div>
                                    </div>

                                    <div fxFlex="8" class="section-list-item">
                                      <button
                                        mat-icon-button
                                        [disabled]="!editable || loading"
                                        matTooltip=""
                                        (click)="
                                          onToggleComboItemVisibility(
                                            comboItem,
                                            material
                                          )
                                        "
                                        class="mat-icon-button"
                                        tabindex="-1"
                                      >
                                        <mat-icon
                                          matTooltip="{{
                                            'ORDERS.INTERNAL_VIEW_TRUE'
                                              | translate
                                          }}"
                                          [matTooltipPosition]="'right'"
                                          *ngIf="comboItem.internal_view_only"
                                          class="s-16"
                                          >{{
                                            getIcon("VISIBLILITY_OFF")
                                          }}</mat-icon
                                        >
                                        <mat-icon
                                          matTooltip="{{
                                            'ORDERS.INTERNAL_VIEW_FALSE'
                                              | translate
                                          }}"
                                          [matTooltipPosition]="'right'"
                                          *ngIf="!comboItem.internal_view_only"
                                          class="s-16"
                                          >{{ getIcon("VISIBLILITY") }}
                                        </mat-icon>
                                      </button>

                                      <button
                                        mat-icon-button
                                        [disabled]="!editable || loading"
                                        matTooltip=""
                                        (click)="
                                          onToggleComboItemMoney(
                                            comboItem,
                                            material
                                          )
                                        "
                                        class="mat-icon-button"
                                        tabindex="-1"
                                      >
                                        <mat-icon
                                          matTooltip="{{
                                            'ORDERS.SHOW_UNIT_PRICE_TRUE'
                                              | translate
                                          }}"
                                          [matTooltipPosition]="'right'"
                                          *ngIf="comboItem.show_unit_price"
                                          class="s-16"
                                        >
                                          {{ getIcon("ATTACH_MONEY") }}
                                        </mat-icon>
                                        <mat-icon
                                          matTooltip="{{
                                            'ORDERS.SHOW_UNIT_PRICE_FALSE'
                                              | translate
                                          }}"
                                          [matTooltipPosition]="'right'"
                                          *ngIf="!comboItem.show_unit_price"
                                          class="s-16"
                                        >
                                          {{ getIcon("MONEY_OFF") }}
                                        </mat-icon>
                                      </button>
                                    </div>

                                    <div
                                      fxFlex="13"
                                      matTooltip="{{ comboItem.qty }} par combo"
                                      class="section-list-item section-list-item-border"
                                    >
                                      {{ comboItem.qty * material.qty }}
                                    </div>

                                    <div
                                      fxFlex="12"
                                      class="section-list-item section-list-item-border"
                                    >
                                      <div
                                        [class.item_show_no_unit_price]="
                                          !comboItem.show_unit_price
                                        "
                                      >
                                        {{ comboItem.price_label }}
                                      </div>
                                    </div>

                                    <div
                                      fxFlex="13"
                                      class="section-list-item section-list-item-border-right"
                                    ></div>

                                    <div
                                      fxFlex="230px"
                                      class="section-list-item section-list-item-border-right"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    }
                    <div tabindex="0">&nbsp;</div>
                  </div>
                </div>

                <div *ngIf="dndLoading" class="product-list-spinner-container">
                  <!-- <mat-spinner [diameter]="28" class="{{ (productsExpand)? 'product-list-spinner-expand' : 'product-list-spinner'}}"></mat-spinner> -->
                  <mat-spinner
                    [diameter]="28"
                    class="product-list-spinner"
                  ></mat-spinner>
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="end center"
                class="px-12 sub-total"
              >
                <div
                  *ngIf="
                    order.material_subtotal_raw !== 0 && order.guest_count !== 0
                  "
                  class="py-4"
                >
                  {{ "ORDERS.SUB_TOTAL_PER_PERSON" | translate }}
                  <span class="total-number"
                    >${{
                      order.material_subtotal_raw / order.guest_count
                        | numberNotInfinityPipe
                        | number: "1.2-2"
                    }}</span
                  >
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {{ "ORDERS.SUB_TOTAL_MATERIALS" | translate }}
                  <span class="total-number">{{
                    order.material_subtotal
                  }}</span>
                </div>
                <div
                  *ngIf="
                    order.product_subtotal_raw === 0 || order.guest_count === 0
                  "
                  class="py-4"
                >
                  {{ "ORDERS.SUB_TOTAL_PER_PERSON" | translate }}
                  <span class="total-number">$0.00</span>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {{ "ORDERS.SUB_TOTAL_MATERIALS" | translate }}
                  <span class="total-number">{{
                    order.material_subtotal
                  }}</span>
                </div>
              </div>
            </mat-tab>

            <!-- ORDERS STAFF TAB -->
            <mat-tab label="{{ 'ORDERS.STAFF' | translate }}">
              <div class="" fxLayout="row" fxFlex="100">
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="100"
                  class="section-list-header"
                >
                  <div fxFlex class="section-list-item-border-right">
                    <div class="p-4 pl-12">
                      {{ "GENERIC.NAME" | translate }}
                    </div>
                  </div>

                  <div
                    fxFlex="8"
                    class="section-list-item section-list-item-border-right"
                  >
                    <div class="p-4">{{ "GENERIC.FROM" | translate }}</div>
                  </div>

                  <div
                    fxFlex="8"
                    class="section-list-item section-list-item-border-right"
                  >
                    <div class="p-4">{{ "GENERIC.TO" | translate }}</div>
                  </div>
                  <div
                    fxFlex="10"
                    class="section-list-item section-list-item-border-right"
                  >
                    <div class="p-4">{{ "GENERIC.DURATION" | translate }}</div>
                  </div>

                  <div
                    fxFlex="12"
                    class="section-list-item section-list-item-border-right"
                  >
                    <div class="p-4">{{ "GENERIC.QUANTITY" | translate }}</div>
                  </div>

                  <div
                    fxFlex="12"
                    class="section-list-item section-list-item-border-right"
                  >
                    <div class="p-4">{{ "GENERIC.PRICE" | translate }}</div>
                  </div>

                  <div
                    fxFlex="10"
                    class="section-list-item section-list-item-border-right"
                  >
                    <div class="p-4">{{ "GENERIC.SUBTOTAL" | translate }}</div>
                  </div>

                  <div
                    fxFlex="120px"
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="end start"
                  >
                    <div
                      *ngxPermissionsOnly="rolesService.SALE_ROLES"
                      fxLayout="row"
                    >
                      <!-- TODO: mettre un Tooltip sur ce bouton synch staff, to uncomment-->
                      <div
                        *ngIf="editable"
                        class=""
                        fxLayout="row"
                        style="
                          width: 58px;
                          height: 42px;
                          margin-right: 2px;
                          background: #039be5;
                          justify-content: center;
                        "
                      >
                        <button
                          type="button"
                          mat-icon-button
                          class="sync-staff-button"
                          (click)="synchOrderStaffsFromAssigments()"
                        >
                          <mat-icon class="icon-mat" color="">cached</mat-icon>
                        </button>
                      </div>
                      <div
                        *ngIf="editable"
                        class="gt2-light-green"
                        fxLayout="row"
                        style="width: 42px; height: 42px"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{ 'ORDERS.ADD_STAFF' | translate }}"
                          mat-icon-button
                          class="add-product"
                          (click)="onAddStaff()"
                        >
                          <mat-icon class="icon-mat-black" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div
                  *ngIf="!order || !order.staff || order.staff.length === 0"
                  fxFlex="100"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <div class="font-size-20 my-16">
                    {{ "ORDERS.NO_STAFF" | translate }}
                  </div>
                </div>

                <div
                  *ngIf="loading"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  style="height: 100%; width: 100%"
                >
                  <mat-spinner [diameter]="40" class="my-40"></mat-spinner>
                </div>

                <div
                  *ngIf="
                    !loading && order && order.staff && order.staff.length > 0
                  "
                  fxFlex="100"
                >
                  <div
                    *ngFor="let item of order.staff"
                    fxLayout="row"
                    fxLayout="start center"
                    class="child-item"
                  >
                    <div
                      fxFlex
                      fxLayout="row"
                      fxLayout="start center"
                      class="section-list-item-border-right pl-8 py-8"
                    >
                      {{ item.name }}
                    </div>
                    <div
                      fxFlex="8"
                      fxLayout="column"
                      fxLayoutAlign="space-between center"
                      class="section-list-item section-list-item-border-right py-8"
                    >
                      <div>{{ item.from_time_label }}</div>
                    </div>

                    <div
                      fxLayout="column"
                      fxFlex="8"
                      class="section-list-item section-list-item-border-right py-8"
                    >
                      <div>{{ item.to_time_label }}</div>
                    </div>

                    <div
                      fxFlex="10"
                      class="section-list-item section-list-item-border-right py-8"
                    >
                      {{ item.duration }}
                    </div>

                    <div
                      *ngxPermissionsOnly="rolesService.SALE_ROLES"
                      fxFlex="12"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      class="section-list-item section-list-item-border-right"
                    >
                      <div *ngIf="!editMode">{{ item.qty }}</div>
                      <input
                        *ngIf="editMode"
                        matInput
                        class="text-center no-spinners color-qty-input"
                        type="number"
                        fxFlex="76px"
                        pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                        [ngModel]="item.qty"
                        [disabled]="!editable"
                        autocomplete="off"
                        (ngModelChange)="onLabourChargeQtyChange(item)"
                        required
                      />
                    </div>
                    <div
                      *ngxPermissionsExcept="rolesService.SALE_ROLES"
                      fxFlex="12"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      class="section-list-item section-list-item-border-right"
                    >
                      {{ item.qty }}
                    </div>

                    <div
                      fxFlex="12"
                      class="section-list-item section-list-item-border-right py-8"
                    >
                      {{ item.unit_price_label }}
                    </div>

                    <div
                      fxFlex="10"
                      class="section-list-item section-list-item-border-right py-8"
                    >
                      {{ item.sub_total_item_price_label }}
                    </div>

                    <div fxFlex="120px" class="section-list-item">
                      <button
                        class=""
                        mat-icon-button
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        [disabled]="!editable"
                        tabIndex="-1"
                        (click)="onEditLabourCharge(item)"
                      >
                        <mat-icon class="s-16">{{ getIcon("EDIT") }}</mat-icon>
                      </button>
                      <button
                        class=""
                        mat-icon-button
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        tabIndex="-1"
                        [disabled]="!editable || loading"
                        (click)="onDeleteLabourCharge(item)"
                      >
                        <mat-icon class="s-16">{{
                          getIcon("DELETE")
                        }}</mat-icon>
                      </button>

                      <button
                        *ngIf="item.note"
                        class=""
                        mat-icon-button
                        [disabled]="false"
                      >
                        <mat-icon
                          matTooltip="{{
                            item.note.substring(0, noteHintMaxChar)
                          }} {{
                            item.note.length > noteHintMaxChar ? '...' : ''
                          }}"
                          placement="top"
                          class="s-16 icon-cursor"
                        >
                          {{ getIcon("INFO") }}
                        </mat-icon>
                      </button>

                      <button
                        *ngIf="!item.note"
                        class=""
                        mat-icon-button
                        [disabled]="true"
                      >
                        <mat-icon
                          *ngIf="!item.note"
                          class="s-16 note-icon-btn-disabled"
                        >
                          {{ getIcon("INFO") }}
                        </mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="end center"
                class="px-12 sub-total"
              >
                <div
                  *ngIf="
                    order.staff_subtotal_raw !== 0 && order.guest_count !== 0
                  "
                  class="py-4"
                >
                  {{ "ORDERS.SUB_TOTAL_PER_PERSON" | translate }}
                  <span class="total-number"
                    >${{
                      order.staff_subtotal_raw / order.guest_count
                        | numberNotInfinityPipe
                        | number: "1.2-2"
                    }}</span
                  >
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {{ "ORDERS.SUB_TOTAL_STAFFS" | translate }}
                  <span class="total-number">{{ order.staff_subtotal }}</span>
                </div>
                <div
                  *ngIf="
                    order.product_subtotal_raw === 0 || order.guest_count === 0
                  "
                  class="py-4"
                >
                  {{ "ORDERS.SUB_TOTAL_PER_PERSON" | translate }}
                  <span class="total-number">$0.00</span>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {{ "ORDERS.SUB_TOTAL_STAFFS" | translate }}
                  <span class="total-number">{{ order.staff_subtotal }}</span>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="{{ seviceChargeLabel }}">
              <div fxFlex fxLayout="column">
                <!-- Service charge normal / prix fixe -->
                <div class="" fxLayout="column" fxFlex="100">
                  <div
                    class=""
                    fxLayout="row"
                    fxFlex="100"
                    *ngIf="
                      !loading &&
                      order &&
                      ((order.service_charge_normal &&
                        order.service_charge_normal.length > 0) ||
                        (order.service_charges_multiply_date_range &&
                          order.service_charges_multiply_date_range.length ===
                            0 &&
                          order.service_charges_percent_invoice_cat &&
                          order.service_charges_percent_invoice_cat.length ===
                            0))
                    "
                  >
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxFlex="100"
                      class="section-list-header"
                      style="background-color: #fafafa"
                    >
                      <div fxFlex class="section-list-item-border-right">
                        <div class="p-4 pl-12">
                          {{ "ORDERS.SC_FIX_PRICE" | translate }}
                        </div>
                      </div>

                      <div
                        fxFlex="9"
                        class="section-list-item section-list-item-border-right"
                      >
                        <div class="p-4">
                          {{ "GENERIC.QUANTITY" | translate }}
                        </div>
                      </div>

                      <div
                        fxFlex="11"
                        class="section-list-item section-list-item-border-right"
                      >
                        <div class="p-4">{{ "GENERIC.RATE" | translate }}</div>
                      </div>
                      <div
                        fxFlex="12"
                        class="section-list-item section-list-item-border-right"
                      >
                        <div class="p-4">
                          {{ "ORDERS.PRICE_SUBTOTAL" | translate }}
                        </div>
                      </div>

                      <div
                        fxFlex="15"
                        class=""
                        fxLayout="row"
                        fxLayoutAlign="end start"
                      >
                        <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
                          <div
                            *ngIf="editable"
                            class="gt2-light-green"
                            style="width: 42px; height: 42px"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                          >
                            <button
                              type="button"
                              matTooltip="{{
                                'ORDERS.ADD_SERVICE_CHARGE' | translate
                              }}"
                              mat-icon-button
                              class="add-product"
                              (click)="onAddServiceCharge()"
                            >
                              <mat-icon class="icon-mat-black" color="">{{
                                getIcon("ADD")
                              }}</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      !loading &&
                      order &&
                      order.service_charge_normal &&
                      order.service_charge_normal.length > 0
                    "
                  >
                    <div fxFlex="100">
                      <div
                        *ngFor="let item of order.service_charge_normal"
                        fxLayout="row"
                        fxLayout="start center"
                        class="child-item"
                      >
                        <div
                          fxFlex
                          fxLayout="row"
                          fxLayout="start center"
                          class="section-list-item-border-right pl-8 py-8"
                        >
                          {{ item.name }}
                        </div>

                        <div
                          *ngxPermissionsOnly="rolesService.SALE_ROLES"
                          fxFlex="9"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                          class="section-list-item section-list-item-border-right"
                        >
                          <div *ngIf="!editMode">{{ item.qty }}</div>

                          <input
                            *ngIf="editMode"
                            matInput
                            class="text-center no-spinners color-qty-input"
                            type="number"
                            fxFlex="76px"
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            [ngModel]="item.qty"
                            [disabled]="!editable"
                            autocomplete="off"
                            (ngModelChange)="onServiceChargeQtyChange(item)"
                          />
                        </div>
                        <div
                          *ngxPermissionsExcept="rolesService.SALE_ROLES"
                          fxFlex="9"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                          class="section-list-item section-list-item-border-right"
                        >
                          <div>{{ item.qty }}</div>
                        </div>

                        <div
                          fxFlex="11"
                          class="section-list-item section-list-item-border-right py-8"
                        >
                          {{ item.unit_price_label }}
                        </div>

                        <div
                          fxFlex="12"
                          class="section-list-item section-list-item-border-right py-8"
                        >
                          {{ item.sub_total_item_price_label }}
                        </div>

                        <div fxFlex="15" class="section-list-item">
                          <button
                            class=""
                            mat-icon-button
                            *ngxPermissionsOnly="rolesService.SALE_ROLES"
                            [disabled]="!editable"
                            tabIndex="-1"
                            (click)="onEditServiceCharge(item)"
                          >
                            <mat-icon class="s-16">{{
                              getIcon("EDIT")
                            }}</mat-icon>
                          </button>
                          <button
                            class=""
                            mat-icon-button
                            *ngxPermissionsOnly="rolesService.SALE_ROLES"
                            tabIndex="-1"
                            [disabled]="!editable || loading"
                            (click)="onDeleteServiceCharge(item)"
                          >
                            <mat-icon class="s-16">{{
                              getIcon("DELETE")
                            }}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Service charge percent -->
                <div
                  class=""
                  fxLayout="column"
                  fxFlex="100"
                  *ngIf="
                    !loading &&
                    order &&
                    order.service_charges_percent_invoice_cat &&
                    order.service_charges_percent_invoice_cat.length > 0
                  "
                >
                  <div class="" fxLayout="row" fxFlex="100">
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxFlex="100"
                      class="section-list-header"
                      style="background-color: #fafafa; height: 42px"
                    >
                      <div fxFlex class="section-list-item-border-right">
                        <div class="p-4 pl-12">
                          {{ "GENERIC.PERCENTAGE" | translate }}
                        </div>
                      </div>

                      <div
                        fxFlex="9"
                        class="section-list-item section-list-item-border-right"
                      >
                        <div class="p-4">
                          {{ "GENERIC.QUANTITY" | translate }}
                        </div>
                      </div>

                      <div
                        fxFlex="11"
                        class="section-list-item section-list-item-border-right"
                      >
                        <div class="p-4">{{ "GENERIC.RATE" | translate }}</div>
                      </div>
                      <div
                        fxFlex="12"
                        class="section-list-item section-list-item-border-right"
                      >
                        <div class="p-4">
                          {{ "ORDERS.PRICE_SUBTOTAL" | translate }}
                        </div>
                      </div>

                      <div
                        fxFlex="15"
                        class=""
                        fxLayout="row"
                        fxLayoutAlign="end start"
                      >
                        <div
                          *ngIf="
                            editable &&
                            order.service_charge_normal &&
                            order.service_charge_normal.length === 0
                          "
                          class="gt2-light-green"
                          style="width: 42px; height: 42px"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                        >
                          <button
                            type="button"
                            matTooltip="{{
                              'ORDERS.ADD_SERVICE_CHARGE' | translate
                            }}"
                            mat-icon-button
                            class="add-product"
                            (click)="onAddServiceCharge()"
                          >
                            <mat-icon class="icon-mat-black" color="">{{
                              getIcon("ADD")
                            }}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div fxFlex="100">
                      <div
                        *ngFor="
                          let item of order.service_charges_percent_invoice_cat
                        "
                        fxLayout="row"
                        fxLayout="start center"
                        class="child-item"
                      >
                        <div
                          fxFlex
                          fxLayout="row"
                          fxLayout="start center"
                          class="section-list-item-border-right pl-8 py-8"
                        >
                          {{ item.name }}
                        </div>

                        <div
                          *ngxPermissionsOnly="rolesService.SALE_ROLES"
                          fxFlex="9"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                          class="section-list-item section-list-item-border-right"
                        >
                          <div *ngIf="!editMode">{{ item.qty }}</div>
                          <input
                            *ngIf="editMode"
                            matInput
                            class="text-center no-spinners color-qty-input"
                            type="number"
                            fxFlex="76px"
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            [ngModel]="item.qty"
                            [disabled]="!editable"
                            autocomplete="off"
                            (ngModelChange)="onServiceChargeQtyChange(item)"
                          />
                        </div>

                        <div
                          *ngxPermissionsExcept="rolesService.SALE_ROLES"
                          fxFlex="9"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                          class="section-list-item section-list-item-border-right"
                        >
                          <div>{{ item.qty }}</div>
                        </div>

                        <div
                          fxFlex="11"
                          class="section-list-item section-list-item-border-right py-8"
                        >
                          {{ item.unit_price_label }}
                        </div>

                        <div
                          fxFlex="12"
                          class="section-list-item section-list-item-border-right py-8"
                        >
                          {{ item.sub_total_item_price_label }}
                        </div>

                        <div fxFlex="15" class="section-list-item">
                          <button
                            class=""
                            mat-icon-button
                            *ngxPermissionsOnly="rolesService.SALE_ROLES"
                            [disabled]="!editable"
                            tabIndex="-1"
                            (click)="onEditServiceCharge(item)"
                          >
                            <mat-icon class="s-16">{{
                              getIcon("EDIT")
                            }}</mat-icon>
                          </button>
                          <button
                            class=""
                            mat-icon-button
                            *ngxPermissionsOnly="rolesService.SALE_ROLES"
                            tabIndex="-1"
                            [disabled]="!editable || loading"
                            (click)="onDeleteServiceCharge(item)"
                          >
                            <mat-icon class="s-16">{{
                              getIcon("DELETE")
                            }}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Service charge nuite -->
                <div
                  class=""
                  fxLayout="column"
                  fxFlex="100"
                  *ngIf="
                    !loading &&
                    order &&
                    order.service_charges_multiply_date_range &&
                    order.service_charges_multiply_date_range.length > 0
                  "
                >
                  <div class="" fxLayout="row" fxFlex="100">
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxFlex="100"
                      class="section-list-header"
                      style="background-color: #fafafa; height: 42px"
                    >
                      <div fxFlex class="section-list-item-border-right">
                        <div class="p-4 pl-12">
                          {{ "ORDERS.SC_NIGHT" | translate }}
                        </div>
                      </div>

                      <div
                        fxFlex="9"
                        class="section-list-item section-list-item-border-right"
                      >
                        <div class="p-4">{{ "GENERIC.FROM" | translate }}</div>
                      </div>

                      <div
                        fxFlex="9"
                        class="section-list-item section-list-item-border-right"
                      >
                        <div class="p-4">{{ "GENERIC.TO" | translate }}</div>
                      </div>
                      <div
                        fxFlex="6"
                        class="section-list-item section-list-item-border-right"
                      >
                        <div class="p-4">{{ "GENERIC.NIGHT" | translate }}</div>
                      </div>

                      <div
                        fxFlex="9"
                        class="section-list-item section-list-item-border-right"
                      >
                        <div class="p-4">
                          {{ "GENERIC.QUANTITY" | translate }}
                        </div>
                      </div>

                      <div
                        fxFlex="11"
                        class="section-list-item section-list-item-border-right"
                      >
                        <div class="p-4">{{ "GENERIC.RATE" | translate }}</div>
                      </div>
                      <div
                        fxFlex="12"
                        class="section-list-item section-list-item-border-right"
                      >
                        <div class="p-4">
                          {{ "ORDERS.PRICE_SUBTOTAL" | translate }}
                        </div>
                      </div>

                      <div
                        fxFlex="15"
                        class=""
                        fxLayout="row"
                        fxLayoutAlign="end start"
                      >
                        <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
                          <div
                            *ngIf="
                              editable &&
                              order.service_charge_normal &&
                              order.service_charge_normal.length === 0 &&
                              order.service_charges_percent_invoice_cat &&
                              order.service_charges_percent_invoice_cat
                                .length === 0
                            "
                            class="gt2-light-green"
                            style="width: 42px; height: 42px"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                          >
                            <button
                              type="button"
                              matTooltip="{{
                                'ORDERS.ADD_SERVICE_CHARGE' | translate
                              }}"
                              mat-icon-button
                              class="add-product"
                              (click)="onAddServiceCharge()"
                            >
                              <mat-icon class="icon-mat-black" color="">{{
                                getIcon("ADD")
                              }}</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div fxFlex="100">
                      <div
                        *ngFor="
                          let item of order.service_charges_multiply_date_range
                        "
                        fxLayout="row"
                        fxLayout="start center"
                        class="child-item"
                      >
                        <div
                          fxFlex
                          fxLayout="row"
                          fxLayout="start center"
                          class="section-list-item-border-right pl-8 py-8"
                        >
                          {{ item.name }}
                        </div>

                        <div
                          fxFlex="9"
                          class="section-list-item section-list-item-border-right py-8"
                        >
                          {{ item.multiply_date_label }}
                        </div>

                        <div
                          fxFlex="9"
                          class="section-list-item section-list-item-border-right py-8"
                        >
                          {{ item.multiply_date_end_label }}
                        </div>

                        <div
                          fxFlex="6"
                          class="section-list-item section-list-item-border-right py-8"
                        >
                          <div
                            *ngIf="
                              item.multiply_enum === 'MULTIPLY_BY_DATE_RANGE'
                            "
                          >
                            {{ item.multiply_by }}
                          </div>
                        </div>

                        <div
                          *ngxPermissionsOnly="rolesService.SALE_ROLES"
                          fxFlex="9"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                          class="section-list-item section-list-item-border-right"
                        >
                          <div *ngIf="!editMode">{{ item.qty }}</div>
                          <input
                            *ngIf="editMode"
                            matInput
                            class="text-center no-spinners color-qty-input"
                            type="number"
                            fxFlex="76px"
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            [ngModel]="item.qty"
                            [disabled]="!editable"
                            autocomplete="off"
                            (ngModelChange)="onServiceChargeQtyChange(item)"
                          />
                        </div>
                        <div
                          *ngxPermissionsExcept="rolesService.SALE_ROLES"
                          fxFlex="9"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                          class="section-list-item section-list-item-border-right"
                        >
                          <div>{{ item.qty }}</div>
                        </div>

                        <div
                          fxFlex="11"
                          class="section-list-item section-list-item-border-right py-8"
                        >
                          {{ item.unit_price_label }}
                        </div>

                        <div
                          fxFlex="12"
                          class="section-list-item section-list-item-border-right py-8"
                        >
                          {{ item.sub_total_item_price_label }}
                        </div>

                        <div fxFlex="15" class="section-list-item">
                          <button
                            class=""
                            mat-icon-button
                            *ngxPermissionsOnly="rolesService.SALE_ROLES"
                            tabIndex="-1"
                            [disabled]="!editable"
                            (click)="onEditServiceCharge(item)"
                          >
                            <mat-icon class="s-16">{{
                              getIcon("EDIT")
                            }}</mat-icon>
                          </button>
                          <button
                            class=""
                            mat-icon-button
                            *ngxPermissionsOnly="rolesService.SALE_ROLES"
                            tabIndex="-1"
                            [disabled]="!editable || loading"
                            (click)="onDeleteServiceCharge(item)"
                          >
                            <mat-icon class="s-16">{{
                              getIcon("DELETE")
                            }}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- NO SERVICE CHARGE -->
                <div
                  *ngIf="
                    !order ||
                    ((!order.service_charge_normal ||
                      order.service_charge_normal.length === 0) &&
                      (!order.service_charges_multiply_date_range ||
                        order.service_charges_multiply_date_range.length ===
                          0) &&
                      (!order.service_charges_percent_invoice_cat ||
                        order.service_charges_percent_invoice_cat.length === 0))
                  "
                  fxFlex="100"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <div class="font-size-20 my-16">
                    {{ "ORDERS.NO_SERVICE_CHARGE" | translate }}
                  </div>
                </div>

                <!-- SPINNER -->
                <div
                  *ngIf="loading"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  style="height: 100%; width: 100%"
                >
                  <mat-spinner [diameter]="40" class="my-40"></mat-spinner>
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="end center"
                class="px-12 sub-total"
              >
                <div
                  *ngIf="
                    order.service_charge_subtotal_raw !== 0 &&
                    order.guest_count !== 0
                  "
                  class="py-4"
                >
                  {{ "ORDERS.SUB_TOTAL_PER_PERSON" | translate }}
                  <span class="total-number"
                    >${{
                      order.service_charge_subtotal_raw / order.guest_count
                        | numberNotInfinityPipe
                        | number: "1.2-2"
                    }}</span
                  >
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {{ "ORDERS.SUB_TOTAL_SERVICE_CHARGE" | translate }}
                  <span class="total-number">{{
                    order.service_charge_subtotal
                  }}</span>
                </div>
                <div
                  *ngIf="
                    order.product_subtotal_raw === 0 || order.guest_count === 0
                  "
                  class="py-4"
                >
                  {{ "ORDERS.SUB_TOTAL_PER_PERSON" | translate }}
                  <span class="total-number">$0.00</span>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {{ "ORDERS.SUB_TOTAL_SERVICE_CHARGE" | translate }}
                  <span class="total-number">{{
                    order.service_charge_subtotal
                  }}</span>
                </div>
              </div>
            </mat-tab>

            <mat-tab
              *ngIf="order && order.type !== 'INVOICE'"
              label="{{ 'ORDERS.SPECIFICS_CONDITIONS' | translate }}"
            >
              <div class="" fxLayout="row" fxFlex="100">
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxFlex="100"
                  class="section-list-header"
                >
                  <div fxFlex="85" class="section-list-item-border-right">
                    <div class="p-4 pl-12">
                      {{ "GENERIC.NAME" | translate }}
                    </div>
                  </div>

                  <div
                    fxFlex="15"
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="end start"
                  >
                    <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
                      <div
                        *ngIf="editable"
                        class="gt2-light-green"
                        style="width: 42px; height: 42px"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <button
                          type="button"
                          matTooltip="{{
                            'ORDERS.ADD_SPECIFIC_CONDITION' | translate
                          }}"
                          mat-icon-button
                          class="add-product"
                          (click)="onAddSpecificCondition()"
                        >
                          <mat-icon class="icon-mat-black" color="">{{
                            getIcon("ADD")
                          }}</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div
                  *ngIf="
                    !order ||
                    !order.specific_condition ||
                    order.specific_condition.length === 0
                  "
                  fxFlex="100"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <div class="font-size-20 my-16">
                    {{ "ORDERS.NO_SPECIFIC_CONDITION" | translate }}
                  </div>
                </div>

                <div
                  *ngIf="loading"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  style="height: 100%; width: 100%"
                >
                  <mat-spinner [diameter]="40" class="my-40"></mat-spinner>
                </div>

                <div
                  *ngIf="
                    order &&
                    !loading &&
                    order.specific_condition &&
                    order.specific_condition.length > 0
                  "
                  fxFlex="100"
                >
                  <div
                    *ngFor="let item of order.specific_condition"
                    fxLayout="row"
                    fxLayout="start center"
                    class="child-item"
                  >
                    <div
                      fxFlex="85"
                      fxLayout="row"
                      fxLayout="start center"
                      class="section-list-item-border-right pl-8 py-8"
                    >
                      {{ item.name }}
                    </div>

                    <div fxFlex="15" class="section-list-item">
                      <button
                        class=""
                        mat-icon-button
                        *ngxPermissionsOnly="rolesService.SALE_ROLES"
                        [disabled]="!editable || loading"
                        (click)="onDeleteSpecificCondition(item)"
                      >
                        <mat-icon class="s-16">{{
                          getIcon("DELETE")
                        }}</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="end center"
                class="px-12 sub-total"
              >
                <div class="py-4">&nbsp;<span class="total-number"></span></div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div
          *ngIf="order"
          class="profile-box info-box general"
          style="min-width: 1023px"
          fxLayout="column"
        >
          <div class="gt2-order-theme">
            <header [ngClass]="orderTypeHeaderColor || 'accent'" class="">
              <div class="title p-16 icon-mat">
                {{ "GENERIC.TOTAL" | translate }}
              </div>
            </header>
          </div>

          <div class="content-no-pad" fxLayout="row" fxFlex="100">
            <div
              *ngIf="
                moduleService.modulesAreSet && !moduleService.hasJLReport()
              "
              fxFlex="35"
              class="content-left"
              fxLayout="column"
              class="br"
            >
              &nbsp;
            </div>
            <div
              *ngIf="moduleService.modulesAreSet && moduleService.hasJLReport()"
              fxFlex="35"
              class="content-left"
              fxLayout="column"
              class="br"
            >
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                class="bb total-section-category"
              >
                <div fxLayout="row" class="mlr">Total</div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" class="row">
                <div fxLayout="row" fxFlex="40" class="bb br mlr">
                  {{ "GENERIC.BILLING" | translate }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb br mlr">
                  {{ "GENERIC.INCLUDED" | translate }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb mlr">
                  {{ "GENERIC.CHARGED" | translate }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" class="row">
                <div fxLayout="row" fxFlex="40" class="bb br mlr">
                  {{ "GENERIC.FOOD" | translate }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb br mlr">
                  {{ order.total_food_included }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb mlr">
                  {{ order.total_food_charged }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" class="row">
                <div fxLayout="row" fxFlex="40" class="bb br mlr">
                  {{ "GENERIC.DRINK" | translate }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb br mlr">
                  {{ order.total_drink_included }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb mlr">
                  {{ order.total_drink_charged }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" class="bold row">
                <div
                  fxLayout="row"
                  fxLayoutAlign="end start"
                  fxFlex="40"
                  class="bb br mlr"
                >
                  Total
                </div>
                <div fxLayout="row" fxFlex="30" class="bb br mlr row">
                  {{ order.total_included }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb mlr row">
                  {{ order.total_charged }}
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="start start" class="row">
                &nbsp;
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                class="bb bt total-section-category"
              >
                <div fxLayout="row" class="mlr">
                  {{ "GENERIC.PRICE_PER_GUEST" | translate }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" class="row">
                <div fxLayout="row" fxFlex="40" class="bb br mlr">
                  {{ "GENERIC.BILLING" | translate }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb br mlr">
                  {{ "GENERIC.INCLUDED" | translate }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb mlr">
                  {{ "GENERIC.CHARGED" | translate }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" class="row">
                <div fxLayout="row" fxFlex="40" class="bb br mlr">
                  {{ "GENERIC.FOOD" | translate }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb br mlr">
                  {{ order.per_person_food_included }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb mlr">
                  {{ order.per_person_food_charged }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" class="row">
                <div fxLayout="row" fxFlex="40" class="bb br mlr">
                  {{ "GENERIC.DRINK" | translate }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb br mlr">
                  {{ order.per_person_drink_included }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb mlr">
                  {{ order.per_person_drink_charged }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" class="bold row">
                <div
                  fxLayout="row"
                  fxLayoutAlign="end start"
                  fxFlex="40"
                  class="bb br mlr"
                >
                  Total
                </div>
                <div fxLayout="row" fxFlex="30" class="bb br mlr row">
                  {{ order.per_person_total_included }}
                </div>
                <div fxLayout="row" fxFlex="30" class="bb mlr row">
                  {{ order.per_person_total_charged }}
                </div>
              </div>
            </div>

            <div fxFlex="65">
              <div
                *ngFor="let cost of order.total_collection; let i = index"
                class="total-section"
              >
                <div *ngIf="order.is_ctm">
                  <div
                    fxFlex="60"
                    fxLayout="row"
                    class="{{
                      i !== order.total_collection.length - 1
                        ? 'total-section-lcol total-section-underline'
                        : 'total-section-lcol'
                    }}"
                  >
                    <div
                      fxFlex="100"
                      *ngIf="cost.isCategory"
                      class="total-section-category total-section-category-number"
                    >
                      <b>{{ cost.name }}</b>
                    </div>
                    <div fxFlex="100" *ngIf="!cost.isCategory" class="">
                      {{ cost.name }}
                    </div>
                  </div>
                  <div
                    fxFlex="40"
                    fxLayout="row"
                    class="{{
                      i !== order.total_collection.length - 1
                        ? 'total-section-rcol total-section-underline'
                        : 'total-section-rcol'
                    }}"
                  >
                    <div
                      fxFlex="100"
                      *ngIf="cost.isCategory"
                      class="total-section-category"
                    >
                      {{ cost.value }} &nbsp;
                    </div>
                    <div fxFlex="100" *ngIf="!cost.isCategory" class="">
                      {{ cost.value }} &nbsp;
                    </div>
                  </div>
                </div>
                <div *ngIf="!order.is_ctm && !cost.cashing">
                  <div
                    fxFlex="60"
                    fxLayout="row"
                    class="{{
                      i !== order.total_collection.length - 1
                        ? 'total-section-lcol total-section-underline'
                        : 'total-section-lcol'
                    }}"
                  >
                    <div
                      fxFlex="100"
                      *ngIf="cost.isCategory"
                      class="total-section-category total-section-category-number"
                    >
                      <b>{{ cost.name }}</b>
                    </div>
                    <div fxFlex="100" *ngIf="!cost.isCategory" class="">
                      {{ cost.name }}
                    </div>
                  </div>
                  <div
                    fxFlex="40"
                    fxLayout="row"
                    class="{{
                      i !== order.total_collection.length - 1
                        ? 'total-section-rcol total-section-underline'
                        : 'total-section-rcol'
                    }}"
                  >
                    <div
                      fxFlex="100"
                      *ngIf="cost.isCategory"
                      class="total-section-category"
                    >
                      {{ cost.value }} &nbsp;
                    </div>
                    <div fxFlex="100" *ngIf="!cost.isCategory" class="">
                      {{ cost.value }} &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="order && order.type !== 'INVOICE'"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <div class="gt2-order-theme" style="display: contents">
            <header [ngClass]="orderTypeHeaderColor || 'accent'" class="">
              <div class="title p-16 icon-mat">
                {{ "GENERIC.NOTES" | translate }}
              </div>
            </header>
          </div>
          <mat-tab-group
            tabIndex="-1"
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
          >
            <mat-tab tabIndex="-1" label="{{ 'GENERIC.GENERAL' | translate }}">
              <div class="note-section" fxLayout="column">
                <p-editor
                  #noteEditor
                  [(ngModel)]="order.note"
                  [readonly]="!editMode"
                  [style]="{
                    height: '120px',
                    width: '100%',
                    'background-color': editMode ? '#ffffff' : '',
                  }"
                >
                  <div class="">
                    <span class="ql-formats">
                      <select class="ql-font"></select>
                      <select class="ql-size"></select>
                    </span>

                    <span class="ql-formats">
                      <button class="ql-bold"></button>
                      <button class="ql-italic"></button>
                      <button class="ql-underline"></button>
                      <button class="ql-strike"></button>
                    </span>

                    <span class="ql-formats">
                      <select class="ql-color"></select>
                      <select class="ql-background"></select>
                    </span>

                    <span class="ql-formats">
                      <button class="ql-list" value="ordered"></button>
                      <button class="ql-list" value="bullet"></button>
                      <button class="ql-indent" value="-1"></button>
                      <button class="ql-indent" value="+1"></button>
                    </span>
                  </div>
                </p-editor>
                <mat-error
                  *ngIf="noteInvalid"
                  fxLayout="row"
                  fxLayoutAlign="end center"
                  class="p-4 pr-8"
                >
                  {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
                </mat-error>
              </div>
            </mat-tab>

            <mat-tab
              tabindex="-1"
              label="{{ 'ORDERS.KITCHEN_NOTE' | translate }}"
            >
              <ng-template matTabContent>
                <div class="note-section" fxLayout="column">
                  <p-editor
                    #noteKitchenEditor
                    [(ngModel)]="order.note_kitchen"
                    [readonly]="!editMode"
                    [style]="{
                      height: '120px',
                      width: '100%',
                      'background-color': editMode ? '#ffffff' : '',
                    }"
                  >
                    <div class="">
                      <span class="ql-formats">
                        <select class="ql-font"></select>
                        <select class="ql-size"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <button class="ql-strike"></button>
                      </span>

                      <span class="ql-formats">
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-indent" value="-1"></button>
                        <button class="ql-indent" value="+1"></button>
                      </span>
                    </div>
                  </p-editor>
                  <mat-error
                    *ngIf="noteInvalid"
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="p-4 pr-8"
                  >
                    {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
                  </mat-error>
                </div>
              </ng-template>
            </mat-tab>

            <mat-tab label="{{ 'ORDERS.MATERIAL_NOTE' | translate }}">
              <ng-template matTabContent>
                <div class="note-section" fxLayout="column">
                  <p-editor
                    #noteMaterialEditor
                    [(ngModel)]="order.note_materials"
                    [readonly]="!editMode"
                    [style]="{
                      height: '120px',
                      width: '100%',
                      'background-color': editMode ? '#ffffff' : '',
                    }"
                  >
                    <div class="">
                      <span class="ql-formats">
                        <select class="ql-font"></select>
                        <select class="ql-size"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <button class="ql-strike"></button>
                      </span>

                      <span class="ql-formats">
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-indent" value="-1"></button>
                        <button class="ql-indent" value="+1"></button>
                      </span>
                    </div>
                  </p-editor>
                  <mat-error
                    *ngIf="noteInvalid"
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="p-4 pr-8"
                  >
                    {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
                  </mat-error>
                </div>
              </ng-template>
            </mat-tab>

            <mat-tab label="{{ 'ORDERS.ALLERGIES_NOTE' | translate }}">
              <ng-template matTabContent>
                <div class="note-section" fxLayout="column">
                  <p-editor
                    #noteAllergensEditor
                    [(ngModel)]="order.note_allergens"
                    [readonly]="!editMode"
                    [style]="{
                      height: '120px',
                      width: '100%',
                      'background-color': editMode ? '#ffffff' : '',
                    }"
                  >
                    <div class="">
                      <span class="ql-formats">
                        <select class="ql-font"></select>
                        <select class="ql-size"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <button class="ql-strike"></button>
                      </span>

                      <span class="ql-formats">
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-indent" value="-1"></button>
                        <button class="ql-indent" value="+1"></button>
                      </span>
                    </div>
                  </p-editor>
                  <mat-error
                    *ngIf="noteInvalid"
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="p-4 pr-8"
                  >
                    {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
                  </mat-error>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

      <div
        *ngIf="
          order &&
          event &&
          moduleService.modulesAreSet &&
          moduleService.moduleExist('MODULE_EVENT_FILES')
        "
        class="profile-box info-box general mt-16"
        fxLayout="column"
      >
        <div class="gt2-order-theme" style="display: contents">
          <header [ngClass]="orderTypeHeaderColor || 'accent'" class="">
            <div fxFlex class="title p-16 icon-mat">
              {{ "GENERIC.FILES" | translate }}
            </div>

            <div
              fxFlex="230px"
              class=""
              fxLayout="row"
              fxLayoutAlign="end start"
              style="height: 56px"
            >
              <div
                *ngxPermissionsOnly="rolesService.SALE_ROLES"
                class="gt2-light-green"
                style="width: 60px; height: 56px"
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <button
                  type="button"
                  matTooltip="{{ 'GENERIC.ATTACH_FILE' | translate }}"
                  mat-icon-button
                  class=""
                  [disabled]="event.invoice"
                  (click)="eventAttachFile.onAttachDocument()"
                >
                  <mat-icon class="icon-mat-black" color="">{{
                    getIcon("ADD")
                  }}</mat-icon>
                </button>
              </div>
            </div>
          </header>
        </div>

        <div class="" fxLayout="column">
          <app-event-attached-files
            [eventUUID]="event.uuid"
            #eventAttachFile
          ></app-event-attached-files>
        </div>
      </div>

      <app-comment-section
        *ngIf="event"
        [commentsModel]="event"
        [serviceAPIUrl]="'/v1/events/comments/' + event.uuid"
      >
      </app-comment-section>
      <app-created-updated-at
        *ngIf="order"
        [historicModel]="order.historic_model"
      ></app-created-updated-at>
    </div>
  </div>
</div>

<div *ngIf="order && editable" fxLayout="row" fxLayoutAlign="end start">
  <div *ngIf="order.type === 'PROPOSAL' || order.type === 'ORDER'">
    <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
      <div class="fixed-button">
        <button
          mat-fab
          #saveButton
          [ngClass]="editMode ? 'gt2-save-button' : 'gt2-edit-button'"
          id="edit-order-button"
          (click)="onEdit()"
          [disabled]="loading || (editMode && !form.valid)"
          *fuseIfOnDom
          [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        >
          <mat-icon class="icon-mat-black" *ngIf="!editMode && !loading">{{
            getIcon("EDIT_FAB")
          }}</mat-icon>
          <mat-icon
            class="icon-mat-black"
            *ngIf="editMode && !loading && !saveButton.disabled"
            matTooltip="{{ 'GENERIC.SAVE' | translate }}"
            >{{ getIcon("SAVE") }}</mat-icon
          >
          <mat-icon
            class="icon-mat-black"
            *ngIf="editMode && !loading && saveButton.disabled"
            matTooltip="{{ 'GENERIC.FORM_DIRTY_TIP' | translate }}"
            >{{ getIcon("SAVE") }}</mat-icon
          >
          <i [ngClass]="loading ? 'fa fa-spinner fa-spin' : ''"></i>
        </button>
      </div>
      <div class="fixed-button2">
        <button
          mat-fab
          *ngIf="editMode"
          id="cancel-edit-order-button"
          class="gt2-cancel-edit-button"
          matTooltip="{{ 'GENERIC.CANCEL' | translate }}"
          (click)="onCancelEdit($event)"
          [disabled]="loading"
          [@animate]="{ value: '*', params: { delay: '120ms', scale: '.2' } }"
        >
          <mat-icon class="s-24 icon-mat">{{ getIcon("CANCEL") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="order.type === 'INVOICE'">
    <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
      <div class="fixed-button">
        <button
          mat-fab
          #saveButton
          [ngClass]="editMode ? 'gt2-save-button' : 'gt2-edit-button'"
          id="edit-order-button"
          (click)="onEdit()"
          [disabled]="loading || (editMode && !form.valid)"
          *fuseIfOnDom
          [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        >
          <mat-icon class="icon-mat-black" *ngIf="!editMode && !loading">{{
            getIcon("EDIT_FAB")
          }}</mat-icon>
          <mat-icon
            class="icon-mat-black"
            *ngIf="editMode && !loading && !saveButton.disabled"
            matTooltip="{{ 'GENERIC.SAVE' | translate }}"
            >{{ getIcon("SAVE") }}</mat-icon
          >
          <mat-icon
            class="icon-mat-black"
            *ngIf="editMode && !loading && saveButton.disabled"
            matTooltip="{{ 'GENERIC.FORM_DIRTY_TIP' | translate }}"
            >{{ getIcon("SAVE") }}</mat-icon
          >
          <i [ngClass]="loading ? 'fa fa-spinner fa-spin' : ''"></i>
        </button>
      </div>

      <div class="fixed-button2">
        <button
          mat-fab
          *ngIf="editMode"
          id="cancel-edit-order-button"
          class="gt2-cancel-edit-button"
          matTooltip="{{ 'GENERIC.CANCEL' | translate }}"
          (click)="onCancelEdit($event)"
          [disabled]="loading"
          [@animate]="{ value: '*', params: { delay: '120ms', scale: '.2' } }"
        >
          <mat-icon class="s-24 icon-mat">{{ getIcon("CANCEL") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
