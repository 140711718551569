import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { VerticalLayout1Component } from './vertical/layout-1/layout-1.component';
import { VerticalLayout2Component } from './vertical/layout-2/layout-2.component';
import { VerticalLayout3Component } from './vertical/layout-3/layout-3.component';
@NgModule({
  imports: [
    VerticalLayout1Component,
    TranslateModule,
    VerticalLayout2Component,
    MatInputModule,
    VerticalLayout3Component,
  ],
  exports: [],
})
export class LayoutModule {}
