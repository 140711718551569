import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OrdersService } from '../../../api/orders.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { GT2CalendarUtils } from '../../../utils/GT2CalendarUtils';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
})
export class CreateOrderComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form!: UntypedFormGroup;
  order: any;
  loading: boolean = false;
  event_date: any = null;
  client_uuid: any = null;

  constructor(
    public override dialogRef: MatDialogRef<CreateOrderComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public ordersService: OrdersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    if (data && data.event_date) {
      this.event_date = data.event_date;
      this.order = {
        name: null,
        event_date: this.event_date,
      };

      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        event_date: '',
      });
    } else if (data && data.client_uuid) {
      this.client_uuid = data.client_uuid;
      this.order = {
        name: null,
        client_uuid: this.client_uuid,
      };

      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
      });
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onDatePickerChange(event: any): void {
    const date: Date = new Date(event.value);
    this.order.event_date = GT2CalendarUtils.formatDateForAPI(date);
  }

  public onCreateOrder(): void {
    this.loading = true;
    if (this.form.valid) {
      this.doCreateOrder();
    }
  }

  public doCreateOrder(): void {
    if (this.event_date) {
      this.ordersService.createOrder(this.order).subscribe(
        (response: any) => {
          this.dialogRef.close(response.data);
        },
        (error: any) => {
          this.logger.error(
            'CreateOrderComponent.onCreateOrder() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
    } else if (this.client_uuid) {
      this.ordersService.createOrderForClient(this.order).subscribe(
        (response: any) => {
          this.dialogRef.close(response.data);
        },
        (error: any) => {
          this.logger.error(
            'CreateOrderComponent.onCreateOrder() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
    }
  }

  public doCreateEvent(): void {}

  public doCreateProposal(): void {}

  public doCreateInvoice(): void {}
}
