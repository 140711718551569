export const locale = {
  lang: "fr-CA",
  data: {
    ASSIGNMENTS: {
      ASSIGNMENTS_GROUP: "Groupe d'affectation",
      ASSIGNMENTS: "Affectations",
      ASSIGNMENT: "Affectation",
      GROUP_NAME: "Nom du groupe",
      INVOICE_SUMMARY: "Sommaire de la facture",
      ORDER_SUMMARY: "Sommaire de la commande",
      ORDER_NECESSARY: "Une commande est nécessaire.",
      ASSIGNMENTS_SUMMARY: "Sommaire des affectations",
      TOTAL_BILLED: "Prix facturé",
      TOTAL_BILLED_HINT: "Le prix facturé est basé sur le prix de base de la fonction de travail.",
      COST_PRICE: "Prix coût",
      TOTAL_HOURS: "Total des heures",
      JOB_FUNCTIONS_BILLED: "Personnel facturé",
      RATE: "Taux/h",
      PRICE: "Prix/h",
      HOURLY_RATE: "Taux horaire",
      GROUP: "Groupe",
      REQUESTED_ALL_DAY: "Toute la journée",
      DATE_START: "Date réelle de début",
      DATE_END: "Date réelle de fin",
      TIME_START: "Heure réelle de début",
      TIME_END: "Heure réelle de fin",
      SOURCE: "Source",
      MEAL: "Repas",
      TIPS: "Pourboires",
      MEAL_INCLUDED: "Repas inclus par affectation",
      JOBS_COUNT: "Nombre d'affectation(s) total",
      BILLABLE: "Facturable",
      TIPS_ENABLE: "Pourboire",
      CHAR_COUNT_MESSAGE: "{{min}} de {{max}} charactères",
      ACTIONS: "Actions",
      PROPOSALS: "Employé(s) à qui proposer",
      PROPOSAL: "Proposition",
      NO_PROPOSALS: "Aucune proposition",
      NO_ASSIGNMENTS: "Aucune affectation",
      TIPS_AMOUNT: "Montant pourboire",
      ADD_ASSIGNEMENT_HINT: "Ajouter une affectation",
      ADD_GROUP_HINT: "Ajouter un groupe d'affectation",

      JOB_PROPOSAL_UNSET: "Aucun",
      JOB_PROPOSAL_ASSIGNED: "Affecté(e)",
      JOB_PROPOSAL_AVAILABLE_UNASSIGNED: "Disponible, mais non assigné(e)",
      JOB_PROPOSAL_CANCEL: "Annulé",
      JOB_PROPOSAL_ON_HOLD: "En attente",
      JOB_PROPOSAL_PROPOSED: "Proposé",
      JOB_PROPOSAL_REFUSED: "Refusé",

      SELECT_STAFF_PROVIDER: "Fournisseurs",
      EXPENSES: "Dépenses",
      JOB_EXPENSES: "Dépenses de l'affectation",
      ADD_EXPENSE: "Ajouter une dépense",
      NO_EXPENSES: "Aucune dépense pour cette affectation",

      OPEN_EMPLOYEE: "Ouvrir l’employé",
      INTERNAL: "Interne",
      NO_GROUP: "Vous n’avez pas de Groupe d’affectation",
      NO_TIPS: "Aucun pourboire",
      TOTAL_COST: "Coût total",
      ASSIGNMENT_SALE: "Affectations - ventes",
      ASSIGNMENT_COST: "Affectations - coûts",
      ASSIGNMENT_ATTENDANCE: "Relevé des présences",
      ASSIGNMENTS_COMPLETE: "Toutes les affectations sont assignées",

      UNSAVED_EXPENSES_TITLE: "Dépenses non sauvegardées",
      UNSAVED_EXPENSES_MESSAGE: "Vous avez des dépenses non sauvegardées. Voulez-vous tout de même fermer la fenêtre?",
      JOBS_COUNT_OVER_50: "50 affectations maximum",
      ASSIGNMENTS_TOTAL: "Nombre d’affectations",
      ASSIGNMENT_DELETE_EMLPOYEE_HINT: "Cette affectation ne peut pas être effacée, car elle est associée à un employé: ",
      ASSIGNMENT_DELETE_STAFF_PROVIDER_HINT: "Cette affectation ne peut pas être effacée, car elle est associée à une ressource externe: ",
      NO_JOB_EXPENSES: "Aucune dépense",
      INVOICE_TOTAL: "Prix facturé",
      DETACH_EXTRERNAL: "Détacher la ressource externe",
      PLANNED_START: "Début planifié",
      PLANNED_END: "Fin planifié",
      ASSIGMENTS_DEFAULT_VALUES_LABEL: "Valeurs par défaut des affectations",
    },
  },
};
