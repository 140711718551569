export const locale = {
  lang: "fr-CA",
  data: {
    MATERIAL_COMBO: {
      TITLE: "Modifier l'ensemble",
      TITLE_CREATE: "Nouvel ensemble",
      LEFT_LIST_LABEL: "Glisser et déposer ou utiliser le bouton + pour ajouter du matériel",
      RIGHT_LIST_LABEL: "Réordonner et/ou enlever un matériel",
      CREATE_MATERIAL: "Créer un matériel",
      SEARCH: "recherche par nom interne, nom externe ou code de matériel",
      SEARCH_MATERIAL: "Effectuez une recherche de votre matériel.",
    },
  },
};
