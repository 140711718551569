export const locale = {
  lang: "fr-CA",
  data: {
    MATERIAL_CATEGORIES: {
      CREATE_NEW_CATEGORY: "Nouvelle catégorie de matériel",
      CATEGORY_NAME: "Nom de la catégorie de matériel",
      ACCOUNTING_NUMBER: "Compte de grand livre",
      NEW_CATEGORY_NAME: "Nom de la nouvelle catégorie",
      CREATE_SUCCESS: "Catégorie de matériel créer avec succès!",
      EDIT_SUCCESS: "Catégorie de matériel modifiée avec succès!",
      DELETE_SUCCESS: "Catégorie de matériel effacée avec succès!",
      LEDGER_ACCOUNT: "Compte de grand livre",
    },
  },
};
