export const locale = {
  lang: "en-CA",
  data: {
    CRUD_GENERIC_DT: {
      CREATE_SUCCESS_TOAST: "Creation success!",
      CREATE_ERROR_TOAST: "Creation error!",
      DELETE_SUCCESS_TOAST: "Deletion success!",
      DELETE_ERROR_TOAST: "Deletion error!",
      EDIT_SUCCESS_TOAST: "Edit success!",
      EDIT_ERROR_TOAST: "Edit error!",
    },
  },
};
