export const locale = {
  lang: "fr-CA",
  data: {
    MATERIALS: {
      MATERIALS: "Matériels",
      MATERIAL: "Matériel",
      COMBO: "Ensemble",
      MATERIAL_NUMBER: "Code de matériel",
      CONFIRMED: "Confirmé",
      SKU: "SKU",
      NOT_CONFIRMED: "Non confirmé",
      SINGLE_MATERIAL: "Unitaire",
      MODIFY_MATERIAL: "Modifier le matériel",
      CLOSE_EDIT: "Fermer modification",
      CLONE_MATERIAL: "Copier ce matériel",
      CREATE_COMBO: "Créer un ensemble",
      CLONE_COMBO: "Copier cet ensemble",
      INTERNAL_NAME: "Nom interne",
      EXTERNAL_NAME: "Nom externe",
      INTERNAL_DESCRIPTION: "Description interne",
      DESCRIPTION: "Description",
      PRICE: "Prix",
      // 'MATERIAL_NUMBER': 'Numéro du matériel',
      COST: "Coût du matériel",
      TOTAL_INVENTORY_QTY: "Quantité en inventaire",
      MATERIAL_PROVIDER: "Fournisseur",
      MATERIAL_PROVIDER_SELECT: "Sélectionner un fournisseur",
      CHANGE_COMBO_ORDER: "Ajouter/enlever des items",
      LOGISTIC: "Logistique",
      MATERIAL_CATEGORIES: "Catégories de matériel",
      MATERIAL_CATEGORY: "Catégorie du matériel",
      MATERIAL_CATEGORY_SELECT: "Sélectionner une catégorie",
      INTERNAL_VIEW_ONLY: "Afficher à l’interne seulement",
      SHOW_UNIT_PRICE: "Afficher le prix unitaire",
      TAXES: "Taxes",
      TAXES_SELECT: "Sélectionner les taxes applicables",
      NO_MATERIAL_PROVIDER_RESULTS: "Aucun fournisseurs. Visiter la section fournisseur à la page préférences pour en ajouter.",
      ORDERED_MATERIAL: "Matériel commandé",
      SEE_ORDER: "Voir commande",
      SEE_PROPOSAL: "Voir proposition",
      SEE_INVOICE: "Voir facture",
    },
  },
};
