import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class NotesService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getNoteByDate(date: string): any {
    //this.logger.info("NotesService.getNoteByDate()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.DASHBOARD_NOTE) + date);
  }

  public updateNote(date: string, note: any): any {
    //this.logger.info("NotesService.updateNote()");
    return this.http.post<any>(this.api.createUrl(ApiRoutes.DASHBOARD_NOTE + "update/") + date, {
      note: note,
    });
  }
}
