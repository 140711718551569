import { SettingsModel } from "./SettingsModel";

export class Settings {
  static isLoaded: boolean = false;
  static version: string;
  static build: string;
  static termsAndConditionsPathFr: string;
  static termsAndConditionsPathEn: string;
  static supportedLanguages: string[];
  static inputMinChar: number = 2;
  static inputMaxCharSmall: number = 50;
  static inputMaxChar: number = 191;
  static inputMaxCharMid: number = 120;
  static inputMaxCharLong: number = 2048;
  static editorMaxCharLong: number = 2048; //50000
  static tagMaxCharLong: number = 30;
  static refreshFormData: boolean = false;
  static featureExcelDownload: boolean = true;
  static featureHtmlPrint: boolean = true;
  static featurePdfDownload: boolean = true;
  static timezone: string = "america/montreal"; // Default value very important for AppConfig class

  static mapModel(model: SettingsModel): void {
    Settings.version = model.version;
    Settings.build = model.build;
    Settings.termsAndConditionsPathFr = model.termsAndConditionsPathFr;
    Settings.termsAndConditionsPathEn = model.termsAndConditionsPathEn;
    Settings.supportedLanguages = model.supportedLanguages;
    Settings.isLoaded = true;
    Settings.featureExcelDownload = model.feature_excel_download;
    Settings.featureHtmlPrint = model.feature_html_print;
    Settings.featurePdfDownload = model.feature_pdf_download;

    // if (model.feature_excel_download)
    // {
    // }
    //
    // if (model.feature_html_print)
    // {
    // }
    //
    // if (model.feature_pdf_download)
    // {
    // }
  }
}
