<div style="min-width: 350px" class="">
  <!-- <ng-template #merchant_idHintTemplate>
        <p>{{'SHOPS.MONERIS_MERCHANT_ID_HINT' | translate }}</p>
    </ng-template>

    <ng-template #merchant_keyHintTemplate>
        <p>{{'SHOPS.MONERIS_MERCHANT_KEY_HINT' | translate }}</p>
    </ng-template> -->

  <!-- <ng-template #profile_idHintTemplate>
        <p>{{'SHOPS.MONERIS_PROFILE_ID_HINT' | translate }}</p>
    </ng-template> -->

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">
        {{ "SHOPS.STRIPE_API_KEY" | translate }}
        <!-- <button class="" style="height: 40px;" mat-icon-button
                    matTooltip="merchant_idHintTemplate" content-type="template"
                    type="button">
                    <mat-icon class="s-20" color="">help</mat-icon>
                </button> -->
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="info text-truncate">{{ stripeModel.api_key }}</div>
      </div>
      <div *ngIf="!stripeModel.api_key" class="info empty-value"></div>
    </div>

    <div
      *ngIf="editMode"
      [formGroup]="formStripe"
      class="info-line"
      fxFlex="100%"
    >
      <mat-form-field class="theme-selector" style="width: 90%">
        <mat-label>{{ "SHOPS.STRIPE_API_KEY" | translate }}</mat-label>
        <input
          [(ngModel)]="stripeModel.api_key"
          autocomplete="nope"
          matInput
          formControlName="api_key"
          placeholder="{{ 'SHOPS.STRIPE_API_KEY' | translate }}"
          type="text"
        />
      </mat-form-field>
      <!-- <button class="" mat-icon-button matTooltip="merchant_idHintTemplate"
                content-type="template" type="button">
                <mat-icon class="s-20" color="">help</mat-icon>
            </button> -->
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">
        {{ "SHOPS.STRIPE_SECRET_KEY" | translate }}
        <!-- <button class="" mat-icon-button matTooltip="merchant_keyHintTemplate"
                    content-type="template" type="button">
                    <mat-icon class="s-20" color="">help</mat-icon>
                </button> -->
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="info text-truncate">{{ stripeModel.secret_key }}</div>
      </div>
      <div *ngIf="!stripeModel.secret_key" class="info empty-value"></div>
    </div>

    <div
      *ngIf="editMode"
      [formGroup]="formStripe"
      class="info-line"
      fxFlex="100%"
    >
      <!-- <div class="title">{{ 'SHOPS.MONERIS_MERCHANT_KEY' | translate }}</div> -->
      <mat-form-field class="theme-selector" style="width: 90%">
        <mat-label>{{ "SHOPS.STRIPE_SECRET_KEY" | translate }}</mat-label>
        <input
          [(ngModel)]="stripeModel.secret_key"
          autocomplete="nope"
          matInput
          formControlName="secret_key"
          placeholder="{{ 'SHOPS.STRIPE_SECRET_KEY' | translate }}"
          type="text"
        />
      </mat-form-field>
      <!-- <button class="" mat-icon-button matTooltip="merchant_keyHintTemplate"
                content-type="template" type="button">
                <mat-icon class="s-20" color="">help</mat-icon>
            </button> -->
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">
        {{ "SHOPS.MONERIS_TEST_MODE" | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="stripeModel.test_mode" class="info text-truncate">
          {{ "GENERIC.YES" | translate }}
        </div>
        <div *ngIf="stripeModel.test_mode === false" class="info text-truncate">
          {{ "GENERIC.NO" | translate }}
        </div>
      </div>
      <div
        *ngIf="stripeModel.test_mode === null"
        class="info empty-value"
      ></div>
    </div>

    <div *ngIf="editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "SHOPS.MONERIS_TEST_MODE" | translate }}</div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div style="margin-right: 8px">
          {{ "GENERIC.OFF" | translate | uppercase }}
        </div>
        <mat-slide-toggle
          class="mat-accent"
          [(ngModel)]="stripeModel.test_mode"
        >
        </mat-slide-toggle>
        <div style="margin-left: 8px">
          {{ "GENERIC.ON" | translate | uppercase }}
        </div>
      </div>

      <!-- <button class="" mat-icon-button 
                    matTooltip="merchant_keyHintTemplate" 
                    content-type="template"
                    type="button">
                    <mat-icon class="s-20" color="">help</mat-icon>
            </button> -->
    </div>
  </div>

  <!-- PROFILES -->
  <!-- <div class="profile-box info-box general">

        <header class="accent" fx-fxLayout="row" fxLayoutAlign="space-between center">
            <div class="sub-title">
                {{ "SHOPS.MONERIS_MERCHANT_PROFILES" | translate}}</div>
            <div *ngIf="editMode" class="gt2-light-green" fxLayout="row"
                fxLayoutAlign="center center" style="width: 60px; height: 40px;">
                <button (click)="onAddStripeProfile()" class="" mat-icon-button
                    matTooltip="{{ 'SHOPS.MONERIS_MERCHANT_ADD_PROFILES' | translate }}"
                    type="button">
                    <mat-icon class="" color="">add</mat-icon>
                </button>
            </div>
        </header>

        <div class="sub-content bracket-list" fxLayout="column">
            <div *ngFor="let item of stripeModel.profiles; let i = index;">


                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start"
                    class="">
                    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                        <div class="title">
                            {{ 'SHOPS.MONERIS_PROFILE_URL' | translate }}</div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="info text-truncate">{{item.url}}</div>
                        </div>
                        <div *ngIf="item.url === null" class="info empty-value">
                        </div>
                    </div>

                    <div *ngIf="editMode" class="info-line" fxFlex="50%">
                        <mat-form-field class="form-input-container bracket-item-input"
                            fxFlex="100%" style="width: 100%;">
                            <input [(ngModel)]="item.url" autocomplete="nope" matInput
                                placeholder="{{ 'SHOPS.MONERIS_PROFILE_URL' | translate }}"
                                type="text">
                        </mat-form-field>
                    </div>


                    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                        <div class="title">
                            {{ 'SHOPS.MONERIS_PROFILE_ID' | translate }}</div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="info text-truncate">{{item.profile_id}}
                            </div>
                        </div>
                        <div *ngIf="item.profile_id === null" class="info empty-value">
                        </div>
                    </div>

                    <div *ngIf="editMode" class="info-line" fxLayout="row" fxFlex="50%">
                        <mat-form-field class="form-input-container bracket-item-input"
                            fxFlex="100%" style="width: 100%;">
                            <input [(ngModel)]="item.profile_id" autocomplete="nope"
                                matInput
                                placeholder="{{ 'SHOPS.MONERIS_PROFILE_ID' | translate }}"
                                type="text">
                        </mat-form-field>
                    </div>

                    <button *ngIf="editMode" mat-icon-button
                        matTooltip="profile_idHintTemplate" content-type="template"
                        type="button">
                        <mat-icon class="s-20" color="">help</mat-icon>
                    </button>

                    <button mat-icon-button *ngIf="editMode" class=""
                        (click)="onRemoveStripeProfile(item, i)" aria-label="Palette">
                        <mat-icon class="s-20">delete_forever</mat-icon>
                    </button>
                </div>
            </div>

        </div>
    </div> -->

  <div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start">
    <button
      (click)="onSaveStripeInfo()"
      color="accent"
      class="save-btn"
      [disabled]="formStripe.invalid"
      mat-raised-button
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
  </div>
</div>
