export const locale = {
  lang: 'en-CA',
  data: {
    APP: {
      TITLE: 'Caterer Manager',
      LOGO: 'fuse-en.png',
    },
    GENERIC: {
      INGREDIENTS: 'Ingredients',
      CHOOSE_DATE: 'Select a date',
      START_TIME: 'Time (Start)',
      END_TIME: 'Time (End)',
      START_DATE: 'Date (Start)',
      END_DATE: 'Date (End)',
      CREATED_AT: 'Created at',
      EVENT_NAME: 'Event name',
      GUEST_COUNT_DT: 'Guest count',
      EVENT_LOGISTIC_STATUS: 'Logistic status',
      SELECT_RESPONSIBLE_EMPLOYEE: 'Advisor',
      PROJECT_DEPOSIT: 'Project deposit',
      REPORT_MODE_NORMAL: 'Period',
      REPORT_MODE_DATE_NOW: 'Until now',
      NEXT: 'Next',
      BACK: 'Previous',
      PREVIOUS: 'Previous',
      YES: 'Yes',
      NO: 'No',
      ON: 'on',
      OFF: 'off',
      OK: 'OK',
      CLEAR: 'Clear',
      ADD: 'Add',
      CONFIRM: 'Confirm',
      CANCEL: 'Cancel',
      SUBMIT: 'Submit',
      LOGIN: 'Log in',
      LOGOUT: 'Log out',
      BILLING: 'Billing',
      PRICE_PER_GUEST: 'Price per guest',
      FOOD: 'Food',
      DRINK: 'Drink',
      CHARGED: 'Charged',
      INCLUDED: 'Included',
      AND: 'and',
      GENDER: {
        GENDER: 'Gender',
        FEMALE: 'Female',
        MALE: 'Male',
        MR: 'Mr.',
        MS: 'Ms.',
      },
      MISS: 'Miss',
      MS: 'Mis',
      MRS: 'Mrs',
      MR: 'Mr',
      'fr-CA': 'French',
      'en-CA': 'English',
      'mobile-fr-CA': 'Image for mobile French',
      'mobile-en-CA': 'Image for mobile English',
      APPLICATION: 'Application',
      SUPPORT: 'Support',
      LANGUAGE: 'Language',
      EMAIL: 'Email',
      PASSWORD: 'Password',
      ROLE: 'Role',
      GOD: 'God',
      ROLE_OWNER: 'Owner',
      ROLE_ADMIN: 'Administrator',
      ROLE_EMPLOYEE: 'Employee',
      ROLE_SALE: 'Sales',
      ROLE_ACCOUNTANT: 'Accountant',
      ROLE_HR: 'Human Resources',
      ROLE_TEAM_LEAD: 'Supervisor',
      ADMIN: 'Admin',
      KITCHEN: 'Kitchen',
      SALE: 'Sale',
      SERVICE: 'Service',
      OTHER: 'Other',
      TAX: 'Tax',
      CREATE: 'Create',
      DELETE_CONFIRM: 'Are you sure you want to delete this item?',
      CANCEL_CONFIRM: 'Are you sure you want to delete this item?',
      DELETE_CONFIRM_S: 'Are you sure you want to delete these items?',
      DELETE_SUCCESS: 'Deleted successfully',

      API_CALL_SUCCESS: {
        SAVE_SUCCESS: 'Saved successfully',
        CREATE_SUCCESS: 'Created successfully',
        DELETE_SUCCESS: 'Deleted successfully',
        CREATE_CLIENT: 'Client created successfully',
        CREATE_PRODUCT: 'Product created successfully',
        CREATE_MATERIAL: 'Equipment created successfully',
        CREATE_ENTERPRISE: 'Enterprise created successfully',
        DELETE_EMPLOYEE: 'Employee deleted successfully',
        INVITE: 'Invitation successfully resent',
        CREATE_EMPLOYEE: 'Employee created successfully',
        CREATE_ROOM: 'Room created successfully',
        CREATE_PLACE: 'Place created successfully',
        CREATE_SECTOR: 'Sector successfully created',
        CREATE_SHOP: 'Online shop created successfully',
      },
      ERROR: 'Error',
      ERROR_DIALOG_TITLE: 'Error',
      ERROR_DIALOG_422_TITLE: 'Warning - Validation error',
      ERROR_DIALOG_422_MESSAGE: 'The data provided was invalid.',
      ERROR_DIALOG_TITLE_422: 'Validation',
      ERRORS: {
        GENERIC: 'Unable to process the request. Please try again',
        GENERIC_ERROR: 'An unknown error has occurred.',
        API_CALL_STATUS_NOT_FOUND:
          'Unable to process request not found. Please try again',
        CREATE_CLIENT: 'An error occurred while creating the client',
        DELETE_EMPLOYEE: 'An error occurred while deleting the employee',
        INVITE: 'An error occurred while sending the invitation.',
        CREATE_EMPLOYEE: 'An error occurred while creating the employee',
      },
      TERMS_CONDITIONS: 'terms and conditions',
      SEARCH: 'Search',
      FILTERING_PLACEHOLDER: 'filter',
      NAME: 'Name',
      CLIENT_NAME: 'Client name',
      NAME_INTERNAL: 'Internal name',
      PRICE: 'Price',
      RATE: 'Rate',
      AMOUNT: 'Amount',
      ACTIVE: 'Active',
      CATEGORY: 'Category',
      QUANTITY: 'Quantity',
      PHONE: 'Telephone',
      PHONE_NUMBER: 'Telephone number',
      MOBILE: 'Mobile',
      OFFICE_PHONE: 'Office phone',
      OFFICE_EXT: 'Ext',
      SAVE: 'Save',
      DELETE: 'Delete',
      EDIT: 'Edit',
      EDIT_STOP: 'Stop Edit',
      SEND: 'Send',
      ID: 'ID',
      TOTAL: 'Total',
      SUBTOTAL: 'Subtotal',
      PAYMENT: 'Payment',
      PAYMENT_MODE: 'Payment Mode',
      STATUS: 'Status',
      TYPE: 'Type',
      DATE: 'Date',
      TIME: 'Time',
      FROM: 'from',
      TO: 'to',
      TO2: 'to',
      SUBJECT: 'Subject',
      START: 'Start',
      END: 'End',
      UNTIL: 'Until',
      DATE_START: 'Start date',
      DATE_END: 'End date',
      TIME_START: 'Time start',
      TIME_END: 'Time end',
      SEE_ON_GOOGLE_MAP: 'See on Google Map',
      CONTENT: 'Content',
      FILTER: 'Filter...',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      CONFIRMED: 'Confirmed',
      SELECT_LANGUAGE: 'Select your language',
      EMPLOYEES: 'Employees',
      EMPLOYEE: 'Employee',
      USER: 'User',
      CLIENTS: 'Clients',
      CLIENTS_ENTERPRISE: 'Clients and enterprises',
      CLIENT: 'Client',
      WEB_CLIENT: 'Web client',
      ENTERPRISES: 'Enterprises',
      ENTERPRISE: 'Enterprise',
      ORGANIZATION: 'Organization',
      VENUE: 'Place',
      VENUES: 'Places',
      VENUES_TAB: 'Place and delivery',
      ROOM: 'Room',
      ROOMS: 'Rooms',
      ROOM_WEB: 'WEB order room',
      ALCOHOL_PERMIT_LOCAL: 'Event local',
      SECTOR: 'Sector',
      PRODUCTS: 'Products',
      FOOD_DRINK: 'Foods & drinks',
      PRODUCT: 'Product',
      MATERIALS: 'Equipments',
      MATERIAL: 'Equipment',
      DASHBOARD: 'Dashboard',
      DASHBOARD_DETAILED: 'Dashboard',
      DASHBOARD_DETAILED_JUL: 'Detailed Dashboard - Jul',
      ROOM_MANAGEMENT: 'Room management',
      DELIVERY: 'Delivery',

      EVENT: 'Event',
      EVENTS: 'Events',
      NO_RESULTS: 'No results',
      TABLE_ITEMS_PER_PAGE: 'Items per page',
      TABLE_NEXT_PAGE: 'Next page',
      TABLE_PREVIOUS_PAGE: 'Previous page',
      TABLE_FIRST_PAGE: 'First page',
      TABLE_LAST_PAGE: 'Last page',
      INFORMATION: 'Information',
      GENERAL_INFORMATION: 'General information',
      PAGE_FORM_GARD:
        'You have unsaved changes! If you leave, your changes will be lost.',
      NONE: 'None',
      PRINT: 'Print',
      PRINT_ORDER: 'Print purchase order',
      DOWNLOAD_ORDER: 'Download purchase order',
      PRINT_PROPOSAL: 'Print the proposal',
      DOWNLOAD_PROPOSAL: 'Download proposal',
      PRINT_DOWNLOAD_PROPOSAL: 'Download/print proposal',
      SEND_PROPOSAL: 'Send proposal',
      PREVIEW: 'Preview',
      OPEN_NEW_TAB: 'Open in new tab',
      OPEN_SAME_TAB: 'Open in same tab',
      EDIT_COMBO_ACTION:
        'Change the order of items or click the + button to add/remove items',
      OPEN_CLIENT: 'Open client page',
      OPEN_ENTERPRISE: 'Open enterprise page',
      ORDERS: 'Orders',
      ORDER: 'Order',
      PROPOSAL: 'Proposal',
      INVOICE: 'Invoice',
      WRAPPING: 'Packaging',
      CONTACT: 'Contact',
      ADDRESS: 'Address',
      ADDRESSES: 'Addresses',
      NOTE: 'Note',
      NOTES: 'Notes',
      DAY_NOTES: 'Note of the Day',
      CALENDAR: 'Calendar',
      TODAY: 'Today',
      DAY: 'Day',
      WEEK: 'Week',
      MONTH: 'Month',
      THIS_WEEK: 'This week',
      NEXT_2_WEEK: 'Next 2 weeks',
      THIS_MONTH: 'This month',
      NEXT_MONTH: 'Next month',
      DOWNLOAD: 'Download',
      DOWNLOAD_PDF: 'Download PDF',
      DOWNLOAD_VCARD: 'Download VCard',
      DOWNLOAD_XLS: 'Download in Excel format',
      DOWNLOAD_CSV: 'Download in CSV format',
      WEBSITE: 'Website',
      BRANCH: 'Branch',
      GENERAL: 'General',
      GENERALS: 'General',
      REPORT: 'Report',
      CLICK_TO_COPY: 'Click to copy',
      GOOGLE_ADDRESS_PLACEHOLDER: 'Search for an address',
      MEDIA: 'Media',
      ALLERGENS: 'Allergens',
      NUMBER: 'Number',
      FACEBOOK: 'Facebook',
      LINKEDIN: 'LinkedIn',
      TWITTER: 'Twitter',
      YOUTUBE: 'Youtube',
      INSTAGRAM: 'Instagram',
      DURATION: 'Duration',
      EMAIL_INVALID: 'Must be a valid email',
      INPUT_LENGTH_INVALID_MIN_2: '2 characters minimum',
      INPUT_LENGTH_INVALID_SMALL: 'Must be between 2 and 50 characters',
      INPUT_LENGTH_INVALID: 'Must be between 2 and 120 characters',
      INPUT_LENGTH_INVALID_1: 'Must be between 1 and 120 characters',
      INPUT_LENGTH_INVALID_20: 'Must be between 2 and 20 characters',
      INPUT_LENGTH_INVALID_30: 'Must be between 2 and 30 characters',
      INPUT_LENGTH_INVALID_40: 'Must be between 2 and 40 characters',
      NOTE_EDITOR_LENGTH_INVALID_2048: 'Note must not exceed 2048 characters',
      NOTE_EDITOR_LENGTH_INVALID: 'Note must not exceed 50 000 characters',
      RICH_TEXT_EDITOR_LENGTH_INVALID:
        'This text must not exceed 50 000 characters',
      TAG_LENGTH_INVALID: 'Tags must not exceed 30 characters',
      MODEL_NUMBER_INPUT_LENGTH_INVALID: 'Must be between 1 and 120 characters',
      INPUT_LENGTH_INVALID_191: 'Must be between 2 and 191 characters',
      INPUT_LONG_LENGTH_INVALID: 'Must be between 2 and 2048 characters',
      INPUT_SHORT_LENGTH_INVALID: 'Must be between 2 and 30 characters',
      INPUT_NUMBER: 'Must be a number',
      INPUT_NUMBER_0_999: 'Number must be between 0 and 999',
      INPUT_NUMBER_0_100: 'Number must be between 0 and 100 inclusive',
      INPUT_NUMBER_0_9999: 'Number must be between 0 and 9999',
      INPUT_NUMBER_0_999999: 'Number must be between 0 and 1 million',
      INPUT_NUMBER_POSTFIX_999999: ' and maximum of 1 million',
      INPUT_NUMBER_ABOVE_ZERO: 'Number must higher than zero',
      INPUT_NUMBER_POSTFIX_2_DECIMAL: ', no more than 2 decimals',
      INPUT_NUMBER_INTEGER_ONLY: 'The number must be an integer',
      INPUT_NUMBER_POSTFIX_INTEGER_ONLY: ', integer only',
      INPUT_NUMBER_MINUS_100_100: 'The number must be between -100 and 100',
      INPUT_NUMBER_NOT_ZERO: "The number can't be zero",
      DATE_DOB_INVALID:
        "The date must be between the year 1900 and today's date",
      DATE_DEFAULT_RANGE_INVALID:
        'The date must be between the year 1900 and 2099',
      DATE_TODAY_UP_RANGE_INVALID: "The date must be today's date or higher",
      DATE_TODAY_DOWN_RANGE_INVALID: "The date must be today's date or lower",
      NEW_TAG: 'Tags:',
      LEDGER_ACCOUNT: 'Ledger account',
      AVAILABLE: 'Available',
      ALCOHOLIC_PRODUCT: 'Alcoholic product',
      DATA: 'Data',
      CONFORMED_INFO_TITLE: 'Confirmed and unconfirmed product',
      // tslint:disable-next-line:max-line-length
      CONFORMED_INFO_DESCRIPTION:
        'Confirmed product: A confirmed product is a regular product approved by the Chef or Menu Producer. The cost of food and the sale price have been validated by the manager. The confirmed statement indicates that the product meets your standards and your food cost targets. \n \n \n Unconfirmed product:\n Is a product created or modified within an order or in the product section not validated by the Chef or the person responsible for the development of the menus. Ex. Last minute product, customized, adapted for a particular customer, etc. Only the Chef or Menu Developers can change the status of a product.',
      INTERNAL_NAME: 'Internal name',
      EXTERNAL_NAME: 'External name',
      SAGE: 'Sage',
      GOOGLE_CALENDER: 'Google Calendar',
      SHOP_WEB: 'Shop web',
      STRIPE: 'Stripe',
      QUICK_ORDER: 'Quick Order',
      READ_ONLY_MODE: 'READ ONLY MODE',
      ARCHIVE_PAGE: 'Page archived',
      SECTORS: 'Sectors',
      CHOOSE_SECTOR: 'Choose a sector',
      NO_SECTORS: 'No sectors created yet.',
      MATERIAL_COMBO: 'Kit',
      DAYS: {
        MONDAY: 'Monday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        THURSDAY: 'Thursday',
        FRIDAY: 'Friday',
        SATURDAY: 'Saturday',
        SUNDAY: 'Sunday',
      },
      ASSIGNMENTS: 'Assignments',
      ASSIGNMENT: 'Assignment',
      ALL_DAY: 'Entire day',
      LETTER_FORMAT: 'letter format',
      LEGAL_FORMAT: 'legal format',
      NO_PERMISSIONS: 'Access denied',
      JOB_FUNCTIONS: 'Job functions',
      AVAILABLE_EDIT_MODE_ONLY: 'In edit mode only',
      PRODUCT_CATEGORY: 'Product category',
      FOOD_DRINK_CATEGORY: 'Foods & drinks category',
      PRODUCT_CATEGORY_SELECT: 'Select a category',
      MATERIAL_CATEGORY: 'Equipment category',
      WITH_DELIVERY: 'With delivery',
      WITH_TAKEOUT: 'With takeout',
      DELIVERY_TYPE1: 'Delivery only',
      DELIVERY_TYPE2: 'Deliver and install',
      DELIVERY_TYPE3: 'With staff',
      DELIVERY_TYPE4: 'Takeout at the counter',
      RESPONSIBLE_EMPLOYEE: 'Advisor',
      FILES: 'Files',
      ATTACH_FILE: 'Attach a file',
      SELECT_IMG_RULES: 'JPG or PNG image of 10 MB maximum',
      NO_ATTACHED_FILES: 'No files',
      FILESIZE: 'File size',
      NIGHT: 'Night',
      PERCENTAGE: 'Percentage',
      ROYALTY_FOOD_BEVERAGE: 'Royalty on food & beverage',
      ROYALTY_FOOD: 'Royalty on food',
      ROYALTY_BEVERAGE: 'Royalty on beverage',
      ROYALTY_MATERIAL: 'Royalty on equipment',
      ROYALTY_SERVICE: 'Royalty on services',
      ROYALTY_DELIVERY_FEE: 'Royalty on delivery fees',
      ROYALTY_TOTAL_TAXES_INCLUDED_FEE:
        'Royalty on total amount taxes included',
      ROYALTY_SERVICE_CHARGE: 'Service charges',
      NO_ATTACHED_FILES_DROPZONE:
        'Drop a file here or click on the green + button',
      FORM_DIRTY_TIP: 'One or more required field is missing or incorrect.',
      NEW: 'New',
      RESERVATION: 'Reservation',
      BLOCKED: 'Blocked',
      OVERLAP_ERROR_MESSAGE:
        'An event cannot overlap with another for the same room',
      ATTEMPT: 'Attempt',
      EVENT_IN_PROPOSAL: 'In proposal',
      EVENT_IN_ORDER: 'In order',
      EVENT_IN_INVOICE: 'In invoice',

      RP_EVENT_IN_PROPOSAL: 'In proposal',
      RP_EVENT_IN_ORDER: 'Final',
      RP_EVENT_IN_INVOICE: 'In invoice',
      RP_ATTEMPT: 'Attempt',

      NO_END_TIME_FOR_EVENT_WITH_ROOM_INTERNAL:
        'You have selected an internal venue, please select an end time for your reservation to appear in your room management calendar',
      ROOM_STATUS: 'Room status',
      INTERNAL: 'Internal',
      PROGRAMS: 'Programs',
      DESCRIPTION: 'Description',
      MONTAGE: 'Assembly',
      GUEST: 'Guests',
      GUEST_S: 'Guest',
      GUEST_COUNT: 'Guests count',
      ESPACE: 'Space',
      TARIFS: 'Prices',
      SELECT_ROOM: 'Select room',
      SELECT: 'Select',
      GUEST_COUNT2: '# guests',
      SC_ROOM: 'Room',

      INVOICE_OPEN: 'Invoice issued',
      INVOICE_SEND: 'Invoice sent',
      INVOICE_PAID: 'Invoice paid',
      INVOICE_OVER_DUE: 'Overdue invoice',
      INVOICE_CANCEL: 'Invoice canceled',
      CLIENT_INVOICE: 'Client invoice',
      CONTRAT_BANQUET_CLIENT: 'Order by enterprise',
      CONTRAT_BANQUET_CLIENT_CT: 'Contrat banquet by enterprise',
      CREATE_PROJECT: 'Create project',
      CREATE_EVENT: 'Create event',

      EVENT_DATE: 'Event date',
      EVENT_DATE_START: 'Event start date',
      EVENT_DATE_END: 'Event end date',
      EVENT_TIME_START: 'Event start time',
      EVENT_TIME_END: 'Event end time',
      EVENT_TIME: 'Event time',
      EVENT_NUMBER: 'Event number',
      CHOOSE_A_DATE: 'Choose a date range',
      INCLUDE_SHOW_CANCELED: 'Show cancelled congress',
      CONGRESS_NUMBER_DYN: 'Congress number',
      SPCM_NUMBER_DYN: 'SPCM number',
      PROJECTS: 'Projects',
      PROJECT: 'Project',
      CONGRESS: 'Congress',
      CONGRESSES: 'Congresses',
      SEND_CONTRAT_BANQUET: 'Send contrat banquet',
      SPCM_NUMBER: '# SPCM',
      PROJECT_NUMBER: 'Project #',
      ALERT_DISTRUBITION_MODE_MAIL_ME:
        'Your request is being processed when the report is ready, it will be sent by email',
      SEND_PDF_WITH_EMAIL: 'Send report with email',

      MOVE_TO_PROJECT: 'Move to project',
      COPY_TO_PROJECT: 'Copy to project',
      GROUP_ACTION_MOVE_EVENT: 'Move events',
      GROUP_ACTION_COPY_EVENT: 'Copy events',
      EVENT_TYPE: 'Event type',
      ACTION: 'Action',
      CLONED_SUCCESSFULLY: 'Cloned successfully',
      MOVED_SUCCESSFULLY: 'Moved successfully',
      CREATE_CONGRESS_TITLE: 'Create a congress',

      CREATE_ORDER: 'Create an order',
      CREATE_PROPOSAL: 'Create a proposal',
      CREATE_INVOICE: 'Create an invoice',

      CANCEL_PROJECT_CONFIRM: 'Are you sure you want to cancel this project?',
      CANCEL_CONGRESS_CONFIRM: 'Are you sure you want to cancel this congress?',

      MAX_USER_TOO_LOW_ERROR:
        'The number of users entered is less than your number of active users.',
      USERS_COUNT: 'Current active user count',
      DISCOUNT_OR_SURCHARGE: 'Discount / Surchage',
      NO_SERVICE_CHARGE: 'No service charge for this enterprise',
      NEW_SERVICE_CHARGE: 'Create service charge',
      NEW_SERVICE_CHARGE_NAME: 'New service charge name',
      ADD_SERVICE_CHARGE: 'Add service charge',
      UPDATE_SERVICE_CHARGE: 'Update service charge',
      UPDATE: 'Update',
      CONTRAT_BANQUET: 'Banquet Contract',
      PRODUCTION_ZONE: 'Production zone',
      SHOW_CHARGED_PRICE: 'Charged price',
      GL_TOOLTIP_HINT:
        'If you use the non-taxable tips field in invoices or in your online ordering stores, the subtotal - net sale in the general ledger account report does not equal the net amount in the list of invoices and other reports of sales because it includes the amount of non-taxable tips.',
      ENTERPRISE_COPY_ADDRESS_CLIENT: 'Copy business address to clients',
      COPY_ENTERPRISE_ADDRESS_CLIENTS_CONFIRM:
        'This action will modify the addresses of all clients attached to this enterprise. Are you sure you want to proceed?',
      COPY_INTERNAL_NAME_TO_EXTERNAL: 'Copy internal Name',
      PRODUCT_UPSALE: 'Upsale',
      SET_PRODUCT_CYCLIC_DATE: 'Cyclic menu schedule',
      CLEAR_ALL_DATES: 'Reset all',
      CLEAR_ALL_DATES_CONFIRM: 'Are you sure you want to delete all dates?',
      SEARCH_PRODUCT_DETAILED:
        'search by internal name, external name or product code',
      SEARCH_PRODUCT: 'Search for your product.',
      LEFT_LIST_LABEL: 'Drag & drop or use the + button to add product',
      RIGHT_LIST_LABEL: 'Rearrange or use the - button to remove product',
      SEARCH_PRODUCTS: 'Search products',
    },
    CLIENT_OVERRIDE: {
      'capital-traiteur': {
        GENERIC: {
          ENTERPRISES: 'Clients',
          ENTERPRISE: 'Client',
          CLIENTS: 'Contacts',
          CLIENT: 'Contact',
          CLIENT_NAME: 'Contact name',
          CREATE_CLIENT: 'Contact created successfully',
          CLIENT_INVOICE: 'Contact invoice',
          OPEN_CLIENT: 'Open contact page',
          WEB_CLIENT: 'Web contact',
          CLIENTS_ENTERPRISE: 'Contacts and enterprises',
          OPEN_ENTERPRISE: 'Open customer page',
        },
        ERRORS: {
          CREATE_CLIENT: 'An error occurred while creating the contact',
        },
        API_CALL_SUCCESS: {
          CREATE_CLIENT: 'Contact created successfully',
          CREATE_ENTERPRISE: 'Client created successfully',
        },
      },
      'agence-traiteur': {
        GENERIC: {
          ENTERPRISES: 'Clients',
          ENTERPRISE: 'Client',
          CLIENTS: 'Contacts',
          CLIENT: 'Contact',
          CLIENT_NAME: 'Contact name',
        },
      },
      CREATE_ORDER: 'Create an order',
      CREATE_PROPOSAL: 'Create a proposal',
    },
  },
};
