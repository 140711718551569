import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Settings } from '../../../settings/settings';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { of, merge } from 'rxjs';
import { startWith, switchMap, catchError, map } from 'rxjs/operators';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { ClientsCategoryModel } from '../../../api/clients-categories.service';
import { CrudDatatableGenericService } from '../../../api/crud-datatable-generic.service';
import { GenericCreateComponent } from '../../../components/dialogs/generic-create/generic-create.component';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TableSizePipeModule } from '../../../modules/table-size.module';

@Component({
  selector: 'app-crud-datable-generic-no-local',
  templateUrl: './crud-datable-generic-no-local.component.html',
  styleUrls: ['./crud-datable-generic-no-local.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    MatTableModule,
    MatSortModule,
    CdkTableModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    TableSizePipeModule,
  ],
  providers: [CrudDatatableGenericService, DatatableHelperService],
  animations: fuseAnimations,
})
export class CrudDatableGenericNoLocalComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit
{
  @Input() serviceURL!: string;
  @Input() headerLabel!: string;
  @Input() tableHeaderLabel!: string;
  @Input() createLabel!: string;
  @Input() createPlaceholder!: string;
  @Input() useLanguages: boolean = false;
  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'edit', 'delete'];
  selected!: ClientsCategoryModel;
  editSelected!: ClientsCategoryModel | any;
  form: UntypedFormGroup;
  newCategory!: string;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;

  confirmDialogRef: any;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private crudDatatableGenericService: CrudDatatableGenericService,
    public datatableHelperService: DatatableHelperService,
    public translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // this.logger.info(">>>> serviceURL: " + this.serviceURL);
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.crudDatatableGenericService.getAll(
            this.serviceURL,
            this.sort?.active,
            this.sort?.direction,
            this.paginator?.pageIndex,
            this.paginator?.pageSize,
          );
        }),
      )
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe((data) => (this.dataSource.data = data));
  }

  public onSelect(selected: ClientsCategoryModel) {
    // this.logger.info("ClientsCategoriesSettingsComponent.onSelect() -> " + selected);
    this.onEdit(selected);
  }

  public reloadData(): void {
    // this.dataSource = new ClientsCategoriesDataSource(this.clientsCategoriesService);
    // Hack to refresh the Table
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onCreateNew() {
    // this.logger.info("CrudDatatableGenericComponent.onCreateNew() - this.newCategory: " + this.newCategory);
    // this.logger.info("CrudDatatableGenericComponent.onCreateNew() - this.serviceURL: " + this.serviceURL);
    this.confirmDialogRef = this.dialog.open(GenericCreateComponent, {
      disableClose: false,
      width: '400px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      data: {
        dialogTitle: this.createLabel,
        serviceURL: this.serviceURL,
        createPlaceholder: this.createPlaceholder,
        useEnglish: false,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.logger.info("CrudDatatableGenericComponent.onCreateNew() -> CREATION SUCCESS");
        this.reloadData();
      } else {
        // this.logger.info("CrudDatatableGenericComponent.onCreateNew() -> CREATION CANCELED");
      }
      this.confirmDialogRef = null;
    });

    // this.crudDatatableGenericService.create(this.serviceURL, this.newCategory, "", false).subscribe(response => {
    //     this.logger.info("CrudDatatableGenericComponent.onCreateNew() -> SUCCESS:" + JSON.stringify(response));
    //     this.toastr.success(this.translate.instant('CRUD_GENERIC_DT.CREATE_SUCCESS_TOAST'));
    //     this.newCategory = null;
    //     this.form.markAsUntouched();
    //     this.form.markAsPristine();
    //     this.reloadData();
    // }, error => {
    //     this.logger.info("CrudDatatableGenericComponent.onCreateNew() -> ERROR" + JSON.stringify(error));
    //     this.toastr.error(this.translate.instant('CRUD_GENERIC_DT.CREATE_ERROR_TOAST'));
    //     this.handleAPIError(error, this.dialog);
    // });
  }

  public onEdit(selected: ClientsCategoryModel) {
    // this.logger.info("CrudDatatableGenericComponent.onEdit() -> " + JSON.stringify(selected));
    // if ( !selected.editable )
    // {
    //     return;
    // }

    if (this.editSelected === selected) {
      this.editSelected = null;
      this.reloadData();
    } else {
      this.editSelected = selected;
    }
  }

  public onDelete(selected: ClientsCategoryModel) {
    // this.logger.info("CrudDatatableGenericComponent.onDelete() -> " + JSON.stringify(selected));
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.crudDatatableGenericService
          .delete(this.serviceURL, selected)
          .subscribe(
            (response: any) => {
              // this.logger.info("CrudDatatableGenericComponent.onDelete() -> SUCCESS:" + JSON.stringify(response));
              this.toastr.success(
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.reloadData();
            },
            (error: any) => {
              this.logger.error(
                'CrudDatatableGenericComponent.onDelete() -> ERROR' +
                  JSON.stringify(error),
              );
              // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          );
      }
      this.confirmDialogRef = null;
    });
  }

  public onEditCategoryFocusOut(selected: ClientsCategoryModel): any {
    // this.logger.info("CrudDatatableGenericComponent.onEditCategoryFocusOut() -> job: " + JSON.stringify(selected));
    this.autosaveDataChange(selected);
  }

  public autosaveDataChange(data: ClientsCategoryModel): void {
    // this.logger.info("CrudDatatableGenericComponent.autosaveDataChange() -> data to save: " + JSON.stringify(data));
    this.crudDatatableGenericService.edit(this.serviceURL, data).subscribe(
      (response: any) => {
        // this.logger.info("CrudDatatableGenericComponent.onEdit() -> SUCCESS:" + JSON.stringify(response));
        this.editSelected = null;
        this.toastr.success(
          this.translate.instant('CRUD_GENERIC_DT.EDIT_SUCCESS_TOAST'),
        );
        this.reloadData();
      },
      (error: any) => {
        this.logger.error(
          'CrudDatatableGenericComponent.onEdit() -> ERROR' +
            JSON.stringify(error),
        );
        // this.toastr.error(this.translate.instant('CRUD_GENERIC_DT.EDIT_ERROR_TOAST'));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('CRUD_GENERIC_DT.EDIT_ERROR_TOAST'),
        );
      },
    );
  }

  public onLanguageChange(lang: string): void {
    // this.logger.info("CrudDatatableGenericComponent.onLanguageChange() -> " + lang);
    this.reloadData();
  }
}
