export class ResponsesStatus {
  //  GENERIC HTTP STATUS
  static OK: string = "OK";

  // PAGE: register-2
  static EMAIL_ALREADY_USED: string = "EMAIL_ALREADY_USED";
  static EMAIL_STILL_UNCONFIRMED: string = "EMAIL_STILL_UNCONFIRMED";

  // PAGE: register-wizard
  static USER_ALREADY_REGISTERED: string = "USER_ALREADY_REGISTERED";

  // Forgot password
  static RESET_PASS_TOKEN_INVALID: string = "RESET_PASS_TOKEN_INVALID";

  static USER_NOT_FOUND: string = "USER_NOT_FOUND";
}
