import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { of, merge } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { ClientsCategoryModel } from '../../../api/clients-categories.service';
import { CrudDatatableGenericService } from '../../../api/crud-datatable-generic.service';
import { LedgerAccountService } from '../../../api/ledger-account.service';
import { GenericCreateComponent } from '../../../components/dialogs/generic-create/generic-create.component';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { Settings } from '../../../settings/settings';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { CrudDatatableGenericModel } from '../../crud/crud-datatable-generic/crud-datatable-generic.component';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { TaxesService } from '../../../api/taxes.service';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableSizePipeModule } from '../../../modules/table-size.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CdkTableModule } from '@angular/cdk/table';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-jobs-functions',
  templateUrl: './jobs-functions.component.html',
  styleUrls: ['./jobs-functions.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatCheckboxModule,
    FuseDirectivesModule,
    CdkTableModule,
    FusePipesModule,
    MatButtonModule,
    Gt2PrimengModule,
    Gt2SelectComponent,
    MatTooltipModule,
    MatPaginatorModule,
    TableSizePipeModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
  ],
  providers: [CrudDatatableGenericService],
  animations: fuseAnimations,
})
export class JobsFunctionsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() headerLabel!: string;
  serviceURL: string;
  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'edit', 'delete'];
  selected!: ClientsCategoryModel;
  editSelected: any;
  form: UntypedFormGroup;
  newCategory!: string;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  confirmDialogRef: any;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  currentLanguage?: string;

  constructor(
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public translate: TranslateService,
    public ledgerAccountService: LedgerAccountService,
    public datatableHelperService: DatatableHelperService,
    public taxesService: TaxesService,
    private crudDatatableGenericService: CrudDatatableGenericService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.form = this.formBuilder.group({
      name_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      hourly_wage: [
        '',
        [
          ValidatorHelper.isPositive,
          ValidatorHelper.isNumber0To1000000,
          Validators.required,
        ],
      ],
      price: [
        '',
        [
          ValidatorHelper.isPositive,
          ValidatorHelper.isNumber0To1000000,
          Validators.required,
        ],
      ],
    });

    this.serviceURL = ApiRoutes.JOBS_FUNCTION_ALL;
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.crudDatatableGenericService.getAll(
            ApiRoutes.JOBS_FUNCTION_ALL,
            this.sort?.active + '->' + this.translate.currentLang,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
          );
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe((data) => (this.dataSource.data = data));
  }

  public onSelect(selected: ClientsCategoryModel) {
    // this.logger.info("ClientsCategoriesSettingsComponent.onSelect() -> " + selected);
    this.onEdit(selected);
  }

  public reloadData(): void {
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onCreateNew() {
    // this.logger.info("JobsFunctionsComponent.onCreateNew() ");
    this.confirmDialogRef = this.dialog.open(GenericCreateComponent, {
      disableClose: false,
      width: '400px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      data: {
        dialogTitle: this.translate.instant(
          'JOB_FUNCTIONS.CREATE_NEW_CATEGORY',
        ),
        serviceURL: this.serviceURL,
        createPlaceholder: this.translate.instant(
          'JOB_FUNCTIONS.NEW_CATEGORY_NAME',
        ),
        useEnglish: true,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.logger.info("JobsFunctionsComponent.onCreateNew() -> CREATION SUCCESS");
        this.reloadData();
      } else {
        // this.logger.info("JobsFunctionsComponent.onCreateNew() -> CREATION CANCELED");
      }
      this.confirmDialogRef = null;
    });
  }

  public onLedgerAccountChange(event: any): void {
    // this.logger.info("JobsFunctionsComponent.onLedgerAccountChange() -> event: " + JSON.stringify(event));
    this.editSelected.ledger_account = event;
  }

  public onEdit(selected: ClientsCategoryModel) {
    // this.logger.info("JobsFunctionsComponent.onEdit() -> " + JSON.stringify(selected));
    if (this.editSelected === selected) {
      this.editSelected = null;
    } else {
      this.editSelected = selected;
    }
  }

  public onDelete(selected: ClientsCategoryModel) {
    // this.logger.info("JobsFunctionsComponent.onDelete() -> " + selected);
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.crudDatatableGenericService
          .delete(ApiRoutes.JOBS_FUNCTION_ALL, selected)
          .subscribe(
            (response: any) => {
              // this.logger.info("JobsFunctionsComponent.onDelete() -> SUCCESS:" + JSON.stringify(response));
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.reloadData();
            },
            (error: any) => {
              this.logger.error(
                'JobsFunctionsComponent.onDelete() -> ERROR' +
                  JSON.stringify(error),
              );
              // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          );
      }
      this.confirmDialogRef = null;
    });
  }

  public onEditCategoryFocusOut(
    selected: CrudDatatableGenericModel,
    name: string,
    local: string,
  ): void {
    // this.logger.info("JobsFunctionsComponent.onEditCategoryFocusOut() -> name: " + name);
    // this.logger.info("JobsFunctionsComponent.onEditCategoryFocusOut() -> local: " + local);
    this.autosaveDataChange(selected);
  }

  public onSaveHourlyWage(editSelected: any): void {
    // this.logger.info("JobsFunctionsComponent.onSaveAccoutningNumber() -> editSelected: " + JSON.stringify(editSelected));
    this.autosaveDataChange(editSelected);
  }

  public autosaveDataChange(data: any): void {
    // this.logger.info("JobsFunctionsComponent.autosaveDataChange() -> data to save: " + JSON.stringify(data));
    this.crudDatatableGenericService.edit(this.serviceURL, data).subscribe(
      (response: any) => {
        // this.logger.info("JobsFunctionsComponent.onEdit() -> SUCCESS:" + JSON.stringify(response));
        this.editSelected = null;
        this.toastr.success(
          this.translate.instant('JOB_FUNCTIONS.EDIT_SUCCESS'),
        );
        this.reloadData();
      },
      (error: any) => {
        this.logger.error(
          'JobsFunctionsComponent.onEdit() -> ERROR' + JSON.stringify(error),
        );
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    );
  }

  public onLanguageChange(lang: string): void {
    // this.logger.info("JobsFunctionsComponent.onLanguageChange() -> " + lang);
    this.reloadData();
  }

  public onTaxChange(event: any): void {
    // this.logger.info("JobsFunctionsComponent.onTaxChange() -> event: " + JSON.stringify(event));
    this.editSelected.tax = event;
  }
}
