import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter, MatOptionModule } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { ProgramsService } from '../../../api/programs.service';
import { SelectInternalRoomComponent } from './select-internal-room/select-internal-room.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-create-program',
  templateUrl: './create-program.component.html',
  styleUrls: ['./create-program.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    NgxMaterialTimepickerModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
})
export class CreateProgramComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static EDIT: string = 'EDIT';
  //
  static THEATER = 'THEATER'; // Théâtre
  static SCHOOL = 'SCHOOL'; // École
  static HALF_ROUND = 'HALF_ROUND'; // Demi-ronde
  static BANQUET = 'BANQUET'; // Banquet
  static CONFERENCE = 'CONFERENCE'; // Conférence
  static SQUARE = 'SQUARE'; // Carré
  static U_ASSEMBLY = 'U_ASSEMBLY'; // U
  static RECEPTION_FB = 'RECEPTION_FB'; // Réception F&B
  static CUSTOM = 'CUSTOM'; //  Personnalisé
  static UNSET = 'UNSET';
  assemblies = [
    CreateProgramComponent.THEATER,
    CreateProgramComponent.SCHOOL,
    CreateProgramComponent.HALF_ROUND,
    CreateProgramComponent.BANQUET,
    CreateProgramComponent.CONFERENCE,
    CreateProgramComponent.SQUARE,
    CreateProgramComponent.U_ASSEMBLY,
    CreateProgramComponent.RECEPTION_FB,
    CreateProgramComponent.CUSTOM,
    CreateProgramComponent.UNSET, // Can we set an assembly as unset?
  ];

  //
  mode: string = CreateProgramComponent.CREATE;
  form: UntypedFormGroup;
  program: any;
  eventUUID!: string;
  loading: boolean = false;
  autoRedirect: boolean = false;
  noteInvalid: boolean = false;
  isCreateForm: boolean = false;
  // noteEditorSubscription: Subscription;
  // noteEditordebounceTimeValue: number = 1500;
  // @ViewChild("noteEditor", { static: false }) noteEditor: Editor;
  programs!: any[];
  selectedProgram: any;
  programToUpdate: any;
  dialogRefPopup: any;

  constructor(
    public override dialogRef: MatDialogRef<CreateProgramComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private programService: ProgramsService,
    //private atp: any,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.adapter.setLocale(this.translate.currentLang);

    this.form = this.formBuilder.group({
      date_start: ['', [ValidatorHelper.dateRangeDefault, Validators.required]],
      date_end: ['', []],
      // eventDateTime: ['', [Validators.required]],
      // time_start,
      // time_end,
      guest_count: [
        '',
        [Validators.required, ValidatorHelper.isNumber0To1000000],
      ],
      price: ['', [Validators.required, ValidatorHelper.isValidPriceNumber]],
      selectedProgram: [''],
      // assembly_type: ["", [Validators.required]],
      description: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(120),
        ],
      ],
    });

    // //this.logger.info("CreateProgramComponent.constructor() -> data: " + JSON.stringify(data));
    this.program = {
      date_start: null,
      date_end: null,
      time_start: null,
      time_end: null,
      price: null,
      guest_count: null,
      assembly_type: null,
      // selectedTime: "12:00",
      description: '',
      room: null,
      // for_client_only: true,
    };

    if (data && data.mode !== null) {
      this.mode = data.mode;
    }

    if (data && data.eventUUID !== null) {
      this.eventUUID = data.eventUUID;
    }

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }

    if (data && data.date !== null) {
      this.program.date = data.date;
    }
    if (data && data.isCreateForm) {
      this.isCreateForm = data.isCreateForm;
    }
    if (data && data.programToUpdate) {
      this.programToUpdate = data.programToUpdate;
      //this.logger.info("CreateProgramComponent.constructor() -> programToUpdate: " + JSON.stringify(data.programToUpdate));
      this.program.description = this.programToUpdate.description;
      this.program.date_start = this.programToUpdate.date_start;
      this.program.date_end = this.programToUpdate.date_end;
      this.program.time_start = this.programToUpdate.time_start;
      this.program.time_end = this.programToUpdate.time_end;
      this.program.guest_count = this.programToUpdate.guest_count;
      this.program.price = this.programToUpdate.price;
      this.selectedProgram = this.programToUpdate.assembly_type;
      this.program.room = this.programToUpdate.room;
    }

    // this.programService.getProgramServiceList().subscribe(
    //     (response) => {
    //         //this.logger.info(
    //             "CreateProgramComponent.getprogramServiceList() -> SUCCESS: " +
    //                 JSON.stringify(response)
    //         );
    //         this.programs = response.data.map((item) => {
    //             return { label: item.name, value: item };
    //         });
    //         // //this.logger.info("CreateProgramComponent.getprogramServiceList() -> this.programs: " + JSON.stringify(response));
    //     },
    //     (error) => {
    //         this.logger.error(
    //             "CreateProgramComponent.onCreateProposal() -> ERROR: " +
    //                 JSON.stringify(error)
    //         );
    //         this.handleAPIError(error, this.dialog, null, null);
    //     }
    // );
  }

  ngOnInit() {}

  ngAfterViewInit() {
    //     this.noteEditorSubscription = this.noteEditor.onTextChange
    //         .debounceTime(this.noteEditordebounceTimeValue)
    //         .distinctUntilChanged()
    //         .subscribe((data) => {
    //             if (data.htmlValue) {
    //                 this.noteInvalid =
    //                     data.htmlValue.length > Settings.editorMaxCharLong;
    //                 //this.logger.info(
    //                     "CreateProgramComponent.noteEditor.onTextChange() -> this.noteInvalid: " +
    //                         this.noteInvalid
    //                 );
    //                 if (this.noteInvalid) {
    //                     return;
    //                 }
    //             }
    //         });
  }

  ngOnDestroy() {}

  public openTimePicker(): void {
    // const amazingTimePicker = this.atp.open({
    //   time: this.program.selectedTime,
    // });
    // amazingTimePicker.afterClose().subscribe((time: any) => {
    //   this.program.selectedTime = time;
    // });
  }

  public onCreateprogram() {
    this.loading = true;
    //this.logger.info("CreateProgramComponent.onCreateprogram() -> program: " + JSON.stringify(this.program));

    if (this.mode === CreateProgramComponent.CREATE) {
      this.doCreateprogram();
    } else if (this.mode === CreateProgramComponent.EDIT) {
      this.updateprogram();
    }
  }

  public doCreateprogram() {
    // //this.logger.info(
    //     "CreateProgramComponent.doCreateprogram() -> selectedprogram: " +
    //         JSON.stringify(this.selectedProgram)
    // );
    // //this.logger.info(
    //     "CreateProgramComponent.doCreateprogram() -> selectedprogram instanceof object: " +
    //         (this.selectedProgram instanceof Object)
    // );
    // //this.logger.info(
    //     "CreateProgramComponent.doCreateprogram() -> selectedprogram typeof string: " +
    //         (typeof this.selectedProgram === "string")
    // );
    //this.logger.info("CreateProgramComponent.doCreateprogram() -> program: " + JSON.stringify(this.program));
    const newprogram: any = {
      date_start: this.program.date_start,
      date_end: this.program.date_end,
      time_start: this.program.time_start,
      time_end: this.program.time_end,
      guest_count: 1, // todo:
      price: this.program.price,
      room: this.program.room,
      // for_client_only: this.program.for_client_only,
      assembly_type: this.selectedProgram,
      // selected_program_uuid: null,
      description: this.program.description,
    };

    // this.program = {
    //     date_start: null,
    //     date_end: null,
    //     time_start: null,
    //     time_end: null,
    //     price: null,
    //     guest_count: null,
    //     assembly_type: null,
    //     // selectedTime: "12:00",
    //     note: "",
    //     // for_client_only: true,
    // };

    // if (typeof this.selectedProgram === "string") {
    //     newprogram.custom_program = this.selectedProgram;
    // } else if (this.selectedProgram instanceof Object) {
    //     newprogram.selected_program_uuid = this.selectedProgram.uuid;
    // }

    newprogram.assembly_type = this.selectedProgram;
    if (!newprogram.assembly_type) {
      newprogram.assembly_type = 'UNSET';
    }

    //this.logger.info("CreateProgramComponent.doCreateprogram() -> ***** newprogram: " + JSON.stringify(newprogram));
    this.programService.createProgram(newprogram, this.eventUUID).subscribe(
      (response) => {
        // //this.logger.info("CreateProgramComponent.onCreateprogram() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        this.dialogRef.close(response);
      },
      (error) => {
        this.logger.error(
          'CreateProgramComponent.onCreateprogram() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
      },
    );
  }

  public updateprogram() {
    const programCopy = Object.assign({}, this.programToUpdate);

    if (typeof this.selectedProgram === 'string') {
      programCopy.custom_program = this.selectedProgram;
    } else if (this.selectedProgram instanceof Object) {
      programCopy.selected_program_uuid = this.selectedProgram.uuid;
    }

    programCopy.date_start = this.program.date_start;
    programCopy.date_end = this.program.date_end;
    programCopy.time_start = this.program.time_start;
    programCopy.time_end = this.program.time_end;
    programCopy.guest_count = this.program.guest_count;
    programCopy.price = this.program.price;
    programCopy.assembly_type = this.selectedProgram;
    programCopy.description = this.program.description;
    programCopy.room = this.program.room;

    // date_start: this.program.date_start,
    // date_end: this.program.date_end,
    // time_start: this.program.time_start,
    // time_end: this.program.time_end,
    // guest_count: 1, // todo:
    // price: 2.5,
    // // for_client_only: this.program.for_client_only,
    // assembly_type: this.selectedProgram,
    // // selected_program_uuid: null,
    // description: this.program.description,

    //this.logger.info("CreateProgramComponent.updateprogram() -> ***** this.program.room: " + JSON.stringify(this.program.room));

    //this.logger.info("CreateProgramComponent.updateprogram() -> ***** programToUpdate: " + JSON.stringify(programCopy));

    this.programService.updateProgram(programCopy, this.eventUUID).subscribe(
      (response) => {
        // //this.logger.info("CreateProgramComponent.onCreateprogram() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        this.dialogRef.close(response);
      },
      (error) => {
        this.logger.error(
          'CreateProgramComponent.onCreateprogram() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
      },
    );
  }

  public onSelectVenue(): void {
    // //this.logger.info("EventVenuesComponent.onSelectVenue() -> ");

    this.dialogRefPopup = this.dialog.open(SelectInternalRoomComponent, {
      width: '80%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        eventUUID: this.eventUUID,
        skipVenueSearch: true,
      },
    });

    this.dialogRefPopup.afterClosed().subscribe((result: any) => {
      if (result) {
        //this.logger.info("EventVenuesComponent.onSelectVenue() -> this.dialogRef.afterClosed() " + JSON.stringify(result));
        this.program.room = result;
        // this.loading = true;
      }
      this.dialogRefPopup = null;
    });
  }
  public updateDateEnd(event: any) {
    if (this.isCreateForm === true) {
      this.program.date_end = this.program.date_start;
    }
  }
}
