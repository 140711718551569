<div class="profile-box info-box width-100-content" fxLayout="column">
  <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title">{{ "SETTINGS.LEDGER_ACCOUNT_DEFAULT" | translate }}</div>
    <!-- <div class="gt2-light-green" style="width: 60px; height: 56px;" fxLayout="row" fxLayoutAlign="center center">
        <button type="button" matTooltip="{{ 'WRAPPING.CREATE_NEW_CATEGORY' | translate }}" mat-icon-button class=""
          (click)="onCreateNew()">
          <mat-icon class="" color="">add</mat-icon>
        </button>
      </div> -->
  </header>
  <div *ngIf="settingsModel" class="content p-16" fxLayout="column">
    <!--        <app-gt2-select [service]="ledgerAccountService"
                        [nullableUseNone]="false"
                        [selectedModel]="settingsModel.ledger_account_food"
                        [placeHolder]="'SETTINGS.LEDGER_ACCOUNT_FOOD' | translate"
                        [labelKey]="'name_label'"
                        [label]="'Taxes'" 
                        (changeEvent)="onLedgerFoodSelected($event)" 
                        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                        fxFlex="100%" 
                        class=""></app-gt2-select>

        <app-gt2-select [service]="ledgerAccountService" 
                        [nullableUseNone]="false" 
                        [selectedModel]="settingsModel.ledger_account_drink"
                        [placeHolder]="'SETTINGS.LEDGER_ACCOUNT_DRINK' | translate"
                        [label]="'Taxes'" 
                        [labelKey]="'name_label'"
                        (changeEvent)="onLedgerDrinkSelected($event)" 
                        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                        fxFlex="100%" 
                        class=""></app-gt2-select>-->

    <app-gt2-select
      [service]="ledgerAccountService"
      [nullableUseNone]="false"
      [selectedModel]="settingsModel.ledger_account_material"
      [placeHolder]="'SETTINGS.LEDGER_ACCOUNT_MATERIAL' | translate"
      [label]="'Taxes'"
      [labelKey]="'name_label'"
      (changeEvent)="onLedgerMaterialSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""
    ></app-gt2-select>

    <app-gt2-select
      [service]="ledgerAccountService"
      [nullableUseNone]="false"
      [selectedModel]="settingsModel.ledger_account_service_charge"
      [placeHolder]="'SETTINGS.LEDGER_ACCOUNT_SERVICE_CHARGE' | translate"
      [label]="'Taxes'"
      [labelKey]="'name_label'"
      (changeEvent)="onLedgerServiceChargeSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""
    ></app-gt2-select>

    <app-gt2-select
      [service]="ledgerAccountService"
      [nullableUseNone]="false"
      [selectedModel]="settingsModel.ledger_account_staff_charge"
      [placeHolder]="'SETTINGS.LEDGER_ACCOUNT_STAFF_CHARGE' | translate"
      [label]="'Taxes'"
      [labelKey]="'name_label'"
      (changeEvent)="onLedgerStaffChargeSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""
    ></app-gt2-select>

    <!-- <app-gt2-select [service]="ledgerAccountService" 
                        [nullableUseNone]="false" 
                        [selectedModel]="settingsModel.ledger_account_delivery"
                        [placeHolder]="'SETTINGS.LEDGER_ACCOUNT_DELIVERY' | translate"
                        [label]="'Taxes'" 
                        (changeEvent)="onLedgerDeliverySelected($event)" 
                        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                        fxFlex="100%" 
                        class=""></app-gt2-select> -->

    <app-gt2-select
      [service]="ledgerAccountService"
      [nullableUseNone]="false"
      [selectedModel]="settingsModel.ledger_account_royalty"
      [placeHolder]="'SETTINGS.LEDGER_ACCOUNT_ROYALTY' | translate"
      [label]="'Taxes'"
      [labelKey]="'name_label'"
      (changeEvent)="onLedgerRoyaltySelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""
    ></app-gt2-select>

    <!--          <app-gt2-select [service]="ledgerAccountService"
                          [nullableUseNone]="false"
                          [selectedModel]="settingsModel.ledger_account_discount"
                          [placeHolder]="'SETTINGS.LEDGER_ACCOUNT_DISCOUNT' | translate"
                          [label]="'Taxes'" 
                          [labelKey]="'name_label'"
                          (changeEvent)="onLedgerDiscountSelected($event)" 
                          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                          fxFlex="100%" 
                          class=""></app-gt2-select>-->

    <!-- MOVED: 8 juin 2020 -->
    <!-- <app-gt2-select [service]="ledgerAccountService" 
                        [nullableUseNone]="false" 
                        [selectedModel]="settingsModel.ledger_account_tips_no_tax"
                        [placeHolder]="'SETTINGS.LEDGER_ACCOUNT_TIPS_NO_TAX' | translate"
                        [label]="'Taxes'" 
                        [labelKey]="'name_label'"
                        (changeEvent)="onLedgerTipNoTaxSelected($event)" 
                        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                        fxFlex="100%" 
                        class=""></app-gt2-select> -->

    <app-gt2-select
      [service]="ledgerAccountService"
      [nullableUseNone]="false"
      [selectedModel]="settingsModel.ledger_account_tax"
      [placeHolder]="'SETTINGS.LEDGER_ACCOUNT_TAX' | translate"
      [label]="'Taxes'"
      [labelKey]="'name_label'"
      (changeEvent)="onLedgerTaxSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""
    ></app-gt2-select>
  </div>

  <div *ngIf="settingsModel" fxLayout="row" fxLayoutAlign="center start" fxFlex="100%" class="gt2-buttons-theme content px-16 pb-16">
    <button mat-raised-button *ngIf="settingsModel" color="accent" class="" (click)="onSaveData()">
      {{ "SETTINGS.UPDATE" | translate }}
    </button>
  </div>
</div>
