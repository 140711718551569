import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Gt2ApiService } from "../../api/gt2-api.service";
import { ApiRoutes } from "../../api/ApiRoutes";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class ContactService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public updateContact(data: any, contactUUID: string) {
    //this.logger.info("ContactService.updateContact() -> contactUUID: " + contactUUID);
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.UPDATE_CONTACT) + contactUUID, data);
  }
}
