export const locale = {
  lang: "fr-CA",
  data: {
    EVENT_LOGISTIC_STATUS: {
      TITLE: "Statut logistique",
      LIST_COLUMN_NAME: "Nom",
      CREATE_DIALOG_TITLE: "Nouveau statut logistique",
      CREATE_DIALOG_PLACEHOLDER: "Nom du statut logistique",
      CREATE_SUCCESS: "Statut logistique créé avec succès!",
      EDIT_SUCCESS: "Statut logistique  modifié avec succès!",
      DELETE_SUCCESS: "Statut logistique  effacé avec succès!",
    },
  },
};
