import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Gt2ApiService } from "./gt2-api.service";
import { ApiRoutes } from "./ApiRoutes";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class VenuesPlaceService implements IGetDataService {
  constructor(private http: HttpClient, private router: Router, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getData(): Observable<any> {
    // this.logger.info("VenuesPlaceService.getData()");
    return this.getPlacesList();
  }

  public getPlacesList() {
    // this.logger.info("VenuesPlaceService.getPlacesList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PLACES_LIST));
  }

  public getPlacesRoomsList(keyword?: string) {
    // this.logger.info("VenuesPlaceService.getPlacesRoomsList()");
    if (keyword === undefined) {
      return this.http.get<any>(this.api.createUrl(ApiRoutes.PLACES_ROOMS_LIST));
    }
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PLACES_ROOMS_LIST) + "?keyword=" + keyword);
  }

  public getEnterpriseRoomsList(enterpriseUUID: string) {
    // this.logger.info("VenuesPlaceService.getEnterpriseRoomsList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.ENTERPRISE_ROOMS_LIST) + enterpriseUUID);
  }
}
