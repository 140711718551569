export const locale = {
  lang: 'fr-CA',
  data: {
    ROOMS: {
      ROOMS: 'Salles',
      ROOM: 'Salle',
      ROOM_NAME: 'Nom / # de salle',
      DELETE_ROOM: 'Effacer cette salle',
      ADD_ROOM: 'Ajouter une salle',
      ATTACH_ROOM: 'Attacher une salle',
      DETACH_ROOM: 'Détacher la salle',
      NO_ROOM_TO_ATTACH: 'Aucune salle à attacher',
      DELETE_CONFIRM: 'Êtes vous sûr de vouloir effacer cette salle?',
      DELETE_SUCCESS: 'Salle effacé avec Succès',
      ROYALTY: 'Redevances',
      ROYALTY_PERCENT: 'Pourcentage sur les redevances',
      ROYALTY_FOOD_BEVERAGE: 'Pourcentage sur la nourriture et boisson',
      ROYALTY_FOOD: 'Pourcentage sur la nourriture',
      ROYALTY_BEVERAGE: 'Pourcentage sur les boissons',
      ROYALTY_MATERIAL: 'Pourcentage sur le matériel',
      ROYALTY_SERVICE: 'Pourcentage sur le personnel',
      MAX_PLACE: 'Nombre de places maximum',
      NO_ROOMS: 'Il n’y a pas de salles attitrés pour le moment.',
      NO_SUB_ROOMS: 'Il n’y a pas de salles attachés pour le moment.',
      NO_ROOMS_HR:
        'Il n’y a pas de salles attitrés pour le moment. Appuyez sur le + vert en mode modification pour en ajouter.',
      NO_SUB_ROOMS_HR:
        'Il n’y a pas de salles attachés pour le moment. Appuyez sur le + jaune en mode modification pour en ajouter.',
      SUB_ROOMS: 'Sous-salles',
      NAME: 'Nom',
    },
  },
};
