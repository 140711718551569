export class GT2ConstantsEnum {
  // GENDER
  static MALE: string = "MALE";
  static FEMALE: string = "FEMALE";

  // ROLE
  static GOD: string = "GOD";
  static OWNER: string = "OWNER";
  static ADMIN: string = "ADMIN";
  static TEAM_LEAD: string = "TEAM_LEAD";
  static SALE: string = "SALE";
  static ACCOUNTANT: string = "ACCOUNTANT";
  static HR: string = "HR";
  static EMPLOYEE: string = "EMPLOYEE";

  static NO_ROLE: string = "NO_ROLE";

  // PAYMENT
  static PAYMENT_TRIAL: string = "TRIAL";
  static PAYMENT_CC: string = "CC";
  static PAYMENT_CONTACT: string = "CONTACT";

  //
  static ORDER: string = "ORDER";
  static PROPOSAL: string = "PROPOSAL";
  static INVOICE: string = "INVOICE";

  // EVENT
  static EVENT_OPEN: string = "EVENT_OPEN";
  static EVENT_IN_PROPOSAL: string = "EVENT_IN_PROPOSAL";
  static EVENT_IN_ORDER: string = "EVENT_IN_ORDER";
  static EVENT_IN_INVOICE: string = "EVENT_IN_INVOICE";
  static EVENT_CANCEL: string = "EVENT_CANCEL";
  static EVENT_ABORT: string = "EVENT_ABORT";
  static EVENT_COMPLETE: string = "EVENT_COMPLETE";
  static EVENT_PAST_EVENT: string = "EVENT_PAST_EVENT";

  // ORDER
  static ORDER_OPEN: string = "ORDER_OPEN";
  static ORDER_SEND: string = "ORDER_SEND";
  static ORDER_CANCEL: string = "ORDER_CANCEL";
  static ORDER_CONFIRM: string = "ORDER_CONFIRM";
  static ORDER_TO_COMPLETE: string = "ORDER_TO_COMPLETE";
  static ORDER_TO_INVOICE: string = "ORDER_TO_INVOICE";
  static ORDER_COMPLETE: string = "ORDER_COMPLETE";

  // PROPOSAL
  static PROPOSAL_OPEN: string = "PROPOSAL_OPEN";
  static PROPOSAL_PROPOSE: string = "PROPOSAL_PROPOSE";
  static PROPOSAL_REFUSE: string = "PROPOSAL_REFUSE";
  static PROPOSAL_EXPIRE: string = "PROPOSAL_EXPIRE";
  static PROPOSAL_DISCARD: string = "PROPOSAL_DISCARD";
  static PROPOSAL_ACCEPT: string = "PROPOSAL_ACCEPT";

  // INVOICE
  static INVOICE_OPEN: string = "INVOICE_OPEN";
  static INVOICE_SEND: string = "INVOICE_SEND";
  static INVOICE_PAID: string = "INVOICE_PAID";
  static INVOICE_OVER_DUE: string = "INVOICE_OVER_DUE";
  static INVOICE_CANCEL: string = "INVOICE_CANCEL";

  // TENANT CONST

  static BF_SLUG: string = "bernard-et-fils-traiteur";

  // UPSALE
  static GROUP_PRODUCTS: string = "GROUP_PRODUCTS";
  static GROUP_MATERIALS: string = "GROUP_MATERIALS";
}
