import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { IDynamicTableItemAction } from './../../../components/dynamic-table/dynamic-table.component';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { GT2FormPageAbstract } from '../../../content/abstract/GT2FormPageAbstract';
import { ICanDeactivateComponent } from '../../../gards/can-deactivate-guard.service';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ShopsService } from '../../../api/shops.service';
import { Location } from '@angular/common';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';

@Component({
  selector: 'app-shop-rte-schedule-edit-unified',
  templateUrl: './shop-rte-schedule-edit-unified.component.html',
  styleUrls: ['./shop-rte-schedule-edit-unified.component.scss'],
  animations: fuseAnimations,
})
export class ShopRteScheduleEditUnifiedComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  @ViewChild('scrollMe', { static: false })
  private myScrollContainer!: ElementRef;

  loading: boolean = false;
  shopID!: string;
  scheduleUUID!: string;
  shop: any;
  shopCatgories!: any[];

  schedule!: any[];
  tableColumns: string[] = [
    'checkboxes',
    'start_at',
    'end_at',
    'delivery_start_at',
    'delivery_end_at',
    'takeout_start_at',
    'takeout_end_at',
    'action',
  ];
  scheduleTableItemAction!: ScheduleTableItemAction;

  constructor(
    private shopsService: ShopsService,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public location: Location,
    public router: Router,
    private titleService: Title,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit(): void {
    this.scheduleTableItemAction = new ScheduleTableItemAction();

    this.route.params.subscribe((params: any) => {
      // //this.logger.info(
      //     "ShopRteScheduleEditComponent.ngOnInit().route.params"
      // );
      this.shopID = params.id;
      this.scheduleUUID = params.scheduleUUID;
      // //this.logger.info(
      //     "ShopRteScheduleEditComponent.ngOnInit().route.params -> scheduleUUID: " +
      //         this.scheduleUUID
      // );
      // //this.logger.info(
      //     "ShopRteScheduleEditComponent.ngOnInit().route.params -> scheduleType: " +
      //         this.scheduleType
      // );
      this.loading = true;
      this.shop = null;
      this.loadShop();
      this.loadSchedule();
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public canDeactivate(): boolean {
    return true;
  }

  public loadSchedule(): void {
    // //this.logger.info("ShopRteScheduleEditComponent.loadSchedule()");
    this.loading = true;
    this.shopsService.getAllScheduleByShopUUID(this.shopID).subscribe(
      (response: any) => {
        // //this.logger.info(
        //     "ShopRteScheduleEditComponent.loadSchedule() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        this.loading = false;
        this.schedule = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopRteScheduleEditComponent.loadSchedule() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public loadShop(): void {
    // //this.logger.info("ShopRteScheduleEditComponent.loadShop()");
    this.loading = true;

    this.shopsService.getShopWithCategories(this.shopID).subscribe(
      (responseList) => {
        // //this.logger.info(
        //     "ShopWebComponent.loadShop() -> SUCCESS: " +
        //         JSON.stringify(responseList)
        // );

        this.shop = responseList[0].data;
        this.shopCatgories = responseList[1].data;
        this.loading = false;

        // //this.logger.info(
        //     "ShopWebComponent.loadShop() -> SUCCESS: " +
        //         JSON.stringify(this.shop)
        // );
        //this.titleService.setTitle(this.translate.instant("SHOPS.SHOPS") + " " + this.shop.name);
      },
      (error) => {
        this.logger.error(
          'ShopRteScheduleEditComponent.loadShop() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        // TODO: the errors are from a forkJoin, so the handleAPIError might fail
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public loadShopSchedule(): void {
    this.shopsService.getShopNextRTEScheduleStack(this.shopID).subscribe(
      (response: any) => {
        //this.logger.info("ShopRteScheduleEditComponent.ngOnInit() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
      },
      (error: any) => {
        this.logger.error(
          'ShopRteScheduleEditComponent.ngOnInit() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        // this.spinner.hide('rteSpinner');
      },
    );
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public openURL(url: string): void {
    window.open(url, '_blank');
  }

  public onScrollPage(): void {
    //this.logger.info("ShopRteScheduleEditComponent.onScrollPage() -> this.myScrollContainer: " + this.myScrollContainer);
    this.myScrollContainer.nativeElement.scrollTop =
      this.myScrollContainer?.nativeElement.scrollHeight;
  }
}

export class ScheduleTableItemAction implements IDynamicTableItemAction {
  itemClick: Subject<any> = new Subject();

  constructor() {}

  onItemClick(item: any): void {}
}
