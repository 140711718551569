import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class GuestsService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getData(): Observable<any> {
    // this.logger.info("GuestsService.getData()");
    return this.getGuestsList();
  }

  public getGuestsList() {
    // this.logger.info("GuestsService.getGuestsList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.GUEST_LIST));
  }

  public createGuest(guest: any, eventUUID: string) {
    // this.logger.info("GuestsService.createGuest() -> " + JSON.stringify(guest));
    // this.logger.info("GuestsService.createGuest() -> eventUUID: " + eventUUID);
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT) + "guests/" + eventUUID, guest);
  }

  public deleteGuest(guest: any, eventUUID: string) {
    // this.logger.info("GuestsService.deleteGuest() -> " + JSON.stringify(guest));
    // this.logger.info("GuestsService.deleteGuest() -> eventUUID: " + eventUUID);
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.EVENT) + "guests/" + eventUUID + "/" + guest.uuid);
  }

  public updateGuest(guest: any, guestUUID: string, eventUUID: string) {
    // this.logger.info("GuestsService.updateGuest() -> " + JSON.stringify(guest));
    // this.logger.info("GuestsService.updateGuest() -> eventUUID: " + eventUUID);
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.EVENT) + "guests/" + eventUUID + "/" + guestUUID, guest);
  }
}
