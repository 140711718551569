<div id="login" fxLayout="row" fxLayoutAlign="start">
  <div id="login-intro" fxFlex fxHide fxShow.gt-xs>
    <div
      class="logo"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { scale: '0.5' } }"
    >
      <img src="assets/images/logos/{{ 'APP.LOGO' | translate }}" />
    </div>

    <div
      class="title"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }"
    >
      {{ "LOGIN.WELCOME_MESSAGE" | translate }}
    </div>

    <div
      class="description"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }"
    >
      {{ "LOGIN.LONG_WELCOME_MESSAGE" | translate }}
    </div>

    <div
      class="description2"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }"
    >
      {{ "LOGIN.LONG_WELCOME_MESSAGE_2" | translate }}
    </div>
  </div>

  <div
    id="login-form-wrapper"
    fusePerfectScrollbar
    *fuseIfOnDom
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
  >
    <div id="login-form">
      <div id="login-form-lang">
        <button
          mat-stroked-button
          style="
            background-color: rgba(60, 66, 82, 0.05);
            margin-right: 5px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          (click)="toggleTheme()"
        >
          <mat-icon style="margin: 0; padding: 0">{{
            iconsService.getIcon("DARKMODE")
          }}</mat-icon>
        </button>

        <button
          mat-stroked-button
          style="
            background-color: rgba(60, 66, 82, 0.05);
            margin-left: 5px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          (click)="toggleLanguage()"
        >
          {{ buttonLabel }}
        </button>
      </div>

      <div class="logo" fxHide.gt-xs>
        <img src="assets/images/logos/{{ 'APP.LOGO' | translate }}" />
      </div>

      <div class="title">{{ "LOGIN.LOGIN_TO_YOUR_ACCOUNT" | translate }}</div>

      <form name="loginForm" [formGroup]="loginForm" novalidate>
        <mat-form-field appearance="outline">
          <mat-label>{{ "LOGIN.EMAIL_PLACEHOLDER" | translate }}</mat-label>
          <input
            matInput
            type="email"
            [(ngModel)]="userEmailModel"
            formControlName="email"
          />
          <mat-icon matSuffix class="secondary-text">mail</mat-icon>
          <mat-error *ngIf="loginFormErrors.email.required">
            {{ "LOGIN.EMAIL_REQUIRED" | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              !loginFormErrors.email.required && loginFormErrors.email.email
            "
          >
            {{ "LOGIN.EMAIL_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ "LOGIN.PASSWORD_PLACEHOLDER" | translate }}</mat-label>
          <input
            matInput
            [type]="password"
            class="red-input"
            type="password"
            formControlName="password"
          />

          <mat-icon
            matSuffix
            class="secondary-text"
            aria-hidden="true"
            *ngIf="!show"
            (click)="onPasswordShowHide()"
            >visibility</mat-icon
          >
          <mat-icon
            matSuffix
            class="secondary-text"
            aria-hidden="true"
            *ngIf="show"
            (click)="onPasswordShowHide()"
            >visibility_off</mat-icon
          >

          <mat-error *ngIf="loginFormErrors.password.required">
            {{ "LOGIN.PASSWORD_REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>

        <div
          class="remember-forgot-password"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="space-between center"
        >
          <mat-checkbox
            class="remember-me"
            aria-label="Remember Me"
            [ngModel]="rememberMeChecked"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="onRememberMeChange($event)"
          >
            <span class="text-checkbox">{{
              "LOGIN.REMEMBER_ME" | translate
            }}</span>
          </mat-checkbox>
        </div>

        <div
          class="remember-forgot-password"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="center center"
        >
          <a
            class="forgot-password pr-8"
            [routerLink]="'/' + appRoutesService.password"
          >
            {{ "LOGIN.FORGOT_PASSWORD" | translate }}
          </a>
          |
          <a
            class="forgot-password pl-8"
            href="https://gestiontraiteur.com/demande-de-ticket/"
          >
            {{ "LOGIN.NEED_HELP" | translate }}
          </a>
        </div>

        <div *ngIf="error401" class="no-match">
          {{ "LOGIN.ERROR_401_MESSAGE" | translate }}
        </div>

        <!--<div *ngIf="callingAPI" xLayout="column" fxLayoutAlign="center center">-->
        <!--<div class="spacer" style="height: 22px"></div>-->
        <!--<mat-spinner [diameter]="40"></mat-spinner>-->
        <!--<div class="spacer" style="height: 12px"></div>-->
        <!--</div>-->

        <button
          mat-raised-button
          color="accent"
          class="submit-button"
          aria-label="LOGIN"
          (click)="onLogin()"
          [disabled]="loginForm.invalid || callingAPI"
        >
          {{ "LOGIN.LOGIN_BTN_LABEL" | translate }}
        </button>
      </form>

      <div
        class="remember-forgot-password"
        fxLayout="row"
        fxLayout.xs="row"
        fxLayoutAlign="center center"
      >
        {{ "LOGIN.NO_ACCOUNT" | translate }}
        <a class="forgot-password pl-8" href="https://gestiontraiteur.com/">
          {{ "LOGIN.SIGNUP" | translate }}
        </a>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center">
        <div class="mat-caption mt-32">
          Version: {{ environment.appVersion }}
        </div>
      </div>
    </div>
  </div>
</div>
