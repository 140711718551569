import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class GeneralConditionsService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getConditions(): any {
    //this.logger.info("GeneralConditionsService.getConditions()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.GENERAL_CONDITIONS));
  }

  public updateCondition(data: any, uuid: string) {
    //this.logger.info("GeneralConditionsService.updateCondition()");
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.GENERAL_CONDITIONS) + "/" + uuid, data);
  }
}

export interface GeneralConditionModel {
  object: string;
  uuid: string;
  proposal_condition: string;
  deposite_invoice_condition: string;
  event_invoice_condition: string;
  adjusment_invoice_condition: string;
  credit_note_condition: string;

  proposal_condition_local: any;
  deposite_invoice_condition_local: any;
  event_invoice_condition_local: any;
  adjusment_invoice_condition_local: any;
  credit_note_condition_local: any;
}
