<div class="page-layout blank p-24" fusePerfectScrollbar>
  <h2>Test Page</h2>

  <div>
    <p>
      This is a TEMPORARY test page that act as a sandbox for various dev test i
      may have to do.
    </p>
    <p>{{ currentColor.hex }}</p>
    <!-- https://www.npmjs.com/package/ngx-color-picker -->
    <!-- https://github.com/zefoy/ngx-color-picker/tree/4.x.x/ -->
    <!-- https://zefoy.github.io/ngx-color-picker/ -->
    <!-- <input [(colorPicker)]="color" [style.background]="color"
            [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
            [cpAlphaChannel]="forced" [cpWidth]="300" />
        <input type="color"> -->
    <!-- https://github.com/bytelabsco/ngx-color-selector -->
    <!-- https://github.com/bytelabsco/ngx-color-selector/blob/master/src/color-palettes/material-colors.ts -->
    <!-- <bytelabs-color-selector
      [(color)]="currentColor"
      [options]="options"></bytelabs-color-selector> -->
  </div>

  <!-- <div class="app">
        <div class="container main-content">
            <div class="sidebar">
                <div class="picker">
                    <app-color-picker [color]="backgroundColor" [heading]="'Background'"
                        (event)="setColor('background', $event)"></app-color-picker>
                </div>
            </div>
        </div>
    </div> -->

  <div style="margin-bottom: 16px">================================</div>

  <ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div
      [ngClass]="
        (day.date | calendarDate: 'dayViewTitle') ===
        (selectedDayForEvents.date | calendarDate: 'dayViewTitle')
          ? 'selected-day'
          : ''
      "
    >
      <div class="cal-cell-top">
        <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
          day.badgeTotal
        }}</span>
        <span class="cal-day-number">{{
          day.date | calendarDate: "monthViewDayNumber" : locale
        }}</span>
      </div>
      <div class="cell-totals">
        <span
          *ngFor="let group of day.eventGroups"
          class="badge badge-{{ group[0] }}"
        >
          {{ group[1].length }}
        </span>
      </div>
    </div>
  </ng-template>

  <!-- (eventClicked)="editEvent('edit', $event.event)" (eventTimesChanged)="eventTimesChanged($event)" -->
  <!-- <mwl-calendar-month-view  
            [viewDate]="viewDate" 
            [events]="events" 
            [refresh]="refresh"
            (dayClicked)="onDatePickerChange($event.day)"
            (beforeViewRender)="beforeMonthViewRender($event)" 
            [cellTemplate]="customCellTemplate"
            weekStartsOn="0">
    </mwl-calendar-month-view> -->

  <!-- <mwl-calendar-month-view [viewDate]="viewDate" >
    </mwl-calendar-month-view> -->

  <!-- <mat-form-field class="create-width mt-12">
        <input fxFlex="100%" matInput [matDatepicker]="datePicker" [(ngModel)]="date"
            (dateChange)="onDatePickerChange($event)" min="1900-01-01" placeholder="{{ 'GENERIC.DATE' | translate }}"
             >
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field> -->

  <!-- <div style="width: 240px;">
        <mat-calendar [selected]="{start: date, end: date}" (selectedChange)="onDatePickerChange($event)"></mat-calendar>
    </div> -->

  <div style="margin-top: 16px">================================</div>
  <button
    mat-icon-button
    matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
    (click)="
      menuReportsService.buildAndRunVo(
        ReportsEnum.REPORT2_ADMIN_ACCOUNTING_CLIENT_LIST_STARS_TEMP,
        DistributionModeEnum.FILE,
        ExportFormatEnum.XLSX,
        ExportToEnum.DOWNLOAD,
        LocaleEnum.FR_CA,
        PaperEnum.LEGAL,
        paramsDate2
      )
    "
  >
    <mat-icon
      class="fa-lg"
      fontIcon="{{ iconsService.getIcon('XLS') }}"
      fontSet="fa"
    ></mat-icon>
  </button>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_CLIENT_LIST_STARS_TEMP',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_ACCOUNTING_CLIENT_LIST_STARS_TEMP</div>
  </div>

  <!--

    <div>================================</div>
    <div>============== CT ==============</div>
    <div>================================</div>
        <button mat-raised-button class="mr-12" (click)="menuReportsService.buildAndRunVo(
                                ReportsEnum.REPORT_CLIENT_CONTRACT_ACCOMODATIONS_STACK,
                                DistributionModeEnum.FILE,
                                ExportFormatEnum.PDF_STACK,
                                ExportToEnum.PRINT,
                                LocaleEnum.FR_CA,
                                PaperEnum.LETTER,
                                { uuid_order: 'baf089ba-9d56-498f-917e-251b30d19202'})">
            test stack print
        </button>

    
    <button mat-raised-button class="mr-12" (click)="menuReportsService.buildAndRunVo(
                                ReportsEnum.REPORT_CLIENT_PROPOSAL_CT_STACK,
                                DistributionModeEnum.FILE,
                                ExportFormatEnum.PDF_STACK,
                                ExportToEnum.PRINT,
                                LocaleEnum.FR_CA,
                                PaperEnum.A4,
                                { uuid_order: 'baf089ba-9d56-498f-917e-251b30d19202'})">
        {{ "GENERIC.PROPOSAL" | translate }} print
    </button>

    <button mat-raised-button class="mr-12" (click)="menuReportsService.buildAndRunVo(
                                    ReportsEnum.REPORT_CLIENT_PROPOSAL_CT_STACK,
                                    DistributionModeEnum.FILE,
                                    ExportFormatEnum.PDF_STACK,
                                    ExportToEnum.DOWNLOAD,
                                    LocaleEnum.FR_CA,
                                    PaperEnum.A4,
                                    { uuid_order: 'baf089ba-9d56-498f-917e-251b30d19202'})">
        {{ "GENERIC.PROPOSAL" | translate }} dl
    </button>

    <button mat-raised-button class="mr-12" (click)="menuReportsService.buildAndRunVo(
                                ReportsEnum.REPORT_CLIENT_ORDER_CT_STACK,
                                DistributionModeEnum.FILE,
                                ExportFormatEnum.PDF_STACK,
                                ExportToEnum.PRINT,
                                LocaleEnum.FR_CA,
                                PaperEnum.A4,
                                { uuid_order: 'f4ac581c-bce0-45f6-bac5-99aee4c60e21'})">
        {{ "GENERIC.ORDER" | translate }} print
    </button>

    <button mat-raised-button class="mr-12" (click)="menuReportsService.buildAndRunVo(
                                    ReportsEnum.REPORT_CLIENT_ORDER_CT_STACK,
                                    DistributionModeEnum.FILE,
                                    ExportFormatEnum.PDF_STACK,
                                    ExportToEnum.DOWNLOAD,
                                    LocaleEnum.FR_CA,
                                    PaperEnum.A4,
                                    { uuid_order: 'f4ac581c-bce0-45f6-bac5-99aee4c60e21'})">
        {{ "GENERIC.ORDER" | translate }} 
    </button>

    <button mat-raised-button (click)="menuReportsService.buildAndRunVo(
                                                    'REPORT_CLIENT_PDF_LIST_TEST',
                                                    DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                                                    ExportFormatEnum.PDF_STACK,
                                                    ExportToEnum.PRINT,
                                                    LocaleEnum.FR_CA,
                                                    PaperEnum.LETTER,
                                                    { uuid_order: 'f4ac581c-bce0-45f6-bac5-99aee4c60e21'},
                                                    null,
                                                    'GT_REPORT2')">
        REPORT_CLIENT_PDF_LIST_TEST
    </button>

    <button mat-raised-button (click)="menuReportsService.buildAndRunVo(
                                                        'REPORT2_MNGT_ORDERS_BY_DAY',
                                                        DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                                                        ExportFormatEnum.PDF_STACK,
                                                        ExportToEnum.PRINT,
                                                        LocaleEnum.FR_CA,
                                                        PaperEnum.LETTER,
                                                        { 
                                                            date_from: '2022-07-15',
                                                            date_to: '2022-07-16'
                                                        },
                                                        null,
                                                        'GT_REPORT2')">
        REPORT2_MNGT_ORDERS_BY_DAY
    </button> -->

  <!-- <button mat-raised-button
    
        (click)="menuReportsService.buildAndRunVo(
                                        'REPORT_CLIENT_PDF_LIST_TEST',
                                        DistributionModeEnum.FILE,
                                        ExportFormatEnum.PDF,
                                        ExportToEnum.PRINT,
                                        LocaleEnum.FR_CA,
                                        PaperEnum.A4,
                                        { date_from: '2022-07-15'})">
        REPORT_CLIENT_PDF_LIST_TEST
    </button>

    <button mat-raised-button (click)="menuReportsService.buildAndRunVo(
                                            'REPORT2_MNGT_ORDERS_BY_DAY',
                                            DistributionModeEnum.FILE,
                                            ExportFormatEnum.PDF,
                                            ExportToEnum.PRINT,
                                            LocaleEnum.FR_CA,
                                            PaperEnum.A4,
                                            { date_from: '2022-07-15'})">
        REPORT2_MNGT_ORDERS_BY_DAY
    </button> -->

  <div>===============================</div>

  <div fxLayout="row">
    <button
      mat-raised-button
      color="accent"
      aria-label="TEST ME"
      class="mb-12 ml-8"
      (click)="onError408()"
    >
      ERROR 408
    </button>

    <button
      mat-raised-button
      color="accent"
      aria-label="TEST ME"
      class="mb-12 ml-8"
      (click)="onError422()"
    >
      ERROR 422
    </button>

    <button
      mat-raised-button
      color="accent"
      aria-label="TEST ME"
      class="mb-12 ml-8"
      (click)="onError456()"
    >
      ERROR 456
    </button>

    <button
      mat-raised-button
      color="accent"
      aria-label="TEST ME"
      class="mb-12 ml-8"
      (click)="onError429()"
    >
      ERROR 429
    </button>

    <button
      mat-raised-button
      color="accent"
      aria-label="TEST ME"
      class="mb-12 ml-8"
      (click)="onError404()"
    >
      ERROR 404
    </button>

    <button
      mat-raised-button
      color="accent"
      aria-label="TEST ME"
      class="mb-12 ml-8"
      (click)="onError500()"
    >
      ERROR 500
    </button>

    <button
      mat-raised-button
      color="accent"
      aria-label="TEST ME"
      class="mb-12 ml-8"
      (click)="onError400()"
    >
      ERROR 400
    </button>

    <button
      mat-raised-button
      color="accent"
      class="mb-12 ml-8"
      (click)="setUpCalendarData()"
    >
      Set Calendar Data
    </button>

    <button
      mat-raised-button
      color="accent"
      class="mb-12 ml-8"
      (click)="testMaintenance()"
    >
      MAINTENANCE
    </button>
  </div>

  <!-- MENU BUTTON TEST -->
  <div fxLayout="row" class="mt-8 mb-8">
    <mat-checkbox
      labelPosition="after"
      color="accent"
      [(ngModel)]="isCanada"
      [ngModelOptions]="{ standalone: true }"
      class="mr-16"
    >
      is Canada
    </mat-checkbox>

    <button
      mat-button
      *ngIf="fileService.printEnabled()"
      class="primary"
      [matMenuTriggerFor]="isCanada ? menuPrintWithFormat : menuPrint"
    >
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon color="" class="s-28 subheader-menu-icon">{{
          getIcon("PRINT")
        }}</mat-icon>
        <span class="subheader-menu-text ml-8">{{
          "GENERIC.PRINT" | translate
        }}</span>
        <mat-icon class="">more_vert</mat-icon>
      </div>
    </button>
    <mat-menu #menuPrint="matMenu">
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.A4,
            paramsOrder2
          )
        "
      >
        {{ "GENERIC.ORDER" | translate }}
      </button>

      <!-- onPrintPurchaseOrder() report-client-order-purchase-order-production -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.A4,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.PURCHASE_ORDER" | translate }}
      </button>

      <!-- onPrintPurchaseOrderKitchen() report-mngt-order-purchase-order-kitchen -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.A4,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.PURCHASE_ORDER_KITCHEN" | translate }}
      </button>

      <!-- onPrintPurchaseOrderClient() report-client-order-purchase-order-client-copy -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER_PURCHASE_ORDER_CLIENT_COPY,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.A4,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.PURCHASE_ORDER_CLIENT" | translate }}
      </button>

      <!-- onPrintPurchaseOrderMaterial() report-mngt-order-purchase-material-->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_MATERIAL,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.A4,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.PURCHASE_ORDER_MATERIAL" | translate }}
      </button>
      <button mat-menu-item [matMenuTriggerFor]="print_en">Anglais</button>
    </mat-menu>

    <!--            CANADA SUB-MENU-->
    <mat-menu #menuPrintWithFormat="matMenu">
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            null,
            paramsOrder2
          )
        "
      >
        {{ "GENERIC.ORDER" | translate }}
      </button>

      <button mat-menu-item [matMenuTriggerFor]="printPurchaseOrder">
        {{ "ORDERS.PURCHASE_ORDER" | translate }}
      </button>
      <button mat-menu-item [matMenuTriggerFor]="printPurchaseOrderKitchen">
        {{ "ORDERS.PURCHASE_ORDER_KITCHEN" | translate }}
      </button>
      <button mat-menu-item [matMenuTriggerFor]="printPurchaseOrderClient">
        {{ "ORDERS.PURCHASE_ORDER_CLIENT" | translate }}
      </button>
      <button mat-menu-item [matMenuTriggerFor]="printPurchaseOrderMaterial">
        {{ "ORDERS.PURCHASE_ORDER_MATERIAL" | translate }}
      </button>
      <!--                <button mat-menu-item [matMenuTriggerFor]="print_en">Anglais</button>-->
      <button
        mat-menu-item
        [matMenuTriggerFor]="isCanada ? print_canada_en : print_en"
      >
        Anglais
      </button>
    </mat-menu>

    <mat-menu #printPurchaseOrder="matMenu">
      <!-- onPrintPurchaseOrder('fr-CA', 'letter') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.LETTER,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LETTER_FORMAT" | translate }}
      </button>

      <!-- onPrintPurchaseOrder('fr-CA', 'legal') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.LEGAL,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LEGAL_FORMAT" | translate }}
      </button>
    </mat-menu>
    <mat-menu #printPurchaseOrderKitchen="matMenu">
      <!-- onPrintPurchaseOrderKitchen('fr-CA', 'letter') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.LETTER,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LETTER_FORMAT" | translate }}
      </button>

      <!-- onPrintPurchaseOrderKitchen('fr-CA', 'legal') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.LEGAL,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LEGAL_FORMAT" | translate }}
      </button>
    </mat-menu>
    <mat-menu #printPurchaseOrderClient="matMenu">
      <!-- onPrintPurchaseOrderClient('fr-CA', 'letter') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER_PURCHASE_ORDER_CLIENT_COPY,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.LETTER,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LETTER_FORMAT" | translate }}
      </button>

      <!-- onPrintPurchaseOrderClient('fr-CA', 'legal') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER_PURCHASE_ORDER_CLIENT_COPY,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.LEGAL,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LEGAL_FORMAT" | translate }}
      </button>
    </mat-menu>

    <mat-menu #printPurchaseOrderMaterial="matMenu">
      <!-- onPrintPurchaseOrderMaterial('fr-CA', 'letter') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_MATERIAL,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.LETTER,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LETTER_FORMAT" | translate }}
      </button>

      <!-- onPrintPurchaseOrderMaterial('fr-CA', 'legal') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_MATERIAL,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.LEGAL,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LEGAL_FORMAT" | translate }}
      </button>
    </mat-menu>

    <mat-menu #print_en="matMenu">
      <!-- onPrintPurchaseOrder('en-CA') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.EN_CA,
            PaperEnum.A4,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.PURCHASE_ORDER" | translate }}
      </button>

      <!-- onPrintPurchaseOrderKitchen('en-CA') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.EN_CA,
            PaperEnum.A4,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.PURCHASE_ORDER_KITCHEN" | translate }}
      </button>

      <!-- onPrintPurchaseOrderClient('en-CA') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER_PURCHASE_ORDER_CLIENT_COPY,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.EN_CA,
            PaperEnum.A4,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.PURCHASE_ORDER_CLIENT" | translate }}
      </button>

      <!-- onPrintPurchaseOrderMaterial('en-CA') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_MATERIAL,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.EN_CA,
            PaperEnum.A4,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.PURCHASE_ORDER_MATERIAL" | translate }}
      </button>
    </mat-menu>

    <mat-menu #print_canada_en="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="printPurchaseOrderEN">
        {{ "ORDERS.PURCHASE_ORDER" | translate }}
      </button>
      <button mat-menu-item [matMenuTriggerFor]="printPurchaseOrderKitchenEN">
        {{ "ORDERS.PURCHASE_ORDER_KITCHEN" | translate }}
      </button>
      <button mat-menu-item [matMenuTriggerFor]="printPurchaseOrderClientEN">
        {{ "ORDERS.PURCHASE_ORDER_CLIENT" | translate }}
      </button>
      <button mat-menu-item [matMenuTriggerFor]="printPurchaseOrderMaterialEN">
        {{ "ORDERS.PURCHASE_ORDER_MATERIAL" | translate }}
      </button>
    </mat-menu>
    <mat-menu #printPurchaseOrderEN="matMenu">
      <!-- onPrintPurchaseOrder('en-CA', 'letter') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.EN_CA,
            PaperEnum.LETTER,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LETTER_FORMAT" | translate }}
      </button>

      <!-- onPrintPurchaseOrder('en-CA', 'legal') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.EN_CA,
            PaperEnum.LEGAL,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LEGAL_FORMAT" | translate }}
      </button>
    </mat-menu>

    <mat-menu #printPurchaseOrderKitchenEN="matMenu">
      <!-- onPrintPurchaseOrderKitchen('en-CA', 'letter') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.EN_CA,
            PaperEnum.LETTER,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LETTER_FORMAT" | translate }}
      </button>

      <!-- onPrintPurchaseOrderKitchen('en-CA', 'legal') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.EN_CA,
            PaperEnum.LEGAL,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LEGAL_FORMAT" | translate }}
      </button>
    </mat-menu>

    <mat-menu #printPurchaseOrderClientEN="matMenu">
      <!-- onPrintPurchaseOrderClient('en-CA', 'letter') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER_PURCHASE_ORDER_CLIENT_COPY,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.EN_CA,
            PaperEnum.LETTER,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LETTER_FORMAT" | translate }}
      </button>

      <!-- onPrintPurchaseOrderClient('en-CA', 'legal') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_ORDER_PURCHASE_ORDER_CLIENT_COPY,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.EN_CA,
            PaperEnum.LEGAL,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LEGAL_FORMAT" | translate }}
      </button>
    </mat-menu>

    <mat-menu #printPurchaseOrderMaterialEN="matMenu">
      <!-- onPrintPurchaseOrderMaterial('en-CA', 'letter') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_MATERIAL,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.EN_CA,
            PaperEnum.LETTER,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LETTER_FORMAT" | translate }}
      </button>

      <!-- onPrintPurchaseOrderMaterial('en-CA', 'legal') -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_MATERIAL,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.EN_CA,
            PaperEnum.LEGAL,
            paramsOrder2
          )
        "
      >
        {{ "ORDERS.LEGAL_FORMAT" | translate }}
      </button>
    </mat-menu>

    <!-- INVOICE REPORT MENU -->
    <button mat-button [matMenuTriggerFor]="invoiceReportMenu">
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="s-28">{{ getIcon("REPORT") }}</mat-icon>
        <span class="ml-8">{{ "GENERIC.REPORT" | translate }}</span>
        <mat-icon class="">more_vert</mat-icon>
      </div>
    </button>
    <mat-menu #invoiceReportMenu="matMenu">
      <!-- onPrintInvoiceReport() -->
      <button
        mat-menu-item
        *ngIf="fileService.printEnabled()"
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_INVOICE,
            DistributionModeEnum.RAW_HTML,
            ExportFormatEnum.HTML,
            ExportToEnum.PRINT,
            LocaleEnum.FR_CA,
            PaperEnum.A4,
            paramsInvoice
          )
        "
      >
        {{ "GENERIC.PRINT" | translate }}
      </button>

      <!-- onDowloadInvoiceReport() -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.buildAndRunVo(
            ReportsEnum.REPORT_CLIENT_INVOICE,
            DistributionModeEnum.FILE,
            ExportFormatEnum.PDF,
            ExportToEnum.DOWNLOAD,
            LocaleEnum.FR_CA,
            PaperEnum.A4,
            paramsInvoice,
            'invoice'
          )
        "
      >
        {{ "GENERIC.DOWNLOAD" | translate }}
      </button>

      <!-- onSendInvoiceReport() -->
      <button
        mat-menu-item
        (click)="
          menuReportsService.sendReport(
            paramsInvoice.uuid_order,
            invoiceComposeService
          )
        "
      >
        {{ "GENERIC.SEND" | translate }}
      </button>
    </mat-menu>
  </div>

  <!-- <div fxLayout="row">
        <button mat-raised-button color="accent" aria-label="TRIGGER DUMP" class="mb-12 ml-8" (click)="onAPIDump()">
            TRIGGER DUMP
        </button>
    </div> -->

  <!-- <div fxLayout="row">
        <button mat-raised-button color="accent" aria-label="TRIGGER DUMP" class="mb-12 ml-8" (click)="onReportTest()">
            PREVIEW Report 2
        </button>

        <button mat-raised-button color="accent" aria-label="TRIGGER DUMP" class="mb-12 ml-8" (click)="onPrintReportTest()">
            PRINT Report 2
        </button>
    </div> -->

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_MNGT_PROD_LIST_BY_PROD_ZONE',
          DistributionModeEnum.RAW_HTML,
          ExportFormatEnum.HTML,
          ExportToEnum.PREVIEW,
          paramsOrderDate
        )
      "
    >
    </app-report-button>
    <div>preview REPORT_MNGT_PROD_LIST_BY_PROD_ZONE</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_ALLERGIES',
          DistributionModeEnum.RAW_HTML,
          ExportFormatEnum.HTML,
          ExportToEnum.PREVIEW,
          paramsOrderDate
        )
      "
    >
    </app-report-button>
    <div>preview REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_ALLERGIES</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.PRINT,
          paramsOrderDate2
        )
      "
    >
    </app-report-button>
    <div>print REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <!-- V1 -->
    <!-- <app-report-button 
            [useV1]="true" 
            (onV1Click)="onReportv1()" 
            [vo]="reportFactoryService.getVO('REPORT_CLIENT_ORDER', 
                                            DistributionModeEnum.FILE, 
                                            ExportFormatEnum.PDF, 
                                            ExportToEnum.DOWNLOAD)">
        </app-report-button> -->

    <!-- V2 -->
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_MNGT_MATERIAL_LIST',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>

    <div class="">REPORT_ALL_CLIENT_HISTORICAL_ACCOUNT_GOD</div>
    <!-- <app-report-button 
                    [vo]="reportFactoryService.getVO('REPORT_DATA_PRODUCTS_LIST', DistributionModeEnum.RAW_HTML, ExportFormatEnum.HTML, ExportToEnum.PRINT)"
                    [color]="'accent'"
                    [useV1]="false">
                </app-report-button>
        
                <app-report-button
                    [vo]="reportFactoryService.getVO('REPORT_DATA_PRODUCTS_LIST', DistributionModeEnum.FILE, ExportFormatEnum.PDF, ExportToEnum.DOWNLOAD)"
                    [color]="'primary'"
                    [useV1]="true">
                </app-report-button>
        
                <app-report-button 
                    [vo]="reportFactoryService.getVO('REPORT2_DATA_PRODUCTS_LIST_XLSX', DistributionModeEnum.FILE, ExportFormatEnum.XLSX, ExportToEnum.PREVIEW)">
                </app-report-button> -->
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsOrder2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_CLIENT_INVOICE',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsInvoice
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_CLIENT_INVOICE</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_CLIENT_EVENT_RECEIPT',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsEvent
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_CLIENT_EVENT_RECEIPT</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_MNGT_NEW_CLIENT',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_MNGT_NEW_CLIENT</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_CLIENT_ORDER',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsOrder2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_CLIENT_ORDER</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_DATA_PLACES_LIST',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsOrder2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_DATA_PLACES_LIST</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_DATA_EMPLOYEES_LIST',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsOrder2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_DATA_EMPLOYEES_LIST</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_DATA_ENTERPRISES_LIST',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsOrder2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_DATA_ENTERPRISES_LIST</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_DATA_MATERIALS_LIST',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsOrder2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_DATA_MATERIALS_LIST</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_DATA_PRODUCTS_LIST',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsOrder2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_DATA_PRODUCTS_LIST</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_AGING_OF_CLIENT_ACCOUNT_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_ACCOUNTING_AGING_OF_CLIENT_ACCOUNT_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_AGING_OF_ENTERPRISE_ACCOUNT_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">
      REPORT2_ADMIN_ACCOUNTING_AGING_OF_ENTERPRISE_ACCOUNT_XLSX
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_BALANCE_OF_CLIENT_ENTERPRISE_ACCOUNT_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">
      REPORT2_ADMIN_ACCOUNTING_BALANCE_OF_CLIENT_ENTERPRISE_ACCOUNT_XLSX
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_DEPOSITE_TRACKING_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_ACCOUNTING_DEPOSITE_TRACKING_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_LEDGER_ACCOUNT_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_ACCOUNTING_LEDGER_ACCOUNT_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_LIST_OF_INVOICES_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_ACCOUNTING_LIST_OF_INVOICES_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_PAYMENT_JOURNAL_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_ACCOUNTING_PAYMENT_JOURNAL_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_SUMMARY_PAYMENT_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_ACCOUNTING_SUMMARY_PAYMENT_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_PER_DAY_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">
      REPORT2_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_PER_DAY_XLSX
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_MNGT_DELIVERY_A_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_MNGT_DELIVERY_A_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_MNGT_DELIVERY_B_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_MNGT_DELIVERY_B_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_MNGT_TAKEOUT_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_MNGT_TAKEOUT_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_DATA_MATERIAL_LIST_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_DATA_MATERIAL_LIST_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_DATA_PRODUCTS_LIST_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_DATA_PRODUCTS_LIST_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_DATA_PLACES_LIST_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_DATA_PLACES_LIST_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_DATA_EMPLOYEES_LIST_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_DATA_EMPLOYEES_LIST_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_DATA_ENTERPRISES_LIST_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_DATA_ENTERPRISES_LIST_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_DATA_CLIENTS_LIST_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_DATA_CLIENTS_LIST_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_SYSTEM_CONTACTS_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_ACCOUNTING_SYSTEM_CONTACTS_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_ACCOUNTING_SYSTEM_INVOICES_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_ACCOUNTING_SYSTEM_INVOICES_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_CASHED_DEPOSIT_BEFORE_INVOICE_AT_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_CASHED_DEPOSIT_BEFORE_INVOICE_AT_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_SALE_STATS_PRODUCT_BY_CATEGORY_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_SALE_STATS_PRODUCT_BY_CATEGORY_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_SALE_STATS_BY_EVENT_TYPE_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_SALE_STATS_BY_EVENT_TYPE_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_SALE_STATS_BY_CLIENT_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_SALE_STATS_BY_CLIENT_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_SALE_STATS_BY_ENTERPRISE_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_SALE_STATS_BY_ENTERPRISE_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_SALE_STATS_BY_SELLER_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_SALE_STATS_BY_SELLER_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_SALE_STATS_BY_DELIVERY_TYPE_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_SALE_STATS_BY_DELIVERY_TYPE_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_ADMIN_SALE_STATS_BY_PRODUCT_VELOCITY_XLSX',
          DistributionModeEnum.FILE,
          ExportFormatEnum.XLSX,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_ADMIN_SALE_STATS_BY_PRODUCT_VELOCITY_XLSX</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsOrder2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_MNGT_ORDER_PURCHASE_MATERIAL',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsOrder2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_MNGT_ORDER_PURCHASE_MATERIAL</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_DATA_CLIENTS_LIST',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsOrder2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_DATA_CLIENTS_LIST</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_CLIENT_ORDER_PURCHASE_ORDER_CLIENT_COPY',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsOrder2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_CLIENT_ORDER_PURCHASE_ORDER_CLIENT_COPY</div>
  </div>

  <div>------</div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_CLIENT_STATEMENT_CLIENT',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsClient
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_CLIENT_STATEMENT_CLIENT</div>
    <!-- <div style="margin-left: 16px;">500</div> -->
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_CLIENT_STATEMENT_ENTERPRISE',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsEnterprise
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_CLIENT_STATEMENT_ENTERPRISE</div>
    <!-- <div style="font-weight: 500;  margin-left: 16px;">500</div> -->
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsOrder2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN</div>
    <!-- <div style="font-weight: 500;  margin-left: 16px;">500</div> -->
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_MNGT_PROD_LIST_BY_PROD_ZONE',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_MNGT_PROD_LIST_BY_PROD_ZONE</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_MNGT_PRODUCT_AND_DRINK_LIST',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_MNGT_PRODUCT_AND_DRINK_LIST</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_MNGT_EVENT_BY_SELLER',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_MNGT_EVENT_BY_SELLER</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_MNGT_EVENT_BY_STATUS',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_MNGT_EVENT_BY_STATUS</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_ADMIN_ACCOUNTING_AGING_OF_CLIENT_ACCOUNT',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_ADMIN_ACCOUNTING_AGING_OF_CLIENT_ACCOUNT</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_ADMIN_ACCOUNTING_AGING_OF_ENTERPRISE_ACCOUNT',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_ADMIN_ACCOUNTING_AGING_OF_ENTERPRISE_ACCOUNT</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_ADMIN_ACCOUNTING_DEPOSITE_TRACKING',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_ADMIN_ACCOUNTING_DEPOSITE_TRACKING</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_ADMIN_ACCOUNTING_SUMMARY_PAYMENT',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_ADMIN_ACCOUNTING_SUMMARY_PAYMENT</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT_ADMIN_CASHED_DEPOSIT_AFTER_INVOICE_AT',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF,
          ExportToEnum.DOWNLOAD,
          paramsDate2
        )
      "
    >
    </app-report-button>
    <div class="">REPORT_ADMIN_CASHED_DEPOSIT_AFTER_INVOICE_AT</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <app-report-button
      [vo]="
        reportFactoryService.getVO(
          'REPORT2_MNGT_ORDERS_BY_DAY',
          DistributionModeEnum.FILE,
          ExportFormatEnum.PDF_STACK,
          ExportToEnum.DOWNLOAD,
          paramsDate3,
          null,
          localFR,
          paperLetter
        )
      "
    >
    </app-report-button>
    <div class="">REPORT2_MNGT_ORDERS_BY_DAY</div>
  </div>

  <!--
    PDF
     -->

  <!-- <div class="pt-32">Prime Data Table</div>

    <div>

        <p-table #dt2
                 [columns]="primeNGCols"
                 [value]="primeNGData"
                 [lazy]="true"
                 (onLazyLoad)="onLazyLoadPagination($event)"
                 [paginator]="true"
                 [rows]="pageSize"
                 [totalRecords]="totalRecords"
                 [loading]="loading"
                 dataKey="name"
                 [resizableColumns]="true"
                 [reorderableColumns]="true"
                 selectionMode="single"
                 [(selection)]="selectedEvent1"
                 stateStorage="session"
                 stateKey="gt2test4-local">


            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn pReorderableColumn>
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field" class="ui-fluid">
                        <input pInputText type="text"
                               (input)="dt2.filter($event.target.value, col.field, col.filterMatchMode)"
                               [value]="dt2.filters[col.field]?.value">
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns">
                        {{rowData[col.field]}}
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div> -->

  <div id="calendar"></div>
</div>
