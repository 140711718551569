import { NgModule } from '@angular/core';
import { RoomManagementComponent } from './room-management.component';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { RouterModule } from '@angular/router';
import { EventRoomService } from '../../api/event-room.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from 'primeng/editor';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CreateOrderButtonsComponent } from '../../components/create-order-buttons/create-order-buttons.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.EN.ROOM_MANAGEMENT,
    component: RoomManagementComponent,
  },
  {
    path: AppRoutesName.FR.ROOM_MANAGEMENT,
    component: RoomManagementComponent,
  },
];

@NgModule({
  declarations: [RoomManagementComponent],
  imports: [
    CreateOrderButtonsComponent,
    MatInputModule,
    GenericAlertComponent,
    MatButtonModule,
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    EditorModule,
    NgxPermissionsModule,
    FormsModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    MatIconModule,
    TranslateModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    NgxMaterialTimepickerModule,
  ],
  exports: [RoomManagementComponent],
  providers: [EventRoomService],
})
export class RoomManagementModule {}
