export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_SHOP: {
      TITLE: "Nouvelle commande en ligne",
      SELECT_TYPE: "Sélectionner un type de commande en ligne",
      INTEGRATION_TYPE: "Type d’intégration",
      SELL_TYPE: "Mode de vente",
    },
  },
};
