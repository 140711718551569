import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class DocumentsService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public createProposal(proposal: any, eventUUID: string) {
    // this.logger.info("DocumentsService.createProposal() -> " + JSON.stringify(proposal));
    // this.logger.info("DocumentsService.createProposal() -> eventUUID: " + eventUUID);
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT) + "documents/proposal/" + eventUUID, proposal);
  }
}
