export const locale = {
  lang: "en-CA",
  data: {
    CREATE_DELIVERY_REQUEST_TEMPLATE: {
      TITLE: "New delivery request template",
      EDIT_TITLE: "Edit delivery request template",
      SELECT_FLOW: "Define or select a flow",
      SHOW_ON_ORDER: "Show on orders",
      SHOW_ON_PROPOSAL: "Show on proposals",
      SHOW_ON_INVOICE: "Show on invoices",
      SHOW_ON_MATERIAL_REPORT: "Show on equipment reports",
      NEED_INSTALATION: "Need installation",

      SHOW_ON_PURCHASE_ORDER_PRODUCTION: "Show on purchase order",
      SHOW_ON_PURCHASE_ORDER_CLIENT_COPY: "Show on purchase order - Client",
      SHOW_ON_CLIENT_PROPOSAL_AND_ORDER: "Show on proposal and order",
      SHOW_ON_PURCHASE_ORDER_KITCHEN: "Show on purchase order - Kitchen",
      SHOW_ON_PURCHASE_MATERIAL: "Show on equipment purchase",

      TEMPLATE_NAME: "Template name",
      TEMPLATES: "Select a delivery request template",
      TEMPLATES_EMPTY: "No templates have been created yet.",
      TEMPLATES_EMPTY_CLICK: "Click here to navigate to the create template section",
    },
  },
};
