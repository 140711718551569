import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { EditorModule } from 'primeng/editor';
import { FuseSidebarModule } from '../../../../@fuse/components/sidebar/sidebar.module';
import { FuseWidgetModule } from '../../../../@fuse/components/widget/widget.module';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { AllergensService } from '../../api/allergens.service';
import { InvoicesCategoriesService } from '../../api/invoices-categories.service';
import { PersonnalTitlesService } from '../../api/personnal-titles.service';
import { ProductCategoryService } from '../../api/product-category.service';
import { ProductionZonesService } from '../../api/production-zones.service';
import { ProductsService } from '../../api/products.service';
import { TaxesService } from '../../api/taxes.service';
import { UnitService } from '../../api/unit.service';
import { WrappingService } from '../../api/wrapping.service';
import { CreateMaterialComponent } from '../../components/dialogs/create-material/create-material.component';
import { CreateProductComponent } from '../../components/dialogs/create-product/create-product.component';
import { CanDeactivateGuard } from '../../gards/can-deactivate-guard.service';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { ProductComponent } from './product/product.component';
import { ProductsComponent } from './products.component';
import { ProductUpsaleModule } from './product-upsale/product-upsale.module';
import { ProductUpsaleComponent } from './product-upsale/product-upsale.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TableSizePipeModule } from '../../modules/table-size.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CopyWrapperComponent } from '../../components/copy-wrapper/copy-wrapper.component';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatSortModule } from '@angular/material/sort';
import { CommentSectionComponent } from '../../components/comment-section/comment-section.component';
import { AddressComponent } from '../../components/address/address.component';
import { CreateComboComponent } from '../../components/create-combo/create-combo.component';
import { CreatedUpdatedAtComponent } from '../../components/created-updated-at/created-updated-at.component';
import { DragDropListsComponent } from '../../components/dialogs/drag-drop-lists/drag-drop-lists.component';
import { DynamicTableComponent } from '../../components/dynamic-table/dynamic-table.component';
import { EditComboComponent } from '../../components/edit-combo/edit-combo.component';
import { Gt2SelectComponent } from '../../components/gt2-select/gt2-select.component';
import { SectionHistoryPanelComponent } from '../../components/section-history-panel/section-history-panel.component';
import { TagsByModelComponent } from '../../components/tags-by-model/tags-by-model.component';
import { ContactComponent } from '../../components/contact/contact.component';
import { ContactService } from '../../components/contact/contact.service';
import { ChoiceComboSelectDialogComponent } from './choice-combo-select-dialog/choice-combo-select-dialog.component';
import { ProductPictureUploadComponent } from './product-picture-upload/product-picture-upload.component';
import { ProductShopsComponent } from './product-shops/product-shops.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.FR.PRODUCTS,
    component: ProductsComponent,
  },
  {
    path: AppRoutesName.FR.PRODUCTS + '/:slug/:id',
    component: ProductComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.FR.PRODUCTS + '/:slug/:id/upsale',
    component: ProductUpsaleComponent,
  },
  {
    path: AppRoutesName.EN.PRODUCTS,
    component: ProductsComponent,
  },
  {
    path: AppRoutesName.EN.PRODUCTS + '/:slug/:id',
    component: ProductComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.EN.PRODUCTS + '/:slug/:id/upsale',
    component: ProductUpsaleComponent,
  },
];

@NgModule({
  declarations: [ProductsComponent],
  imports: [
    MatTabsModule,
    MatOptionModule,
    CreateMaterialComponent,
    CopyWrapperComponent,
    MatChipsModule,
    ProductComponent,
    MatSelectModule,
    MatSortModule,
    MatFormFieldModule,
    MatButtonModule,
    NgxPermissionsModule,
    CreateProductComponent,
    MatInputModule,
    MatTableModule,
    TableSizePipeModule,
    TranslateModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatRippleModule,
    CdkTreeModule,
    CdkTableModule,
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    MatBadgeModule,
    RouterModule.forChild(routes),
    FuseWidgetModule,
    NgxChartsModule,
    DynamicTableComponent,
    EditorModule,
    FormsModule,
    AddressComponent,
    ContactComponent,
    CommentSectionComponent,
    CreatedUpdatedAtComponent,
    Gt2SelectComponent,
    CreateComboComponent,
    EditComboComponent,
    CurrencyMaskModule,
    DragDropListsComponent,
    TagsByModelComponent,
    FuseSidebarModule,
    SectionHistoryPanelComponent,
    ProductShopsComponent,
    ProductPictureUploadComponent,
    ProductUpsaleModule,
    ChoiceComboSelectDialogComponent,
  ],
  exports: [ProductsComponent],
  providers: [
    ProductsService,
    ContactService,
    UnitService,
    ProductionZonesService,
    WrappingService,
    TaxesService,
    InvoicesCategoriesService,
    ProductCategoryService,
    AllergensService,
    PersonnalTitlesService,
    DatatableHelperService,
  ],
})
export class ProductsModule {}
