import { HttpClient } from "@angular/common/http";
import { Gt2ApiService } from "./gt2-api.service";
import { ApiRoutes } from "./ApiRoutes";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class CreateClientService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public createClient(data: any): any {
    // this.logger.info("CreateClientService.createClient() -> data: " + JSON.stringify(data));
    return this.http.post<any>(this.api.createUrl(ApiRoutes.CREATE_CLIENT), data);
  }

  public createEnterprise(data: any): any {
    // this.logger.info("CreateClientService.createEnterprise()");
    return this.http.post<any>(this.api.createUrl(ApiRoutes.CREATE_ENTERPRISE), data);
  }

  public createClientForEnterprise(data: any, uuid: string): any {
    // this.logger.info("CreateClientService.createClient()");
    return this.http.post<any>(this.api.createUrl(ApiRoutes.CREATE_CLIENT_FOR_ENTERPRISE) + uuid, data);
  }

  public createClientAndEnterprise(data: any): any {
    // this.logger.info("CreateClientService.createClient()");
    return this.http.post<any>(this.api.createUrl(ApiRoutes.CREATE_CLIENT_ENTERPRISE), data);
  }

  public createClientEnterpriseAndBranch(data: any): any {
    // this.logger.info("CreateClientService.createClient()");
    return this.http.post<any>(this.api.createUrl(ApiRoutes.CREATE_CLIENT_ENTERPRISE_BRANCH), data);
  }

  public attachClientToEnterprise(data: any): any {
    // this.logger.info("CreateClientService.createClient()");
    return this.http.post<any>(this.api.createUrl(ApiRoutes.CREATE_CLIENT_ENTERPRISE_BRANCH), data);
  }
}
