<div style="max-height: 100%; overflow: auto">
  <div *ngIf="uiMode === 'DEPOSIT'">
    <div matDialogTitle *ngIf="mode === 'CREATE'" class="event-dialog-title">
      {{ "CREATE_CASHING.DEPOSIT_TITLE" | translate }}
    </div>
    <div matDialogTitle *ngIf="mode === 'EDIT'" class="event-dialog-title">
      {{ "CREATE_CASHING.DEPOSIT_EDIT_TITLE" | translate }}
    </div>

    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      [formGroup]="form"
      class="item-full-width px-8"
    >
      <!-- DEPOSIT RESQUEST LIST -->
      <div
        *ngIf="depositIn.deposit_request && mode !== 'CREATE'"
        class="amount-label mb-8"
      >
        {{ "CREATE_CASHING.DEPOSIT_REQUEST" | translate }}:
        {{ depositIn.deposit_request.deposit_number }} |
        {{ depositIn.deposit_request.amount }}
      </div>
      <app-gt2-select
        *ngIf="mode === 'CREATE'"
        [service]="depositRequestService"
        [placeHolder]="'CREATE_CASHING.DEPOSIT_REQUEST' | translate"
        [label]="''"
        [selectedModel]="depositIn.deposit_request"
        (changeEvent)="onDepositRequestChange($event)"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
        [nullableUseNone]="false"
        fxFlex="100%"
        class="item-full-width"
      ></app-gt2-select>

      <!-- DATE -->
      <mat-form-field fxFlex="100%" class="item-full-width">
        <mat-label>{{ "GENERIC.DATE" | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="receiveAtDate"
          [(ngModel)]="depositIn.received_at"
          min="1900-01-01"
          formControlName="received_at"
          required
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="receiveAtDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #receiveAtDate></mat-datepicker>
        <mat-error *ngIf="form.controls['received_at'].invalid"
          >{{ "GENERIC.DATE_TODAY_DOWN_RANGE_INVALID" | translate }}
        </mat-error>
      </mat-form-field>

      <!-- AMOUNT (in this case it's just a label, no input) -->
      <div class="amount-label my-4">
        {{ "GENERIC.AMOUNT" | translate }}:
        <span *ngIf="depositIn.deposit_request">{{
          depositIn.deposit_request.amount_label
        }}</span>
      </div>
      <!-- <div class="amount-label my-8">{{ 'GENERIC.AMOUNT' | translate}}: {{ depositIn.amount_label }}</div> -->

      <!-- <mat-form-field *ngIf="mode === 'CREATE'" fxFlex="100%" class="item-full-width">
            <input matInput
              type="text"
              placeholder="{{ 'GENERIC.AMOUNT' | translate}}"
              formControlName="amount"
              pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
              [(ngModel)]="depositIn.amount">
            <mat-error *ngIf="form.controls['amount'].invalid">
                {{'GENERIC.INPUT_NUMBER_ABOVE_ZERO' | translate }}
            </mat-error>
        </mat-form-field> -->

      <!-- PAYMENT MODES -->
      <app-gt2-select
        name="'select1"
        [service]="paymentModesService"
        [placeHolder]="'CREATE_CASHING.PAYMENT_MODES' | translate"
        [label]="''"
        [isRequired]="true"
        [selectedModel]="depositIn.payment_mode"
        (changeEvent)="onPaymentModeChange($event)"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
        [nullableUseNone]="false"
        (modelSetEvent)="onPaymentModeListLoaded($event)"
        fxFlex="100%"
        class="item-full-width"
        required
      ></app-gt2-select>

      <!-- REF -->
      <div [formGroup]="formRef" class="item-full-width">
        <mat-form-field class="item-full-width" fxFlex="100%">
          <mat-label>{{
            "CREATE_CASHING.REFERENCE_NUMBER" | translate
          }}</mat-label>
          <input
            matInput
            formControlName="ref_number"
            autocomplete="nope"
            type="text"
            [(ngModel)]="depositIn.ref_number"
          />
          <mat-error *ngIf="formRef.controls['ref_number'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!-- UDEM NEW DEPOSIT IN SPECIAL FIELDS-->
      <div
        [formGroup]="formUdm"
        *ngIf="moduleService.modulesAreSet && moduleService.hasUDEMReport()"
        fxLayout="column"
        fxLayoutAlign="center start"
        class="item-full-width"
      >
        <mat-form-field class="item-full-width" fxFlex="100%">
          <mat-label>{{ "CREATE_CASHING.ACCOUNT" | translate }}</mat-label>
          <input
            matInput
            formControlName="account"
            autocomplete="nope"
            type="text"
            [(ngModel)]="depositIn.account"
          />
          <mat-error *ngIf="formUdm.controls['account'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="item-full-width" fxFlex="100%">
          <mat-label>{{ "CREATE_CASHING.UDEM_PROJECT" | translate }}</mat-label>
          <input
            matInput
            formControlName="project_udem"
            autocomplete="nope"
            type="text"
            [(ngModel)]="depositIn.project_udem"
          />
          <mat-error *ngIf="formUdm.controls['project_udem'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="item-full-width" fxFlex="100%">
          <mat-label>{{ "CREATE_CASHING.ACTIVITY" | translate }}</mat-label>
          <input
            matInput
            formControlName="activity"
            autocomplete="nope"
            type="text"
            [(ngModel)]="depositIn.activity"
          />
          <mat-error *ngIf="formUdm.controls['activity'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="item-full-width" fxFlex="100%">
          <mat-label>{{ "CREATE_CASHING.TYPE_SRCE" | translate }}</mat-label>
          <input
            matInput
            formControlName="type_srce"
            autocomplete="nope"
            type="text"
            [(ngModel)]="depositIn.type_srce"
          />
          <mat-error *ngIf="formUdm.controls['type_srce'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div *ngIf="uiMode === 'INVOICE'">
    <div matDialogTitle *ngIf="mode === 'CREATE'" class="event-dialog-title">
      {{ "CREATE_CASHING.INVOICE_TITLE" | translate }}
    </div>
    <div matDialogTitle *ngIf="mode === 'EDIT'" class="event-dialog-title">
      {{ "CREATE_CASHING.INVOICE_EDIT_TITLE" | translate }}
    </div>

    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      [formGroup]="form"
      class="item-full-width px-8"
    >
      <!-- DATE -->
      <mat-form-field fxFlex="100%" class="item-full-width">
        <mat-label>{{ "GENERIC.DATE" | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="receiveAtDate"
          [(ngModel)]="depositIn.received_at"
          min="1900-01-01"
          formControlName="received_at"
          required
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="receiveAtDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #receiveAtDate></mat-datepicker>
        <mat-error *ngIf="form.controls['received_at'].invalid"
          >{{ "GENERIC.DATE_TODAY_DOWN_RANGE_INVALID" | translate }}
        </mat-error>
      </mat-form-field>

      <!-- AMOUNT -->
      <mat-form-field fxFlex="100%" class="item-full-width">
        <mat-label>{{ "GENERIC.AMOUNT" | translate }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="amount"
          autocomplete="nope"
          pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
          [(ngModel)]="depositIn.amount"
          required
        />
        <mat-error *ngIf="form.controls['amount'].invalid">
          {{ "GENERIC.INPUT_NUMBER_ABOVE_ZERO" | translate }}
        </mat-error>
      </mat-form-field>

      <!-- PAYMENT MODES -->
      <app-gt2-select
        name="select2"
        [service]="paymentModesService"
        [placeHolder]="'CREATE_CASHING.PAYMENT_MODES' | translate"
        [label]="''"
        [isRequired]="true"
        [selectedModel]="depositIn.payment_mode"
        (changeEvent)="onPaymentModeChange($event)"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
        [nullableUseNone]="false"
        fxFlex="100%"
        class="item-full-width"
        required
      ></app-gt2-select>

      <!-- REF -->
      <div [formGroup]="formRef" class="item-full-width">
        <mat-form-field class="item-full-width" fxFlex="100%">
          <mat-label>{{
            "CREATE_CASHING.REFERENCE_NUMBER" | translate
          }}</mat-label>
          <input
            matInput
            formControlName="ref_number"
            autocomplete="nope"
            type="text"
            [(ngModel)]="depositIn.ref_number"
          />
          <mat-error *ngIf="formRef.controls['ref_number'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!-- UDEM NEW DEPOSIT IN SPECIAL FIELDS-->
      <div
        [formGroup]="formUdm"
        *ngIf="moduleService.modulesAreSet && moduleService.hasUDEMReport()"
        fxLayout="column"
        fxLayoutAlign="center start"
        class="item-full-width"
      >
        <mat-form-field class="item-full-width" fxFlex="100%">
          <mat-label>{{ "CREATE_CASHING.ACCOUNT" | translate }}</mat-label>
          <input
            matInput
            formControlName="account"
            autocomplete="nope"
            type="text"
            [(ngModel)]="depositIn.account"
          />
          <mat-error *ngIf="formUdm.controls['account'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="item-full-width" fxFlex="100%">
          <mat-label>{{ "CREATE_CASHING.UDEM_PROJECT" | translate }}</mat-label>
          <input
            matInput
            formControlName="project_udem"
            autocomplete="nope"
            type="text"
            [(ngModel)]="depositIn.project_udem"
          />
          <mat-error *ngIf="formUdm.controls['project_udem'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="item-full-width" fxFlex="100%">
          <mat-label>{{ "CREATE_CASHING.ACTIVITY" | translate }}</mat-label>
          <input
            matInput
            formControlName="activity"
            autocomplete="nope"
            type="text"
            [(ngModel)]="depositIn.activity"
          />
          <mat-error *ngIf="formUdm.controls['activity'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="item-full-width" fxFlex="100%">
          <mat-label>{{ "CREATE_CASHING.TYPE_SRCE" | translate }}</mat-label>
          <input
            matInput
            formControlName="type_srce"
            autocomplete="nope"
            type="text"
            [(ngModel)]="depositIn.type_srce"
          />
          <mat-error *ngIf="formUdm.controls['type_srce'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div
    *ngIf="uiMode === 'DEPOSIT' || uiMode === 'INVOICE'"
    fxLayout="column"
    fxLayoutAlign="center start"
    class="item-full-width note-section"
  >
    <p-editor
      #noteEditor
      [(ngModel)]="depositIn.note"
      [style]="{
        height: '112px',
        width: '100%',
      }"
      class="item-full-width"
    >
      <ng-template pTemplate="header" class="">
        <!-- <span class="ql-formats">
        <select class="ql-font"></select>
        <select class="ql-size"></select>
      </span> -->

        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-strike"></button>
        </span>

        <span class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </span>

        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-indent" value="-1"></button>
          <button class="ql-indent" value="+1"></button>
        </span>
      </ng-template>
    </p-editor>
    <mat-error
      *ngIf="noteInvalid"
      fxLayout="row"
      fxLayoutAlign="end center"
      class="p-4 pr-8"
    >
      {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
    </mat-error>
  </div>

  <div *ngIf="uiMode === 'MESSAGE'">
    <div matDialogTitle class="event-dialog-title">
      {{ "CREATE_CASHING.MESSAGE_TITLE" | translate }}
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      [formGroup]="form"
      class="item-full-width px-8"
    >
      <div>{{ "CREATE_CASHING.MESSAGE_MESSAGE" | translate }}</div>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <div *ngIf="uiMode === 'DEPOSIT'">
      <button
        mat-raised-button
        [disabled]="
          !form.valid ||
          loading ||
          !depositIn.deposit_request ||
          !depositIn.payment_mode
        "
        color="accent"
        class="mr-16"
        (click)="onCreateDepositRequest()"
      >
        {{ "GENERIC.SAVE" | translate }}
      </button>
    </div>

    <div *ngIf="uiMode === 'INVOICE'">
      <button
        mat-raised-button
        [disabled]="!form.valid || loading || !depositIn.payment_mode"
        color="accent"
        class="mr-16"
        (click)="onCreateDepositRequest()"
      >
        {{ "GENERIC.SAVE" | translate }}
      </button>
    </div>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
