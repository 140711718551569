export const locale = {
  lang: "fr-CA",
  data: {
    NUMBERING: {
      TITLE: "Préfixes et numérotations",
      SUBTITLE: "Un préfixe standard et un numéro unique pour chaque document créé.",
      PREFIX: "Préfixes",
      PROJECT_PREFIX: "Préfixe de projet",
      EVENT_PREFIX: "Préfixe d'événement",
      PROPOSITION_PREFIX: "Préfixe de proposition",
      ORDER_PREFIX: "Préfixe de commande",
      INVOICE_PREFIX: "Préfixe de facture",
      NUMBERING: "Numérotations",
      NUMBER_NEXT: "Numérotation suivante",
      NUMBER_INVOICE_NEXT: "Numérotation de la facturation suivante",
      PREFIX_VALIDATION_ERROR: "Doit conternir anetre 3 et 6 charactères, alpha-numérique seulement",
    },
  },
};
