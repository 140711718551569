<div
  id="upsale"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
  style="height: max-content"
>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    name="upsaleGroupSpinner"
    size="medium"
    color="#90ee90"
    [fullScreen]="true"
    type="ball-scale-multiple"
  >
  </ngx-spinner>

  <!-- HEADER -->
  <div class="gt2-theme-extended">
    <div
      class="header warn py-24 pl-24 pr-8 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button
        class="mr-0 mr-sm-16 white-fg"
        mat-icon-button
        (click)="onPageBack(location, router, '/dashboard')"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>

      <div
        fxLayout="column"
        fxLayoutAlign="center start"
        fxFlex="72%"
        class="text-truncate"
      >
        <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
          <div
            class="event-title text-truncate"
            *fuseIfOnDom
            [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }"
          >
            <div class="">
              <span class="text-truncate"
                >{{ "PRODUCTS.UPSALE" | translate }}
              </span>
            </div>
            <!-- <div *ngIf="assignments && assignments.event" class="text-truncate">
                            <span
                                *ngIf="!assignments.event.shop || !assignments.event.shop.shop_type || !assignments.event.shop.shop_type.key || assignments.event.shop.shop_type.key !== 'SHOP_WEB'"
                                class="text-truncate pr-8">
                                {{ "ASSIGNMENTS.ASSIGNMENTS" | translate }} - {{assignments.event.name}} - {{
                                assignments.event.event_number }}
                            </span>
                            <span
                                *ngIf="assignments.event.shop && assignments.event.shop.shop_type && assignments.event.shop.shop_type.key === 'SHOP_WEB'"
                                class="text-truncate pr-8">
                                {{ "ASSIGNMENTS.ASSIGNMENTS" | translate | titlecase}} - {{assignments.event.name}} - {{
                                assignments.event.event_number }}
                            </span>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <!-- <div fxLayout="row" fxLayoutAlign="space-between center" style="height: 40px; min-height: 40px; max-height: 40px;"
        class="primary mat-elevation-z1">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            Dont show subhader if there is nothing in it
        </div>
    </div> -->
  <!-- / SUB HEADER -->

  <!--HISTORY SIDEBAR-->
  <!-- <div class="container-history-panel">
        <fuse-sidebar *ngIf="eventUUDID" name="assignmentsHistoryPanel" position="right" class="section-history-panel">
            <app-section-history-panel *ngIf="assignments" panelName="assignmentsHistoryPanel"
                section="events/assignments" sectionUUID="{{eventUUDID}}">
            </app-section-history-panel>
        </fuse-sidebar>
    </div> -->
  <!-- / HISTORY SIDEBAR-->

  <!--HISTORY SIDEBAR BUTTON-->
  <!-- <div>
        <button mat-icon-button class="warn mat-elevation-z2 historic-button" *fuseIfOnDom
            [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
            (click)="toggleHistoricSidebarOpen('assignmentsHistoryPanel')">
            <mat-icon>history</mat-icon>
        </button>
    </div> -->
  <!-- /HISTORY SIDEBAR BUTTON-->

  <div
    *ngIf="questions"
    class="profile-box info-box general"
    fxLayout="column"
    fusePerfectScrollbar
    style="overflow-y: scroll"
  >
    <div>
      <header class="accent">
        <div class="title">{{ "PRODUCTS.UPSALE_QUESTIONS" | translate }}</div>
      </header>
    </div>

    <app-upsale-question-item
      *ngFor="let question of questions; let i = index"
      [question]="question"
      [panelOpenState]="groupsOpened.includes(question.uuid)"
      (stateChange)="onGroupStateChange($event)"
      (onLoadQuestion)="loadQuestions()"
    >
    </app-upsale-question-item>

    <!-- <mat-tab-group animationDuration="0ms" dynamicHeight="true"
                        (selectedIndexChange)="onGeneralTabChange($event)">
            <mat-tab *ngFor="let question of questions" label="{{question.name}}">
                        
                            <div class="" >
                                <div class="product-container-item" *ngFor="let group of question.groups; let i = index;" >
                                
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="category-title px-8 py-4">
                                        <div fxLayoutAlign="start center">
                                            <mat-icon class="mr-8 s-24">drag_handle</mat-icon>
                                            <div class="">{{group.name }}</div>
                                        </div>
                                
                                        <div fxLayoutAlign="end end" fxFlex="161px" class="section-list-item">
                                            <button class="" mat-icon-button *ngxPermissionsOnly="rolesService.SALE_ROLES"
                                                (click)="onEditOptionsGroup(group, question.uuid)" tabindex="-1">
                                                <mat-icon class="s-16">{{
                                                    getIcon('EDIT') }}</mat-icon>
                                            </button>
                                            <button class="" mat-icon-button [disabled]="loading" *ngxPermissionsOnly="rolesService.SALE_ROLES"
                                                (click)="onRemoveOptionsGroup(group.uuid)" tabindex="-1">
                                                <mat-icon class="s-16">{{
                                                    getIcon('DELETE') }}</mat-icon>
                                            </button>
                                
                                        </div>
                                    </div>
                                    <div class="" *ngIf="group.options">
                                        <div class="product-child-item" *ngFor="let option of group.options">
                                
                                            <div fxLayout="row" fxLayoutAlign="start center">
                                
                                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" class="">
                                
                                                    <div fxFlex fxLayout="row" fxLayoutAlign="start center"
                                                        class="px-16 py-4 section-list-item-border-right">
                                                        <mat-icon class="mr-8 s-20">drag_handle</mat-icon>
                                                        <div>
                                                            {{option.name}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="center center" class="mb-12 mt-24">
                                 <button mat-raised-button class="accent p-2 mr-16" (click)="onCreateOptionsGroup(question)">
                                     Add a new slection 
                                 </button>
                                 <button mat-raised-button class="warn p-2" (click)="onDeleteQuestion(question.uuid)">
                                    Effacer la question
                                 </button>
                            </div>
            </mat-tab>
        </mat-tab-group> -->
  </div>

  <!-- CONTENT -->
  <div class="content center">
    <div class="p-24 pb-64 pr-sm-80" #upsalePage fusePerfectScrollbar>
      <!-- 
 <h1> QUESTIONS </h1>

            <div *ngIf="questions">
                <div *ngFor="let question of questions">
                    <button (click)="onCreateOptionsGroup(question)">
                    {{
                        question.name
                    }}
                    </button>
                </div>
            </div>

            -->
    </div>
  </div>
  <!-- /CONTENT -->
</div>

<div class="gt2-theme-extended fixed-button">
  <button
    mat-fab
    class="gt2-light-green"
    id="new-group-button"
    [disabled]="loading"
    (click)="onAddNewQuestion()"
    matTooltip="{{ 'PRODUCTS.ADD_QUESTION_HINT' | translate }}"
  >
    <mat-icon>{{ getIcon("ADD") }}</mat-icon>
  </button>
</div>
