<div style="max-height: 100%; overflow: auto">
  <div matDialogTitle *ngIf="mode === 'CREATE'" class="event-dialog-title">
    {{ "CREATE_DEPOSIT_REQUEST.TITLE" | translate }}
  </div>
  <div matDialogTitle *ngIf="mode === 'EDIT'" class="event-dialog-title">
    {{ "CREATE_DEPOSIT_REQUEST.EDIT_TITLE" | translate }}
  </div>
  <div fxLayout="column" fxLayoutAlign="center start">
    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      [formGroup]="form"
      class="item-full-width"
    >
      <mat-form-field fxFlex="100%" class="item-full-width">
        <mat-label>{{ "GENERIC.DATE" | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="dueDate"
          [(ngModel)]="depositRequest.due_date"
          min="todayDate"
          formControlName="due_date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="dueDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #dueDate></mat-datepicker>

        <mat-error *ngIf="form.controls['due_date'].invalid">
          {{ "GENERIC.DATE_TODAY_UP_RANGE_INVALID" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" class="item-full-width">
        <mat-label>{{ "GENERIC.AMOUNT" | translate }}</mat-label>
        <input
          matInput
          type="text"
          autocomplete="nope"
          formControlName="amount"
          (ngModelChange)="onPriceSet(depositRequest.amount)"
          pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
          [ngModel]="depositRequest.amount"
        />
        <mat-error *ngIf="form.controls['amount'].invalid">
          {{ "GENERIC.INPUT_NUMBER_ABOVE_ZERO" | translate }}
          {{ "GENERIC.INPUT_NUMBER_POSTFIX_999999" | translate }}
        </mat-error>
      </mat-form-field>

      <!-- <mat-form-field fxFlex="100%" class="item-full-width">
        <span matPrefix>% &nbsp;</span>
        <input matInput type="text" placeholder="{{ 'GENERIC.PERCENT' | translate}}" autocomplete="nope"
            formControlName="percent" 
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?" 
            (change)="onPercentSet(depositRequest)"
            [(ngModel)]="depositRequestPercent">
        
    </mat-form-field> -->
    </div>

    <div
      *ngIf="order"
      fxLayout="column"
      fxLayoutAlign="start start"
      style="width: 100%"
      class="mt-4"
      [formGroup]="form"
    >
      <!-- TODO: localize -->
      <span class="percentage">{{
        "CREATE_DEPOSIT_REQUEST.PERCENTAGE" | translate
      }}</span>
      <div fxLayout="column" class="">
        <mat-checkbox
          labelPosition="after"
          [ngModel]="serviceCharge.has_food"
          (ngModelChange)="onPercentSet()"
          [ngModelOptions]="{ standalone: true }"
          >{{ "GENERIC.ROYALTY_FOOD_BEVERAGE" | translate }}
        </mat-checkbox>
        <!-- <mat-checkbox labelPosition="after" [(ngModel)]="serviceCharge.has_beverage"(change)="onPercentSet(depositRequest)"  [ngModelOptions]="{standalone: true}">
                {{
                'GENERIC.ROYALTY_BEVERAGE' | translate }}
            </mat-checkbox> -->
        <mat-checkbox
          labelPosition="after"
          [ngModel]="serviceCharge.has_material"
          (ngModelChange)="onPercentSet()"
          [ngModelOptions]="{ standalone: true }"
        >
          {{ "GENERIC.ROYALTY_MATERIAL" | translate }}
        </mat-checkbox>
        <mat-checkbox
          labelPosition="after"
          [ngModel]="serviceCharge.has_service"
          (ngModelChange)="onPercentSet()"
          [ngModelOptions]="{ standalone: true }"
          >{{ "GENERIC.ROYALTY_SERVICE" | translate }}
        </mat-checkbox>
        <mat-checkbox
          labelPosition="after"
          [ngModel]="serviceCharge.has_service_charge"
          (ngModelChange)="onPercentSet()"
          [ngModelOptions]="{ standalone: true }"
          >{{ "GENERIC.ROYALTY_SERVICE_CHARGE" | translate }}
        </mat-checkbox>
        <mat-checkbox
          labelPosition="after"
          [ngModel]="serviceCharge.has_chambre"
          (ngModelChange)="onPercentSet()"
          [ngModelOptions]="{ standalone: true }"
          >{{ "GENERIC.SC_ROOM" | translate }}
        </mat-checkbox>
        <!-- <mat-checkbox labelPosition="after" [(ngModel)]="serviceCharge.has_delivery" (change)="onPercentSet(depositRequest)" [ngModelOptions]="{standalone: true}">
                {{
                'GENERIC.ROYALTY_DELIVERY_FEE' | translate }}
            </mat-checkbox> -->
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" class="item-full-width">
        <!-- <div class="mat-caption pr-8">{{ 'GENERIC.PERCENTAGE' | translate}}: </div> -->
        <mat-form-field class="item-full-width">
          <span matSuffix>% &nbsp;</span>
          <input
            matInput
            type="text"
            placeholder=""
            autocomplete="false"
            formControlName="percent"
            [disabled]="
              !serviceCharge.has_food &&
              !serviceCharge.has_material &&
              serviceCharge.has_service
            "
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            (ngModelChange)="onPercentSet()"
            [ngModel]="depositRequestPercent"
          />
        </mat-form-field>
      </div>

      <!-- [tickInterval]="onPercentSet(depositRequest)" -->
      <!-- <mat-slider  class="example-margin" [disabled]="false" [invert]="false" [max]="100" [min]="0" style="width: 100%; margin: 0; padding: 0;"
            [step]="1" [thumbLabel]="false" (change)="onPercentSet()" [(ngModel)]="depositRequestPercent"
            [vertical]="false" aria-labelledby="">
        </mat-slider>

        <div fxLayout="row" fxLayoutAlign="start start" fxFlex class="mat-caption mr-8">
            {{depositRequestPercent | number : '1.2-2' }}%
        </div> -->
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!form.valid || loading"
      color="accent"
      class="mr-16"
      (click)="onCreateDepositRequest()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
