import { Subject } from "rxjs";
import { NGXLogger } from "ngx-logger";

export class PageHistory {
  static MAX_CALLS: number = 20;
  static callStack: HistoryStack[] = [];
  static callStackChanged: Subject<HistoryStack[]> = new Subject();

  constructor(private logger: NGXLogger) {}

  static addCall(call: HistoryStack): void {
    PageHistory.callStack.unshift(call);

    PageHistory.callStack = PageHistory.callStack.filter((value, index, array) => index == array.findIndex((item) => item.uuid == value.uuid));

    if (PageHistory.callStack.length > PageHistory.MAX_CALLS) {
      PageHistory.callStack.splice(-1, 1);
    }

    PageHistory.callStackChanged.next(PageHistory.callStack);
  }

  static clearStack(): void {
    PageHistory.callStack = [];
    PageHistory.callStackChanged.next(PageHistory.callStack);
  }
}

export interface HistoryStack {
  url: string;
  uuid: string;
  label: string;
  icon: string;
}
