export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_PROGRAM: {
      TITLE: "Nouveau programme",
      EDIT_TITLE: "Modifier programme",
      SELECT_PROGRAM: "Définir ou sélectionner un programme",
      PROGRAM: "Programme",
      FOR_CLIENT_ONLY: "Ne pas afficher en production",
      THEATER: "Théâtre",
      SCHOOL: "École",
      HALF_ROUND: "Demi-ronde",
      BANQUET: "Banquet",
      CONFERENCE: "Conférence",
      SQUARE: "Carré",
      U_ASSEMBLY: "U",
      RECEPTION_FB: "Réception F&B",
      CUSTOM: "Personnalisé",
      UNSET: "",
    },
  },
};
