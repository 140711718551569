<div
  *ngIf="collectionData && dataSource"
  style="width: fit-content">
  <mat-table
    #scheduleTable
    class="schedule-table"
    [@animateStagger]="{ value: '10' }"
    [dataSource]="dataSource">
    <ng-container
      *ngFor="let col of displayedColumns; let i = index"
      cdkColumnDef="{{ displayedColumns[i] }}">
      <ng-container [ngSwitch]="displayedColumns[i]">
        <!-- CHECKBOXES -->
        <ng-container *ngSwitchCase="'checkboxes'">
          <mat-header-cell
            *cdkHeaderCellDef=""
            mat-header
            fxFlex="180px"
            class="item-cell-header">
            <div></div>
          </mat-header-cell>

          <mat-cell
            *cdkCellDef="let row"
            class="item-cell"
            fxFlex="180px"
            fxLayout="row"
            fxLayoutAlign="start center">
            <div fxLayout="column">
              <mat-checkbox
                labelPosition="after"
                color="accent"
                [(ngModel)]="row.with_delivery"
                [ngModelOptions]="{ standalone: true }">
                {{ "GENERIC.WITH_DELIVERY" | translate }}
              </mat-checkbox>
              <mat-checkbox
                labelPosition="after"
                color="accent"
                [(ngModel)]="row.with_takeout"
                [ngModelOptions]="{ standalone: true }">
                {{ "GENERIC.WITH_TAKEOUT" | translate }}
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <!-- ACTIONS -->
        <ng-container *ngSwitchCase="'action'">
          <mat-header-cell
            *cdkHeaderCellDef
            mat-header
            fxFlex="98px"
            class="item-cell-header">
            <button
              mat-button
              mat-icon-button
              (click)="onAddRow()">
              <mat-icon class="s-20">
                {{ getIcon("ADD") }}
              </mat-icon>
            </button>
          </mat-header-cell>

          <mat-cell
            *cdkCellDef="let row"
            class="item-cell"
            fxFlex="98px"
            fxLayout="row"
            fxLayoutAlign="start center">
            <button
              mat-button
              mat-icon-button
              (click)="onSaveRow(row)">
              <mat-icon class="s-16">
                {{ getIcon("SAVE") }}
              </mat-icon>
            </button>

            <button
              mat-button
              mat-icon-button
              (click)="onDeleteRow(row)">
              <mat-icon class="s-16">
                {{ getIcon("DELETE") }}
              </mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <!-- START_AT -->
        <ng-container *ngSwitchCase="'start_at'">
          <mat-header-cell
            *cdkHeaderCellDef
            mat-header
            [fxFlex]="columnWidth">
            {{ "SHOPS.RTE_SCHEDULE_TABLE." + displayedColumns[i] | translate }}
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            [fxFlex]="columnWidth"
            fxLayout="column"
            fxLayoutAlign="start start">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="date-picker">
              <div class="date-label">{{ "GENERIC.DATE" | translate }}:</div>
              <mat-form-field class="date-form scaled-comp">
                <input
                  matInput
                  autocomplete="nope"
                  [matDatepicker]="eventDate"
                  min="1900-01-01"
                  max="2099-01-01"
                  [(ngModel)]="row[displayedColumns[i]]"
                  (ngModelChange)="onDateChange(row, displayedColumns[i])" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDate"></mat-datepicker-toggle>
                <mat-datepicker #eventDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="time-picker">
              <div class="time-label">{{ "GENERIC.TIME" | translate }}:</div>
              <ngx-timepicker-field
                [buttonAlign]="'left'"
                [format]="24"
                [(ngModel)]="row.start_time"
                class="no-border scaled-comp">
              </ngx-timepicker-field>
            </div>
          </mat-cell>
        </ng-container>

        <!-- END_AT -->
        <ng-container *ngSwitchCase="'end_at'">
          <mat-header-cell
            *cdkHeaderCellDef
            mat-header
            [fxFlex]="columnWidth">
            {{ "SHOPS.RTE_SCHEDULE_TABLE." + displayedColumns[i] | translate }}
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            fxLayout="column"
            [fxFlex]="columnWidth"
            fxLayoutAlign="start start">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="date-picker">
              <div class="date-label">{{ "GENERIC.DATE" | translate }}:</div>
              <mat-form-field class="date-form scaled-comp">
                <input
                  matInput
                  autocomplete="nope"
                  [matDatepicker]="eventDate"
                  min="1900-01-01"
                  max="2099-01-01"
                  [(ngModel)]="row[displayedColumns[i]]"
                  (ngModelChange)="onDateChange(row, displayedColumns[i])" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDate"></mat-datepicker-toggle>
                <mat-datepicker #eventDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="time-picker">
              <div class="time-label">{{ "GENERIC.TIME" | translate }}:</div>
              <ngx-timepicker-field
                [buttonAlign]="'left'"
                [format]="24"
                [(ngModel)]="row.end_time"
                class="no-border scaled-comp">
              </ngx-timepicker-field>
            </div>
          </mat-cell>
        </ng-container>

        <!-- TAKEOUT_START_AT -->
        <ng-container *ngSwitchCase="'takeout_start_at'">
          <mat-header-cell
            *cdkHeaderCellDef
            mat-header
            [fxFlex]="columnWidth">
            {{ "SHOPS.RTE_SCHEDULE_TABLE." + displayedColumns[i] | translate }}
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            fxLayout="column"
            [fxFlex]="columnWidth"
            fxLayoutAlign="start start">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="date-picker">
              <div class="date-label">{{ "GENERIC.DATE" | translate }}:</div>
              <mat-form-field class="date-form scaled-comp">
                <input
                  matInput
                  autocomplete="nope"
                  [matDatepicker]="eventDate"
                  min="1900-01-01"
                  max="2099-01-01"
                  [(ngModel)]="row[displayedColumns[i]]"
                  [disabled]="!row.with_takeout"
                  (ngModelChange)="onDateChange(row, displayedColumns[i])" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDate">
                </mat-datepicker-toggle>
                <mat-datepicker
                  #eventDate
                  [disabled]="!row.with_takeout"></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="time-picker">
              <div class="time-label">{{ "GENERIC.TIME" | translate }}:</div>
              <ngx-timepicker-field
                [buttonAlign]="'left'"
                [format]="24"
                [(ngModel)]="row.takeout_start_time"
                [disabled]="!row.with_takeout"
                class="no-border scaled-comp">
              </ngx-timepicker-field>
            </div>
          </mat-cell>
        </ng-container>

        <!-- TAKEOUT_END_AT -->
        <ng-container *ngSwitchCase="'takeout_end_at'">
          <mat-header-cell
            *cdkHeaderCellDef
            mat-header
            [fxFlex]="columnWidth">
            {{ "SHOPS.RTE_SCHEDULE_TABLE." + displayedColumns[i] | translate }}
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            fxLayout="column"
            [fxFlex]="columnWidth"
            fxLayoutAlign="start start">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="date-picker">
              <div class="date-label">{{ "GENERIC.DATE" | translate }}:</div>
              <mat-form-field class="date-form scaled-comp">
                <input
                  matInput
                  autocomplete="nope"
                  [matDatepicker]="eventDate"
                  min="1900-01-01"
                  max="2099-01-01"
                  [(ngModel)]="row[displayedColumns[i]]"
                  [disabled]="!row.with_takeout"
                  (ngModelChange)="onDateChange(row, displayedColumns[i])" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDate"></mat-datepicker-toggle>
                <mat-datepicker
                  #eventDate
                  [disabled]="!row.with_takeout"></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="time-picker">
              <div class="time-label">{{ "GENERIC.TIME" | translate }}:</div>
              <ngx-timepicker-field
                [buttonAlign]="'left'"
                [format]="24"
                [(ngModel)]="row.takeout_end_time"
                [disabled]="!row.with_takeout"
                class="no-border scaled-comp">
              </ngx-timepicker-field>
            </div>
          </mat-cell>
        </ng-container>

        <!-- DELIVERY_START_AT -->
        <ng-container *ngSwitchCase="'delivery_start_at'">
          <mat-header-cell
            *cdkHeaderCellDef
            mat-header
            [fxFlex]="columnWidth">
            {{ "SHOPS.RTE_SCHEDULE_TABLE." + displayedColumns[i] | translate }}
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            fxLayout="column"
            [fxFlex]="columnWidth"
            fxLayoutAlign="start start">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="date-picker">
              <div class="date-label">{{ "GENERIC.DATE" | translate }}:</div>
              <mat-form-field class="date-form scaled-comp">
                <input
                  matInput
                  autocomplete="nope"
                  [matDatepicker]="eventDate"
                  min="1900-01-01"
                  max="2099-01-01"
                  [(ngModel)]="row[displayedColumns[i]]"
                  [disabled]="!row.with_delivery"
                  (ngModelChange)="onDateChange(row, displayedColumns[i])" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDate"></mat-datepicker-toggle>
                <mat-datepicker
                  #eventDate
                  [disabled]="!row.with_delivery"></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="time-picker">
              <div class="time-label">{{ "GENERIC.TIME" | translate }}:</div>
              <ngx-timepicker-field
                [buttonAlign]="'left'"
                [format]="24"
                [(ngModel)]="row.delivery_start_time"
                [disabled]="!row.with_delivery"
                class="no-border scaled-comp">
              </ngx-timepicker-field>
            </div>
          </mat-cell>
        </ng-container>

        <!-- DELIVERY_END_AT -->
        <ng-container *ngSwitchCase="'delivery_end_at'">
          <mat-header-cell
            *cdkHeaderCellDef
            mat-header
            [fxFlex]="columnWidth">
            {{ "SHOPS.RTE_SCHEDULE_TABLE." + displayedColumns[i] | translate }}
          </mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            fxLayout="column"
            [fxFlex]="columnWidth"
            fxLayoutAlign="start start">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="date-picker">
              <div class="date-label">{{ "GENERIC.DATE" | translate }}:</div>
              <mat-form-field class="date-form scaled-comp">
                <input
                  matInput
                  autocomplete="nope"
                  [matDatepicker]="eventDate"
                  min="1900-01-01"
                  max="2099-01-01"
                  [(ngModel)]="row[displayedColumns[i]]"
                  [disabled]="!row.with_delivery"
                  (ngModelChange)="onDateChange(row, displayedColumns[i])" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="eventDate"></mat-datepicker-toggle>
                <mat-datepicker
                  #eventDate
                  [disabled]="!row.with_delivery"></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="time-picker">
              <div class="time-label">{{ "GENERIC.TIME" | translate }}:</div>
              <ngx-timepicker-field
                [buttonAlign]="'left'"
                [format]="24"
                [(ngModel)]="row.delivery_end_time"
                [disabled]="!row.with_delivery"
                class="no-border scaled-comp">
              </ngx-timepicker-field>
            </div>
          </mat-cell>
        </ng-container>
      </ng-container>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

    <mat-row
      *cdkRowDef="let row; columns: displayedColumns"
      (click)="onSelect(row)"
      [@animate]="{ value: '*', params: { y: '100%' } }">
    </mat-row>
  </mat-table>
</div>
