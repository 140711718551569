import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Subject, BehaviorSubject } from "rxjs";

@Injectable()
export class ModulesService {
  // Modules Enums
  static MODULE_ROOM_MNGT: string = "MODULE_ROOM_MNGT";
  static MODULE_DASHBOARD_GRID: string = "MODULE_DASHBOARD_GRID";
  static MODULE_ONLINE_PAYMENT_REQUEST: string = "MODULE_ONLINE_PAYMENT_REQUEST";
  static MODULE_INTEGRATION_SAGE_ONE: string = "MODULE_INTEGRATION_SAGE_ONE";
  static MODULE_INTEGRATION_GOOGLE_CALENDAR: string = "MODULE_INTEGRATION_GOOGLE_CALENDAR";
  static MODULE_SHOP_WEB: string = "MODULE_SHOP_WEB";
  static MODULE_QUICK_ORDER: string = "MODULE_QUICK_ORDER";
  static MODULE_STRIPE: string = "MODULE_STRIPE";
  static MODULE_INTEGRATION_AC: string = "MODULE_INTEGRATION_AC";
  static MODULE_INTEGRATION_PIPEDRIVE: string = "MODULE_INTEGRATION_PIPEDRIVE";
  static MODULE_ORG_IDENTITY: string = "MODULE_ORG_IDENTITY";
  static MODULE_STAFF_ASSIGNMENT: string = "MODULE_STAFF_ASSIGNMENT";
  static MODULE_REPORT2_MNGT_DELIVERY_TAKEOUT_JR_XLSX: string = "MODULE_REPORT2_MNGT_DELIVERY_TAKEOUT_JR_XLSX";
  //
  static MODULE_REPORT2_BASE: string = "MODULE_REPORT2_";
  static MODULE_REPORT2_JR: string = "MODULE_REPORT2_JR";
  static MODULE_REPORT2_VIC: string = "MODULE_REPORT2_VIC";
  static MODULE_REPORT2_CAC: string = "MODULE_REPORT2_CAC";
  static MODULE_REPORT2_JL: string = "MODULE_REPORT2_JL";
  static MODULE_REPORT2_DAN: string = "MODULE_REPORT2_DAN";
  static MODULE_REPORT2_GAB: string = "MODULE_REPORT2_GAB";
  static MODULE_REPORT2_LAN: string = "MODULE_REPORT2_LAN";
  static MODULE_EVENT_FILES: string = "MODULE_EVENT_FILES";
  static MODULE_EVENT_PROGRAMS: string = "MODULE_EVENT_PROGRAMS";
  static MODULE_REPORT2_RP: string = "MODULE_REPORT2_RP";
  static MODULE_REPORT2_PARREIRA: string = "MODULE_REPORT2_PARREIRA";
  static MODULE_REPORT2_ODIKA: string = "MODULE_REPORT2_ODIKA";
  static MODULE_REPORT2_BTF: string = "MODULE_REPORT2_BTF";
  static MODULE_REPORT2_CT: string = "MODULE_REPORT2_CT";
  static MODULE_REPORT2_SOT: string = "MODULE_REPORT2_SOT";
  static MODULE_REPORT2_LOL: string = "MODULE_REPORT2_LOL";
  //
  static MODULE_CONGRESS_CT: string = "MODULE_CONGRESS_CT";
  static MODULE_MULTI_CASHING: string = "MODULE_MULTI_CASHING";
  static MODULE_CHANGELOGS: string = "MODULE_CHANGELOGS";
  static MODULE_EVENT_TABBED: string = "MODULE_EVENT_TABBED";
  static MODULE_REPORT2_UDEM: string = "MODULE_REPORT2_UDEM";
  static MODULE_REPORT2_OKA: string = "MODULE_REPORT2_OKA";
  static MODULE_REPORT2_BISTRO_V: string = "MODULE_REPORT2_BISTRO_V";
  static MODULE_REPORT2_ALEX: string = "MODULE_REPORT2_ALEX";
  static MODULE_REPORT2_UQAM = "MODULE_REPORT2_UQAM";
  static MODULE_CEL_UPSALE = "MODULE_CEL_UPSALE";
  static MODULE_CYCLIC_MENU = "MODULE_CYCLIC_MENU";
  static MODULE_COMBO_DYNAMIC = "MODULE_COMBO_DYNAMIC";
  static MODULE_MULTIPLICATION_COMBO_CHILDS = "MODULE_MULTIPLICATION_COMBO_CHILDS";
  static MODULE_GLOBAL_PAYMENTS = "MODULE_GLOBAL_PAYMENTS";
  //
  modulesAreSet: boolean = false;
  modulesSet: BehaviorSubject<boolean> = new BehaviorSubject(false);

  //
  private _modules: string[] = [];
  private modulesSetSubject: Subject<boolean> = new Subject<boolean>();

  constructor(private logger: NGXLogger) {}

  public onModuleSet(): any {
    return this.modulesSetSubject;
  }

  public setModules(modulesColl: string[]): void {
    this._modules = modulesColl;
    this.modulesAreSet = true;
    this.modulesSetSubject.next(true);
    this.modulesSet.next(true);
    // this.logger.info(
    //     "ModulesService.setModules() -> modules: " +
    //         JSON.stringify(this._modules)
    // );
  }

  public getModules(): string[] {
    return this._modules;
  }

  public moduleExist(key: string): boolean {
    // if (key === "MODULE_EVENT_PROGRAMS") {
    // }
    return this._modules.indexOf(ModulesService[key]) !== -1;
  }

  public hasRoomManagement(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_ROOM_MNGT) !== -1;
  }

  public hasDetailedCalendar(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_DASHBOARD_GRID) !== -1;
  }

  public hasShopWeb(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_SHOP_WEB) !== -1;
  }

  public hasSage(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_INTEGRATION_SAGE_ONE) !== -1;
  }

  public hasGoogleCalendar(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_INTEGRATION_GOOGLE_CALENDAR) !== -1;
  }

  public hasOnlinePayment(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_ONLINE_PAYMENT_REQUEST) !== -1;
  }

  public hasQuickOrder(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_QUICK_ORDER) !== -1;
  }

  public hasStripe(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_STRIPE) !== -1;
  }

  // TODO: TO DELETE LATER, HAS BEEN REPLACED BY hasJRReport()
  // public hasDeliveryTakeoutReport(): boolean {
  //     return (
  //         this._modules.indexOf(
  //             ModulesService.MODULE_REPORT2_MNGT_DELIVERY_TAKEOUT_JR_XLSX
  //         ) !== -1
  //     );
  // }

  public hasSOTReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_SOT) !== -1;
  }

  public hasJRReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_JR) !== -1;
  }

  public hasVicReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_VIC) !== -1;
  }

  public hasCaCReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_CAC) !== -1;
  }

  public hasJLReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_JL) !== -1;
  }

  public hasDANReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_DAN) !== -1;
  }

  public hasGABReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_GAB) !== -1;
  }

  public hasLANReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_LAN) !== -1;
  }

  public hasRPReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_RP) !== -1;
  }

  public hasParreiraReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_PARREIRA) !== -1;
  }

  public hasOdikaReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_ODIKA) !== -1;
  }

  public hasBTFReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_BTF) !== -1;
  }

  public hasCTReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_CT) !== -1;
  }

  public hasUQAMReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_UQAM) !== -1;
  }

  public hasLoLReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_LOL) !== -1;
  }

  public hasCongressModule(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_CONGRESS_CT) !== -1;
  }

  public hasMultiCashing(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_MULTI_CASHING) !== -1;
  }
  public hasChangelogsModule(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_CHANGELOGS) !== -1;
  }

  public hasUDEMReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_UDEM) !== -1;
  }

  public hasEventTabsModule(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_EVENT_TABBED) !== -1;
  }

  public hasOkaReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_OKA) !== -1;
  }

  public hasBistroVReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_BISTRO_V) !== -1;
  }

  public hasAlexReport(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_REPORT2_ALEX) !== -1;
  }

  public hasUpsaleModule(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_CEL_UPSALE) !== -1;
  }

  public hasCyclicMenuModule(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_CYCLIC_MENU) !== -1;
  }

  public hasComboDynamicModule(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_COMBO_DYNAMIC) !== -1;
  }

  public hasMultiplicationComboChilds(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_MULTIPLICATION_COMBO_CHILDS) !== -1;
  }

  public hasGlobalPayments(): boolean {
    return this._modules.indexOf(ModulesService.MODULE_GLOBAL_PAYMENTS) !== -1;
  }
}
