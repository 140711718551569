import { HttpClient } from "@angular/common/http";
import { Gt2ApiService } from "./gt2-api.service";
import { ApiRoutes } from "./ApiRoutes";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class TimezoneService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getTimeZones() {
    //this.logger.info("TimezoneService.getTimeZones()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.TIME_ZONE));
  }

  public getData(): Observable<any> {
    //this.logger.info("TimezoneService.getData()");
    return this.getTimeZones();
  }

  public updateTimezone(timezone: any, uuid: string) {
    //this.logger.info("TimezoneService.updateTimezone() -> " + JSON.stringify(timezone));
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.SETTINGS_PREF) + "/" + uuid, timezone);
  }
}
