import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class JobsFunctionService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getAll(): any {
    // this.logger.info("JobsFunctionService.getAll()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.JOBS_FUNCTION_ALL));
  }

  public geList(): any {
    // this.logger.info("JobsFunctionService.getAll()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.JOBS_FUNCTION_LIST));
  }

  public getJobsFunctionAvailable(employeeUUID: string): any {
    // this.logger.info("JobsFunctionService.getJobsFunctionAvailable()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.JOBS_FUNCTION_AVAILABLE) + employeeUUID);
  }

  public disassociateJobFunctionFromEmployee(employeeUUID: string, jobsFunctionUUID: string): any {
    // this.logger.info("JobsFunctionService.disassociateJobFunctionFromEmployee()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.JOBS_FUNCTION_DISASSOCIATE) + employeeUUID + "/" + jobsFunctionUUID);
  }
}
