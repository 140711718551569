export const locale = {
  lang: "fr-CA",
  data: {
    SERVICE_CHARGE: {
      CREATE_NEW_CATEGORY: "Nouveau frais de service",
      CATEGORY_NAME: "Nom du frais de service",
      NEW_CATEGORY_NAME: "Nom du nouveau frais de service",
      CREATE_SUCCESS: "Frais de service créé avec succès!",
      EDIT_SUCCESS: "Frais de service modifié avec succès!",
      DELETE_SUCCESS: "Frais de service effacé avec succès!",
      ACCOUNT_NUMBER: "Numéro de compte",
      LEDGER_ACCOUNT: "Compte de grand livre",
      TAXES: "Taxe",
      SELECT_SERVICE_CHARGE_MULTIPLIER: "Sélectionner un  multiplicateur",
      DISCOUNT: "Escompte",
      SURCHARGE: "Majoration",
      AMOUNT: "Montant",
    },
  },
};
