import { ServiceChargeMultiplierService } from './../../../../api/service-charge-multiplier.service';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { GT2PageAbstract } from '../../../abstract/GT2PageAbstract';
import { CrudDatatableGenericService } from '../../../../api/crud-datatable-generic.service';
import { Settings } from '../../../../settings/settings';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { ServiceChargeService } from '../../../../api/service-charge.service';
import { ValidatorHelper } from '../../../../validators/ValidatorHelper';
import { TaxesService } from '../../../../api/taxes.service';
import { ServiceChargeMultiplyEnum } from '../../../../enum/service-charge-multiply-enum';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Gt2SelectComponent } from '../../../../components/gt2-select/gt2-select.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-service-charge-create',
  templateUrl: './service-charge-create.component.html',
  styleUrls: ['./service-charge-create.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    FormsModule,
    FlexLayoutModule,
    Gt2SelectComponent,
    MatRadioModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    TranslateModule,
  ],
  providers: [CrudDatatableGenericService],
})
export class ServiceChargeCreateComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  // @Input() serviceURL: string;
  // @Input() createPlaceholder: string;
  serviceURL!: string;
  createPlaceholder!: string;
  ledgerAccounts: any;
  ledger_account: any;
  dialogTitle!: string;
  useEnglish: boolean = true;
  form!: UntypedFormGroup;
  formPercent!: UntypedFormGroup;
  formPercentEnterprise!: UntypedFormGroup;
  formPrice!: UntypedFormGroup;
  model: any;
  loading: boolean = false;
  newCategory_fr_CA: string | any;
  newCategory_en_CA: string | any;
  accountNumber!: string;
  price!: number;
  tax: any;
  selectedServiceChargeMultiplier: any;
  percent!: number;
  royalty_on_food: boolean = false;
  royalty_on_beverage: boolean = false;
  royalty_on_material: boolean = false;
  royalty_on_service: boolean = false;
  royalty_on_delivery: boolean = false;
  isEnterpriseServiceCharge: boolean = false;
  hideServiceChargeMultiplierInput: boolean = false;
  enterpriseUuid!: string;
  serviceChargeMultiplierDefaultValue: any;
  serviceCharge: any;
  serviceChargeToUpdate: any;
  ledgerAccount: any;
  //is_discount: number = 0;
  constructor(
    public override dialogRef: MatDialogRef<ServiceChargeCreateComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crudDatatableGenericService: CrudDatatableGenericService,
    public serviceChargeService: ServiceChargeService,
    private formBuilder: UntypedFormBuilder,
    public taxesService: TaxesService,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    public logger: NGXLogger,
    public serviceChargeMultiplierService: ServiceChargeMultiplierService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    if (data) {
      this.dialogTitle = data.dialogTitle;
      this.serviceURL = data.serviceURL;
      this.createPlaceholder = data.createPlaceholder;
      this.ledgerAccounts = data.ledgerAccounts;

      if (data.useEnglish !== null) {
        this.useEnglish = data.useEnglish;
      }
      if (data.isEnterpriseServiceCharge !== null) {
        this.isEnterpriseServiceCharge = data.isEnterpriseServiceCharge;
      }
      if (data.enterpriseUuid !== null) {
        this.enterpriseUuid = data.enterpriseUuid;
      }
      if (data.serviceChargeMultiplierDefaultValue !== null) {
        this.selectedServiceChargeMultiplier =
          data.serviceChargeMultiplierDefaultValue;
        this.serviceChargeMultiplierDefaultValue =
          data.serviceChargeMultiplierDefaultValue;
      }
      if (data.hideServiceChargeMultiplierInput) {
        this.hideServiceChargeMultiplierInput =
          data.hideServiceChargeMultiplierInput;
      }
      if (data.serviceChargeToUpdate != null) {
        this.serviceChargeToUpdate = data.serviceChargeToUpdate;
        this.selectedServiceChargeMultiplier =
          this.serviceChargeToUpdate.multiply_enum_item;
      }
    }
    // this.setRegularForm();
    this.initServiceCharge();
    this.createForm();
  }

  ngOnInit() {
    //this.logger.info("ServiceChargeCreateComponent.ngOnInit()");
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public createForm(): void {
    //this.logger.info("ServiceChargeCreateComponent.setRegularForm() ->");
    if (this.useEnglish) {
      this.form = this.formBuilder.group({
        name_fr_CA: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        name_en_CA: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        ledger_account: '',
        // price: [
        //     "",
        //     [
        //         ValidatorHelper.isPositive,
        //         ValidatorHelper.isNumber0To1000000,
        //         Validators.required,
        //     ],
        // ],
      });
      //
      this.formPrice = this.formBuilder.group({
        price: [
          '',
          [
            ValidatorHelper.isPositive,
            ValidatorHelper.isNumber0To1000000,
            Validators.required,
          ],
        ],
      });

      //
      this.formPercent = this.formBuilder.group({
        percent: [
          '',
          [
            // ValidatorHelper.isNotZero,
            // ValidatorHelper.isNumberMinus100To100,
            Validators.required,
          ],
        ],
      });
      //
      this.formPercentEnterprise = this.formBuilder.group({
        percent: [
          '',
          [ValidatorHelper.isNotZero, Validators.min(1), Validators.required],
        ],
      });
    } else {
      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
      });

      this.formPercent = this.formBuilder.group({
        percent: [
          '',
          [
            ValidatorHelper.isNotZero,
            ValidatorHelper.isNumberMinus100To100,
            Validators.required,
          ],
        ],
      });
      //
      this.formPercentEnterprise = this.formBuilder.group({
        percent: [
          '',
          [ValidatorHelper.isNotZero, Validators.min(1), Validators.required],
        ],
      });
    }
  }

  test(): void {
    //this.logger.info("percent: " + this.formPercent.controls['percent'].valid);
    //this.logger.info("formPercent: " + this.formPercent.valid);
    //this.logger.info("form: " + this.form.valid);
    //this.logger.info(
    //"validation: " + (this.selectedServiceChargeMultiplier && this.selectedServiceChargeMultiplier.value === ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES && this.formPercent.invalid),
    //);
  }

  // public setPercentForm(): void {
  //     //this.logger.info("ServiceChargeCreateComponent.setPercentForm() ->");
  //     if (this.useEnglish) {

  //     } else {

  //     }
  // }
  public initServiceCharge() {
    this.serviceCharge = {
      uuid: this.serviceChargeToUpdate ? this.serviceChargeToUpdate.uuid : null,
      name_local: {
        name_fr_CA: this.serviceChargeToUpdate
          ? this.serviceChargeToUpdate.name_local.name_fr_CA
          : null,
        name_en_CA: this.serviceChargeToUpdate
          ? this.serviceChargeToUpdate.name_local.name_en_CA
          : null,
      },
      ledger_account: this.serviceChargeToUpdate
        ? this.serviceChargeToUpdate.ledger_account
        : null,
      price: this.serviceChargeToUpdate ? this.serviceChargeToUpdate.price : 0,
      tax: this.serviceChargeToUpdate ? this.serviceChargeToUpdate.tax : null,
      enterprise_uuid: this.enterpriseUuid,
      multiply_enum: this.serviceChargeToUpdate
        ? this.serviceChargeToUpdate.multiply_enum
        : null,
      multiplier: this.serviceChargeToUpdate
        ? this.serviceChargeToUpdate.multiply_enum_item
        : null,
      percentage_by: this.serviceChargeToUpdate
        ? this.serviceChargeToUpdate.percentage_by
        : false,
      percentage_by_enterprise: this.serviceChargeToUpdate
        ? this.serviceChargeToUpdate.percentage_by_enterprise
        : false,
      has_food: this.serviceChargeToUpdate
        ? this.serviceChargeToUpdate.has_food
        : false,
      has_beverage: this.serviceChargeToUpdate
        ? this.serviceChargeToUpdate.has_beverage
        : false,
      has_material: this.serviceChargeToUpdate
        ? this.serviceChargeToUpdate.has_material
        : false,
      has_service: this.serviceChargeToUpdate
        ? this.serviceChargeToUpdate.has_service
        : false,
      has_delivery: this.serviceChargeToUpdate
        ? this.serviceChargeToUpdate.has_delivery
        : false,
      has_total_taxes: this.serviceChargeToUpdate
        ? this.serviceChargeToUpdate.has_total_taxes
        : false,
      is_discount: this.serviceChargeToUpdate
        ? this.serviceChargeToUpdate.is_discount
        : 0,
    };

    //this.logger.info("ServiceChargeCreateComponent.initServiceCharge() -> ledger_account: " + this.ledger_account);
    //this.logger.info("ServiceChargeCreateComponent.initServiceCharge() -> isEnterpriseServiceCharge: " + this.isEnterpriseServiceCharge);
  }
  public compareledgerAccount(object1: any, object2: any): any {
    if (object2) {
      return object1.uuid === object2.uuid;
    }
  }
  public onLedgerAccountChange(event: any) {
    this.serviceCharge.ledger_account = event.value;

    //this.logger.info("ServiceChargeCreateComponent.onLedgerAccountChange() -> ledger_account: " + this.serviceCharge.ledger_account);
  }
  public onIsDiscountChange(event: any): void {
    //this.logger.info("ServiceChargeCreateComponent.onIsDiscountChange() -> event: " + event.value);
    this.serviceCharge.is_discount = event.value == 1;
  }
  public onTaxChange(event: any): void {
    // //this.logger.info("ServiceChargeCreateComponent.onTaxChange() -> event: " + JSON.stringify(event));
    this.tax = event;
  }

  public onServiceChargeMultiplierChange(event: any): void {
    //this.logger.info("ServiceChargeCreateComponent.onServiceChargeMultiplierChange() -> event: " + JSON.stringify(event));
    this.selectedServiceChargeMultiplier = event;

    //this.logger.info("ServiceChargeCreateComponent.onServiceChargeMultiplierChange() -> event: " + event.value + " " + ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES);
    this.serviceCharge.multiplier =
      this.selectedServiceChargeMultiplier !== null
        ? this.selectedServiceChargeMultiplier.value
        : null;
    if (
      this.selectedServiceChargeMultiplier &&
      this.selectedServiceChargeMultiplier.value !==
        ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
    ) {
      this.serviceCharge.percentage_by = false;
      this.serviceCharge.has_food = false;
      this.serviceCharge.has_beverage = false;
      this.serviceCharge.has_material = false;
      this.serviceCharge.has_service = false;
      this.serviceCharge.has_delivery = false;
      this.serviceCharge.has_total_taxes = false;
    }
  }

  public onCreateNew() {
    // //this.logger.info("ServiceChargeCreateComponent.onCreateNew() - this.newCategory_fr_CA: " + this.newCategory_fr_CA);
    // //this.logger.info("ServiceChargeCreateComponent.onCreateNew() - this.newCategory_en_CA: " + this.newCategory_en_CA);
    // //this.logger.info("ServiceChargeCreateComponent.onCreateNew() - this.serviceURL: " + this.serviceURL);
    // const obj: any = {
    //     name_local: {
    //         name_fr_CA: this.newCategory_fr_CA,
    //         name_en_CA: this.newCategory_en_CA,
    //     },
    //     ledger_account: this.ledger_account,
    //     price: this.price,
    //     tax: this.tax,
    //     enterprise_uuid: (this.enterpriseUuid) ? this.enterpriseUuid : null,
    //     multiplier:
    //         this.selectedServiceChargeMultiplier !== null
    //             ? this.selectedServiceChargeMultiplier.value
    //             : null,
    // };

    // if (
    //     this.selectedServiceChargeMultiplier &&
    //     this.selectedServiceChargeMultiplier.value ===
    //     ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
    // ) {
    //     obj.percentage_by = this.percent;
    //     obj.has_food = this.royalty_on_food;
    //     obj.has_beverage = this.royalty_on_beverage;
    //     obj.has_material = this.royalty_on_material;
    //     obj.has_service = this.royalty_on_service;
    //     obj.has_delivery = this.royalty_on_delivery;
    // }

    //this.logger.info("obj: " + JSON.stringify(this.serviceCharge));
    if (this.isEnterpriseServiceCharge) {
      //this.logger.info("isEnterpriseServiceCharge");
      this.serviceCharge.multiplier =
        ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES;
    }
    this.serviceChargeService.create(this.serviceCharge).subscribe(
      (response: any) => {
        // //this.logger.info("GenericCreateComponent.onCreateNew() -> SUCCESS:" + JSON.stringify(response));
        this.toastr.success(
          this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_SUCCESS'),
        );
        this.newCategory_fr_CA = null;
        this.newCategory_en_CA = null;
        this.dialogRef.close(response.data);
        // this.form.markAsUntouched();
        // this.form.markAsPristine();
        // this.reloadData();
      },
      (error: any) => {
        this.logger.error(
          'ServiceChargeCreateComponent.onCreateNew() -> ERROR' +
            JSON.stringify(error),
        );
        // this.toastr.error(this.translate.instant('GENERIC.ERRORS.GENERIC'));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    );
  }
  public onUpdate() {
    //this.logger.info("obj: " + JSON.stringify(this.serviceCharge));
    this.serviceChargeService.update(this.serviceCharge).subscribe(
      (response: any) => {
        this.toastr.success(
          this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_SUCCESS'),
        );
        this.dialogRef.close(response.data);
      },
      (error: any) => {
        this.logger.error(
          'ServiceChargeCreateComponent.onCreateNew() -> ERROR' +
            JSON.stringify(error),
        );
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    );
  }
}
