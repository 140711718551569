<div
  id="venue"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <div class="gt2-theme">
    <div
      class="custom-header header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
    >
      <div
        style="display: flex; flex-direction: row; flex: 1"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <button
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
          (click)="onPageBack(location, router, '/places')"
        >
          <mat-icon class="icon-mat">arrow_back</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutAlign="center start">
          <div *ngIf="venue" class="h1">
            {{ "VENUES.VENUE" | translate }} - {{ venue.name }}
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center">
        <button
          mat-button
          matTooltip="{{ 'VENUES.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="icon-mat s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <div
    *ngIf="venue && !editable"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="height: 38px; min-height: 38px; max-height: 38px"
    class="gt2-gray mat-elevation-z1 py-8"
  >
    <div class="text-white font-weight-600">
      <b>{{ "VENUES.READ_ONLY_MODE" | translate }}</b> :
      {{ "VENUES.ARCHIVE_PAGE" | translate }}
    </div>
  </div>

  <!--HISTORY SIDEBAR-->
  <div *ngIf="!isSmallScreen" class="container-history-panel">
    <fuse-sidebar
      *ngIf="venueID"
      name="venueHistoryPanel"
      position="right"
      class="section-history-panel"
    >
      <app-section-history-panel
        *ngIf="venue"
        panelName="venueHistoryPanel"
        section="places"
        sectionUUID="{{ venueID }}"
      >
      </app-section-history-panel>
    </fuse-sidebar>
  </div>
  <!-- / HISTORY SIDEBAR-->

  <!-- CONTENT -->
  <div class="content">
    <!--<mat-sidenav-container>-->

    <!--HISTORY SIDEBAR BUTTON-->
    <div *ngIf="!isSmallScreen">
      <button
        mat-icon-button
        class="warn mat-elevation-z2 historic-button"
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        (click)="toggleHistoricSidebarOpen('venueHistoryPanel')"
      >
        <mat-icon class="icon-mat">history</mat-icon>
      </button>
      <!-- </div> -->
    </div>
    <!-- /HISTORY SIDEBAR BUTTON-->

    <!-- CENTER -->
    <div class="center p-24 pb-56 pr-sm-72" fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="venue-content">
        <div
          *ngIf="venue"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "VENUES.INFORMATION" | translate }}</div>
          </header>

          <div class="content" fxLayout="column">
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start start"
            >
              <!--<div *ngIf="!editMode" class="info-line" fxFlex="100%">-->
              <!--<div class="title">Title</div>-->
              <!--<div class="info">Info</div>-->
              <!--</div>-->
              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "VENUES.NAME" | translate }}</div>
                <div class="info">{{ venue.name }}</div>
                <div *ngIf="!venue.name" class="info empty-value"></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="form" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{ "VENUES.NAME" | translate }}</mat-label>
                      <input
                        matInput
                        placeholder="{{ 'VENUES.NAME' | translate }}"
                        formControlName="name"
                        type="text"
                        autocomplete="nope"
                        [(ngModel)]="venue.name"
                        required
                      />
                      <mat-error *ngIf="form.controls['name'].invalid"
                        >{{ "VENUES.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "VENUES.CONTACT_NAME" | translate }}</div>
                <div class="info">{{ venue.contact_name }}</div>
                <div *ngIf="!venue.contact_name" class="info empty-value"></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="form" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{
                        "VENUES.CONTACT_NAME" | translate
                      }}</mat-label>
                      <input
                        matInput
                        placeholder="{{ 'VENUES.CONTACT_NAME' | translate }}"
                        formControlName="contact_name"
                        type="text"
                        autocomplete="nope"
                        [(ngModel)]="venue.contact_name"
                      />
                      <mat-error *ngIf="form.controls['contact_name'].invalid"
                        >{{ "VENUES.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start start"
              class=""
            >
              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "VENUES.INTERNAL" | translate }}</div>
                <div *ngIf="venue.internal" class="info">
                  {{ "VENUES.YES" | translate }}
                </div>
                <div *ngIf="!venue.internal" class="info">
                  {{ "VENUES.NO" | translate }}
                </div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="form" class="">
                    <mat-checkbox
                      labelPosition="before"
                      (change)="onVenueIsInternalChange($event)"
                      color="accent"
                      [(ngModel)]="venue.internal"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      {{ "VENUES.INTERNAL" | translate }}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="venue" class="" fxLayout="column">
          <!--<header class="accent">-->
          <!--<div class="title">{{ "VENUES.ROOMS" | translate}}</div>-->
          <!--</header>-->
          <!-- [disabledAddSubRoom]="venue.internal" -->
          <div class="" fxLayout="column">
            <app-rooms
              #approom
              [roomsServiceURL]="roomsServiceURL"
              [parentType]="'PLACE'"
              [parentUUID]="venueID"
              [editMode]="editMode"
              [showUnlinkButton]="false"
              [breadCrumbName]="'VENUES.VENUES' | translate"
            ></app-rooms>
          </div>
        </div>

        <div
          *ngIf="venue && venue.contact"
          class="profile-box info-box general mt-12"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "VENUES.CONTACT" | translate }}</div>
          </header>

          <mat-tab-group animationDuration="0ms" class="">
            <mat-tab label="{{ 'VENUES.GENERAL' | translate }}">
              <div class="">
                <!--<form [formGroup]="form" class="">-->
                <app-contact
                  #contactComponent
                  [(groupModel)]="venue.contact"
                  [editMode]="false"
                  [useAutosave]="false"
                  [hideSaveButton]="true"
                  class=""
                ></app-contact>

                <!--</form>-->
              </div>
            </mat-tab>

            <mat-tab label="{{ 'VENUES.ADDRESS' | translate }}">
              <div class="">
                <!--<form [formGroup]="form" class="p-4">-->
                <app-address
                  #addressComponent
                  [(groupModel)]="venue.address"
                  [isVisible]="true"
                  [editMode]="false"
                  [useAutosave]="false"
                  [hideSaveButton]="true"
                  class=""
                ></app-address>
                <!--</form>-->
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div
          *ngIf="venue"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "VENUES.NOTES" | translate }}</div>
          </header>

          <div class="">
            <!--<form [formGroup]="form" class="">-->
            <p-editor
              #noteEditor
              [(ngModel)]="venue.note"
              [readonly]="!editMode"
              [style]="{
                height: '140px',
                width: '100%',
                'background-color': editMode ? '#ffffff' : '',
              }"
            >
              <ng-template pTemplate="header" class="">
                <span class="ql-formats">
                  <select class="ql-font"></select>
                  <select class="ql-size"></select>
                </span>

                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>

                <span class="ql-formats">
                  <select class="ql-color"></select>
                  <select class="ql-background"></select>
                </span>

                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                </span>
              </ng-template>
            </p-editor>
            <!--</form>-->
            <mat-error
              *ngIf="noteInvalid"
              fxLayout="row"
              fxLayoutAlign="end center"
              class="p-4 pr-8"
            >
              {{ "VENUES.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
            </mat-error>
          </div>
        </div>

        <app-comment-section
          *ngIf="venue"
          [commentsModel]="venue"
          [disabled]="!editable"
          [serviceAPIUrl]="'/v1/places/comments/' + venue.uuid"
        >
        </app-comment-section>

        <app-created-updated-at
          *ngIf="venue"
          [historicModel]="venue.historic_model"
        ></app-created-updated-at>

        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <div
            *ngIf="venue && editable"
            fxFlex="100%"
            fxLayout="row"
            fxLayoutAlign="center center"
            class="mt-8"
          >
            <button mat-raised-button (click)="deleteVenue()" class="warn">
              {{ "VENUES.DELETE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--</mat-sidenav-container>-->
  </div>

  <div *ngIf="venue" fxLayout="row" fxLayoutAlign="end start">
    <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
      <div class="fixed-button">
        <!-- <button mat-fab
                    class="gt2-edit-button"
                    id="edit-employee-button"
                    (click)="onEdit($event)"
                    [disabled]="loading"
                    *fuseIfOnDom [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
                <mat-icon *ngIf="!addressComponent.editMode">{{ getIcon('EDIT_FAB') }}</mat-icon>
                <mat-icon *ngIf="addressComponent.editMode">{{ getIcon('UNDO') }}</mat-icon>
            </button> -->
        <button
          mat-fab
          #saveButton
          [ngClass]="editMode ? 'gt2-save-button' : 'gt2-edit-button'"
          id="edit-venue-button"
          (click)="onEdit($event)"
          [disabled]="
            loading ||
            (editMode && (!form.valid || !contactComponent.form.valid)) ||
            !editable
          "
          *fuseIfOnDom
          [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        >
          <mat-icon class="icon-mat" *ngIf="!editMode && !loading">{{
            getIcon("EDIT_FAB")
          }}</mat-icon>
          <mat-icon
            *ngIf="editMode && !loading && !saveButton.disabled"
            matTooltip="{{ 'VENUES.SAVE' | translate }}"
            position="left"
            showDelay="900"
            hideDelay="900"
            class="icon-mat"
            >{{ getIcon("SAVE") }}</mat-icon
          >
          <mat-icon
            *ngIf="editMode && !loading && saveButton.disabled"
            matTooltip="{{ 'VENUES.FORM_DIRTY_TIP' | translate }}"
            position="left"
            hideDelay="900"
            class="icon-mat"
            >{{ getIcon("SAVE") }}</mat-icon
          >
          <i [ngClass]="loading ? 'fa fa-spinner fa-spin' : ''"></i>
        </button>
      </div>

      <div class="fixed-button2">
        <button
          mat-fab
          *ngIf="editMode"
          id="cancel-edit-venue-button"
          class="gt2-cancel-edit-button"
          matTooltip="{{ 'VENUES.CANCEL' | translate }}"
          position="left"
          showDelay="900"
          hideDelay="900"
          (click)="onCancelEdit($event)"
          [disabled]="loading"
          [@animate]="{ value: '*', params: { delay: '120ms', scale: '.2' } }"
        >
          <mat-icon class="icon-mat s-24">{{ getIcon("CANCEL") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
