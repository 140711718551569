import { FuseConfig } from "../../@fuse/types";

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */
let colorCache = localStorage.getItem("theme");
let currentTheme = colorCache === "theme-blue-gray-dark" || colorCache === "theme-default" ? colorCache : "theme-default";
document.body.className = currentTheme;

export const fuseConfig: FuseConfig = {
  // Color themes can be defined in src/app/app.theme.scss
  //colorTheme: "theme-blue-gray-dark",
  colorTheme: currentTheme,
  customScrollbars: true,
  layout: {
    style: "vertical-layout-1",
    width: "fullwidth",
    navbar: {
      primaryBackground: "fuse-navy-700",
      secondaryBackground: "fuse-navy-900",
      folded: false,
      hidden: false,
      position: "left",
      variant: "vertical-style-1",
    },
    toolbar: {
      customBackgroundColor: false,
      background: "fuse-white-500",
      hidden: false,
      position: "below-static",
    },
    footer: {
      customBackgroundColor: true,
      background: "fuse-navy-900",
      hidden: false,
      position: "below-fixed",
    },
    sidepanel: {
      hidden: false,
      position: "right",
    },
  },
};
export function switchTheme() {
  if (currentTheme === "theme-default") {
    currentTheme = "theme-blue-gray-dark";
  } else {
    currentTheme = "theme-default";
  }
  document.body.className = currentTheme;
  localStorage.setItem("theme", currentTheme);
}
