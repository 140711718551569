import { GenericAlertComponent } from './../../../components/generic-alert/generic-alert.component';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
//import { AmazingTimePickerService } from 'amazing-time-picker';
import { EventRoomService } from '../../../api/event-room.service';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-event-for-room',
  templateUrl: './create-event-for-room.component.html',
  styleUrls: ['./create-event-for-room.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatProgressSpinnerModule,
    MatInputModule,
    NgxMaterialTimepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    FormsModule,
    MatDatepickerModule,
    MatSelectModule,
  ],
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
})
export class CreateEventForRoomComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static FROM_RESERVATION: string = 'FROM_RESERVATION';
  static CREATE_EVENT_FROM_RESERVATION: string =
    'CREATE_EVENT_FROM_RESERVATION';
  static EDIT_RESERVATION: string = 'EDIT_RESERVATION';

  form: UntypedFormGroup;
  selectionInfo: any;
  newEvent: any;
  roomName: string = '';
  placeName: string = '';
  type: string | any = CreateEventForRoomComponent.CREATE;
  loading: boolean = false;
  confirmDialogRef: any;

  constructor(
    public override dialogRef: MatDialogRef<CreateEventForRoomComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private eventRoomService: EventRoomService,
    //private atp: AmazingTimePickerService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    // //this.logger.info("CreateEventForRoomComponent.contructor()");

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      event_date: ['', [Validators.required]],
      event_date_end: ['', [Validators.required]],
      // event_time: ['', [Validators.required]],
      // event_time_end: ['', [Validators.required]],
      // amount: ['', [ValidatorHelper.isAboveZero, ValidatorHelper.isNumber0To1000000]],
    });

    this.newEvent = {
      name: '',
      order_type: null,
      event_date: null,
      event_date_end: null,
      event_time: null,
      event_time_end: null,
      // place_uuid: null,
      uuid_room: null,
    };

    if (data && data.selectionInfo) {
      this.selectionInfo = data.selectionInfo;
      this.type = data.type;
      this.newEvent.order_type = data.selectionInfo.order_type;
      this.newEvent.name = data.selectionInfo.title;

      // date
      this.newEvent.event_date = moment(this.selectionInfo.startStr).format();
      this.newEvent.event_date_end = moment(this.selectionInfo.endStr).format();

      // time
      if (this.newEvent.order_type === 'BLOCKED') {
        this.newEvent.event_time = moment(this.selectionInfo.startStr).format(
          'HH:mm',
        ); //  moment.utc(this.selectionInfo.startStr, "HH:mm"); // moment(this.selectionInfo.startStr).format("hh:mm");
        this.newEvent.event_time_end = moment(this.selectionInfo.endStr).format(
          'HH:mm',
        ); // moment.utc(this.selectionInfo.endStr, "HH:mm"); // moment(this.selectionInfo.endStr).format("hh:mm");
      } else {
        this.newEvent.event_time = moment(this.selectionInfo.startStr)
          .utc()
          .format('HH:mm'); //  moment.utc(this.selectionInfo.startStr, "HH:mm"); // moment(this.selectionInfo.startStr).format("hh:mm");
        this.newEvent.event_time_end = moment(this.selectionInfo.endStr)
          .utc()
          .format('HH:mm'); // moment.utc(this.selectionInfo.endStr, "HH:mm"); // moment(this.selectionInfo.endStr).format("hh:mm");
      }

      // //this.logger.info("CreateEventForRoomComponent.contructor() -> this.selectionInfo: " + this.selectionInfo);
      // //this.logger.info("CreateEventForRoomComponent.contructor() -> this.newEvent: " + JSON.stringify(this.newEvent));
      // //this.logger.info("CreateEventForRoomComponent.contructor() -> test: " + moment(this.selectionInfo.startStr).format('LL') );
    }

    if (data && data.uuid_room) {
      this.newEvent.uuid_room = data.uuid_room;
    }

    if (data && data.roomName) {
      this.roomName = data.roomName;
      // //this.logger.info("CreateEventForRoomComponent.contructor() -> this.roomNames: " + this.roomName);
    }

    if (data && data.placeName) {
      this.placeName = data.placeName;
      // //this.logger.info("CreateEventForRoomComponent.contructor() -> this.placeName: " + this.placeName);
    }

    //this.logger.info("CreateEventForRoomComponent.contructor() -> this.newEvent: " + JSON.stringify(this.newEvent));
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateEvent(): void {
    // //this.logger.info(
    //     "CreateEventForRoomComponent.onCreateEvent() -> this.newEvent: " +
    //         JSON.stringify(this.newEvent)
    // );
    this.loading = true;

    if (this.newEvent.order_type === 'BLOCKED') {
      this.eventRoomService.createBlocked(this.newEvent).subscribe(
        (response) => {
          // //this.logger.info("CreateEventForRoomComponent.onCreateEvent() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.dialogRef.close(response);
        },
        (error) => {
          this.logger.error(
            'CreateEventForRoomComponent.onCreateEvent() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.GENERIC"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      );
    } else {
      this.eventRoomService.createEvent(this.newEvent).subscribe(
        (response) => {
          // //this.logger.info("CreateEventForRoomComponent.onCreateEvent() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.dialogRef.close(response);
        },
        (error) => {
          this.logger.error(
            'CreateEventForRoomComponent.onCreateEvent() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.GENERIC"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      );
    }
  }

  public onCreateEventFromReservation(): void {
    this.loading = true;
    //this.logger.info("CreateEventForRoomComponent.onCreateEventFromReservation() -> " + this.newEvent.order_type);
    this.eventRoomService
      .createEventForReservation(
        this.selectionInfo.id,
        this.newEvent.order_type,
      )
      .subscribe(
        (response) => {
          // //this.logger.info("CreateEventForRoomComponent.onCreateEventFromReservation() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.dialogRef.close(response);
        },
        (error) => {
          this.logger.error(
            'CreateEventForRoomComponent.onCreateEventFromReservation() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.GENERIC"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      );
  }

  public onSaveEditReservation(): void {
    //this.logger.info("CreateEventForRoomComponent.onSaveEditReservation()");
    this.loading = true;
    this.eventRoomService
      .updateReservation(this.newEvent, this.selectionInfo.id)
      .subscribe(
        (response) => {
          // //this.logger.info("CreateEventForRoomComponent.onSaveEditReservation() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.dialogRef.close(response);
        },
        (error) => {
          this.logger.error(
            'CreateEventForRoomComponent.onSaveEditReservation() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.GENERIC"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      );
  }

  public onDeleteReservation(): void {
    //this.logger.info("CreateEventForRoomComponent.onDeleteReservation() -> this.selectionInfo: " + JSON.stringify(this.selectionInfo));
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.eventRoomService
          .deleteReservation(this.selectionInfo.id)
          .subscribe(
            (response) => {
              // //this.logger.info("CreateEventForRoomComponent.onDeleteReservation() -> SUCCESS: " + JSON.stringify(response));
              this.loading = false;
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
              );
              this.dialogRef.close(true);
            },
            (error) => {
              this.logger.error(
                'CreateEventForRoomComponent.onDeleteReservation() -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.loading = false;
              // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          );
      }
    });
  }

  public onEditReservationUI(): void {
    //this.logger.info("CreateEventForRoomComponent.onEditReservationUI()");
    this.type = CreateEventForRoomComponent.EDIT_RESERVATION;
  }

  public onCreateEventForReservationUI(): void {
    //this.logger.info("CreateEventForRoomComponent.onCreateEventForReservationUI()");
    this.type = CreateEventForRoomComponent.CREATE_EVENT_FROM_RESERVATION;
  }

  public onBack(): void {
    this.type = CreateEventForRoomComponent.FROM_RESERVATION;
  }

  public onDateFromChange(event: any): void {
    // //this.logger.info("CreateEventForRoomComponent.onDateFromChange() -> event: " + event);
    this.newEvent.event_date = event;
  }

  public onDateToChange(event: any): void {
    // //this.logger.info("CreateEventForRoomComponent.onDateToChange() -> event: " + event);
    this.newEvent.event_date_end = event;
  }
  public openStartTimePicker(): void {
    // const amazingTimePicker = this.atp.open({
    //   time: this.newEvent.event_time,
    // });
    // amazingTimePicker.afterClose().subscribe((time: any) => {
    //   this.newEvent.event_time = time;
    // });
  }
  public openEndTimePicker(): void {
    // const amazingTimePicker = this.atp.open({
    //   time: this.newEvent.event_time_end,
    // });
    // amazingTimePicker.afterClose().subscribe((time: any) => {
    //   this.newEvent.event_time_end = time;
    // });
  }
}
