import { ExportFormatEnum } from './../ReportsEnum';
import { ReportFactoryService } from './../report-factory.service';
import { ReportVOModel } from './../reports-factory';
import { NGXLogger } from 'ngx-logger';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
} from '@angular/material/dialog';
import { GT2PageAbstract } from './../../content/abstract/GT2PageAbstract';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Inject,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { ReportButtonComponent } from '../report-button/report-button.component';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
    MatDialogModule,
    MatIconModule,
    ReportButtonComponent,
  ],
})
export class ReportViewerComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('previewContainer')
  previewContainer!: ElementRef;

  @Input() vo!: ReportVOModel | any;
  rawHTML;
  rawHTMLString!: string;
  exportFormat = ExportFormatEnum.PDF;

  constructor(
    public override dialogRef: MatDialogRef<ReportViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public reportFactoryService: ReportFactoryService,
    private logger: NGXLogger,
  ) {
    super();
    if (data && data.rawHTML) {
      this.rawHTMLString = data.rawHTML;
      this.rawHTML = this.sanitizer.bypassSecurityTrustHtml(data.rawHTML);
      // this.logger.info("ReportViewerComponent.constructor() --> rawHTML: " + this.rawHTML);
    }

    if (data && data.vo) {
      this.vo = data.vo;
      if (this.vo?.report_key.includes('XLSX')) {
        this.exportFormat = ExportFormatEnum.XLSX;
      }
    }

    if (!this.vo || !this.rawHTML) {
      this.logger.error(
        'ReportViewerComponent.constructor() --> NO rawHTML OR NO vo',
      );
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.previewContainer) {
      setTimeout(() => {
        this.previewContainer.nativeElement.scrollTop = 0;
      }, 500);
    }
  }

  ngOnDestroy() {}
}
