<div style="min-width: 350px" class="">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div class="info-line" fxFlex="100%" fxLayout="column">
      <mat-radio-group
        aria-label="Select an option"
        fxLayout="column"
        [ngModelOptions]="{ standalone: true }"
        (change)="onRadioGroupChange($event)"
        [(ngModel)]="multiTakeoutRadioValue"
        [disabled]="!editMode"
      >
        <mat-radio-button [value]="0">
          {{ "SHOPS.ACCEPT_MULTI_TAKEOUT" | translate }}
        </mat-radio-button>

        <mat-radio-button [value]="1">
          {{ "SHOPS.USE_MULTI_TAKEOUT_INPUT" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div
    *ngIf="editMode"
    fxLayout="row"
    fxLayoutAlign="end start"
    style="margin-bottom: 16px"
  >
    <button
      (click)="onSaveInfo()"
      color="accent"
      class="save-btn"
      mat-raised-button
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
  </div>
</div>

<div *ngIf="multiPointTakeoutModel" class="profile-box info-box general">
  <header class="accent" fx-fxLayout="row" fxLayoutAlign="space-between center">
    <div class="sub-title">{{ "SHOPS.POINT_TAKEOUT" | translate }}</div>
    <div
      *ngIf="editMode"
      class="gt2-light-green"
      fxLayout="row"
      fxLayoutAlign="center center"
      style="width: 60px; height: 40px"
    >
      <button
        (click)="onAddMultiPointTakeout()"
        class=""
        mat-icon-button
        matTooltip="{{ 'SHOPS.ADD_MULTI_POINT_TAKEOUT' | translate }}"
        type="button"
      >
        <mat-icon class="" color="">add</mat-icon>
      </button>
    </div>
  </header>

  <div class="sub-content bracket-list" fxLayout="column">
    <div *ngFor="let item of multiPointTakeoutModel; let i = index">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start start"
        class=""
      >
        <div class="info-line" fxFlex="1000%">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="info text-truncate">
              {{ item.name_local[localeHelperService.name] }}
            </div>
            <div *ngIf="editMode">
              <button
                mat-icon-button
                (click)="onEditPointTakeout(item)"
                content-type="template"
                type="button"
              >
                <mat-icon class="s-20" color="">edit</mat-icon>
              </button>

              <button
                mat-icon-button
                *ngIf="editMode"
                class=""
                (click)="onRemovePointTakeout(item, i)"
                aria-label="Palette"
              >
                <mat-icon class="s-20">delete_forever</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
