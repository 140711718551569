<div *ngIf="model">
  <div
    fxLayout="column"
    fxLayoutAlign="0 1 auto"
    class="taxes-child-item"
    style="min-width: 350px"
  >
    <!--name-->
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100%">
      <div class="mr-12" fxFlex="30%">
        <div class="font-weight-500">
          {{ "SETTINGS.MAX_USERS" | translate }}
        </div>
        <div class="mat-caption">
          {{ "GENERIC.USERS_COUNT" | translate }} : {{ model.users_count }}
        </div>
      </div>
      <!--max_user input-->
      <div [formGroup]="form" fxFlex="70%" class="" fxLayout="column">
        <mat-form-field class="" fxFlex="100%">
          <input
            matInput
            formControlName="max_user"
            type="text"
            autocomplete="nope"
            maxlength="191"
            [(ngModel)]="model.max_user"
          />
          <mat-error *ngIf="form.controls['max_user'].invalid">{{
            "GENERIC.INPUT_NUMBER" | translate
          }}</mat-error>
          <mat-error *ngIf="model.users_count > model.max_user"
            >{{ "GENERIC.MAX_USER_TOO_LOW_ERROR" | translate }} ({{
              model.users_count
            }})</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" class="mt-20">
      <button
        mat-raised-button
        color="accent"
        (click)="updateMaxUsers()"
        [disabled]="form.invalid || model.users_count > model.max_user"
        style="font-size: 11px"
        class=""
      >
        {{ "SETTINGS.UPDATE" | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
