import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { IGetDataService } from '../components/gt2-select/gt2-select.component';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';

@Injectable()
export class ChangelogsService implements IGetDataService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}
  getData(): Observable<any> {
    //this.logger.info("ChangelogsService.getData()");
    return this.getChangelogsList();
  }

  public getChangelogsList(): any {
    //this.logger.info("ChangelogsService.getChangelogsList()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.GET_CHANGELOGS_LIST)
    );
  }

  public createChangelog(changelog: ChangelogModel): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CREATE_CHANGELOG),
      changelog
    );
  }
}
export interface ChangelogModel {
  log: string | null;
  event_uuid: string | null;
  order_uuid: string | null;
}
