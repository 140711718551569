import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class CrudDeliverySectorsService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public createSector(sector: any): Observable<any> {
    // this.logger.info("CrudDeliverySectorsService.createSector() -> searchString: " + JSON.stringify(sector));
    return this.http.post<any>(this.api.createUrl(ApiRoutes.SECTORS), sector);
  }
}
