export const locale = {
  lang: "en-CA",
  data: {
    SELECT_PLACE: {
      TITLE: "Select a place and a room",
      SELECTED_ROOM: "Selected room",
      NO_RESULT: "No result found",
    },
  },
};
