<div id="eventGuest">
  <div fxLayout="row" fxLayout="start center" class="section-list-header">
    <!-- <div fxFlex="50px" class=""></div> -->
    <div fxFlex class="section-list-item-border-right pl-8 py-4">
      {{ "GENERIC.NAME" | translate }}
    </div>
    <div
      fxFlex="16"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.TOTAL" | translate }}
    </div>
    <div
      fxFlex="16"
      class="section-list-item section-list-item-border-right py-4"
    >
      Actions
    </div>
  </div>

  <div fusePerfectScrollbar>
    <div
      *ngIf="!event || !event.guests || event.guests.length === 0"
      fxFlex="100"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <div class="font-size-20 my-16">{{ "EVENTS.NO_GUEST" | translate }}</div>
    </div>

    <div
      *ngIf="loading"
      fxLayout="row"
      fxLayoutAlign="center center"
      style="height: 100%; width: 100%"
    >
      <mat-spinner [diameter]="40" class="my-40"></mat-spinner>
    </div>

    <div
      *ngIf="event && !loading && event.guests && event.guests.length > 0"
      fxFlex="100"
    >
      <div
        *ngFor="let item of event.guests"
        fxLayout="row"
        fxLayout="start center"
        class="child-item"
      >
        <div
          fxFlex
          fxLayout="row"
          fxLayout="start center"
          class="section-list-item-border-right pl-8 py-8"
        >
          {{ item.name }}
        </div>
        <div
          fxFlex="16"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.guest_type_count }}
        </div>
        <!-- <div fxFlex="20" class="section-list-item section-list-item-border-right text-truncate py-8">
                        <span class="text-truncate px-8">{{item.note_label}}</span>
                    </div> -->
        <div fxFlex="16" class="section-list-item">
          <button
            class=""
            mat-icon-button
            [disabled]="!editable"
            (click)="onEditGuest(item)"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("EDIT")
            }}</mat-icon>
          </button>
          <button
            class=""
            mat-icon-button
            [disabled]="!editable"
            (click)="onDeleteGuest(item)"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("DELETE")
            }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
