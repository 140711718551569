import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '../api/auth-service.service';
import { LocalizationConfig } from '../../localization/LocalizationConfig';
import { ApiBenching, APICallBenching } from '../benching/api-benching';
import { NGXLogger } from 'ngx-logger';
import { Settings } from '../settings/settings';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  static injector: Injector;

  auth?: AuthenticationService;
  readonly environment = environment;

  constructor(public inj: Injector, public logger: NGXLogger) {
    AuthInterceptor.injector = inj;
  }

  static injectHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const auth = AuthInterceptor.injector.get(AuthenticationService);
    const idToken = auth.getToken();
    const lang: string = LocalizationConfig.getCurrentLanguage().locale;
    const appVersion = environment.appVersion ? environment.appVersion : '';

    if (idToken) {
      return req.clone({
        headers: req.headers
          .set('Authorization', 'Bearer ' + idToken)
          .set('Accept-Language', lang)
          .set('client-timezone', Settings.timezone)
          .set('app-version', appVersion),
        // .set('Cache-Control', 'no-cache')
      });
    } else {
      return req.clone({
        headers: req.headers.set('Accept-Language', lang), // .set('Cache-Control', 'no-cache')
      });
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const auth = this.inj.get(AuthenticationService);
    const idToken = auth.getToken();
    const lang: string = LocalizationConfig.getCurrentLanguage().locale;
    // this.logger.info("AuthInterceptor.intercept() -> lang: " + lang);
    // this.logger.info("AuthInterceptor.intercept() ->>>>> access token: " + idToken);
    // this.logger.info("AuthInterceptor.intercept() ->>>>> req.url: " + req.url);
    const cloned = AuthInterceptor.injectHeaders(req);

    const callBenching: APICallBenching = {
      url: req.url,
      startTime: new Date().getTime(),
      endTime: 0,
      seconds: 0,
      method: req.method,
    };

    return next.handle(cloned).pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          callBenching.endTime = new Date().getTime();
          callBenching.seconds =
            (callBenching.endTime - callBenching.startTime) / 1000;
          ApiBenching.addCall(callBenching);
        }
      })
    );

    // if (idToken)
    // {
    //     const cloned = req.clone({
    //         headers: req.headers.set("Authorization",
    //             "Bearer " + idToken).set("Accept-Language", lang)
    //     });
    //
    //     const callBenching: APICallBenching = {url: req.url, startTime: new Date().getTime(), endTime: 0, seconds: 0, method: req.method};
    //
    //     return next.handle(cloned)
    //         .do((ev: HttpEvent<any>) =>
    //         {
    //             if (ev instanceof HttpResponse)
    //             {
    //                 callBenching.endTime = new Date().getTime();
    //                 callBenching.seconds = (callBenching.endTime - callBenching.startTime) / 1000;
    //                 ApiBenching.addCall(callBenching);
    //             }
    //         });
    // }
    // else
    // {
    //     const clonedLang = req.clone({
    //         headers: req.headers.set("Accept-Language", lang)
    //     });
    //
    //     return next.handle(clonedLang)
    //         .do((ev: HttpEvent<any>) =>
    //         {
    //             if (ev instanceof HttpResponse)
    //             {
    //                 this.logger.info('RESPONSE: processing response', ev);
    //             }
    //         });
    //     // return next.handle(req);
    // }
  }
}
