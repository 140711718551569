<div id="eventDocument">
  <div fxLayout="row" fxLayout="start center" class="section-list-header">
    <!-- <div fxFlex="50px" class=""></div> -->
    <div fxFlex="20" class="section-list-item-border-right pl-12 py-4">
      {{ "GENERIC.TYPE" | translate }}
    </div>
    <div
      fxFlex="40"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.NAME" | translate }}
    </div>
    <div
      fxFlex="15"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "EVENTS.DOCUMENT_NUMBER" | translate }}
    </div>
    <div
      fxFlex="13"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.STATUS" | translate }}
    </div>
    <div
      fxFlex="12"
      class="section-list-item section-list-item-border-right py-4"
    >
      Actions
    </div>
  </div>

  <div fxLayout="column" fusePerfectScrollbar>
    <div
      *ngIf="
        !event.documents ||
        (!event.documents.order &&
          (!event.documents.proposals ||
            event.documents.proposals.length === 0) &&
          (!event.documents.invoice || event.documents.invoice.length === 0))
      "
      fxFlex="100"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <div class="font-size-20 my-16">
        {{ "EVENTS.NO_DOCUMENTS" | translate }}
      </div>
    </div>

    <div
      *ngIf="event.documents && event.documents.order"
      fxLayout="row"
      fxLayout="start center"
      class="child-item"
    >
      <div
        fxFlex="20"
        fxLayout="row"
        fxLayout="start center"
        (click)="onOpenPage(event.documents.order)"
        class="section-list-item-border-right pl-12 py-8"
      >
        <mat-icon class="mr-8">{{ getIcon("ORDER") }}</mat-icon>
        <div class="text-truncate">
          <span class="text-truncate">{{
            "GENERIC." + event.documents.order.type | translate
          }}</span>
        </div>
      </div>
      <div
        fxFlex="40"
        (click)="onOpenPage(event.documents.order)"
        class="section-list-item section-list-item-border-right py-8"
      >
        {{ event.documents.order.name }}
      </div>
      <div
        fxFlex="15"
        (click)="onOpenPage(event.documents.order)"
        class="section-list-item section-list-item-border-right py-8"
      >
        {{ event.documents.order.order_number }}
      </div>
      <div
        fxFlex="13"
        (click)="onOpenPage(event.documents.order)"
        class="section-list-item section-list-item-border-right py-8"
      >
        {{ event.documents.order.status_label }}
      </div>
      <div fxFlex="12" class="section-list-item">
        <button
          class=""
          mat-icon-button
          [disabled]="!editable"
          (click)="onOpenPage(event.documents.order)"
        >
          <mat-icon class="s-16 secondary-text">{{ getIcon("EDIT") }}</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenItemInNewTab(event.documents.order)"
          class="mat-icon-button"
        >
          <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>

    <div
      *ngIf="
        event.documents &&
        event.documents.proposals &&
        event.documents.proposals.length > 0
      "
      fxFlex="100"
    >
      <div
        *ngFor="let item of event.documents.proposals"
        fxLayout="row"
        fxLayout="start center"
        class="child-item"
      >
        <div
          fxFlex="20"
          fxLayout="row"
          fxLayout="start center"
          (click)="onOpenPage(item)"
          class="section-list-item-border-right pl-12 py-8"
        >
          <mat-icon class="mr-8">{{ getIcon("PROPOSAL") }}</mat-icon>
          <div class="">{{ "GENERIC." + item.type | translate }}</div>
        </div>
        <div
          fxFlex="40"
          (click)="onOpenPage(item)"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.name }}
        </div>
        <div
          fxFlex="15"
          (click)="onOpenPage(item)"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.proposal_number }}
        </div>
        <div
          fxFlex="13"
          (click)="onOpenPage(item)"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.status_label }}
        </div>
        <div fxFlex="12" class="section-list-item">
          <button
            class=""
            mat-icon-button
            [disabled]="!editable"
            (click)="onOpenPage(item)"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("EDIT")
            }}</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
            (click)="onOpenItemInNewTab(item)"
            class="mat-icon-button"
          >
            <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="event.documents && event.documents.invoice" fxFlex="100">
      <div fxLayout="row" fxLayout="start center" class="child-item">
        <div
          fxFlex="20"
          fxLayout="row"
          fxLayout="start center"
          (click)="onOpenPage(event.documents.invoice)"
          class="section-list-item-border-right pl-12 py-8"
        >
          <mat-icon class="mr-8">{{ getIcon("INVOICE") }}</mat-icon>
          <div class="mr-8">
            {{ "GENERIC." + event.documents.invoice.type | translate }}
          </div>
        </div>
        <div
          fxFlex="40"
          (click)="onOpenPage(event.documents.invoice)"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ event.documents.invoice.name }}
        </div>
        <div
          fxFlex="15"
          (click)="onOpenPage(event.documents.invoice)"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ event.documents.invoice.invoice_number }}
        </div>
        <div
          fxFlex="13"
          (click)="onOpenPage(event.documents.invoice)"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ event.documents.invoice.status_label }}
        </div>
        <div fxFlex="12" class="section-list-item">
          <button
            class=""
            mat-icon-button
            [disabled]="!editable"
            (click)="onOpenPage(event.documents.invoice)"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("EDIT")
            }}</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
            (click)="onOpenItemInNewTab(event.documents.invoice)"
            class="mat-icon-button"
          >
            <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
