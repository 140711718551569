export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_CLIENT_WITH_DETAILS: {
      TITLE: "Nouveau client",
      TITLE_CONTACT: "Infos contact client",
      TITLE_ADDRESS: "Infos address client",
      COMM_LANGUAGE: "Langue de communication",
      ATTACH_ENTERPRISE: "Attacher une entreprise",
      SAVE_AND_ATTACH: "Sauvegarder et attacher une entreprise",
    },
  },
};
