export const locale = {
  lang: "en-CA",
  data: {
    LABOUR_CHARGE: {
      TITLE: "Labour charge",
      EDIT_TITLE: "Edit labour charge",
      SELECT_LABOUR_CHARGE: "Select a labour charge",
    },
  },
};
