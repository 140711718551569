export class DateFormatPreference {
  static DATE_FORMAT_KEY: string = 'DATE_FORMAT_PREFERENCE';
  static DEFAULT_DATE_FORMAT: string = 'DD-MM-YYYY';

  static saveDateFormat(format: string): void {
    localStorage.setItem(DateFormatPreference.DATE_FORMAT_KEY, format);
  }

  static getDateFormat(): string {
    let dateFormat: string =
      localStorage.getItem(DateFormatPreference.DATE_FORMAT_KEY) || '';
    if (!dateFormat || dateFormat == undefined || dateFormat === 'undefined') {
      dateFormat = DateFormatPreference.DEFAULT_DATE_FORMAT;
    }
    return dateFormat;
  }
}
