export const locale = {
  lang: "en-CA",
  data: {
    SETTINGS: {
      SETTINGS: "Settings",
      BASIC_SETTINGS: "Basic settings",
      "CATEGORIES_&_ZONE": "Categories and zones",
      ORDERS_BILLING_SETTINGS: "Order and invoices",
      TAXES_SETTINGS: "Taxes and account",
      DATE_TIME_SETTINGS: "Date and time",
      CONDITONS_SETTINGS: "Conditions",
      CLIENTS_CATEGORIES: "Clients Categories",
      MATERIALS_CATEGORIES: "Equipment categories",
      STAFF_CATEGORIES: "Staff categories",
      PRODUCTS_CATEGORIES: "Products categories",
      PRODUCTION_ZONES: "Production zones",
      MATERIALS_PROVIDERS: "Equipments Providers",
      STAFF_PROVIDERS: "Staff Providers",
      EVENT_LOGISTIC_STATUS: "Logistic status",
      SERVICE_CHARGE: "Service charges",
      SERVICE_CHARGE_CT: "Administration charge",
      LEDGER_ACCOUNT: "Ledger account",
      LEDGER_ACCOUNT_DEFAULT: "Default ledger account",
      ALLERGENS: "Allergens",
      JOB_FUNCTIONS: "Job functions",
      WRAPPING: "Wrapping",
      WRAPPING_DEFAULT: "Default wrapping",
      SOCIAL_CHARGE: "Global social charge",
      SOCIAL_CHARGE_PERCENT: "Charge sociale globale (in percent, without the % sign)",
      LANG: "Default language",
      TIMEZONE: "Timezone",
      PRINT: "Print",
      DELIVERY_SECTOR: "Delivery sectors",
      DELIVERY: "Delivery",
      TAXES: "Taxes number",
      TAXES_DEFAULT: "Default taxes",
      ONLINE_PAYMENT: "Online payments",
      THEME: "Theme",
      NOTIFICATIONS: "Notifications",
      DOWNLOADS: "Files download",
      SECTORS: "Delivery sectors",
      PRICE: "Price",
      ORDERS_NUMBER: "Orders number",
      NUMBERING: "Numbering",
      INVOICES_NUMBER: "Invoices number",
      PROPOSALS_NUMBER: "Proposals number",
      DATE_FORMAT: "Date format",
      DATE_TIME_FORMAT: "Date time format",
      GENERAL_CONDITIONS: "General conditions",
      SPECIFICS_CONDITIONS: "Specific conditions",
      INTEGRATION: "Integration",
      SAGE: "Sage",

      SECTOR_NAME: "Sector name",
      CREATE_SECTOR: "New sector",

      ALLERGENS_NAME: "Allergens name",
      ALLERGENS_CREATE_NEW: "New allergen",
      ALLERGENS_CREATE_NEW_PLACEHOLDER: "Name of new allergen",

      EVENT_LOGISTIC_STATUS_NAME: "Name",
      EVENT_LOGISTIC_STATUS_CREATE_NEW: "New logistic status",
      EVENT_LOGISTIC_STATUS_CREATE_NEW_PLACEHOLDER: "Logistic status name",

      PRODUCTION_ZONE_NAME: "Production zone name",
      PRODUCTION_ZONE_CREATE_NEW: "New zone",
      PRODUCTION_ZONE_CREATE_NEW_PLACEHOLDER: "Name of new zone",

      MATERIALS_CATEGORY_NAME: "Equipment category name",
      MATERIALS_CATEGORY_CREATE_NEW: "New category",
      MATERIALS_CATEGORY_CREATE_NEW_PLACEHOLDER: "Name of new category",

      PRODUCTION_CATEGORY_NAME: "Production category name",
      PRODUCTION_CATEGORY_CREATE_NEW: "New category",
      PRODUCTION_CATEGORY_CREATE_NEW_PLACEHOLDER: "Name of new category",

      PROVIDERS_NAME: "Provider's name",
      STAF_PROVIDERS_NAME: "Provider's name",
      PROVIDERS: "Providers",
      PROVIDERS_CATEGORY_CREATE_NEW: "New provider",
      STAFF_PROVIDERS_CATEGORY_CREATE_NEW: "New staff provider",
      PROVIDERS_CATEGORY_CREATE_NEW_PLACEHOLDER: "Name of new provider",

      SPECIFIC_CONDITION_NAME: "Condition's name",
      SPECIFIC_CONDITION_CREATE_NEW: "New condition",
      SPECIFIC_CONDITION_CREATE_NEW_PLACEHOLDER: "Name of new condition",

      TIMEZONE_SELECT: "Select a timezone",
      DATE_FORMAT_SELECT: "Select a date format",
      DATE_TIME_FORMAT_SELECT: "Select a date time format",

      UPDATE: "Update",

      ONLINE_DELIVERY: "Online order delivery",

      DELIVERY_FIXED_COST: "Delivery fixed cost",
      DELIVERY_MAX_DISTANCE_KM: "Delivery max distance (km)",
      TAXES_SELECT: "Select a tax",

      DEFAULT_WRAPPING_PRODUCT: "Default product wrapping",
      DEFAULT_WRAPPING_ORDER: "Default order wrapping",
      DEFAULT_WRAPPING_DELIVERY: "Default delivery request wrapping",

      DEFAULT_DELIVERY_TAX: "Default delivery tax",
      DEFAULT_PRODUCT_TAX: "Default product tax ",
      DEFAULT_STAFF_CHARGE_TAX: "Default staff charge tax",
      DEFAULT_MATERIAL_TAX: "Default equipment tax",
      DEFAULT_SERVICE_CHARGE_TAX: "Default service charge tax",

      LEDGER_ACCOUNT_FOOD: "Default food ledger account",
      LEDGER_ACCOUNT_DRINK: "Default drink ledger account",
      LEDGER_ACCOUNT_MATERIAL: "Default equipment ledger account",
      LEDGER_ACCOUNT_SERVICE_CHARGE: "Default service charge ledger account",
      LEDGER_ACCOUNT_STAFF_CHARGE: "Default staff charge ledger account",
      LEDGER_ACCOUNT_DELIVERY: "Default delivery ledger account",
      LEDGER_ACCOUNT_ROYALTY: "Default royalty ledger account",
      LEDGER_ACCOUNT_DISCOUNT: "Default discount ledger account",
      LEDGER_ACCOUNT_TIPS_NO_TAX: "Default tips no tax ledger account",
      LEDGER_ACCOUNT_TAX: "Default tax ledger account",
      ROYALTY_TAX: "Royalty tax",
      EVENTS_TYPES: "Events types",
      EVENTS_TYPES_NAME: "Events types",
      EVENTS_TYPES_CREATE_NEW: "New event type",
      EVENTS_TYPES_CREATE_NEW_PLACEHOLDER: "Name of new event type",

      DELIVERY_TEMPLATES: "Delivery request templates",
      CREATE_NEW_TEMPLATE: "New delivery request templates",
      TEMPLATE_NAME: "Delivery request template name",

      NOTIFICATION_MESSAGES: "Notification messages",
      NOTIFICATION_MESSAGE_PROPOSAL: "Proposal",
      NOTIFICATION_MESSAGE_ORDER: "Order",
      NOTIFICATION_MESSAGE_INVOICE: "Invoice",
      NOTIFICATION_MESSAGE_INVOICE_ON_LINE_GP: "Online invoice payment (GP)",
      NOTIFICATION_MESSAGE_INVOICE_ON_LINE_GP_PAGE: "Online invoice payment | Global Payment",
      NOTIFICATION_MESSAGE_ALL_RECEIPT_NOTICES: "Receipt notice",
      NOTIFICATION_MESSAGE_RECEIPT: "Receipt",
      NOTIFICATION_MESSAGE_CLIENT_STATEMENT: "Client statement",
      NOTIFICATION_MESSAGE_ENTERPRISE_STATEMENT: "Enterprise statement",
      NOTIFICATION_MESSAGE_SCHEDULE: "Schedule",
      NOTIFICATION_MESSAGE_BANQUET_CONTRACT: "Congress - Contrat banquet",
      NOTIFICATION_MESSAGE_HISTORIC_ACCOUNT: "Client account historic",
      USERS_AND_EMPLOYEES: "Users and Employees",
      MAX_USERS: "Number of users",
    },
  },
};
