<div style="max-height: 100%; overflow: auto">
  <h1 matDialogTitle class="h1-title">
    {{ "ORDERS.EDIT_PRODUCT" | translate }}
  </h1>
  <div *ngIf="product" [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="30">
        <!-- INTERNAL_NAME_FR -->
        <mat-form-field class="item-width">
          <mat-label>{{ "ORDERS.INTERNAL_NAME_FR" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="name_internal_fr_CA"
            type="text"
            [(ngModel)]="product.name_internal_local.name_internal_fr_CA"
            required
          />
          <mat-error *ngIf="form.controls.name_internal_fr_CA.invalid">
            {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <!-- INTERNAL_NAME_EN -->
        <mat-form-field class="item-width">
          <mat-label>{{ "ORDERS.INTERNAL_NAME_EN" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="name_internal_en_CA"
            type="text"
            [(ngModel)]="product.name_internal_local.name_internal_en_CA"
            required
          />
          <mat-error *ngIf="form.controls.name_internal_en_CA.invalid">
            {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <!-- EXTERNAL_NAME_FR -->
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field class="item-width">
            <button
              class=""
              mat-icon-button
              matPrefix
              (click)="
                product.name_local.name_fr_CA =
                  product.name_internal_local.name_internal_fr_CA
              "
              class=""
              [matTooltip]="
                'GENERIC.COPY_INTERNAL_NAME_TO_EXTERNAL' | translate
              "
            >
              <mat-icon class="s-12">redo</mat-icon>
            </button>
            <mat-label>{{ "ORDERS.EXTERNAL_NAME_FR" | translate }}</mat-label>
            <input
              matInput
              fxFlex="100%"
              type="text"
              formControlName="name_fr_CA"
              [(ngModel)]="product.name_local.name_fr_CA"
              required
            />
            <mat-error *ngIf="form.controls.name.invalid">
              {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <!-- EXTERNAL_NAME_EN -->
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field class="item-width">
            <button
              class=""
              mat-icon-button
              matPrefix
              (click)="
                product.name_local.name_en_CA =
                  product.name_internal_local.name_internal_en_CA
              "
              class=""
              [matTooltip]="
                'GENERIC.COPY_INTERNAL_NAME_TO_EXTERNAL' | translate
              "
            >
              <mat-icon class="s-12">redo</mat-icon>
            </button>
            <mat-label>{{ "ORDERS.EXTERNAL_NAME_EN" | translate }}</mat-label>
            <input
              matInput
              fxFlex="100%"
              formControlName="name"
              type="text"
              [(ngModel)]="product.name_local.name_en_CA"
              required
            />
            <mat-error *ngIf="form.controls.name.invalid">
              {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div
          *ngIf="
            (modulesService.modulesAreSet && !modulesService.hasJLReport()) ||
            !product.show_charged_price
          "
          [formGroup]="priceForm"
        >
          <mat-form-field class="item-width">
            <mat-label>{{ "GENERIC.PRICE" | translate }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="unit_price"
              pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
              [(ngModel)]="product.unit_price"
            />
            <mat-error *ngIf="priceForm.controls.unit_price.invalid">
              {{ "GENERIC.INPUT_NUMBER" | translate }}
              {{ "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div
          *ngIf="
            product.show_charged_price &&
            modulesService.modulesAreSet &&
            modulesService.hasJLReport()
          "
          [formGroup]="chargedPriceForm"
        >
          <mat-form-field class="item-width">
            <mat-label>{{ "GENERIC.PRICE" | translate }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="unit_price"
              pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
              [(ngModel)]="product.charged_price"
            />
            <mat-error *ngIf="chargedPriceForm.controls.unit_price.invalid">
              {{ "GENERIC.INPUT_NUMBER" | translate }}
              {{ "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field class="item-width">
          <mat-label>{{ "GENERIC.QUANTITY" | translate }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="quantity"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="product.qty"
          />
          <mat-error *ngIf="form.controls.quantity.invalid">
            {{ "GENERIC.INPUT_NUMBER_ABOVE_ZERO" | translate }}
            {{ "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL" | translate }}
          </mat-error>
        </mat-form-field>

        <!-- product category -->
        <div class="item-width">
          <app-gt2-select
            [label]="'GENERIC.FOOD_DRINK_CATEGORY' | translate"
            [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
            [nullableUseNone]="false"
            [placeHolder]="'GENERIC.PRODUCT_CATEGORY_SELECT' | translate"
            [selectedModel]="productCategory"
            (changeEvent)="onProductCategoryChange($event)"
            [disabled]="!editable"
            [service]="productCategoryService"
            class=""
            fxFlex="100%"
          >
          </app-gt2-select>
        </div>

        <!-- Product Zones -->
        <div class="item-width">
          <mat-form-field class="" fxFlex="100%">
            <mat-label>{{ "ORDERS.PRODUCTION_ZONE" | translate }}</mat-label>
            <mat-select
              [(ngModel)]="productionZones"
              [formControl]="zones"
              multiple
              (selectionChange)="productZonesChange($event)"
            >
              <mat-option
                *ngFor="let zone of zonesList | keyvalue"
                [value]="zone.key"
                >{{ zone.value }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div
          class="charged-price"
          *ngIf="
            !isComboChild &&
            modulesService.modulesAreSet &&
            modulesService.hasJLReport()
          "
        >
          <!-- <div class="price-value">
                  Valeur: {{ (product.show_charged_price)?  '0.00$' : product.sub_total_item_price_label }}
              </div> -->
          <mat-checkbox
            [ngModel]="product.show_charged_price"
            [ngModelOptions]="{ standalone: true }"
            color="accent"
            labelPosition="before"
            style="margin-bottom: 12px"
            (ngModelChange)="onProductShowPrice($event)"
          >
            {{ "GENERIC.SHOW_CHARGED_PRICE" | translate }}
          </mat-checkbox>
          <div class="charged" *ngIf="!loading">
            Prix unitaire: ${{ product.charged_price_label }} | Valeur: ${{
              product.show_charged_price
                ? product.charged_price_total_label
                : product.unit_price
            }}
          </div>
          <div class="charged" *ngIf="loading">
            <mat-spinner [diameter]="18" class=""></mat-spinner>
          </div>
        </div>
      </div>

      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex="40"
        fxLayoutGap="20px"
      >
        <!-- product description FR -->
        <div class="item-width">
          <div class="title">
            {{ "ORDERS.PRODUCT_DESCRIPTION_FR" | translate }}
          </div>
          <p-editor
            #txtEditor
            formControlName="description_fr_CA"
            [(ngModel)]="product.description_local.description_fr_CA"
            [style]="{ height: '160px', width: '100%' }"
          >
            <div class="">
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
              </span>

              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>

              <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-indent" value="-1"></button>
                <button class="ql-indent" value="+1"></button>
              </span>
            </div>
          </p-editor>
          <mat-error
            *ngIf="form.controls.description_fr_CA.invalid"
            class="p-4 pr-8"
            fxLayout="row"
            fxLayoutAlign="end center"
          >
            {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
          </mat-error>
        </div>

        <!-- product description EN -->
        <div class="item-width">
          <div class="title">
            {{ "ORDERS.PRODUCT_DESCRIPTION_EN" | translate }}
          </div>
          <p-editor
            #txtEditor
            formControlName="description_en_CA"
            [(ngModel)]="product.description_local.description_en_CA"
            [style]="{ height: '160px', width: '100%' }"
          >
            <div class="">
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
              </span>

              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>

              <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-indent" value="-1"></button>
                <button class="ql-indent" value="+1"></button>
              </span>
            </div>
          </p-editor>
          <mat-error
            *ngIf="form.controls.description_en_CA.invalid"
            class="p-4 pr-8"
            fxLayout="row"
            fxLayoutAlign="end center"
          >
            {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
          </mat-error>
        </div>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex="40"
        fxLayoutGap="20px"
      >
        <!-- product note -->
        <div class="item-width">
          <div class="title">{{ "ORDERS.PRODUCTION_NOTE" | translate }}</div>
          <p-editor
            #txtEditor
            formControlName="prod_note"
            [(ngModel)]="product.prod_note"
            [style]="{ height: '160px', width: '100%' }"
          >
            <div class="">
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
              </span>

              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>

              <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-indent" value="-1"></button>
                <button class="ql-indent" value="+1"></button>
              </span>
            </div>
          </p-editor>
          <mat-error
            *ngIf="form.controls.prod_note.invalid"
            class="p-4 pr-8"
            fxLayout="row"
            fxLayoutAlign="end center"
          >
            {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
          </mat-error>
        </div>

        <!-- Product sale note sale_note -->
        <div class="item-width">
          <div class="title">{{ "ORDERS.SALE_NOTE" | translate }}</div>
          <p-editor
            #txtEditor
            formControlName="sale_note"
            [(ngModel)]="product.sale_note"
            [style]="{ height: '160px', width: '100%' }"
          >
            <div class="">
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
              </span>

              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>

              <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-indent" value="-1"></button>
                <button class="ql-indent" value="+1"></button>
              </span>
            </div>
          </p-editor>
          <mat-error
            *ngIf="form.controls.sale_note.invalid"
            class="p-4 pr-8"
            fxLayout="row"
            fxLayoutAlign="end center"
          >
            {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
          </mat-error>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    [formGroup]="form"
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      *ngIf="editable && !product.show_charged_price"
      color="accent"
      class="mr-16"
      type="submit"
      [disabled]="!form.valid || !priceForm.valid || loading || noteInvalid"
      (click)="onSaveProduct()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      *ngIf="editable && product.show_charged_price"
      color="accent"
      class="mr-16"
      type="submit"
      [disabled]="
        !form.valid ||
        (modulesService.modulesAreSet &&
          modulesService.hasJLReport() &&
          !chargedPriceForm.valid) ||
        loading ||
        noteInvalid
      "
      (click)="onSaveProduct()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      *ngIf="!editable"
      color="accent"
      class="mr-16"
      type="submit"
      (click)="onSaveProduct()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
