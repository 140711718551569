export const locale = {
  lang: "en-CA",
  data: {
    SECTORS: {
      SECTORS: "Sectors",
      SECTOR: "Sector",
      CHOOSE_SECTOR: "Choose a sector",
      NO_SECTORS: "No sectors created yet.",
    },
  },
};
