import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReportsComponent } from './reports.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.EN.REPORTS,
    component: ReportsComponent,
  },
  {
    path: AppRoutesName.FR.REPORTS,
    component: ReportsComponent,
  },
];

@NgModule({
  declarations: [ReportsComponent],
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgxPermissionsModule,
    MatMenuModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatButtonModule,
    TranslateModule,
    GenericAlertComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    RouterModule.forChild(routes),
  ],
  exports: [ReportsComponent],
})
export class ReportsModule {}
