export const locale = {
  lang: "fr-CA",
  data: {
    SELECT_PLACE: {
      TITLE: "Sélectionner une salle",
      SELECTED_ROOM: "Salle sélectionné",
      SELECT_ENTERPRISE_NO_ROOM: "Sélectionner l'enterprise sans choisir de salle",
    },
  },
};
