import { CanDeactivateGuard } from './../../gards/can-deactivate-guard.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CalendarModule as AngularCalendarModule,
  DateAdapter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PaymentDeadlineService } from '../../api/payment-deadline.service';
import { EditorModule } from 'primeng/editor';
import { FuseSidebarModule } from '../../../../@fuse/components/sidebar/sidebar.module';
import { FuseWidgetModule } from '../../../../@fuse/components/widget/widget.module';
import { CalendarService } from '../../../services/calendar.service';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { FileService } from '../../../services/file.service';
import { PrintService } from '../../../services/print.service';
import { ClientsService } from '../../api/clients.service';
import { CreateClientService } from '../../api/create-client.service';
import { CreateEnterpriseBranchService } from '../../api/create-enterprise-branch.service';
import { EnterprisesService } from '../../api/enterprises.service';
import { PersonnalTitlesService } from '../../api/personnal-titles.service';
import { AttachClientEnterpriseComponent } from '../../components/dialogs/attach-client-enterprise/attach-client-enterprise.component';
import { CreateClientEnterpriseBranchComponent } from '../../components/dialogs/create-client-enterprise-branch/create-client-enterprise-branch.component';
import { CreateClientEnterpriseComponent } from '../../components/dialogs/create-client-enterprise/create-client-enterprise.component';
import { CreateClientComponent } from '../../components/dialogs/create-client/create-client.component';
import { CreateEnterpriseBranchComponent } from '../../components/dialogs/create-enterprise-branch/create-enterprise-branch.component';
import { CreateEnterpriseForClientComponent } from '../../components/dialogs/create-enterprise-for-client/create-enterprise-for-client.component';
import { CreateEnterpriseComponent } from '../../components/dialogs/create-enterprise/create-enterprise.component';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { FuseCalendarEventFormDialogComponent } from '../calendar/event-form/event-form.component';
import { ClientComponent } from '../client/client.component';
import { EnterpriseComponent } from '../enterprise/enterprise.component';
import { RoomsModule } from '../rooms/rooms.module';
import { ClientsComponent } from './clients.component';
import { ClientReceiptComposeService } from '../../api/client-receipt-compose.service';
import { EnterpriseReceiptComposeService } from '../../api/enterprise-receipt-compose.service';
import { HistoricAccountComposeService } from '../../api/historic-account-client-compose.service';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableSizePipeModule } from '../../modules/table-size.module';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DisplayHtmlLinkPipeModule } from '../../modules/display-html-link.module';
import { CdkTableModule } from '@angular/cdk/table';
import { CommentSectionComponent } from '../../components/comment-section/comment-section.component';
import { AddressComponent } from '../../components/address/address.component';
import { CollectionTableComponent } from '../../components/collection-table/collection-table.component';
import { CreateCongressesButtonComponent } from '../../components/create-congresses-button/create-congresses-button.component';
import { CreateOrderButtonsComponent } from '../../components/create-order-buttons/create-order-buttons.component';
import { CreatedUpdatedAtComponent } from '../../components/created-updated-at/created-updated-at.component';
import { ClientAttachEnterpriseComponent } from '../../components/dialogs/client-attach-enterprise/client-attach-enterprise.component';
import { ClientAttachEnterpriseTablePaginatedComponent } from '../../components/dialogs/client-attach-enterprise-table-paginated/client-attach-enterprise-table-paginated.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { Gt2SelectComponent } from '../../components/gt2-select/gt2-select.component';
import { SectionHistoryPanelComponent } from '../../components/section-history-panel/section-history-panel.component';
import { TagsComponent } from '../../components/tags/tags.component';
import { TagsByModelComponent } from '../../components/tags-by-model/tags-by-model.component';
import { EnterpriseClientsComponent } from './enterprise-clients/enterprise-clients.component';
import { MailComposeDialogComponent } from '../compose/compose.component';
import { ContactComponent } from '../../components/contact/contact.component';
import { ContactService } from '../../components/contact/contact.service';
import { CrudDatableGenericNoLocalComponent } from '../crud/crud-datable-generic-no-local/crud-datable-generic-no-local.component';
import { CrudDatatableGenericComponent } from '../crud/crud-datatable-generic/crud-datatable-generic.component';
import { EnterpriseInvoiceComponent } from '../enterprise-invoice/enterprise-invoice.component';
import { MatRippleModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.EN.CLIENTS,
    component: ClientsComponent,
  },
  {
    path: AppRoutesName.EN.CLIENTS + '/:slug' + '/:id',
    component: ClientComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.EN.ENTERPRISES + '/:slug' + '/:id',
    component: EnterpriseComponent,
    canDeactivate: [CanDeactivateGuard],
    resolve: {
      chat: CalendarService,
    },
  },
  {
    path: AppRoutesName.FR.CLIENTS,
    component: ClientsComponent,
  },
  {
    path: AppRoutesName.FR.CLIENTS + '/:slug' + '/:id',
    component: ClientComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.FR.ENTERPRISES + '/:slug' + '/:id',
    component: EnterpriseComponent,
    canDeactivate: [CanDeactivateGuard],
    resolve: {
      chat: CalendarService,
    },
  },
];

@NgModule({
  imports: [
    DisplayHtmlLinkPipeModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    CreateClientEnterpriseComponent,
    MatPaginatorModule,
    MatProgressBarModule,
    TableSizePipeModule,
    MatRippleModule,
    MatSortModule,
    MatProgressSpinnerModule,
    CreateEnterpriseComponent,
    MatTableModule,
    MatIconModule,
    MatTooltipModule,
    MatTabsModule,
    MatInputModule,
    CdkTableModule,
    MatFormFieldModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    GenericAlertComponent,
    RouterModule.forChild(routes),
    AddressComponent,
    ContactComponent,
    CommentSectionComponent,
    CreatedUpdatedAtComponent,
    EditorModule,
    FuseWidgetModule,
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    RoomsModule,
    CollectionTableComponent,
    Gt2SelectComponent,
    TagsComponent,
    TagsByModelComponent,
    AttachClientEnterpriseComponent,
    FuseSidebarModule,
    ClientAttachEnterpriseComponent,
    MailComposeDialogComponent,
    SectionHistoryPanelComponent,
    CrudDatatableGenericComponent,
    CrudDatableGenericNoLocalComponent,
    EnterpriseClientsComponent,
    FuseCalendarEventFormDialogComponent,
    CreateClientEnterpriseBranchComponent,
    CreateOrderButtonsComponent,
    CreateEnterpriseForClientComponent,
    CreateEnterpriseBranchComponent,
    CreateClientComponent,
    EnterpriseInvoiceComponent,
    ClientComponent,
    CreateCongressesButtonComponent,
    ClientAttachEnterpriseTablePaginatedComponent,
  ],
  declarations: [ClientsComponent, EnterpriseComponent],
  exports: [EnterpriseComponent, ClientsComponent],
  providers: [
    ClientsService,
    EnterprisesService,
    PrintService,
    CalendarService,
    ContactService,
    FileService,
    CreateEnterpriseBranchService,
    CreateClientService,
    PersonnalTitlesService,
    DatatableHelperService,
    PaymentDeadlineService,
    ClientReceiptComposeService,
    EnterpriseReceiptComposeService,
    HistoricAccountComposeService,
  ],
})
export class ClientsModule {}
