<div *ngIf="shopPreference" fxLayout="column" class="shop-preference">
  <!-- ledger_account_delivery -->
  <div>
    <div *ngIf="!editMode" class="info-line">
      <div class="title">{{ "SHOPS.LEDGER_ACCOUNT_DELIVERY" | translate }}</div>
      <div
        *ngIf="shopPreference.ledger_account_delivery"
        class="info text-truncate"
      >
        {{ shopPreference.ledger_account_delivery.name_label }}
      </div>
      <div
        *ngIf="
          !shopPreference.ledger_account_delivery ||
          !shopPreference.ledger_account_delivery.name_label
        "
        class="info empty-value"
      ></div>
    </div>

    <app-gt2-select
      *ngIf="editMode"
      #ledgerAccountSelect
      [service]="ledgerAccountService"
      [placeHolder]="'SHOPS.LEDGER_ACCOUNT_DELIVERY' | translate"
      [label]="'SHOPS.LEDGER_ACCOUNT_DELIVERY' | translate"
      [labelKey]="'name_label'"
      [selectedModel]="shopPreference.ledger_account_delivery"
      (changeEvent)="onLedgerAccountChange($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      [nullableUseNone]="false"
      class=""
    ></app-gt2-select>
  </div>

  <!-- ledger_account_tips_no_tax -->
  <div>
    <div *ngIf="!editMode" class="info-line">
      <div class="title">
        {{ "SHOPS.LEDGER_ACCOUNT_TIPS_NO_TAX" | translate }}
      </div>
      <div
        *ngIf="shopPreference.ledger_account_tips_no_tax"
        class="info text-truncate"
      >
        {{ shopPreference.ledger_account_tips_no_tax.name_label }}
      </div>
      <div
        *ngIf="
          !shopPreference.ledger_account_tips_no_tax ||
          !shopPreference.ledger_account_tips_no_tax.name_label
        "
        class="info empty-value"
      ></div>
    </div>

    <app-gt2-select
      *ngIf="editMode"
      [service]="ledgerAccountService"
      [nullableUseNone]="false"
      [selectedModel]="shopPreference.ledger_account_tips_no_tax"
      [placeHolder]="'SHOPS.LEDGER_ACCOUNT_TIPS_NO_TAX' | translate"
      [label]="'Taxes'"
      [labelKey]="'name_label'"
      (changeEvent)="onLedgerTipNoTaxSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""
    ></app-gt2-select>
  </div>

  <!-- order_wrapping -->
  <div>
    <div *ngIf="!editMode" class="info-line">
      <div class="title">{{ "SHOPS.DEFAULT_WRAPPING_ORDER" | translate }}</div>
      <div *ngIf="shopPreference.order_wrapping" class="info text-truncate">
        {{ shopPreference.order_wrapping.name }}
      </div>
      <div
        *ngIf="
          !shopPreference.order_wrapping || !shopPreference.order_wrapping.name
        "
        class="info empty-value"
      ></div>
    </div>

    <app-gt2-select
      *ngIf="editMode"
      [service]="wrappingService"
      [nullableUseNone]="false"
      [selectedModel]="shopPreference.order_wrapping"
      [placeHolder]="'SHOPS.DEFAULT_WRAPPING_ORDER' | translate"
      [label]="'Wrapping'"
      (changeEvent)="onOrderWrappingSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""
    ></app-gt2-select>
  </div>

  <!-- delivery_request_wrapping -->
  <div *ngIf="!editMode" class="info-line">
    <div class="title">{{ "SHOPS.DEFAULT_WRAPPING_DELIVERY" | translate }}</div>
    <div
      *ngIf="shopPreference.delivery_request_wrapping"
      class="info text-truncate"
    >
      {{ shopPreference.delivery_request_wrapping.name }}
    </div>
    <div
      *ngIf="
        !shopPreference.delivery_request_wrapping ||
        !shopPreference.delivery_request_wrapping.name
      "
      class="info empty-value"
    ></div>
  </div>

  <app-gt2-select
    *ngIf="editMode"
    [service]="wrappingService"
    [nullableUseNone]="false"
    [selectedModel]="shopPreference.delivery_request_wrapping"
    [placeHolder]="'SHOPS.DEFAULT_WRAPPING_DELIVERY' | translate"
    [label]="'Wrapping'"
    (changeEvent)="onDeliveryWrappingSelected($event)"
    [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
    fxFlex="100%"
    class=""
  ></app-gt2-select>

  <!-- takeout_request_wrapping -->
  <div *ngIf="!editMode" class="info-line">
    <div class="title">{{ "SHOPS.DEFAULT_WRAPPING_TAKOUT" | translate }}</div>
    <div
      *ngIf="shopPreference.takeout_request_wrapping"
      class="info text-truncate"
    >
      {{ shopPreference.takeout_request_wrapping.name }}
    </div>
    <div
      *ngIf="
        !shopPreference.takeout_request_wrapping ||
        !shopPreference.takeout_request_wrapping.name
      "
      class="info empty-value"
    ></div>
  </div>

  <app-gt2-select
    *ngIf="editMode"
    [service]="wrappingService"
    [nullableUseNone]="false"
    [selectedModel]="shopPreference.takeout_request_wrapping"
    [placeHolder]="'SHOPS.DEFAULT_WRAPPING_TAKOUT' | translate"
    [label]="'Wrapping'"
    (changeEvent)="onTakeoutWrappingSelected($event)"
    [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
    fxFlex="100%"
    class=""
  ></app-gt2-select>

  <!-- delivery_tax -->
  <div>
    <div *ngIf="!editMode" class="info-line">
      <div class="title">{{ "SHOPS.DEFAULT_DELIVERY_TAX" | translate }}</div>
      <div *ngIf="shopPreference.delivery_tax" class="info text-truncate">
        {{ shopPreference.delivery_tax.name }}
      </div>
      <div
        *ngIf="
          !shopPreference.delivery_tax || !shopPreference.delivery_tax.name
        "
        class="info empty-value"
      ></div>
    </div>

    <app-gt2-select
      *ngIf="editMode"
      [service]="taxesService"
      [nullableUseNone]="false"
      [selectedModel]="shopPreference.delivery_tax"
      [placeHolder]="'SHOPS.DEFAULT_DELIVERY_TAX' | translate"
      [label]="'Taxes'"
      (changeEvent)="onDeliveryTaxSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""
    ></app-gt2-select>
  </div>
</div>
