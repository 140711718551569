import { NotesService } from './../../../../api/notes.service';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Editor, EditorModule } from 'primeng/editor';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-dashboard-note',
  templateUrl: './dashboard-note.component.html',
  styleUrls: ['./dashboard-note.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    EditorModule,
    MatInputModule,
    FlexLayoutModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    TranslateModule,
  ],
})
export class DashboardNoteComponent
  extends GT2PageAbstract
  implements OnInit, OnChanges
{
  @ViewChild('noteEditor') noteEditor?: Editor;
  @Input() date: any;
  @Input() dashboardJul?: string;
  note: string = '';
  noteControl: UntypedFormControl = new UntypedFormControl('');

  constructor(
    private logger: NGXLogger,
    private notesService: NotesService,
    public dialog: MatDialog,
  ) {
    super();
    //
    this.noteControl = new UntypedFormControl();
    this.noteControl.valueChanges.pipe(debounceTime(1000)).subscribe((res) => {
      /* //this.logger.info("noteControl is changing => " + JSON.stringify(res));
                //this.logger.info("noteControl is changing => " + this.date.date); */

      //
      const formatedDate = moment(this.date.date).format().substring(0, 10);
      //
      // TODO: Allow save of empty note, kind of a delete
      if (res && res != '') {
        this.updateNoteByDate(formatedDate, res);
      } else if (res == null) {
        this.updateNoteByDate(formatedDate, '');
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['date'];
    /* //this.logger.info(
            "DashboardNotes.ngOnChanges() : " +
                moment(chng.currentValue.date).format("YYYY-MM-DD")
        ); */

    this.getNoteByDate(chng.currentValue.date);
  }

  ngOnInit() {}

  public onNoteChange(event: any): void {
    ////this.logger.info(" onNoteChange note: => " + event.htmlValue);
    ////this.logger.info(
    //    " onNoteChange date: => " + JSON.stringify(this.date.date)
    // );
  }

  public getNoteByDate(date: string): void {
    const formatedDate = moment(date).format().substring(0, 10);
    this.notesService.getNoteByDate(formatedDate).subscribe(
      (response: any) => {
        // //this.logger.info(
        //     "getNoteByDate=>response : " + JSON.stringify(response)
        // );
        if (response != null) {
          this.note = response.data.note;
        } else {
          this.note = '';
        }
      },
      (error: any) => {
        this.logger.error(
          'DashboardNoteComponent.getNoteByDate() -> NotesService ERRORS: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public updateNoteByDate(date: string, note: string): void {
    this.notesService.updateNote(date, note).subscribe(
      (response: any) => {
        /* //this.logger.info(
                    "onNoteChange=>response : " + JSON.stringify(response)
                ); */
        //this.note = response.data.note;
      },
      (error: any) => {
        this.logger.error(
          'DashboardNoteComponent.onNoteChange() -> NotesService ERRORS: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }
}
