import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ApiRoutes } from "../../api/ApiRoutes";
import { Gt2ApiService } from "../../api/gt2-api.service";

@Injectable()
export class SectionHistoryService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getHistory(section: string, sectionUUID: string): any {
    // this.logger.info("SectionHistoryService.getHistory() -> section: " + section);
    return this.http.get<any>(this.api.createUrl(ApiRoutes.V1) + section + "/historics-all/" + sectionUUID);
  }
}
