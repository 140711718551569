import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { LedgerAccountService } from '../../../../api/ledger-account.service';
import { Settings } from '../../../../settings/settings';
import { ValidatorHelper } from '../../../../validators/ValidatorHelper';
import { GT2PageAbstract } from '../../../abstract/GT2PageAbstract';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-ledger-account-create-component',
  templateUrl: './ledger-account-create-component.component.html',
  styleUrls: ['./ledger-account-create-component.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
  ],
})
export class LedgerAccountCreateComponentComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  serviceURL?: string;
  createPlaceholder?: string;
  dialogTitle?: string;
  useEnglish: boolean = false;
  form: UntypedFormGroup;
  model: any;
  loading: boolean = false;
  newCategory_fr_CA!: string | any;
  newCategory_en_CA!: string | any;
  accountNumber!: string;

  constructor(
    public override dialogRef: MatDialogRef<LedgerAccountCreateComponentComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ledgerAccountService: LedgerAccountService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    if (data) {
      this.dialogTitle = data.dialogTitle;
      this.serviceURL = data.serviceURL;
      this.createPlaceholder = data.createPlaceholder;

      if (data.useEnglish !== null) {
        this.useEnglish = data.useEnglish;
      }
    }

    if (this.useEnglish) {
      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        account_number: [
          '',
          [ValidatorHelper.isAboveZero, ValidatorHelper.isInteger],
        ],
      });
    } else {
      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        account_number: [
          '',
          [ValidatorHelper.isAboveZero, ValidatorHelper.isInteger],
        ],
      });
    }

    // this.model = {
    //     name: null
    // };
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateNew() {
    // this.logger.info("GenericCreateComponent.onCreateNew()");

    // this.logger.info("GenericCreateComponent.onCreateNew() - this.newCategory_fr_CA: " + this.newCategory_fr_CA);
    // this.logger.info("GenericCreateComponent.onCreateNew() - this.newCategory_en_CA: " + this.newCategory_en_CA);
    // this.logger.info("GenericCreateComponent.onCreateNew() - this.serviceURL: " + this.serviceURL);
    this.ledgerAccountService
      .create(this.newCategory_fr_CA, this.accountNumber)
      .subscribe(
        (response: any) => {
          // this.logger.info("GenericCreateComponent.onCreateNew() -> SUCCESS:" + JSON.stringify(response));
          this.toastr.success(
            this.translate.instant('LEDGER_ACCOUNT.CREATE_SUCCESS'),
          );
          this.newCategory_fr_CA = null;
          this.newCategory_en_CA = null;
          this.dialogRef.close(response.data);
          // this.form.markAsUntouched();
          // this.form.markAsPristine();
          // this.reloadData();
        },
        (error: any) => {
          this.logger.error(
            'GenericCreateComponent.onCreateNew() -> ERROR' +
              JSON.stringify(error),
          );
          // this.toastr.error(this.translate.instant('LEDGER_ACCOUNT.CREATE_ERROR'));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('LEDGER_ACCOUNT.CREATE_ERROR'),
          );
        },
      );
  }
}
