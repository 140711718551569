import { StaffProviderService } from './staff-provider.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FuseWidgetModule } from './../../../../../../@fuse/components/widget/widget.module';
import { FuseSidebarModule } from '../../../../../../@fuse/components/sidebar/sidebar.module';
import { RouterModule } from '@angular/router';
import { Gt2PrimengModule } from '../../../../modules/gt2-primeng.module';
import { AssignmentsComponent } from './assignments.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignmentGroupComponent } from './assignment-group/assignment-group.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AssignementsService } from './assignements.service';
import { CreateGroupModalComponent } from './create-group-modal/create-group-modal.component';
import { EditJobAssignmentModalComponent } from './edit-job-assignment-modal/edit-job-assignment-modal.component';
import { JobExpensesModalComponent } from './job-expenses-modal/job-expenses-modal.component';
import { AppRoutesName } from '../../../../const/AppRoutesName';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from '@swimlane/ngx-charts';
import { NumericDirectiveModule } from '../../../../modules/numeric.module';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { CreatedUpdatedAtComponent } from '../../../../components/created-updated-at/created-updated-at.component';
import { Gt2SelectComponent } from '../../../../components/gt2-select/gt2-select.component';
import { RichTextLocaleComponent } from '../../../../components/rich-text-locale/rich-text-locale.component';
import { SectionHistoryPanelComponent } from '../../../../components/section-history-panel/section-history-panel.component';
import { JobExpensesItemComponent } from './job-expenses-item/job-expenses-item.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.EN.EVENT_ASSIGNMENT_ROUTE,
    component: AssignmentsComponent,
    // canDeactivate: [CanDeactivateGuard]
  },
  {
    path: AppRoutesName.EN.EVENT_ASSIGNMENT_ROUTE_GROUP,
    component: AssignmentsComponent,
    // canDeactivate: [CanDeactivateGuard]
  },
  {
    path: AppRoutesName.FR.EVENT_ASSIGNMENT_ROUTE,
    component: AssignmentsComponent,
    // canDeactivate: [CanDeactivateGuard]
  },
  {
    path: AppRoutesName.FR.EVENT_ASSIGNMENT_ROUTE_GROUP,
    component: AssignmentsComponent,
    // canDeactivate: [CanDeactivateGuard]
  },
];

@NgModule({
  declarations: [AssignmentsComponent],
  imports: [
    NumericDirectiveModule,
    JobExpensesModalComponent,
    CommonModule,
    EditJobAssignmentModalComponent,
    MatFormFieldModule,
    CreateGroupModalComponent,
    AssignmentGroupComponent,
    JobExpensesItemComponent,
    MatButtonModule,
    MatInputModule,
    TooltipModule,
    MatDatepickerModule,
    MatOptionModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    FormsModule,
    MatTooltipModule,
    MatSortModule,
    FlexLayoutModule,
    MatMenuModule,
    MatSelectModule,
    MatCardModule,
    ScrollingModule,
    NgxSpinnerModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatIconModule,
    TranslateModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    FuseSidebarModule,
    SectionHistoryPanelComponent,
    CreatedUpdatedAtComponent,
    Gt2SelectComponent,
    FuseWidgetModule,
    MatExpansionModule,
    NgxMaterialTimepickerModule,
    RichTextLocaleComponent,
  ],
  exports: [AssignmentsComponent],
  providers: [AssignementsService, StaffProviderService],
})
export class AssignmentsModule {}
