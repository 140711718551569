export const locale = {
  lang: "fr-CA",
  data: {
    NOT_SUPPORTED: {
      NOT_SUPPORTED: "Votre navigateur n’est pas supporté. ",
      MESSAGE: "Nous vous recommandons d’utiliser Google Chrome.",
      CONTACT_US: "Contactez nous",
      // 'SUB_MESSAGE': 'Just kidding, looks like we have an internal issue, please try again in couple minutes',
      SUB_MESSAGE: "Nous vous recommandons d’utiliser Google Chrome",
      REPORT_PROBLEM: "Report this problem",
      BACK_PAGE: "Retourner à la page précédente",
      BACK_HOME_PAGE: "Retourner au tableau de bord",
      GT2_SITE: "Visiter www.gestiontraiteur.com",
      SUBJECT: "Sujet",
      SUCCESS_MESSAGE: "Votre message a été envoyé avec succès!",
      CONTACT_MESSAGE: "Bien que nous avons été averti du problème automatiquement, nous apprécions toujours plus de détails sur l’erreur que vous avez reçu. Svp contactez nous.",
    },
  },
};
