<div style="max-height: 100%; overflow: auto">
  <h1 matDialogTitle>{{ "PRODUCTS.ADD_QUESTION_HINT" | translate }}</h1>

  <div
    fxLayout="column"
    fxLayoutAlign="center start"
    [formGroup]="form"
    (keyup.enter)="form.valid && !loading ? onCreateQuestion() : null"
    class="mt-8 create-question-width"
  >
    <mat-form-field class="create-question-width">
      <mat-label>{{ "PRODUCTS.ADD_QUESTION_NAME" | translate }}</mat-label>
      <input
        matInput
        fxFlex="100%"
        placeholder="{{ 'PRODUCTS.ADD_QUESTION_NAME' | translate }}"
        formControlName="name"
        type="text"
        autocomplete="nope"
        [(ngModel)]="question.name"
        required
      />
      <mat-error *ngIf="form.controls['name'].invalid">{{
        "GENERIC.INPUT_LENGTH_INVALID" | translate
      }}</mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    [formGroup]="form"
    (submit)="onCreateQuestion()"
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      color="accent"
      class="mr-16"
      type="submit"
      [disabled]="!form.valid || loading"
      (click)="onCreateQuestion()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
