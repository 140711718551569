export const locale = {
  lang: "en-CA",
  data: {
    REPORTS: {
      TITLE: "Reports",
      SALE_STATISTIC: "Sales statistics",
      PRODUCTS_SOLD_LIST: "Sold products list",
      PRODUCT_CATEGORY: "Product category",
      EVENT_TYPE: "Event type",
      SELLER: "Seller",
      DELIVERY_TYPE: "Delivery type",
      PRODUCT_VELOCITY: "Products sales velocity",
      ACCOUNTING: "Accounting",
      ACCOUNTING_UNTIL: "Accounting until",
      ACCOUNTING_TODAY: "Accounting today",
      SALE_BY_LEDGER_ACCOUNT: "Sales by ledger account",
      SALE_BY_LEDGER_ACCOUNT_JR: "BETA - Sales by ledger account",
      SALE_BY_LEDGER_ACCOUNT_UQAM: "Sales by ledger account - UQAM",
      SALE_BY_LEDGER_ACCOUNT_PER_DAY: "Sales by ledger account per day",
      SALE_BY_LEDGER_ACCOUNT_PER_DAY_HINT: "7 days maximum",
      SOLD_PRODUCTS_LIST_HINT: "31 days maximum",
      EMPLOYEE_ASSIGNMENTS_HINT: "31 days maximum",
      EMPLOYEE_ASSIGNMENTS_HINT2: "Select a 14 day period",
      AGE_OF_CLIENT_ACCOUNT: "Age of client accounts",
      AGE_OF_ENTERPRISE_ACCOUNT: "Age of enterprise accounts",
      AGE_ACCOUNTS_REPORT: "Age of accounts",
      CALCULATED_CLIENT_ENTERPRISE_BALANCE: "Client/Entreprise balance",
      INVOICES_LIST: "Invoices list",
      DEPOSIT_TRACKING: "Deposit tracking",
      RECEIPTS_JOURNAL: "Receipts journal",
      ACCOUNTING_SUMMARY_PAYMENTS: "Accounting summary payments",
      ACCOUNTING_SYSTEM_CONTACTS: "Accounting contacts",
      ACCOUNTING_SYSTEM_INVOICES: "Accounting invoices",
      ACCOUNTING_SYSTEM_LOGS: "Accounting entries",
      CASH_DEPOSIT_BEFORE_INVOICE_AT: "List of cashed deposits of unbilled events",
      DATE_OF: "Date of",
      EMPLOYEES: "Staff",
      EMPLOYEES_ASSIGNMENT: "Wages staff assignments",
      JR_PRODUCT_CATEGORY: "Product categories JR",
      SCHEDULE_PER_DATE: "Schedule assignments by date",
      PAYMENT_DATA_NETHRIS: "Payment data Nethris - JR",
      SUMMARIES_OF_EVENTS: "Summaries of events",
      PROVISIONAL: " (Provisional)",
      INTERNAL_PAYMENT: "Internal payment",
      AGE_ACCOUNTS_REPORT_BTF: "Age of accounts - Reconciled collection",
      REPORT_ADMIN_ACCOUNTING_DEPOSITE_TRACKING_OKA: "Follow-up of final payments for events - ADO",
      REPORT_LIST_DEPOSITS_COLLECTED_FROM_BILLED_EVENTS: "List of deposits collected from billed events",
    },
    REPORTS_HELP: {
      REPORT_AGE_ACCOUNTS: "This report includes corporates and individuals accounts",
      TITLE: "Reports",
      REPORT_ADMIN_SALE_STATS_BY_DELIVERY_TYPE:
        "Presents the net sum of items ordered by category on events confirmed, invoiced or paid. <br><br>Does not take into account adjustment invoices and credit notes.",
      REPORT_ADMIN_SALE_STATS_BY_ENTERPRISE_PRELIMINARY: "Shows the net sum of items ordered by company on unbilled and billed orders.",
      REPORT_ADMIN_SALE_STATS_BY_ENTERPRISE: "Shows the net sum of items ordered by enterprises on invoiced orders.",
    },
  },
};
