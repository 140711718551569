import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InviteWizardComponent } from './invite-wizard.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { MatSelectModule } from '@angular/material/select';

const routes = [
  {
    path: AppRoutesName.EN.INVITE_WIZARD,
    component: InviteWizardComponent,
  },
  {
    path: AppRoutesName.FR.INVITE_WIZARD,
    component: InviteWizardComponent,
  },
];

@NgModule({
  declarations: [InviteWizardComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    RouterModule.forChild(routes),
    TranslateModule,
    MatDividerModule,
  ],
  exports: [InviteWizardComponent],
})
export class InviteWizardModule {}
