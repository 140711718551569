import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { FuseUtils } from '../../../../@fuse/utils/index';
import { GT2FormPageAbstract } from '../abstract/GT2FormPageAbstract';
import { FaqService } from './faq.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  faqs: any;
  faqsFiltered: any;
  step: number;
  searchInput: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _faqService: FaqService,
    private logger: NGXLogger,
    private translationLoader: FuseTranslationLoaderService
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    // Set the defaults
    this.searchInput = new UntypedFormControl('');
    this.step = 0;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._faqService.onFaqsChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        // this.logger.info("FaqComponent.ngOnInit() -> SUCCESS: " + JSON.stringify(response));
        this.faqs = response.data;
        this.faqsFiltered = response.data;
      });

    this.searchInput.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((searchText: any) => {
        this.faqsFiltered = FuseUtils.filterArrayByString(
          this.faqs,
          searchText
        );
      });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  /**
   * Set step
   *
   * @param {number} index
   */
  setStep(index: number): void {
    this.step = index;
  }

  /**
   * Next step
   */
  nextStep(): void {
    this.step++;
  }

  /**
   * Previous step
   */
  prevStep(): void {
    this.step--;
  }
}
