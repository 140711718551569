<div class="rich-text">
  <!-- [ngStyle]="{'height':'240px', 'width' : '100%', 'background-color' : (editMode)? '#ffffff' : '#ff0000'}" -->

  <!-- [ngStyle]="{'width' : '100%', 'background-color' : (editMode)? '#ffffff' : '#ff0000'}" -->

  <!-- [ngStyle]="{'background-color' : (!editMode)? '#ffffff' : ''}" -->

  <p-editor
    #richTextEditor
    (onInit)="setFocus($event)"
    [(ngModel)]="richText[localKey]"
    [readonly]="!editMode"
    [style]="{
      width: '100%',
      'min-height': '80px',
      'background-color': editMode ? '#ffffff' : ''
    }"
  >
    <ng-template pTemplate="header" class="">
      <span class="ql-formats" [ngClass]="!editMode ? 'span-disabled' : ''">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>
      </span>

      <span class="ql-formats" [ngClass]="!editMode ? 'span-disabled' : ''">
        <select *ngIf="allowColors" class="ql-color"></select>
        <select *ngIf="allowColors" class="ql-background"></select>
      </span>

      <span class="ql-formats" [ngClass]="!editMode ? 'span-disabled' : ''">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <button class="ql-indent" value="-1"></button>
        <button class="ql-indent" value="+1"></button>
      </span>
    </ng-template>
  </p-editor>

  <div fxLayout="row" fxLayoutAlign="space-between start">
    <!-- <div class="mat-caption">{{ currentLength }} de {{ maxLength }} charactères</div> -->
    <div class="mat-caption">
      {{ charCountLocal | translate : { min: currentLength, max: maxLength } }}
    </div>

    <mat-error
      *ngIf="richTextInvalid"
      fxLayout="row"
      fxLayoutAlign="end center"
      class="p-4 pr-8"
    >
      {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_" + maxLength | translate }}
    </mat-error>

    <div>
      <button
        *ngIf="showClearButton"
        (click)="onClearRichText()"
        color="primary"
        class="save-btn mr-16"
        [disabled]="invalid"
        mat-raised-button
      >
        {{ "GENERIC.DELETE" | translate }}
      </button>

      <button
        *ngIf="showSaveButton"
        (click)="onSaveRichText()"
        color="accent"
        class="save-btn"
        [disabled]="invalid"
        mat-raised-button
      >
        {{ "GENERIC.SAVE" | translate }}
      </button>
    </div>
  </div>
</div>
