import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { CreateComboComponent } from '../../create-combo/create-combo.component';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProductsService } from '../../../api/products.service';
import { DropdownModule } from 'primeng/dropdown';
import {
  CdkDrag,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-drag-drop-lists',
  templateUrl: './drag-drop-lists.component.html',
  styleUrls: ['./drag-drop-lists.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    CdkDropListGroup,
    CdkDropList,
    CdkDrag,
    FusePipesModule,
    Gt2PrimengModule,
    DropdownModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    TranslateModule,
    MatProgressSpinnerModule,
  ],
  providers: [ProductsService],
})
export class DragDropListsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  service?: IDnDDataService;
  parent!: IDnDParent;
  draggableList!: IDnDItem[];
  droppableList!: IDnDItem[];
  isLoadingDraggables: boolean = false;
  isLoadingDroppables: boolean = false;
  @ViewChild('filter') filter?: ElementRef;
  filterValue: string = '';
  title: string = '';
  labelDraggable: string = '';
  labelDroppable: string = '';
  lastResponse: any;
  serviceType: string = '';
  productQuestionDropProcess: boolean = false;
  @Output() productQuestionDropProcessChange = new EventEmitter<any>();

  constructor(
    public override dialogRef: MatDialogRef<CreateComboComponent>, // WHY IS THIS THE WRONG DECLARATION?
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public dialog: MatDialog,
    public logger: NGXLogger,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    if (data.parent) {
      this.parent = data.parent;
    } else {
      // TODO: BIG WARNING!!
      //this.logger.info("DragDropListsComponent.constructor() -> WARNING: NO PARENTS!");
    }

    if (data.service) {
      this.service = data.service;
    } else {
      // TODO: BIG WARNING!!
      //this.logger.info("DragDropListsComponent.constructor() -> WARNING: NO SERVICE!");
    }

    if (data.title) {
      this.title = data.title;
    }

    if (data.labelDraggable) {
      this.labelDraggable = data.labelDraggable;
    }

    if (data.labelDroppable) {
      this.labelDroppable = data.labelDroppable;
    }

    if (data && data.serviceType) {
      this.serviceType = data.serviceType;
    }
  }

  ngOnInit() {
    //this.logger.info("DragDropListsComponent.ngOnInit() -> this.service: " + this.service);
    //this.logger.info("DragDropListsComponent.ngOnInit() -> this.parent: " + this.parent);
    if (!this.service || !this.parent) {
      return;
    }

    this.isLoadingDraggables = true;
    this.service.getDraggables(this.parent.uuid).subscribe({
      next: (response) => {
        // //this.logger.info(
        //     "DragDropListsComponent.ngOnInit() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        this.draggableList = response.data;
        this.isLoadingDraggables = false;
      },
      error: (error) => {
        this.logger.error(
          'DragDropListsComponent.ngOnInit() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoadingDraggables = false;
      },
    });

    this.isLoadingDroppables = true;
    this.service.getDroppables(this.parent.uuid).subscribe({
      next: (response) => {
        // //this.logger.info(
        //     "DragDropListsComponent.ngOnInit() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        this.droppableList = response.data;
        this.isLoadingDroppables = false;
      },
      error: (error) => {
        this.logger.error(
          'CreateMaterialComboComponent.ngOnInit() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoadingDroppables = false;
      },
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onAttachItem(event: any): void {
    // //this.logger.info("onAttachItem");
    // //this.logger.info(event);
    this.service?.attach(this.parent.uuid, event.value.uuid).subscribe({
      next: (response) => {
        //this.logger.info("DragDropListsComponent.onAttachItem() -> SUCCESS: " + JSON.stringify(response));
        this.lastResponse = response;
      },
      error: (error) => {
        this.logger.error(
          'CreateMaterialComboComponent.onAttachItem() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onDeattachedItem(event: any): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        this.draggableList,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    this.service?.deattach(this.parent.uuid, event.item.data.uuid).subscribe({
      next: (response) => {
        //this.logger.info("DragDropListsComponent.onDeattachedItem() -> SUCCESS: " + JSON.stringify(response));
        this.lastResponse = response;
      },
      error: (error) => {
        this.logger.error(
          'CreateMaterialComboComponent.onDeattachedItem() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
    //this.logger.info("onDeattachedItem");
    //this.logger.info(event);
    // this.service?.deattach(this.parent.uuid, event.value.uuid).subscribe({
    //   next: (response) => {
    //     //this.logger.info("DragDropListsComponent.onDeattachedItem() -> SUCCESS: " + JSON.stringify(response));
    //     this.lastResponse = response;
    //   },
    //   error: (error) => {
    //     this.logger.error(
    //       'CreateMaterialComboComponent.onDeattachedItem() -> ERROR: ' +
    //         JSON.stringify(error),
    //     );
    //     this.handleAPIError(error, this.dialog, null, null);
    //   },
    // });
  }

  public onRemoveItem(item: any, index: number) {
    // //this.logger.info(
    //     "DragDropListsComponent.onRemoveItem() -> index: " + index
    // );

    // do
    for (let i = 0; i < this.droppableList.length; ++i) {
      if (this.droppableList[i].uuid === item.uuid) {
        this.droppableList?.splice(i, 1);
        this.draggableList?.push(item);
      }
    }

    this.service?.deattach(this.parent.uuid, item.uuid).subscribe({
      next: (response) => {
        //this.logger.info("DragDropListsComponent.onRemoveItem() -> SUCCESS: " + JSON.stringify(response));
        this.lastResponse = response;
      },
      error: (error) => {
        this.logger.error(
          'CreateMaterialComboComponent.onRemoveItem() -> ERROR: ' +
            JSON.stringify(error),
        );
        // undo in case of error
        this.droppableList?.push(item);
        for (let i = 0; i < this.draggableList.length; ++i) {
          if (this.draggableList[i].uuid === item.uuid) {
            this.draggableList?.splice(i, 1);
          }
        }
      },
    });
  }

  public onDrop(event: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        this.droppableList,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.onAddItem(event.item.data);
    //this.logger.info("DragDropListsComponent.onDrop event => " + JSON.stringify(event));
    // if (this.serviceType === 'upsale') {
    //   if (event.value === undefined) {
    //     return;
    //   }

    //   if (event.source.className.indexOf('combo-list') !== -1) {
    //     this.onProductQuestionDrop(event);
    //     return;
    //   }
    // }
    // //
    // const items: any[] = this.droppableList.filter((item) => {
    //   return item.uuid === event.value.uuid;
    // });
    // if (items && items.length > 1) {
    //   // do nothing;
    // } else {
    //   this.onAddItem(event.value);
    // }
  }

  public onProductQuestionDrop(event: any) {
    if (this.droppableList.length < 2) {
      this.productQuestionDropProcess = false;
      this.spinner.hide('comboSpinner');
      return;
    }
    let i = 0;
    const length: number = this.droppableList.length;
    for (i; i < length; ++i) {
      if (this.droppableList[i].uuid === event.value.uuid) {
        const productUUID = event.value.uuid;
        //this.logger.info("productUUID: " + productUUID);
        // move before
        this.productQuestionDropProcess = true;
        this.spinner.show('comboSpinner');
        if (i === 0) {
          this.service
            ?.productQuestionMoveBefore(
              this.parent.uuid,
              productUUID,
              this.droppableList[i + 1].uuid,
            )
            .subscribe({
              next: (response: any) => {
                //this.logger.info("CreateComboComponent.onProductChildDrop() -> SUCCESS (productChildMoveBefore): " + JSON.stringify(response));
                this.productQuestionDropProcess = false;
                this.spinner.hide('comboSpinner');
                this.droppableList = response.data.upsale_questions;
              },
              error: (error: any) => {
                this.logger.error(
                  'CreateComboComponent.onProductChildDrop() -> ERROR (productChildMoveBefore): ' +
                    JSON.stringify(error),
                );
                this.productQuestionDropProcess = false;
                this.spinner.hide('comboSpinner');
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        } // move after
        else {
          this.service
            ?.productQuestionMoveAfter(
              this.parent.uuid,
              productUUID,
              this.droppableList[i - 1].uuid,
            )
            .subscribe({
              next: (response: any) => {
                //this.logger.info("CreateComboComponent.onProductChildDrop() -> SUCCESS (productChildMoveBefore): " + JSON.stringify(response));
                this.productQuestionDropProcess = false;
                this.spinner.hide('comboSpinner');
                this.droppableList = response.data.upsale_questions;
              },
              error: (error: any) => {
                this.logger.error(
                  'CreateComboComponent.onProductChildDrop() -> ERROR (productChildMoveBefore): ' +
                    JSON.stringify(error),
                );
                this.productQuestionDropProcess = false;
                this.spinner.hide('comboSpinner');
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
        break;
      }
    }
  }

  public onAddItem(item: any, doAddItemInModel: boolean = false) {
    // do
    for (let i = 0; i < this.draggableList.length; ++i) {
      if (this.draggableList[i].uuid === item.uuid) {
        this.draggableList?.splice(i, 1);
        this.droppableList?.push(item);
      }
    }

    this.service?.attach(this.parent.uuid, item.uuid).subscribe({
      next: (response) => {
        //this.logger.info("DragDropListsComponent.onAddItem() -> SUCCESS: " + JSON.stringify(response));
        this.lastResponse = response;
      },
      error: (error) => {
        this.logger.error(
          'DragDropListsComponent.onAddItem() -> ERROR: ' +
            JSON.stringify(error),
        );

        // undo in case of error
        this.draggableList?.push(item);
        for (let i = 0; i < this.droppableList.length; ++i) {
          if (this.droppableList[i].uuid === item.uuid) {
            this.droppableList?.splice(i, 1);
          }
        }
      },
    });
  }

  public clearSearch(): void {
    this.filterValue = '';
  }
}

export interface IDnDDataService {
  getDraggables(uuid: string): Observable<any>;
  getDroppables(uuid: string): Observable<any>;
  attach(parentUUID: string, itemUUID: string): Observable<any>;
  deattach(parentUUID: string, itemUUID: string): Observable<any>;
  productQuestionMoveBefore(
    productUuid: string,
    questionUuid: string,
    beforeUuid: string,
  ): any;
  productQuestionMoveAfter(
    productUuid: string,
    questionUuid: string,
    afterUuid: string,
  ): any;
}

export interface IDnDItem {
  name: string;
  uuid: string;
}

export interface IDnDParent {
  uuid: string;
}
