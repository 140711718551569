export const locale = {
  lang: 'en-CA',
  data: {
    CONTACT: {
      ORG_EMAIL: 'Professional email',
      MOBILE: 'Mobile',
      OFFICE_PHONE: 'Office phone',
      OFFICE_EXT: 'Ext',
      PHONE_OTHER: 'Phone other',
      FAX: 'Fax',
      SKYPE: 'Skype',
      NO_CONTACT_INFO: 'No contact informations.',
      EMAIL_CC: 'Email CC',
      PHONE: 'Phone',
      EMAIL: 'Email',
      INPUT_LENGTH_INVALID: 'Must be between 2 and 120 characters',
      INPUT_LENGTH_INVALID_20: 'Must be between 2 and 20 characters',
      EMAIL_INVALID: 'Must be a valid email',
      SAVE: 'Save',
    },
  },
};
