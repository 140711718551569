import { CanDeactivateGuard } from './../../gards/can-deactivate-guard.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { EditorModule } from 'primeng/editor';
import { FuseWidgetModule } from '../../../../@fuse/components/widget/widget.module';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { CreatePlaceComponent } from '../../components/dialogs/create-place/create-place.component';
import { CreateRoomComponent } from '../../components/dialogs/create-room/create-room.component';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { VenueComponent } from '../venue/venue.component';
import { VenueService } from '../venue/venue.service';
import { VenuesComponent } from './venues.component';
import { VenuesService } from './venues.service';
import { FuseSidebarModule } from '../../../../@fuse/components';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { TableSizePipeModule } from '../../modules/table-size.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { TooltipModule } from 'primeng/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CdkTableModule } from '@angular/cdk/table';
import { MatSortModule } from '@angular/material/sort';
import { CommentSectionComponent } from '../../components/comment-section/comment-section.component';
import { AddressComponent } from '../../components/address/address.component';
import { CreatedUpdatedAtComponent } from '../../components/created-updated-at/created-updated-at.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { ContactComponent } from '../../components/contact/contact.component';
import { ContactService } from '../../components/contact/contact.service';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.EN.VENUES,
    component: VenuesComponent,
  },
  {
    path: AppRoutesName.FR.VENUES,
    component: VenuesComponent,
  },
  {
    path: AppRoutesName.EN.VENUES + '/:slug' + '/:id',
    component: VenueComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.FR.VENUES + '/:slug' + '/:id',
    component: VenueComponent,
    canDeactivate: [CanDeactivateGuard],
  },
];

@NgModule({
  declarations: [VenuesComponent],
  imports: [
    CreatePlaceComponent,
    TableSizePipeModule,
    CreateRoomComponent,
    MatInputModule,
    MatOptionModule,
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatSelectModule,
    Gt2PrimengModule,
    FormsModule,
    MatSortModule,
    MatFormFieldModule,
    MatRippleModule,
    CdkTableModule,
    MatTableModule,
    TooltipModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatButtonModule,
    TranslateModule,
    RouterModule.forChild(routes),
    FuseWidgetModule,
    NgxChartsModule,
    MatPaginatorModule,
    MatIconModule,
    MatProgressBarModule,
    EditorModule,
    AddressComponent,
    ContactComponent,
    CommentSectionComponent,
    CreatedUpdatedAtComponent,
    FuseSidebarModule,
    GenericAlertComponent,
  ],
  exports: [VenuesComponent],
  providers: [
    VenuesService,
    ContactService,
    VenueService,
    DatatableHelperService,
  ],
})
export class VenuesModule {}
