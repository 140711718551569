import { Injectable } from "@angular/core";

import { NGXLogger } from "ngx-logger";
import { CatererModel } from "./caterer-model";
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Gt2ApiService } from "../../api/gt2-api.service";
import { ApiRoutes } from "../../api/ApiRoutes";

@Injectable()
export class CatererService {
  //
  static CATERER: CatererModel;

  //
  static COUNTRY_CODE = {
    CANADA: "ca",
  };

  // public
  onCatererChanged: BehaviorSubject<any>;

  constructor(private logger: NGXLogger, private http: HttpClient, private api: Gt2ApiService) {
    this.onCatererChanged = new BehaviorSubject(null);
  }

  public setCaterer(caterer: CatererModel): void {
    CatererService.CATERER = caterer;
    // this.logger.info("CatererService.setCaterer() -> CATERER: " + JSON.stringify(CatererService.CATERER));
    this.onCatererChanged.next(CatererService.CATERER);
  }

  public getCaterer(): CatererModel {
    return CatererService.CATERER;
  }

  public isCanada(): boolean {
    if (!CatererService.CATERER) {
      return false;
    }
    return CatererService.CATERER.country.code === CatererService.COUNTRY_CODE.CANADA;
  }
  public updateMaxUser(caterer: CatererModel) {
    // this.setCaterer(caterer);
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.UPDATE_CATERER) + caterer.uuid, {
      max_user: caterer.max_user,
    });
  }
}
