<div style="max-height: 100%; overflow: auto">
  <div matDialogTitle class="enterprise-cashing-dialog-title">
    {{ "CLIENTS.CASHING_TITLE" | translate }}
  </div>

  <div>
    <!-- <div matDialogTitle *ngIf="mode === 'CREATE'" class="event-dialog-title">
        {{ 'CREATE_CASHING.INVOICE_TITLE' | translate}}</div> -->

    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      [formGroup]="form"
      class="item-full-width px-8"
    >
      <!-- DATE -->
      <mat-form-field fxFlex="100%" class="item-full-width form-field">
        <mat-label>{{ "GENERIC.DATE" | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="receiveAtDate"
          [(ngModel)]="depositIn.received_at"
          min="1900-01-01"
          placeholder="{{ 'GENERIC.DATE' | translate }}"
          formControlName="received_at"
          required
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="receiveAtDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #receiveAtDate></mat-datepicker>
        <mat-error *ngIf="form.controls['received_at'].invalid"
          >{{ "GENERIC.DATE_TODAY_DOWN_RANGE_INVALID" | translate }}
        </mat-error>
      </mat-form-field>

      <!-- AMOUNT -->
      <mat-form-field fxFlex="100%" class="item-full-width form-field">
        <mat-label>{{ "GENERIC.AMOUNT" | translate }}</mat-label>
        <input
          matInput
          type="text"
          placeholder="{{ 'GENERIC.AMOUNT' | translate }}"
          formControlName="amount"
          autocomplete="nope"
          pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
          [(ngModel)]="depositIn.amount"
          (change)="onNewAmount($event)"
          required
        />
        <mat-error *ngIf="form.controls['amount'].invalid">
          {{ "GENERIC.INPUT_NUMBER_ABOVE_ZERO" | translate }}
        </mat-error>
      </mat-form-field>

      <!-- PAYMENT MODES -->
      <app-gt2-select
        name="select2"
        [service]="paymentModesService"
        [placeHolder]="'CLIENTS.PAYMENT_MODES' | translate"
        [label]="''"
        [isRequired]="true"
        [selectedModel]="depositIn.payment_mode"
        (changeEvent)="onPaymentModeChange($event)"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
        [nullableUseNone]="false"
        fxFlex="100%"
        class="item-full-width form-field"
        required
      ></app-gt2-select>

      <div [formGroup]="formRef" class="item-full-width">
        <mat-form-field class="item-full-width form-field" fxFlex="100%">
          <mat-label>{{ "CLIENTS.REFERENCE_NUMBER" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'CLIENTS.REFERENCE_NUMBER' | translate }}"
            formControlName="ref_number"
            autocomplete="nope"
            type="text"
            [(ngModel)]="depositIn.ref_number"
          />
          <mat-error *ngIf="formRef.controls['ref_number'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="invoices" fxLayout="column" class="item-full-width">
        <div class="selected-invoices">
          {{ "CLIENTS.SELECTED_INVOICES" | translate }}
        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex="40"># Facture</div>
          <div fxFlex="40">Montant</div>
          <div fxFlex="20">Encaissement</div>
        </div>
        <div
          *ngFor="let invoice of invoicesModel"
          class="invoice-item"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <app-enterprise-cashing-invoice
            fxFlex
            (onUpdate)="crunchTotal()"
            [invoice]="invoice"
          >
          </app-enterprise-cashing-invoice>
        </div>
        <div
          fxFlex
          fxLayout="row"
          fxLayoutAlign="start center"
          class="total-table-footer"
        >
          <div fxFlex="40">&nbsp;</div>
          <div fxFlex="40" fxLayout="row" fxLayoutAlign="end center">
            Total: &nbsp;$
          </div>
          <!-- <div fxFlex="20" style="">{{ totalEntered | number: '1.2-2'}}</div> -->
          <div fxFlex="20">{{ totalEnteredRounded | number: "1.2-2" }}</div>
        </div>
      </div>
    </div>

    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      class="item-full-width note-section mt-8"
    >
      <p-editor
        #noteEditor
        [(ngModel)]="depositIn.note"
        [style]="{
          height: '112px',
          width: '100%',
          'background-color': '#ffffff',
        }"
        class="item-full-width"
      >
        <ng-template pTemplate="header" class="">
          <!-- <span class="ql-formats">
            <select class="ql-font"></select>
            <select class="ql-size"></select>
          </span> -->

          <span class="mr-32" style="font-weight: 500; font-size: 16px"
            >Note</span
          >
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </ng-template>
      </p-editor>
      <mat-error
        *ngIf="noteInvalid"
        fxLayout="row"
        fxLayoutAlign="end center"
        class="p-4 pr-8"
      >
        {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
      </mat-error>
    </div>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <div>
      <button
        mat-raised-button
        color="accent"
        [disabled]="
          loading ||
          form.invalid ||
          !depositIn.amount ||
          depositIn.amount <= 0 ||
          !depositIn.received_at ||
          !depositIn.payment_mode
        "
        class="mr-16"
        (click)="onCreateCashings()"
      >
        {{ "GENERIC.SAVE" | translate }}
      </button>
    </div>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
