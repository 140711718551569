<button
  *ngxPermissionsOnly="rolesService.SALE_ROLES"
  mat-button
  (click)="onCreateNewCongress($event)"
  class=""
>
  <mat-icon class="s-24">{{ getIcon("CONGRESS") }}</mat-icon>
  {{ "GENERIC.CREATE_CONGRESS_TITLE" | translate }}
</button>

<button
  *ngxPermissionsOnly="rolesService.SALE_ROLES"
  mat-button
  (click)="onGoToCongresses()"
  class=""
>
  <mat-icon class="s-24">{{ getIcon("CONGRESS") }}</mat-icon>
  {{ "GENERIC.CONGRESSES" | translate }}
</button>
