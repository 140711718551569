export const locale = {
  lang: "en-CA",
  data: {
    ERROR_500: {
      CONTACT_US: "Contact us",
      MESSAGE: "Well, you broke the internet!",
      // 'SUB_MESSAGE': 'Just kidding, looks like we have an internal issue, please try again in couple minutes',
      SUB_MESSAGE: "There is a problem with our server: ",
      REPORT_PROBLEM: "Report this problem",
      BACK_PAGE: "Go back to the previous page",
      BACK_HOME_PAGE: "Go back to dashboard",
      GT2_SITE: "Go to www.gestiontraiteur.com",
      SUBJECT: "Subject",
      SUCCESS_MESSAGE: "Your message was sent successfully!",
      CONTACT_MESSAGE: "While we have been automatically informed, We always appreciate a more detailed feedback. Please contact us",
    },
  },
};
