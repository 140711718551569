export class LocalizationConfig {
  static FR_PREFIX: string = 'fr';
  static EN_PREFIX: string = 'en';
  static DEFAULT_LANGUAGE: string = 'fr-CA';
  static LOCAL_STORED_LANG_ID: string = 'local_lang';

  // TODO: Is this still used
  static SUPPORTED_LANGUAGES: string[] = ['en-US', 'en-CA', 'fr-FR', 'fr-CA'];
  static LANGUAGES: LanguageModel[] = [
    {
      id: 'en-CA',
      locale: 'en-CA',
      label: 'EN',
      title: 'English',
    },
    {
      id: 'fr-CA',
      locale: 'fr-CA',
      label: 'FR',
      title: 'Français',
    },
    // {
    //     'id'   : 'fr',
    //     'locale'   : 'fr-FR',
    //     'title': 'French',
    //     'flag' : 'fr',
    //     'currency' : 'CAD'
    // }
  ];

  static getCurrentLanguage(): LanguageModel {
    // TODO: temp
    // return LocalizationConfig.LANGUAGES[1];

    const localLang: string =
      localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID) || '';
    const nav: any = window.navigator;

    // TODO : UNCOMMENT THIS WHEN WE SUPPORT MULTI-LANGUAGE, AS OF NOW, WE RELEASE FOR FRENCH ONLY
    // const browserLang  = nav.languages[0]; // nav.language;
    // const browserLangPrefix  = LocalizationConfig.getLocalePrefix(browserLang);

    // const browserLangSupportedMatch: LanguageModel = LocalizationConfig.LANGUAGES.find( item =>
    // {
    //     return LocalizationConfig.getLocalePrefix(item.locale) === browserLangPrefix;
    // });

    // User preferred language in Local storage
    if (localLang && localLang !== '') {
      const lang = LocalizationConfig.LANGUAGES.find((item) => {
        return item.id === localLang;
      });

      if (lang) {
        return lang;
      }
    }

    // TODO : UNCOMMENT THIS WHEN WE SUPPORT MULTI-LANGUAGE, AS OF NOW, WE RELEASE FOR FRENCH ONLY
    // Browser Language
    // if ( browserLang && browserLangPrefix && browserLangSupportedMatch)
    // {
    //     return browserLangSupportedMatch;
    // }

    // Default languages
    const langDefault = LocalizationConfig.LANGUAGES.find((item) => {
      return item.id === LocalizationConfig.DEFAULT_LANGUAGE;
    });

    if (langDefault) {
      return langDefault;
    }

    // fail safe
    return LocalizationConfig.LANGUAGES[0];
  }

  static getCurrentLanguagePrefix(): string {
    return LocalizationConfig.getLocalePrefix(
      LocalizationConfig.getCurrentLanguage().locale
    );
  }

  static getLocalePrefix(locale: string): string {
    return locale.split('-')[0];
  }
}

export interface LanguageModel {
  id: string;
  locale: string;
  label: string;
  title: string;
  // flag: string;
  // currency: string;
}
