<div class="fuse-search-bar" [ngClass]="{ expanded: !collapsed }">
  <div class="fuse-search-bar-content">
    <label for="fuse-search-bar-input">
      <button
        mat-icon-button
        class="fuse-search-bar-expander"
        aria-label="Expand Search Bar"
        (click)="expand()"
        *ngIf="collapsed"
      >
        <mat-icon class="s-24" color="rgba(255,255,255,.7)">search</mat-icon>
      </button>
    </label>
    <button
      (click)="expand()"
      #searchButton
      style="
        height: 0px;
        width: 0px;
        padding: 0px;
        margin: 0px;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: -20px;
      "
    ></button>

    <div *ngIf="collapsed" style="width: 100%; height: 64px" (click)="expand()">
      &nbsp;
    </div>

    <input
      #searchInput
      id="fuse-search-bar-input"
      class="ml-24"
      type="text"
      placeholder="{{ 'GENERIC.SEARCH' | translate | titlecase }}"
      (keyup)="onKey($event)"
      (input)="onSearchInput($event)"
      autofocus
      fxFlex
    />

    <button
      mat-icon-button
      class="fuse-search-bar-collapser"
      (click)="onCallSearch(searchInput.value)"
    >
      <mat-icon
        style="margin-top: 18px"
        class="s-24"
        color="rgba(255,255,255,.7)"
        >search</mat-icon
      >
    </button>
    <button
      mat-icon-button
      class="fuse-search-bar-collapser"
      (click)="collapse()"
      aria-label="Collapse Search Bar"
    >
      <mat-icon
        style="margin-top: 18px"
        class="s-24"
        color="rgba(255,255,255,.7)"
        >close</mat-icon
      >
    </button>
  </div>
</div>

<!--Fuse 8.1.2 Code below-->

<!--<div class="fuse-search-bar" [ngClass]="{'expanded':!collapsed}">-->

<!--    <div class="fuse-search-bar-content">-->

<!--        <label for="fuse-search-bar-input">-->
<!--            <button mat-icon-button class="fuse-search-bar-expander" aria-label="Expand Search Bar" (click)="expand()"-->
<!--                    *ngIf="collapsed">-->
<!--                <mat-icon class="s-24 secondary-text">search</mat-icon>-->
<!--            </button>-->
<!--        </label>-->

<!--        <input id="fuse-search-bar-input" class="ml-24" type="text" placeholder="Search" (input)="search($event)"-->
<!--               fxFlex>-->

<!--        <button mat-icon-button class="fuse-search-bar-collapser" (click)="collapse()"-->
<!--                aria-label="Collapse Search Bar">-->
<!--            <mat-icon class="s-24 secondary-text">close</mat-icon>-->
<!--        </button>-->

<!--    </div>-->

<!--</div>-->
