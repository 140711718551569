import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ApiRoutes } from "../../api/ApiRoutes";
import { Gt2ApiService } from "../../api/gt2-api.service";

@Injectable()
export class SupportContactService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public postEmail(email: any) {
    // this.logger.info("SupportContactService.postEmail() -> email: " + JSON.stringify(email));
    return this.http.post<any>(this.api.createUrl(ApiRoutes.SUPPORT_CONTACT), email);
  }

  public postEmailError500(email: any) {
    // this.logger.info("SupportContactService.postEmail() -> email: " + JSON.stringify(email));
    return this.http.post<any>(this.api.createUrl(ApiRoutes.SUPPORT_CONTACT_500), email);
  }

  public postEmailError404(email: any) {
    // this.logger.info("SupportContactService.postEmail() -> email: " + JSON.stringify(email));
    return this.http.post<any>(this.api.createUrl(ApiRoutes.SUPPORT_CONTACT_404), email);
  }

  public postEmailError429(email: any) {
    // this.logger.info("SupportContactService.postEmail() -> email: " + JSON.stringify(email));
    return this.http.post<any>(this.api.createUrl(ApiRoutes.SUPPORT_CONTACT_429), email);
  }

  public postEmailError503(email: any) {
    // this.logger.info("SupportContactService.postEmail() -> email: " + JSON.stringify(email));
    return this.http.post<any>(this.api.createUrl(ApiRoutes.SUPPORT_CONTACT_503), email);
  }
}
