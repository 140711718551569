import { ApiRoutes } from '../../../../api/ApiRoutes';
import { Gt2ApiService } from './../../../../api/gt2-api.service';
import { NGXLogger } from 'ngx-logger';
import { HttpClient } from '@angular/common/http';
import { IGetDataService } from './../../../../components/gt2-select/gt2-select.component';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StaffProviderService implements IGetDataService {
  staffProviderObs?: Observable<any>;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getStaffProviders(): any {
    this.staffProviderObs = this.http
      .get<any>(this.api.createUrl(ApiRoutes.STAFF_PROVIDERS_LIST))
      .pipe(shareReplay(1));

    return this.staffProviderObs;
  }

  public getData(): Observable<any> {
    // this.logger.info("DateFormatService.getData()");
    return this.getStaffProviders();
  }
}
