export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_CLIENT_ENTERPRISE_BRANCH: {
      TITLE: "Nouveau client, entreprise et succursale",
      CLIENT_FIRST_NAME: "Prénom client",
      CLIENT_LAST_NAME: "Nom de famille client",
      ENTERPRISE_NAME: "Nom de l'entreprise",
      BRANCH_NAME: "Nom de la succursale",
      CREATE_SUCCESS: "Client, entreprise et succursale créé avec succès",
    },
  },
};
