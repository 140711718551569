import { FuseSidebarModule } from './../../../../@fuse/components/sidebar/sidebar.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgModule } from '@angular/core';
import { ShopsComponent } from './shops.component';
import { RouterModule } from '@angular/router';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { ShopsService } from '../../api/shops.service';
import { CanDeactivateGuard } from '../../gards/can-deactivate-guard.service';
import { CreateShopComponent } from '../../components/dialogs/create-shop/create-shop.component';
import { ShopTypeService } from '../../api/shop-type.service';
import { ShopProductTypeService } from './../../api/shop-product-type.service';
import { ShopWoocommerceComponent } from './shop-woocommerce/shop-woocommerce.component';
import { InternalShopComponent } from './internal-shop/internal-shop.component';
import { ShopRteScheduleEditComponent } from './shop-rte-schedule-edit/shop-rte-schedule-edit.component';
import { ShopRteScheduleEditDataTableComponent } from './shop-rte-schedule-edit-data-table/shop-rte-schedule-edit-data-table.component';
import { ShopWebDetailedComponent } from './shop-web-detailed/shop-web-detailed.component';
import { GeneralConfigurationsComponent } from './shop-web-detailed/general-configurations/general-configurations.component';
import { PoinstTakeoutComponent } from './shop-web-detailed/poinst-takeout/poinst-takeout.component';
import { MonerisSettingsComponent } from './shop-web-detailed/moneris-settings/moneris-settings.component';
import { ThemesSettingsComponent } from './shop-web-detailed/themes-settings/themes-settings.component';
import { DeliveryRulesComponent } from './shop-web-detailed/delivery-rules/delivery-rules.component';
import { TipsSettingsComponent } from './shop-web-detailed/tips-settings/tips-settings.component';
import { NotificationsSettingsComponent } from './shop-web-detailed/notifications-settings/notifications-settings.component';
import { MessagesSettingsComponent } from './shop-web-detailed/messages-settings/messages-settings.component';
import { StripeSettingsComponent } from './shop-web-detailed/stripe-settings/stripe-settings.component';
import { ShopRteScheduleEditUnifiedComponent } from './shop-rte-schedule-edit-unified/shop-rte-schedule-edit-unified.component';
import { ShopRteScheduleEditDataTableUnifiedComponent } from './shop-rte-schedule-edit-data-table-unified/shop-rte-schedule-edit-data-table-unified.component';
import { GlobalPaymentsSettingsComponent } from './shop-web-detailed/global-payments-settings/global-payments-settings.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TableSizePipeModule } from '../../modules/table-size.module';
import { MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DisplayHtmlLinkPipeModule } from '../../modules/display-html-link.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CdkTableModule } from '@angular/cdk/table';
import { NumericDirectiveModule } from '../../modules/numeric.module';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { SliderModule } from 'primeng/slider';
import { ContextMenuModule } from 'primeng/contextmenu';
import { AddressComponent } from '../../components/address/address.component';
import { BusinessDaysComponent } from '../../components/business-days/business-days.component';
import { Gt2SelectComponent } from '../../components/gt2-select/gt2-select.component';
import { HoursGridComponent } from '../../components/hours-grid/hours-grid.component';
import { RichTextLocaleComponent } from '../../components/rich-text-locale/rich-text-locale.component';
import { RteScheduleComponent } from '../../components/rte-schedule/rte-schedule.component';
import { SectionHistoryPanelComponent } from '../../components/section-history-panel/section-history-panel.component';
import { ShopPreferenceComponent } from '../../components/shop-preference/shop-preference.component';
import { MessageImageSettingsComponent } from './shop-web-detailed/message-image-settings/message-image-settings.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AddressService } from '../../components/address/address.service';

const routes = [
  {
    path: AppRoutesName.FR.SHOPS,
    component: ShopsComponent,
  },
  {
    path: AppRoutesName.FR.SHOPS + '/shop-web/:slug/:id',
    component: ShopWebDetailedComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.FR.SHOPS + '/shop-web-detailed/:slug/:id',
    component: ShopWebDetailedComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.FR.SHOPS + '/shop-web-detailed/:slug/:id/:section',
    component: ShopWebDetailedComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path:
      AppRoutesName.FR.SHOPS +
      '/shop-web/:slug/:id/rte-schedule/:scheduleType/:scheduleUUID',
    component: ShopRteScheduleEditComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path:
      AppRoutesName.FR.SHOPS +
      '/shop-web/:slug/:id/rte-schedule-unified/:scheduleUUID',
    component: ShopRteScheduleEditUnifiedComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.FR.SHOPS + '/shop-woocommerce/:slug/:id',
    component: ShopWoocommerceComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.FR.SHOPS + '/shop-internal/:slug/:id',
    component: InternalShopComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.EN.SHOPS,
    component: ShopsComponent,
  },
  {
    path: AppRoutesName.EN.SHOPS + '/shop-web/:slug/:id',
    component: ShopWebDetailedComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.EN.SHOPS + '/shop-web-detailed/:slug/:id',
    component: ShopWebDetailedComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.EN.SHOPS + '/shop-web-detailed/:slug/:id/:section',
    component: ShopWebDetailedComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path:
      AppRoutesName.EN.SHOPS +
      '/shop-web/:slug/:id/rte-schedule/:scheduleType/:scheduleUUID',
    component: ShopRteScheduleEditComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path:
      AppRoutesName.EN.SHOPS +
      '/shop-web/:slug/:id/rte-schedule-unified/:scheduleUUID',
    component: ShopRteScheduleEditUnifiedComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.EN.SHOPS + '/shop-woocommerce/:slug/:id',
    component: ShopWoocommerceComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.EN.SHOPS + '/shop-internal/:slug/:id',
    component: InternalShopComponent,
    canDeactivate: [CanDeactivateGuard],
  },
];

@NgModule({
  declarations: [
    ShopsComponent,
    ShopWoocommerceComponent,
    InternalShopComponent,
    ShopRteScheduleEditComponent,
    ShopRteScheduleEditUnifiedComponent,
    ShopWebDetailedComponent,
  ],
  imports: [
    GeneralConfigurationsComponent,
    PoinstTakeoutComponent,
    TipsSettingsComponent,
    ThemesSettingsComponent,
    StripeSettingsComponent,
    NgxPermissionsModule,
    NotificationsSettingsComponent,
    MatRippleModule,
    DisplayHtmlLinkPipeModule,
    ShopRteScheduleEditDataTableComponent,
    ShopRteScheduleEditDataTableUnifiedComponent,
    MonerisSettingsComponent,
    Gt2SelectComponent,
    MessagesSettingsComponent,
    GlobalPaymentsSettingsComponent,
    DeliveryRulesComponent,
    MatButtonModule,
    MatSortModule,
    CdkTreeModule,
    CommonModule,
    CreateShopComponent,
    NumericDirectiveModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatDatepickerModule,
    CdkTableModule,
    FormsModule,
    MatSlideToggleModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatRadioModule,
    MessageImageSettingsComponent,
    RichTextLocaleComponent,
    HoursGridComponent,
    BusinessDaysComponent,
    ShopPreferenceComponent,
    SectionHistoryPanelComponent,
    RteScheduleComponent,
    MatIconModule,
    MatFormField,
    MatInputModule,
    TableSizePipeModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    InputTextModule,
    InputMaskModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule,
    TabViewModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    SliderModule,
    ContextMenuModule,
    RouterModule.forChild(routes),
    AddressComponent,
    NgxMaterialTimepickerModule,
    FuseSidebarModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule,
    TranslateModule,
  ],
  exports: [ShopsComponent],
  providers: [
    DatatableHelperService,
    ShopsService,
    ShopTypeService,
    AddressService,
    ShopProductTypeService,
  ],
})
export class ShopsModule {}
