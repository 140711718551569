<div #enterpriseClients>
  <div class="pl-8 pr-8" fxLayout="row" fxLayoutAlign="end center">
    <!-- SEARCH -->
    <div class="" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <label for="clientsSearch" class="mr-8">
        <mat-icon class="secondary-text">search</mat-icon>
      </label>
      <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
        <mat-label>{{
          "GENERIC.FILTERING_PLACEHOLDER" | translate | titlecase
        }}</mat-label>
        <input
          id="clientsSearch"
          [(ngModel)]="clientsFilterValue"
          matInput
          #clientsFilter
          placeholder="{{
            'GENERIC.FILTERING_PLACEHOLDER' | translate | titlecase
          }}"
        />
        <button
          mat-button
          *ngIf="clientsFilterValue"
          matSuffix
          mat-icon-button
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <!-- / SEARCH -->
  </div>

  <div class="empty" fxLayout="column">
    <div
      *ngIf="
        !isLoadingResults && dataSource.data && dataSource.data.length === 0
      "
      fxLayout="row"
      fxLayoutAlign="center center"
      class="my-16 font-size-16 font-weight-500"
    >
      {{ "GENERIC.NO_RESULTS" | translate }}
    </div>

    <div>
      <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        name="enterpriseClientsSpinner"
        size="medium"
        color="#90ee90"
        [fullScreen]="false"
        type="ball-scale-multiple"
      >
      </ngx-spinner>

      <!-- <div *ngIf="isLoadingResults"
            fxLayout="row"
            fxLayoutAlign="center center" class="table-spinner">
            <mat-spinner [diameter]="26" class="my-12"></mat-spinner>
        </div> -->

      <mat-table
        #table
        class=""
        matSort
        matSortStart="desc"
        [ngStyle]="{
          display:
            dataSource.data && dataSource.data.length === 0 ? 'none' : '',
        }"
        [@animateStagger]="{ value: '50' }"
        [dataSource]="dataSource"
      >
        <!-- first_name Column -->
        <ng-container cdkColumnDef="first_name">
          <mat-header-cell fxFlex="40" *cdkHeaderCellDef mat-sort-header
            >{{ "GENERIC.FIRST_NAME" | translate }}
          </mat-header-cell>
          <mat-cell fxFlex="40" *cdkCellDef="let row">
            <div>
              {{ row.first_name }}
            </div>
          </mat-cell>
        </ng-container>

        <!-- last_name Column -->
        <ng-container cdkColumnDef="last_name">
          <mat-header-cell fxFlex="40" *cdkHeaderCellDef mat-sort-header
            >{{ "GENERIC.LAST_NAME" | translate }}
          </mat-header-cell>
          <mat-cell fxFlex="40" *cdkCellDef="let row">
            <div>
              {{ row.last_name }}
            </div>
          </mat-cell>
        </ng-container>

        <!-- client_number -->
        <ng-container cdkColumnDef="client_number">
          <mat-header-cell fxFlex *cdkHeaderCellDef mat-sort-header
            >{{ "CLIENTS.CLIENT_NUMBER" | translate }}
          </mat-header-cell>
          <mat-cell fxFlex *cdkCellDef="let row">
            <div>
              {{ row.client_number }}
            </div>
          </mat-cell>
        </ng-container>

        <!-- actions -->
        <ng-container cdkColumnDef="actions">
          <mat-header-cell
            fxFlex="42px"
            *cdkHeaderCellDef
            mat-sort-header
          ></mat-header-cell>
          <mat-cell fxFlex="42px" *cdkCellDef="let client">
            <button
              mat-button
              matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
              (click)="$event.stopPropagation(); onOpenItemInNewTab(client)"
              class="mat-icon-button"
            >
              <mat-icon class="">{{ getIcon("NEW_TAB") }}</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *cdkRowDef="let row; columns: displayedColumns"
          (click)="onNavigateToItem(row)"
          style="cursor: pointer"
          matRipple
          [@animate]="{ value: '*', params: { y: '100%' } }"
        >
        </mat-row>
      </mat-table>
    </div>
    <mat-paginator
      #paginator
      [length]="resultsLength"
      (page)="datatableHelperService.onPaginator($event, 'enterpriseClients')"
      [ngStyle]="{
        display: dataSource.data && dataSource.data.length === 0 ? 'none' : '',
      }"
      showFirstLastButtons="true"
      [pageIndex]="0"
      [pageSize]="'enterpriseClients' | tableSizePipe: 'medium'"
      [pageSizeOptions]="datatableHelperService.pageSizeMediumOptions"
    >
    </mat-paginator>
  </div>
</div>
