export const locale = {
  lang: "fr-CA",
  data: {
    ORDER_PRODUCTS: {
      TITLE: "Ajouter un produit",
      TITLE_CREATE: "Ajouter un produit",
      LEFT_LIST_LABEL: "Glisser et déposer ou utiliser le bouton + pour ajouter des produits",
      RIGHT_LIST_LABEL: "Liste des produits à ajouter",
      CREATE_PRODUCT: "Créer un produit",
      SEARCH: "recherche par nom interne, nom externe ou code de produit",
      SEARCH_PRODUCT: "Effectuez une recherche de votre produit.",
    },
  },
};
