export const locale = {
  lang: "en-CA",
  data: {
    QUICK_PANEL: {
      NOTIFICATIONS: "Turn notifications on",
      WELCOME_MESSAGE: "Welcome to the FUSE!",
      WELCOME_LONG_MESSAGE:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat,\n" +
        "            vel convallis elit fermentum pellentesque. Sed mollis velit facilisis facilisis viverra.",
    },
  },
};
