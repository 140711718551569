import { MaterialCategoryService } from './../../../../api/material-category.service';
import { ProductCategoryService } from './../../../../api/product-category.service';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GT2PageAbstract } from '../../../abstract/GT2PageAbstract';
import { NGXLogger } from 'ngx-logger';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ValidatorHelper } from '../../../../validators/ValidatorHelper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { WrappingService } from '../../../../api/wrapping.service';
import { SpecificConditionService } from '../../../../api/specific-condision.service';
import { ProposalComposeService } from '../../../../api/proposal-compose.service';
import { ProposalComposeCTService } from '../../../../api/proposal-compose-ct.service';
import { OrderComposeService } from '../../../../api/order-compose.service';
import { InvoiceComposeService } from '../../../../api/invoice-compose.service';
import { InvoiceComposeCTService } from '../../../../api/invoice-compose-ct.service';
import { InvoiceComposeGlobalPaymentsService } from '../../../../api/invoice-compose-global-payments.service';
import { CatererService } from '../../../caterer/caterer.service';
import { ConseillersService } from '../../../../api/conseillers.service';
import { ReportsService } from '../../../../reports/reports.service';
import { ReportFactoryService } from '../../../../reports/report-factory.service';
import { MenuReportsService } from '../../../../reports/menu-reports.service';
import { ReceiptNoticeComposeService } from '../../../../api/receipt-notice-compose.service';
import { ReceiptNoticeSellOnlyComposeService } from '../../../../api/receipt-notice-sell-only-compose.service';
import { StorageNoticeComposeService } from '../../../../api/storage-notice-compose.service';
import { Gt2SelectComponent } from '../../../../components/gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-group-action-single-input',
  templateUrl: './group-action-single-input.component.html',
  styleUrls: ['./group-action-single-input.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    FlexLayoutModule,
    Gt2SelectComponent,
    CommonModule,
    ReactiveFormsModule,
  ],
  providers: [
    WrappingService,
    SpecificConditionService,
    ProposalComposeService,
    ProposalComposeCTService,
    OrderComposeService,
    InvoiceComposeService,
    InvoiceComposeCTService,
    InvoiceComposeGlobalPaymentsService,
    CatererService,
    ConseillersService,
    ReportsService,
    ReportFactoryService,
    MenuReportsService,
    ReceiptNoticeComposeService,
    ReceiptNoticeSellOnlyComposeService,
    StorageNoticeComposeService,
  ],
})
export class GroupActionSingleInputComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static TYPE_PRICE: string = 'PRICE';
  static TYPE_QTY: string = 'QTY';
  static PRODUCT_CATEGORY: string = 'PRODUCT_CATEGORY';
  static MATERIAL_CATEGORY: string = 'MATERIAL_CATEGORY';

  title: string = '';
  actionValue: any;
  form: UntypedFormGroup | any;
  type: string | any;

  constructor(
    public override dialogRef: MatDialogRef<GroupActionSingleInputComponent>,
    private logger: NGXLogger,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public productCategoryService: ProductCategoryService,
    public materialCategoryService: MaterialCategoryService,
  ) {
    super();
    //this.logger.info("GroupActionSingleInputComponent.constructor() -> data: " + JSON.stringify(data));

    if (data && data.title !== null) {
      this.title = data.title;
    }

    if (data && data.type !== null) {
      this.type = data.type;
    }

    if (this.type === GroupActionSingleInputComponent.TYPE_PRICE) {
      this.form = this.formBuilder.group({
        inputControl: [
          '',
          [ValidatorHelper.isValidPriceNumber, Validators.required],
        ],
      });
    } else if (this.type === GroupActionSingleInputComponent.TYPE_QTY) {
      this.form = this.formBuilder.group({
        inputControl: [
          '',
          [ValidatorHelper.isNumber0To1000000, Validators.required],
        ],
      });
    }

    // this.form = this.formBuilder.group({
    //     unit_price: ['', [ValidatorHelper.isValidPriceNumber, Validators.required]],
    // });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onCategoryChange(event: any): void {
    //this.logger.info("GroupActionSingleInputComponent.onProductCategoryChange() -> category: " + JSON.stringify(event));
    this.actionValue = event;
  }
}
