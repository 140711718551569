import { Subject } from "rxjs";
import { NGXLogger } from "ngx-logger";

export class ApiBenching {
  static MAX_CALLS: number = 10;
  static callStack: APICallBenching[] = [];
  static callStackChanged: Subject<APICallBenching[]> = new Subject();

  constructor(private logger: NGXLogger) {}

  static addCall(call: APICallBenching): void {
    ApiBenching.callStack.unshift(call);

    // this.logger.info("ApiBenching.addCall() - 1. " + ApiBenching.callStack.length);
    if (ApiBenching.callStack.length > ApiBenching.MAX_CALLS) {
      ApiBenching.callStack.splice(-1, 1);
    }
    // this.logger.info("ApiBenching.addCall() - 2. " + ApiBenching.callStack.length);
    ApiBenching.callStackChanged.next(ApiBenching.callStack);
  }

  static clearStack(): void {
    ApiBenching.callStack = [];
    ApiBenching.callStackChanged.next(ApiBenching.callStack);
  }
}

export interface APICallBenching {
  url: string;
  startTime: number;
  endTime: number;
  seconds: number;
  method: string;
}
