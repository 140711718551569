import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'knowledge-base-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  standalone: true,
  imports: [MatToolbarModule, FlexLayoutModule, MatButtonModule],
  encapsulation: ViewEncapsulation.None,
})
export class KnowledgeBaseArticleComponent {
  /**
   * Constructor
   *
   * @param {MatDialogRef<KnowledgeBaseArticleComponent>} matDialogRef
   * @param _data
   */
  constructor(
    public matDialogRef: MatDialogRef<KnowledgeBaseArticleComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) {}
}
