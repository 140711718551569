export const locale = {
  lang: "fr-CA",
  data: {
    LEDGER_ACCOUNT: {
      CREATE_NEW_CATEGORY: "Nouveau compte de grand livre",
      CATEGORY_NAME: "Compte de grand livre",
      NEW_CATEGORY_NAME: "Nom du nouveau compte de grand livre",
      CREATE_SUCCESS: "Compte de grand livre créé avec succès",
      CREATE_ERROR: "Une erreur est survenue lors de la création",
      EDIT_SUCCESS: "Compte de grand livre modifiée avec succès!",
      DELETE_SUCCESS: "Compte de grand livre effacée avec succès!",
      ACCOUNT_NUMBER: "Numéro de compte",
    },
  },
};
