export const locale = {
  lang: "fr-CA",
  data: {
    SELECT_PLACE: {
      TITLE: "Sélectionner une salle",
      SELECTED_ROOM: "Salle sélectionné",
      NO_RESULT: "Aucun résultats trouvés",
    },
  },
};
