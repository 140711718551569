<div
  id="support"
  class="page-layout simple fullwidth inner-scroll"
  fxLayout="column"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent p-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1">{{ "CONTACT.CONTACT_TICKET" | translate }}</div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <div class="p-24" fusePerfectScrollbar>
    <div class="content">
      <a
        href="https://gestiontraiteur.com/demande-de-ticket/"
        target="_blank"
        style="font-size: 19px"
        >{{ "CONTACT.CONTACT_TICKET_LINK" | translate }}</a
      >
    </div>
  </div>
</div>
