import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { locale as englishAddress } from '../../address/i18n/en-CA';
import { locale as frenchAddress } from '../../address/i18n/fr-CA';
import { VenuesService } from '../../../content/venues/venues.service';
import { Settings } from '../../../settings/settings';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import {
  SectorModel,
  SectorsService,
} from '../../sectors-select/sectors.service';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-create-place',
  templateUrl: './create-place.component.html',
  styleUrls: ['./create-place.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    MatFormFieldModule,
    MatOptionModule,
    FusePipesModule,
    MatDialogModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
  providers: [SectorsService],
  animations: fuseAnimations,
})
export class CreatePlaceComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  place: any;
  loading: boolean = false;
  uuid?: string;
  noGeoLocation: boolean = false;
  sectors: SectorModel[] = [];
  selectedSectorModel?: string;
  selectedSector: any;

  userSettings: any = {
    inputPlaceholderText: '',
    showRecentSearch: true,
  };
  @ViewChild('autocompleteInput', { static: false })
  autocompleteInput!: ElementRef;
  //@ts-ignore
  autocomplete!: google.maps.places.Autocomplete;

  constructor(
    public compDialogRef: MatDialogRef<CreatePlaceComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private venuesService: VenuesService,
    private sectorsService: SectorsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.translationLoader.loadTranslations(englishAddress, frenchAddress);
    this.userSettings.inputPlaceholderText = this.translate.instant(
      'GENERIC.GOOGLE_ADDRESS_PLACEHOLDER',
    );

    if (data) {
      this.uuid = data.uuid;
    }

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      street_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.required,
        ],
      ],
      street: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      apartment: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      line2: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      city: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      province: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      country: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      postal_code: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(25),
          Validators.required,
        ],
      ],
    });

    this.place = {
      name: null,
      sector: null,
      address: {
        object: 'Address',
        uuid: null,
        line2: null,
        street_number: null,
        apartment: null,
        street: null,
        city: null,
        province: null,
        country: null,
        postal_code: null,
        lat: null,
        long: null,
      },
    };

    // load sectors
    this.sectorsService.getSectors().subscribe({
      next: (response: any) => {
        // this.logger.info("CreatePlaceComponent.sectorsService.getSectors() -> SUCCESS: " + JSON.stringify(response));
        this.sectors = response.data;
      },
      error: (error: any) => {
        this.logger.error(
          'CreatePlaceComponent.sectorsService.getSectors() -> ERROR: ' +
            JSON.stringify(error),
        );
      },
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.initializeAutocomplete();
  }

  ngOnDestroy() {}

  public onCancel(): void {
    // this.logger.info("CreatePlaceComponent.onCancel()");
  }

  public onCreatePlace(): void {
    this.loading = true;
    // this.logger.info("CreatePlaceComponent.onCreatePlace() -> " + this.form.valid);
    // this.logger.info("CreatePlaceComponent.onCreatePlace() -> this.client: " + JSON.stringify(this.place));
    if (this.form.valid) {
      this.place.note = '';
      this.place.royalty_percent = 1;
      this.venuesService.createVenue(this.place).subscribe({
        next: (response: any) => {
          // this.logger.info("CreatePlaceComponent.onCreatePlace() -> SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_PLACE'),
          );
          this.loading = false;
          this.compDialogRef.close(response.data);
        },
        error: (error: any) => {
          this.logger.error(
            'CreatePlaceComponent.onCreatePlace() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.CREATE_CLIENT'),
          );
        },
      });
    }
  }

  initializeAutocomplete(): void {
    //@ts-ignore
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.nativeElement,
      {
        types: ['geocode'],
        componentRestrictions: { country: 'ca' },
      },
    );

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      if (place && place.address_components) {
        this.noGeoLocation = false;
        this.extractAddressComponents(place);
      } else {
        this.noGeoLocation = true;
        return;
      }
    });
  }

  extractAddressComponents(
    //@ts-ignore
    place: google.maps,
  ): any {
    //@ts-ignore
    let addressComponents: google.maps.GeocoderAddressComponent[] =
      place.address_components;
    let street_number = '';
    let street = '';
    let city = '';
    let country = '';
    let postal_code = '';
    let province = '';
    let full_address = place.formatted_address;
    let lat = place.geometry.location.lat();
    let long = place.geometry.location.lng();
    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes('street_number')) {
        street_number = component.long_name;
      } else if (types.includes('route')) {
        street = component.long_name;
      } else if (types.includes('locality')) {
        city = component.long_name;
      } else if (types.includes('country')) {
        country = component.long_name;
      } else if (types.includes('postal_code')) {
        postal_code = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        province = component.long_name;
      }
    });

    this.place.address = {
      ...this.place.address,
      street_number,
      street,
      city,
      country,
      postal_code,
      province,
      full_address,
      lat,
      long,
    };
    this.cdr.detectChanges();
  }

  public onSectorChange(event: any): void {
    // this.logger.info("CreatePlaceComponent.onSectorChange() -> event: " + event);
    // this.logger.info("CreatePlaceComponent.onSectorChange() -> event uuid: " + event.value);

    // uuid is null = user selected Aucun/None
    if (!event.value) {
      // this.changeEvent.next(null);
      return;
    }

    const newSector = this.sectors.find((item) => {
      // this.logger.info("CreatePlaceComponent.onSectorChange() -> event uuid: " + event.value);
      return item.uuid === event.value;
    });
    this.place.sector = newSector;

    // this.logger.info("CreatePlaceComponent.onSectorChange() -> newSector" + JSON.stringify(this.place.sector));
    // this.changeEvent.next(newSector);
  }
}
