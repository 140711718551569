import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OrdersService } from '../../api/orders.service';
import { CreateEventComponent } from '../../components/dialogs/create-event/create-event.component';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { GT2FormPageAbstract } from '../abstract/GT2FormPageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { DashboardCalendarComponent } from './components/dashboard-calendar/dashboard-calendar.component';
import { ModulesService } from '../../services/modules.service';
import { Title } from '@angular/platform-browser';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { RolesService } from '../../services/roles.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppRoutes } from '../../const/AppRoutes';
import { CreateCongresDialogComponent } from '../congresses/create-congres-dialog/create-congres-dialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static TABS_INDEX: string = 'DASHBOARD_TABS_INDEX';

  @ViewChild('dashboarCalendar')
  dashboardCalendarComponent?: DashboardCalendarComponent;
  // @ViewChild("dashboarRoomManagement", { static: false })
  // dashboarRoomManagement: RoomManagementComponent;
  // @ViewChild("calendarDetailed", { static: false })
  // calendarDetailed: DashboardDetailedCalendarComponent;
  // @ViewChild("tabsGroup", { static: false }) tabsGroup: MatTabGroup;

  confirmDialogRef: any;
  modulesAreSet: boolean = false;

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public ordersService: OrdersService,
    public router: Router,
    public rolesService: RolesService,
    public modulesService: ModulesService,
    private logger: NGXLogger,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private translationLoader: FuseTranslationLoaderService
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit(): void {
    //this.setTitle(this.translate.instant("GENERIC.DASHBOARD"));
    this.modulesAreSet = false;
    this.spinner.show();

    this.modulesService.onModuleSet().subscribe((value: any) => {
      // this.logger.info("DashboardComponent.ngOnInit().modulesService.onModuleSet() -> value: " + value);
      if (value && this.modulesService.modulesAreSet) {
        this.spinner.hide();
        this.modulesAreSet = true;

        // setTimeout(() => {
        //     this.setTabsIndex();
        // }, 100);
      }
    });
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //     this.setTabsIndex();
    // }, 100);
    // this.setTabsIndex();
  }

  ngOnDestroy(): void {}

  // public setTabsIndex(): void {
  //     const tabsIndex: string = localStorage.getItem(
  //         DashboardComponent.TABS_INDEX
  //     );
  //     // this.logger.info(
  //     //     "DashboardComponent.setTabsIndex() -> tabsIndex: " + tabsIndex
  //     // );
  //     // this.logger.info(
  //     //     "DashboardComponent.setTabsIndex() -> this.tabsGroup: " +
  //     //         this.tabsGroup
  //     // );
  //     // if (this.tabsGroup && tabsIndex) {
  //     //     // this.logger.info("DashboardComponent.setTabsIndex() -> set index ");
  //     //     this.tabsGroup.selectedIndex = parseInt(tabsIndex, 10);
  //     //     // this.logger.info("DashboardComponent.setTabsIndex().tabsGroup.selectedIndex -> value: " + this.tabsGroup.selectedIndex);
  //     // }

  //     if (tabsIndex === "0") {
  //         this.logger.info("pre onSelectChange()");
  //         this.onSelectChange({ index: 0 });
  //     }
  // }

  public setTitle(newTitle: string): void {
    //this.titleService.setTitle(newTitle);
  }

  public onCreateOrder(): void {
    const curDate = this.dashboardCalendarComponent
      ? this.dashboardCalendarComponent.selectedDay.date
      : new Date().toISOString();
    /* this.logger.info(
            "DashboardCalendarComponent.onCreateOrder() -> this.dashboardCalendarComponent.selectedDay.date: " +
                this.dashboardCalendarComponent.selectedDay.date
        );
        //this.logger.info(
            "DashboardCalendarComponent.onCreateOrder() -> curDate: " + curDate
        ); */
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '45%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        subType: CreateEventComponent.ORDER_SUBTYPE,
        event_date: this.dashboardCalendarComponent
          ? this.dashboardCalendarComponent.selectedDay.date
          : new Date().toISOString(),
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      /* this.logger.info(
                "DashboardCalendarComponent.onCreateOrder() -> result: " +
                    JSON.stringify(result)
            ); */
      if (result) {
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }

      this.dialogRef = null;
    });
  }

  public onQuickOrder(): void {
    //this.logger.info("DashboardCalendarComponent.onQuickOrder()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle = 'Commande rapide';
    this.confirmDialogRef.componentInstance.alertMessage = 'À venir!';

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      this.confirmDialogRef = null;
    });
  }

  public onNewProposal(): void {
    //this.logger.info("DashboardCalendarComponent.onNewProposal()");
    // this.logger.info("DashboardCalendarComponent.onNewProposal() -> ******: " + this.addressComponent.selectedDay.date);
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '45%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        subType: CreateEventComponent.PROPOSAL_SUBTYPE,
        event_date: this.dashboardCalendarComponent
          ? this.dashboardCalendarComponent.selectedDay.date
          : new Date().toISOString(),
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      /* this.logger.info(
                "DashboardCalendarComponent.onNewEvent() -> result: " +
                    JSON.stringify(result)
            ); */
      if (result) {
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }

      this.dialogRef = null;
    });
  }

  public onNewEvent(): void {
    //this.logger.info("DashboardCalendarComponent.onNewEvent()");
    // this.dialogRef = this.dialog.open(CreateOrderComponent, {
    //     width: '45%',
    //     disableClose: false,
    //     data : {
    //         // event_date: GT2CalendarUtils.formatDateForAPI(this.selectedDay.date)
    //     }
    // });
    // this.dialogRef.afterClosed().subscribe(result => {
    //     this.logger.info('DashboardCalendarComponent.onNewEvent() -> result: ' + JSON.stringify(result));
    //     if (result)
    //     {
    //         this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
    //     }
    //     this.dialogRef = null;
    // });
  }

  public onNewInvoice(): void {
    //this.logger.info("DashboardCalendarComponent.onNewInvoice()");
    // this.dialogRef = this._matDialog.open(CreateOrderComponent, {
    //     width: '45%',
    //     disableClose: false,
    //     data : {
    //         // event_date: GT2CalendarUtils.formatDateForAPI(this.selectedDay.date)
    //     }
    // });
    // this.dialogRef.afterClosed().subscribe(result => {
    //     this.logger.info('DashboardCalendarComponent.onNewEvent() -> result: ' + JSON.stringify(result));
    //     if (result)
    //     {
    //         this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
    //     }
    //     this.dialogRef = null;
    // });
  }

  // public onSelectChange(event: any): void {
  //     this.logger.info(
  //         "DashboardComponent.onSelectChange() -> event.index: " + event.index
  //     );
  //     localStorage.setItem(
  //         DashboardComponent.TABS_INDEX,
  //         event.index.toString()
  //     );

  //     if (event.index === 0) {
  //         setTimeout(() => {
  //             // this.logger.info('DashboardComponent.onSelectChange() -> dashboarRoomManagement: ' + this.dashboarRoomManagement);
  //             if (this.dashboarRoomManagement) {
  //                 this.dashboarRoomManagement.refreshTimeline();
  //             }

  //             // if (this.dashboardCalendarComponent) {
  //             //     this.dashboardCalendarComponent.tabSelected();
  //             // }
  //         });
  //     } else if (event.index === 1) {
  //         // if (this.calendarDetailed) {
  //         //     this.calendarDetailed.tabSelected();
  //         // }
  //     } else {
  //         setTimeout(() => {
  //             // this.logger.info('DashboardComponent.onSelectChange() -> dashboardCalendarComponent: ' + this.dashboardCalendarComponent);
  //             if (this.dashboarRoomManagement) {
  //                 this.dashboarRoomManagement.initCalendar();
  //             }
  //         });
  //     }
  // }

  public onCreateNewCongress(event: any): void {
    //this.logger.info("CongressesComponent.onCreateNewCongress() -> event: " + event);
    this.dialogRef = this.dialog.open(CreateCongresDialogComponent, {
      width: '440px',
      minWidth: 350,
      maxHeight: '96vh',
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: { autoRedirect: true },
    });
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.reloadTable();
      }
      this.dialogRef = null;
    });
  }

  public onGoToCongresses(): void {
    this.router.navigateByUrl('/' + AppRoutes.CONGRESSES);
  }
}
