export const locale = {
  lang: "en-CA",
  data: {
    MATERIAL_COMBO: {
      TITLE: "Edit material combo ",
      TITLE_CREATE: "New material combo",
      LEFT_LIST_LABEL: "Drag & drop or use the + button to add a material",
      RIGHT_LIST_LABEL: "Rearrange or use the - button to remove a material",
      CREATE_MATERIAL: "Create material",
      SEARCH: "search by internal name, external name or material code",
      SEARCH_MATERIAL: "Perform a search for your material",
    },
  },
};
