import { CreateCongresDialogComponent } from './../../../gt2/content/congresses/create-congres-dialog/create-congres-dialog.component';
import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { FuseSidebarService } from '../../../../@fuse/components/sidebar/sidebar.service';
import { navigation } from '../../../navigation/navigation';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { AuthenticationService } from '../../../gt2/api/auth-service.service';
import { SearchService } from '../../../gt2/api/search.service';
import { SearchResultsSharedDataService } from '../../../gt2/content/search-results/search-results-shared-data.service';
import { CrudEmployeeService } from '../../../gt2/content/crud/crud-employee/crud-employee.service';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { EmployeeModelData } from '../../../gt2/api/models/CrudEmployeeModel';
import { Router, RouterModule } from '@angular/router';
import { CreateEmployeeComponent } from '../../../gt2/components/dialogs/create-employee/create-employee.component';
import { CreateProductComponent } from '../../../gt2/components/dialogs/create-product/create-product.component';
import { CreatePlaceComponent } from '../../../gt2/components/dialogs/create-place/create-place.component';
import { CreateEnterpriseComponent } from '../../../gt2/components/dialogs/create-enterprise/create-enterprise.component';
import { CreateClientComponent } from '../../../gt2/components/dialogs/create-client/create-client.component';
import { CreateMaterialComponent } from '../../../gt2/components/dialogs/create-material/create-material.component';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
import { AppRoutes } from '../../../gt2/const/AppRoutes';
import { CreateComboComponent } from '../../../gt2/components/create-combo/create-combo.component';
import { ItemUrlFinder } from '../../../gt2/utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { DeviceDetectorService, DEVICES } from 'ngx-device-detector';
import { CreateMaterialComboComponent } from '../../../gt2/components/create-material-combo/create-material-combo.component';
import { IconsService } from '../../../gt2/services/icons.service';
import { CreateEventComponent } from '../../../gt2/components/dialogs/create-event/create-event.component';
import { CrudOrganizationService } from '../../../gt2/content/crud/crud-organization/crud-organization.service';
import { JwtInterceptor } from '../../../gt2/interceptors/JwtInterceptor';
import { ModulesService } from '../../../gt2/services/modules.service';
import { RolesService } from '../../../gt2/services/roles.service';
import { PageHistory } from '../../../gt2/benching/page-history';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Gt2ApiService } from '../../../gt2/api/gt2-api.service';
import { switchTheme } from '../../../fuse-config';
import { AppRoutesName } from '../../../gt2/const/AppRoutesName';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, DOCUMENT } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import {
  FuseSearchBarModule,
  FuseShortcutsModule,
} from '../../../../@fuse/components';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import moment from 'moment';
import { FuseFullscreenComponent } from '../../../../@fuse/components/fullscreen/fullscreen.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    TranslateModule,
    MatInputModule,
    MatIconModule,
    FuseSearchBarModule,
    MatMenuModule,
    MatProgressBarModule,
    MatToolbarModule,
    CommonModule,
    FuseFullscreenComponent,
    NgxPermissionsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    FuseShortcutsModule,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  employee?: EmployeeModelData;
  caterer: any;
  organization: any; // OrganizationModel;
  dialogRef: any;
  isMobile: boolean = false;
  isSmallScreen: boolean = false;

  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
  @ViewChild('createMenuButton')
  createMenuButton?: MatButton;

  //
  horizontalNavbar?: boolean;
  rightNavbar?: boolean;
  hiddenNavbar?: boolean;
  languages: any;
  modeText?: string;
  navigation: any;
  selectedLanguage: any;
  loading: boolean = false;
  userStatusOptions: any[];

  // Private
  private _unsubscribeAll: Subject<any>;
  history?: import('../../../gt2/benching/page-history').HistoryStack[];
  histories: import('../../../gt2/benching/page-history').HistoryStack[];
  private _document = inject(DOCUMENT);

  /*
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    public rolesService: RolesService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private auth: AuthenticationService,
    private searchService: SearchService,
    private searchResultsSharedDataService: SearchResultsSharedDataService,
    public dialog: MatDialog,
    private router: Router,
    public logger: NGXLogger,
    private deviceService: DeviceDetectorService,
    private employeeService: CrudEmployeeService,
    public iconsService: IconsService,
    public modulesService: ModulesService,
    private orgService: CrudOrganizationService,
    private breakpointObserver: BreakpointObserver,
    private api: Gt2ApiService,
  ) {
    this.translationLoader.loadTranslations(english, french);

    this.histories = PageHistory.callStack;
    PageHistory.callStackChanged.subscribe({
      next: (callStack) => {
        this.histories = callStack;
      },
    });

    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.languages = [
      {
        id: 'en-CA',
        title: 'Français',
        shortTitle: 'FR',
        flag: 'fr',
      },
      {
        id: 'fr-CA',
        title: 'English',
        shortTitle: 'EN',
        flag: 'us',
      },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // user
    this.employeeService.employee.subscribe({
      next: (employee: any) => {
        if (employee) {
          this.employee = employee;
        }
      },
    });

    this.orgService.getOrganization().subscribe({
      next: (organization) => {
        if (organization.data) {
          this.organization = organization.data;
        }
      },
    });

    this.caterer = this.orgService.getCatererMeObject();
    if (this.caterer === null) {
      this.orgService.loadCatererMe('ToolbarComponent').subscribe({
        next: (response) => {
          if (response.data) {
            this.caterer = response;
          }
        },
        error: (error) => {
          // don't show long error message in console if just a 401
          if (error.status !== 401) {
            this.logger.error(
              'FuseToolbarComponent.constructor().load Caterer Me() -> Error: ' +
                JSON.stringify(error),
            );
          }
        },
      });
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this.modeText =
      localStorage.getItem('theme') === 'theme-default'
        ? this._translateService.instant('TOOLBAR.DARKMODE')
        : this._translateService.instant('TOOLBAR.LIGTHMODE');
    localStorage.setItem('API_URL', this.api.getDomainUrl() + '/v1/');
    this.breakpointObserver.observe(['(max-width: 599px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (settings: any) => {
          this.horizontalNavbar = settings.layout.navbar.position === 'top';
          this.rightNavbar = settings.layout.navbar.position === 'right';
          this.hiddenNavbar = settings.layout.navbar.hidden === true;
        },
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });
    this.isMobile = this.detectMobileDevice();

    // this.logger.info("this.selectedLanguage: " + this.selectedLanguage);
  }
  detectMobileDevice(): boolean {
    return this.deviceService.isMobile() || this.deviceService.isTablet();
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  toggleTheme() {
    switchTheme();
    this.modeText =
      localStorage.getItem('theme') === 'theme-default'
        ? this._translateService.instant('TOOLBAR.DARKMODE')
        : this._translateService.instant('TOOLBAR.LIGTHMODE');
  }
  toggleFullscreen(): void {
    if (!this._document.fullscreenEnabled) {
      return;
    }

    // Check if the fullscreen is already open
    const fullScreen = this._document.fullscreenElement;

    // Toggle the fullscreen
    if (fullScreen) {
      this._document.exitFullscreen();
    } else {
      this._document.documentElement.requestFullscreen().catch(() => {
        console.error('Entering fullscreen mode failed.');
      });
    }
  }
  toggleLang() {
    const langg = localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID);
    const lang = this.languages.find((l: any) => l.id !== langg);
    this.selectedLanguage = lang;

    localStorage.setItem(LocalizationConfig.LOCAL_STORED_LANG_ID, lang.id);
    // Update page data
    this.fromUrlFrToEn();
    moment.locale(lang.id);
    // Use the selected language for translations
    this._translateService.use(lang.id);
  }
  fromUrlFrToEn() {
    const currentUrl = window.location.href;

    const routeKeys = Object.keys(AppRoutes);
    for (const key of routeKeys) {
      //@ts-ignore
      const routeFR = '/' + AppRoutesName.FR[key];
      //@ts-ignore
      const routeEN = '/' + AppRoutesName.EN[key];

      if (currentUrl.includes(routeFR)) {
        window.location.href = currentUrl.replace(routeFR, routeEN);
        return;
      } else if (currentUrl.includes(routeEN)) {
        window.location.href = currentUrl.replace(routeEN, routeFR);
        return;
      }
    }

    window.location.href = currentUrl;
    return;
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key: any): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  setHistory(history: any): void {
    //this.logger.info("setHistory() -> history: " + history.url);
    //window.open(history.url);
    this.router.navigateByUrl(history.url);
  }
  public autoComplete(value: any) {
    //this.logger.info("autoComplete() -> value: " + value);
  }

  public search(value: any) {
    this.loading = true;
    // Do your search here...
    //this.logger.info("ToolbarComponent.search() -> value: " + value);

    // TEMPORARY
    const isTestSearch: boolean = value instanceof Object;
    if (isTestSearch) {
      // this.logger.info("SEARCH TEST");
      if (!value.value || value.value.length < 2) {
        // TODO: add a visual warning for user
        //this.logger.info("WARNING: NO VALUE OR SMALLER THAN 3");
        return;
      }

      this.searchService.getSearchResultsTest(value.value).subscribe({
        next: (response) => {
          // this.logger.info("FuseToolbarComponent.search() -> SUCCESS: " + JSON.stringify(response));
          this.searchResultsSharedDataService.setSearchData(
            response,
            value.value,
          );
          this.router.navigateByUrl(
            '/' + AppRoutes.SEARCH + '?keyword=' + value.value,
          );
          this.loading = false;
        },
        error: (error) => {
          this.logger.error(
            'FuseToolbarComponent.search() -> ERROR: ' + JSON.stringify(error),
          );
          this.loading = false;
        },
      });
    } else {
      //
      if (!value || value.length < 3) {
        //this.logger.info("WARNING: NO VALUE OR SMALLER THAN 3");
        return;
      }

      this.searchService.getSearchResults(value).subscribe({
        next: (response) => {
          // this.logger.info("FuseToolbarComponent.search() -> SUCCESS: " + JSON.stringify(response));
          this.searchResultsSharedDataService.setSearchData(response, value);
          this.router.navigateByUrl('/search?keyword=' + value);
          this.loading = false;
        },
        error: (error) => {
          this.logger.error(
            'FuseToolbarComponent.search() -> ERROR: ' + JSON.stringify(error),
          );
          this.loading = false;
        },
      });
    }
  }

  public onShop(): void {
    window.open(this.organization.shop_url, '_blank');
  }

  public onCreateNewEmployee(): void {
    this.dialogRef = this.dialog.open(CreateEmployeeComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateNewProduct(): void {
    //this.logger.info("FuseToolbarComponent.onCreateNewProduct()");
    this.dialogRef = this.dialog.open(CreateProductComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateNewProductCombo(): void {
    //this.logger.info("FuseToolbarComponent.onCreateNewProductCombo()");
    this.dialogRef = this.dialog.open(CreateProductComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.tranformProductIntoCombo(result);
        }
        this.dialogRef = null;
      },
    });
  }

  private tranformProductIntoCombo(product: any): void {
    //this.logger.info("FuseToolbarComponent.tranformProductIntoCombo() -> product: " + JSON.stringify(product));
    this.dialogRef = this.dialog.open(CreateComboComponent, {
      width: '80%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: { product: product, products: null, creationMode: true },
    });

    this.dialogRef.componentInstance.confirmMessage = '';

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        //this.logger.info("FuseToolbarComponent.tranformProductIntoCombo() -> result: " + JSON.stringify(result));
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(product));
        this.dialogRef = null;
      },
    });
  }

  public onCreateNewMaterialCombo(): void {
    //this.logger.info("FuseToolbarComponent.onCreateNewMaterialCombo()");
    this.dialogRef = this.dialog.open(CreateMaterialComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.tranformMaterialIntoCombo(result);
        }
        this.dialogRef = null;
      },
    });
  }

  private tranformMaterialIntoCombo(material: any): void {
    //this.logger.info("FuseToolbarComponent.tranformProductIntoCombo() -> product: " + JSON.stringify(material));
    this.dialogRef = this.dialog.open(CreateMaterialComboComponent, {
      width: '80%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: { material: material, materials: null, creationMode: true },
    });

    this.dialogRef.componentInstance.confirmMessage = '';

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        // this.logger.info("FuseToolbarComponent.tranformProductIntoCombo() -> result: " + JSON.stringify(result));
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(material));
        this.dialogRef = null;
      },
    });
  }

  public onCreateNewPlace(): void {
    //this.logger.info("FuseToolbarComponent.onCreateNewPlace()");
    this.dialogRef = this.dialog.open(CreatePlaceComponent, {
      width: '62%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateNewOrder(): void {
    //this.logger.info("FuseToolbarComponent.onCreateNewOrder()");
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '30%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        subType: CreateEventComponent.ORDER_SUBTYPE,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        //this.logger.info("DashboardCalendarComponent.onNewEvent() -> result: " + JSON.stringify(result));
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateNewCongress(): void {
    //this.logger.info("FuseToolbarComponent.onCreateNewCongress()");
    this.dialogRef = this.dialog.open(CreateCongresDialogComponent, {
      width: '440px',
      maxHeight: '96vh',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: { autoRedirect: true },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        // if (result) {
        //     this.reloadTable();
        // }
        this.dialogRef = null;
      },
    });
  }

  public onCreateNewProposal(): void {
    //this.logger.info("FuseToolbarComponent.onCreateNewProposal()");
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '30%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        subType: CreateEventComponent.PROPOSAL_SUBTYPE,
        // event_date: new Date().toISOString()
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        //this.logger.info("DashboardCalendarComponent.onNewEvent() -> result: " + JSON.stringify(result));
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateNewEnterprise(): void {
    //this.logger.info("FuseToolbarComponent.onCreateNewEnterprise()");
    this.dialogRef = this.dialog.open(CreateEnterpriseComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateNewClient(): void {
    //this.logger.info("FuseToolbarComponent.onCreateNewClient()");
    this.dialogRef = this.dialog.open(CreateClientComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateNewMaterial(): void {
    //this.logger.info("FuseToolbarComponent.onCreateNewMaterial()");
    this.dialogRef = this.dialog.open(CreateMaterialComponent, {
      width: '340px',
      disableClose: false,
      data: {
        autoRedirect: true,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.dialogRef = null;
      },
    });
  }

  public onProfile() {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(this.employee));
  }

  public onOrganization() {
    this.router.navigateByUrl(
      '/' +
        AppRoutes.CATERERS +
        '/' +
        this.caterer.data.slug +
        '/' +
        this.caterer.data.uuid,
    );
  }

  public onCaterer() {
    this.router.navigateByUrl('/' + AppRoutes.CATERER);
  }

  public onSettings(): void {
    this.router.navigateByUrl('/' + AppRoutes.SETTINGS);
  }

  public onLogout(): void {
    //this.logger.info("FuseToolbarComponent.onLogout()");
    this.auth.logout(true).subscribe({
      next: (response) => {
        // this.logger.info("FuseToolbarComponent.onLogout() -> SUCCESS: " + JSON.stringify(response));
        JwtInterceptor.LAST_URL_401 = null;
      },
      error: (error) => {
        this.logger.error(
          'FuseToolbarComponent.onLogout() -> ERROR: ' + JSON.stringify(error),
        );
      },
    });
  }

  public openMyMenu(event: MouseEvent) {
    //this.logger.info("FuseToolbarComponent.openMyMenu()");
    //this.logger.info(event);
    // this.logger.info("FuseToolbarComponent.openMyMenu() -> this.deviceService.device: " + this.deviceService.device);
    // this.logger.info("FuseToolbarComponent.openMyMenu() -> DEVICES.I_PAD: " + DEVICES.I_PAD);
    if (this.deviceService.device !== DEVICES.iPad) {
      // this.logger.info("FuseToolbarComponent.openMyMenu() -> NOT AN IPAD!!! ");
      this.trigger?.openMenu();
    }
  }

  public closeMyMenu() {
    // this.logger.info("FuseToolbarComponent.closeMyMenu()");
    this.trigger?.closeMenu();
    // this.createMenuButton.focus();
  }

  public onMenuClosed() {
    // this.logger.info("FuseToolbarComponent.openMyMenu() -> " + this.createMenuButton);
  }
}
