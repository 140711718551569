import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CreateClientService } from '../../../api/create-client.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Settings } from '../../../settings/settings';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { Gt2LocaleService } from '../../../../localization/gt2-locale.service';
import { locale as englishAddress } from '../../address/i18n/en-CA';
import { locale as frenchAddress } from '../../address/i18n/fr-CA';
import { locale as englishContact } from '../../contact/i18n/en-CA';
import { locale as frenchContact } from '../../contact/i18n/fr-CA';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
@Component({
  selector: 'app-create-enterprise-with-details',
  templateUrl: './create-enterprise-with-details.component.html',
  styleUrls: ['./create-enterprise-with-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    TranslateModule,
    MatInputModule,
    MatButtonModule,
  ],
})
export class CreateEnterpriseWithDetailsComponent
  extends GT2PageAbstract
  implements OnInit
{
  form: UntypedFormGroup;
  enterprise: any;
  loading: boolean = false;
  type?: string;
  uuid?: string;
  userSettings: any = {
    inputPlaceholderText: '',
    showRecentSearch: true,
  };
  noGeoLocation: boolean = false;
  addressPrefillDirty: boolean = false;
  constructor(
    public override dialogRef: MatDialogRef<CreateEnterpriseWithDetailsComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private createclientService: CreateClientService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public gt2LocalService: Gt2LocaleService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.translationLoader.loadTranslations(englishAddress, frenchAddress);
    this.translationLoader.loadTranslations(englishContact, frenchContact);
    this.userSettings.inputPlaceholderText = this.translate.instant(
      'GENERIC.GOOGLE_ADDRESS_PLACEHOLDER',
    );

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      professional_email: ['', [Validators.email]],
      telephone_office: [
        '',
        [Validators.minLength(2), Validators.maxLength(100)],
      ],
      telephone_office_ext: [
        '',
        [Validators.minLength(2), Validators.maxLength(20)],
      ],
      street_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.required,
        ],
      ],
      street: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      apartment: '',
      line2: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      city: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      province: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      country: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      postal_code: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.enterprise = {
      name: null,
      note: null,
      contact: {
        email_organisation: null,
        telephone_office: null,
        telephone_office_ext: null,
      },
      address: {
        street_number: null,
        street: null,
        apartment: null,
        line2: null,
        city: null,
        province: null,
        country: null,
        postal_code: null,
      },
    };
  }

  ngOnInit() {}

  public findAddressProperty(address: any, key: string): string {
    const item = address.data.address_components.find((data: any) => {
      return data.types.indexOf(key) !== -1;
    });

    if (item && item.long_name) {
      return item.long_name;
    }
    return '';
  }

  public handleAddressChange(address: any) {
    // this.logger.info("AddressComponent.handleAddressChange() --> address: " + JSON.stringify(address));

    if (address.response === false) {
      this.noGeoLocation = true;
      return;
    } else {
      //this.isVisible = true;
      this.noGeoLocation = false;

      Promise.resolve(null).then(() => {
        // street_number
        // this.enterprise.address.street_number = address.data.address_components.find( data => {
        //     return data.types.indexOf('street_number') !== -1;
        // }).long_name;

        this.enterprise.address.street_number = this.findAddressProperty(
          address,
          'street_number',
        );
        this.enterprise.address.street = this.findAddressProperty(
          address,
          'route',
        );
        this.enterprise.address.city = this.findAddressProperty(
          address,
          'locality',
        );
        this.enterprise.address.province = this.findAddressProperty(
          address,
          'administrative_area_level_1',
        );
        this.enterprise.address.country = this.findAddressProperty(
          address,
          'country',
        );
        this.enterprise.address.postal_code = this.findAddressProperty(
          address,
          'postal_code',
        );
        this.enterprise.address.lat = address.data.geometry.location.lat;
        this.enterprise.address.long = address.data.geometry.location.lng;
        this.enterprise.address.full_address = address.data.formatted_address;

        this.addressPrefillDirty = true;
      });
    }
  }

  public onCreateEnterprise(): void {
    this.loading = true;
    this.createclientService.createEnterprise(this.enterprise).subscribe(
      (response: any) => {
        // this.logger.info("CreateenterpriseComponent.onCreateenterprise() -> SUCCESS: " + JSON.stringify(response));
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_enterprise'),
        );
        this.dialogRef.close(response.data);
        this.loading = false;
        //this.router.navigateByUrl(
        //    ItemUrlFinder.getItemURL(response.data)
        // );
      },
      (error: any) => {
        this.logger.error(
          'CreateenterpriseComponent.onCreateenterprise() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_enterprise"));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.CREATE_enterprise'),
        );
      },
    );
  }
}
