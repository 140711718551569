<!--<div id="material" class="page-layout simple left-sidenav inner-sidenav" fxLayout="column">-->
<div
  id="material"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
          (click)="onPageBack(location, router, '/materials')"
        >
          <mat-icon class="icon-mat">arrow_back</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
            <div
              class="material-title"
              *fuseIfOnDom
              [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' },
              }"
            >
              <!--                        <div *ngIf="material" class="position" matTooltip="{{material.name}}" [matTooltipPosition]="'right'">-->
              <div *ngIf="material" class="position">
                {{ "MATERIALS.MATERIAL" | translate }} -
                {{ material.name_internal }}
              </div>
            </div>
          </div>

          <div
            *ngIf="material"
            fxLayout="row"
            fxLayoutAlign="start end"
            class=""
          >
            <div *ngIf="material.childs">
              {{ "MATERIALS.COMBO" | translate }}
            </div>
            <div *ngIf="!material.childs || material.childs.length === 0">
              {{ "MATERIALS.SINGLE_MATERIAL" | translate }}
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              &nbsp; &nbsp;| &nbsp;{{ "MATERIALS.CONFIRMED" | translate }}:
              &nbsp;
              <mat-icon
                *ngIf="material && material.confirmed"
                class="s-16"
                style="background-color: green; color: white"
                >check
              </mat-icon>
              <mat-icon
                *ngIf="material && !material.confirmed"
                class="s-16"
                style="background-color: red; color: white"
                >clear
              </mat-icon>
            </div>

            <div
              *ngIf="material.ordered && material.order"
              class="text-button-cursor"
              (click)="onOpenObjectPage(material.order)"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              &nbsp; &nbsp;| &nbsp;{{ "GENERIC.ORDER" | translate }}:
              {{ material.order.name }} - {{ material.order.order_number }}
            </div>
            <div
              *ngIf="material.ordered && !material.order"
              class="text-button-cursor"
              (click)="onOpenObjectPage(material.order)"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              &nbsp; &nbsp;| &nbsp;{{
                "MATERIALS.ORDERED_MATERIAL" | translate
              }}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end start">
        <!-- <button mat-button
                        *ngIf="fileService.pdfEnabled()"
                        matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                        (click)="onDownloadPDF()" class="mat-icon-button">
                    <mat-icon class="s-30">picture_as_pdf</mat-icon>
                </button> -->

        <!-- <mat-spinner *ngIf="print.loadingPrint" [diameter]="20" class="mt-12"></mat-spinner> -->
        <!-- <button mat-button
                        *ngIf="fileService.printEnabled()"
                        matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                        (click)="onPrint()" class="mat-icon-button">
                    <mat-icon class="s-30">print</mat-icon>
                </button> -->

        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="icon-mat s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    *ngIf="material"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 py-8 gt2-sub-header content-subheader"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <button
        (click)="onNavigateToOrder($event)"
        *ngIf="material.order"
        class="gt2-sub-header-button"
        mat-button
      >
        <mat-icon
          *ngIf="material.order.type === 'ORDER'"
          [matTooltipPosition]="'right'"
          class="icon-mat s-24"
          matTooltip="{{ 'GENERIC.ORDER' | translate }}"
          >{{ getIcon("ORDER") }}</mat-icon
        >
        <mat-icon
          *ngIf="material.order.type === 'PROPOSAL'"
          [matTooltipPosition]="'right'"
          class="icon-mat s-24"
          matTooltip="{{ 'GENERIC.PROPOSAL' | translate }}"
          >{{ getIcon("PROPOSAL") }}</mat-icon
        >
        <mat-icon
          *ngIf="material.order.type === 'INVOICE'"
          [matTooltipPosition]="'right'"
          class="icon-mat s-24"
          matTooltip="{{ 'GENERIC.INVOICE' | translate }}"
          >{{ getIcon("INVOICE") }}</mat-icon
        >
        <span class="ml-8 icon-mat">
          {{ "MATERIALS.SEE_" + material.order.type | translate }}
        </span>
      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        mat-button
        *ngIf="!material.childs"
        (click)="onCloneMaterial()"
        class="gt2-sub-header-button"
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("CLONE") }}</mat-icon>
        <span class="ml-8 icon-mat">{{
          "MATERIALS.CLONE_MATERIAL" | translate
        }}</span>
      </button>

      <button
        mat-button
        *ngIf="material.childs"
        (click)="onCloneCombo()"
        class=""
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("CLONE") }}</mat-icon>
        <span class="ml-8 icon-mat">{{
          "MATERIALS.CLONE_COMBO" | translate
        }}</span>
      </button>

      <button mat-button *ngIf="!material.childs" (click)="onCombo()" class="">
        <!--<mat-icon class="s-24">mail_outline</mat-icon>-->
        <mat-icon
          fontSet="fa"
          fontIcon="{{ getIcon('COMBO') }}"
          class="icon-mat fa-lg"
        ></mat-icon>
        <span class="ml-8 icon-mat">{{
          "MATERIALS.CREATE_COMBO" | translate
        }}</span>
      </button>
    </div>
  </div>
  <!-- / SUB HEADER -->

  <div
    *ngIf="material && !editable"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="height: 38px; min-height: 38px; max-height: 38px"
    class="gt2-gray mat-elevation-z1 py-8"
  >
    <div class="text-white font-weight-600">
      <b>{{ "GENERIC.READ_ONLY_MODE" | translate }}</b> :
      {{ "GENERIC.ARCHIVE_PAGE" | translate }}
    </div>
  </div>

  <div *ngIf="!isSmallScreen" class="container-history-panel">
    <fuse-sidebar
      style="display: flex; align-items: flex-end"
      name="materialHistoryPanel"
      position="right"
      class="section-history-panel"
    >
      <div [style.width]="componentWidth">
        <app-section-history-panel
          panelName="materialHistoryPanel"
          section="materials"
          sectionUUID="{{ materialID }}"
        ></app-section-history-panel>
      </div>
    </fuse-sidebar>
  </div>

  <div class="content">
    <div *ngIf="!isSmallScreen">
      <button
        mat-icon-button
        class="warn mat-elevation-z2 historic-button"
        *fuseIfOnDom
        [@animate]="{
          value: '*',
          params: { delay: '300ms', scale: '.2' },
        }"
        (click)="toggleSidebarOpen('materialHistoryPanel')"
      >
        <mat-icon class="icon-mat">history</mat-icon>
      </button>
    </div>

    <!-- CENTER -->
    <div class="center p-24 pb-56 pr-sm-72" #mainContainer fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="material-content">
        <!--TAGS-->
        <div fxLayout="row" fxLayoutAlign="start end">
          <app-tags-by-model
            #tags
            fxFlex="100%"
            class="ml-4"
            [disabled]="!editable"
            [tagPlaceholder]="'GENERIC.NEW_TAG' | translate"
            [tagFamily]="['tags-material-type', 'tags-material-custom']"
            [customTagFamily]="'tags-material-custom'"
            [tagModel]="'material'"
            [tagModelUUID]="materialID"
          ></app-tags-by-model>
        </div>

        <div
          *ngIf="material"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "GENERIC.INFORMATION" | translate }}</div>
          </header>

          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            dynamicHeight="true"
            (selectedIndexChange)="onGeneralTabChange($event)"
          >
            <mat-tab label="{{ 'GENERIC.GENERAL' | translate }}">
              <div class="content" fxLayout="column">
                <!-- MATERIAL INTERNAL NAME FR -->
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <!--  VIEW MODE -->
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.INTERNAL_NAME" | translate }}
                    </div>
                    <div class="info text-truncate">
                      {{ material.name_internal_local.name_internal_fr_CA }}
                    </div>
                    <div
                      *ngIf="!material.name_internal_local.name_internal_fr_CA"
                      class="info empty-value"
                    ></div>
                  </div>
                  <!--  EDIT MODE -->
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "GENERIC.INTERNAL_NAME" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="name_internal_fr_CA"
                            autocomplete="nope"
                            type="text"
                            [(ngModel)]="
                              material.name_internal_local.name_internal_fr_CA
                            "
                            required
                          />
                          <mat-error
                            *ngIf="form.controls['name_internal_fr_CA'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <!-- MATERIAL EXTERNAL NAME FR -->
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.EXTERNAL_NAME" | translate }}
                    </div>
                    <div class="info text-truncate">
                      {{ material.name_local.name_fr_CA }}
                    </div>
                    <div
                      *ngIf="
                        !material.name_local || !material.name_local.name_fr_CA
                      "
                      class="info empty-value"
                    ></div>
                  </div>
                  <!-- ARROW button -->
                  <button
                    *ngIf="editMode"
                    class=""
                    mat-icon-button
                    (click)="
                      material.name_local.name_fr_CA =
                        form.controls['name_internal_fr_CA'].value
                    "
                    class="mt-4"
                    matTooltip="{{
                      'GENERIC.COPY_INTERNAL_NAME_TO_EXTERNAL' | translate
                    }}"
                  >
                    <mat-icon class="icon-mat s-20">redo</mat-icon>
                  </button>
                  <!-- / ARROW button -->
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "GENERIC.EXTERNAL_NAME" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="name_fr_CA"
                            autocomplete="nope"
                            type="text"
                            [(ngModel)]="material.name_local.name_fr_CA"
                            required
                          />
                          <mat-error *ngIf="form.controls['name_fr_CA'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="mb-16"
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div class="info-line" fxFlex="100%" [formGroup]="form">
                    <div class="title">
                      {{ "MATERIALS.DESCRIPTION" | translate }}
                    </div>
                    <p-editor
                      #descriptionFrEditor
                      formControlName="description_fr_CA"
                      [(ngModel)]="material.description_local.description_fr_CA"
                      [readonly]="!editMode"
                      [style]="{
                        height: '120px',
                        width: '100%',
                        color: editMode ? '#ffffff' : '#EDEDED',
                      }"
                      styleClass="{{ editMode ? '' : 'disbled-editor' }}"
                    >
                      <ng-template pTemplate="header" class="">
                        <!-- <span [ngClass]="(editMode)? '' : 'ql-formats'">
                                                    <select class="ql-font"></select>
                                                    <select class="ql-size"></select>
                                                </span> -->

                        <span class="ql-formats">
                          <button class="ql-bold"></button>
                          <button class="ql-italic"></button>
                          <button class="ql-underline"></button>
                          <button class="ql-strike"></button>
                        </span>

                        <!-- <span class="ql-formats">
                                                    <select class="ql-color"></select>
                                                    <select class="ql-background"></select>
                                                </span> -->

                        <span class="ql-formats">
                          <button class="ql-list" value="ordered"></button>
                          <button class="ql-list" value="bullet"></button>
                          <button class="ql-indent" value="-1"></button>
                          <button class="ql-indent" value="+1"></button>
                        </span>
                      </ng-template>
                    </p-editor>

                    <mat-error
                      *ngIf="form.controls['description_fr_CA'].invalid"
                      class="p-4 pr-8"
                      fxLayout="row"
                      fxLayoutAlign="end center"
                    >
                      {{
                        "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate
                      }}
                    </mat-error>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                  class="4"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">{{ "MATERIALS.PRICE" | translate }}</div>
                    <div class="info text-truncate">
                      {{ material.price_label }}
                    </div>
                    <div
                      *ngIf="!material.price_label"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "MATERIALS.PRICE" | translate
                          }}</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="price"
                            autocomplete="nope"
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            [(ngModel)]="material.price"
                            required
                          />
                          <mat-error *ngIf="form.controls['price'].invalid"
                            >{{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
                            {{
                              "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL"
                                | translate
                            }}</mat-error
                          >
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">{{ "MATERIALS.COST" | translate }}</div>
                    <div *ngIf="material.cost" class="info text-truncate">
                      {{ material.cost_label }}
                    </div>
                    <div *ngIf="!material.cost" class="info empty-value"></div>
                  </div>
                  <div *ngIf="editMode" class="info-line mb-4" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container mr-4"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "MATERIALS.COST" | translate
                          }}</mat-label>
                          <input
                            matInput
                            type="text"
                            autocomplete="nope"
                            formControlName="cost"
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            [(ngModel)]="material.cost"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                  class=""
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "MATERIALS.MATERIAL_NUMBER" | translate }}
                    </div>
                    <div class="info text-truncate">
                      {{ material.material_number }}
                    </div>
                    <div
                      *ngIf="!material.material_number"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line mb-4" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container mr-4"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "MATERIALS.MATERIAL_NUMBER" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="material_number"
                            autocomplete="nope"
                            type="text"
                            [(ngModel)]="material.material_number"
                            required
                          />
                          <mat-error
                            *ngIf="form.controls['material_number'].invalid"
                            >{{
                              "GENERIC.MODEL_NUMBER_INPUT_LENGTH_INVALID"
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <!-- HERE -->
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.CONFIRMED" | translate }}
                    </div>
                    <div *ngIf="material.confirmed" class="info">
                      {{ "GENERIC.YES" | translate }}
                    </div>
                    <div *ngIf="!material.confirmed" class="info">
                      {{ "GENERIC.NO" | translate }}
                    </div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="100%">
                    <div
                      *ngxPermissionsOnly="rolesService.RH_ROLES"
                      class="info-editable"
                    >
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "GENERIC.CONFIRMED" | translate
                          }}</mat-label>
                          <mat-select
                            formControlName="confirmed"
                            [(ngModel)]="material.confirmed"
                          >
                            <mat-option [value]="1">
                              <span class="iso">{{
                                "GENERIC.YES" | translate
                              }}</span>
                            </mat-option>
                            <mat-option [value]="0">
                              <span class="iso">{{
                                "GENERIC.NO" | translate
                              }}</span>
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div
                      *ngxPermissionsExcept="['ADMIN', 'GOD', 'OWNER']"
                      class="info-editable"
                    >
                      <div class="title">
                        {{ "GENERIC.CONFIRMED" | translate }}
                      </div>
                      <div *ngIf="material.confirmed" class="info">
                        {{ "GENERIC.YES" | translate }}
                      </div>
                      <div *ngIf="!material.confirmed" class="info">
                        {{ "GENERIC.NO" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                  class=""
                >
                  <div *ngIf="!editMode" class="info-line mr-4" fxFlex="100%">
                    <div class="title">
                      {{ "MATERIALS.TOTAL_INVENTORY_QTY" | translate }}
                    </div>
                    <div class="info text-truncate">
                      {{ material.total_inventory_qty }}
                    </div>
                    <div
                      *ngIf="
                        !material.total_inventory_qty &&
                        material.total_inventory_qty !== 0
                      "
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container mr-4"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "MATERIALS.TOTAL_INVENTORY_QTY" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="total_inventory_qty"
                            type="number"
                            min="0"
                            autocomplete="nope"
                            [(ngModel)]="material.total_inventory_qty"
                            required
                          />
                          <!--oninput="validity.valid||(value='');"-->
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line ml-8" fxFlex="100%">
                    <div class="title">
                      {{ "MATERIALS.MATERIAL_PROVIDER" | translate }}
                    </div>
                    <div
                      *ngIf="material.material_provider"
                      class="info text-truncate"
                    >
                      {{ material.material_provider.name }}
                    </div>
                    <div
                      *ngIf="!material.material_provider"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="">
                      <app-gt2-select
                        [service]="materialProviderService"
                        [nullableUseNone]="false"
                        [placeHolder]="
                          'MATERIALS.MATERIAL_PROVIDER_SELECT' | translate
                        "
                        [label]="'Provider'"
                        [selectedModel]="material.material_provider"
                        (changeEvent)="onMaterialProviderSelected($event)"
                        [noResultsLabel]="
                          translate.instant(
                            'MATERIALS.NO_MATERIAL_PROVIDER_RESULTS'
                          )
                        "
                        fxFlex="100%"
                        class=""
                      ></app-gt2-select>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                  class=""
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "MATERIALS.MATERIAL_CATEGORIES" | translate }}
                    </div>
                    <div
                      *ngIf="material.material_category"
                      class="info text-truncate"
                    >
                      {{ material.material_category.name }}
                    </div>
                    <div
                      *ngIf="!material.material_category"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line mr-4" fxFlex="50%">
                    <div class="">
                      <app-gt2-select
                        [service]="materialCategoryService"
                        [nullableUseNone]="false"
                        [placeHolder]="
                          'MATERIALS.MATERIAL_CATEGORY_SELECT' | translate
                        "
                        [label]="'Category'"
                        [selectedModel]="material.material_category"
                        (changeEvent)="onMaterialCategorySelected($event)"
                        [noResultsLabel]="
                          translate.instant('GENERIC.NO_RESULTS')
                        "
                        fxFlex="100%"
                        class=""
                      ></app-gt2-select>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">{{ "MATERIALS.TAXES" | translate }}</div>
                    <div *ngIf="material.tax" class="info text-truncate">
                      {{ material.tax.name }}
                    </div>
                    <div *ngIf="!material.tax" class="info empty-value"></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="">
                      <app-gt2-select
                        [service]="taxesService"
                        [placeHolder]="'MATERIALS.TAXES_SELECT' | translate"
                        [label]="'MATERIALS.TAXES' | translate"
                        [selectedModel]="material.tax"
                        (changeEvent)="onTaxesSelected($event)"
                        [noResultsLabel]="
                          translate.instant('GENERIC.NO_RESULTS')
                        "
                        fxFlex="100%"
                        class=""
                      ></app-gt2-select>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start end"
                  class="4"
                >
                  <div *ngIf="!editMode" class="info-line mb-4" fxFlex="100%">
                    <div class="title">{{ "MATERIALS.SKU" | translate }}</div>
                    <div class="info">{{ material.sku }}</div>
                    <div *ngIf="!material.sku" class="info empty-value"></div>
                  </div>
                  <div *ngIf="editMode" class="info-line mb-4" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container mr-4"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "MATERIALS.SKU" | translate
                          }}</mat-label>
                          <input
                            matInput
                            autocomplete="nope"
                            formControlName="sku"
                            type="text"
                            [(ngModel)]="material.sku"
                          />
                          <mat-error *ngIf="form.controls['sku'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                  class=""
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.AVAILABLE" | translate }}
                    </div>
                    <div *ngIf="material.active" class="info">
                      {{ "GENERIC.YES" | translate }}
                    </div>
                    <div *ngIf="!material.active" class="info">
                      {{ "GENERIC.NO" | translate }}
                    </div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <mat-checkbox
                      labelPosition="before"
                      color="accent"
                      (ngModelChange)="onMaterialActiveChange($event)"
                      [ngModel]="material.active"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <span class="icon-mat-dark">
                        {{ "GENERIC.AVAILABLE" | translate }}
                      </span>
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="{{ 'GENERIC.en-CA' | translate }}">
              <ng-template matTabContent>
                <div class="content" fxLayout="column">
                  <!-- MATERIAL INTERNAL NAME EN -->
                  <div
                    fxLayout="row"
                    fxLayout.xs="column"
                    fxLayoutAlign="start start"
                  >
                    <!-- VIEW MODE -->
                    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                      <div class="title">
                        {{ "GENERIC.INTERNAL_NAME" | translate }}
                      </div>
                      <div class="info">
                        {{ material.name_internal_local.name_internal_en_CA }}
                      </div>
                      <div
                        *ngIf="
                          !material.name_internal_local ||
                          !material.name_internal_local.name_internal_en_CA
                        "
                        class="info empty-value"
                      ></div>
                    </div>
                    <!-- EDIT MODE -->
                    <div *ngIf="editMode" class="info-line" fxFlex="50%">
                      <div class="info-editable">
                        <div [formGroup]="formEnTab" class="">
                          <mat-form-field
                            class="form-input-container"
                            fxFlex="100%"
                          >
                            <mat-label>{{
                              "GENERIC.INTERNAL_NAME" | translate
                            }}</mat-label>
                            <input
                              [(ngModel)]="
                                material.name_internal_local.name_internal_en_CA
                              "
                              autocomplete="nope"
                              formControlName="name_internal_en_CA"
                              matInput
                              required
                              type="text"
                            />
                            <mat-error
                              *ngIf="
                                formEnTab.controls['name_internal_en_CA']
                                  .invalid
                              "
                            >
                              {{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <!-- MATERIAL EXTERNAL NAME EN -->

                    <!-- VIEW MODE -->
                    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                      <div class="title">
                        000{{ "GENERIC.EXTERNAL_NAME" | translate }}
                      </div>
                      <div class="info text-truncate">
                        {{ material.name_local.name_en_CA }}
                      </div>
                      <div
                        *ngIf="
                          !material.name_local ||
                          !material.name_local.name_en_CA
                        "
                        class="info empty-value"
                      ></div>
                    </div>
                    <div *ngIf="editMode" class="info-line" fxFlex="100%">
                      <div class="info-editable">
                        <div [formGroup]="formEnTab" class="">
                          <mat-form-field
                            class="form-input-container"
                            fxFlex="100%"
                          >
                            <mat-label>{{
                              "GENERIC.NAME" | translate
                            }}</mat-label>
                            <input
                              matInput
                              formControlName="name_en_CA"
                              type="text"
                              autocomplete="nope"
                              [(ngModel)]="material.name_local.name_en_CA"
                              required
                            />
                            <mat-error
                              *ngIf="formEnTab.controls['name_en_CA'].invalid"
                              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="mt-8 mb-8"
                    fxLayout="row"
                    fxLayout.xs="column"
                    fxLayoutAlign="start start"
                  >
                    <div
                      class="info-line"
                      fxFlex="100%"
                      [formGroup]="formEnTab"
                    >
                      <div class="title">
                        {{ "MATERIALS.DESCRIPTION" | translate }}
                      </div>
                      <p-editor
                        #descriptionEnEditor
                        formControlName="description_en_CA"
                        [(ngModel)]="
                          material.description_local.description_en_CA
                        "
                        [readonly]="!editMode"
                        [style]="{
                          height: '120px',
                          width: '100%',
                          'background-color': editMode ? '#ffffff' : '#EDEDED',
                        }"
                      >
                        <ng-template pTemplate="header" class="">
                          <!-- <span class="ql-formats">
                                                    <select class="ql-font"></select>
                                                    <select class="ql-size"></select>
                                                </span> -->

                          <span class="ql-formats">
                            <button class="ql-bold"></button>
                            <button class="ql-italic"></button>
                            <button class="ql-underline"></button>
                            <button class="ql-strike"></button>
                          </span>

                          <!-- <span class="ql-formats">
                                                    <select class="ql-color"></select>
                                                    <select class="ql-background"></select>
                                                </span> -->

                          <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                            <button class="ql-indent" value="-1"></button>
                            <button class="ql-indent" value="+1"></button>
                          </span>
                        </ng-template>
                      </p-editor>
                      <mat-error
                        *ngIf="formEnTab.controls['description_en_CA'].invalid"
                        class="p-4 pr-8"
                        fxLayout="row"
                        fxLayoutAlign="end center"
                      >
                        {{
                          "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate
                        }}
                      </mat-error>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!--CHILDS-->
        <div
          *ngIf="material && material.childs"
          class="profile-box info-box"
          fxLayout="column"
        >
          <header
            class="accent"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div class="title">{{ "MATERIALS.COMBO" | translate }}</div>
            <div
              class="gt2-light-green"
              style="width: 60px; height: 56px"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <button
                type="button"
                matTooltip="{{ 'MATERIALS.CHANGE_COMBO_ORDER' | translate }}"
                mat-icon-button
                class=""
                [disabled]="!editable"
                (click)="onEditCombo()"
              >
                <mat-icon class="" style="color: black">add</mat-icon>
              </button>
            </div>
          </header>
          <div
            class=""
            style="max-height: 340px"
            fxLayout="column"
            fusePerfectScrollbar
          >
            <div>
              <!--<div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="editMode">-->
              <!--<div class="pl-8 mat-caption">{{ 'MATERIALS.CHANGE_COMBO_ORDER' | translate }}</div>-->
              <!--<button *ngIf="editMode"-->
              <!--class="mr-12"-->
              <!--type="button"-->
              <!--mat-icon-button-->
              <!--(click)="onEditCombo()">-->
              <!--<mat-icon color="accent">add</mat-icon>-->
              <!--</button>-->
              <!--</div>-->

              <!-- HACK! display: none-->
              <!-- <ngx-dnd-container
                [(ngModel)]="['Item 1', 'Item 2', 'Item 3']"
                style="display: none"
              >
              </ngx-dnd-container> -->

              <div
                *ngIf="editMode"
                class=""
                ngxDroppable
                cdkDropList
                (cdkDropListDropped)="onMaterialChildDrop($event)"
              >
                @for (item of material.childs; track item; let i = $index) {
                  <div
                    class="material-child-item"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    matRipple
                    ngxDraggable
                    [cdkDragData]="item"
                    cdkDrag
                    (click)="onNavigateToMaterial(item)"
                  >
                    <mat-icon cdkDragHandle class="icon-mat mr-8"
                      >drag_handle</mat-icon
                    >
                    <div
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      fxFlex="100%"
                    >
                      <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        matTooltip="{{ item.name }}"
                      >
                        <mat-chip-listbox>
                          <mat-chip>{{ item.qty }}</mat-chip>
                        </mat-chip-listbox>
                        &nbsp; &nbsp;{{ item.name_internal }}
                      </div>
                      <div fxLayout="column" fxLayoutAlign="end end">
                        <mat-checkbox
                          labelPosition="before"
                          (click)="$event.stopPropagation()"
                          (ngModelChange)="
                            onComboItemInternalViewChange($event, item)
                          "
                          [ngModel]="item.internal_view_only"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <span class="icon-mat-dark">
                            {{ "MATERIALS.INTERNAL_VIEW_ONLY" | translate }}
                          </span>
                        </mat-checkbox>
                        <mat-checkbox
                          labelPosition="before"
                          (click)="$event.stopPropagation()"
                          (ngModelChange)="
                            onComboItemInternalViewChange($event, item)
                          "
                          [ngModel]="item.show_unit_price"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <span class="icon-mat-dark">
                            {{ "MATERIALS.SHOW_UNIT_PRICE" | translate }}
                          </span>
                        </mat-checkbox>
                      </div>
                    </div>
                    <!--<span matBadge="4" matBadgeOverlap="false">Text with a badge</span>-->
                  </div>
                }
              </div>

              <div *ngIf="!editMode" class="">
                <div
                  class="material-child-item-no-cursor"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  matRipple
                  *ngFor="let item of material.childs; let i = index"
                  (click)="onNavigateToMaterial(item)"
                >
                  <!--<mat-icon class="mr-8">drag_handle</mat-icon>-->
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    matTooltip="{{ item.name }}"
                  >
                    <mat-icon class="icon-mat mr-8">link</mat-icon>
                    <mat-chip-listbox>
                      <mat-chip>{{ item.qty }}</mat-chip>
                    </mat-chip-listbox>
                    &nbsp;
                    <div class="ml-4">{{ item.name_internal }}</div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="end center">
                    <!--                                        <div fxFlex="62px" fxLayoutAlign="end center">
                                            <button mat-button matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                                                    (click)="onNavigateToNewTabMaterial(item)" class="mat-icon-button">
                                                <mat-icon class="s-20">{{ getIcon('NEW_TAB') }}</mat-icon>
                                            </button>
                                        </div>-->
                    <div fxLayout="column" fxLayoutAlign="end end">
                      <mat-checkbox
                        labelPosition="before"
                        disabled
                        (ngModelChange)="
                          onComboItemInternalViewChange($event, item)
                        "
                        [ngModel]="item.internal_view_only"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <span class="icon-mat-dark">
                          {{ "MATERIALS.INTERNAL_VIEW_ONLY" | translate }}
                        </span>
                      </mat-checkbox>
                      <mat-checkbox
                        labelPosition="before"
                        disabled
                        (ngModelChange)="
                          onComboItemInternalViewChange($event, item)
                        "
                        [ngModel]="item.show_unit_price"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <span class="icon-mat-dark">
                          {{ "MATERIALS.SHOW_UNIT_PRICE" | translate }}
                        </span>
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--NOTE-->
        <div
          *ngIf="material"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "GENERIC.NOTES" | translate }}</div>
          </header>

          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
          >
            <mat-tab label="{{ 'GENERIC.GENERAL' | translate }}">
              <div class="note-section">
                <p-editor
                  #noteEditor
                  [(ngModel)]="material.note"
                  [readonly]="!editMode"
                  [style]="{
                    height: '120px',
                    width: '100%',
                    'background-color': editMode ? '#ffffff' : '',
                  }"
                >
                  <ng-template pTemplate="header" class="">
                    <span class="ql-formats">
                      <select class="ql-font"></select>
                      <select class="ql-size"></select>
                    </span>

                    <span class="ql-formats">
                      <button class="ql-bold"></button>
                      <button class="ql-italic"></button>
                      <button class="ql-underline"></button>
                      <button class="ql-strike"></button>
                    </span>

                    <span class="ql-formats">
                      <select class="ql-color"></select>
                      <select class="ql-background"></select>
                    </span>

                    <span class="ql-formats">
                      <button class="ql-list" value="ordered"></button>
                      <button class="ql-list" value="bullet"></button>
                      <button class="ql-indent" value="-1"></button>
                      <button class="ql-indent" value="+1"></button>
                    </span>
                  </ng-template>
                </p-editor>

                <mat-error
                  *ngIf="noteInvalid.note"
                  fxLayout="row"
                  fxLayoutAlign="end center"
                  class="p-4 pr-8"
                >
                  {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
                </mat-error>
              </div>
            </mat-tab>

            <mat-tab label="{{ 'GENERIC.SALE' | translate }}">
              <ng-template matTabContent>
                <div class="note-section">
                  <p-editor
                    #noteSaleEditor
                    [(ngModel)]="material.sale_note"
                    [readonly]="!editMode"
                    [style]="{
                      height: '120px',
                      width: '100%',
                      'background-color': editMode ? '#ffffff' : '',
                    }"
                  >
                    <ng-template pTemplate="header" class="">
                      <span class="ql-formats">
                        <select class="ql-font"></select>
                        <select class="ql-size"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <button class="ql-strike"></button>
                      </span>

                      <span class="ql-formats">
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-indent" value="-1"></button>
                        <button class="ql-indent" value="+1"></button>
                      </span>
                    </ng-template>
                  </p-editor>
                  <mat-error
                    *ngIf="noteInvalid.sale_note"
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="p-4 pr-8"
                  >
                    {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
                  </mat-error>
                </div>
              </ng-template>
            </mat-tab>

            <mat-tab label="{{ 'MATERIALS.LOGISTIC' | translate }}">
              <ng-template matTabContent>
                <div class="note-section">
                  <p-editor
                    #noteLogisticEditor
                    [(ngModel)]="material.logistic_notes"
                    [readonly]="!editMode"
                    [style]="{
                      height: '120px',
                      width: '100%',
                      'background-color': editMode ? '#ffffff' : '',
                    }"
                  >
                    <ng-template pTemplate="header" class="">
                      <span class="ql-formats">
                        <select class="ql-font"></select>
                        <select class="ql-size"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <button class="ql-strike"></button>
                      </span>

                      <span class="ql-formats">
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
                      </span>

                      <span class="ql-formats">
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-indent" value="-1"></button>
                        <button class="ql-indent" value="+1"></button>
                      </span>
                    </ng-template>
                  </p-editor>
                  <mat-error
                    *ngIf="noteInvalid.logistic_notes"
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="p-4 pr-8"
                  >
                    {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
                  </mat-error>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>

        <app-comment-section
          *ngIf="material"
          [disabled]="!editable"
          [commentsModel]="material"
          [serviceAPIUrl]="'/v1/materials/comments/' + material.uuid"
        >
        </app-comment-section>

        <app-created-updated-at
          *ngIf="material"
          [historicModel]="material.historic_model"
        ></app-created-updated-at>

        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <div
            *ngIf="material && editable"
            fxFlex="100%"
            fxLayout="row"
            fxLayoutAlign="center center"
            class="mt-8"
          >
            <button mat-raised-button (click)="deleteMaterial()" class="warn">
              {{ "GENERIC.DELETE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="material && editable" fxLayout="row" fxLayoutAlign="end start">
  <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
    <div class="fixed-button">
      <!-- <button mat-fab
                    class="gt2-edit-button"
                    id="edit-material-button"
                    (click)="onEdit($event)"
                    [disabled]="loading"
                    *fuseIfOnDom [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
                <mat-icon *ngIf="!editMode">{{ getIcon('EDIT_FAB') }}</mat-icon>
                <mat-icon *ngIf="editMode">{{ getIcon('UNDO') }}</mat-icon>
            </button> -->
      <button
        mat-fab
        #saveButton
        [ngClass]="editMode ? 'gt2-save-button' : 'gt2-edit-button'"
        id="edit-material-button"
        (click)="onEdit($event)"
        [disabled]="
          loading || (editMode && !form.valid) || (editMode && !formEnTabValid)
        "
        *fuseIfOnDom
        [@animate]="{
          value: '*',
          params: { delay: '300ms', scale: '.2' },
        }"
      >
        <mat-icon class="icon-mat-black" *ngIf="!editMode && !loading">{{
          getIcon("EDIT_FAB")
        }}</mat-icon>
        <mat-icon
          *ngIf="editMode && !loading && !saveButton.disabled"
          matTooltip="{{ 'GENERIC.SAVE' | translate }}"
          position="left"
          showDelay="900"
          hideDelay="900"
          class="icon-mat-black"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <mat-icon
          *ngIf="editMode && !loading && saveButton.disabled"
          matTooltip="{{ 'GENERIC.FORM_DIRTY_TIP' | translate }}"
          position="left"
          hideDelay="900"
          class="icon-mat-black"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <i [ngClass]="loading ? 'fa fa-spinner fa-spin' : ''"></i>
      </button>
    </div>
    <div class="fixed-button2">
      <button
        mat-fab
        *ngIf="editMode"
        id="cancel-edit-material-button"
        class="gt2-cancel-edit-button"
        matTooltip="{{ 'GENERIC.CANCEL' | translate }}"
        position="left"
        showDelay="900"
        hideDelay="900"
        (click)="onCancelEdit($event)"
        [disabled]="loading"
        [@animate]="{
          value: '*',
          params: { delay: '120ms', scale: '.2' },
        }"
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("CANCEL") }}</mat-icon>
      </button>
    </div>
  </div>
</div>
