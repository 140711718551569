import { ApiRoutes } from "../../../../api/ApiRoutes";
import { Gt2ApiService } from "./../../../../api/gt2-api.service";
import { NGXLogger } from "ngx-logger";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JobExpensesService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public addJobExpense(expenseInfo: any): any {
    //jobUUID: string,
    return this.http.post<any>(this.api.createUrl(ApiRoutes.JOB_EXPENSES), expenseInfo);
  }

  public editJobExpense(expenseUUID: string, expenseInfo: any): any {
    //jobUUID: string,
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.JOB_EXPENSES) + "/" + expenseUUID, expenseInfo);
  }

  public deletedJobExpense(expenseUUID: string): any {
    //jobUUID: string,
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.JOB_EXPENSES) + "/" + expenseUUID);
  }
}
