<div style="max-height: 100%">
  <h1 matDialogTitle class="h1-title">{{ dialogTitle }}</h1>

  <div
    [formGroup]="form"
    (keyup.enter)="
      newCategory_fr_CA && newCategory_en_CA ? onCreateNew() : null
    "
    class="p-8"
  >
    <div fxLayout="column">
      <div class="mat-body-2 mt-4">Français</div>
      <mat-form-field fxFlex="100" class="" floatLabel="auto">
        <textarea
          matInput
          [(ngModel)]="newCategory_fr_CA"
          formControlName="newCategory_fr_CA"
          placeholder=""
          mat-maxlength="250"
          max-rows="4"
        >
        </textarea>
      </mat-form-field>
    </div>

    <div fxLayout="column" class="mt-4">
      <div class="mat-body-2 mt-4">English</div>

      <mat-form-field fxFlex="100" class="" floatLabel="auto">
        <textarea
          matInput
          [(ngModel)]="newCategory_en_CA"
          formControlName="newCategory_en_CA"
          placeholder=""
          mat-maxlength="250"
          max-rows="4"
        >
        </textarea>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" class="gt2-buttons-theme">
    <button
      mat-raised-button
      color="accent"
      (click)="onCreateNew()"
      [disabled]="!newCategory_fr_CA || !newCategory_en_CA"
      class="mr-16"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
