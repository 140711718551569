import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-themes-settings',
  templateUrl: './themes-settings.component.html',
  styleUrls: ['./themes-settings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatSlideToggleModule,
  ],
})
export class ThemesSettingsComponent implements OnInit {
  @Input() shop: any;
  @Input() editMode: boolean = false;
  @Input() defaultTheme?: string;
  @Input() isThemeDark?: boolean;
  @Output() shopChange: EventEmitter<any> = new EventEmitter();
  @Output() defaultThemeChange: EventEmitter<any> = new EventEmitter();
  @Output() isThemeDarkChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public onThemeChange(event: any, theme: any): void {
    // this.themeService.setThemeByID(event.value);
    // this.localStorageShopService.setItem(LocalStorageShopService.SHOP_THEME_LS, event.value, false);
    // this.updateUser();
    if (event.isUserInput) {
      // this.logger.info("ShopWebComponent.onThemeChange() -> theme: " + JSON.stringify(theme));
      // this.logger.info("ShopWebComponent.onThemeChange() -> event: " + event);
      // this.logger.info("ShopWebComponent.onThemeChange() -> event.id: " + event.id);
      this.shop.default_theme = theme;
    }
  }

  public onThemeDarkChange(event: MatSlideToggleChange): void {
    // this.logger.info('ShopWebComponent.onThemeDarkChange() -> ' + event.checked);
    // this.logger.info('ShopWebComponent.onThemeDarkChange() -> 1: ' + this.shop.theme_mode);
    this.isThemeDark = event.checked;
    this.isThemeDarkChange.emit();
    this.shop.theme_mode = event.checked ? 'dark-theme' : 'light-theme';
    // this.logger.info('ShopWebComponent.onThemeDarkChange() -> 2: ' + this.shop.theme_mode);
  }

  public onSaveInfo(): void {
    this.shopChange.emit();
  }
}
