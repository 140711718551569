import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ApiRoutes } from '../../api/ApiRoutes';
import { Gt2ApiService } from '../../api/gt2-api.service';

@Injectable()
export class SectorsService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getSectors(): any {
    return this.http.get<any>(this.api.createUrl(ApiRoutes.SECTORS));
  }
}

export interface SectorModel {
  object?: string | null;
  uuid?: string | null;
  name?: string | null;
  slug?: string | null;
  price_label?: string | null;
  price?: number | null;
}
