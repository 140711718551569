import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RouterModule } from '@angular/router';
import { Gt2PrimengModule } from './../../modules/gt2-primeng.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardDetailedComponent } from './dashboard-detailed.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { CreateCongressesButtonComponent } from '../../components/create-congresses-button/create-congresses-button.component';
import { CreateOrderButtonsComponent } from '../../components/create-order-buttons/create-order-buttons.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.EN.DASHBOARD_DETAILED,
    component: DashboardDetailedComponent,
  },
  {
    path: AppRoutesName.FR.DASHBOARD_DETAILED,
    component: DashboardDetailedComponent,
  },
];

@NgModule({
  declarations: [DashboardDetailedComponent],
  imports: [
    MatIconModule,
    NgxSpinnerModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    NgxPermissionsModule,
    MatButtonModule,
    TranslateModule,
    MatSortModule,
    GenericAlertComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    NgxMaterialTimepickerModule,
    CreateCongressesButtonComponent,
    CreateOrderButtonsComponent,
  ],
})
export class DashboardDetailedModule {}
