import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { fromEvent, BehaviorSubject, Subscription } from 'rxjs';
import { NgPipesModule } from 'ngx-pipes';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { ProductModel, ProductsService } from '../../api/products.service';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { CreateProductComponent } from '../dialogs/create-product/create-product.component';
import { locale as english } from '../create-combo/i18n/en-CA';
import { locale as french } from '../create-combo/i18n/fr-CA';
//import * as _ from 'lodash';
import { OrdersService } from '../../api/orders.service';
import { ModulesService } from '../../services/modules.service';
import { GenericAlertComponent } from '../generic-alert/generic-alert.component';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-edit-combo',
  templateUrl: './edit-combo.component.html',
  styleUrls: ['./edit-combo.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    NgxSpinnerModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    Gt2PrimengModule,
    MatTooltipModule,
    MatBadgeModule,
    MatIconModule,
    TranslateModule,
    NgPipesModule,
  ],
  animations: fuseAnimations,
  providers: [NgPipesModule, ProductsService],
})
export class EditComboComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  product!: ProductModel;
  products?: ProductModel[];
  combo: any[] = [];
  comboCopy?: any[];
  isLoading: boolean = false;
  saving: boolean = false;
  isEmpty: boolean = true;
  creationMode: boolean = false;
  applyCall: boolean = true;
  @ViewChild('filter') filter?: ElementRef;
  @ViewChild('productList') productList!: ElementRef;
  @ViewChild('comboList') comboList?: ElementRef;
  @ViewChild('box') box?: Input;
  filterValue: string = '';
  newDialogRef: any;
  fromEventSubscription?: Subscription;
  disableBtn = false;
  productChildDropProcess: boolean = false;
  @Output() productChildDropProcessChange = new EventEmitter<any>();
  // orderUUDID: string;
  onMultiplayComboItemPriceChanged: BehaviorSubject<ProductModel> | any =
    new BehaviorSubject(null);
  confirmDialogRef: any;
  constructor(
    public override dialogRef: MatDialogRef<EditComboComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public productsService: ProductsService,
    private dialog: MatDialog,
    private logger: NGXLogger,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ordersService: OrdersService,
    public moduleService: ModulesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french); // we use the same locale files from CreateCombo component
    if (data.product) {
      this.product = data.product;
    }
    if (data.products) {
      this.combo = data.products;
      this.comboCopy = JSON.parse(JSON.stringify(this.combo));
    }
    if (data.creationMode) {
      this.creationMode = data.creationMode;
    }
  }

  ngOnInit() {
    //this.logger.info("EditComboComponent.ngOnInit() -> product: " + JSON.stringify(this.product));
  }

  ngAfterViewInit() {
    this.fromEventSubscription = fromEvent(this.filter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          // //this.logger.info("EditComboComponent.onFilterChange() -> " + this.productList);
          // this.productList.nativeElement.scrollTop = 0;
          if (this.filterValue.length >= 3) {
            this.loadProductList();
          } else {
            this.products = [];
            this.isEmpty = true;
          }
          /*
                    if (this.filterValue.length >= 3 || this.filterValue === "")
                    {
                        this.loadProductList();
                    }
*/
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {}

  getComboRealTotal(): number {
    return this.combo.length;
  }

  public loadProductList(): void {
    //this.logger.info("Edit combo component::loadProductList => Le produit est un  " + (this.product.is_choice_product_combo ? "combo dynamique" : "combo normal"));
    if (this.product && this.product.is_choice_product_combo) {
      this.searchProducts();
    } else {
      this.searchProductsNoCombo();
    }
  }

  public searchProducts(): void {
    this.isEmpty = false;
    this.isLoading = true;
    this.productsService.getProducts(this.filterValue).subscribe(
      (response: any) => {
        // //this.logger.info("EditComboComponent.getProductsList() -> SUCCESS>>: " + JSON.stringify(response));
        this.isLoading = false;
        if (response.data.length > 0) {
          this.products = response.data;
          this.isEmpty = false;
          this.productList.nativeElement.scrollTop = 0;
        } else {
          this.isEmpty = true;
          this.products = [];
        }
      },
      (error: any) => {
        this.logger.error(
          'EditComboComponent.getProductsList() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoading = false;
        this.isEmpty = true;
      },
    );
  }

  public searchProductsNoCombo(): void {
    this.isEmpty = false;
    this.isLoading = true;
    this.productsService
      .getProductsListWithoutCombo(this.filterValue)
      .subscribe(
        (response: any) => {
          // //this.logger.info("EditComboComponent.getProductsListWithoutCombo() -> SUCCESS>>: " + JSON.stringify(response));
          this.isLoading = false;
          if (response.data.length > 0) {
            this.products = response.data;
            this.isEmpty = false;
            this.productList.nativeElement.scrollTop = 0;
          } else {
            this.isEmpty = true;
            this.products = [];
          }
        },
        (error: any) => {
          this.logger.error(
            'EditComboComponent.getProductsListWithoutCombo() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
          this.isLoading = false;
          this.isEmpty = true;
        },
      );
  }

  public saveData(): void {
    // //this.logger.info("EditComboComponent.saveData() -> this.combo: " + JSON.stringify(this.combo));
    // //this.logger.info("EditComboComponent.saveData() -> this.comboCopy: " + JSON.stringify(this.comboCopy));
    //this.logger.info("EditComboComponent.saveData() -> ARE EQUAL: " + _.isEqual(this.combo, this.comboCopy));

    // TODO: 3. apply same changes in other similar pop up
    // if (!_.isEqual(this.combo, this.comboCopy)) {
    //   // //this.logger.info("EditComboComponent.saveData() -> IS NOT EQUAL, UPDATING THE DATABASE ");
    //   const children: any[] = [];
    //   for (let i = 0; i < this.combo.length; ++i) {
    //     children.push({
    //       uuid: this.combo[i].uuid,
    //       qty: this.combo[i].qty,
    //     });
    //   }

    //   // //this.logger.info("EditComboComponent.saveData() -> TO UPDATE: " + JSON.stringify(children));
    //   this.saving = true;
    //   this.disableBtn = true;
    //   this.productsService
    //     .productComboQtyUpdate(this.product.uuid, children)
    //     .subscribe(
    //       (response: any) => {
    //         // //this.logger.info("EditComboComponent.saveData() UPDATE -> SUCCESS: " + JSON.stringify(response));
    //         this.disableBtn = false;
    //         this.saving = false;
    //         this.dialogRef.close(true);
    //       },
    //       (error: any) => {
    //         this.disableBtn = false;
    //         this.saving = false;
    //         this.logger.error(
    //           'EditComboComponent.saveData() UPDATE -> ERROR: ' +
    //             JSON.stringify(error)
    //         );
    //         this.handleAPIError(error, this.dialog, null, null);
    //       }
    //     );
    // } else {
    //this.logger.info("EditComboComponent.saveData() -> IS EQUAL, CLOSING WINDOW");
    this.dialogRef.close(true);
    //}
  }

  public onFilterChange() {
    // //this.logger.info("EditComboComponent.onFilterChange()");
    this.productList.nativeElement.scrollTop = 0;
  }

  public onDrop(event: any) {
    // //this.logger.info(event);
    // //this.logger.info(event.source);
    // //this.logger.info(typeof event.source);
    // //this.logger.info(event.source.className.indexOf("combo-list"));
    // //this.logger.info(isUndefined(event.value));
    // //this.logger.info("onDrop()");

    if (event.value == undefined) {
      return;
    }

    if (event.source.className.indexOf('combo-list') !== -1) {
      this.onProductChildDrop(event);
      return;
    }

    // //this.logger.info("EditComboComponent.onDrop() -> item: " + event.value);
    event.value.qty = 1;

    // event.value.qty = 1;
    const items: any[] = this.combo.filter((item) => {
      return item.uuid === event.value.uuid;
    });
    // //this.logger.info("EditComboComponent.onDrop( ) -> items: " + items.length);

    // If this product was already in the list, we add to qty
    if (items && items.length > 1) {
      // remove all except event.value & set qty to items.length
      let previousQuantity: number = 1;
      for (let i = 0; i < this.combo.length; ++i) {
        if (
          this.combo[i].uuid === event.value.uuid &&
          this.combo[i] !== event.value
        ) {
          previousQuantity = this.combo[i].qty;
          this.combo.splice(i, 1);
        }
      }
      event.value.qty += previousQuantity;
      //
      if (this.applyCall) {
        this.productsService
          .productComboUpdate(
            this.product.uuid,
            event.value.uuid,
            event.value.qty,
          )
          .subscribe(
            (response) => {
              // //this.logger.info("EditComboComponent.onDrop() UPDATE -> SUCCESS: " + JSON.stringify(response));
            },
            (error) => {
              this.logger.error(
                'EditComboComponent.onDrop() UPDATE -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
            },
          );
      }
    } else if (this.applyCall) {
      this.addNewProductToComboList(event.value);
    }
  }

  public addNewProductToComboList(item: any): void {
    this.productChildDropProcess = true;
    this.spinner.show('comboSpinner');
    this.productsService
      .productComboAttach(this.product.uuid, item.uuid, item.qty)
      .subscribe(
        (response) => {
          // //this.logger.info(
          //     "EditComboComponent.addNewProductToComboList() ATTACH -> SUCCESS"
          // );
          // //this.logger.info(
          //     "EditComboComponent.onDrop() ATTACH -> SUCCESS: " +
          //         JSON.stringify(response)
          // );
          // //this.logger.info(
          //     "EditComboComponent.onDrop() ATTACH -> SUCCESS: " +
          //         JSON.stringify(response.data.childs)
          // );
          this.onProductChildDrop({ value: item }, response.new_product);
          this.combo = response.product.childs;
        },
        (error) => {
          this.logger.error(
            'EditComboComponent.onDrop() ATTACH -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public onProductChildDrop(event: any, newProduct?: any): void {
    // //this.logger.info(
    //     "ProductComponent.onProductChildDrop() -> " +
    //         JSON.stringify(event.value)
    // );
    // //this.logger.info("EditComboComponent.onProductChildDrop() -> " + JSON.stringify(this.product.childs));
    if (this.combo.length < 2) {
      this.productChildDropProcess = false;
      this.spinner.hide('comboSpinner');
      return;
    }

    let i = 0;
    const length: number = this.combo.length;
    for (i; i < length; ++i) {
      if (this.combo[i].uuid === event.value.uuid) {
        const productUUID = newProduct ? newProduct.uuid : event.value.uuid;
        //this.logger.info("productUUID: " + productUUID);
        // move before
        this.productChildDropProcess = true;
        this.spinner.show('comboSpinner');
        if (i === 0) {
          // //this.logger.info("Combo UUID: " + this.product.uuid);
          // //this.logger.info(
          //     "attached product UUID: " + event.value.uuid
          // );
          // //this.logger.info("beforeUUID: " + this.combo[i + 1].uuid);

          this.productsService
            .productChildMoveBefore(
              this.product.uuid,
              productUUID,
              this.combo[i + 1].uuid,
            )
            .subscribe(
              (response) => {
                // //this.logger.info(
                //     "EditComboComponent.onProductChildDrop() -> SUCCESS (productChildMoveBefore): " +
                //         JSON.stringify(response)
                // );
                this.productChildDropProcess = false;
                this.spinner.hide('comboSpinner');
                this.combo = response.data.childs;
              },
              (error) => {
                this.logger.error(
                  'EditComboComponent.onProductChildDrop() -> ERROR (productChildMoveBefore): ' +
                    JSON.stringify(error),
                );
                this.productChildDropProcess = false;
                this.spinner.hide('comboSpinner');
                this.handleAPIError(error, this.dialog, null, null);
              },
            );
        } // move after
        else {
          // //this.logger.info("Combo UUID: " + this.product.uuid);
          // //this.logger.info("productUUID: " + event.value.uuid);
          // //this.logger.info("afterUUID: " + this.combo[i - 1].uuid);
          // //this.logger.info(
          //     "attached product UUID (2): " + event.value.uuid
          // );
          this.productsService
            .productChildMoveAfter(
              this.product.uuid,
              productUUID,
              this.combo[i - 1].uuid,
            )
            .subscribe(
              (response) => {
                // //this.logger.info("EditComboComponent.onProductChildDrop() -> SUCCESS (productChildMoveBefore): " + JSON.stringify(response));
                this.productChildDropProcess = false;
                this.spinner.hide('comboSpinner');
                this.combo = response.data.childs;
              },
              (error) => {
                this.logger.error(
                  'EditComboComponent.onProductChildDrop() -> ERROR (productChildMoveBefore): ' +
                    JSON.stringify(error),
                );
                this.productChildDropProcess = false;
                this.spinner.hide('comboSpinner');
                this.handleAPIError(error, this.dialog, null, null);
              },
            );
        }
        break;
      }
    }
  }

  public onRemoveItem(item: any, index: number): void {
    // //this.logger.info("EditComboComponent.onRemoveItem() -> index: " + index);
    this.productChildDropProcess = true;
    this.spinner.show('comboSpinner');
    item.qty = 0;
    for (let i = 0; i < this.combo.length; ++i) {
      if (this.combo[i] === item) {
        this.combo.splice(i, 1);
        this.productsService
          .productComboDetach(this.product.uuid, item.uuid, item.qty)
          .subscribe(
            (response) => {
              // //this.logger.info("EditComboComponent.onRemoveItem() Detach -> SUCCESS: " + JSON.stringify(response));
              this.productChildDropProcess = false;
              this.spinner.hide('comboSpinner');
            },
            (error) => {
              this.logger.error(
                'EditComboComponent.onRemoveItem() Detach -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
              // in case of error, add the item back
              this.combo.splice(i, 0, item);
              this.productChildDropProcess = false;
              this.spinner.hide('comboSpinner');
            },
          );
        break;
      }
    }
  }

  public onAddItem(item: any, doAddItemInModel: boolean = false): void {
    const items: any[] = this.combo.filter((filterItem) => {
      // //this.logger.info(filterItem.uuid === item.uuid);
      return filterItem.uuid === item.uuid;
    });

    // item.qty = (items.length === 0) ? 1 : item.qty++;
    //this.logger.info("onAddItem: " + items.length);
    if (items.length === 0) {
      item.qty = 1;
      this.combo.push(item);
      this.addNewProductToComboList(item);
    } else {
      items[0].qty++;
    }
  }

  public onBlur(): void {
    //this.logger.info("EditComboComponent.onBlur()");
    this.disableBtn = true;
  }
  public onKey(value: string): void {
    //this.logger.info("EditComboComponent.onKey()");
    this.disableBtn = true;
  }

  public onItemQtyChange(item: any, i: number): void {
    //this.logger.info("EditComboComponent.onItemQtyChange() -> item.qty: " + item.qty);
  }

  public onLessItem(item: any, i: number): void {
    if (item.qty - 1 === 0) {
      // this.onRemoveItem(item, i);
    } else {
      item.qty -= 1;
    }
  }

  public onRemove(event: any): void {
    // //this.logger.info("EditComboComponent.onRemove() -> combo: " + this.combo.length);

    if (event.value.qty > 1) {
      event.value.qty -= 1;
      this.combo.push(event.value);
    }
  }

  public clearSearch(): void {
    this.filterValue = '';
    this.products = [];
    this.isEmpty = true;
  }

  public onProductComboMultiply($event: any): void {
    // this.ordersService.updateProducts(this.orderUUDID, [this.product])

    //this.logger.info("EditComboComponent.onProductComboMultiply() -> : " + this.product.multiply_combo_item_price);
    this.productsService
      .updateProduct(this.product, this.product.uuid)
      .subscribe(
        (response: any) => {
          //this.logger.info("EditComboComponent.onProductComboMultiply() -> SUCCESS: " + JSON.stringify(response));
          // this.product = response.data;
          this.onMultiplayComboItemPriceChanged.next(response.data);
        },
        (error: any) => {
          // this.loading = false;
          // //this.logger.info("EditComboComponent.onProductComboMultiply() -> ERROR: " + JSON.stringify(error));
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public onCreateProduct(): void {
    // //this.logger.info("EditComboComponent.onCreateProduct()");
    this.newDialogRef = this.dialog.open(CreateProductComponent, {
      width: '340px',
      disableClose: false,
      data: {
        autoRedirect: false,
      },
    });

    this.newDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // //this.logger.info("CreateMaterialComboComponent.onCreateMaterial() -> SUCCESS: " + JSON.stringify(result));

        result.qty = 1;
        this.combo.push(result);

        this.productsService
          .productComboAttach(this.product.uuid, result.uuid, result.qty)
          .subscribe(
            (response) => {
              // //this.logger.info("EditComboComponent.onCreateProduct() ATTACH -> SUCCESS: " + JSON.stringify(response));
            },
            (error) => {
              this.logger.error(
                'EditComboComponent.onCreateProduct() ATTACH -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
              for (let i = 0; i < this.combo.length; ++i) {
                if (this.combo[i].uuid === result.uuid) {
                  this.combo.splice(i, 1);
                  break;
                }
              }
            },
          );
        this.loadProductList();
      }
      this.newDialogRef = null;
    });
  }

  public onOpenInNewTab(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }

  public onShowProductInfo(message: any): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useHTML = true;
    this.confirmDialogRef.componentInstance.useCancel = false;
    //this.confirmDialogRef.componentInstance.alertTitle =
    // this.translate.instant("REPORTS_HELP.TITLE");
    this.confirmDialogRef.componentInstance.alertMessage = message;

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {});
  }
}
