import { AddressService } from './../../../components/address/address.service';
import { AddressModel } from './../../../api/models/AddressModel';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { ICanDeactivateComponent } from '../../../gards/can-deactivate-guard.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { ShopsService } from '../../../api/shops.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FileService } from '../../../../services/file.service';
import { MatDialog } from '@angular/material/dialog';
import { PrintService } from '../../../../services/print.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { RolesService } from '../../../services/roles.service';
import { FuseSidebarService } from '../../../../../@fuse/components/sidebar/sidebar.service';
import { Title } from '@angular/platform-browser';
import { ShopTypeService } from '../../../api/shop-type.service';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { Settings } from '../../../settings/settings';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import {
  BusinessDayModel,
  BusinessDaysComponent,
} from '../../../components/business-days/business-days.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-internal-shop',
  templateUrl: './internal-shop.component.html',
  styleUrls: ['./internal-shop.component.scss'],
  animations: fuseAnimations,
})
export class InternalShopComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  @ViewChild('businessDaysComponent')
  businessDaysComponent!: BusinessDaysComponent;

  shop: any;
  shopID!: string;
  loading?: boolean;
  form: UntypedFormGroup;
  editMode = false;
  confirmDialogRef: any;
  editable = true;
  businessHours: any;
  takeOutHours: any;
  businessDays?: BusinessDayModel[];
  shopPreference: any;
  isSmallScreen?: boolean;

  // connectionMessageSuccess: string;
  // connectionTitleSuccess: string;
  // @ViewChild('testConnectionTemplate', null) testConnectionTemplate: TemplateRef<any>;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private shopsService: ShopsService,
    public translate: TranslateService,
    public location: Location,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private fileService: FileService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    public print: PrintService,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    public rolesService: RolesService,
    private _fuseSidebarService: FuseSidebarService,
    private titleService: Title,
    public shopTypeService: ShopTypeService,
    private addressService: AddressService,
    private api: Gt2ApiService,
    public router: Router,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      // TODO: validate url???
      // store_url: ['', [Validators.minLength(Settings.inputMinChar), Validators.required]],
      // consumer_secret: ['', [Validators.minLength(Settings.inputMinChar), Validators.required]],
      // consumer_key: ['', [Validators.minLength(Settings.inputMinChar), Validators.required]],
    });
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
    //this.titleService.setTitle(this.translate.instant("SHOPS.SHOPS"));
    this.route.params.subscribe({
      next: (params: any) => {
        this.shopID = params.id;
        this.loading = true;
        this.shop = null;
        this.loadShop();
      },
    });

    // this.connectionTitleSuccess = this.translate.instant('SHOPS.CONNECTION');
    // this.connectionMessageSuccess = this.translate.instant('SHOPS.CONNECTION_SUCCESS');
  }

  ngAfterViewInit(): void {
    //   if (this.editMode)
    //   {
    //       this.setControlsChanges(this.form);
    //   }
  }

  ngOnDestroy(): void {}

  public canDeactivate(): boolean {
    // //this.logger.info("canDeactivate() -> this.form.dirty: " + this.form.dirty);
    return true;
    // return !this.editMode && !this.form.dirty;
  }

  public loadShop(): void {
    this.loading = true;
    this.shopsService.getShop(this.shopID).subscribe({
      next: (response) => {
        //this.logger.info("InternalShopComponent.loadShop() -> SUCCESS: " + JSON.stringify(response));

        this.shop = response.data;
        this.loading = false;
        // this.editable = false; // !this.shop.isArchive;
        // //this.logger.info("ProductComponent.loadProduct() -> isArchive: " + this.product.isArchive);
        //this.titleService.setTitle(this.translate.instant("SHOPS.SHOPS") + " " + this.shop.name);
        this.loadShopPreference(this.shop.shop_preference_uuid);
        this.loadHours(
          this.shop.business_hours.business_uuid,
          this.shop.business_hours.takeout_uuid,
        );
        this.loadDays(this.shop.uuid);
      },
      error: (error) => {
        this.logger.error(
          'InternalShopComponent.loadShop() -> ERROR: ' + JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onEdit(event: any): void {
    this.editMode = !this.editMode;
  }

  public onSaveInfo(): void {
    this.saveData(this.shop);
  }

  public saveData(data: any): void {
    //this.logger.info("InternalShopComponent.saveData() -> *** data: " + JSON.stringify(data));
    this.loading = true;
    // this.shopsService.updateShopByUUID(data, this.shopID).subscribe(response => {
    //     // //this.logger.info("InternalShopComponent.saveData() -> SUCCESS: " + JSON.stringify(response));
    //     this.loading = false;
    //     this.form.markAsPristine();
    //     this.shop = response.data;
    //     this.form.markAsUntouched();
    // }, error => {
    //     this.logger.error("InternalShopComponent.saveData() -> ERROR: " + JSON.stringify(error));
    //     this.loading = false;
    //     this.handleAPIError(error, this.dialog, null, null);
    // });
  }

  public loadShopPreference(uuid: string): void {
    this.shopsService.getShopPreference(uuid).subscribe({
      next: (response: any) => {
        // //this.logger.info("InternalShopComponent.loadShopPreference() -> response: " + JSON.stringify(response));
        this.shopPreference = response.data;
      },
      error: (error: any) => {
        this.logger.error(
          'InternalShopComponent.loadShopPreference() -> ERROR: ' +
            JSON.stringify(error),
        );
      },
    });
  }

  public onSavePreference(): void {
    this.shopsService.savePreferences(this.shopPreference).subscribe({
      next: (response: any) => {
        //this.logger.info("InternalShopComponent.onSavePreference() -> SUCCESS: " + JSON.stringify(response));
        this.shopPreference = response.data;
      },
      error: (error: any) => {
        this.logger.error(
          'InternalShopComponent.onSavePreference() -> ERROR: ' +
            JSON.stringify(error),
        );
      },
    });
  }

  public loadHours(businessUUID: string, takeOutUUID: string): void {
    this.shopsService.getShopHours(businessUUID, takeOutUUID).subscribe({
      next: (response: any) => {
        //this.logger.info("InternalShopComponent.loadHours() -> response: " + JSON.stringify(response));
        this.businessHours = response[0].data;
        this.takeOutHours = response[1].data;
      },
      error: (error: any) => {
        this.logger.error(
          'InternalShopComponent.loadHours() -> ERROR: ' +
            JSON.stringify(error),
        );
      },
    });
  }

  public loadDays(uuid: string): void {
    this.shopsService.getBusinnesDaysByShopUUID(uuid).subscribe({
      next: (response: any) => {
        //this.logger.info("InternalShopComponent.loadDays() -> response: " + JSON.stringify(response));
        this.businessDays = response.data;
      },
      error: (error: any) => {
        this.logger.error(
          'InternalShopComponent.loadDays() -> ERROR: ' + JSON.stringify(error),
        );
      },
    });
  }

  public onSaveBusinessHours(businessHours: any): void {
    this.shopsService.saveShopHours(businessHours).subscribe({
      next: (response: any) => {
        //this.logger.info("InternalShopComponent.onSaveBusinessHours() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'InternalShopComponent.onSaveBusinessHours() -> ERROR: ' +
            JSON.stringify(error),
        );
      },
    });
  }

  public getBusinessDaySelectedUuid() {
    ////this.logger.info("getBusinessDaySelectedUuid => " + JSON.stringify(this.businessDays));
    let day: any = this.businessDays?.filter((day: any) => {
      let date1: Date = new Date(day.affected_date);
      let date2: Date = new Date(
        this.businessDaysComponent.selectedBusinessDay.affected_date,
      );
      if (date1 < date2) {
        return false;
      } else if (date1 > date2) {
        return false;
      } else {
        return true;
      }
    });
    if (day?.length > 0) {
      this.businessDaysComponent.selectedBusinessDay.uuid = day[0].uuid;
    }
    //this.logger.info("getBusinessDaySelectedUuid.getDay() => " + JSON.stringify(this.businessDaysComponent.selectedBusinessDay));
  }

  public onSaveBusinessDay(): void {
    this.getBusinessDaySelectedUuid();
    if (
      this.businessDaysComponent?.selectedBusinessDay?.open_business_type !=
      'TYPE_OPEN_CUSTOM_HOURS'
    ) {
      this.businessDaysComponent.selectedBusinessDay.open_business_custom_hour_end_at =
        null;
      this.businessDaysComponent.selectedBusinessDay.open_business_custom_hour_start_at =
        null;
    }
    if (
      this.businessDaysComponent?.selectedBusinessDay?.takeout_business_type !=
      'TYPE_OPEN_CUSTOM_HOURS'
    ) {
      this.businessDaysComponent.selectedBusinessDay.takeout_business_custom_hour_start_at =
        null;
      this.businessDaysComponent.selectedBusinessDay.takeout_business_custom_hour_end_at =
        null;
    }
    if (this.businessDaysComponent?.selectedBusinessDay?.uuid) {
      this.shopsService
        .updateShopDays(this.businessDaysComponent.selectedBusinessDay)
        .subscribe({
          next: (response: any) => {
            //this.logger.info("InternalShopComponent.onSaveBusinessDay() updateShopDays -> SUCCESS: " + JSON.stringify(response));
            this.businessDays = response.data;
            this.businessDaysComponent?.refreshCalendar();
          },
          error: (error: any) => {
            this.logger.error(
              'InternalShopComponent.onSaveBusinessDay() -> ERROR: ' +
                JSON.stringify(error),
            );
          },
        });
    } else {
      this.shopsService
        .saveShopDays(
          this.businessDaysComponent?.selectedBusinessDay,
          this.shop.uuid,
        )
        .subscribe({
          next: (response: any) => {
            //this.logger.info("InternalShopComponent.onSaveBusinessDay() saveShopDays -> SUCCESS: " + JSON.stringify(response));
            this.businessDays = response.data;
            this.businessDaysComponent?.refreshCalendar();
          },
          error: (error: any) => {
            this.logger.error(
              'InternalShopComponent.onSaveBusinessDay() -> ERROR: ' +
                JSON.stringify(error),
            );
          },
        });
    }
  }

  public onNewAdress(address: AddressModel): void {
    //this.logger.info("InternalShopComponent.onNewAdress() -> address: " + JSON.stringify(address));
  }

  public onSaveAdress(): void {
    //this.logger.info("InternalShopComponent.onSaveAdress() -> address: " + JSON.stringify(this.shop.address));
    this.addressService
      .updateAddress(this.shop.address, this.shop.address.uuid)
      .subscribe({
        next: (response) => {
          //this.logger.info("InternalShopComponent.onSaveAdress() -> SUCCESS: " + JSON.stringify(response));
        },
        error: (error) => {
          this.logger.error(
            'InternalShopComponent.onSaveAdress() -> ERROR: ' +
              JSON.stringify(error),
          );
        },
      });
  }

  public override autosaveDataChange(data: any): void {
    //   //this.logger.info("InternalShopComponent.autosaveDataChange() -> *** data: " + JSON.stringify(data));
    //   // //this.logger.info("ShopComponent.autosaveDataChange() -> *** this.order: " + JSON.stringify(this.order));
    //   this.loading = true;
    //   this.shopsService.updateShopByUUID(data, this.shopID).subscribe(response => {
    //       //this.logger.info("InternalShopComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
    //       this.loading = false;
    //       this.form.markAsPristine();
    //       this.shop = response.data;
    //       this.form.markAsUntouched();
    //   }, error => {
    //       this.logger.error("ShopWoocommerceComponent.autosaveDataChange() -> ERROR: " + JSON.stringify(error));
    //       this.loading = false;
    //       this.handleAPIError(error, this.dialog, null, null);
    //   });
  }

  public deleteShop(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.shopsService.deleteShop(this.shopID).subscribe({
            next: (response: any) => {
              //this.logger.info("ShopWoocommerceComponent.deleteShop() -> SUCCESS: " + JSON.stringify(response));
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.shop = response.data;
              // this.editable = !this.shop.isArchive;
              // this.router.navigateByUrl("/" + AppRoutes.PRODUCTS);
            },
            error: (error: any) => {
              this.logger.error(
                'ShopWoocommerceComponent.deleteShop() -> ERROR: ' +
                  JSON.stringify(error),
              );
              // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.loading = false;
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
      },
    });
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public openURL(url: string): void {
    window.open(url, '_blank');
  }

  public toggleHistoricSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
