import { DatatableHelperService } from "../../services/datatable-helper.service";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "fileNameExtensionIconPipe",
})
export class FileNameExtensionIconPipe implements PipeTransform {
  constructor(private tableHelper: DatatableHelperService) {}

  transform(filename: string): string {
    const fileEx: string = filename.slice(filename.lastIndexOf("."));
    if (fileEx.toLowerCase() === ".pdf") {
      return "pdf";
    } else if (fileEx.toLowerCase() === ".xlsx") {
      return "xls";
    } else if (fileEx.toLowerCase() === ".xls") {
      return "xls";
    } else if (fileEx.toLowerCase() === ".jpeg") {
      return "image";
    } else if (fileEx.toLowerCase() === ".jpg") {
      return "image";
    } else if (fileEx.toLowerCase() === ".png") {
      return "image";
    } else if (fileEx.toLowerCase() === ".gif") {
      return "image";
    }

    return fileEx.toLowerCase();
  }
}
