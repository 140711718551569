<div style="max-height: 100%; overflow: auto">
  <h1 matDialogTitle class="h1-title">
    {{ "CREATE_PRODUCT.TITLE" | translate }}
  </h1>

  <div
    fxLayout="column"
    fxLayoutAlign="center start"
    [formGroup]="form"
    (keyup.enter)="form.valid && !loading ? onCreateProduct() : null"
    class="mt-8 create-client-width"
  >
    <mat-form-field fxFlex="100%" class="item-full-width">
      <mat-label>{{ "GENERIC.EXTERNAL_NAME" | translate }}</mat-label>
      <input
        matInput
        fxFlex="100%"
        placeholder="{{ 'GENERIC.EXTERNAL_NAME' | translate }}"
        formControlName="name"
        autocomplete="nope"
        type="text"
        [(ngModel)]="product.name"
        required
      />
      <mat-error *ngIf="form.controls['name'].invalid">{{
        "GENERIC.INPUT_LENGTH_INVALID" | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="item-full-width">
      <mat-label>{{ "CREATE_PRODUCT.SELECT_TYPE" | translate }}</mat-label>
      <mat-select
        placeholder="{{ 'CREATE_PRODUCT.SELECT_TYPE' | translate }}"
        [(value)]="product.invoice_category"
        class=""
      >
        <mat-option [value]="'FOOD'">{{
          "CREATE_PRODUCT.FOOD" | translate
        }}</mat-option>
        <mat-option [value]="'DRINK'">{{
          "CREATE_PRODUCT.DRINK" | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!form.valid || loading"
      color="accent"
      class="mr-16"
      (click)="onCreateProduct()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
