import { EventAttachedFilesService } from './../../api/event-attached-files.service';
import { TakeoutRequestService } from './../../api/takeout-request.service';
import { CreateTakeoutRequestComponent } from './../../components/dialogs/create-takeout-request/create-takeout-request.component';
import { EventTakoutRequestComponent } from './event/event-takout-request/event-takout-request.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  FuseSidebarModule,
  FuseWidgetModule,
} from '../../../../@fuse/components';
import { FlatpickrModule } from 'angularx-flatpickr';
import { DeliveryRequestContentService } from '../../api/delivery-request-content.service';
import { DeliveryRequestTypeService } from '../../api/delivery-request-type.service';
import { DeliveryRequestService } from '../../api/delivery-request.service';
import { DeliveryTypeService } from '../../api/delivery-type.service';
import { DepositInService } from '../../api/deposit-in.service';
import { DepositRequestService } from '../../api/deposit-request.service';
import { DocumentsService } from '../../api/documents.service';
import { EventComposeService } from '../../api/event-compose.service';
import { EventTypeService } from '../../api/event-type.service';
import { EventsService } from '../../api/events.service';
import { FlowService } from '../../api/flow.service';
import { GuestsService } from '../../api/guests.service';
import { PaymentModesService } from '../../api/payment-modes.service';
import { VenuesPlaceService } from '../../api/venues-place.service';
import { CreateDeliveryRequestComponent } from '../../components/dialogs/create-delivery-request/create-delivery-request.component';
import { CreateDepositInComponent } from '../../components/dialogs/create-deposit-in/create-deposit-in.component';
import { CreateDepositRequestComponent } from '../../components/dialogs/create-deposit-request/create-deposit-request.component';
import { CreateFlowComponent } from '../../components/dialogs/create-flow/create-flow.component';
import { CreateGuestComponent } from '../../components/dialogs/create-guest/create-guest.component';
import { CreateProposalComponent } from '../../components/dialogs/create-proposal/create-proposal.component';
import { SelectClientComponent } from '../../components/dialogs/select-client/select-client.component';
import { CanDeactivateGuard } from '../../gards/can-deactivate-guard.service';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { DropdownModule } from 'primeng/dropdown';
import { EventClientSectionComponent } from './event/event-clients/event-client-section/event-client-section.component';
import { EventClientsComponent } from './event/event-clients/event-clients.component';
import { EventDeliveryRequestComponent } from './event/event-delivery-request/event-delivery-request.component';
import { EventDepositInComponent } from './event/event-deposit-in/event-deposit-in.component';
import { EventDepositRequestComponent } from './event/event-deposit-request/event-deposit-request.component';
import { EventDocumentsComponent } from './event/event-documents/event-documents.component';
import { EventFlowComponent } from './event/event-flow/event-flow.component';
import { EventGuestComponent } from './event/event-guest/event-guest.component';
import { EventInformationComponent } from './event/event-information/event-information.component';
import { EventInvoicesComponent } from './event/event-invoices/event-invoices.component';
import { EventStatsComponent } from './event/event-stats/event-stats.component';
import { EventVenuesComponent } from './event/event-venues/event-venues.component';
import { SelectEnterpriseRoomComponent } from './event/event-venues/select-enterprise-room/select-enterprise-room.component';
import { SelectPlaceRoomComponent } from './event/event-venues/select-place-room/select-place-room.component';
import { EventComponent } from './event/event.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DeliveryRequestTemplateService } from '../../api/delivery-request-template.service';
import { FileSaverModule } from 'ngx-filesaver';
import { EventProgramsComponent } from './event/event-programs/event-programs.component';
import { ProgramsService } from '../../api/programs.service';
import { CreateProgramComponent } from '../../components/dialogs/create-program/create-program.component';
import { SelectInternalRoomComponent } from '../../components/dialogs/create-program/select-internal-room/select-internal-room.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { Select2Module } from 'ng-select2-component';
import { ChangelogsDialogComponent } from '../../components/dialogs/changelogs-dialog/changelogs-dialog.component';
import { ChangelogsService } from '../../api/changelogs.service';
import { CreateClientWithDetailsComponent } from '../../components/dialogs/create-client-with-details/create-client-with-details.component';
import { CreateEnterpriseWithDetailsComponent } from '../../components/dialogs/create-enterprise-with-details/create-enterprise-with-details.component';
import { CreateClientEnterpriseWithDetailsComponent } from '../../components/dialogs/create-client-enterprise-with-details/create-client-enterprise-with-details.component';
import { EventNoticeOfReceiptComponent } from './event/event-notice-of-receipt/event-notice-of-receipt.component';
import { EventStorageNoticeComponent } from './event/event-storage-notice/event-storage-notice.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { GoogleLinkPipeModule } from '../../modules/google-link.module';
import { CommentSectionComponent } from '../../components/comment-section/comment-section.component';
import { AddressComponent } from '../../components/address/address.component';
import { CreatedUpdatedAtComponent } from '../../components/created-updated-at/created-updated-at.component';
import { Gt2SelectComponent } from '../../components/gt2-select/gt2-select.component';
import { SectionHistoryPanelComponent } from '../../components/section-history-panel/section-history-panel.component';
import { TagsByModelComponent } from '../../components/tags-by-model/tags-by-model.component';
import { MailComposeDialogComponent } from '../compose/compose.component';
import { EventAttachedFilesComponent } from './event/event-attached-files/event-attached-files.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.EN.EVENT_ROUTE,
    component: EventComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.FR.EVENT_ROUTE,
    component: EventComponent,
    canDeactivate: [CanDeactivateGuard],
  },
];

@NgModule({
  declarations: [EventComponent],
  imports: [
    EventFlowComponent,
    SelectPlaceRoomComponent,
    EventVenuesComponent,
    SelectEnterpriseRoomComponent,
    EventInvoicesComponent,
    NgxPermissionsModule,
    ScrollingModule,
    EventTakoutRequestComponent,
    EventNoticeOfReceiptComponent,
    EventStorageNoticeComponent,
    EventStatsComponent,
    EventProgramsComponent,
    EventInformationComponent,
    EventGuestComponent,
    EventDepositInComponent,
    EventDepositRequestComponent,
    EventDocumentsComponent,
    CreateProposalComponent,
    SelectClientComponent,
    EventDeliveryRequestComponent,
    EventClientSectionComponent,
    EventClientsComponent,
    CreateDepositRequestComponent,
    CreateTakeoutRequestComponent,
    MatSelectModule,
    GoogleLinkPipeModule,
    SelectInternalRoomComponent,
    CreateProgramComponent,
    CreateClientWithDetailsComponent,
    CreateDepositInComponent,
    CreateEnterpriseWithDetailsComponent,
    ChangelogsDialogComponent,
    CreateFlowComponent,
    MatDatepickerModule,
    ReactiveFormsModule,
    CreateDeliveryRequestComponent,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatIconModule,
    MatMenuModule,
    CreateClientEnterpriseWithDetailsComponent,
    TranslateModule,
    MatTooltipModule,
    MatButtonModule,
    MatTabsModule,
    CommonModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    FuseSidebarModule,
    SectionHistoryPanelComponent,
    CreatedUpdatedAtComponent,
    FuseWidgetModule,
    CommentSectionComponent,
    DropdownModule,
    TagsByModelComponent,
    Gt2SelectComponent,
    FormsModule,
    FlatpickrModule.forRoot(),
    MailComposeDialogComponent,
    NgxMaterialTimepickerModule,
    AddressComponent,
    FileSaverModule,
    CreateGuestComponent,
    EventAttachedFilesComponent,
    MatSliderModule,
    Select2Module,
    MatAutocompleteModule,
  ],
  exports: [EventComponent, MatSliderModule],
  providers: [
    EventsService,
    DocumentsService,
    FlowService,
    ProgramsService,
    GuestsService,
    DeliveryRequestService,
    DeliveryRequestContentService,
    DeliveryRequestTypeService,
    DeliveryTypeService,
    VenuesPlaceService,
    DepositRequestService,
    DepositInService,
    PaymentModesService,
    EventTypeService,
    EventComposeService,
    DeliveryRequestTemplateService,
    TakeoutRequestService,
    EventAttachedFilesService,
    ChangelogsService,
  ],
})
export class EventsModule {}
