<div *ngIf="hoursModel" class="grid-hours">
  <!-- HEADER -->
  <div fxLayout="row" fxLayoutAlign="start start" class="title">
    <div fxFlex="160px" class="right-border header-cell">&nbsp;</div>

    <div
      fxFlex="72px"
      class="right-border header-cell"
      fxLayoutAlign="start center"
    >
      {{ "SHOPS.OFF" | translate | uppercase }}
    </div>

    <div fxFlex class="right-border header-cell" fxLayoutAlign="start center">
      {{ "GENERIC.FROM" | translate | uppercase }}
    </div>

    <div fxFlex class="header-cell" fxLayoutAlign="start center">
      {{ "GENERIC.TO" | translate | uppercase }}
    </div>
  </div>

  <!-- MONDAY -->
  <div fxLayout="row" fxLayoutAlign="start start">
    <div
      fxFlex="160px"
      class="right-border top-border cell"
      fxLayoutAlign="start center"
    >
      <div class="title">{{ "GENERIC.DAYS.MONDAY" | translate }}</div>
    </div>

    <div
      fxFlex="72px"
      class="right-border top-border cell"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-checkbox
        labelPosition="after"
        (change)="
          onCheckboxChange(
            $event,
            'monday_start_time',
            'monday_end_time',
            'monday'
          )
        "
        [checked]="!hoursModel.monday_start_time || !hoursModel.monday_end_time"
        [disabled]="!editMode"
        color="accent"
        class=""
      >
      </mat-checkbox>
    </div>

    <div fxFlex class="right-border top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.monday"
        [(ngModel)]="hoursModel.monday_start_time"
        (ngModelChange)="onTimeChange($event, 'monday_start_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.monday_start_time | momentFormat }}
      </div>
    </div>

    <div fxFlex class="top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.monday"
        [(ngModel)]="hoursModel.monday_end_time"
        (ngModelChange)="onTimeChange($event, 'monday_end_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.monday_end_time | momentFormat }}
      </div>
    </div>
  </div>

  <!-- TUESDAY -->
  <div fxLayout="row" fxLayoutAlign="start start">
    <div
      fxFlex="160px"
      class="right-border top-border cell"
      fxLayoutAlign="start center"
    >
      <div class="title">{{ "GENERIC.DAYS.TUESDAY" | translate }}</div>
    </div>

    <div
      fxFlex="72px"
      class="right-border top-border cell"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-checkbox
        labelPosition="after"
        (change)="
          onCheckboxChange(
            $event,
            'tuesday_start_time',
            'tuesday_end_time',
            'tuesday'
          )
        "
        color="accent"
        [checked]="
          !hoursModel.tuesday_start_time || !hoursModel.tuesday_end_time
        "
        [disabled]="!editMode"
        class=""
      >
      </mat-checkbox>
    </div>

    <div fxFlex class="right-border top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.tuesday"
        [(ngModel)]="hoursModel.tuesday_start_time"
        (ngModelChange)="onTimeChange($event, 'tuesday_start_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.tuesday_start_time | momentFormat }}
      </div>
    </div>

    <div fxFlex class="top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.tuesday"
        [(ngModel)]="hoursModel.tuesday_end_time"
        (ngModelChange)="onTimeChange($event, 'tuesday_end_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.tuesday_end_time | momentFormat }}
      </div>
    </div>
  </div>

  <!-- Wednesday -->
  <div fxLayout="row" fxLayoutAlign="start start">
    <div
      fxFlex="160px"
      class="right-border top-border cell"
      fxLayoutAlign="start center"
    >
      <div class="title">{{ "GENERIC.DAYS.WEDNESDAY" | translate }}</div>
    </div>

    <div
      fxFlex="72px"
      class="right-border top-border cell"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-checkbox
        labelPosition="after"
        (change)="
          onCheckboxChange(
            $event,
            'wednesday_start_time',
            'wednesday_end_time',
            'wednesday'
          )
        "
        color="accent"
        [checked]="
          !hoursModel.wednesday_start_time || !hoursModel.wednesday_end_time
        "
        [disabled]="!editMode"
        class=""
      >
      </mat-checkbox>
    </div>

    <div fxFlex class="right-border top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.wednesday"
        [(ngModel)]="hoursModel.wednesday_start_time"
        (ngModelChange)="onTimeChange($event, 'wednesday_start_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.wednesday_start_time | momentFormat }}
      </div>
    </div>

    <div fxFlex class="top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.wednesday"
        [(ngModel)]="hoursModel.wednesday_end_time"
        (ngModelChange)="onTimeChange($event, 'wednesday_end_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.wednesday_end_time | momentFormat }}
      </div>
    </div>
  </div>

  <!-- Thursday -->
  <div fxLayout="row" fxLayoutAlign="start start">
    <div
      fxFlex="160px"
      class="right-border top-border cell"
      fxLayoutAlign="start center"
    >
      <div class="title">{{ "GENERIC.DAYS.THURSDAY" | translate }}</div>
    </div>

    <div
      fxFlex="72px"
      class="right-border top-border cell"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-checkbox
        labelPosition="after"
        (change)="
          onCheckboxChange(
            $event,
            'thursday_start_time',
            'thursday_end_time',
            'thursday'
          )
        "
        color="accent"
        [checked]="
          !hoursModel.thursday_start_time || !hoursModel.thursday_end_time
        "
        [disabled]="!editMode"
        class=""
      >
      </mat-checkbox>
    </div>

    <div fxFlex class="right-border top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.thursday"
        [(ngModel)]="hoursModel.thursday_start_time"
        (ngModelChange)="onTimeChange($event, 'thursday_start_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.thursday_start_time | momentFormat }}
      </div>
    </div>

    <div fxFlex class="top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.thursday"
        [(ngModel)]="hoursModel.thursday_end_time"
        (ngModelChange)="onTimeChange($event, 'thursday_end_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.thursday_end_time | momentFormat }}
      </div>
    </div>
  </div>

  <!-- Friday -->
  <div fxLayout="row" fxLayoutAlign="start start">
    <div
      fxFlex="160px"
      class="right-border top-border cell"
      fxLayoutAlign="start center"
    >
      <div class="title">{{ "GENERIC.DAYS.FRIDAY" | translate }}</div>
    </div>

    <div
      fxFlex="72px"
      class="right-border top-border cell"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-checkbox
        labelPosition="after"
        (change)="
          onCheckboxChange(
            $event,
            'friday_start_time',
            'friday_end_time',
            'friday'
          )
        "
        color="accent"
        [checked]="!hoursModel.friday_start_time || !hoursModel.friday_end_time"
        [disabled]="!editMode"
        class=""
      >
      </mat-checkbox>
    </div>

    <div fxFlex class="right-border top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.friday"
        [(ngModel)]="hoursModel.friday_start_time"
        (ngModelChange)="onTimeChange($event, 'friday_start_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.friday_start_time | momentFormat }}
      </div>
    </div>

    <div fxFlex class="top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.friday"
        [(ngModel)]="hoursModel.friday_end_time"
        (ngModelChange)="onTimeChange($event, 'friday_end_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.friday_end_time | momentFormat }}
      </div>
    </div>
  </div>

  <!-- Saturday -->
  <div fxLayout="row" fxLayoutAlign="start start">
    <div
      fxFlex="160px"
      class="right-border top-border cell"
      fxLayoutAlign="start center"
    >
      <div class="title">{{ "GENERIC.DAYS.SATURDAY" | translate }}</div>
    </div>

    <div
      fxFlex="72px"
      class="right-border top-border cell"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-checkbox
        labelPosition="after"
        (change)="
          onCheckboxChange(
            $event,
            'saturday_start_time',
            'saturday_end_time',
            'saturday'
          )
        "
        color="accent"
        [checked]="
          !hoursModel.saturday_start_time || !hoursModel.saturday_end_time
        "
        [disabled]="!editMode"
        class=""
      >
      </mat-checkbox>
    </div>

    <div fxFlex class="right-border top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.saturday"
        [(ngModel)]="hoursModel.saturday_start_time"
        (ngModelChange)="onTimeChange($event, 'saturday_start_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.saturday_start_time | momentFormat }}
      </div>
    </div>

    <div fxFlex class="top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.saturday"
        [(ngModel)]="hoursModel.saturday_end_time"
        (ngModelChange)="onTimeChange($event, 'saturday_end_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.saturday_end_time | momentFormat }}
      </div>
    </div>
  </div>

  <!-- Sunday -->
  <div fxLayout="row" fxLayoutAlign="start start">
    <div
      fxFlex="160px"
      class="right-border top-border cell"
      fxLayoutAlign="start center"
    >
      <div class="title">{{ "GENERIC.DAYS.SUNDAY" | translate }}</div>
    </div>

    <div
      fxFlex="72px"
      class="right-border top-border cell"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-checkbox
        labelPosition="after"
        (change)="
          onCheckboxChange(
            $event,
            'sunday_start_time',
            'sunday_end_time',
            'sunday'
          )
        "
        color="accent"
        [checked]="!hoursModel.sunday_start_time || !hoursModel.sunday_end_time"
        [disabled]="!editMode"
        class=""
      >
      </mat-checkbox>
    </div>

    <div fxFlex class="right-border top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.sunday"
        [(ngModel)]="hoursModel.sunday_start_time"
        (ngModelChange)="onTimeChange($event, 'sunday_start_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.sunday_start_time | momentFormat }}
      </div>
    </div>

    <div fxFlex class="top-border cell">
      <ngx-timepicker-field
        *ngIf="editMode"
        [buttonAlign]="'left'"
        [format]="ampm"
        [disabled]="disabledDay.sunday"
        [(ngModel)]="hoursModel.sunday_end_time"
        (ngModelChange)="onTimeChange($event, 'sunday_end_time')"
        class="no-border"
      >
      </ngx-timepicker-field>

      <div *ngIf="!editMode" fxFill fxLayoutAlign="start center" class="title">
        {{ hoursModel.sunday_end_time | momentFormat }}
      </div>
    </div>
  </div>
</div>
