<div
  id="settings"
  class="page-layout simple fullwidth inner-scroll"
  fxLayout="column"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1">{{ "SETTINGS.SETTINGS" | translate }}</div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <div
    class="py-24 pl-12 pr-24"
    style="overflow-x: scroll"
    fusePerfectScrollbar
  >
    <!-- CONTENT -->
    <div class="settings-content">
      <div fxLayout="row" fxLayoutAlign="start start">
        <div class="left-menu-content">
          <!--LEFT MENUES-->
          <div
            fxLayout="column"
            fxLayoutAlign="start start"
            fxFlex="256px"
            class="mr-16"
          >
            <!--CATEGORIES_&_ZONE-->
            <div class="profile-box info-box" fxLayout="column">
              <header
                class="accent"
                fxLayout="row"
                fxLayoutAlign="space-between center"
                (click)="toggleSection('categoriesAndZoneSectionOpen')"
              >
                <div class="title no-text-cursor">
                  {{ "SETTINGS.CATEGORIES_&_ZONE" | translate }}
                </div>
                <div *ngIf="!sections.categoriesAndZoneSectionOpen.value">
                  <button
                    pButton
                    icon="fa fa-plus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
                <div *ngIf="sections.categoriesAndZoneSectionOpen.value">
                  <button
                    pButton
                    icon="fa fa-minus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
              </header>

              <div
                class="content"
                fxLayout="column"
                *ngIf="sections.categoriesAndZoneSectionOpen.value"
              >
                <mat-table #table [dataSource]="dataSource" *fuseIfOnDom>
                  <!-- Name Column -->
                  <ng-container cdkColumnDef="name">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell
                      matRipple
                      *cdkCellDef="let row"
                      class="no-text-cursor"
                    >
                      {{ row.name }}</mat-cell
                    >
                  </ng-container>

                  <mat-header-row
                    *cdkHeaderRowDef="displayedColumns"
                    style="display: none"
                  ></mat-header-row>
                  <mat-row
                    class="mat-row-color"
                    *cdkRowDef="let row; columns: displayedColumns"
                    (click)="onSelect(row)"
                    [ngClass]="{ 'menu-selected': row == selected }"
                    matRipple
                  >
                  </mat-row>
                </mat-table>
              </div>
            </div>

            <!--BASIC_SETTINGS-->
            <div class="profile-box info-box" fxLayout="column">
              <header
                class="accent"
                fxLayout="row"
                fxLayoutAlign="space-between center"
                (click)="toggleSection('basicPreferenceSectionOpen')"
              >
                <div class="title no-text-cursor">
                  {{ "SETTINGS.BASIC_SETTINGS" | translate }}
                </div>

                <div *ngIf="!sections.basicPreferenceSectionOpen.value">
                  <button
                    pButton
                    icon="fa fa-plus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
                <div *ngIf="sections.basicPreferenceSectionOpen.value">
                  <button
                    pButton
                    icon="fa fa-minus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
              </header>

              <div
                class="content"
                fxLayout="column"
                *ngIf="sections.basicPreferenceSectionOpen.value"
              >
                <mat-table
                  #table
                  [dataSource]="basicSettingsDataSource"
                  *fuseIfOnDom
                >
                  <!-- Name Column -->
                  <ng-container cdkColumnDef="name">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell
                      matRipple
                      *cdkCellDef="let row"
                      class="no-text-cursor"
                    >
                      {{ row.name }}</mat-cell
                    >
                  </ng-container>

                  <mat-header-row
                    *cdkHeaderRowDef="displayedColumns"
                    style="display: none"
                  ></mat-header-row>
                  <mat-row
                    class="mat-row-color"
                    *cdkRowDef="let row; columns: displayedColumns"
                    (click)="onSelect(row)"
                    [ngClass]="{ 'menu-selected': row == selected }"
                    matRipple
                  >
                  </mat-row>
                </mat-table>
              </div>
            </div>

            <!--ORDERS_BILLING_SETTINGS-->
            <div class="profile-box info-box" fxLayout="column">
              <header
                class="accent"
                fxLayout="row"
                fxLayoutAlign="space-between center"
                (click)="toggleSection('ordersAndBillingSectionOpen')"
              >
                <div class="title no-text-cursor">
                  {{ "SETTINGS.ORDERS_BILLING_SETTINGS" | translate }}
                </div>

                <div *ngIf="!sections.ordersAndBillingSectionOpen.value">
                  <button
                    pButton
                    icon="fa fa-plus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
                <div *ngIf="sections.ordersAndBillingSectionOpen.value">
                  <button
                    pButton
                    icon="fa fa-minus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
              </header>

              <div
                class="content"
                fxLayout="column"
                *ngIf="sections.ordersAndBillingSectionOpen.value"
              >
                <mat-table
                  #table
                  [dataSource]="ordersSettingsDataSource"
                  *fuseIfOnDom
                >
                  <!-- Name Column -->
                  <ng-container cdkColumnDef="name">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell
                      matRipple
                      *cdkCellDef="let row"
                      class="no-text-cursor"
                    >
                      {{ row.name }}</mat-cell
                    >
                  </ng-container>

                  <mat-header-row
                    *cdkHeaderRowDef="displayedColumns"
                    style="display: none"
                  ></mat-header-row>
                  <mat-row
                    class="mat-row-color"
                    *cdkRowDef="let row; columns: displayedColumns"
                    (click)="onSelect(row)"
                    [ngClass]="{ 'menu-selected': row == selected }"
                    matRipple
                  >
                  </mat-row>
                </mat-table>
              </div>
            </div>

            <!--TAXES_SETTINGS-->
            <div class="profile-box info-box" fxLayout="column">
              <header
                class="accent"
                fxLayout="row"
                fxLayoutAlign="space-between center"
                (click)="toggleSection('taxesSectionOpen')"
              >
                <div class="title no-text-cursor">
                  {{ "SETTINGS.TAXES_SETTINGS" | translate }}
                </div>

                <div *ngIf="!sections.taxesSectionOpen.value">
                  <button
                    pButton
                    icon="fa fa-plus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
                <div *ngIf="sections.taxesSectionOpen.value">
                  <button
                    pButton
                    icon="fa fa-minus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
              </header>

              <div
                class="content"
                fxLayout="column"
                *ngIf="sections.taxesSectionOpen.value"
              >
                <mat-table
                  #table
                  [dataSource]="taxesSettingsDataSource"
                  *fuseIfOnDom
                >
                  <!-- Name Column -->
                  <ng-container cdkColumnDef="name">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell
                      matRipple
                      *cdkCellDef="let row"
                      class="no-text-cursor"
                    >
                      {{ row.name }}</mat-cell
                    >
                  </ng-container>

                  <mat-header-row
                    *cdkHeaderRowDef="displayedColumns"
                    style="display: none"
                  ></mat-header-row>
                  <mat-row
                    class="mat-row-color"
                    *cdkRowDef="let row; columns: displayedColumns"
                    (click)="onSelect(row)"
                    [ngClass]="{ 'menu-selected': row == selected }"
                    matRipple
                  >
                  </mat-row>
                </mat-table>
              </div>
            </div>

            <!--CONDITIONS-->
            <div class="profile-box info-box" fxLayout="column">
              <header
                class="accent"
                fxLayout="row"
                fxLayoutAlign="space-between center"
                (click)="toggleSection('conditionsSectionOpen')"
              >
                <div class="title no-text-cursor">
                  {{ "SETTINGS.CONDITONS_SETTINGS" | translate }}
                </div>

                <div *ngIf="!sections.conditionsSectionOpen.value">
                  <button
                    pButton
                    icon="fa fa-plus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
                <div *ngIf="sections.conditionsSectionOpen.value">
                  <button
                    pButton
                    icon="fa fa-minus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
              </header>

              <div
                class="content"
                fxLayout="column"
                *ngIf="sections.conditionsSectionOpen.value"
              >
                <mat-table
                  #table
                  [dataSource]="conditionsSettingsDataSource"
                  *fuseIfOnDom
                >
                  <!-- Name Column -->
                  <ng-container cdkColumnDef="name">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell
                      matRipple
                      *cdkCellDef="let row"
                      class="no-text-cursor"
                    >
                      {{ row.name }}</mat-cell
                    >
                  </ng-container>

                  <mat-header-row
                    *cdkHeaderRowDef="displayedColumns"
                    style="display: none"
                  ></mat-header-row>
                  <mat-row
                    class="mat-row-color"
                    *cdkRowDef="let row; columns: displayedColumns"
                    (click)="onSelect(row)"
                    [ngClass]="{ 'menu-selected': row == selected }"
                    matRipple
                  >
                  </mat-row>
                </mat-table>
              </div>
            </div>

            <!-- NOTIFICATION MESSAGES -->
            <div class="profile-box info-box" fxLayout="column">
              <header
                class="accent"
                fxLayout="row"
                fxLayoutAlign="space-between center"
                (click)="toggleSection('notificationMessageSectionOpen')"
              >
                <div class="title no-text-cursor">
                  {{ "SETTINGS.NOTIFICATION_MESSAGES" | translate }}
                </div>

                <div *ngIf="!sections.notificationMessageSectionOpen.value">
                  <button
                    pButton
                    icon="fa fa-plus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
                <div *ngIf="sections.notificationMessageSectionOpen.value">
                  <button
                    pButton
                    icon="fa fa-minus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
              </header>

              <div
                class="content"
                fxLayout="column"
                *ngIf="sections.notificationMessageSectionOpen.value"
              >
                <mat-table
                  #table
                  [dataSource]="notificationMessagesSettingsDataSource"
                  *fuseIfOnDom
                >
                  <!-- Name Column -->
                  <ng-container cdkColumnDef="name">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell
                      matRipple
                      *cdkCellDef="let row"
                      class="no-text-cursor"
                    >
                      {{ row.name }}</mat-cell
                    >
                  </ng-container>

                  <mat-header-row
                    *cdkHeaderRowDef="displayedColumns"
                    style="display: none"
                  ></mat-header-row>
                  <mat-row
                    class="mat-row-color"
                    *cdkRowDef="let row; columns: displayedColumns"
                    (click)="onSelect(row)"
                    [ngClass]="{ 'menu-selected': row == selected }"
                    matRipple
                  >
                  </mat-row>
                </mat-table>
              </div>
            </div>

            <!-- USERS_AND_EMPLOYEES -->
            <div
              *ngIf="hasOwnerRoles"
              class="profile-box info-box"
              fxLayout="column"
            >
              <header
                class="accent"
                fxLayout="row"
                fxLayoutAlign="space-between center"
                (click)="toggleSection('usersAndEmployeesSettingsSectionOpen')"
              >
                <div class="title no-text-cursor">
                  {{ "SETTINGS.USERS_AND_EMPLOYEES" | translate }}
                </div>

                <div
                  *ngIf="!sections.usersAndEmployeesSettingsSectionOpen.value"
                >
                  <button
                    pButton
                    icon="fa fa-plus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
                <div
                  *ngIf="sections.usersAndEmployeesSettingsSectionOpen.value"
                >
                  <button
                    pButton
                    icon="fa fa-minus-square"
                    class="toggle-section-button"
                    type="button"
                  ></button>
                </div>
              </header>

              <div
                class="content"
                fxLayout="column"
                *ngIf="sections.usersAndEmployeesSettingsSectionOpen.value"
              >
                <mat-table
                  #table
                  [dataSource]="usersAndEmployeesSettingsDataSource"
                  *fuseIfOnDom
                >
                  <!-- Name Column -->
                  <ng-container cdkColumnDef="name">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell
                      matRipple
                      *cdkCellDef="let row"
                      class="no-text-cursor"
                    >
                      {{ row.name }}</mat-cell
                    >
                  </ng-container>

                  <mat-header-row
                    *cdkHeaderRowDef="displayedColumns"
                    style="display: none"
                  ></mat-header-row>
                  <mat-row
                    class="mat-row-color"
                    *cdkRowDef="let row; columns: displayedColumns"
                    (click)="onSelect(row)"
                    [ngClass]="{ 'menu-selected': row == selected }"
                    matRipple
                  >
                  </mat-row>
                </mat-table>
              </div>
            </div>
            <!--Integration-->
            <!-- <div class="profile-box info-box" fxLayout="column">
                            <header class="accent" fxLayout="row" fxLayoutAlign="space-between center"
                                    (click)="toggleSection('integrationSectionOpen')">
                                <div class="title">{{ "SETTINGS.INTEGRATION" | translate}}</div>
                                <button class="toggle-section-button"
                                        type="button"
                                        mat-icon-button>
                                        <div *ngIf="!sections.integrationSectionOpen.value">
                                            <mat-icon class="mb-16" fontSet="fa"
                                            fontIcon="fa-plus-square"></mat-icon>
                                        </div>
                                        <div *ngIf="sections.integrationSectionOpen.value">
                                            <mat-icon class="mb-16" fontSet="fa"
                                            fontIcon="fa-minus-square"></mat-icon>
                                        </div>
                                    
                                </button>
                            </header>

                            <div class="content" fxLayout="column" *ngIf="sections.integrationSectionOpen.value">
                                <mat-table #table [dataSource]="integrationSettingsDataSource" *fuseIfOnDom>

                                    
                                    <ng-container cdkColumnDef="name">
                                        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                                        <mat-cell *cdkCellDef="let row"> {{row.name}}</mat-cell>
                                    </ng-container>

                                    <mat-header-row *cdkHeaderRowDef="displayedColumns"
                                                    style="display: none"></mat-header-row>
                                    <mat-row *cdkRowDef="let row; columns: displayedColumns;"
                                             (click)="onSelect(row)"
                                             [ngClass]="{'menu-selected':row == selected}"
                                             matRipple>
                                    </mat-row>
                                </mat-table>
                            </div>
                        </div> -->
          </div>
        </div>

        <!--RIGHT CONTENT-->
        <div
          *ngIf="selected"
          fxLayout="column"
          fxLayoutAlign="start start"
          class="right-content"
          fxFlex
        >
          <div [ngSwitch]="selected.id" fxFlex="100" class="width-100-content">
            <div
              *ngSwitchCase="'EVENT_LOGISTIC_STATUS'"
              class=""
              fxLayout="column"
            >
              <app-event-logistic-status> </app-event-logistic-status>
              <!-- 
                            <app-crud-datatable-generic [headerLabel]="'SETTINGS.EVENT_LOGISTIC_STATUS' | translate"
                                [useLanguages]="true" [useSortWithLocal]="true"
                                [serviceURL]="eventLogisticStatusServiceURL"
                                [tableHeaderLabel]="translate.instant('SETTINGS.EVENT_LOGISTIC_STATUS_NAME')"
                                [createLabel]="translate.instant('SETTINGS.EVENT_LOGISTIC_STATUS_CREATE_NEW')"
                                [createPlaceholder]="translate.instant('SETTINGS.EVENT_LOGISTIC_STATUS_CREATE_NEW_PLACEHOLDER')">
                            </app-crud-datatable-generic> -->
            </div>
            <div *ngSwitchCase="'ALLERGENS'" class="" fxLayout="column">
              <app-crud-datatable-generic
                [headerLabel]="'SETTINGS.ALLERGENS' | translate"
                [useLanguages]="true"
                [useSortWithLocal]="true"
                [serviceURL]="allergensServiceURL"
                [tableHeaderLabel]="
                  translate.instant('SETTINGS.ALLERGENS_NAME')
                "
                [createLabel]="
                  translate.instant('SETTINGS.ALLERGENS_CREATE_NEW')
                "
                [createPlaceholder]="
                  translate.instant('SETTINGS.ALLERGENS_CREATE_NEW_PLACEHOLDER')
                "
              >
              </app-crud-datatable-generic>
            </div>

            <div
              *ngSwitchCase="'WRAPPING'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-wrapping [headerLabel]="'SETTINGS.WRAPPING' | translate">
              </app-wrapping>
            </div>

            <div
              *ngSwitchCase="'WRAPPING_DEFAULT'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-wrapping-default
                [settingsModel]="setting"
              ></app-wrapping-default>
            </div>

            <div
              *ngSwitchCase="'JOB_FUNCTIONS'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-jobs-functions
                [headerLabel]="'SETTINGS.JOB_FUNCTIONS' | translate"
              >
              </app-jobs-functions>
            </div>

            <div
              *ngSwitchCase="'CLIENTS_CATEGORIES'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-clients-categories-settings
                [headerLabel]="'SETTINGS.CLIENTS_CATEGORIES' | translate"
              >
              </app-clients-categories-settings>
            </div>

            <div
              *ngSwitchCase="'MATERIALS_CATEGORIES'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-material-categories-settings
                [headerLabel]="'SETTINGS.MATERIALS_CATEGORIES' | translate"
              >
              </app-material-categories-settings>
            </div>

            <div
              *ngSwitchCase="'PRODUCTS_CATEGORIES'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-products-categories
                [headerLabel]="'SETTINGS.PRODUCTS_CATEGORIES' | translate"
              >
              </app-products-categories>
            </div>

            <div
              *ngSwitchCase="'PRODUCTION_ZONES'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-crud-datable-generic-no-local
                [useLanguages]="false"
                [headerLabel]="'SETTINGS.PRODUCTION_ZONES' | translate"
                [serviceURL]="productionZoneServiceURL"
                [tableHeaderLabel]="
                  translate.instant('SETTINGS.PRODUCTION_ZONE_NAME')
                "
                [createLabel]="
                  translate.instant('SETTINGS.PRODUCTION_ZONE_CREATE_NEW')
                "
                [createPlaceholder]="
                  translate.instant(
                    'SETTINGS.PRODUCTION_ZONE_CREATE_NEW_PLACEHOLDER'
                  )
                "
              >
              </app-crud-datable-generic-no-local>
            </div>

            <div
              *ngSwitchCase="'EVENTS_TYPES'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-crud-datatable-generic
                [headerLabel]="'SETTINGS.EVENTS_TYPES' | translate"
                [useLanguages]="true"
                [useSortWithLocal]="true"
                [serviceURL]="eventsTypeServiceURL"
                [tableHeaderLabel]="
                  translate.instant('SETTINGS.EVENTS_TYPES_NAME')
                "
                [createLabel]="
                  translate.instant('SETTINGS.EVENTS_TYPES_CREATE_NEW')
                "
                [createPlaceholder]="
                  translate.instant(
                    'SETTINGS.EVENTS_TYPES_CREATE_NEW_PLACEHOLDER'
                  )
                "
              >
              </app-crud-datatable-generic>
            </div>

            <div
              *ngSwitchCase="'MATERIALS_PROVIDERS'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-crud-datatable-generic
                [useLanguages]="false"
                [headerLabel]="'SETTINGS.MATERIALS_PROVIDERS' | translate"
                [serviceURL]="providersCategoryServiceURL"
                [tableHeaderLabel]="
                  translate.instant('SETTINGS.PROVIDERS_NAME')
                "
                [createLabel]="
                  translate.instant('SETTINGS.PROVIDERS_CATEGORY_CREATE_NEW')
                "
                [createPlaceholder]="
                  translate.instant(
                    'SETTINGS.PROVIDERS_CATEGORY_CREATE_NEW_PLACEHOLDER'
                  )
                "
              >
              </app-crud-datatable-generic>
            </div>

            <div
              *ngSwitchCase="'STAFF_PROVIDERS'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-crud-datatable-generic
                [useLanguages]="false"
                [headerLabel]="'SETTINGS.STAF_PROVIDERS_NAME' | translate"
                [serviceURL]="staffProvidersCategoryServiceURL"
                [tableHeaderLabel]="
                  translate.instant('SETTINGS.PROVIDERS_NAME')
                "
                [createLabel]="
                  translate.instant(
                    'SETTINGS.STAFF_PROVIDERS_CATEGORY_CREATE_NEW'
                  )
                "
                [createPlaceholder]="
                  translate.instant(
                    'SETTINGS.PROVIDERS_CATEGORY_CREATE_NEW_PLACEHOLDER'
                  )
                "
              >
              </app-crud-datatable-generic>
            </div>

            <div
              *ngSwitchCase="'SERVICE_CHARGE'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-service-charge
                [headerLabel]="'SETTINGS.SERVICE_CHARGE' | translate"
              >
              </app-service-charge>
            </div>

            <div
              *ngSwitchCase="'DELIVERY_TEMPLATES'"
              class="width-100-content"
              fxLayout="column"
            >
              <!--                            <app-service-charge [headerLabel]="'SETTINGS.SERVICE_CHARGE' | translate">-->
              <!--                            </app-service-charge>-->
              <app-delivery-requests-templates
                [headerLabel]="'SETTINGS.DELIVERY_TEMPLATES' | translate"
              >
              </app-delivery-requests-templates>
            </div>

            <div
              *ngSwitchCase="'LEDGER_ACCOUNT'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-ledger-account
                [headerLabel]="'SETTINGS.LEDGER_ACCOUNT' | translate"
              >
              </app-ledger-account>
            </div>

            <div
              *ngSwitchCase="'LEDGER_ACCOUNT_DEFAULT'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-ledger-account-default
                [settingsModel]="setting"
              ></app-ledger-account-default>
            </div>

            <div
              *ngSwitchCase="'SOCIAL_CHARGE'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.SOCIAL_CHARGE" | translate }}
                </div>
              </header>

              <div
                class="content p-12"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <div [formGroup]="form" class="" fxFlex="78%">
                  <mat-form-field *ngIf="setting" fxFlex="100%" class="mr-16">
                    <mat-label>{{
                      "SETTINGS.SOCIAL_CHARGE_PERCENT" | translate
                    }}</mat-label>
                    <input
                      matInput
                      [placeholder]="
                        'SETTINGS.SOCIAL_CHARGE_PERCENT' | translate
                      "
                      type="text"
                      formControlName="social_charge"
                      pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                      [(ngModel)]="setting.social_charge"
                    />
                    <mat-error *ngIf="form.controls['social_charge'].invalid">
                      {{ "GENERIC.INPUT_NUMBER_0_999" | translate }}
                      {{ "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <button
                  mat-raised-button
                  color="accent"
                  (click)="onSocialChargeUpdate(setting.social_charge)"
                  style="font-size: 11px"
                  [disabled]="!form.valid"
                  class=""
                >
                  {{ "SETTINGS.UPDATE" | translate | uppercase }}
                </button>
              </div>
            </div>

            <div
              *ngSwitchCase="'LANG'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.LANG" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <div class="title">LANG Title</div>
                <div class="info">LANG Info</div>
              </div>
            </div>

            <div
              *ngSwitchCase="'PRINT'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.PRINT" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <div class="title">PRINT Title</div>
                <div class="info">PRINT Info</div>
              </div>
            </div>

            <div
              *ngSwitchCase="'TAXES'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.TAXES" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-taxes></app-taxes>
              </div>
            </div>

            <div
              *ngSwitchCase="'TAXES_DEFAULT'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-taxes-default [settingsModel]="setting"></app-taxes-default>
            </div>

            <div
              *ngSwitchCase="'DATE_TIME'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.DATE_TIME_SETTINGS" | translate }}
                </div>
              </header>

              <div *ngIf="setting" class="content p-16" fxLayout="column">
                <app-gt2-select
                  [service]="timezoneService"
                  [placeHolder]="'SETTINGS.TIMEZONE_SELECT' | translate"
                  [label]="'Unit'"
                  [labelKey]="'name'"
                  [selectedModel]="setting.timezone"
                  [nullableUseNone]="false"
                  (changeEvent)="onTimeZoneSelected($event)"
                  [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                  fxFlex="100%"
                  class="p-16"
                ></app-gt2-select>

                <app-gt2-select
                  [service]="dateFormatService"
                  [placeHolder]="'SETTINGS.DATE_FORMAT_SELECT' | translate"
                  [labelKey]="'picker_format'"
                  [label]="'Unit'"
                  [nullableUseNone]="false"
                  [selectedModel]="setting.date_format"
                  (changeEvent)="onDateFormatSelected($event)"
                  [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                  fxFlex="100%"
                  class="p-16"
                ></app-gt2-select>

                <app-gt2-select
                  [service]="dateTimeFormatService"
                  [placeHolder]="'SETTINGS.DATE_TIME_FORMAT_SELECT' | translate"
                  [labelKey]="'format'"
                  [label]="'Unit'"
                  [nullableUseNone]="false"
                  [selectedModel]="setting.datetime_format"
                  (changeEvent)="onDateTimeFormatSelected($event)"
                  [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                  fxFlex="100%"
                  class="p-16"
                ></app-gt2-select>

                <div
                  fxLayout="row"
                  fxLayoutAlign="center start"
                  fxFlex="100%"
                  class="gt2-buttons-theme content px-16 pb-16"
                >
                  <button
                    mat-raised-button
                    color="accent"
                    class=""
                    (click)="onDateTimePreferencesData()"
                  >
                    {{ "SETTINGS.UPDATE" | translate }}
                  </button>
                </div>
              </div>
            </div>

            <div
              *ngSwitchCase="'ONLINE_PAYMENT'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.ONLINE_PAYMENT" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <div class="title">ONLINE_PAYMENT Title</div>
                <div class="info">ONLINE_PAYMENT Info</div>
              </div>
            </div>

            <div
              *ngSwitchCase="'THEME'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.THEME" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <div class="title">THEME Title</div>
                <div class="info">THEME Info</div>
              </div>
            </div>

            <div
              *ngSwitchCase="'DELIVERY_SECTOR'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-crud-delivery-sectors></app-crud-delivery-sectors>
            </div>

            <div
              *ngSwitchCase="'DELIVERY'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.DELIVERY" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-delivery-settings
                  *ngIf="setting"
                  [settingsModel]="setting"
                ></app-delivery-settings>
              </div>
            </div>

            <div
              *ngSwitchCase="'NOTIFICATIONS'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.NOTIFICATIONS" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <div class="title">NOTIFICATIONS Title</div>
                <div class="info">NOTIFICATIONS Info</div>
              </div>
            </div>

            <div
              *ngSwitchCase="'DOWNLOADS'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.DOWNLOADS" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <div class="title">DOWNLOADS Title</div>
                <div class="info">DOWNLOADS Info</div>
              </div>
            </div>

            <div
              *ngSwitchCase="'NUMBERING'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.NUMBERING" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-numbering> </app-numbering>
              </div>
            </div>

            <div
              *ngSwitchCase="'GENERAL_CONDITIONS'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.GENERAL_CONDITIONS" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-general-conditions></app-general-conditions>
              </div>
            </div>

            <div
              *ngSwitchCase="'SPECIFICS_CONDITIONS'"
              class="width-100-content"
              fxLayout="column"
            >
              <app-specific-conditions
                [useLanguages]="true"
                [headerLabel]="'SETTINGS.SPECIFICS_CONDITIONS' | translate"
                [serviceURL]="specificConditionsServiceURL"
                [tableHeaderLabel]="
                  translate.instant('SETTINGS.SPECIFIC_CONDITION_NAME')
                "
                [createLabel]="
                  translate.instant('SETTINGS.SPECIFIC_CONDITION_CREATE_NEW')
                "
                [createPlaceholder]="
                  translate.instant(
                    'SETTINGS.SPECIFIC_CONDITION_CREATE_NEW_PLACEHOLDER'
                  )
                "
              >
              </app-specific-conditions>
            </div>

            <!-- NOTIFICATION_MESSAGES -->
            <div
              *ngSwitchCase="'NOTIFICATION_MESSAGE_PROPOSAL'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.NOTIFICATION_MESSAGE_PROPOSAL" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-notification-message [key]="'proposal_notification'">
                </app-notification-message>
              </div>
            </div>

            <div
              *ngSwitchCase="'NOTIFICATION_MESSAGE_INVOICE'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.NOTIFICATION_MESSAGE_INVOICE" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-notification-message [key]="'invoice_notification'">
                </app-notification-message>
              </div>
            </div>

            <div
              *ngSwitchCase="'NOTIFICATION_MESSAGE_INVOICE_ON_LINE_GP'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title">
                  {{
                    "SETTINGS.NOTIFICATION_MESSAGE_INVOICE_ON_LINE_GP_PAGE"
                      | translate
                  }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-notification-message
                  [key]="'invoice_notification_online_gp'"
                >
                </app-notification-message>
              </div>
            </div>

            <div
              *ngSwitchCase="'NOTIFICATION_MESSAGE_ALL_RECEIPT_NOTICES'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title">
                  {{
                    "SETTINGS.NOTIFICATION_MESSAGE_ALL_RECEIPT_NOTICES"
                      | translate
                  }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-notification-message
                  [key]="'notification_all_receipt_notices'"
                >
                </app-notification-message>
              </div>
            </div>

            <div
              *ngSwitchCase="'NOTIFICATION_MESSAGE_ORDER'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.NOTIFICATION_MESSAGE_ORDER" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-notification-message [key]="'order_notification'">
                </app-notification-message>
              </div>
            </div>

            <div
              *ngSwitchCase="'NOTIFICATION_MESSAGE_RECEIPT'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.NOTIFICATION_MESSAGE_RECEIPT" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-notification-message [key]="'event_receipt_notification'">
                </app-notification-message>
              </div>
            </div>

            <div
              *ngSwitchCase="'NOTIFICATION_MESSAGE_CLIENT_STATEMENT'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title">
                  {{
                    "SETTINGS.NOTIFICATION_MESSAGE_CLIENT_STATEMENT" | translate
                  }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-notification-message
                  [key]="'client_statement_notification'"
                >
                </app-notification-message>
              </div>
            </div>

            <div
              *ngSwitchCase="'NOTIFICATION_MESSAGE_ENTERPRISE_STATEMENT'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title">
                  {{
                    "SETTINGS.NOTIFICATION_MESSAGE_ENTERPRISE_STATEMENT"
                      | translate
                  }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-notification-message
                  [key]="'enterprise_statement_notification'"
                >
                </app-notification-message>
              </div>
            </div>

            <div
              *ngSwitchCase="'NOTIFICATION_MESSAGE_SCHEDULE'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.NOTIFICATION_MESSAGE_SCHEDULE" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-notification-message [key]="'schedule_notification'">
                </app-notification-message>
              </div>
            </div>

            <div
              *ngSwitchCase="'NOTIFICATION_MESSAGE_BANQUET_CONTRACT'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title">
                  {{
                    "SETTINGS.NOTIFICATION_MESSAGE_BANQUET_CONTRACT" | translate
                  }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-notification-message [key]="'congress_banquet_contract'">
                </app-notification-message>
              </div>
            </div>

            <!--<div *ngSwitchCase="'NOTIFICATION_MESSAGE_HISTORIC_ACCOUNT'" class="profile-box info-box width-100-content"
                             fxLayout="column">
                             <header class="accent">
                                <div class="title">{{'SETTINGS.NOTIFICATION_MESSAGE_HISTORIC_ACCOUNT' | translate}}</div>
                            </header>

                            <div class="content p-16" fxLayout="column">
                                <app-notification-message
                                    [key]="'historic-account'">
                                </app-notification-message>
                            </div>
                        </div>-->

            <div
              *ngSwitchCase="'INTEGRATION'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.INTEGRATION" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <div class="title">
                  Courte description pour Sage, Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit.
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" class="mt-8">
                  <button mat-raised-button color="accent" (click)="onSage()">
                    Autoriser Sage
                  </button>
                </div>
              </div>
            </div>

            <div
              *ngSwitchCase="'MAX_USERS'"
              class="profile-box info-box width-100-content"
              fxLayout="column"
            >
              <header class="accent">
                <div class="title no-text-cursor">
                  {{ "SETTINGS.MAX_USERS" | translate }}
                </div>
              </header>

              <div class="content p-16" fxLayout="column">
                <app-user-employee-setting></app-user-employee-setting>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / CONTENT -->
  </div>
</div>
