<div id="eventDepositRequest">
  <div fxLayout="row" fxLayout="start center" class="section-list-header">
    <!-- <div fxFlex="50px" class=""></div> -->
    <div fxFlex="14" class="section-list-item-border-right pl-12 py-4">
      {{ "GENERIC.NUMBER" | translate }}
    </div>
    <div
      fxFlex="15"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "EVENTS.DATE_DUE" | translate }}
    </div>
    <div
      fxFlex="17"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "EVENTS.NET_TOTAL" | translate }}
    </div>
    <div
      fxFlex="18"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "EVENTS.RECEIVED_ON" | translate }}
    </div>
    <div
      fxFlex="21"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "EVENTS.RECEIVED_FROM" | translate }}
    </div>
    <div
      fxFlex="15"
      class="section-list-item section-list-item-border-right py-4"
    >
      Actions
    </div>
  </div>

  <div fusePerfectScrollbar>
    <div
      *ngIf="
        event &&
        (!event.deposit_requests || event.deposit_requests.length === 0)
      "
      fxFlex="100"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <div class="font-size-20 my-16">
        {{ "EVENTS.NO_DEPOSIT_REQUEST" | translate }}
      </div>
    </div>

    <div
      *ngIf="loading"
      fxLayout="row"
      fxLayoutAlign="center center"
      style="height: 100%; width: 100%"
    >
      <mat-spinner [diameter]="40" class="my-40"></mat-spinner>
    </div>

    <div
      *ngIf="
        !loading &&
        event &&
        event.deposit_requests &&
        event.deposit_requests.length > 0
      "
      fxFlex="100"
    >
      <div
        *ngFor="let item of event.deposit_requests"
        fxLayout="row"
        fxLayout="start center"
        class="child-item"
      >
        <div
          fxFlex="14"
          fxLayout="row"
          fxLayout="start center"
          class="section-list-item-border-right pl-12 py-8"
        >
          <div class="">{{ item.deposit_number }}</div>
        </div>
        <div
          fxFlex="15"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.due_date_label }}
        </div>
        <div
          fxFlex="17"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.amount_label }}
        </div>
        <div
          fxFlex="18"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.received_at_label }}
        </div>
        <div
          fxFlex="21"
          class="section-list-item section-list-item-border-right py-8 text-truncate"
        >
          <span
            *ngIf="item.received_by && item.received_by.employee"
            (click)="onOpenItemInNewTab(item.received_by.employee)"
            class="mx-4"
            >{{ item.received_by.employee.name }}</span
          >
        </div>
        <div
          fxFlex="15"
          *ngxPermissionsOnly="rolesService.SALE_ROLES"
          class="section-list-item"
        >
          <button
            mat-icon-button
            (click)="onEditItem(item)"
            matTooltip="{{ 'GENERIC.EDIT' | translate }}"
            [disabled]="item.received_by || item.received_at || !editable"
            [ngClass]="{ 'btn-disabled': item.received_by || item.received_at }"
            class="mat-icon-button"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("EDIT")
            }}</mat-icon>
          </button>
          <button
            mat-icon-button
            [disabled]="item.received_by || item.received_at || !editable"
            [ngClass]="{ 'btn-disabled': item.received_by || item.received_at }"
            matTooltip="{{ 'GENERIC.DELETE' | translate }}"
            (click)="onDeleteItem(item)"
            class="mat-icon-button"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("DELETE")
            }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
