export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_ENTERPRISE: {
      TITLE: "Nouvelle entreprise",
      TITLE_BRANCH: "Nouvelle succursale",
      CREATE_SUCCESS: "Succursale créé avec succès",
    },
  },
};
