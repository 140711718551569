import { RichTextsValidator } from './../../../utils/RichTextsValidator';
import { CommonModule, Location } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor, EditorModule } from 'primeng/editor';
import { Subscription } from 'rxjs';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { FuseSidebarService } from '../../../../../@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { FileService } from '../../../../services/file.service';
import { PrintService } from '../../../../services/print.service';
import { AllergensService } from '../../../api/allergens.service';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { InvoicesCategoriesService } from '../../../api/invoices-categories.service';
import { ProductCategoryService } from '../../../api/product-category.service';
import { ProductionZonesService } from '../../../api/production-zones.service';
import { UpsaleService } from '../../../api/upsale.service';
import { ProductModel, ProductsService } from '../../../api/products.service';
import { TaxesService } from '../../../api/taxes.service';
import { UnitService } from '../../../api/unit.service';
import { WrappingService } from '../../../api/wrapping.service';
import { CreateComboComponent } from '../../../components/create-combo/create-combo.component';
import { DragDropListsComponent } from '../../../components/dialogs/drag-drop-lists/drag-drop-lists.component';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { ICanDeactivateComponent } from '../../../gards/can-deactivate-guard.service';
import { RolesService } from '../../../services/roles.service';
import { Settings } from '../../../settings/settings';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import {
  ProductShopsComponent,
  ShopsCheckbox,
} from '../product-shops/product-shops.component';
import { EditComboComponent } from '../../../components/edit-combo/edit-combo.component';
import { ModulesService } from '../../../services/modules.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSidebarModule } from '../../../../../@fuse/components';
import { MatTableModule } from '@angular/material/table';
import { CopyWrapperComponent } from '../../../components/copy-wrapper/copy-wrapper.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { CommentSectionComponent } from '../../../components/comment-section/comment-section.component';
import { CreatedUpdatedAtComponent } from '../../../components/created-updated-at/created-updated-at.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { SectionHistoryPanelComponent } from '../../../components/section-history-panel/section-history-panel.component';
import { TagsByModelComponent } from '../../../components/tags-by-model/tags-by-model.component';
import { ProductPictureUploadComponent } from '../product-picture-upload/product-picture-upload.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatIconModule,
    CommonModule,
    CopyWrapperComponent,
    EditorModule,
    MatTabsModule,
    NgxPermissionsModule,
    MatSelectModule,
    MatOptionModule,
    FlexLayoutModule,
    CreatedUpdatedAtComponent,
    Gt2SelectComponent,
    MatChipsModule,
    ProductPictureUploadComponent,
    CommentSectionComponent,
    ProductShopsComponent,
    MatTableModule,
    TagsByModelComponent,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    SectionHistoryPanelComponent,
    FuseSidebarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
  animations: fuseAnimations,
})
export class ProductComponent
  extends GT2FormPageAbstract
  implements
    OnInit,
    AfterViewInit,
    OnDestroy,
    AfterViewChecked,
    ICanDeactivateComponent
{
  @ViewChild('descriptionFrEditor')
  descriptionFrEditor!: Editor;
  @ViewChild('descriptionEnEditor')
  descriptionEnEditor!: Editor;
  @ViewChild('noteEditor') noteEditor!: Editor;
  @ViewChild('noteSaleEditor') noteSaleEditor!: Editor;
  @ViewChild('noteProdEditor') noteProdEditor!: Editor;
  @ViewChild('noteIngredientsEditor')
  noteIngredientsEditor!: Editor;
  @ViewChild('mainContainer') mainContainer!: ElementRef;
  @ViewChild('productShop')
  productShop?: ProductShopsComponent;
  product!: ProductModel | any;
  productID!: string;
  loading?: boolean;
  isCloning = false;
  form: UntypedFormGroup;
  formEnTab: UntypedFormGroup;
  formEnTabValid = true;
  formEnTabValidSub?: Subscription;
  editMode = false;
  comboDialogRef: any;
  confirmDialogRef: any;
  editable = true;
  autosaveNameKeys: string[] = ['name_fr_CA', 'name_en_CA'];
  autosaveDescriptionKeys: string[] = [
    'description_fr_CA',
    'description_en_CA',
  ];
  isSmallScreen?: boolean;

  noteInvalid: any = {
    note: false,
    sale_note: false,
    prod_note: false,
    ingredient_note: false,
  };

  richTextsValidator?: RichTextsValidator;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private productsService: ProductsService,
    public unitService: UnitService,
    public productCategoryService: ProductCategoryService,
    public prodZoneService: ProductionZonesService,
    public upsaleService: UpsaleService,
    public invoicesCategoriesService: InvoicesCategoriesService,
    public taxesService: TaxesService,
    public wrappingService: WrappingService,
    public allergensService: AllergensService,
    public translate: TranslateService,
    public location: Location,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private fileService: FileService,
    private dialog: MatDialog,
    public print: PrintService,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    public rolesService: RolesService,
    private _fuseSidebarService: FuseSidebarService,
    private titleService: Title,
    private api: Gt2ApiService,
    public router: Router,
    public modulesService: ModulesService,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      // name: [
      //     "",
      //     [
      //         Validators.minLength(Settings.inputMinChar),
      //         Validators.maxLength(Settings.inputMaxCharMid),
      //         // Validators.required,
      //     ],
      // ],
      name_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      // name_en_CA: [
      //     "",
      //     [
      //         Validators.minLength(Settings.inputMinChar),
      //         Validators.maxLength(Settings.inputMaxCharMid),
      //         Validators.required,
      //     ],
      // ],
      name_internal_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      // description: [
      //     "",
      //     [
      //         Validators.minLength(Settings.inputMinChar),
      //         Validators.maxLength(Settings.inputMaxCharLong),
      //     ],
      // ],
      description_fr_CA: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
      // description_en_CA: [
      //     "",
      //     [
      //         Validators.minLength(Settings.inputMinChar),
      //         Validators.maxLength(Settings.inputMaxCharLong),
      //     ],
      // ],
      description_internal: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
      product_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      // accountant_code: "",
      production_delay: ['', [ValidatorHelper.isNumber0To10000]],
      serving_count: [
        '',
        [
          // Validators.minLength(1),
          ValidatorHelper.isNumber0To1000000,
          Validators.required,
        ],
      ],
      price: [
        '',
        [
          Validators.minLength(1),
          ValidatorHelper.isNumber0To1000000,
          ValidatorHelper.isValidPriceNumber,
          Validators.required,
        ],
      ],
      cost: [
        '',
        [
          Validators.minLength(1),
          ValidatorHelper.isNumber0To1000000,
          ValidatorHelper.isValidPriceNumber,
        ],
      ],
      sku: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      min_qty_order: [
        '',
        [
          // Validators.minLength(1),
          ValidatorHelper.isNumber0To1000000,
          Validators.required,
        ],
      ],
      item_qty: [
        '',
        [
          // Validators.minLength(1),
          ValidatorHelper.isNumber0To1000000,
          Validators.required,
        ],
      ],
      confirmed: '',
    });
    //
    this.formEnTab = this.formBuilder.group({
      name_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_internal_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      description_en_CA: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 600px)'])
      .subscribe((result) => {
        this.isSmallScreen = result.matches;
      });
    //this.titleService.setTitle(this.translate.instant("PRODUCTS.PRODUCT"));
    this.route.params.subscribe((params: any) => {
      this.productID = params.id;
      this.loading = true;
      this.product = null;
      this.loadProduct();
    });
    this.richTextsValidator = new RichTextsValidator();
  }

  ngAfterViewInit(): void {
    if (this.editMode) {
      this.setControlsChanges(this.form);
    }
    // this._fuseSidebarService.getSidebar("productHistoryPanel").toggleOpen();
  }

  public onGeneralTabChange(index: any): void {
    // //this.logger.info("onGeneralTabChange() " + index);
    if (index === 1) {
      setTimeout(() => {
        // this.generalTabGenerated = true;
        this.formEnTabValidSub = this.formEnTab.statusChanges.subscribe(
          (status) => {
            // //this.logger.info("formEnTab.statusChanges() " + status);
            this.formEnTabValid = status === 'VALID';
            // //this.logger.info(
            //     "formEnTab.statusChanges() " + this.formEnTabValid
            // );
          },
        );
      }, 50);
    } else {
      this.formEnTabValidSub?.unsubscribe();
    }
  }

  public initRichText(): void {
    this.richTextsValidator?.registerRichText(this.noteEditor, 'noteEditor');
    this.richTextsValidator?.registerRichText(
      this.noteProdEditor,
      'noteProdEditor',
    );
    this.richTextsValidator?.registerRichText(
      this.noteIngredientsEditor,
      'noteIngredientsEditor',
    );
    this.richTextsValidator?.registerRichText(
      this.noteSaleEditor,
      'noteSaleEditor',
    );
    this.richTextsValidator?.registerRichText(
      this.descriptionFrEditor,
      'descriptionFrEditor',
    );
    this.richTextsValidator?.registerRichText(
      this.descriptionEnEditor,
      'descriptionEnEditor',
    );
  }

  ngAfterViewChecked(): void {}

  ngOnDestroy(): void {
    if (this.richTextsValidator) {
      this.richTextsValidator.destroy();
    }
  }

  public canDeactivate(): boolean {
    // //this.logger.info(
    //     "canDeactivate() -> this.richTextsValidator.dirty(): " +
    //         this.richTextsValidator.dirty()
    // );
    return !this.form.dirty && !this.richTextsValidator?.dirty();
  }

  public override autosaveDataChange(data: any): void {}

  public loadProduct(): void {
    this.loading = true;
    this.productsService.getProduct(this.productID).subscribe(
      (response: any) => {
        // //this.logger.info("ProductComponent.loadProduct() -> SUCCESS: ");
        // //this.logger.info(
        //     "ProductComponent.loadProduct() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        const isFirstLoad: boolean =
          this.product === null || this.product == undefined;

        this.product = response.data;
        this.loading = false;
        this.editable = !this.product?.isArchive;
        // //this.logger.info("ProductComponent.loadProduct() -> isArchive: " + this.product.isArchive);
        // //this.logger.info("ProductComponent.loadProduct() -> parents: " + JSON.stringify(this.product.parents));
        //this.titleService.setTitle(this.translate.instant("PRODUCTS.PRODUCT") + " " + this.product.name);

        if (isFirstLoad) {
          setTimeout(() => {
            this.initRichText();
          }, 500);
        }
      },
      (error: any) => {
        this.logger.error(
          'ProductComponent.loadProduct() -> ERROR: ' + JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public deleteProduct(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.productsService.deleteProduct(this.productID).subscribe(
          (response) => {
            // //this.logger.info("ProductComponent.deleteEnterprise() -> SUCCESS: " + JSON.stringify(response));
            this.toastr.success(
              '',
              this.translate.instant('GENERIC.DELETE_SUCCESS'),
            );
            this.product = response.data;
            this.editable = !this.product?.isArchive;
            // this.router.navigateByUrl("/" + AppRoutes.PRODUCTS);
          },
          (error) => {
            this.logger.error(
              'ProductComponent.deleteClient() -> ERROR: ' +
                JSON.stringify(error),
            );
            // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
            this.loading = false;
            this.handleAPIError(
              error,
              this.dialog,
              this.toastr,
              this.translate.instant('GENERIC.ERRORS.GENERIC'),
            );
          },
        );
      }
    });
  }

  public onEdit(event: any): void {
    this.editMode = !this.editMode;

    if (this.noteEditor) {
      this.noteEditor.readonly = !this.editMode;
    }

    if (this.noteSaleEditor) {
      this.noteSaleEditor.readonly = !this.editMode;
    }

    if (this.noteProdEditor) {
      this.noteProdEditor.readonly = !this.editMode;
    }

    if (this.noteIngredientsEditor) {
      this.noteIngredientsEditor.readonly = !this.editMode;
    }

    if (this.descriptionFrEditor) {
      this.descriptionFrEditor.readonly = !this.editMode;
    }

    if (this.descriptionEnEditor) {
      this.descriptionEnEditor.readonly = !this.editMode;
    }

    if (this.editMode) {
      // this.setControlsChanges(this.form);
    } else {
      // this.unsetControlsChanges();

      // HACK: p-editor add a space when deleting content
      if (this.product?.description_local.description_fr_CA === ' ') {
        this.product.description_local.description_fr_CA = '';
      }
      if (this.product?.description_local.description_en_CA === ' ') {
        this.product.description_local.description_en_CA = '';
      }

      // //this.logger.info(
      //     "description_fr_CA : " +
      //         this.product.description_local.description_fr_CA
      // );

      // //this.logger.info(
      //     "description_fr_CA length : " +
      //         this.product.description_local.description_fr_CA.length
      // );

      this.loading = true;
      this.productsService
        .updateProduct(this.product, this.product.uuid)
        .subscribe(
          (response: any) => {
            // //this.logger.info(
            //     "ProductComponent.autosaveDataChange() -> SUCCESS: " +
            //         JSON.stringify(response)
            // );
            this.form.markAsPristine();
            this.form.markAsUntouched();
            this.loading = false;
            this.product = response.data;

            //
            if (this.richTextsValidator) {
              this.richTextsValidator.clean();
            }
          },
          (error: any) => {
            this.loading = false;
            // //this.logger.info("ProductComponent.autosaveDataChange() -> ERROR: " + JSON.stringify(error));
            this.handleAPIError(error, this.dialog, null, null);
          },
        );
    }
  }

  public onCancelEdit(event: any): void {
    this.editMode = false;

    if (this.noteEditor) {
      this.noteEditor.readonly = !this.editMode;
    }

    if (this.noteSaleEditor) {
      this.noteSaleEditor.readonly = !this.editMode;
    }

    if (this.noteProdEditor) {
      this.noteProdEditor.readonly = !this.editMode;
    }

    if (this.noteIngredientsEditor) {
      this.noteIngredientsEditor.readonly = !this.editMode;
    }

    if (this.descriptionFrEditor) {
      this.descriptionFrEditor.readonly = !this.editMode;
    }

    if (this.descriptionEnEditor) {
      this.descriptionEnEditor.readonly = !this.editMode;
    }

    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.loadProduct();
  }

  public onUnitSelected(unit: any): void {
    // //this.logger.info("ProductComponent.onUnitSelected() -> unit: " + JSON.stringify(unit));
    this.product.item_unit = unit;
  }

  public onProductCategorySelected(category: any): void {
    // //this.logger.info("ProductComponent.onProductCategorySelected() -> category: " + JSON.stringify(category));
    this.product.product_category = category;
  }

  public onProdZoneSelected(zone: any): void {
    // //this.logger.info("ProductComponent.onProdZoneSelected() -> zone: " + JSON.stringify(zone));
    this.product.production_zones = zone;
  }

  public onWrappingSelected(wrapping: any): void {
    // //this.logger.info("ProductComponent.onWrappingSelected() -> wrapping: " + JSON.stringify(wrapping));
    this.product.wrapping = wrapping;
  }

  public onTaxesSelected(taxes: any): void {
    // //this.logger.info("ProductComponent.onTaxesSelected() -> taxes: " + JSON.stringify(taxes));
    this.product.tax = taxes;
  }

  public onInvoiceCategorySelected(category: any): void {
    // //this.logger.info("ProductComponent.onInvoiceCategorySelected() -> category: " + JSON.stringify(category));
    this.product.invoice_category = category;
  }

  public onNavigateToOrder(event: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(this.product?.order));
  }

  public onCloneProduct(event: any): void {
    // //this.logger.info("onCloneProduct() -> uuid: " + this.product.uuid);
    if (this.isCloning) {
      // //this.logger.info("onCloneProduct() -> ALREADY CLONING. DENIED!!!");
      return;
    }

    this.isCloning = true;
    this.productsService.cloneProduct(this.product.uuid).subscribe(
      (response) => {
        // //this.logger.info("ProductComponent.onCloneProduct() -> SUCCESS: " + JSON.stringify(response));
        this.isCloning = false;
        if (response.data) {
          // this.router.navigateByUrl('/' + AppRoutes.PRODUCTS + "/" + response.data.slug + '/' + response.data.uuid);
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
        }
      },
      (error) => {
        this.logger.error(
          'ProductComponent.onCloneProduct() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
        this.isCloning = false;
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
      },
    );
  }

  public onCloneCombo(event: any): void {
    // //this.logger.info("onCloneCombo() -> uuid: " + this.product.uuid);
    if (this.isCloning) {
      // //this.logger.info("onCloneCombo() -> ALREADY CLONING. DENIED!!!");
      return;
    }

    this.isCloning = true;
    this.productsService.cloneCombo(this.product.uuid).subscribe(
      (response) => {
        // //this.logger.info("ProductComponent.onCloneCombo() -> SUCCESS: " + JSON.stringify(response));
        this.isCloning = false;
        if (response.data) {
          // this.router.navigateByUrl('/' + AppRoutes.PRODUCTS + "/" + response.data.slug + '/' + response.data.uuid);
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
        }
      },
      (error) => {
        this.logger.error(
          'ProductComponent.onCloneCombo() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
        this.isCloning = false;
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
      },
    );
  }

  public onCombo($event: any): void {
    // //this.logger.info("ProductComponent.onCombo()");
    this.comboDialogRef = this.dialog.open(CreateComboComponent, {
      width: '80%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: { product: this.product, products: null, creationMode: true },
    });

    this.comboDialogRef.componentInstance.confirmMessage = ''; // this.translate.instant("ROOMS.DELETE_CONFIRM");

    this.comboDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.loadProduct();
      }

      this.comboDialogRef = null;
    });
  }

  public onRemoveAllergen(item: any): void {
    // //this.logger.info("ProductComponent.onRemoveAllergen()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.allergensService.deattach(this.productID, item.uuid).subscribe(
          (response) => {
            //this.logger.info("ProductComponent.onRemoveAllergen() -> SUCCESS: " + JSON.stringify(response));
            // this.loadProduct();
            this.product.allergens = response.data.allergens;
          },
          (error) => {
            this.logger.error(
              'ProductComponent.onRemoveAllergen() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
          },
        );
      }
    });
  }

  public onRemoveProductionZone(item: any): void {
    // //this.logger.info("ProductComponent.onRemoveProductionZone()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.prodZoneService.deattach(this.productID, item.uuid).subscribe(
          (response) => {
            // //this.logger.info("ProductComponent.onRemoveProductionZone() -> SUCCESS: " + JSON.stringify(response));
            // this.loadProduct();
            this.product.production_zones = response.data.production_zones;
          },
          (error) => {
            this.logger.error(
              'ProductComponent.onRemoveProductionZone() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
          },
        );
      }
    });
  }

  public onRemoveUpsaleQuestion(item: any): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.upsaleService.deattach(this.productID, item.uuid).subscribe(
          (response) => {
            // this.loadProduct();
            this.product.upsale_questions = response.data.upsale_questions;
          },
          (error) => {
            this.logger.error(
              'ProductComponent.onRemoveUpsaleQuestion() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
          },
        );
      }
    });
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public onOpenProductInNewTab(product: any): void {
    window.open(ItemUrlFinder.getItemURL(product), '_blank');
  }

  public doPrint(url: string): void {
    this.print.printHTML(url).subscribe(
      (response: any) => {
        // //this.logger.info("ProductComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      (error: any) => {
        this.logger.error(
          'ProductComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public doDownload(url: string): void {
    this.fileService.downloadFile(url).subscribe(
      (response: any) => {
        // //this.logger.info("ProductComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      (error: any) => {
        this.logger.error(
          'ProductComponent.doDownload() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public onPrint(): void {
    const url: string =
      this.api.createUrl('/reports/products/html/') + this.product?.uuid;
    // this.print.printHTML(url);
    this.doPrint(url);
  }

  public onDownloadPDF(): void {
    const url: string =
      this.api.createUrl('/reports/products/pdf/') + this.product?.uuid;
    this.doDownload(url);
  }

  public onProductChildDrop(event: any): void {
    // //this.logger.info(
    //     "ProductComponent.onProductChildDrop() -> " +
    //         JSON.stringify(event.value)
    // );
    // //this.logger.info("ProductComponent.onProductChildDrop() -> " + JSON.stringify(this.product.childs));
    if (this.product?.childs.length < 2) {
      return;
    }

    let i = 0;
    const length: number = this.product?.childs.length;
    for (i; i < length; i++) {
      // // //this.logger.info("> " + i);
      if (this.product?.childs[i].uuid === event.value.uuid) {
        // // //this.logger.info("> OK : " + i);

        // // //this.logger.info("Combo UUID: " + this.product.uuid);
        // move before
        if (i === 0) {
          // //this.logger.info("Combo UUID: " + this.product.uuid);
          // //this.logger.info("productUUID: " + event.value.uuid);
          // //this.logger.info("beforeUUID: " + this.product.childs[i + 1].uuid);
          this.productsService
            .productChildMoveBefore(
              this.product.uuid,
              event.value.uuid,
              this.product?.childs[i + 1].uuid,
            )
            .subscribe(
              (response) => {
                // //this.logger.info("ProductComponent.onProductChildDrop() -> SUCCESS (productChildMoveBefore): " + JSON.stringify(response));
              },
              (error) => {
                this.logger.error(
                  'ProductComponent.onProductChildDrop() -> ERROR (productChildMoveBefore): ' +
                    JSON.stringify(error),
                );
                this.handleAPIError(error, this.dialog, null, null);
              },
            );
        } // move after
        else {
          // //this.logger.info("Combo UUID: " + this.product.uuid);
          // //this.logger.info("productUUID: " + event.value.uuid);
          // //this.logger.info("afterUUID: " + this.product.childs[i - 1].uuid);
          this.productsService
            .productChildMoveAfter(
              this.product.uuid,
              event.value.uuid,
              this.product?.childs[i - 1].uuid,
            )
            .subscribe(
              (response) => {
                // //this.logger.info("ProductComponent.onProductChildDrop() -> SUCCESS (productChildMoveBefore): " + JSON.stringify(response));
              },
              (error) => {
                this.logger.error(
                  'ProductComponent.onProductChildDrop() -> ERROR (productChildMoveBefore): ' +
                    JSON.stringify(error),
                );
                this.handleAPIError(error, this.dialog, null, null);
              },
            );
        }
        break;
      }
    }
  }

  public onEditCombo(): void {
    // //this.logger.info("ProductComponent.onEditCombo()");
    this.comboDialogRef = this.dialog.open(EditComboComponent, {
      width: '80%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: { product: this.product, products: this.product?.childs },
    });

    // TODO: replace with proper text
    this.comboDialogRef.componentInstance.confirmMessage =
      this.translate.instant('ROOMS.DELETE_CONFIRM');

    this.comboDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      }
      this.loadProduct();
      this.comboDialogRef = null;
    });
  }

  public onEditAllergens(): void {
    // //this.logger.info("ProductComponent.onEditAllergens()");
    this.comboDialogRef = this.dialog.open(DragDropListsComponent, {
      width: '80%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        parent: this.product,
        service: this.allergensService,
        title: this.translate.instant('PRODUCTS.ALLERGENS_DIALOG_TITLE'),
        labelDraggable: this.translate.instant(
          'PRODUCTS.ALLERGENS_COL_LEFT_LABEL',
        ),
        labelDroppable: this.translate.instant(
          'PRODUCTS.ALLERGENS_COL_RIGHT_LABEL',
        ),
      },
    });

    this.comboDialogRef.beforeClosed().subscribe((result: any) => {
      this.product.allergens =
        this.comboDialogRef.componentInstance.lastResponse.data.allergens;
    });

    this.comboDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        //this.logger.info("result: " + JSON.stringify(result));
        // this.loadProduct();
        // this.product.allergens = result
      }
      this.comboDialogRef = null;
    });
  }

  public onEditProductionsZone(): void {
    // //this.logger.info("ProductComponent.onEditAllergens()");
    this.comboDialogRef = this.dialog.open(DragDropListsComponent, {
      width: '80%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        parent: this.product,
        service: this.prodZoneService,
        title: this.translate.instant('PRODUCTS.PRODUCTION_ZONE_DIALOG_TITLE'),
        labelDraggable: this.translate.instant(
          'PRODUCTS.PRODUCTION_ZONE_COL_LEFT_LABEL',
        ),
        labelDroppable: this.translate.instant(
          'PRODUCTS.PRODUCTION_ZONE_COL_RIGHT_LABEL',
        ),
      },
    });

    this.comboDialogRef.beforeClosed().subscribe((result: any) => {
      this.product.production_zones =
        this.comboDialogRef.componentInstance.lastResponse.data.production_zones;
    });

    this.comboDialogRef.afterClosed().subscribe((result: any) => {
      // if (result) {
      //     this.loadProduct();
      // }
      this.comboDialogRef = null;
    });
  }

  public onEditProductUpsale(): void {
    // //this.logger.info("ProductComponent.onEditAllergens()");
    this.comboDialogRef = this.dialog.open(DragDropListsComponent, {
      width: '80%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        parent: this.product,
        service: this.upsaleService,
        title: this.translate.instant('UPSALE'),
        labelDraggable: this.translate.instant(
          'PRODUCTS.UPSALE_DRAG_AND_DROP_LEFT_LABEL',
        ),
        labelDroppable: this.translate.instant(
          'PRODUCTS.UPSALE_DRAG_AND_DROP_RIGHT_LABEL',
        ),
        serviceType: 'upsale',
      },
    });

    this.comboDialogRef.beforeClosed().subscribe((result: any) => {
      this.product.upsale_questions =
        this.comboDialogRef.componentInstance.lastResponse.data.upsale_questions;
    });

    this.comboDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.loadProduct();
      }
      this.comboDialogRef = null;
    });
  }

  public onComboShowChildNameChange(event: any): void {
    // // //this.logger.info("ProductComponent.onComboShowChildNameChange() ->");
  }

  public onComboShowChildQuantityChange(event: any): void {
    // // //this.logger.info("ProductComponent.onComboShowChildNameChange() ->");
  }

  public onComboShowChildPriceChange(event: any): void {
    // //this.logger.info("ProductComponent.onComboShowChildPriceChange() -> " + event.checked);
  }

  public onProductActiveChange(event: any): void {
    // //this.logger.info("ProductComponent.onProductActiveChange() -> " + event.checked);
  }
  public onProductAlcoholicStateChange(event: any): void {
    // //this.logger.info("ProductComponent.onProductAlcoholicStateChange() -> " + event.checked);
  }

  public onNavigateToProduct(item: any): void {
    // //this.logger.info("ProductComponent.onComboShowChildNameChange() -> item: " + JSON.stringify(item));
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
    this.mainContainer.nativeElement.scrollTop = 0;
  }

  public toggleHistoricSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  public onShowConfirmedInfo(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useHTML = true;
    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle = this.translate.instant(
      'GENERIC.CONFORMED_INFO_TITLE',
    );
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.CONFORMED_INFO_DESCRIPTION');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {});
  }

  public onOpenObjectPage(item: any): void {
    // //this.logger.info("ProductComponent.onOpenObjectPage() -> item: " + JSON.stringify(item));
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public onShopProductEvent(event: ProductModel): void {
    //this.logger.info("ProductComponent.onShopProductEvent() -> event product: " + JSON.stringify(event));
    this.product = event;
  }

  public onShopCheckboxEvent(event: ShopsCheckbox): void {
    //this.logger.info("ProductComponent.onShopCheckboxEvent() -> event: " + JSON.stringify(event));
    // this.loadingShop = true;
    // event.loading = true;
    // this.loadingShop[event.uuid] = true;
    this.productShop?.setItemLoading(event.uuid, true);
    if (event.checked) {
      this.productsService.attachShop(this.product.uuid, event.uuid).subscribe(
        (response: any) => {
          //this.logger.info("ProductComponent.onShopCheckboxEvent().attachShop() -> SUCCESS : " + JSON.stringify(response));
          this.productShop?.setItemLoading(event.uuid, false);
          this.product.shops = response.data.shops;
        },
        (error: any) => {
          this.logger.error(
            'ProductComponent.onShopCheckboxEvent().attachShop() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.productShop?.setItemLoading(event.uuid, false);
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
    } else {
      this.productsService.dettachShop(this.product.uuid, event.uuid).subscribe(
        (response: any) => {
          //this.logger.info("ProductComponent.onShopCheckboxEvent().attachShop() -> SUCCESS : " + JSON.stringify(response));
          this.product.shops = response.data.shops;
          this.productShop?.setItemLoading(event.uuid, false);
        },
        (error: any) => {
          this.logger.error(
            'ProductComponent.onShopCheckboxEvent().attachShop() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.productShop?.setItemLoading(event.uuid, false);
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
    }
  }

  public onUpsale(): void {
    const url: string = ItemUrlFinder.getItemURL(this.product) + '/upsale';
    //this.logger.info("> url: " + url);
    this.router.navigateByUrl(url);
  }

  public onCyclicValueChange(event: any): void {
    this.loading = true;
    this.productsService
      .updateProduct(this.product, this.product.uuid)
      .subscribe(
        (response: any) => {
          // //this.logger.info(
          //     "ProductComponent.onCyclicValueChange() -> SUCCESS: " +
          //         JSON.stringify(response)
          // );
          this.loading = false;
          this.product = response.data;
        },
        (error: any) => {
          this.loading = false;
          // //this.logger.info("ProductComponent.onCyclicValueChange() -> ERROR: " + JSON.stringify(error));
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public onChoiceProductComboValueChange(event: any): void {
    this.loading = true;
    this.productsService
      .updateProduct(this.product, this.product.uuid)
      .subscribe(
        (response: any) => {
          // //this.logger.info(
          //     "ProductComponent.onCyclicValueChange() -> SUCCESS: " +
          //         JSON.stringify(response)
          // );
          this.loading = false;
          this.product = response.data;
        },
        (error: any) => {
          this.loading = false;
          // //this.logger.info("ProductComponent.onCyclicValueChange() -> ERROR: " + JSON.stringify(error));
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }
}
