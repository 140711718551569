export const locale = {
  lang: "fr-CA",
  data: {
    ADDRESS: {
      ADDRESS: "Adresse",
      SEARCH_ADDRESS: "Rechercher une adresse",
      SEE_ON_GOOGLE_MAP: "Voir sur Google Map",
      NO_GEO_LOCATION: "Pas de permission pour le Geolocation.",
      ADDRESS_NUMBER: "Numéro",
      STREET: "Rue",
      APP_NUMBER: "# App",
      LINE_2: "Adresse ligne 2",
      CITY: "Ville",
      PROVINCE: "Province/État",
      COUNTRY: "Pays",
      POSTAL_CODE: "Code Postal/Zip",
      SECTOR: "Secteur",
      NO_ADDRESS_INFO: "Aucune information pour cette adresse",
      NAME: "Nom du lieu",
      ON_SITE_CONTACT: "Livraison et personne à contacter sur le site",
    },
  },
};
