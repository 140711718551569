export const locale = {
  lang: "fr-CA",
  data: {
    ROOM_MANAGEMENT: {
      TITLE: "Gestion des salles",
      CREATE_TITLE: "Nouveau",
      CREATE_TITLE_FROM_RESERVATION: "Créer un événement pour cette réservation",
      ORDER_TYPE: "Type",
      ROOM_NAME: "Nom de la salle",
      PLACE_NAME: "Nom du lieu",
      NOTE: "Note: Il n'y a aucun lieu interne",
      EDIT_RESERVATION_TITLE: "Modifier cette réservation",
      DELETE_RESERVATION_TITLE: "Effacer cette réservation",
    },
  },
};
