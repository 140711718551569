export const locale = {
  lang: "en-CA",
  data: {
    CREATED_AT: {
      CREATED_ON: "Created on",
      BY: "by",
      LAST_MODIFICATION: "Last modification on",
    },
  },
};
