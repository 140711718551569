import { DomSanitizer } from '@angular/platform-browser';
import { TestFromRequestResult } from './../../../api/test-error.service';
import { NGXLogger } from 'ngx-logger';
import { ReportViewerComponent } from './../../../reports/report-viewer/report-viewer.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';

@Component({
  selector: 'app-test-error-result',
  templateUrl: './test-error-result.component.html',
  styleUrls: ['./test-error-result.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatTabsModule,
    MatButtonModule,
    TranslateModule,
    MatInputModule,
    Gt2PrimengModule,
    MatDialogModule,
  ],
})
export class TestErrorResultComponent implements OnInit {
  testFromRequestResult?: TestFromRequestResult;
  testFromRequestResultRawHTML: any;
  testFromRequestResultBody?: string;
  testFromRequestResultError: any;
  constructor(
    public dialogRef: MatDialogRef<ReportViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private logger: NGXLogger,
    public sanitizer: DomSanitizer,
  ) {
    if (data && data.testFromRequestResult) {
      this.testFromRequestResult = data.testFromRequestResult;
      this.testFromRequestResultRawHTML =
        this.sanitizer.bypassSecurityTrustHtml(
          this.testFromRequestResult?.result.error,
        );

      if (this.testFromRequestResult?.request.body) {
        this.testFromRequestResultBody = JSON.stringify(
          this.testFromRequestResult.request.body,
        );
      }

      this.testFromRequestResultError = JSON.parse(
        this.testFromRequestResult?.result.error.replace(/\\/g, ''),
      );
      //.replace(/\\"/g, '"');
      //  null, 2;
      // if (this.testFromRequestResultError) {
      //     this.testFromRequestResultError = JSON.parse(
      //         this.testFromRequestResultError
      //     );
      // }
    }
    //this.logger.info("TestErrorResultComponent().constructor() -> data:  " + this.testFromRequestResultError);

    //this.logger.info("TestErrorResultComponent().constructor() -> typeof:  " + typeof this.testFromRequestResult.result.error);
  }

  ngOnInit() {}
}
