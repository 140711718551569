<div id="contact" class="p-16" fxLayout="column">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    class="contact-content"
  >
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "CONTACT.ORG_EMAIL" | translate }}</div>
      <div *ngIf="groupModel && groupModel.email_organisation" class="info">
        <a href="{{ 'mailto:' + groupModel.email_organisation }}">{{
          groupModel.email_organisation_label
        }}</a>
      </div>
      <div
        *ngIf="!groupModel || !groupModel.email_organisation"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "CONTACT.ORG_EMAIL" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'CONTACT.ORG_EMAIL' | translate }}"
              formControlName="email_organisation"
              type="email"
              [(ngModel)]="groupModel.email_organisation"
            />
            <mat-error *ngIf="form.controls['email_organisation'].invalid"
              >{{ "GENERIC.EMAIL_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "CONTACT.EMAIL" | translate }}</div>
      <div *ngIf="groupModel && groupModel.email_personnal" class="info">
        <a href="{{ 'mailto:' + groupModel.email_personnal }}">{{
          groupModel.email_personnal_label
        }}</a>
      </div>
      <div
        *ngIf="!groupModel || !groupModel.email_personnal"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "CONTACT.EMAIL" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'CONTACT.EMAIL' | translate }}"
              formControlName="email_personnal"
              type="email"
              [(ngModel)]="groupModel.email_personnal"
            />
            <mat-error *ngIf="form.controls['email_personnal'].invalid"
              >{{ "GENERIC.EMAIL_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    class="contact-content"
  >
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "CONTACT.PHONE" | translate }}</div>
      <div *ngIf="groupModel && groupModel.telephone_home" class="info">
        <a href="{{ 'tel:' + groupModel.telephone_home }}">{{
          groupModel.telephone_home
        }}</a>
      </div>
      <div
        *ngIf="!groupModel || !groupModel.telephone_home"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "CONTACT.PHONE" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'CONTACT.PHONE' | translate }}"
              formControlName="telephone_home"
              [(ngModel)]="groupModel.telephone_home"
              type="tel"
            />
            <mat-error *ngIf="form.controls['telephone_home'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "CONTACT.MOBILE" | translate }}</div>
      <div *ngIf="groupModel && groupModel.mobile" class="info">
        <a href="{{ 'tel:' + groupModel.mobile }}">{{ groupModel.mobile }}</a>
      </div>
      <div
        *ngIf="!groupModel || !groupModel.mobile"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "CONTACT.MOBILE" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'CONTACT.MOBILE' | translate }}"
              formControlName="mobile"
              [(ngModel)]="groupModel.mobile"
              type="tel"
            />
            <mat-error *ngIf="form.controls['mobile'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    class="contact-content"
  >
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "CONTACT.OFFICE_PHONE" | translate }}</div>
      <div *ngIf="groupModel && groupModel.telephone_office" class="info">
        <a href="{{ 'tel:' + groupModel.telephone_office }}">{{
          groupModel.telephone_office
        }}</a>
      </div>
      <div
        *ngIf="!groupModel || !groupModel.telephone_office"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "CONTACT.OFFICE_PHONE" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'CONTACT.OFFICE_PHONE' | translate }}"
              formControlName="telephone_office"
              type="tel"
              [(ngModel)]="groupModel.telephone_office"
            />
            <mat-error *ngIf="form.controls['telephone_office'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "CONTACT.OFFICE_EXT" | translate }}</div>
      <div *ngIf="groupModel && groupModel.telephone_office_ext" class="info">
        {{ groupModel.telephone_office_ext }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.telephone_office_ext"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="40%">
            <mat-label>{{ "CONTACT.OFFICE_EXT" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'CONTACT.OFFICE_EXT' | translate }}"
              formControlName="telephone_office_ext"
              type="text"
              [(ngModel)]="groupModel.telephone_office_ext"
            />
            <mat-error *ngIf="form.controls['telephone_office_ext'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID_20" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    class="contact-content"
  >
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "CONTACT.PHONE_OTHER" | translate }}</div>
      <div *ngIf="groupModel && groupModel.telephone_other" class="info">
        <a href="{{ 'tel:' + groupModel.telephone_other }}">{{
          groupModel.telephone_other
        }}</a>
      </div>
      <div
        *ngIf="!groupModel || !groupModel.telephone_other"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "CONTACT.PHONE_OTHER" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'CONTACT.PHONE_OTHER' | translate }}"
              formControlName="telephone_other"
              [(ngModel)]="groupModel.telephone_other"
              type="tel"
            />
            <mat-error *ngIf="form.controls['telephone_other'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "CONTACT.FAX" | translate }}</div>
      <div *ngIf="groupModel && groupModel.fax" class="info">
        <a href="{{ 'tel:' + groupModel.fax }}">{{ groupModel.fax }}</a>
      </div>
      <div
        *ngIf="!groupModel || !groupModel.fax"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "CONTACT.FAX" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'CONTACT.FAX' | translate }}"
              formControlName="fax"
              [(ngModel)]="groupModel.fax"
              type="text"
            />
            <mat-error *ngIf="form.controls['fax'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    class="contact-content"
  >
    <div *ngIf="!editMode" class="info-line pr-8" fxFlex="50%">
      <div class="title">{{ "CONTACT.SKYPE" | translate }}</div>
      <div *ngIf="groupModel && groupModel.skype" class="info">
        {{ groupModel.skype }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.skype"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line pr-4" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "CONTACT.SKYPE" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'CONTACT.SKYPE' | translate }}"
              formControlName="skype"
              [(ngModel)]="groupModel.skype"
              type="text"
            />
            <mat-error *ngIf="form.controls['skype'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "CONTACT.EMAIL_CC" | translate }}</div>
      <div *ngIf="groupModel && groupModel.email_cc" class="info">
        <a href="{{ 'mailto:' + groupModel.email_cc }}">{{
          groupModel.email_cc_label
        }}</a>
      </div>
      <div
        *ngIf="!groupModel || !groupModel.email_cc"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "CONTACT.EMAIL_CC" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'CONTACT.EMAIL_CC' | translate }}"
              formControlName="email_cc"
              type="email"
              [(ngModel)]="groupModel.email_cc"
            />
            <mat-error *ngIf="form.controls['email_cc'].invalid"
              >{{ "GENERIC.EMAIL_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="editMode && !hideSaveButton"
    fxLayout="row"
    fxLayoutAlign="end start"
    class="mt-16"
  >
    <button
      (click)="onManualSave()"
      color="accent"
      class="save-btn"
      [disabled]="form.invalid"
      mat-raised-button
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
  </div>
</div>
