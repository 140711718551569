export const locale = {
  lang: "fr-CA",
  data: {
    REPORTS: {
      TITLE: "Rapports",
      SALE_STATISTIC: "Statistique des ventes",
      PRODUCTS_SOLD_LIST: "Liste des produits vendus",
      PRODUCT_CATEGORY: "Catégories de produit",
      EVENT_TYPE: "Type d'événement",
      SELLER: "Conseillers",
      DELIVERY_TYPE: "Types de livraison",
      PRODUCT_VELOCITY: "Rapport de vélocité des produits",
      ACCOUNTING: "Comptabilité",
      ACCOUNTING_UNTIL: "Comptabilité jusqu'aux",
      ACCOUNTING_TODAY: "Comptabilité aujourd'hui",
      SALE_BY_LEDGER_ACCOUNT: "Vente par compte de grand livre",
      SALE_BY_LEDGER_ACCOUNT_JR: "BETA - Vente par compte de grand livre",
      SALE_BY_LEDGER_ACCOUNT_UQAM: "Vente par compte de grand livre - UQAM",
      SALE_BY_LEDGER_ACCOUNT_PER_DAY: "Vente par compte de grand livre par jour ",
      SALE_BY_LEDGER_ACCOUNT_PER_DAY_HINT: "Maximum 7 jours",
      SOLD_PRODUCTS_LIST_HINT: "Maximum 31 jours",
      EMPLOYEE_ASSIGNMENTS_HINT: "Maximum 31 jours",
      EMPLOYEE_ASSIGNMENTS_HINT2: "Sélectionner une période de 14 jours",
      AGE_OF_CLIENT_ACCOUNT: "Âge des comptes client",
      AGE_OF_ENTERPRISE_ACCOUNT: "Âge des comptes Entreprise",
      AGE_ACCOUNTS_REPORT: "Âge des comptes",
      CALCULATED_CLIENT_ENTERPRISE_BALANCE: "Calcul solde des comptes client/entreprise",
      INVOICES_LIST: "Liste des factures",
      DEPOSIT_TRACKING: "Suivi des dépôts",
      RECEIPTS_JOURNAL: "Journal des encaissements",
      ACCOUNTING_SUMMARY_PAYMENTS: "Résumé des encaissements",
      ACCOUNTING_SYSTEM_CONTACTS: "Contacts système comptables",
      ACCOUNTING_SYSTEM_INVOICES: "Factures système comptables",
      ACCOUNTING_SYSTEM_LOGS: "Entrée journal système comptable",
      CASH_DEPOSIT_BEFORE_INVOICE_AT: "Liste des dépôts encaissés des événements non facturés",
      DATE_OF: "En date du",
      EMPLOYEES: "Personnel",
      EMPLOYEES_ASSIGNMENT: "Paie affectation de personnel",
      JR_PRODUCT_CATEGORY: "Catégories de produit JR",
      SCHEDULE_PER_DATE: "Horaire affectations par date",
      PAYMENT_DATA_NETHRIS: "Données de paie Nethris - JR",
      SUMMARIES_OF_EVENTS: "Sommaires des événements",
      PROVISIONAL: " (Prévisionnel)",
      INTERNAL_PAYMENT: "Paiement interne",
      AGE_ACCOUNTS_REPORT_BTF: "Âge des comptes - encaissement concilié",
      REPORT_ADMIN_ACCOUNTING_DEPOSITE_TRACKING_OKA: "Suivi des paiements finaux des événements - ADO",
      REPORT_LIST_DEPOSITS_COLLECTED_FROM_BILLED_EVENTS: "Liste des dépôts encaissés des événements facturés",
    },
    REPORTS_HELP: {
      REPORT_AGE_ACCOUNTS: "Ce rapport inclus les clients corporatifs et particuliers",
      TITLE: "Rapports",
      REPORT_ADMIN_SALE_STATS_BY_DELIVERY_TYPE:
        "Présente la somme net des articles commandés par catégories sur les événements confirmés, facturés ou payés.<br><br>Ne tient pas compte des factures d'ajustements et des notes de crédit.",
      REPORT_ADMIN_SALE_STATS_BY_ENTERPRISE_PRELIMINARY: "Présente la somme net des articles commandés par entreprises sur les commandes non facturées et facturées.",
      REPORT_ADMIN_SALE_STATS_BY_ENTERPRISE: "Présente la somme net des articles commandés par entreprises sur les commandes facturées.",
    },
  },
};
