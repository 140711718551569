import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '../../../api/user-settings.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { LedgerAccountService } from '../../../api/ledger-account.service';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-ledger-account-default',
  templateUrl: './ledger-account-default.component.html',
  styleUrls: ['./ledger-account-default.component.scss'],
  standalone: true,
  imports: [
    Gt2SelectComponent,
    TranslateModule,
    FlexLayoutModule,
    MatIconModule,
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class LedgerAccountDefaultComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() settingsModel: any;

  constructor(
    public router: Router,
    private logger: NGXLogger,
    private toastr: ToastrService,
    public translate: TranslateService,
    public ledgerAccountService: LedgerAccountService,
    public settingsService: UserSettingsService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    //this.logger.info("LedgerAccountDefaultComponent.ngAfterViewInit() -> : " + JSON.stringify(this.settingsModel));
  }

  ngOnDestroy() {}

  public onLedgerFoodSelected(ledgerAccount: any): void {
    //this.logger.info("LedgerAccountDefaultComponent.onLedgerFoodSelected() -> ledgerAccount: " + JSON.stringify(ledgerAccount));
    this.settingsModel.ledger_account_food = ledgerAccount;
  }

  public onLedgerDrinkSelected(ledgerAccount: any): void {
    //this.logger.info("LedgerAccountDefaultComponent.onLedgerDrinkSelected() -> ledgerAccount: " + JSON.stringify(ledgerAccount));
    this.settingsModel.ledger_account_drink = ledgerAccount;
  }

  public onLedgerMaterialSelected(ledgerAccount: any): void {
    //this.logger.info("LedgerAccountDefaultComponent.onLedgerMaterialSelected() -> ledgerAccount: " + JSON.stringify(ledgerAccount));
    this.settingsModel.ledger_account_material = ledgerAccount;
  }

  public onLedgerServiceChargeSelected(ledgerAccount: any): void {
    //this.logger.info("LedgerAccountDefaultComponent.onLedgerServiceChargeSelected() -> ledgerAccount: " + JSON.stringify(ledgerAccount));
    this.settingsModel.ledger_account_service_charge = ledgerAccount;
  }

  public onLedgerStaffChargeSelected(ledgerAccount: any): void {
    //this.logger.info("LedgerAccountDefaultComponent.onLedgerStaffChargeSelected() -> ledgerAccount: " + JSON.stringify(ledgerAccount));
    this.settingsModel.ledger_account_staff_charge = ledgerAccount;
  }

  public onLedgerDeliverySelected(ledgerAccount: any): void {
    //this.logger.info("LedgerAccountDefaultComponent.onLedgerDeliverySelected() -> ledgerAccount: " + JSON.stringify(ledgerAccount));
    this.settingsModel.ledger_account_delivery = ledgerAccount;
  }

  public onLedgerRoyaltySelected(ledgerAccount: any): void {
    //this.logger.info("LedgerAccountDefaultComponent.onLedgerRoyaltySelected() -> ledgerAccount: " + JSON.stringify(ledgerAccount));
    this.settingsModel.ledger_account_royalty = ledgerAccount;
  }

  public onLedgerDiscountSelected(ledgerAccount: any): void {
    //this.logger.info("LedgerAccountDefaultComponent.onLedgerDiscountSelected() -> ledgerAccount: " + JSON.stringify(ledgerAccount));
    this.settingsModel.ledger_account_discount = ledgerAccount;
  }

  public onLedgerTipNoTaxSelected(ledgerAccount: any): void {
    //this.logger.info("LedgerAccountDefaultComponent.onLedgerTipNoTaxSelected() -> ledgerAccount: " + JSON.stringify(ledgerAccount));
    this.settingsModel.ledger_account_tips_no_tax = ledgerAccount;
  }

  public onLedgerTaxSelected(ledgerAccount: any): void {
    //this.logger.info("LedgerAccountDefaultComponent.onLedgerTaxSelected() -> ledgerAccount: " + JSON.stringify(ledgerAccount));
    this.settingsModel.ledger_account_tax = ledgerAccount;
  }

  public onSaveData(): void {
    //this.logger.info("LedgerAccountDefaultComponent.onSaveData() -> ");
    this.settingsService
      .updateDefaultTaxes(this.settingsModel, this.settingsModel.uuid)
      .subscribe(
        (response: any) => {
          //this.logger.info("LedgerAccountDefaultComponent.onSaveData() SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
        },
        (error: any) => {
          //this.logger.info("LedgerAccountDefaultComponent.onSaveData() ERROR: " + JSON.stringify(error));
          this.toastr.error(
            '',
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      );
  }
}
