import { MatSort, MatSortable } from '@angular/material/sort';
import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

@Injectable()
export class DatatableHelperService {
  static LS_LAST_SORT: string = 'LS_LAST_SORT_';
  static LS_LAST_SORT_DIRECTION: string = 'LS_LAST_SORT_DIRECTION_';
  static PAGE_SIZE_LOCAL_STORAGE: string = 'PAGE_SIZE_LOCAL_STORAGE_1';

  short: string = 'short';
  large: string = 'large';

  // pageSize: number = 25;
  pageSizeOptions: number[] = [25, 50, 100, 250, 500];

  // pageSize: number = 25;
  pageSizeMedium2: number[] = [10, 15, 25, 50, 100];

  // pageSizeShort: number = 5;
  pageSizeShortOptions: number[] = [5, 10, 15];

  // pageSizeMedium: number = 10;
  pageSizeMediumOptions: number[] = [10, 15, 25];

  shortDefault: number = 5;
  largeDefault: number = 25;
  pageSizes: any = {};

  constructor(private logger: NGXLogger) {
    const value = localStorage.getItem(
      DatatableHelperService.PAGE_SIZE_LOCAL_STORAGE
    );
    if (value) {
      this.pageSizes = JSON.parse(value);
    }
  }

  public savePageSizes(): void {
    localStorage.setItem(
      DatatableHelperService.PAGE_SIZE_LOCAL_STORAGE,
      JSON.stringify(this.pageSizes)
    );
  }

  public onPaginator(event: any, key: string): void {
    // this.logger.info("DatatableHelperService.onPaginator() ");
    this.pageSizes[key] = event.pageSize;
    this.savePageSizes();
  }

  public setSort(
    sort: MatSort,
    tableID: string,
    defaultActive: string,
    defaultDirection: string
  ): void {
    // this.logger.info('DatatableHelperService.setSort() -> clientsSort: ' + sort);
    if (sort) {
      const lastSort =
        localStorage.getItem(DatatableHelperService.LS_LAST_SORT + tableID) ||
        defaultActive;
      const lastSortDir =
        localStorage.getItem(
          DatatableHelperService.LS_LAST_SORT_DIRECTION + tableID
        ) || defaultDirection;
      // this.logger.info('DatatableHelperService.setSort() -> lastSort: ' + lastSort);
      // this.logger.info('DatatableHelperService.setSort() -> lastSortDir: ' + lastSortDir);
      sort.sort({ id: lastSort, start: lastSortDir } as MatSortable);
    } else {
      this.logger.error(
        'DatatableHelperService.setSort() -> ERROR: sort is undefined'
      );
    }
  }

  public updateSort(tableID: string, active: string, direction: string): void {
    localStorage.setItem(DatatableHelperService.LS_LAST_SORT + tableID, active);
    localStorage.setItem(
      DatatableHelperService.LS_LAST_SORT_DIRECTION + tableID,
      direction
    );
  }
}
