<div>
  <mat-table
    #table
    class=""
    matSort
    matSortStart="desc"
    [@animateStagger]="{ value: '50' }"
    [dataSource]="dataSource"
  >
    <!-- Name Column -->
    <ng-container
      *ngFor="let col of displayedColumns; let i = index"
      cdkColumnDef="{{ displayedColumns[i] }}"
    >
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{
        displayedColumnsHeaderName[i]
      }}</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <div>
          {{ row[displayedColumns[i]] }}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *cdkRowDef="let row; columns: displayedColumns"
      (click)="onSelect(row)"
      [@animate]="{ value: '*', params: { y: '100%' } }"
    >
    </mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [length]="resultsLength"
    showFirstLastButtons="true"
    [pageIndex]="0"
    (page)="datatableHelperService.onPaginator($event, 'app-dynamic-table')"
    [pageSize]="'app-dynamic-table' | tableSizePipe: 'short'"
    [pageSizeOptions]="datatableHelperService.pageSizeShortOptions"
  >
  </mat-paginator>
</div>
