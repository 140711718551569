import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CreateClientService } from '../../../api/create-client.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Settings } from '../../../settings/settings';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { Gt2LocaleService } from '../../../../localization/gt2-locale.service';
import { locale as englishAddress } from '../../address/i18n/en-CA';
import { locale as frenchAddress } from '../../address/i18n/fr-CA';
import { locale as englishContact } from '../../contact/i18n/en-CA';
import { locale as frenchContact } from '../../contact/i18n/fr-CA';
import { ClientAttachEnterpriseTablePaginatedComponent } from '../client-attach-enterprise-table-paginated/client-attach-enterprise-table-paginated.component';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Gt2SelectComponent } from '../../gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { EditorModule } from 'primeng/editor';
@Component({
  selector: 'app-create-client-with-details',
  templateUrl: './create-client-with-details.component.html',
  styleUrls: ['./create-client-with-details.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    Gt2SelectComponent,
    ReactiveFormsModule,
    MatButtonModule,
    EditorModule,
    MatDialogModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    TranslateModule,
  ],
})
export class CreateClientWithDetailsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static CREATE_FOR_BRANCH: string = 'CREATE_FOR_BRANCH';
  static CREATE_FOR_ENTERPRISE: string = 'CREATE_FOR_ENTERPRISE';

  form: UntypedFormGroup;
  client: any;
  loading: boolean = false;
  type: string;
  uuid?: string;
  userSettings: any = {
    inputPlaceholderText: '',
    showRecentSearch: true,
  };
  noGeoLocation: boolean = false;
  addressPrefillDirty: boolean = false;
  dialogRefModal: any;
  navigateToClient: boolean = true;
  @ViewChild('autocompleteInput', { static: false })
  autocompleteInput!: ElementRef;
  //@ts-ignore
  autocomplete!: google.maps.places.Autocomplete;
  //
  constructor(
    public override dialogRef: MatDialogRef<CreateClientWithDetailsComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private createClientService: CreateClientService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public gt2LocalService: Gt2LocaleService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.translationLoader.loadTranslations(englishAddress, frenchAddress);
    this.translationLoader.loadTranslations(englishContact, frenchContact);
    this.userSettings.inputPlaceholderText = this.translate.instant(
      'GENERIC.GOOGLE_ADDRESS_PLACEHOLDER',
    );
    if (data) {
      this.type = data.type;
      this.uuid = data.uuid;
    } else {
      this.type = CreateClientWithDetailsComponent.CREATE;
    }
    //
    if (data && data.navigateToClient !== null) {
      this.navigateToClient = data.navigateToClient;
    }
    //this.logger.info("AddressComponent.constructor() --> navigateToClient: " + this.navigateToClient);
    //this.logger.info("AddressComponent.constructor() --> data: " + data.navigateToClient);
    //
    this.form = this.formBuilder.group({
      first_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      last_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      professional_email: ['', [Validators.email]],
      telephone_office: [
        '',
        [Validators.minLength(2), Validators.maxLength(100)],
      ],
      telephone_office_ext: [
        '',
        [Validators.minLength(2), Validators.maxLength(20)],
      ],
      street_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.required,
        ],
      ],
      street: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      apartment: '',
      line2: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      city: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      province: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      country: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      postal_code: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.client = {
      first_name: null,
      last_name: null,
      note: null,
      contact: {
        email_organisation: null,
        telephone_office: null,
        telephone_office_ext: null,
      },
      address: {
        street_number: null,
        street: null,
        apartment: null,
        line2: null,
        city: null,
        province: null,
        country: null,
        postal_code: null,
      },
    };
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.initializeAutocomplete();
  }
  ngOnDestroy() {}

  initializeAutocomplete(): void {
    //@ts-ignore
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.nativeElement,
      {
        types: ['geocode'],
        componentRestrictions: { country: 'ca' },
      },
    );

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      if (place && place.address_components) {
        this.noGeoLocation = false;
        this.extractAddressComponents(place);
        this.addressPrefillDirty = true;
      } else {
        this.noGeoLocation = true;
        return;
      }
    });
  }

  extractAddressComponents(
    //@ts-ignore
    place: google.maps,
  ): any {
    //@ts-ignore
    let addressComponents: google.maps.GeocoderAddressComponent[] =
      place.address_components;
    let street_number = '';
    let street = '';
    let city = '';
    let country = '';
    let postal_code = '';
    let province = '';
    let full_address = place.formatted_address;
    let lat = place.geometry.location.lat();
    let long = place.geometry.location.lng();
    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes('street_number')) {
        street_number = component.long_name;
      } else if (types.includes('route')) {
        street = component.long_name;
      } else if (types.includes('locality')) {
        city = component.long_name;
      } else if (types.includes('country')) {
        country = component.long_name;
      } else if (types.includes('postal_code')) {
        postal_code = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        province = component.long_name;
      }
    });

    this.client.address = {
      ...this.client.address,
      street_number,
      street,
      city,
      country,
      postal_code,
      province,
      full_address,
      lat,
      long,
    };
    this.cdr.detectChanges();
  }

  public onCreateClient(): void {
    this.loading = true;
    this.createClientService.createClient(this.client).subscribe({
      next: (response: any) => {
        // this.logger.info("CreateClientComponent.onCreateClient() -> SUCCESS: " + JSON.stringify(response));
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_CLIENT'),
        );
        this.dialogRef.close(response.data);
        this.loading = false;
        if (this.navigateToClient) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
        }
      },
      error: (error: any) => {
        this.logger.error(
          'CreateClientComponent.onCreateClient() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.CREATE_CLIENT'),
        );
      },
    });
  }

  public onCreateClientAndAttach(): void {
    this.loading = true;
    this.createClientService.createClient(this.client).subscribe({
      next: (response: any) => {
        // this.logger.info("CreateClientComponent.onCreateClient() -> SUCCESS: " + JSON.stringify(response));
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_CLIENT'),
        );
        this.dialogRef.close(response.data);
        this.loading = false;
        this.onAttachEnterprise(response.data);
        // this.router.navigateByUrl(
        //     ItemUrlFinder.getItemURL(response.data)
        // );
      },
      error: (error: any) => {
        this.logger.error(
          'CreateClientComponent.onCreateClient() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.CREATE_CLIENT'),
        );
      },
    });
  }

  public onLocalSelected(event: any): void {
    this.client.communication_local = event;
    // this.autosaveDataChange({
    //     communication_local: this.client.communication_local,
    // });
  }

  public onAttachEnterprise(client: any) {
    // this.logger.info("ClientComponent.onAttachEnterprise()");
    // ClientAttachEnterpriseComponent
    this.dialogRefModal = this.dialog.open(
      ClientAttachEnterpriseTablePaginatedComponent,
      {
        width: '80%',
        minWidth: 350,
        maxHeight: '98vh',
        panelClass: 'table-dialog-container',
        disableClose: false,
        data: { client: client },
      },
    );
    this.dialogRefModal.componentInstance.alertMessage = 'Fonction à venir'; // this.translate.instant("CLIENTS_CATEGORIES.DELETE_CONFIRM", {value: selected.name});
    // this.dialogRef.componentInstance.showConfirmButton = false;

    this.dialogRefModal.afterClosed().subscribe({
      next: (result: any) => {
        //this.logger.info("CreateClientComponent.onCreateClient() -> SUCCESS result: " + JSON.stringify(result));
        if (result) {
          // this.logger.info("ATTACH SUCCESS -> NEW CLIENT MODEL RECEIVED!");
          // this.client = result.data;
          this.dialogRef.close(result.data);
        }
        this.dialogRefModal = null;
      },
    });
  }
}
