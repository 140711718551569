import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
// TODO: this import is the proper one but if i use it and decommand
//       the wrong one, QuickPanelComponent throw an error : Property 'combineLatest' does not exist on type 'typeof Observable'.
// import { Observable } from 'rxjs';
import { Observable } from 'rxjs';
import { IGetDataService } from '../components/gt2-select/gt2-select.component';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import { MetaModelData } from './models/MetaModelData';

@Injectable()
export class ClientsCategoriesService implements IGetDataService {
  clientsCategory?: ClientsCategoryModel[];

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getClientCategoriesData() {
    // this.logger.info("ClientsCategoriesService.getClientCategoriesData()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.CLIENTS_CATEGORIES));
  }

  public getClientCategoriesDataList() {
    // this.logger.info("ClientsCategoriesService.getClientCategoriesDataList()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.CLIENTS_CATEGORIES_LIST)
    );
  }

  public getData(): Observable<any> {
    // this.logger.info("ClientsCategoriesService.getData()");
    return this.getClientCategoriesDataList();
  }

  public getAll(
    sort: string,
    direction: string,
    page: number,
    pageSize: number
  ): Promise<ClientsCategoriesResponse> {
    // this.logger.info("ClientsCategoriesService.getAll()");
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.CLIENTS_CATEGORIES));
    const routeURL: string = this.api.createUrl(ApiRoutes.CLIENTS_CATEGORIES);
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe((response: any) => {
        this.clientsCategory = response.data;
        // this.logger.info("ClientsCategoriesService.getAll() -> this.clientsCategory: " + JSON.stringify(this.clientsCategory));
        // this.logger.info("ClientsCategoriesService.getAll() -> this.response: " + JSON.stringify(response));
        resolve(response);
      }, reject);
    });
  }

  public createCategory(categoryName: string): any {
    // this.logger.info("ClientsCategoriesService.getAll()");
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CLIENTS_CATEGORIES),
      { name: categoryName }
    );
  }

  public editCategory(selected: ClientsCategoryModel): any {
    // this.logger.info("ClientsCategoriesService.getAll()");
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.CLIENTS_CATEGORIES) + '/' + selected.uuid,
      selected
    );
  }

  public deleteCategories(selected: ClientsCategoryModel): any {
    // this.logger.info("ClientsCategoriesService.getAll()");
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.CLIENTS_CATEGORIES) + '/' + selected.uuid
    );
  }
}

export interface ClientsCategoriesResponse {
  data: ClientsCategoryModel[];
  meta: MetaModelData;
}

export interface ClientsCategoryModel {
  object: string;
  uuid: string;
  name: string;
  editable?: boolean;
}
