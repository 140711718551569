<div id="eventPrograms">
  <div fxLayout="row" fxLayout="start center" class="section-list-header">
    <!-- <div fxFlex="50px" class=""></div> -->
    <div fxFlex="17" class="section-list-item-border-right pl-8 py-4">
      {{ "GENERIC.DATE" | translate }}
    </div>
    <div
      fxFlex="13"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.TIME" | translate }}
    </div>
    <div
      fxFlex="15"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.DESCRIPTION" | translate }}
    </div>
    <div
      fxFlex="10"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.ESPACE" | translate }}
    </div>
    <div
      fxFlex="10"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.MONTAGE" | translate }}
    </div>

    <div
      fxFlex="15"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.GUEST_COUNT" | translate }}
    </div>

    <div
      fxFlex="10"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.TARIFS" | translate }}
    </div>

    <div
      fxFlex="10"
      class="section-list-item section-list-item-border-right py-4"
    >
      Actions
    </div>
  </div>

  <div fusePerfectScrollbar>
    <div
      *ngIf="
        !event || !event.event_programs || event.event_programs.length === 0
      "
      fxFlex="100"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="full-width"
    >
      <div class="font-size-20 my-16">
        {{ "EVENTS.NO_PROGRAMS" | translate }}
      </div>
    </div>

    <div
      *ngIf="loading"
      fxLayout="row"
      fxLayoutAlign="center center"
      style="height: 100%; width: 100%"
    >
      <mat-spinner [diameter]="40" class="my-40"></mat-spinner>
    </div>

    <div
      *ngIf="
        event &&
        !loading &&
        event.event_programs &&
        event.event_programs.length > 0
      "
      fxFlex="100"
      class=""
    >
      <div
        *ngFor="let item of event.event_programs"
        fxLayout="row"
        fxLayout="start center"
        class="child-item"
      >
        <div
          *ngIf="item.date_end_label !== null && item.date_end_label !== ''"
          fxFlex="17"
          fxLayout="row"
          fxLayout="start center"
          class="section-list-item-border-right pl-8 py-8"
        >
          {{ item.date_start_label }} {{ "GENERIC.TO2" | translate }}
          {{ item.date_end_label }}
        </div>

        <div
          *ngIf="item.date_end_label === null || item.date_end_label === ''"
          fxFlex="17"
          fxLayout="row"
          fxLayout="start center"
          class="section-list-item-border-right pl-8 py-8"
        >
          {{ item.date_start_label }}
        </div>

        <div
          fxFlex="13"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.time_start_label }} {{ "GENERIC.TO" | translate }}
          {{ item.time_end_label }}
        </div>

        <div
          fxFlex="15"
          class="section-list-item section-list-item-border-right text-truncate py-8"
        >
          <span class="text-truncate px-8">{{ item.description }}</span>
        </div>

        <div
          fxFlex="10"
          class="section-list-item section-list-item-border-right py-8"
        >
          <div *ngIf="item.room">{{ item.room.name }}</div>
        </div>

        <div
          fxFlex="10"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.assembly_type_label }}
        </div>

        <div
          fxFlex="15"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.guest_count }}
        </div>

        <div
          fxFlex="10"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.price_label }}
        </div>

        <div fxFlex="10" class="section-list-item">
          <button
            class=""
            mat-icon-button
            [disabled]="!editable"
            (click)="onEditProgram(item)"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("EDIT")
            }}</mat-icon>
          </button>
          <button
            class=""
            mat-icon-button
            [disabled]="!editable"
            (click)="onDeleteProgram(item)"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("DELETE")
            }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
