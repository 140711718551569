export const locale = {
  lang: "fr-CA",
  data: {
    MANAGEMENT: {
      TITLE: "Gestion",
      PRODUCTION: "Production",
      PRODUCTION_ZONE_LIST: "Liste de production par zone de production",
      FORECAST_PRODUCTION_ZONE_LIST_HINT: "Rapport de production incluant les propositions et les commandes non-confirmées.",
      FORECAST_PRODUCTION_ZONE_LIST: "Liste de production par zone de production - Prévisionnel",
      PRODUCTION_ZONE_LIST_JUL: "Liste de production par zone de production - JUL",
      PRODUCT_LIST: "Liste des produits nourritures et boissons",
      LOGISTIC: "Logistique",
      DELIVERY_A: "Liste des livraisons A",
      DELIVERY_B: "Liste des livraisons B",
      MATERIAL_LIST: "Liste du matériel",
      INCOMING_EVENT: "Événement à venir",
      EVENT_BY_CONTACT: "Événement par conseiller",
      ACTIVITY_REPORT: "Rapport d'activités",
      EVENT_BY_STATUS: "Événement par statut",
      NEW_CLIENT: "Nouveaux clients",
      LETTER_FORMAT: "Format lettre",
      LEGAL_FORMAT: "Format légal",
      TAKEOUT: "Liste des cueillettes",
      DELIVERY_TAKEOUT_LIST: "Liste des livraisons et des cueillettes JR",
      PRODUCTION_ZONE_ALLERGIES_LIST: "Liste de production par zone de production avec allergies",
      PRODUCTION_ZONE_SIMPLIFIED_LIST: "Liste de production par zone de production simplifié",
      EVENT_LIST: "Liste des événements",
      EVENT_LIST_VIC: "Liste des événements | Vic",
      EVENT_LIST_UQAM: "Liste des événements - UQAM",
      BUFFET_LABEL_5963: "Étiquette plateaux et buffet",
      BOX_LABEL_55164: "Étiquette coffret",
      BUFFET_BOX_LABEL_1323: "Étiquette plateaux, buffet et coffret | JUL",
      ORDER_PURCHASE_ORDER: "Commandes & Bon de commandes | Journée",
      PURCHASE_ORDER: "Bon de commandes",
      PURCHASE_ORDER_DAY_ALLERGIES: "Bon de commande journée allergie sans prix",

      REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY: "Bon de commandes",

      REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY: "Bon de commandes allergies",

      REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_WARMBUFFET_NO_PRICE: "Bon de commandes - JUL | Buffets Chauds",

      REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE: "Bon de commandes - JUL | Coffrets",

      REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY_HINT: "Ce rapport inclus tous les bon de commandes incluant une note dans le champs allergies et restrictions alimentaires",

      REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE_AL: "Bon de commandes - JUL | Coffrets Allergie",
      REPORT2_MNGT_PROD_LIST_BY_ORDER_XLS: "Production par commande",
      REPORT2_MNGT_PROD_LIST_CAC_BY_ORDER_XLS: "Production par commande - CAC",
      REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF_STANDALONE_STACK: "Bon de commande – cuisine et personnel – DAN",
      REPORT_MNGT_EVENT_FINANCIAL_DATA: "Événements à venir – Données Financières",
      EMPLOYEE: "Employés",
      LOGIN_HISTORY: "Historique de connexion",
      ORDERS_BILLED_STAFF: "Personnel facturé",
      CONGRESS: "Congrès",
      CHANGE_LOG: "Avis de changement",
      CHANGE_LOG_DATE_RANGE: "Avis de changement par date d'événement",
      ORDERS_PURCHASE_ORDERS_DAY: "Commande & bons de commandes | journée",
      // PURCHASE_ORDER_CHEF: "Bon de commande Chef (Batch List)",
      // PURCHASE_ORDER_HOT: "Bon commande Chaud (Batch List)",
      // PURCHASE_ORDER_COLD: "Bon commande Froid (Batch List)",
      // PURCHASE_ORDER_HOT_COLD: "Bon commande Chaud/Froid (Batch List)",
      // PURCHASE_ORDER_PASTRY: "Bon commande Patisserie (Batch List)",
      // PURCHASE_ORDER_SERVICE: "Bon commande service (Batch List)",
      PURCHASE_ORDER_CHEF: "Bon commande Chef - MC",
      PURCHASE_ORDER_HOT: "Bon commande Chaud - MC",
      PURCHASE_ORDER_COLD: "Bon commande Froid - MC",
      PURCHASE_ORDER_PASTRY: "Bon commande Pâtisserie - MC",
      PURCHASE_ORDER_SERVICE: "Bon commande service - MC",
      PURCHASE_ORDER_HOT_AND_COLD: "Bon commande Chaud/Froid - MC",
      //
      PURCHASE_ORDER_CHEF_NEW_EVT: "Bon commande Chef | Nouveau EVT - MC",
      PURCHASE_ORDER_HOT_NEW_EVT: "Bon commande Chaud | Nouveau EVT - MC",
      PURCHASE_ORDER_COLD_NEW_EVT: "Bon commande Froid | Nouveau EVT - MC",
      PURCHASE_ORDER_PASTRY_NEW_EVT: "Bon commande Pâtisserie | Nouveau EVT - MC",
      PURCHASE_ORDER_HOT_AND_COLD_NEW_EVT: "Bon commande Chaud/Froid | Nouveau EVT - MC",
      //
      INVOICE: "Facture - MC",
      INVOICE_UQAM: "Factures - UQAM",
      REPORT2_ADMIN_ACCOUNTING_LIST_OF_ORDERS_CREATION_DATE_XLSX: "Événement par date de création",
      EVENT_DATE_END: "Fin de l'événement",
      EVENT_DATE_START: "Début de l'événement",
      NEW_EVENTS_CTM_REPORT: "Liste des nouveaux événements - MC",
      REPORT2_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_BY_DAY_ST_GAB: "Bon de commande - Cuisine - St-Gab",
    },
    REPORTS_HELP: {
      TITLE: "Rapports",
      CTM_HELP_MESSAGE: "Ce document inclut seulement les commandes confirmées et en facturation",
    },
  },
};
