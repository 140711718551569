import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, Injector } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // TODO: add explicit constructor

  translate?: TranslateService;
  logger?: NGXLogger;

  // constructor(public inj: Injector)
  // {
  //     super();
  //     this.translate = this.inj.get(TranslateService);
  //     this.logger = this.inj.get(NGXLogger);
  //     // this.logger.info("CustomDateFormatter.constructor() -> " + this.translate);
  // }

  public override monthViewColumnHeader({
    date,
    locale,
  }: DateFormatterParams): string | any {
    const langPrefix: string = LocalizationConfig.getCurrentLanguagePrefix();
    const newDate: string | any = new DatePipe(langPrefix).transform(
      date,
      'EEEE',
      langPrefix
    );
    // this.logger.info("CustomDateFormatter.monthViewColumnHeader() -> translate.currentLang: " + this.translate.currentLang);
    // this.logger.info("CustomDateFormatter.monthViewColumnHeader() -> newDate: " + newDate);
    return newDate;
  }

  public override dayViewTitle({
    date,
    locale,
  }: DateFormatterParams): string | any {
    const langPrefix: string = LocalizationConfig.getCurrentLanguagePrefix();
    const newDate: string | any = new DatePipe(langPrefix).transform(
      date,
      'EEEE d MMMM yyyy',
      langPrefix
    );

    return newDate;
  }

  // public monthViewDayNumber({date, locale}: DateFormatterParams): string
  // {
  //     // this.logger.info("CustomDateFormatter.monthViewDayNumber() -> date: " + date);
  //     // this.logger.info("CustomDateFormatter.monthViewDayNumber() -> locale: " + locale);

  //     return new DatePipe(locale).transform(date, 'EEE', locale); // use short week days
  // }

  // public monthViewTitle({date, locale}: DateFormatterParams): string
  // {
  //     this.logger.info("CustomDateFormatter.monthViewTitle() -> date: " + date);
  //     this.logger.info("CustomDateFormatter.monthViewTitle() -> locale: " + locale);
  //
  //     return new DatePipe(locale).transform(date, 'EEE', locale); // use short week days
  // }

  public override weekViewColumnHeader({
    date,
    locale,
  }: DateFormatterParams): string {
    // this.logger.info("CustomDateFormatter.weekViewColumnHeader() -> date: " + date);
    // this.logger.info("CustomDateFormatter.weekViewColumnHeader() -> locale: " + locale);
    // const newDate: string = new DatePipe(this.translate.currentLang).transform(date, 'EEEE', this.translate.currentLang);
    const newDate: string | any = new DatePipe(
      LocalizationConfig.getCurrentLanguagePrefix()
    ).transform(date, 'EEEE', LocalizationConfig.getCurrentLanguagePrefix());
    // const newDate: string = new DatePipe(LocalizationConfig.getCurrentLanguagePrefix()).transform(date, DateFormatPreference.getDateFormat(), LocalizationConfig.getCurrentLanguagePrefix());

    // this.logger.info("CustomDateFormatter.weekViewColumnHeader() -> newDate: " + newDate);
    return newDate;
    // return new DatePipe(locale).transform(date, 'EEE', locale); // use short week days
  }

  public override weekViewColumnSubHeader({
    date,
    locale,
  }: DateFormatterParams): string {
    // this.logger.info("CustomDateFormatter.weekViewColumnSubHeader() -> date: " + date);
    // this.logger.info("CustomDateFormatter.weekViewColumnSubHeader() -> locale: " + locale);
    const newDate: string | any = new DatePipe(
      LocalizationConfig.getCurrentLanguagePrefix()
    ).transform(date, 'LLL d', LocalizationConfig.getCurrentLanguagePrefix());
    // const newDate: string = new DatePipe(LocalizationConfig.getCurrentLanguagePrefix()).transform(date, DateFormatPreference.getDateFormat(), LocalizationConfig.getCurrentLanguagePrefix());
    // this.logger.info("CustomDateFormatter.weekViewColumnSubHeader() -> newDate: " + newDate);
    return newDate;
    // return new DatePipe(locale).transform(date, 'EEE', locale); // use short week days
  }

  // public weekViewTitle({date, locale}: DateFormatterParams): string
  // {
  //     this.logger.info("CustomDateFormatter.weekViewTitle() -> date: " + date);
  //     this.logger.info("CustomDateFormatter.weekViewTitle() -> locale: " + locale);
  //
  //     return new DatePipe(locale).transform(date, 'EEE', locale); // use short week days
  // }
  //
  //
  // public dayViewHour({date, locale}: DateFormatterParams): string
  // {
  //     this.logger.info("CustomDateFormatter.dayViewHour() -> date: " + date);
  //     this.logger.info("CustomDateFormatter.dayViewHour() -> locale: " + locale);
  //
  //     return new DatePipe(locale).transform(date, 'EEE', locale); // use short week days
  // }
  //
  //
  // public dayViewTitle({date, locale}: DateFormatterParams): string
  // {
  //     this.logger.info("CustomDateFormatter.dayViewTitle() -> date: " + date);
  //     this.logger.info("CustomDateFormatter.dayViewTitle() -> locale: " + locale);
  //
  //     return new DatePipe(locale).transform(date, 'EEE', locale); // use short week days
  // }
}
