import { MatDialog } from '@angular/material/dialog';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AddressService } from './address.service';
import { GT2FormPageAbstract } from '../../content/abstract/GT2FormPageAbstract';
import { AddressModel } from '../../api/models/AddressModel';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SectorModel } from '../sectors-select/sectors.service';
import { Settings } from '../../settings/settings';
import { NGXLogger } from 'ngx-logger';
import { ModulesService } from '../../services/modules.service';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { GoogleLinkPipeModule } from '../../modules/google-link.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { SectorsSelectComponent } from '../sectors-select/sectors-select.component';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    TranslateModule,
    MatInputModule,
    FormsModule,
    GoogleLinkPipeModule,
    ReactiveFormsModule,
    SectorsSelectComponent,
    MatButtonModule,
    MatFormFieldModule,
  ],
  providers: [AddressService],
})
export class AddressComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  userSettings: any = {
    inputPlaceholderText: '',
    showRecentSearch: true,
  };

  @Input() groupModel?: AddressModel | any;
  @Output() groupModelChange = new EventEmitter<AddressModel>();
  @Input() isVisible: boolean = false;
  @Input() editMode: boolean = true;
  @Input() useAutosave: boolean = false;
  @Input() hideSaveButton: boolean = false;
  @Input() showVenuNameInput: boolean = false;
  @Output() addressEvent: EventEmitter<AddressModel> = new EventEmitter();
  noGeoLocation: boolean = false;
  form: UntypedFormGroup;
  addressPrefillDirty: boolean = false;
  sectorChanged: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private logger: NGXLogger,
    private dialog: MatDialog,
    private addressService: AddressService,
    public moduleService: ModulesService,
  ) {
    super();
    // Initialisé la langue
    const local_lang =
      localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID) ||
      LocalizationConfig.DEFAULT_LANGUAGE;

    // Ajouter les traductions locales
    if (local_lang === 'en-CA')
      translate.setTranslation(english.lang, english.data, true);
    else translate.setTranslation(french.lang, french.data, true);

    // Définir la langue à utiliser
    translate.setDefaultLang(LocalizationConfig.DEFAULT_LANGUAGE);
    translate.use(local_lang);

    this.userSettings.inputPlaceholderText = this.translate.instant(
      'GENERIC.GOOGLE_ADDRESS_PLACEHOLDER',
    );

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      street_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.required,
        ],
      ],
      street: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      apartment: '',
      line2: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      city: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      province: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      country: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      postal_code: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      on_site_contact: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
    });

    // translate.get("ADDRESS.SEARCH_ADDRESS").subscribe( translated =>
    // {
    //     this.userSettings.inputPlaceholderText = translated;
    // });
  }

  ngOnInit() {
    // this.logger.info(
    //     "AddressComponent.ngOnInit() --> groupModel: " +
    //         JSON.stringify(this.groupModel)
    // );
  }

  ngAfterViewInit() {
    if (!this.groupModel) {
      this.groupModel = {
        object: 'Address',
        name: null,
        uuid: null,
        street_number: null,
        street: null,
        apartment: null,
        line2: null,
        city: null,
        province: null,
        country: null,
        postal_code: null,
        long: null,
        lat: null,
        full_address: null,
        sector: null,
        on_site_contact: null,
      };
    }

    // this.logger.info("AddressComponent.ngAfterViewInit() --> groupModel: " + JSON.stringify(this.groupModel) );
    if (this.useAutosave) {
      // this.setControlsChanges(this.form);
    }
  }

  ngOnDestroy() {}

  public handleAddressChange(address: any) {
    if (address.response === false) {
      this.noGeoLocation = true;
      return;
    } else {
      this.isVisible = true;
      this.noGeoLocation = false;

      Promise.resolve(null).then(() => {
        this.groupModel = {
          ...this.groupModel,
          street_number: this.findAddressProperty(address, 'street_number'),
          street: this.findAddressProperty(address, 'route'),
          city: this.findAddressProperty(address, 'locality'),
          province: this.findAddressProperty(
            address,
            'administrative_area_level_1',
          ),
          country: this.findAddressProperty(address, 'country'),
          postal_code: this.findAddressProperty(address, 'postal_code'),
          lat: address.data.geometry.location.lat,
          long: address.data.geometry.location.lng,
          full_address: address.data.formatted_address,
        };
        this.addressPrefillDirty = true;
        if (this.useAutosave) {
          // this.autosaveDataChange(this.groupModel);
        }
        this.groupModelChange.emit(this.groupModel);

        this.addressEvent.emit(this.groupModel);
      });
    }
  }

  public findAddressProperty(address: any, key: string): string {
    const item = address.data.address_components.find((data: any) => {
      return data.types.indexOf(key) !== -1;
    });

    if (item && item.long_name) {
      return item.long_name;
    }
    return '';
  }

  public onManualSave(): void {
    if (this.form.dirty || this.addressPrefillDirty || this.sectorChanged) {
      this.autosaveDataChange(this.groupModel);
    }
    // this.autosaveDataChange(this.groupModel);
  }

  public override autosaveDataChange(data: any): void {
    //this.logger.info("AddressComponent.autosaveDataChange() -> data: " + JSON.stringify(data));
    if (this.groupModel?.street_number && this.groupModel?.street_number) {
    }

    this.addressService.updateAddress(data, this.groupModel.uuid).subscribe(
      (response) => {
        //this.logger.info("AddressComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
        this.addressPrefillDirty = false;
        this.sectorChanged = false;
      },
      (error) => {
        this.logger.error(
          'AddressComponent.autosaveDataChange() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public onSectorSelected(sector: SectorModel): void {
    // this.logger.info("AddressComponent.onSectorSelected() -> sector: " + JSON.stringify(sector));

    this.groupModel.sector = sector;
    this.sectorChanged = true;
    // this.autosaveDataChange(this.groupModel);
  }
}
