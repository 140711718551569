export const locale = {
  lang: "fr-CA",
  data: {
    GENERIC_CREATE: {
      TITLE: "",
      CREATE_SUCCESS_TOAST: "Succès de la création!",
      CREATE_ERROR_TOAST: "Une erreur est survenue lors de la création!",
    },
  },
};
