import { InvoiceComposeCTService } from '../../api/invoice-compose-ct.service';
import { ProposalComposeCTService } from '../../api/proposal-compose-ct.service';
import { RichTextsValidator } from '../../utils/RichTextsValidator';
import { ModulesService } from '../../services/modules.service';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '../../../../@fuse/animations';
import { FuseSidebarService } from '../../../../@fuse/components/sidebar/sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { OrdersService } from '../../api/orders.service';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { OrderMaterialDndComponent } from '../../components/order-material-dnd/order-material-dnd.component';
import { OrderProductDndComponent } from '../../components/order-product-dnd/order-product-dnd.component';
import { AppRoutes } from '../../const/AppRoutes';
import { Settings } from '../../settings/settings';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { ValidatorHelper } from '../../validators/ValidatorHelper';
import { FileService } from '../../../services/file.service';
import { PrintService } from '../../../services/print.service';
import { ToastrService } from 'ngx-toastr';
import { Editor } from 'primeng/editor';
import { forkJoin, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { GT2FormPageAbstract } from '../abstract/GT2FormPageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { EditMaterialComponent } from './edit-material/edit-material.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { EventsService } from '../../api/events.service';
import { CreateEventComponent } from '../../components/dialogs/create-event/create-event.component';
import { AddServiceChargeComponent } from '../../components/dialogs/add-service-charge/add-service-charge.component';
import { AddSpecificConditionComponent } from '../../components/dialogs/add-specific-condition/add-specific-condition.component';
import { ApiRoutes } from '../../api/ApiRoutes';
import { ProposalComposeService } from '../../api/proposal-compose.service';
import { MailComposeDialogComponent } from '../compose/compose.component';
import { OrderComposeService } from '../../api/order-compose.service';
import { ReceiptNoticeComposeService } from '../../api/receipt-notice-compose.service';
import { InvoiceComposeService } from '../../api/invoice-compose.service';
import { CreateLabourChargeComponent } from '../../components/dialogs/create-labour-charge/create-labour-charge.component';
import { GT2ConstantsEnum } from '../../const/GT2ConstantsEnum';
import { Title } from '@angular/platform-browser';
import { GroupActionSingleInputComponent } from './group-action-single-input/group-action-single-input.component';
import { CatererService } from '../caterer/caterer.service';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { AlertDrinksValidationComponent } from '../../components/dialogs/alert-drinks-validation-dialog/alert-drinks-validation-dialog.component';
import moment from 'moment';
import { ChangelogsDialogComponent } from '../../components/dialogs/changelogs-dialog/changelogs-dialog.component';
import { ProductModel, ProductsService } from '../../api/products.service';
import { EditComboComponent } from '../../components/edit-combo/edit-combo.component';
import { InvoiceComposeGlobalPaymentsService } from '../../api/invoice-compose-global-payments.service';
import { ReceiptNoticeSellOnlyComposeService } from '../../api/receipt-notice-sell-only-compose.service';
import { StorageNoticeComposeService } from '../../api/storage-notice-compose.service';
import { HistoryStack, PageHistory } from '../../benching/page-history';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MenuReportsService } from '../../reports/menu-reports.service';
import { ReportFactoryService } from '../../reports/report-factory.service';
import { WrappingService } from '../../api/wrapping.service';
import { ConseillersService } from '../../api/conseillers.service';
import { RolesService } from '../../services/roles.service';
import { CalendarDateFormatter, DateAdapter } from 'angular-calendar';
import { CustomDateFormatter } from '../calendar/CustomDateFormatter';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  animations: fuseAnimations,
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class OrderComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static TYPE_ORDER: string = 'ORDER';
  static TYPE_PROPOSAL: string = 'PROPOSAL';
  static TYPE_INVOICE: string = 'INVOICE';
  static ORDER_COMBO_OPEN: string = 'ORDER_COMBO_OPEN_ID';
  @ViewChild('noteEditor', { static: false }) noteEditor!: Editor;
  @ViewChild('noteKitchenEditor', { static: false })
  noteKitchenEditor!: Editor;
  @ViewChild('noteAllergensEditor', { static: false })
  noteAllergensEditor!: Editor;
  @ViewChild('noteMaterialEditor', { static: false })
  noteMaterialEditor!: Editor;
  editable: boolean = true;
  isCTEditable: boolean = true;
  isSmallScreen!: boolean;
  order!: OrderModel;
  orderType: string = OrderComponent.TYPE_ORDER;
  orderTypeHeaderColor: string = 'primary';
  event: any;
  orderUUDID!: string;
  orderURL!: string;
  orderSlug!: string;
  loading: boolean = false;
  editMode: boolean = false;
  form: FormGroup;
  declare dialogRef: any;
  confirmDialogRef: any;
  dndLoading: boolean = false;
  productQtySubject: Subject<any> = new Subject<any>();
  materialQtySubject: Subject<any> = new Subject<any>();
  serviceChargeQtySubject: Subject<any> = new Subject<any>();
  labourChargeQtySubject: Subject<any> = new Subject<any>();
  productCheckboxes: {} | any;
  selectedProducts: any[] = [];
  materialCheckboxes: {} | any;
  selectedMaterials: any[] = [];
  isCanada: boolean = false;
  comboToggleOpen = false;
  requestsCollection: object | any = {};
  productsCollection: object | any = {};
  materialsCollection: object | any = {};
  serviceChargesCollection: object | any = {};
  labourChargesCollection: object | any = {};
  richTextsValidator!: RichTextsValidator;
  noteInvalid: boolean = false;
  productsExpand: boolean = true;
  saveDataTimeout: any;
  selectedDrinks: any[] = [];
  noteHintMaxChar = 200;
  OrderStaffs: any[] = [];
  timeZone: string;
  comboDialogRef: any;
  seviceChargeLabel: string = 'allo';
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public ordersService: OrdersService,
    public rolesService: RolesService,
    public print: PrintService,
    public fileService: FileService,
    public wrappingService: WrappingService,
    public conseillersService: ConseillersService,
    private api: Gt2ApiService,
    private formBuilder: FormBuilder,
    private eventsService: EventsService,
    private _fuseSidebarService: FuseSidebarService,
    public proposalComposeService: ProposalComposeService,
    public proposalComposeCTService: ProposalComposeCTService,
    private titleService: Title,
    public orderComposeService: OrderComposeService,
    public receiptNoticeSellOnlyComposeService: ReceiptNoticeSellOnlyComposeService,
    public receiptNoticeComposeService: ReceiptNoticeComposeService,
    public storageNoticeComposeService: StorageNoticeComposeService,
    public invoiceComposeService: InvoiceComposeService,
    public invoiceComposeCTService: InvoiceComposeCTService,
    public invoiceComposeGlobalPaymentsService: InvoiceComposeGlobalPaymentsService,
    public catererService: CatererService,
    public moduleService: ModulesService,
    public router: Router,
    public modulesService: ModulesService,
    public productsService: ProductsService,
    private breakpointObserver: BreakpointObserver,
    public menuReportsService: MenuReportsService,
    public reportFactoryService: ReportFactoryService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.timeZone = 'America/Montreal';
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      guest_count: [
        '',
        [ValidatorHelper.isNumber0To1000000, Validators.required],
      ],
    });
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
    //this.titleService.setTitle(this.translate.instant('ORDERS.ORDER'));

    this.route.params.subscribe({
      next: (params: any) => {
        this.orderUUDID = params.id;
        this.orderSlug = params.slug;
        this.loadData();
      },
    });

    this.route.url.subscribe((url) => {
      this.orderURL = url[0].path;
      if (url[0].path === AppRoutes.PROPOSITIONS) {
        this.orderType = OrderComponent.TYPE_PROPOSAL;
        this.orderTypeHeaderColor = 'primary';

        this.form.addControl(
          'proposal_expired_at',
          new FormControl('', [Validators.required]),
        );
      } else if (url[0].path === AppRoutes.ORDERS) {
        this.orderType = OrderComponent.TYPE_ORDER;
        this.orderTypeHeaderColor = 'accent-800';
      } else if (url[0].path === AppRoutes.INVOICES) {
        this.orderType = OrderComponent.TYPE_INVOICE;
        this.orderTypeHeaderColor = 'warn-900';
        this.form.addControl(
          'non_taxable_tip',
          new FormControl('', [
            Validators.minLength(1),
            ValidatorHelper.isNumber0To1000000,
            ValidatorHelper.isValidPriceNumber,
            Validators.required,
          ]),
        );

        this.form.addControl(
          'invoiced_at',
          new FormControl('', [
            ValidatorHelper.dateRangeDefault,
            Validators.required,
          ]),
        );
      }
    });

    this.catererService.onCatererChanged.subscribe({
      next: (caterer) => {
        if (caterer !== null) {
          this.isCanada = this.catererService.isCanada();
        }
      },
    });

    this.comboToggleOpen = localStorage.getItem('comboToggleOpen') === 'true';

    this.richTextsValidator = new RichTextsValidator();

    this.modulesService.onModuleSet().subscribe({
      next: (value: any) => {
        if (value && this.modulesService.modulesAreSet) {
          this.validateStatus();
        }
      },
    });
  }

  public initRichText(): void {
    if (this.noteEditor) {
      this.richTextsValidator.registerRichText(this.noteEditor, 'noteEditor');
      this.richTextsValidator.registerRichText(this.noteEditor, 'noteEditor');
    }
    if (this.noteKitchenEditor) {
      this.richTextsValidator.registerRichText(
        this.noteKitchenEditor,
        'noteKitchenEditor',
      );
    }
    if (this.noteAllergensEditor) {
      this.richTextsValidator.registerRichText(
        this.noteAllergensEditor,
        'noteAllergensEditor',
      );
    }
    if (this.noteMaterialEditor) {
      this.richTextsValidator.registerRichText(
        this.noteMaterialEditor,
        'noteMaterialEditor',
      );
    }
  }

  ngAfterViewInit(): void {
    this.productQtySubject
      .pipe(
        tap((product) => {
          this.productsCollection[product.uuid] = product;
        }),
      )
      .subscribe();

    this.materialQtySubject
      .pipe(
        tap((material) => {
          this.materialsCollection[material.uuid] = material;
        }),
      )
      .subscribe();

    this.serviceChargeQtySubject
      .pipe(
        tap((serviceCharge) => {
          this.serviceChargesCollection[serviceCharge.uuid_pivot] =
            serviceCharge;
        }),
      )
      .subscribe();

    this.labourChargeQtySubject
      .pipe(
        tap((labourCharge) => {
          this.labourChargesCollection[labourCharge.uuid] = labourCharge;
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.requestsCollection = null;
    this.productsCollection = null;
    this.materialsCollection = null;
    this.serviceChargesCollection = null;
    this.labourChargesCollection = null;

    if (this.productQtySubject) {
      this.productQtySubject.unsubscribe();
    }
    if (this.materialQtySubject) {
      this.materialQtySubject.unsubscribe();
    }
    if (this.serviceChargeQtySubject) {
      this.serviceChargeQtySubject.unsubscribe();
    }

    if (this.richTextsValidator) {
      this.richTextsValidator.destroy();
    }
  }

  loadData(): void {
    this.loading = true;
    this.ordersService.getOrdersByUUID(this.orderUUDID).subscribe({
      next: (response: any) => {
        const isFirstLoad: boolean =
          this.order === null || this.order === undefined;
        this.order = response.data;

        if (this.order.type === GT2ConstantsEnum.ORDER) {
          const callBenching: HistoryStack = {
            url: this.router.url,
            icon: this.getIcon('ORDER'),
            uuid: this.order.uuid,
            label: this.order.name + ' ' + this.order.order_number,
          };
          PageHistory.addCall(callBenching);
        } else if (this.order.type === GT2ConstantsEnum.PROPOSAL) {
          const callBenching: HistoryStack = {
            url: this.router.url,
            icon: this.getIcon('PROPOSAL'),
            uuid: this.order.uuid,
            label: this.order.name + ' ' + this.order.proposal_number,
          };
          PageHistory.addCall(callBenching);
        } else if (this.order.type === GT2ConstantsEnum.INVOICE) {
          const callBenching: HistoryStack = {
            url: this.router.url,
            icon: this.getIcon('INVOICE'),
            uuid: this.order.uuid,
            label: this.order.name + ' ' + this.order.invoice_number,
          };
          PageHistory.addCall(callBenching);
          if (
            this.order.status === 'INVOICE_OPEN' &&
            this.order.event.balance <= 0
          ) {
            this.doCompleteAndPayInvoice(this.order);
          }
        }

        this.validateStatus();
        if (this.order.event) {
          this.loadEvent(this.order.event.uuid);
        }
        // if (this.order.type === GT2ConstantsEnum.ORDER) {
        //   this.titleService.setTitle(this.translate.instant('ORDERS.ORDER'));
        // } else if (this.order.type === GT2ConstantsEnum.PROPOSAL) {
        //   this.titleService.setTitle(this.translate.instant('ORDERS.PROPOSAL'));
        // } else if (this.order.type === GT2ConstantsEnum.INVOICE) {
        //   this.titleService.setTitle(this.translate.instant('ORDERS.INVOICE'));
        // }

        this.setComboOpenStatus(
          this.order.product_category_collection,
          this.order.material_category_collection,
        );
        this.resetCheckboxes();

        if (isFirstLoad) {
          setTimeout(() => {
            this.initRichText();
          }, 500);
        }
        this.loading = false;
      },
      error: (error: any) => {
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  get allergensTooltip(): string {
    return this.order.product_allergen_collection
      .map((allergen: any) => allergen.name)
      .join(`\n - `);
  }

  public setComboOpenStatus(products: any, materials: any): void {
    for (const product of products) {
      for (const item of product.product_collection) {
        if (item.combo && item.combo.length > 0) {
          item.comboToggleOpen =
            localStorage.getItem(
              OrderComponent.ORDER_COMBO_OPEN + item.uuid,
            ) === 'true';
        }
      }
    }

    for (const material of materials) {
      for (const item of material.material_collection) {
        if (item.combo && item.combo.length > 0) {
          item.comboToggleOpen =
            localStorage.getItem(
              OrderComponent.ORDER_COMBO_OPEN + item.uuid,
            ) === 'true';
        }
      }
    }
  }

  public validateStatus(): void {
    this.editable = true;

    if (this.order?.type === GT2ConstantsEnum.PROPOSAL) {
      if (
        this.order.status === GT2ConstantsEnum.PROPOSAL_ACCEPT ||
        this.order.status === GT2ConstantsEnum.PROPOSAL_EXPIRE ||
        this.order.status === GT2ConstantsEnum.PROPOSAL_DISCARD ||
        (this.order.status === GT2ConstantsEnum.PROPOSAL_REFUSE &&
          this.order.event.status === GT2ConstantsEnum.EVENT_IN_INVOICE)
      ) {
        this.editable = false;
      }

      if (
        this.order.event.status === GT2ConstantsEnum.EVENT_ABORT ||
        this.order.event.status === GT2ConstantsEnum.EVENT_CANCEL
      ) {
        this.editable = false;
      }

      if (
        this.order.status === GT2ConstantsEnum.PROPOSAL_REFUSE &&
        this.event &&
        this.event.documents.order
      ) {
        this.editable = false;
      }
    }

    if (this.order?.type === GT2ConstantsEnum.ORDER) {
      if (
        this.order.status === GT2ConstantsEnum.ORDER_COMPLETE ||
        this.order.status === GT2ConstantsEnum.ORDER_CANCEL
      ) {
        this.editable = false;
      }

      if (
        this.order.event.status === GT2ConstantsEnum.EVENT_ABORT ||
        this.order.event.status === GT2ConstantsEnum.EVENT_CANCEL
      ) {
        this.editable = false;
      }
    }

    if (this.order?.type === GT2ConstantsEnum.INVOICE) {
      if (this.order.status === GT2ConstantsEnum.INVOICE_PAID) {
        this.editable = false;
      }

      if (
        this.order.event.status === GT2ConstantsEnum.EVENT_ABORT ||
        this.order.event.status === GT2ConstantsEnum.EVENT_CANCEL
      ) {
        this.editable = false;
      }

      if (this.order.status === GT2ConstantsEnum.INVOICE_PAID) {
        this.editable = false;
      }

      if (this.order.status === GT2ConstantsEnum.INVOICE_CANCEL) {
        this.editable = false;
      }
    }

    this.isCTEditable =
      this.editable ||
      (this.moduleService.hasCTReport() &&
        this.order.type === GT2ConstantsEnum.ORDER);
  }

  public loadEvent(eventUUDID: string): void {
    this.eventsService.getEvent(eventUUDID).subscribe({
      next: (response: any) => {
        this.event = response.data;
        this.validateStatus();
        this.loading = false;
        this.setServiceChargeLabel();
      },
      error: (error: any) => {
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onEdit(): void {
    this.editMode = !this.editMode;
    if (this.editMode) {
    } else {
      this.saveData();
    }
  }

  public saveData(): void {
    if (this.saveDataTimeout !== null) {
      clearTimeout(this.saveDataTimeout);
    }
    this.saveDataTimeout = setTimeout(() => {
      this.doSaveData();
    }, 100);
  }

  public doSaveData(): void {
    this.loading = true;
    this.forkAllRequest().subscribe({
      next: (response: any) => {
        this.loading = false;
        const orderData: any = response.find((dataModel: any) => {
          return dataModel['data']['object'] === 'Order';
        });
        if (orderData && orderData.data) {
          this.order = orderData.data;
        } else {
          this.loadData();
        }
        this.requestsCollection = {};
        this.productsCollection = {};
        this.materialsCollection = {};
        this.serviceChargesCollection = {};
        this.labourChargesCollection = {};
        this.validateStatus();
        this.form.markAsUntouched();
        this.form.markAsPristine();

        if (this.richTextsValidator) {
          this.richTextsValidator.clean();
        }
      },
      error: (error: any) => {
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });

    this.eventsService
      .updateEventInformation(this.event.uuid, this.event.informations)
      .subscribe({
        next: () => {},
        error: (error: any) => {
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }

  public forkAllRequest() {
    const products = [];
    for (let key in this.productsCollection) {
      products.push(this.productsCollection[key]);
    }

    const materials = [];
    for (let key in this.materialsCollection) {
      materials.push(this.materialsCollection[key]);
    }

    const serviceCharges = [];
    for (let key in this.serviceChargesCollection) {
      serviceCharges.push(this.serviceChargesCollection[key]);
    }

    const staffs = [];
    for (let key in this.labourChargesCollection) {
      staffs.push(this.labourChargesCollection[key]);
    }
    let requests = [];
    if (products !== null && products.length > 0) {
      requests.push(
        this.ordersService.updateProducts(this.orderUUDID, products),
      );
    }
    if (materials !== null && materials.length > 0) {
      requests.push(
        this.ordersService.updateMaterials(this.orderUUDID, materials),
      );
    }
    if (serviceCharges !== null && serviceCharges.length > 0) {
      requests.push(
        this.ordersService.updateServiceCharges(
          this.orderUUDID,
          serviceCharges,
        ),
      );
    }
    if (staffs !== null && staffs.length > 0) {
      requests.push(
        this.ordersService.updateLabourCharges(this.orderUUDID, staffs),
      );
    }

    this.order.invoiced_at = this.formatDate(this.order.invoiced_at);
    requests.push(
      this.ordersService.updateOrderByUUID(this.order, this.orderUUDID),
    );

    return forkJoin([requests]);
  }

  public onCancelEdit(event: any): void {
    this.editMode = false;

    if (this.noteEditor) {
      this.noteEditor.readonly = !this.editMode;
    }

    if (this.noteKitchenEditor) {
      this.noteKitchenEditor.readonly = !this.editMode;
    }

    if (this.noteMaterialEditor) {
      this.noteMaterialEditor.readonly = !this.editMode;
    }

    if (this.noteAllergensEditor) {
      this.noteMaterialEditor.readonly = !this.editMode;
    }

    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.loadData();
  }

  public setServiceChargeLabel(): void {
    if (this.event.is_ctm) {
      this.seviceChargeLabel = this.translate.instant(
        'ORDERS.SERVICE_CHARGE_CT',
      );
    } else {
      this.seviceChargeLabel = this.translate.instant('ORDERS.SERVICE_CHARGE');
    }
  }

  public toggleHistoricSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  public onWrappingSelected(wrapping: any): void {
    this.order.wrapping = wrapping;
    this.form.markAsDirty();
  }

  public onResponsibleEmployeeSelected(employee: any): void {
    this.order.responsible_employee = employee;
    this.form.markAsDirty();
  }

  public onProductCategoryDrop(event: any): void {
    moveItemInArray(
      this.order.product_category_collection,
      event.previousIndex,
      event.currentIndex,
    );
    if (!event.item.data) {
      return;
    }

    const productCategory: any = event.item.data;
    if (this.order.product_category_collection.length < 2) {
      return;
    }

    let i: number = 0;
    const length: number = this.order.product_category_collection.length;
    for (i; i < length; i++) {
      if (
        this.order.product_category_collection[i].uuid === productCategory.uuid
      ) {
        if (i === 0) {
          this.dndLoading = true;
          this.ordersService
            .productCategoryMoveBefore(
              this.order.uuid,
              productCategory.uuid,
              this.order.product_category_collection[i + 1].uuid,
            )
            .subscribe({
              next: () => {
                this.dndLoading = false;
              },
              error: (error: any) => {
                this.dndLoading = false;
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        } else {
          this.dndLoading = true;
          this.ordersService
            .productCategoryMoveAfter(
              this.order.uuid,
              productCategory.uuid,
              this.order.product_category_collection[i - 1].uuid,
            )
            .subscribe({
              next: () => {
                this.dndLoading = false;
              },
              error: (error: any) => {
                this.dndLoading = false;
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
        break;
      }
    }
  }

  public onProductChildDrop(event: any, parent: any) {
    moveItemInArray(
      parent.product_collection,
      event.previousIndex,
      event.currentIndex,
    );
    const product: any = event.item.data;
    let productCategory: any;

    let i: number = 0;
    let length: number = this.order.product_category_collection.length;
    for (i; i < length; i++) {
      const temp = this.order.product_category_collection[
        i
      ].product_collection.find((item: any) => {
        return item.uuid === product.uuid;
      });
      if (temp) {
        productCategory = this.order.product_category_collection[i];
        break;
      }
    }

    if (productCategory.product_collection.length < 2) {
      return;
    }

    i = 0;
    length = productCategory.product_collection.length;
    for (i; i < length; i++) {
      if (productCategory.product_collection[i].uuid === product.uuid) {
        if (i === 0) {
          this.dndLoading = true;
          this.ordersService
            .productMoveBefore(
              this.order.uuid,
              product.uuid,
              productCategory.product_collection[i + 1].uuid,
            )
            .subscribe({
              next: () => {
                this.dndLoading = false;
              },
              error: (error: any) => {
                this.dndLoading = false;
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        } else {
          this.dndLoading = true;
          this.ordersService
            .productMoveAfter(
              this.order.uuid,
              product.uuid,
              productCategory.product_collection[i - 1].uuid,
            )
            .subscribe({
              next: () => {
                this.dndLoading = false;
              },
              error: (error: any) => {
                this.dndLoading = false;
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
        break;
      }
    }
  }

  public showInvoiceSendAlertMessage(callback: string, options?: any): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle = this.translate.instant(
      'ORDERS.INVOICE_SENT_TITLE',
    );
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('ORDERS.INVOICE_SENT_MESSAGE');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.confirmDialogRef = null;
          if (options) {
            //this[callback](options);
          } else {
            //this[callback]();
          }
        }
      },
    });
  }

  public onAddProduct(): void {
    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doAddProduct');
    } else {
      this.doAddProduct();
    }
  }

  public doAddProduct(): void {
    if (this.editMode) {
      this.saveData();
    }
    this.dialogRef = this.dialog.open(OrderProductDndComponent, {
      width: '94%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: {
        orderUUID: this.orderUUDID,
        guest_count: this.order.guest_count,
      },
    });

    this.dialogRef.componentInstance.confirmMessage = '';

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.order = result;
          this.resetCheckboxes();
          this.resetSelected();
        }
        this.dialogRef = null;
      },
    });
  }

  public onEditProduct(product: any, productCategory: any): void {
    if (!this.editable && !this.isCTEditable) {
      return;
    }

    const productData: any = {
      product: product,
      productCategory: productCategory,
    };
    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doEditProduct', productData);
    } else {
      this.doEditProduct(productData, false);
    }
  }
  public onEditProductCombo(
    product: any,
    productCategory: any,
    parentUuid: string,
  ): void {
    if (!this.editable && !this.isCTEditable) {
      return;
    }

    const productData: any = {
      product: product,
      productCategory: productCategory,
      parentUuid: parentUuid,
    };
    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doEditProduct', productData);
    } else {
      this.doEditProduct(productData, true);
    }
  }

  public onReattachProduct(product: any) {
    this.ordersService
      .reattachProducts(this.order.uuid, { products: [product] })
      .subscribe({
        next: (response: any) => {
          this.order = response.data;
          //
          this.toastr.success(
            '',
            'Produit ' +
              product.name_internal +
              ' attaché et réattaché avec succès!',
          );
        },
        error: (error: any) => {
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public onEditCombo(product: any) {
    this.comboDialogRef = this.dialog.open(EditComboComponent, {
      width: '80%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: { product: product, products: product.combo },
    });

    this.comboDialogRef.componentInstance.confirmMessage =
      this.translate.instant('ROOMS.DELETE_CONFIRM');

    this.comboDialogRef.componentInstance.onMultiplayComboItemPriceChanged.subscribe(
      {
        next: (product: ProductModel) => {
          if (product) {
            this.loadData();
          }
        },
      },
    );
    this.comboDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
        }
        this.loadData();
        this.comboDialogRef = null;
      },
    });
  }

  public onRemoveComboItem(product: any, combo: any): void {
    this.loading = true;
    combo.qty = 0;
    this.productsService
      .productComboDetach(product.uuid, combo.uuid, combo.qty)
      .subscribe({
        next: (response) => {
          this.loadData();
          this.loading = false;
        },
        error: (error) => {
          this.handleAPIError(error, this.dialog, null, null);
          this.loading = false;
        },
      });
  }

  public doEditProduct(productData: any, isComboChild: boolean = false): void {
    const productCategory = _.cloneDeep(productData.productCategory);
    delete productCategory.product_collection;
    this.dialogRef = this.dialog.open(EditProductComponent, {
      disableClose: false,
      minWidth: '1200px',
      panelClass: 'scrollable-dialog',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        product: productData.product,
        productCategory: productCategory,
        orderUUID: this.orderUUDID,
        editable: this.editable,
        parentUUID: productData.parentUuid,
        isComboChild: isComboChild,
        isCTEditable:
          this.moduleService.hasCTReport() &&
          this.order.type === GT2ConstantsEnum.ORDER,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.order = result;
        }
        this.dialogRef = null;
      },
    });
  }

  public onDeleteProduct(product: any): void {
    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doDeleteProduct', product);
    } else {
      this.doDeleteProduct(product);
    }
  }

  public doDeleteProduct(product: any): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.loading = true;
          this.ordersService.deleteProduct(this.orderUUDID, product).subscribe({
            next: (response: any) => {
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.loading = false;
              this.order = response.data;
              this.resetCheckboxes();
              this.resetSelected();
            },
            error: (error: any) => {
              this.handleAPIError(error, this.dialog, null, null);
              this.loading = false;
            },
          });
        }
      },
    });
  }

  public onProductQtyChange(product: any): void {
    if (this.editable) {
      this.productQtySubject.next(product);
    }
  }

  public onMaterialQtyChange(material: any): void {
    if (this.editable) {
      this.materialQtySubject.next(material);
    }
  }

  public onServiceChargeQtyChange(serviceCharge: any): void {
    if (this.editable) {
      this.serviceChargeQtySubject.next(serviceCharge);
    }
  }

  public onLabourChargeQtyChange(labourCharge: any): void {
    if (this.editable) {
      this.labourChargeQtySubject.next(labourCharge);
    }
  }

  public onMaterialCategoryDrop(event: any): void {
    moveItemInArray(
      this.order.material_category_collection,
      event.previousIndex,
      event.currentIndex,
    );
    const materialCategory: any = event.item.data;
    if (this.order.material_category_collection.length < 2) {
      return;
    }

    let i: number = 0;
    const length: number = this.order.material_category_collection.length;
    for (i; i < length; i++) {
      if (
        this.order.material_category_collection[i].uuid ===
        materialCategory.uuid
      ) {
        if (i === 0) {
          this.dndLoading = true;
          this.ordersService
            .materialCategoryMoveBefore(
              this.order.uuid,
              materialCategory.uuid,
              this.order.material_category_collection[i + 1].uuid,
            )
            .subscribe({
              next: () => {
                this.dndLoading = false;
              },
              error: (error: any) => {
                this.dndLoading = false;
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        } else {
          this.dndLoading = true;
          this.ordersService
            .materialCategoryMoveAfter(
              this.order.uuid,
              materialCategory.uuid,
              this.order.material_category_collection[i - 1].uuid,
            )
            .subscribe({
              next: () => {
                this.dndLoading = false;
              },
              error: (error: any) => {
                this.dndLoading = false;
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
        break;
      }
    }
  }

  public onMaterialChildDrop(event: any, parent: any) {
    moveItemInArray(
      parent.material_collection,
      event.previousIndex,
      event.currentIndex,
    );
    const material: any = event.item.data;
    let materialCategory: any;

    let i: number = 0;
    let length: number = this.order.material_category_collection.length;
    for (i; i < length; i++) {
      const temp = this.order.material_category_collection[
        i
      ].material_collection.find((item: any) => {
        return item.uuid === material.uuid;
      });
      if (temp) {
        materialCategory = this.order.material_category_collection[i];
        break;
      }
    }

    if (materialCategory.material_collection.length < 2) {
      return;
    }

    i = 0;
    length = materialCategory.material_collection.length;
    for (i; i < length; i++) {
      if (materialCategory.material_collection[i].uuid === material.uuid) {
        if (i === 0) {
          this.dndLoading = true;
          this.ordersService
            .materialMoveBefore(
              this.order.uuid,
              material.uuid,
              materialCategory.material_collection[i + 1].uuid,
            )
            .subscribe({
              next: () => {
                this.dndLoading = false;
              },
              error: (error: any) => {
                this.dndLoading = false;
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        } else {
          this.dndLoading = true;
          this.ordersService
            .materialMoveAfter(
              this.order.uuid,
              material.uuid,
              materialCategory.material_collection[i - 1].uuid,
            )
            .subscribe({
              next: () => {
                this.dndLoading = false;
              },
              error: (error: any) => {
                this.dndLoading = false;
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
        break;
      }
    }
  }

  public onAddMaterial(): void {
    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doAddMaterial');
    } else {
      this.doAddMaterial();
    }
  }

  public doAddMaterial(): void {
    if (this.editMode) {
      this.saveData();
    }

    this.dialogRef = this.dialog.open(OrderMaterialDndComponent, {
      width: '94%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: {
        orderUUID: this.orderUUDID,
        guest_count: this.order.guest_count,
      },
    });

    this.dialogRef.componentInstance.confirmMessage = '';

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.order = result;
          this.resetCheckboxes();
          this.resetSelected();
        }
        this.dialogRef = null;
      },
    });
  }

  public onEditMaterial(material: any, materialCategory: any): void {
    const materialData: any = {
      material: material,
      materialCategory: materialCategory,
    };

    if (!this.editable) {
      return;
    }

    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doEditMaterial', material);
    } else {
      this.doEditMaterial(material, materialData);
    }
  }

  public doEditMaterial(material: any, materialData: any): void {
    const materialCategory = _.cloneDeep(materialData.materialCategory);
    delete materialCategory.material_collection;

    this.dialogRef = this.dialog.open(EditMaterialComponent, {
      disableClose: false,
      minWidth: '1200px',
      panelClass: 'scrollable-dialog',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        material: material,
        orderUUID: this.orderUUDID,
        materialCategory: materialCategory,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
          this.order = result;
        }
        this.dialogRef = null;
      },
    });
  }

  public onDeleteMaterial(material: any): void {
    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doDeleteMaterial', material);
    } else {
      this.doDeleteMaterial(material);
    }
  }

  public doDeleteMaterial(material: any): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.loading = true;
          this.ordersService
            .deleteMaterial(this.orderUUDID, material)
            .subscribe({
              next: (response: any) => {
                this.toastr.success(
                  '',
                  this.translate.instant('GENERIC.DELETE_SUCCESS'),
                );
                this.loading = false;
                this.order = response.data;
                this.resetCheckboxes();
                this.resetSelected();
              },
              error: (error: any) => {
                this.handleAPIError(error, this.dialog, null, null);
                this.loading = false;
              },
            });
        }
      },
    });
  }

  public onCloneOrderIntoNewEvent(keepEventInfo: boolean): void {
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '30%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        subType: CreateEventComponent.CLONE_ORDER_INTO_EVENT,
        keepEventInfo: keepEventInfo,
        event: this.event,
        proposal: this.order,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
            disableClose: false,
          });

          this.confirmDialogRef.componentInstance.useCancel = false;
          this.confirmDialogRef.componentInstance.alertMessage =
            this.translate.instant('ORDERS.VALIDATE_DATE_MESSAGE');

          this.confirmDialogRef.afterClosed().subscribe({
            next: () => {
              this.confirmDialogRef = null;
              this.editable = true;
              this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
            },
          });
        }

        this.dialogRef = null;
      },
    });
  }

  public onClonePropositionIntoNewEvent(keepEventInfo: boolean): void {
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '30%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        subType: CreateEventComponent.CLONE_PROPOSAL_INTO_EVENT,
        keepEventInfo: keepEventInfo,
        event: this.event,
        proposal: this.order,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.loadData();

          this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
            disableClose: false,
          });

          this.confirmDialogRef.componentInstance.useCancel = false;
          this.confirmDialogRef.componentInstance.alertMessage =
            this.translate.instant('ORDERS.VALIDATE_DATE_MESSAGE');

          this.confirmDialogRef.afterClosed().subscribe(() => {
            this.confirmDialogRef = null;
            this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
          });
        }

        this.dialogRef = null;
      },
    });
  }

  public onCloneProposition(): void {
    this.eventsService.cloneProposal(this.order).subscribe({
      next: (response: any) => {
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
      },
      error: (error: any) => {
        this.handleAPIError(error, this.dialog, null, null);
        this.loading = false;
      },
    });
  }

  public onAcceptProposal(): void {
    this.loading = true;
    this.ordersService.acceptProposal(this.orderUUDID).subscribe({
      next: () => {
        this.loading = false;
        this.validateStatus();
      },
      error: (error: any) => {
        this.handleAPIError(error, this.dialog, null, null);
        this.loading = false;
      },
    });
  }

  public onRefuseProposal(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('ORDERS.REFUSE_PROPOSAL_ALERT');
    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;
        if (result) {
          this.loading = true;
          this.ordersService.refuseProposal(this.orderUUDID).subscribe({
            next: (response: any) => {
              this.toastr.success(
                '',
                this.translate.instant('ORDERS.REFUSE_PROPOSAL_SUCESS'),
              );
              this.order = response.data;
              this.loading = false;
              this.validateStatus();
            },
            error: (error: any) => {
              this.handleAPIError(error, this.dialog, null, null);
              this.loading = false;
            },
          });
        }
      },
    });
  }

  public onConfirmSendInvoice(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant(
        'Êtes-vous sûr de vouloir confirmer que cette facture a été envoyée?',
      );
    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;
        if (result) {
          this.loading = true;
          this.ordersService.confirmSendInvoice(this.orderUUDID).subscribe({
            next: (response: any) => {
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
              );
              this.loading = false;
              this.order = response.data;
              this.validateStatus();
            },
            error: (error: any) => {
              this.loading = false;
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
      },
    });
  }
  public onCancelInvoice(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('ORDERS.CANCEL_INVOICE_ALERT');
    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;

        if (result) {
          this.ordersService.cancelInvoice(this.orderUUDID).subscribe({
            next: (response: any) => {
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
              );
              this.loading = false;
              this.order = response.data;
              this.validateStatus();
            },
            error: (error: any) => {
              this.loading = false;
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
      },
    });
  }

  public onCloseInvoice(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('ORDERS.CLOSE_INVOICE_ALERT');
    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;

        if (result) {
          this.ordersService.closeInvoice(this.orderUUDID).subscribe({
            next: (response: any) => {
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
              );
              this.loading = false;
              this.order = response.data;
              this.validateStatus();
            },
            error: (error: any) => {
              this.loading = false;
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
      },
    });
  }

  public onCompleteInvoicePaid(): void {
    this.loading = true;
    this.ordersService.completeAndPaidInvoice(this.orderUUDID).subscribe({
      next: (response: any) => {
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
        );
        this.loading = false;
        this.order = response.data;
        this.validateStatus();
      },
      error: (error: any) => {
        this.loading = false;
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    });
  }

  public onCancelOrder(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('ORDERS.CANCEL_ORDER_ALERT');
    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;

        if (result) {
          this.loading = true;
          this.ordersService.cancelOrder(this.orderUUDID).subscribe({
            next: (response: any) => {
              this.toastr.success(
                '',
                this.translate.instant('ORDERS.CANCEL_ORDER_SUCCESS'),
              );
              this.order = response.data;
              this.loading = false;
              this.validateStatus();
            },
            error: (error: any) => {
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
              this.loading = false;
            },
          });
        }
      },
    });
  }

  public onConfirmOrder(): void {
    const alertTitle: string = this.translate.instant(
      'ORDERS.CONFIRM_ORDER_ALERT_TITLE',
    );
    const alertMessage: string[] = [];

    if (!this.event.clients.invoicing_client) {
      alertMessage.push(
        this.translate.instant(
          'ORDERS.CONFIRM_ORDER_ALERT_NO_INVOICING_CLIENT',
        ),
      );
    }

    if (!this.event.clients.ordering_client) {
      alertMessage.push(
        this.translate.instant('ORDERS.CONFIRM_ORDER_ALERT_NO_ORDERING_CLIENT'),
      );
    }

    if (!this.event.spot) {
      alertMessage.push(
        this.translate.instant('ORDERS.CONFIRM_ORDER_ALERT_NO_SPOT'),
      );
    }

    if (!this.event.delivery_type) {
      alertMessage.push(
        this.translate.instant('ORDERS.CONFIRM_ORDER_ALERT_NO_DELIVERY_TYPE'),
      );
    }

    if (!this.event.informations.event_time) {
      alertMessage.push(
        this.translate.instant('ORDERS.CONFIRM_ORDER_ALERT_NO_EVENT_TIME'),
      );
    }

    if (alertMessage.length > 0) {
      this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
        disableClose: false,
      });

      const lastMessageList: string | undefined = alertMessage.pop();
      const finalMessage: string =
        this.translate.instant('ORDERS.CONFIRM_ORDER_ALERT_PRE_MESSAGE') +
        alertMessage.join(', ') +
        ' ' +
        this.translate.instant('GENERIC.AND') +
        ' ' +
        lastMessageList +
        '.';
      this.confirmDialogRef.componentInstance.useCancel = false;
      this.confirmDialogRef.componentInstance.alertTitle = alertTitle;
      this.confirmDialogRef.componentInstance.alertMessage = finalMessage;

      this.confirmDialogRef.afterClosed().subscribe({
        next: (result: any) => {
          if (result) {
          }
        },
      });
    } else {
      this.loading = true;
      this.ordersService.confirmOrder(this.orderUUDID).subscribe({
        next: (response: any) => {
          this.toastr.success(
            '',
            this.translate.instant('ORDERS.CONFIRM_ORDER_SUCCESS'),
          );
          this.order = response.data;
          this.loading = false;
          this.validateStatus();
        },
        error: (error: any) => {
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
    }
  }

  public onCompleteOrder(): void {
    this.loading = true;
    this.ordersService.completeOrder(this.orderUUDID).subscribe({
      next: () => {},
      error: (error: any) => {
        this.loading = false;
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    });
  }

  public doCompleteAndPayInvoice(invoice: any) {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useYesNo = true;
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('ORDERS.COMPLETE_INVOICE_DIALOG_MESSAGE');
    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.confirmDialogRef = null;
        if (result) {
          this.loading = true;
          this.ordersService.completeAndPaidInvoice(invoice.uuid).subscribe({
            next: (response: any) => {
              this.loading = false;
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
              );
              this.order = response.data;
              this.loading = false;
              this.validateStatus();
            },
            error: (error: any) => {
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
      },
    });
  }

  public onAddServiceCharge(): void {
    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doAddServiceCharge');
    } else {
      this.doAddServiceCharge();
    }
  }

  public doAddServiceCharge(): void {
    this.dialogRef = this.dialog.open(AddServiceChargeComponent, {
      width: '50vw',
      minWidth: 350,
      maxWidth: 500,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        orderUUID: this.orderUUDID,
        mode: AddServiceChargeComponent.CREATE,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.order = result;
        }
        this.dialogRef = null;
      },
    });
  }

  public onEditServiceCharge(serviceCharge: any): void {
    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doEditServiceCharge', serviceCharge);
    } else {
      this.doEditServiceCharge(serviceCharge);
    }
  }

  public doEditServiceCharge(serviceCharge: any): void {
    this.dialogRef = this.dialog.open(AddServiceChargeComponent, {
      width: '50vw',
      minWidth: 350,
      maxWidth: 500,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        orderUUID: this.orderUUDID,
        mode: AddServiceChargeComponent.EDIT,
        serviceChargeToUpdate: serviceCharge,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.order = result;
        }
        this.dialogRef = null;
      },
    });
  }

  public onDeleteServiceCharge(serviceCharge: any): void {
    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doDeleteServiceCharge', serviceCharge);
    } else {
      this.doDeleteServiceCharge(serviceCharge);
    }
  }

  public doDeleteServiceCharge(serviceCharge: any): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.ordersService
            .removeServiceCharge(this.orderUUDID, serviceCharge.uuid_pivot)
            .subscribe({
              next: (response: any) => {
                this.toastr.success(
                  '',
                  this.translate.instant('GENERIC.DELETE_SUCCESS'),
                );
                this.order = response.data;
              },
              error: (error: any) => {
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
      },
    });
  }

  public onAddSpecificCondition(): void {
    this.dialogRef = this.dialog.open(AddSpecificConditionComponent, {
      width: '50vw',
      minWidth: 350,
      maxWidth: 500,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        orderUUID: this.orderUUDID,
        mode: AddSpecificConditionComponent.CREATE,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.order = result;
        }
        this.dialogRef = null;
      },
    });
  }

  public onDeleteSpecificCondition(specificCondition: any): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.ordersService
            .removeSpecificCondition(this.orderUUDID, specificCondition.uuid)
            .subscribe({
              next: (response: any) => {
                this.toastr.success(
                  '',
                  this.translate.instant('GENERIC.DELETE_SUCCESS'),
                );
                this.order = response.data;
              },
              error: (error: any) => {
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
      },
    });
  }

  public onAddStaff(): void {
    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doAddStaff');
    } else {
      this.doAddStaff();
    }
  }

  public doAddStaff(): void {
    this.dialogRef = this.dialog.open(CreateLabourChargeComponent, {
      width: '50vw',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        orderUUID: this.orderUUDID,
        mode: CreateLabourChargeComponent.CREATE,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.order = result;
        }
        this.dialogRef = null;
      },
    });
  }

  public onEditLabourCharge(labourCharge: any): void {
    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doEditLabourCharge', labourCharge);
    } else {
      this.doEditLabourCharge(labourCharge);
    }
  }

  public doEditLabourCharge(labourCharge: any): void {
    this.dialogRef = this.dialog.open(CreateLabourChargeComponent, {
      width: '50vw',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        orderUUID: this.orderUUDID,
        mode: CreateLabourChargeComponent.EDIT,
        labourChargeToUpdate: labourCharge,
      },
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.order = result;
        }
        this.dialogRef = null;
      },
    });
  }

  public onDeleteLabourCharge(labourCharge: any): void {
    if (this.order.status === GT2ConstantsEnum.INVOICE_SEND) {
      this.showInvoiceSendAlertMessage('doDeleteLabourCharge', labourCharge);
    } else {
      this.doDeleteLabourCharge(labourCharge);
    }
  }

  public doDeleteLabourCharge(labourCharge: any): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.ordersService
            .removeLabourCharge(this.orderUUDID, labourCharge.id)
            .subscribe({
              next: (response: any) => {
                this.toastr.success(
                  '',
                  this.translate.instant('GENERIC.DELETE_SUCCESS'),
                );
                this.order = response.data;
              },
              error: (error: any) => {
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
      },
    });
  }

  public onSendOrder(): void {
    this.orderComposeService.parentUUID = this.orderUUDID;

    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: this.orderComposeService,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (response) {
          this.editable = true;
          this.loadData();
        }
        this.dialogRef = null;
      },
    });
  }

  public onSendReceiptNotice(): void {
    this.receiptNoticeComposeService.parentUUID = this.orderUUDID;

    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: this.receiptNoticeComposeService,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (response) {
          this.editable = true;
          this.loadData();
        }
        this.dialogRef = null;
      },
    });
  }

  public onSendReceiptNoticeSellOnly(): void {
    this.receiptNoticeSellOnlyComposeService.parentUUID = this.orderUUDID;

    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: this.receiptNoticeSellOnlyComposeService,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (response) {
          this.editable = true;
          this.loadData();
        }
        this.dialogRef = null;
      },
    });
  }

  public onSendStorageNotice(): void {
    this.storageNoticeComposeService.parentUUID = this.orderUUDID;

    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: this.storageNoticeComposeService,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (response) {
          this.editable = true;
          this.loadData();
        }
        this.dialogRef = null;
      },
    });
  }

  public onSendEmailToMangerUBR(): void {
    this.orderComposeService.parentUUID = this.orderUUDID;
    this.loading = true;
    this.orderComposeService.sendEmailUBR().subscribe({
      next: () => {
        this.loading = false;
      },
      error: (error) => {
        this.handleAPIError(error, this.dialog, null, null);
        this.loading = false;
      },
    });
  }

  public doPrint(url: string): void {
    this.print.printHTML(url).subscribe({
      next: () => {},
      error: (error: any) => {
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doDownload(url: string): void {
    this.fileService.downloadFile(url).subscribe({
      next: () => {},
      error: (error: any) => {
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onPrintOrder(): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PRINT,
        'client/report-client-order',
        'fr-CA',
      ) +
      '/' +
      this.orderUUDID;
    this.doPrint(url);
  }

  public onPrintPurchaseOrder(
    local: string = 'fr-CA',
    format: string = 'a4',
  ): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PRINT,
        'client/report-client-order-purchase-order-production',
        format + '/' + local,
      ) +
      '/' +
      this.orderUUDID;
    this.doPrint(url);
  }

  public onPrintPurchaseOrderClient(
    local: string = 'fr-CA',
    format: string = 'a4',
  ): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PRINT,
        'client/report-client-order-purchase-order-client-copy',
        format + '/' + local,
      ) +
      '/' +
      this.orderUUDID;
    this.doPrint(url);
  }

  public onPrintPurchaseOrderKitchen(
    local: string = 'fr-CA',
    format: string = 'a4',
  ): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PRINT,
        'mngt/report-mngt-order-purchase-order-kitchen',
        format + '/' + local,
      ) +
      '/' +
      this.orderUUDID;
    this.doPrint(url);
  }

  public onPrintPurchaseOrderMaterial(
    local: string = 'fr-CA',
    format: string = 'a4',
  ): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PRINT,
        'mngt/report-mngt-order-purchase-material',
        format + '/' + local,
      ) +
      '/' +
      this.orderUUDID;
    this.doPrint(url);
  }

  public onPrintPurchaseOrderOnlyMaterial(
    local: string = 'fr-CA',
    format: string = 'a4',
  ): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PRINT,
        'mngt/report-mngt-order-purchase-only-material',
        format + '/' + local,
      ) +
      '/' +
      this.orderUUDID;
    this.doPrint(url);
  }

  public onDownloadOrder(format: string = 'a4'): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        'client/report-client-order',
        'fr-CA',
      ) +
      '/' +
      this.orderUUDID;
    this.doDownload(url);
  }

  public onDownloadPurchaseOrder(
    local: string = 'fr-CA',
    format: string = 'a4',
  ): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        'client/report-client-order-purchase-order-production',
        format + '/' + local,
      ) +
      '/' +
      this.orderUUDID;
    this.doDownload(url);
  }

  public onDownloadPurchaseOrderKitchen(
    local: string = 'fr-CA',
    format: string = 'a4',
  ): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        'mngt/report-mngt-order-purchase-order-kitchen',
        format + '/' + local,
      ) +
      '/' +
      this.orderUUDID;
    this.doDownload(url);
  }

  public onDownloadPurchaseOrderClient(
    local: string = 'fr-CA',
    format: string = 'a4',
  ): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        'client/report-client-order-purchase-order-client-copy',
        format + '/' + local,
      ) +
      '/' +
      this.orderUUDID;
    this.doDownload(url);
  }

  public onDownloadPurchaseOrderMaterial(
    local: string = 'fr-CA',
    format: string = 'a4',
  ): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        'mngt/report-mngt-order-purchase-material',
        format + '/' + local,
      ) +
      '/' +
      this.orderUUDID;
    this.doDownload(url);
  }

  public onDownloadPurchaseOrderOnlyMaterial(
    local: string = 'fr-CA',
    format: string = 'a4',
  ): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        'mngt/report-mngt-order-purchase-only-material',
        format + '/' + local,
      ) +
      '/' +
      this.orderUUDID;
    this.doDownload(url);
  }

  public onDownloadPurchaseOrderStaff(local: string = 'fr-CA'): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        'mngt/report-mngt-order-purchase-staff',
        local,
      ) +
      '/' +
      this.orderUUDID;
    this.doDownload(url);
  }

  public onPrintProposalReport(): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PRINT,
        'client/report-client-proposal',
        'fr-CA',
      ) +
      '/' +
      this.orderUUDID;
    this.doPrint(url);
  }

  public onDowloadProposalReport(): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        'client/report-client-proposal',
        'fr-CA',
      ) +
      '/' +
      this.orderUUDID;
    this.doDownload(url);
  }

  public onSendPropsalReport(): void {
    this.proposalComposeService.parentUUID = this.orderUUDID;

    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: this.proposalComposeService,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        this.loading = false;
        if (response) {
          this.loadData();
        }
        this.dialogRef = null;
      },
    });
  }

  public onPrintInvoiceReport(): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PRINT,
        'client/report-client-invoice',
        'fr-CA',
      ) +
      '/' +
      this.orderUUDID;
    this.doPrint(url);
  }

  public onDowloadInvoiceReport(): void {
    const url: string =
      this.api.createReportURL(
        ApiRoutes.REPORT_PDF,
        'client/report-client-invoice',
        'fr-CA',
      ) +
      '/' +
      this.orderUUDID;
    this.doDownload(url);
  }

  public onSendInvoiceReport(): void {
    this.invoiceComposeService.parentUUID = this.orderUUDID;

    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: this.invoiceComposeService,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        this.loading = false;
        if (response) {
          this.loadData();
        }
        this.dialogRef = null;
      },
    });
  }

  public onSendInvoiceGlobalPaymentsReport(): void {
    this.invoiceComposeGlobalPaymentsService.parentUUID = this.orderUUDID;

    this.dialogRef = this.dialog.open(MailComposeDialogComponent, {
      panelClass: 'mail-compose-dialog',
      data: {
        composeService: this.invoiceComposeGlobalPaymentsService,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        this.loading = false;
        if (response) {
          this.loadData();
        }
        this.dialogRef = null;
      },
    });
  }

  public onToggleComboOpen(item: any): void {
    item.comboToggleOpen = !item.comboToggleOpen;
    localStorage.setItem(
      OrderComponent.ORDER_COMBO_OPEN + item.uuid,
      item.comboToggleOpen ? 'true' : 'false',
    );
  }

  public onToggleComboItemVisibility(comboItem: any, material: any): void {
    comboItem.internal_view_only = !comboItem.internal_view_only;

    this.ordersService.updateMaterials(this.order.uuid, [material]).subscribe({
      next: (response: any) => {
        this.loading = false;
        this.order = response.data;
      },
      error: (error: any) => {
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onToggleComboVisibility(combo: any, value: boolean): void {
    if (combo.internal_view_only === undefined) {
      combo.internal_view_only = false;
    }
    combo.internal_view_only = !value;
    for (let i = 0; i < combo.combo.length; ++i) {
      combo.combo[i].internal_view_only = combo.internal_view_only;
    }

    this.ordersService.updateMaterials(this.order.uuid, [combo]).subscribe({
      next: (response: any) => {
        this.loading = false;
        this.order = response.data;
      },
      error: (error: any) => {
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onToggleComboItemMoney(comboItem: any, material: any): void {
    comboItem.show_unit_price = !comboItem.show_unit_price;

    this.ordersService.updateMaterials(this.order.uuid, [material]).subscribe({
      next: (response: any) => {
        this.loading = false;
        this.order = response.data;
      },
      error: (error: any) => {
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onToggleComboMoney(combo: any, value: boolean): void {
    if (combo.show_unit_price === undefined) {
      combo.show_unit_price = false;
    }
    combo.show_unit_price = !value;
    for (let i = 0; i < combo.combo.length; ++i) {
      combo.combo[i].show_unit_price = combo.show_unit_price;
    }

    this.ordersService.updateMaterials(this.order.uuid, [combo]).subscribe({
      next: (response: any) => {
        this.loading = false;
        this.order = response.data;
      },
      error: (error: any) => {
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onToggleItemVisibility(item: any): void {
    item.internal_view_only = !item.internal_view_only;
    this.ordersService.updateMaterials(this.order.uuid, [item]).subscribe({
      next: (response: any) => {
        this.loading = false;
        this.order = response.data;
      },
      error: (error: any) => {
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public onToggleItemMoney(item: any): void {
    if (!item.internal_view_only) {
      item.show_unit_price = !item.show_unit_price;
      this.ordersService.updateMaterials(this.order.uuid, [item]).subscribe({
        next: (response: any) => {
          this.loading = false;
          this.order = response.data;
        },
        error: (error: any) => {
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
    }
  }

  public onVisitEvent(): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(this.order.event));
  }

  public onVisitDeposit(): void {
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL(this.order.event) + '?tab=encaissement',
    );
  }

  public onOpenObjectPage(item: any): void {
    let url = ItemUrlFinder.getItemURL(item);
    this.router.navigateByUrl(url);
  }

  public onOpenItemInNewTab(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }

  public onOpenProductInNewTab(product: any): void {
    window.open(ItemUrlFinder.getItemURL(product), '_blank');
  }

  public onOpenInNewTab(goto: any): void {
    window.open(ItemUrlFinder.getItemURL(goto), '_blank');
  }

  public onSelectedChange(productUUID: any): void {
    if (this.selectedProducts.length > 0) {
      const index = this.selectedProducts.indexOf(productUUID);

      if (index !== -1) {
        this.selectedProducts.splice(index, 1);
        return;
      }
    }

    this.selectedProducts.push(productUUID);
  }

  productAllCheckboxes: boolean = false;
  public onSelectedAllChange(): void {
    for (const product in this.productCheckboxes) {
      const selected = this.productCheckboxes[product];
      this.productCheckboxes[product] = this.productAllCheckboxes;
      if (selected !== this.productAllCheckboxes) {
        this.onSelectedChange(product);
      }
    }
  }

  public onSelectedMaterialsChange(materialUUID: any): void {
    if (this.selectedMaterials.length > 0) {
      const index = this.selectedMaterials.indexOf(materialUUID);

      if (index !== -1) {
        this.selectedMaterials.splice(index, 1);
        return;
      }
    }

    this.selectedMaterials.push(materialUUID);
  }

  materialAllCheckboxes: boolean = false;
  public onSelectedMaterialAllChange(): void {
    for (const material in this.materialCheckboxes) {
      const selected = this.materialCheckboxes[material];
      this.materialCheckboxes[material] = this.materialAllCheckboxes;
      if (selected !== this.materialAllCheckboxes) {
        this.onSelectedMaterialsChange(material);
      }
    }
  }

  public resetCheckboxes(): void {
    this.productCheckboxes = {};
    this.order.product_category_collection.map((productCategory: any) => {
      productCategory.product_collection.map((product: any) => {
        this.productCheckboxes[product.uuid] = false;
      });
    });

    this.materialCheckboxes = {};
    this.order.material_category_collection.map((materialCategory: any) => {
      materialCategory.material_collection.map((material: any) => {
        this.materialCheckboxes[material.uuid] = false;
      });
    });
  }

  public resetSelected(): void {
    this.selectedProducts = [];
    this.selectedMaterials = [];
  }

  public getSelectedCollection(section: string): any {
    if (section === 'materials') {
      return this.selectedMaterials;
    }
    return this.selectedProducts;
  }

  public groupModifyQty(section: string): void {
    this.confirmDialogRef = this.dialog.open(GroupActionSingleInputComponent, {
      disableClose: false,
      data: {
        title: this.translate.instant('ORDERS.GROUP_ACTION_QTY_MODAL_TITLE'),
        type: GroupActionSingleInputComponent.TYPE_QTY,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.ordersService
            .updateGroupAction(
              this.order.uuid,
              section,
              'qty',
              result,
              this.getSelectedCollection(section),
            )
            .subscribe({
              next: (response: any) => {
                this.order = response.data;
                this.loading = false;
              },
              error: (error: any) => {
                this.loading = false;
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
      },
    });
  }

  public groupModifyPrice(section: string): void {
    this.confirmDialogRef = this.dialog.open(GroupActionSingleInputComponent, {
      disableClose: false,
      data: {
        title: this.translate.instant('ORDERS.GROUP_ACTION_PRICE_MODAL_TITLE'),
        type: GroupActionSingleInputComponent.TYPE_PRICE,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.ordersService
            .updateGroupAction(
              this.order.uuid,
              section,
              'price',
              result,
              this.getSelectedCollection(section),
            )
            .subscribe({
              next: (response: any) => {
                this.order = response.data;
                this.loading = false;
              },
              error: (error: any) => {
                this.loading = false;
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
      },
    });
  }

  public groupModifyPriceFree(section: string): void {
    this.ordersService
      .updateGroupAction(
        this.order.uuid,
        section,
        'price',
        0,
        this.getSelectedCollection(section),
      )
      .subscribe({
        next: (response: any) => {
          this.order = response.data;
          this.loading = false;
        },
        error: (error: any) => {
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public groupDelete(section: string): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM_S');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.loading = true;
          this.ordersService
            .updateGroupAction(
              this.order.uuid,
              section,
              'remove',
              null,
              this.getSelectedCollection(section),
            )
            .subscribe({
              next: (response: any) => {
                this.order = response.data;
                this.loading = false;
                this.resetCheckboxes();
                this.resetSelected();
              },
              error: (error: any) => {
                this.loading = false;
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
      },
    });
  }

  public groupVisibleToClient(value: boolean): void {
    this.ordersService
      .updateGroupAction(
        this.order.uuid,
        'materials',
        'visible',
        value,
        this.selectedMaterials,
      )
      .subscribe({
        next: (response: any) => {
          this.order = response.data;
          this.loading = false;
        },
        error: (error: any) => {
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public groupShowPriceToClient(value: boolean): void {
    this.ordersService
      .updateGroupAction(
        this.order.uuid,
        'materials',
        'visible-price',
        value,
        this.selectedMaterials,
      )
      .subscribe({
        next: (response: any) => {
          this.order = response.data;
          this.loading = false;
        },
        error: (error: any) => {
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public onAffectations(): void {
    const url: string = ItemUrlFinder.getItemURL(this.event) + '/affectations';
    this.router.navigateByUrl(url);
  }

  public onRoomStatusChange(event: any): void {
    this.event.room_reservations.status = event.value;
  }
  public onNavigateToCongress(): void {
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: 'Congress',
        uuid: this.event.congress_uuid,
        slug: this.event.congress_slug,
      }),
    );
  }

  public onNavigateToProject(): void {
    this.router.navigateByUrl(
      ItemUrlFinder.getItemURL({
        object: 'Project',
        uuid: this.event.project_uuid,
        slug: this.event.project_slug,
      }),
    );
  }
  public synchOrderStaffsFromAssigments(): void {
    this.ordersService.getJobAssignmentByEvent(this.event.uuid).subscribe({
      next: (response: any) => {
        this.OrderStaffs = [];
        if (response.data.length > 0) {
          for (var jf of response.data) {
            jf.job_function.make_assignment = 'yess';
            jf.job_function.real_date_from = jf.real_date_from;
            jf.job_function.real_date_to = jf.real_date_to;
            jf.job_function.real_time_from = jf.real_time_from;
            jf.job_function.real_time_to = jf.real_time_to;
            jf.job_function.group_assignment_slug = jf.group_assignment_slug;
            this.OrderStaffs.push(jf.job_function);
          }
          this.cleanJobAssignmentsToOrderStaff(this.order.staff);
        }
      },
      error: (error: any) => {
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public addJobAssignmentsToOrderStaff(): void {
    this.ordersService
      .addLabourCharge(this.orderUUDID, {
        staffs: this.OrderStaffs,
      })
      .subscribe({
        next: (response: any) => {
          this.order = response.data;
        },
        error: (error: any) => {
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      });
  }

  public cleanJobAssignmentsToOrderStaff(assignmentStaff: any): void {
    this.ordersService
      .removeJobAssginmentFromOrderStaff(this.orderUUDID, {
        staffs: assignmentStaff,
      })
      .subscribe({
        next: (response: any) => {
          this.order = response.data;
          this.addJobAssignmentsToOrderStaff();
        },
        error: (error: any) => {
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public getSelectedDrinks(): void {
    for (let productCategory of this.order.product_category_collection) {
      for (let product of productCategory.product_collection) {
        if (product.is_drink) {
          this.selectedDrinks.push(product);
        }
      }
    }
  }

  public onInvoicedDateChange(event: any): void {
    var dt = moment(event).tz(this.timeZone).format();
    this.order.invoiced_at = dt;
  }
  public formatDate(date: any): string {
    return moment(date).format('YYYY-MM-DD').toString();
  }

  public openDrinksValidationAlert(): void {
    this.moduleService.modulesSet.subscribe({
      next: (areSet) => {
        this.selectedDrinks = [];
        this.getSelectedDrinks();
        if (this.selectedDrinks.length > 0) {
          const dialogRef = this.dialog.open(AlertDrinksValidationComponent, {
            width: '50vw',
            panelClass: 'scrollable-dialog',
            minWidth: 350,
            data: {
              selectedDrinks: this.selectedDrinks,
              orderUUID: this.orderUUDID,
            },
          });

          dialogRef.afterClosed().subscribe({
            next: (result) => {
              if (result == true) {
                this.onCompleteOrder();
              }
            },
          });
        } else {
          this.onCompleteOrder();
        }
      },
    });
  }

  public openChangelogDialog() {
    if (
      this.moduleService.hasChangelogsModule() &&
      this.order.status === GT2ConstantsEnum.ORDER_CONFIRM
    ) {
      const dialogRef = this.dialog.open(ChangelogsDialogComponent, {
        width: '50vw',
        minWidth: 350,
        panelClass: 'scrollable-dialog',
        data: {
          changelog: this.order.changelog,
          event_uuid: this.event.uuid,
          order_uuid: this.order.uuid,
        },
      });

      dialogRef.afterClosed().subscribe({
        next: (response) => {
          if (response) {
            this.order.changelog = response.data;
          }
        },
      });

      this.dialogRef = null;
    }
  }
}

export interface OrderModel {
  uuid: string;
  reference: any;
  name: string;
  slug: string;
  type: string;
  status_label: string;
  status: string;
  invoice_number: string;
  proposal_number: string;
  order_number: string;
  guest_count: number;
  project: any;
  event: any;
  place: any;
  room: any;
  shop: any;
  wrapping: any;
  responsible_employee: any;
  non_taxable_tip: number;
  invoicing_client: any;
  ordering_client: any;
  from_proposal: any;
  from_order: any;
  note: string;
  note_allergens: string;
  note_kitchen: string;
  note_materials: string;
  product_subtotal: string;
  product_subtotal_raw: number;
  product_category_collection: any[];
  material_subtotal: string;
  material_subtotal_raw: number;
  material_category_collection: any[];
  total_collection: any[];
  service_charge: any[];
  service_charge_normal: any[];
  service_charges_multiply_date_range: any[];
  service_charges_percent_invoice_cat: any[];
  specific_condition: any[];
  historic_model: any;
  product_allergen_collection: any[];
  staff: any[];
  invoiced_at: string;
  invoiced_at_label: string;
  proposal_expired_at: string;
  proposal_expired_at_label: string;
  staff_subtotal: string;
  staff_subtotal_raw: number;
  service_charge_subtotal: string;
  service_charge_subtotal_raw: number;
  changelog: any[];
  total_food_included: any;
  total_food_charged: any;
  total_drink_included: any;
  total_drink_charged: any;
  total_included: any;
  total_charged: any;
  per_person_food_included: any;
  per_person_food_charged: any;
  per_person_drink_included: any;
  per_person_drink_charged: any;
  per_person_total_included: any;
  per_person_total_charged: any;
  is_ctm: any;
}
