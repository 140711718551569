import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { PictureUploadComponent } from '../../../../components/picture-upload/picture-upload.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { Gt2PrimengModule } from '../../../../modules/gt2-primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../../@fuse/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-message-image-settings',
  templateUrl: './message-image-settings.component.html',
  styleUrls: ['./message-image-settings.component.scss'],
  standalone: true,
  imports: [
    PictureUploadComponent,
    MatButtonModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    TranslateModule,
    MatFormFieldModule,
    MatOptionModule,
  ],
})
export class MessageImageSettingsComponent implements OnInit, AfterViewInit {
  @Input() shopMessageImage: any;
  @Input() editMode: boolean = false;
  @Input() form!: UntypedFormGroup;
  @Output() saveMessageImage: EventEmitter<any> = new EventEmitter();
  @Output() clealMessageImage: EventEmitter<any> = new EventEmitter();

  constructor(private logger: NGXLogger) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    // this.logger.info('MessageImageSettingsComponent.ngAfterViewInit() -> shopMessageUUID: ' + this.shopMessageImage);
    // setTimeout( () => {
    //     this.logger.info('MessageImageSettingsComponent.ngAfterViewInit() -> 2 shopMessageImage: ' + JSON.stringify(this.shopMessageImage));
    // }, 2000);
  }

  public onSaveInfo(): void {
    this.saveMessageImage.emit(this.shopMessageImage);
  }

  public onClear(): void {
    this.clealMessageImage.emit(this.shopMessageImage);
  }
}
