<div style="max-height: 100%; overflow: auto">
  <div *ngIf="modelDates" style="width: 500px">
    <app-fullcalendar-picker
      #calendat
      [(modelDates)]="shop_cyclic_dates_clone"
      (datesEvent)="onDatesEvent($event)"
    >
    </app-fullcalendar-picker>
  </div>

  <div
    *ngIf="!modelDates"
    style="width: 500px; height: 416px"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <mat-spinner [diameter]="28" class=""></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      color="primary"
      class="mr-8"
      (click)="onClearAllDates()"
    >
      {{ "GENERIC.CLEAR_ALL_DATES" | translate }}
    </button>

    <button
      mat-raised-button
      color="accent"
      class="mr-16"
      (click)="onSaveDates()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="onCancelDates()"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>

  <!-- <div class="" style="width: 100%;">
    <mat-form-field class="" style="width: 100%;">
        <mat-chip-listbox>
            <mat-chip *ngFor="let date of shop.cyclic_dates | slice:0:10; let i = index" [selectable]="true"
                [removable]="true" (removed)="remove(date)">
                {{date}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
        </mat-chip-listbox>
    </mat-form-field>
</div> -->
</div>
