export const locale = {
  lang: "en-CA",
  data: {
    ATTACH_ENTERPRISE: {
      TITLE: "Attach an enterprise to ",
      ATTACH: "Attach",
      SELECTED_ENTERPRISE: "Selected enterprise: ",
    },
  },
};
