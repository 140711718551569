export const locale = {
  lang: "en-CA",
  data: {
    MANAGEMENT: {
      TITLE: "Management",
      PRODUCTION: "Production",
      PRODUCTION_ZONE_LIST: "Production list by production zone",
      FORECAST_PRODUCTION_ZONE_LIST_HINT: "Production report including proposals and unconfirmed orders.",
      FORECAST_PRODUCTION_ZONE_LIST: "Production list by production zone - Forecast",
      PRODUCTION_ZONE_LIST_JUL: "Production list by production zone - JUL",
      PRODUCT_LIST: "Product list food and beverage",
      LOGISTIC: "Logistic",
      DELIVERY_A: "Delivery list A",
      DELIVERY_B: "Delivery list B",
      MATERIAL_LIST: "Equipment list",
      INCOMING_EVENT: "Incoming event",
      EVENT_BY_CONTACT: "Event by contact",
      ACTIVITY_REPORT: "Activity report",
      EVENT_BY_STATUS: "Event by status",
      NEW_CLIENT: "New client",
      LETTER_FORMAT: "letter format",
      LEGAL_FORMAT: "legal format",
      TAKEOUT: "Takeout list",
      DELIVERY_TAKEOUT_LIST: "JR delivery and takeout list",
      PRODUCTION_ZONE_ALLERGIES_LIST: "Production list by production zone with allergies",
      PRODUCTION_ZONE_SIMPLIFIED_LIST: "Production list by production zone simplified",
      EVENT_LIST: "Events list",
      EVENT_LIST_VIC: "Events list | Vic",
      EVENT_LIST_UQAM: "Events list - UQAM",
      BUFFET_LABEL_5963: "Tray and buffet label",
      BOX_LABEL_55164: "Box label",
      BUFFET_BOX_LABEL_1323: "Tray, buffet and box label | JUL",
      ORDER_PURCHASE_ORDER: "Orders & Purchase Orders | Day",
      PURCHASE_ORDER: "Purchase Orders",
      PURCHASE_ORDER_DAY_ALLERGIES: "Purchase Orders day allergies without price",

      REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY: "Purchase orders",

      REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY: "Purchase orders allergies",

      REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_WARMBUFFET_NO_PRICE: "Purchase orders - JUL | Boxes allergies",

      REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE: "Purchase orders - JUL | Box",

      REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY_HINT: "This report includes all purchase orders including a note in the field allergies and dietary restrictions",

      REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE_AL: "Purchase orders - JUL | Box Allergie",
      REPORT2_MNGT_PROD_LIST_BY_ORDER_XLS: "Production by order",
      REPORT2_MNGT_PROD_LIST_CAC_BY_ORDER_XLS: "Production by order - CAC",

      REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF_STANDALONE_STACK: "Purchase order – kitchen and staff – DAN",
      REPORT_MNGT_EVENT_FINANCIAL_DATA: "Upcoming Events – Financials",
      EMPLOYEE: "Employees",
      LOGIN_HISTORY: "Login history",
      ORDERS_BILLED_STAFF: "Billed staff",
      CONGRESS: "Congress",
      CHANGE_LOG: "Notice of change",
      CHANGE_LOG_DATE_RANGE: "Notice of change per event date",
      ORDERS_PURCHASE_ORDERS_DAY: "Orders & purchase orders | per day",
      PURCHASE_ORDER_CHEF: "Purchase order Chef - MC",
      PURCHASE_ORDER_HOT: "Purchase order Hot - MC",
      PURCHASE_ORDER_COLD: "Purchase order Cold - MC",
      PURCHASE_ORDER_PASTRY: "Purchase order Pastry - MC",
      PURCHASE_ORDER_SERVICE: "Purchase order service - MC",
      PURCHASE_ORDER_HOD_AND_COLD: "Purchase order Hot/Cold - MC",
      //
      PURCHASE_ORDER_CHEF_NEW_EVT: "Purchase order Chef | New EVT - MC",
      PURCHASE_ORDER_HOT_NEW_EVT: "Purchase order Hot | New EVT - MC",
      PURCHASE_ORDER_COLD_NEW_EVT: "Purchase order Cold | New EVT - MC",
      PURCHASE_ORDER_PASTRY_NEW_EVT: "Purchase order Pastry | New EVT - MC",
      PURCHASE_ORDER_HOT_AND_COLD_NEW_EVT: "Purchase order Hot/Cold | New EVT - MC",
      //
      INVOICE: "Facture - MC",
      INVOICE_UQAM: "Invoices - UQAM",
      REPORT2_ADMIN_ACCOUNTING_LIST_OF_ORDERS_CREATION_DATE_XLSX: "Event by creation date",
      EVENT_TIME_END: "Event end time",
      EVENT_TIME_START: "Event start time",
      NEW_EVENTS_CTM_REPORT: "List of new events - MC",
      REPORT2_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_BY_DAY_ST_GAB: "Purchase orders - Kitchen - St-Gab",
    },
    REPORTS_HELP: {
      TITLE: "Repports",
      CTM_HELP_MESSAGE: "This document only includes confirmed and invoicing orders",
    },
  },
};
