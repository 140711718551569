<h1 matDialogTitle>"SHOPS.ADD_MULTI_POINT_TAKEOUT"</h1>

<div
  fxLayout="column"
  fxLayoutAlign="center start"
  [formGroup]="form"
  (keyup.enter)="form.valid && !loading ? onCreatePoint() : null"
  class="mt-8"
  sty
>
  <mat-form-field fxFlex="100%" class="item-full-width">
    <mat-label>SHOPS.NAME_FR</mat-label>
    <input
      matInput
      fxFlex="100%"
      placeholder="SHOPS.NAME_FR"
      formControlName="name_fr_CA"
      autocomplete="nope"
      type="text"
      [(ngModel)]="point.name_fr_CA"
      required
    />
    <mat-error *ngIf="form.controls['name_fr_CA'].invalid"
      >"GENERIC.INPUT_LENGTH_INVALID"
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="100%" class="item-full-width">
    <mat-label>SHOPS.NAME_EN</mat-label>
    <input
      matInput
      fxFlex="100%"
      placeholder="SHOPS.NAME_EN"
      formControlName="name_en_CA"
      autocomplete="nope"
      type="text"
      [(ngModel)]="point.name_en_CA"
      required
    />
    <mat-error *ngIf="form.controls['name_en_CA'].invalid"
      >"GENERIC.INPUT_LENGTH_INVALID"
    </mat-error>
  </mat-form-field>
</div>

<div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
  <mat-spinner [diameter]="28" class="mt-20"></mat-spinner>
</div>

<div
  mat-dialog-actions
  class="mt-16 gt2-buttons-theme"
  fxLayout="row"
  fxLayoutAlign="center start"
>
  <button
    mat-raised-button
    [disabled]="!form.valid || loading"
    color="accent"
    class="mr-16"
    (click)="onCreatePoint()"
  >
    "GENERIC.SAVE"
  </button>

  <button
    mat-raised-button
    color="primary"
    (click)="dialogRef.close(false)"
    class=""
  >
    "GENERIC.CANCEL"
  </button>
</div>
