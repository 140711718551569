export const locale = {
  lang: "en-CA",
  data: {
    CATERER: {
      CATERER: "Caterer",
      GRAPHICS: "Graphics",
      LOGO: "Logo",
      UPLOAD_LOGO: "Upload Logo",
      UPLOAD_LOGO_SUCCESS: "Logo uploaded successfully",
      UPLOAD_LOGO_DIMENSION: "minimum width & height: 200px",
      UPLOAD_HEADER: "Upload Header",
      UPLOAD_HEADER_SUCCESS: "Header uploaded successfully",
      UPLOAD_HEADER_DIMENSION: "minimum width: 600, minimum height: 120px",
      SELECT_LOCALES: "Select one or more languages this organization want to use from the list below.",
      SELECT_DEFAULT_LOCALES: "Select default language for this organization",
      CURRENT_LOGO: "Current Logo",
      CURRENT_REPORT_HEADER: "Current Reports Header",
      CURRENT_REPORT_ASSETS: "Other Reports Assets",
      NEXT_PAYMENT_DATE: "Next payment date",
      OPEN_SINCE: "Open since",
      TOTAL_USER: "Total paying user",
      CURRENT_COST: "Monthly current cost",
      RESPONSIBLE_USER: "Responsible user",
      ORG_LIST: "List of caterer",
      TRIAL: "Trial",
      SELECT_EMPLOYEE: "Select a user",
    },
  },
};
