import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { IGetDataService } from '../components/gt2-select/gt2-select.component';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';

@Injectable()
export class TaxesService implements IGetDataService {
  taxesObs?: Observable<any>;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getTaxes() {
    // this.logger.info("TaxesService.getTaxes()");
    if (!this.taxesObs) {
      this.taxesObs = this.http
        .get<any>(this.api.createUrl(ApiRoutes.TAXES))
        .pipe(shareReplay(1))
        .pipe(tap((data) => this.resetObservable()));
    }

    return this.taxesObs;
  }

  private resetObservable(): void {
    // this.logger.info("TaxesService.resetTaxes()");
    this.taxesObs = null;
  }

  public getData(): Observable<any> {
    // this.logger.info("TaxesService.getData()");
    return this.getTaxes();
  }

  public getTaxesItems() {
    // this.logger.info("TaxesService.getTaxesItems()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.TAXES_ITEMS));
  }

  public updateTaxItem(taxUUID: string, taxItem: any) {
    // this.logger.info("TaxesService.updateTaxItem() -> taxItem: " + JSON.stringify(taxItem));
    // this.logger.info("TaxesService.updateTaxItem() -> taxUUID: " + taxUUID);
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.TAXES_ITEMS_PREF) + taxUUID,
      taxItem
    );
  }
}
