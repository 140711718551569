import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class TakeoutRequestService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public createTakoutRequest(delivery: any, eventUUID: string): any {
    //this.logger.info("DeliveryRequestService.createDeliveryRequest() -> " + JSON.stringify(delivery));
    //this.logger.info("DeliveryRequestService.createDeliveryRequest() -> eventUUID: " + eventUUID);
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT) + "takeout-requests/" + eventUUID, delivery);
  }

  public deleteTakoutRequest(delivery: any, eventUUID: string): any {
    //this.logger.info("DeliveryRequestService.deleteDeliveryRequest() -> " + JSON.stringify(delivery));
    //this.logger.info("DeliveryRequestService.deleteDeliveryRequest() -> eventUUID: " + eventUUID);
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.EVENT) + "takeout-requests/" + eventUUID + "/" + delivery.uuid);
  }

  public updateTakoutRequest(delivery: any, eventUUID: string): any {
    //this.logger.info("DeliveryRequestService.updateDeliveryRequest() -> " + JSON.stringify(delivery));
    //this.logger.info("DeliveryRequestService.updateDeliveryRequest() -> eventUUID: " + eventUUID);
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.EVENT) + "takeout-requests/" + eventUUID + "/" + delivery.uuid, delivery);
  }

  public getNewTakoutRequestData(eventUUID: string): any {
    return this.http.get<any>(this.api.createUrl(ApiRoutes.EVENT_TAKEOUT_REQUEST_DATA) + eventUUID);
  }
}
