import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { Gt2LocaleService } from '../../../localization/gt2-locale.service';
import { AuthenticationService } from '../../api/auth-service.service';
import { AddressComponent } from '../../components/address/address.component';
import { ContactComponent } from '../../components/contact/contact.component';
import { RolesService } from '../../services/roles.service';
import { Settings } from '../../settings/settings';
import { GT2FormPageAbstract } from '../abstract/GT2FormPageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';
import { CrudOrganizationService } from '../crud/crud-organization/crud-organization.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { UserService } from '../../api/user.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-caterer',
  templateUrl: './caterer.component.html',
  styleUrls: ['./caterer.component.scss'],
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
  animations: fuseAnimations,
})
export class CatererComponent
  extends GT2FormPageAbstract
  implements OnInit, OnDestroy
{
  @ViewChild('addressComponent') addressComponent?: AddressComponent;
  @ViewChild('contactComponent') contactComponent?: ContactComponent;

  catererUUID!: string;
  caterer: any;
  catererForm: UntypedFormGroup;
  loading: boolean = false;
  editMode: boolean = false;
  isVisible: boolean = true;
  displayedOrganizationColumns: string[] = ['name', 'actionsColumn'];
  // orgListdataSource:
  orgList: any = {};

  // logoUploadURL: string;
  // headerUploadURL: string;
  // uploadedFiles: any[] = [];
  // uploadedLogoResponse: LogoResponse;
  // uploadedLogoError: string;
  // uploadedHeaderResponse: ReportHeaderResponse;
  // uploadedHeaderError: string;

  // gt2AppSupportedLocale: LocaleModel[];
  // clientSupportedLocale: LocaleModel[] = [];

  // languages: any;
  // selectedLanguage: any;

  constructor(
    private auth: AuthenticationService,
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private dialog: MatDialog,
    public translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private toastr: ToastrService,
    private titleService: Title,
    private gt2LocaleService: Gt2LocaleService,
    private api: Gt2ApiService,
    public location: Location,
    public rolesService: RolesService,
    public userService: UserService,
    private route: ActivatedRoute,
    public router: Router,
    private orgService: CrudOrganizationService
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    // this.logger.info("CatererComponent.constructor()!!!!!");
    this.catererForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
          Validators.required,
        ],
      ],
      owner_user: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
          Validators.required,
        ],
      ],
    });

    this.setControlsChanges(this.catererForm);
    this.orgList.onOrgListChanged = new BehaviorSubject({});
    this.orgList.dataSource = new FilesDataSource(this.orgList);
    // this.logoUploadURL = this.api.createUrl(ApiRoutes.UPLOAD_LOGO);
    // this.headerUploadURL = this.api.createUrl(ApiRoutes.UPLOAD_HEADER);
  }

  ngOnInit() {
    // this.logger.info("CatererComponent.ngOnInit()");

    //this.titleService.setTitle(this.translate.instant("CATERER.CATERER"));

    // this.gt2LocaleService.loadAppSupportedLocale().subscribe( response =>
    // {
    //     this.logger.info("CrudOrganizationComponent.constructor().loadAppSupportedLocale() SUCCESS -> " + JSON.stringify(response));
    //     this.gt2AppSupportedLocale = response.data;
    // }, error =>
    // {
    //     this.logger.error("CrudOrganizationComponent.constructor().loadAppSupportedLocale() ERROR -> " + JSON.stringify(error));
    // });

    // //
    // this.languages = LocalizationConfig.LANGUAGES;
    // this.selectedLanguage = LocalizationConfig.getCurrentLanguage();

    this.route.params.subscribe((params: any) => {
      this.catererUUID = params.id;
      this.loadCatererByUUID(this.catererUUID);
    });
  }

  ngOnDestroy() {}

  public loadCatererByUUID(uuid: string): void {
    // this.logger.info("CatererComponent.loadCatererByUUID() -> uuid: " + uuid);
    this.loading = true;
    this.orgService.loadCatererByUUID(uuid).subscribe(
      (caterer) => {
        // this.logger.info("NavbarVerticalStyle1Component -> this.orgService.getOrganization().subscribe() " + organization);
        // this.logger.info("CrudOrganizationComponent.loadCatererByUUID() " + JSON.stringify(organization));
        if (caterer.data) {
          this.caterer = caterer.data;
          this.orgList.onOrgListChanged.next(this.caterer.organizations);
          // this.logger.info("CatererComponent.loadCatererByUUID() " + JSON.stringify(this.caterer));

          //this.titleService.setTitle(this.translate.instant("CATERER.CATERER") + " " + this.caterer.name);
        }
        this.loading = false;
      },
      (error) => {
        this.logger.error(
          'CatererComponent.loadCatererByUUID() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public override autosaveDataChange(data: any): void | any {
    // this.logger.info("CatererComponent.autosaveDataChange() -> data: " + JSON.stringify(data));

    this.loading = true;
    this.orgService.updateCaterer(data, this.catererUUID).subscribe(
      (response) => {
        // this.logger.info("CatererComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
        // this.organization = response;
        this.loading = false;
        // if (Settings.refreshFormData)
        // {
        //     this.loadOrganization();
        // }
      },
      (error) => {
        this.logger.error(
          'CatererComponent.autosaveDataChange() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.loading = false;
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC')
        );
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
      }
    );
  }

  public onEdit($event: any): void {
    this.editMode = !this.editMode;
    // this.logger.info("CatererComponent.onEdit() this.addressComponent: " + this.addressComponent);
    // this.logger.info("CatererComponent.onEdit() this.contactComponent: " + this.contactComponent);

    if (this.addressComponent) {
      this.addressComponent.editMode = this.editMode;
    }

    if (this.contactComponent) {
      this.contactComponent.editMode = this.editMode;
    }

    if (this.editMode) {
      this.setControlsChanges(this.catererForm);
    } else {
      this.unsetControlsChanges();
      // this.loadOrganization();
      this.catererForm.markAsPristine();
      this.catererForm.markAsUntouched();
    }
  }

  public onEditOrganization(org: any): void {
    // this.logger.info("CatererComponent.onEditOrganization() -> org: " + JSON.stringify(org));
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(org));
  }

  public onOpenInNewTab(): void {
    this.openInNewTab(this.router.url);
  }

  public onOwnerUserChange(event: any): void {
    this.caterer.owner_user = event;
    this.autosaveDataChange({ owner_user: this.caterer.owner_user });
  }

  // public onFileUpload(event): void
  // {
  //     this.logger.info("CrudOrganizationComponent.onFileUploaded() -> $event: " + JSON.stringify(event));
  //     // for (const file of event.files) {
  //     //
  //     //     this.uploadedFiles.push(file);
  //     // }
  // }

  // public onBeforeUpload($event)
  // {
  //     this.logger.info("CrudOrganizationComponent.onBeforeUpload()");
  // }

  // public onBeforeSend($event)
  // {
  //     this.logger.info("CrudOrganizationComponent.onBeforeSend() -> event " + JSON.stringify($event));
  //     const idToken = this.auth.getToken();
  //     const lang: string = LocalizationConfig.getCurrentLanguage().locale;

  //     $event.xhr.setRequestHeader("Accept", "application/json");
  //     $event.xhr.setRequestHeader("Authorization", "Bearer " + idToken);
  //     $event.xhr.setRequestHeader("Accept-Language", lang);
  // }

  // public customLogoUploader(event, formFileUpload): void
  // {
  //     this.logger.info("CrudOrganizationComponent.customLogoUploader() -> event " + JSON.stringify(event));

  //     if (event.files.length === 0)
  //     {
  //         this.logger.info('CrudOrganizationComponent.customLogoUploader() -> No file selected.');
  //         return;
  //     }
  //     const fileToUpload = event.files[0];
  //     const input = new FormData();
  //     input.append("files", fileToUpload);
  //     this.uploadedLogoError = null;
  //     // this.logger.info("CrudOrganizationComponent.customUploader() -> input " + JSON.stringify(input));
  //     this.http.post<LogoResponse>(this.logoUploadURL, input).subscribe( response => {
  //             this.logger.info("CrudOrganizationComponent.customLogoUploader() -> SUCCESS: " + JSON.stringify(response));
  //             formFileUpload.clear();
  //             this.toastr.success(this.translate.instant("ORG.UPLOAD_LOGO_SUCCESS"));
  //             if (response.response_status === ResponsesStatus.OK )
  //             {
  //                 this.uploadedLogoResponse = response;
  //             }
  //         },
  //         error =>
  //         {
  //             this.logger.error("CrudOrganizationComponent.customLogoUploader() -> ERROR: " + JSON.stringify(error));
  //             this.logger.error("CrudOrganizationComponent.customLogoUploader() -> ERROR: " + error.status);
  //             if (error.status === 422 )
  //             {
  //                 this.uploadedLogoError = error.error.errors.files[0];
  //                 this.toastr.error(this.uploadedLogoError, "");
  //             }
  //             else
  //             {
  //                 this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
  //             }
  //         }
  //     );
  // }

  // public customHeaderUploader(event, formFileUpload): void
  // {
  //     this.logger.info("CrudOrganizationComponent.customHeaderUploader() -> event " + JSON.stringify(event));

  //     if (event.files.length === 0)
  //     {
  //         this.logger.info('CrudOrganizationComponent.customHeaderUploader() -> No file selected.');
  //         return;
  //     }
  //     const fileToUpload = event.files[0];
  //     const input = new FormData();
  //     input.append("files", fileToUpload);
  //     this.uploadedHeaderError = null;
  //     // this.logger.info("CrudOrganizationComponent.customUploader() -> input " + JSON.stringify(input));
  //     this.http.post<ReportHeaderResponse>(this.headerUploadURL, input).subscribe( response => {
  //             this.logger.info("CrudOrganizationComponent.customHeaderUploader() -> SUCCESS: " + JSON.stringify(response));
  //             formFileUpload.clear();
  //             this.toastr.success(this.translate.instant("ORG.UPLOAD_HEADER_SUCCESS"));
  //             if (response.response_status === ResponsesStatus.OK )
  //             {
  //                 this.uploadedHeaderResponse = response;
  //             }
  //         },
  //         error =>
  //         {
  //             this.logger.error("CrudOrganizationComponent.customUploader() -> ERROR: " + JSON.stringify(error));
  //             if (error.status === 422 )
  //             {
  //                 this.uploadedHeaderError = error.error.errors.files[0];
  //                 this.toastr.error(this.uploadedHeaderError, "");
  //             }
  //             else
  //             {
  //                 this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
  //             }
  //         }
  //     );
  // }

  // public onLogoClear(event): void
  // {
  //     this.logger.info("CrudOrganizationComponent.onLogoClear()");
  //     this.uploadedLogoError = null;
  // }

  // public onHeaderClear(event): void
  // {
  //     this.logger.info("CrudOrganizationComponent.onHeaderClear()");
  //     this.uploadedHeaderError = null;
  // }

  // public onLocaleCheckbox( event, locale: LocaleModel ): void
  // {
  //     this.logger.info("CrudOrganizationComponent.onLocaleClick() -> checked: " + event.checked);
  //     // this.logger.info("CrudOrganizationComponent.onLocaleClick() -> locale: " + JSON.stringify(locale));

  //     // this.logger.info("CrudOrganizationComponent.onLocaleClick() -> this.clientSupportedLocale: " + JSON.stringify(this.clientSupportedLocale));
  //     // const index: number = this.clientSupportedLocale.indexOf(locale);
  //     // this.logger.info("index: " + index);
  //     // if (index >= 0)
  //     // {
  //     //     this.clientSupportedLocale.splice(index, 1);
  //     // }
  //     // if ( event.checked )
  //     // {
  //     //     this.clientSupportedLocale.push(locale);
  //     // }
  //     // this.logger.info("CrudOrganizationComponent.onLocaleClick() -> this.clientSupportedLocale: " + JSON.stringify(this.clientSupportedLocale));

  //     //      /v1/organizations/{uuid}/locals/add/{uuid}

  //     this.logger.info("CrudOrganizationComponent.onSaveLocale().addLocale() event.checked: " + event.checked);
  //     // TODO: add confirm
  //     if ( event.checked )
  //     {
  //         this.logger.info("CrudOrganizationComponent.onSaveLocale().addLocale() *****");
  //         this.orgService.addLocale(locale, this.organization.data.uuid).subscribe( response =>
  //         {
  //             this.logger.info("CrudOrganizationComponent.onSaveLocale().addLocale() -> SUCCESS: " + JSON.stringify(response));
  //         }, error =>
  //         {
  //             this.logger.error("CrudOrganizationComponent.onSaveLocale().addLocale() -> ERROR: " + JSON.stringify(error));
  //             this.handleAPIError(error, this.dialog);
  //         });
  //     }
  //     else
  //     {
  //         this.logger.info("CrudOrganizationComponent.onSaveLocale().deleteLocale() *****");
  //         this.orgService.deleteLocale(locale, this.organization.data.uuid).subscribe( response =>
  //         {
  //             this.logger.info("CrudOrganizationComponent.onSaveLocale().addLocale() -> SUCCESS: " + JSON.stringify(response));
  //         }, error =>
  //         {
  //             this.logger.info("CrudOrganizationComponent.onSaveLocale().addLocale() -> ERROR: " + JSON.stringify(error));
  //             this.handleAPIError(error, this.dialog);
  //         });
  //     }
  // }

  // public isLocaleInList(locale: any): boolean
  // {
  //     // this.logger.info("isLocaleInList() -> locale: " + JSON.stringify(locale));
  //     const index: number = this.clientSupportedLocale.indexOf(locale);
  //     // this.logger.info("isLocaleInList() -> index: " + index);

  //     // this.logger.info("-------------- " + JSON.stringify(locale));

  //     let isInList: boolean = false;
  //     // this.logger.info("--------------");
  //     this.organization.data.locals.filter( loc =>
  //         {
  //         // this.logger.info("loc.uuid: " + loc.uuid );
  //         // this.logger.info("locale.uuid: " + locale.uuid );
  //         // this.logger.info("...");
  //         if ( loc.uuid === locale.uuid)
  //         {
  //             isInList = true;
  //         }
  //     });
  //     // this.logger.info("-------------- isInList: " + isInList);
  //     return isInList;
  // }

  // onLanguageChange(event): void
  // {
  //     this.logger.debug('CrudOrganizationComponent.onLanguageChange()');
  //     this.logger.info("CrudOrganizationComponent.onLanguageChange() -> value: " + event.value);
  //     // this.setLanguage(event.value);
  // }

  // public onSaveLocale(): void
  // {
  //     // this.logger.info("CrudOrganizationComponent.onSaveLocale() -> ");
  //     this.organization.data.locals = this.clientSupportedLocale;
  //     // this.logger.info("CrudOrganizationComponent.onLocaleClick() -> this.clientSupportedLocale: " + JSON.stringify(this.clientSupportedLocale));
  //     // this.logger.info("CrudOrganizationComponent.onLocaleClick() -> this.clientSupportedLocale: " + JSON.stringify(this.organization.data.locals));

  //     // this.autosaveDataChange({locals: this.organization.data.locals});

  //     // TODO: add confirm
  //     // this.orgService.updateOrganizationLocales(this.organization.data.locals, this.organization.data.uuid).subscribe( response =>
  //     // {
  //     //     this.logger.info("CrudOrganizationComponent.onSaveLocale() -> SUCESS: " + JSON.stringify(response));
  //     // }, error =>
  //     // {
  //     //     this.logger.info("CrudOrganizationComponent.onSaveLocale() -> ERROR: " + JSON.stringify(error));
  //     // });
  // }

  // public onUpdateOrganizationTest(): void
  // {
  //     this.organization.data.organizationCity = "Mont-Jolie";
  //     const newCity: any = {organizationCity: "Rimouski", uuid: this.organization.data.uuid};
  //
  //     this.orgService.autosaveDataChange(newCity, this.organization.data.uuid).subscribe(response => {
  //             this.logger.info("CrudOrganizationComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
  //             this.organization = response.data;
  //         },
  //         error => {
  //             this.logger.info("CrudOrganizationComponent.autosaveDataChange() -> ERROR: " + JSON.stringify(error));
  //         }
  //     );
  // }

  // public saveEditable(value)
  // {
  //     // call to http service
  //     this.logger.info('CrudOrganizationComponent.saveEditable() -> value: ' + value);
  //     this.logger.info('CrudOrganizationComponent.saveEditable() -> organization: ' + JSON.stringify(this.organization));
  //
  //     this.orgService.autosaveDataChange(this.organization, this.organization.data.uuid).subscribe(response => {
  //             this.logger.info('CrudEmployeeComponent.saveEditable() -> SUCCESS: ' + JSON.stringify(response));
  //         },
  //         error => {
  //             this.logger.info('CrudEmployeeComponent.saveEditable() -> ERROR: ' + JSON.stringify(error));
  //         }
  //     );
  // }
}

export class FilesDataSource extends DataSource<any> {
  constructor(private orgList: any) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return this.orgList.onOrgListChanged;
  }

  disconnect() {}
}
