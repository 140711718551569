<div
  fxLayout="column"
  (keyup.enter)="!saving ? saveData() : null"
  style="max-height: 100%; overflow: auto"
>
  <div class="profile-box info-box" style="width: 100%" fxLayout="column">
    <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <div *ngIf="!creationMode" class="title">
        {{ "COMBO.TITLE" | translate }} {{ product.name }}
      </div>
      <div *ngIf="creationMode" class="title">
        {{ "COMBO.TITLE_CREATE" | translate }}
      </div>

      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-checkbox
          *ngIf="
            moduleService.modulesAreSet &&
            moduleService.hasMultiplicationComboChilds() &&
            product &&
            (product.combo || product.is_combo)
          "
          labelPosition="before"
          color="accent"
          [(ngModel)]="product.multiply_combo_item_price"
          [ngModelOptions]="{ standalone: true }"
          (change)="onProductComboMultiply($event)"
        >
          Mutliplié quantité enfants combo
        </mat-checkbox>

        <div
          class="gt2-light-green"
          style="width: 60px; height: 56px; margin-left: 20px"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <button
            type="button"
            matTooltip="{{ 'COMBO.CREATE_PRODUCT' | translate }}"
            mat-icon-button
            class=""
            (click)="onCreateProduct()"
          >
            <mat-icon class="" color="">add</mat-icon>
          </button>
        </div>
      </div>
    </header>
  </div>

  <div fxLayout="column" class="px-16">
    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      name="comboSpinner"
      size="medium"
      color="#90ee90"
      [fullScreen]="false"
      type="ball-scale-multiple"
    >
    </ngx-spinner>

    <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="mr-4">
      <mat-label>{{ "COMBO.SEARCH" | translate | ucfirst }}</mat-label>
      <input
        #filter
        id="search"
        [(ngModel)]="filterValue"
        matInput
        [maxLength]="200"
        placeholder="{{ 'COMBO.SEARCH' | translate | ucfirst }}"
      />
      <button
        *ngIf="filterValue"
        matSuffix
        mat-icon-button
        aria-label="Search"
        (click)="loadProductList()"
      >
        <mat-icon>search</mat-icon>
      </button>
      <button
        *ngIf="filterValue"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div fxLayout="row" style="min-width: 780px">
      <div fxLayout="column" fxFlex="50%" fxFlex.xs="40%">
        <div
          fxLayout="row"
          fxLayoutAlign="center start"
          fxLayoutAlign.xs="start start"
          class="pb-4 mat-caption"
        >
          {{ "COMBO.LEFT_LIST_LABEL" | translate }}
        </div>
      </div>

      <div fxLayout="column" fxFlex="50%" fxFlex.xs="60%">
        <div
          fxLayout="row"
          fxLayoutAlign="center start"
          fxLayoutAlign.xs="start start"
          class="pb-4 mat-caption"
        >
          {{ "COMBO.RIGHT_LIST_LABEL" | translate }}
        </div>
      </div>
    </div>

    <div style="min-width: 780px; overflow-x: scroll" class="myForm">
      <!-- <div class="" fxLayout="row">
        <div
          #productList
          class="ngx-dnd-container-custom mr-4 ngx-dnd-scrollable"
          ngxDroppable
          [ngModel]="products"
          fxLayout="column"
          fxFlex="50%"
          fxFlex.xs="38%"
          style="height: 60vh"
        >
          <div
            *ngIf="isLoading"
            fxLayout="column"
            fxFlex="100%"
            fxLayoutAlign="center center"
          >
            <mat-spinner [diameter]="28" class=""></mat-spinner>
          </div>

          <div
            *ngIf="isEmpty"
            fxLayout="column"
            fxFlex="100%"
            fxLayoutAlign="center center"
          >
            {{ "COMBO.SEARCH_PRODUCT" | translate }}
          </div>

          <div
            ngxDraggable="['example-target']"
            [ngModel]="item"
            *ngFor="let item of products; let i = index"
          >
            <div
              *ngIf="!isLoading && item.uuid !== product.uuid"
              matRipple
              class="product-child-item"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div fxLayout="row">
                <mat-icon *ngIf="!isSmallScreen" class="mr-8"
                  >drag_handle</mat-icon
                >
                <div fxLayout="column" fxLayoutAlign="start start">
                  <div class="item-name-internal">{{ item.name_internal }}</div>
                  <div class="item-name">
                    {{
                      item.name +
                        " | " +
                        item.price_label +
                        (item.is_combo ? " | Combo" : "")
                    }}
                  </div>
                </div>
              </div>

              <div fxFlex="62px" fxLayoutAlign="end center">
                <button mat-icon-button (click)="onAddItem(item, true)">
                  <mat-icon class="">add</mat-icon>
                </button>

                <button
                  *ngIf="!isSmallScreen"
                  mat-button
                  matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                  (click)="onOpenInNewTab(item)"
                  class="mat-icon-button"
                >
                  <mat-icon class="s-20">{{ getIcon("NEW_TAB") }}</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          #comboList
          class="ngx-dnd-container-custom ml-4 combo-list ngx-dnd-scrollable"
          ngxDroppable="example-target"
          [ngModel]="combo"
          (drop)="onDrop($event)"
          style="height: 60vh"
          fxLayout="column"
          fxFlex="50%"
          fxFlex.xs="62%"
        >
          <div
            class="product-child-item-right"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            matRipple
            ngxDraggable
            [moves]="!productChildDropProcess"
            [ngModel]="item"
            *ngFor="let item of combo; let i = index"
          >
            <div fxLayout="row">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="mr-8">drag_handle</mat-icon>
                <mat-form-field
                  style="padding: 5px"
                  class="form-input-container mx-4 mr-4"
                  fxFlex="70px;"
                  fxFlexAlign
                >
                  <input
                    #box
                    matInput
                    class="text-center"
                    type="text"
                    pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                    autocomplete="nope"
                    [(ngModel)]="item.qty"
                    (ngModelChange)="onBlur()"
                    (change)="onItemQtyChange(item, i)"
                    style="text-align: center"
                  />
                </mat-form-field>
                &nbsp;
                <div
                  matTooltip="{{ item.name }}"
                  [matTooltipPosition]="'right'"
                >
                  {{ item.name_internal }}
                </div>

                <button
                  type="button"
                  mat-icon-button
                  class="ml-8"
                  (click)="onModifyCombo(item)"
                >
                  <mat-icon
                    *ngIf="item.is_choice_product_combo"
                    class="mr-4"
                    fontIcon="{{ getIcon('COMBO') }}"
                    fontSet="fa"
                  ></mat-icon>
                </button>
              </div>
            </div>

            <div fxFlex="120px">
              <mat-icon (click)="onAddItem(item, true)" class="ml-8"
                >add</mat-icon
              >
              <mat-icon (click)="onLessItem(item, i)" class="ml-8"
                >remove</mat-icon
              >
              <mat-icon (click)="onRemoveItem(item, i)" class="ml-8"
                >delete</mat-icon
              >
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div fxLayout="row">
      <div fxLayout="row" fxFlex="50%">&nbsp;</div>

      <div fxLayout="row" fxLayoutAlign="end start" fxFlex="50%">
        <div *ngIf="combo && combo.length > 0" class="pr-4">
          {{ combo.length }} {{ combo && combo.length > 1 ? "items" : "item" }}
        </div>
      </div>
    </div>
  </div>

  <!--HACK TO MAKE THE D&D ABOVE WORK (TODO: fix the style/code that does not seem to import here if not present-->
  <!-- <ngx-dnd-container
    [ngModel]="['Item 1', 'Item 2', 'Item 3']"
    style="display: none"
  >
  </ngx-dnd-container> -->

  <div
    mat-dialog-actions
    class="p-16"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      (click)="saveData()"
      [class.btn-spinner]="saving"
      [disabled]="saving"
      class="accent mr-12"
    >
      {{ "GENERIC.OK" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
