import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-copy-wrapper',
  template: `
    <div
      #content
      (click)="copyContent()"
      (mouseenter)="showCopyTooltip()"
      (mouseleave)="hideTooltip()"
    >
      <ng-content></ng-content>
      <span class="tooltip" *ngIf="showTooltip">{{ tooltipText }}</span>
    </div>
  `,
  styleUrls: ['./copy-wrapper.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class CopyWrapperComponent {
  @ViewChild('content', { static: true }) content?: ElementRef;
  showTooltip = false; // Pour gérer l'affichage du tooltip
  tooltipText = ''; // Texte du tooltip

  constructor(
    private renderer: Renderer2,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
  ) {
    this.translationLoader.loadTranslations(english, french);
  }
  showCopyTooltip() {
    this.tooltipText = this.translate.instant('CLIPBOARD.TO_COPY');
    this.showTooltip = true;
  }
  copyContent() {
    const text = (
      this.content?.nativeElement.innerText ||
      this.content?.nativeElement.textContent
    )
      .replace(this.translate.instant('CLIPBOARD.TO_COPY'), '')
      .replace(this.translate.instant('CLIPBOARD.COPY'), '');

    // Crée un élément temporaire pour sélectionner le texte
    const textarea = this.renderer.createElement('textarea');

    this.renderer.setProperty(textarea, 'value', text);
    this.renderer.appendChild(document.body, textarea);
    textarea.select();

    try {
      // Copie le texte dans le presse-papier
      document.execCommand('copy');
      this.tooltipText = this.translate.instant('CLIPBOARD.COPY'); // Affiche "Copié!" dans le tooltip
      this.showTooltip = true;

      // Cache le tooltip après un délai
      setTimeout(() => {
        this.showTooltip = false;
      }, 2000); // Le tooltip disparaît après 2 secondes
    } catch (err) {
      console.error('Erreur lors de la copie', err);
    }

    this.renderer.removeChild(document.body, textarea);
  }

  hideTooltip() {
    // Cache immédiatement le tooltip quand la souris quitte l'élément
    this.showTooltip = false;
  }
}
