<div *ngIf="collectionData && dataSource" class="collection-table-jul">
  <mat-table
    #table
    class=""
    matSort
    matSortStart="desc"
    (matSortChange)="sortData($event)"
    [@animateStagger]="{ value: '30' }"
    [dataSource]="dataSource"
  >
    <!-- event_type Column -->
    <ng-container cdkColumnDef="delivery_type_id">
      <mat-header-cell
        fxFlex="52px"
        *cdkHeaderCellDef
        mat-sort-header
      ></mat-header-cell>
      <mat-cell fxFlex="52px" *cdkCellDef="let event">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div
            matTooltip="{{ 'GENERIC.DELIVERY_TYPE1' | translate }}"
            [matTooltipPosition]="'right'"
          >
            <mat-icon
              class="mx-12 fa-lg"
              *ngIf="event.delivery_type_id === 1"
              fontSet="fa"
              fontIcon="fa-shipping-fast"
              style="color: blue"
            >
            </mat-icon>
          </div>

          <div
            matTooltip="{{ 'GENERIC.DELIVERY_TYPE2' | translate }}"
            [matTooltipPosition]="'right'"
          >
            <mat-icon
              class="mx-12 fa-lg"
              *ngIf="event.delivery_type_id === 2"
              fontSet="fa"
              fontIcon="fa-utensils"
              style="color: rgb(28, 28, 28)"
            >
            </mat-icon>
          </div>

          <div
            matTooltip="{{ 'GENERIC.DELIVERY_TYPE3' | translate }}"
            [matTooltipPosition]="'right'"
          >
            <mat-icon
              class="mx-12 fa-lg"
              *ngIf="event.delivery_type_id === 3"
              fontSet="fa"
              fontIcon="fa-users"
              style="color: darkolivegreen"
            >
            </mat-icon>
          </div>

          <div
            matTooltip="{{ 'GENERIC.DELIVERY_TYPE4' | translate }}"
            [matTooltipPosition]="'right'"
          >
            <mat-icon
              class="mx-12 fa-lg"
              *ngIf="event.delivery_type_id === 4"
              fontSet="fa"
              fontIcon="fa-concierge-bell"
              style="color: darkorange"
            >
            </mat-icon>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <!-- time Column -->
    <ng-container cdkColumnDef="time">
      <mat-header-cell fxFlex="70px" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.TIME" | translate }}
      </mat-header-cell>
      <mat-cell fxFlex="70px" *cdkCellDef="let event">
        <p class="text-truncate font-weight-600">{{ event.time }}</p>
      </mat-cell>
    </ng-container>

    <!-- event_name Column -->
    <ng-container cdkColumnDef="event_name">
      <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.EVENT" | translate }}
      </mat-header-cell>
      <mat-cell fxFlex="50" *cdkCellDef="let event">
        <p class="text-truncate font-weight-600">{{ event.event_name }}</p>
      </mat-cell>
    </ng-container>

    <!-- guest_count Column -->
    <ng-container cdkColumnDef="guest_count">
      <mat-header-cell fxFlex="72px" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.GUEST" | translate }}
      </mat-header-cell>
      <mat-cell fxFlex="72px" *cdkCellDef="let event">
        <p class="text-truncate font-weight-600">{{ event.guest_count }}</p>
      </mat-cell>
    </ng-container>

    <!-- client_name Column -->
    <ng-container cdkColumnDef="client_name">
      <mat-header-cell fxFlex="20" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.CLIENT" | translate }}
      </mat-header-cell>
      <mat-cell fxFlex="20" *cdkCellDef="let event">
        <p class="text-truncate font-weight-600">{{ event.client_name }}</p>
      </mat-cell>
    </ng-container>

    <!-- responsible_employee_name Column -->
    <ng-container cdkColumnDef="responsible_employee_name">
      <mat-header-cell fxFlex="20" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.RESPONSIBLE_EMPLOYEE" | translate }}
      </mat-header-cell>
      <mat-cell fxFlex="20" *cdkCellDef="let event">
        <p class="text-truncate font-weight-600">
          {{ event.responsible_employee_name }}
        </p>
      </mat-cell>
    </ng-container>

    <!-- place Column -->
    <ng-container cdkColumnDef="place">
      <mat-header-cell fxFlex="50px" *cdkHeaderCellDef mat-sort-header>{{
        "GENERIC.VENUE" | translate
      }}</mat-header-cell>
      <mat-cell fxFlex="50px" *cdkCellDef="let event">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon
            color="accent"
            class="s-24"
            (click)="onNavigateToEventVenue(event); $event.stopPropagation()"
          >
            {{ iconsService.getIcon("PLACE") }}
          </mat-icon>
        </div>
      </mat-cell>
    </ng-container>

    <!-- current_order_type Column -->
    <ng-container cdkColumnDef="current_order_type">
      <mat-header-cell
        fxFlex="42px"
        *cdkHeaderCellDef
        mat-sort-header
      ></mat-header-cell>
      <mat-cell fxFlex="42px" *cdkCellDef="let event">
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          style="margin-left: 15px"
        >
          <mat-icon
            *ngIf="event.current_order_type === 'ORDER'"
            matTooltip="{{ 'GENERIC.ORDER' | translate }}"
            (click)="onNavigateToOrderSecondContext(event)"
            [matTooltipPosition]="'right'"
            class="mr-8 s-20"
            >{{ getIcon("ORDER") }}</mat-icon
          >
          <mat-icon
            *ngIf="event.current_order_type === 'PROPOSAL'"
            matTooltip="{{ 'GENERIC.PROPOSAL' | translate }}"
            (click)="onNavigateToOrderSecondContext(event)"
            [matTooltipPosition]="'right'"
            class="mr-8 s-20"
            >{{ getIcon("PROPOSAL") }}</mat-icon
          >
          <mat-icon
            *ngIf="event.current_order_type === 'INVOICE'"
            matTooltip="{{ 'GENERIC.INVOICE' | translate }}"
            (click)="onNavigateToOrderSecondContext(event)"
            [matTooltipPosition]="'right'"
            class="mr-8 s-20"
            >{{ getIcon("INVOICE") }}</mat-icon
          >

          <!-- <mat-icon *ngIf="event.project_context"
                                matTooltip="{{ ('GENERIC.PROJECT' | translate) + ' ' +  event.project_context.project_number}}"
                                (click)="onNavigateToProjectContext(order)" [matTooltipPosition]="'right'" class="mr-8 s-20">
                                {{ getIcon('PROJECT') }}
                            </mat-icon> -->
        </div>
      </mat-cell>
    </ng-container>

    <!-- status Column -->
    <ng-container cdkColumnDef="status">
      <mat-header-cell fxFlex="50px" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.STATUS" | translate }}
      </mat-header-cell>
      <mat-cell fxFlex="50px" *cdkCellDef="let event">
        <!-- <p class="text-truncate font-weight-600">{{event.status}}</p> -->
        <div fxLayout="row" fxLayoutAlign="center center">
          <div [ngSwitch]="event.order_status">
            <div
              *ngSwitchCase="'PROPOSAL_OPEN'"
              matTooltip="{{ 'DASHBOARD.PROPOSAL_OPEN' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-star"
                style="color: #ffd900"
              >
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'PROPOSAL_ACCEPT'"
              matTooltip="{{ 'DASHBOARD.PROPOSAL_ACCEPT' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-star"
                style="color: #ffd900"
              >
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'PROPOSAL_PROPOSE'"
              matTooltip="{{ 'DASHBOARD.PROPOSAL_PROPOSE' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-envelope"
                style="color: blue"
              >
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'PROPOSAL_REFUSE'"
              matTooltip="{{ 'DASHBOARD.PROPOSAL_REFUSE' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon color="warn" class="s-24" style="margin-left: 10px">
                {{ iconsService.getIcon("CLOSE") }}
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'PROPOSAL_EXPIRE'"
              matTooltip="{{ 'DASHBOARD.PROPOSAL_EXPIRE' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon color="warn" class="s-24" style="margin-left: 10px">
                {{ iconsService.getIcon("CLOSE") }}
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'PROPOSAL_DISCARD'"
              matTooltip="{{ 'DASHBOARD.PROPOSAL_DISCARD' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon color="warn" class="s-24" style="margin-left: 10px">
                {{ iconsService.getIcon("CLOSE") }}
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'INVOICE_OPEN'"
              matTooltip="{{ 'DASHBOARD.INVOICE_OPEN' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-check"
                style="color: green"
              >
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'INVOICE_SEND'"
              matTooltip="{{ 'DASHBOARD.INVOICE_SEND' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-envelope"
                style="color: blue"
              >
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'INVOICE_OVER_DUE'"
              matTooltip="{{ 'DASHBOARD.INVOICE_OVER_DUE' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-envelope"
                style="color: blue"
              >
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'INVOICE_PAID'"
              matTooltip="{{ 'DASHBOARD.INVOICE_PAID' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-hand-holding-usd"
                style="color: darkmagenta"
              >
              </mat-icon>
              <!--    fa-hand-holding-usd
                                    fa-file-invoice-dollar
                                    fa-money-check-alt -->
            </div>

            <div
              *ngSwitchCase="'INVOICE_CANCEL'"
              matTooltip="{{ 'DASHBOARD.INVOICE_CANCEL' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon color="warn" class="s-24" style="margin-left: 10px">
                {{ iconsService.getIcon("CLOSE") }}
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'ORDER_OPEN'"
              matTooltip="{{ 'DASHBOARD.ORDER_OPEN' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-star"
                style="color: #ffd900"
              >
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'ORDER_SEND'"
              matTooltip="{{ 'DASHBOARD.ORDER_SEND' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-envelope"
                style="color: blue"
              >
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'ORDER_CONFIRM'"
              matTooltip="{{ 'DASHBOARD.ORDER_CONFIRM' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-check"
                style="color: green"
              >
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'ORDER_TO_INVOICE'"
              matTooltip="{{ 'DASHBOARD.ORDER_TO_INVOICE' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-check"
                style="color: green"
              >
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'ORDER_COMPLETE'"
              matTooltip="{{ 'DASHBOARD.ORDER_COMPLETE' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-check"
                style="color: green"
              >
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'ORDER_TO_COMPLETE'"
              matTooltip="{{ 'DASHBOARD.ORDER_TO_COMPLETE' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon
                class="mx-12 fa-lg"
                fontSet="fa"
                fontIcon="fa-check"
                style="color: green"
              >
              </mat-icon>
            </div>

            <div
              *ngSwitchCase="'ORDER_CANCEL'"
              matTooltip="{{ 'DASHBOARD.ORDER_CANCEL' | translate }}"
              [matTooltipPosition]="'left'"
            >
              <mat-icon color="warn" class="s-24" style="margin-left: 10px">
                {{ iconsService.getIcon("CLOSE") }}
              </mat-icon>
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *cdkHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      *cdkRowDef="let row; columns: displayedColumns"
      (click)="onNavigateToOrder(row)"
      [ngStyle]="{
        'background-color':
          row.status === 'EVENT_ABORT' ||
          row.status === 'EVENT_CANCEL' ||
          row.order_status === 'PROPOSAL_DISCARD' ||
          row.order_status === 'PROPOSAL_REFUSE'
            ? '#ff867d'
            : ''
      }"
      [@animate]="{ value: '*', params: { y: '100%' } }"
    >
    </mat-row>
  </mat-table>
</div>
