import { NgModule } from '@angular/core';
import { DocumentationComponent } from './documentation.component';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { RouterModule } from '@angular/router';
import { AppRoutesName } from '../../const/AppRoutesName';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.EN.DOCUMENTATION,
    component: DocumentationComponent,
  },
  {
    path: AppRoutesName.FR.DOCUMENTATION,
    component: DocumentationComponent,
  },
];

@NgModule({
  declarations: [DocumentationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    TranslateModule,
    MatInputModule,
    MatButtonModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
  ],
  exports: [DocumentationComponent],
  providers: [],
})
export class DocumentationModule {}
