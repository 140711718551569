export const locale = {
  lang: "en-CA",
  data: {
    CREATE_FLOW: {
      TITLE: "New flow",
      EDIT_TITLE: "Edit flow",
      SELECT_FLOW: "Define or select a flow",
      FOR_CLIENT_ONLY: "Don't show in production",
    },
  },
};
