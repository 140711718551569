<div style="max-height: 100%">
  <h1 class="h1-title" matDialogTitle *ngIf="!useHTML">
    {{ alertTitle }}
  </h1>
  <h1 matDialogTitle *ngIf="useHTML" [innerHTML]="alertTitle"></h1>

  <div mat-dialog-content *ngIf="!useHTML">
    {{ alertMessage }}
  </div>
  <div mat-dialog-content *ngIf="useHTML" [innerHTML]="alertMessage"></div>

  <div *ngIf="useYesNo">
    <div
      mat-dialog-actions
      fxLayout="row"
      fxLayoutAlign="end center"
      class="pt-24"
    >
      <button
        mat-raised-button
        class="accent mr-12"
        (click)="dialogRef.close(true)"
      >
        {{ "GENERIC.YES" | translate }}
      </button>
      <button
        mat-raised-button
        class="primary"
        (click)="dialogRef.close(false)"
      >
        {{ "GENERIC.NO" | translate }}
      </button>
    </div>
  </div>

  <div *ngIf="!useYesNo">
    <div
      mat-dialog-actions
      *ngIf="!useCancel"
      fxLayout="row"
      fxLayoutAlign="end center"
      class="pt-24"
    >
      <button mat-raised-button class="accent" (click)="dialogRef.close(false)">
        OK
      </button>
    </div>

    <div
      mat-dialog-actions
      *ngIf="useCancel"
      fxLayout="row"
      fxLayoutAlign="end center"
      class="pt-24"
    >
      <button
        mat-raised-button
        class="accent mr-12"
        (click)="dialogRef.close(true)"
      >
        {{ "GENERIC.OK" | translate }}
      </button>
      <button
        mat-raised-button
        class="primary"
        (click)="dialogRef.close(false)"
      >
        {{ "GENERIC.CANCEL" | translate }}
      </button>
    </div>
  </div>
</div>
