import { Injectable } from '@angular/core';
import { SearchResultsModel } from '../../api/models/SearchResultsModel';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SearchResultsSharedDataService {
  searchData?: SearchResultsModel;
  searchDataChange: BehaviorSubject<any> = new BehaviorSubject({});
  searchString?: string;

  constructor() {}

  setSearchData(data: SearchResultsModel, searchString: string) {
    this.searchData = data;
    this.searchString = searchString;
    this.searchDataChange.next(this.searchData);
  }
}
