<div
  id="dashboard"
  class="page-layout simple fullwidth"
  fxLayout="column"
  style="overflow-x: scroll"
>
  <!--Scroll tout la page et pas les contenus de la tab-->
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1 mt-8">{{ "GENERIC.ROOM_MANAGEMENT" | translate }}</div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->
  <!-- SUB HEADER -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 content-subheader"
  >
    <div fxLayout="row" fxLayoutAlign="start center"></div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <div
        *ngIf="
          modulesService.modulesAreSet &&
          !modulesService.hasCTReport() &&
          !modulesService.hasRPReport()
        "
      >
        <app-create-order-buttons></app-create-order-buttons>
      </div>

      <div *ngIf="modulesService.modulesAreSet && modulesService.hasCTReport()">
        <button
          *ngxPermissionsOnly="rolesService.SALE_ROLES"
          mat-button
          (click)="onCreateNewCongress()"
          class=""
        >
          <mat-icon class="s-24">{{ getIcon("CONGRESS") }}</mat-icon>
          {{ "GENERIC.CREATE_CONGRESS_TITLE" | translate }}
        </button>

        <button
          *ngxPermissionsOnly="rolesService.SALE_ROLES"
          mat-button
          (click)="onGoToCongresses()"
          class=""
        >
          <mat-icon class="s-24">{{ getIcon("CONGRESS") }}</mat-icon>
          {{ "GENERIC.CONGRESSES" | translate }}
        </button>
      </div>

      <div
        *ngIf="modulesService.modulesAreSet && modulesService.hasQuickOrder()"
      >
        <button mat-button (click)="onQuickOrder()" class="">
          <mat-icon class="s-24">{{ getIcon("SHOP") }}</mat-icon>
          {{ "GENERIC.QUICK_ORDER" | translate }}
        </button>
      </div>
    </div>
  </div>
  <!-- / SUB HEADER -->

  <div id="roomManagement" style="min-width: 1023px">
    <div class="content">
      <!-- ADD/DEBUG VERTICAL SCROLL -->
      <!-- ADD A ngIF WITH MAYBE A SPINNER BEFORE DATA IS LOADED -->
      <!-- COLLAPSE RESSOURCE WITH NO EVENTS -->

      <!-- CENTER -->
      <div class="center p-24 pb-52 calendar-wrapper" fusePerfectScrollbar>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-8">
          <div fxLayout="row" fxLayoutAlign="start center">
            <button
              mat-raised-button
              color="primary"
              class="mr-8"
              (click)="onCalendarToday()"
            >
              {{ "GENERIC.TODAY" | translate }}
            </button>
            <!--                <mat-button-toggle-group name="prevNext" aria-label="previous/next">-->
            <!--                    <mat-button-toggle (click)="onCalendarPrevious()"> < </mat-button-toggle>-->
            <!--                    <mat-button-toggle (click)="onCalendarNext()"> > </mat-button-toggle>-->
            <!--                </mat-button-toggle-group>-->

            <!--                <div style="border-radius: 8px; background-color: #eceff1; border: 1px solid #CCCCCC;">-->
            <!--                    <button mat-icon-button class="" [disableRipple]="true" color="" style="max-width: 44px;" (click)="onCalendarPrevious()">-->
            <!--                        <mat-icon class="s-20">arrow_back_ios</mat-icon>-->
            <!--                    </button>-->
            <!--                    <button mat-icon-button class="" [disableRipple]="true" color="" style="max-width: 44px;" (click)="onCalendarNext()">-->
            <!--                        <mat-icon class="s-20">arrow_forward_ios</mat-icon>-->
            <!--                    </button>-->
            <!--                </div>-->
            <button
              mat-stroked-button
              class="grey-100"
              color=""
              style="max-width: 24px"
              (click)="onCalendarPrevious()"
            >
              <mat-icon class="s-20">arrow_back_ios</mat-icon>
            </button>
            <button
              mat-stroked-button
              class="grey-100"
              color=""
              style="max-width: 24px"
              (click)="onCalendarNext()"
            >
              <mat-icon class="s-20">arrow_forward_ios</mat-icon>
            </button>

            <div fxLayout="row" fxLayoutAlign="start end">
              <input
                matInput
                [matDatepicker]="picker"
                min="1900-01-01"
                max="2099-01-01"
                class=""
                (dateChange)="onDatePickerChange($event)"
                style="visibility: hidden; width: 1px"
              />
              <mat-datepicker #picker></mat-datepicker>
              <button
                mat-stroked-button
                class="grey-100"
                (click)="picker.open()"
              >
                {{ "GENERIC.CHOOSE_DATE" | translate }}
              </button>
            </div>
            <!-- <mat-form-field class="custom-date-picker mr-24" style="margin-bottom: -10px;">
                            <input matInput placeholder="{{ 'GENERIC.START' | translate }}" formControlName="date_from1"
                                [matDatepicker]="eventDateFrom1" min="1900-01-01" max="2099-01-01" (ngModelChange)="onDateFromChange1($event)"
                                [(ngModel)]="dateRange1.date_from" class="">
                            <mat-datepicker-toggle matSuffix [for]="eventDateFrom1"></mat-datepicker-toggle>
                            <mat-datepicker #eventDateFrom1></mat-datepicker>
                        
                            <mat-error *ngIf="formDates.controls['date_from1'].invalid">{{
                                'GENERIC.DATE_DEFAULT_RANGE_INVALID' | translate }}
                            </mat-error>
                        </mat-form-field> -->
          </div>

          <div class="calendar-title" *ngIf="calendar">
            {{ calendarTitle }}
          </div>

          <mat-button-toggle-group
            [(ngModel)]="calendarDayMonthValue"
            name="dayMonth"
            aria-label="day/month"
          >
            <mat-button-toggle value="timelineDay" (click)="onCalendarDay()">{{
              "GENERIC.DAY" | translate | uppercase
            }}</mat-button-toggle>
            <!-- <mat-button-toggle value="agendaWeek" (click)="onCalendarWeek()">{{ 'GENERIC.WEEK' | translate |
                            uppercase }}
                        </mat-button-toggle> -->
            <mat-button-toggle value="month" (click)="onCalendarMonth()">{{
              "GENERIC.MONTH" | translate | uppercase
            }}</mat-button-toggle>
          </mat-button-toggle-group>

          <!--            <div fxLayout="row" fxLayoutAlign="start start">-->
          <!--                <button mat-raised-button color="accent" class="ml-8" (click)="onCalendarDay()">-->
          <!--                    Day-->
          <!--                </button>-->

          <!--                <button mat-raised-button color="accent" class="ml-8" (click)="onCalendarMonth()">-->
          <!--                    Month-->
          <!--                </button>-->
          <!--            </div>-->
        </div>
        <div class="py-8" *ngIf="!isLoading && resultsResourceLength === 0">
          {{ "ROOM_MANAGEMENT.NOTE" | translate }}
        </div>
        <div>
          <div id="calendar"></div>
        </div>

        <!-- <div class="py-4">1</div>
          <div class="py-4">2</div>
          <div class="py-4">3</div> -->
      </div>
    </div>
  </div>
</div>
