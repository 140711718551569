export const locale = {
  lang: "en-CA",
  data: {
    MATERIALS: {
      MATERIALS: "Equipments",
      MATERIAL: "Equipment",
      COMBO: "Combo",
      MATERIAL_NUMBER: "Equipment code",
      CONFIRMED: "Confirmed",
      SKU: "SKU",
      NOT_CONFIRMED: "Not confirmed",
      SINGLE_MATERIAL: "Single equipment",
      MODIFY_MATERIAL: "Edit equipment",
      CLOSE_EDIT: "Close edit mode",
      CLONE_MATERIAL: "Clone equipment",
      CREATE_COMBO: "Create combo",
      CLONE_COMBO: "Clone combo",
      INTERNAL_NAME: "Internal name",
      EXTERNAL_NAME: "External name",
      INTERNAL_DESCRIPTION: "Internal description",
      DESCRIPTION: "Description",
      PRICE: "Price",
      // 'MATERIAL_NUMBER': 'Equipment number',
      COST: "Cost",
      TOTAL_INVENTORY_QTY: "Inventory quantity",
      MATERIAL_PROVIDER: "Equipment provider",
      MATERIAL_PROVIDER_SELECT: "Select a equipment provider",
      CHANGE_COMBO_ORDER: "Add/remove items",
      LOGISTIC: "Logistic",
      MATERIAL_CATEGORIES: "Equipment categories",
      MATERIAL_CATEGORY: "Equipment category",
      MATERIAL_CATEGORY_SELECT: "Select a category",
      INTERNAL_VIEW_ONLY: "Internal view only",
      SHOW_UNIT_PRICE: "Show unit price",
      TAXES: "Taxes",
      TAXES_SELECT: "Select a tax",
      NO_MATERIAL_PROVIDER_RESULTS: "No provider yet. Visit the provider section in Settings.",
      ORDERED_MATERIAL: "Ordered equipment",
      SEE_ORDER: "See order",
      SEE_PROPOSAL: "See proposal",
      SEE_INVOICE: "See invoice",
    },
  },
};
