<div
  class="expansion-container"
  fusePerfectScrollbar
  style="overflow-y: scroll"
>
  <mat-expansion-panel
    *ngIf="question"
    [expanded]="panelOpenState"
    (opened)="onStateChange(true)"
    (closed)="onStateChange(false)"
    hideToggle
    class="expension-p"
  >
    <mat-expansion-panel-header
      [collapsedHeight]="'48px'"
      [expandedHeight]="'54px'"
      class="panel-header"
    >
      <mat-panel-title
        fxFlex="100%"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="title p-24"
        style="height: 58px"
      >
        <div class="pt-4">{{ question.name }}</div>
        <button
          class=""
          mat-icon-button
          *ngxPermissionsOnly="rolesService.SALE_ROLES"
          (click)="onEditQuestion()"
          style="position: absolute; right: 60px"
        >
          <mat-icon class="s-16">{{ getIcon("EDIT") }}</mat-icon>
        </button>
      </mat-panel-title>
      <mat-panel-description
        fxLayout="row"
        fxLayoutAlign="end center"
        style="margin-right: 0 !important; padding-right: 0 !important"
      >
        <div fxLayout="row" fxLayoutAlign="start center" class="px-24">
          <mat-icon *ngIf="panelOpenState" class="s-28">{{
            iconService.getIcon("UP")
          }}</mat-icon>
          <mat-icon *ngIf="!panelOpenState" class="s-28">{{
            iconService.getIcon("DOWN")
          }}</mat-icon>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="">
      <div class="" *ngFor="let group of question.groups; let i = index">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="category-title group-row"
        >
          <div fxLayout="row" fxLayoutAlign="start center" class="px-24">
            <mat-icon class="mx-8 s-8">circle</mat-icon>
            <!-- drag_handle -->
            <div class="group-name">{{ group.name }}</div>
          </div>

          <!-- 
                    ICONS:
                        list
                        circle
                        line_end
                        line_end_circle 
                        line_end_square
                        line_end_arrow
                        line_end_arrow_notch
                        line_end_diamond
                        fiber_manual_record
                        subdirectory_arrow_right
                    -->

          <!-- <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="mr-8 s-24">drag_handle</mat-icon>
                            <div class="">{{ group.name }}</div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="">{{ group.name }}</div>
                        </div>
                    </div> -->

          <div
            fxLayoutAlign="end end"
            fxFlex="161px"
            class="section-list-item px-24"
          >
            <button
              class=""
              mat-icon-button
              *ngxPermissionsOnly="rolesService.SALE_ROLES"
              (click)="onEditOptionsGroup(group, question.uuid)"
              tabindex="-1"
            >
              <mat-icon class="s-16">{{ getIcon("EDIT") }}</mat-icon>
            </button>
            <button
              class=""
              mat-icon-button
              [disabled]="isLoading"
              *ngxPermissionsOnly="rolesService.SALE_ROLES"
              (click)="onRemoveOptionsGroup(group.uuid)"
              tabindex="-1"
            >
              <mat-icon class="s-16">{{ getIcon("DELETE") }}</mat-icon>
            </button>
          </div>
        </div>
        <div class="px-24" *ngIf="group.options">
          <div class="product-child-item" *ngFor="let option of group.options">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="100"
                class=""
              >
                <div
                  fxFlex
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  class="py-4 px-16 section-list-item-border-right"
                >
                  <mat-icon class="mr-8 s-10">radio_button_unchecked</mat-icon>
                  <div class="mr-24">
                    {{ option.name }}
                  </div>
                  <div class="mr-24">{{ option.product_number }}</div>
                  <div class="mr-24">{{ option.price_label }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="mb-12 mt-24">
      <button
        mat-raised-button
        class="accent p-2 mr-16"
        (click)="onCreateOptionsGroup(question)"
      >
        {{ "PRODUCTS.ADD_QUESTION_ANSWER" | translate }}
      </button>
      <button
        mat-raised-button
        class="warn p-2"
        (click)="onDeleteQuestion(question.uuid)"
      >
        {{ "PRODUCTS.DELETE_QUESTION_ANSWER" | translate }}
      </button>
    </div>
  </mat-expansion-panel>
</div>
