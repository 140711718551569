import {
  DistributionModeEnum,
  ExportFormatEnum,
  ExportToEnum,
  LocaleEnum,
  PaperEnum,
} from './ReportsEnum';
import { ReportsFactory, ReportVOModel } from './reports-factory';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class ReportFactoryService {
  constructor(private logger: NGXLogger) {}

  public getVO(
    reportKey: string,
    distributionMode: DistributionModeEnum = DistributionModeEnum.RAW_HTML,
    exportFormat: ExportFormatEnum = ExportFormatEnum.HTML,
    exportTo: ExportToEnum = ExportToEnum.PREVIEW,
    params: any = null,
    filename: string | null = null,
    local: LocaleEnum = LocaleEnum.FR_CA,
    paper: PaperEnum = PaperEnum.A4
  ): ReportVOModel {
    return ReportsFactory.getVO(
      reportKey,
      distributionMode,
      exportFormat,
      exportTo,
      params,
      filename,
      local,
      paper
    );
  }
}
