export const locale = {
  lang: "en-CA",
  data: {
    NUMBERING: {
      TITLE: "Prefix and numbering",
      SUBTITLE: "A standard prefix and a unique number for each document created.",
      PREFIX: "Prefixes",
      PROJECT_PREFIX: "Project prefix",
      EVENT_PREFIX: "Event prefix",
      PROPOSITION_PREFIX: "Proposition prefix",
      ORDER_PREFIX: "Order prefix",
      INVOICE_PREFIX: "Invoice prefix",
      NUMBERING: "Numbering",
      NUMBER_NEXT: "Next numbering",
      NUMBER_INVOICE_NEXT: "Next invoice numbering",
      PREFIX_VALIDATION_ERROR: "The prefix must be between 3 and 6 characters and alpha-numeric only",
    },
  },
};
