<div style="max-height: 100%; overflow: auto">
  <div class="px-16 pb-16" fxLayout="column">
    <!--<div class="" *ngIf="client">{{ 'ATTACH_ENTERPRISE.TITLE' | translate }}{{client.name}}</div>-->

    <div
      fxFlex="100%"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      style="width: 100%"
    >
      <div matDialogTitle class="dialog-title text-bold">
        {{ "SELECT_CLIENT.TITLE" | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" style="margin-right: 24px">
          <mat-radio-group
            class="clients-radio-group"
            (change)="reloadClients()"
            [(ngModel)]="clientType"
          >
            <mat-radio-button
              class="clients-radio-button dialog-subtitle text-bold"
              value="client"
            >
              {{ "SELECT_CLIENT.BY_CLIENT" | translate }}
            </mat-radio-button>
            <mat-radio-button
              class="clients-radio-button dialog-subtitle text-bold"
              value="enterprise"
            >
              {{ "SELECT_CLIENT.BY_ENTERPRISE" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div
          class="gt2-light-green add-client-enterprise"
          style="width: 40px; height: 40px"
          fxLayout="row"
          fxLayoutAlign="right right"
        >
          <button
            [matMenuTriggerFor]="menuClient"
            type="button"
            matTooltip="{{ 'SELECT_CLIENT.NEW_CLIENT_TOOLTIP' | translate }}"
            mat-icon-button
            class=""
          >
            <mat-icon class="" color="">add</mat-icon>
          </button>
        </div>
        <mat-menu #menuClient="matMenu">
          <button mat-menu-item (click)="onCreateClient()">
            {{ "SELECT_CLIENT.CREATE_CLIENT" | translate }}
          </button>
          <button mat-menu-item (click)="onCreateEnterprise()">
            {{ "SELECT_CLIENT.CREATE_ENTERPRISE" | translate }}
          </button>
          <button mat-menu-item (click)="onCreateClientEnterprise()">
            {{ "SELECT_CLIENT.CREATE_CLIENT_ENTERPRISE" | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
    <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
      <mat-label>{{ "SELECT_CLIENT.SEARCH" | translate }}</mat-label>
      <input
        #filter
        id="search"
        [(ngModel)]="filterValue"
        matInput
        placeholder="{{ 'SELECT_CLIENT.SEARCH' | translate }}"
      />
      <button
        mat-button
        *ngIf="filterValue"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div
      #comboList
      class="container-border"
      style="height: 400px"
      fxLayout="column"
    >
      <div
        *ngIf="isLoading"
        fxLayout="column"
        fxFlex="100%"
        fxLayoutAlign="center center"
      >
        <mat-spinner [diameter]="20" class=""></mat-spinner>
      </div>

      <div
        *ngIf="isEmpty"
        fxLayout="column"
        fxFlex="100%"
        fxLayoutAlign="center center"
      >
        Effectuez une recherche de votre client.
      </div>

      <div
        *ngIf="!isLoading && !isEmpty && (!clients || clients.length === 0)"
        fxLayout="column"
        fxFlex="100%"
        fxLayoutAlign="center center"
      >
        <div class="font-size-20 my-16">
          {{ "SELECT_CLIENT.NO_CLIENTS" | translate }}
        </div>
      </div>

      <div *ngIf="!isLoading">
        <cdk-virtual-scroll-viewport
          #venueVScroll
          [itemSize]="50"
          class="venue-list-vscroll"
        >
          <div
            *cdkVirtualFor="let item of clients; trackBy: trackByUUID"
            class=""
          >
            <div
              class="client-child-item"
              (click)="onSelectClient(item)"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div>{{ item.name_label }}</div>
              <mat-radio-button
                [checked]="selectedClient === item"
              ></mat-radio-button>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>

    <div class="mt-8 selected-label text-bold" *ngIf="selectedClient">
      <!-- {{ 'SELECT_CLIENT.SELECTED_CLIENT' | translate }} {{selectedClient.first_name}} {{selectedClient.last_name}} -->
      {{ "SELECT_CLIENT.SELECTED_CLIENT" | translate }}
      {{ selectedClient.name_label }}
    </div>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!selectedClient"
      (click)="onClientSelected()"
      color="accent"
      class="mr-16"
    >
      {{ "GENERIC.OK" | translate }}
    </button>
    <button mat-raised-button (click)="dialogRef.close(false)" class="primary">
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
