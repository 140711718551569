<div
  *ngIf="loading"
  fxLayout="row"
  fxLayoutAlign="center center"
  style="width: 320px"
>
  <mat-spinner [diameter]="20" class=""></mat-spinner>
</div>
<mat-form-field *ngIf="product && !loading" style="width: 320px">
  <!-- <mat-label>{{ product.name_internal }}</mat-label> -->
  <mat-label>Faites un choix</mat-label>
  <mat-select [(ngModel)]="selectedValue" (selectionChange)="onSelect($event)">
    <mat-option *ngFor="let child of product.childs" [value]="child">
      {{ child.name_org }}
    </mat-option>
  </mat-select>
</mat-form-field>
