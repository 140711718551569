<div *ngIf="assignmentGroup" fxFlex class="group-panel">
  <mat-expansion-panel
    [expanded]="panelOpenState"
    (opened)="onStateChange(true)"
    (closed)="onStateChange(false)"
    hideToggle
  >
    <mat-expansion-panel-header
      [collapsedHeight]="'58px'"
      [expandedHeight]="'64px'"
      class="panel-header"
      [ngClass]="
        (assignmentGroup | groupAssigmentsCompletePipe)
          ? 'panel-header-complete'
          : ''
      "
    >
      <mat-panel-title
        fxFlex="38"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="title-content"
        style="height: 58px"
      >
        <div style="padding-top: 4px">{{ assignmentGroup.name }}</div>
      </mat-panel-title>
      <mat-panel-description
        fxLayout="row"
        fxLayoutAlign="end start"
        style="margin-right: 0 !important; padding-right: 0 !important"
      >
        <div fxLayout="row" fxLayoutAlign="end start" style="height: 100%">
          <ng-template #assignmentFullHint>
            <div>{{ "ASSIGNMENTS.ASSIGNMENTS_COMPLETE" | translate }}</div>
          </ng-template>

          <div
            fxLayout="row"
            fxLayoutAlign="end center"
            class="title mr-8"
            style="text-align: end"
          >
            <div>{{ assignmentGroup.count_label }}</div>
            <mat-icon
              *ngIf="assignmentGroup | groupAssigmentsCompletePipe"
              class="s-32 ml-8"
              color="accent"
              matTooltip="assignmentFullHint"
              content-type="template"
            >
              {{ getIcon("CIRCLE_CHECK") }}
            </mat-icon>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            class="toggle-icons mr-16"
          >
            <mat-icon *ngIf="panelOpenState" class="s-28">{{
              iconService.getIcon("UP")
            }}</mat-icon>
            <mat-icon *ngIf="!panelOpenState" class="s-28">{{
              iconService.getIcon("DOWN")
            }}</mat-icon>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="add-btn gt2-light-green"
            matTooltip="{{ 'ASSIGNMENTS.ADD_ASSIGNEMENT_HINT' | translate }}"
            (click)="$event.stopPropagation(); onIncrementGroup()"
          >
            <mat-icon style="color: black">{{
              iconService.getIcon("ADD")
            }}</mat-icon>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div fxLayout="column">
      <!-- DETAILS -->
      <mat-card fxFlex fxLayout="column" class="mt-16">
        <div fxFlex fxLayout="row" fxLayoutAlign="en start">
          <div fxFlex>
            <div fxFlex="50">
              <div class="job-title">
                {{ assignmentGroup.job_function.name }}
              </div>
              <div
                *ngIf="assignmentGroup.details.requested_all_day"
                class="mb-8"
                fxLayout="row"
              >
                <div class="details-prefix">
                  {{ "ASSIGNMENTS.REQUESTED_ALL_DAY" | translate }}: &nbsp;
                </div>
                <div class="details-value">{{ "GENERIC.YES" | translate }}</div>
              </div>
              <div
                *ngIf="!assignmentGroup.details.requested_all_day"
                class="mb-8"
                fxLayout="row"
              >
                <div class="details-prefix">
                  {{ "ASSIGNMENTS.REQUESTED_ALL_DAY" | translate }}: &nbsp;
                </div>
                <div class="details-value">{{ "GENERIC.NO" | translate }}</div>
              </div>
              <div fxLayout="row" class="mb-8">
                <div class="details-prefix">
                  {{ "ASSIGNMENTS.PLANNED_START" | translate | titlecase }}:
                  &nbsp;
                </div>
                <div class="details-value" fxLayout="row">
                  {{ assignmentGroup.details.requested_date_from }}
                  <div *ngIf="!assignmentGroup.details.requested_all_day">
                    - {{ assignmentGroup.details.requested_time_from }}
                  </div>
                </div>
              </div>
              <div fxLayout="row" class="mb-8">
                <div class="details-prefix">
                  {{ "ASSIGNMENTS.PLANNED_END" | translate | titlecase }}:
                  &nbsp;
                </div>
                <div class="details-value" fxLayout="row">
                  {{ assignmentGroup.details.requested_date_to }}
                  <div *ngIf="!assignmentGroup.details.requested_all_day">
                    - {{ assignmentGroup.details.requested_time_to }}
                  </div>
                </div>
              </div>
            </div>

            <div fxFlex="50">
              <div class="details-prefix mb-4">
                {{ "GENERIC.NOTES" | translate }}
              </div>
              <div
                [ngClass]="
                  assignmentGroup.details.note &&
                  assignmentGroup.details.note.length > 0
                    ? 'note'
                    : ''
                "
                [innerHTML]="assignmentGroup.details.note"
              ></div>
            </div>
          </div>

          <button mat-button mat-icon-button (click)="onEdit()">
            <mat-icon color="accent" class="s-28">
              {{ getIcon("EDIT") }}
            </mat-icon>
          </button>
        </div>
      </mat-card>

      <!-- propositions -->
      <div class="card-title mt-24">
        {{ "ASSIGNMENTS.PROPOSALS" | translate }}
      </div>
      <div class="list-header">
        <div fxFlex="50" class="">
          {{ "GENERIC.NAME" | translate }}
        </div>
        <div fxFlex="50" class="">
          {{ "GENERIC.STATUS" | translate }}
        </div>
      </div>
      <div class="propositions-list" fusePerfectScrollbar>
        <!-- TRACKBY: trackBy -->
        <div
          *ngFor="let prop of assignmentGroup.proposals; trackBy: trackByUUID"
          fxLayout="row"
          class="propositions-item"
        >
          <div
            *ngIf="prop.employee"
            fxFlex="50"
            fxLayout="row"
            fxLayoutAlign="start center"
            class="item-cell"
          >
            <div style="margin-right: 8px">
              {{ prop.employee.first_name }} {{ prop.employee.last_name }}
            </div>
            <mat-icon
              class="secondary-text icon-btn-prop"
              matTooltip="{{ 'ASSIGNMENTS.OPEN_EMPLOYEE' | translate }}"
              (click)="onViewEmployee(prop)"
              >{{ getIcon("EMPLOYEES") }}</mat-icon
            >
          </div>
          <div
            *ngIf="!prop.employee"
            fxFlex="50"
            fxLayout="row"
            fxLayoutAlign="start center"
            class="item-cell"
          >
            &nbsp;
          </div>
          <div
            fxFlex="50"
            fxLayout="row"
            fxLayoutAlign="start center"
            class="item-cell"
          >
            <mat-label>Select a status</mat-label>
            <mat-select
              placeholder="Select a status"
              [ngModel]="prop.status"
              (selectionChange)="onProposalStatusChange($event, prop)"
            >
              <mat-option
                *ngFor="let status of proposalsEnums"
                [value]="status.enum"
              >
                <span class="iso">{{ status.label }}</span>
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div
          fxFlex
          fxLayout="row"
          fxLayoutAlign="center center"
          *ngIf="assignmentGroup.proposals.length === 0"
          style="padding: 16px"
          class="mat-h3"
        >
          {{ "ASSIGNMENTS.NO_PROPOSALS" | translate }}
        </div>
      </div>

      <!-- Assignements -->
      <div class="card-title mt-24">
        {{ "ASSIGNMENTS.ASSIGNMENTS" | translate }}
      </div>
      <div
        class="list-header scroll-space"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div fxFlex="28" class="">
          {{ "GENERIC.NAME" | translate }}
        </div>
        <div fxFlex="33" class="item-cell-bl">
          {{ "ASSIGNMENTS.SOURCE" | translate }}
        </div>
        <!-- DO NOT DELETE, TEMPORARY REMOVED -->
        <!-- <div fxFlex="15" class="item-cell-bl">{{ 'GENERIC.STATUS' | translate }}</div> -->
        <div fxFlex="12" class="item-cell-bl">
          {{ "ASSIGNMENTS.EXPENSES" | translate }}
        </div>
        <div fxFlex="12" class="item-cell-bl">
          {{ "ASSIGNMENTS.RATE" | translate }}
        </div>
        <div fxFlex="88px" class="item-cell-bl">
          {{ "ASSIGNMENTS.TIPS" | translate }}
        </div>
        <div fxFlex="60px" class="item-cell-bl">
          {{ "ASSIGNMENTS.MEAL" | translate }}
        </div>
        <div fxFlex="95px" class="item-cell-bl">
          {{ "ASSIGNMENTS.ACTIONS" | translate }}
        </div>
        <div fxFlex="40px" class="item-cell-bl">
          {{ "GENERIC.NOTES" | translate }}
        </div>
      </div>

      <div class="assignments-list">
        <!-- TRACKBY: trackBy -->
        <!-- <div *ngFor="let assignment of assignmentGroup.jobs; trackBy: trackByUUID" fxLayout="row" class="assignment-item">
                        <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center" class="item-cell">
                            <div *ngIf="assignment.employee">{{ assignment.employee.name }}</div>
                            <div *ngIf="assignment.staff_provider">{{ assignment.staff_provider.name }}</div>
                        </div>
                        
                        <div fxFlex="35" *ngIf="!assignment.employee" fxLayout="row" fxLayoutAlign="start center" class="item-cell item-cell-bl">
                            <app-gt2-select
                                style="width: 100%;"
                                [useFloatValue]="'never'"
                                [service]="staffProvider" 
                                [nullableUseNone]="false" 
                                [placeHolder]="'ASSIGNMENTS.SELECT_STAFF_PROVIDER' | translate"
                                [label]="''" 
                                [selectedModel]="assignment.staff_provider" 
                                (changeEvent)="onJobStaffProviderChange($event, assignment)"
                                [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')" 
                                fxFlex="100%" 
                                class="">
                            </app-gt2-select>
    
                            <mat-icon   class="secondary-text icon-btn"
                                        matTooltip="Texte a venir..."
                                        [ngClass]="(assignment.staff_provider !== null)? '' : 'icon-btn-disabled'"
                                        (click)="onUnlink(assignment)">{{ getIcon('LINK_OFF') }}</mat-icon>
                        </div>
    
                        <div *ngIf="assignment.employee" fxFlex="35" fxLayout="row" fxLayoutAlign="start center" class="item-cell item-cell-bl">
                            {{ 'ASSIGNMENTS.INTERNAL' | translate }}
                        </div>
    
                        <div fxFlex="15" fxLayout="row" fxLayoutAlign="start center" class="item-cell item-cell-bl">
                            {{ assignment.status_label }}
                        </div>
    
                        <div fxFlex="10" fxLayout="row" fxLayoutAlign="start center" class="item-cell item-cell-bl">
                            <div fxLayout="row" fxLayoutAlign="start center" 
                                (click)="onJobExpenses(assignment)"
                                matTooltip="{{ 'GENERIC.EDIT' | translate }} {{ 'ASSIGNMENTS.JOB_EXPENSES' | translate | lowercase }}" 
                                style="cursor: pointer;">   
                                {{ assignment.job_expenses_total_label }}
                                <mat-icon class="secondary-text icon-btn s-16 ml-4">{{ getIcon('EDIT') }}</mat-icon>
                            </div>
                        </div>
    
                        <ng-template #rateHint>
                            <ul style="margin-right: 24px; text-align: start;">
                                <li>{{ 'ASSIGNMENTS.RATE' | translate }}: {{ assignment.hourly_wage_label }}</li>
                                <li>{{ 'GENERIC.DURATION' | translate }}: {{ assignment.real_duration_label_hour }}</li>
                                <li>{{ 'ASSIGNMENTS.TOTAL_COST' | translate }}: {{ assignment.full_duration_cost_label }}</li>
                            </ul>
                        </ng-template>
    
                        <div fxFlex="10" fxLayout="row" fxLayoutAlign="start center" class="item-cell item-cell-bl">
                            <div *ngxPermissionsOnly="rolesService.RH_ROLES" fxLayout="row" fxLayoutAlign="start center">
                                {{ assignment.hourly_wage_label }}
                                <mat-icon 
                                    class="s-20 ml-8"
                                    [matTooltip]="rateHint" 
                                    content-type="template">
                                    {{ getIcon('INFO') }}
                                </mat-icon>
                            </div>
                            <div *ngxPermissionsExcept="rolesService.RH_ROLES" fxLayout="row" fxLayoutAlign="start center">
                                <button class="" mat-icon-button
                                matTooltip="{{ 'GENERIC.NO_PERMISSIONS' | translate }}"
                                type="button">
                                    <mat-icon class="s-20" color="">help</mat-icon>
                                </button>
                            </div>
                        </div>
    
                        <div fxFlex="88px" fxLayout="row" fxLayoutAlign="start center" class="item-cell item-cell-bl">
                            <mat-icon *ngIf="assignment.tips_enabled" color="accent" class="s-20">
                                {{ getIcon('CHECK') }}
                            </mat-icon>
                        </div>
                        
                        <div fxFlex="60px" fxLayout="row" fxLayoutAlign="start center" class="item-cell item-cell-bl">
                            {{ assignment.meal_included }}
                        </div>
    
                        <div fxFlex="95px" fxLayout="row" fxLayoutAlign="start center" class="item-cell item-cell-bl">
                            <mat-icon class="secondary-text icon-btn icon-action" 
                                        matTooltip="{{ 'ASSIGNMENTS.OPEN_EMPLOYEE' | translate }}"
                                        [ngClass]="(assignment.employee !== null)? '' : 'icon-btn-disabled'"
                                        (click)="onViewAssignment(assignment)">{{ getIcon('EMPLOYEES') }}</mat-icon>
                            <mat-icon class="secondary-text icon-btn icon-action" 
                                        matTooltip="{{ 'GENERIC.EDIT' | translate }}"
                                        (click)="onEditAssignment(assignment)">{{ getIcon('EDIT') }}</mat-icon>
                            <mat-icon class="secondary-text icon-btn icon-action"
                                        matTooltip="{{ 'GENERIC.DELETE' | translate }}"
                                        (click)="onDeleteAssignment(assignment)">{{ getIcon('DELETE') }}</mat-icon>
                                        
                        </div>
                        <div fxFlex="40px" fxLayout="row" fxLayoutAlign="center center" class="item-cell item-cell-bl">
                            <mat-icon 
                                *ngIf="assignment.note_instruction"
                                matTooltip="{{assignment.note_instruction.substring(0, noteHintMaxChar)}} {{(assignment.note_instruction.length > noteHintMaxChar)? '...' : ''}}" 
                                placement="top" 
                                show-delay="100" 
                                class="s-20 icon-cursor">
                                {{ getIcon('INFO') }}
                            </mat-icon>
    
                            <mat-icon 
                                *ngIf="!assignment.note_instruction"
                                class="s-20 note-icon-btn-disabled">
                                {{ getIcon('INFO') }}
                            </mat-icon>
                        </div>
                            
                    </div> -->

        <cdk-virtual-scroll-viewport
          [itemSize]="10"
          class="list-vscroll"
          [ngClass]="
            assignmentGroup.jobs && assignmentGroup.jobs.length === 0
              ? 'list-vscroll-empty'
              : ''
          "
        >
          <div
            *cdkVirtualFor="
              let assignment of assignmentGroup.jobs;
              trackBy: trackByUUID
            "
            fxLayout="row"
            class="assignment-item"
          >
            <div
              fxFlex="28"
              fxLayout="row"
              fxLayoutAlign="start center"
              class="item-cell"
            >
              <div *ngIf="assignment.employee">
                {{ assignment.employee.name }}
              </div>
              <div *ngIf="assignment.staff_provider">
                {{ assignment.staff_provider.name }}
              </div>
            </div>

            <div
              fxFlex="33"
              *ngIf="!assignment.employee"
              fxLayout="row"
              fxLayoutAlign="start center"
              class="item-cell item-cell-bl"
            >
              <app-gt2-select
                style="width: 100%"
                [useFloatValue]="'auto'"
                [service]="staffProvider"
                [nullableUseNone]="false"
                [placeHolder]="'ASSIGNMENTS.SELECT_STAFF_PROVIDER' | translate"
                [label]="''"
                [selectedModel]="assignment.staff_provider"
                (changeEvent)="onJobStaffProviderChange($event, assignment)"
                [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                fxFlex="100%"
                class=""
              >
              </app-gt2-select>

              <mat-icon
                class="secondary-text icon-btn"
                matTooltip="{{ 'ASSIGNMENTS.DETACH_EXTRERNAL' | translate }}"
                [ngClass]="
                  assignment.staff_provider !== null ? '' : 'icon-btn-disabled'
                "
                (click)="onUnlink(assignment)"
                >{{ getIcon("LINK_OFF") }}</mat-icon
              >
            </div>

            <div
              *ngIf="assignment.employee"
              fxFlex="33"
              fxLayout="row"
              fxLayoutAlign="start center"
              class="item-cell item-cell-bl"
            >
              {{ "ASSIGNMENTS.INTERNAL" | translate }}
            </div>

            <!-- DO NOT DELETE, TEMPORARY REMOVED -->
            <!-- <div fxFlex="15" fxLayout="row" fxLayoutAlign="start center" class="item-cell item-cell-bl">
                        {{ assignment.status_label }}
                    </div> -->

            <ng-template #jobExpensesHint>
              <ul style="margin-right: 24px; text-align: start">
                <li *ngFor="let expense of assignment.job_expenses">
                  {{ expense.name }}: {{ expense.amount_label }}
                </li>
                <li
                  *ngIf="
                    !assignment.job_expenses ||
                    assignment.job_expenses.length === 0
                  "
                >
                  {{ "ASSIGNMENTS.NO_JOB_EXPENSES" | translate }}
                </li>
              </ul>
            </ng-template>
            <div
              fxFlex="12"
              fxLayout="row"
              fxLayoutAlign="start center"
              class="item-cell item-cell-bl"
            >
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                (click)="onJobExpenses(assignment)"
                matTooltip="{{ 'GENERIC.EDIT' | translate }} {{
                  'ASSIGNMENTS.JOB_EXPENSES' | translate | lowercase
                }}"
                style="cursor: pointer"
              >
                {{ assignment.job_expenses_total_label }}
                <mat-icon class="secondary-text icon-btn s-16 ml-8">{{
                  getIcon("EDIT")
                }}</mat-icon>
              </div>
              <mat-icon
                class="s-20 ml-4 hint-tooltip"
                matTooltip="jobExpensesHint"
                content-type="template"
              >
                {{ getIcon("INFO") }}
              </mat-icon>
            </div>

            <ng-template #rateHint>
              <ul style="margin-right: 24px; text-align: start">
                <li>
                  {{ "ASSIGNMENTS.RATE" | translate }}:
                  {{ assignment.hourly_wage_label }}
                </li>
                <li>
                  {{ "GENERIC.DURATION" | translate }}:
                  {{ assignment.real_duration_label_hour }}
                </li>
                <li>
                  {{ "ASSIGNMENTS.TOTAL_COST" | translate }}:
                  {{ assignment.full_duration_cost_label }}
                </li>
              </ul>
            </ng-template>

            <div
              fxFlex="12"
              fxLayout="row"
              fxLayoutAlign="start center"
              class="item-cell item-cell-bl"
            >
              <div
                *ngxPermissionsOnly="rolesService.RH_ROLES"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                {{ assignment.hourly_wage_label }}
                <mat-icon
                  matTooltip="{{ 'GENERIC.EDIT' | translate }}"
                  (click)="onEditAssignment(assignment)"
                  class="secondary-text icon-btn s-20 ml-8"
                  >{{ getIcon("EDIT") }}</mat-icon
                >
                <mat-icon
                  class="s-20 ml-4 hint-tooltip"
                  matTooltip="rateHint"
                  content-type="template"
                >
                  {{ getIcon("INFO") }}
                </mat-icon>
              </div>
              <div
                *ngxPermissionsExcept="rolesService.RH_ROLES"
                fxLayout="row"
                fxLayoutAlign="center center"
                style="width: 100%"
              >
                <mat-icon
                  matTooltip="{{ 'GENERIC.NO_PERMISSIONS' | translate }}"
                  class="icon-btn s-20"
                  >help</mat-icon
                >
              </div>
            </div>

            <div
              fxFlex="88px"
              fxLayout="row"
              fxLayoutAlign="center center"
              class="item-cell item-cell-bl"
            >
              <div
                *ngIf="assignment.tips_enabled"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <mat-icon color="accent" class="s-20 pr-8">
                  {{ getIcon("CHECK") }}
                </mat-icon>
                <div class="ml-4">{{ assignment.tips_amount_label }}</div>
              </div>
            </div>

            <div
              fxFlex="60px"
              fxLayout="row"
              fxLayoutAlign="center center"
              class="item-cell item-cell-bl"
            >
              <div class="pr-8">{{ assignment.meal_included }}</div>
            </div>

            <div
              fxFlex="95px"
              fxLayout="row"
              fxLayoutAlign="start center"
              class="item-cell item-cell-bl"
            >
              <mat-icon
                class="secondary-text icon-btn icon-action"
                matTooltip="{{ 'ASSIGNMENTS.OPEN_EMPLOYEE' | translate }}"
                [ngClass]="
                  assignment.employee !== null ? '' : 'icon-btn-disabled'
                "
                (click)="onViewEmployee(assignment)"
                >{{ getIcon("EMPLOYEES") }}</mat-icon
              >

              <mat-icon
                class="secondary-text icon-btn icon-action"
                matTooltip="{{ 'GENERIC.EDIT' | translate }}"
                (click)="onEditAssignment(assignment)"
                >{{ getIcon("EDIT") }}</mat-icon
              >

              <mat-icon
                *ngIf="!assignment.employee && !assignment.staff_provider"
                class="secondary-text icon-btn icon-action"
                matTooltip="{{ 'GENERIC.DELETE' | translate }}"
                (click)="onDeleteAssignment(assignment)"
                >{{ getIcon("DELETE") }}</mat-icon
              >

              <mat-icon
                *ngIf="assignment.employee"
                class="secondary-text icon-btn icon-action icon-btn-disabled"
                matTooltip="{{
                  'ASSIGNMENTS.ASSIGNMENT_DELETE_EMLPOYEE_HINT' | translate
                }} {{ assignment.employee.name }}"
              >
                {{ getIcon("DELETE") }}
              </mat-icon>

              <mat-icon
                *ngIf="assignment.staff_provider"
                class="secondary-text icon-btn icon-action icon-btn-disabled"
                matTooltip="{{
                  'ASSIGNMENTS.ASSIGNMENT_DELETE_STAFF_PROVIDER_HINT'
                    | translate
                }} {{ assignment.staff_provider.name }}"
              >
                {{ getIcon("DELETE") }}
              </mat-icon>
            </div>
            <div
              fxFlex="40px"
              fxLayout="row"
              fxLayoutAlign="center center"
              class="item-cell item-cell-bl"
            >
              <mat-icon
                *ngIf="assignment.note_instruction"
                matTooltip="{{
                  assignment.note_instruction.substring(0, noteHintMaxChar)
                }} {{
                  assignment.note_instruction.length > noteHintMaxChar
                    ? '...'
                    : ''
                }}"
                position="top"
                showDelay="200"
                hideDelay="200"
                class="s-20 icon-cursor"
              >
                {{ getIcon("INFO") }}
              </mat-icon>

              <mat-icon
                *ngIf="!assignment.note_instruction"
                class="s-20 note-icon-btn-disabled"
              >
                {{ getIcon("INFO") }}
              </mat-icon>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>

        <div
          fxFlex
          fxLayout="row"
          fxLayoutAlign="center center"
          *ngIf="assignmentGroup.jobs.length === 0"
          style="padding: 16px"
          class="mat-h3 no-assignments"
        >
          {{ "ASSIGNMENTS.NO_ASSIGNMENTS" | translate }}
        </div>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="center start">
        <button
          mat-raised-button
          color="warn"
          class="mt-16"
          (click)="onDelete()"
        >
          {{ "GENERIC.DELETE" | translate }}
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</div>
