export const locale = {
  lang: "fr-CA",
  data: {
    ATTACH_ENTERPRISE: {
      TITLE: "Attacher une entreprise à ",
      ATTACH: "Attacher",
      SELECTED_ENTERPRISE: "Entreprise sélectionnée : ",
    },
  },
};
