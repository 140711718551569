<!--<div id="clients" class="page-layout simple fullwidth inner-scroll" fxLayout="column">-->
<div
  id="clients"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
  style="overflow-x: scroll"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
      fusePerfectScrollbar
      style="min-width: 1023px"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <!--<div class="black-fg" fxLayout="row" fxLayoutAlign="start center">-->
        <!--<mat-icon class="secondary-text s-16">home</mat-icon>-->
        <!--<mat-icon class="secondary-text s-16">chevron_right</mat-icon>-->
        <!--<span class="secondary-text">{{ "GENERIC.APPLICATION" | translate}}</span>-->
        <!--</div>-->
        <div class="h1 mt-8">
          {{ "GENERIC.CLIENTS_ENTERPRISE" | translate }}
        </div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    style="height: 40px; min-height: 40px; max-height: 40px; min-width: 1023px"
    class="primary mat-elevation-z1 py-8 gt2-sub-header"
  >
    <button mat-button (click)="onCreateClient()" class="gt2-sub-header-button">
      <!-- <mat-icon class="s-24">{{ getIcon('CLIENT') }}</mat-icon> -->
      <mat-icon
        class="fa-lg gt2-sub-header-button"
        fontSet="fa"
        fontIcon="{{ getIcon('CLIENT') }}"
      ></mat-icon>
      <span class="ml-8">{{ "CLIENTS.CREATE_CLIENT" | translate }}</span>
    </button>

    <button
      mat-button
      (click)="onCreateEnterprise()"
      class="gt2-sub-header-button"
    >
      <mat-icon class="s-24 gt2-sub-header-button">{{
        getIcon("ENTERPRISE")
      }}</mat-icon>
      <span class="ml-8">{{ "CLIENTS.CREATE_ENTERPRISE" | translate }}</span>
    </button>

    <button
      mat-button
      (click)="onCreateClientAndEnterprise()"
      class="gt2-sub-header-button"
    >
      <mat-icon class="s-24 gt2-sub-header-button">{{
        getIcon("ADD")
      }}</mat-icon>
      <span class="ml-8">{{
        "CLIENTS.CREATE_CLIENT_ENTERPRISE" | translate
      }}</span>
    </button>
  </div>
  <!-- / SUB HEADER -->

  <div
    #mainContainer
    fxLayout="row"
    fxLayoutAlign="start start"
    class="center p-20 pt-8"
    fusePerfectScrollbar
    style="min-width: 1023px"
  >
    <div fxFlex="{{ viewSize }}" class="mr-12">
      <!--CLIENTS Data table-->
      <div class="" fxLayout="row" fxLayoutAlign="end center">
        <button
          mat-button
          *ngIf="fileService.printEnabled()"
          matTooltip="{{ 'GENERIC.PRINT' | translate }}"
          (click)="onPrint('clients')"
          class="mat-icon-button"
        >
          <mat-icon class="s-30">print</mat-icon>
        </button>
        <button
          mat-button
          *ngIf="fileService.pdfEnabled()"
          matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
          (click)="onDownloadPDF('clients')"
          class="mat-icon-button"
        >
          <mat-icon class="s-30">picture_as_pdf</mat-icon>
        </button>

        <mat-spinner
          *ngIf="print.loadingPrint"
          [diameter]="20"
          class="mt-12"
        ></mat-spinner>

        <button
          mat-button
          *ngIf="fileService.excelEnabled()"
          matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
          (click)="onDownloadXLS('clients')"
          class="mat-icon-button"
        >
          <!--<mat-icon class="s-30">collections</mat-icon>-->
          <mat-icon fontSet="fa" fontIcon="fa-table" class="fa-2x"></mat-icon>
          <!--<fa name="table" size="2x" class="p-4"></fa>-->
        </button>
        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>

      <div class="mat-white-bg mat-elevation-z2">
        <div
          class="pl-12 pr-12"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div class="h1">{{ "GENERIC.CLIENTS" | translategt }}</div>
          <div class="mat-body-2 p-12">
            {{ clientsResultsLength }}
            {{ "GENERIC.CLIENTS" | translate }}
          </div>
        </div>
        <div class="pl-8 pr-8" fxLayout="row" fxLayoutAlign="end center">
          <!-- SEARCH -->
          <div
            class=""
            fxFlex="1 0 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <label for="clientsSearch" class="mr-8">
              <mat-icon class="secondary-text">search</mat-icon>
            </label>
            <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
              <mat-label>{{
                "GENERIC.FILTERING_PLACEHOLDER" | translate | titlecase
              }}</mat-label>
              <input
                id="clientsSearch"
                [(ngModel)]="clientsFilterValue"
                matInput
                #clientsFilter
              />
              <button
                mat-button
                *ngIf="clientsFilterValue"
                matSuffix
                mat-icon-button
                (click)="clearSearch()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <!-- / SEARCH -->
        </div>
        <div class="border-bottom">
          <div
            *ngIf="!isClientsLoadingResults && clientsResultsLength === 0"
            fxLayout="row"
            fxLayoutAlign="center start"
            class="pb-8 mat-body-2"
          >
            {{ "GENERIC.NO_RESULTS" | translate }}
          </div>

          <div *ngIf="isClientsLoadingResults" class="">
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>
        <div>
          <div class="border-bottom">
            <mat-paginator
              #clientsPaginator
              [length]="clientsResultsLength"
              (page)="datatableHelperService.onPaginator($event, 'clients')"
              showFirstLastButtons="true"
              [pageIndex]="0"
              [pageSize]="'clients' | tableSizePipe: 'large'"
              [pageSizeOptions]="datatableHelperService.pageSizeOptions"
            >
            </mat-paginator>
          </div>
          <mat-table
            #clientsTable
            class=""
            matSort
            matSortStart="desc"
            #clientsSort="matSort"
            [@animateStagger]="{ value: '50' }"
            [dataSource]="clientsDataSource"
          >
            <!-- First Name Column -->
            <ng-container cdkColumnDef="first_name">
              <mat-header-cell fxFlex="25" *cdkHeaderCellDef mat-sort-header
                >{{ "GENERIC.FIRST_NAME" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="25"
                *cdkCellDef="let client"
              >
                <p class="text-truncate font-weight-600">
                  {{ client.first_name }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- Last Name Column -->
            <ng-container cdkColumnDef="last_name">
              <mat-header-cell fxFlex="25" *cdkHeaderCellDef mat-sort-header
                >{{ "GENERIC.LAST_NAME" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="25"
                *cdkCellDef="let client"
              >
                <p class="text-truncate font-weight-600">
                  {{ client.last_name }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- Employee # Column -->
            <ng-container cdkColumnDef="client_number">
              <mat-header-cell fxFlex="20" *cdkHeaderCellDef mat-sort-header>
                {{ "CLIENTS.CLIENT_NUMBER" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="20"
                *cdkCellDef="let client"
              >
                <p class="text-truncate">
                  {{ client.client_number }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- enterprise -->
            <ng-container cdkColumnDef="enterprise">
              <mat-header-cell fxFlex="20" *cdkHeaderCellDef mat-sort-header>
                {{ "GENERIC.ENTERPRISE" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="20"
                *cdkCellDef="let client"
              >
                <p *ngIf="client.enterprise_name_label" class="text-truncate">
                  {{ client.enterprise_name_label }}
                </p>
                <p *ngIf="!client.enterprise_name_label" class="text-truncate">
                  &nbsp;
                </p>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="newTab">
              <mat-header-cell fxFlex="10" *cdkHeaderCellDef> </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="10"
                *cdkCellDef="let client"
              >
                <button
                  mat-button
                  matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                  (click)="$event.stopPropagation(); onOpenItemInNewTab(client)"
                  class="mat-icon-button"
                >
                  <mat-icon class="">{{ getIcon("NEW_TAB") }}</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *cdkHeaderRowDef="clientsDisplayedColumns"
            ></mat-header-row>
            <mat-row
              *cdkRowDef="let client; columns: clientsDisplayedColumns"
              class="mat-row-color"
              matRipple
              [routerLink]="
                '/' +
                appRoutesService.clients +
                '/' +
                client.slug +
                '/' +
                client.uuid
              "
            >
            </mat-row>
            <!--[ngClass]="{'mat-light-blue-50-bg':true}"-->
          </mat-table>
          <mat-paginator
            #paginatorBottom
            [length]="clientsPaginator.length"
            (page)="clientsSyncPrimaryPaginator($event)"
            showFirstLastButtons="true"
            [pageIndex]="clientsPaginator.pageIndex"
            [pageSize]="clientsPaginator.pageSize"
            [pageSizeOptions]="clientsPaginator.pageSizeOptions"
          >
          </mat-paginator>
          <div
            *ngIf="isClientsLoadingResults && !isFirstLoad"
            fxLayout="row"
            fxLayoutAlign="center start"
            class=""
          >
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>

    <!-- ENTERPRISES -->
    <div fxFlex="{{ viewSize }}" class="">
      <div class="" fxLayout="row" fxLayoutAlign="end center">
        <button
          mat-button
          *ngIf="fileService.printEnabled()"
          matTooltip="{{ 'GENERIC.PRINT' | translate }}"
          (click)="onPrint('enterprises')"
          class="mat-icon-button"
        >
          <mat-icon class="s-30">print</mat-icon>
        </button>
        <button
          mat-button
          *ngIf="fileService.pdfEnabled()"
          matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
          (click)="onDownloadPDF('enterprises')"
          class="mat-icon-button"
        >
          <mat-icon class="s-30">picture_as_pdf</mat-icon>
        </button>

        <mat-spinner
          *ngIf="print.loadingPrint"
          [diameter]="20"
          class="mt-12"
        ></mat-spinner>

        <button
          mat-button
          *ngIf="fileService.excelEnabled()"
          matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
          (click)="onDownloadXLS('enterprises')"
          class="mat-icon-button"
        >
          <!--<mat-icon class="s-30">collections</mat-icon>-->
          <mat-icon fontSet="fa" fontIcon="fa-table" class="fa-2x"></mat-icon>
        </button>
        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
      <div class="mat-white-bg mat-elevation-z2">
        <div
          class="pl-12 pr-12"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div class="h1">{{ "GENERIC.ENTERPRISES" | translategt }}</div>
          <div class="mat-body-2 p-12">
            {{ enterprisesResultsLength }}
            {{ "GENERIC.ENTERPRISES" | translategt }}
          </div>
        </div>
        <div class="pl-8 pr-8" fxLayout="row" fxLayoutAlign="end center">
          <!-- SEARCH -->
          <div
            class=""
            fxFlex="1 0 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <label for="searchEnterprise" class="mr-8">
              <mat-icon class="secondary-text">search</mat-icon>
            </label>
            <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
              <mat-label>{{
                "GENERIC.FILTERING_PLACEHOLDER" | translate | titlecase
              }}</mat-label>
              <input
                id="searchEnterprise"
                [(ngModel)]="enterprisesFilterValue"
                matInput
                #enterprisesFilter
              />
              <button
                mat-button
                *ngIf="enterprisesFilterValue"
                matSuffix
                mat-icon-button
                (click)="clearEnterpriseSearch()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <!-- / SEARCH -->
        </div>
        <div class="border-bottom">
          <div
            *ngIf="
              !isEnterprisesLoadingResults && enterprisesResultsLength === 0
            "
            fxLayout="row"
            fxLayoutAlign="center start"
            class="pb-8 mat-body-2"
          >
            {{ "GENERIC.NO_RESULTS" | translate }}
          </div>

          <div *ngIf="isEnterprisesLoadingResults" class="">
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>
        <div>
          <div class="" class="border-bottom">
            <mat-paginator
              #enterprisesPaginator
              [length]="enterprisesResultsLength"
              (page)="datatableHelperService.onPaginator($event, 'enterprises')"
              showFirstLastButtons="true"
              [pageIndex]="0"
              [pageSize]="'enterprises' | tableSizePipe: 'large'"
              [pageSizeOptions]="datatableHelperService.pageSizeOptions"
            >
            </mat-paginator>
          </div>
          <mat-table
            #enterprisesTable
            class=""
            matSort
            matSortStart="desc"
            #enterprisesSort="matSort"
            [@animateStagger]="{ value: '50' }"
            [dataSource]="enterprisesDataSource"
          >
            <!-- Name Column -->
            <ng-container cdkColumnDef="name">
              <mat-header-cell fxFlex="65" *cdkHeaderCellDef mat-sort-header
                >{{ "GENERIC.NAME" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="65"
                *cdkCellDef="let enterprise"
              >
                <!--<p class="text-truncate font-weight-600">test</p>-->
                <p class="text-truncate font-weight-600">
                  {{ enterprise.name }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- Employee # Column -->
            <ng-container cdkColumnDef="enterprise_number">
              <mat-header-cell fxFlex="25" *cdkHeaderCellDef mat-sort-header
                >{{ "CLIENTS.ENTERPRISE_NUMBER" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="25"
                *cdkCellDef="let enterprise"
              >
                <p class="text-truncate">
                  {{ enterprise.enterprise_number }}
                </p>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="newTab">
              <mat-header-cell fxFlex="10" *cdkHeaderCellDef> </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="10"
                *cdkCellDef="let enterprise"
              >
                <button
                  mat-button
                  matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                  (click)="
                    $event.stopPropagation(); onOpenItemInNewTab(enterprise)
                  "
                  class="mat-icon-button"
                >
                  <mat-icon class="">{{ getIcon("NEW_TAB") }}</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *cdkHeaderRowDef="enterprisesDisplayedColumns"
            ></mat-header-row>
            <mat-row
              *cdkRowDef="let enterprise; columns: enterprisesDisplayedColumns"
              class="mat-row-color"
              matRipple
              [routerLink]="
                '/' +
                appRoutesService.enterprises +
                '/' +
                enterprise.slug +
                '/' +
                enterprise.uuid
              "
            >
            </mat-row>
          </mat-table>
          <mat-paginator
            #paginatorBottom
            [length]="enterprisesPaginator.length"
            (page)="enterprisesSyncPrimaryPaginator($event)"
            showFirstLastButtons="true"
            [pageIndex]="enterprisesPaginator.pageIndex"
            [pageSize]="enterprisesPaginator.pageSize"
            [pageSizeOptions]="enterprisesPaginator.pageSizeOptions"
          >
          </mat-paginator>
          <div
            *ngIf="isEnterprisesLoadingResults && !isFirstLoad"
            fxLayout="row"
            fxLayoutAlign="center start"
            class=""
          >
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
