import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class DeliveryRequestContentService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getData(): Observable<any> {
    //this.logger.info("DeliveryRequestContentService.getData()");
    return this.getDeliveryRequestContentList();
  }

  public getDeliveryRequestContentList() {
    //this.logger.info("DeliveryRequestContentService.getDeliveryRequestContentList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.DELIVERY_REQUEST_CONTENT_LIST));
  }
}
