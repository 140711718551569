<div style="max-height: 100%; overflow: auto">
  <div matDialogTitle *ngIf="mode === 'CREATE'" class="dialog-title">
    {{ "SERVICE_CHARGE.TITLE" | translate }}
  </div>
  <div matDialogTitle *ngIf="mode === 'EDIT'" class="dialog-title">
    {{ "SERVICE_CHARGE.EDIT_TITLE" | translate }}
  </div>
  <div fxLayout="column" fxLayoutAlign="center start">
    <!-- (keyup.enter)="(form.valid && !loading && ((mode === 'CREATE')? selectedServiceCharge : true))? onCreateServiceCharge() : null" -->

    <div
      *ngIf="serviceCharge"
      fxLayout="column"
      fxLayoutAlign="center start"
      [formGroup]="form"
      class="item-full-width"
    >
      <span *ngIf="mode === 'CREATE'" class="label-mat">{{
        "SERVICE_CHARGE.SELECT_SERVICE_CHARGE" | translate
      }}</span>
      <select2
        class="mb-8"
        fxFlex="100%"
        style="width: 100%"
        *ngIf="mode === 'CREATE'"
        overlay="true"
        minCountForSearch="0"
        displaySearchStatus="default"
        styleMode="material"
        [data]="serviceChargeSelect2Data"
        [value]="selectedServiceChargeUuid"
        (update)="onServiceChargeChange($event)"
      >
      </select2>

      <!--<app-gt2-select 
      *ngIf="mode === 'CREATE'"
      [service]="serviceChargeService" 
      [placeHolder]="'SERVICE_CHARGE.SELECT_SERVICE_CHARGE' | translate" 
      [label]="''"
      [selectedModel]="serviceCharge.service_charge" 
      (changeEvent)="onServiceChargeChange($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')" 
      [nullableUseNone]="false" 
      fxFlex="100%" class="item-full-width"></app-gt2-select>-->

      <div *ngIf="mode === 'EDIT'" class="my-8 service-charge-name">
        {{ serviceCharge.name }}
      </div>

      <mat-form-field
        [formGroup]="formPrice"
        fxFlex="100%"
        class="item-full-width"
        *ngIf="
          serviceCharge &&
          serviceCharge.multiply_enum !==
            ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
        "
      >
        <mat-label>{{ "GENERIC.PRICE" | translate }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="unit_price"
          autocomplete="nope"
          pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
          [(ngModel)]="serviceCharge.unit_price"
          required
        />
        <!-- ERROR COMMENTED SINCE SERVICE CHARGE CAN BE NEGATIVE NUMBER -->
        <mat-error *ngIf="formPrice.controls['unit_price'].invalid">
          {{ "GENERIC.INPUT_NUMBER" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field
        [formGroup]="formPercent"
        fxFlex="100%"
        class="item-full-width"
        *ngIf="
          serviceCharge &&
          serviceCharge.multiply_enum ===
            ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
        "
      >
        <mat-label>{{ "GENERIC.PERCENTAGE" | translate }}</mat-label>
        <input
          matInput
          formControlName="percent"
          autocomplete="nope"
          type="number"
          [(ngModel)]="serviceCharge.percentage_by"
        />
        <mat-error *ngIf="formPercent.controls['percent'].invalid" class="">
          {{ "GENERIC.INPUT_NUMBER_MINUS_100_100" | translate }}
          {{ "GENERIC.INPUT_NUMBER_NOT_ZERO" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" class="item-full-width">
        <mat-label>{{ "GENERIC.QUANTITY" | translate }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="qty"
          autocomplete="nope"
          pattern="^-?([1-9]\d*)?$"
          [(ngModel)]="serviceCharge.qty"
          required
        />
        <mat-error *ngIf="form.controls['qty'].invalid">
          {{ "GENERIC.INPUT_NUMBER_INTEGER_ONLY" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- check boxes -->

    <!-- [formGroup]="formPercent" -->
    <div
      fxLayout="column"
      class=""
      *ngIf="
        serviceCharge &&
        serviceCharge.multiply_enum ===
          ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
      "
    >
      <mat-checkbox
        labelPosition="after"
        [(ngModel)]="serviceCharge.has_food"
        [ngModelOptions]="{ standalone: true }"
        >{{ "GENERIC.ROYALTY_FOOD" | translate }}
      </mat-checkbox>
      <mat-checkbox
        labelPosition="after"
        [(ngModel)]="serviceCharge.has_beverage"
        [ngModelOptions]="{ standalone: true }"
      >
        {{ "GENERIC.ROYALTY_BEVERAGE" | translate }}
      </mat-checkbox>
      <mat-checkbox
        labelPosition="after"
        [(ngModel)]="serviceCharge.has_material"
        [ngModelOptions]="{ standalone: true }"
      >
        {{ "GENERIC.ROYALTY_MATERIAL" | translate }}
      </mat-checkbox>
      <mat-checkbox
        labelPosition="after"
        [(ngModel)]="serviceCharge.has_service"
        [ngModelOptions]="{ standalone: true }"
        >{{ "GENERIC.ROYALTY_SERVICE" | translate }}
      </mat-checkbox>
      <mat-checkbox
        labelPosition="after"
        [(ngModel)]="serviceCharge.has_delivery"
        [ngModelOptions]="{ standalone: true }"
      >
        {{ "GENERIC.ROYALTY_DELIVERY_FEE" | translate }}
      </mat-checkbox>
      <!--<mat-checkbox labelPosition="after" [(ngModel)]="serviceCharge.has_total_taxes" [ngModelOptions]="{ standalone: true }">
        {{ "GENERIC.ROYALTY_TOTAL_TAXES_INCLUDED_FEE" | translate }}
      </mat-checkbox>-->
    </div>
  </div>

  <div
    *ngIf="
      serviceCharge && serviceCharge.multiply_enum === 'MULTIPLY_BY_DATE_RANGE'
    "
    fxLayout="column"
    fxLayoutAlign="center start"
    [formGroup]="formMultiply"
    class="item-full-width"
  >
    <mat-form-field style="width: 100%" class="mt-16">
      <mat-label>{{ "GENERIC.DATE_START" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="eventDateFrom"
        [(ngModel)]="serviceCharge.multiply_date"
        min="1900-01-01"
        formControlName="date"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="eventDateFrom"
      ></mat-datepicker-toggle>
      <mat-datepicker #eventDateFrom></mat-datepicker>

      <mat-error *ngIf="formMultiply.controls['date'].invalid"
        >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field style="width: 100%" class="mt-16">
      <mat-label>{{ "GENERIC.DATE_END" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="eventDateEnd"
        [(ngModel)]="serviceCharge.multiply_date_end"
        min="1900-01-01"
        formControlName="date_end"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="eventDateEnd"
      ></mat-datepicker-toggle>
      <mat-datepicker #eventDateEnd></mat-datepicker>

      <mat-error *ngIf="formMultiply.controls['date_end'].invalid"
        >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
      </mat-error>
    </mat-form-field>

    <!-- <mat-form-field fxLayout="column" style="width: 100%;" class="mt-16">
    </mat-form-field> -->

    <div fxLayout="row" fxLayoutAlign="start center" class="mt-16">
      <mat-checkbox
        labelPosition="after"
        [(ngModel)]="has_majoration"
        [ngModelOptions]="{ standalone: true }"
        class="mr-12"
      >
        {{ "SERVICE_CHARGE.MAJORATION_FEE" | translate }}
      </mat-checkbox>
      <mat-form-field *ngIf="has_majoration" fxLayout="column">
        <mat-label>{{ "SERVICE_CHARGE.MAJORATION_FEE" | translate }}</mat-label>
        <input
          matInput
          type="text"
          autocomplete="nope"
          formControlName="markup"
          pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
          [(ngModel)]="serviceCharge.markup"
        />
        <mat-error *ngIf="formMultiply.controls['markup'].invalid">
          {{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      *ngIf="mode === 'CREATE'"
      [disabled]="
        !form.valid || !formPrice.valid || selectedServiceChargeUuid === ''
      "
      color="accent"
      class="mr-16"
      (click)="onCreateServiceCharge()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button
      mat-raised-button
      *ngIf="mode === 'EDIT'"
      [disabled]="
        form.invalid ||
        loading ||
        (serviceCharge.multiply_enum ===
          ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES &&
          formPercent.invalid) ||
        (serviceCharge.multiply_enum !==
          ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES &&
          formPrice.invalid) ||
        (serviceCharge.multiply_enum ===
          ServiceChargeMultiplyEnum.MULTIPLY_BY_DATE_RANGE &&
          formMultiply.invalid)
      "
      color="accent"
      class="mr-16"
      (click)="onCreateServiceCharge()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
