import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ApiRoutes } from "../../api/ApiRoutes";
import { Gt2ApiService } from "../../api/gt2-api.service";

@Injectable()
export class AddressService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public updateAddress(data: any, addressUUID: string) {
    //this.logger.info("AddressService.updateAddress() -> contactUUID: " + addressUUID);
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.UPDATE_ADDRESS) + addressUUID, data);
  }
}
