import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { GT2FormPageAbstract } from '../abstract/GT2FormPageAbstract';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { Settings } from '../../settings/settings';
import { SupportContactService } from './support-contact.service';
import { SettingsService } from '../../settings/settings.service';
import { SettingsModel } from '../../settings/SettingsModel';
import { ResponsesStatus } from '../../api/ResponsesStatus';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  subject!: string;
  message!: string;
  setting!: SettingsModel;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    private settings: SettingsService,
    private titleService: Title,
    private supportContactService: SupportContactService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      subject: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      message: [
        '',
        [Validators.minLength(Settings.inputMinChar), Validators.required],
      ],
      // message: ['', [Validators.minLength(Settings.inputMinChar), Validators.maxLength(Settings.inputMaxCharLong), Validators.required]],
    });

    //
    this.settings.loadSettings().subscribe(
      (response) => {
        // this.logger.info('ContactComponent.constructor().settings() -> SUCCESS: ' + JSON.stringify(response));
        if (response.response_status === ResponsesStatus.OK) {
          this.setting = response.response;
        }
      },
      (error) => {
        this.logger.error(
          'ContactComponent.constructor().settings() -> ERRORS: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  ngOnInit() {
    //this.titleService.setTitle(this.translate.instant("CONTACT.CONTACT"));
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onSendContactForm(): void {
    // this.logger.info("ContactComponent.onSendContactForm()");
    const email: any = { subject: this.subject, message: this.message };
    this.supportContactService.postEmail(email).subscribe(
      (response) => {
        // this.logger.info("ContactComponent.onSendContactForm() -> SUCCESS: " + JSON.stringify(response));
        this.showConfirmationDialog();
      },
      (error) => {
        this.logger.error(
          'ContactComponent.onSendContactForm() -> ERROR: ' +
            JSON.stringify(error),
        );
      },
    );
  }

  public showConfirmationDialog(): void {
    this.subject = '';
    this.message = '';
    this.form.markAsUntouched();
    this.form.markAsPristine();

    this.dialogRef = this.dialog.open(GenericAlertComponent, {
      // width: '45%',
      disableClose: false,
    });

    this.dialogRef.componentInstance.alertTitle = '';
    this.dialogRef.componentInstance.alertMessage = this.translate.instant(
      'CONTACT.SUCCESS_MESSAGE',
    );

    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.dialogRef = null;
    });
  }
}
