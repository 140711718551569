import { Injectable } from '@angular/core';
import { UserRole } from '../user/UserRole';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Gt2ApiService } from '../api/gt2-api.service';
import { Observable } from 'rxjs';
import { IGetDataService } from '../components/gt2-select/gt2-select.component';
import { ApiRoutes } from '../api/ApiRoutes';

@Injectable({
  providedIn: 'root',
})
export class RolesService implements IGetDataService {
  GOD_ROLES = [UserRole.GOD];

  ALL_ROLES = [
    UserRole.GOD,
    UserRole.OWNER,
    UserRole.ADMIN,
    UserRole.SALE,
    UserRole.ACCOUNTANT,
    UserRole.HR,
    UserRole.TEAM_LEAD,
    UserRole.EMPLOYEE,
  ];

  ADMIN_ROLES = [UserRole.GOD, UserRole.OWNER, UserRole.ADMIN];

  OWNER_ROLES = [UserRole.GOD, UserRole.OWNER];

  RH_ROLES = [UserRole.GOD, UserRole.OWNER, UserRole.ADMIN, UserRole.HR];

  ACCOUNTANT_ROLES = [
    UserRole.GOD,
    UserRole.OWNER,
    UserRole.ADMIN,
    UserRole.HR,
    UserRole.ACCOUNTANT,
  ];

  TEAM_LEAD_ROLES = [
    UserRole.GOD,
    UserRole.OWNER,
    UserRole.ADMIN,
    UserRole.SALE,
    UserRole.ACCOUNTANT,
    UserRole.HR,
    UserRole.TEAM_LEAD,
    // UserRole.EMPLOYEE
  ];

  EMPLOYEE_ROLES = [
    UserRole.GOD,
    UserRole.OWNER,
    UserRole.ADMIN,
    UserRole.SALE,
    UserRole.ACCOUNTANT,
    UserRole.HR,
    UserRole.TEAM_LEAD,
    UserRole.EMPLOYEE,
  ];

  SALE_ROLES = [
    UserRole.GOD,
    UserRole.OWNER,
    UserRole.ADMIN,
    UserRole.ACCOUNTANT,
    UserRole.HR,
    UserRole.SALE,
  ];

  ALL_STAFF = [
    UserRole.OWNER,
    UserRole.ADMIN,
    UserRole.SALE,
    UserRole.ACCOUNTANT,
    UserRole.HR,
    UserRole.TEAM_LEAD,
    UserRole.EMPLOYEE,
  ];

  NO_OWNERS_STAFF = [
    UserRole.ADMIN,
    UserRole.SALE,
    UserRole.ACCOUNTANT,
    UserRole.HR,
    UserRole.TEAM_LEAD,
    UserRole.EMPLOYEE,
  ];

  TEST_NO_ROLES = [];

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getData(): Observable<any> {
    //this.logger.info("RolesService.getData()");
    return this.getRolesList();
  }

  public getRolesList() {
    //this.logger.info("RolesService.getRolesList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.ROLES));
  }
}
