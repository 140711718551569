<div id="eventDeliveryRequest">
  <div fxLayout="row" fxLayout="start center" class="section-list-header">
    <!-- <div fxFlex="50px" class=""></div> -->
    <div fxFlex="22" class="section-list-item-border-right pl-8 py-4">
      {{ "GENERIC.DATE" | translate }}
    </div>
    <div
      fxFlex="26"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.NAME" | translate }}
    </div>
    <div
      fxFlex="12"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.CONTENT" | translate }}
    </div>
    <div
      fxFlex="12"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.TYPE" | translate }}
    </div>
    <div
      fxFlex="15"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.WRAPPING" | translate }}
    </div>
    <div
      fxFlex="13"
      class="section-list-item section-list-item-border-right py-4"
    >
      Actions
    </div>
  </div>

  <div fusePerfectScrollbar>
    <div
      *ngIf="loading"
      fxLayout="column"
      fxFlex="100%"
      fxLayoutAlign="center center"
      class="item-full-width my-32"
      style="height: 100%"
    >
      <mat-spinner [diameter]="28" class=""></mat-spinner>
    </div>

    <div
      *ngIf="
        !loading &&
        (!event ||
          !event.delivery_requests ||
          event.delivery_requests.length === 0)
      "
      fxFlex="100"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <div class="font-size-20 my-16">
        {{ "EVENTS.NO_DELIVERY_REQUEST" | translate }}
      </div>
    </div>

    <div
      *ngIf="
        event &&
        !loading &&
        event.delivery_requests &&
        event.delivery_requests.length > 0
      "
      fxFlex="100"
    >
      <div
        *ngFor="let item of event.delivery_requests"
        fxLayout="row"
        fxLayout="start center"
        class="child-item"
      >
        <div
          fxFlex="22"
          fxLayout="column"
          fxLayout="start start"
          (click)="onEditDeliveryRequest(item)"
          class="section-list-item-border-right pl-8 py-8"
        >
          <div fxLayout="row">
            <div class="font-weight-500">
              {{ "GENERIC.START" | translate }}:
            </div>
            &nbsp;{{ item.delivery_from_date_label }}
            {{ item.delivery_from_time_label }} &nbsp;
          </div>
          <div fxLayout="row">
            <div class="font-weight-500">{{ "GENERIC.END" | translate }}:</div>
            &nbsp;{{ item.delivery_to_date_label }}
            {{ item.delivery_to_time_label }}
          </div>
        </div>
        <div
          fxFlex="26"
          (click)="onEditDeliveryRequest(item)"
          class="section-list-item section-list-item-border-right py-8"
        >
          <div class="">
            <span class="">{{ item.name }}</span>
          </div>
        </div>
        <div
          fxFlex="12"
          (click)="onEditDeliveryRequest(item)"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.content.name }}
        </div>
        <div
          fxFlex="12"
          (click)="onEditDeliveryRequest(item)"
          class="section-list-item section-list-item-border-right text-truncate py-8"
        >
          <span class="text-truncate px-8">{{ item.type.name }}</span>
        </div>
        <div
          fxFlex="15"
          (click)="onEditDeliveryRequest(item)"
          class="section-list-item section-list-item-border-right text-truncate py-8"
        >
          <span class="text-truncate px-8">{{
            lang === "fr-CA"
              ? item.wrapping.name_local.name_fr_CA
              : item.wrapping.name_local.name_en_CA
          }}</span>
        </div>
        <div fxFlex="13" class="section-list-item">
          <button
            class=""
            mat-icon-button
            [disabled]="!editable"
            (click)="onEditDeliveryRequest(item)"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("EDIT")
            }}</mat-icon>
          </button>
          <button
            class=""
            mat-icon-button
            [disabled]="!editable"
            (click)="onDeleteDeliveryRequest(item)"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("DELETE")
            }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
