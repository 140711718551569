<ng-template #visitHintTemplate>
  <p>{{ "SHOPS.VISIT" | translate }}</p>
</ng-template>

<ng-template #storeUrlHintTemplate>
  <p>{{ "SHOPS.STORE_URL_HINT" | translate }}</p>
  <!-- L’url de la commande en ligne une fois intégré au site du client (optionnel) -->
</ng-template>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
    <div class="title">{{ "GENERIC.NAME" | translate }}</div>
    <div class="info text-truncate">{{ shop.name }}</div>
    <div *ngIf="!shop.name" class="info empty-value"></div>
  </div>
  <div *ngIf="editMode" class="info-line" fxFlex="100%">
    <div class="info-editable">
      <div [formGroup]="form" class="">
        <mat-form-field class="form-input-container" fxFlex="100%">
          <mat-label>{{ "GENERIC.INTERNAL_NAME" | translate }}</mat-label>
          <input
            [(ngModel)]="shop.name"
            autocomplete="nope"
            formControlName="name"
            matInput
            placeholder="{{ 'GENERIC.INTERNAL_NAME' | translate }}"
            required
            type="text"
          />
          <mat-error *ngIf="form.controls['name'].invalid"
            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div>&nbsp;</div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
    <div class="title">
      {{ "SHOPS.STORE_URL" | translate }}
      <button
        class="mb-4"
        mat-icon-button
        matTooltip="storeUrlHintTemplate"
        content-type="template"
        type="button"
      >
        <mat-icon class="s-20" color="">help</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="text-truncate">{{ shop.shop_web.store_url }}</div>
      <mat-icon
        *ngIf="shop.shop_web.store_url"
        matTooltip="visitHintTemplate"
        content-type="template"
        style="margin-left: 10px; cursor: pointer"
        (click)="openURL(shop.shop_web.store_url)"
        color="accent"
        >link
      </mat-icon>
    </div>

    <div *ngIf="!shop.shop_web.store_url" class="info empty-value"></div>
  </div>

  <div *ngIf="editMode" class="info-line" fxFlex="100%">
    <div [formGroup]="form" class="">
      <mat-form-field class="form-input-container" fxFlex="100%">
        <mat-label>{{ "SHOPS.STORE_URL" | translate }}</mat-label>
        <input
          [(ngModel)]="shop.shop_web.store_url"
          autocomplete="nope"
          formControlName="store_url"
          matInput
          placeholder="{{ 'SHOPS.STORE_URL' | translate }}"
          type="text"
        />
        <mat-icon
          matSuffix
          *ngIf="shop.shop_web.store_url && shop.shop_web.store_url.length > 0"
          style="cursor: pointer"
          matTooltip="visitHintTemplate"
          content-type="template"
          (click)="openURL(shop.shop_web.store_url)"
          color="accent"
          >link
        </mat-icon>
        <mat-icon
          matSuffix
          style="opacity: 0.25"
          *ngIf="
            !shop.shop_web.store_url || shop.shop_web.store_url.length === 0
          "
          matTooltip="visitHintTemplate"
          content-type="template"
          color="disabled"
        >
          link</mat-icon
        >
      </mat-form-field>
    </div>
  </div>
</div>

<!-- MIN AMOUNT TAKEOUT / DELIVERY -->
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
    <div class="title">{{ "SHOPS.MIN_TO_ORDER" | translate }}</div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="info text-truncate">
        {{ shop.min_subtotal_delivery_label }}
      </div>
    </div>
    <div
      *ngIf="!shop.min_subtotal_delivery_label"
      class="info empty-value"
    ></div>
  </div>

  <div *ngIf="editMode" class="info-line" fxFlex="100%">
    <div [formGroup]="form" class="">
      <mat-form-field class="form-input-container" fxFlex="100%">
        <mat-label>{{ "SHOPS.MIN_TO_ORDER" | translate }}</mat-label>
        <input
          [(ngModel)]="shop.min_subtotal_delivery"
          autocomplete="nope"
          formControlName="min_subtotal_delivery"
          matInput
          pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
          placeholder="{{ 'SHOPS.MIN_TO_ORDER' | translate }}"
          type="text"
        />
        <span matPrefix>$&nbsp;</span>
      </mat-form-field>
    </div>
  </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
    <div class="title">{{ "SHOPS.MIN_TO_TAKEOUT" | translate }}</div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="info text-truncate">
        {{ shop.min_subtotal_takeout_label }}
      </div>
    </div>
    <div
      *ngIf="!shop.min_subtotal_takeout_label"
      class="info empty-value"
    ></div>
  </div>

  <div *ngIf="editMode" class="info-line" fxFlex="100%">
    <div [formGroup]="form" class="">
      <mat-form-field class="form-input-container" fxFlex="100%">
        <mat-label>{{ "SHOPS.MIN_TO_TAKEOUT" | translate }}</mat-label>
        <input
          [(ngModel)]="shop.min_subtotal_takeout"
          autocomplete="nope"
          formControlName="min_subtotal_takeout"
          matInput
          pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
          placeholder="{{ 'SHOPS.MIN_TO_TAKEOUT' | translate }}"
          type="text"
        />
        <span matPrefix>$&nbsp;</span>
      </mat-form-field>
    </div>
  </div>
</div>

<!-- APPLICATION FEE -->
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
    <div class="title">{{ "SHOPS.APPLICATION_FEE" | translate }}</div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div *ngIf="shop.apply_application_fees" class="info">
        {{ "GENERIC.YES" | translate }}
      </div>
      <div *ngIf="!shop.apply_application_fees" class="info">
        {{ "GENERIC.NO" | translate }}
      </div>
    </div>
    <!-- <div *ngIf="!shop.shop.apply_application_fees"
                    class="info empty-value"></div> -->
  </div>

  <ng-template #applicationFeeHintTemplate>
    <p>{{ "SHOPS.APPLICATION_FEE_HINT" | translate }}</p>
  </ng-template>

  <div *ngIf="editMode" class="info-line" fxFlex="100%">
    <div [formGroup]="form" class="">
      <!-- apply_application_fees -->
      <mat-checkbox
        labelPosition="after"
        color="accent"
        [(ngModel)]="shop.apply_application_fees"
        [ngModelOptions]="{ standalone: true }"
        class=""
      >
        {{ "SHOPS.APPLICATION_FEE" | translate }}
        <button
          class="mb-4"
          mat-icon-button
          matTooltip="applicationFeeHintTemplate"
          content-type="template"
          type="button"
        >
          <mat-icon class="s-20" color="">help</mat-icon>
        </button>
      </mat-checkbox>
    </div>
  </div>
</div>

<!-- DESCRIPTION FR / EN -->
<div class="info-line">
  <div class="title-big mt-12">{{ "SHOPS.DESCRIPTION" | translate }}</div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div class="info-line" fxFlex="100%">
      <div class="title">{{ "GENERIC.fr-CA" | translate }}</div>
      <app-rich-text-locale
        [(richText)]="shop.description_local"
        [localKey]="'description_fr_CA'"
        [applyFocus]="true"
        [maxLength]="2048"
        [editMode]="editMode"
        [showClearButton]="false"
        [showSaveButton]="false"
      >
      </app-rich-text-locale>

      <div class="title mt-16">{{ "GENERIC.en-CA" | translate }}</div>
      <app-rich-text-locale
        [(richText)]="shop.description_local"
        [localKey]="'description_en_CA'"
        [maxLength]="2048"
        [editMode]="editMode"
        [showClearButton]="false"
        [showSaveButton]="false"
      >
      </app-rich-text-locale>
    </div>
  </div>
</div>

<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="start start"
  class="mt-4"
>
  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
    <div class="title">{{ "SHOPS.SHOW_SWITCH_LOCALE" | translate }}</div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div *ngIf="shop.show_switch_locale" class="info">
        {{ "GENERIC.YES" | translate }}
      </div>
      <div *ngIf="!shop.show_switch_locale" class="info">
        {{ "GENERIC.NO" | translate }}
      </div>
    </div>
    <div *ngIf="!shop.theme_mode" class="info empty-value"></div>
  </div>

  <div *ngIf="editMode" class="info-line" fxFlex="100%">
    <div class="title">{{ "SHOPS.SHOW_SWITCH_LOCALE" | translate }}</div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-slide-toggle
        labelPosition="before"
        class="mat-body"
        [ngModel]="shop.show_switch_locale"
        (click)="$event.stopPropagation()"
        (change)="onSwitchLocale($event)"
      >
      </mat-slide-toggle>
      <div
        *ngIf="shop.show_switch_locale"
        class="info"
        style="margin-left: 10px"
      >
        {{ "SHOPS.BILINGUAL" | translate }}
      </div>
      <div
        *ngIf="!shop.show_switch_locale"
        class="info"
        style="margin-left: 10px"
      >
        {{ "SHOPS.FRENCH_ONLY" | translate }}
      </div>
    </div>
  </div>
</div>

<!-- GOOGLE ANALYTICS -->
<!--
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="mt-4">
            <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ 'SHOPS.GA_KEY' | translate }}</div>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="info text-truncate">{{shop.google_ga_caterer_shop}}</div>
                </div>
                <div *ngIf="!shop.google_ga_caterer_shop" class="info empty-value"></div>
            </div>

            <div *ngIf="editMode" class="info-line" fxFlex="100%">
                <div [formGroup]="form" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                        <input [(ngModel)]="shop.google_ga_caterer_shop" autocomplete="nope"
                            formControlName="google_ga_caterer_shop" matInput
                            placeholder="{{ 'SHOPS.GA_KEY' | translate }}" type="text">
                    </mat-form-field>
                </div>
            </div>
        </div>
        -->

<!-- SHOW ON HOME -->
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
    <div class="title">
      {{ "SHOPS.SHOW_ON_HOME" | translate }}
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div *ngIf="shop.show_on_home" class="info text-truncate">
        {{ "GENERIC.YES" | translate }}
      </div>
      <div *ngIf="shop.show_on_home === false" class="info text-truncate">
        {{ "GENERIC.NO" | translate }}
      </div>
    </div>
    <div *ngIf="shop.show_on_home === null" class="info empty-value"></div>
  </div>

  <div *ngIf="editMode" class="info-line" fxFlex="100%">
    <div class="title">{{ "SHOPS.SHOW_ON_HOME" | translate }}</div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div style="margin-right: 8px">
        {{ "GENERIC.OFF" | translate | uppercase }}
      </div>
      <mat-slide-toggle class="mat-accent" [(ngModel)]="shop.show_on_home">
      </mat-slide-toggle>
      <div style="margin-left: 8px">
        {{ "GENERIC.ON" | translate | uppercase }}
      </div>
    </div>
  </div>
</div>

<!-- REDIRECT URL -->
<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="start start"
  class="mt-4"
>
  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
    <div class="title">{{ "SHOPS.REDIRECT_ULR" | translate }}</div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="info text-truncate">{{ shop.redirect_url }}</div>
    </div>
    <div *ngIf="!shop.redirect_url" class="info empty-value"></div>
  </div>

  <div *ngIf="editMode" class="info-line" fxFlex="100%">
    <div [formGroup]="form" class="">
      <mat-form-field class="form-input-container" fxFlex="100%">
        <mat-label>{{ "SHOPS.REDIRECT_ULR" | translate }}</mat-label>
        <input
          [(ngModel)]="shop.redirect_url"
          autocomplete="nope"
          formControlName="redirect_url"
          matInput
          placeholder="{{ 'SHOPS.REDIRECT_ULR' | translate }}"
          type="text"
        />
      </mat-form-field>
    </div>
  </div>
</div>

<!-- SAVE -->
<div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start">
  <button
    (click)="onSaveInfo()"
    color="accent"
    class="save-btn"
    [disabled]="false"
    mat-raised-button
  >
    {{ "GENERIC.SAVE" | translate }}
  </button>
</div>
