export class NavigationFactory {
  static create(navigation: any, include: any[]): any {
    let nav = JSON.parse(JSON.stringify(navigation));

    // REMOVE GOD AND DEV NAV MENU IF PRESENT
    nav = nav.filter((item: any) => {
      return item.id !== 'godMenu' && item.id !== 'devMenu';
    });

    nav[0].children = NavigationFactory.include(nav[0].children, include);

    // sort on order provided by the include array
    nav[0].children = nav[0].children.sort((a: any, b: any) => {
      const indexA = include.findIndex((obj) => obj.id === a.id);
      const indexB = include.findIndex((obj) => obj.id === b.id);
      return indexA - indexB;
    });
    return nav;
  }

  static createAndExclude(navigation: any, excludes?: any[]): any {
    let nav = JSON.parse(JSON.stringify(navigation));

    // REMOVE GOD AND DEV NAV MENU IF PRESENT
    nav = nav.filter((item: any) => {
      return item.id !== 'godMenu' && item.id !== 'devMenu';
    });

    // exclude navigation items
    if (excludes) {
      nav[0].children = NavigationFactory.exclude(nav[0].children, excludes);
    }

    return nav;
  }

  static include(children: any[], includes: any): any {
    return children.filter((item) => {
      for (const include of includes) {
        if (item.id === include.id) {
          if (include.children && include.children.length > 0) {
            item.children = NavigationFactory.include(
              item.children,
              include.children
            );
          }
          return true;
        }
      }
      return false;
    });
  }

  static exclude(children: any[], excludes: any): any {
    return children.filter((item) => {
      for (const exclude of excludes) {
        if (item.id === exclude.id) {
          if (!exclude.children || exclude.children.length === 0) {
            // the navigation item is in the exclude list and does not have children like a submenu can (is not a submenu like support for example)
            // If you want to disabled a submenu entirely and not just one or more of his children, don't declared a children array
            return false;
          } else {
            // the navigation item is in the exclude list and does have children, so we want to keep the parents and do an exclude pass on the children
            item.children = NavigationFactory.exclude(
              item.children,
              exclude.children
            );
          }
        }
      }

      return true;
    });
  }
}
