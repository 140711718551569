<div style="max-height: 100%">
  <h1 class="h1-title" matDialogTitle>
    {{ "CONGRESSES.CREATE_PROJECT_TITLE" | translate }}
  </h1>

  <div
    fxLayout="column"
    fxLayoutAlign="center start"
    [formGroup]="form"
    (keyup.enter)="form.valid && !loading ? onCreateProject() : null"
    class="mt-8 create-client-width"
  >
    <mat-form-field fxFlex="100%" class="item-full-width">
      <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
      <input
        matInput
        fxFlex="100%"
        placeholder="{{ 'GENERIC.NAME' | translate }}"
        formControlName="name"
        autocomplete="nope"
        type="text"
        [(ngModel)]="project.name"
        required
      />
      <mat-error *ngIf="form.controls['name'].invalid">{{
        "GENERIC.INPUT_LENGTH_INVALID" | translate
      }}</mat-error>
    </mat-form-field>

    <div [formGroup]="form" fxFlex="100%" class="item-full-width">
      <app-gt2-select
        [service]="conseillersService"
        [placeHolder]="'GENERIC.SELECT_RESPONSIBLE_EMPLOYEE' | translate"
        [label]="'Personnal title'"
        (changeEvent)="onResponsibleEmployeeSelected($event)"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
        [nullableUseNone]="false"
        class=""
      >
      </app-gt2-select>
    </div>

    <mat-form-field class="item-full-width">
      <mat-label>{{ "GENERIC.DATE_START" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="date_start"
        [(ngModel)]="project.date_start"
        min="1900-01-01"
        placeholder="{{ 'GENERIC.DATE_START' | translate }}"
        formControlName="date_start"
        required
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="date_start"
      ></mat-datepicker-toggle>
      <mat-datepicker #date_start></mat-datepicker>
      <mat-error *ngIf="form.controls['date_start'].invalid"
        >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="100%" class="item-full-width">
      <mat-label>{{ "GENERIC.DATE_END" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="date_end"
        [(ngModel)]="project.date_end"
        min="1900-01-01"
        placeholder="{{ 'GENERIC.DATE_END' | translate }}"
        formControlName="date_end"
      />
      <mat-datepicker-toggle matSuffix [for]="date_end"></mat-datepicker-toggle>
      <mat-datepicker #date_end></mat-datepicker>
      <mat-error *ngIf="form.controls['date_end'].invalid"
        >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!form.valid || loading"
      color="accent"
      class="mr-16"
      (click)="onCreateProject()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
