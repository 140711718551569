import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class DepositInService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getData(): Observable<any> {
    //this.logger.info("DepositInService.getData()");
    return this.getDepositInList();
  }

  public getDepositInList() {
    //this.logger.info("DepositInService.getDepositInList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.DEPOSIT_IN_LIST));
  }

  public createDepositInRequest(deposit: any, eventUUID: string) {
    //this.logger.info("DepositInService.createDepositInRequest() -> " + JSON.stringify(deposit));
    //this.logger.info("DepositInService.createDepositInRequest() -> eventUUID: " + eventUUID);
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT) + "cashings/" + eventUUID, deposit);
  }

  public deleteDepositInRequest(deposit: any, eventUUID: string) {
    //this.logger.info("DepositInService.deleteDepositInRequest() -> " + JSON.stringify(deposit));
    //this.logger.info("DepositInService.deleteDepositInRequest() -> eventUUID: " + eventUUID);
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.EVENT) + "cashings/" + eventUUID + "/" + deposit.uuid);
  }

  public updateDepositInRequest(deposit: any, eventUUID: string) {
    //this.logger.info("DepositInService.updateDepositInRequest() -> " + JSON.stringify(deposit));
    //this.logger.info("DepositInService.updateDepositInRequest() -> eventUUID: " + eventUUID);
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.EVENT) + "cashings/" + eventUUID + "/" + deposit.uuid, deposit);
  }
}
