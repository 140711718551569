import { MatDialog } from '@angular/material/dialog';
import { TestErrorService } from './gt2/api/test-error.service';
import { Platform } from '@angular/cdk/platform';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  NavigationEnd,
  Router,
  NavigationStart,
  ActivatedRoute,
} from '@angular/router';
import { FuseNavigationService } from '../@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '../@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '../@fuse/services/config.service';
import { FuseSplashScreenService } from '../@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '../@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { navigation, navigationEn } from './navigation/navigation';
import { BROWSERS, DeviceDetectorService } from 'ngx-device-detector';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from './gt2/api/auth-service.service';
import { EmployeeModelData } from './gt2/api/models/CrudEmployeeModel';
import { ResponsesStatus } from './gt2/api/ResponsesStatus';
import { UserSettingsService } from './gt2/api/user-settings.service';
import { TopAlertModel } from './gt2/components/top-alerts/top-alerts.component';
import { AppRoutes } from './gt2/const/AppRoutes';
import { GT2ConstantsEnum } from './gt2/const/GT2ConstantsEnum';
import { GT2PageAbstract } from './gt2/content/abstract/GT2PageAbstract';
import { CrudEmployeeService } from './gt2/content/crud/crud-employee/crud-employee.service';
import { CrudOrganizationService } from './gt2/content/crud/crud-organization/crud-organization.service';
import { JwtInterceptor } from './gt2/interceptors/JwtInterceptor';
import { SettingsService } from './gt2/settings/settings.service';
import { ValidatorHelper } from './gt2/validators/ValidatorHelper';
import { locale as genericEnglish } from './localization/i18n/en-CA';
import { locale as genericFrench } from './localization/i18n/fr-CA';
import {
  LanguageModel,
  LocalizationConfig,
} from './localization/LocalizationConfig';
import { locale as navigationEnglish } from './navigation/i18n/en-CA';
import { locale as navigationFrench } from './navigation/i18n/fr-CA';
import { DevGroupNavigationModel } from './navigation/restricted-group/dev-group.navigation.model';
import { GodGroupNavigationModel } from './navigation/restricted-group/god-group.navigation.model';
import { ModulesService } from './gt2/services/modules.service';
import { Title } from '@angular/platform-browser';
import { RolesService } from './gt2/services/roles.service';
import { CatererService } from './gt2/content/caterer/caterer.service';
import { NavigationFactory } from './navigation/NavigationFactory';
import { Gt2ApiService } from './gt2/api/gt2-api.service';
import { TestErrorResultComponent } from './gt2/components/dialogs/test-error-result/test-error-result.component';
import { GtTools } from './gt2/directives/gt-clients.directive';
import { FuseProgressBarModule } from '../@fuse/components/progress-bar/progress-bar.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LayoutModule } from './layout/layout.module';
import { FuseSidebarModule } from '../@fuse/components/sidebar/sidebar.module';
import { FuseThemeOptionsModule } from '../@fuse/components/theme-options/theme-options.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FirebaseProcessComponent } from './gt2/components/firebase-process/firebase-process.component';
import { HorizontalLayout1Component } from './layout/horizontal/layout-1/layout-1.component';
import { VerticalLayout1Component } from './layout/vertical/layout-1/layout-1.component';
import { VerticalLayout2Component } from './layout/vertical/layout-2/layout-2.component';
import { VerticalLayout3Component } from './layout/vertical/layout-3/layout-3.component';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FuseProgressBarModule,
    NgxSpinnerModule,
    MatButtonModule,
    MatInputModule,
    VerticalLayout1Component,
    VerticalLayout2Component,
    VerticalLayout3Component,
    LayoutModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    HorizontalLayout1Component,
    FirebaseProcessComponent,
    FuseSidebarModule,
    FuseThemeOptionsModule,
  ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  dialogRef: any;
  topAlertModelUser!: TopAlertModel | null;
  topAlertModelSettings!: TopAlertModel | null;
  topAlertModelCaterer!: TopAlertModel | null;

  pingInterval: any;
  innerWidth: any;
  doPing: boolean = false;

  // @ViewChild('firebaseProcessComponent') firebaseProcessComponent: FirebaseProcessComponent;

  // Private
  private _unsubscribeAll: Subject<any>;
  private _bottomSheetRef: any;

  /*
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   * @param {AuthenticationService} auth
   * @param {Router} router
   * @param {MatDialog} dialog
   * @param {CrudEmployeeService} employeeService
   * @param {CrudOrganizationService} orgService
   * @param {NgxPermissionsService} permissionsService
   * @param {SettingsService} settings
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private auth: AuthenticationService,
    private router: Router,
    private employeeService: CrudEmployeeService,
    private orgService: CrudOrganizationService,
    private permissionsService: NgxPermissionsService,
    private rolesService: RolesService,
    private appSettings: SettingsService,
    public userSettingsService: UserSettingsService,
    private logger: NGXLogger,
    private titleService: Title,
    private translate: TranslateService,
    private modulesService: ModulesService,
    private deviceService: DeviceDetectorService,
    private catererService: CatererService,
    private api: Gt2ApiService,
    private testErrorService: TestErrorService,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
  ) {
    // --o METRICS: Google Analytics, HotJar
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // this.logger.info("NavigationEnd -> Google Analytic tracking - url: " + event.urlAfterRedirects);
      }
      if (event instanceof NavigationStart) {
        // this.logger.info("NavigationStart -> ");
        this.titleService.setTitle(this.translate.instant('APP.TITLE'));
      }
    });
    //
    // init
    GtTools.client.init(modulesService);

    // Add languages
    this._translateService.addLangs(['en-CA', 'fr-CA']);

    // Set the default language
    // this._translateService.setDefaultLang('en');
    this._translateService.setDefaultLang(LocalizationConfig.DEFAULT_LANGUAGE);

    // Set the navigation translations
    // Set the navigation and generic translations
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationFrench,
    );
    this._fuseTranslationLoaderService.loadTranslations(
      genericEnglish,
      genericFrench,
    );

    // Use a language
    const langModel: LanguageModel = LocalizationConfig.getCurrentLanguage();
    this._translateService.use(langModel.id);

    // Get default navigation copy
    if (langModel.id !== 'fr-CA') {
      this.navigation = JSON.parse(JSON.stringify(navigationEn));
    } else {
      this.navigation = JSON.parse(JSON.stringify(navigation));
    }

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      // Keeping this line just in case.
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    //
    const isMobile: boolean = this.deviceService.isMobile();
    JwtInterceptor.IS_MOBILE = isMobile;

    const browser: string = this.deviceService.browser.toLowerCase();
    const browserIsSupported: boolean =
      browser !== BROWSERS.IE && browser !== BROWSERS.OPERA;
    JwtInterceptor.BROSWER_SUPPORTED = browserIsSupported;
    // this.logger.info("BROWSER: " + browser);
    this.innerWidth = window.innerWidth;

    JwtInterceptor.IS_MOBILE = false;
    this.setUpGT2App();

    /*if (isMobile && this.innerWidth < 800) {
      // this.logger.info("PLATFORM: IS MOBILE");
      // this.logger.info(window.innerWidth);
      if (this.api.isDemo === false) {
        this.router.navigateByUrl("/" + AppRoutes.DEVICE_NOT_SUPPORTED);
      }
      // this._fuseSplashScreenService.hide();
    } else if (!browserIsSupported) {
      //this.logger.info("BROWSER NOT SUPPORTED");
      this.router.navigateByUrl(AppRoutes.BROWSER_NOT_SUPPORTED);
      // this._fuseSplashScreenService.hide();
    } else {
      JwtInterceptor.IS_MOBILE = false;
      // this.logger.info("PLATFORM: IS NOT MOBILE (I REPEAT, NOT MOBILE)");
      // this.getExternalEnv();
      this.setUpGT2App();
    }*/

    //
    this.testErrorService.onTestFromRequest.subscribe(
      (testFromRequestResult) => {
        if (testFromRequestResult) {
          /* this.logger.info(
                        "AppComponent.constructor().testErrorService() -> testFromRequestResult:  " +
                            JSON.stringify(testFromRequestResult)
                    ); */

          //
          this.dialogRef = this.dialog.open(TestErrorResultComponent, {
            width: '96vw',
            maxHeight: '98vh',
            minWidth: 350,
            disableClose: false,
            panelClass: 'scrollable-dialog',
            data: {
              testFromRequestResult: testFromRequestResult,
            },
          });

          this.dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              // this.logger.info("POP UP Closed!");
            }
            this.dialogRef = null;
          });
        }
      },
    );
  }

  public setUpGT2App(): void {
    // this.logger.info("AppComponent.constructor() -> isLoggedIn:  " + this.auth.isLoggedIn());
    this.employeeService.employee.subscribe((employee: any) => {
      if (employee) {
        // this.logger.info("AppComponent.constructor() -> EMPLOYEE:  " + JSON.stringify(employee));
        /* this.logger.info(
                    "AppComponent.constructor() -> MY EMPLOYEE UUID:  " +
                        employee.uuid
                ); */
        // this.logger.info("AppComponent.constructor() -> MY USER UUID:  " + employee.user.uuid);
        // this.logger.info("AppComponent.constructor() -> employee:  " + JSON.stringify(employee));
        this.topAlertModelUser = employee.top_alert;

        // override the current to use of LocalizationConfig.getCurrentLanguage() once the employee is loaded.
        // The employee local set in the Database has the priority.
        if (employee.employee_locale) {
          this._translateService.use(employee.employee_locale);
          // this.logger.info("AppComponent.constructor() -> employee loaded, lang set :  " + this._translateService.currentLang);
        }

        // navigationModel = new FuseNavigationModel();

        // MODULES MENU
        // TODO: plug back the modules available
        // navigationModel.model.push(new AppModulesGroupNavigationModel(employee.user.appModules));

        // employee.user.role = "SALE";
        /////////////////////
        // PERMISSIONS PLUGIN
        /* this.logger.info(
                    "AppComponent.constructor() -> employee.user.role: " +
                        employee.user.role
                ); */
        const perm = [];
        perm.push(employee.user.role);
        GT2PageAbstract.USER_ROLE = employee.user.role;

        //
        const langModel: LanguageModel =
          LocalizationConfig.getCurrentLanguage();

        if (langModel.id !== 'fr-CA') {
          this.navigation = NavigationFactory.create(
            navigationEn,
            employee.navigation_menu,
          );
        } else {
          this.navigation = NavigationFactory.create(
            navigation,
            employee.navigation_menu,
          );
        }
        // this.logger.info('AppComponent.constructor() -> navigation_menu: ' + JSON.stringify(employee.navigation_menu) );
        // this.logger.info('AppComponent.constructor() -> employee.user.role: ' + employee.user.role );

        // sales AND HIGHER CAN SEE EVERYTHING
        if (this.rolesService.RH_ROLES.indexOf(employee.user.role) !== -1) {
          // this.logger.info("AppComponent.constructor() -> LEFT MENU: HAS ALL PERMISSSION!");
        } else if (
          this.rolesService.ACCOUNTANT_ROLES.indexOf(employee.user.role) !== -1
        ) {
          // const newNav = this.navigation[0].children.filter(
          //     (item) => {
          //         return item.id !== "employees";
          //     }
          // );
          // this.navigation[0].children = newNav;
          // this.logger.info("AppComponent.constructor() -> ACCOUNTANT_ROLES newNav: " + JSON.stringify(newNav));
        } else if (
          this.rolesService.SALE_ROLES.indexOf(employee.user.role) !== -1
        ) {
          const newNav = this.navigation[0].children.filter((item: any) => {
            return (
              // item.id !== "employees" &&
              item.id !== 'reports' && item.id !== 'settings'
            );
          });
          this.navigation[0].children = newNav;
          // this.logger.info("AppComponent.constructor() -> SALE_ROLES newNav: " + JSON.stringify(newNav));
        } else if (
          this.rolesService.TEAM_LEAD_ROLES.indexOf(employee.user.role) !== -1
        ) {
          const newNav = this.navigation[0].children.filter((item: any) => {
            return (
              item.id !== 'employees' &&
              item.id !== 'reports' &&
              item.id !== 'settings' &&
              item.id !== 'venues' &&
              item.id !== 'clients'
            );
          });
          this.navigation[0].children = newNav;
          // this.logger.info("AppComponent.constructor() -> TEAM_LEAD_ROLES newNav: " + JSON.stringify(newNav));
        } else {
          const newNav = this.navigation[0].children.filter((item: any) => {
            return (
              item.id !== 'employees' &&
              item.id !== 'reports' &&
              item.id !== 'settings' &&
              item.id !== 'venues' &&
              item.id !== 'clients' &&
              item.id !== 'products' &&
              //item.id !== "upsale" &&
              item.id !== 'materials'
            );

            // TODO: is management missing ????
          });
          this.navigation[0].children = newNav;
          //this.logger.info("AppComponent.constructor() -> EMPLOYEE_ROLES newNav: " + JSON.stringify(newNav));
        }
        //
        // --o
        if (
          this.modulesService.hasCTReport() &&
          !this.rolesService.OWNER_ROLES.includes(employee.user.role)
        ) {
          const newNav = this.navigation[0].children.filter((item: any) => {
            return item.id !== 'reports';
          });
          this.navigation[0].children = newNav;
        }

        //
        // this.logger.info('AppComponent.constructor() -> this.navigation[0].children: ' + JSON.stringify(this.navigation[0].children));
        // this.logger.info("AppComponent.constructor() -> EMPLOYEE_ROLES: " + employee.user.role);
        if (employee.user.role === GT2ConstantsEnum.GOD) {
          this.navigation.push(new GodGroupNavigationModel());
          this.navigation.push(new DevGroupNavigationModel());
          // this.navigation.push(new AdminGroupNavigationModel());
          // this.logger.info(
          //     "AppComponent.constructor() -> EMPLOYEE_ROLES GOD newNav: " +
          //         JSON.stringify(this.navigation[0].children)
          // );
        }

        // Register the navigation to the service
        this._fuseNavigationService.unregister('main');
        this._fuseNavigationService.register('main', this.navigation);
        this._fuseNavigationService.setCurrentNavigation('main');

        this.permissionsService.loadPermissions(perm);
        // this.logger.info("AppComponent.constructor() -> PERMISSIONS: " + JSON.stringify(this.permissionsService.getPermissions()));

        // --o reset language
        // const langModel: LanguageModel = LocalizationConfig.getCurrentLanguage();
        this._translateService.setDefaultLang(
          LocalizationConfig.DEFAULT_LANGUAGE,
        );
        this._translateService.use(LocalizationConfig.getCurrentLanguage().id);

        //
        this.loadStartingData(employee);
        // this.logger.info("app.component -> 1. ***** HIDE SPLASH SCREEN *****");
        this._fuseSplashScreenService.hide();
      }
    });

    // load app settings
    this.appSettings
      .loadSettings()
      .pipe(
        tap((response) => {
          // this.logger.info('AppComponent.constructor().settings() -> SUCCESS: ' + JSON.stringify(response));
          if (response.response_status === ResponsesStatus.OK) {
            LocalizationConfig.SUPPORTED_LANGUAGES =
              response.response.supportedLanguagesSimple;
            LocalizationConfig.LANGUAGES = response.response.supportedLanguages;
            this.doPing = response.response.do_ping;
            this.topAlertModelSettings = response.response.top_alert;
          }
        }),
        catchError((error: any): any => {
          this.logger.error(
            'AppComponent.constructor().settings() -> ERRORS: ' +
              JSON.stringify(error),
          );
          if (
            error.status === 500 ||
            error.status === 503 ||
            error.status === 404 ||
            error.status === 429
          ) {
            // Errors 400, 429, 500 and 503 (maintenance), we hide the splashscreen so we can see the error page
            this.logger.error(
              'AppComponent.constructor().settings() -> ERROR 503!!!! ',
            );
            this._fuseSplashScreenService.hide();
          }
        }),
      )
      .subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Replace the native minLength validator with a custom one (also validate string with trim())
    Validators.minLength = ValidatorHelper.minLength;

    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: any) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }
        let colorCache = localStorage.getItem('theme');
        let currentTheme =
          colorCache === 'theme-blue-gray-dark' ||
          colorCache === 'theme-default'
            ? colorCache
            : 'theme-default';
        localStorage.setItem('theme', currentTheme);

        this.document.body.classList.add(currentTheme);
      });

    // --o PING
    // this.pingInterval = setInterval(() => {
    //     if (this.doPing) {
    //         this.appSettings.doPing().subscribe(
    //             (response) => {
    //                 // this.logger.info("AppComponent.doPing() -> SUCCESS: " + JSON.stringify(response));
    //             },
    //             (error) => {
    //                 this.logger.error(
    //                     "AppComponent.doPing() -> Error: " +
    //                         JSON.stringify(error)
    //                 );
    //             }
    //         );
    //     }
    // }, 1000 * 60 * 30);

    this.auth.logoutSubject.subscribe((value) => {
      this.topAlertModelUser = null;
      this.topAlertModelSettings = null;
      this.topAlertModelCaterer = null;
    });

    document.body.className = localStorage.getItem('theme') || 'theme-default';
  }

  ngAfterViewInit(): void {
    //this.initGoogleAnalyticsPageView();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  @HostListener('window:keydown.control.k', ['$event'])
  openSearch(event: KeyboardEvent) {
    event.preventDefault();
  }
  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key: any): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  public loadStartingData(employee: EmployeeModelData): void {
    this.orgService.loadCatererMe().subscribe(
      (response) => {
        this.topAlertModelCaterer = response.data.top_alert;
        this.catererService.setCaterer(response.data);
        // this.logger.info("AppComponent.loadStartingData().loadCatererMe() -> this.topAlertModelCaterer: " + JSON.stringify(this.topAlertModelCaterer));
      },
      (error) => {
        // don't show long error message in console if just a 401
        if (error.status === 401) {
          //this.logger.info("AppComponent.loadStartingData().loadCatererMe() -> Error: 401 - Unauthenticated!");
        } else {
          this.logger.error(
            'AppComponent.loadStartingData().loadCatererMe() -> Error: ' +
              JSON.stringify(error),
          );
        }
      },
    );

    // this.logger.info("AppComponent.loadStartingData().loadOrganization() ->  ABOUT TO LOAD ORGANIZATION");
    this.orgService.loadOrganization().subscribe((organizations) => {
      this.modulesService.setModules(organizations.data.appModules);

      //
    });

    this.modulesService.modulesSet.subscribe((data) => {
      if (data) {
        // REMOVE BUTTONS FROM MENU IF THE ORG DOES NOT HAVE THE CORRESPONDANT MODULES
        if (!this.modulesService.hasShopWeb()) {
          const newNav = this.navigation[0].children.filter((item: any) => {
            return item.id !== 'shops';
          });
          this.navigation[0].children = newNav;
        }
        //
        // if is JUL and were on the main dashboard
        // redirect to JUL dashboard
        if (this.modulesService.hasJLReport()) {
          if (
            this.router.url === '/tableau-de-bord' ||
            this.router.url === '/dashboard'
          ) {
            this.router.navigateByUrl(AppRoutes.DASHBOARD_JUL);
          }
        }

        //
        // this.activeRoute.params.subscribe(params => {
        //     // this.catererUUID = params.id;
        //     // this.loadCatererByUUID(this.catererUUID);
        // });

        // this.logger.info(
        //     "this.modulesService.modulesAreSet -> " +
        //         this.modulesService.modulesAreSet
        // );
        // this.logger.info(
        //     "this.modulesService.getModules() -> " +
        //         this.modulesService.getModules()
        // );
        // this.logger.info(
        //     "this.modulesService.hasGABReport() -> " +
        //         this.modulesService.hasGABReport()
        // );
        if (!this.modulesService.hasRoomManagement()) {
          const newNav = this.navigation[0].children.filter((item: any) => {
            return item.id !== 'room-management';
          });
          this.navigation[0].children = newNav;
          //
          this._fuseNavigationService.unregister('main');
          this._fuseNavigationService.register('main', this.navigation);
          this._fuseNavigationService.setCurrentNavigation('main');
          // this.logger.info("newNav -> " + JSON.stringify(newNav));
        }

        // Hide Repports section from All employees except for the Owner, only for CTM

        // this.rolesService.NO_OWNERS_STAFF.indexOf(
        //         employee.user.role
        //     ) !== -1
        if (
          this.modulesService.hasCTReport() &&
          !this.rolesService.OWNER_ROLES.includes(employee.user.role)
        ) {
          const newNav = this.navigation[0].children.filter((item: any) => {
            return item.id !== 'reports';
          });
          this.navigation[0].children = newNav;

          this._fuseNavigationService.unregister('main');
          this._fuseNavigationService.register('main', this.navigation);
          this._fuseNavigationService.setCurrentNavigation('main');
        }
      }
    });

    this.userSettingsService.getSettings().subscribe(
      (response: any) => {
        // this.logger.info('AppComponent.loadStartingData() -> SUCCESS: ' + JSON.stringify(response));
        // this.userSettingsService.setUserSettings(response.data);
        // localStorage.setItem(DateFormatPreference.DATE_FORMAT_KEY, null);
      },
      (error: any) => {
        this.logger.error(
          'AppComponent.loadStartingData() -> userSettingsService ERRORS: ' +
            JSON.stringify(error),
        );
      },
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE methods
  // -----------------------------------------------------------------------------------------------------
  private initGoogleAnalyticsPageView(): void {
    const interval = setInterval(() => {
      if ((window as any).ga && (window as any).ga.getAll) {
        this.router.events.subscribe((event) => {
          const ga = (window as any).ga;
          if (event instanceof NavigationEnd) {
            const tracker = ga.getAll()[0];
            tracker.set('page', event.urlAfterRedirects);
            tracker.send('pageview');
          }
        });
        clearInterval(interval);
      }
    }, 50);
  }
}
