<div id="eventInfo" *ngIf="event">
  <!-- NAME Numéro du bon de commande -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "GENERIC.NAME" | translate }}</div>
      <div class="info text-truncate">{{ event.informations.name }}</div>
      <div
        *ngIf="!event.informations || !event.informations.name"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'GENERIC.NAME' | translate }}"
              autocomplete="nope"
              formControlName="name"
              type="text"
              [(ngModel)]="event.informations.name"
              required
            />
            <mat-error *ngIf="form.controls['name'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.PO_NUMBER" | translate }}</div>
      <div class="info text-truncate">{{ event.informations.po_number }}</div>
      <div
        *ngIf="!event.informations || !event.informations.po_number"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "EVENTS.PO_NUMBER" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'EVENTS.PO_NUMBER' | translate }}"
              autocomplete="nope"
              formControlName="po_number"
              type="text"
              [(ngModel)]="event.informations.po_number"
            />
            <mat-error *ngIf="form.controls['po_number'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <!-- DATE TIME START -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "EVENTS.EVENT_DATE_START" | translate }}</div>
      <div class="info">{{ event.informations.event_date_label }}</div>
      <!-- <div class="info"><b>Fin:</b> {{event.informations.event_date_end_label}}</div> -->
      <div
        *ngIf="!event.informations || !event.informations.event_date"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" fxLayout="row" class="">
          <mat-form-field class="" fxFlex="100%">
            <mat-label>{{ "EVENTS.EVENT_DATE_START" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'EVENTS.EVENT_DATE_START' | translate }}"
              formControlName="event_date"
              autocomplete="nope"
              [matDatepicker]="eventDate"
              min="1900-01-01"
              max="2099-01-01"
              (ngModelChange)="onDateChange($event)"
              [(ngModel)]="event.informations.event_date"
              required
            />

            <mat-datepicker-toggle
              matSuffix
              [for]="eventDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #eventDate></mat-datepicker>

            <mat-error
              *ngIf="
                form.controls['event_date'].invalid ||
                event.informations.event_date === null
              "
              >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
          <button
            mat-button
            [disabled]="!event.informations.event_date"
            mat-icon-button
            aria-label="Clear"
            (click)="clearStartDate()"
          >
            <mat-icon class="s-16">close</mat-icon>
          </button>

          <!-- <mat-form-field class="" fxFlex="100%">
                        <input matInput placeholder="{{ 'EVENTS.EVENT_DATE_END' | translate }}" formControlName="event_date_end"
                          [matDatepicker]="eventDateEnd" min="1900-01-01" max="2099-01-01"
                          (ngModelChange)="onDateEndChange($event)"
                          [(ngModel)]="event.informations.event_date_end">
                        <mat-datepicker-toggle matSuffix [for]="eventDateEnd"></mat-datepicker-toggle>
                        <mat-datepicker  #eventDateEnd></mat-datepicker>

                        <mat-error *ngIf="form.controls['event_date_end'].invalid">{{
                          'GENERIC.DATE_DEFAULT_RANGE_INVALID' | translate }}
                        </mat-error>

                      </mat-form-field> -->
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "EVENTS.EVENT_TIME_START" | translate }}</div>
      <div class="info">{{ event.informations.event_time_label }}</div>
      <!-- <div class="info"><b>Fin:</b> {{event.informations.event_time_end_label}}</div> -->
      <div
        *ngIf="!event.informations || !event.informations.event_time_label"
        class="info empty-value"
      ></div>
    </div>
    <div
      *ngIf="editMode"
      class="ngx-timepicker-field-custom"
      fxLayout="column"
      fxFlex="50%"
    >
      <div
        class="custom-label"
        [ngStyle]="{
          color:
            event.informations.event_time === '' ||
            event.informations.event_time === null
              ? '#ff0000'
              : '',
        }"
      >
        {{ "EVENTS.EVENT_TIME_START" | translate }} *
      </div>
      <div fxLayout="row" fxFlex>
        <ngx-timepicker-field
          fxFlex
          [buttonAlign]="'left'"
          #ngxTimeStart
          [format]="24"
          [(ngModel)]="event.informations.event_time"
          (ngModelChange)="onTimeChange($event, event)"
          class=""
        >
        </ngx-timepicker-field>
        <button
          mat-button
          matSuffix
          [disabled]="!event.informations.event_time"
          mat-icon-button
          aria-label="Clear"
          (click)="event.informations.event_time = ''"
        >
          <mat-icon class="s-16">close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- DATE TIME END -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "EVENTS.EVENT_DATE_END" | translate }}</div>
      <!-- <div class="info"><b>Début:</b> {{event.informations.event_date_label}}</div> -->
      <div class="info">{{ event.informations.event_date_end_label }}</div>
      <div
        *ngIf="!event.informations || !event.informations.event_date"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" fxLayout="row" class="">
          <mat-form-field class="" fxFlex="100%">
            <mat-label>{{ "EVENTS.EVENT_DATE_END" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'EVENTS.EVENT_DATE_END' | translate }}"
              autocomplete="nope"
              formControlName="event_date_end"
              [matDatepicker]="eventDateEnd"
              min="1900-01-01"
              max="2099-01-01"
              (ngModelChange)="onDateEndChange($event)"
              [(ngModel)]="event.informations.event_date_end"
            />

            <mat-datepicker-toggle
              matSuffix
              [for]="eventDateEnd"
            ></mat-datepicker-toggle>
            <mat-datepicker #eventDateEnd></mat-datepicker>

            <mat-error *ngIf="form.controls['event_date_end'].invalid"
              >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
          <button
            mat-button
            [disabled]="!event.informations.event_date_end"
            mat-icon-button
            aria-label="Clear"
            (click)="event.informations.event_date_end = ''"
          >
            <mat-icon class="s-16">close</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "EVENTS.EVENT_TIME_END" | translate }}</div>
      <!-- <div class="info"><b>Début:</b> {{event.informations.event_time_label}}</div> -->
      <div class="info">{{ event.informations.event_time_end_label }}</div>
      <div
        *ngIf="!event.informations || !event.informations.event_time_label"
        class="info empty-value"
      ></div>
    </div>
    <div
      *ngIf="editMode"
      class="ngx-timepicker-field-custom"
      fxLayout="column"
      fxFlex="50%"
    >
      <div class="custom-label">{{ "EVENTS.EVENT_TIME_END" | translate }}</div>
      <div fxLayout="row" fxFlex>
        <ngx-timepicker-field
          fxFlex
          [buttonAlign]="'left'"
          [format]="24"
          [(ngModel)]="event.informations.event_time_end"
          (ngModelChange)="onTimeEndChange($event, event)"
          class=""
        >
        </ngx-timepicker-field>
        <button
          mat-button
          [disabled]="!event.informations.event_time_end"
          mat-icon-button
          aria-label="Clear"
          (click)="event.informations.event_time_end = ''"
        >
          <mat-icon class="s-16">close</mat-icon>
        </button>
      </div>

      <!--          [clockTheme]="clockTheme"-->
      <!--          [ngxMaterialTimepickerTheme]="ngxMaterialTimepickerTheme"-->
    </div>
    <!--      <div *ngIf="editMode" class="info-line" fxFlex="50%">-->
    <!--        <div class="info-editable">-->
    <!--          <div [formGroup]="form" fxLayout="column" class="">-->
    <!--            <mat-form-field class="" fxFlex="100%">-->
    <!--                <mat-icon (click)="onOpenTimeEndPicker()" class="s-16 mr-8 button-cursor">{{ getIcon('TIME') }}</mat-icon>-->
    <!--                <input matInput type="time" formControlName="event_time_end"-->
    <!--                  autocomplete="nope"-->
    <!--                  placeholder="{{ 'EVENTS.EVENT_TIME_END' | translate }}"-->
    <!--                  [(ngModel)]="event.informations.event_time_end"  class="pr-28">-->
    <!--              </mat-form-field>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
  </div>

  <!-- LOCAL -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.ORDERING_LOCAL" | translate }}</div>
      <div *ngIf="event.ordering_local" class="info text-truncate">
        {{ event.ordering_local.name }}
      </div>
      <div *ngIf="!event.ordering_local" class="info empty-value"></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <app-gt2-select
          [service]="gt2LocalService"
          [nullableUseNone]="false"
          [placeHolder]="'EVENTS.ORDERING_LOCAL' | translate"
          [label]="'EVENTS.ORDERING_LOCAL' | translate"
          [selectedModel]="event.ordering_local"
          (changeEvent)="onOrderingLocalSelected($event)"
          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
          fxFlex="100%"
          class="pr-4"
        >
        </app-gt2-select>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.INVOICING_LOCAL" | translate }}</div>
      <div *ngIf="event.invoicing_local" class="info text-truncate">
        {{ event.invoicing_local.name }}
      </div>
      <div *ngIf="!event.invoicing_local" class="info empty-value"></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <app-gt2-select
        [service]="gt2LocalService"
        [nullableUseNone]="false"
        [placeHolder]="'EVENTS.INVOICING_LOCAL' | translate"
        [label]="'EVENTS.INVOICING_LOCAL' | translate"
        [selectedModel]="event.invoicing_local"
        (changeEvent)="onInvoicingLocalSelected($event)"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
        fxFlex="100%"
        class="pr-4"
      >
      </app-gt2-select>
    </div>
  </div>

  <!-- UQAM UBR && MANAGER EMAIL EVENT DATA -->
  <div
    *ngIf="this.moduleService.modulesAreSet && moduleService.hasUQAMReport()"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
  >
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.UBR_CODE" | translate }}</div>
      <div *ngIf="event.code_ubr" class="info text-truncate">
        {{ event.code_ubr }}
      </div>
      <div *ngIf="!event.code_ubr" class="info empty-value"></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="uqam_ubr_form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "EVENTS.UBR_CODE" | translate }}</mat-label>
            <input
              (ngModelChange)="populateUbrCodes($event)"
              type="text"
              matInput
              [(ngModel)]="event.code_ubr"
              class=""
              formControlName="ubr_code"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete class="ubr-autocomplete" #auto="matAutocomplete">
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="uqam_ubr_form.controls['ubr_code'].invalid">{{
              "EVENTS.VALID_UBR_CODE" | translate
            }}</mat-error>
            <div
              class="no-results-message"
              *ngIf="noResultsUbr && uqam_ubr_form.controls['ubr_code'].valid"
            >
              {{ "EVENTS.NO_UBR_RESULTS" | translate }}
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.UBR_MANAGER_EMAIL" | translate }}</div>
      <div *ngIf="event.manager_email" class="info text-truncate">
        {{ event.manager_email }}
      </div>
      <div *ngIf="!event.manager_email" class="info empty-value"></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="uqam_ubr_form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "EVENTS.UBR_MANAGER_EMAIL" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'EVENTS.UBR_MANAGER_EMAIL' | translate }}"
              autocomplete="nope"
              formControlName="manager_email"
              type="email"
              [(ngModel)]="event.manager_email"
            />
            <mat-error *ngIf="uqam_ubr_form.controls['manager_email'].invalid"
              >{{ "GENERIC.EMAIL_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <!-- EVENT TYPE -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <!-- <div *ngIf="!editMode" class="info-line" fxFlex="50%">
          <div class="title">{{ 'EVENTS.RESPONSIBLE_EMPLOYEE' | translate }}</div>
          <div *ngIf="event.responsible_employee && event.responsible_employee.name" class="info text-truncate">{{event.responsible_employee.name}}</div>
          <div *ngIf="!event.responsible_employee || !event.responsible_employee.name" class="info empty-value"></div>
        </div>
        <div *ngIf="editMode" class="info-line" fxFlex="50%">
          <div class="info-editable">
            <app-gt2-select [service]="employeesService" [placeHolder]=" 'EVENTS.SELECT_RESPONSIBLE_EMPLOYEE' | translate "
              [label]="'Personnal title'" [selectedModel]="event.responsible_employee" (changeEvent)="onResponsibleEmployeeSelected($event)"
              [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')" [nullableUseNone]="false" class="pr-4">
            </app-gt2-select>
          </div>
        </div> -->

    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "EVENTS.EVENT_TYPE" | translate }}</div>
      <div
        *ngIf="event.informations && event.informations.event_type"
        class="info text-truncate"
      >
        {{ event.informations.event_type.name }}
      </div>
      <div
        *ngIf="!event.informations || !event.informations.event_type"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="" fxFlex="48%">
      <div class="">
        <div class="mb-8 mat-caption">
          {{ "EVENTS.EVENT_TYPE" | translate }}
        </div>
        <select2
          overlay="true"
          minCountForSearch="0"
          displaySearchStatus="default"
          [placeholder]="'EVENTS.EVENT_TYPE' | translate"
          [data]="eventTypeSelect2Data"
          styleMode="material"
          [value]="selectedEventTypeUuid"
          (update)="onEventTypeSelected($event)"
        >
        </select2>
        <!-- <app-gt2-select [service]="eventTypeService"
                                [placeHolder]="'EVENTS.SELECT_EVENT_TYPE' | translate"
                                [label]="''"
                                [selectedModel]="event.informations.event_type"
                                (changeEvent)="onEventTypeSelected($event)"
                                [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')" [nullableUseNone]="false"
                                class="">
                </app-gt2-select> -->
      </div>
    </div>

    <!-- UQAM alcohol permit LOCAL -->
    <!--<div *ngIf="!editMode && event.is_uqam" class="info-line" fxFlex="50%">
            <div class="title">{{ 'GENERIC.ALCOHOL_PERMIT_LOCAL' | translate }}</div>
            <div *ngIf="event.informations && event.informations.alcoholic_permit_local"  class="info text-truncate">
                {{event.informations.alcoholic_permit_local}}
            </div>
            <div *ngIf="!event.informations || !event.informations.alcoholic_permit_local"  class="info empty-value"></div>
        </div>
         <div *ngIf="editMode && event.is_uqam" class="info-line" fxFlex="50%" style="padding-left: 2%;">
            <div class="info-editable">
                <div class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                        <input matInput placeholder="{{ 'GENERIC.ALCOHOL_PERMIT_LOCAL' | translate }}"
                               autocomplete="nope"
                               type="text"
                               [(ngModel)]="event.informations.alcoholic_permit_local">
                    </mat-form-field>
                </div>
            </div>
        </div> -->

    <!-- Salle COMMANDE WEB, shopScheckout_multitakoutInput -->
    <div
      *ngIf="
        !editMode && event.is_ctm && event.informations.display_takeout_input
      "
      class="info-line"
      fxFlex="50%"
    >
      <div class="title">{{ "GENERIC.ROOM_WEB" | translate }}</div>
      <div
        *ngIf="event.informations && event.informations.multi_takeout_input"
        class="info text-truncate"
      >
        {{ event.informations.multi_takeout_input }}
      </div>
      <div
        *ngIf="!event.informations || !event.informations.multi_takeout_input"
        class="info empty-value"
      ></div>
    </div>
    <div
      *ngIf="
        editMode && event.is_ctm && event.informations.display_takeout_input
      "
      class="info-line"
      fxFlex="50%"
    >
      <div class="info-editable">
        <div class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "GENERIC.ROOM_WEB" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'GENERIC.ROOM_WEB' | translate }}"
              autocomplete="nope"
              type="text"
              (ngModelChange)="onRoomChange($event)"
              [(ngModel)]="event.informations.multi_takeout_input"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <!-- ORG_IDENTITY -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div
      *ngIf="
        !editMode &&
        event.informations &&
        event.informations.organization_identity_list &&
        event.informations.organization_identity_list.length > 0
      "
      class="info-line"
      fxFlex="50%"
    >
      <div class="title">{{ "EVENTS.ORG_IDENTITY" | translate }}</div>
      <div
        *ngIf="event.informations && event.informations.organization_identity"
        class="info text-truncate"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div>{{ event.informations.organization_identity.name }}</div>
        <mat-icon
          class="s-20 ml-8"
          matTooltip="{{ 'EVENTS.ORG_IDENTITY_HELP' | translate }}"
          color=""
          >help</mat-icon
        >
      </div>
      <div
        *ngIf="!event.informations || !event.informations.organization_identity"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="info"
      >
        <div fxFlex class="empty-value"></div>
        <mat-icon
          class="s-20"
          matTooltip="{{ 'EVENTS.ORG_IDENTITY_HELP' | translate }}"
          color=""
          >help</mat-icon
        >
      </div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div
        class="info-editable"
        *ngIf="
          event.informations &&
          event.informations.organization_identity_list &&
          event.informations.organization_identity_list.length > 0
        "
      >
        <div class="" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "EVENTS.ORG_IDENTITY" | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'EVENTS.ORG_IDENTITY' | translate }}"
              (selectionChange)="onIdentityChange($event)"
              [(ngModel)]="event.informations.organization_identity"
            >
              <mat-option [value]="null">{{
                "GENERIC.NONE" | translate
              }}</mat-option>
              <mat-option
                *ngFor="
                  let identity of event.informations.organization_identity_list
                "
                [value]="identity"
                >{{ identity.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-icon
            class="s-20 ml-8"
            matTooltip="{{ 'EVENTS.ORG_IDENTITY_HELP' | translate }}"
            color=""
            >help</mat-icon
          >
        </div>
      </div>
    </div>
  </div>

  <!-- EVENT LOGISTIC STATUS -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "Statut logistique" | translate }}</div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div
          fxLayoutAlign="center center"
          *ngIf="event.event_logistics_status"
          [ngStyle]="{
            margin: '5px',
            minWidth: '160px',
            height: '30px',
            'background-color': event.event_logistics_status.color_code,
          }"
        >
          <div [ngStyle]="{ color: '#FFFFFF', padding: '7px' }">
            {{ event.event_logistics_status.name | translate }}
          </div>
        </div>
        <div
          fxLayoutAlign="center center"
          *ngIf="!event.event_logistics_status"
        >
          Aucun
        </div>
      </div>
    </div>

    <div *ngIf="editMode" class="" fxFlex="48%">
      <div class="">
        <div class="mb-8 mat-caption">
          {{ "Statut logistique" | translate }}
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex="100%">
            <select2
              #event_logistics_status_select2
              overlay="true"
              minCountForSearch="0"
              displaySearchStatus="default"
              resettable="true"
              [placeholder]="'Sélectionner un statut logistique' | translate"
              stylemode="material"
              [data]="event.event_logistics_status_list"
              [templates]="template"
              [value]="event_logistics_status_uuid"
              (update)="onEventLogisticStatusSelected($event)"
            >
              <ng-template #template let-data="color_code" let-label="name">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div
                    fxLayoutAlign="center center"
                    [ngStyle]="{
                      margin: '5px',
                      minWidth: '160px',
                      height: '30px',
                      'background-color': data,
                    }"
                  >
                    <div [ngStyle]="{ color: '#FFFFFF', padding: '7px' }">
                      {{ label | translate }}
                    </div>
                  </div>
                </div>
              </ng-template>
            </select2>
          </div>
          <!-- <div>
                        <button mat-button mat-icon-button aria-label="Clear"
                            (click)="event_logistics_status_select2.handleClearClick();">
                            <mat-icon class="s-16">close</mat-icon>
                        </button>
                    </div> -->
        </div>
      </div>
    </div>

    <div class="info-line" fxFlex="50%">&nbsp;</div>
  </div>

  <!-- ROOM_STATUS -->
  <div
    *ngIf="
      moduleService.modulesAreSet &&
      moduleService.moduleExist('MODULE_ROOM_MNGT')
    "
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
  >
    <div
      *ngIf="
        !editMode && event.room_reservation && event.room_reservation.status
      "
      class="info-line"
      fxFlex="50%"
    >
      <div class="title">{{ "GENERIC.ROOM_STATUS" | translate }}</div>
      <div *ngIf="moduleService.modulesAreSet && moduleService.hasRPReport()">
        <div
          *ngIf="
            event.informations && event.informations.room_reservation_status
          "
          class="info text-truncate"
        >
          {{
            "GENERIC.RP_" + event.informations.room_reservation_status
              | translate
          }}
        </div>
      </div>
      <div *ngIf="!moduleService.modulesAreSet || !moduleService.hasRPReport()">
        <div
          *ngIf="
            event.informations && event.informations.room_reservation_status
          "
          class="info text-truncate"
        >
          {{
            "GENERIC." + event.informations.room_reservation_status | translate
          }}
        </div>
      </div>

      <div
        *ngIf="
          !event.informations || !event.informations.room_reservation_status
        "
        class="info empty-value"
      ></div>
    </div>

    <div
      *ngIf="
        editMode && event.room_reservation && event.room_reservation.status
      "
      class="info-line"
      fxFlex="50%"
    >
      <mat-form-field class="" style="width: 100%">
        <mat-select
          *ngIf="moduleService.modulesAreSet && moduleService.hasRPReport()"
          placeholder="{{ 'GENERIC.ROOM_STATUS' | translate }}"
          (selectionChange)="onRoomStatusChange($event)"
          [(ngModel)]="event.informations.room_reservation_status"
        >
          <mat-option
            *ngFor="let reservation of event.room_reservation_list"
            [value]="reservation"
          >
            <div>{{ "GENERIC.RP_" + reservation | translate }}</div>
          </mat-option>
        </mat-select>

        <mat-select
          *ngIf="!moduleService.modulesAreSet || !moduleService.hasRPReport()"
          placeholder="{{ 'GENERIC.ROOM_STATUS' | translate }}"
          (selectionChange)="onRoomStatusChange($event)"
          [(ngModel)]="event.informations.room_reservation_status"
        >
          <mat-option
            *ngFor="let reservation of event.room_reservation_list"
            [value]="reservation"
          >
            {{ "GENERIC." + reservation | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="info-line" fxFlex="50%">&nbsp;</div>
  </div>
</div>

<!-- </div> -->
