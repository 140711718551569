export const locale = {
  lang: "en-CA",
  data: {
    COMMENTS: {
      COMMENTS: "Comments",
      ADD_COMMENTS: "Add a comment...",
      ADD_COMMENTS_BUTTON_LABEL: "Add Comment",
      COMMENTS_INVALID: "Le commentaire doit contenir moins de {{commentMaxChar}} caractères.",
    },
  },
};
