<!--<div fxFlex="100" fxLayout="row" fxLayoutAlign="end center">-->

<!--<div class="mat-body-2">Tags:&nbsp;&nbsp;</div>-->
<!--<mat-form-field class="example-chip-list">-->
<!--<mat-chip-listbox #tagList>-->

<!--<mat-chip *ngFor="let tag of tags"-->

<!--[removable]="removable">-->
<!--{{tag.name}}-->
<!--</mat-chip>-->
<!--&lt;!&ndash;color="{{tagsColors[tag.family]}}"&ndash;&gt;-->

<!--<input placeholder="New tag..."-->
<!--#tagInput-->
<!--[formControl]="fruitCtrl"-->
<!--[matAutocomplete]="auto"-->
<!--[matChipInputFor]="chipList"-->
<!--[matChipInputSeparatorKeyCodes]="separatorKeysCodes"-->
<!--[matChipInputAddOnBlur]="addOnBlur"-->
<!--(matChipInputTokenEnd)="add($event)">-->

<!--</mat-chip-listbox>-->
<!--<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">-->
<!--<mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">-->
<!--{{fruit}}-->
<!--</mat-option>-->
<!--</mat-autocomplete>-->
<!--</mat-form-field>-->
<!--</div>-->

<mat-form-field class="example-chip-list">
  <mat-chip-grid #chipList>
    <mat-chip
      *ngFor="let tag of tags"
      [removable]="removable"
      (removed)="remove(tag)"
    >
      {{ tag }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <mat-label>{{ tagPlaceholder }}</mat-label>
    <input
      placeholder="{{ tagPlaceholder }}"
      #tagInput
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      {{ tag }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
