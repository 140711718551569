export const locale = {
  lang: "en-CA",
  data: {
    JOB_FUNCTIONS: {
      CREATE_NEW_CATEGORY: "New function",
      CATEGORY_NAME: "Function name",
      HOURLY_WAGE: "Hourly wage",
      NEW_CATEGORY_NAME: "Name of new function",
      CREATE_SUCCESS: "Function created successfully",
      EDIT_SUCCESS: "Function modified successfully",
      DELETE_SUCCESS: "Function deleted successfully",
      BILLABLE: "Billable",
    },
  },
};
