import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { InputTextarea } from 'primeng/inputtextarea';
import { CommentsModel } from '../../api/models/CommentsModel';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { CrudEmployeeService } from '../../content/crud/crud-employee/crud-employee.service';
import { AppRoutesService } from '../../services/app-routes.service';
import { CommentsService } from './comments.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  styleUrls: ['./comment-section.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatButtonModule,
    MatInputModule,
    TranslateModule,
    RouterModule,
    MatIconModule,
    MatFormFieldModule,
  ],
  providers: [CommentsService],
})
export class CommentSectionComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() commentsModel!: CommentModelInterface | any;
  @Input() serviceAPIUrl!: string;
  @Input() commentInput!: InputTextarea;
  @Input() disabled: boolean = false;

  postCommentModel!: string;
  form: UntypedFormGroup;
  formErrors: any;
  loading: boolean = false;
  currentEditComment!: CommentsModel;
  commentMaxChar: number = 4000;
  commentMinChar: number = 1;
  commentsToggleClosed: boolean = false;

  constructor(
    private commentsService: CommentsService,
    public employeeService: CrudEmployeeService,
    public translate: TranslateService,
    public dialog: MatDialog,
    public appRoutesService: AppRoutesService,
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
    // Initialisé la langue
    const local_lang =
      localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID) ||
      LocalizationConfig.DEFAULT_LANGUAGE;

    // Ajouter les traductions locales
    if (local_lang === 'en-CA')
      translate.setTranslation(english.lang, english.data, true);
    else translate.setTranslation(french.lang, french.data, true);

    // Définir la langue à utiliser
    translate.setDefaultLang(LocalizationConfig.DEFAULT_LANGUAGE);
    translate.use(local_lang);

    this.form = this.formBuilder.group({
      comment: [
        '',
        [
          Validators.minLength(this.commentMinChar),
          Validators.maxLength(this.commentMaxChar),
          Validators.required,
        ],
      ],
    });

    this.formErrors = {
      comment: {},
    };

    this.form.valueChanges.subscribe(() => {
      this.onLoginFormValuesChanged();
    });

    this.commentsToggleClosed =
      localStorage.getItem('commentsToggleClosed') === 'true'; // | false;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // this.logger.info(
    //     "CommentSectionComponent.ngAfterViewInit() -> commentsModel: " +
    //         JSON.stringify(this.commentsModel)
    // );
  }

  ngOnDestroy(): void {}

  public onPostComment(): void {
    //this.logger.info("CommentSectionComponent.onPostComment() -> serviceAPIUrl: " + this.serviceAPIUrl);

    if (this.form.valid) {
      // this.logger.info("CommentSectionComponent.onPostComment() -> postCommentModel: " + this.postCommentModel);
      //this.logger.info("CommentSectionComponent.onPostComment() -> commentsModel.comments: " + this.commentsModel.comments);

      if (!this.commentsModel?.comments) {
        this.commentsModel.comments = [];
        //this.logger.info("CommentSectionComponent.onPostComment() -> CREATED COMMENTS ARRAY comments: " + this.commentsModel.comments);
      }

      const newComment: any = {
        object: 'Comment',
        uuid: null,
        title: null,
        body: this.postCommentModel,
        comment_date: null,
        employee_reference: null,
      };
      //this.logger.info("CommentSectionComponent.onPostComment() -> newComment: " + JSON.stringify(newComment));
      this.loading = true;
      this.commentsService
        .addComments(this.serviceAPIUrl, newComment)
        .subscribe(
          (response: any) => {
            // this.logger.info("CommentSectionComponent.onPostComment() -> SUCCESS: " + JSON.stringify(response));
            this.loading = false;
            this.postCommentModel = '';
            this.commentsModel.comments = response;
            this.form.markAsPristine();
          },
          (error: any) => {
            this.logger.error(
              'CommentSectionComponent.onPostComment() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.loading = false;
            this.handleAPIError(error, this.dialog, null, null);
          },
        );
    }
  }

  public onEditComment(comment: any): void {
    //this.logger.info("CommentSectionComponent.onEditComment() -> ");
    this.currentEditComment = comment;
  }

  public onLoginFormValuesChanged(): void {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.formErrors[field] = {};

      // Get the control
      const control = this.form.get(field);

      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }
  public onToggleComments(): void {
    this.commentsToggleClosed = !this.commentsToggleClosed;
    localStorage.setItem(
      'commentsToggleClosed',
      this.commentsToggleClosed.toString(),
    );
  }
}

export interface CommentModelInterface {
  uuid: string;
  comments: CommentsModel[];
}
