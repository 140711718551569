import { ErrorHandler, Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import * as i0 from "@angular/core";
import * as i1 from "@bugsnag/js";
// zones are optional, so we need to detect if they are being used
// see https://angular.io/guide/zone#noopzone
const isNgZoneEnabled = typeof Zone !== 'undefined' && !!Zone.current;
export class BugsnagErrorHandler extends ErrorHandler {
  constructor(client) {
    super();
    if (client) {
      this.bugsnagClient = client;
    } else {
      this.bugsnagClient = Bugsnag._client;
    }
  }
  handleError(error) {
    const handledState = {
      severity: 'error',
      severityReason: {
        type: 'unhandledException'
      },
      unhandled: true
    };
    const event = this.bugsnagClient.Event.create(error, true, handledState, 'angular error handler', 1);
    if (error.ngDebugContext) {
      event.addMetadata('angular', {
        component: error.ngDebugContext.component,
        context: error.ngDebugContext.context
      });
    }
    this.bugsnagClient._notify(event);
    ErrorHandler.prototype.handleError.call(this, error);
  }
}
BugsnagErrorHandler.ɵfac = function BugsnagErrorHandler_Factory(t) {
  return new (t || BugsnagErrorHandler)(i0.ɵɵinject(i1.Client));
};
BugsnagErrorHandler.ɵprov = i0.ɵɵdefineInjectable({
  token: BugsnagErrorHandler,
  factory: BugsnagErrorHandler.ɵfac
});
/*@__PURE__*/
(function () {
  i0.ɵsetClassMetadata(BugsnagErrorHandler, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.Client
    }];
  }, null);
})();
const plugin = {
  load: client => {
    const originalNotify = client._notify;
    client._notify = function () {
      const originalArguments = arguments;
      if (isNgZoneEnabled) {
        // run notify in the root zone to avoid triggering change detection
        Zone.root.run(() => {
          originalNotify(originalArguments);
        });
      } else {
        // if zones are not enabled, change detection will not run anyway
        originalNotify(originalArguments);
      }
    };
    return new BugsnagErrorHandler(client);
  },
  name: 'Angular'
};
export default plugin;
