import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-release-note',
  templateUrl: './release-note.component.html',
  styleUrls: ['./release-note.component.scss'],
})
export class ReleaseNoteComponent implements OnInit {
  isMobile: boolean = false;
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private deviceService: DeviceDetectorService,
    public translate: TranslateService,
  ) {
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit(): void {
    this.isMobile = this.detectMobileDevice();
    // this.logger.info("this.selectedLanguage: " + this.selectedLanguage);
  }
  detectMobileDevice(): boolean {
    return this.deviceService.isMobile() || this.deviceService.isTablet();
  }
}
