<div style="max-height: 100%; overflow: auto">
  <h1 matDialogTitle>{{ "CREATE_CLIENT_WITH_DETAILS.TITLE" | translate }}</h1>

  <div
    fxLayout="column"
    fxLayoutAlign="center start"
    [formGroup]="form"
    (keyup.enter)="form.valid && !loading ? onCreateClient() : null"
    class="mt-8 create-client-width"
  >
    <mat-form-field class="create-client-width">
      <mat-label>{{ "GENERIC.FIRST_NAME" | translate }}</mat-label>
      <input
        matInput
        fxFlex="100%"
        placeholder="{{ 'GENERIC.FIRST_NAME' | translate }}"
        formControlName="first_name"
        type="text"
        autocomplete="nope"
        [(ngModel)]="client.first_name"
        required
      />
      <mat-error *ngIf="form.controls['first_name'].invalid">{{
        "GENERIC.INPUT_LENGTH_INVALID" | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="create-client-width">
      <mat-label>{{ "GENERIC.LAST_NAME" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'GENERIC.LAST_NAME' | translate }}"
        formControlName="last_name"
        autocomplete="nope"
        type="text"
        [(ngModel)]="client.last_name"
        required
      />
      <mat-error *ngIf="form.controls['last_name'].invalid">{{
        "GENERIC.INPUT_LENGTH_INVALID" | translate
      }}</mat-error>
    </mat-form-field>
    <div class="info-editable" style="width: 100%">
      <app-gt2-select
        #wrapping
        [service]="gt2LocalService"
        [nullableUseNone]="false"
        [placeHolder]="'CREATE_CLIENT_WITH_DETAILS.COMM_LANGUAGE' | translate"
        [label]="'CREATE_CLIENT_WITH_DETAILS.COMM_LANGUAGE' | translate"
        [selectedModel]="client.communication_local"
        (changeEvent)="onLocalSelected($event)"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
        fxFlex="100%"
        class="pr-4"
      >
      </app-gt2-select>
    </div>

    <h2 matDialogTitle>
      {{ "CREATE_CLIENT_WITH_DETAILS.TITLE_CONTACT" | translate }}
    </h2>

    <mat-form-field class="create-client-width">
      <mat-label>{{ "CONTACT.ORG_EMAIL" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'CONTACT.ORG_EMAIL' | translate }}"
        formControlName="professional_email"
        autocomplete="nope"
        type="email"
        [(ngModel)]="client.contact.email_organisation"
      />
      <mat-error *ngIf="form.controls['professional_email'].invalid">{{
        "GENERIC.EMAIL_INVALID" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field class="create-client-width" fxFlex="100%">
      <mat-label>{{ "CONTACT.OFFICE_PHONE" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'CONTACT.OFFICE_PHONE' | translate }}"
        formControlName="telephone_office"
        [(ngModel)]="client.contact.telephone_office"
        type="tel"
      />
      <mat-error *ngIf="form.controls['telephone_office'].invalid"
        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="create-client-width" fxFlex="40%">
      <mat-label>{{ "CONTACT.OFFICE_EXT" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'CONTACT.OFFICE_EXT' | translate }}"
        formControlName="telephone_office_ext"
        type="text"
        [(ngModel)]="client.contact.telephone_office_ext"
      />
      <mat-error *ngIf="form.controls['telephone_office_ext'].invalid"
        >{{ "GENERIC.INPUT_LENGTH_INVALID_20" | translate }}
      </mat-error>
    </mat-form-field>

    <h2 matDialogTitle>
      {{ "CREATE_CLIENT_WITH_DETAILS.TITLE_ADDRESS" | translate }}
    </h2>
    <!-- <ngxgeo-autocomplete
      [userSettings]="userSettings"
      (componentCallback)="handleAddressChange($event)"
      class="app-address"
    >
    </ngxgeo-autocomplete> -->
    <mat-form-field class="create-client-width" fxFlex="100%">
      <mat-label
        >{{ "ADDRESS.ADDRESS_NUMBER" | translate }}# addresse</mat-label
      >
      <input
        matInput
        placeholder="{{ 'ADDRESS.ADDRESS_NUMBER' | translate }}# addresse"
        formControlName="street_number"
        type="text"
        [(ngModel)]="client.address.street_number"
        required
      />
      <mat-error *ngIf="form.controls['street_number'].invalid"
        >{{ "GENERIC.INPUT_LENGTH_INVALID_1" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="create-client-width" fxFlex="100%">
      <mat-label>{{ "ADDRESS.STREET" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'ADDRESS.STREET' | translate }}"
        formControlName="street"
        type="text"
        [(ngModel)]="client.address.street"
        required
      />
      <mat-error *ngIf="form.controls['street'].invalid"
        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="create-client-width" fxFlex="100%">
      <mat-label>{{ "ADDRESS.APP_NUMBER" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'ADDRESS.APP_NUMBER' | translate }}"
        formControlName="apartment"
        [(ngModel)]="client.address.apartment"
        type="text"
      />
      <mat-error *ngIf="form.controls['apartment'].invalid"
        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="create-client-width" fxFlex="100%">
      <mat-label>{{ "ADDRESS.LINE_2" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'ADDRESS.LINE_2' | translate }}"
        formControlName="line2"
        [(ngModel)]="client.address.line2"
        type="text"
      />
      <mat-error *ngIf="form.controls['line2'].invalid"
        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="create-client-width" fxFlex="100%">
      <mat-label>{{ "ADDRESS.CITY" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'ADDRESS.CITY' | translate }}"
        formControlName="city"
        [(ngModel)]="client.address.city"
        type="text"
        required
      />
      <mat-error *ngIf="form.controls['city'].invalid"
        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="create-client-width" fxFlex="100%">
      <mat-label>{{ "ADDRESS.PROVINCE" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'ADDRESS.PROVINCE' | translate }}"
        formControlName="province"
        type="text"
        [(ngModel)]="client.address.province"
        required
      />
      <mat-error *ngIf="form.controls['province'].invalid"
        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="create-client-width" fxFlex="100%">
      <mat-label>{{ "ADDRESS.COUNTRY" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'ADDRESS.COUNTRY' | translate }}"
        formControlName="country"
        type="text"
        [(ngModel)]="client.address.country"
        required
      />
      <mat-error *ngIf="form.controls['country'].invalid"
        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="create-client-width" fxFlex="100%">
      <mat-label>{{ "ADDRESS.POSTAL_CODE" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'ADDRESS.POSTAL_CODE' | translate }}"
        formControlName="postal_code"
        type="text"
        [(ngModel)]="client.address.postal_code"
        required
      />
      <mat-error *ngIf="form.controls['postal_code'].invalid"
        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
      </mat-error>
    </mat-form-field>

    <!-- CLIENT NOTES -->
    <div class="profile-box info-box general" fxLayout="column">
      <header class="accent">
        <div class="title">{{ "GENERIC.NOTES" | translate }}</div>
      </header>

      <div class="note-section">
        <p-editor
          #noteEditor
          [(ngModel)]="client.note"
          [style]="{
            height: '140px',
            width: '100%',
            'background-color': '#ffffff',
          }"
        >
          <ng-template pTemplate="header" class="">
            <span class="ql-formats">
              <select class="ql-font"></select>
              <select class="ql-size"></select>
            </span>

            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-strike"></button>
            </span>

            <span class="ql-formats">
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span>

            <span class="ql-formats">
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-indent" value="-1"></button>
              <button class="ql-indent" value="+1"></button>
            </span>
          </ng-template>
        </p-editor>
        <!--</form>-->
      </div>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    [formGroup]="form"
    (submit)="onCreateClient()"
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      color="accent"
      class="mr-16"
      type="submit"
      [disabled]="!form.valid || loading"
      (click)="onCreateClient()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="accent"
      class="mr-16"
      type="submit"
      [disabled]="!form.valid || loading"
      (click)="onCreateClientAndAttach()"
    >
      {{ "CREATE_CLIENT_WITH_DETAILS.SAVE_AND_ATTACH" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
