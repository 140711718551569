<div id="eventDepositIn">
  <div fxLayout="row" fxLayout="start center" class="section-list-header">
    <!-- <div fxFlex="50px" class=""></div> -->
    <div fxFlex="12" class="section-list-item-border-right pl-4 py-4">
      {{ "GENERIC.DATE" | translate }}
    </div>
    <div
      fxFlex="12"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.AMOUNT" | translate }}
    </div>
    <div
      fxFlex="15"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "EVENTS.MODE" | translate }}
    </div>
    <div
      fxFlex="12"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "EVENTS.DEPOSIT_NUMBER" | translate }}
    </div>
    <div
      fxFlex="12"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "EVENTS.INVOICE_NUMBER" | translate }}
    </div>
    <div
      fxFlex="11"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "EVENTS.REFERENCE_NUMBER" | translate }}
    </div>
    <div
      fxFlex="14"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.USER" | translate }}
    </div>
    <div
      fxFlex="12"
      class="section-list-item section-list-item-border-right py-4"
    >
      Actions
    </div>
  </div>

  <div fusePerfectScrollbar style="width: 100%">
    <div
      *ngIf="
        !loading && event && (!event.cashings || event.cashings.length === 0)
      "
      fxFlex="100"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <div class="font-size-20 my-16">
        {{ "EVENTS.NO_DEPOSIT_IN" | translate }}
      </div>
    </div>

    <div
      *ngIf="loading"
      fxFlex="100"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <mat-spinner [diameter]="40" class="my-40"></mat-spinner>
    </div>

    <!-- DATA -->
    <div
      *ngIf="!loading && event && event.cashings && event.cashings.length > 0"
      fxFlex=""
    >
      <div
        *ngFor="let item of event.cashings"
        fxLayout="row"
        fxLayout="start center"
        class="child-item"
      >
        <div fxFlex="12" class="section-list-item-border-right py-8 pl-8">
          <div class="">{{ item.received_at_label }}</div>
        </div>
        <div
          fxFlex="12"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.amount_label }}
        </div>
        <div
          fxFlex="15"
          class="section-list-item section-list-item-border-right py-8 text-truncate"
        >
          <span *ngIf="item.payment_mode">{{ item.payment_mode.name }}</span>
        </div>
        <div
          fxFlex="12"
          class="section-list-item section-list-item-border-right py-8"
        >
          <span *ngIf="item.deposit_request">{{
            item.deposit_request.deposit_number
          }}</span>
        </div>
        <div
          fxFlex="12"
          class="section-list-item section-list-item-border-right py-8 text-truncate"
        >
          <span *ngIf="event.documents.invoice">{{
            event.documents.invoice.invoice_number
          }}</span>
        </div>
        <div
          fxFlex="11"
          class="section-list-item section-list-item-border-right py-8 text-truncate"
        >
          <span>{{ item.ref_number }}</span>
        </div>
        <div
          fxFlex="14"
          class="section-list-item section-list-item-border-right py-8 text-truncate"
        >
          <span
            *ngIf="item.received_by && item.received_by.employee"
            (click)="onOpenItemInNewTab(item.received_by.employee)"
            >{{ item.received_by.employee.name }}</span
          >
          <span
            *ngIf="
              item.received_by &&
              !item.received_by.employee &&
              item.received_by.email_label
            "
            >{{ item.received_by.email_label }}</span
          >
        </div>
        <div
          fxFlex="12"
          *ngxPermissionsOnly="rolesService.RH_ROLES"
          class="section-list-item"
        >
          <!--                            <button mat-icon-button -->
          <!--                                (click)="onEditItem(item)"-->
          <!--                                matTooltip="{{ 'GENERIC.EDIT' | translate }}" -->
          <!--                                class="mat-icon-button">-->

          <button
            mat-icon-button
            (click)="onEditItem(item)"
            [disabled]="!editable"
            matTooltip="{{ 'GENERIC.EDIT' | translate }}"
            class="mat-icon-button"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("EDIT")
            }}</mat-icon>
          </button>

          <!--                            <button mat-icon-button-->
          <!--                                    matTooltip="{{ 'GENERIC.DELETE' | translate }}"-->
          <!--                                    (click)="onDeleteItem(item)"-->
          <!--                                    color="gt2-grey"-->
          <!--                                    class="mat-icon-button">-->

          <button
            mat-icon-button
            matTooltip="{{ 'GENERIC.DELETE' | translate }}"
            [disabled]="!editable"
            (click)="onDeleteItem(item)"
            color="gt2-grey"
            class="mat-icon-button"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("DELETE")
            }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
