import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CongressesService } from '../../../../api/congresses.service';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import { NGXLogger } from 'ngx-logger';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-group-action',
  templateUrl: './group-action.component.html',
  styleUrls: ['./group-action.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    FlexLayoutModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class GroupActionComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static TYPE_PRICE: string = 'PRICE';
  static TYPE_QTY: string = 'QTY';
  static PRODUCT_CATEGORY: string = 'PRODUCT_CATEGORY';
  static MATERIAL_CATEGORY: string = 'MATERIAL_CATEGORY';

  title: string = '';
  actionValue: any;
  form: UntypedFormGroup;
  type?: string;
  congressUidd?: string;
  projects: any;
  selectedProject?: string;
  selectedEventType?: string;
  showEventTypeInput = false;

  constructor(
    public override dialogRef: MatDialogRef<GroupActionComponent>,
    private logger: NGXLogger,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public congressesService: CongressesService,
  ) {
    super();
    if (data && data.title !== null) {
      this.title = data.title;
    }
    if (data && data.congressUidd !== null) {
      this.congressUidd = data.congressUidd;
    }
    if (data && data.showEventTypeInput !== null) {
      this.showEventTypeInput = data.showEventTypeInput;
    }
    this.form = this.formBuilder.group({
      project: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.projects = [];
    this.congressesService
      .getCongressProjectsList(this.congressUidd)
      .subscribe((result: any) => {
        this.projects = result.data;
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public changeSelectedProject(event: any) {
    this.selectedProject = event;
  }
}
