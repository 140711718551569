<div style="max-height: 100%; overflow: auto">
  <h1 class="h1-title" matDialogTitle>{{ "CREATE_SHOP.TITLE" | translate }}</h1>

  <div
    fxLayout="column"
    fxLayoutAlign="center start"
    [formGroup]="form"
    (keyup.enter)="
      form.valid && !loading && shop.shop_type ? onCreateShop() : null
    "
    class="mt-8 create-client-width"
  >
    <mat-form-field fxFlex="100%" class="item-full-width">
      <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
      <input
        matInput
        fxFlex="100%"
        placeholder="{{ 'GENERIC.NAME' | translate }}"
        formControlName="name"
        autocomplete="nope"
        type="text"
        [(ngModel)]="shop.name"
        required
      />
      <mat-error *ngIf="form.controls['name'].invalid">{{
        "GENERIC.INPUT_LENGTH_INVALID" | translate
      }}</mat-error>
    </mat-form-field>

    <div class="item-full-width">
      <app-gt2-select
        [service]="shopTypeService"
        [placeHolder]="'CREATE_SHOP.INTEGRATION_TYPE' | translate"
        [label]="translate.instant('CREATE_SHOP.INTEGRATION_TYPE')"
        [selectedModel]="shop.shop_type"
        (changeEvent)="onShopTypeChange($event)"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
        [nullableUseNone]="false"
        [isRequired]="false"
        fxFlex="100%"
        class="px-4"
      ></app-gt2-select>
    </div>

    <div class="item-full-width">
      <app-gt2-select
        [service]="shopProdudctTypeService"
        [placeHolder]="'CREATE_SHOP.SELL_TYPE' | translate"
        [label]="translate.instant('CREATE_SHOP.SELL_TYPE')"
        [selectedModel]="shop.shop_product_type"
        (changeEvent)="onShopProductTypeChange($event)"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
        [nullableUseNone]="false"
        [isRequired]="false"
        fxFlex="100%"
        class="px-4"
      ></app-gt2-select>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!form.valid || !shop.shop_type || loading"
      color="accent"
      class="mr-16"
      (click)="onCreateShop()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
