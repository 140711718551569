export const AppRoutesName = {
  EN: {
    DASHBOARD_DETAILED: "advanced-dashboard",
    REGISTER_WIZARD: "register-wizard/:id",
    INVITE_WIZARD: "invite-wizard/:id",
    DASHBOARD_JUL: "dashboard-jul",
    PRODUCT_UPSALE: "upsale-products",
    ROOM_MANAGEMENT: "room-management",
    SUB_ROOMS_2: "basement-rooms-2",
    SUB_ROOMS: "basement-rooms",
    KB: "knowledge-base",
    FAQ: "frequently-asked-questions",
    DEVICE_NOT_SUPPORTED: "device-not-supported",
    BROWSER_NOT_SUPPORTED: "browser-not-supported",
    RELEASE_NOTES: "note-update",
    GOD_EMAIL: "god-emails",
    GOD_IMPERSONATION: "god-impersonation",
    GOD_LOCAL_STORAGE: "god-local-storage",
    GOD_REPORTS: "god-reports",
    PDF_DOWNLOADER: "pdf-downloader/:pdf",
    ERROR_404: "errors/error-404",
    ERROR_500: "errors/error-500",
    ERROR_429: "errors/error-429",
    SHOPS: "shops",
    ORGANIZATIONS: "caterer/organization",
    ORGANIZATION: "caterer/organization/:slug/:id",
    EMPLOYEE_CREATE: "employee/create",
    DASHBOARD_DATE: "dashboard/:date",
    DASHBOARD: "dashboard",
    FORGOT_PASSWORD: "password",
    ORDER: "orders/:slug/:id",
    EVENT_ROUTE: "events/:slug/:id",
    EVENT_ASSIGNMENT_ROUTE: "events/:slug/:id/affectations",
    EVENT_ASSIGNMENT_ROUTE_GROUP: "events/:slug/:id/affectations/:group",
    PROPOSITION: "proposals/:slug/:id",
    INVOICE: "invoices/:slug/:id",
    EMPLOYEE_BY_ID: "employees/:id",
    CATERER: "caterer/:slug/:id",
    PROJECT_ROUTE: "project/:slug/:id",
    PROJECTS_ROUTE: "project/:slug/:id",
    TEST: "test",
    LOGIN: "login",
    PROFILE: "profile",
    PRODUCTS: "products",
    CLIENTS: "clients",
    ENTERPRISES: "companies",
    VENUES: "places",
    ROOMS: "rooms",
    ORDERS: "orders",
    EVENTS: "events",
    PROPOSITIONS: "proposals",
    INVOICES: "invoices",
    REPORTS: "reports",
    SUPPORT: "support",
    SETTINGS: "settings",
    SEARCH: "research",
    CONTACT: "contact",
    DOCUMENTATION: "documentation",
    EMPLOYEES: "employees",
    CATERERS: "caterer",
    MATERIALS: "equipments",
    MANAGEMENT: "management",
    MODULE1: "module1",
    MAINTENANCE: "maintenance",
    CONGRESSES: "congresses",
    CONGRES: "congress",
    PROJECT: "project",
    PROJECTS: "projects",
    UPSALE: "upsale",
    JOBS_FUNCTION: "fonction-de-travail",
  },
  FR: {
    DASHBOARD_DETAILED: "tableau-de-bord-detaille",
    REGISTER_WIZARD: "register-wizard/:id",
    INVITE_WIZARD: "invite-wizard/:id",
    DASHBOARD_JUL: "tableau-de-bord-jul",
    PRODUCT_UPSALE: "produits-upsale",
    ROOM_MANAGEMENT: "gestion-salles",
    SUB_ROOMS_2: "sous-salles-2",
    SUB_ROOMS: "sous-salles",
    KB: "base-de-connaissance",
    FAQ: "foire-aux-questions",
    DEVICE_NOT_SUPPORTED: "appareil-non-supporte",
    BROWSER_NOT_SUPPORTED: "browser-non-supporte",
    RELEASE_NOTES: "note-mise-a-jour",
    GOD_EMAIL: "god-emails",
    GOD_IMPERSONATION: "god-impersonation",
    GOD_LOCAL_STORAGE: "god-local-storage",
    GOD_REPORTS: "god-reports",
    PDF_DOWNLOADER: "pdf-downloader/:pdf",
    ERROR_404: "errors/error-404",
    ERROR_500: "errors/error-500",
    ERROR_429: "errors/error-429",
    SHOPS: "commande-en-ligne",
    ORGANIZATIONS: "traiteur/organisation",
    ORGANIZATION: "traiteur/organisation/:slug/:id",
    EMPLOYEE_CREATE: "employe/create",
    DASHBOARD_DATE: "tableau-de-bord/:date",
    DASHBOARD: "tableau-de-bord",
    FORGOT_PASSWORD: "mot-de-passe",
    ORDER: "commandes/:slug/:id",
    EVENT_ROUTE: "evenements/:slug/:id",
    EVENT_ASSIGNMENT_ROUTE: "evenements/:slug/:id/affectations",
    EVENT_ASSIGNMENT_ROUTE_GROUP: "evenements/:slug/:id/affectations/:group",
    PROPOSITION: "propositions/:slug/:id",
    INVOICE: "factures/:slug/:id",
    EMPLOYEE_BY_ID: "employes/:id",
    CATERER: "traiteur/:slug/:id",
    PROJECT_ROUTE: "projet/:slug/:id",
    PROJECTS_ROUTE: "projets/:slug/:id",
    TEST: "test",
    LOGIN: "authentification",
    PROFILE: "profile",
    PRODUCTS: "produits",
    CLIENTS: "clients",
    ENTERPRISES: "entreprises",
    VENUES: "lieux",
    ROOMS: "salles",
    ORDERS: "commandes",
    EVENTS: "evenements",
    PROPOSITIONS: "propositions",
    INVOICES: "factures",
    REPORTS: "rapports",
    SUPPORT: "support",
    SETTINGS: "preferences",
    SEARCH: "recherche",
    CONTACT: "contact",
    DOCUMENTATION: "documentation",
    EMPLOYEES: "employes",
    CATERERS: "traiteur",
    MATERIALS: "materiels",
    MANAGEMENT: "gestion",
    MODULE1: "module1",
    MAINTENANCE: "maintenance",
    CONGRESSES: "congresses",
    CONGRES: "congres",
    PROJECT: "projet",
    PROJECTS: "projets",
    UPSALE: "upsale",
    JOBS_FUNCTION: "fonction-de-travail",
  },
};
