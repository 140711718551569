import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';

@Injectable()
export class ProductionZonesService implements IGetDataService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getZones() {
    // this.logger.info("ProductionZonesService.getZones()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PRODUCTION_ZONE));
  }
  public getZonesList() {
    //this.logger.info("ProductionZonesService.getZonesList()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCTION_ZONE_LIST)
    );
  }

  public getAvailableZones(uuid: any) {
    // this.logger.info("ProductionZonesService.getZones()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCTION_ZONE_AVAILABLE) + uuid
    );
  }

  public getData() {
    // this.logger.info("ProductionZonesService.getData()");
    return this.getZones();
  }

  public getDataList(): Observable<any> {
    return this.getZonesList();
  }

  public getDraggables(uuid: string): Observable<any> {
    // this.logger.info("AllergensService.getDraggables()");
    return this.getAvailableZones(uuid);
  }

  public getDroppables(uuid: string): Observable<any> {
    // this.logger.info("AllergensService.getDroppables()");
    return this.getAttachedProdZones(uuid);
  }

  public getAttachedProdZones(uuid: string) {
    // this.logger.info("AllergensService.getAttachedAllergens()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCTION_ZONE_ATTACHED) + uuid
    );
  }

  public attach(parentUUID: string, itemUUID: string): Observable<any> {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCTION_ZONE_ACTION) +
        parentUUID +
        '/attach/' +
        itemUUID
    );
  }

  public deattach(parentUUID: string, itemUUID: string): Observable<any> {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.PRODUCTION_ZONE_ACTION) +
        parentUUID +
        '/detach/' +
        itemUUID
    );
  }
}

export interface IGetDataService {
  getData(): Observable<any>;
}
