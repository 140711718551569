import { HttpClient } from '@angular/common/http';
import { Gt2ApiService } from './gt2-api.service';
import { ApiRoutes } from './ApiRoutes';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SearchResultsModel } from './models/SearchResultsModel';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class SearchService {
  searchString: any;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public search(search: Observable<string>): any {
    //this.logger.info("SearchService.search()");
    return search
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap((searchString) => this.callSearch(searchString)));
  }

  public getSearchResults(
    searchString: string
  ): Observable<SearchResultsModel> {
    //this.logger.info("SearchService.getSearchResults() -> searchString: " + searchString);
    this.searchString = searchString;
    return this.http.post<any>(this.api.createUrl(ApiRoutes.SEARCH), {
      key: searchString,
    });
  }

  public getSearchResultsTest(
    searchString: string
  ): Observable<SearchResultsModel> {
    //this.logger.info("SearchService.getSearchResultsTest() -> searchString: " + searchString);
    this.searchString = searchString;
    return this.http.post<any>(this.api.createUrl(ApiRoutes.SEARCH_TEST), {
      key: searchString,
    });
  }

  private callSearch(searchString: string): any {
    //this.logger.info("SearchService.callSearch() -> searchString: " + searchString);
    this.searchString = searchString;
    return this.http.post<any>(this.api.createUrl(ApiRoutes.SEARCH), {
      key: searchString,
    });
  }
}
