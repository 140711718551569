import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
// export class DeliveryRequestService implements IGetDataService
export class DepositRequestService implements IGetDataService {
  depositRequestListEventUUID: string = "";

  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getData(): Observable<any> {
    //this.logger.info("DepositRequestService.getData()");
    return this.getDepositRequestList();
  }

  public getDepositRequestList() {
    // this.logger.info("DepositRequestService.getDepositRequestList() -> this.depositRequestListEventUUID: " + this.depositRequestListEventUUID);
    return this.http.get<any>(this.api.createUrl(ApiRoutes.DEPOSIT_REQUEST_LIST) + this.depositRequestListEventUUID);
  }

  public createDepositRequest(deposit: any, eventUUID: string) {
    //this.logger.info("DepositRequestService.createDepositRequest() -> " + JSON.stringify(deposit));
    //this.logger.info("DepositRequestService.createDepositRequest() -> eventUUID: " + eventUUID);
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT) + "deposit-requests/" + eventUUID, deposit);
  }

  public creatOkaDepositRequest(eventUUID: string) {
    //this.logger.info("DepositRequestService.creatOkaDepositRequest() -> eventUUID: " + eventUUID);
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT) + "deposit-requests-oka/" + eventUUID, {});
  }

  public deleteDepositRequest(deposit: any, eventUUID: string) {
    //this.logger.info("DepositRequestService.deleteDepositRequest() -> " + JSON.stringify(deposit));
    //this.logger.info("DepositRequestService.deleteDepositRequest() -> eventUUID: " + eventUUID);
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.EVENT) + "deposit-requests/" + eventUUID + "/" + deposit.uuid);
  }

  public updateDepositRequest(deposit: any, eventUUID: string) {
    //this.logger.info("DepositRequestService.updateDeliveryRequest() -> " + JSON.stringify(deposit));
    //this.logger.info("DepositRequestService.updateDeliveryRequest() -> eventUUID: " + eventUUID);
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.EVENT) + "deposit-requests/" + eventUUID + "/" + deposit.uuid, deposit);
  }
}
