export const locale = {
  lang: "en-CA",
  data: {
    CREATE_PROGRAM: {
      TITLE: "New program",
      EDIT_TITLE: "Edit program",
      SELECT_PROGRAM: "Define or select a program",
      PROGRAM: "Program",
      FOR_CLIENT_ONLY: "Don't show in production",
      THEATER: "Theater",
      SCHOOL: "School",
      HALF_ROUND: "Half-round",
      BANQUET: "Banquet",
      CONFERENCE: "Conference",
      SQUARE: "Square",
      U_ASSEMBLY: "U",
      RECEPTION_FB: "F&B Reception",
      CUSTOM: "Custom",
      UNSET: "",
    },
  },
};
