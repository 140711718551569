<!--<div id="maintenance" fxLayout="column">-->

<!--<div id="maintenance-form-wrapper" fxLayout="column" fxLayoutAlign="center center">-->

<!--<div id="maintenance-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">-->

<!--<div class="logo">-->
<!--<img src="assets/images/logos/fuse.svg">-->
<!--</div>-->

<!--<div class="title">Closed for scheduled maintenance!</div>-->

<!--<div class="subtitle">-->
<!--We're sorry for the inconvenience. <br> Please check back later.-->
<!--</div>-->

<!--</div>-->

<!--</div>-->

<!--</div>-->

<div id="maintenance" fxLayout="column" fxLayoutAlign="center center">
  <div
    class="content mb-24 mt-32"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div class="error-code">{{ "ERROR_503.MAINTENANCE" | translate }}</div>

    <!--<div class="message">{{ 'ERROR_500.MESSAGE' | translate }}</div>-->

    <div class="sub-message">
      {{ "ERROR_503.SUB_MESSAGE" | translate }}
    </div>

    <a class="report-link" (click)="onPageBack()">{{
      "ERROR_503.BACK_PAGE" | translate
    }}</a>
    <a class="report-link" [routerLink]="'/' + appRoutesService.dashboard">{{
      "ERROR_503.BACK_HOME_PAGE" | translate
    }}</a>
    <a class="report-link" (click)="onVisitGt2()">{{
      "ERROR_503.GT2_SITE" | translate
    }}</a>
  </div>

  <div
    class="content-form mb-32"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-card class="" style="width: 100%">
      <mat-card-header>
        <mat-card-title class="">{{
          "ERROR_503.CONTACT_US" | translate
        }}</mat-card-title>
        <mat-card-subtitle
          *ngIf="
            setting &&
            setting.support_tel_fr &&
            translate.currentLang === 'fr-CA'
          "
          >Par téléphone :
          <a href="{{ 'tel:' + setting.support_tel_fr }}">{{
            setting.support_tel_fr
          }}</a>
        </mat-card-subtitle>
        <mat-card-subtitle
          *ngIf="
            setting &&
            setting.support_tel_en &&
            translate.currentLang === 'en-CA'
          "
          >By phone:
          <a href="{{ 'tel:' + setting.support_tel_en }}">{{
            setting.support_tel_en
          }}</a>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="setting && translate.currentLang === 'fr-CA'"
          >Par courriel :
          <a href="{{ 'mailto:' + setting.support_email_fr }}">{{
            setting.support_email_fr
          }}</a>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="setting && translate.currentLang === 'en-CA'"
          >By email:
          <a href="{{ 'mailto:' + setting.support_email_en }}">{{
            setting.support_email_en
          }}</a>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="setting && translate.currentLang === 'fr-CA'"
          >Demande de ticket :
          <a href="https://gestiontraiteur.com/demande-de-ticket/"
            >https://gestiontraiteur.com/demande-de-ticket/</a
          >
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="setting && translate.currentLang === 'en-CA'"
          >Ticket request:
          <a href="https://gestiontraiteur.com/demande-de-ticket/"
            >https://gestiontraiteur.com/demande-de-ticket/</a
          >
        </mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <div [formGroup]="form" fxLayout="column" class="p-16">
          <!--<div class="">Subject</div>-->
          <mat-card-subtitle class="mt-12">{{
            "ERROR_503.CONTACT_MESSAGE" | translate
          }}</mat-card-subtitle>
          <mat-form-field class="" appearance="outline">
            <mat-label>{{ "ERROR_503.SUBJECT" | translate }}</mat-label>
            <input
              matInput
              class="contact-input"
              placeholder="{{ 'ERROR_503.SUBJECT' | translate }}"
              formControlName="subject"
              type="text"
              [(ngModel)]="subject"
              required
            />
            <mat-error *ngIf="form.controls['subject'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>

          <!--<div class="mt-4">Message</div>-->
          <mat-form-field class="" appearance="outline">
            <mat-label>Message</mat-label>
            <textarea
              matInput
              class="contact-input"
              rows="6"
              placeholder="Message"
              formControlName="message"
              type="text"
              [(ngModel)]="message"
              required
            >
            </textarea>
            <mat-error *ngIf="form.controls['message'].invalid"
              >{{ "GENERIC.INPUT_LONG_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="end start" class="mr-20">
          <button
            mat-raised-button
            color="accent"
            class=""
            [disabled]="!form.valid"
            (click)="onSendContactForm()"
          >
            {{ "GENERIC.SEND" | translate }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
