import * as i0 from '@angular/core';
import { EventEmitter, Component, Optional, Self, Attribute, Input, HostBinding, Output, HostListener, NgModule } from '@angular/core';
import * as i1 from '@angular/forms';
import { Validators, ReactiveFormsModule } from '@angular/forms';
import * as i2 from '@angular/cdk/a11y';
import { coerceBooleanProperty, coerceArray } from '@angular/cdk/coercion';
import * as i3 from '@angular/material/core';
import { mixinTabIndex, mixinDisabled, mixinErrorState } from '@angular/material/core';
import * as i6 from '@angular/material/datepicker';
import { MatDatepicker, MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i5 from '@angular/material/chips';
import { MatChipsModule } from '@angular/material/chips';
import * as i7 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import * as i8 from '@angular/material/input';
import { MatInputModule } from '@angular/material/input';

/* eslint-disable max-classes-per-file */
function MultipleDatesComponent_mat_chip_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-chip", 6);
    i0.ɵɵlistener("removed", function MultipleDatesComponent_mat_chip_2_Template_mat_chip_removed_0_listener() {
      const item_r3 = i0.ɵɵrestoreView(_r2).$implicit;
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.removeChip(item_r3));
    });
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "date");
    i0.ɵɵelementStart(3, "mat-icon", 7);
    i0.ɵɵpipe(4, "date");
    i0.ɵɵtext(5, "cancel");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("highlighted", !!ctx_r3.color)("color", ctx_r3.color)("ngClass", ctx_r3.getClassName(item_r3));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(2, 5, item_r3, ctx_r3.format), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵattribute("aria-label", "remove " + i0.ɵɵpipeBind2(4, 8, item_r3, ctx_r3.format));
  }
}
function MultipleDatesComponent_input_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "input", 8);
    i0.ɵɵlistener("dateChange", function MultipleDatesComponent_input_3_Template_input_dateChange_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.dateChanged($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    const chipList_r6 = i0.ɵɵreference(1);
    i0.ɵɵproperty("value", ctx_r3.resetModel)("matDatepicker", ctx_r3.matDatepicker)("matDatepickerFilter", ctx_r3.matDatepickerFilter)("min", ctx_r3.min)("max", ctx_r3.max)("matChipInputFor", chipList_r6)("placeholder", ctx_r3.placeholder);
  }
}
function MultipleDatesComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 9);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    const chipList_r6 = i0.ɵɵreference(1);
    i0.ɵɵproperty("value", ctx_r3.resetModel)("min", ctx_r3.min)("max", ctx_r3.max)("matChipInputFor", chipList_r6)("placeholder", ctx_r3.placeholder);
  }
}
function MultipleDatesComponent_input_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 10);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    const chipList_r6 = i0.ɵɵreference(1);
    i0.ɵɵproperty("matChipInputFor", chipList_r6)("placeholder", ctx_r3.placeholder);
  }
}
class MultipleDatesBaseMixinBase {
  $elementRef;
  _defaultErrorStateMatcher;
  _parentForm;
  _parentFormGroup;
  ngControl;
  /**
   * Stream that emits whenever the state of the control changes such that the parent
   * `MatFormField` needs to run change detection.
   */
  stateChanges = new Subject();
  constructor($elementRef, _defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl) {
    this.$elementRef = $elementRef;
    this._defaultErrorStateMatcher = _defaultErrorStateMatcher;
    this._parentForm = _parentForm;
    this._parentFormGroup = _parentFormGroup;
    this.ngControl = ngControl;
  }
}
const _MultipleDatesBaseMixinBase = mixinTabIndex(mixinDisabled(mixinErrorState(MultipleDatesBaseMixinBase)));
/**
 * Multiple dates component.
 * @template D Date type.
 */
class MultipleDatesComponent extends _MultipleDatesBaseMixinBase {
  ngControl;
  $elementRef;
  _changeDetectorRef;
  _focusMonitor;
  _dateAdapter;
  static nextId = 0;
  /** Unique id of the element. */
  id = `ngx-multiple-dates-${MultipleDatesComponent.nextId++}`;
  describedBy = '';
  /** Whether the control is in an error state. */
  errorState = false;
  /** An object used to control when error messages are shown. */
  errorStateMatcher;
  tabIndex;
  /**
   * The date/time components to include, using predefined options or a custom format string.
   * @see {@link https://angular.io/api/common/DatePipe#usage-notes DatePipe Usage notes} for more
   * information.
   */
  format;
  /** Emits when a change event is fired on this `ngx-multiple-dates` element. */
  dateChange = new EventEmitter();
  /** Emits on a date removal. */
  remove = new EventEmitter();
  /** Whether `ngx-multiple-dates` element has focus. */
  focused = false;
  /** A name for this control that can be used by mat-form-field. */
  controlType = 'ngx-multiple-dates';
  /**
   * Model used to reset datepicker selected value, so unselect just selected date will be
   * possible.
   */
  resetModel;
  _destroy = new Subject();
  /**
   * The datepicker (or calendar - for inline view) that this `ngx-multiple-dates` element is
   * associated with.
   */
  _matDatepicker;
  /** Whether datepicker should be closed on date selected, or opened to select more dates. */
  _closeOnSelected = false;
  /** Placeholder to be shown if no value has been selected. */
  _placeholder;
  /** Whether the component is required. */
  _required = false;
  /** Whether the component is disabled. */
  _disabled = false;
  /** The value of the `ngx-multiple-dates` control. */
  _value = [];
  /**
   * Theme color palette for the component. This API is supported in M2 themes only, it has no
   * effect in M3 themes.
   * For information on applying color variants in M3, see
   * https://material.angular.io/guide/theming#using-component-color-variants.
   */
  _color = null;
  /** Function that can be used to filter out dates within the datepicker. */
  _dateFilter;
  /** The minimum valid date. */
  _min;
  /** The maximum valid date. */
  _max;
  /** Custom date classes. */
  _classes = [];
  _validator;
  _onChange = () => {};
  _onTouched = () => {};
  _onValidatorChange = () => {};
  _filterValidator = control => {
    const value = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value));
    return !this._dateFilter || !value || this._dateFilter(value) ? null : {
      matDatepickerFilter: true
    };
  };
  _minValidator = control => {
    const value = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value));
    return !this.min || !value || this._dateAdapter.compareDate(this.min, value) <= 0 ? null : {
      matDatepickerMin: {
        min: this.min,
        actual: value
      }
    };
  };
  _maxValidator = control => {
    const value = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value));
    return !this.max || !value || this._dateAdapter.compareDate(this.max, value) >= 0 ? null : {
      matDatepickerMax: {
        max: this.max,
        actual: value
      }
    };
  };
  /**
   * The datepicker (or calendar - for inline view) that this `ngx-multiple-dates` element is
   * associated with.
   */
  get matDatepicker() {
    return this._matDatepicker;
  }
  set matDatepicker(value) {
    if (!value || !(value instanceof MatDatepicker) && !(value instanceof MatCalendar)) {
      throw new TypeError(`Either "matDatepicker" or "matCalendar" attribute of "ngx-multiple-dates" is required and
        should be an instance of Angular Material Datepicker component.`);
    }
    this._matDatepicker = value;
    if (this.matDatepicker instanceof MatDatepicker) {
      this.matDatepicker.closedStream.pipe(takeUntil(this._destroy)).subscribe(() => this.blur());
    } else {
      this.matDatepicker.selectedChange.pipe(takeUntil(this._destroy)).subscribe(event => this.dateChanged({
        value: event
      }));
    }
    if (!this.matDatepicker.startAt) {
      this._setStartAt();
    }
    this._setDisabled();
    this._setDateClass();
  }
  /** Whether datepicker should be closed on date selected, or opened to select more dates. */
  get closeOnSelected() {
    return this._closeOnSelected;
  }
  set closeOnSelected(value) {
    this._closeOnSelected = coerceBooleanProperty(value);
  }
  /** Placeholder to be shown if no value has been selected. */
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(value) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  /** Whether the component is required. */
  get required() {
    return this._required;
  }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  /** Whether the component is disabled. */
  get disabled() {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this._setDisabled();
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  /** The value of the `ngx-multiple-dates` control. */
  get value() {
    if (!this._value) {
      this._value = [];
    }
    return this._value;
  }
  set value(value) {
    if (value !== this._value) {
      this.writeValue(value);
    }
  }
  /**
   * Theme color palette for the component. This API is supported in M2 themes only, it has no
   * effect in M3 themes.
   * For information on applying color variants in M3, see
   * https://material.angular.io/guide/theming#using-component-color-variants.
   */
  get color() {
    return this._color;
  }
  set color(value) {
    this._color = value;
  }
  /** Function that can be used to filter out dates within the datepicker. */
  get matDatepickerFilter() {
    return this._dateFilter;
  }
  set matDatepickerFilter(value) {
    this._dateFilter = value;
    this._onValidatorChange();
  }
  /** The minimum valid date. */
  get min() {
    return this._min;
  }
  set min(value) {
    this._min = this._getValidDateOrNull(this._dateAdapter.deserialize(value));
    this._onValidatorChange();
  }
  /** The maximum valid date. */
  get max() {
    return this._max;
  }
  set max(value) {
    this._max = this._getValidDateOrNull(this._dateAdapter.deserialize(value));
    this._onValidatorChange();
  }
  /** Custom date classes. */
  get classes() {
    return this._classes;
  }
  set classes(value) {
    this._classes = coerceArray(value);
  }
  /** Whether the `MatFormField` label should try to float. */
  get shouldLabelFloat() {
    return !this.empty || this.focused && !this.disabled;
  }
  /** Whether the select has a value. */
  get empty() {
    return !this.value || !this.value.length;
  }
  /** Whether the settled picker is a datepicker. */
  get isDatepicker() {
    return this.matDatepicker instanceof MatDatepicker;
  }
  /**
   * Creates an instance of MultipleDatesComponent.
   * @param ngControl Form control to manage component.
   * @param $elementRef A wrapper around a native element inside of a View.
   * @param _changeDetectorRef Base class that provides change detection functionality.
   * @param _focusMonitor Monitors mouse and keyboard events to determine the cause of focus events.
   * @param _dateAdapter Adapts type `D` to be usable as a date by cdk-based components that work
   * with dates.
   * @param parentForm Parent form.
   * @param parentFormGroup Parent form group.
   * @param defaultErrorStateMatcher Provider that defines how form controls behave with regards to
   * displaying error messages.
   * @param tabIndex Tab index.
   */
  constructor(ngControl, $elementRef, _changeDetectorRef, _focusMonitor, _dateAdapter, parentForm, parentFormGroup, defaultErrorStateMatcher, tabIndex) {
    super($elementRef, defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);
    this.ngControl = ngControl;
    this.$elementRef = $elementRef;
    this._changeDetectorRef = _changeDetectorRef;
    this._focusMonitor = _focusMonitor;
    this._dateAdapter = _dateAdapter;
    this.resetModel = _dateAdapter.createDate(0, 0, 1);
    const validators = [this._filterValidator, this._minValidator, this._maxValidator];
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
      if (this.ngControl.validator) {
        validators.push(this.ngControl.validator);
      }
    }
    this._validator = Validators.compose(validators);
    _focusMonitor.monitor($elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    this.tabIndex = Number(tabIndex) || 0;
  }
  ngAfterViewInit() {
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setValidators(this.validate.bind(this));
    }
    this._setStartAt();
    this._setDateClass();
  }
  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this.$elementRef.nativeElement);
  }
  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }
  /** Focuses the `ngx-multiple-dates` element. */
  focus() {
    if (!this.disabled) {
      this.focused = true;
      if (this.matDatepicker && this.matDatepicker instanceof MatDatepicker) {
        this.matDatepicker.open();
      }
      this.stateChanges.next();
    }
  }
  /** Used to leave focus from the `ngx-multiple-dates` element. */
  blur() {
    this.focused = false;
    if (!this.disabled) {
      this._onTouched();
      this._changeDetectorRef.markForCheck();
      this.stateChanges.next();
    }
  }
  writeValue(value) {
    if (Array.isArray(value)) {
      this._value = [...value];
      this._sort();
    } else {
      this._value = value;
    }
    this._onChange(value);
    this.stateChanges.next();
  }
  registerOnChange(fn) {
    this._onChange = fn;
  }
  registerOnTouched(fn) {
    this._onTouched = fn;
  }
  registerOnValidatorChange(fn) {
    this._onValidatorChange = fn;
  }
  /**
   * Sets the list of element IDs that currently describe this control.
   * @param ids Ids to set.
   */
  setDescribedByIds(ids) {
    this.describedBy = ids.join(' ');
  }
  /** Handles a click on the control's container. */
  onContainerClick() {
    if (!this.focused) {
      this.focus();
    }
  }
  /**
   * Performs synchronous validation for the control.
   * @param control The control to validate against.
   * @returns A map of validation errors if validation fails, otherwise null.
   */
  validate(control) {
    return this._validator ? this._validator(control) : null;
  }
  /**
   * Function used to add CSS classes to selected dates.
   * @param date Date to check if classes should be applied.
   * @returns CSS classes to apply.
   */
  dateClass = date => {
    let className;
    if (this.classes.length) {
      className = this.getClassName(date);
    }
    if (this._find(date) !== -1) {
      return ['selected', ...(className ? [className] : [])];
    }
    if (className) {
      return [className];
    }
    return [];
  };
  /**
   * Fires when a change event is fired on the datepicker `<input />`.
   * @param event Change event.
   */
  dateChanged(event) {
    if (event.value) {
      const date = event.value;
      if (this.value) {
        const index = this._find(date);
        if (index === -1) {
          this.value.push(date);
          this._sort();
        } else {
          this.value.splice(index, 1);
          this.remove.emit({
            type: 'datepicker',
            date
          });
        }
      }
      this.resetModel = this._dateAdapter.createDate(0, 0, 1);
      this._setStartAt();
      if (this.matDatepicker && this.matDatepicker instanceof MatDatepicker && !this.closeOnSelected) {
        const closeFn = this.matDatepicker.close;
        this.matDatepicker.close = () => {};
        this.matDatepicker['_componentRef'].instance._calendar.monthView._createWeekCells();
        setTimeout(() => this.matDatepicker.close = closeFn);
        this._changeDetectorRef.detectChanges();
      } else if (this.matDatepicker instanceof MatCalendar) {
        this.matDatepicker.monthView._createWeekCells();
      }
      this.writeValue(this.value);
    }
    this.dateChange.emit(event);
  }
  /**
   * Removes selected chip from the list.
   * @param date Value to remove.
   */
  removeChip(date) {
    if (this.value && this.value.length) {
      this._onTouched();
      const index = this._find(date);
      this.value.splice(index, 1);
      this.writeValue(this.value);
      if (this.matDatepicker instanceof MatCalendar) {
        this.matDatepicker.monthView._createWeekCells();
        this.matDatepicker.monthView._changeDetectorRef.detectChanges();
      }
      this.remove.emit({
        type: 'chip',
        date
      });
      this._changeDetectorRef.detectChanges();
    }
  }
  trackByValue(_index, item) {
    return item;
  }
  getClassName(value) {
    for (const classValue of this.classes) {
      if (this._dateAdapter.compareDate(classValue.value, value) === 0) {
        return classValue.className;
      }
    }
    return undefined;
  }
  _setStartAt() {
    if (this.matDatepicker) {
      if (this.value && this.value.length) {
        this.matDatepicker.startAt = this.value[this.value.length - 1];
      } else {
        this.matDatepicker.startAt = this._dateAdapter.today();
      }
    }
  }
  _setDisabled() {
    if (this.matDatepicker && this.matDatepicker instanceof MatDatepicker) {
      this.matDatepicker.disabled = this.disabled;
    }
  }
  _setDateClass() {
    if (this.matDatepicker) {
      const dateClassFn = this.matDatepicker.dateClass;
      this.matDatepicker.dateClass = date => {
        const classList = this.dateClass(date);
        if (dateClassFn) {
          const oldClasses = dateClassFn(date, 'month');
          if (classList.length) {
            if (oldClasses instanceof Set) {
              for (const className of classList) {
                oldClasses.add(className);
              }
            } else if (oldClasses instanceof Array) {
              for (const className of classList) {
                oldClasses.push(className);
              }
            } else if (typeof 't' === 'string') {
              return [oldClasses, ...classList].join(' ');
            } else {
              for (const className of classList) {
                oldClasses[className] = className;
              }
            }
            return oldClasses;
          }
          return oldClasses;
        }
        return classList;
      };
    }
  }
  _find(date) {
    if (!this.value) {
      return -1;
    }
    return this.value.map(value => this._dateAdapter.compareDate(value, date)).indexOf(0);
  }
  _sort() {
    if (this.value) {
      this.value.sort((lhs, rhs) => this._dateAdapter.compareDate(lhs, rhs));
    }
  }
  _getValidDateOrNull(obj) {
    return this._dateAdapter.isDateInstance(obj) && this._dateAdapter.isValid(obj) ? obj : null;
  }
  static ɵfac = function MultipleDatesComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MultipleDatesComponent)(i0.ɵɵdirectiveInject(i1.NgControl, 10), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i2.FocusMonitor), i0.ɵɵdirectiveInject(i3.DateAdapter), i0.ɵɵdirectiveInject(i1.NgForm, 8), i0.ɵɵdirectiveInject(i1.FormGroupDirective, 8), i0.ɵɵdirectiveInject(i3.ErrorStateMatcher), i0.ɵɵinjectAttribute('tabindex'));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: MultipleDatesComponent,
    selectors: [["ngx-multiple-dates"]],
    hostVars: 11,
    hostBindings: function MultipleDatesComponent_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("focus", function MultipleDatesComponent_focus_HostBindingHandler() {
          return ctx.focus();
        })("blur", function MultipleDatesComponent_blur_HostBindingHandler() {
          return ctx.blur();
        });
      }
      if (rf & 2) {
        i0.ɵɵhostProperty("id", ctx.id);
        i0.ɵɵattribute("aria-describedby", ctx.describedBy)("aria-invalid", ctx.errorState)("tabindex", ctx.tabIndex)("aria-label", ctx.placeholder)("aria-required", ctx.required)("disabled", ctx.disabled);
        i0.ɵɵclassProp("mat-form-field-invalid", ctx.errorState)("floating", ctx.shouldLabelFloat);
      }
    },
    inputs: {
      id: "id",
      errorStateMatcher: "errorStateMatcher",
      tabIndex: "tabIndex",
      format: "format",
      matDatepicker: "matDatepicker",
      closeOnSelected: "closeOnSelected",
      placeholder: "placeholder",
      required: "required",
      disabled: "disabled",
      value: "value",
      color: "color",
      matDatepickerFilter: "matDatepickerFilter",
      min: "min",
      max: "max",
      classes: "classes"
    },
    outputs: {
      dateChange: "dateChange",
      remove: "remove"
    },
    exportAs: ["ngxMultipleDates"],
    features: [i0.ɵɵProvidersFeature([{
      provide: MatFormFieldControl,
      useExisting: MultipleDatesComponent
    }]), i0.ɵɵInheritDefinitionFeature],
    decls: 7,
    vars: 6,
    consts: [["chipList", ""], ["simpleChipInput", ""], [3, "click"], ["removable", "", 3, "highlighted", "color", "ngClass", "removed", 4, "ngFor", "ngForOf", "ngForTrackBy"], ["matInput", "", "hidden", "", 3, "value", "matDatepicker", "matDatepickerFilter", "min", "max", "matChipInputFor", "placeholder", "dateChange", 4, "ngIf", "ngIfElse"], [3, "matChipInputFor", "placeholder", 4, "ngIf"], ["removable", "", 3, "removed", "highlighted", "color", "ngClass"], ["matChipRemove", ""], ["matInput", "", "hidden", "", 3, "dateChange", "value", "matDatepicker", "matDatepickerFilter", "min", "max", "matChipInputFor", "placeholder"], ["matInput", "", "hidden", "", 3, "value", "min", "max", "matChipInputFor", "placeholder"], [3, "matChipInputFor", "placeholder"]],
    template: function MultipleDatesComponent_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "mat-chip-grid", 2, 0);
        i0.ɵɵlistener("click", function MultipleDatesComponent_Template_mat_chip_grid_click_0_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.focus());
        });
        i0.ɵɵtemplate(2, MultipleDatesComponent_mat_chip_2_Template, 6, 11, "mat-chip", 3)(3, MultipleDatesComponent_input_3_Template, 1, 7, "input", 4)(4, MultipleDatesComponent_ng_template_4_Template, 1, 5, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(6, MultipleDatesComponent_input_6_Template, 1, 2, "input", 5);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        const simpleChipInput_r7 = i0.ɵɵreference(5);
        i0.ɵɵattribute("aria-label", ctx.placeholder);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.value)("ngForTrackBy", ctx.trackByValue);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.isDatepicker)("ngIfElse", simpleChipInput_r7);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", !(ctx.value == null ? null : ctx.value.length));
      }
    },
    dependencies: [i4.NgClass, i4.NgForOf, i4.NgIf, i5.MatChip, i5.MatChipGrid, i5.MatChipInput, i5.MatChipRemove, i6.MatDatepickerInput, i7.MatIcon, i8.MatInput, i4.DatePipe],
    styles: ["[_nghost-%COMP%]{display:block;outline:none!important}[_nghost-%COMP%]   span[_ngcontent-%COMP%]{opacity:0;transition:opacity .2s}.floating[_nghost-%COMP%]   span[_ngcontent-%COMP%]{opacity:1}[_nghost-%COMP%]     mat-chip-list{outline:none!important}[_nghost-%COMP%]     mat-chip-list .mat-chip-list-wrapper{min-height:18px}[_nghost-%COMP%]     mat-chip-list .mat-chip-remove{outline:none!important}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MultipleDatesComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-multiple-dates',
      providers: [{
        provide: MatFormFieldControl,
        useExisting: MultipleDatesComponent
      }],
      exportAs: 'ngxMultipleDates',
      template: "<mat-chip-grid #chipList [attr.aria-label]=\"placeholder\" (click)=\"focus()\">\r\n  <mat-chip *ngFor=\"let item of value; trackBy: trackByValue;\"\r\n            removable (removed)=\"removeChip(item)\" [highlighted]=\"!!color\"\r\n            [color]=\"color\" [ngClass]='getClassName(item)'>\r\n    {{ item | date : format }}\r\n    <mat-icon matChipRemove [attr.aria-label]=\"'remove ' + (item | date : format)\">cancel</mat-icon>\r\n  </mat-chip>\r\n  <input *ngIf=\"isDatepicker; else simpleChipInput;\" matInput hidden [value]=\"resetModel\"\r\n         [matDatepicker]=\"matDatepicker\" [matDatepickerFilter]=\"matDatepickerFilter\"\r\n         [min]=\"min\" [max]=\"max\" [matChipInputFor]=\"chipList\" (dateChange)=\"dateChanged($event)\"\r\n         [placeholder]=\"placeholder\" />\r\n  <ng-template #simpleChipInput>\r\n    <input matInput hidden [value]=\"resetModel\" [min]=\"min\" [max]=\"max\"\r\n           [matChipInputFor]=\"chipList\" [placeholder]=\"placeholder\" />\r\n  </ng-template>\r\n  <input *ngIf=\"!value?.length\" [matChipInputFor]=\"chipList\" [placeholder]=\"placeholder\" />\r\n</mat-chip-grid>\r\n",
      styles: [":host{display:block;outline:none!important}:host span{opacity:0;transition:opacity .2s}:host.floating span{opacity:1}:host ::ng-deep mat-chip-list{outline:none!important}:host ::ng-deep mat-chip-list .mat-chip-list-wrapper{min-height:18px}:host ::ng-deep mat-chip-list .mat-chip-remove{outline:none!important}\n"]
    }]
  }], () => [{
    type: i1.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i2.FocusMonitor
  }, {
    type: i3.DateAdapter
  }, {
    type: i1.NgForm,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i1.FormGroupDirective,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i3.ErrorStateMatcher
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['tabindex']
    }]
  }], {
    id: [{
      type: Input
    }, {
      type: HostBinding
    }],
    describedBy: [{
      type: HostBinding,
      args: ['attr.aria-describedby']
    }],
    errorState: [{
      type: HostBinding,
      args: ['attr.aria-invalid']
    }, {
      type: HostBinding,
      args: ['class.mat-form-field-invalid']
    }],
    errorStateMatcher: [{
      type: Input
    }],
    tabIndex: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.tabindex']
    }],
    format: [{
      type: Input
    }],
    dateChange: [{
      type: Output
    }],
    remove: [{
      type: Output
    }],
    matDatepicker: [{
      type: Input
    }],
    closeOnSelected: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.aria-label']
    }],
    required: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.aria-required']
    }],
    disabled: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.disabled']
    }],
    value: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    matDatepickerFilter: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    classes: [{
      type: Input
    }],
    shouldLabelFloat: [{
      type: HostBinding,
      args: ['class.floating']
    }],
    focus: [{
      type: HostListener,
      args: ['focus']
    }],
    blur: [{
      type: HostListener,
      args: ['blur']
    }]
  });
})();

/**
 * Date class item.
 * @template D Date type.
 */
class DateClass {
  /** Date value. */
  value;
  /** CSS class name(s). */
  className;
}

/**
 * An event used for `ngx-multiple-dates` date removal.
 * @template D Date type.
 */
class DateRemoveEvent {
  /** Event type Specifies where the date was removed from (chip, datepicker). */
  type;
  /** Date removed. */
  date;
}
class NgxMultipleDatesModule {
  static ɵfac = function NgxMultipleDatesModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxMultipleDatesModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxMultipleDatesModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, ReactiveFormsModule, MatChipsModule, MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMultipleDatesModule, [{
    type: NgModule,
    args: [{
      declarations: [MultipleDatesComponent],
      imports: [CommonModule, ReactiveFormsModule, MatChipsModule, MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule],
      exports: [MultipleDatesComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-multiple-dates
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DateClass, DateRemoveEvent, MultipleDatesComponent, NgxMultipleDatesModule };
