import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { MatDialog } from '@angular/material/dialog';
import { CatererService } from '../../caterer/caterer.service';
import { CatererModel } from '../../caterer/caterer-model';
import { CrudEmployeeService } from '../../crud/crud-employee/crud-employee.service';
import * as _ from 'lodash';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-user-employee-setting',
  templateUrl: './user-employee-setting.component.html',
  styleUrls: ['./user-employee-setting.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class UserEmployeeSettingComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  model!: CatererModel;
  authData: any;
  form!: UntypedFormGroup;
  numericRegex = /^[a-zA-Z0-9]+$/;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public catererService: CatererService,
    public employeeService: CrudEmployeeService,
  ) {
    super();

    this.translationLoader.loadTranslations(english, french);

    this.createForms(0);

    this.loadData();
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public createForms(min: number): void {
    this.form = this.formBuilder.group({
      max_user: [
        '',
        [
          Validators.required,
          Validators.pattern(this.numericRegex),
          Validators.min(min),
        ],
      ],
    });
    //this.logger.info("UserEmployeeSettingComponent() -> min: " + min);
    // //this.logger.info("UserEmployeeSettingComponent() -> users_count: " + this.model.users_count);
  }

  public loadData(): void {
    this.catererService.onCatererChanged.subscribe(
      (caterer) => {
        if (caterer) {
          this.model = caterer;
          this.createForms(this.model.users_count);
          this.form?.markAllAsTouched();
          this.form?.markAsDirty();
          // this.form.controls['max_user']. = true;
        }
      },
      (error) => {
        this.logger.error(
          'UserEmployeeSettingComponent.loadData() -> ERROR: ' +
            JSON.stringify(error),
        );
      },
    );
  }

  public updateMaxUsers(): void {
    // _.cloneDeep(this.model)
    this.catererService.updateMaxUser(this.model).subscribe(
      (response) => {
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
        );
        //   this.model = response.data;
        //   this.createForms(this.model.users_count);
      },
      (error) => {
        this.toastr.error('', this.translate.instant('GENERIC.ERRORS.GENERIC'));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    );
  }
}
