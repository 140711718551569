<div fxLayout="column">
  <div>
    <ngx-dropzone
      fxFlex
      (change)="onSelect($event)"
      [disableClick]="true"
      [multiple]="false"
      [maxFileSize]="10485760"
      accept="{{ supportedFileType }}"
      fxFlex
      class="dropzone-no-outline"
    >
      <mat-table
        #table
        fxFlex
        class=""
        matSort
        matSortStart="desc"
        [@animateStagger]="{ value: '50' }"
        [dataSource]="dataSource"
      >
        <!-- icon Column -->
        <ng-container cdkColumnDef="icon">
          <mat-header-cell
            fxFlex="58px"
            style="padding: 0; margin: 0"
            *cdkHeaderCellDef
          ></mat-header-cell>
          <mat-cell
            fxFlex="58px"
            style="
              padding: 0;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            *cdkCellDef="let file"
          >
            <mat-icon
              *ngIf="(file.name | fileNameExtensionIconPipe) === 'pdf'"
              class="s-24"
              >{{ getIcon("PDF") }}
            </mat-icon>
            <mat-icon
              *ngIf="(file.name | fileNameExtensionIconPipe) === 'xls'"
              class="fa-lg"
              fontIcon="{{ getIcon('XLS') }}"
              fontSet="fa"
            ></mat-icon>
            <mat-icon
              *ngIf="(file.name | fileNameExtensionIconPipe) === 'image'"
              class="s-24"
            >
              {{ getIcon("IMAGE") }}</mat-icon
            >
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container cdkColumnDef="name">
          <mat-header-cell fxFlex *cdkHeaderCellDef mat-sort-header
            >{{ "GENERIC.NAME" | translate }}
          </mat-header-cell>
          <mat-cell fxFlex *cdkCellDef="let file">
            <div
              *ngIf="file.loading"
              fxFlex
              class="p-16"
              xLayout="row"
              fxLayoutAlign="start center"
            >
              <mat-progress-bar [value]="file.progress"></mat-progress-bar>
            </div>
            <div *ngIf="!file.loading">{{ file.name }}</div>
          </mat-cell>
        </ng-container>

        <!-- fileSize -->
        <ng-container cdkColumnDef="fileSize">
          <mat-header-cell fxFlex="142px" *cdkHeaderCellDef mat-sort-header
            >{{ "GENERIC.FILESIZE" | translate }}
          </mat-header-cell>
          <mat-cell
            fxFlex="142px"
            *cdkCellDef="let file"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <div *ngIf="!file.loading && file.fileSize !== 0">
              {{ file.fileSize }}
            </div>
          </mat-cell>
        </ng-container>

        <!-- action Column -->
        <ng-container cdkColumnDef="action">
          <mat-header-cell
            fxFlex="100px"
            style="
              padding: 0;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            *cdkHeaderCellDef
          ></mat-header-cell>
          <mat-cell
            fxFlex="100px"
            style="
              padding: 0;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            *cdkCellDef="let file"
          >
            <button
              mat-icon-button
              (click)="onOpen(file)"
              [disabled]="file.loading"
            >
              <mat-icon class="secondary-text icon-btn s-16">{{
                getIcon("FILE_DOWNLOAD")
              }}</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="onDeleteAttachedFile(file)"
              [disabled]="file.loading"
            >
              <mat-icon class="secondary-text icon-btn s-16">{{
                getIcon("DELETE")
              }}</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *cdkRowDef="let employee; columns: displayedColumns"
          class=""
          style="cursor: default"
        >
        </mat-row>
      </mat-table>
    </ngx-dropzone>
  </div>

  <div
    *ngIf="attachedFiles && attachedFiles.length === 0"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="center center"
    class="p-16 mat-body-2"
  >
    <ngx-dropzone
      (change)="onSelect($event)"
      [multiple]="false"
      [maxFileSize]="10485760"
      accept="{{ supportedFileType }}"
      fxFlex
      class="p-8"
    >
      <ngx-dropzone-label>{{
        "GENERIC.NO_ATTACHED_FILES_DROPZONE" | translate
      }}</ngx-dropzone-label>
    </ngx-dropzone>
  </div>
</div>

<div>
  <input
    type="file"
    #fileUpload
    id="fileUpload"
    name="fileUpload"
    accept="application/msword,application/vnd.ms-excel,application/pdf,image/*,.xlsx"
    style="display: none"
  />
</div>
