<div *ngIf="historicModel">
  <div
    *ngIf="historicModel.created_at"
    fxLayout="row"
    fxLayoutAlign="start start"
    class="mat-caption"
  >
    <div>
      {{ "CREATED_AT.CREATED_ON" | translate }} {{ historicModel.created_at }}
    </div>
    <div *ngIf="historicModel.created_by">
      &nbsp;{{ "CREATED_AT.BY" | translate }} {{ historicModel.created_by }}
    </div>
  </div>

  <div
    *ngIf="historicModel.updated_at"
    fxLayout="row"
    fxLayoutAlign="start start"
    class="mat-caption"
  >
    <div>
      {{ "CREATED_AT.LAST_MODIFICATION" | translate }}
      {{ historicModel.updated_at }}
    </div>
    <div *ngIf="historicModel.updated_by && historicModel.updated_by !== ''">
      &nbsp;{{ "CREATED_AT.BY" | translate }} {{ historicModel.updated_by }}
    </div>
  </div>
</div>
