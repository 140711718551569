import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { TagItemModel, TagsService } from './tags.service';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatIconModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatInputModule,
    MatChipsModule,
    Gt2PrimengModule,
  ],
  providers: [TagsService],
})
export class TagsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() tagFamily!: string;
  @Input() tagModel!: string;
  @Input() tagModelUUID!: string;
  @Input() tagPlaceholder?: string;
  // tags: TagItemModel[] = [];
  tagsColors: any = {};

  //
  visible = true;
  selectable: boolean = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new UntypedFormControl();
  filteredTags: Observable<string[]>; // Used for autocomplete list
  tags: any[] | any = [];
  // tags: string[] = ['Lemon'];
  allTags: string[] = [];
  // allTags: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  autoCompleteTags?: TagItemModel[];

  @ViewChild('tagInput') tagInput!: ElementRef;

  constructor(
    public translate: TranslateService,
    public tagsService: TagsService,
    private logger: NGXLogger,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    super();

    this.tagsColors['tags-crm'] = 'accent';
    this.tagsColors['tags-order-kind'] = 'primary';

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allTags.slice(),
      ),
    );
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // this.logger.info("tagFamily: " + this.tagFamily);
    // this.logger.info("tagModel: " + this.tagModel);
    // this.logger.info("tagModelUUID: " + this.tagModelUUID);

    this.addAutoCompleteTagsByFamily(this.tagFamily);
    this.addTagsByModelFamily(this.tagModel, this.tagModelUUID, this.tagFamily);
  }

  ngOnDestroy() {}

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // this.logger.info("add() -> value: " + value);
    if (this.tags.indexOf(value) !== -1) {
      return;
    }

    // Add our tag
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);

    this.tagsService
      .addTagItemByModelFamily(
        this.tagModel,
        this.tagModelUUID,
        this.tagFamily,
        value,
      )
      .subscribe(
        (response: any) => {
          // this.logger.info("TagsComponent.add() -> SUCCESS:" + JSON.stringify(response));
        },
        (error: any) => {
          this.logger.error(
            'TagsComponent.add() -> ERROR:' + JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  remove(fruit: string): void {
    const index = this.tags.indexOf(fruit);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const tagValue: string = event.option.viewValue;
    if (this.tags.indexOf(tagValue) !== -1) {
      return;
    }

    this.tags.push(tagValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    // this.logger.info("selected(): " + tagValue);

    // this.logger.info("++++++++ > " + JSON.stringify(this.tags));

    const tag: any = this.autoCompleteTags?.find((item) => {
      return item.name === tagValue;
    });

    // this.logger.info("--> autoCompleteTags: " + JSON.stringify(this.autoCompleteTags));
    // this.logger.info("--> tag: " + JSON.stringify(tag));

    this.tagsService
      .addNewTagItemByModelFamily(this.tagModel, this.tagModelUUID, tag.uuid)
      .subscribe(
        (response: any) => {
          // this.logger.info("TagsComponent.add() -> SUCCESS:" + JSON.stringify(response));
        },
        (error: any) => {
          this.logger.error(
            'TagsComponent.add() -> ERROR:' + JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(
      (fruit) => fruit.toLowerCase().indexOf(filterValue) === 0,
    );
  }

  // public addTagsByFamily(family: string): void
  // {
  //     this.tagsService.getTagsByFamily(family).subscribe( response =>
  //     {
  //         this.logger.info("TagsComponent.getTagsByFamily() -> SUCCESS:" + JSON.stringify(response));
  //         // this.tags = this.tags.concat(response.data);
  //     }, error =>
  //     {
  //         this.logger.info("TagsComponent.getTagsByFamily() -> SUCCESS:" + JSON.stringify(error));
  //     });
  // }

  public addAutoCompleteTagsByFamily(family: string): void {
    this.tagsService.getTagsByFamily(family).subscribe(
      (response: any) => {
        // this.logger.info("TagsComponent.getTagsByFamily() -> SUCCESS:" + JSON.stringify(response));
        // this.tags = this.tags.concat(response.data);
        this.autoCompleteTags = response.data;
        let i: number = 0;
        const length: number = response.data.length;
        for (i; i < length; i++) {
          this.allTags.push(response.data[i].name);
          this.filteredTags = this.tagCtrl.valueChanges.pipe(
            startWith(null),
            map((fruit: string | null) =>
              fruit ? this._filter(fruit) : this.allTags.slice(),
            ),
          );
        }
      },
      (error: any) => {
        this.logger.error(
          'TagsComponent.getTagsByFamily() -> ERROR:' + JSON.stringify(error),
        );
      },
    );
  }

  public addTagsByModelFamily(
    model: string,
    modelUUID: string,
    family: string,
  ): void {
    this.tagsService.addTagsByModelFamily(model, modelUUID, family).subscribe(
      (response: any) => {
        // this.logger.info("TagsComponent.getTagsByFamily() -> SUCCESS:" + JSON.stringify(response));
        // this.tags = this.tags.concat(response.data);
        let i: number = 0;
        const length: number = response.data.length;
        for (i; i < length; i++) {
          this.tags.push(response.data[i].name);
        }
      },
      (error: any) => {
        this.logger.error(
          'TagsComponent.getTagsByFamily() -> ERROR:' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }
}
