export const locale = {
  lang: "en-CA",
  data: {
    CREATE_CASHING: {
      DEPOSIT_TITLE: "New cashing - Deposit",
      DEPOSIT_EDIT_TITLE: "Edit cashing - Deposit",
      INVOICE_TITLE: "Edit cashing - Invoice",
      INVOICE_EDIT_TITLE: "Edit cashing - Invoice",
      MESSAGE_TITLE: "Cashing",
      MESSAGE_MESSAGE: "Vous n’avez pas de demande de dépot disponible à l’encaissement, ni de facture",
      PAYMENT_MODES: "Modes of payment",
      REFERENCE_NUMBER: "Reference number",
      DEPOSIT_REQUEST: "Deposit request",
      ACCOUNT: "Account",
      UDEM_PROJECT: "UM Poject",
      ACTIVITY: "Activity",
      TYPE_SRCE: "Type Srce",
    },
  },
};
