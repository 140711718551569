import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { EditorModule } from 'primeng/editor';
import { FuseSidebarModule } from '../../../../@fuse/components/sidebar/sidebar.module';
import { FuseWidgetModule } from '../../../../@fuse/components/widget/widget.module';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { RoomComponent } from '../room/room.component';
import { RoomsComponent } from './rooms.component';
import { RoomsService } from './rooms.service';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableSizePipeModule } from '../../modules/table-size.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { CommentSectionComponent } from '../../components/comment-section/comment-section.component';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AddressComponent } from '../../components/address/address.component';
import { CreatedUpdatedAtComponent } from '../../components/created-updated-at/created-updated-at.component';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { SectionHistoryPanelComponent } from '../../components/section-history-panel/section-history-panel.component';
import { ContactComponent } from '../../components/contact/contact.component';
import { ContactService } from '../../components/contact/contact.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.FR.ROOMS + '/:slug' + '/:id',
    component: RoomComponent,
  },
  {
    path: AppRoutesName.FR.SUB_ROOMS + '/:slug' + '/:id',
    component: RoomComponent,
  },
  {
    path: AppRoutesName.FR.SUB_ROOMS_2 + '/:slug' + '/:id',
    component: RoomComponent,
  },
  {
    path: AppRoutesName.FR.ROOMS,
    component: RoomsComponent,
  },
  {
    path: AppRoutesName.EN.ROOMS + '/:slug' + '/:id',
    component: RoomComponent,
  },
  {
    path: AppRoutesName.EN.SUB_ROOMS + '/:slug' + '/:id',
    component: RoomComponent,
  },
  {
    path: AppRoutesName.EN.SUB_ROOMS_2 + '/:slug' + '/:id',
    component: RoomComponent,
  },
  {
    path: AppRoutesName.EN.ROOMS,
    component: RoomsComponent,
  },
];

@NgModule({
  declarations: [RoomsComponent, RoomComponent],
  imports: [
    GenericAlertComponent,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    FormsModule,
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSortModule,
    NgxPermissionsModule,
    MatInputModule,
    TranslateModule,
    TableSizePipeModule,
    CdkTableModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    RouterModule.forChild(routes),
    FuseWidgetModule,
    NgxChartsModule,
    EditorModule,
    AddressComponent,
    ContactComponent,
    CommentSectionComponent,
    CreatedUpdatedAtComponent,
    FuseSidebarModule,
    SectionHistoryPanelComponent,
  ],
  exports: [RoomsComponent, RoomComponent],
  providers: [RoomsService, DatatableHelperService, ContactService],
})
export class RoomsModule {}
