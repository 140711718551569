import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '../../../api/user-settings.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { LedgerAccountService } from '../../../api/ledger-account.service';
import { TaxesService } from '../../../api/taxes.service';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-delivery-settings',
  templateUrl: './delivery-settings.component.html',
  styleUrls: ['./delivery-settings.component.scss'],
  standalone: true,
  imports: [
    Gt2SelectComponent,
    TranslateModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    FlexLayoutModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
})
export class DeliverySettingsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() settingsModel: any;
  form: UntypedFormGroup;

  constructor(
    public router: Router,
    private logger: NGXLogger,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    public ledgerAccountService: LedgerAccountService,
    public taxesService: TaxesService,
    public settingsService: UserSettingsService,
    public translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      delivery_fixed_cost: [
        '',
        [
          ValidatorHelper.isPositive,
          ValidatorHelper.isNumber0To1000000,
          Validators.required,
        ],
      ],
      delivery_max_distance_km: [
        '',
        [
          ValidatorHelper.isPositive,
          ValidatorHelper.isNumber0To1000000,
          Validators.required,
        ],
      ],
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // this.logger.info("DeliverySettingsComponent.ngAfterViewInit() -> " + JSON.stringify(this.settingsModel));
    // this.logger.info("DeliverySettingsComponent.ngAfterViewInit() -> delivery_max_distance_km: " + this.settingsModel.delivery_max_distance_km);
  }

  ngOnDestroy(): void {}

  public onSaveData(): void {
    // this.logger.info("DeliverySettingsComponent.onSaveData() -> ");

    this.settingsService
      .updateDelivery(this.settingsModel, this.settingsModel.uuid)
      .subscribe(
        (response: any) => {
          // this.logger.info("DeliverySettingsComponent.onSaveData() SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
        },
        (error: any) => {
          this.logger.error(
            'DeliverySettingsComponent.onSaveData() ERROR: ' +
              JSON.stringify(error),
          );
          this.toastr.error(
            '',
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      );
  }

  public onLedgerAccountChange(event: any): void {
    // this.logger.info("DeliverySettingsComponent.onLedgerAccountChange() -> event: " + JSON.stringify(event));
    this.settingsModel.ledger_account_delivery = event;
  }

  public onTaxesSelected(taxes: any): void {
    // this.logger.info("DeliverySettingsComponent.onTaxesSelected() -> taxes: " + JSON.stringify(taxes));
    this.settingsModel.delivery_tax = taxes;
  }
}
