export const locale = {
  lang: "en-CA",
  data: {
    ORDER_PRODUCTS: {
      TITLE: "Add products",
      TITLE_CREATE: "Add products",
      LEFT_LIST_LABEL: "Drag & drop or use the + button to add product",
      RIGHT_LIST_LABEL: "List of products to add",
      CREATE_PRODUCT: "Create product",
      SEARCH: "search by internal name, external name or product code",
      SEARCH_PRODUCT: "Perform a search for your product.",
    },
  },
};
