export const locale = {
  lang: "fr-CA",
  data: {
    CLIENTS_CATEGORIES: {
      SETTINGS: "Préférences",
      CREATE_NEW_CATEGORY: "Nouvelle catégorie",
      CREATE_SUCCESS: "Catégorie créer avec succès!",
      EDIT_SUCCESS: "Catégorie modifiée avec succès!",
      DELETE_SUCCESS: "Catégorie effacée avec succès!",
      DELETE_CONFIRM: "Êtes vous sûr de vouloir effacer la catégorie {{value}}?",
      NEW_CATEGORY_NAME: "Nom de la nouvelle catégorie",
      CATEGORY_NAME: "Nom de catégorie",
    },
  },
};
