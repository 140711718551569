import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class ErrorDialogComponent implements OnInit, OnDestroy {
  // public errorMessage: string;
  // public errorString: string;
  public error: any;
  public code: string = '500';
  public errorsData: any[] = [];
  public useError456Template: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    private translate: TranslateService,
  ) {
    this.errorsData = [];
  }

  ngOnInit() {
    // if (this.error)
    // {
    //   this.errorString = this.error.toString();
    // }
  }

  ngOnDestroy() {}

  public setCode(code: string): void {
    this.code = code;
  }

  public setError(error: any): void {
    if (!error.error.title) {
      if (this.code == '422') {
        //error.error.title = "Attention - Erreur de validation";
        error.error.title = this.translate.instant(
          'GENERIC.ERROR_DIALOG_422_TITLE',
        );
      }
    }

    if (this.code == '422') {
      //error.error.message = "Les données fournies n'étaient pas valides.";
      error.error.message = this.translate.instant(
        'GENERIC.ERROR_DIALOG_422_MESSAGE',
      );
    }

    this.error = error;
    if (this.error.error && this.error.error.errors) {
      for (const each of Object.keys(this.error.error.errors)) {
        this.errorsData.push({
          key: each,
          data: this.error.error.errors[each],
        });
      }
    }
  }
}
