export const locale = {
  lang: "en-CA",
  data: {
    GENERAL_CONDITIONS: {
      PROPOSAL_CONDITION: "Proposals/Orders condition",
      DEPOSITE_INVOICE_CONDITION: "Deposit invoice condition",
      EVENT_INVOICE_CONDITION: "Event invoice condition",
      ADJUSMENT_INVOICE_CONDITION: "Adjusment invoice condition",
      CREDIT_NOTE_CONDITION: "Credit note condition",

      PROPOSAL_CONDITION_DESCRIPTION:
        "Court texte expliquant ce qu'est Proposal condition... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      DEPOSITE_INVOICE_CONDITION_DESCRIPTION:
        "Court texte expliquant ce qu'est Deposit invoice condition... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      EVENT_INVOICE_CONDITION_DESCRIPTION:
        "Court texte expliquant ce qu'est Event invoice condition... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      ADJUSMENT_INVOICE_CONDITION_DESCRIPTION:
        "Court texte expliquant ce qu'est Adjusment invoice condition... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      CREDIT_NOTE_CONDITION_DESCRIPTION:
        "Court texte expliquant ce qu'est Credit note condition... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  },
};
