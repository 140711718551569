export const locale = {
  lang: "fr-CA",
  data: {
    EVENTS: {
      EVENT: "Événement",
      LOGISTIC: "Logistique",
      ACCOUNTING: "Encaissement et dépôt",
      DOCUMENTS: "Documents",
      DOCUMENT_NUMBER: "# de document",
      NO_DOCUMENTS: "Aucun document",
      FLOW: "Déroulement",
      NO_FLOW: "Aucun déroulement",
      GUEST: "Convive",
      GUESTS: "Convives",
      NO_GUEST: "Aucun convive",
      DELIVERY_REQUEST: "Demande de livraison",
      NO_DELIVERY_REQUEST: "Aucune demande de livraison",
      NO_TAKEOUT_REQUEST: "Aucune demande de cueillette",
      STATS: "Statistiques",
      DEPOSIT_REQUEST: "Demande de dépôt",
      CREATE_DEPOSIT_REQUEST: "Nouvelle demande de dépôt",
      NO_DEPOSIT_REQUEST: "Aucune demande de dépôt",
      DEPOSIT_IN: "Encaissement",
      CREATE_DEPOSIT_IN: "Nouvel encaissement",
      NO_DEPOSIT_IN: "Aucun encaissement",
      INVOICES: "Factures",
      PO_NUMBER: "Numéro du bon de commande",
      EVENT_DATE: "Date de l'événement",
      EVENT_DATE_START: "Date début de l'événement",
      EVENT_DATE_END: "Date fin de l'événement",
      EVENT_TIME_START: "Heure début de l'événement",
      EVENT_TIME_END: "Heure fin de l'événement",
      EVENT_TIME: "Heure de l'événement",
      VISIT_ORDER: "Voir commande",
      VISIT_INVOICE: "Voir facture",
      ORDER_EXIST: "Commande existante",
      ADD_PROPOSAL: "Ajouter une proposition",
      ADD_FLOW: "Ajouter un déroulement",
      ADD_PROGRAM: "Ajouter un programme",
      ADD_GUEST: "Ajouter un type de convive",
      ORDER_CLIENT: "Commandé par client",
      INVOICE_CLIENT: "Facturé au client",
      NO_CLIENT_SELECTED: "Aucun client sélectionné",
      SELECT_CLIENT: "Sélectionner un client",
      SELECT_SAME_CLIENT: "Même client",
      SEE_NOTE: "Voir note",
      BALANCE: "Balance",
      TOTAL_PER_GUEST: "Total par convive",
      NEXT_PAYMENT_DATE: "Prochain paiement",
      SELECT_CLIENT_FIRST: "Choisir votre client en premier",
      SET_CLIENT_ADDRESS: "Aucune adresse pour ce client.",
      SELECT_DELIVERY_TYPE: "Livraison ou cueillette",
      DOES_NOT_NEED_DELIVERY: "Le client viendra au comptoir",
      NO_VENUES_SELECTED: "Aucun lieu sélectionné",
      SELECT_VENUE_PLACE: "Choisir un lieu",
      SELECT_ORDERING_CLIENT: "Choisir client commandé",
      SELECT_ENTERPRISE_ORDERING_CLIENT: "Choisir entreprise du client commandé",
      ADD_PLACE: "Ajouter une nouvelle place",
      ADD_ROOM: "Ajouter une nouvelle salle",
      CAPACITY: "Capacité",
      ROYALTY: "Redevances",
      DATE_DUE: "Date d'échéance",
      NET_TOTAL: "Montant net",
      RECEIVED_ON: "Reçu le",
      RECEIVED_FROM: "Reçu par",
      MODE: "Mode",
      DEPOSIT_NUMBER: "Numéro de dépôt",
      INVOICE_NUMBER: "Numéro de facture",
      REFERENCE_NUMBER: "Référence",
      RESPONSIBLE_EMPLOYEE: "Employé responsable",
      SELECT_RESPONSIBLE_EMPLOYEE: "Sélectionner l'employé responsable",
      ORDERING_LOCAL: "Sélectionner la langue du client qui a commandé",
      INVOICING_LOCAL: "Sélectionner la langue du client facturé",
      CANCEL_EVENT: "Annuler l'événement",
      ABORT_EVENT: "Abandonner l'événement",
      CANCEL_EVENT_SUCESS: "Événement annulé!",
      ABORT_EVENT_SUCESS: "Événement abandonné!",
      EVENT_TYPE: "Type d'événement",
      SELECT_EVENT_TYPE: "Sélectionner le type d'événement",
      REPORT_RECEIVED: "Émettre un reçu",
      DELIVERY_REQUEST_SPOT_NOT_SELECTTED: "Un lieu doit être sélectionné avant de pouvoir créer des demandes de livraison",
      COMPLETE_INVOICE_DIALOG_MESSAGE: "La balance de la facture est maintenant de 0$ ou inférieur. Voulez-compléter la facture avec le statut payé ?",
      CANCEL_EVENT_DIALOG_MESSAGE: "Êtes-vous sûr(e) de vouloir annuler cet événement?",
      ABORT_EVENT_DIALOG_MESSAGE: "Êtes-vous sûr(e) de vouloir abandonner cet événement?",
      REOPEN_EVENT: "Ouvrir l'événement",
      RECLOSE_EVENT: "Fermer l'événement",
      REOPEN_EVENT_DIALOG_MESSAGE: "Êtes-vous sûr(e) de vouloir réouvrir cet événement?",
      ORG_IDENTITY: "Identité de l’organisation",
      ORG_IDENTITY_SELECT: "Sélectionner une identité",
      ORG_IDENTITY_HINT: "Sélectionner une identité dans le cas où le client souhaite récupérer sa commande dans un autre point de cueillette que l’identité sélectionnée dans la section information.",
      ORG_IDENTITY_DROPDOWN_ITEM: "Identité",
      ORG_IDENTITY_HELP: "Vous pouvez, au besoin, appliquer une identité autre à votre événement.",
      TAKEOUT_REQUEST: "Demande de cueillette",
      ADD_DELIVERY_REQUEST: "Ajouter une demande de livraison",
      ADD_TAKEOUT_REQUEST: "Ajouter une demande de cueillette",
      ASSIGNEMENTS: "Affectations",
      ASSIGNEMENTS_REPORTS: "Rapports affectations",
      ASSIGNMENT_SALE: "Affectations - ventes",
      ASSIGNMENT_COST: "Affectations - coûts",
      ASSIGNMENT_ATTENDANCE: "Relevé des présences",
      ADD_SPORADIC_ADDRESS: "Ajouter une adresse sporadique",
      SPORADIC_ADDRESS: "Livraison adresse sporadique",
      FILES: "Fichiers",
      ATTACH_FILE: "Attacher un fichier",
      NO_ATTACHED_FILES: "Aucun fichier",

      UNSUPPORTED_FILE_TITLE: "Type de fichier non supporté.",
      UNSUPPORTED_FILE_MESSAGE: "Les types de fichiers que vous pouvez attacher sont: pdf, document word, excel et images.",
      TOO_MANY_FILE_MESSAGE: "Vous ne pouvez glisser qu'un fichier à la fois.",
      FILE_TO_BIG_MESSAGE: "Le fichier dépasse la taille maximale de 10 mb.",
      ROOM_STATUS: "Status de la salle",
      NO_PROGRAMS: "Aucun programmes",
      WITH_STAFF: "Avec du personnel",
      TAKEOUT: "Cueillette au comptoir",
      DELIVERY_ONLY: "Livraison seulement",
      DELIVERY_INSTALL: "Livrer et installer",
      CONGRESS_BLOQUES: "Le client ne peut être changer si un congrès est attaché!",
      CHANGELOG: "Avis de changement",
      CREATE_OKA_DEPOSIT_REQUEST: "Générer calcul de dépôt",
      RECEIPT_NOTICE: "Avis de réception",
      STORAGE_NOTICE: "Avis d’entreposage",
      ORGANIZER_PERSON: "Personne garante et/ou responsable (personne présente à l'événement)",
      RECEIPT_NOTICE_LOCAL: "Local événement avec permis de réunion",
      STORAGE_DATE: "Date début d'entreposage",
      STORAGE_DATE_END: "Date fin d'entreposage",
      RECEIPT_NOTICE_HOUR: "Heure",
      START_TIME: "Heure de début",
      END_TIME: "Heure de fin",
      EVENT_NAME: "Nom de l’événement",
      EVENT_PLACE: "Lieu de l’événement(Local)",
      UBR_MANAGER_EMAIL: "Email du reponsable UBR",
      UBR_CODE: "Code UBR",
      VALID_UBR_CODE: "Le champ code UBR doit être un code valide",
      NO_UBR_RESULTS: "Aucun résultat",
    },
  },
};
