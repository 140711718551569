import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class AllergensService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}
  getData(): Observable<any> {
    throw new Error("Method not implemented.");
  }

  public getAllAllergens(uuid: string): any {
    // this.logger.info("AllergensService.getAllAllergens()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.ALLERGENS_AVAILABLE) + uuid);
  }

  public getAttachedAllergens(uuid: string): any {
    // this.logger.info("AllergensService.getAttachedAllergens()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.ALLERGENS_ATTACHED) + uuid);
  }

  public getDraggables(uuid: string): Observable<any> {
    // this.logger.info("AllergensService.getDraggables()");
    return this.getAllAllergens(uuid);
  }

  public getDroppables(uuid: string): Observable<any> {
    // this.logger.info("AllergensService.getDroppables()");
    return this.getAttachedAllergens(uuid);
  }

  public attach(parentUUID: string, itemUUID: string): Observable<any> {
    return this.http.get<any>(this.api.createUrl(ApiRoutes.ALLERGENS_ACTION) + parentUUID + "/attach/" + itemUUID);
  }

  public deattach(parentUUID: string, itemUUID: string): Observable<any> {
    return this.http.get<any>(this.api.createUrl(ApiRoutes.ALLERGENS_ACTION) + parentUUID + "/detach/" + itemUUID);
  }
}

export interface IGetDataService {
  getData(): Observable<any>;
}
