import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { WrappingService } from '../../../api/wrapping.service';
import { UserSettingsService } from '../../../api/user-settings.service';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-wrapping-default',
  templateUrl: './wrapping-default.component.html',
  styleUrls: ['./wrapping-default.component.scss'],
  standalone: true,
  imports: [
    Gt2SelectComponent,
    MatButtonModule,
    CommonModule,
    FormsModule,
    MatIconModule,
    FlexLayoutModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    TranslateModule,
  ],
})
export class WrappingDefaultComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() settingsModel: any;

  constructor(
    public router: Router,
    private logger: NGXLogger,
    private toastr: ToastrService,
    public translate: TranslateService,
    public wrappingService: WrappingService,
    public settingsService: UserSettingsService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onProductWrappingSelected(wrapping: any): void {
    //this.logger.info("WrappingDefaultComponent.onProductWrappingSelected() -> wrapping: " + JSON.stringify(wrapping));
    this.settingsModel.product_wrapping = wrapping;
  }

  public onOrderWrappingSelected(wrapping: any): void {
    //this.logger.info("WrappingDefaultComponent.onOrderWrappingSelected() -> wrapping: " + JSON.stringify(wrapping));
    this.settingsModel.order_wrapping = wrapping;
  }

  public onDeliveryWrappingSelected(wrapping: any): void {
    //this.logger.info("WrappingDefaultComponent.onDeliveryWrappingSelected() -> wrapping: " + JSON.stringify(wrapping));
    this.settingsModel.delivery_request_wrapping = wrapping;
  }

  public onSaveData(): void {
    //this.logger.info("WrappingDefaultComponent.onSaveData() -> ");
    this.settingsService
      .updateDefaultWrapping(this.settingsModel, this.settingsModel.uuid)
      .subscribe(
        (response: any) => {
          //this.logger.info("DeliverySettingsComponent.onSaveData() SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
          );
        },
        (error: any) => {
          //this.logger.info("DeliverySettingsComponent.onSaveData() ERROR: " + JSON.stringify(error));
          this.toastr.error(
            '',
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
        },
      );
  }
}
