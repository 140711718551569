import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { FileSaverService } from 'ngx-filesaver';
import { NGXLogger } from 'ngx-logger';
import { GT2FirebaseService } from './gt2-firebase.service';
import { Settings } from '../gt2/settings/settings';

@Injectable()
export class FileService {
  private httpClientBypass: HttpClient;
  currentDownload: any[] = [];

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private logger: NGXLogger,
    private _FileSaverService: FileSaverService
  ) {
    this.httpClientBypass = new HttpClient(handler);
  }

  public createFileName(
    prefix: string = '',
    name: string,
    fileExt: string = '',
    useDate: boolean = true
  ): string {
    let date: string = '';
    if (useDate) {
      const dateFormat = new DatePipe('en-US'); // require('dateformat');
      const oDate = new Date();
      date =
        '-' +
        dateFormat
          ?.transform(oDate, 'medium')
          ?.toString()
          ?.split(',')
          ?.join('-')
          ?.split(' ')
          ?.join('');
    }
    fileExt = fileExt.substr(0, 1) === '.' ? fileExt : '.' + fileExt;
    prefix = prefix !== '' && prefix !== null ? prefix + '-' : '';
    return prefix + name + date + fileExt;
  }

  public downloadFile(fileURL: string, fileName?: string): any {
    //this.logger.info("FileService.downloadFile() -> fileURL: " + fileURL);
    const newURL: string = fileURL + '/' + GT2FirebaseService.CLIENT_KEY;
    //this.logger.info("FileService.downloadFile() -> newURL with clienKey: " + newURL);
    return this.http.get(newURL);
  }

  public postDownloadFile(fileURL: string, data: any) {
    // this.logger.info("FileService.postDownloadFile() -> fileURL: " + fileURL);
    // this.logger.info("FileService.postDownloadFile() -> data: " + JSON.stringify(data));
    const newURL: string = fileURL + '/' + GT2FirebaseService.CLIENT_KEY;
    //this.logger.info("FileService.postDownloadFile() -> newURL with clienKey: " + newURL);
    return this.http.post(newURL, data);
  }

  public async processDownloadFile(
    fileURL: string,
    fileName?: string // : Promise<void>
  ) {
    //this.logger.info("FileService.processDownloadFile() -> INFO!! DOWNLOADING fileURL: " + fileURL);

    if (this.currentDownload.indexOf(fileURL) !== -1) {
      this.logger.error(
        'FileService.processDownloadFile() -> ERROR!! DOWNLOADING FILE IS ALREADY DOWNLOADING, fileURL: ' +
          fileURL
      );
      throw new Error(
        'ERROR!! DOWNLOADING FILE IS ALREADY DOWNLOADING, url: ' + fileURL
      );
    }

    return new Promise((resolve, reject) => {
      this.currentDownload.push(fileURL);
      this.httpClientBypass
        .get(fileURL, {
          observe: 'response',
          responseType: 'blob',
        })
        .subscribe(
          (res: any) => {
            // this.logger.info(
            //     "PDF Header response filename: " +
            //         res.headers.get("filename")
            // );
            this._FileSaverService.save(res.body, res.headers.get('filename'));
            this.removeCurrentDownload(fileURL);
            resolve(res);
          },
          (error) => {
            this.logger.error(
              'FileService.processDownloadFile() -> ERROR: ' +
                JSON.stringify(error)
            );
            this.removeCurrentDownload(fileURL);
            reject(error);
          }
        );
    });
  }

  public removeCurrentDownload(fileURL: string): void {
    const index: number = this.currentDownload.indexOf(fileURL);
    if (index !== -1) {
      this.currentDownload.splice(index, 1);
    }
  }

  public async downloadResource(fileURL: string): Promise<Blob | undefined> {
    const file = await this.http
      .get<Blob>(fileURL, { responseType: 'blob' as 'json' })
      .toPromise();
    return file;
  }

  public printEnabled(): boolean {
    return Settings.featureHtmlPrint;
  }

  public pdfEnabled(): boolean {
    return Settings.featurePdfDownload;
  }

  public excelEnabled(): boolean {
    return Settings.featureExcelDownload;
  }
}
