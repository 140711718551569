export const locale = {
  lang: "fr-CA",
  data: {
    DRINKS_VALIDATION: {
      ERROR_MESSAGE: "Une ou plusieurs boissons non pas été confirmé veuillez les confirmer en cochant la case confirmé",
      DRINKS_NAME: "Nom du produit",
      DRINKS_INITIAL_QTY: "Qté initiale",
      DRINKS_REAL_QTY: "Qté réelle",
      DRINKS_CONFIRMED: "Confirmé",
      CANCEL: "Annuler",
    },
  },
};
