export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_CLIENT_ENTERPRISE: {
      TITLE: "Nouveau client et entreprise",
      CLIENT_FIRST_NAME: "Prénom client",
      CLIENT_LAST_NAME: "Nom de famille client",
      ENTERPRISE_NAME: "Nom de l'entreprise",
      CREATE_CLIENT_ENTERPRISE_SUCCESS: "Client et entreprise créés avec succès",
    },
  },
};
