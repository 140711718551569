import { CongressesModule } from './gt2/content/congresses/congresses.module';
import { DashboardDetailedModule } from './gt2/content/dashboard-detailed/dashboard-detailed.module';
import { BrokenResponseInterceptor } from './gt2/interceptors/BrokenResponseInterceptor';
import { TestErrorService } from './gt2/api/test-error.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AssignmentsModule } from './gt2/content/events/event/assignments/assignments.module';
import { registerLocaleData } from '@angular/common';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  MatMomentDateModule,
  provideMomentDateAdapter,
} from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FuseModule } from '../@fuse/fuse.module';
import { TranslateModule } from '@ngx-translate/core';
import { fuseConfig } from './fuse-config';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FileSaverModule } from 'ngx-filesaver';
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { MessagesModule } from 'primeng/messages';
import { environment } from '../environments/environment';
import { AuthenticationService } from './gt2/api/auth-service.service';
import { Gt2ApiService } from './gt2/api/gt2-api.service';
import { SearchService } from './gt2/api/search.service';
import { UsersService } from './gt2/api/users.service';
import { AppRoutes } from './gt2/const/AppRoutes';
import { BrowserNotSupportedModule } from './gt2/content/browser-not-supported/browser-not-supported.module';
import { CatererModule } from './gt2/content/caterer/caterer.module';
import { ClientsModule } from './gt2/content/clients/clients.module';
import { ContactModule } from './gt2/content/contact/contact.module';
import { CrudEmployeesModule } from './gt2/content/crud/crud-employees/crud-employees.module';
import { CrudOrganizationModule } from './gt2/content/crud/crud-organization/crud-organization.module';
import { DashboardModule } from './gt2/content/dashboard/dashboard.module';
import { DeviceNotSupportedModule } from './gt2/content/device-not-supported/device-not-supported.module';
import { EventsModule } from './gt2/content/events/events.module';
import { FaqModule } from './gt2/content/faq/faq.module';
import { GodEmailsModule } from './gt2/content/god/god-emails/god-emails.module';
import { GodLocalStorageModule } from './gt2/content/god/god-local-storage/god-local-storage.module';
import { InviteWizardModule } from './gt2/content/invite-wizard/invite-wizard.module';
import { KnowledgeBaseModule } from './gt2/content/knowledge-base/knowledge-base.module';
import { ManagementModule } from './gt2/content/management/management.module';
import { MaterialsModule } from './gt2/content/materials/materials.module';
import { ProductsModule } from './gt2/content/products/products.module';
import { ReportsModule } from './gt2/content/reports/reports.module';
import { RoomManagementModule } from './gt2/content/room-management/room-management.module';
import { RoomsModule } from './gt2/content/rooms/rooms.module';
import { SearchResultsSharedDataService } from './gt2/content/search-results/search-results-shared-data.service';
import { SearchResultsModule } from './gt2/content/search-results/search-results.module';
import { SettingsModule } from './gt2/content/settings/settings.module';
import { TestModule } from './gt2/content/test/test.module';
import { VenuesModule } from './gt2/content/venues/venues.module';
import { Login2Module } from './gt2/fuse/login-2/login-2.module';
import { AuthInterceptor } from './gt2/interceptors/AuthInterceptor';
import { JwtInterceptor } from './gt2/interceptors/JwtInterceptor';
import { AppRoutesService } from './gt2/services/app-routes.service';
import { ModulesService } from './gt2/services/modules.service';
import { SettingsService } from './gt2/settings/settings.service';
import { MatPaginatorIntlCro } from './localization/MatPaginatorIntlCro';
import { ForgotPassword2Module } from './main/pages/authentication/forgot-password-2/forgot-password-2.module';
import { Error404Module } from './main/pages/errors/404/error-404.module';
import { Error500Module } from './main/pages/errors/500/error-500.module';
import { Error429Module } from './main/pages/errors/error-429/error-429.module';
import { MaintenanceModule } from './main/pages/maintenance/maintenence.module';
import { GT2FirebaseService } from './services/gt2-firebase.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CatererService } from './gt2/content/caterer/caterer.service';
import { ReleaseNoteModule } from './gt2/content/release-note/release-note.module';
import { ExternalEnvService } from './gt2/api/external-env.service';
import { AppConfig } from './gt2/api/app-config.service';
import { ShopsModule } from './gt2/content/shops/shops.module';
import { DocumentationModule } from './gt2/content/documentation/documentation.module';
import { GodReportsModule } from './gt2/content/god/god-reports/god-reports.module';
import { ProductUpsaleModule } from './gt2/content/products/product-upsale/product-upsale.module';
import { DashboardJulModule } from './gt2/content/dashboard-jul/dashboard-jul.module';
import { MediaObserver } from '@angular/flex-layout';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AppComponent } from './app.component';
import { ErrorDialogComponent } from './gt2/components/dialogs/error-dialog/error-dialog.component';
import { ProcessesBottomSheetComponent } from './gt2/components/dialogs/processes-bottom-sheet/processes-bottom-sheet.component';
import { TestErrorResultComponent } from './gt2/components/dialogs/test-error-result/test-error-result.component';
import { FirebaseProcessComponent } from './gt2/components/firebase-process/firebase-process.component';
import { RichTextLocaleComponent } from './gt2/components/rich-text-locale/rich-text-locale.component';
import { TopAlertsComponent } from './gt2/components/top-alerts/top-alerts.component';
import { GenericAlertComponent } from './gt2/components/generic-alert/generic-alert.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import 'moment/locale/fr';
import { OrdersModule } from './gt2/content/orders/orders.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { progressInterceptor } from 'ngx-progressbar/http';

const appRoutes: Routes = [
  {
    path: '**',
    redirectTo: AppRoutes.DASHBOARD,
  },
  {
    path: '*',
    redirectTo: AppRoutes.LOGIN,
  },
];

/////////////// --o BUGSNAG
/* TEMP - Martin - 
const bugsnagClient = Bugsnag.start(environment.bugsnag);

// create a factory which will return the bugsnag error handler
export function errorHandlerFactory(): any {
  return new BugsnagErrorHandler(bugsnagClient);
}
}*/
///////////////  BUGSNAG o--

// create a factory for AngularFire, used mainly for debugging
export function angularFireConfigFactory(): any {
  return environment.firebase;
}

registerLocaleData(localeFr, 'fr', localeFrExtra);
@NgModule({
  declarations: [],
  imports: [
    ErrorDialogComponent,
    GenericAlertComponent,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled',
    }),

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),

    // App modules
    NgxStripeModule.forRoot('pk_test_MPoh82Qv83GEFOTrJAYNpLuc'), // TEST
    Login2Module,
    ForgotPassword2Module,
    InviteWizardModule,
    TestModule,
    LoggerModule.forRoot({
      level: environment.logLevel,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    GodLocalStorageModule,
    GodReportsModule,
    NgxSpinnerModule,
    GodEmailsModule,
    CrudEmployeesModule,
    CrudOrganizationModule,
    NgxPermissionsModule.forRoot(),
    AngularFireModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    ToastrModule.forRoot(),
    ProductsModule,
    VenuesModule,
    RoomsModule,
    ReportsModule,
    RoomManagementModule,
    DashboardDetailedModule,
    OrdersModule,
    MaintenanceModule,
    ClientsModule,
    SettingsModule,
    SearchResultsModule,
    DashboardModule,
    DashboardJulModule,
    MaterialsModule,
    ManagementModule,
    FaqModule,
    KnowledgeBaseModule,
    ContactModule,
    Error500Module,
    Error404Module,
    Error429Module,
    MaintenanceModule,
    MessagesModule,
    DeviceNotSupportedModule,
    BrowserNotSupportedModule,
    FileSaverModule,
    EventsModule,
    AssignmentsModule,
    ProcessesBottomSheetComponent,
    FirebaseProcessComponent,
    TopAlertsComponent,
    CatererModule,
    NgxMaterialTimepickerModule,
    ReleaseNoteModule,
    DocumentationModule,
    ShopsModule,
    RichTextLocaleComponent,
    ScrollingModule,
    TestErrorResultComponent,
    CongressesModule,
    ProductUpsaleModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    provideMomentDateAdapter(),
    Gt2ApiService,
    AuthenticationService,
    ExternalEnvService,
    CookieService,

    // --o AngularFire / Firebase
    {
      provide: FIREBASE_OPTIONS,
      useFactory: angularFireConfigFactory,
    },
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfig) => () => config.load(),
      deps: [AppConfig],
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrokenResponseInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlCro,
    },
    provideHttpClient(withInterceptors([progressInterceptor])),
    NGXLogger,
    SettingsService,
    UsersService,
    MediaObserver,
    SearchService,
    SearchResultsSharedDataService,
    AppRoutesService,
    DeviceDetectorService,
    GT2FirebaseService,
    ModulesService,
    CatererService,
    TestErrorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
