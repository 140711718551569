import { ProjectsService } from './../../../../api/projects.service';
import { GT2CalendarUtils } from '../../../../utils/GT2CalendarUtils';
import { ValidatorHelper } from '../../../../validators/ValidatorHelper';
import { Settings } from '../../../../settings/settings';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { CreateEventComponent } from '../../../../components/dialogs/create-event/create-event.component';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import { GT2DateAdapter } from '../../../../utils/GT2DateAdapter';
import { DateAdapter } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { locale as english } from './../../../../components/dialogs/create-event/i18n/en-CA';
import { locale as french } from './../../../../components/dialogs/create-event/i18n/fr-CA';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-event-project',
  templateUrl: './create-event-project.component.html',
  styleUrls: ['./create-event-project.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgxMaterialTimepickerModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    FlexLayoutModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
})
export class CreateEventProjectComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  subType: string = CreateEventComponent.ORDER_SUBTYPE;
  form: UntypedFormGroup;
  event: any;
  eventModel: any;
  loading: boolean = false;
  event_date: any = null;
  keepEventInfo: boolean = false;
  proposal: any;
  projectUUID!: string;

  constructor(
    public override dialogRef: MatDialogRef<CreateEventProjectComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public projectsService: ProjectsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    this.event = {
      name: null,
      event_date: new Date().toISOString(),
      event_date_end: new Date().toISOString(),
      event_time: null,
      event_time_end: null,
    };

    // //this.logger.info("CreateEventProjectComponent.constructor() -> 0. this.event.toISOString(): " + this.event.event_date);
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      event_date: ['', [Validators.required]],
    });

    if (data && data.event) {
      this.eventModel = data.event;
    }
    // //this.logger.info("CreateEventProjectComponent.constructor() -> eventModel: " + JSON.stringify(this.eventModel));

    if (data && data.subType) {
      this.subType = data.subType;
      //this.logger.info("CreateEventProjectComponent.constructor() -> subType: " + this.subType);
    }

    if (data && data.keepEventInfo) {
      this.keepEventInfo = data.keepEventInfo;
    }
    //this.logger.info("CreateEventProjectComponent.constructor() -> keepEventInfo: " + this.keepEventInfo);

    if (data && data.projectUUID) {
      this.projectUUID = data.projectUUID;
    }

    if (data && data.proposal) {
      this.proposal = data.proposal;
      this.event.name =
        this.translate.instant('CREATE_EVENT.COPY') + this.proposal.name;
      this.event.event_date = this.eventModel.informations.event_date;
      this.event.event_date_end = this.eventModel.informations.event_date_end;
      this.event.event_time = this.eventModel.informations.event_time;
      this.event.event_time_end = this.eventModel.informations.event_time_end;

      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            ValidatorHelper.excludeStrings([this.proposal.name]),
            Validators.required,
          ],
        ],
        event_date: ['', [Validators.required]],
        event_date_end: [''],
      });
    }

    if (data && data.event_date) {
      this.event.event_date = data.event_date;
      // HACK: https://stackoverflow.com/questions/31096130/how-to-json-stringify-a-javascript-date-and-preserve-timezone
      /*this.event.event_date.toJSON = function (): any {
                return moment(this).format();
            };*/
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.event = null;
    this.proposal = null;
  }

  public test(): void {
    //this.logger.info("CreateEventProjectComponent.test() -> event.event_time: " + this.event.event_time);
  }

  public onDatePickerChange(event: any): void {
    //this.logger.info("CreateEventProjectComponent.onDatePickerChange() -> event: " + event.value);
    const date: Date = new Date(event.value);
    const dateFormated = GT2CalendarUtils.formatDateForAPI(date);
    //this.logger.info("CreateEventProjectComponent.onDatePickerChange() -> date formatDateForAPI: " + dateFormated);
    this.event.event_date = dateFormated;
  }

  public onDatePickerChangeEnd(event: any): void {
    //this.logger.info("CreateOrderComponent.onDatePickerChangeEnd() -> event: " + event.value);
    if (!event.value || event.value === null) {
      return;
    }
    const date: Date = new Date(event.value);
    //this.logger.info("CreateEventProjectComponent.onDatePickerChangeEnd() -> date formatDateForAPI: " + GT2CalendarUtils.formatDateForAPI(date));
    this.event.event_date_end = GT2CalendarUtils.formatDateForAPI(date);
  }

  public onCreateEvent(): void {
    this.loading = true;
    if (this.form.valid) {
      if (this.subType === CreateEventComponent.PROPOSAL_SUBTYPE) {
        this.doCreateProposal();
      } else if (this.subType === CreateEventComponent.ORDER_SUBTYPE) {
        this.doCreateOrder();
      }
    }
  }

  public doCreateOrder(): void {
    //this.logger.info("CreateEventProjectComponent.doCreateOrder() -> event: " + JSON.stringify(this.event));
    //this.logger.info("CreateEventProjectComponent.doCreateOrder() -> this.event.event_date: " + this.event.event_date);
    this.projectsService
      .createEventOrder(this.event, this.projectUUID)
      .subscribe(
        (response: any) => {
          // //this.logger.info("CreateEventProjectComponent.doCreateOrder() -> SUCCESS: " + JSON.stringify(response));
          this.dialogRef.close(response.data);
        },
        (error: any) => {
          this.logger.error(
            'CreateEventProjectComponent.doCreateOrder() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
          this.loading = false;
        },
      );
  }

  public doCreateProposal(): void {
    // //this.logger.info("CreateEventProjectComponent.doCreateProposal()");
    this.projectsService
      .createEventProposal(this.event, this.projectUUID)
      .subscribe(
        (response: any) => {
          // //this.logger.info("CreateEventProjectComponent.doCreateProposal() -> SUCCESS: " + JSON.stringify(response));
          this.dialogRef.close(response.data);
        },
        (error: any) => {
          this.logger.error(
            'CreateEventProjectComponent.doCreateProposal() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
          this.loading = false;
        },
      );
  }
}
