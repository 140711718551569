import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseLogin2Component } from './login-2.component';
import { LoginErrorsDialogComponent } from './login-errors-dialog/login-errors-dialog.component';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { AppRoutesName } from '../../const/AppRoutesName';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
import { MatButtonModule } from '@angular/material/button';

const routes = [
  {
    path: AppRoutesName.FR.LOGIN,
    component: FuseLogin2Component,
  },
  {
    path: AppRoutesName.EN.LOGIN,
    component: FuseLogin2Component,
  },
];

@NgModule({
  declarations: [FuseLogin2Component],
  imports: [
    CommonModule,
    MatInputModule,
    LoginErrorsDialogComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    FormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    TranslateModule.forChild(),
  ],
})
export class Login2Module {
  constructor(translate: TranslateService) {
    // Initialisé la langue
    const local_lang =
      localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID) ||
      LocalizationConfig.DEFAULT_LANGUAGE;

    // Ajouter les traductions locales
    if (local_lang === 'en-CA')
      translate.setTranslation(english.lang, english.data, true);
    else translate.setTranslation(french.lang, french.data, true);

    // Définir la langue à utiliser
    translate.setDefaultLang(LocalizationConfig.DEFAULT_LANGUAGE);
    translate.use(local_lang);
  }
}
