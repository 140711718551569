import { Injectable } from '@angular/core';
import { AppRoutes } from '../const/AppRoutes';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class AppRoutesService {
  public enterprises: string = AppRoutes.ENTERPRISES;
  public employees: string = AppRoutes.EMPLOYEES;
  public materials: string = AppRoutes.MATERIALS;
  public venues: string = AppRoutes.VENUES;
  public rooms: string = AppRoutes.ROOMS;
  public sub_rooms: string = AppRoutes.SUB_ROOMS;
  public sub_rooms2: string = AppRoutes.SUB_ROOMS_2;
  public clients: string = AppRoutes.CLIENTS;
  public products: string = AppRoutes.PRODUCTS;
  public dashboard: string = AppRoutes.DASHBOARD;
  public jobsFunctions: string = AppRoutes.JOBS_FUNCTION;
  public settings: string = AppRoutes.SETTINGS;
  public shops: string = AppRoutes.SHOPS;
  public congresses: string = AppRoutes.CONGRESSES;
  public projects: string = AppRoutes.PROJECTS;
  public project: string = AppRoutes.PROJECT;
  public upsale: string = AppRoutes.UPSALE;
  public password: string = AppRoutes.FORGOT_PASSWORD;
  public login: string = AppRoutes.LOGIN;
  public auth: any;
  // public shopsDetailed: string = AppRoutes.SHOPS;
  // public shopsInternal: string = AppRoutes.SHOPS;

  constructor(public logger: NGXLogger) {}

  public getRouteConstatnt(routeID: string): string {
    // this.logger.info("getRouteConstatnt() -> " + AppRoutes[routeID]);
    return AppRoutes[routeID];
  }
}
