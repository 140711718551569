export const locale = {
  lang: "en-CA",
  data: {
    SELECT_PLACE: {
      TITLE: "Select a room",
      SELECTED_ROOM: "Selected room",
      SELECT_ENTERPRISE_NO_ROOM: "Select enterprise without selecting a room",
    },
  },
};
