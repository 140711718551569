<div style="max-height: 100%">
  <h1 matDialogTitle class="h1-title">API CALL BENCHING</h1>

  <div>
    <div *ngIf="callStack">
      <div fxLayout="column" style="height: 400px" fusePerfectScrollbar>
        <div
          *ngFor="let call of callStack"
          class="call-item"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <!--CLIENT-->
          <div>
            <div class="" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="column" fxLayoutAlign="center center">
                <div class="blue-fg" style="font-size: 20px">
                  {{ call.seconds }}
                </div>
                <div class="" style="font-size: 12px">second(s)</div>
              </div>

              <div fxLayout="column" fxLayoutAlign="start start">
                <div class="ml-16">{{ call.url }}</div>
                <div class="ml-16 mat-body-2">{{ call.method }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-16" fxLayout="row" fxLayoutAlign="center end">
    <button
      mat-raised-button
      (click)="compDialogRef.close(false)"
      class="mat-fuse-dark-700-bg"
    >
      {{ "GENERIC.OK" | translate }}
    </button>
    <button
      mat-raised-button
      (click)="clearStack()"
      class="mat-fuse-dark-700-bg ml-8"
    >
      {{ "GENERIC.CLEAR" | translate }}
    </button>
  </div>
</div>
