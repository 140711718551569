import { CommonModule, Location } from '@angular/common';
import { ProjectsService } from './../../../../api/projects.service';
import { ItemUrlFinder } from '../../../../utils/item-url-finder';
import { ICanDeactivateComponent } from '../../../../gards/can-deactivate-guard.service';
import { GT2FormPageAbstract } from '../../../../content/abstract/GT2FormPageAbstract';
import { ModulesService } from '../../../../services/modules.service';
import { DatatableHelperService } from '../../../../../services/datatable-helper.service';
import { RolesService } from '../../../../services/roles.service';
import { ToastrService } from 'ngx-toastr';
import { Gt2ApiService } from '../../../../api/gt2-api.service';
import { FileService } from '../../../../../services/file.service';
import { merge, of } from 'rxjs';
import { startWith, switchMap, catchError, map } from 'rxjs/operators';
import { PrintService } from '../../../../../services/print.service';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
} from '@angular/forms';
import { CustomDateFormatter } from './../../../calendar/CustomDateFormatter';
import { CalendarDateFormatter } from 'angular-calendar';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortable, MatSortModule } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatTableModule,
} from '@angular/material/table';
import { GroupActionComponent } from '../../project/group-action/group-action.component';
import { EventsService } from '../../../../api/events.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { TableSizePipeModule } from '../../../../modules/table-size.module';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-events-box',
  templateUrl: './events-box.component.html',
  styleUrls: ['./events-box.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatTabsModule,
    FlexLayoutModule,
    TableSizePipeModule,
    MatMenuModule,
    MatSortModule,
    MatTooltipModule,
    MatButtonModule,
    MatInputModule,
    CdkTableModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class EventsBoxComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  //
  @Input() projectUUID!: string;
  @Input() congresUUID!: string;
  //
  dataSource = new MatTableDataSource();
  eventCheckboxes!: any;
  selectedEvents: any[] = [];
  declare dialogRef: any;
  confirmDialogRef: any;
  selectedTypeEvent!: string;
  loading = false;
  displayedColumns = [
    'uuid',
    'name',
    'event_date',
    'event_time',
    'room',
    'status',
    'event_number',
    'actions',
  ];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  @Output() onMeta = new EventEmitter<any>();
  //
  constructor(
    private formBuilder: UntypedFormBuilder,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public location: Location,
    private logger: NGXLogger,
    public print: PrintService,
    public fileService: FileService,
    public api: Gt2ApiService,
    public toastr: ToastrService,
    public rolesService: RolesService,
    public datatableHelperService: DatatableHelperService,
    private projectService: ProjectsService,
    private adapter: DateAdapter<any>,
    public moduleService: ModulesService,
    public eventsService: EventsService,
    private spinner: NgxSpinnerService,
  ) {
    super();
    //this.logger.info("EventsBoxComponent.constructor()");
  }

  ngOnInit(): void {
    //this.logger.info("EventsBoxComponent.ngOnInit()");
  }

  ngAfterViewInit() {
    this.loadData();
  }

  ngOnDestroy() {
    //this.logger.info("EventsBoxComponent.ngOnDestroy()");
  }
  public loadData() {
    //this.logger.info("EventsBoxComponent.ngAfterViewInit()");
    //
    this.sort.sort({ id: 'event_time', start: 'asc' } as MatSortable);
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.isLoadingResults = true;

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });
          return this.projectService.getAllProjectEventsWithPagination(
            this.projectUUID,
            this.sort?.active,
            this.sort?.direction,
            this.paginator?.pageIndex,
            this.paginator?.pageSize,
          );
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          this.onMeta.next(data.meta);
          return data.data;
        }),
      )
      .pipe(
        catchError((error) => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          this.handleAPIError(error, this.dialog, null, null);
          return of([]);
        }),
      )
      .subscribe((data) => {
        this.dataSource.data = data;
        this.resetCheckboxes();
      });
  }
  public resetCheckboxes(): void {
    this.eventCheckboxes = {};
    let events: any = <any>{};
    events = this.dataSource.data;
    events.map((event: any) => {
      this.eventCheckboxes[event.uuid] = false;
    });
  }

  public canDeactivate(): boolean {
    return true;
  }

  public onNavigateToItem(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }
  public onOpenEventInNewTab(event: any): void {
    window.open(ItemUrlFinder.getItemURL(event), '_blank');
  }

  public onOpenPage(item: any): void {
    // this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public onSelectedChange(eventUUID: any): void {
    //this.logger.info("onSelectedChange() - > " + eventUUID);
    if (this.selectedEvents.length > 0) {
      const index = this.selectedEvents.indexOf(eventUUID);

      if (index !== -1) {
        this.selectedEvents.splice(index, 1);
        return;
      }
    }

    // If we don't have it, push as selected
    this.selectedEvents.push(eventUUID);
  }
  eventAllCheckboxes: boolean = false;
  public onSelectedAllEventChange(): void {
    for (const event in this.eventCheckboxes) {
      const selected = this.eventCheckboxes[event];
      this.eventCheckboxes[event] = this.eventAllCheckboxes;
      if (selected !== this.eventAllCheckboxes) {
        this.onSelectedChange(event);
      }
    }
  }
  public moveEvents(): void {
    this.confirmDialogRef = this.dialog.open(GroupActionComponent, {
      width: '500px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        title: this.translate.instant('GENERIC.GROUP_ACTION_MOVE_EVENT'),
        congressUidd: this.congresUUID,
        showEventTypeInput: true,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show('reportSpinner');
        this.eventsService
          .moveToProjectAction(result.uuid, this.selectedEvents)
          .subscribe(
            (response: any) => {
              this.loadData();
              this.hideSpinner();
              this.toastr.success(
                this.translate.instant('GENERIC.MOVED_SUCCESSFULLY'),
              );
            },
            (error: any) => {
              this.hideSpinner();
              this.logger.error(
                'OrderComponent.groupVisibleToClient() -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.loading = false;
              this.handleAPIError(error, this.dialog, null, null);
            },
          );
      }
    });
  }
  public hideSpinner(): void {
    setTimeout(() => {
      this.spinner.hide('reportSpinner');
    }, 50);
  }
  public copyEvents(): void {
    this.confirmDialogRef = this.dialog.open(GroupActionComponent, {
      width: '500px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        title: this.translate.instant('GENERIC.GROUP_ACTION_COPY_EVENT'),
        showEventTypeInput: false,
        congressUidd: this.congresUUID,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show('reportSpinner');
        this.eventsService
          .copyToProjectAction(
            result.uuid,
            this.congresUUID,
            this.selectedEvents,
          )
          .subscribe(
            (response: any) => {
              this.loadData();
              this.hideSpinner();
              this.toastr.success(
                this.translate.instant('GENERIC.CLONED_SUCCESSFULLY'),
              );
            },
            (error: any) => {
              this.hideSpinner();
              this.logger.error(
                'OrderComponent.groupVisibleToClient() -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.loading = false;
              this.handleAPIError(error, this.dialog, null, null);
            },
          );
      }
    });
  }
}
