export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_FLOW: {
      TITLE: "Nouveau déroulement",
      EDIT_TITLE: "Modifier déroulement",
      SELECT_FLOW: "Définir ou sélectionner un déroulement",
      FOR_CLIENT_ONLY: "Ne pas afficher en production",
    },
  },
};
