import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { of, merge } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { ClientsCategoryModel } from '../../../api/clients-categories.service';
import { CrudDatatableGenericService } from '../../../api/crud-datatable-generic.service';
import { LedgerAccountService } from '../../../api/ledger-account.service';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { Settings } from '../../../settings/settings';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { CrudDatatableGenericModel } from '../../crud/crud-datatable-generic/crud-datatable-generic.component';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { LedgerAccountCreateComponentComponent } from './ledger-account-create-component/ledger-account-create-component.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CdkTableModule } from '@angular/cdk/table';
import { TableSizePipeModule } from '../../../modules/table-size.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-ledger-account',
  templateUrl: './ledger-account.component.html',
  styleUrls: ['./ledger-account.component.scss'],
  standalone: true,
  imports: [
    MatTooltipModule,
    MatPaginatorModule,
    MatIconModule,
    TableSizePipeModule,
    FormsModule,
    MatTableModule,
    CdkTableModule,
    FlexLayoutModule,
    MatSortModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
    CommonModule,
  ],
  providers: [CrudDatatableGenericService],
  animations: fuseAnimations,
})
export class LedgerAccountComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() headerLabel!: string;
  serviceURL: string;
  dataSource = new MatTableDataSource();
  hasRow: boolean = false;
  displayedColumns = ['name', 'edit', 'delete'];
  selected!: ClientsCategoryModel;
  editSelected: any;
  form: UntypedFormGroup;
  newCategory!: string;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  confirmDialogRef: any;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  currentLanguage?: string;

  constructor(
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public translate: TranslateService,
    public ledgerAccountService: LedgerAccountService,
    public datatableHelperService: DatatableHelperService,
    private crudDatatableGenericService: CrudDatatableGenericService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      account_number: [
        '',
        [ValidatorHelper.isInteger, ValidatorHelper.isPositive],
      ],
    });

    this.serviceURL = ApiRoutes.LEDGER_ACCOUNT;
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.crudDatatableGenericService.getAll(
            this.serviceURL,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
          );
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          this.hasRow = data.data.length > 0;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe((data) => (this.dataSource.data = data));
  }

  public onSelect(selected: any) {
    // this.logger.info("LedgerAccountComponent.onSelect() -> " + selected);
    if (selected.editable) {
      this.onEdit(selected);
    }
  }

  public reloadData(): void {
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onCreateNew() {
    // this.logger.info("LedgerAccountComponent.onCreateNew() ");

    this.confirmDialogRef = this.dialog.open(
      LedgerAccountCreateComponentComponent,
      {
        disableClose: false,
        width: '400px',
        minWidth: 350,
        panelClass: 'scrollable-dialog',
        data: {
          dialogTitle: this.translate.instant(
            'LEDGER_ACCOUNT.CREATE_NEW_CATEGORY',
          ),
          serviceURL: this.serviceURL,
          createPlaceholder: this.translate.instant(
            'LEDGER_ACCOUNT.NEW_CATEGORY_NAME',
          ),
          useEnglish: false,
        },
      },
    );

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.logger.info("LedgerAccountComponent.onCreateNew() -> CREATION SUCCESS");
        this.reloadData();
      } else {
        // this.logger.info("LedgerAccountComponent.onCreateNew() -> CREATION CANCELED");
      }
      this.confirmDialogRef = null;
    });
  }

  public onEdit(selected: ClientsCategoryModel) {
    // this.logger.info("LedgerAccountComponent.onEdit() -> " + JSON.stringify(selected));
    if (this.editSelected === selected) {
      this.editSelected = null;
    } else {
      this.editSelected = selected;
    }
  }

  public onDelete(selected: ClientsCategoryModel) {
    // this.logger.info("LedgerAccountComponent.onDelete() -> " + selected);
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.crudDatatableGenericService
          .delete(ApiRoutes.LEDGER_ACCOUNT, selected)
          .subscribe(
            (response: any) => {
              // this.logger.info("LedgerAccountComponent.onDelete() -> SUCCESS:" + JSON.stringify(response));
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.reloadData();
            },
            (error: any) => {
              this.logger.error(
                'LedgerAccountComponent.onDelete() -> ERROR' +
                  JSON.stringify(error),
              );
              // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          );
      }
      this.confirmDialogRef = null;
    });
  }

  public onEditCategoryFocusOut(
    selected: CrudDatatableGenericModel,
    name: string,
    local: string,
  ): void {
    // this.logger.info("LedgerAccountComponent.onEditCategoryFocusOut() -> name: " + name);
    // this.logger.info("LedgerAccountComponent.onEditCategoryFocusOut() -> local: " + local);
    this.autosaveDataChange(selected);
  }

  public onSaveHourlyWage(editSelected: any): void {
    // this.logger.info("LedgerAccountComponent.onSaveAccoutningNumber() -> editSelected: " + JSON.stringify(editSelected));
    this.autosaveDataChange(editSelected);
  }

  public autosaveDataChange(data: any): void {
    // this.logger.info("LedgerAccountComponent.autosaveDataChange() -> data to save: " + JSON.stringify(data));
    this.crudDatatableGenericService.edit(this.serviceURL, data).subscribe(
      (response: any) => {
        // this.logger.info("LedgerAccountComponent.onEdit() -> SUCCESS:" + JSON.stringify(response));
        this.editSelected = null;
        this.toastr.success(
          this.translate.instant('LEDGER_ACCOUNT.EDIT_SUCCESS'),
        );
        this.reloadData();
      },
      (error: any) => {
        /* this.logger.error(
                    "LedgerAccountComponent.onEdit() -> ERROR" +
                        JSON.stringify(error)
                ); */
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    );
  }

  public onLanguageChange(lang: string): void {
    // this.logger.info("LedgerAccountComponent.onLanguageChange() -> " + lang);
    this.reloadData();
  }
}
