<div style="max-height: 100%; overflow: auto">
  <p *ngIf="allDrinksChecked == false" class="error-message">
    {{ "DRINKS_VALIDATION.ERROR_MESSAGE" | translate }}
  </p>
  <div mat-dialog-content>
    <table mat-table [dataSource]="selectedDrinks" class="mat-elevation-z8">
      <!-- Product name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          {{ "DRINKS_VALIDATION.DRINKS_NAME" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <!-- Initial qty Column -->
      <ng-container matColumnDef="intial-qty">
        <th mat-header-cell *matHeaderCellDef>
          {{ "DRINKS_VALIDATION.DRINKS_INITIAL_QTY" | translate }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ initialqtys[i] }}
        </td>
      </ng-container>

      <!-- Real qty Column -->
      <ng-container matColumnDef="real-qty">
        <th mat-header-cell *matHeaderCellDef>
          {{ "DRINKS_VALIDATION.DRINKS_REAL_QTY" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field class="form-input-container">
            <input
              matInput
              [(ngModel)]="element.qty"
              (ngModelChange)="modelRealQtyChange()"
              type="text"
              maxlength="4"
              pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            />
          </mat-form-field>
        </td>
      </ng-container>

      <!-- Confirmed Column -->
      <ng-container matColumnDef="confirmed">
        <th mat-header-cell *matHeaderCellDef>
          {{ "DRINKS_VALIDATION.DRINKS_CONFIRMED" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            class="example-margin"
            (change)="onConfirmed($event, element)"
          ></mat-checkbox>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      (click)="updateDrinks()"
      color="accent"
      [disabled]="!allDrinksChecked || loading"
      class="mr-12"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button mat-raised-button (click)="closeDialog()" color="primary">
      {{ "DRINKS_VALIDATION.CANCEL" | translate }}
    </button>
  </div>
</div>
