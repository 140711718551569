import { TranslateService } from "@ngx-translate/core";
import { ModulesService } from "../services/modules.service";
import { ChangeDetectorRef, Injector, Pipe, PipeTransform } from "@angular/core";
import { AsyncPipe } from "@angular/common";

@Pipe({
  name: "scpmPipe",
})
export class ScpmPipe implements PipeTransform {
  private asyncPipe: AsyncPipe;

  modulesAreSet: boolean = false;

  constructor(private modulesService: ModulesService, private translate: TranslateService, private injector: Injector) {
    this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef));
    this.modulesService.modulesSet.subscribe((data) => {
      this.modulesAreSet = data;
      // if (data) {
      // }
    });
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }

  transform(text: string): any {
    if (!this.modulesService.hasCongressModule() || !this.modulesService.hasCTReport()) {
      return text;
    } else {
      const replaceWith: string = this.translate.instant("GENERIC.SPCM_NUMBER_DYN");
      const replace: string = this.translate.instant("GENERIC.CONGRESS_NUMBER_DYN");
      const result: string = text.replace(replace, replaceWith);
      return result;
    }

    // this.asyncPipe.transform();
    // this.modulesService.modulesSet.subscribe((data) => {
    //     if (data) {
    //         // REMOVE BUTTONS FROM MENU IF THE ORG DOES NOT HAVE THE CORRESPONDANT MODULES
    //         if (!this.modulesService.hasCongressModule()) {
    //             return text;
    //         } else {
    //             const replaceWith: string = this.translate.instant(
    //                 "GENERIC.SPCM_NUMBER_DYN"
    //             );
    //             const replace: string = this.translate.instant(
    //                 "GENERIC.CONGRESS_NUMBER_DYN"
    //             );
    //             return result;
    //         }
    //     }
    //     return text;
    // });

    // if (
    //     this.modulesService.modulesAreSet &&
    //     this.modulesService.hasCongressModule()
    // ) {
    //     const replaceWith: string = this.translate.instant(
    //         "GENERIC.SPCM_NUMBER_DYN"
    //     );
    //     const replace: string = this.translate.instant(
    //         "GENERIC.CONGRESS_NUMBER_DYN"
    //     );
    //     const result: string = text.replace(replace, replaceWith);
    //     return result;
    //     // return text.replace(
    //     //     this.translate.instant("GENERIC.CONGRESS_NUMBER_DYN"),
    //     //     this.translate.instant("GENERIC.SPCM_NUMBER_DYN")
    //     // );
    // }

    // // return this.translate.instant(text);
    // // this.asyncPipe.transform();
    // return text;
  }
}
