export const locale = {
  lang: "en-CA",
  data: {
    CONTACT: {
      CONTACT: "Contact",
      CONTACT_TICKET: "Support - Demande de ticket",
      CONTACT_TICKET_LINK: "Support - Demande de ticket",
      CONTACT_US: "Contact us",
      SUCCESS_MESSAGE: "Your message was sent successfully!",
      USE_FORM: "Ou utiliser le formulaire ci dessous",
      SUBJECT: "Subject",
      MESSAGE: "Message",
    },
  },
};
