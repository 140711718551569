import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { RolesService } from '../../../services/roles.service';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { Router } from '@angular/router';
import { IconsService } from '../../../services/icons.service';
import { ProductModel, ProductsService } from '../../../api/products.service';
import { MatDialog } from '@angular/material/dialog';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
} from '@angular/forms';
import { ProductShopCalendarPickerDialogComponent } from '../product-shop-calendar-picker-dialog/product-shop-calendar-picker-dialog.component';
import { FullcalendarPickerComponent } from '../../../components/fullcalendar-picker/fullcalendar-picker.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { NgxMultipleDatesModule } from 'ngx-multiple-dates';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'app-product-shops',
  templateUrl: './product-shops.component.html',
  styleUrls: ['./product-shops.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    NgxPermissionsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    NgxMultipleDatesModule,
    FormsModule,
  ],
})
export class ProductShopsComponent
  extends GT2FormPageAbstract
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() shops: any;
  @Input() product: ProductModel | any;
  @Output() productChange = new EventEmitter<ProductModel>();
  @Input() hasCyclicMenuModule?: boolean;
  @Input() loading?: boolean;
  @Output() changeEvent = new EventEmitter<ShopsCheckbox>();
  @Output() productChangeEvent = new EventEmitter<ProductModel>();
  //
  @ViewChild(FullcalendarPickerComponent)
  calendarPicker?: FullcalendarPickerComponent;

  //
  loadingShop: any = {};
  modelDates: Date[] = [];
  dateEvents: any[] = [];

  recurenceValue?: string;
  rruleWeekdays: any[] = [];

  /*days = this._formBuilder.group({
        monday: true,
        tuesday: false,
        wednesday: false,
        thursday: true,
        friday: false,
        saturday: true,
        sunday: false,
    });*/

  constructor(
    private logger: NGXLogger,
    public router: Router,
    public rolesService: RolesService,
    private productsService: ProductsService,
    private dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  ngOnInit(): any {
    this.loading = false;
    // if (
    //     this.product &&
    //     this.product.cyclic_dates &&
    //     this.product.cyclic_dates.length > 0
    // ) {
    //     this.modelDates = this.product.cyclic_dates.map(
    //         (elm) => new Date(elm)
    //     );
    //     //this.logger.info(
    //         "ProductShopsComponent.ngOnInit() -> Model Dates: " +
    //             this.modelDates
    //     );
    // } else {
    //     this.modelDates = [];
    // }
    // //this.logger.info(
    //     "ProductShopsComponent.ngOnInit() -> product: " +
    //         this.product
    // );
    //this.logger.info("ProductShopsComponent.ngOnInit() -> Model Dates: " + this.modelDates);
  }

  ngAfterViewInit(): void {
    //this.logger.info("ProductShopsComponent.ngAfterViewInit() -> calendarPicker: " + this.calendarPicker);
  }

  ngOnDestroy(): void {
    this.updateProduct();
  }

  public override getIcon(iconName: string): string {
    return IconsService.getIconStatic(iconName);
  }

  public onShopCheckbox(event: any, item: any): void {
    // this.logger.log("ProductShopsComponent.onShopCheckbox() -> shops: " + JSON.stringify(this.shops));
    // this.logger.log("ProductShopsComponent.onShopCheckbox() -> item: " + JSON.stringify(item) + "  event.checked: " + event.checked);
    // this.logger.log("ProductShopsComponent.onShopCheckbox() -> item.selected: " + item.selected);
    // this.changeEvent.emit( {uuid: item.uuid, checked: event.checked} );
    // this.changeEvent.emit( {uuid: item.shop_woocommerce.uuid, checked: event.checked} );
  }

  public onShopItemClick(item: any): void {
    if (this.loading) {
      return;
    }

    this.logger.log(
      'ProductShopsComponent.onShopItemClick() -> item: ' +
        JSON.stringify(item),
    );
    item.selected = !item.selected;
    // this.loadingShop[item.uuid] = true;
    this.changeEvent.emit({ uuid: item.uuid, checked: item.selected });
  }

  public setItemLoading(uuid: string, value: boolean): void {
    this.loadingShop[uuid] = value;
  }

  public onOpenShopInNewTab(url: string): void {
    // product_shop_url
    window.open(url, '_blank');
  }

  public onOpenObjectPage(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public onSetCyclicDatesForShop(shop: any): void {
    this.dialogRef = this.dialog.open(
      ProductShopCalendarPickerDialogComponent,
      {
        disableClose: false,
        minWidth: '440px',
        // autoFocus: false,
        maxHeight: '90vh',
        panelClass: 'scrollable-dialog',
        data: {
          shop: shop,
          product: this.product,
        },
      },
    );

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        //this.logger.info("ProductShopsComponent.onSetCyclicDatesForShop() -> result: " + JSON.stringify(result));
        // this.product = result;
        this.productChangeEvent.emit(result);
        this.productChange.emit(result);
      }
      this.dialogRef = null;
    });
  }

  public updateProduct() {
    this.loading = true;
    this.productsService
      .updateProduct(this.product, this.product.uuid)
      .subscribe(
        (response: any) => {
          // //this.logger.info(
          //     "ProductComponent.autosaveDataChange() -> SUCCESS: " +
          //         JSON.stringify(response)
          // );
          //this.form.markAsPristine();
          //this.form.markAsUntouched();
          this.loading = false;
          this.product = response.data;
        },
        (error: any) => {
          this.loading = false;
          // //this.logger.info("ProductComponent.autosaveDataChange() -> ERROR: " + JSON.stringify(error));
          // this.handleAPIError(error, this.dialog, null, null);
        },
      );
    //
    this.refreshCalendar();
  }

  public refreshCalendar(): void {
    if (this.calendarPicker) {
      this.calendarPicker.refreshTimeline();
    }
  }

  /*
    public setRruleWeekdays(day): void{
        //this.logger.info(
            "days checkboxes change => " + JSON.stringify(this.days.value)
        );
        //this.logger.info("key => : " + day);

        switch (day) {
            case 0:
                if(!this.rruleWeekdays.includes(RRule.MO)){
                    this.rruleWeekdays.push(RRule.MO);
                }
                break;
            case 1:
                 if (!this.rruleWeekdays.includes(RRule.TU)) {
                     this.rruleWeekdays.push(RRule.TU);
                 }
                break;
            case 2:
                 if (!this.rruleWeekdays.includes(RRule.WE)) {
                     this.rruleWeekdays.push(RRule.WE);
                 }
                break;
            case 3:
                 if (!this.rruleWeekdays.includes(RRule.TH)) {
                     this.rruleWeekdays.push(RRule.TH);
                 }
                break;
            case 4:
                 if (!this.rruleWeekdays.includes(RRule.FR)) {
                     this.rruleWeekdays.push(RRule.FR);
                 }
                break;
            case 5:
                if(!this.rruleWeekdays.includes(RRule.SA)){
                    this.rruleWeekdays.push(RRule.SA);
                }
                break;
            case 6:
                 if (!this.rruleWeekdays.includes(RRule.SU)) {
                     this.rruleWeekdays.push(RRule.SU);
                 }
                break;
            default:
                break;
        }

        //this.logger.info("Rrule weekdays=>: " + this.rruleWeekdays);            
        this.createRrule(this.rruleWeekdays);
        this.updateProduct();

    }
    */
}

export interface ShopsCheckbox {
  uuid: string;
  checked: boolean;
}
