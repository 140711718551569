<div style="max-height: 100%; overflow: auto">
  <div matDialogTitle *ngIf="mode === 'CREATE'" class="event-dialog-title">
    {{ "CREATE_DELIVERY_REQUEST_TEMPLATE.TITLE" | translate }}
  </div>
  <div matDialogTitle *ngIf="mode === 'EDIT'" class="event-dialog-title">
    {{ "CREATE_DELIVERY_REQUEST_TEMPLATE.EDIT_TITLE" | translate }}
  </div>

  <div fxLayout="column" fxLayoutAlign="center start">
    <div
      fxLayout="row"
      fxLayoutAlign="center start"
      [formGroup]="form"
      class="item-full-width"
    >
      <mat-form-field fxFlex="100%" class="item-full-width mb-4 mr-4">
        <mat-label>{{
          "CREATE_DELIVERY_REQUEST_TEMPLATE.TEMPLATE_NAME" | translate
        }}</mat-label>
        <input
          matInput
          fxFlex="100%"
          placeholder="{{
            'CREATE_DELIVERY_REQUEST_TEMPLATE.TEMPLATE_NAME' | translate
          }}"
          autocomplete="nope"
          formControlName="template_name"
          type="text"
          [(ngModel)]="deliveryRequestTemplate.template_name"
          required
        />
        <mat-error *ngIf="form.controls['template_name'].invalid">{{
          "GENERIC.INPUT_LENGTH_INVALID" | translate
        }}</mat-error>
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center start"
      [formGroup]="form"
      class="item-full-width"
    >
      <div fxFlex="65" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex="100%" class="item-full-width mb-4 mr-4">
          <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            placeholder="{{ 'GENERIC.NAME' | translate }}"
            autocomplete="nope"
            formControlName="name"
            type="text"
            [(ngModel)]="deliveryRequestTemplate.name"
          />
          <mat-error *ngIf="form.controls['name'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="35" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field fxFlex="100%" class="item-full-width ml-4">
          <mat-label>{{ "GENERIC.PRICE" | translate }}</mat-label>
          <input
            matInput
            type="text"
            placeholder="{{ 'GENERIC.PRICE' | translate }}"
            formControlName="price"
            autocomplete="nope"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="deliveryRequestTemplate.price"
          />
          <mat-error *ngIf="form.controls['price'].invalid">
            {{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- CHECKBOXES -->
    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxFlex="100"
      class="item-full-width mt-12 mb-8"
    >
      <div fxFlex="100" fxLayout="column" fxLayoutAlign="center start">
        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="
            deliveryRequestTemplate.show_on_purchase_order_production
          "
          [ngModelOptions]="{ standalone: true }"
          class=""
        >
          {{
            "CREATE_DELIVERY_REQUEST_TEMPLATE.SHOW_ON_PURCHASE_ORDER_PRODUCTION"
              | translate
          }}
        </mat-checkbox>

        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="
            deliveryRequestTemplate.show_on_purchase_order_client_copy
          "
          [ngModelOptions]="{ standalone: true }"
        >
          {{
            "CREATE_DELIVERY_REQUEST_TEMPLATE.SHOW_ON_PURCHASE_ORDER_CLIENT_COPY"
              | translate
          }}
        </mat-checkbox>

        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="
            deliveryRequestTemplate.show_on_client_proposal_and_order
          "
          [ngModelOptions]="{ standalone: true }"
        >
          {{
            "CREATE_DELIVERY_REQUEST_TEMPLATE.SHOW_ON_CLIENT_PROPOSAL_AND_ORDER"
              | translate
          }}
        </mat-checkbox>

        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="deliveryRequestTemplate.show_on_purchase_order_kitchen"
          [ngModelOptions]="{ standalone: true }"
          class=""
        >
          {{
            "CREATE_DELIVERY_REQUEST_TEMPLATE.SHOW_ON_PURCHASE_ORDER_KITCHEN"
              | translate
          }}
        </mat-checkbox>
        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="deliveryRequestTemplate.show_on_purchase_material"
          [ngModelOptions]="{ standalone: true }"
        >
          {{
            "CREATE_DELIVERY_REQUEST_TEMPLATE.SHOW_ON_PURCHASE_MATERIAL"
              | translate
          }}
        </mat-checkbox>
      </div>
    </div>

    <div
      fxFlex="100%"
      fxLayout="row"
      fxLayoutAlign="start center"
      class="my-12 item-full-width"
    >
      <div class="ngx-timepicker-field-from">
        <div class="timepicker-custom-label">
          {{ "GENERIC.TIME_START" | translate }}
        </div>
        <ngx-timepicker-field
          [buttonAlign]="'left'"
          [format]="24"
          [(ngModel)]="deliveryRequestTemplate.delivery_from_time"
          class=""
        >
        </ngx-timepicker-field>
      </div>

      <div class="ngx-timepicker-field-to">
        <div class="timepicker-custom-label">
          {{ "GENERIC.TIME_END" | translate }}
        </div>
        <ngx-timepicker-field
          [buttonAlign]="'left'"
          [format]="24"
          [(ngModel)]="deliveryRequestTemplate.delivery_to_time"
          class=""
        >
        </ngx-timepicker-field>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxFlex="100"
      [formGroup]="form"
      class="item-full-width"
    >
      <div fxFlex="33" fxLayout="row" fxLayoutAlign="start start">
        <app-gt2-select
          #wrapping
          [service]="wrappingService"
          [nullableUseNone]="false"
          [placeHolder]="'GENERIC.WRAPPING' | translate"
          [label]="'ORDERS.WRAPPING' | translate"
          [selectedModel]="deliveryRequestTemplate.wrapping"
          (changeEvent)="onWrappingSelected($event)"
          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
          [isRequired]="false"
          fxFlex="100%"
          class="pr-4"
        ></app-gt2-select>
      </div>

      <div fxFlex="33" fxLayout="row" fxLayoutAlign="start start">
        <app-gt2-select
          [service]="deliveryRequestTypeService"
          [placeHolder]="'GENERIC.TYPE' | translate"
          [label]="'Personnal title'"
          [selectedModel]="deliveryRequestTemplate.delivery_request_type"
          (changeEvent)="onDeliveryRequestTypeChange($event)"
          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
          [nullableUseNone]="false"
          [isRequired]="false"
          fxFlex="100%"
          class="px-4"
        ></app-gt2-select>
      </div>

      <div fxFlex="33" fxLayout="row" fxLayoutAlign="start start">
        <app-gt2-select
          [service]="deliveryRequestContentService"
          [placeHolder]="'GENERIC.CONTENT' | translate"
          [label]="'Personnal title'"
          [selectedModel]="deliveryRequestTemplate.delivery_request_content"
          (changeEvent)="onDeliveryRequestContentChange($event)"
          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
          [nullableUseNone]="false"
          [isRequired]="false"
          fxFlex="100%"
          class="pl-4"
        ></app-gt2-select>
      </div>
    </div>

    <div fxFlex="100%" class="item-full-width mt-8 note-section">
      <p-editor
        #noteEditor
        [(ngModel)]="deliveryRequestTemplate.note"
        [style]="{
          height: '74px',
          width: '100%',
          'background-color': '#ffffff',
        }"
      >
        <ng-template pTemplate="header" class="">
          <span><b>Note | </b></span>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <span class="ql-formats">
            <!--          matTooltip="Bold"          -->
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </ng-template>
      </p-editor>
      <mat-error
        *ngIf="noteInvalid"
        fxLayout="row"
        fxLayoutAlign="end center"
        class="p-4 pr-8"
      >
        {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
      </mat-error>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!form.valid || loading"
      color="accent"
      class="mr-16"
      (click)="onCreateDeliveryRequest()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
