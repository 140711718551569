<div *ngIf="collectionData && dataSource">
  <mat-table
    #table
    class=""
    matSort
    matSortStart="desc"
    [@animateStagger]="{ value: '50' }"
    [dataSource]="dataSource"
  >
    <!-- Name Column -->
    <ng-container
      *ngFor="let col of displayedColumns; let i = index"
      cdkColumnDef="{{ displayedColumns[i] }}"
    >
      <mat-header-cell
        *cdkHeaderCellDef
        style="display: none"
      ></mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <!--<div>-->
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon *ngIf="dynamicTableItemAction" class="mr-4"
              >link</mat-icon
            >

            <div>{{ row[displayedColumns[i]] }}</div>
          </div>

          <!-- *ngxPermissionsOnly="rolesService.GOD_ROLES" -->
          <button
            mat-icon-button
            (click)="onActionEndClick(row); $event.stopPropagation()"
          >
            <mat-icon *ngIf="dynamicTableItemActionEnd" class="mr-4">
              link_off
            </mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *cdkRowDef="let row; columns: displayedColumns"
      (click)="onSelect(row)"
      [@animate]="{ value: '*', params: { y: '100%' } }"
    >
    </mat-row>
  </mat-table>
  <!--<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
  <!--<mat-paginator #paginator-->
  <!--[length]="resultsLength"-->
  <!--[pageIndex]="0"-->
  <!--[pageSize]="5"-->
  <!--[pageSizeOptions]="[5, 10, 20]">-->
  <!--</mat-paginator>-->
</div>
