import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OrdersService } from '../../../../api/orders.service';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import { ItemUrlFinder } from '../../../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { locale as english } from '../../i18n/en-CA';
import { locale as french } from '../../i18n/fr-CA';
import { UpsaleService } from '../../../../api/upsale.service';
import { MaterialsService } from '../../../../api/materials.service';
import { GT2ConstantsEnum } from '../../../../const/GT2ConstantsEnum';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
@Component({
  selector: 'app-create-upsale-materials',
  templateUrl: './create-upsale-materials.component.html',
  styleUrls: ['./create-upsale-materials.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
})
export class CreateUpsaleMaterialsComponent
  extends GT2PageAbstract
  implements OnInit
{
  material: any;
  materials!: any[];
  combo: any[] = [];
  isLoading: boolean = false;
  isEmpty: boolean = true;
  creationMode: boolean = false;
  applyCall: boolean = true;
  @ViewChild('filter') filter!: ElementRef;
  @ViewChild('productList') productList!: ElementRef;
  filterValue: string = '';
  newDialogRef: any;
  fromEventSubscription!: Subscription;
  orderUUID!: string;
  defaultQty: number = 1;
  questionUUID!: string;
  group_name!: string;
  mode!: string;
  group: any;
  loading: boolean = false;
  constructor(
    public override dialogRef: MatDialogRef<CreateUpsaleMaterialsComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public materialsService: MaterialsService,
    public ordersService: OrdersService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public upsaleService: UpsaleService,
  ) {
    super();

    this.translationLoader.loadTranslations(english, french);
    if (data && data.questionUUID) {
      this.questionUUID = data.questionUUID;
    }
    if (data && data.name) {
      this.group_name = data.name;
    }
    if (data && data.mode) {
      this.mode = data.mode;
    }
    if (data && data.group) {
      this.group = data.group;
    }
  }

  ngOnInit() {
    if (this.mode == 'EDIT') {
      this.loadAttachedMaterials();
    }
  }

  ngAfterViewInit() {
    this.fromEventSubscription = fromEvent(this.filter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          // //this.logger.info("CreateMaterialComboComponent.onFilterChange() -> " + this.productList);
          // this.productList.nativeElement.scrollTop = 0;
          if (this.filterValue.length >= 3) {
            this.loadMaterialList();
          } else {
            this.materials = [];
            this.isEmpty = true;
          }
        }),
      )
      .subscribe();
  }

  public loadMaterialList(): void {
    // //this.logger.info("CreateMaterialComboComponent.loadMaterialList()");
    this.isLoading = true;
    this.isEmpty = false;
    this.materialsService.getMaterialsWithoutCombo(this.filterValue).subscribe(
      (response) => {
        // //this.logger.info("CreateMaterialComboComponent.getMaterialsWithoutCombo() -> SUCCESS: " + JSON.stringify(response));
        this.isLoading = false;
        if (response.data.length > 0) {
          this.materials = response.data;
          this.isEmpty = false;
          this.productList.nativeElement.scrollTop = 0;
        } else {
          this.isEmpty = true;
          this.materials = [];
        }
      },
      (error) => {
        this.logger.error(
          'CreateMaterialComboComponent.getMaterialsWithoutCombo() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoading = false;
        this.isEmpty = true;
      },
    );
  }

  public loadAttachedMaterials(): void {
    this.isLoading = true;
    this.upsaleService.getAttachedOptions(this.group.uuid).subscribe(
      (response) => {
        this.isLoading = false;
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            this.combo.push(response.data[i].products[0]);
          }
          this.isEmpty = false;
          this.productList.nativeElement.scrollTop = 0;
        } else {
          this.isEmpty = true;
          this.combo = [];
        }
        //this.logger.info("CreateUpsaleOptionsComponent.loadAttachedOptions() -> combo: " + JSON.stringify(this.combo));
      },
      (error) => {
        this.logger.error(
          'CreateUpsaleOptionsComponent.loadAttachedOptions() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoading = false;
        this.isEmpty = true;
      },
    );
  }

  public onFilterChange() {
    // //this.logger.info("OrderProductDndComponent.onFilterChange()");
    this.productList.nativeElement.scrollTop = 0;
  }
  public updateGroupOptions() {
    //this.logger.info("CreateUpsaleOptionsComponent.onUpdateGroupOptions()");
    this.isLoading = true;
    this.upsaleService
      .updateOptions(this.group.uuid, {
        options: this.combo,
      })
      .subscribe(
        (response: any) => {
          //this.logger.info("CreateUpsaleOptionsComponent.onUpdateGroupOptions() -> SUCCESS>>: " + JSON.stringify(response));
          this.isLoading = false;
          this.dialogRef.close(response.data);
        },
        (error: any) => {
          this.logger.error(
            'CreateUpsaleOptionsComponent.onUpdateGroupOptions() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.isLoading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public createNewGroupOptions() {
    this.isLoading = true;
    this.upsaleService
      .addOptions(this.questionUUID, {
        options: this.combo,
        name: this.group_name,
        type: GT2ConstantsEnum.GROUP_MATERIALS,
      })
      .subscribe(
        (response: any) => {
          //this.logger.info("CreateUpsaleOptionsComponent.getProducts() -> SUCCESS>>: " + JSON.stringify(response));
          this.isLoading = false;
          this.dialogRef.close(response.data);
          //this.dialog.closeAll();
        },
        (error: any) => {
          this.logger.error(
            'CreateUpsaleOptionsComponent.getProducts() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.isLoading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public onSaveOptions(): void {
    if (this.mode == 'EDIT') {
      this.updateGroupOptions();
    } else {
      this.createNewGroupOptions();
    }
  }

  public onDrop(event: any): void {
    //this.logger.info(event.source);
    // //this.logger.info(event.source.className.indexOf("combo-list"));
    if (event.value == undefined) {
      return;
    }

    if (event.source.className.indexOf('combo-list') !== -1) {
      this.onProductChildDrop(event);
      return;
    }

    //this.logger.info("OrderProductDndComponent.onDrop() -> item: " + event.value);
    // event.value.qty = 1;
    event.value.qty = this.defaultQty;
    const items: any[] = this.combo.filter((item) => {
      return item.uuid === event.value.uuid;
    });
    // //this.logger.info("OrderProductDndComponent.onDrop() -> items: " + items.length);

    // If this product was already in the list, we add to qty
    if (items && items.length > 1) {
      // remove all except event.value & set qty to items.length
      let previousQuantity: number = 1;
      for (let i = 0; i < this.combo.length; ++i) {
        if (
          this.combo[i].uuid === event.value.uuid &&
          this.combo[i] !== event.value
        ) {
          previousQuantity = this.combo[i].qty;
          this.combo.splice(i, 1);
        }
      }
      event.value.qty += previousQuantity;
    }
  }

  public onProductChildDrop(event: any) {
    // //this.logger.info("OrderProductDndComponent.onProductChildDrop() -> " + JSON.stringify(event.value));
    // //this.logger.info("CreateComboComponent.onProductChildDrop() -> " + JSON.stringify(this.product.childs));
    // //this.logger.info(event);
  }

  public onRemoveItem(item: any, index: number) {
    // //this.logger.info("OrderProductDndComponent.onRemoveItem() -> index: " + index);
    item.qty = 0;
    for (let i = 0; i < this.combo.length; ++i) {
      if (this.combo[i] === item) {
        this.combo.splice(i, 1);
        break;
      }
    }
  }

  public onItemQtyChange(item: any): void {
    // //this.logger.info("OrderProductDndComponent.onItemQtyChange() -> ");
  }

  public onAddItem(item: any, doAddItemInModel: boolean = false) {
    //this.logger.info("OrderProductDndComponent.onAddItem() -> items: " + item);

    const items: any[] = this.combo.filter((filterItem) => {
      // //this.logger.info(filterItem.uuid === item.uuid);
      return filterItem.uuid === item.uuid;
    });

    if (items.length === 0) {
      // item.qty = 1;
      this.combo.push(item);

      //this.logger.info("add item");
    } else {
      items[0].qty++;
    }
    // //this.logger.info("OrderProductDndComponent.onAddItem() -> items: " + items.length);
  }

  public onLessItem(item: any, i: number): void {
    item.qty -= 1;

    if (item.qty === 0) {
      this.onRemoveItem(item, i);
    }
  }

  public onRemove(event: any) {
    // //this.logger.info("CreateComboComponent.onRemove() -> combo: " + this.combo.length);
    // //this.logger.info("OrderProductDndComponent.onRemove() -> combo: " + event);

    if (event.value.qty > 1) {
      event.value.qty -= 1;
      this.combo.push(event.value);
    }
  }

  public clearSearch(): void {
    this.filterValue = '';
    this.materials = [];
    this.isEmpty = true;
  }

  public onOpenInNewTab(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }
}
