import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { RoomsService } from '../../../content/rooms/rooms.service';
import { Settings } from '../../../settings/settings';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-room',
  templateUrl: './create-room.component.html',
  styleUrls: ['./create-room.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
  ],
})
export class CreateRoomComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static PLACE: string = 'PLACE';
  static ENTERPRISE: string = 'ENTERPRISE';

  form: UntypedFormGroup;
  room: any;
  loading: boolean = false;
  parentType!: string;
  parentUUID!: string;
  isSubRoom: boolean = false;

  userSettings: any = {
    inputPlaceholderText: '',
    showRecentSearch: true,
  };

  constructor(
    public compDialogRef: MatDialogRef<CreateRoomComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private roomsService: RoomsService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.userSettings.inputPlaceholderText = this.translate.instant(
      'GENERIC.GOOGLE_ADDRESS_PLACEHOLDER',
    );

    if (data) {
      this.parentType = data.parentType;
      this.parentUUID = data.parentUUID;
      this.isSubRoom = data.isSubRoom;
      this.logger.log(
        'CreateRoomComponent.constructor() -> parentType: ' + this.parentType,
      );
      this.logger.log(
        'CreateRoomComponent.constructor() -> parentUUID: ' + this.parentUUID,
      );
      this.logger.log(
        'CreateRoomComponent.constructor() -> isSubRoom: ' + this.isSubRoom,
      );
    }

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.room = {
      name: null,
    };
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCancel(): void {
    // this.logger.info("CreateRoomComponent.onCancel()");
  }

  public onCreateRoom(): void {
    this.loading = true;
    // this.logger.info("CreateRoomComponent.onCreateRoom() -> " + this.form.valid);
    // this.logger.info("CreateRoomComponent.onCreateRoom() -> this.client: " + JSON.stringify(this.room));
    if (this.form.valid) {
      const newRoom: any = { name: this.room.name };

      this.logger.log(
        'CreateRoomComponent.onCreateRoom() -> newRoom: ' +
          JSON.stringify(newRoom),
      );

      this.logger.log(
        'CreateRoomComponent.onCreateRoom() -> this.room.uuid: ' +
          this.parentUUID,
      );

      if (this.isSubRoom === true) {
        this.roomsService.createSubRoom(newRoom, this.parentUUID).subscribe(
          (response) => {
            // this.logger.info("CreateRoomComponent.onCreateRoom() -> SUCCESS: " + JSON.stringify(response));
            this.toastr.success(
              '',
              this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_ROOM'),
            );
            this.loading = false;
            this.compDialogRef.close(response);
          },
          (error) => {
            this.logger.error(
              'CreateRoomComponent.onCreateRoom() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.loading = false;
            // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
            this.handleAPIError(
              error,
              this.dialog,
              this.toastr,
              this.translate.instant('GENERIC.ERRORS.CREATE_CLIENT'),
            );
          },
        );
      } else {
        if (this.parentType === CreateRoomComponent.PLACE) {
          newRoom.place_uuid = this.parentUUID;
        } else {
          newRoom.enterprise_uuid = this.parentUUID;
        }
        this.roomsService.createRoom(newRoom).subscribe(
          (response) => {
            // this.logger.info("CreateRoomComponent.onCreateRoom() -> SUCCESS: " + JSON.stringify(response));
            this.toastr.success(
              '',
              this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_ROOM'),
            );
            this.loading = false;
            this.compDialogRef.close(response);
          },
          (error) => {
            this.logger.error(
              'CreateRoomComponent.onCreateRoom() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.loading = false;
            // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
            this.handleAPIError(
              error,
              this.dialog,
              this.toastr,
              this.translate.instant('GENERIC.ERRORS.CREATE_CLIENT'),
            );
          },
        );
      }
    }
  }

  public handleAddressChange(address: any) {
    // this.logger.info("CreateRoomComponent.handleAddressChange() -> : " + JSON.stringify(address));
  }
}
