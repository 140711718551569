// import { Directive, ElementRef, HostListener, Input } from "@angular/core";

// @Directive({
//   selector: "[numeric]"
// })
// export class NumericDirective {
//   @Input("decimals") decimals: number = 0;
//   @Input("negative") negative: number = 0;

//   private checkAllowNegative(value: string): any {
//     if (this.decimals <= 0) {
//       return String(value).match(new RegExp(/^-?\d+$/));
//     } else {
//       const regExpString =
//         "^-?\\s*((\\d+(\\.\\d{0," +
//         this.decimals +
//         "})?)|((\\d*(\\.\\d{1," +
//         this.decimals +
//         "}))))\\s*$";
//       return String(value).match(new RegExp(regExpString));
//     }
//   }

//   private check(value: string): any {
//     if (this.decimals <= 0) {
//       return String(value).match(new RegExp(/^\d+$/));
//     } else {
//       const regExpString =
//         "^\\s*((\\d+([\\,]\\d{0," +
//         this.decimals +
//         "})?)|((\\d*(\\,\\d{1," +
//         this.decimals +
//         "}))))\\s*$";
//       return String(value).match(new RegExp(regExpString));
//     }
//   }

//   private run(oldValue) {
//     setTimeout(() => {
//       let currentValue: string = this.el.nativeElement.value;
//       let allowNegative = this.negative > 0 ? true : false;

//       if (allowNegative) {
//         if (
//           !["", "-"].includes(currentValue) &&
//           !this.checkAllowNegative(currentValue)
//         ) {
//           this.el.nativeElement.value = oldValue;
//         }
//       } else {
//         if (currentValue !== "" && !this.check(currentValue)) {
//           this.el.nativeElement.value = oldValue;
//         }
//       }
//     });
//   }

//   constructor(private el: ElementRef) {}

//   @HostListener("keydown", ["$event"])
//   onKeyDown(event: KeyboardEvent) {
//     this.run(this.el.nativeElement.value);
//   }

//   @HostListener("paste", ["$event"])
//   onPaste(event: ClipboardEvent) {
//     this.run(this.el.nativeElement.value);
//   }
// }

// ************ ALLOW NEGATIVE AND ALLOW ,

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Form, UntypedFormGroup } from '@angular/forms';

@Directive({
  selector: '[numeric]',
})
export class NumericDirective {
  // tslint:disable-next-line: no-input-rename
  @Input('decimals') decimals: number = 0;
  // tslint:disable-next-line: no-input-rename
  @Input('allowNegative') allowNegative: boolean = false;
  // tslint:disable-next-line: no-input-rename
  @Input('validationForm') validationForm?: UntypedFormGroup;
  @Input('controlName') controlName?: string;
  @Input('control') control: any;
  // use as   [control]="form.get('meal_included')"

  // @Input("separator") separator: string = ".";

  private checkAllowNegative(value: string): any {
    if (this.decimals <= 0) {
      return String(value).match(new RegExp(/^-?\d+$/));
    } else {
      const regExpString =
        '^[+|-]?\\s*((\\d+([\\.|,]\\d{0,' +
        this.decimals +
        '})?)|((\\d*([\\.|,]\\d{1,' +
        this.decimals +
        '}))))\\s*$';
      return String(value).match(new RegExp(regExpString));
    }
  }

  private check(value: string): any {
    if (this.decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    } else {
      const regExpString =
        '^[+]?\\s*((\\d+([\\.,]\\d{0,' +
        this.decimals +
        '})?)|((\\d*([\\.,]\\d{1,' +
        this.decimals +
        '}))))\\s*$';
      return String(value).match(new RegExp(regExpString));
    }
  }

  private run(oldValue: any): any {
    setTimeout(() => {
      const currentValue: string = this.el.nativeElement.value;
      if (this.allowNegative) {
        if (
          !['', '-'].includes(currentValue) &&
          !this.checkAllowNegative(currentValue)
        ) {
          this.el.nativeElement.value = oldValue;
        }
      } else {
        if (currentValue !== '' && !this.check(currentValue)) {
          this.el.nativeElement.value = oldValue;
        }
      }

      // validation form
      // if (this.control) {
      //     setTimeout( () => {
      //         this.control.markAsPristine();
      //         this.control.markAsUntouched()
      //     }, 3000);
      // }
      // if (this.validationForm && this.controlName) {
      // const control = this.validationForm.get(this.controlName);
      // const control = this.validationForm.controls[this.controlName];
      // }
    });
  }

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.run(this.el.nativeElement.value);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    this.run(this.el.nativeElement.value);
  }
}

// *********** ORIGINAL

// import {Directive, ElementRef, HostListener, Input} from '@angular/core';

// @Directive({
//     selector: '[numeric]'
// })

// export class NumericDirective {

//     // tslint:disable-next-line: no-input-rename
//     @Input('decimals') decimals: number = 0;

//     private check(value: string, decimals: number)
//     {
//       if (decimals <= 0) {
//         return String(value).match(new RegExp(/^\d+$/));
//       } else {
//         // pattern="^[+-]?[0-9]+([.|,][0-9]{1,2})?"
//           // const regExpString = '^\\s*((\\d+(\\.\\d{0,' + decimals + '})?)|((\\d*(\\.\\d{1,' + decimals + '}))))\\s*$'

//           const regExpString = '^\\s*((\\d+(\\.\\d{0,' + decimals + '})?)|((\\d*(\\.\\d{1,' + decimals + '}))))\\s*$';
//           return String(value).match(new RegExp(regExpString));
//       }
//     }

//     // tslint:disable-next-line: member-ordering
//     private specialKeys = [
//         'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
//       ];

//     constructor(private el: ElementRef) {
//     }

//     @HostListener('keydown', [ '$event' ])
//     onKeyDown(event: KeyboardEvent) {
//         if (this.specialKeys.indexOf(event.key) !== -1) {
//             return;
//         }
//         // Do not use event.keycode this is deprecated.
//         // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
//         let current: string = this.el.nativeElement.value;
//         let next: string = current.concat(event.key);
//         if ( next && !this.check(next, this.decimals) ) {
//            event.preventDefault();
//         }
//     }
// }
