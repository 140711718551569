import { Component, OnInit } from "@angular/core";
import { LocalizationConfig } from "../../../../localization/LocalizationConfig";
import { Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { GT2PageAbstract } from "../../abstract/GT2PageAbstract";
import { AuthenticationService } from "../../../api/auth-service.service";

@Component({
  selector: "app-god-local-storage",
  templateUrl: "./god-local-storage.component.html",
  styleUrls: ["./god-local-storage.component.scss"],
})
export class GodLocalStorageComponent extends GT2PageAbstract implements OnInit {
  constructor(private authService: AuthenticationService, private logger: NGXLogger, private router: Router) {
    super();
  }

  ngOnInit() {
    this.validatePageAccess(this.authService, this.router);
  }

  onDeleteLanguageLocalStorage(): void {
    //this.logger.info("onDeleteLanguageLocalStorage()");
    localStorage.removeItem(LocalizationConfig.LOCAL_STORED_LANG_ID);
  }
}
