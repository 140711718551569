export const locale = {
  lang: "en-CA",
  data: {
    CREATE_PRODUCT: {
      TITLE: "New product",
      SELECT_TYPE: "Select a product type",
      FOOD: "Food",
      DRINK: "Drink",
    },
  },
};
