import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { FuseWidgetModule } from '../../../../../@fuse/components/widget/widget.module';
import { Gt2LocaleService } from '../../../../localization/gt2-locale.service';
import { CrudOrganizationComponent } from './crud-organization.component';
import { CrudOrganizationService } from './crud-organization.service';
import { AppRoutesName } from '../../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DisplayHtmlLinkPipeModule } from '../../../modules/display-html-link.module';
import { MatButtonModule } from '@angular/material/button';
import { AddressComponent } from '../../../components/address/address.component';
import { ContactComponent } from '../../../components/contact/contact.component';
import { ContactService } from '../../../components/contact/contact.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.EN.ORGANIZATION,
    component: CrudOrganizationComponent,
  },
  {
    path: AppRoutesName.FR.ORGANIZATION,
    component: CrudOrganizationComponent,
  },
];

@NgModule({
  declarations: [CrudOrganizationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    DisplayHtmlLinkPipeModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    MatFormFieldModule,
    NgxPermissionsModule,
    MatInputModule,
    AddressComponent,
    ContactComponent,
    MultiSelectModule,
    RouterModule.forChild(routes),
    FileUploadModule,
    FuseWidgetModule,
  ],
  exports: [CrudOrganizationComponent],
  providers: [CrudOrganizationService, ContactService, Gt2LocaleService],
})
export class CrudOrganizationModule {}
