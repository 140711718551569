<div class="profile-box info-box width-100-content">
  <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title">{{ "CLIENTS.ENTERPRISE_INVOICES" | translate }}</div>
    <!-- gt2-light-green -->
    <!-- <div class=""
            style="width: 120px; height: 56px;" fxLayout="row" fxLayoutAlign="center center">
            <button type="button" 
                    matTooltip="{{ 'CLIENTS.DO_CASHING' | translate }}" 
                    mat-raised-button class=""
                    color="primary"
                    [disabled]="checkedInvoicesLength === 0"
                    (click)="onCashing()">
                {{ 'CLIENTS.DO_CASHING' | translate }}
            </button>
        </div> -->
    <div
      *ngIf="enterpriseInformation"
      fxLayout="row"
      fxLayoutAlign="start center"
      class="pr-12 header-info"
    >
      <div class="mr-8">{{ "CLIENTS.TOTAL_UNPAID_INVOICES" | translate }}</div>
      <div>{{ enterpriseInformation.total_invoices }}</div>
    </div>
  </header>
  <div class="content" fxLayout="column">
    <div fxFlex fxLayout="row" fxLayoutAlign="start start" [formGroup]="form">
      <div fxFill fxFlex="33" class="card-container">
        <mat-card
          fxLayout="column"
          fxLayoutAlign="center center"
          class="invoices-card"
        >
          <div class="title">{{ "CLIENTS.CASHING_AMOUNT" | translate }}</div>
          <mat-form-field dense class="cashing-amount">
            <div matPrefix>$</div>
            <input
              matInput
              placeholder=""
              formControlName="cashing"
              (change)="onCashingAmountChange($event)"
              [(ngModel)]="cashingAmount"
              type="text"
            />
          </mat-form-field>
        </mat-card>
      </div>

      <div fxFill fxFlex class="card-container">
        <mat-card
          fxLayout="column"
          fxLayoutAlign="center center"
          class="invoices-card"
        >
          <div class="title">
            {{ "CLIENTS.TOTAL_INVOICES_SELECTED" | translate }}
          </div>
          <div>{{ totalSelected | number: "1.2-2" }}$</div>
        </mat-card>
      </div>

      <div fxFill fxFlex="33" class="card-container">
        <mat-card
          fxLayout="column"
          fxLayoutAlign="center center"
          class="invoices-card"
        >
          <div class="title">{{ "CLIENTS.CASHING_BALANCE" | translate }}</div>
          <div>{{ cashingBalance | number: "1.2-2" }}$</div>
        </mat-card>
      </div>
      <!-- <div fxFill fxFlex="25" class="card-container">
                <mat-card fxLayout="column" fxLayoutAlign="center center" class="invoices-card">
                    <div class="title">Montant reçu</div>
                    <div>0.00$</div>
                </mat-card>
            </div>
            <div fxFill fxFlex="25" class="card-container">
                <mat-card fxLayout="column" fxLayoutAlign="center center" class="invoices-card">
                    <div class="title">Montant utilisé pour les paiements</div>
                    <div>0.00$</div>
                </mat-card>
            </div> -->

      <!-- <div fxFill fxFlex="50" class="card-container" [formGroup]="form">
                <mat-card fxLayout="column" fxLayoutAlign="center center" class="invoices-card">
                        <div class="title">Entrer un montant à encaisser</div>

                        
                        <mat-form-field class="cashing-amount">
                            <div matPrefix>$</div>
                            <input matInput placeholder="" formControlName="cashing" [(ngModel)]="cashingAmount" type="text">
                        </mat-form-field>
                        

                        <div class="title">Cocher les factures à payer</div>
                        <div class="and">ET</div>
                        
                        
                        <button type="button" matTooltip="{{ 'CLIENTS.DO_CASHING' | translate }}" mat-raised-button class="mt-8" color="primary"
                            [disabled]="checkedInvoicesLength === 0" (click)="onCashing()">
                            {{ 'CLIENTS.DO_CASHING' | translate }}
                        </button>
                    
                </mat-card>
            </div> -->
    </div>

    <mat-chip-listbox
      [selectable]="false"
      aria-label="Factures"
      class="invoice-chip-list"
    >
      <mat-chip
        *ngFor="let invoice of checkedInvoices | keyvalue"
        class="invoice-chip"
        [removable]="true"
        (removed)="removeInvoiceFromTag(invoice.value)"
      >
        {{ invoice.value.invoice_number }} -
        {{ invoice.value.event.balance_label }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-listbox>

    <div
      class="pl-24 pr-12"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div style="font-weight: 500">
        {{ "CLIENTS.CHECK_INVOICES_TO_BE_PAID" | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="loading" fxLayout="row" fxLayoutAlign="start center">
          <mat-spinner [diameter]="28" class="mr-16"></mat-spinner>
        </div>
        <!-- matTooltip="{{ 'CLIENTS.DO_CASHING' | translate }}" -->
        <button
          type="button"
          mat-raised-button
          class=""
          color="primary"
          [disabled]="checkedInvoicesLength === 0"
          (click)="onCashing()"
        >
          {{ "CLIENTS.DO_CASHING" | translate }}
        </button>
      </div>
    </div>

    <div class="pl-8 pr-8" fxLayout="row" fxLayoutAlign="end center">
      <!-- SEARCH -->
      <div
        class=""
        fxFlex="1 0 auto"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <label for="clientsSearch" class="mr-8">
          <mat-icon class="secondary-text">search</mat-icon>
        </label>
        <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
          <mat-label>{{
            "GENERIC.FILTERING_PLACEHOLDER" | translate | titlecase
          }}</mat-label>
          <input
            id="clientsSearch"
            [(ngModel)]="invoicesFilterValue"
            matInput
            #invoicesFilter
            placeholder="{{
              'GENERIC.FILTERING_PLACEHOLDER' | translate | titlecase
            }}"
          />
          <button
            mat-button
            *ngIf="invoicesFilterValue"
            matSuffix
            mat-icon-button
            (click)="clearSearch()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- / SEARCH -->
    </div>

    <!--Data table-->
    <div class="mat-white-bg mat-elevation-z2">
      <div>
        <div
          *ngIf="
            !isLoadingResults && dataSource.data && dataSource.data.length === 0
          "
          fxLayout="row"
          fxLayoutAlign="center start"
          class="py-8 mat-body-2"
        >
          <div class="mat-body-2">
            {{ "CLIENTS.NO_ENTERPRISE_INVOICES" | translate }}
          </div>
          <!-- <div *ngxPermissionsOnly="rolesService.RH_ROLES">
                        <div *ngIf="!parentIsSubRoom">{{ "ROOMS.NO_ROOMS_HR" | translate}}</div>
                        
                    </div>
                    <div *ngxPermissionsExcept="rolesService.RH_ROLES">
                        <div *ngIf="!parentIsSubRoom">{{ "ROOMS.NO_ROOMS" | translate}}</div>
                        <div *ngIf="parentIsSubRoom">{{ "ROOMS.NO_SUB_ROOMS" | translate}}</div>

                    </div> -->
        </div>

        <mat-table
          #table
          class=""
          matSort
          matSortStart="desc"
          [ngStyle]="{
            display:
              dataSource.data && dataSource.data.length === 0 ? 'none' : '',
          }"
          [@animateStagger]="{ value: '50' }"
          [dataSource]="dataSource"
        >
          <!-- Name Column -->
          <ng-container cdkColumnDef="invoice_number">
            <mat-header-cell fxFlex="120px" *cdkHeaderCellDef mat-sort-header
              >{{ "CLIENTS.ENTERPRISE_INVOICE_NUMBER" | translate }}
            </mat-header-cell>
            <mat-cell fxFlex="120px" *cdkCellDef="let row">
              <div>
                {{ row.invoice_number }}
              </div>
            </mat-cell>
          </ng-container>

          <!-- invoiced_at Column -->
          <ng-container cdkColumnDef="invoiced_at">
            <mat-header-cell fxFlex="140px" *cdkHeaderCellDef mat-sort-header
              >{{ "GENERIC.DATE" | translate }}
            </mat-header-cell>
            <mat-cell fxFlex="140px" *cdkCellDef="let row">
              <div>
                {{ row.invoiced_at_label }}
              </div>
            </mat-cell>
          </ng-container>

          <!-- total Column -->
          <ng-container cdkColumnDef="total">
            <mat-header-cell fxFlex *cdkHeaderCellDef mat-sort-header
              >{{ "GENERIC.TOTAL" | translate }}
            </mat-header-cell>
            <mat-cell fxFlex *cdkCellDef="let row">
              <div>
                {{ row.event.stats.total }}
              </div>
            </mat-cell>
          </ng-container>

          <!-- paid_amount Column -->
          <ng-container cdkColumnDef="paid_amount">
            <mat-header-cell fxFlex *cdkHeaderCellDef
              >{{ "CLIENTS.PAID_AMOUNT" | translate }}
            </mat-header-cell>
            <mat-cell fxFlex *cdkCellDef="let row">
              <div>
                {{ row.paid_amount }}
              </div>
            </mat-cell>
          </ng-container>

          <!-- balance Column -->
          <ng-container cdkColumnDef="balance">
            <mat-header-cell fxFlex *cdkHeaderCellDef
              >{{ "CLIENTS.BALANCE" | translate }}
            </mat-header-cell>
            <mat-cell fxFlex *cdkCellDef="let row">
              <div>
                <!-- {{row.event.balance_label}} -->
                {{ row.event.balance_label }}
              </div>
            </mat-cell>
          </ng-container>

          <!-- client_name Column -->
          <ng-container cdkColumnDef="name">
            <mat-header-cell fxFlex="22" *cdkHeaderCellDef mat-sort-header
              >{{ "CLIENTS.CLIENT_NAME" | translate }}
            </mat-header-cell>
            <mat-cell fxFlex="22" *cdkCellDef="let row">
              <div
                *ngIf="row.event.clients && row.event.clients.ordering_client"
              >
                {{ row.event.clients.ordering_client.name_label }}
              </div>
              <!-- <div *ngIf="row.event.clients && row.event.clients.invoicing_client">
                                {{row.event.clients.invoicing_client.name_label}}
                            </div> -->
            </mat-cell>
          </ng-container>

          <!-- status Column -->
          <ng-container cdkColumnDef="status">
            <mat-header-cell fxFlex *cdkHeaderCellDef
              >{{ "GENERIC.STATUS" | translate }}
            </mat-header-cell>
            <mat-cell fxFlex *cdkCellDef="let row">
              <div>
                {{ "GENERIC." + row.status | translate }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="actions">
            <mat-header-cell fxFlex="70px" *cdkHeaderCellDef>
              <mat-checkbox
                class=""
                (click)="$event.stopPropagation()"
                [checked]="checkboxAllComplete"
                (change)="setAllCheckbox($event.checked)"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell fxFlex="70px" *cdkCellDef="let row">
              <!-- [checked]="checkboxAllComplete" -->
              <mat-checkbox
                class=""
                [checked]="
                  checkedInvoices[row.uuid] !== null &&
                  checkedInvoices[row.uuid] !== undefined
                "
                (change)="setCheckbox(row, $event.checked)"
              >
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            *cdkRowDef="let row; columns: displayedColumns"
            matRipple
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
          </mat-row>
        </mat-table>
        <mat-paginator
          #paginator
          [length]="resultsLength"
          (page)="
            datatableHelperService.onPaginator($event, 'enterprise_invoice')
          "
          [ngStyle]="{
            display:
              dataSource && dataSource.data && dataSource.data.length === 0
                ? 'none'
                : '',
          }"
          showFirstLastButtons="true"
          [pageIndex]="0"
          [pageSize]="'enterprise_invoice' | tableSizePipe: 'large'"
          [pageSizeOptions]="datatableHelperService.pageSizeShortOptions"
        >
        </mat-paginator>

        <!-- <mat-paginator #paginator *ngIf="!tableIsEmpty" [length]="resultsLength"
                    (page)='datatableHelperService.onPaginator($event, "rooms2")' showFirstLastButtons="true"
                    [pageIndex]="0" [pageSize]="'rooms' | tableSizePipe:'large'"
                    [pageSizeOptions]="datatableHelperService.pageSizeOptions">

                </mat-paginator> -->
      </div>
    </div>
  </div>
</div>
