import {
  Component,
  Inject,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EditorModule } from 'primeng/editor';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'mail-compose',
  templateUrl: './compose.component.html',
  styleUrls: ['./compose.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    EditorModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MailComposeDialogComponent
  extends GT2PageAbstract
  implements AfterViewInit
{
  composeService?: IComposeService;
  composeData: any;
  loading: boolean = true;

  showExtraToFields: boolean = true;
  composeForm: UntypedFormGroup;
  postData: any;
  confirmDialogRef?: MatDialogRef<GenericAlertComponent>;
  editMode: any;

  /**
   * Constructor
   *
   * @param {MatDialogRef<MailComposeDialogComponent>} matDialogRef
   * @param _data
   */
  constructor(
    public matDialogRef: MatDialogRef<MailComposeDialogComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public logger: NGXLogger,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    if (_data && _data.postData) {
      this.postData = _data.postData;
    }
    if (_data && _data.composeService) {
      this.composeService = _data.composeService;
      this.loadInitData();
    }

    // Set the defaults
    this.composeForm = this.createComposeForm();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showExtraToFields = false;
    }, 800);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public loadInitData(): void {
    // //this.logger.info("MailComposeDialogComponent.loadInitData()");
    this.loading = true;
    this.composeService?.getData().subscribe(
      (response) => {
        // //this.logger.info("MailComposeDialogComponent.loadInitData() -> SUCCESS: " + JSON.stringify(response));
        this.composeData = response.data;
        // TODO: DELETE
        // this.composeData.vo.to = ["andre@agence.ninja"];
        this.loading = false;
        // //this.logger.info("MailComposeDialogComponent.loadInitData() -> composeData.vo.to: " + JSON.stringify(this.composeData.vo.to));
      },
      (error) => {
        this.logger.error(
          'MailComposeDialogComponent.loadInitData() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.loading = false;
      },
    );
  }

  public sendData(): void {
    // this.composeData.vo.to = this.composeData.vo.to.split(",");
    // //this.logger.info("MailComposeDialogComponent.sendData() -> data: " + JSON.stringify(this.composeData));
    this.composeData.vo.param.title = this.composeData.vo.subject;
    if (
      this.validateEmailsInput(this.composeData.vo.to) == false ||
      (this.composeData.vo.cc &&
        this.validateEmailsInput(this.composeData.vo.cc) == false) ||
      (this.composeData.vo.bcc &&
        this.validateEmailsInput(this.composeData.vo.bcc) == false)
    ) {
      ////this.logger.info("there is an error on to emails list");
      this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
        disableClose: false,
      });
      this.confirmDialogRef.componentInstance.alertTitle =
        this.translate.instant('COMPOSE.EMAILS_ALERT_TITLE');
      this.confirmDialogRef.componentInstance.alertMessage =
        this.translate.instant('COMPOSE.EMAILS_ALERT_MESSAGE');
    } else {
      let newComposeData = _.cloneDeep(this.composeData);
      //this.logger.info("MailComposeDialogComponent.sendData() -> newComposeData 1: " + JSON.stringify(newComposeData));
      // var objectC = {...objectA, ...objectB};
      if (this.postData) {
        newComposeData = { ...this.composeData, ...this.postData };
        //this.logger.info("MailComposeDialogComponent.sendData() -> newComposeData 1: " + JSON.stringify(newComposeData));
      }
      this.loading = true;
      this.composeService?.saveData(newComposeData).subscribe(
        (response) => {
          // //this.logger.info("MailComposeDialogComponent.sendData() -> SUCCESS: " + JSON.stringify(response));
          this.matDialogRef.close(['send', this.composeForm]);
          this.loading = false;
        },
        (error) => {
          this.logger.error(
            'MailComposeDialogComponent.sendData() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
          this.loading = false;
        },
      );
    }
  }

  /**
   * Create compose form
   *
   * @returns {FormGroup}
   */
  createComposeForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      from: new UntypedFormControl({
        value: '',
        disabled: true,
      }),
      to: new UntypedFormControl(''),
      cc: new UntypedFormControl(''),
      bcc: new UntypedFormControl(''),
      subject: new UntypedFormControl(''),
      message: new UntypedFormControl(''),
    });
  }

  /**
   * Toggle extra to fields
   */
  toggleExtraToFields(): void {
    this.showExtraToFields = !this.showExtraToFields;
  }
  public validateEmailsInput(emails: any): boolean {
    var emailsToValidate = emails;
    var lastChar = emailsToValidate.slice(-1);
    if (lastChar == ';') {
      emailsToValidate = emailsToValidate.slice(0, -1);
    }
    var emailsArray = emailsToValidate.split(';');
    for (let email of emailsArray) {
      if (this.validateEmail(email) == null) {
        ////this.logger.info(email);
        return false;
      }
    }
    return true;
  }
  public validateEmail(email: any): any | null {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  }
}

export interface IComposeService {
  parentUUID: string;
  getData(): Observable<any>;
  saveData(data: any): Observable<any>;
}
