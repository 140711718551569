import { HttpClient } from "@angular/common/http";
import { Gt2ApiService } from "./gt2-api.service";
import { ApiRoutes } from "./ApiRoutes";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";

@Injectable()
export class LabourChargeService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  // public create(nameFR: string, nameEN: string, ledgerAccount: string): any
  // {
  //     const obj: any = {name_local: {name_fr_CA: nameFR, name_en_CA: nameEN}, ledger_account: ledgerAccount};
  //     this.logger.info("LabourChargeService.create() -> obj: " + JSON.stringify(obj));
  //     return this.http.post<any>(this.api.createUrl(ApiRoutes.SERVICE_CHARGE), obj);
  // }

  public getData() {
    return this.labourChargesList();
  }

  public labourChargesList(): any {
    //this.logger.info("LabourChargeService.labourChargesList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.LABOUR_CHARGE_LIST));
  }
}

export interface SettingsPrefModel {
  object: string;
  uuid: string;
  datetime_format: DateTimeFormatModel;
  date_format: DateFormatModel;
  timezone: TimezoneModel;
  delivery_tax: DeliveryTaxModel;
  social_charge: string;
}

export interface DateTimeFormatModel {
  object: string;
  uuid: string;
  format: string;
  format_moment: string;
}

export interface DateFormatModel {
  object: string;
  uuid: string;
  format: string;
  format_moment: string;
  picker_format: string;
}

export interface TimezoneModel {
  object: string;
  uuid: string;
  name: string;
  location: string;
}

export interface DeliveryTaxModel {
  id: string;
  uuid: string;
  name: string;
  slug: string;
  tax_item_1_id: number;
  tax_item_2_id: number;
  tax_item_3_id: number;
  tax_item_4_id: number;
  from_date: string;
  to_date: string;
  percent_total: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  tax1: TaxModel;
  tax2: TaxModel;
  tax3: TaxModel;
  tax4: TaxModel;
}

export interface TaxModel {
  id: number;
  uuid: string;
  name: any;
  slug: string;
  percent_tax: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  pref: TaxPrefModel;
}

export interface TaxPrefModel {
  id: number;
  uuid: string;
  tax_number: string;
  tax_item_id: number;
  organization_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
