<div
  fxLayout="row"
  fxLayout.xs="column"
  style="min-width: 350px"
  fxLayoutAlign="start start"
  class=""
>
  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
    <div class="title">{{ "SHOPS.COST_MODE" | translate }}</div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="info text-truncate">
        {{ "SHOPS." + deliveryRules.rate_mode | translate }}
      </div>
    </div>
    <div *ngIf="!deliveryRules.rate_mode" class="info empty-value"></div>
  </div>

  <div *ngIf="editMode" class="info-line" fxFlex="50%">
    <mat-form-field class="" style="width: 100%">
      <mat-label>{{ "SHOPS.COST_MODE" | translate }}</mat-label>
      <mat-select
        placeholder="{{ 'SHOPS.COST_MODE' | translate }}"
        [(ngModel)]="deliveryRules.rate_mode"
      >
        <mat-option [value]="'RATE_MODE_FIXED_RATE'">
          {{ "SHOPS.RATE_MODE_FIXED_RATE" | translate }}</mat-option
        >
        <mat-option [value]="'RATE_MODE_RATE_PER_KM'">
          {{ "SHOPS.RATE_MODE_RATE_PER_KM" | translate }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
    <div class="title">{{ "SHOPS.LIMIT_KM" | translate }}</div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="info text-truncate">
        {{ deliveryRules.limit_distance_km }} km
      </div>
    </div>
    <div
      *ngIf="!deliveryRules.limit_distance_km"
      class="info empty-value"
    ></div>
  </div>

  <div *ngIf="editMode" class="info-line" fxFlex="50%">
    <div [formGroup]="form" class="">
      <mat-form-field class="form-input-container" fxFlex="100%">
        <mat-label>{{ "SHOPS.LIMIT_KM" | translate }}</mat-label>
        <input
          [(ngModel)]="deliveryRules.limit_distance_km"
          autocomplete="nope"
          formControlName="limit_distance_km"
          matInput
          placeholder="{{ 'SHOPS.LIMIT_KM' | translate }}"
          type="text"
        />
        <span matSuffix>km</span>
      </mat-form-field>
    </div>
  </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="">
  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
    <div class="title">{{ "SHOPS.DISTANCE_MODE" | translate }}</div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="info text-truncate">
        {{ "SHOPS." + deliveryRules.distance_mode | translate }}
      </div>
    </div>
    <div *ngIf="!deliveryRules.distance_mode" class="info empty-value"></div>
  </div>

  <div *ngIf="editMode" class="info-line" fxFlex="50%">
    <mat-form-field class="" style="width: 100%">
      <mat-label>{{ "SHOPS.DISTANCE_MODE" | translate }}</mat-label>
      <mat-select
        placeholder="{{ 'SHOPS.DISTANCE_MODE' | translate }}"
        [(ngModel)]="deliveryRules.distance_mode"
      >
        <mat-option [value]="'DISTANCE_MODE_GOOGLE_ROUTE'">
          {{ "SHOPS.DISTANCE_MODE_GOOGLE_ROUTE" | translate }}
        </mat-option>
        <mat-option [value]="'DISTANCE_MODE_CROW_FLIES'">
          {{ "SHOPS.DISTANCE_MODE_CROW_FLIES" | translate }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
    <div class="title">{{ "SHOPS.BRACKET_MODE" | translate }}</div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="info text-truncate">
        {{ "SHOPS." + deliveryRules.bracket_mode | translate }}
      </div>
    </div>
    <div *ngIf="!deliveryRules.bracket_mode" class="info empty-value"></div>
  </div>

  <div *ngIf="editMode" class="info-line" fxFlex="50%">
    <mat-form-field class="" style="width: 100%">
      <mat-label>{{ "SHOPS.BRACKET_MODE" | translate }}</mat-label>
      <mat-select
        placeholder="{{ 'SHOPS.BRACKET_MODE' | translate }}"
        [(ngModel)]="deliveryRules.bracket_mode"
      >
        <mat-option [value]="'BRACKET_MODE_SINGLE'">
          {{ "SHOPS.BRACKET_MODE_SINGLE" | translate }}</mat-option
        >
        <mat-option [value]="'BRACKET_MODE_MULTI'">
          {{ "SHOPS.BRACKET_MODE_MULTI" | translate }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</div>

<!-- BRAKET MODES -->

<div
  *ngIf="deliveryRules.bracket_mode === 'BRACKET_MODE_SINGLE'"
  class="profile-box info-box general"
>
  <header class="accent">
    <div class="sub-title">
      {{ "SHOPS.BRACKET_MODE_SINGLE_LABEL" | translate }}
    </div>
  </header>

  <div class="sub-content" fxLayout="column">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start start"
      class=""
    >
      <div *ngIf="!editMode" class="info-line" fxFlex="50%">
        <div class="title">{{ "SHOPS.RATE" | translate }}</div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="info text-truncate">{{ deliveryRules.single_rate }}</div>
        </div>
        <div
          *ngIf="deliveryRules.single_rate === null"
          class="info empty-value"
        ></div>
      </div>

      <div *ngIf="editMode" class="info-line" fxFlex="50%">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "SHOPS.RATE" | translate }}</mat-label>
            <input
              [(ngModel)]="deliveryRules.single_rate"
              autocomplete="nope"
              formControlName="single_rate"
              matInput
              placeholder="{{ 'SHOPS.RATE' | translate }}"
              type="text"
            />
            <span matSuffix>$</span>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="deliveryRules.bracket_mode === 'BRACKET_MODE_MULTI'"
  class="profile-box info-box general"
>
  <header class="accent" fx-fxLayout="row" fxLayoutAlign="space-between center">
    <div class="sub-title">
      {{ "SHOPS.BRACKET_MODE_MULTI_LABEL" | translate }}
    </div>
    <div
      *ngIf="editMode"
      class="gt2-light-green"
      fxLayout="row"
      fxLayoutAlign="center center"
      style="width: 60px; height: 40px"
    >
      <button
        (click)="onAddDeliveryBracket()"
        class=""
        mat-icon-button
        matTooltip="{{ 'SHOPS.ADD_BRACKETS' | translate }}"
        type="button"
      >
        <mat-icon class="" color="">add</mat-icon>
      </button>
    </div>
  </header>

  <div class="sub-content bracket-list" fxLayout="column">
    <div
      *ngFor="let item of deliveryRules.distance_brackets; let i = index"
      class="bracket-item"
    >
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start start"
        class=""
      >
        <div *ngIf="!editMode" class="info-line-bracket" fxFlex="50%">
          <div class="title">
            {{ "SHOPS.MIN_BRACKET_DISTANCE" | translate }}
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div class="info text-truncate">{{ item.min }}</div>
          </div>
          <div *ngIf="item.min === null" class="info empty-value"></div>
        </div>

        <div *ngIf="editMode" class="info-line-bracket" fxFlex="50%">
          <mat-form-field
            class="form-input-container bracket-item-input"
            fxFlex="100%"
            style="width: 100%"
          >
            <mat-label>{{
              "SHOPS.MIN_BRACKET_DISTANCE" | translate
            }}</mat-label>
            <input
              [(ngModel)]="item.min"
              autocomplete="nope"
              matInput
              numeric
              [decimals]="2"
              [allowNegative]="false"
              placeholder="{{ 'SHOPS.MIN_BRACKET_DISTANCE' | translate }}"
              type="text"
            />
            <span matSuffix>km</span>
          </mat-form-field>
        </div>

        <div *ngIf="!editMode" class="info-line-bracket" fxFlex="50%">
          <div class="title">
            {{ "SHOPS.MAX_BRACKET_DISTANCE" | translate }}
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div class="info text-truncate">{{ item.max }}</div>
          </div>
          <div *ngIf="item.max === null" class="info empty-value"></div>
        </div>

        <div *ngIf="editMode" class="info-line-bracket" fxFlex="50%">
          <mat-form-field
            class="form-input-container bracket-item-input"
            fxFlex="100%"
            style="width: 100%"
          >
            <mat-label>{{
              "SHOPS.MAX_BRACKET_DISTANCE" | translate
            }}</mat-label>
            <input
              [(ngModel)]="item.max"
              autocomplete="nope"
              matInput
              numeric
              [decimals]="2"
              [allowNegative]="false"
              placeholder="{{ 'SHOPS.MAX_BRACKET_DISTANCE' | translate }}"
              type="text"
            />
            <span matSuffix>km</span>
          </mat-form-field>
        </div>

        <button
          mat-icon-button
          *ngIf="editMode"
          class=""
          (click)="onRemoveBracket(item, i)"
          aria-label="Palette"
        >
          <mat-icon class="s-20">delete_forever</mat-icon>
        </button>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start start"
        class=""
      >
        <div *ngIf="!editMode" class="info-line-bracket" fxFlex="50%">
          <div class="title">{{ "SHOPS.RATE" | translate }}</div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div class="info text-truncate">
              {{ deliveryRules.rate_brackets[i] }}
            </div>
          </div>
          <div
            *ngIf="
              !deliveryRules.rate_brackets[i] ||
              deliveryRules.rate_brackets[i] === null
            "
            class="info empty-value"
          ></div>
        </div>

        <div *ngIf="editMode" class="-bracket" fxFlex="50%">
          <mat-form-field
            class="form-input-container bracket-item-input"
            fxFlex="100%"
            style="width: 100%"
          >
            <mat-label>{{ "SHOPS.RATE" | translate }}</mat-label>
            <input
              [(ngModel)]="deliveryRules.rate_brackets[i]"
              autocomplete="nope"
              matInput
              numeric
              [decimals]="2"
              [allowNegative]="false"
              placeholder="{{ 'SHOPS.RATE' | translate }}"
              type="text"
            />
            <span matSuffix>$</span>
          </mat-form-field>
        </div>

        <div class="info-line" fxFlex="50%">
          <div class="info text-truncate">&nbsp;</div>
        </div>
        <!-- delete button space -->
        <div style="width: 40px">&nbsp;</div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start" class="">
  <button
    (click)="onSaveDeliveryRules()"
    color="accent"
    class="save-btn"
    [disabled]="
      !shopPreference ||
      (deliveryRules.bracket_mode === 'BRACKET_MODE_MULTI' &&
        deliveryRules.distance_brackets.length === 0)
    "
    mat-raised-button
  >
    {{ "GENERIC.SAVE" | translate }}
  </button>
</div>
