import { ServiceChargeMultiplyEnum } from './../../enum/service-charge-multiply-enum';
import {
  DistributionModeEnum,
  ExportFormatEnum,
  ExportToEnum,
  LocaleEnum,
  PaperEnum,
  ReportsEnum,
} from './../../reports/ReportsEnum';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../api/auth-service.service';
import { ResponsesStatus } from '../../api/ResponsesStatus';
import { ErrorDialogComponent } from '../../components/dialogs/error-dialog/error-dialog.component';
import { AppRoutes } from '../../const/AppRoutes';
import { GT2ConstantsEnum } from '../../const/GT2ConstantsEnum';
import { IconsService } from '../../services/icons.service';
import { ToastrService } from 'ngx-toastr';
import { GtTools } from '../../directives/gt-clients.directive';

export class GT2PageAbstract {
  static USER_ROLE: string = '';
  //
  dialogRef: any;
  errorDialogRef: any;
  //
  gt = GtTools;
  tooltipOptionsEditButtons = {
    showDelay: 900,
    position: 'left',
    hideDelay: 900,
  };

  tooltipOptionsEditButtonsDisabled = {
    position: 'left',
    hideDelay: 900,
  };

  public DistributionModeEnum = DistributionModeEnum;
  public ExportFormatEnum = ExportFormatEnum;
  public ExportToEnum = ExportToEnum;
  public LocaleEnum = LocaleEnum;
  public PaperEnum = PaperEnum;
  public ReportsEnum = ReportsEnum;
  public ServiceChargeMultiplyEnum = ServiceChargeMultiplyEnum;

  constructor() {}

  public isGod(role: string): boolean {
    return role === GT2ConstantsEnum.GOD;
  }

  public isAdmin(role: string): boolean {
    return role === GT2ConstantsEnum.GOD;
  }

  public getIcon(iconName: string): string {
    return IconsService.getIconStatic(iconName);
  }

  public validatePageAccess(
    authService: AuthenticationService,
    router: Router,
    redirect: string = '/profile'
  ): void {
    authService.isPageAuthorized(router.url).subscribe(
      (response) => {
        if (
          response.response_status === ResponsesStatus.OK &&
          response.response === true
        ) {
        } else {
          router.navigateByUrl(redirect);
        }
      },
      (error) => {}
    );
  }

  public handleAPIError(
    error: any,
    dialog: MatDialog,
    toastr: ToastrService | null,
    toastrError: string | null
  ): void {
    if (!dialog || !error) {
      return;
    }

    // Show a toastr error message if error is not a 456
    if (toastr && toastrError && error.status !== 456) {
      toastr.error('', toastrError);
    }

    if (error.status === 422) {
      // OK
      this.errorDialogRef = dialog.open(ErrorDialogComponent, {
        disableClose: false,
      });

      this.errorDialogRef.componentInstance.setCode('422');
      this.errorDialogRef.componentInstance.setError(error);

      this.errorDialogRef.afterClosed().subscribe((result: any) => {
        this.errorDialogRef = null;
      });
    } else if (error.status === 456) {
      // OK
      this.errorDialogRef = dialog.open(ErrorDialogComponent, {
        disableClose: false,
      });

      this.errorDialogRef.componentInstance.useError456Template = true;
      this.errorDialogRef.componentInstance.setError(error);

      this.errorDialogRef.afterClosed().subscribe((result: any) => {
        this.errorDialogRef = null;
      });
    } else if (error.status === 401) {
      // Do nothing
    } else if (error.status === 404) {
      // Do nothing
    } else if (error.status === 500) {
      // Do nothing
    } else if (error.status === 408) {
      this.showError(error, dialog, 'Request Time-out');
    } else if (error.status === 400) {
      this.showError(error, dialog, 'Bad Request.');
    } else if (error.status === 429) {
      this.showError(error, dialog, 'Too Many Requests.');
    } else if (error.status === 403) {
      this.showError(error, dialog, 'Forbidden');
    } else if (error.status === 405) {
      this.showError(error, dialog, 'Method Not Allowed');
    } else if (error.status === 406) {
      this.showError(error, dialog, 'Not Acceptable');
    } else if (error.status === 444) {
      this.showError(error, dialog, 'No Response');
    } else if (error.status === 495) {
      this.showError(error, dialog, 'SSL Certificate Error');
    } else if (error.status === 496) {
      this.showError(error, dialog, 'SSL Certificate Required');
    } else if (error.status === 497) {
      this.showError(error, dialog, 'HTTP Request Sent to HTTPS Port');
    } else if (error.status === 498) {
      this.showError(error, dialog, 'Token expired/invalid');
    } else if (error.status === 499) {
      this.showError(error, dialog, 'Client Closed Request');
    } else if (error.status === 450) {
      this.showError(error, dialog, 'Blocked by Windows Parental Controls');
    } else if (error.status === 431) {
      this.showError(error, dialog, 'Request Header Fields Too Large');
    } else if (error.status === 409) {
      this.showError(error, dialog, 'Conflict');
    } else if (error.status === 410) {
      this.showError(error, dialog, 'Gone');
    } else if (error.status === 411) {
      this.showError(error, dialog, 'Length Required');
    } else if (error.status === 412) {
      this.showError(error, dialog, 'Precondition Failed');
    } else if (error.status === 413) {
      this.showError(error, dialog, 'Request Entity Too Large');
    } else if (error.status === 414) {
      this.showError(error, dialog, 'Request-URI Too Long');
    } else if (error.status === 415) {
      this.showError(error, dialog, 'Unsupported Media Type');
    } else if (error.status === 416) {
      this.showError(error, dialog, 'Requested range unsatisfiable');
    } else if (error.status === 417) {
      this.showError(error, dialog, 'Expectation failed');
    } else if (error.status === 421) {
      this.showError(error, dialog, 'Bad mapping / Misdirected Request');
    } else if (error.status === 0) {
      this.errorDialogRef = dialog.open(ErrorDialogComponent, {
        disableClose: false,
      });

      let customError: any = {
        error: {
          title: 'Erreur',
          errors: {
            key: ['Une erreur inconnue est survenue. (' + error.status + ')'],
          },
        },
      };

      if (GT2PageAbstract.USER_ROLE === GT2ConstantsEnum.GOD) {
        customError = {
          error: {
            title: 'Error status 0 (God user only)',
            errors: {
              key: [
                'Error status 0, Http failure response for (unknown url). Please check for code that could break execution like a dump() and that the JSON object is valid.',
              ],
            },
          },
        };
      }
      // else
      // {
      //     customError = {
      //         error:
      //         {
      //             title: "Erreur",
      //             errors: {
      //                 key: ["Une erreur inconnue est survenue."]
      //             },
      //         }
      //     };
      // }

      if (customError) {
        this.errorDialogRef.componentInstance.setError(customError);
        this.errorDialogRef.afterClosed().subscribe((result: any) => {
          this.errorDialogRef = null;
        });
      } else {
        this.errorDialogRef.componentInstance.setError(error);
      }
    } else {
      this.errorDialogRef = dialog.open(ErrorDialogComponent, {
        disableClose: false,
      });

      let customError: any = {
        error: {
          title: 'Erreur',
          errors: {
            key: ['Une erreur inconnue est survenue.'],
          },
        },
      };
      if (GT2PageAbstract.USER_ROLE === GT2ConstantsEnum.GOD) {
        this.errorDialogRef.componentInstance.setError(error);
      } else {
        this.errorDialogRef.componentInstance.setError(customError);
      }

      // let customError: any;
      // if (GT2PageAbstract.USER_ROLE === GT2ConstantsEnum.GOD)
      // {
      //     customError = {
      //         error:
      //         {
      //             title: "Error status 0 (God user only)",
      //             errors: {
      //                 key: ["Error status 0, Http failure response for (unknown url). Please check for code that could break execution like a dump() and that the JSON object is valid."]
      //         },                    }
      //     };
      // }
      // else
      // {
      //     customError = {
      //         error:
      //         {
      //             title: "Erreur",
      //             errors: {
      //                 key: ["Une erreur inconnue est survenue."]
      //             },
      //         }
      //     };
      // }

      // if (customError) {
      //     this.errorDialogRef.componentInstance.setError(customError);
      //     this.errorDialogRef.afterClosed().subscribe((result) => {
      //         this.errorDialogRef = null;
      //     });

      //     this.errorDialogRef.afterClosed().subscribe((result) => {
      //         this.errorDialogRef = null;
      //     });
      // } else {
      //     this.errorDialogRef.componentInstance.setError(error);
      // }
    }
  }

  public showError(error: any, dialog: MatDialog, message: string): void {
    this.errorDialogRef = dialog.open(ErrorDialogComponent, {
      disableClose: false,
    });

    let customError: any = {
      error: {
        title: 'Erreur',
        errors: {
          key: [message + ' (' + error.status + ')'],
        },
      },
    };
    this.errorDialogRef.componentInstance.setError(customError);
  }

  public openInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  // public downloadFile(url: string): void
  // {
  //     // const url: string = environment.api.protocol + environment.api.domain + '/clients/pdf/' + this.client.uuid;
  //     // window.location.href = url;
  // }

  public onPageBack(
    location: Location,
    router: Router,
    alternativeRoute?: string
  ): void {
    if (window.history.length > 1) {
      location.back();
    } else if (alternativeRoute) {
      router.navigateByUrl(alternativeRoute);
    } else {
      router.navigateByUrl(AppRoutes.DASHBOARD);
    }
  }
}
