import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { IComposeService } from '../content/compose/compose.component';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import { GT2FirebaseService } from '../../services/gt2-firebase.service';

@Injectable()
export class EmployeeAssignmentsComposeService implements IComposeService {
  parentUUID: any;
  byJobFunction: boolean = false;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getData(): Observable<any> {
    //this.logger.info("EmployeeAssignmentsComposeService.getData()");
    if (this.byJobFunction) {
      return this.http.get<any>(
        this.api.createUrl(ApiRoutes.EMPLOYEE_ASSIGNMENTS_JOB_COMPOSER_GET) +
          this.parentUUID
      );
    } else {
      return this.http.get<any>(
        this.api.createUrl(ApiRoutes.EMPLOYEE_ASSIGNMENTS_DATES_COMPOSER_GET) +
          this.parentUUID
      );
    }
  }

  public saveData(data: any): Observable<any> {
    //this.logger.info("EmployeeAssignmentsComposeService.saveData()");
    if (this.byJobFunction) {
      return this.http.post<any>(
        this.api.createUrl(ApiRoutes.EMPLOYEE_ASSIGNMENTS_JOB_COMPOSER_SAVE) +
          this.parentUUID +
          '/' +
          GT2FirebaseService.CLIENT_KEY,
        data
      );
    } else {
      return this.http.post<any>(
        this.api.createUrl(ApiRoutes.EMPLOYEE_ASSIGNMENTS_DATES_COMPOSER_SAVE) +
          this.parentUUID +
          '/' +
          GT2FirebaseService.CLIENT_KEY,
        data
      );
    }
  }
}
