import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { CrudDatatableGenericService } from '../../../api/crud-datatable-generic.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-generic-create',
  templateUrl: './generic-create.component.html',
  styleUrls: ['./generic-create.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    MatInputModule,
    MatButtonModule,
  ],
  providers: [CrudDatatableGenericService],
})
export class GenericCreateComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  serviceURL!: string;
  createPlaceholder!: string;
  dialogTitle!: string;
  useEnglish: boolean = false;
  form: UntypedFormGroup;
  model: any;
  loading: boolean = false;
  newCategory_fr_CA!: any;
  newCategory_en_CA!: any;

  constructor(
    public override dialogRef: MatDialogRef<GenericCreateComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crudDatatableGenericService: CrudDatatableGenericService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    if (data) {
      this.dialogTitle = data.dialogTitle;
      this.serviceURL = data.serviceURL;
      this.createPlaceholder = data.createPlaceholder;

      if (data.useEnglish !== null) {
        this.useEnglish = data.useEnglish;
      }
    }

    if (this.useEnglish) {
      this.form = this.formBuilder.group({
        name_fr_CA: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        name_en_CA: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
      });
    } else {
      this.form = this.formBuilder.group({
        name_fr_CA: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        name_en_CA: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
          ],
        ],
      });
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateNew() {
    // this.logger.info("GenericCreateComponent.onCreateNew()");
    this.loading = true;
    // this.logger.info("GenericCreateComponent.onCreateNew() - this.newCategory_fr_CA: " + this.newCategory_fr_CA);
    // this.logger.info("GenericCreateComponent.onCreateNew() - this.newCategory_en_CA: " + this.newCategory_en_CA);
    // this.logger.info("GenericCreateComponent.onCreateNew() - this.serviceURL: " + this.serviceURL);
    this.crudDatatableGenericService
      .create(
        this.serviceURL,
        this.newCategory_fr_CA,
        this.newCategory_en_CA,
        this.useEnglish,
      )
      .subscribe(
        (response: any) => {
          // this.logger.info("GenericCreateComponent.onCreateNew() -> SUCCESS:" + JSON.stringify(response));
          this.loading = false;
          this.toastr.success(
            this.translate.instant('GENERIC_CREATE.CREATE_SUCCESS_TOAST'),
          );
          this.newCategory_fr_CA = null;
          this.newCategory_en_CA = null;
          this.dialogRef.close(response.data);
        },
        (error: any) => {
          this.loading = false;
          this.logger.error(
            'GenericCreateComponent.onCreateNew() -> ERROR' +
              JSON.stringify(error),
          );
          // this.toastr.error(this.translate.instant('GENERIC_CREATE.CREATE_ERROR_TOAST'));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC_CREATE.CREATE_ERROR_TOAST'),
          );
        },
      );
  }
}
