import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { ClientsService } from '../../../api/clients.service';
import { EnterprisesService } from '../../../api/enterprises.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { MatBadgeModule } from '@angular/material/badge';

@Component({
  selector: 'app-client-attach-enterprise',
  templateUrl: './client-attach-enterprise.component.html',
  styleUrls: ['./client-attach-enterprise.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    TranslateModule,
    Gt2PrimengModule,
    MatBadgeModule,
  ],
})
export class ClientAttachEnterpriseComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  enterprises?: any[];
  client: any;
  filterValue: string = '';
  selectedEnterprise: any;
  isLoading: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<ClientAttachEnterpriseComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private logger: NGXLogger,
    public dialog: MatDialog,
    private clientsService: ClientsService,
    private enterprisesService: EnterprisesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    if (data.client) {
      this.client = data.client;
    }

    this.isLoading = true;
    this.enterprisesService.getAllEnterprise().subscribe(
      (response) => {
        // this.logger.info("ClientAttachEnterpriseComponent.constructor() -> SUCCESS: " + JSON.stringify(response));
        this.enterprises = response.data;
        this.isLoading = false;
      },
      (error) => {
        this.logger.error(
          'ClientAttachEnterpriseComponent.constructor() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.isLoading = false;
      },
    );
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onSelectEnterprise(item: any): void {
    //this.logger.info("ClientAttachEnterpriseComponent.onSelectEnterprise()");
    this.selectedEnterprise = item;
  }

  public onAttachEnterprise(): void {
    //this.logger.info("ClientAttachEnterpriseComponent.onAttachEnterprise()");
    this.isLoading = true;
    this.clientsService
      .attachClientToEnterprise(this.client.uuid, this.selectedEnterprise.uuid)
      .subscribe(
        (response) => {
          // this.logger.info("ClientAttachEnterpriseComponent.onAttachEnterprise() -> SUCCESS: " + JSON.stringify(response));
          this.isLoading = false;
          this.dialogRef.close(response);
        },
        (error) => {
          this.logger.error(
            'ClientAttachEnterpriseComponent.onAttachEnterprise() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.isLoading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public clearSearch(): void {
    this.filterValue = '';
  }
}
