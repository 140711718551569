import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import { DateFormatPreference } from './DateFormatPreference';
import { Injectable } from '@angular/core';

@Injectable()
export class GT2DateAdapter extends MomentDateAdapter {
  override parse(value: any, parseFormat: string | string[]): Moment | null {
    const dateFormat: string | string[] =
      DateFormatPreference.getDateFormat() || parseFormat;
    return super.parse(value, dateFormat);
  }

  override format(date: Moment, displayFormat: string): string {
    const dateFormat: string | string[] =
      DateFormatPreference.getDateFormat() || displayFormat;
    return super.format(date, dateFormat);
  }

  override getFirstDayOfWeek(): number {
    return 0;
  }
}
