export const locale = {
  lang: "en-CA",
  data: {
    CLIENTS: {
      CLIENT_NUMBER: "Client #",
      ENTERPRISE_NUMBER: "Enterprise #",
      ACTIVE: "Active",
      GENERAL: "General",
      PROFILE: "Profile",
      SALUTATION: "Salutation",
      SELECT_TITLE: "Select a title",
      COMM_LANGUAGE: "Communication language",
      DELETE_CLIENT: "Delete Client",
      DELETE_ENTERPRISE: "Delete Enterprise",
      EDIT_CLIENT: "Edit Client",
      EDIT_ENTERPRISE: "Edit Enterprise",
      CLOSE_EDIT: "Close edit mode",
      ADD_ORDER: "Add order",
      ADD_CLIENT: "Create new client",
      ADD_BRANCH: "Create new branch",
      ADD_ENTERPRISE: "Add enterprise",
      ATTACH_ENTERPRISE: "Attach an enterprise",
      VISIT_ENTERPRISE: "Visit enterprise",
      VISIT_ENTERPRISE_PARENT: "Visit parent enterprise",
      ADD_ENTERPRISE_TO_CLIENT: "Add enterprise to this client",
      ATTACH_CLIENT_TO_ENTERPRISE: "Attach this client to this enterprise/branch",
      ADD_CLIENT_TO_BRANCH: "Add a client to this branch",
      ADD_CLIENT_TO_ENTERPRISE: "Add a client to this enterprise",
      ADD_BRANCH_TO_ENTERPRISE: "Add a branch to this enterprise",
      DELETE_SUCCESS: "Client deleted successfully",
      CREATE_CLIENT: "Create a client",
      CREATE_ENTERPRISE: "Create an enterprise",
      CREATE_CLIENT_ENTERPRISE: "Create a client & an enterprise",
      CREATE_CLIENT_ENTERPRISE_BRANCH: "Add a client, an enterprise and a branch",
      ROOMS: "Rooms",
      CLIENT_CATEGORIES: "Client categories",
      CLIENT_DELETE_CONFIRM: "Are you sure you want to delete this client?",
      ENTERPRISE_DELETE_CONFIRM: "Are you sure you want to delete this enterprise?",
      // 'NO_PROJECTS': 'No projects',
      NO_EVENTS: "No events",
      CREATE_PROPOSAL: "Create a proposal",
      CREATE_INVOICE: "Create an invoice",
      CREATE_ORDER: "Create an order",
      EVENT_NUMBER: "Event number",
      EVENT_DATE: "Event date",
      CAPACITY: "Capacity",
      ROYALTY: "Royalty",
      GENERATE_ACCOUNT_STATEMENT: "Generate account statement",
      GENERATE_ACCOUNT_HISTORIC: "Historic",
      GENERATE_ENTERPRISE_INVOICES: "Enterprise invoices",
      PAYMENT_DEADLINE: "Payment deadline",
      PAYMENT_DEADLINES: "Payment deadlines",
      SHOP_NAME: "Shop name",
      SHOP_USER_LABEL: "Online shop user",
      CONTACT: "Contact",
      IS_CORPO: "Business account",
      NO_ENTERPRISE_INVOICES: "No invoices for this enterprise",
      ENTERPRISE_INVOICES: "Collection",
      ENTERPRISE_INVOICE_NUMBER: "Invoice #",
      PAID_AMOUNT: "Paid",
      BALANCE: "Balance",
      CLIENT_NAME: "Client name",
      DO_CASHING: "Cash out",
      CASHING_TITLE: "New collection - invoice",
      SELECTED_INVOICES: "Selected invoices",
      DEPOSIT_TITLE: "New cashing - Deposit",
      DEPOSIT_EDIT_TITLE: "Edit cashing - Deposit",
      INVOICE_TITLE: "Edit cashing - Invoice",
      INVOICE_EDIT_TITLE: "Edit cashing - Invoice",
      MESSAGE_TITLE: "Cashing",
      MESSAGE_MESSAGE: "Vous n’avez pas de demande de dépot disponible à l’encaissement, ni de facture",
      PAYMENT_MODES: "Modes of payment",
      REFERENCE_NUMBER: "Reference number",
      DEPOSIT_REQUEST: "Deposit request",
      TOTAL_UNPAID_INVOICES: "Total unpaid invoices:",
      CASHING_AMOUNT: "Enter cashing amount",
      TOTAL_INVOICES_SELECTED: "Total invoices selected",
      CASHING_BALANCE: "Cashing balance",
      CHECK_INVOICES_TO_BE_PAID: "CHECK THE INVOICES TO BE PAID",
    },
    REPORTS_HELP: {
      TITLE: "Reports",
      REPORT_CLIENT_STATEMENT_CLIENT: "Shows the net sum of items ordered by category on confirmed, invoiced or paid events.<br><br>Does not take into account adjustment invoices and credit notes.",
      REPORT_AGE_ACCOUNTS: "This report includes corporate and individual clients.",
      REPORT_ADMIN_SALE_STATS_BY_DELIVERY_TYPE:
        "Shows the net sum of items ordered by category on confirmed, invoiced or paid events.<br><br>Does not take into account adjustment invoices and credit notes.",
      REPORT_ADMIN_SALE_STATS_BY_ENTERPRISE_PRELIMINARY: "Shows the net sum of items ordered by an enterprise on unbilled and billed orders.",
      REPORT_ADMIN_SALE_STATS_BY_ENTERPRISE: "Shows the net sum of items ordered by enterprises on invoiced orders.",
    },
  },
};
