<div *ngIf="collectionData && dataSource" class="prod-zone-jul">
  <div fxLayout="row" class="tree-header2" fxLayoutAlign="end center">
    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="start center"
      class="right-border"
    >
      Zone de production
    </div>
    <div
      fxFlex="80px"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="right-border"
    >
      Nombre
    </div>
    <div fxFlex="80px" fxLayout="row" fxLayoutAlign="center center ">Total</div>
  </div>

  <mat-tree
    [dataSource]="dataSourceTree"
    [treeControl]="treeControl"
    class="tree-list"
  >
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodePadding
      matTreeNodePaddingIndent="20"
      (click)="onTreeOrderClick(node)"
      class="order-tree-node bottom-border"
    >
      <!-- use a disabled button to provide padding for tree leaf -->
      <div style="width: 50px">
        <button mat-icon-button disabled></button>
      </div>

      <div fxFlex="100" fxLayout="row" fxLayoutAlign="end start">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center" class>
          <mat-icon class="s-16">{{ iconsService.getIcon("ORDER") }}</mat-icon>
          <div style="margin-left: 8px">{{ node.name }}</div>
        </div>
        <div
          fxFlex="80px"
          fxLayout="row"
          fxLayoutAlign="center center"
          class="left-border"
        >
          &nbsp;{{ node.qty | number: "1.0-0" }}
        </div>
        <div
          fxFlex="80px"
          fxLayout="row"
          fxLayoutAlign="center center"
          class="left-border"
        >
          &nbsp;
        </div>
      </div>
    </mat-tree-node>

    <!-- [ngStyle]="{'width': treeControl.isExpanded(node) ? '50px' : '20px'}" -->
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      matTreeNodePaddingIndent="20"
      class="bottom-border node-toggle"
      [ngClass]="node.middle ? 'middle-tree-node' : ''"
    >
      <div style="width: 50px">
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
      </div>
      <div
        matTreeNodeToggle
        fxFlex="100"
        fxLayout="row"
        fxLayoutAlign="end start"
      >
        <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="">
          <mat-icon *ngIf="!node.is_root" class="s-16">{{
            iconsService.getIcon("PRODUCT")
          }}</mat-icon>
          <div style="margin-left: 8px">{{ node.name }}</div>
        </div>
        <div
          fxFlex="80px"
          fxLayout="row"
          fxLayoutAlign="center center"
          class="left-border"
        >
          &nbsp;
        </div>
        <div
          fxFlex="80px"
          fxLayout="row"
          fxLayoutAlign="center center"
          class="left-border"
        >
          &nbsp;{{ node.total | number: "1.0-0" }}
        </div>
      </div>
    </mat-tree-node>

    <!-- <mat-tree-node *matTreeNodeDef="let node;when: isMiddle" matTreeNodePadding matTreeNodePaddingIndent="20" class="">
        
            
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="end start">
                <div fxFlex fxLayout="row" fxLayoutAlign="center center">{{node.name}}</div>
                <div fxFlex="12" fxLayout="row" fxLayoutAlign="center center" class="left-border">&nbsp;</div>
                
            </div>
        </mat-tree-node> -->
  </mat-tree>

  <!-- <div class="back-container"></div> -->

  <!-- <mat-table #table class="" matSort matSortStart="desc" (matSortChange)="sortData($event)"
        [@animateStagger]="{value:'30'}" [dataSource]="dataSource">

        <ng-container cdkColumnDef="name">
            <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header>{{ 'GENERIC.EVENT' | translate}}
            </mat-header-cell>
            <mat-cell fxFlex="50" *cdkCellDef="let event">
                <p class="text-truncate font-weight-600">{{event.event_name}}</p>
                
            </mat-cell>
        </ng-container>       

        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns;" (click)="onNavigateToOrder(row)"
            [ngStyle]="{'background-color': (row.status === 'EVENT_ABORT' || row.status === 'EVENT_CANCEL') ? '#ff867d' : ''}"
            [@animate]="{value:'*',params:{y:'100%'}}">
        </mat-row>
    </mat-table> -->
</div>

<!-- <div *ngIf="collectionData && dataSource" class="prod-zone-jul-test">
    <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="right-border">Zone de production</div>
    <div fxFlex="12" fxLayout="row" fxLayoutAlign="center center" class="right-border">Nombre</div>
    <div fxFlex="12" fxLayout="row" fxLayoutAlign="center center">Total</div>
</div> -->
