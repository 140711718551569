export const locale = {
  lang: "fr-CA",
  data: {
    SPECIFIC_CONDITIONS: {
      CREATE_SUCCESS_TOAST: "Succès de la création!",
      CREATE_ERROR_TOAST: "Une erreur est survenue lors de la création!",
      DELETE_SUCCESS_TOAST: "Succès de l'effaçage!",
      DELETE_ERROR_TOAST: "Une erreur est survenue lors de l'effaçage!",
      EDIT_SUCCESS_TOAST: "Succès de la modification!",
      EDIT_ERROR_TOAST: "Une erreur est survenue lors de la sauvegarde de la modification!",
    },
  },
};
