export const locale = {
  lang: "en-CA",
  data: {
    CREATE_SHOP: {
      TITLE: "New shop",
      SELECT_TYPE: "Select a shop type",
      INTEGRATION_TYPE: "Integration type",
      SELL_TYPE: "Sales mode",
    },
  },
};
