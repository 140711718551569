<div style="min-width: 350px" class="">
  <ng-template #show_ctrl_themeHintTemplate>
    <p>{{ "SHOPS.SHOW_CTRL_THEME_HINT" | translate }}</p>
  </ng-template>

  <ng-template #show_ctrl_theme_modeHintTemplate>
    <p>{{ "SHOPS.SHOW_CTRL_THEME_MODE_HINT" | translate }}</p>
  </ng-template>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "SHOPS.THEME_SELECT" | translate }}</div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="info text-truncate">{{ shop.default_theme.title }}</div>
      </div>
      <div *ngIf="!shop.default_theme" class="info empty-value"></div>
    </div>

    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "SHOPS.THEME_SELECT" | translate }}</div>
      <mat-form-field
        floatLabel="auto"
        class="theme-selector"
        style="width: 90%"
      >
        <mat-select [(ngModel)]="defaultTheme">
          <mat-option
            (onSelectionChange)="onThemeChange($event, theme)"
            *ngFor="let theme of shop.themes"
            [value]="theme.id"
          >
            {{ theme.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "SHOPS.DARK_THEME" | translate }}</div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="shop.theme_mode === 'light-theme'" class="info">
          {{ "SHOPS.THEME_MODE_LIGHT" | translate }}
        </div>
        <div *ngIf="shop.theme_mode !== 'light-theme'" class="info">
          {{ "SHOPS.THEME_MODE_DARK" | translate }}
        </div>
      </div>
      <div *ngIf="!shop.theme_mode" class="info empty-value"></div>
    </div>

    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "SHOPS.DARK_THEME" | translate }}</div>
      <mat-slide-toggle
        labelPosition="before"
        class="mat-body dark-slide"
        [ngModel]="isThemeDark"
        (click)="$event.stopPropagation()"
        (change)="onThemeDarkChange($event)"
      >
        <div class="info">{{ "SHOPS.THEME_MODE_DARK" | translate }}</div>
      </mat-slide-toggle>
    </div>
  </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
    <div class="title">
      {{ "SHOPS.SHOW_CTR_THEME" | translate }}
      <button
        class="mb-4"
        mat-icon-button
        matTooltip="show_ctrl_themeHintTemplate"
        content-type="template"
        type="button"
      >
        <mat-icon class="s-20" color="">help</mat-icon>
      </button>
    </div>
    <div *ngIf="shop.show_ctrl_theme" class="">
      {{ "GENERIC.YES" | translate }}
    </div>
    <div *ngIf="!shop.show_ctrl_theme" class="">
      {{ "GENERIC.NO" | translate }}
    </div>
  </div>

  <div *ngIf="editMode" class="info-line" fxFlex="50%">
    <mat-checkbox
      labelPosition="after"
      color="accent"
      [(ngModel)]="shop.show_ctrl_theme"
      [ngModelOptions]="{ standalone: true }"
      class=""
    >
      {{ "SHOPS.SHOW_CTR_THEME" | translate }}
    </mat-checkbox>
  </div>

  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
    <div class="title">
      {{ "SHOPS.SHOW_CTR_THEME_MODE" | translate }}
      <button
        class="mb-4"
        mat-icon-button
        matTooltip="show_ctrl_theme_modeHintTemplate"
        content-type="template"
        type="button"
      >
        <mat-icon class="s-20" color="">help</mat-icon>
      </button>
    </div>
    <div *ngIf="shop.show_ctrl_theme_mode" class="">
      {{ "GENERIC.YES" | translate }}
    </div>
    <div *ngIf="!shop.show_ctrl_theme_mode" class="">
      {{ "GENERIC.NO" | translate }}
    </div>
  </div>

  <div *ngIf="editMode" class="info-line" fxFlex="50%">
    <mat-checkbox
      labelPosition="after"
      color="accent"
      [(ngModel)]="shop.show_ctrl_theme_mode"
      [ngModelOptions]="{ standalone: true }"
      class=""
    >
      {{ "SHOPS.SHOW_CTR_THEME_MODE" | translate }}
    </mat-checkbox>
  </div>
</div>

<div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start">
  <button
    (click)="onSaveInfo()"
    color="accent"
    class="save-btn"
    [disabled]="false"
    mat-raised-button
  >
    {{ "GENERIC.SAVE" | translate }}
  </button>
</div>
