<div style="max-height: 100%">
  <h1 class="h1-title" matDialogTitle *ngIf="mode === 'CREATE'">
    {{ "CREATE_FLOW.TITLE" | translate }}
  </h1>
  <h1 class="h1-title" matDialogTitle *ngIf="mode === 'EDIT'">
    {{ "CREATE_FLOW.EDIT_TITLE" | translate }}
  </h1>

  <div fxLayout="column" fxLayoutAlign="center start">
    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      [formGroup]="form"
      (keyup.enter)="
        form.valid && !loading && flow.selectedTime !== 'Invalid DateTime'
          ? onCreateFlow()
          : null
      "
      class="item-full-width"
    >
      <div *ngIf="flows" class="item-full-width mb-16">
        <div class="mb-4">{{ "CREATE_FLOW.SELECT_FLOW" | translate }}</div>
        <p-dropdown
          [options]="flows"
          [(ngModel)]="selectedFlow"
          [style]="{ width: '100%' }"
          editable="true"
          formControlName="selectedFlow"
          placeholder="{{ 'CREATE_FLOW.SELECT_FLOW' | translate }}"
        >
        </p-dropdown>
      </div>
      <div
        *ngIf="!flows"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="item-full-width mb-16"
      >
        <mat-spinner [diameter]="20" class=""></mat-spinner>
      </div>

      <mat-form-field fxFlex="100%" class="item-full-width">
        <mat-label>{{ "GENERIC.DATE" | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="eventDate"
          [(ngModel)]="flow.date"
          min="1900-01-01"
          placeholder="{{ 'GENERIC.DATE' | translate }}"
          formControlName="eventDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="eventDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #eventDate></mat-datepicker>

        <mat-error *ngIf="form.controls['eventDate'].invalid"
          >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100%" class="item-full-width note-section">
      <div class="item-full-width mb-12">
        <ngx-timepicker-field
          [buttonAlign]="'left'"
          [format]="24"
          [(ngModel)]="flow.selectedTime"
          class=""
        >
        </ngx-timepicker-field>
      </div>

      <p-editor
        #noteEditor
        [(ngModel)]="flow.note"
        [style]="{
          height: '140px',
          width: '100%',
          'background-color': '#ffffff',
        }"
      >
        <ng-template pTemplate="header" class="">
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </ng-template>
      </p-editor>
      <mat-error
        *ngIf="noteInvalid"
        fxLayout="row"
        fxLayoutAlign="end center"
        class="p-4 pr-8"
      >
        {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
      </mat-error>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxFlex="100"
      class="item-full-width mt-12"
    >
      <mat-checkbox
        labelPosition="before"
        color="accent"
        [(ngModel)]="flow.for_client_only"
        [ngModelOptions]="{ standalone: true }"
      >
        {{ "CREATE_FLOW.FOR_CLIENT_ONLY" | translate }}
      </mat-checkbox>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="
        !form.valid || flow.selectedTime === 'Invalid DateTime' || loading
      "
      color="accent"
      class="mr-16"
      (click)="onCreateFlow()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
