import { GT2DateAdapter } from './../../utils/GT2DateAdapter';
import { UserSettingsService } from './../../api/user-settings.service';
import { GenericAlertComponent } from './../../components/generic-alert/generic-alert.component';
import { ModulesService } from '../../services/modules.service';
import { RolesService } from './../../services/roles.service';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '../../../../@fuse/animations';
import { TranslateService } from '@ngx-translate/core';
import { EventRoomService } from '../../api/event-room.service';
import { IconsService } from '../../services/icons.service';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { PrintService } from '../../../services/print.service';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { locale as english } from '../dashboard/i18n/en-CA';
import { locale as french } from '../dashboard/i18n/fr-CA';
import { CreateEventForRoomComponent } from './create-event-for-room/create-event-for-room.component';
import { ToastrService } from 'ngx-toastr';
import { CreateCongresDialogComponent } from '../congresses/create-congres-dialog/create-congres-dialog.component';
import { AppRoutes } from '../../const/AppRoutes';

declare const FullCalendar: any;

// declare const $: any;

@Component({
  selector: 'app-room-management',
  templateUrl: './room-management.component.html',
  styleUrls: ['./room-management.component.scss'],
  animations: fuseAnimations,
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
})
export class RoomManagementComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static LS_CURRENT_DATE: string = 'LS_CURRENT_DATE';
  confirmDialogRef: any;
  isLoading = true;
  resultsEventLength = 0;
  resultsResourceLength = 0;
  calendarTitle: string = '';
  calendarDayMonthValue: string = 'timelineDay';
  currentDate?: string;
  timeZone: string = 'America/Montreal';

  calendar: any;
  calendarInit: boolean = false;
  calendarData: any = {
    calendarRessources: [],
    calendarEvents: [],
  };

  constructor(
    public translate: TranslateService,
    public iconsService: IconsService,
    public logger: NGXLogger,
    public print: PrintService,
    private dialog: MatDialog,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private eventRoomService: EventRoomService,
    private router: Router,
    private translationLoader: FuseTranslationLoaderService,
    public rolesService: RolesService,
    public modulesService: ModulesService,
    public userSettingsService: UserSettingsService
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);
    //this.loadData();
  }

  ngOnInit() {
    // this.userSettingsService.getSettings().subscribe(
    //     (response) => {
    //         // this.dateFormat = response.data.date_format.format_moment;
    //         this.timeZone = response.data.timezone.code; // 'Europe/Paris';
    //         //this.logger.info(
    //             "response.data.timezone.code: " +
    //                 response.data.timezone.code
    //         );
    //         // this.initCalendar();
    //     },
    //     (error) => {
    //         this.logger.error(
    //             "RoomManagementComponent.ngOnInit() -> userSettingsService ERRORS: " +
    //                 JSON.stringify(error)
    //         );
    //         this.timeZone = "America/Montreal";
    //         // this.initCalendar();
    //         // this.handleAPIError(error, dialog, null, null);
    //     }
    // );
    this.initCalendar();
  }

  ngAfterViewInit() {
    // this.setUpCalendar();
  }

  ngOnDestroy() {}

  public initCalendar(): void {
    if (!this.calendarInit) {
      this.setUpCalendar();
      this.calendarInit = true;
      this.loadData();
    }
  }

  public loadData(): void {
    const date: string = moment(this.currentDate).toISOString();
    //const date: string = "2023-02-01T00:00:00.000Z";
    //this.logger.info("RoomManagementComponent.loadData() -> date: " + date);
    this.eventRoomService.getEventRoom(date).subscribe(
      (response) => {
        //this.logger.info("RoomManagementComponent.loadData() -> SUCCESS: " + JSON.stringify(response));
        this.calendarData = response;
        //
        // this.calendarData.calendarEvents[0].backgroundColor = "#ff0000";
        // this.calendarData.calendarEvents[1].color = "#ff0000";
        // //this.logger.info(
        //     "RoomManagementComponent.loadData() -> SUCCESS: " +
        //         JSON.stringify(this.calendarData)
        // );
        //
        this.isLoading = false;
        this.calendar.refetchResources();
        this.calendar.refetchEvents();
        this.resultsEventLength = 0;
        this.resultsResourceLength =
          this.calendarData.calendarRessources.length;
        // //this.logger.info("RoomManagementComponent.data count(): " + this.calendarData.calendarRessources.length);
      },
      (error) => {
        this.logger.error(
          'RoomManagementComponent.loadData() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoading = false;
      }
    );
  }

  public refreshTimeline(): void {
    // //this.logger.info("RoomManagementComponent.refreshTimeline() -> this.calendar: " + this.calendar);
    if (this.calendar) {
      setTimeout(() => {
        this.calendar.rerenderResources();
        this.calendar.rerenderEvents();
      }, 100); // TODO: test with no time
    }
    // else
    // {
    //   this.initCalendar();
    // }
  }

  public setUpCalendar(): void {
    // /v1/events/by-room-month/2021-12-03T18:44:19.985Z
    const lsDate = localStorage.getItem(
      RoomManagementComponent.LS_CURRENT_DATE
    );
    //this.logger.info("RoomManagementComponent.setUpCalendar()-> lsDate: " + lsDate);
    if (lsDate) {
      this.currentDate = moment(lsDate).toISOString();
    } else {
      this.currentDate = moment().toISOString();
    }
    // //this.logger.info(
    //     "RoomManagementComponent.setUpCalendar()-> moment(): " +
    //         moment().tz.name
    // );
    const calendarEl = document.getElementById('calendar');
    const context = this;
    this.calendar = new FullCalendar.Calendar(calendarEl, {
      now: this.currentDate,
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source', // TODO: MARTIN OUBLIE PAS DE METTRE LA VRAI LICENSE ICI
      editable: false,
      eventResourceEditable: false,
      scrollTime: '08:00:00',
      locale: this.translate.currentLang,
      eventOverlap: false,
      eventTextColor: '#fff',
      slotEventOverlap: false,
      // header: {
      //     left: "addRoomBtn today prev,next",
      //     center: "title",
      //     right: "timelineDay,month",
      // },
      header: false,
      buttonText: {
        today: "aujourd'hui",
        month: 'mois',
        week: 'semaine',
        day: 'jour',
        list: 'liste',
        timelineThreeDays: '3 jours',
      },
      // Defines custom buttons that can be used in the ** HEADER/FOOTER **.
      // customButtons: {
      //     promptResource: {
      //         text: "+ salle",
      //         click: function () {
      //             context.addNewRoom();
      //         },
      //     },
      // },
      eventTimeFormat: {
        // like '14:30:00'
        hour: 'numeric',
        minute: '2-digit',
        // second: "2-digit",
        meridiem: false,
      },
      defaultView: 'timelineDay',
      views: {
        timelineThreeDays: {
          type: 'timeline',
          duration: { days: 3 },
        },
        timelineDay: {
          eventTimeFormat: {
            // like '14:30:00'
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            meridiem: false,
          },
          // slotEventOverlap: false,
          // slotLabelFormat: "h(:mm)a",
          // slotLabelFormat: ["H:mm"],
          // eventTimeFormat: {
          //     hour12: false,
          //     hour: "2-digit",
          //     minute: "2-digit",
          // },
          // slotLabelFormat: {
          //     hour: "numeric",
          //     minute: "2-digit",
          //     omitZeroMinute: true,
          //     meridiem: "short",
          // },
        },
        dayGridMonth: {
          eventTimeFormat: {
            // like '14:30:00'
            hour: 'numeric',
            minute: '2-digit',
            // second: "2-digit",
            meridiem: false,
          },
        },
        agendaWeek: {
          groupByResource: true,
          groupByDateAndResource: false,
          // duration: { days: 7 }
          // expandRows: true,
          // slotDuration: "00:15:00",
          // slotLabelInterval: 15,
          // slotLabelFormat: "h(:mm)a",
          // minTime: "00:00:00",
          // maxTime: "24:00:00",
          columnHeaderFormat: {
            weekday: 'short',
            month: 'numeric',
            day: 'numeric',
            omitCommas: true,
          },
        },
      },
      resourceLabelText: this.translate.instant('GENERIC.ROOMS'),
      resourceGroupField: 'place_name',
      // height: 500, // "auto",
      // contentHeight: 500,
      aspectRatio: 1.8,
      expandRows: false,
      timeZone: 'UTC', // this.timeZone, // "UTC",
      // timeZone: this.timeZone,
      selectable: true,
      slotWidth: 32,
      // displayEventTime: false,
      // resourcesInitiallyExpanded: true,
      // filterResourcesWithEvents: true,
      // groupByResource: true,
      // groupByDateAndResource: true,
      eventRender: function (info: any) {
        // https://fullcalendar.io/docs/v4/eventRender
        if (info.event.extendedProps.occupied && info.view.type === 'month') {
          info.el.style.display = 'none';
          // info.el.style.visibility = "hidden";
        } else if (info.view.type === 'month') {
        }
      },
      resourceRender: function (info: any) {
        // info.el.innerHTML = "This is your paragraph!";
        info.el.style.cursor = 'pointer';
        // info.el.innerHTML += "<div>test</div";
        // info.el.addEventListener("click", context.test());
        const elSpan = info.el.getElementsByClassName('fc-cell-content');

        // info.el.style.position = "relative";

        // https://altcodeunicode.com/
        // » ► →
        elSpan[0].innerHTML +=
          "<span class='icon' style='position: absolute; right: 8px; top: -4px; font-size: 24px;'>»</span>";

        // elSpan[0].style.hover = "background-color: yellow;";

        info.el.getElementsByClassName('icon')[0].style.display = 'none';
        info.el.addEventListener('click', function () {
          context.onRessourceRoomClick(
            info.resource.id,
            info.resource.extendedProps
          );
        });

        info.el.addEventListener('mouseover', function () {
          info.el.style.opacity = '0.70';
          info.el.getElementsByClassName('icon')[0].style.display = 'block';
        });

        info.el.addEventListener('mouseout', function () {
          info.el.style.opacity = '1';
          info.el.getElementsByClassName('icon')[0].style.display = 'none';
        });

        // resource.el.addEventListener('click', function () {
        //     if (confirm('Are you sure you want to delete ' + resource.title + '?')) {
        //         this.calendar.removeResource(resource);
        //     }
        // });
      },
      datesRender: function (info: any) {
        context.calendarTitle = info.view.title;
        context.calendarDayMonthValue = info.view.type;
        context.onCalendarDateChange(info.view.calendar.state.currentDate);
      },
      eventDrop: function (eventDropInfo: any) {
        context.onEventDrop(eventDropInfo);
      },
      eventResize: function (info: any) {
        context.onEventDrop(info);
        // context.onEventResizeStop(info);
      },
      eventClick: function (info: any) {
        context.onEventClick(info);
      },
      eventMouseEnter: function (mouseEnterInfo: any) {},
      select: function (selectionInfo: any) {
        context.onSelection(selectionInfo);
      },
      resources: function (
        fetchInfo: any,
        successCallback: any,
        failureCallback: any
      ) {
        // context.logger.info(">>>>>> context.calendarData.calendarRessources: " + JSON.stringify(context.calendarData.calendarRessources));
        // const ressources = [
        //     {
        //         id: "fee34ebf-93a0-4c17-93a0-d9b61a83624e",
        //         place_name: "Centre Jacques Gingras SA",
        //         title: "Lounge Centre Jacques Gingras SA",
        //         slug: "lounge-centre-jacques-gingras-sa",
        //         object: "Room",
        //     },
        // ];
        // successCallback(ressources);

        successCallback(context.calendarData.calendarRessources);
      },
      events: function (
        fetchInfo: any,
        successCallback: any,
        failureCallback: any
      ) {
        //context.logger.info(">>>>>>  context.calendarData.calendarEvents: " + JSON.stringify(context.calendarData.calendarEvents));
        const events = context.calendarData.calendarEvents;
        /* const events = [
                      {
                          id: "0175435b-5a90-42a5-ad79-90a71ad3fe13",
                       resourceId: "fee34ebf-93a0-4c17-93a0-d9b61a83624e",
                          start: "2023-03-20T14:00:00",
                         end: "2023-03-20T18:00:00",
                          title: "PropoLounge14h_1",
                          slug: "propolounge14h-1",
                          object: "Event",
                      },
                     {
                         title: "My Event",
                         resourceId: "fee34ebf-93a0-4c17-93a0-d9b61a83624e",
                         start: "2023-03-22T14:30:00",
                         allDay: false,
                     },
                 ];*/
        //successCallback(events);

        successCallback(context.calendarData.calendarEvents);
      },
    });
    this.calendar.render();
  }

  public onRessourceRoomClick(id: string, extendedProps: any): void {
    //this.logger.info("onRessourceRoomClick() id: " + id);
    //this.logger.info("onRessourceRoomClick() extendedProps: " + JSON.stringify(extendedProps));
    const roomModel = {
      object: 'Room',
      uuid: id,
      slug: extendedProps.slug,
    };

    this.router.navigateByUrl(ItemUrlFinder.getItemURL(roomModel));
  }

  public onCalendarDateChange(date: any): void {
    // //this.logger.info(
    //     "RoomManagementComponent.onCalendarDateChange() -> currentDate: " +
    //         this.currentDate
    // );
    // //this.logger.info(
    //     "RoomManagementComponent.onCalendarDateChange() -> date: " + date
    // );
    const date1: Date = new Date(this.currentDate);
    const date2: Date = new Date(date);
    if (date1.getUTCMonth() !== date2.getUTCMonth()) {
      this.currentDate = date;
      this.loadData();
    }
    localStorage.setItem(RoomManagementComponent.LS_CURRENT_DATE, date);
    this.currentDate = date;
  }

  public onDatePickerChange(event: any): void {
    // //this.logger.info("onDatePickerChange() -> " + event.value);
    this.calendar.gotoDate(new Date(event.value));
    this.onCalendarDateChange(event.value);
  }

  //
  public addNewRoom(): void {
    // //this.logger.info("RoomManagementComponent.addNewRoom() -> ");
    const title = prompt('Nom de la salle');
    if (title) {
      this.calendar.addResource({ title: title }, true); // true = scroll to new resource
    }
  }

  public onEventDrop(eventDropInfo: any): void {
    // //this.logger.info("RoomManagementComponent.onEventDrop()");
    // //this.logger.info(
    //     "RoomManagementComponent.onEventDrop() -> eventDropInfo: " +
    //         JSON.stringify(eventDropInfo)
    // );
    // //this.logger.info("RoomManagementComponent.onEventDrop() -> eventDropInfo.event.start: " + eventDropInfo.event.start);
    // //this.logger.info("RoomManagementComponent.onEventDrop() -> eventDropInfo.event.end: " + eventDropInfo.event.end);
    // //this.logger.info("RoomManagementComponent.onEventDrop() -> eventDropInfo.newResource: " + eventDropInfo.newResource);

    const event: any = this.calendarData.calendarEvents.find((item: any) => {
      return item.id === eventDropInfo.event.id;
    });

    //
    let addZero: (value: number) => string = function (x) {
      let i = x.toString();
      if (x < 10) {
        i = '0' + x;
      }
      return i;
    };

    const startStr =
      addZero(eventDropInfo.event.start.getUTCHours()) + ':' + ':00';
    const endStr =
      addZero(eventDropInfo.event.end.getUTCHours()) +
      ':' +
      addZero(eventDropInfo.event.end.getUTCMinutes()) +
      ':00';

    let eventObj: any;
    if (eventDropInfo.newResource) {
      // //this.logger.info("RoomManagementComponent.onEventDrop() -> eventDropInfo.newResource.id: " + eventDropInfo.newResource.id);
      eventObj = {
        start: eventDropInfo.event.start,
        end: eventDropInfo.event.end,
        start_time: startStr,
        end_time: endStr,
        uuid_room: eventDropInfo.newResource.id,
      };
    } else {
      const eventRoom: any = this.calendarData.calendarRessources.find(
        (room: any) => {
          return room.id === event.resourceId;
        }
      );

      eventObj = {
        start: eventDropInfo.event.start,
        end: eventDropInfo.event.end,
        start_time: startStr,
        end_time: endStr,
        uuid_room: eventRoom.id,
      };
    }

    // //this.logger.info("RoomManagementComponent.onEventDrop() -> eventObj: " + JSON.stringify(eventObj));

    this.eventRoomService.updateEvent(event.id, eventObj).subscribe(
      (response) => {
        //this.logger.info("RoomManagementComponent.onEventDrop() -> SUCCESS: " + JSON.stringify(response));
        // this.calendarData = response;
        this.loadData();
        // TOASTR UPDATE SUCCESS
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS')
        );
      },
      (error) => {
        this.logger.error(
          'RoomManagementComponent.onEventDrop() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC')
        );
        // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.GENERIC"));
      }
    );
  }

  // public onEventResizeStop(info): void
  // {
  //   // //this.logger.info("RoomManagementComponent.onEventResizeStop() -> info.event.id: " + info.event.id);
  //   //this.logger.info("RoomManagementComponent.onEventResizeStop() -> info.event.start: " + info.event.start);
  //   //this.logger.info("RoomManagementComponent.onEventResizeStop() -> info.event.end: " + info.event.end);
  //   // //this.logger.info("RoomManagementComponent.onEventResizeStop() -> info.newResource: " + info.newResource);
  // }

  public onEventClick(info: any): void {
    // //this.logger.info(
    //     "RoomManagementComponent.onEventClick() -> info.event.id: " +
    //         info.event.id
    // );

    const event: any = this.calendarData.calendarEvents.find((item: any) => {
      return item.id === info.event.id;
    });
    // //this.logger.info("event: " + JSON.stringify(event));

    const eventRoom2: any = this.calendarData.calendarRessources.find(
      (room: any) => {
        // if (!info.resource) {
        //     // //this.logger.info(
        //     //     "RoomManagementComponent.onSelection() -> selectionInfo.resource DOES NOT EXIST"
        //     // );
        //     return false;
        // }
        return room.id === event.resourceId;
      }
    );
    // //this.logger.info("eventRoom2: " + JSON.stringify(eventRoom2));

    if (event === null) {
      return;
    } else if (event.occupied === true) {
      return;
    } else if (event.no_event === true) {
      // TODO: Open popup, prefill data, new api url, etc
      const eventRoom: any = this.calendarData.calendarRessources.find(
        (room: any) => {
          // if (!info.resource) {
          //     // //this.logger.info(
          //     //     "RoomManagementComponent.onSelection() -> selectionInfo.resource DOES NOT EXIST"
          //     // );
          //     return false;
          // }
          return room.id === event.resourceId;
        }
      );
      if (eventRoom) {
        event.startStr = event.start;
        event.endStr = event.end;
        event.order_type = 'BLOCKED';

        this.dialogRef = this.dialog.open(CreateEventForRoomComponent, {
          width: '520px',
          minWidth: 350,
          panelClass: 'scrollable-dialog',
          disableClose: false,
          // panelClass: '',
          data: {
            selectionInfo: event,
            uuid_room: event.resourceId,
            roomName: eventRoom.title,
            placeName: eventRoom.place_name,
            type: CreateEventForRoomComponent.FROM_RESERVATION,
          },
        });

        this.dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            this.dialogRef = null;
            this.loadData();
          }
        });
      }
      return;
    }

    // //this.logger.info(
    //     "RoomManagementComponent.onEventClick() -> event data: " +
    //         JSON.stringify(event)
    // );

    const itemObj: any = {
      object: event.object,
      uuid: event.id,
      slug: event.slug,
    };
    // //this.logger.info("RoomManagementComponent.onEventClick() -> itemObj: " + JSON.stringify(itemObj));
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(itemObj));
  }

  public onSelection(selectionInfo: any): void {
    // //this.logger.info(
    //     "RoomManagementComponent.onSelection() -> selectionInfo: " +
    //         JSON.stringify(selectionInfo)
    // );
    // //this.logger.info("RoomManagementComponent.onSelection() -> selectionInfo.start: " + selectionInfo.start);
    // //this.logger.info("RoomManagementComponent.onSelection() -> selectionInfo.end: " + selectionInfo.end);
    // //this.logger.info("RoomManagementComponent.onSelection() -> selectionInfo.startStr: " + selectionInfo.startStr);
    // //this.logger.info("RoomManagementComponent.onSelection() -> selectionInfo.endStr: " + selectionInfo.endStr);
    // //this.logger.info("RoomManagementComponent.onSelection() -> selectionInfo.resource: " + selectionInfo.resource);
    // //this.logger.info("RoomManagementComponent.onSelection() -> selectionInfo.resource.id: " + selectionInfo.resource.id);
    // //this.logger.info("RoomManagementComponent.onSelection() -> selectionInfo.resource.place_name: " + selectionInfo.resource.place_name);

    const eventRoom: any = this.calendarData.calendarRessources.find(
      (room: any) => {
        if (!selectionInfo.resource) {
          // //this.logger.info(
          //     "RoomManagementComponent.onSelection() -> selectionInfo.resource DOES NOT EXIST"
          // );
          return false;
        }
        return room.id === selectionInfo.resource.id;
      }
    );

    if (eventRoom) {
      // //this.logger.info(
      //     "RoomManagementComponent.onSelection() -> selectionInfo: " +
      //         selectionInfo.start
      // );

      // const startUTC = new Date(selectionInfo.start).toUTCString();
      // const endUTC = new Date(selectionInfo.end).toUTCString();
      // const currentStart = new Date(startUTC).getTime();
      // const currentEnd = new Date(endUTC).getTime();
      // for (let event of this.calendarData.calendarEvents) {
      //     if (eventRoom.id === event.resourceId) {
      //         const eventStart = new Date(event.start).getTime();
      //         const eventEnd = new Date(event.end).getTime();
      //         if (
      //             (currentEnd > eventStart && currentEnd < eventEnd) ||
      //             (currentStart > eventStart && currentStart < eventEnd)
      //         ) {
      //             //this.logger.info(
      //                 "RoomManagementComponent.onSelection() -> overlap: TRUE"
      //             );
      //             this.dialogRef = this.dialog.open(
      //                 GenericAlertComponent,
      //                 {
      //                     width: "45%",
      //                     disableClose: false,
      //                 }
      //             );
      //             this.dialogRef.componentInstance.useYesNo = false;
      //             this.dialogRef.componentInstance.useCancel = false;
      //             this.dialogRef.componentInstance.alertMessage =
      //                 this.translate.instant(
      //                     "GENERIC.OVERLAP_ERROR_MESSAGE"
      //                 );
      //             this.dialogRef.componentInstance.useCancel = true;

      //             this.dialogRef.afterClosed().subscribe((result) => {
      //                 //
      //             });
      //             return;
      //         } else {
      //             // //this.logger.info(
      //             //     "RoomManagementComponent.onSelection() -> overlap: FALSE"
      //             // );
      //         }
      //     }
      // }

      //
      this.dialogRef = this.dialog.open(CreateEventForRoomComponent, {
        width: '520px',
        minWidth: 350,
        panelClass: 'scrollable-dialog',
        disableClose: false,
        // panelClass: '',
        data: {
          selectionInfo: selectionInfo,
          uuid_room: selectionInfo.resource.id,
          roomName: eventRoom.title,
          placeName: eventRoom.place_name,
          type: CreateEventForRoomComponent.CREATE,
        },
      });

      this.dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.dialogRef = null;
          this.loadData();
        }
      });
    } else {
      // if we enter this else, its because the selection was made in teh calendar Month View
      const selectedDate: any = moment(selectionInfo.end).format(
        'YYYY-MM-DD hh:mm'
      );
      this.calendar.changeView('timelineDay', selectedDate);
    }
  }

  public onCalendarToday(): void {
    //this.logger.info("onCalendarToday()");
    // this.calendar.today();
    const today = new Date();
    this.calendar.gotoDate(today.toUTCString());
    this.onCalendarDateChange(today.toUTCString());
  }

  public onCalendarPrevious(): void {
    this.calendar.prev();
  }

  public onCalendarNext(): void {
    this.calendar.next();
  }

  public onCalendarDay(): void {
    // const selectedDate: any = moment(selectionInfo.end).format('YYYY-MM-DD hh:mm')
    this.calendar.changeView('timelineDay');
  }

  public onCalendarWeek(): void {
    // agendaWeek   timeGridWeek   timelineWeek
    this.calendar.changeView('timelineWeek');
  }

  public onCalendarMonth(): void {
    this.calendar.changeView('month');
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  //

  // public onNewProposal(): void {
  //     //this.logger.info("DashboardCalendarComponent.onNewProposal()");
  //     // //this.logger.info("DashboardCalendarComponent.onNewProposal() -> ******: " + this.addressComponent.selectedDay.date);
  //     this.dialogRef = this.dialog.open(CreateEventComponent, {
  //         width: "45%",
  //         disableClose: false,
  //         data: {
  //             subType: CreateEventComponent.PROPOSAL_SUBTYPE,
  //         },
  //     });

  //     this.dialogRef.afterClosed().subscribe((result) => {
  //         //this.logger.info(
  //             "DashboardCalendarComponent.onNewEvent() -> result: " +
  //                 JSON.stringify(result)
  //         );
  //         if (result) {
  //             this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
  //         }

  //         this.dialogRef = null;
  //     });
  // }

  // public onCreateOrder(): void {
  //     this.dialogRef = this.dialog.open(CreateEventComponent, {
  //         width: "45%",
  //         disableClose: false,
  //         data: {
  //             subType: CreateEventComponent.ORDER_SUBTYPE,
  //         },
  //     });

  //     this.dialogRef.afterClosed().subscribe((result) => {
  //         //this.logger.info(
  //             "DashboardCalendarComponent.onCreateOrder() -> result: " +
  //                 JSON.stringify(result)
  //         );
  //         if (result) {
  //             this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
  //         }

  //         this.dialogRef = null;
  //     });
  // }

  public onQuickOrder(): void {
    //this.logger.info("DashboardCalendarComponent.onQuickOrder()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle = 'Commande rapide';
    this.confirmDialogRef.componentInstance.alertMessage = 'À venir!';

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      this.confirmDialogRef = null;
    });
  }

  public onCreateNewCongress(): void {
    //this.logger.info("CongressesComponent.onCreateNewCongress() -> event: " + event);
    this.dialogRef = this.dialog.open(CreateCongresDialogComponent, {
      width: '440px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      maxHeight: '96vh',
      disableClose: false,
      data: { autoRedirect: true },
    });
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.reloadTable();
      }
      this.dialogRef = null;
    });
  }

  public onGoToCongresses(): void {
    this.router.navigateByUrl('/' + AppRoutes.CONGRESSES);
  }
}
