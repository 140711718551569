import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class ProgramsService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getData(): Observable<any> {
    //this.logger.info("ProgramsService.getData()");
    return this.getProgramServiceList();
  }

  public getProgramServiceList() {
    //this.logger.info("ProgramsService.getProgramsServiceList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PROGRAM_LIST));
  }

  public createProgram(program: any, eventUUID: string) {
    //this.logger.info("ProgramsService.createProgram() -> " + JSON.stringify(program));
    //this.logger.info("ProgramsService.createProgram() -> eventUUID: " + eventUUID);
    return this.http.post<any>(this.api.createUrl(ApiRoutes.CREATE_PROGRAM) + "/" + eventUUID, program);

    //
    // return this.http.post<any>(
    //     this.api.createUrl(ApiRoutes.CREATE_PROGRAM) +
    //         "programs/" +
    //         eventUUID,
    //     program
    // );
  }

  public deleteProgram(program: any, eventUUID: string) {
    //this.logger.info("ProgramsService.deleteProgram() -> " + JSON.stringify(program));
    //this.logger.info("ProgramsService.deleteProgram() -> eventUUID: " + eventUUID);
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.CREATE_PROGRAM) + "/" + program.uuid);
  }

  public updateProgram(program: any, eventUUID: string) {
    //this.logger.info("ProgramsService.updateProgram() -> " + JSON.stringify(program));
    //this.logger.info("ProgramsService.updateProgram() -> eventUUID: " + eventUUID);
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.UPDATE_PROGRAM) + "/" + program.uuid, program);
  }
}
