import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'momentFormat',
})
export class MomentFormatPipe implements PipeTransform {
  transform(value: string, format: string = 'h:mm A', lang?: string): string {
    const local_lang = lang || localStorage.getItem('local_lang');
    if (value && local_lang === 'en-CA') {
      return moment(value, 'HH:mm').format(format);
    }
    return value;
  }
}
