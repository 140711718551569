import { ProjectsService } from '../../../../../api/projects.service';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { RolesService } from '../../../../../services/roles.service';
import { locale as english } from '../../../i18n/en-CA';
import { locale as french } from '../../../i18n/fr-CA';
import { CommonModule, Location } from '@angular/common';
import { fuseAnimations } from '../../../../../../../@fuse/animations';
import { EventsService } from '../../../../../api/events.service';
import { GT2FormPageAbstract } from '../../../../../content/abstract/GT2FormPageAbstract';
import { GenericAlertComponent } from '../../../../../components/generic-alert/generic-alert.component';
import { SelectClientComponent } from '../../../../../components/dialogs/select-client/select-client.component';
import { ItemUrlFinder } from '../../../../../utils/item-url-finder';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { GoogleLinkPipeModule } from '../../../../../modules/google-link.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-event-client-section',
  templateUrl: './event-client-section.component.html',
  styleUrls: ['./event-client-section.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    FlexLayoutModule,
    NgxPermissionsModule,
    MatProgressSpinnerModule,
    FormsModule,
    GoogleLinkPipeModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  animations: fuseAnimations,
})
export class EventClientSectionComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CLIENT_FOR_EVENT: string = 'CLIENT_FOR_EVENT';
  static CLIENT_FOR_PROJECT: string = 'CLIENT_FOR_PROJECT';
  //
  @Input() client: any;
  @Output() clientChange = new EventEmitter<any>();
  @Input() eventUUID: any;
  //
  @Input() otherClientUUID: string | null = null;
  @Input() otherClientIsSet: boolean = false;
  @Input() clientSection!: string;
  //
  @Input() hasCongress: boolean = false;
  @Input() clientFor: string = EventClientSectionComponent.CLIENT_FOR_EVENT;
  @Input() projectUUID: any;
  @Input() editable: boolean = false;
  @Output() modelUpdatedEvent = new EventEmitter<any>();
  //
  declare dialogRef: any;
  isLoading: boolean = false;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public logger: NGXLogger,
    public location: Location,
    private dialog: MatDialog,
    public rolesService: RolesService,
    private eventsService: EventsService,
    public router: Router,
    public projectsService: ProjectsService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    /* //this.logger.info(
            "EventClientSectionComponent.ngAfterViewInit() - hasCongress: " +
                this.hasCongress
        );

        //this.logger.info(
            "EventClientSectionComponent.ngAfterViewInit() - eventUUID: " +
                this.eventUUID
        );

        //this.logger.info(
            "EventClientSectionComponent.ngAfterViewInit() - editable: " +
                this.editable
        ); */
  }

  ngOnDestroy() {}

  public onDispatchModelUdpated(): void {
    /*         //this.logger.info(
            "EventClientSectionComponent.onDispatchModelUdpated()"
        ); */
    this.modelUpdatedEvent.next(undefined);
  }

  public onModelLoaded(): void {
    //this.logger.info("EventClientSectionComponent.onModelLoaded() *** ");
    this.isLoading = false;
  }

  public onSelectClient(): void {
    //this.logger.info("EventClientSectionComponent.onSelectClient()");
    this.dialogRef = this.dialog.open(SelectClientComponent, {
      width: '80%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        eventUUID: this.eventUUID,
        projectUUID: this.projectUUID,
        clientSection: this.clientSection,
        clientFor: this.clientFor,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      //this.logger.info("EventClientSectionComponent.onSelectClient() -> this.isLoading: " + this.isLoading);
      if (result) {
        this.isLoading =
          this.clientFor === EventClientSectionComponent.CLIENT_FOR_EVENT;

        //this.logger.info("EventClientSectionComponent.onSelectClient() -> result: " + JSON.stringify(result));
        //this.logger.info("EventClientSectionComponent.onSelectClient() -> result.data.note: " + result.data.note);
        // this.client = result;
        this.onDispatchModelUdpated();
        if (
          result.data.note &&
          result.data.note != '' &&
          this.clientSection == 'ordering'
        ) {
          this.onSeeNote(result.data.note);
        }
      }
      this.dialogRef = null;
    });
  }

  public onSelectSameClient(): void {
    //this.logger.info("EventClientSectionComponent.onSelectSameClient()");
    this.isLoading = true;
    //
    // if (this.clientFor === EventClientSectionComponent.CLIENT_FOR_EVENT) {
    //     this.addClientToEvent();
    // } else if (
    //     this.clientFor === EventClientSectionComponent.CLIENT_FOR_PROJECT
    // ) {
    //     this.addClientToProject();
    // }
    if (this.clientFor === EventClientSectionComponent.CLIENT_FOR_PROJECT) {
      this.addClientToProject();
    } else {
      this.addClientToEvent();
    }

    // this.eventsService
    //     .addClient(this.otherClientUUID, this.eventUUID, this.clientSection)
    //     .subscribe(
    //         (response) => {
    //             // //this.logger.info("SelectClientComponent.onSelectSameClient() -> SUCCESS: " + JSON.stringify(response));
    //             // this.resetLoading();
    //             this.isLoading = true;
    //             //this.logger.info(
    //                 "EventClientSectionComponent.onSelectSameClient() -> this.isLoading: " +
    //                     this.isLoading
    //             );
    //             this.onDispatchModelUdpated();
    //         },
    //         (error) => {
    //             this.logger.error(
    //                 "EventClientSectionComponent.onSelectSameClient() -> ERROR: " +
    //                     JSON.stringify(error)
    //             );
    //             this.isLoading = false;
    //         }
    //     );
  }

  public addClientToEvent(): void {
    //this.logger.info("EventClientSectionComponent.addClientToEvent()");
    this.eventsService
      .addClient(this.otherClientUUID, this.eventUUID, this.clientSection)
      .subscribe(
        (response: any) => {
          //this.logger.info("EventClientSectionComponent.onClientSelected() -> SUCCESS: " + JSON.stringify(response));
          this.isLoading = false;
          //
          if (response.data.note) {
            this.onSeeNote(response.data.note);
          }

          this.onDispatchModelUdpated();
        },
        (error: any) => {
          //this.logger.info("EventClientSectionComponent.onClientSelected() -> ERROR: " + JSON.stringify(error));
          this.isLoading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public addClientToProject(): void {
    //this.logger.info("EventClientSectionComponent.addClientToProject()");
    this.projectsService
      .addClient(this.otherClientUUID, this.projectUUID, this.clientSection)
      .subscribe(
        (response: any) => {
          //this.logger.info("SelectClientComponent.onClientSelected() -> SUCCESS: " + JSON.stringify(response));
          this.isLoading = false;
          this.onDispatchModelUdpated();
        },
        (error: any) => {
          //this.logger.info("EventClientSectionComponent.onClientSelected() -> ERROR: " + JSON.stringify(error));
          this.isLoading = false;

          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  // public resetLoading(): void
  // {
  //   setTimeout(() => {
  //     this.isLoading = false;
  //   }, 5000);
  // }

  public onSeeNote(note: string | null = null): void {
    //this.logger.info("EventClientSectionComponent.onSeeNote() -> note: " + note);
    this.dialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.dialogRef.componentInstance.useCancel = false;
    this.dialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.NOTE');

    if (note) {
      //this.logger.info("EventClientSectionComponent.onSeeNote() -> 2- note: " + note);
      this.dialogRef.componentInstance.alertMessage = note;
    } else {
      this.dialogRef.componentInstance.alertMessage = this.client.note;
    }

    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.dialogRef = null;
    });
  }

  public onOpenPage(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public onOpenInNewTab(enterprise: any): void {
    enterprise.object = 'Enterprise';
    window.open(ItemUrlFinder.getItemURL(enterprise), '_blank');
  }
}
