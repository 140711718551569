<mat-toolbar #matToolbar fxLayout="column" class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="icon-mat-darkmode">menu</mat-icon>
      </button>

      <div class="toolbar-separator" style="width: 2px"></div>
    </div>

    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <fuse-search-bar
        fxFlex="100"
        [loading]="loading"
        (onInput)="autoComplete($event)"
        (onSearch)="search($event)"
      ></fuse-search-bar>

      <div class="toolbar-separator" style="width: 1px"></div>

      <button
        mat-button
        *ngxPermissionsOnly="rolesService.SALE_ROLES"
        #createMenuButton
        [attr.mobile]="isMobile ? true : null"
        [attr.desktop]="!isMobile ? true : null"
        (click)="isMobile && openMyMenu($event)"
        (mouseenter)="!isMobile && openMyMenu($event)"
        [matMenuTriggerFor]="createMenu"
        class="user-button gt2-light-green"
        style="padding: 0; margin: 0"
      >
        <mat-icon class="s-32 icon-mat-black">add</mat-icon>
      </button>

      <mat-menu
        #createMenu="matMenu"
        (menuClosed)="onMenuClosed()"
        [overlapTrigger]="false"
      >
        <span (mouseleave)="closeMyMenu()" (click)="closeMyMenu()">
          <button
            fxLayout="row"
            fxLayoutAlign="start center"
            mat-menu-item
            (click)="onCreateNewEmployee(); closeMyMenu()"
          >
            <mat-icon class="icon-mat-black" fxFlex="13%">{{
              iconsService.getIcon("EMPLOYEE")
            }}</mat-icon>
            <span>{{ "TOOLBAR.CREATE_EMPLOYEE" | translate }}</span>
          </button>

          <button
            fxLayout="row"
            fxLayoutAlign="start center"
            mat-menu-item
            (click)="onCreateNewProduct(); closeMyMenu()"
          >
            <mat-icon class="icon-mat-black" fxFlex="13%">{{
              iconsService.getIcon("PRODUCT")
            }}</mat-icon>
            <span>{{ "TOOLBAR.CREATE_PRODUCT" | translate }}</span>
          </button>

          <button
            fxLayout="row"
            fxLayoutAlign="start center"
            mat-menu-item
            (click)="onCreateNewProductCombo(); closeMyMenu()"
          >
            <mat-icon
              fxFlex="13%"
              class="fa-lg icon-mat-black"
              fontSet="fa"
              fontIcon="{{ iconsService.getIcon('COMBO') }}"
            ></mat-icon>
            <span>{{ "TOOLBAR.CREATE_PRODUCT_COMBO" | translate }}</span>
          </button>

          <!--<button mat-menu-item (click)="onCreateNewOrder()">-->
          <!--<span>{{ 'TOOLBAR.CREATE_ORDER' | translate }}</span>-->
          <!--</button>-->

          <button
            fxLayout="row"
            fxLayoutAlign="start center"
            mat-menu-item
            (click)="onCreateNewPlace(); closeMyMenu()"
          >
            <mat-icon class="icon-mat-black" fxFlex="13%">{{
              iconsService.getIcon("PLACE")
            }}</mat-icon>
            <span>{{ "TOOLBAR.CREATE_PLACE" | translate }}</span>
          </button>

          <button
            fxLayout="row"
            fxLayoutAlign="start center"
            mat-menu-item
            (click)="onCreateNewEnterprise(); closeMyMenu()"
          >
            <mat-icon class="icon-mat-black" fxFlex="13%">{{
              iconsService.getIcon("ENTERPRISE")
            }}</mat-icon>
            <span>{{ "TOOLBAR.CREATE_ENTERPRISE" | translate }}</span>
          </button>

          <button
            fxLayout="row"
            fxLayoutAlign="start center"
            mat-menu-item
            (click)="onCreateNewClient(); closeMyMenu()"
          >
            <mat-icon
              fxFlex="13%"
              class="fa-lg icon-mat-black"
              fontSet="fa"
              fontIcon="{{ iconsService.getIcon('CLIENT') }}"
            ></mat-icon>
            <span>{{ "TOOLBAR.CREATE_CLIENT" | translate }}</span>
          </button>

          <button
            fxLayout="row"
            fxLayoutAlign="start center"
            mat-menu-item
            (click)="onCreateNewMaterial(); closeMyMenu()"
          >
            <mat-icon class="icon-mat-black" fxFlex="13%">{{
              iconsService.getIcon("MATERIAL")
            }}</mat-icon>
            <span>{{ "TOOLBAR.CREATE_MATERIAL" | translate }}</span>
          </button>

          <button
            fxLayout="row"
            fxLayoutAlign="start center"
            mat-menu-item
            (click)="onCreateNewMaterialCombo(); closeMyMenu()"
          >
            <mat-icon
              fxFlex="13%"
              class="fa-lg icon-mat-black"
              fontSet="fa"
              fontIcon="{{ iconsService.getIcon('COMBO') }}"
            ></mat-icon>
            <span>{{ "TOOLBAR.CREATE_MATERIAL_COMBO" | translate }}</span>
          </button>

          <button
            fxLayout="row"
            fxLayoutAlign="start center"
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasCTReport()
            "
            mat-menu-item
            (click)="onCreateNewProposal(); closeMyMenu()"
          >
            <!-- <mat-icon class="s-20" fontSet="fa" fontIcon="{{ iconsService.getIcon('PROPOSAL') }}"></mat-icon> -->
            <mat-icon class="icon-mat-black" fxFlex="13%">{{
              iconsService.getIcon("PROPOSAL")
            }}</mat-icon>
            <span>{{ "TOOLBAR.CREATE_PROPOSAL" | translate }}</span>
          </button>

          <div
            *ngIf="modulesService.modulesAreSet && modulesService.hasCTReport()"
          ></div>

          <button
            fxLayout="row"
            fxLayoutAlign="start center"
            mat-menu-item
            (click)="onCreateNewOrder(); closeMyMenu()"
          >
            <!-- <mat-icon class="s-20" fontSet="fa" fontIcon="{{ iconsService.getIcon('ORDER') }}"></mat-icon> -->
            <mat-icon class="icon-mat-black" fxFlex="13%">{{
              iconsService.getIcon("ORDER")
            }}</mat-icon>
            <span>{{ "TOOLBAR.CREATE_ORDER" | translate }}</span>
          </button>

          <button
            fxLayout="row"
            fxLayoutAlign="start center"
            mat-menu-item
            *ngIf="
              modulesService.modulesAreSet && modulesService.hasCongressModule()
            "
            (click)="onCreateNewCongress(); closeMyMenu()"
          >
            <mat-icon class="icon-mat-black" fxFlex="13%">{{
              iconsService.getIcon("CONGRESS")
            }}</mat-icon>
            <span>{{ "TOOLBAR.CREATE_CONGRES" | translate }}</span>
          </button>
        </span>
      </mat-menu>

      <!-- History, ORDER it's important, after CreateMenu -->
      <div
        *ngIf="!isSmallScreen"
        class="toolbar-separator"
        style="width: 1px"
      ></div>
      <!-- <div>{{histories.length}}</div> -->
      <div fxLayout="row" fxLayoutAlign="start center">
        <!-- DISABLED BUTTON MENU -->
        <button
          *ngIf="histories.length == 0"
          mat-button
          fxHide
          fxShow.gt-xs
          class="language-button"
          [matMenuTriggerFor]="historyMenu"
          #historyMenuButton
          disabled
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="s-32" color="rgba(255,255,255,.7)"
              >history</mat-icon
            >
          </div>
        </button>
        <!-- ACTIVE BUTTON MENU -->
        <button
          *ngIf="histories.length > 0"
          mat-button
          fxHide
          fxShow.gt-xs
          class="language-button"
          [matMenuTriggerFor]="historyMenu"
          #historyMenuButton
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="s-32" color="rgba(255,255,255,.7)"
              >history</mat-icon
            >
          </div>
        </button>

        <mat-menu #historyMenu="matMenu" [overlapTrigger]="false">
          <button
            mat-menu-item
            *ngFor="let lang of histories"
            (click)="setHistory(lang)"
          >
            <mat-icon class="s-32 icon-mat-black">{{ lang.icon }}</mat-icon>
            <span class="iso">{{ lang.label }}</span>
          </button>
        </mat-menu>

        <div
          *ngIf="!isSmallScreen"
          class="toolbar-separator"
          style="width: 2px"
        ></div>
      </div>

      <!--Languages-->
      <div
        *ngIf="!isSmallScreen"
        class="toolbar-separator"
        style="width: 1px"
      ></div>
      <!--<div class="toolbar-separator"></div>-->
      <!-- *ngxPermissionsOnly="['GOD']" -->
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          mat-button
          fxHide
          fxShow.gt-xs
          class="language-button"
          (click)="toggleFullscreen()"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="s-24 icon-mat-darkmode">zoom_out_map</mat-icon>
          </div>
        </button>

        <div
          *ngIf="!isSmallScreen"
          class="toolbar-separator"
          style="width: 2px"
        ></div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          mat-button
          fxHide
          fxShow.gt-xs
          class="language-button"
          (click)="toggleLang()"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <span class="iso text-uppercase">{{
              selectedLanguage.shortTitle
            }}</span>
          </div>
        </button>

        <div
          *ngIf="!isSmallScreen"
          class="toolbar-separator"
          style="width: 2px"
        ></div>
      </div>

      <!--USER MENU-->
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center" class="px-12">
          <img
            *ngIf="employee && (employee.avatar_thumb || isMobile)"
            class="avatar mx-4"
            onerror="if (this.src !== '/assets/images/avatars/default-no-avatar.png') this.src='/assets/images/avatars/default-no-avatar.png';"
            src="{{ employee.avatar_thumb }}"
          />

          <img
            *ngIf="!employee"
            class="avatar"
            src="assets/images/avatars/profile.jpg"
          />
          <span
            *ngIf="employee"
            maxlength="12"
            class="username employee-name"
            fxHide
            fxShow.gt-sm
          >
            {{
              employee.first_name.length > 11
                ? (employee.first_name | slice: 0 : 11) + "..."
                : employee.first_name
            }}
          </span>
          <mat-icon class="s-16 icon-mat-darkmode" fxHide.xs
            >keyboard_arrow_down</mat-icon
          >
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="onProfile()">
          <mat-icon class="icon-mat-black">{{
            iconsService.getIcon("PROFILE")
          }}</mat-icon>
          <span>{{ "TOOLBAR.MY_PROFILE" | translate }}</span>
        </button>

        <button *ngIf="isMobile" mat-menu-item (click)="toggleLang()">
          <mat-icon class="icon-mat-black">{{
            iconsService.getIcon("LANG")
          }}</mat-icon>
          <span>{{ selectedLanguage.title }}</span>
        </button>

        <button mat-menu-item (click)="toggleTheme()">
          <mat-icon class="icon-mat-black">{{
            iconsService.getIcon("DARKMODE")
          }}</mat-icon>
          <span>{{ modeText }}</span>
        </button>

        <button
          *ngxPermissionsOnly="rolesService.OWNER_ROLES"
          mat-menu-item
          (click)="onOrganization()"
        >
          <mat-icon class="icon-mat-black">{{
            iconsService.getIcon("ENTERPRISE")
          }}</mat-icon>
          <span>{{ "TOOLBAR.ORGANIZATION" | translate }}</span>
        </button>

        <button
          *ngxPermissionsOnly="rolesService.RH_ROLES"
          mat-menu-item
          (click)="onSettings()"
        >
          <mat-icon class="icon-mat-black">{{
            iconsService.getIcon("SETTINGS")
          }}</mat-icon>
          <span>{{ "TOOLBAR.SETTINGS" | translate }}</span>
        </button>

        <button
          *ngIf="isMobile && histories.length !== 0"
          mat-menu-item
          [matMenuTriggerFor]="historyMenu"
        >
          <mat-icon class="" color="rgba(255,255,255,.7)">history</mat-icon>
          <span>{{ "TOOLBAR.HISTORY" | translate }}</span>
        </button>

        <button mat-menu-item class="" (click)="onLogout()">
          <mat-icon class="icon-mat-black">exit_to_app</mat-icon>
          <span>{{ "TOOLBAR.LOGOUT" | translate }}</span>
        </button>
      </mat-menu>

      <div
        *ngIf="!isSmallScreen"
        class="toolbar-separator"
        fxHide
        fxShow.gt-xs
      ></div>

      <!--<button *ngxPermissionsOnly="['GOD']" mat-icon-button class="chat-panel-toggle-button" (click)="toggleSidebarOpen('chatPanel')" aria-label="Toggle chat panel">
        <mat-icon class="secondary-text">chat</mat-icon>
      </button>

      <div class="toolbar-separator" style="width: 2px"></div>-->

      <button
        *ngIf="!isSmallScreen"
        mat-icon-button
        class="quick-panel-toggle-button"
        (click)="toggleSidebarOpen('quickPanel')"
        aria-label="Toggle quick panel"
      >
        <mat-icon class="icon-mat-darkmode">format_list_bulleted</mat-icon>
      </button>

      <div
        class="toolbar-separator"
        style="width: 2px"
        *ngIf="!hiddenNavbar && rightNavbar && !isSmallScreen"
        fxHide
        fxShow.gt-xs
      ></div>

      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="icon-mat-darkmode">menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
