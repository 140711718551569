export const locale = {
  lang: "fr-CA",
  data: {
    SPECIFIC_CONDITION: {
      TITLE: "Nouvelle condition spécifique",
      EDIT_TITLE: "Modifier condition spécifique",
      SELECT_SPECIFIC_CONDITION: "Sélectionner une condition spécifique",
    },
  },
};
