import {
  DistributionModeEnum,
  ExportFormatEnum,
  ExportToEnum,
  BenchModeEnum,
  PaperEnum,
  LocaleEnum,
  ColorModeEnum,
  OrientationEnum,
  SocketModeEnum,
} from './ReportsEnum';

export class ReportsFactory {
  static getVO(
    reportKey: string,
    distributionMode: DistributionModeEnum = DistributionModeEnum.RAW_HTML,
    exportFormat: ExportFormatEnum = ExportFormatEnum.HTML,
    exportTo: ExportToEnum = ExportToEnum.PREVIEW,
    params: any = null,
    filename: string | null = null,
    local: LocaleEnum = LocaleEnum.FR_CA,
    paper: PaperEnum = PaperEnum.A4,
    job_queue: string | null = null
  ): ReportVOModel {
    const vo: any = ReportsFactory.getBasicVO();
    vo.report_key = reportKey;
    vo.distribution_mode = distributionMode;
    vo.export_format = exportFormat;
    vo.export_to = exportTo;
    vo.local = local;
    vo.paper = paper;
    vo.job_queue = job_queue;

    if (
      vo.export_to !== ExportToEnum.PREVIEW &&
      vo.distribution_mode !== DistributionModeEnum.EMAIL_ATTACHMENT &&
      vo.distribution_mode !== DistributionModeEnum.MAIL_ME
    ) {
      vo.distribution_mode = DistributionModeEnum.S3_STORAGE_USER_DOCUMENT;
    }

    if (
      vo.export_to !== ExportToEnum.PREVIEW &&
      vo.distribution_mode !== DistributionModeEnum.EMAIL_ATTACHMENT
    ) {
      vo.job_queue = 'GT_REPORT2';
    }
    if (params) {
      vo.params = params;
    }
    return vo;
  }

  static getBasicVO(): ReportVOModel {
    const vo: any = {};
    vo.report_key = null;
    vo.distribution_mode = DistributionModeEnum.RAW_HTML;
    vo.export_format = ExportFormatEnum.HTML;
    vo.export_to = ExportToEnum.PRINT;
    vo.bench_mode = BenchModeEnum.NONE;
    vo.paper = PaperEnum.LETTER;
    vo.local = LocaleEnum.FR_CA;
    vo.file_name = null; // "rapport"; // TODO: Filename will be different depending on the context / page it is called?
    vo.job_queue = null;
    vo.color_mode = ColorModeEnum.COLOR;
    vo.orientation = OrientationEnum.PORTRAIT;
    return vo;
  }

  // --o TEMP
  // static getXlsVO(): ReportVOModel {
  //     const vo: any = {};
  //     vo.report_key = null;
  //     vo.distribution_mode = DistributionModeEnum.FILE;
  //     vo.export_format = ExportFormatEnum.XLSX;
  //     vo.export_to = ExportToEnum.PREVIEW;
  //     vo.bench_mode = BenchModeEnum.BENCH_TOOL;
  //     vo.paper = PaperEnum.LETTER;
  //     vo.local = LocaleEnum.FR_CA;
  //     vo.file_name = "rapport_production_patate"; // TODO:
  //     vo.job_queue = null;
  //     // vo.user_id = 1;
  //     vo.color_mode = ColorModeEnum.COLOR;
  //     vo.orientation = OrientationEnum.PORTRAIT;
  //     vo.socket_mode = SocketModeEnum.NONE;
  //     return vo;
  // }
}

export interface ReportVOModel {
  report_key: string;
  distribution_mode: DistributionModeEnum;
  export_format: ExportFormatEnum;
  export_to: ExportToEnum;
  bench_mode: BenchModeEnum;
  paper: PaperEnum;
  local: LocaleEnum;
  file_name: string;
  job_queue: string;
  color_mode: ColorModeEnum;
  orientation: OrientationEnum;
  socket_mode: SocketModeEnum;
  params: any;
  socket_key: string;
  fbKey: string;
  job_connection: string;
}
