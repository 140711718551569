<div
  id="caterer"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
          (click)="onPageBack(location, router)"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
            <div
              class="caterer-title"
              *fuseIfOnDom
              [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' },
              }"
            >
              <div *ngIf="caterer" class="">
                <span class="text-truncate">
                  {{ caterer.name | uppercase }}
                </span>
              </div>
            </div>
          </div>

          <div
            *ngIf="caterer"
            fxLayout="row"
            fxLayoutAlign="start start"
            class=""
          >
            <div *ngIf="caterer.account_mode_label">
              {{ caterer.account_mode_label }}
            </div>
            <div *ngIf="caterer.account_mode === 'TRIAL'">
              &nbsp; - {{ "CATERER.TRIAL" | translate }}:
              {{ caterer.trial_ended_at_label }}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end start">
        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    *ngIf="caterer"
    fxLayout="row"
    fxLayoutAlign="end center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 py-8 gt2-sub-header content-subheader"
  ></div>
  <!-- / SUB HEADER -->

  <!--HISTORY SIDEBAR-->
  <!-- <fuse-sidebar name="productHistoryPanel" position="right" class="section-history-panel">
      <app-section-history-panel panelName="productHistoryPanel" section="products" sectionUUID="{{productID}}"></app-section-history-panel>
  </fuse-sidebar> -->

  <div class="content">
    <!--HISTORY SIDEBAR BUTTON-->
    <!-- <div *ngxPermissionsOnly="['GOD']">
          <button mat-icon-button class="warn mat-elevation-z2 historic-button" *fuseIfOnDom [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
              (click)="toggleHistoricSidebarOpen('productHistoryPanel')">
              <mat-icon>history</mat-icon>
          </button>
      </div> -->

    <!-- CENTER -->
    <div class="center p-24 pb-56 pr-sm-72" #mainContainer fusePerfectScrollbar>
      <!-- CONTENT -->
      <div fxLayout="column" class="caterer-content">
        <!-- STATS -->
        <div
          *ngIf="caterer && caterer.stats"
          fxLayout="column"
          fxLayoutAlign="0 1 auto"
          fxFlex="100"
        >
          <div
            class="widget-group"
            fxLayout="colomn"
            fxLayoutAlign="center start"
            fxFlex="100%"
            fxLayoutWrap
            *fuseIfOnDom
            class=""
          >
            <fuse-widget
              [@animate]="{ value: '*', params: { y: '100%' } }"
              class="widget"
              fxLayout="column"
              fxFlex="100"
              fxFlex.gt-xs="100"
              fxFlex.gt-md="100"
            >
              <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
                <div
                  class="pl-16 pr-8 py-16 h-52"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <div class="h3">{{ "CATERER.TOTAL_USER" | translate }}</div>
                </div>

                <div
                  class="pt-8 pb-32"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                >
                  <div class="red-fg font-size-36 line-height-36 text-center">
                    {{ caterer.users_count }}
                  </div>
                </div>
              </div>
            </fuse-widget>
          </div>

          <!--<div class="widget-group" fxLayout="row" fxLayoutAlign="space-around start" fxFlex="100%" fxLayoutWrap
                *fuseIfOnDom class="">
                <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="50"
                    fxFlex.gt-xs="50" fxFlex.gt-md="50">
    
    
                    <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
                        <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="center center">
                            
                            <div class="h3">{{ 'CATERER.CURRENT_COST' | translate }}</div>
                        </div>
    
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <div class="red-fg font-size-36 line-height-36 text-center">
                                {{caterer.stats.current_cost}}
                            </div>
                            
                        </div>
                    </div>
    
                </fuse-widget>
    
                <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="50"
                    fxFlex.gt-xs="50" fxFlex.gt-md="50">
    
                    <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
                        <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="center center">
                            <div class="h3">{{ 'CATERER.TOTAL_USER' | translate }}</div>
    
                        </div>
    
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <div class="red-fg font-size-36 line-height-36 text-center">
                                {{caterer.stats.total_user}}
                            </div>
                            
                        </div>
                    </div>
    
                </fuse-widget>
            </div>
    
            <div class="widget-group" fxLayout="row" fxLayoutAlign="space-around start" fxFlex="100" fxLayoutWrap
                *fuseIfOnDom>
                <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="50"
                    fxFlex.gt-xs="50" fxFlex.gt-md="50">
    
                    <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
                        <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="center center">
                            <div class="h3">{{ 'CATERER.OPEN_SINCE' | translate }}</div>
                        </div>
    
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <div class="red-fg font-size-36 line-height-36 text-center">
                                {{caterer.stats.open_since}}
                            </div>
                            <div class="h3 secondary-text font-weight-500"></div>
                        </div>
                    </div>
    
                </fuse-widget>
    
                <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="50"
                    fxFlex.gt-xs="50" fxFlex.gt-md="50">
    
                    <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
                        <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="center center">
                            <div class="h3">{{ 'CATERER.NEXT_PAYMENT_DATE' | translate }}</div>
                        </div>
    
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <div class="red-fg font-size-36 line-height-36 text-center">
                                {{caterer.stats.next_payment_date}}
                            </div>
                            <div class="h3 secondary-text font-weight-500"></div>
                        </div>
                    </div>
    
                </fuse-widget>
            </div>-->
        </div>
        <!-- STATS END -->

        <!-- INFORMATION -->
        <div class="profile-box info-box general mt-12" fxLayout="column">
          <header class="accent">
            <div class="title">{{ "GENERIC.INFORMATION" | translate }}</div>
          </header>
          <div class="content">
            <div
              *ngIf="caterer"
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start start"
            >
              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "GENERIC.NAME" | translate }}</div>
                <div class="info text-truncate">{{ caterer.name }}</div>
                <div *ngIf="!caterer.name" class="info empty-value"></div>
              </div>

              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="catererForm" class="">
                    <mat-form-field class="form-input-container" fxFlex="100%">
                      <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
                      <input
                        matInput
                        placeholder="{{ 'GENERIC.NAME' | translate }}"
                        formControlName="name"
                        type="text"
                        autocomplete="nope"
                        [(ngModel)]="caterer.name"
                        required
                      />
                      <mat-error *ngIf="catererForm.controls['name'].invalid"
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">
                  {{ "CATERER.RESPONSIBLE_USER" | translate }}
                </div>
                <div *ngIf="caterer.owner_user" class="info text-truncate">
                  {{ caterer.owner_user.name }}
                </div>
                <div *ngIf="!caterer.owner_user" class="info empty-value"></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <app-gt2-select
                    [service]="userService"
                    [placeHolder]="'CATERER.SELECT_EMPLOYEE' | translate"
                    [label]="''"
                    [selectedModel]="caterer.owner_user"
                    (changeEvent)="onOwnerUserChange($event)"
                    [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                    [nullableUseNone]="false"
                    fxFlex="100%"
                    class="item-full-width"
                  ></app-gt2-select>
                  <!-- <div [formGroup]="catererForm" class="">
                                          <mat-form-field class="form-input-container" fxFlex="100%">
                                              <input matInput placeholder="{{ 'CATERER.RESPONSIBLE_USER' | translate }}"
                                                  formControlName="owner_user" type="text" 
                                                  [(ngModel)]="caterer.owner_user">
                                              <mat-error *ngIf="catererForm.controls['owner_user'].invalid">{{
                                                  'GENERIC.INPUT_LENGTH_INVALID' | translate }}
                                              </mat-error>
                                          </mat-form-field>
                                      </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ADDRESS -->
        <div
          *ngIf="caterer"
          class="profile-box info-box general mt-12"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "GENERIC.ADDRESS" | translate }}</div>
          </header>
          <div class="">
            <app-address
              #addressComponent
              [groupModel]="caterer.address"
              [isVisible]="true"
              [editMode]="false"
              [useAutosave]="false"
              class=""
            ></app-address>
          </div>
        </div>

        <!-- CONTACT -->
        <div
          *ngIf="caterer"
          class="profile-box info-box general mt-12"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "GENERIC.CONTACT" | translate }}</div>
          </header>
          <div class="">
            <app-contact
              #contactComponent
              [groupModel]="caterer.contact"
              [editMode]="false"
              [useAutosave]="false"
              class=""
            >
            </app-contact>
          </div>
        </div>

        <!-- CATERER LIST -->
        <div
          *ngIf="caterer"
          class="profile-box info-box general mt-12"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "CATERER.ORG_LIST" | translate }}</div>
          </header>
          <div class="">
            <mat-table
              #table
              class="jobs-table"
              [dataSource]="orgList.dataSource"
            >
              <!-- Nom Column -->
              <ng-container cdkColumnDef="name">
                <mat-header-cell fxFlex *cdkHeaderCellDef>
                  {{ "GENERIC.NAME" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex *cdkCellDef="let org">
                  <p class="text-truncate">
                    {{ org.name }}
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actionsColumn">
                <mat-header-cell *cdkHeaderCellDef fxFlex="60px">
                </mat-header-cell>
                <mat-cell *cdkCellDef="let org" fxFlex="60px">
                  <button
                    mat-icon-button
                    color="primary"
                    type="button"
                    focusable="false"
                    class="mx-8"
                    (click)="onEditOrganization(org)"
                  >
                    <span>
                      <mat-icon color="primary">edit</mat-icon>
                    </span>
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row
                *cdkHeaderRowDef="displayedOrganizationColumns"
              ></mat-header-row>
              <mat-row
                *cdkRowDef="let org; columns: displayedOrganizationColumns"
                class=""
                (click)="onEditOrganization(org)"
                matRipple
              >
              </mat-row>
            </mat-table>
          </div>
        </div>
      </div>
      <!-- / CONTENT -->
    </div>
    <!-- / CENTER -->
  </div>
</div>

<div *ngIf="caterer" fxLayout="row" fxLayoutAlign="end start">
  <div *ngxPermissionsOnly="rolesService.RH_ROLES">
    <div class="fixed-button">
      <button
        mat-fab
        class="gt2-edit-button"
        id="edit-caterer-button"
        [disabled]="loading"
        (click)="onEdit($event)"
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
      >
        <mat-icon *ngIf="!editMode">{{ getIcon("EDIT_FAB") }}</mat-icon>
        <mat-icon *ngIf="editMode">{{ getIcon("UNDO") }}</mat-icon>
      </button>
    </div>
  </div>
</div>
