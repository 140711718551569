<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row>
      <span class="title dialog-title">{{ _data.article.title }}</span>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
    <div [innerHTML]="_data.article.content"></div>
  </div>

  <div
    mat-dialog-actions
    class="m-0 p-16"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <button
      mat-button
      (click)="matDialogRef.close()"
      color="accent"
      class=""
      aria-label="Close"
    >
      CLOSE
    </button>
  </div>
</div>
