import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '../../../../@fuse/animations';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { AppRoutes } from '../../const/AppRoutes';
import { AppRoutesService } from '../../services/app-routes.service';
import { Settings } from '../../settings/settings';
import { SettingsModel } from '../../settings/SettingsModel';
import { GT2FormPageAbstract } from '../abstract/GT2FormPageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-device-not-supported',
  templateUrl: './device-not-supported.component.html',
  styleUrls: ['./device-not-supported.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class DeviceNotSupportedComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  subject?: string;
  message?: string;
  setting?: SettingsModel;

  /*
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private router: Router,
    public location: Location,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    public appRoutesService: AppRoutesService
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    //
    this.form = this.formBuilder.group({
      subject: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      message: [
        '',
        [Validators.minLength(Settings.inputMinChar), Validators.required],
      ],
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onSendContactForm(): void {
    //this.logger.info("DeviceNotSupportedComponent.onSendContactForm()");
  }

  public override onPageBack(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl(AppRoutes.DASHBOARD);
    }
  }

  public onVisitGt2(): void {
    window.open(environment.gt2Website, '_blank');
    // window.open("https://www.gestiontraiteur.com", '_blank');
  }

  public showConfirmationDialog(): void {}
}
