import { GT2ConstantsEnum } from './../const/GT2ConstantsEnum';
import { CrudEmployeeService } from './../content/crud/crud-employee/crud-employee.service';
import { TestErrorService } from './../api/test-error.service';
import { MatDialog } from '@angular/material/dialog';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaderResponse,
  HttpSentEvent,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '../api/auth-service.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class BrokenResponseInterceptor implements HttpInterceptor {
  //
  authService?: AuthenticationService;

  constructor(
    public inj: Injector,
    public logger: NGXLogger,
    private testError: TestErrorService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    // this.logger.info("-> JwtInterceptor.intercept()");
    const router = this.inj.get(Router);
    const dialog = this.inj.get(MatDialog);
    this.authService = this.inj.get(AuthenticationService);
    const employeeService = this.inj.get(CrudEmployeeService);
    // private employeeService: CrudEmployeeService,

    // this.logger.info(
    //     "BrokenResponseInterceptor.intercept() - employeeService: " +
    //         employeeService.employee
    // );

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          // this.logger.error("ERROR! JwtInterceptor.intercept() - Error: " + JSON.stringify(err));

          switch ((<HttpErrorResponse>err).status) {
            case 0:
              // router.navigateByUrl(AppRoutes.ERROR_429);
              //this.logger.info("BrokenResponseInterceptor.intercept() - **** Error 0! ****");

              // check if god, only god should have that error handling / call test
              if (
                employeeService &&
                employeeService.me &&
                employeeService.me.user.role === GT2ConstantsEnum.GOD
              ) {
                this.handleStatusZeroResponse(request);
              }

              return throwError(err);
            default:
              /* this.logger.info(
                                "BrokenResponseInterceptor.intercept() -> UNHANDLED ERROR CODE, DEFAULT THROW ERROR!"
                            ); */
              return throwError(err);
          }
        }
        return throwError(err);
        // else {
        //     return throwError(err);
        // }
      })
    );
  }

  // public requestCount: number = 0;
  public lastRequest: HttpRequest<any> | null = null;
  public handleStatusZeroResponse(request: HttpRequest<any>): void {
    //     if (this.lastRequest) {
    //         this.logger.info(
    //             "BrokenResponseInterceptor.handleStatusZeroResponse() -> lastRequest.url: " +
    //                 this.lastRequest.url
    //         );
    //         this.logger.info(
    //             "BrokenResponseInterceptor.handleStatusZeroResponse() -> request.url: " +
    //                 request.url
    //         );
    //         this.logger.info(
    //             "BrokenResponseInterceptor.handleStatusZeroResponse() -> lastRequest.body: " +
    //                 JSON.stringify(this.lastRequest.body)
    //         );
    //         this.logger.info(
    //             "BrokenResponseInterceptor.handleStatusZeroResponse() -> request.body: " +
    //                 JSON.stringify(request.body)
    //         );
    //     }
    //     if (
    //         this.lastRequest &&
    //         this.lastRequest.url === request.url &&
    //         this.lastRequest.body === request.body
    //     ) {
    //         this.lastRequest = null;
    //         return;
    //     }
    //     this.lastRequest = request;
    //     const cloneReq = request.clone();
    //     this.testError
    //         .testErrorFromRequest(cloneReq)
    //         .catch((error) => {
    //             // this.logger.error(
    //             //     "BrokenResponseInterceptor.handleStatusZeroResponse().catch() -> ERROR: " +
    //             //         JSON.stringify(error)
    //             // );
    //         })
    //         .then((response) => {
    //             // this.logger.info(
    //             //     "BrokenResponseInterceptor.handleStatusZeroResponse().then() -> SUCCESS: " +
    //             //         JSON.stringify(response)
    //             // );
    //         });
  }
}
