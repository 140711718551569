import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ClientsService } from '../../../api/clients.service';
import { EnterprisesService } from '../../../api/enterprises.service';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { AppRoutesService } from '../../../services/app-routes.service';
import { ModulesService } from '../../../services/modules.service';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from '../client-attach-enterprise/i18n/en-CA';
import { locale as french } from '../client-attach-enterprise//i18n/fr-CA';
import { fromEvent, merge, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  startWith,
  switchMap,
  catchError,
  map,
} from 'rxjs/operators';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { ClientAttachEnterpriseComponent } from '../client-attach-enterprise/client-attach-enterprise.component';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { TableSizePipeModule } from '../../../modules/table-size.module';
import { MatButtonModule } from '@angular/material/button';
import { CdkTableModule } from '@angular/cdk/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { MatBadgeModule } from '@angular/material/badge';

@Component({
  selector: 'app-client-attach-enterprise-table-paginated',
  templateUrl: './client-attach-enterprise-table-paginated.component.html',
  styleUrls: ['./client-attach-enterprise-table-paginated.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatProgressBarModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    TableSizePipeModule,
    CdkTableModule,
    MatButtonModule,
    MatRadioModule,
    MatSortModule,
    MatIconModule,
    Gt2PrimengModule,
    MatBadgeModule,
    TranslateModule,
  ],
  animations: fuseAnimations,
})
export class ClientAttachEnterpriseTablePaginatedComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  // static LS_CLIENTS: string = "CLIENTS";

  // visible: boolean = true;
  confirmDialogRef: any;
  isLoading: boolean = false;

  // enterprises
  selectedEnterprise: any;
  enterprisesDisplayedColumns = ['name'];
  @ViewChild('enterprisesPaginator')
  enterprisesPaginator!: MatPaginator;
  @ViewChild('enterprisesSort') enterprisesSort!: MatSort;
  @ViewChild('enterprisesTable')
  enterprisesTable!: MatTable<any>;
  @ViewChild('enterprisesFilter')
  enterprisesFilter!: ElementRef;
  enterprisesFilterValue: string = '';
  enterprisesDataSource = new MatTableDataSource();
  enterprisesResultsLength = 0;
  isEnterprisesLoadingResults = false;
  isEnterprisesRateLimitReached = false;

  //
  isFirstLoad: boolean = true;
  viewSize: string = '50%';
  client: any;

  constructor(
    public override dialogRef: MatDialogRef<ClientAttachEnterpriseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clientsService: ClientsService,
    private enterprisesService: EnterprisesService,
    // private createClientService: CreateClientService,
    public dialog: MatDialog,
    public datatableHelperService: DatatableHelperService,
    public router: Router,
    private logger: NGXLogger,
    // public print: PrintService,
    // public fileService: FileService,
    // private toastr: ToastrService,
    public translate: TranslateService,
    private api: Gt2ApiService,
    public appRoutesService: AppRoutesService,
    // private titleService: Title,
    private translationLoader: FuseTranslationLoaderService,
    private modulesService: ModulesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    //this.logger.info("ClientAttachEnterpriseTablePaginatedComponent.constructor()");
    //
    if (data.client) {
      this.client = data.client;
    }
  }

  ngOnInit() {
    //this.logger.info("ClientAttachEnterpriseTablePaginatedComponent.ngOnInit()");
    //
    // this.titleService.setTitle(
    //     this.translate.instant("GENERIC.CLIENTS_ENTERPRISE")
    // );
  }

  ngAfterViewInit() {
    //this.logger.info("ClientAttachEnterpriseTablePaginatedComponent.ngAfterViewInit()");
    this.modulesService.modulesSet.subscribe((data) => {
      //this.logger.info("ClientAttachEnterpriseTablePaginatedComponent.ngAfterViewInit.modulesSet() data: " + JSON.stringify(data));
      if (data) {
        // this.setUpClientsDataTable();
        this.setUpEnterprisesDataTable();
      }
    });
    // this.setUpClientsDataTable();
    // this.setUpEnterprisesDataTable();
  }

  ngOnDestroy() {}

  public enterprisesSyncPrimaryPaginator(event: PageEvent) {
    //this.logger.info("enterprisesSyncPrimaryPaginator()");
    this.enterprisesPaginator.pageIndex = event.pageIndex;
    this.enterprisesPaginator.pageSize = event.pageSize;
    this.enterprisesPaginator.page.emit(event);
  }

  public setUpEnterprisesDataTable(): void {
    this.enterprisesSort.sortChange.subscribe(() => {
      this.enterprisesPaginator.pageIndex = 0;
    });

    // Observable.merge(this.enterprisesSort.sortChange, this.enterprisesPaginator.page)

    merge(this.enterprisesSort.sortChange, this.enterprisesPaginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isEnterprisesLoadingResults = true;
          });

          return this.enterprisesService.getEnterprisesWithPagination(
            this.enterprisesSort.active,
            this.enterprisesSort.direction,
            this.enterprisesPaginator.pageIndex,
            this.enterprisesPaginator.pageSize,
            this.enterprisesFilterValue,
          );
        }),
      )
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isEnterprisesLoadingResults = false;
          this.isEnterprisesRateLimitReached = false;
          this.enterprisesResultsLength = data.meta.pagination.total;
          this.isFirstLoad = false;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isEnterprisesLoadingResults = false;
            this.isEnterprisesRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe((data) => (this.enterprisesDataSource.data = data));

    fromEvent(this.enterprisesFilter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.enterprisesFilter?.nativeElement.value.length > 1 ||
            this.enterprisesFilter?.nativeElement.value === ''
          ) {
            this.enterprisesPaginator.pageIndex = 0;
            this.reloadEnterprisesTable();
          }
        }),
      )
      .subscribe();
  }

  public onSelectEnterprise(item: any): void {
    //this.logger.info("ClientAttachEnterpriseTablePaginatedComponent.onSelectEnterprise()");
    this.selectedEnterprise = item;
  }

  public reloadTables(): void {
    // --o Hack to force reload of tables
    this.reloadEnterprisesTable();
  }

  public reloadEnterprisesTable(): void {
    this.enterprisesPaginator?._changePageSize(
      this.enterprisesPaginator.pageSize,
    );
  }

  public clearEnterpriseSearch(): void {
    this.enterprisesFilterValue = '';
    this.enterprisesPaginator.pageIndex = 0;
    this.enterprisesPaginator?._changePageSize(
      this.enterprisesPaginator.pageSize,
    );
  }

  public onAttachEnterprise(): void {
    //this.logger.info("ClientAttachEnterpriseComponent.onAttachEnterprise()");
    this.isLoading = true;
    this.clientsService
      .attachClientToEnterprise(this.client.uuid, this.selectedEnterprise.uuid)
      .subscribe(
        (response) => {
          // this.logger.info("ClientAttachEnterpriseComponent.onAttachEnterprise() -> SUCCESS: " + JSON.stringify(response));
          this.isLoading = false;
          this.dialogRef.close(response);
        },
        (error) => {
          this.logger.error(
            'ClientAttachEnterpriseComponent.onAttachEnterprise() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.isLoading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public onOpenItemInNewTab(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }
}
