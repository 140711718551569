<div
  id="notsupported"
  fxLayout="column"
  fxLayoutAlign="center center">
  <div
    class="content mb-32 mt-32"
    fxLayout="column"
    fxLayoutAlign="center center">
    <div class="error-code mb-36">{{ "NOT_SUPPORTED.NOT_SUPPORTED" | translate }}</div>

    <!-- <div class="message">{{ 'NOT_SUPPORTED.MESSAGE' | translate }}</div> -->

    <!-- <div class="sub-message">
        {{ 'NOT_SUPPORTED.SUB_MESSAGE' | translate }}
      </div> -->
    <a
      class="report-link"
      (click)="onDownloadChrome()"
      >{{ "NOT_SUPPORTED.SUB_MESSAGE" | translate }}</a
    >

    <a
      class="report-link2 mt-24"
      (click)="onVisitGt2()"
      >{{ "NOT_SUPPORTED.GT2_SITE" | translate }}</a
    >
  </div>
</div>
