<footer
  class="profile-box info-box"
  fxLayout="column"
  fxLayoutAlign="start start"
>
  <span class="mat-title">{{ "COMMENTS.COMMENTS" | translate }}</span>
  <div
    *ngIf="commentsModel && commentsModel.comments"
    class="comment-count"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    {{ commentsModel.comments.length }}
    {{ "COMMENTS.COMMENTS" | translate | lowercase }}
    <button mat-icon-button (click)="onToggleComments()">
      <mat-icon *ngIf="commentsToggleClosed" class="collapse-icons"
        >keyboard_arrow_down</mat-icon
      >
      <mat-icon *ngIf="!commentsToggleClosed" class="collapse-icons"
        >keyboard_arrow_up</mat-icon
      >
    </button>
  </div>

  <div *ngIf="commentsModel && !commentsToggleClosed">
    <div
      fxLayout="row"
      fxFlexFill
      *ngFor="let comment of commentsModel.comments"
    >
      <div *ngIf="comment.uuid" class="comment">
        <img
          *ngIf="
            comment &&
            comment.employee_reference &&
            !comment.employee_reference.deleted_at
          "
          (error)="
            comment.employee_reference.avatar_thumb =
              '/assets/images/avatars/default-no-avatar.png'
          "
          [src]="comment.employee_reference.avatar_thumb"
          class="avatar"
        />
        <img
          *ngIf="
            !comment ||
            !comment.employee_reference ||
            comment.employee_reference.deleted_at
          "
          src="/assets/images/avatars/default-no-avatar.png"
          class="avatar"
        />

        <div fxLayout="column" fxFlex>
          <div fxLayout="row" fxLayoutAlign="start center">
            <a
              *ngIf="
                comment &&
                comment.employee_reference &&
                !comment.employee_reference.deleted_at
              "
              class="link username"
              [routerLink]="[
                '/' +
                  appRoutesService.employees +
                  '/' +
                  comment.employee_reference.slug +
                  '/' +
                  comment.employee_reference.uuid,
              ]"
              >{{ comment.employee_reference.first_name }}
              {{ comment.employee_reference.last_name }}</a
            >
            <div
              *ngIf="
                !comment ||
                !comment.employee_reference ||
                comment.employee_reference.deleted_at
              "
            >
              {{ comment.employee_reference.first_name }}
              {{ comment.employee_reference.last_name }}
            </div>
            <span class="time">&nbsp;{{ comment.comment_date }}</span>
          </div>
          <div>
            {{ comment.body }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="reply" fxLayout="row" fxFlexFill>
    <img
      *ngIf="
        employeeService && employeeService.me && employeeService.me.avatar_thumb
      "
      (error)="
        employeeService.me.avatar_thumb =
          '/assets/images/avatars/default-no-avatar.png'
      "
      src="{{ employeeService.me.avatar_thumb }}"
      class="avatar"
    />
    <!--<img *ngIf="employeeService && employeeService.me && !employeeService.me.avatar64" src="{{employeeService.me.avatar}}" class="avatar"/>-->
    <form [formGroup]="form" fxFlex>
      <textarea
        #commentInput
        formControlName="comment"
        [(ngModel)]="postCommentModel"
        placeholder="{{ 'COMMENTS.ADD_COMMENTS' | translate }}"
        required
      ></textarea>

      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          mat-raised-button
          color="accent"
          class="post-comment-button"
          [disabled]="form.invalid || loading || disabled"
          (click)="onPostComment()"
        >
          {{ "COMMENTS.ADD_COMMENTS_BUTTON_LABEL" | translate }}
        </button>
        <mat-error *ngIf="form.invalid && form.dirty" class="ml-16">
          {{
            "COMMENTS.COMMENTS_INVALID"
              | translate: { commentMaxChar: commentMaxChar }
          }}
        </mat-error>
      </div>
    </form>
  </div>
</footer>
