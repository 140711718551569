<div>
  <div class="border-bottom">
    <div
      *ngIf="!isLoadingResults && resultsLength === 0"
      fxLayout="row"
      fxLayoutAlign="center start"
      class="p-8 mat-subheading-2"
      style="margin-bottom: -3px"
    >
      {{ "CONGRESSES.NO_INVOICES" | translate }}
    </div>

    <div *ngIf="isLoadingResults" class="">
      <mat-progress-bar
        mode="query"
        color="warn"
        style="height: 3px"
      ></mat-progress-bar>
    </div>
  </div>

  <mat-table
    #table
    class=""
    matSort
    matSortStart="desc"
    *ngIf="dataSource && dataSource.data"
    [ngStyle]="{
      display:
        dataSource && dataSource.data && dataSource.data.length === 0
          ? 'none'
          : '',
    }"
    [@animateStagger]="{ value: '50' }"
    [dataSource]="dataSource"
  >
    <ng-container cdkColumnDef="name">
      <mat-header-cell fxFlex *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.CLIENT_NAME" | translate }}
      </mat-header-cell>

      <mat-cell fxFlex *cdkCellDef="let client">
        <p class="text-truncate font-weight-500">
          {{ client?.name }}
        </p>
      </mat-cell>
    </ng-container>

    <!-- <ng-container cdkColumnDef="open">
            <mat-header-cell fxFlex="90px" *cdkHeaderCellDef mat-sort-header>
                
            </mat-header-cell>
            <mat-cell fxFlex="90px" *cdkCellDef="let client">
                <p class="text-truncate font-weight-500">
                    <button mat-icon-button matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}" (click)="onOpenInNewTab(client)"
                        class="mat-icon-button mr-24">
                        <mat-icon class="s-16">{{ getIcon('NEW_TAB') }}</mat-icon>
                    </button>
                </p>
            </mat-cell>
        </ng-container> -->

    <ng-container cdkColumnDef="enterprise">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>
        {{ "GENERIC.ENTERPRISE" | translate }}
      </mat-header-cell>
      <mat-cell *cdkCellDef="let client">
        {{ client?.enterprise?.name }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="actions">
      <mat-header-cell fxFlex="292px" *cdkHeaderCellDef mat-sort-header>
      </mat-header-cell>
      <mat-cell fxFlex="292px" *cdkCellDef="let client">
        &nbsp;&nbsp;&nbsp;
        <!-- $event.stopPropagation() -->

        <div fxLayout="row" fxLayoutAlign="end center">
          <button
            mat-icon-button
            matTooltip="{{ 'GENERIC.OPEN_CLIENT' | translate }}"
            (click)="onNavigateToClient(client.uuid, client.slug)"
            class=""
          >
            <mat-icon
              class="s-20"
              fontSet="fa"
              fontIcon="{{ getIcon('CLIENT') }}"
            ></mat-icon>
          </button>
          <button
            *ngIf="client.enterprise"
            mat-icon-button
            matTooltip="{{ 'GENERIC.OPEN_ENTERPRISE' | translate }}"
            (click)="
              onNavigateToEnterprise(
                client.enterprise.uuid,
                client.enterprise.slug
              )
            "
            class=""
          >
            <mat-icon class="s-20">{{ getIcon("ENTERPRISE") }}</mat-icon>
          </button>

          <button
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasCTReport()
            "
            mat-icon-button
            matTooltip="{{ 'GENERIC.PRINT' | translate }}"
            [matMenuTriggerFor]="localMenuPrint"
            (click)="$event.stopPropagation()"
          >
            <mat-icon class="s-20">{{
              iconsService.getIcon("PRINT")
            }}</mat-icon>
          </button>

          <button
            *ngIf="modulesService.modulesAreSet && modulesService.hasCTReport()"
            mat-icon-button
            matTooltip="{{ 'GENERIC.PRINT' | translate }}"
            [matMenuTriggerFor]="localMenuPrintCT"
            (click)="$event.stopPropagation()"
          >
            <mat-icon class="s-20">{{
              iconsService.getIcon("PRINT")
            }}</mat-icon>
          </button>

          <mat-menu #localMenuPrint="matMenu">
            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_ORDER_STACK_LIST,
                  DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                  ExportFormatEnum.PDF_STACK,
                  ExportToEnum.PRINT,
                  LocaleEnum.FR_CA,
                  PaperEnum.A4,
                  {
                    uuid_client: client.uuid,
                    uuid_congress: congress.uuid,
                  },
                  null,
                  'GT_REPORT2'
                );
                $event.stopPropagation()
              "
            >
              {{ "GENERIC.fr-CA" | translate }}
            </button>
            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_ORDER_STACK_LIST,
                  DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                  ExportFormatEnum.PDF_STACK,
                  ExportToEnum.PRINT,
                  LocaleEnum.EN_CA,
                  PaperEnum.A4,
                  {
                    uuid_client: client.uuid,
                    uuid_congress: congress.uuid,
                  },
                  null,
                  'GT_REPORT2'
                );
                $event.stopPropagation()
              "
            >
              {{ "GENERIC.en-CA" | translate }}
            </button>
          </mat-menu>

          <mat-menu #localMenuPrintCT="matMenu">
            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_ORDER_CT_STACK_LIST,
                  DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                  ExportFormatEnum.PDF_STACK,
                  ExportToEnum.PRINT,
                  LocaleEnum.FR_CA,
                  PaperEnum.A4,
                  {
                    uuid_client: client.uuid,
                    uuid_congress: congress.uuid,
                  },
                  null,
                  'GT_REPORT2'
                );
                $event.stopPropagation()
              "
            >
              {{ "GENERIC.fr-CA" | translate }}
            </button>
            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_ORDER_CT_STACK_LIST,
                  DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                  ExportFormatEnum.PDF_STACK,
                  ExportToEnum.PRINT,
                  LocaleEnum.EN_CA,
                  PaperEnum.A4,
                  {
                    uuid_client: client.uuid,
                    uuid_congress: congress.uuid,
                  },
                  null,
                  'GT_REPORT2'
                );
                $event.stopPropagation()
              "
            >
              {{ "GENERIC.en-CA" | translate }}
            </button>
          </mat-menu>

          <!-- date_from: '2022-07-15',
                date_to: '2022-07-18' -->
          <button
            *ngIf="
              modulesService.modulesAreSet && !modulesService.hasCTReport()
            "
            mat-icon-button
            matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
            [matMenuTriggerFor]="localMenuDownload"
            (click)="$event.stopPropagation()"
          >
            <mat-icon class="s-20">{{ iconsService.getIcon("PDF") }}</mat-icon>
          </button>

          <button
            *ngIf="modulesService.modulesAreSet && modulesService.hasCTReport()"
            mat-icon-button
            matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
            [matMenuTriggerFor]="localMenuDownloadCT"
            (click)="$event.stopPropagation()"
          >
            <mat-icon class="s-20">{{ iconsService.getIcon("PDF") }}</mat-icon>
          </button>

          <mat-menu #localMenuDownload="matMenu">
            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_ORDER_STACK_LIST,
                  DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                  ExportFormatEnum.PDF_STACK,
                  ExportToEnum.DOWNLOAD,
                  LocaleEnum.FR_CA,
                  PaperEnum.A4,
                  {
                    uuid_client: client.uuid,
                    uuid_congress: congress.uuid,
                  },
                  null,
                  'GT_REPORT2'
                );
                $event.stopPropagation()
              "
            >
              {{ "GENERIC.fr-CA" | translate }}
            </button>

            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_ORDER_STACK_LIST,
                  DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                  ExportFormatEnum.PDF_STACK,
                  ExportToEnum.DOWNLOAD,
                  LocaleEnum.EN_CA,
                  PaperEnum.A4,
                  {
                    uuid_client: client.uuid,
                    uuid_congress: congress.uuid,
                  },
                  null,
                  'GT_REPORT2'
                );
                $event.stopPropagation()
              "
            >
              {{ "GENERIC.en-CA" | translate }}
            </button>
          </mat-menu>

          <mat-menu #localMenuDownloadCT="matMenu">
            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_ORDER_CT_STACK_LIST,
                  DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                  ExportFormatEnum.PDF_STACK,
                  ExportToEnum.DOWNLOAD,
                  LocaleEnum.FR_CA,
                  PaperEnum.A4,
                  {
                    uuid_client: client.uuid,
                    uuid_congress: congress.uuid,
                  },
                  null,
                  'GT_REPORT2'
                );
                $event.stopPropagation()
              "
            >
              {{ "GENERIC.fr-CA" | translate }}
            </button>

            <button
              mat-menu-item
              (click)="
                menuReportsService.buildAndRunVo(
                  ReportsEnum.REPORT_CLIENT_ORDER_CT_STACK_LIST,
                  DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                  ExportFormatEnum.PDF_STACK,
                  ExportToEnum.DOWNLOAD,
                  LocaleEnum.EN_CA,
                  PaperEnum.A4,
                  {
                    uuid_client: client.uuid,
                    uuid_congress: congress.uuid,
                  },
                  null,
                  'GT_REPORT2'
                );
                $event.stopPropagation()
              "
            >
              {{ "GENERIC.en-CA" | translate }}
            </button>
          </mat-menu>

          <button
            mat-icon-button
            matTooltip="{{ 'GENERIC.SEND_CONTRAT_BANQUET' | translate }}"
            (click)="onSendOrder(client.uuid, 'fr-CA')"
          >
            <mat-icon class="s-20">{{ getIcon("MAIL") }}</mat-icon>
          </button>

          <!--<mat-menu #localMenuSend="matMenu">
                    <button mat-menu-item (click)="onSendOrder(client.uuid, 'fr-CA')" 
                        class="">
                        {{ 'GENERIC.fr-CA' | translate }}
                    </button>

                    <button mat-menu-item (click)="onSendOrder(client.uuid, 'en-CA')" class="">
                        {{ 'GENERIC.en-CA' | translate }}
                    </button>
                </mat-menu>-->

          <!-- CONTRAT BANQUET SUMMARY OVERVIEW -->
          <app-report-button
            [vo]="
              reportFactoryService.getVO(
                'REPORT_CLIENT_ORDER_CT_STACK_LIST_SUMMARY_OVERVIEW',
                DistributionModeEnum.RAW_HTML,
                ExportFormatEnum.HTML,
                ExportToEnum.PREVIEW,
                {
                  uuid_client: client.uuid,
                  uuid_congress: congress.uuid,
                }
              )
            "
          >
          </app-report-button>

          <button
            mat-icon-button
            matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
            (click)="onOpenInNewTab(client); $event.stopPropagation()"
            class="mat-icon-button"
          >
            <mat-icon class="s-18">{{ getIcon("NEW_TAB") }}</mat-icon>
          </button>

          <!-- <button mat-icon-button
                    (click)="$event.stopPropagation()"
                     matTooltip="{{ 'GENERIC.SEND_CONTRAT_BANQUET' | translate }}" class="">
                    <mat-icon class="s-24 mr-4">{{ getIcon('MAIL') }}</mat-icon>
                </button> -->

          <!-- <app-report-button [vo]="reportFactoryService.getVO('REPORT_CLIENT_ORDER_CT_STACK_LIST', 
                                                                                DistributionModeEnum.RAW_HTML, 
                                                                                ExportFormatEnum.HTML, 
                                                                                ExportToEnum.PREVIEW, 
                                                                                {
                                                                                    uuid_client: client.uuid,
                                                                                    uuid_congress: congress.uuid
                                                                                })">
                </app-report-button> -->
        </div>

        <!-- <button tabindex="-1" [autofocus]="false" type="button" 
                     mat-icon-button class="">
                    <mat-icon class="" color="{{null}}">{{
                        iconsService.getIcon('PRINT') }}</mat-icon>
                </button>

                <button tabindex="-1" [autofocus]="false" type="button"
                      mat-icon-button class="" >
                    <mat-icon  class="" color="{{null}}">{{
                        iconsService.getIcon('PDF') }}</mat-icon>
                </button> -->

        <!-- <button tabindex="-1" [autofocus]="false" type="button" *ngIf="fileService.printEnabled() && vo.export_to === 'PRINT'"
                    matTooltip="{{ tooltipLabel | translate }}" #myTooltip="tooltip" mat-icon-button class="" (click)="onReport()">
                    <mat-icon  class="" color="{{n}}">{{
                        iconsService.getIcon('PRINT') }}</mat-icon>
                </button> -->

        <!-- <app-report-button [vo]="reportFactoryService.getVO('REPORT2_ADMIN_CHANGE_LOG', 
                                                                DistributionModeEnum.FILE,
                                                                ExportFormatEnum.PDF, 
                                                                ExportToEnum.PRINT,
                                                                dateRangeCongress)">
                </app-report-button>
                <app-report-button [vo]="reportFactoryService.getVO('REPORT2_ADMIN_CHANGE_LOG', 
                                                                DistributionModeEnum.FILE,
                                                                ExportFormatEnum.PDF, 
                                                                ExportToEnum.DOWNLOAD,
                                                                dateRangeCongress)">
                </app-report-button> -->

        <!-- <button class="" mat-icon-button (click)="onOpenPage(invoice)">
                    <mat-icon class="s-16 secondary-text">{{ getIcon('EDIT') }}</mat-icon>
                </button>
                <button mat-icon-button matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}" (click)="onOpenProjectInNewTab(invoice)"
                    class="mat-icon-button">
                    <mat-icon class="s-16">{{ getIcon('NEW_TAB') }}</mat-icon>
                </button> -->
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row
      *cdkRowDef="let clients; columns: displayedColumns"
      class=""
      matRipple
    >
    </mat-row>
  </mat-table>
  <!-- <mat-paginator #paginator [length]="paginator.length" (page)='syncPrimaryPaginator($event)'
        [ngStyle]="{'display': (dataSource && dataSource.data && dataSource.data.length === 0) ? 'none' : ''}"
        showFirstLastButtons="true" [pageIndex]="paginator.pageIndex" [pageSize]="paginator.pageSize"
        [pageSizeOptions]="paginator.pageSizeOptions">

    </mat-paginator> -->
  <mat-paginator
    #paginator
    [length]="resultsLength"
    [ngStyle]="{
      display:
        dataSource && dataSource.data && dataSource.data.length === 0
          ? 'none'
          : '',
    }"
    (page)="datatableHelperService.onPaginator($event, 'clients-invoices')"
    showFirstLastButtons="true"
    [pageIndex]="0"
    [pageSize]="'clients-invoices' | tableSizePipe: 'large'"
    [pageSizeOptions]="datatableHelperService.pageSizeShortOptions"
  >
  </mat-paginator>

  <div
    *ngIf="isLoadingResults && !isFirstLoad"
    fxLayout="row"
    fxLayoutAlign="center start"
    class=""
  >
    <mat-progress-bar
      mode="query"
      color="warn"
      style="height: 3px"
    ></mat-progress-bar>
  </div>
</div>
