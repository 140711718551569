import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { SearchResultsModelData } from '../../api/models/SearchResultsModel';
import { SearchService } from '../../api/search.service';
import { AppRoutes } from '../../const/AppRoutes';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { SearchResultsSharedDataService } from './search-results-shared-data.service';
import { SearchResultsTypeEnum } from './SearchResultsTypeEnum';
import { IconsService } from '../../services/icons.service';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  animations: fuseAnimations,
})
export class SearchResultsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  searchData?: SearchResultsModelData[];
  searchDataSubscription?: Subscription;
  searchString?: string;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public location: Location,
    private logger: NGXLogger,
    public dialog: MatDialog,
    public translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private searchService: SearchService,
    private titleService: Title,
    public iconService: IconsService,
    private searchResultsSharedDataService: SearchResultsSharedDataService
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {
    //this.titleService.setTitle(this.translate.instant("GENERIC.SEARCH"));
    this.searchDataSubscription =
      this.searchResultsSharedDataService.searchDataChange.subscribe((data) => {
        // //this.logger.info("SearchResultsComponent.ngOnInit() -> searchDataChange(): " + JSON.stringify(data));
        this.searchData = data.data;
        this.searchString = this.searchResultsSharedDataService.searchString;
      });

    // //this.logger.info("SearchResultsComponent.ngOnInit() -> " + this.searchResultsSharedDataService.searchData);
    if (this.searchResultsSharedDataService.searchData) {
      this.searchData = this.searchResultsSharedDataService.searchData.data;
    } else {
      this.searchData = [];
      this.route.queryParams.subscribe((params) => {
        this.searchString = params['keyword'];
        this.callSearch(this.searchString);
      });
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.searchDataSubscription) {
      this.searchDataSubscription.unsubscribe();
    }
  }

  public onSearchItem(item: SearchResultsModelData): void {
    // //this.logger.info("SearchResultsComponent.onSearchItem() -> " + JSON.stringify(item));
    this.router.navigateByUrl(this.getItemURL(item));
  }

  public getItemURL(item: SearchResultsModelData): string {
    // //this.logger.info("SearchResultsComponent.getItemURL() -> type: " + item.type);
    // //this.logger.info("SearchResultsComponent.getItemURL() -> subtype: " + item.subtype);
    switch (item.type) {
      case SearchResultsTypeEnum.PROJECT: {
        return '/' + AppRoutes.PROJECT + '/' + item.slug + '/' + item.uuid;
      }
      case SearchResultsTypeEnum.CONGRESS: {
        return '/' + AppRoutes.CONGRES + '/' + item.slug + '/' + item.uuid;
      }
      case SearchResultsTypeEnum.CLIENT: {
        return '/' + AppRoutes.CLIENTS + '/' + item.slug + '/' + item.uuid;
      }
      case SearchResultsTypeEnum.ENTERPRISE: {
        return '/' + AppRoutes.ENTERPRISES + '/' + item.slug + '/' + item.uuid;
      }
      case SearchResultsTypeEnum.USER: {
        return '';
      }
      case SearchResultsTypeEnum.EMPLOYEE: {
        return '/' + AppRoutes.EMPLOYEES + '/' + item.slug + '/' + item.uuid;
      }
      case SearchResultsTypeEnum.PLACE: {
        return '/' + AppRoutes.VENUES + '/' + item.slug + '/' + item.uuid;
      }
      case SearchResultsTypeEnum.ROOM: {
        return (
          '/' +
          AppRoutes.ROOMS +
          '/' +
          item.slug +
          '/' +
          item.uuid +
          '?loc=Places'
        );
      }
      case SearchResultsTypeEnum.PRODUCT: {
        return '/' + AppRoutes.PRODUCTS + '/' + item.slug + '/' + item.uuid;
      }
      case SearchResultsTypeEnum.MATERIAL: {
        return '/' + AppRoutes.MATERIALS + '/' + item.slug + '/' + item.uuid;
      }
      case SearchResultsTypeEnum.ORDER: {
        // //this.logger.info("SearchResultsComponent.getItemURL() -> IS ORDER!!! -> ");
        if (item.subtype === 'PROPOSAL') {
          // //this.logger.info("SearchResultsComponent.getItemURL() -> IS PROPOSAL!!! -> " + (AppRoutes.PROPOSITIONS + "/" + item.slug + "/" + item.uuid));
          return (
            '/' + AppRoutes.PROPOSITIONS + '/' + item.slug + '/' + item.uuid
          );
        } else if (item.subtype === 'INVOICE') {
          // //this.logger.info("SearchResultsComponent.getItemURL() -> IS INVOICE!!! -> " + (AppRoutes.INVOICES + "/" + item.slug + "/" + item.uuid));
          return '/' + AppRoutes.INVOICES + '/' + item.slug + '/' + item.uuid;
        }
        return '/' + AppRoutes.ORDERS + '/' + item.slug + '/' + item.uuid;
      }
      case SearchResultsTypeEnum.EVENT: {
        // //this.logger.info("SearchResultsComponent.getItemURL() -> IS EVENT!!! -> " + ("/" + AppRoutes.EVENTS + '/' + item.slug + "/" + item.uuid));
        return '/' + AppRoutes.EVENTS + '/' + item.slug + '/' + item.uuid;
      }
      default: {
        //this.logger.info("onSearchItem() -> Switch case default fot item " + item.label);
        return '';
      }
    }
  }

  public onOpenInNewTab(item: any): void {
    const newWindowURL: string = this.getItemURL(item);
    if (newWindowURL) {
      window.open(newWindowURL, '_blank');
    }
  }

  public callSearch(search: string): void {
    // //this.logger.info("SearchResultsComponent.callSearch() -> search: " + search);
    this.searchService.getSearchResults(search).subscribe(
      (response) => {
        // //this.logger.info("SearchResultsComponent.callSearch() -> SUCCESS: " + JSON.stringify(response));
        this.searchResultsSharedDataService.setSearchData(response, search);
      },
      (error) => {
        this.logger.error(
          'SearchResultsComponent.callSearch() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }
}
