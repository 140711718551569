import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Settings } from '../../../../settings/settings';
import { Question, UpsaleService } from '../../../../api/upsale.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
})
export class CreateQuestionComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  product: any;
  form: UntypedFormGroup;
  loading: boolean = false;
  question: Question | any;

  constructor(
    public override dialogRef: MatDialogRef<CreateQuestionComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    public location: Location,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public upsaleService: UpsaleService,
  ) {
    super();
    //
    if (data && data.product) {
      this.product = data.product;
      //this.logger.info("CreateQuestionComponent:: Product DATA => " + JSON.stringify(this.product));
    }
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.question = {
      uuid: null,
      name: null,
      step: 1,
    };
  }

  ngOnInit() {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onCreateQuestion() {
    //this.logger.info("CreateQuestionComponent.onCreateQuestion");
    this.loading = true;
    this.upsaleService.createQuestions(this.question).subscribe(
      (response: any) => {
        //this.logger.info(JSON.stringify(response.data));
        this.loading = false;
        this.dialogRef.close(true);
      },
      (error: any) => {
        this.logger.error(
          'CreateQuestionComponent.onCreateQuestion() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('ERROR ON CREATE QUESTION'),
        );
      },
    );
  }
}
