import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { RolesService } from '../../../../services/roles.service';
import { locale as english } from '../../i18n/en-CA';
import { locale as french } from '../../i18n/fr-CA';
import { CommonModule, Location } from '@angular/common';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { GT2FormPageAbstract } from '../../../../content/abstract/GT2FormPageAbstract';
import { ItemUrlFinder } from '../../../../utils/item-url-finder';
import { GenericAlertComponent } from '../../../../components/generic-alert/generic-alert.component';
import { CreateDepositInComponent } from '../../../../components/dialogs/create-deposit-in/create-deposit-in.component';
import { DepositInService } from '../../../../api/deposit-in.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-event-deposit-in',
  templateUrl: './event-deposit-in.component.html',
  styleUrls: ['./event-deposit-in.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    NgxPermissionsModule,
    MatButtonModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
  ],
  animations: fuseAnimations,
})
export class EventDepositInComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() event: any;
  @Input() editable: boolean = true;
  @Output() editableChange = new EventEmitter<boolean>();
  loading: boolean = false;
  editMode: boolean = false;
  declare dialogRef: any;
  confirmDialogRef: any;
  @Output() modelUpdatedEvent = new EventEmitter<any>();

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private toastr: ToastrService,
    public logger: NGXLogger,
    public location: Location,
    private dialog: MatDialog,
    public rolesService: RolesService,
    private depositInService: DepositInService,
    public router: Router,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public loadData(): void {}

  public onToggleEdit(): void {
    this.editMode = !this.editMode;
    // //this.logger.info("EventDepositInComponent.onEdit() -> this.editMode: " + this.editMode);
  }

  public onSaveData(): void {
    // //this.logger.info("EventDepositInComponent.onSaveData()");
  }

  public onManualSave(): void {
    //this.logger.info("EventDepositInComponent.onManualSave()");
  }

  public onModelLoaded(): void {
    // //this.logger.info("EventDepositInComponent.onModelLoaded()");
    this.loading = false;
  }

  public onDispatchModelUdpated(): void {
    // //this.logger.info("EventDepositInComponent.onDispatchModelUdpated()");
    this.modelUpdatedEvent.next(undefined);
  }

  public onEditItem(depositIn: any): void {
    // //this.logger.info("EventDepositRequestComponent.onEditItem() -> depositIn: " + JSON.stringify(depositIn) );
    // //this.logger.info("EventDepositRequestComponent.onEditItem() -> uiMode: " + CreateDepositInComponent.getDepositMode(this.event) );
    this.dialogRef = this.dialog.open(CreateDepositInComponent, {
      width: '520px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        autoRedirect: true,
        eventUUID: this.event.uuid,
        mode: CreateDepositInComponent.EDIT,
        uiMode: CreateDepositInComponent.getDepositEditMode(depositIn),
        event: this.event,
        depositInToUpdate: depositIn,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.loading = true;
      if (result) {
        // //this.logger.info("EventDeliveryRequestComponent.onAddNewDeliveryRequest() -> dialog closed: result: " + JSON.stringify(result));
        this.onDispatchModelUdpated();
      } else {
        this.loading = false;
      }
      this.dialogRef = null;
    });
  }

  public onDeleteItem(item: any): void {
    // //this.logger.info("EventDepositRequestComponent.onDeleteItem()");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.loading = true;
    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.depositInService
          .deleteDepositInRequest(item, this.event.uuid)
          .subscribe(
            (response) => {
              // //this.logger.info("EventDepositRequestComponent.onDeleteItem() -> SUCCESS: " + JSON.stringify(response));
              this.onDispatchModelUdpated();
              // this.loading = false;
            },
            (error) => {
              this.logger.error(
                'EventDepositRequestComponent.onDeleteItem() -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.loading = false;
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
              // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
            },
          );
      } else {
        this.loading = false;
      }
      this.confirmDialogRef = null;
    });
  }

  public onOpenPage(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public onOpenItemInNewTab(item: any): void {
    // //this.logger.info("EventDepositRequestComponent.onOpenItemInNewTab()");
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }
}
