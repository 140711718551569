<div fxLayout="column">
  <div matDialogTitle class="dialog-title mb-12">
    {{ title }}
  </div>

  <div
    *ngIf="type !== 'PRODUCT_CATEGORY' && type !== 'MATERIAL_CATEGORY'"
    fxLayout="column"
    fxLayoutAlign="center start"
    [formGroup]="form"
    class="item-full-width"
  >
    <mat-form-field
      *ngIf="type === 'PRICE'"
      fxFlex="100%"
      class="item-full-width"
    >
      <input
        matInput
        type="text"
        placeholder="{{ 'GENERIC.PRICE' | translate }}"
        formControlName="inputControl"
        autocomplete="nope"
        pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
        [(ngModel)]="actionValue"
        required
      />

      <mat-error *ngIf="form.controls['inputControl'].invalid">
        {{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field
      *ngIf="type === 'QTY'"
      fxFlex="100%"
      class="item-full-width"
    >
      <input
        matInput
        type="text"
        placeholder="{{ 'GENERIC.QUANTITY' | translate }}"
        formControlName="inputControl"
        autocomplete="nope"
        pattern="^-?([1-9]\d*)?$"
        [(ngModel)]="actionValue"
        required
      />
      <mat-error *ngIf="form.controls['inputControl'].invalid">
        {{ "GENERIC.INPUT_NUMBER_INTEGER_ONLY" | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div
    *ngIf="type === 'PRODUCT_CATEGORY'"
    fxLayout="column"
    fxLayoutAlign="center start"
    class="item-full-width"
  >
    <app-gt2-select
      [label]="'GENERIC.FOOD_DRINK_CATEGORY' | translate"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      [nullableUseNone]="false"
      [placeHolder]="'GENERIC.PRODUCT_CATEGORY_SELECT' | translate"
      (changeEvent)="onCategoryChange($event)"
      [service]="productCategoryService"
      class="item-full-width"
      fxFlex="100%"
    >
    </app-gt2-select>
  </div>

  <!-- <div *ngIf="type === 'MATERIAL_CATEGORY'" fxLayout="column" fxLayoutAlign="center start" class="item-full-width">
    <app-gt2-select [label]="'GENERIC.PRODUCT_CATEGORY' | translate"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')" [nullableUseNone]="false"
        [placeHolder]="'GENERIC.PRODUCT_CATEGORY_SELECT' | translate" (changeEvent)="onCategoryChange($event)"
        [service]="materialCategoryService" class="item-full-width" fxFlex="100%">
    </app-gt2-select>
</div> -->

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      *ngIf="type !== 'PRODUCT_CATEGORY' && type !== 'MATERIAL_CATEGORY'"
      mat-raised-button
      color="accent"
      class="mr-16"
      [disabled]="!form.valid"
      (click)="dialogRef.close(actionValue)"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button
      *ngIf="type === 'PRODUCT_CATEGORY' || type === 'MATERIAL_CATEGORY'"
      mat-raised-button
      color="accent"
      class="mr-16"
      [disabled]="actionValue === null"
      (click)="dialogRef.close(actionValue)"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
