<div style="max-height: 100%">
  <h1 matDialogTitle class="h1-title">{{ dialogTitle }}</h1>
  <div fxLayout="row" fxLayoutAlign="start end" class="p-8">
    <div [formGroup]="form" fxFlex="100" class="mr-16" fxLayout="column">
      <mat-form-field fxFlex="100" class="mr-4">
        <mat-label>{{ createPlaceholder }} (Français)</mat-label>
        <input
          matInput
          type="text"
          autocomplete="nope"
          [(ngModel)]="newCategory_fr_CA"
          formControlName="name_fr_CA"
        />
      </mat-form-field>

      <mat-form-field *ngIf="useEnglish" fxFlex="100" class="">
        <mat-label>{{ createPlaceholder }} (English)</mat-label>
        <input
          matInput
          type="text"
          autocomplete="nope"
          [(ngModel)]="newCategory_en_CA"
          formControlName="name_en_CA"
        />
      </mat-form-field>

      <mat-form-field>
        <!--<input matInput-->
        <!--[placeholder]="'SERVICE_CHARGE.LEDGER_ACCOUNT' | translate"-->
        <!--type="text"-->
        <!--[(ngModel)]="editSelected.account_number">-->

        <mat-label>{{
          "PRODUCTS_CATEGORIES.LEDGER_ACCOUNT" | translate
        }}</mat-label>
        <mat-select
          [(ngModel)]="ledger_account"
          formControlName="ledger_account"
        >
          <mat-option *ngFor="let item of ledgerAccounts" [value]="item">{{
            item.name_label
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!--<mat-form-field fxFlex="100" class="mr-4">-->
      <!--<input matInput-->
      <!--type="text"-->
      <!--placeholder="{{ 'LEDGER_ACCOUNT.ACCOUNT_NUMBER' | translate }}"-->
      <!--[(ngModel)]="accountNumber"-->
      <!--formControlName="account_number">-->
      <!--</mat-form-field>-->
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" class="gt2-buttons-theme">
    <button
      mat-raised-button
      color="accent"
      (click)="onCreateNew()"
      [disabled]="!form.valid || !ledger_account"
      class="mr-16"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
