<div style="max-height: 100%; overflow: auto">
  <h1 class="h1-title" matDialogTitle *ngIf="mode === 'CREATE'">
    {{ "CREATE_PROGRAM.TITLE" | translate }}
  </h1>
  <h1 class="h1-title" matDialogTitle *ngIf="mode === 'EDIT'">
    {{ "CREATE_PROGRAM.EDIT_TITLE" | translate }}
  </h1>

  <div fxLayout="column" fxLayoutAlign="center start">
    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      [formGroup]="form"
      (keyup.enter)="
        form.valid &&
        !loading &&
        program.time_start !== 'Invalid DateTime' &&
        program.time_end !== 'Invalid DateTime'
          ? onCreateprogram()
          : null
      "
      class="item-full-width"
    >
      <mat-form-field fxFlex="100%" class="item-full-width">
        <mat-label>{{ "GENERIC.DESCRIPTION" | translate }}</mat-label>
        <input
          matInput
          fxFlex="100%"
          placeholder="{{ 'GENERIC.DESCRIPTION' | translate }}"
          formControlName="description"
          autocomplete="nope"
          type="text"
          [(ngModel)]="program.description"
          required
        />
        <mat-error *ngIf="form.controls['description'].invalid">{{
          "GENERIC.INPUT_LENGTH_INVALID" | translate
        }}</mat-error>
      </mat-form-field>

      <div class="item-full-width mb-16">
        <mat-form-field class="item-full-width">
          <mat-label>{{ "GENERIC.MONTAGE" | translate }}</mat-label>
          <mat-select
            placeholder="{{ 'GENERIC.MONTAGE' | translate }}"
            [(ngModel)]="selectedProgram"
            formControlName="selectedProgram"
          >
            <mat-option *ngFor="let assembly of assemblies" [value]="assembly">
              <span class="iso">{{
                "CREATE_PROGRAM." + assembly | translate
              }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div *ngIf="!programs" fxLayout="row" fxLayoutAlign="center center" class="item-full-width mb-16">
            <mat-spinner [diameter]="20" class=""></mat-spinner>
        </div> -->

      <div fxLayout="row" fxLayoutAlign="start center" class="item-full-width">
        <mat-form-field fxFlex="50" class="mr-4">
          <mat-label>{{ "GENERIC.PRICE" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            placeholder="{{ 'GENERIC.PRICE' | translate }}"
            formControlName="price"
            autocomplete="nope"
            type="number"
            [(ngModel)]="program.price"
            required
          />
          <mat-error *ngIf="form.controls['price'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50" class="ml-4">
          <mat-label>{{ "GENERIC.GUEST_COUNT2" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            placeholder="{{ 'GENERIC.GUEST_COUNT2' | translate }}"
            formControlName="guest_count"
            autocomplete="nope"
            type="number"
            [(ngModel)]="program.guest_count"
            required
          />
          <mat-error *ngIf="form.controls['guest_count'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>
      </div>

      <mat-form-field fxFlex="100%" class="item-full-width">
        <mat-label>{{ "GENERIC.DATE_START" | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="date_start"
          [(ngModel)]="program.date_start"
          min="1900-01-01"
          placeholder="{{ 'GENERIC.DATE_START' | translate }}"
          (dateChange)="updateDateEnd($event)"
          formControlName="date_start"
          required
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="date_start"
        ></mat-datepicker-toggle>
        <mat-datepicker #date_start></mat-datepicker>
        <mat-error *ngIf="form.controls['date_start'].invalid"
          >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" class="item-full-width">
        <mat-label>{{ "GENERIC.DATE_END" | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="date_end"
          [(ngModel)]="program.date_end"
          min="1900-01-01"
          placeholder="{{ 'GENERIC.DATE_END' | translate }}"
          formControlName="date_end"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="date_end"
        ></mat-datepicker-toggle>
        <mat-datepicker #date_end></mat-datepicker>
        <mat-error *ngIf="form.controls['date_end'].invalid"
          >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100%" class="item-full-width note-section">
      <div class="item-full-width mb-12">
        <div class="caption">{{ "GENERIC.TIME_START" | translate }}</div>
        <ngx-timepicker-field
          [buttonAlign]="'left'"
          [format]="24"
          [(ngModel)]="program.time_start"
          class=""
        >
        </ngx-timepicker-field>
      </div>

      <div class="item-full-width mb-12">
        <div class="caption">{{ "GENERIC.TIME_END" | translate }}</div>
        <ngx-timepicker-field
          [buttonAlign]="'left'"
          [format]="24"
          [(ngModel)]="program.time_end"
          class=""
        >
        </ngx-timepicker-field>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      class="item-full-width mb-12"
    >
      <button
        mat-raised-button
        color="accent"
        class="mr-16"
        (click)="onSelectVenue()"
      >
        {{ "GENERIC.ROOMS" | translate }}
      </button>
      <div fxFlex *ngIf="program.room">
        {{ program.room.name }}
      </div>
      <div fxFlex *ngIf="!program.room">
        {{ "GENERIC.SELECT_ROOM" | translate }}
      </div>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="
        !form.valid ||
        program.room === null ||
        program.time_start === 'Invalid DateTime' ||
        !program.time_start ||
        program.time_end === 'Invalid DateTime' ||
        !program.time_end ||
        loading
      "
      color="accent"
      class="mr-16"
      (click)="onCreateprogram()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
