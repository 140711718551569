<div style="max-height: 100%; overflow: auto">
  <div fxLayout="column" (keyup.enter)="onOK()" class="custom-dialog-container">
    <div class="profile-box info-box" fxLayout="column">
      <header
        class="accent"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="modal-title">
          {{ "ASSIGNMENTS.JOB_EXPENSES" | translate }}
        </div>
        <div
          class="gt2-light-green"
          style="width: 60px; height: 56px"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <button
            type="button"
            matTooltip="{{ 'ASSIGNMENTS.ADD_EXPENSE' | translate }}"
            mat-icon-button
            class=""
            (click)="onAddExpense()"
          >
            <mat-icon class="" color="">add</mat-icon>
          </button>
        </div>
      </header>
    </div>

    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      name="expensesSpiner"
      size="medium"
      color="#90ee90"
      [fullScreen]="true"
      type="ball-scale-multiple"
    >
    </ngx-spinner>

    <div class="p-12">
      <div fxLayout="column" class="expenses-list" fusePerfectScrollbar>
        <div *ngFor="let jobExpense of jobExpenses" class="expense-item">
          <app-job-expenses-item
            [expense]="jobExpense"
            (saveExpense)="onSaveExpense($event)"
            (editExpense)="onEditExpense($event)"
            (deleteExpense)="onDeleteExpense($event)"
          >
          </app-job-expenses-item>
        </div>
        <div
          fxFlex
          fxLayout="row"
          fxLayoutAlign="center center"
          *ngIf="jobExpenses.length === 0"
          class="mat-h3"
        >
          {{ "ASSIGNMENTS.NO_EXPENSES" | translate }}
        </div>
      </div>

      <div
        mat-dialog-actions
        class="mt-8 p-8-buttons-theme"
        fxLayout="row"
        fxLayoutAlign="center start"
      >
        <button mat-raised-button color="primary" (click)="onOK()" class="">
          {{ "GENERIC.OK" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
