export class SearchResultsTypeEnum {
  static CLIENT: string = "Client";
  static ENTERPRISE: string = "Enterprise";
  static USER: string = "User";
  static EMPLOYEE: string = "Employee";
  static PLACE: string = "Place";
  static ROOM: string = "Room";
  static PRODUCT: string = "Product";
  static MATERIAL: string = "Equipment";
  static MATERIAL2: string = "Material";
  static ORDER: string = "Order";
  static EVENT: string = "Event";
  static PROJECT: string = "Project";
  static CATERER: string = "Caterer";
  static ORG: string = "Organization";
  static SHOP: string = "Shop";
  static CONGRESS: string = "Congress";
}
