import { Component, OnInit } from "@angular/core";
import { locale as english } from "./i18n/en-CA";
import { locale as french } from "./i18n/fr-CA";
import { FuseConfigService } from "../../../../@fuse/services/config.service";
import { FuseTranslationLoaderService } from "../../../../@fuse/services/translation-loader.service";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-browser-not-supported",
  templateUrl: "./browser-not-supported.component.html",
  styleUrls: ["./browser-not-supported.component.scss"],
})
export class BrowserNotSupportedComponent implements OnInit {
  constructor(private _fuseConfigService: FuseConfigService, private translationLoader: FuseTranslationLoaderService, private titleService: Title, public translate: TranslateService) {
    this.translationLoader.loadTranslations(english, french);

    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  ngOnInit() {
    //this.titleService.setTitle(this.translate.instant("NOT_SUPPORTED.NOT_SUPPORTED"));
  }

  public onDownloadChrome(): void {
    // TODO: add website url in environment files
    window.open("https://www.google.com/intl/fr_ALL/chrome/", "_blank");
  }

  public onVisitGt2(): void {
    // TODO: add website url in environment files
    window.open("https://www.gestiontraiteur.com", "_blank");
  }
}
