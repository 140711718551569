<div class="dialog-content">
  <h1 class="h1-title" matDialogTitle>
    {{ "CREATE_PLACE.TITLE" | translate }}
  </h1>

  <div
    fxLayout="column"
    fxLayoutAlign="center start"
    [formGroup]="form"
    (keyup.enter)="form.valid && !loading ? onCreatePlace() : null"
    class="mt-8 create-client-width"
  >
    <mat-form-field class="create-place-width">
      <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
      <input
        matInput
        fxFlex="100%"
        placeholder="{{ 'GENERIC.NAME' | translate }}"
        autocomplete="nope"
        formControlName="name"
        type="text"
        [(ngModel)]="place.name"
        required
      />
      <mat-error *ngIf="form.controls['name'].invalid">{{
        "GENERIC.INPUT_LENGTH_INVALID" | translate
      }}</mat-error>
    </mat-form-field>

    <!-- <ngxgeo-autocomplete [userSettings]="userSettings" (componentCallback)="handleAddressChange($event)" class="app-address"> </ngxgeo-autocomplete> -->

    <div
      [formGroup]="form"
      fxLayout="column"
      *ngIf="place.address"
      class="address-form-container"
      style="width: 100%"
    >
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="space-between start"
      >
        <mat-form-field
          class="address-number form-input-container"
          fxFlex="30%"
        >
          <mat-label>{{ "ADDRESS.ADDRESS_NUMBER" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'ADDRESS.ADDRESS_NUMBER' | translate }}"
            formControlName="street_number"
            type="text"
            [(ngModel)]="place.address.street_number"
            required
          />
        </mat-form-field>

        <mat-form-field
          class="address-street form-input-container"
          fxFlex="55%"
        >
          <mat-label>{{ "ADDRESS.STREET" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'ADDRESS.STREET' | translate }}"
            formControlName="street"
            type="text"
            [(ngModel)]="place.address.street"
            required
          />
          <mat-error *ngIf="form.controls['street'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="address-app form-input-container" fxFlex="15%">
          <mat-label>{{ "ADDRESS.APP_NUMBER" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'ADDRESS.APP_NUMBER' | translate }}"
            autocomplete="nope"
            formControlName="apartment"
            [(ngModel)]="place.address.apartment"
            type="text"
          />
          <mat-error *ngIf="form.controls['apartment'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutAlign="start start">
        <mat-form-field
          class="address-line2 form-input-container"
          fxFlex="100%"
        >
          <mat-label>{{ "ADDRESS.LINE_2" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'ADDRESS.LINE_2' | translate }}"
            formControlName="line2"
            [(ngModel)]="place.address.line2"
            type="text"
          />
          <mat-error *ngIf="form.controls['line2'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field class="address-city form-input-container" fxFlex="50%">
          <mat-label>{{ "ADDRESS.CITY" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'ADDRESS.CITY' | translate }}"
            formControlName="city"
            [(ngModel)]="place.address.city"
            type="text"
            required
          />
          <mat-error *ngIf="form.controls['city'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="address-state form-input-container" fxFlex="50%">
          <mat-label>{{ "ADDRESS.PROVINCE" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'ADDRESS.PROVINCE' | translate }}"
            formControlName="province"
            type="text"
            [(ngModel)]="place.address.province"
            required
          />
          <mat-error *ngIf="form.controls['province'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field
          class="address-country form-input-container"
          fxFlex="50%"
        >
          <mat-label>{{ "ADDRESS.COUNTRY" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'ADDRESS.COUNTRY' | translate }}"
            formControlName="country"
            type="text"
            [(ngModel)]="place.address.country"
            required
          />
          <mat-error *ngIf="form.controls['country'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field
          class="address-postal-code form-input-container"
          fxFlex="50%"
        >
          <mat-label>{{ "ADDRESS.POSTAL_CODE" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'ADDRESS.POSTAL_CODE' | translate }}"
            formControlName="postal_code"
            type="text"
            [(ngModel)]="place.address.postal_code"
            required
          />
          <mat-error *ngIf="form.controls['postal_code'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- THE CODE BELOW IS A SLIGHTLY MODIFIED VERSION OF THE COMPONENT SectorsSelectComponent -->
  <div *ngIf="sectors">
    <mat-form-field fxFlex="100%">
      <mat-label>{{ "GENERIC.CHOOSE_SECTOR" | translate }}</mat-label>
      <mat-select
        placeholder="{{ 'GENERIC.CHOOSE_SECTOR' | translate }}"
        (selectionChange)="onSectorChange($event)"
        [(ngModel)]="selectedSectorModel"
        [disabled]="sectors.length === 0"
      >
        <mat-option [value]="null">{{ "GENERIC.NONE" | translate }}</mat-option>
        <mat-option *ngFor="let sector of sectors" [value]="sector.uuid">{{
          sector.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="sectors.length === 0" class="red-fg mat-caption">
    {{ "GENERIC.NO_SECTORS" | translate }}
  </div>
  <div *ngIf="!sectors">
    <mat-spinner [diameter]="22" class=""></mat-spinner>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!form.valid || loading"
      color="accent"
      class="mr-16"
      (click)="onCreatePlace()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="compDialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
