<!-- <div id="management" class="page-layout simple fullwidth" fxLayout="column" fusePerfectScrollbar> -->
<div
  *ngIf="orgService.organization"
  class="page-layout simple inner-sidebar inner-scroll page-container"
  id="management"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent p-24 h-80 mat-elevation-z1 header-container"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <!--<div class="black-fg" fxLayout="row" fxLayoutAlign="start center">-->
        <!--<mat-icon class="secondary-text s-16">home</mat-icon>-->
        <!--<mat-icon class="secondary-text s-16">chevron_right</mat-icon>-->
        <!--<span class="secondary-text">{{ 'GENERIC.APPLICATION' | translate }}</span>-->
        <!--</div>-->
        <div class="h1">{{ "MANAGEMENT.TITLE" | translate }}</div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    class="primary mat-elevation-z1 header-container content-subheader"
    fusePerfectScrollbar
    fxLayout="row"
    fxLayoutAlign="space-between center"
  ></div>
  <!-- / SUB HEADER -->

  <div class="p-24 reports-container" fusePerfectScrollbar fxLayout="column">
    <!-- <mat-card  class="mb-16">

      <div [formGroup]="form" class="py-4" fxLayout="row" fxLayoutAlign="space-evenly center">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field class="custom-date-picker pl-12" fxLayout="row" fxLayoutAlign="start center">
            <input (ngModelChange)="onDateFromChange($event)" [(ngModel)]="dateRange.date_from" [matDatepicker]="eventDateFrom"
              class="" formControlName="date_from" matInput
              max="2099-01-01" min="1900-01-01" placeholder="{{ 'GENERIC.START' | translate }}">
            <mat-datepicker-toggle [for]="eventDateFrom" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #eventDateFrom></mat-datepicker>

            <mat-error *ngIf="form.controls['date_from'].invalid">{{
                      'GENERIC.DATE_DEFAULT_RANGE_INVALID' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field class="custom-date-picker pr-12" fxLayout="row" fxLayoutAlign="start center">
            <input (ngModelChange)="onDateToChange($event)" [(ngModel)]="dateRange.date_to" [matDatepicker]="eventDateTo"
              class="" formControlName="date_to" matInput max="2099-01-01"
              min="1900-01-01" placeholder="{{ 'GENERIC.END' | translate }}">
            <mat-datepicker-toggle [for]="eventDateTo" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #eventDateTo></mat-datepicker>

            <mat-error *ngIf="form.controls['date_to'].invalid">{{
                  'GENERIC.DATE_DEFAULT_RANGE_INVALID' | translate }}
            </mat-error>
          </mat-form-field>
          <button [matMenuTriggerFor]="menuFrom" class="" mat-icon-button>
            <mat-icon class="">more_vert</mat-icon>
          </button>
          <mat-menu #menuFrom="matMenu">
            <button (click)="onSelectToday()" mat-menu-item>{{ "GENERIC.TODAY" | translate }}</button>
            <button (click)="onSelectThisWeek()" mat-menu-item>{{ "GENERIC.WEEK" | translate }}</button>
            <button (click)="onSelectThisMonth()" mat-menu-item>{{ "GENERIC.MONTH" | translate }}</button>
          </mat-menu>
        </div>
      </div>
    </mat-card> -->
    <div
      class="management-content"
      fxFlex="100"
      fxLayout="column"
      fxLayoutAlign="start start"
    >
      <!-- ROW 1 -->
      <div fxLayout="row" fxLayoutAlign="start start" style="width: 100%">
        <div class="mr-8" fxFlex="50">
          <div class="profile-box info-box general" fxLayout="column">
            <div class="">
              <header class="accent">
                <div class="title p-16">
                  {{ "MANAGEMENT.PRODUCTION" | translate }}
                </div>
              </header>
            </div>
            <div fxLayout="column" class="header-content">
              <div
                [formGroup]="form"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'GENERIC.START' | translate }}"
                    formControlName="date_from_prod"
                    [matDatepicker]="eventDateFromProd"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="onDateFromChange($event, dateRangeProd)"
                    [(ngModel)]="dateRangeProd.date_from"
                    class=""
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="eventDateFromProd"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #eventDateFromProd></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_from_prod'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
                <div style="padding: 5px">&nbsp;</div>
                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'GENERIC.END' | translate }}"
                    formControlName="date_to_prod"
                    [matDatepicker]="eventDateToProd"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="onDateToChange($event, dateRangeProd)"
                    [(ngModel)]="dateRangeProd.date_to"
                    class=""
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="eventDateToProd"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #eventDateToProd></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_to_prod'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>

                <div>
                  <button
                    mat-icon-button
                    class=""
                    [matMenuTriggerFor]="menuFromProd"
                  >
                    <mat-icon class="">more</mat-icon>
                  </button>
                </div>
                <mat-menu #menuFromProd="matMenu">
                  <button mat-menu-item (click)="onSelectToday(dateRangeProd)">
                    {{ "GENERIC.TODAY" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onSelectThisWeek(dateRangeProd)"
                  >
                    {{ "GENERIC.WEEK" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onSelectThisMonth(dateRangeProd)"
                  >
                    {{ "GENERIC.MONTH" | translate }}
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="content" fxLayout="column">
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && !modulesService.hasJLReport()
                "
              >
                <div>{{ "MANAGEMENT.PRODUCTION_ZONE_LIST" | translate }}</div>
                <div>
                  <button
                    type="button"
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    mat-icon-button
                    class=""
                    [matMenuTriggerFor]="menuProdZoneList"
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>
                  <mat-menu #menuProdZoneList="matMenu">
                    <button
                      mat-menu-item
                      (click)="onPrintProductionZoneList('letter')"
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>
                    <button
                      mat-menu-item
                      (click)="onPrintProductionZoneList('legal')"
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <app-download-pdf
                    [dateRange]="dateRangeProd"
                    [urlSection]="'mngt/report-mngt-prod-list-by-prod-zone'"
                  >
                  </app-download-pdf>
                </div>
              </div>
              <!-- PRODUCTION ZONE LIST FOR ONLY JUL -->
              <div
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasJLReport()
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div>
                  {{ "MANAGEMENT.PRODUCTION_ZONE_LIST_JUL" | translate }}
                </div>
                <div>
                  <button
                    type="button"
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    mat-icon-button
                    class=""
                    [matMenuTriggerFor]="menuProdZoneJulList"
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>
                  <mat-menu #menuProdZoneJulList="matMenu">
                    <button
                      mat-menu-item
                      (click)="onPrintProductionZoneJulList('letter')"
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>
                    <button
                      mat-menu-item
                      (click)="onPrintProductionZoneJulList('legal')"
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <app-download-pdf
                    [dateRange]="dateRangeProd"
                    [urlSection]="'mngt/report-mngt-prod-list-by-prod-zone-jul'"
                  >
                  </app-download-pdf>
                </div>
              </div>

              <!-- *ngIf="modulesService.modulesAreSet && !modulesService.hasJLReport()" -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && !modulesService.hasJLReport()
                "
              >
                <div>
                  {{ "MANAGEMENT.PRODUCTION_ZONE_SIMPLIFIED_LIST" | translate }}
                </div>

                <div fxLayout="row">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    class=""
                    [matMenuTriggerFor]="menuPrintProdZoneSimplfied"
                  >
                    <mat-icon color="" class="">{{
                      getIcon("PRINT")
                    }}</mat-icon>
                  </button>
                  <mat-menu #menuPrintProdZoneSimplfied="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_SIMPLIFIED,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeProd
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_SIMPLIFIED,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeProd
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    class=""
                    [matMenuTriggerFor]="menuPdfProdZoneSimplfied"
                  >
                    <mat-icon color="" class="">{{ getIcon("PDF") }}</mat-icon>
                  </button>
                  <mat-menu #menuPdfProdZoneSimplfied="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_SIMPLIFIED,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeProd
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_SIMPLIFIED,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeProd
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_ALLERGIES -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && !modulesService.hasJLReport()
                "
              >
                <div>
                  {{ "MANAGEMENT.PRODUCTION_ZONE_ALLERGIES_LIST" | translate }}
                </div>

                <div fxLayout="row">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    class=""
                    [matMenuTriggerFor]="menuPrint"
                  >
                    <mat-icon color="" class="">{{
                      getIcon("PRINT")
                    }}</mat-icon>
                  </button>
                  <mat-menu #menuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_ALLERGIES,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeProd
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_ALLERGIES,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeProd
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    class=""
                    [matMenuTriggerFor]="menuPDF"
                  >
                    <mat-icon color="" class="">{{ getIcon("PDF") }}</mat-icon>
                  </button>
                  <mat-menu #menuPDF="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_ALLERGIES,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeProd
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_ALLERGIES,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeProd
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <ng-template #forecastProductionlist>
                <p>
                  {{
                    "MANAGEMENT.FORECAST_PRODUCTION_ZONE_LIST_HINT" | translate
                  }}
                </p>
              </ng-template>

              <!-- REPORT_MNGT_FORECAST_PRODUCTION_LIST_BY_PROD_ZONE (Liste de production par zone de production - Prévisionnel) -->

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>
                    {{ "MANAGEMENT.FORECAST_PRODUCTION_ZONE_LIST" | translate }}
                  </div>

                  <button
                    class=""
                    mat-icon-button
                    matTooltip="forecastProductionlist"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>
                <div fxLayout="row">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    class=""
                    [matMenuTriggerFor]="menuPrint"
                  >
                    <mat-icon color="" class="">{{
                      getIcon("PRINT")
                    }}</mat-icon>
                  </button>
                  <mat-menu #menuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_FORECAST_PRODUCTION_LIST_BY_PROD_ZONE,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeProd
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_FORECAST_PRODUCTION_LIST_BY_PROD_ZONE,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeProd
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    class=""
                    [matMenuTriggerFor]="menuPDF"
                  >
                    <mat-icon color="" class="">{{ getIcon("PDF") }}</mat-icon>
                  </button>
                  <mat-menu #menuPDF="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_FORECAST_PRODUCTION_LIST_BY_PROD_ZONE,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeProd
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_FORECAST_PRODUCTION_LIST_BY_PROD_ZONE,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeProd
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>{{ "MANAGEMENT.PRODUCT_LIST" | translate }}</div>
                <div>
                  <button
                    (click)="onPrintProductList()"
                    *ngIf="fileService.printEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    type="button"
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <button
                    (click)="onDownloadProductList()"
                    *ngIf="fileService.pdfEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    type="button"
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>
                </div>
              </div>

              <div
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCaCReport()
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div>
                  {{
                    "MANAGEMENT.REPORT2_MNGT_PROD_LIST_CAC_BY_ORDER_XLS"
                      | translate
                  }}
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT2_MNGT_PROD_LIST_CAC_BY_ORDER_XLS,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.XLSX,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LEGAL,
                        dateRangeProd
                      )
                    "
                  >
                    <mat-icon
                      class="fa-lg"
                      fontIcon="{{ iconsService.getIcon('XLS') }}"
                      fontSet="fa"
                    ></mat-icon>
                  </button>
                </div>
              </div>

              <div
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasJLReport()
                "
              >
                <!-- Étiquette plateaux et buffet -->
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>{{ "MANAGEMENT.BUFFET_LABEL_5963" | translate }}</div>
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <app-report-button
                      [vo]="
                        reportFactoryService.getVO(
                          'REPORT2_MGNT_PLATE_BUFFET_LABEL_5963',
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.PRINT,
                          dateRangeProd
                        )
                      "
                    >
                    </app-report-button>
                    <app-report-button
                      [vo]="
                        reportFactoryService.getVO(
                          'REPORT2_MGNT_PLATE_BUFFET_LABEL_5963',
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.DOWNLOAD,
                          dateRangeProd
                        )
                      "
                    >
                    </app-report-button>
                  </div>
                </div>

                <!-- Étiquette coffret -->
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>{{ "MANAGEMENT.BOX_LABEL_55164" | translate }}</div>
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <app-report-button
                      [vo]="
                        reportFactoryService.getVO(
                          'REPORT2_MNGT_BOX_LABEL_55164',
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.PRINT,
                          dateRangeProd
                        )
                      "
                    >
                    </app-report-button>
                    <app-report-button
                      [vo]="
                        reportFactoryService.getVO(
                          'REPORT2_MNGT_BOX_LABEL_55164',
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF,
                          ExportToEnum.DOWNLOAD,
                          dateRangeProd
                        )
                      "
                    >
                    </app-report-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mr-8" fxFlex="50">
          <!-- LOGISTIC -->
          <div class="profile-box info-box general" fxLayout="column">
            <div class="">
              <header class="accent">
                <div class="title p-16">
                  {{ "MANAGEMENT.LOGISTIC" | translate }}
                </div>
              </header>
            </div>
            <div fxLayout="column" class="header-content">
              <div
                [formGroup]="form"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'GENERIC.START' | translate }}"
                    formControlName="date_from_logistic"
                    [matDatepicker]="eventDateFromLogistic"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="
                      onDateFromChange($event, dateRangeLogistic)
                    "
                    [(ngModel)]="dateRangeLogistic.date_from"
                    class=""
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="eventDateFromLogistic"
                  >
                  </mat-datepicker-toggle>
                  <mat-datepicker #eventDateFromLogistic></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_from_logistic'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
                <div style="padding: 5px">&nbsp;</div>
                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'GENERIC.END' | translate }}"
                    formControlName="date_to_logistic"
                    [matDatepicker]="eventDateToLogistic"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="onDateToChange($event, dateRangeLogistic)"
                    [(ngModel)]="dateRangeLogistic.date_to"
                    class=""
                  />
                  <mat-datepicker-toggle matSuffix [for]="eventDateToLogistic">
                  </mat-datepicker-toggle>
                  <mat-datepicker #eventDateToLogistic></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_to_logistic'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>

                <div>
                  <button
                    mat-icon-button
                    class=""
                    [matMenuTriggerFor]="menuFromLogistic"
                  >
                    <mat-icon class="">more</mat-icon>
                  </button>
                </div>
                <mat-menu #menuFromLogistic="matMenu">
                  <button
                    mat-menu-item
                    (click)="onSelectToday(dateRangeLogistic)"
                  >
                    {{ "GENERIC.TODAY" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onSelectThisWeek(dateRangeLogistic)"
                  >
                    {{ "GENERIC.WEEK" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onSelectThisMonth(dateRangeLogistic)"
                  >
                    {{ "GENERIC.MONTH" | translate }}
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="content" fxLayout="column">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>{{ "MANAGEMENT.DELIVERY_A" | translate }}</div>
                <div>
                  <button
                    (click)="onDownloadDeliveryA_XLS()"
                    *ngIf="fileService.excelEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                    type="button"
                  >
                    <mat-icon
                      class="fa-lg"
                      fontIcon="{{ iconsService.getIcon('XLS') }}"
                      fontSet="fa"
                    >
                    </mat-icon>
                  </button>
                </div>
              </div>

              <div
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasJLReport()
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div>Liste des livraisons - JUL</div>
                <div>
                  <button
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT2_MNGT_DELIVERY_A_JUL_XLSX,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.XLSX,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LETTER,
                        dateRangeLogistic
                      )
                    "
                    *ngIf="fileService.excelEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                    type="button"
                  >
                    <mat-icon
                      class="fa-lg"
                      fontIcon="{{ iconsService.getIcon('XLS') }}"
                      fontSet="fa"
                    >
                    </mat-icon>
                  </button>
                </div>
              </div>

              <!--<div fxLayout="row" fxLayoutAlign="space-between center">
                                            <div>{{ "MANAGEMENT.DELIVERY_B" | translate }}</div>
                                            <div>
                                                <button type="button" matTooltip="{{ 'GENERIC.PRINT' | translate }}" mat-icon-button class=""
                                                (click)="onPrintDeliveryB()">
                                                <mat-icon class="" color="">{{ iconsService.getIcon('PRINT') }}</mat-icon>
                                                </button>
                    
                                                <button type="button" matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}" mat-icon-button class=""
                                                (click)="onDownloadDeliveryB()">
                                                <mat-icon class="" color="">{{ iconsService.getIcon('PDF') }}</mat-icon>
                                                </button>
                    
                                            </div>
                                            </div>-->

              <div
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasJRReport()
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div>{{ "MANAGEMENT.DELIVERY_TAKEOUT_LIST" | translate }}</div>

                <app-report-button
                  [vo]="
                    reportFactoryService.getVO(
                      'REPORT2_MNGT_DELIVERY_TAKEOUT_JR_XLSX',
                      DistributionModeEnum.FILE,
                      ExportFormatEnum.XLSX,
                      ExportToEnum.DOWNLOAD,
                      dateRangeLogistic
                    )
                  "
                >
                </app-report-button>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>{{ "MANAGEMENT.TAKEOUT" | translate }}</div>
                <div>
                  <button
                    (click)="onDownloadTakeoutList()"
                    *ngIf="fileService.excelEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                    type="button"
                  >
                    <mat-icon
                      class="fa-lg"
                      fontIcon="{{ iconsService.getIcon('XLS') }}"
                      fontSet="fa"
                    >
                    </mat-icon>
                  </button>
                </div>
              </div>

              <div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>{{ "MANAGEMENT.EVENT_LIST" | translate }}</div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <button
                      mat-icon-button
                      *ngIf="fileService.printEnabled()"
                      [matMenuTriggerFor]="jrEventListMenuPrint"
                    >
                      <mat-icon class="">{{
                        iconsService.getIcon("PRINT")
                      }}</mat-icon>
                    </button>

                    <mat-menu #jrEventListMenuPrint="matMenu">
                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_EVENT_LIST,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF,
                            ExportToEnum.PRINT,
                            LocaleEnum.FR_CA,
                            PaperEnum.LETTER,
                            dateRangeLogistic
                          )
                        "
                      >
                        {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                      </button>

                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_EVENT_LIST,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF,
                            ExportToEnum.PRINT,
                            LocaleEnum.FR_CA,
                            PaperEnum.LEGAL,
                            dateRangeLogistic,
                            'invoice'
                          )
                        "
                      >
                        {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                      </button>
                    </mat-menu>

                    <button
                      mat-icon-button
                      *ngIf="fileService.printEnabled()"
                      [matMenuTriggerFor]="jrEventListMenuDownload"
                    >
                      <mat-icon class="">{{
                        iconsService.getIcon("PDF")
                      }}</mat-icon>
                    </button>

                    <mat-menu #jrEventListMenuDownload="matMenu">
                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_EVENT_LIST,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF,
                            ExportToEnum.DOWNLOAD,
                            LocaleEnum.FR_CA,
                            PaperEnum.LETTER,
                            dateRangeLogistic
                          )
                        "
                      >
                        {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                      </button>

                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_EVENT_LIST,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF,
                            ExportToEnum.DOWNLOAD,
                            LocaleEnum.FR_CA,
                            PaperEnum.LEGAL,
                            dateRangeLogistic,
                            'invoice'
                          )
                        "
                      >
                        {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasVicReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>{{ "MANAGEMENT.EVENT_LIST_VIC" | translate }}</div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <button
                      mat-icon-button
                      *ngIf="fileService.printEnabled()"
                      [matMenuTriggerFor]="jrEventListMenuPrint"
                    >
                      <mat-icon class="">{{
                        iconsService.getIcon("PRINT")
                      }}</mat-icon>
                    </button>

                    <mat-menu #jrEventListMenuPrint="matMenu">
                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_EVENT_LIST_VIC,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF,
                            ExportToEnum.PRINT,
                            LocaleEnum.FR_CA,
                            PaperEnum.LETTER,
                            dateRangeLogistic
                          )
                        "
                      >
                        {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                      </button>

                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_EVENT_LIST_VIC,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF,
                            ExportToEnum.PRINT,
                            LocaleEnum.FR_CA,
                            PaperEnum.LEGAL,
                            dateRangeLogistic,
                            'invoice'
                          )
                        "
                      >
                        {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                      </button>
                    </mat-menu>

                    <button
                      mat-icon-button
                      *ngIf="fileService.printEnabled()"
                      [matMenuTriggerFor]="jrEventListMenuDownload"
                    >
                      <mat-icon class="">{{
                        iconsService.getIcon("PDF")
                      }}</mat-icon>
                    </button>

                    <mat-menu #jrEventListMenuDownload="matMenu">
                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_EVENT_LIST_VIC,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF,
                            ExportToEnum.DOWNLOAD,
                            LocaleEnum.FR_CA,
                            PaperEnum.LETTER,
                            dateRangeLogistic
                          )
                        "
                      >
                        {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                      </button>

                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_EVENT_LIST_VIC,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF,
                            ExportToEnum.DOWNLOAD,
                            LocaleEnum.FR_CA,
                            PaperEnum.LEGAL,
                            dateRangeLogistic,
                            'invoice'
                          )
                        "
                      >
                        {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>

              <!--  REPORT2_MNGT_EVENT_LIST_UQAM  -->

              <div
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasUQAMReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>{{ "MANAGEMENT.EVENT_LIST_UQAM" | translate }}</div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <button
                      mat-icon-button
                      *ngIf="fileService.printEnabled()"
                      [matMenuTriggerFor]="UqamEventListMenuPrint"
                    >
                      <mat-icon class="">{{
                        iconsService.getIcon("PRINT")
                      }}</mat-icon>
                    </button>

                    <mat-menu #UqamEventListMenuPrint="matMenu">
                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_EVENT_LIST_UQAM,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF,
                            ExportToEnum.PRINT,
                            LocaleEnum.FR_CA,
                            PaperEnum.LETTER,
                            dateRangeLogistic
                          )
                        "
                      >
                        {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                      </button>

                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_EVENT_LIST_UQAM,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF,
                            ExportToEnum.PRINT,
                            LocaleEnum.FR_CA,
                            PaperEnum.LEGAL,
                            dateRangeLogistic,
                            'invoice'
                          )
                        "
                      >
                        {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                      </button>
                    </mat-menu>

                    <button
                      mat-icon-button
                      *ngIf="fileService.printEnabled()"
                      [matMenuTriggerFor]="UqamEventListMenuDownload"
                    >
                      <mat-icon class="">{{
                        iconsService.getIcon("PDF")
                      }}</mat-icon>
                    </button>

                    <mat-menu #UqamEventListMenuDownload="matMenu">
                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_EVENT_LIST_UQAM,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF,
                            ExportToEnum.DOWNLOAD,
                            LocaleEnum.FR_CA,
                            PaperEnum.LETTER,
                            dateRangeLogistic
                          )
                        "
                      >
                        {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                      </button>

                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_EVENT_LIST_UQAM,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF,
                            ExportToEnum.DOWNLOAD,
                            LocaleEnum.FR_CA,
                            PaperEnum.LEGAL,
                            dateRangeLogistic,
                            'invoice'
                          )
                        "
                      >
                        {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>{{ "MANAGEMENT.MATERIAL_LIST" | translate }}</div>
                <div>
                  <button
                    (click)="onPrintMaterialList()"
                    *ngIf="fileService.printEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    type="button"
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <button
                    (click)="onDownloadMaterialList()"
                    *ngIf="fileService.pdfEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    type="button"
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ROW 2 -->
      <div fxLayout="row" fxLayoutAlign="start start" style="width: 100%">
        <div class="mr-8" fxFlex="50">
          <!-- EVENT -->
          <div class="profile-box info-box general" fxLayout="column">
            <div class="">
              <header class="accent">
                <div class="title p-16">{{ "GENERIC.EVENT" | translate }}</div>
              </header>
            </div>
            <div fxLayout="column" class="header-content">
              <div
                [formGroup]="form"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'GENERIC.START' | translate }}"
                    formControlName="date_from_event"
                    [matDatepicker]="eventDateFromEvent"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="onDateFromChange($event, dateRangeEvent)"
                    [(ngModel)]="dateRangeEvent.date_from"
                    class=""
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="eventDateFromEvent"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #eventDateFromEvent></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_from_event'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
                <div style="padding: 5px">&nbsp;</div>
                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'GENERIC.END' | translate }}"
                    formControlName="date_to_event"
                    [matDatepicker]="eventDateToEvent"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="onDateToChange($event, dateRangeEvent)"
                    [(ngModel)]="dateRangeEvent.date_to"
                    class=""
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="eventDateToEvent"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #eventDateToEvent></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_to_event'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>

                <div>
                  <button
                    mat-icon-button
                    class=""
                    [matMenuTriggerFor]="menuFromEvent"
                  >
                    <mat-icon class="">more</mat-icon>
                  </button>
                </div>
                <mat-menu #menuFromEvent="matMenu">
                  <button mat-menu-item (click)="onSelectToday(dateRangeEvent)">
                    {{ "GENERIC.TODAY" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onSelectThisWeek(dateRangeEvent)"
                  >
                    {{ "GENERIC.WEEK" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onSelectThisMonth(dateRangeEvent)"
                  >
                    {{ "GENERIC.MONTH" | translate }}
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="content" fxLayout="column">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>{{ "MANAGEMENT.EVENT_BY_CONTACT" | translate }}</div>
                <div>
                  <button
                    (click)="onPrintEventByContact()"
                    *ngIf="fileService.printEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    type="button"
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <button
                    (click)="onDownloadEventByContact()"
                    *ngIf="fileService.pdfEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    type="button"
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <!-- <div>{{ "MANAGEMENT.INCOMING_EVENT" | translate }}</div> -->
                <div>{{ "MANAGEMENT.NEW_CLIENT" | translate }}</div>
                <div>
                  <button
                    (click)="onPrintIncomingEvent()"
                    *ngIf="fileService.printEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    type="button"
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <button
                    (click)="onDownloadIncomingEvent()"
                    *ngIf="fileService.pdfEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    type="button"
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>
                </div>
              </div>

              <!-- is NOT Capital Traiteur -->
              <div
                *ngIf="
                  modulesService.modulesAreSet && !modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>{{ "MANAGEMENT.EVENT_BY_STATUS" | translate }}</div>
                  <div>
                    <button
                      (click)="onPrintActivityReport()"
                      *ngIf="fileService.printEnabled()"
                      class=""
                      mat-icon-button
                      matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                      type="button"
                    >
                      <mat-icon class="" color="">{{
                        iconsService.getIcon("PRINT")
                      }}</mat-icon>
                    </button>

                    <button
                      (click)="onDownloadActivityReport()"
                      *ngIf="fileService.pdfEnabled()"
                      class=""
                      mat-icon-button
                      matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                      type="button"
                    >
                      <mat-icon class="" color="">{{
                        iconsService.getIcon("PDF")
                      }}</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <!-- IS Capital Traiteur -->
              <div
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div
                  *ngxPermissionsOnly="rolesService.OWNER_ROLES"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                >
                  <div>{{ "MANAGEMENT.EVENT_BY_STATUS" | translate }}</div>
                  <div>
                    <button
                      (click)="onPrintActivityReport()"
                      *ngIf="fileService.printEnabled()"
                      class=""
                      mat-icon-button
                      matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                      type="button"
                    >
                      <mat-icon class="" color="">{{
                        iconsService.getIcon("PRINT")
                      }}</mat-icon>
                    </button>

                    <button
                      (click)="onDownloadActivityReport()"
                      *ngIf="fileService.pdfEnabled()"
                      class=""
                      mat-icon-button
                      matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                      type="button"
                    >
                      <mat-icon class="" color="">{{
                        iconsService.getIcon("PDF")
                      }}</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <!-- REPORT_MNGT_EVENT_FINANCIAL_DATA -->
              <!-- is NOT Capital Traiteur -->
              <div
                *ngIf="
                  modulesService.modulesAreSet && !modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>
                    {{
                      "MANAGEMENT.REPORT_MNGT_EVENT_FINANCIAL_DATA" | translate
                    }}
                  </div>
                  <div>
                    <button
                      mat-icon-button
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_EVENT_FINANCIAL_DATA,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeEvent,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      <mat-icon class="" color="">{{
                        iconsService.getIcon("PRINT")
                      }}</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_EVENT_FINANCIAL_DATA,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeEvent,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      <mat-icon class="" color="">{{
                        iconsService.getIcon("PDF")
                      }}</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <!-- IS Capital Traiteur -->
              <div
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div
                  *ngxPermissionsOnly="rolesService.OWNER_ROLES"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                >
                  <div>
                    {{
                      "MANAGEMENT.REPORT_MNGT_EVENT_FINANCIAL_DATA" | translate
                    }}
                  </div>
                  <div>
                    <button
                      mat-icon-button
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_EVENT_FINANCIAL_DATA,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeEvent,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      <mat-icon class="" color="">{{
                        iconsService.getIcon("PRINT")
                      }}</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_EVENT_FINANCIAL_DATA,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeEvent,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      <mat-icon class="" color="">{{
                        iconsService.getIcon("PDF")
                      }}</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <!-- REPORT_MNGT_EVENT_FINANCIAL_DATA -->
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  {{
                    "MANAGEMENT.REPORT2_ADMIN_ACCOUNTING_LIST_OF_ORDERS_CREATION_DATE_XLSX"
                      | translate
                  }}
                </div>
                <div>
                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT2_ADMIN_ACCOUNTING_LIST_OF_ORDERS_CREATION_DATE_XLSX,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.XLSX,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LEGAL,
                        dateRangeEvent
                      )
                    "
                  >
                    <mat-icon
                      class="fa-lg"
                      fontIcon="{{ iconsService.getIcon('XLS') }}"
                      fontSet="fa"
                    ></mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mr-8" fxFlex="50">
          <!-- Commande & Bon de commande -->
          <!-- *ngIf="modulesService.modulesAreSet && modulesService.hasJLReport()" -->
          <div class="profile-box info-box general" fxLayout="column">
            <div class="">
              <header class="accent">
                <div class="title p-16">
                  {{ "MANAGEMENT.ORDER_PURCHASE_ORDER" | translate }}
                </div>
              </header>
            </div>

            <div fxLayout="column" class="header-content">
              <div
                [formGroup]="form"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'GENERIC.START' | translate }}"
                    formControlName="date_from_orders_day"
                    [matDatepicker]="dateFromOrdersDay"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="
                      onDateFromChange($event, dateRangeOrdersDay)
                    "
                    [(ngModel)]="dateRangeOrdersDay.date_from"
                    class=""
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateFromOrdersDay"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateFromOrdersDay></mat-datepicker>

                  <mat-error
                    *ngIf="form.controls['date_from_orders_day'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="content" fxLayout="column">
              <!-- REPORT2_MNGT_ORDERS_BY_DAY_JUL -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasJLReport()
                "
              >
                <div>{{ "GENERIC.ORDERS" | translate }} - JUL</div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY_JUL,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY_JUL,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY_JUL,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY_JUL,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY_JUL,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY_JUL,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT2_MNGT_ORDERS_BY_DAY_UQAM -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasUQAMReport()
                "
              >
                <div>{{ "GENERIC.ORDERS" | translate }} - UQAM</div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY_UQAM,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY_UQAM,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY_UQAM,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY_UQAM,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY_UQAM,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY_UQAM,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY_JUL -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasJLReport()
                "
              >
                <div>
                  {{
                    "MANAGEMENT.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY"
                      | translate
                  }}
                  - JUL
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="purchaseOrdersDayMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #purchaseOrdersDayMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY_JUL,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY_JUL,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="purchaseOrdersDayMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #purchaseOrdersDayMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY_JUL,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY_JUL,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="purchaseOrdersDayMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #purchaseOrdersDayMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY_JUL,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY_JUL,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT2_MNGT_ORDERS_BY_DAY -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet &&
                  !modulesService.hasLoLReport() &&
                  !modulesService.hasCTReport() &&
                  !modulesService.hasJLReport() &&
                  !modulesService.hasUQAMReport()
                "
              >
                <div>{{ "GENERIC.ORDERS" | translate }}</div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasLoLReport()
                "
              >
                <div>!LOL!{{ "GENERIC.ORDERS" | translate }}</div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_CONTRACTS_BY_DAY_LOL,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_CONTRACTS_BY_DAY_LOL,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_CONTRACTS_BY_DAY_LOL,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDERS_BY_DAY,
                          DistributionModeEnum.S3_STORAGE_USER_DOCUMENT,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_CONTRACTS_BY_DAY_LOL,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_CONTRACTS_BY_DAY_LOL,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet &&
                  !modulesService.hasCTReport() &&
                  !modulesService.hasJLReport()
                "
              >
                <div>
                  {{
                    "MANAGEMENT.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY"
                      | translate
                  }}
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="purchaseOrdersDayMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #purchaseOrdersDayMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="purchaseOrdersDayMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #purchaseOrdersDayMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="purchaseOrdersDayMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #purchaseOrdersDayMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT2_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_BY_DAY_ST_GAB -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasGABReport()
                "
              >
                <div>
                  {{
                    "MANAGEMENT.REPORT2_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_BY_DAY_ST_GAB"
                      | translate
                  }}
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="purchaseOrdersDayMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #purchaseOrdersDayMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_BY_DAY_ST_GAB,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_BY_DAY_ST_GAB,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="purchaseOrdersDayMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #purchaseOrdersDayMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_BY_DAY_ST_GAB,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_BY_DAY_ST_GAB,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="purchaseOrdersDayMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #purchaseOrdersDayMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_BY_DAY_ST_GAB,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_BY_DAY_ST_GAB,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT_MNGT_ORDER_CT_STACK_LIST_PER_DAY -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>{{ "GENERIC.CONTRAT_BANQUET" | translate }}</div>
                  <button
                    class=""
                    mat-icon-button
                    (click)="onShowReportsInfosCTM()"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersContratBanquetDayMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersContratBanquetDayMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_CT_STACK_LIST_PER_DAY,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_CT_STACK_LIST_PER_DAY,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersContratBanquetDayMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersContratBanquetDayMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_CT_STACK_LIST_PER_DAY,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_CT_STACK_LIST_PER_DAY,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="
                      ordersContratBanquetDayMenuSendWithMail
                    "
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersContratBanquetDayMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_CT_STACK_LIST_PER_DAY,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_CT_STACK_LIST_PER_DAY,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT_STACK -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>
                    {{ "MANAGEMENT.PURCHASE_ORDER_SERVICE" | translate }}
                  </div>
                  <button
                    class=""
                    mat-icon-button
                    (click)="onShowReportsInfosCTM()"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayPastryMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayPastryMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayPastryMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayPastryMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersServiceDayMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersServiceDayMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_CT_STACK_LIST_PER_DAY,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay,
                          null,
                          'GT_REPORT2'
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_NEW_EVT_STACK -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>{{ "MANAGEMENT.PURCHASE_ORDER_CHEF" | translate }}</div>
                  <button
                    class=""
                    mat-icon-button
                    (click)="onShowReportsInfosCTM()"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>

                <!-- Ce rapport inclus tous les bon de commandes incluant une note dans le champs allergies et restrictions alimentaires -->

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayChefMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayChefMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayChefMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayChefMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayChefMenuSendMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayChefMenuSendMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_STACK -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>{{ "MANAGEMENT.PURCHASE_ORDER_HOT" | translate }}</div>
                  <button
                    class=""
                    mat-icon-button
                    (click)="onShowReportsInfosCTM()"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>

                <!-- Ce rapport inclus tous les bon de commandes incluant une note dans le champs allergies et restrictions alimentaires -->

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayHotMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayHotMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayHotMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayHotMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayHotMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayHotMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_STACK -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>{{ "MANAGEMENT.PURCHASE_ORDER_COLD" | translate }}</div>
                  <button
                    class=""
                    mat-icon-button
                    (click)="onShowReportsInfosCTM()"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>

                <!-- Ce rapport inclus tous les bon de commandes incluant une note dans le champs allergies et restrictions alimentaires -->

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayColdMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayColdMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayColdMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayColdMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayColdMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayColdMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>
                    {{ "MANAGEMENT.PURCHASE_ORDER_HOT_AND_COLD" | translate }}
                  </div>
                  <button
                    class=""
                    mat-icon-button
                    (click)="onShowReportsInfosCTM()"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>

                <!-- Ce rapport inclus tous les bon de commandes incluant une note dans le champs allergies et restrictions alimentaires -->

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayHotColdMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayHotColdMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayHotColdMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayHotColdMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayHotColdMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayHotColdMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_STACK -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>
                    {{ "MANAGEMENT.PURCHASE_ORDER_PASTRY" | translate }}
                  </div>
                  <button
                    class=""
                    mat-icon-button
                    (click)="onShowReportsInfosCTM()"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayPastryMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayPastryMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayPastryMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayPastryMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayPastryMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayPastryMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT_CLIENT_INVOICE_CT_STACK -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>{{ "MANAGEMENT.INVOICE" | translate }}</div>
                  <!-- <button class="" mat-icon-button  content-type="template" type="button">
                                                                                                                                                                                    <mat-icon class="s-16" color="">help</mat-icon>
                                                                                                                                                                                </button> -->
                </div>

                <!-- Ce rapport inclus tous les bon de commandes incluant une note dans le champs allergies et restrictions alimentaires -->

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayInvoiceMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayInvoiceMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_CLIENT_INVOICE_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_CLIENT_INVOICE_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayInvoiceMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayInvoiceMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_CLIENT_INVOICE_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_CLIENT_INVOICE_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayInvoiceMenuSendWIthMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayInvoiceMenuSendWIthMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_CLIENT_INVOICE_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_CLIENT_INVOICE_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT_CLIENT_INVOICE_UQAM_STACK -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasUQAMReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>{{ "MANAGEMENT.INVOICE_UQAM" | translate }}</div>
                  <!-- <button class="" mat-icon-button  content-type="template" type="button">
                                                                                                                                                                                    <mat-icon class="s-16" color="">help</mat-icon>                                                                                                                                                 </button> -->
                </div>

                <!-- Ce rapport inclus tous les bon de commandes incluant une note dans le champs allergies et restrictions alimentaires -->

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayInvoiceMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayInvoiceMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_CLIENT_INVOICE_UQAM_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_CLIENT_INVOICE_UQAM_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayInvoiceMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayInvoiceMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_CLIENT_INVOICE_UQAM_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_CLIENT_INVOICE_UQAM_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayInvoiceMenuSendWIthMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayInvoiceMenuSendWIthMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_CLIENT_INVOICE_UQAM_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_CLIENT_INVOICE_UQAM_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF_STANDALONE_STACK -->
              <div
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasDANReport()
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div>
                  {{
                    "MANAGEMENT.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF_STANDALONE_STACK"
                      | translate
                  }}
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="
                      purchaseOrdersStaffKitchenStackDayMenuPrint
                    "
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu
                    #purchaseOrdersStaffKitchenStackDayMenuPrint="matMenu"
                  >
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF_STANDALONE_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF_STANDALONE_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="
                      purchaseOrdersStaffKitchenStackDayMenuDownload
                    "
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu
                    #purchaseOrdersStaffKitchenStackDayMenuDownload="matMenu"
                  >
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF_STANDALONE_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF_STANDALONE_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <ng-template #purchaseOrderProdAllergies>
                <p>
                  {{
                    "MANAGEMENT.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY_HINT"
                      | translate
                  }}
                </p>
              </ng-template>

              <!-- REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet &&
                  !modulesService.hasCTReport() &&
                  !modulesService.hasUQAMReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>
                    {{
                      "MANAGEMENT.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY"
                        | translate
                    }}
                  </div>
                  <button
                    class=""
                    mat-icon-button
                    matTooltip="purchaseOrderProdAllergies"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>

                <!-- Ce rapport inclus tous les bon de commandes incluant une note dans le champs allergies et restrictions alimentaires -->

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayALMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayALMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayALMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayALMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_WARMBUFFET_NO_PRICE -->
              <div
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasJLReport()
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div>
                  {{
                    "MANAGEMENT.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_WARMBUFFET_NO_PRICE"
                      | translate
                  }}
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuBuffetPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuBuffetPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_WARMBUFFET_NO_PRICE,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_WARMBUFFET_NO_PRICE,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuBuffetDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuBuffetDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_WARMBUFFET_NO_PRICE,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_WARMBUFFET_NO_PRICE,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE -->
              <div
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasJLReport()
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div>
                  {{
                    "MANAGEMENT.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE"
                      | translate
                  }}
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuBoxPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuBoxPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayMenuBoxDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayMenuBoxDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeOrdersDay
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- JUUL ONLY -->
              <!-- REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE_AL -->
              <div>
                <div
                  *ngIf="
                    modulesService.modulesAreSet && modulesService.hasJLReport()
                  "
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                >
                  <div>
                    {{
                      "MANAGEMENT.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE_AL"
                        | translate
                    }}
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <button
                      mat-icon-button
                      *ngIf="fileService.printEnabled()"
                      matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                      [matMenuTriggerFor]="ordersDayMenuBuffetPrint"
                    >
                      <mat-icon class="">{{
                        iconsService.getIcon("PRINT")
                      }}</mat-icon>
                    </button>

                    <mat-menu #ordersDayMenuBuffetPrint="matMenu">
                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE_AL,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF_STACK,
                            ExportToEnum.PRINT,
                            LocaleEnum.FR_CA,
                            PaperEnum.LETTER,
                            dateRangeOrdersDay
                          )
                        "
                      >
                        {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                      </button>

                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE_AL,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF_STACK,
                            ExportToEnum.PRINT,
                            LocaleEnum.FR_CA,
                            PaperEnum.LEGAL,
                            dateRangeOrdersDay
                          )
                        "
                      >
                        {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                      </button>
                    </mat-menu>

                    <button
                      mat-icon-button
                      *ngIf="fileService.printEnabled()"
                      matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                      [matMenuTriggerFor]="ordersDayMenuBuffetDownload"
                    >
                      <mat-icon class="">{{
                        iconsService.getIcon("PDF")
                      }}</mat-icon>
                    </button>

                    <mat-menu #ordersDayMenuBuffetDownload="matMenu">
                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE_AL,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF_STACK,
                            ExportToEnum.DOWNLOAD,
                            LocaleEnum.FR_CA,
                            PaperEnum.LETTER,
                            dateRangeOrdersDay
                          )
                        "
                      >
                        {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                      </button>

                      <button
                        mat-menu-item
                        (click)="
                          menuReportsService.buildAndRunVo(
                            ReportsEnum.REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE_AL,
                            DistributionModeEnum.FILE,
                            ExportFormatEnum.PDF_STACK,
                            ExportToEnum.DOWNLOAD,
                            LocaleEnum.FR_CA,
                            PaperEnum.LEGAL,
                            dateRangeOrdersDay
                          )
                        "
                      >
                        {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  modulesService.modulesAreSet &&
                  (modulesService.hasJRReport() ||
                    modulesService.hasDANReport())
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div>
                  {{
                    "MANAGEMENT.REPORT2_MNGT_PROD_LIST_BY_ORDER_XLS" | translate
                  }}
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <!-- <button (click)="onDownloadDeliveryA_XLS()" *ngIf="fileService.excelEnabled()" class="" mat-icon-button
                                        matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}" type="button">
                                        <mat-icon class="fa-lg" fontIcon="{{ iconsService.getIcon('XLS') }}" fontSet="fa">
                                        </mat-icon>
                                    </button> -->

                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT2_MNGT_PROD_LIST_BY_ORDER_XLS,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.XLSX,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LEGAL,
                        dateRangeOrdersDay
                      )
                    "
                  >
                    <mat-icon
                      class="fa-lg"
                      fontIcon="{{ iconsService.getIcon('XLS') }}"
                      fontSet="fa"
                    ></mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" style="width: 100%">
        <div class="mr-8" fxFlex="50">
          <!-- Commande & Bon de commande -->
          <!-- *ngIf="modulesService.modulesAreSet && modulesService.hasJLReport()" -->
          <div class="profile-box info-box general" fxLayout="column">
            <div class="">
              <header class="accent">
                <div class="title p-16">
                  {{ "MANAGEMENT.EMPLOYEE" | translate }}
                </div>
              </header>
            </div>

            <div fxLayout="column" class="header-content">
              <div
                [formGroup]="form"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'GENERIC.START' | translate }}"
                    formControlName="date_from_employee"
                    [matDatepicker]="dateFromEmployee"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="
                      onDateFromChange($event, dateRangeEmployee)
                    "
                    [(ngModel)]="dateRangeEmployee.date_from"
                    class=""
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateFromEmployee"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateFromEmployee></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_from_employee'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
                <div style="padding: 5px">&nbsp;</div>
                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'GENERIC.END' | translate }}"
                    formControlName="date_to_employee"
                    [matDatepicker]="dateToEmployee"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="onDateToChange($event, dateRangeEmployee)"
                    [(ngModel)]="dateRangeEmployee.date_to"
                    class=""
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateToEmployee"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateToEmployee></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_to_employee'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>

                <div>
                  <button
                    mat-icon-button
                    class=""
                    [matMenuTriggerFor]="menuFromEmployee"
                  >
                    <mat-icon class="">more</mat-icon>
                  </button>
                </div>
                <mat-menu #menuFromEmployee="matMenu">
                  <button
                    mat-menu-item
                    (click)="onSelectToday(dateRangeEmployee)"
                  >
                    {{ "GENERIC.TODAY" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onSelectThisWeek(dateRangeEmployee)"
                  >
                    {{ "GENERIC.WEEK" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onSelectThisMonth(dateRangeEmployee)"
                  >
                    {{ "GENERIC.MONTH" | translate }}
                  </button>
                </mat-menu>
              </div>
            </div>

            <div class="content" fxLayout="column">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>{{ "MANAGEMENT.LOGIN_HISTORY" | translate }}</div>
                <div>
                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT2_ADMIN_USERS_LOGIN_ACTIVITIES_XLSX,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.XLSX,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LEGAL,
                        dateRangeEmployee
                      )
                    "
                  >
                    <mat-icon
                      class="fa-lg"
                      fontIcon="{{ iconsService.getIcon('XLS') }}"
                      fontSet="fa"
                    ></mat-icon>
                  </button>
                </div>
              </div>

              <!---- REPORT2_ADMIN_ORDERS_BILLED_STAFF_XLSX -->
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>{{ "MANAGEMENT.ORDERS_BILLED_STAFF" | translate }}</div>
                <div>
                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                    (click)="
                      menuReportsService.buildAndRunVo(
                        ReportsEnum.REPORT2_ADMIN_ORDERS_BILLED_STAFF_XLSX,
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.XLSX,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LEGAL,
                        dateRangeEmployee
                      )
                    "
                  >
                    <mat-icon
                      class="fa-lg"
                      fontIcon="{{ iconsService.getIcon('XLS') }}"
                      fontSet="fa"
                    ></mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mr-8"
          fxFlex="50"
          *ngIf="modulesService.modulesAreSet && modulesService.hasCTReport()"
        >
          <div class="profile-box info-box general" fxLayout="column">
            <div class="">
              <header class="accent">
                <div class="title p-16">
                  {{ "MANAGEMENT.CONGRESS" | translate }}
                </div>
              </header>
            </div>

            <div fxLayout="column" class="header-content">
              <div
                [formGroup]="form"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'GENERIC.START' | translate }}"
                    formControlName="date_from_congress"
                    [matDatepicker]="dateFromCongress"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="
                      onDateFromChange($event, dateRangeCongress)
                    "
                    [(ngModel)]="dateRangeCongress.date_from"
                    class=""
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateFromCongress"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateFromCongress></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_from_congress'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="custom-date-picker">
                  <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'GENERIC.END' | translate }}"
                    formControlName="date_to_congress"
                    [matDatepicker]="dateToCongress"
                    min="1900-01-01"
                    max="2099-01-01"
                    (ngModelChange)="onDateToChange($event, dateRangeCongress)"
                    [(ngModel)]="dateRangeCongress.date_to"
                    class=""
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateToCongress"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateToCongress></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_to_congress'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>

                <div>
                  <button
                    mat-icon-button
                    class=""
                    [matMenuTriggerFor]="menuFromCongress"
                  >
                    <mat-icon class="">more</mat-icon>
                  </button>
                </div>
                <mat-menu #menuFromCongress="matMenu">
                  <button
                    mat-menu-item
                    (click)="onSelectToday(dateRangeCongress)"
                  >
                    {{ "GENERIC.TODAY" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onSelectThisWeek(dateRangeCongress)"
                  >
                    {{ "GENERIC.WEEK" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onSelectThisMonth(dateRangeCongress)"
                  >
                    {{ "GENERIC.MONTH" | translate }}
                  </button>
                </mat-menu>
              </div>

              <!-- Events Range Times START -->
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div
                  class="ngx-timepicker-field-custom"
                  fxLayout="column"
                  fxFlex="50%"
                >
                  <div class="custom-label">
                    {{ "GENERIC.START" | translate }}
                  </div>
                  <div
                    fxLayout="row"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                  >
                    <ngx-timepicker-field
                      fxFlex
                      #ngxTimeStart
                      [buttonAlign]="'left'"
                      [format]="24"
                      [(ngModel)]="dateRangeCongress.time_from"
                      (ngModelChange)="
                        onTimeFromChange($event, dateRangeCongress)
                      "
                      class=""
                    >
                    </ngx-timepicker-field>
                    <button
                      mat-button
                      [disabled]="!dateRangeCongress.time_from"
                      mat-icon-button
                      aria-label="Clear"
                      (click)="dateRangeCongress.time_from = ''"
                    >
                      <mat-icon class="s-16">close</mat-icon>
                    </button>
                  </div>
                </div>
                <div
                  class="ngx-timepicker-field-custom"
                  fxLayout="column"
                  fxFlex="50%"
                >
                  <div class="custom-label">
                    {{ "GENERIC.END" | translate }}
                  </div>
                  <div
                    fxLayout="row"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                  >
                    <ngx-timepicker-field
                      fxFlex
                      #ngxTimeStart
                      [buttonAlign]="'left'"
                      [format]="24"
                      [(ngModel)]="dateRangeCongress.time_to"
                      (ngModelChange)="
                        onTimeToChange($event, dateRangeCongress)
                      "
                      class=""
                    >
                    </ngx-timepicker-field>
                    <button
                      mat-button
                      [disabled]="!dateRangeCongress.time_to"
                      mat-icon-button
                      aria-label="Clear"
                      (click)="dateRangeCongress.time_to = ''"
                    >
                      <mat-icon class="s-16">close</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Events Range Times END -->

              <!-- Events Range dates START-->
              <!--
                            <div [formGroup]="form" fxLayout="row" fxLayoutAlign="space-between center">
                                <mat-form-field class="custom-date-picker">
                                    <input matInput placeholder="{{ 'MANAGEMENT.EVENT_DATE_START' | translate }}"
                                        formControlName="date_from_event_changelog"
                                        [matDatepicker]="dateFromEventChangelog" min="1900-01-01" max="2099-01-01"
                                        (ngModelChange)="onEventDateFromChange($event, dateRangeCongress)"
                                        [(ngModel)]="dateRangeCongress.event_date_from" class="">
                                    <mat-datepicker-toggle matSuffix
                                        [for]="dateFromEventChangelog"></mat-datepicker-toggle>
                                    <mat-datepicker #dateFromEventChangelog></mat-datepicker>

                                    <mat-error *ngIf="form.controls['date_from_event_changelog'].invalid">{{
                                        'GENERIC.DATE_DEFAULT_RANGE_INVALID' | translate }}
                                    </mat-error>
                                </mat-form-field>


                                <mat-form-field class="custom-date-picker">
                                    <input matInput placeholder="{{ 'MANAGEMENT.EVENT_DATE_END' | translate }}"
                                        formControlName="date_to_event_changelog" [matDatepicker]="dateToEventChangelog"
                                        min="1900-01-01" max="2099-01-01"
                                        (ngModelChange)="onEventDateToChange($event, dateRangeCongress)"
                                        [(ngModel)]="dateRangeCongress.event_date_to" class="">
                                    <mat-datepicker-toggle matSuffix
                                        [for]="dateToEventChangelog"></mat-datepicker-toggle>
                                    <mat-datepicker #dateToEventChangelog></mat-datepicker>

                                    <mat-error *ngIf="form.controls['date_to_event_changelog'].invalid">{{
                                        'GENERIC.DATE_DEFAULT_RANGE_INVALID' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <div>
                                    <button mat-icon-button class="" [matMenuTriggerFor]="menuEventFromCongress">
                                        <mat-icon class="">more</mat-icon>
                                    </button>
                                </div>
                                <mat-menu #menuEventFromCongress="matMenu">
                                    <button mat-menu-item (click)="onSelectTodayEvent(dateRangeCongress)">{{ "GENERIC.TODAY"
                                        |
                                        translate }}</button>
                                    <button mat-menu-item (click)="onSelectThisWeekEvent(dateRangeCongress)">{{
                                        "GENERIC.WEEK" |
                                        translate }}</button>
                                    <button mat-menu-item (click)="onSelectThisEvent(dateRangeCongress)">{{
                                        "GENERIC.MONTH" |
                                        translate }}</button>
                                </mat-menu>


                            </div>
                            -->
              <!-- Events Range dates END-->
            </div>

            <div class="content" fxLayout="column">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>{{ "MANAGEMENT.CHANGE_LOG" | translate }}</div>
                <div fxLayout="row" fxLayoutAlign="center center">
                  <app-report-button
                    [vo]="
                      reportFactoryService.getVO(
                        'REPORT2_ADMIN_CUSTOM_CHANGE_LOG',
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.PRINT,
                        dateRangeCongress
                      )
                    "
                  >
                  </app-report-button>
                  <app-report-button
                    [vo]="
                      reportFactoryService.getVO(
                        'REPORT2_ADMIN_CUSTOM_CHANGE_LOG',
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.DOWNLOAD,
                        dateRangeCongress
                      )
                    "
                  >
                  </app-report-button>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>{{ "MANAGEMENT.NEW_EVENTS_CTM_REPORT" | translate }}</div>

                <div fxLayout="row" fxLayoutAlign="center center">
                  <button
                    (click)="
                      menuReportsService.buildAndRunVo(
                        'REPORT2_MNGT_EVENT_LIST_CTM',
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.PRINT,
                        LocaleEnum.FR_CA,
                        PaperEnum.LETTER,
                        dateRangeCongress
                      )
                    "
                    *ngIf="fileService.printEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    type="button"
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <button
                    (click)="
                      menuReportsService.buildAndRunVo(
                        'REPORT2_MNGT_EVENT_LIST_CTM',
                        DistributionModeEnum.FILE,
                        ExportFormatEnum.PDF,
                        ExportToEnum.DOWNLOAD,
                        LocaleEnum.FR_CA,
                        PaperEnum.LETTER,
                        dateRangeCongress
                      )
                    "
                    *ngIf="fileService.pdfEnabled()"
                    class=""
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    type="button"
                  >
                    <mat-icon class="" color="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>
                </div>
              </div>

              <!-- Bon commande Chef | Nouveau EVT - MC -->
              <!-- REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_NEW_EVT_STACK -- New Evts -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>
                    {{ "MANAGEMENT.PURCHASE_ORDER_CHEF_NEW_EVT" | translate }}
                  </div>
                  <button
                    class=""
                    mat-icon-button
                    (click)="onShowReportsInfosCTM()"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>

                <!-- Ce rapport inclus tous les bon de commandes incluant une note dans le champs allergies et restrictions alimentaires -->

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayChefMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayChefMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayChefMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayChefMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayChefMenuSendMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayChefMenuSendMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_NEW_EVT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_NEW_EVT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- Bon commande Chaud | Nouveau EVT - MC -->
              <!-- REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_NEW_EVT_STACK -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>
                    {{ "MANAGEMENT.PURCHASE_ORDER_HOT_NEW_EVT" | translate }}
                  </div>
                  <button
                    class=""
                    mat-icon-button
                    (click)="onShowReportsInfosCTM()"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>

                <!-- Ce rapport inclus tous les bon de commandes incluant une note dans le champs allergies et restrictions alimentaires -->

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayHotMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayHotMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayHotMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayHotMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayHotMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayHotMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_NEW_EVT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_NEW_EVT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>
              <!-- Bon commande Froid | Nouveau EVT - MC -->
              <!-- REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_NEW_EVT_STACK -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>
                    {{ "MANAGEMENT.PURCHASE_ORDER_COLD_NEW_EVT" | translate }}
                  </div>
                  <button
                    class=""
                    mat-icon-button
                    (click)="onShowReportsInfosCTM()"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>

                <!-- Ce rapport inclus tous les bon de commandes incluant une note dans le champs allergies et restrictions alimentaires -->

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayColdMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayColdMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayColdMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayColdMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayColdMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayColdMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_NEW_EVT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_NEW_EVT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- Bon commande Chaud/Froid | Nouveau EVT - MC -->
              <!-- REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_NEW_EVT_STACK -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>
                    {{
                      "MANAGEMENT.PURCHASE_ORDER_HOT_AND_COLD_NEW_EVT"
                        | translate
                    }}
                  </div>
                  <button
                    class=""
                    mat-icon-button
                    (click)="onShowReportsInfosCTM()"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>

                <!-- Ce rapport inclus tous les bon de commandes incluant une note dans le champs allergies et restrictions alimentaires -->

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayHotColdMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayHotColdMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayHotColdMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayHotColdMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayHotColdMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayHotColdMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!-- Bon commande Pâtisserie | Nouveau EVT - MC -->
              <!-- REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_NEW_EVT_STACK -->
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngIf="
                  modulesService.modulesAreSet && modulesService.hasCTReport()
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div>
                    {{ "MANAGEMENT.PURCHASE_ORDER_PASTRY_NEW_EVT" | translate }}
                  </div>
                  <button
                    class=""
                    mat-icon-button
                    (click)="onShowReportsInfosCTM()"
                    content-type="template"
                    type="button"
                  >
                    <mat-icon class="s-16" color="">help</mat-icon>
                  </button>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center">
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.PRINT' | translate }}"
                    [matMenuTriggerFor]="ordersDayPastryMenuPrint"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PRINT")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayPastryMenuPrint="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.PRINT,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
                    [matMenuTriggerFor]="ordersDayPastryMenuDownload"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("PDF")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayPastryMenuDownload="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_NEW_EVT_STACK,
                          DistributionModeEnum.FILE,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.DOWNLOAD,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                  <button
                    mat-icon-button
                    *ngIf="fileService.printEnabled()"
                    matTooltip="{{ 'GENERIC.SEND_PDF_WITH_EMAIL' | translate }}"
                    [matMenuTriggerFor]="ordersDayPastryMenuSendWithMail"
                  >
                    <mat-icon class="">{{
                      iconsService.getIcon("EMAIL")
                    }}</mat-icon>
                  </button>

                  <mat-menu #ordersDayPastryMenuSendWithMail="matMenu">
                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_NEW_EVT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LETTER,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
                    </button>

                    <button
                      mat-menu-item
                      (click)="
                        menuReportsService.buildAndRunVo(
                          ReportsEnum.REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_NEW_EVT_STACK,
                          DistributionModeEnum.MAIL_ME,
                          ExportFormatEnum.PDF_STACK,
                          ExportToEnum.NONE,
                          LocaleEnum.FR_CA,
                          PaperEnum.LEGAL,
                          dateRangeCongress
                        )
                      "
                    >
                      {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </div>

              <!--
                             <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div>{{ "MANAGEMENT.CHANGE_LOG_DATE_RANGE" | translate }}</div>
                                <div fxLayout="row" fxLayoutAlign="center center">
                                    <app-report-button [vo]="reportFactoryService.getVO('REPORT2_ADMIN_CHANGE_LOG_BY_EVENT_DATE', 
                                                                                        DistributionModeEnum.FILE,
                                                                                        ExportFormatEnum.PDF, 
                                                                                        ExportToEnum.PRINT,
                                                                                        dateRangeCongress)">
                                    </app-report-button>
                                    <app-report-button [vo]="reportFactoryService.getVO('REPORT2_ADMIN_CHANGE_LOG_BY_EVENT_DATE', 
                                                                                        DistributionModeEnum.FILE,
                                                                                        ExportFormatEnum.PDF, 
                                                                                        ExportToEnum.DOWNLOAD,
                                                                                        dateRangeCongress)">
                                    </app-report-button>
                                </div>

                            </div>
                            -->
            </div>
          </div>
        </div>
      </div>

      <!-- Commande & bons de commandes | journee -->
      <!-- <div class="mr-8" fxFlex="50">
                <div class="profile-box info-box general" fxLayout="column">
                    <div class="">
                        <header class="accent">
                            <div class="title p-16">{{ "MANAGEMENT.ORDERS_PURCHASE_ORDERS_DAY)" | translate }}</div>
                        </header>
                    </div>
            
                    <div fxLayout="column" style="border-bottom: #CCCCCC 1px solid; background-color: white">
            
                        <div [formGroup]="form" fxLayout="row" fxLayoutAlign="space-between center">
                            <mat-form-field class="custom-date-picker">
                                <input matInput placeholder="{{ 'GENERIC.START' | translate }}" formControlName="date_from_congress"
                                    [matDatepicker]="dateFromOrderAndPurchaseOrder" min="1900-01-01" max="2099-01-01"
                                    (ngModelChange)="onDateFromChange($event, dateRangeOrderAndPurchaseOrder)"
                                    [(ngModel)]="dateRangeOrderAndPurchaseOrder.date_from" class="">
                                <mat-datepicker-toggle matSuffix [for]="dateFromOrderAndPurchaseOrder"></mat-datepicker-toggle>
                                <mat-datepicker #dateFromOrderAndPurchaseOrder></mat-datepicker>
            
                                <mat-error *ngIf="form.controls['date_to_orderAndPurchaseOrder'].invalid">{{
                                    'GENERIC.DATE_DEFAULT_RANGE_INVALID' | translate }}
                                </mat-error>
                            </mat-form-field>
        
                        </div>
            
                    </div>
            
                    <div class="content" fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div>{{ "MANAGEMENT.PURCHASE_ORDER_CHEF" | translate }}</div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <app-report-button
                                    [vo]="reportFactoryService.getVO('REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_STACK', 
                                                                                                                DistributionModeEnum.FILE,
                                                                                                                ExportFormatEnum.PDF, 
                                                                                                                ExportToEnum.PRINT,
                                                                                                                dateRangeCongress)">
                                </app-report-button>
                                <app-report-button
                                    [vo]="reportFactoryService.getVO('REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_STACK', 
                                                                                                                DistributionModeEnum.FILE,
                                                                                                                ExportFormatEnum.PDF, 
                                                                                                                ExportToEnum.DOWNLOAD,
                                                                                                                dateRangeCongress)">
                                </app-report-button>
                            </div>
            
                        </div>
            
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div>{{ "MANAGEMENT.PURCHASE_ORDER_HOT" | translate }}</div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <app-report-button
                                    [vo]="reportFactoryService.getVO('REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_STACK', 
                                                                                                                    DistributionModeEnum.FILE,
                                                                                                                    ExportFormatEnum.PDF, 
                                                                                                                    ExportToEnum.PRINT,
                                                                                                                    dateRangeCongress)">
                                </app-report-button>
                                <app-report-button
                                    [vo]="reportFactoryService.getVO('REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_STACK', 
                                                                                                                    DistributionModeEnum.FILE,
                                                                                                                    ExportFormatEnum.PDF, 
                                                                                                                    ExportToEnum.DOWNLOAD,
                                                                                                                    dateRangeCongress)">
                                </app-report-button>
                            </div>
            
                        </div>
                    </div>
            
            
                </div>
            </div> -->
    </div>
  </div>
</div>
