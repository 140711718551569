import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { HistoricModel } from '../../api/models/HistoricModel';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-created-updated-at',
  templateUrl: './created-updated-at.component.html',
  styleUrls: ['./created-updated-at.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class CreatedUpdatedAtComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() historicModel!: HistoricModel;

  constructor(private translate: TranslateService) {
    // Initialisé la langue
    const local_lang =
      localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID) ||
      LocalizationConfig.DEFAULT_LANGUAGE;

    // Ajouter les traductions locales
    if (local_lang === 'en-CA')
      translate.setTranslation(english.lang, english.data, true);
    else translate.setTranslation(french.lang, french.data, true);

    // Définir la langue à utiliser
    translate.setDefaultLang(LocalizationConfig.DEFAULT_LANGUAGE);
    translate.use(local_lang);
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}
}
