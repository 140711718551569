export const locale = {
  lang: "en-CA",
  data: {
    CREATE_CLIENT_WITH_DETAILS: {
      TITLE: "New client",
      TITLE_CONTACT: "Infos contact client",
      TITLE_ADDRESS: "Infos address client",
      COMM_LANGUAGE: "Communication language",
      ATTACH_ENTERPRISE: "Attach an enterprise",
      SAVE_AND_ATTACH: "Save and attach an enterprise",
    },
  },
};
