import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class EventTypeService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getData(): Observable<any> {
    // this.logger.info("EventTypeService.getData()");
    return this.getEventTypeList();
  }

  public getEventTypeList(): any {
    // this.logger.info("EventTypeService.getEventTypeList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.EVENT_TYPE_LIST));
  }
}
