<div
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
  id="shop-web-rte"
>
  <ng-template #visitHintTemplate>
    <p>{{ "SHOPS.VISIT" | translate }}</p>
  </ng-template>

  <div class="gt2-theme">
    <div
      class="header accent p-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button
        (click)="onPageBack(location, router, '/shops')"
        class="mr-0 mr-sm-16 white-fg"
        mat-icon-button
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div
        class="text-truncate"
        fxFlex="80%"
        fxLayout="column"
        fxLayoutAlign="center start"
      >
        <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
          <div
            *fuseIfOnDom
            [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }"
            class="shop-title"
          >
            <div *ngIf="shop" class="">
              <span class="text-truncate"
                >{{ "SHOPS.WEB_SHOP" | translate }} - {{ shop.name }}</span
              >
            </div>
          </div>
        </div>
        <div fxLayout="row">
          <div *ngIf="shop && shop.shop_type">
            {{ "SHOPS.INTEGRATION_TYPE" | translate }}:
            {{ shop.shop_type.name }}
          </div>
          <div *ngIf="shop && shop.shop_product_type">
            &nbsp;| {{ "SHOPS.SELL_TYPE" | translate }}:
            {{ shop.shop_product_type.name }}
          </div>
        </div>
      </div>

      <div fxFlex style="margin-right: 12px">
        <img *ngIf="shop && shop.logos" src="{{ shop.logos.logo_white }}" />
      </div>
      <div
        class=""
        fxLayout="row"
        fxLayoutAlign="end start"
        style="width: 40px"
      >
        <button
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
        >
          <mat-icon class="s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUBHEADER -->
  <div
    *ngIf="shop"
    class="primary mat-elevation-z1 py-8 gt2-sub-header"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    style="height: 40px; min-height: 40px; max-height: 40px"
  >
    <div
      *ngIf="shop.shop_web"
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-left: 20px"
    >
      <div *ngIf="!shop.shop_web.gt_store_url" class="info text-truncate">
        {{ "SHOPS.GT_STORE_URL" | translate }}: {{ shop.shop_web.gt_store_url }}
      </div>
      <div
        *ngIf="shop.shop_web.gt_store_url"
        (click)="openURL(shop.shop_web.gt_store_url)"
        class="info text-truncate link-cursor"
      >
        {{ "SHOPS.GT_STORE_URL" | translate }}: {{ shop.shop_web.gt_store_url }}
      </div>

      <mat-icon
        *ngIf="shop.shop_web.gt_store_url"
        matTooltip="visitHintTemplate"
        content-type="template"
        style="margin-left: 10px; cursor: pointer"
        (click)="openURL(shop.shop_web.gt_store_url)"
        color="accent"
      >
        link</mat-icon
      >
    </div>
  </div>
  <!-- / SUBHEADER -->

  <div class="content">
    <!-- CENTER -->
    <div #mainContainer class="center pb-56" #scrollMe class="container-scroll">
      <!-- CONTENT -->
      <div class="shop-content">
        <app-shop-rte-schedule-edit-data-table-unified
          *ngIf="schedule"
          [shopUUID]="shopID"
          [collectionData]="schedule"
          [displayedColumns]="tableColumns"
          [dynamicTableItemAction]="scheduleTableItemAction"
          (scrollEvent)="onScrollPage()"
        >
        </app-shop-rte-schedule-edit-data-table-unified>

        <!-- / CONTENT -->
      </div>

      <!-- / CENTER -->
    </div>
  </div>
</div>
