import { HttpClient } from "@angular/common/http";
import { Gt2ApiService } from "./gt2-api.service";
import { ApiRoutes } from "./ApiRoutes";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class UsersService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getUsers(): any {
    //this.logger.info("UsersService.getUsers()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.EMPLOYEES));
  }
}
