export const locale = {
  lang: "en-CA",
  data: {
    ASSIGNMENTS: {
      ASSIGNMENTS_GROUP: "Assignments group",
      ASSIGNMENTS: "Assignments",
      ASSIGNMENT: "Assignment",
      GROUP_NAME: "Group name",
      INVOICE_SUMMARY: "Invoice summary",
      ORDER_SUMMARY: "Order summary",
      ORDER_NECESSARY: "An order is necessary.",
      ASSIGNMENTS_SUMMARY: "Assignments summary",
      TOTAL_BILLED: "Invoiced price",
      TOTAL_BILLED_HINT: "The total bill price is based on the base price of the job function",
      COST_PRICE: "Cost price",
      TOTAL_HOURS: "Total hours",
      JOB_FUNCTIONS_BILLED: "Assignments billed",
      RATE: "Wage/h",
      PRICE: "Price/h",
      HOURLY_RATE: "Hourly wage",
      GROUP: "Group",
      REQUESTED_ALL_DAY: "Entire day",
      DATE_START: "Start date",
      DATE_END: "End date",
      TIME_START: "Time start",
      TIME_END: "Time end",
      SOURCE: "Source",
      MEAL_INCLUDED: "Meal included per assignment",
      JOBS_COUNT: "Assignments total",
      BILLABLE: "Billable",
      TIPS_ENABLE: "Tips",
      CHAR_COUNT_MESSAGE: "{{min}} of {{max}} characters",
      ACTIONS: "Actions",
      PROPOSALS: "Propose to staff",
      PROPOSAL: "Proposal",
      NO_PROPOSALS: "No Proposal",
      NO_ASSIGNMENTS: "No assignment",
      TIPS_AMOUNT: "Tips amount",
      ADD_ASSIGNEMENT_HINT: "Add an assignment",
      ADD_GROUP_HINT: "Add an assignments group",

      JOB_PROPOSAL_UNSET: "Unset",
      JOB_PROPOSAL_ASSIGNED: "Assigned",
      JOB_PROPOSAL_AVAILABLE_UNASSIGNED: "Available but unassigned",
      JOB_PROPOSAL_CANCEL: "Cancelled",
      JOB_PROPOSAL_ON_HOLD: "On Hold",
      JOB_PROPOSAL_PROPOSED: "Proposed",
      JOB_PROPOSAL_REFUSED: "Refused",

      SELECT_STAFF_PROVIDER: "Staff provider",
      JOB_EXPENSES: "Assignment expenses",
      EXPENSES: "Expenses",
      ADD_EXPENSE: "Add a job expense",
      NO_EXPENSES: "No expense for this assignment",

      OPEN_EMPLOYEE: "Open employee",
      INTERNAL: "Internal",
      NO_GROUP: "You don't have assignments Group",
      NO_TIPS: "No tips",
      TOTAL_COST: "Total cost",
      ASSIGNMENT_SALE: "Assignments - sales",
      ASSIGNMENT_COST: "Assignments - costs",
      ASSIGNMENT_ATTENDANCE: "Attendance record",
      ASSIGNMENTS_COMPLETE: "All assignments are filled",

      UNSAVED_EXPENSES_TITLE: "Unsaved expenses",
      UNSAVED_EXPENSES_MESSAGE: "You have unsaved expenses. Do you want to continu anyway?",
      JOBS_COUNT_OVER_50: "50 assignments maximum per group",
      ASSIGNMENTS_TOTAL: "Assignments total",
      ASSIGNMENT_DELETE_EMLPOYEE_HINT: "Cette affectation ne peut pas être effacée, car elle est associée à un employé: ",
      ASSIGNMENT_DELETE_STAFF_PROVIDER_HINT: "Cette affectation ne peut pas être effacée, car elle est associée à une ressource externe: ",
      NO_JOB_EXPENSES: "No expenses",
      INVOICE_TOTAL: "Invoice total",
      DETACH_EXTRERNAL: "Detach external ressource",
      PLANNED_START: "Début planifié",
      PLANNED_END: "Fin planifié",
      ASSIGMENTS_DEFAULT_VALUES_LABEL: "Valeurs par défaut des affectations",
    },
  },
};
