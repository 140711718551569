import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-phone-number-with-ext',
  templateUrl: './phone-number-with-ext.component.html',
  styleUrls: ['./phone-number-with-ext.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class PhoneNumberWithExtComponent implements OnInit {
  @Input() groupModel!: PhoneNumberWithExtComponentModel;
  @Input() telephonePlaceHolder!: string;
  @Input() telephoneExtPlaceHolder!: string;
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      telephoneCtrl: '',
      telephoneExtCtrl: '',
    });
  }

  ngOnInit() {
    if (!this.telephonePlaceHolder) {
      this.telephonePlaceHolder = 'Telephone';
    }
    if (!this.telephoneExtPlaceHolder) {
      this.telephoneExtPlaceHolder = 'Ext';
    }
  }
}

export interface PhoneNumberWithExtComponentModel {
  telephone: string;
  telephoneExt: string;
}
