import { NGXLogger } from 'ngx-logger';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { EnterpriseInvoiceService } from '../EnterpriseInvoice.service';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-enterprise-cashing-invoice',
  templateUrl: './enterprise-cashing-invoice.component.html',
  styleUrls: ['./enterprise-cashing-invoice.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [EnterpriseInvoiceService, DatatableHelperService],
})
export class EnterpriseCashingInvoiceComponent implements OnInit {
  //
  @Input() invoice: any;
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();
  form: UntypedFormGroup;
  // cashingAmount: number = 0;
  //
  constructor(
    // private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    // public deliveryRequestTypeService: DeliveryRequestTypeService,
    // public deliveryRequestContentService: DeliveryRequestContentService,
    // public wrappingService: WrappingService,
    // public depositRequestService: DepositRequestService,
    // public paymentModesService: PaymentModesService,
    // private depositInService: DepositInService,
    // public dialog: MatDialog,
    // public router: Router,
    // private adapter: DateAdapter<any>,
    // private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    this.form = this.formBuilder.group({
      // cashing_amount: ["", [Validators.required]],
      // received_at: ["", [ValidatorHelper.dateTodaysOrLower]],
      amount: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    // this.logger.info(
    //     "EnterpriseCashingInvoiceComponent.ngOnInit() - invoice: " +
    //         JSON.stringify(this.invoice)
    // );
  }

  public onAmountChange(event: any): void {
    this.invoice.amount = parseFloat(event.target.value);
    this.onUpdate.emit();
  }
}
