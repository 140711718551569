<div style="max-height: 100%">
  <h1 matDialogTitle>{{ dialogTitle }}</h1>
  <div fxLayout="row" fxLayoutAlign="start end" class="p-8">
    <div [formGroup]="form" fxFlex="100" class="mr-16" fxLayout="column">
      <mat-form-field fxFlex="100" class="mr-4">
        <mat-label>{{ createPlaceholder }} (Français)</mat-label>
        <input
          matInput
          type="text"
          autocomplete="nope"
          placeholder="{{ createPlaceholder }} (Français)"
          [(ngModel)]="serviceCharge.name_local.name_fr_CA"
          formControlName="name_fr_CA"
        />
      </mat-form-field>

      <mat-form-field *ngIf="useEnglish" class="">
        <mat-label>{{ createPlaceholder }} (English)</mat-label>
        <input
          matInput
          type="text"
          autocomplete="nope"
          placeholder="{{ createPlaceholder }} (English)"
          [(ngModel)]="serviceCharge.name_local.name_en_CA"
          formControlName="name_en_CA"
        />
      </mat-form-field>

      <mat-form-field class="" *ngIf="!isEnterpriseServiceCharge">
        <mat-label>{{ "SERVICE_CHARGE.LEDGER_ACCOUNT" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'SERVICE_CHARGE.LEDGER_ACCOUNT' | translate }}"
          [(ngModel)]="serviceCharge.ledger_account"
          [compareWith]="compareledgerAccount"
          formControlName="ledger_account"
          (selectionChange)="onLedgerAccountChange($event)"
        >
          <mat-option *ngFor="let item of ledgerAccounts" [value]="item">{{
            item.name_label
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        *ngIf="!hideServiceChargeMultiplierInput"
      >
        <app-gt2-select
          [service]="serviceChargeMultiplierService"
          [placeHolder]="
            'SERVICE_CHARGE.SELECT_SERVICE_CHARGE_MULTIPLIER' | translate
          "
          [label]="''"
          [selectedModel]="selectedServiceChargeMultiplier"
          (changeEvent)="onServiceChargeMultiplierChange($event)"
          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
          [nullableUseNone]="false"
          fxFlex="100%"
          class="item-full-width"
        ></app-gt2-select>
      </div>

      <mat-form-field
        [formGroup]="formPrice"
        class=""
        *ngIf="
          !selectedServiceChargeMultiplier ||
          selectedServiceChargeMultiplier.value !==
            ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
        "
      >
        <mat-label>{{ "GENERIC.PRICE" | translate }}</mat-label>
        <input
          matInput
          [placeholder]="'GENERIC.PRICE' | translate"
          formControlName="price"
          pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
          autocomplete="nope"
          type="text"
          [(ngModel)]="serviceCharge.price"
        />
        <mat-error *ngIf="formPrice.controls['price'].invalid" class="">
          {{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
          {{ "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL" | translate }}
        </mat-error>
      </mat-form-field>

      <!-- only for enterprise start -->
      <div
        *ngIf="
          isEnterpriseServiceCharge &&
          selectedServiceChargeMultiplier &&
          selectedServiceChargeMultiplier.value ===
            ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
        "
      >
        <mat-radio-group
          (change)="onIsDiscountChange($event)"
          [(ngModel)]="serviceCharge.is_discount"
        >
          <mat-radio-button
            class="mr-8"
            checked="{{ serviceCharge.is_discount == 0 }}"
            [value]="0"
            >{{ "SERVICE_CHARGE.SURCHARGE" | translate }}</mat-radio-button
          >
          <mat-radio-button
            checked="{{ serviceCharge.is_discount == 1 }}"
            [value]="1"
            >{{ "SERVICE_CHARGE.DISCOUNT" | translate }}</mat-radio-button
          >
        </mat-radio-group>
        <mat-form-field class="" [formGroup]="formPercentEnterprise">
          <mat-label>{{ "SERVICE_CHARGE.AMOUNT" | translate }}</mat-label>
          <input
            matInput
            [placeholder]="'SERVICE_CHARGE.AMOUNT' | translate"
            formControlName="percent"
            autocomplete="nope"
            type="number"
            min="1"
            [(ngModel)]="serviceCharge.percentage_by_enterprise"
          />
          <mat-error
            *ngIf="formPercentEnterprise.controls['percent'].invalid"
            class=""
          >
            {{ "GENERIC.INPUT_NUMBER_NOT_ZERO" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- only for enterprise end -->

      <mat-form-field
        class=""
        [formGroup]="formPercent"
        *ngIf="
          !isEnterpriseServiceCharge &&
          selectedServiceChargeMultiplier &&
          selectedServiceChargeMultiplier.value ===
            ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
        "
      >
        <mat-label>{{ "GENERIC.PERCENTAGE" | translate }}</mat-label>
        <input
          matInput
          [placeholder]="'GENERIC.PERCENTAGE' | translate"
          formControlName="percent"
          autocomplete="nope"
          type="number"
          [(ngModel)]="serviceCharge.percentage_by"
        />
        <mat-error *ngIf="formPercent.controls['percent'].invalid" class="">
          {{ "GENERIC.INPUT_NUMBER_MINUS_100_100" | translate }}
          {{ "GENERIC.INPUT_NUMBER_NOT_ZERO" | translate }}
        </mat-error>
      </mat-form-field>

      <div
        *ngIf="
          selectedServiceChargeMultiplier &&
          selectedServiceChargeMultiplier.value ===
            ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
        "
      >
        <div [formGroup]="formPercent" class="">
          <mat-checkbox
            labelPosition="after"
            [(ngModel)]="serviceCharge.has_food"
            [ngModelOptions]="{ standalone: true }"
            >{{ "GENERIC.ROYALTY_FOOD" | translate }}
          </mat-checkbox>
          <mat-checkbox
            labelPosition="after"
            [(ngModel)]="serviceCharge.has_beverage"
            [ngModelOptions]="{ standalone: true }"
            >{{ "GENERIC.ROYALTY_BEVERAGE" | translate }}
          </mat-checkbox>
          <mat-checkbox
            labelPosition="after"
            [(ngModel)]="serviceCharge.has_material"
            [ngModelOptions]="{ standalone: true }"
            >{{ "GENERIC.ROYALTY_MATERIAL" | translate }}
          </mat-checkbox>
          <mat-checkbox
            labelPosition="after"
            [(ngModel)]="serviceCharge.has_service"
            [ngModelOptions]="{ standalone: true }"
            >{{ "GENERIC.ROYALTY_SERVICE" | translate }}
          </mat-checkbox>
          <mat-checkbox
            labelPosition="after"
            [(ngModel)]="serviceCharge.has_delivery"
            [ngModelOptions]="{ standalone: true }"
            >{{ "GENERIC.ROYALTY_DELIVERY_FEE" | translate }}
          </mat-checkbox>
          <!--<mat-checkbox labelPosition="after" [(ngModel)]="serviceCharge.has_total_taxes" [ngModelOptions]="{ standalone: true }"
            >{{ "GENERIC.ROYALTY_TOTAL_TAXES_INCLUDED_FEE" | translate }}
          </mat-checkbox>-->
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        style="padding-top: 12px"
      >
        <app-gt2-select
          #ledgerAccountSelect
          fxFlex="100%"
          [service]="taxesService"
          [placeHolder]="'GENERIC.TAX' | translate"
          [label]="'GENERIC.TAX' | translate"
          [selectedModel]="serviceCharge.tax"
          (changeEvent)="onTaxChange($event)"
          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
          [nullableUseNone]="false"
          class=""
        ></app-gt2-select>
      </div>

      <!-- <div fxLayout="row" fxLayoutAlign="start center">
        <app-gt2-select [service]="serviceChargeMultiplierService"
            [placeHolder]="'SERVICE_CHARGE.SELECT_SERVICE_CHARGE_MULTIPLIER' | translate" [label]="''"
            [selectedModel]="selectedServiceChargeMultiplier" (changeEvent)="onServiceChargeMultiplierChange($event)"
            [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')" [nullableUseNone]="false" fxFlex="100%"
            class="item-full-width"></app-gt2-select>
      </div> -->
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" class="gt2-buttons-theme">
    <!-- <button mat-raised-button *ngIf="!serviceChargeToUpdate" color="accent" (click)="onCreateNew()" [disabled]="!form.valid || (!ledger_account && !isEnterpriseServiceCharge) || 
                    (!isEnterpriseServiceCharge && selectedServiceChargeMultiplier && 
                    selectedServiceChargeMultiplier.value === ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES && 
                    formPercent.invalid) || 
                    (isEnterpriseServiceCharge && selectedServiceChargeMultiplier && 
                    selectedServiceChargeMultiplier.value === ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES && 
                    formPercentEnterprise.invalid) ||
                    (selectedServiceChargeMultiplier && 
                    selectedServiceChargeMultiplier.value !== ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES && 
                    formPrice.invalid)" class="mr-16">
    {{ 'GENERIC.CREATE' | translate | uppercase }}
</button> -->
    <button
      mat-raised-button
      *ngIf="!serviceChargeToUpdate"
      color="accent"
      (click)="onCreateNew()"
      [disabled]="
        !form.valid ||
        !selectedServiceChargeMultiplier ||
        (!serviceCharge.ledger_account && !isEnterpriseServiceCharge) ||
        (!isEnterpriseServiceCharge &&
          selectedServiceChargeMultiplier &&
          selectedServiceChargeMultiplier.value ===
            ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES &&
          formPercent.invalid) ||
        (isEnterpriseServiceCharge &&
          selectedServiceChargeMultiplier &&
          selectedServiceChargeMultiplier.value ===
            ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES &&
          formPercentEnterprise.invalid) ||
        (selectedServiceChargeMultiplier &&
          selectedServiceChargeMultiplier.value !==
            ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES &&
          formPrice.invalid)
      "
      class="mr-16"
    >
      {{ "GENERIC.CREATE" | translate }}
    </button>

    <button
      mat-raised-button
      *ngIf="serviceChargeToUpdate"
      color="accent"
      (click)="onUpdate()"
      class="mr-16"
    >
      {{ "GENERIC.UPDATE" | translate | uppercase }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false); test()"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
