import { FuseTranslationLoaderService } from './../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';
import { DateAdapter } from '@angular/material/core';
import { NGXLogger } from 'ngx-logger';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { locale as english } from './../../content/shops/i18n/en-CA';
import { locale as french } from './../../content/shops/i18n/en-CA';
import moment from 'moment';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MomentFormatModule } from '../../modules/moment-format.module';

@Component({
  selector: 'app-hours-grid',
  templateUrl: './hours-grid.component.html',
  styleUrls: ['./hours-grid.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MomentFormatModule,
    Gt2PrimengModule,
    NgxMaterialTimepickerModule,
    TranslateModule,
  ],
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
})
export class HoursGridComponent implements OnInit {
  @Input() hoursModel: any;
  @Output() hoursModelChange: EventEmitter<any> = new EventEmitter();
  @Input() editMode: boolean = true;
  disabledDay: any = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };
  ampm: number = 24;

  constructor(
    public logger: NGXLogger,
    public translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private adapter: DateAdapter<any>,
  ) {
    this.adapter.setLocale(this.translate.currentLang);
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit(): void {
    // //this.logger.info('HoursGridComponent.ngOnInit() -> hoursModel: ' + JSON.stringify(this.hoursModel));
    // this.hoursModel.monday_start_time = '18:00';

    if (this.hoursModel) {
      this.disabledDay.monday =
        !this.hoursModel.monday_start_time || !this.hoursModel.monday_end_time;
      this.disabledDay.tuesday =
        !this.hoursModel.tuesday_start_time ||
        !this.hoursModel.tuesday_end_time;
      this.disabledDay.wednesday =
        !this.hoursModel.wednesday_start_time ||
        !this.hoursModel.wednesday_end_time;
      this.disabledDay.thursday =
        !this.hoursModel.thursday_start_time ||
        !this.hoursModel.thursday_end_time;
      this.disabledDay.friday =
        !this.hoursModel.friday_start_time || !this.hoursModel.friday_end_time;
      this.disabledDay.saturday =
        !this.hoursModel.saturday_start_time ||
        !this.hoursModel.saturday_end_time;
      this.disabledDay.sunday =
        !this.hoursModel.sunday_start_time || !this.hoursModel.sunday_end_time;
    }
  }
  onTimeChange(value: string, key: string): void {
    this.hoursModel[key] =
      this.translate.currentLang === 'en-CA'
        ? moment(value, 'h:mm A').format('HH:mm')
        : value;
    this.hoursModelChange.emit();
  }
  public onCheckboxChange(
    event: any,
    startTime: string,
    endTime: string,
    day: string,
  ): void {
    //this.logger.info("HoursGridComponent.onCheckboxChange() -> event.checked: " + event.checked);
    this.disabledDay[day] = event.checked;
    if (event.checked) {
      this.hoursModel[startTime] = null;
      this.hoursModel[endTime] = null;
      this.hoursModelChange.emit();
    }
  }
}
