<div id="dashboard" class="page-layout simple fullwidth" fxLayout="column">
  <!--Scroll tout la page et pas les contenus de la tab-->
  <!-- HEADER -->
  <!-- <div class="gt2-theme">
        <div class="header accent p-24 h-80 mat-elevation-z1" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxLayoutAlign="center start">
                <div class="h1 mt-8">{{ "GENERIC.DASHBOARD" | translate}}</div>
            </div>
        </div>
    </div> -->
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 content-subheader"
  >
    <div fxLayout="row" fxLayoutAlign="start center"></div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <div
        *ngIf="
          modulesService.modulesAreSet &&
          !modulesService.hasCTReport() &&
          !modulesService.hasRPReport()
        "
      >
        <button
          *ngxPermissionsOnly="rolesService.SALE_ROLES"
          mat-button
          (click)="onNewProposal()"
        >
          <mat-icon class="icon-color s-24">{{ getIcon("PROPOSAL") }}</mat-icon>
          <p class="icon-color">{{ "GENERIC.CREATE_PROPOSAL" | translate }}</p>
        </button>

        <button
          *ngxPermissionsOnly="rolesService.SALE_ROLES"
          mat-button
          (click)="onCreateOrder()"
        >
          <mat-icon class="icon-color s-24">{{ getIcon("ORDER") }}</mat-icon>
          <p class="icon-color">{{ "DASHBOARD.CREATE_ORDER" | translate }}</p>
        </button>
      </div>

      <div
        *ngIf="
          modulesService.modulesAreSet &&
          (modulesService.hasCTReport() || modulesService.hasRPReport())
        "
      >
        <app-create-congresses-button></app-create-congresses-button>
      </div>

      <div
        *ngIf="modulesService.modulesAreSet && modulesService.hasQuickOrder()"
      >
        <button mat-button (click)="onQuickOrder()">
          <mat-icon class="icon-color s-24">{{ getIcon("SHOP") }}</mat-icon>
          <p class="icon-color">{{ "GENERIC.QUICK_ORDER" | translate }}</p>
        </button>
      </div>
    </div>
  </div>
  <!-- / SUB HEADER -->

  <div fxFlex fxLayout="row" fxLayout.lt-sm="column">
    <div fxLayout="column" fxLayoutAlign="start start" class="left-col-jul">
      <div
        class="toolbar gt2-purple"
        fxLayout="row"
        fxLayoutAlign="center center"
        style="height: 40px; max-height: 40px; min-height: 40px; width: 100%"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="pl-16"
          *ngIf="dateFormat"
        >
          <button
            mat-icon-button
            class="arrow"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="setSelectedDay($event)"
            aria-label="Previous"
          >
            <mat-icon class="icon-color">chevron_left</mat-icon>
          </button>

          <div
            class="title"
            (click)="dp2.open()"
            style="
              cursor: pointer;
              border-radius: 2px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
            "
          >
            {{
              viewDate
                | calendarDate: view + "ViewTitle" : translate.currentLang
            }}
          </div>
          <mat-form-field style="width: 1px; visibility: hidden">
            <input
              matInput
              [min]="minDate"
              [max]="maxDate"
              [matDatepicker]="dp2"
              readonly
            />
            <mat-datepicker
              #dp2
              startView="year"
              (monthSelected)="closeDatePicker($event, dp2)"
            ></mat-datepicker>
          </mat-form-field>

          <button
            mat-icon-button
            class="arrow"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="setSelectedDay($event)"
            aria-label="Next"
          >
            <mat-icon class="icon-color">chevron_right</mat-icon>
          </button>
        </div>
      </div>

      <ngx-spinner
        [name]="'calenderSpinner'"
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="#90ee90"
        [fullScreen]="false"
        type="ball-scale-multiple"
      >
        <p style="font-size: 20px; color: white">
          {{ "DASHBOARD.LOADING" | translate }}
        </p>
      </ngx-spinner>

      <ng-template #customCellTemplate let-day="day" let-locale="locale">
        <div
          style="height: 100%"
          [ngClass]="
            (day.date | calendarDate: 'dayViewTitle') ===
            (selectedDayForEvents.date | calendarDate: 'dayViewTitle')
              ? 'selected-day'
              : ''
          "
        >
          <div class="cal-cell-top">
            <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
              day.badgeTotal
            }}</span>
            <span class="cal-day-number">{{
              day.date | calendarDate: "monthViewDayNumber" : locale
            }}</span>
          </div>
          <div class="cell-totals">
            <span
              *ngFor="let group of day.eventGroups"
              class="badge badge-{{ group[0] }}"
            >
              {{ group[1].length }}
            </span>
          </div>
        </div>
      </ng-template>

      <!-- CONTENT -->
      <div
        fxLayout="column"
        fxLayoutAlign="space-between start"
        class="content"
      >
        <div fxLayout="column" style="width: 100%; height: 625px">
          <mwl-calendar-month-view
            [viewDate]="viewDate"
            [events]="events"
            [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="editEvent('edit', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
            (beforeViewRender)="beforeMonthViewRender($event)"
            [cellTemplate]="customCellTemplate"
            [refresh]="refreshCalendar"
            [weekStartsOn]="0"
          >
          </mwl-calendar-month-view>

          <div fxLayout="row" fxLayoutAlign="center center">
            <button
              mat-raised-button
              (click)="dayDateParamReceived(iniDate.toString())"
              color="primary"
              class
              style="margin: 5px 4px 5px 8px; width: 360px"
            >
              <!-- {{ iniDate.lang('fr-CA').format("dddd, Do MMMM YYYY") }} -->
              {{ iniDate.locale(translate.currentLang).format("DD MMMM YYYY") }}
            </button>
          </div>

          <!--  -->
          <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%">
            <mat-radio-group
              [(ngModel)]="listState"
              fxLayout="row"
              aria-label="Select an option"
              class="list-radio-group"
              (change)="onListRadioChange($event)"
            >
              <mat-radio-button [value]="0" style="margin-right: 20px">{{
                "GENERIC.EVENTS" | translate
              }}</mat-radio-button>
              <mat-radio-button [value]="1">{{
                "GENERIC.PRODUCTION_ZONE" | translate
              }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <!-- Show Guest number by day here -->
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            style="width: 100%; margin: 5px 0"
          >
            <div>
              {{ "DASHBOARD.GUEST_COUNT" | translate }}
              {{
                selectedDayForEvents.date
                  | calendarDate: "dayViewTitle" : translate.currentLang
              }}
            </div>
            <div class="red-fg guest-count-number">{{ guestCountByDay }}</div>
          </div>
        </div>

        <app-dashboard-note
          [date]="selectedDay"
          [dashboardJul]="dashboardJul"
          style="width: 100%; height: 182px"
        ></app-dashboard-note>
      </div>
    </div>

    <!-- EVENTS LIST -->
    <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
      <div
        class="toolbar gt2-pink"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        style="height: 40px; max-height: 40px; min-height: 40px"
      >
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
          <div class="section-title px-12 py-4">
            <div *ngIf="listState === 0">
              {{ "GENERIC.EVENTS" | translate }}
            </div>
          </div>
          <div *ngIf="listState === 0" class="section-subtitle px-12">
            <mat-checkbox
              [(ngModel)]="includeCancelAbortEvents"
              [ngModelOptions]="{ standalone: true }"
              (change)="onIncludeCancelAbortEvent()"
              style="font-size: 14px"
            >
              {{ "DASHBOARD.INCLUDE_CANCEL_ABORT_EVENTS" | translate }}
            </mat-checkbox>
          </div>
          <div class="section-subtitle mr-12">
            {{
              selectedDayForEvents.date
                | calendarDate: "dayViewTitle" : translate.currentLang
            }}
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
        <!-- <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="1 1 100%" style="background-color: aqua;"></div> -->
        <div fxLayout="column" fxFlexFill>
          <div
            *ngIf="
              listState === 0 &&
              (!ordersOfTheDay || ordersOfTheDay.length === 0)
            "
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="100"
          >
            <div class="py-16">{{ "DASHBOARD.NO_ORDERS" | translate }}</div>
          </div>

          <div
            *ngIf="
              listState === 1 &&
              (!productionZonesOfTheDay || productionZonesOfTheDay.length === 0)
            "
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="100"
          >
            <div class="py-16">
              {{ "DASHBOARD.NO_PRODUCTS_PZ" | translate }}
            </div>
          </div>

          <app-collection-table-sort
            *ngIf="
              listState === 0 && ordersOfTheDay && ordersOfTheDay.length > 0
            "
            [(collectionData)]="ordersOfTheDay"
            [displayedColumns]="displayedColumns"
          >
          </app-collection-table-sort>

          <app-prod-zone-table
            *ngIf="
              listState === 1 &&
              productionZonesOfTheDay &&
              productionZonesOfTheDay.length > 0
            "
            [(collectionData)]="productionZonesOfTheDay"
            [displayedColumns]="['name']"
          >
          </app-prod-zone-table>

          <!-- <app-collection-table-sort *ngIf="listState === 1 && ordersOfTheDay && ordersOfTheDay.length > 0"
                        [(collectionData)]="ordersOfTheDay"
                        [displayedColumns]="[ 'delivery_type_id', 'time', 'event_name', 'guest_count', 'client_name', 'responsible_employee_name', 'current_order_type', 'status']">
                    </app-collection-table-sort> -->
        </div>
      </div>
    </div>
  </div>
</div>
