import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OrdersService } from '../../../api/orders.service';
import { ServiceChargeService } from '../../../api/service-charge.service';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { Select2Data, Select2Module } from 'ng-select2-component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { WrappingService } from '../../../api/wrapping.service';
import { SpecificConditionService } from '../../../api/specific-condision.service';
import { ProposalComposeService } from '../../../api/proposal-compose.service';
import { ProposalComposeCTService } from '../../../api/proposal-compose-ct.service';
import { OrderComposeService } from '../../../api/order-compose.service';
import { InvoiceComposeService } from '../../../api/invoice-compose.service';
import { InvoiceComposeCTService } from '../../../api/invoice-compose-ct.service';
import { InvoiceComposeGlobalPaymentsService } from '../../../api/invoice-compose-global-payments.service';
import { CatererService } from '../../../content/caterer/caterer.service';
import { ConseillersService } from '../../../api/conseillers.service';
import { ReportsService } from '../../../reports/reports.service';
import { ReportFactoryService } from '../../../reports/report-factory.service';
import { MenuReportsService } from '../../../reports/menu-reports.service';
import { ReceiptNoticeComposeService } from '../../../api/receipt-notice-compose.service';
import { StorageNoticeComposeService } from '../../../api/storage-notice-compose.service';
import { ReceiptNoticeSellOnlyComposeService } from '../../../api/receipt-notice-sell-only-compose.service';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-add-service-charge',
  templateUrl: './add-service-charge.component.html',
  styleUrls: ['./add-service-charge.component.scss'],
  standalone: true,
  imports: [
    Select2Module,
    MatButtonModule,
    TranslateModule,
    CommonModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    WrappingService,
    SpecificConditionService,
    ProposalComposeService,
    ProposalComposeCTService,
    OrderComposeService,
    InvoiceComposeService,
    InvoiceComposeCTService,
    InvoiceComposeGlobalPaymentsService,
    CatererService,
    ConseillersService,
    ReportsService,
    ReportFactoryService,
    MenuReportsService,
    ReceiptNoticeComposeService,
    ReceiptNoticeSellOnlyComposeService,
    StorageNoticeComposeService,
  ],
})
export class AddServiceChargeComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static EDIT: string = 'EDIT';

  //
  mode: string = AddServiceChargeComponent.CREATE;
  form!: UntypedFormGroup;
  formMultiply!: UntypedFormGroup;
  formPercent!: UntypedFormGroup;
  formPrice!: UntypedFormGroup;
  formMajoration!: UntypedFormGroup;
  serviceCharge: any;
  orderUUID!: string;
  // order: any;
  loading: boolean = false;
  autoRedirect: boolean = false;
  serviceCharges!: any[];
  selectedServiceCharge: any;
  selectedServiceChargeMultiplier: any;
  serviceChargeToUpdate: any;
  serviceChargeData: any;
  has_majoration: boolean = false;
  serviceChargeSelect2Data: Select2Data = [];
  allServiceCharges: any[] = [];
  selectedServiceChargeUuid: string = '';

  constructor(
    public override dialogRef: MatDialogRef<AddServiceChargeComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    // public deliveryRequestTypeService: DeliveryRequestTypeService,
    // public deliveryRequestContentService: DeliveryRequestContentService,
    public serviceChargeService: ServiceChargeService,
    // public serviceChargeMultiplierService: ServiceChargeMultiplierService,
    public orderService: OrdersService,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    // this.form = this.formBuilder.group({
    //     unit_price: [
    //         "",
    //         [ValidatorHelper.isValidPriceNumber, Validators.required],
    //     ],
    //     qty: [
    //         "",
    //         [ValidatorHelper.isNumber0To1000000, Validators.required],
    //     ],
    // });

    //this.logger.info("AddServiceChargeComponent.constructor() -> data: " + JSON.stringify(data));
    this.serviceCharge = {
      unit_price: '',
      qty: 1,
      markup: 10,
      service_charge: null,
      multiply_date: null,
      multiply_date_end: null,
    };

    if (data && data.mode !== null) {
      this.mode = data.mode;
    }

    if (data && data.eventUUID !== null) {
      this.orderUUID = data.orderUUID;

      //
      // this.deliveryRequestService.getNewDeliveryRequestData(this.eventUUID).subscribe( response =>
      //   {
      //     this.logger.info("CreateDeliveryRequestComponent.constructor() -> SUCCESS: " + JSON.stringify(response));
      //     this.deliveryRequestData = response.data;
      //     this.deliveryRequest = this.deliveryRequestData;
      //     // this.deliveryRequest.price = this.deliveryRequestData.price;
      //     // this.deliveryRequest.name = this.deliveryRequestData.name;
      //   }, error =>
      //   {
      //     this.logger.error("CreateDeliveryRequestComponent.constructor() -> ERROR: " + JSON.stringify(error));
      //   });
    }

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }

    if (data && data.serviceChargeToUpdate) {
      this.serviceCharge = data.serviceChargeToUpdate;
      // this.selectedServiceCharge =
      //     data.serviceChargeToUpdate.service_charge.data;

      //this.logger.info("AddServiceChargeComponent.constructor() -> serviceChargeToUpdate TO data.serviceChargeToUpdate: " + JSON.stringify(data.serviceChargeToUpdate));
      // this.logger.info("AddServiceChargeComponent.constructor() -> serviceChargeToUpdate TO this.serviceCharge: " + JSON.stringify(this.serviceCharge));
    }
    //
    this.createForm();
  }

  ngOnInit() {
    // ? set default value has_majoration=false 2022-12-16 if mode = create
    if (this.mode == AddServiceChargeComponent.CREATE) {
      this.has_majoration = false;
      this.loadData();
    } else {
      this.has_majoration =
        this.serviceCharge.markup !== null && this.serviceCharge.markup != 0;
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateServiceCharge() {
    this.loading = true;
    // this.logger.info("AddServiceChargeComponent.onCreateServiceCharge() -> flow: " + JSON.stringify(this.serviceCharge));

    if (this.mode === AddServiceChargeComponent.CREATE) {
      this.doCreateServiceCharge();
    } else if (this.mode === AddServiceChargeComponent.EDIT) {
      this.updateServiceCharge();
    }
  }

  public doCreateServiceCharge() {
    if (
      this.selectedServiceCharge.multiply_enum ===
      this.ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
    ) {
      this.serviceCharge.multiply_enum =
        this.selectedServiceCharge.multiply_enum;
      this.serviceCharge.percentage_by =
        this.selectedServiceCharge.percentage_by;
      this.serviceCharge.has_food = this.selectedServiceCharge.has_food;
      this.serviceCharge.has_beverage = this.selectedServiceCharge.has_beverage;
      this.serviceCharge.has_material = this.selectedServiceCharge.has_material;
      this.serviceCharge.has_service = this.selectedServiceCharge.has_service;
    }
    //
    //this.logger.info("AddServiceChargeComponent.doCreateServiceCharge() -> serviceCharge (array): " + JSON.stringify(this.serviceCharge));

    //
    this.orderService
      .addServiceCharge(this.orderUUID, {
        service_charge: [this.serviceCharge],
      })
      .subscribe(
        (response: any) => {
          // this.logger.info("AddServiceChargeComponent.doCreateServiceCharge() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response.data);
        },
        (error: any) => {
          this.logger.error(
            'AddServiceChargeComponent.doCreateServiceCharge() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      );
  }

  public updateServiceCharge() {
    // this.logger.info("AddServiceChargeComponent.updateServiceCharge() -> ***** UPDATE this.deliveryRequest: " + JSON.stringify(this.serviceCharge));
    if (!this.has_majoration) {
      this.serviceCharge.markup = 0;
    }
    this.orderService
      .updateServiceCharges(this.orderUUID, [this.serviceCharge])
      .subscribe(
        (response: any) => {
          // this.logger.info("AddServiceChargeComponent.updateServiceCharge() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          this.dialogRef.close(response.data);
        },
        (error: any) => {
          this.logger.error(
            'AddServiceChargeComponent.updateServiceCharge() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      );
  }

  public onServiceChargeChange(event: any): void {
    let selected = null;
    if (event.value && this.allServiceCharges) {
      selected = this.allServiceCharges.find((item) => {
        return event.value === item.uuid;
      });
    }

    this.selectedServiceCharge = selected;

    //this.logger.info(
    //   "AddServiceChargeComponent.onServiceChargeChange() -> this.selectedServiceCharge: " +
    //      JSON.stringify(event.value)
    //);
    //this.logger.info("AddServiceChargeComponent.onServiceChargeChange() -> this.selectedServiceCharge.price: " + this.selectedServiceCharge.price);
    if (this.selectedServiceCharge.price) {
      this.selectedServiceCharge.unit_price = this.selectedServiceCharge.price;
    }

    if (this.serviceCharge.qty) {
      this.selectedServiceCharge.qty = this.serviceCharge.qty;
    }

    if (this.serviceCharge.unit_price) {
      this.selectedServiceCharge.unit_price = this.serviceCharge.unit_price;
    }
    //
    this.selectedServiceChargeUuid = this.selectedServiceCharge
      ? this.selectedServiceCharge.uuid
      : '';

    // reset every time
    this.selectedServiceCharge.markup = 0;
    //
    this.serviceCharge = this.selectedServiceCharge;
    this.createForm();
    // this.logger.info("AddServiceChargeComponent.onServiceChargeChange() -> this.selectedServiceCharge: " + JSON.stringify(this.selectedServiceCharge));
  }

  public createForm(): void {
    //this.logger.info(
    //"AddServiceChargeComponent.createForm() -> ",
    //JSON.stringify(this.serviceCharge)
    //);
    this.form = this.formBuilder.group({
      // unit_price: [
      //     "",
      //     [ValidatorHelper.isValidPriceNumber, Validators.required],
      // ],
      qty: [1, [ValidatorHelper.isNumber0To1000000, Validators.required]],
    });
    //
    this.formMultiply = this.formBuilder.group({
      date: ['', [Validators.required]],
      date_end: ['', [Validators.required]],
      markup: [0, [ValidatorHelper.isValidPriceNumber]],
    });
    //
    this.formPrice = this.formBuilder.group({
      unit_price: [
        '',
        [ValidatorHelper.isValidPriceNumber, Validators.required],
      ],
    });
    //
    this.formPercent = this.formBuilder.group({
      percent: [
        '',
        [
          // ValidatorHelper.isNotZero,
          // ValidatorHelper.isNumberMinus100To100,
          Validators.required,
        ],
      ],
    });

    // if (
    //     this.serviceCharge.multiply_enum ===
    //     ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
    // ) {
    //     this.form = this.formBuilder.group({
    //         // percentage_by: [
    //         //     "",
    //         //     [
    //         //         ValidatorHelper.isNotZero,
    //         //         ValidatorHelper.isNumberMinus100To100,
    //         //         Validators.required,
    //         //     ],
    //         // ],
    //         qty: [
    //             "",
    //             [ValidatorHelper.isNumber0To1000000, Validators.required],
    //         ],
    //     });
    // } else {
    //     this.form = this.formBuilder.group({
    //         unit_price: [
    //             "",
    //             [ValidatorHelper.isValidPriceNumber, Validators.required],
    //         ],
    //         qty: [
    //             "",
    //             [ValidatorHelper.isNumber0To1000000, Validators.required],
    //         ],
    //     });
    // }
  }

  public onServiceChargeMultiplierChange(event: any): void {
    //this.logger.info("AddServiceChargeComponent.onServiceChargeMultiplierChange() -> this.selectedServiceCharge: " + JSON.stringify(this.selectedServiceChargeMultiplier));
  }

  public loadData(): void {
    this.serviceChargeService;
    this.loading = true;
    this.serviceChargeService.getData().subscribe(
      (response: any) => {
        this.allServiceCharges = response.data;
        //this.logger.info("AddServiceChargeComponent.loadData() -> allServiceCharges" + JSON.stringify(this.allServiceCharges));
        this.initServiceChargeSelect2Data(this.allServiceCharges);
        if (this.serviceCharge.service_charge) {
          this.selectedServiceChargeUuid =
            this.serviceCharge.service_charge.uuid;
        }
        this.loading = false;
      },
      (error: any) => {
        this.logger.error(
          'EventInformationComponent.getData() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
      },
    );
  }

  private initServiceChargeSelect2Data(data: any) {
    data.forEach((item: any, index: any) => {
      this.serviceChargeSelect2Data.push({
        label: item.name,
        value: item.uuid,
      });
    });
  }
}
