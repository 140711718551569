export const locale = {
  lang: 'fr-CA',
  data: {
    CONTACT: {
      ORG_EMAIL: 'Courriel professionnel',
      MOBILE: 'Mobile',
      OFFICE_PHONE: 'Téléphone bureau',
      OFFICE_EXT: 'Ext',
      PHONE_OTHER: 'Téléphone autre',
      FAX: 'Fax',
      SKYPE: 'Skype',
      NO_CONTACT_INFO: 'Aucune information de contact.',
      EMAIL_CC: 'Email CC',
      PHONE: 'Téléphone',
      EMAIL: 'Courriel',
      INPUT_LENGTH_INVALID: 'Doit contenir entre 2 et 120 caractères',
      INPUT_LENGTH_INVALID_20: 'Doit contenir entre 2 et 20 caractères',
      EMAIL_INVALID: 'Doit être un email valide',
      SAVE: 'Sauvegarder',
    },
  },
};
