import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class ProductCategoryService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getCategories() {
    // this.logger.info("ProductCategoryService.getCategories()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PRODUCT_CATEGORIES));
  }

  public getCategoriesList() {
    // this.logger.info("ProductCategoryService.getCategoriesList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PRODUCT_CATEGORIES_LIST));
  }

  public getData(): Observable<any> {
    // this.logger.info("ProductCategoryService.getData()");
    return this.getCategoriesList();
  }

  public create(nameFR: string, nameEN: string, ledgerAccount: string): any {
    const obj: any = { name_local: { name_fr_CA: nameFR, name_en_CA: nameEN }, ledger_account: ledgerAccount };
    // this.logger.info("ProductCategoryService.create() -> obj: " + JSON.stringify(obj));
    return this.http.post<any>(this.api.createUrl(ApiRoutes.PRODUCT_CATEGORIES), obj);
  }
}
