export const locale = {
  lang: "fr-CA",
  data: {
    JOB_FUNCTIONS: {
      CREATE_NEW_CATEGORY: "Nouvelle fonction",
      CATEGORY_NAME: "Nom de la fonction",
      HOURLY_WAGE: "Taux horaire",
      NEW_CATEGORY_NAME: "Nom de la nouvelle fonction",
      CREATE_SUCCESS: "Fonction créer avec succès!",
      EDIT_SUCCESS: "Fonction modifiée avec succès!",
      DELETE_SUCCESS: "Fonction effacée avec succès!",
      BILLABLE: "Facturable",
    },
  },
};
