export const locale = {
  lang: "fr-CA",
  data: {
    SERVICE_CHARGE: {
      TITLE: "Nouveau frais de service",
      EDIT_TITLE: "Modifier frais de service",
      SELECT_SERVICE_CHARGE: "Sélectionner un frais de service",
      SELECT_SERVICE_CHARGE_MULTIPLIER: "Sélectionner un  multiplicateur",
      MAJORATION_FEE: "Frais de majoration",
    },
  },
};
