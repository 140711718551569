import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { IconsService } from './../../../services/icons.service';
import { fuseAnimations } from './../../../../../@fuse/animations/index';
import { Router } from '@angular/router';
import { ItemUrlFinder } from './../../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EnterprisesService } from './../../../api/enterprises.service';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { merge, fromEvent, of } from 'rxjs';
import {
  startWith,
  switchMap,
  catchError,
  map,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableSizePipeModule } from '../../../modules/table-size.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-enterprise-clients',
  templateUrl: './enterprise-clients.component.html',
  styleUrls: ['./enterprise-clients.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatPaginatorModule,
    CdkTableModule,
    TableSizePipeModule,
    MatInputModule,
    MatButtonModule,
    NgxSpinnerModule,
    FlexLayoutModule,
    FormsModule,
    MatTooltipModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  animations: fuseAnimations,
})
export class EnterpriseClientsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() enterpriseUUID!: string;
  @Input() hasClients: boolean = false;
  @Input() clientsResultsLength: number = 0;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('clientsFilter') clientsFilter!: ElementRef;

  clientsFilterValue: string = '';
  dataSource = new MatTableDataSource();
  displayedColumns = ['first_name', 'last_name', 'client_number', 'actions'];
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(
    private enterprisesService: EnterprisesService,
    private logger: NGXLogger,
    private router: Router,
    private spinner: NgxSpinnerService,
    public datatableHelperService: DatatableHelperService,
  ) {}

  ngOnInit() {
    // setTimeout( () => {
    //     this.hasClients = true;
    // }, 1500);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.isLoadingResults = true;

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
            this.spinner.show('enterpriseClientsSpinner');
          });

          return this.enterprisesService.getEnterpriseClients(
            this.enterpriseUUID,
            this.sort?.active,
            this.sort?.direction,
            this.paginator?.pageIndex,
            this.paginator?.pageSize,
            this.clientsFilterValue,
          );

          // return this.enterprisesService.getAllEnterpriseEvents(
          //     this.sort.active,
          //     this.sort.direction,
          //     this.paginator.pageIndex,
          //     this.paginator.pageSize,
          //     this.enterpriseID);
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.spinner.hide('enterpriseClientsSpinner');
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;

          this.clientsResultsLength = data.meta.pagination.total;
          this.hasClients = data.data !== null && data.data.length > 0;
          // this.logger.info('EnterpriseClientsComponent.enterprisesService.getEnterpriseClients() -> this.hasClients: ' + this.hasClients);
          // this.logger.info('EnterpriseClientsComponent.enterprisesService.getEnterpriseClients() -> data: ' + JSON.stringify(data.data));
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.spinner.hide('enterpriseClientsSpinner');
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe((data) => (this.dataSource.data = data));

    // FILTER / SEARCH
    fromEvent(this.clientsFilter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.clientsFilter?.nativeElement.value.length > 1 ||
            this.clientsFilter?.nativeElement.value === ''
          ) {
            this.paginator.pageIndex = 0;
            this.reloadClientsTable();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {}

  public reloadClientsTable(): void {
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public clearSearch(): void {
    this.clientsFilterValue = '';
    this.paginator.pageIndex = 0;
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onNavigateToItem(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public onOpenItemInNewTab(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }

  public getIcon(iconName: string): string {
    return IconsService.getIconStatic(iconName);
  }
}
