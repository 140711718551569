<!-- Box 1 -->
<div *ngIf="boxName === 'box_notification3'">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "SHOPS.USER_MODE_CORPORATE" | translate }}</div>
      <div *ngIf="shop.register_corporate_mode === 'DIRECT'" class="info">
        {{ "SHOPS.NOTIFICATION_CORPORATE_DIRECT" | translate }}
      </div>
      <div *ngIf="shop.register_corporate_mode === 'NOTIFICATION'" class="info">
        {{ "SHOPS.NOTIFICATION_CORPORATE_NOTIFICATION" | translate }}
      </div>
      <div *ngIf="shop.register_corporate_mode === 'NONE'" class="info">
        {{ "SHOPS.NOTIFICATION_CORPORATE_NONE" | translate }}
      </div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="100%" class="full-width">
      <mat-form-field appearance="fill">
        <mat-label>{{ "SHOPS.USER_MODE_CORPORATE" | translate }}</mat-label>
        <mat-select [formControl]="notificationControl">
          <mat-option [value]="'NONE'">{{
            "SHOPS.NONE" | translate
          }}</mat-option>
          <mat-optgroup
            *ngFor="let group of notificationGroups"
            [label]="group.name"
            [disabled]="group.disabled"
          >
            <mat-option
              *ngFor="let notification of group.notification"
              [value]="notification.value"
            >
              {{ notification.viewValue }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start">
    <button
      (click)="onSaveInfoCorp()"
      color="accent"
      class="save-btn"
      [disabled]="false"
      mat-raised-button
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
  </div>
</div>
<!-- Box 2 -->
<div *ngIf="boxName === 'box_notification'">
  <form (ngSubmit)="onSaveInfoNotificationByRole()">
    <div fxLayout="row" fxLayoutAlign="start start" class="mb-12">
      <div fxFlex="50%" class="title-medium">
        {{ "SHOPS.NOTIFICATIONS_ORDERS" | translate }}
      </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
      <div *ngIf="!editMode" class="info-line" fxFlex="50%">
        <div class="title">{{ "SHOPS.NOTIFY_SALE" | translate }}</div>
        <div *ngIf="shop.notify_order_sale" class="info">
          {{ "GENERIC.YES" | translate }}
        </div>
        <div *ngIf="!shop.notify_order_sale" class="info">
          {{ "GENERIC.NO" | translate }}
        </div>
      </div>

      <div *ngIf="editMode" class="info-line" fxFlex="50%">
        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="shop.notify_order_sale"
          [ngModelOptions]="{ standalone: true }"
          class=""
        >
          {{ "SHOPS.NOTIFY_SALE" | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
      <div *ngIf="!editMode" class="info-line" fxFlex="50%">
        <div class="title">{{ "SHOPS.NOTIFY_OWNER" | translate }}</div>
        <div *ngIf="shop.notify_order_owner" class="info">
          {{ "GENERIC.YES" | translate }}
        </div>
        <div *ngIf="!shop.notify_order_owner" class="info">
          {{ "GENERIC.NO" | translate }}
        </div>
      </div>

      <div *ngIf="editMode" class="info-line" fxFlex="50%">
        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="shop.notify_order_owner"
          [ngModelOptions]="{ standalone: true }"
          class=""
        >
          {{ "SHOPS.NOTIFY_OWNER" | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
      <div *ngIf="!editMode" class="info-line" fxFlex="50%">
        <div class="title">{{ "SHOPS.NOTIFY_ADMIN" | translate }}</div>
        <div *ngIf="shop.notify_order_admin" class="info">
          {{ "GENERIC.YES" | translate }}
        </div>
        <div *ngIf="!shop.notify_order_admin" class="info">
          {{ "GENERIC.NO" | translate }}
        </div>
      </div>

      <div *ngIf="editMode" class="info-line" fxFlex="50%">
        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="shop.notify_order_admin"
          [ngModelOptions]="{ standalone: true }"
          class=""
        >
          {{ "SHOPS.NOTIFY_ADMIN" | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div
      *ngxPermissionsOnly="['GOD']"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start start"
    >
      <div *ngIf="!editMode" class="info-line" fxFlex="50%">
        <div class="title">{{ "SHOPS.NOTIFY_GOD" | translate }}</div>
        <div *ngIf="shop.notify_order_god" class="info">
          {{ "GENERIC.YES" | translate }}
        </div>
        <div *ngIf="!shop.notify_order_god" class="info">
          {{ "GENERIC.NO" | translate }}
        </div>
      </div>

      <div *ngIf="editMode" class="info-line" fxFlex="50%">
        <mat-checkbox
          labelPosition="after"
          color="accent"
          [(ngModel)]="shop.notify_order_god"
          [ngModelOptions]="{ standalone: true }"
          class=""
        >
          {{ "SHOPS.NOTIFY_GOD" | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
      <div *ngIf="!editMode" class="info-line" fxFlex="100%">
        <div class="title">
          {{ "SHOPS.EMAILS_TO_NOTIFY" | translate }}
          <button
            (click)="onShowConfirmedInfo()"
            class=""
            mat-icon-button
            type="button"
          >
            <mat-icon class="s-20" color="">help</mat-icon>
          </button>
        </div>

        <div class="info" *ngIf="shop.emails_to_notify">
          {{ shop.emails_to_notify | displayHtmlLinkPipe }}
        </div>

        <div *ngIf="!shop.emails_to_notify" class="info empty-value"></div>
      </div>
      <div *ngIf="editMode" class="info-line" fxFlex="100%">
        <div>
          <mat-form-field class="form-input-container" fxFlex="80%">
            <mat-label>{{ "SHOPS.EMAILS_TO_NOTIFY" | translate }}</mat-label>
            <input
              matInput
              type="text"
              id="notify_emails"
              [formControl]="notify_emails"
              placeholder="{{ 'GENERIC.EMAIL' | translate }}"
              autocomplete="nope"
              [(ngModel)]="shop.emails_to_notify"
            />
            <mat-error
              *ngIf="
                notify_emails.errors &&
                notify_emails.errors['unvalidEmail'] &&
                (notify_emails.dirty || notify_emails.touched)
              "
            >
              {{ notify_emails.errors["unvalidEmail"] }} est invalide</mat-error
            >
          </mat-form-field>
          <button
            (click)="onShowConfirmedInfo()"
            class=""
            mat-icon-button
            type="button"
          >
            <mat-icon class="s-20" color="">help</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start">
      <button
        type="submit"
        color="accent"
        class="save-btn"
        [disabled]="false"
        mat-raised-button
      >
        {{ "GENERIC.SAVE" | translate }}
      </button>
    </div>
  </form>
</div>
<!-- Box 3 -->
<div *ngIf="boxName === 'box_notification2'">
  <div fxLayout="row" fxLayoutAlign="start start" class="mb-12">
    <div fxFlex="50%" class="title-medium">
      {{ "SHOPS.NOTIFICATIONS_CORPO" | translate }}
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "SHOPS.NOTIFY_SALE" | translate }}</div>
      <div *ngIf="shop.notify_new_corpo_sale" class="info">
        {{ "GENERIC.YES" | translate }}
      </div>
      <div *ngIf="!shop.notify_new_corpo_sale" class="info">
        {{ "GENERIC.NO" | translate }}
      </div>
    </div>

    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <mat-checkbox
        labelPosition="after"
        color="accent"
        [(ngModel)]="shop.notify_new_corpo_sale"
        [ngModelOptions]="{ standalone: true }"
        class=""
      >
        {{ "SHOPS.NOTIFY_SALE" | translate }}
      </mat-checkbox>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "SHOPS.NOTIFY_OWNER" | translate }}</div>
      <div *ngIf="shop.notify_new_corpo_owner" class="info">
        {{ "GENERIC.YES" | translate }}
      </div>
      <div *ngIf="!shop.notify_new_corpo_owner" class="info">
        {{ "GENERIC.NO" | translate }}
      </div>
    </div>

    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <mat-checkbox
        labelPosition="after"
        color="accent"
        [(ngModel)]="shop.notify_new_corpo_owner"
        [ngModelOptions]="{ standalone: true }"
        class=""
      >
        {{ "SHOPS.NOTIFY_OWNER" | translate }}
      </mat-checkbox>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "SHOPS.NOTIFY_ADMIN" | translate }}</div>
      <div *ngIf="shop.notify_new_corpo_admin" class="info">
        {{ "GENERIC.YES" | translate }}
      </div>
      <div *ngIf="!shop.notify_new_corpo_admin" class="info">
        {{ "GENERIC.NO" | translate }}
      </div>
    </div>

    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <mat-checkbox
        labelPosition="after"
        color="accent"
        [(ngModel)]="shop.notify_new_corpo_admin"
        [ngModelOptions]="{ standalone: true }"
        class=""
      >
        {{ "SHOPS.NOTIFY_ADMIN" | translate }}
      </mat-checkbox>
    </div>
  </div>

  <div
    *ngxPermissionsOnly="['GOD']"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
  >
    <div *ngIf="!editMode" class="info-line" fxFlex="50%">
      <div class="title">{{ "SHOPS.NOTIFY_GOD" | translate }}</div>
      <div *ngIf="shop.notify_new_corpo_god" class="info">
        {{ "GENERIC.YES" | translate }}
      </div>
      <div *ngIf="!shop.notify_new_corpo_god" class="info">
        {{ "GENERIC.NO" | translate }}
      </div>
    </div>

    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <mat-checkbox
        labelPosition="after"
        color="accent"
        [(ngModel)]="shop.notify_new_corpo_god"
        [ngModelOptions]="{ standalone: true }"
        class=""
      >
        {{ "SHOPS.NOTIFY_GOD" | translate }}
      </mat-checkbox>
    </div>
  </div>
  <div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start">
    <button
      (click)="onSaveInfo()"
      color="accent"
      class="save-btn"
      [disabled]="false"
      mat-raised-button
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
  </div>
</div>
