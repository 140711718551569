<div id="dashboard" class="page-layout simple fullwidth" fxLayout="column">
  <!--Scroll tout la page et pas les contenus de la tab-->
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fusePerfectScrollbar
      style="min-width: 1023px"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1 mt-8">
          {{ "GENERIC.DASHBOARD_DETAILED" | translate }}
        </div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->
  <!-- SUB HEADER -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 content-subheader"
  >
    <div fxLayout="row" fxLayoutAlign="start center"></div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <app-create-order-buttons
        *ngIf="
          modulesService.modulesAreSet &&
          !modulesService.hasCTReport() &&
          !modulesService.hasRPReport()
        "
      ></app-create-order-buttons>
      <div
        *ngIf="modulesService.modulesAreSet && modulesService.hasQuickOrder()"
      >
        <button mat-button (click)="onQuickOrder()" class="">
          <mat-icon class="s-24">{{ getIcon("SHOP") }}</mat-icon>
          <span class="ml-8">{{ "GENERIC.QUICK_ORDER" | translate }}</span>
        </button>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      *ngIf="modulesService.modulesAreSet && modulesService.hasCTReport()"
    >
      <button
        *ngxPermissionsOnly="rolesService.SALE_ROLES"
        mat-button
        (click)="onCreateNewCongress()"
        class=""
      >
        <mat-icon class="s-24">{{ getIcon("CONGRESS") }}</mat-icon>
        <span class="ml-8">{{
          "GENERIC.CREATE_CONGRESS_TITLE" | translate
        }}</span>
      </button>

      <button
        *ngxPermissionsOnly="rolesService.SALE_ROLES"
        mat-button
        (click)="onGoToCongresses()"
        class=""
      >
        <mat-icon class="s-24">{{ getIcon("CONGRESS") }}</mat-icon>
        <span class="ml-8">{{ "GENERIC.CONGRESSES" | translate }}</span>
      </button>
    </div>
  </div>
  <!-- / SUB HEADER -->
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxFlex="100"
    [fusePerfectScrollbar]="false"
    style="min-width: 1023px"
  >
    <!--        [style]="{height: '724px'}"-->
    <!--        [style]="{width:'1400px'}"-->

    <div *ngIf="loading">
      <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="white"
        [fullScreen]="false"
        type="ball-scale-multiple"
      >
        <p style="font-size: 20px; color: white">
          {{ "DASHBOARD.LOADING" | translate }}
        </p>
      </ngx-spinner>
    </div>

    <p-table
      #primengTable
      [contextMenu]="cm"
      [(contextMenuSelection)]="cmSelectedEvent"
      [columns]="selectedColumns"
      [value]="primeNGData"
      [paginator]="true"
      [rows]="rowsPerPage"
      [loadingIcon]="'fa-spinner'"
      [scrollable]="false"
      scrollHeight="40vh"
      dataKey="name"
      [resizableColumns]="true"
      [reorderableColumns]="true"
      columnResizeMode="expand"
      selectionMode="single"
      [(selection)]="selectedEvent"
      (onRowSelect)="onRowSelect($event)"
      (onFilter)="onFilter($event)"
      stateStorage="local"
      stateKey="gt2test-local-36"
      (sortFunction)="customSort($event)"
      [customSort]="true"
      (onRowUnselect)="rowUnselect($event)"
      withUnsort
    >
      <!-- <ng-template pTemplate="caption">
                    <div class="p-d-flex">                        
                        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                    </div>
                </ng-template> -->

      <ng-template pTemplate="caption">
        <div
          fxLayout="row"
          fxLayout.xs="column"
          style="justify-content: space-between; width: 1600px"
        >
          <div fxLayout="column" fxLayoutAlign="start start">
            <mat-checkbox
              labelPosition="after"
              color="accent"
              class="mb-4"
              (ngModelChange)="onIncludeCancelAbortChange()"
              [ngModel]="includeCancelAbort"
              [ngModelOptions]="{ standalone: true }"
            >
              {{ "DASHBOARD.INCLUDE_CANCEL_ABORT" | translate }}
            </mat-checkbox>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <p-multiSelect
                [options]="primeNGCols"
                [(ngModel)]="selectedColumns"
                optionLabel="header"
                selectedItemsLabel="{{
                  'DASHBOARD.SELECTED_COLUMNS' | translate
                }}"
                (ngModelChange)="onColumnVisibilityChange($event)"
                [style]="{ minWidth: '200px' }"
                defaultLabel="{{ 'DASHBOARD.CHOOSE_COLUMNS' | translate }}"
              >
              </p-multiSelect>
            </div>
          </div>

          <div class="pr-36">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="range-picker-container gt2-theme-no-min-width"
            >
              <mat-icon
                (click)="onOpenRangePicker($event)"
                class="s-36 mr-12 button-cursor"
                >{{ getIcon("TIME") }}</mat-icon
              >
              <div fxLayout="column" fxLayoutAlign="center center">
                <mat-label>{{ "GENERIC.CHOOSE_A_DATE" | translate }}</mat-label>
                <input
                  matInput
                  type="text"
                  ngxDaterangepickerMd
                  [(ngModel)]="selected"
                  [showCustomRangeLabel]="true"
                  [alwaysShowCalendars]="true"
                  [ranges]="ranges"
                  [linkedCalendars]="true"
                  class="datepicker-content"
                  [showCancel]="true"
                  [locale]="localeDate"
                  (ngModelChange)="updateRange($event)"
                />
              </div>
              <!-- <ngx-mat-drp
                (selectedDateRangeChanged)="updateRange($event)"
                [options]="rangeOptions"
                #dateRangePicker
              ></ngx-mat-drp> -->
            </div>
          </div>
          <div
            *ngIf="moduleService.modulesAreSet && !moduleService.hasCTReport()"
            fxLayout="row"
            fxLayoutAlign="end center"
          >
            <div class="total-selling-badge">
              {{ "DASHBOARD.TOTAL_SELLING" | translate }}: ${{
                sellingTotal | number: "1.2-2" : "en-CA"
              }}
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="end center">
            <div *ngIf="extra" style="margin-right: 1px">
              <div
                fxLayout="row"
                fxLayoutAlign="end center"
                class="header-card-label"
              >
                {{ "DASHBOARD.GUEST_COUNT" | translate }}
                <div class="header-card-number red-fg mb-4">
                  &nbsp;{{ extra.total_guest_count }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="end center"
                class="header-card-label"
              >
                {{ "DASHBOARD.EVENTS_TOTAL" | translate }}
                <div class="header-card-number red-fg mb-4">
                  &nbsp;{{ extra.total_event }}
                </div>
              </div>
            </div>
            <div class="p-d-flex ml-12">
              <button
                mat-icon-button
                [matMenuTriggerFor]="exportMenu"
                *ngIf="fileService.excelEnabled()"
                matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
                class=""
              >
                <mat-icon
                  fontSet="fa"
                  fontIcon="fa-table"
                  class="fa-2x"
                ></mat-icon>
              </button>

              <mat-menu #exportMenu="matMenu">
                <button mat-menu-item (click)="exportExcel()">
                  <!-- <mat-icon>format_clear</mat-icon> -->
                  <span>{{ "DASHBOARD.EXPORT_XLS" | translate }}</span>
                </button>

                <button mat-menu-item (click)="exportFilteredExcel()">
                  <!-- <mat-icon>format_clear</mat-icon> -->
                  <span>{{ "DASHBOARD.EXPORT_FILTERES_XLS" | translate }}</span>
                </button>
              </mat-menu>
            </div>
            <div style="margin-right: -14px">
              <button
                mat-icon-button
                [disableRipple]="true"
                [matMenuTriggerFor]="resetMenu"
              >
                <mat-icon class="s-16">more_vert</mat-icon>
              </button>
              <mat-menu #resetMenu="matMenu">
                <button
                  mat-menu-item
                  matTooltip="{{ 'DASHBOARD.RESET_TABLE_MESSAGE' | translate }}"
                  [matTooltipPosition]="'below'"
                  (click)="onResetTable()"
                >
                  <mat-icon>format_clear</mat-icon>
                  <span>{{ "DASHBOARD.RESET_TABLE" | translate }}</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <!-- <col *ngFor="let col of columns" [style.width]="col.colWidth"> -->
          <col
            *ngFor="let col of columns"
            [style.width]="col && col.colWidth ? col.colWidth : ''"
          />
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            *ngFor="let col of columns"
            [pSortableColumn]="col?.field"
            [pSortableColumnDisabled]="!col?.sortable"
            pResizableColumn
            pReorderableColumn
          >
            {{ col?.header }}
          </th>
        </tr>

        <tr *ngIf="useColumnsFilter">
          <th
            *ngFor="let col of columns"
            [ngSwitch]="col?.field"
            class="ui-fluid"
          >
            <div *ngIf="col?.showFilter">
              <input
                pInputText
                type="text"
                *ngSwitchDefault
                [disabled]="!col.showFilter"
              />
              <!-- <input
                pInputText
                type="text"
                *ngSwitchDefault
                [disabled]="!col.showFilter"
                (input)="
                  primengTable.filter(
                    $event.target.value,
                    col?.field,
                    'contains'
                  )
                "
                [value]="primengTable.filters[col.field].value"
              /> -->

              <!-- delivery_type -->
              <p-dropdown
                *ngSwitchCase="'delivery_type'"
                appendTo="body"
                [options]="deliveryTypes"
                [(ngModel)]="deliveryTypesModel"
                [style]="{
                  width: '100%',
                  height: '55px',
                  borderWidth: '0px',
                  borderBottomWidth: '1px',
                }"
                (onChange)="
                  primengTable.filter(
                    $event.value,
                    'delivery_type_id',
                    'equals'
                  );
                  onDeliveryTypeChange($event.value)
                "
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="item">
                    <mat-icon
                      class="mr-8"
                      fontSet="fa"
                      [fontIcon]="item.icon"
                    ></mat-icon>
                    <span class="">{{ item.label }}</span>
                  </div>
                </ng-template>
                <ng-template let-delivery pTemplate="item">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon
                      class="mr-8"
                      fontSet="fa"
                      fontIcon="{{ delivery.icon }}"
                    ></mat-icon>
                    <span class="">{{ delivery.label }}</span>
                  </div>
                </ng-template>
              </p-dropdown>

              <!-- status_label -->
              <p-dropdown
                *ngSwitchCase="'status_label'"
                appendTo="body"
                [options]="status"
                [(ngModel)]="statusModel"
                [style]="{
                  width: '100%',
                  height: '55px',
                  borderWidth: '0px',
                  borderBottomWidth: '1px',
                }"
                (onChange)="
                  primengTable.filter($event.value, 'status', 'equals');
                  onStatusChange($event.value)
                "
              >
              </p-dropdown>

              <!-- event_logistic_status -->
              <p-dropdown
                *ngSwitchCase="'event_logistic_status'"
                appendTo="body"
                [options]="eventLogisticStatus"
                [(ngModel)]="eventLogisticStatusModel"
                (onChange)="onEventLogisticStatusChange($event)"
                [style]="{
                  width: '100%',
                  height: '55px',
                  borderWidth: '0px',
                  borderBottomWidth: '1px',
                }"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    *ngIf="item.value === '0'"
                    [ngStyle]="{
                      margin: '0px',
                      minWidth: '160px',
                      height: '30px',
                      'background-color': item.color_code,
                    }"
                  >
                    <div [ngStyle]="{ color: '#000000', padding: '7px' }">
                      {{ item.label }}
                    </div>
                  </div>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    *ngIf="item.value !== '0'"
                    [ngStyle]="{
                      margin: '0px',
                      minWidth: '160px',
                      height: '30px',
                      'background-color': item.color_code,
                    }"
                  >
                    <div [ngStyle]="{ color: '#FFFFFF', padding: '7px' }">
                      {{ item.label }}
                    </div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    *ngIf="item.value === '0'"
                    [ngStyle]="{
                      margin: '5px',
                      minWidth: '160px',
                      height: '30px',
                      'background-color': item.color_code,
                    }"
                  >
                    <div [ngStyle]="{ color: '#000000', padding: '7px' }">
                      {{ item.label }}
                    </div>
                  </div>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    *ngIf="item.value !== '0'"
                    [ngStyle]="{
                      margin: '5px',
                      minWidth: '160px',
                      height: '30px',
                      'background-color': item.color_code,
                    }"
                  >
                    <div [ngStyle]="{ color: '#FFFFFF', padding: '7px' }">
                      {{ item.label }}
                    </div>
                  </div>
                </ng-template>
              </p-dropdown>

              <!-- status_order_label -->
              <p-dropdown
                *ngSwitchCase="'status_order_label'"
                appendTo="body"
                [options]="ordersStatus"
                [(ngModel)]="ordersStatusModel"
                [style]="{
                  width: '100%',
                  height: '55px',
                  borderWidth: '0px',
                  borderBottomWidth: '1px',
                }"
                (onChange)="onOrderStatusChange($event)"
              >
                <ng-template let-item pTemplate="item">
                  <span
                    *ngIf="
                      item.value === 'ORDER' ||
                      item.value === 'PROPOSAL' ||
                      item.value === 'INVOICE' ||
                      item.value === null
                    "
                  >
                    <b>{{ item.label }}</b>
                  </span>
                  <span
                    *ngIf="
                      item.value !== 'ORDER' &&
                      item.value !== 'PROPOSAL' &&
                      item.value !== 'INVOICE' &&
                      item.value !== null
                    "
                  >
                    {{ item.label }}
                  </span>
                </ng-template>
              </p-dropdown>

              <!-- GUEST COUNT -->
              <div *ngSwitchCase="'guest_count'">
                {{ "DASHBOARD.SLIDER_LABEL" | translate }}
                {{ guestsSliderValue }}
                <mat-icon
                  class="s-16"
                  (click)="
                    guestsSliderValue = null;
                    primengTable.filter(null, col?.field, col?.filterMatchMode)
                  "
                  style="cursor: pointer"
                  *ngIf="guestsSliderValue !== null"
                  >close</mat-icon
                >
                <p-slider
                  [style]="{ width: '100%', 'margin-top': '8px' }"
                  [(ngModel)]="guestsSliderValue"
                  [min]="0"
                  [max]="maxGuestsCount"
                  (onChange)="onGuestChange($event, primengTable)"
                >
                </p-slider>
              </div>
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData" [pContextMenuRow]="rowData">
          <td *ngFor="let col of columns" [ngClass]="col?.applyCSS">
            <div>
              <div
                *ngIf="
                  col?.field === 'delivery_type';
                  then delivery_type_content
                "
              ></div>
              <div
                *ngIf="
                  col?.field === 'event_logistic_status';
                  then event_logistic_status_content
                "
              ></div>
              <div
                *ngIf="col?.field === 'event_type'; then event_type_content"
              ></div>
              <div
                *ngIf="
                  col?.field === 'event_date_label';
                  then event_date_content
                "
              ></div>
              <div
                *ngIf="
                  col?.field === 'event_date_end_label';
                  then event_date_end_content
                "
              ></div>
              <div
                *ngIf="
                  col?.field === 'created_at_label';
                  then created_at_content
                "
              ></div>
              <div
                *ngIf="col?.field === 'payment_mode'; then payment_mode"
              ></div>
              <div
                *ngIf="
                  col?.field === 'event_time_label';
                  then event_time_content
                "
              ></div>
              <div
                *ngIf="
                  col?.field === 'event_time_end_label';
                  then event_time_end_content
                "
              ></div>
              <div *ngIf="col?.field === 'name'; then name_content"></div>
              <div *ngIf="col?.field === 'room_name'; then room_content"></div>
              <div
                *ngIf="col?.field === 'event_number'; then event_number_content"
              ></div>
              <div
                *ngIf="col?.field === 'guest_count'; then guest_count_content"
              ></div>
              <div
                *ngIf="col?.field === 'order_name'; then order_name_content"
              ></div>
              <div
                *ngIf="col?.field === 'po_number'; then po_number_content"
              ></div>
              <div
                *ngIf="
                  col?.field === 'invoicing_client_name';
                  then invoicing_client_name_content
                "
              ></div>
              <div
                *ngIf="
                  col?.field === 'ordering_client_name';
                  then ordering_client_name_content
                "
              ></div>
              <div
                *ngIf="
                  col?.field === 'client_enterprise_name';
                  then client_enterprise_name
                "
              ></div>
              <div
                *ngIf="
                  col?.field === 'enterprise_name';
                  then enterprise_name_content
                "
              ></div>
              <div
                *ngIf="col?.field === 'status_label'; then status_label_content"
              ></div>
              <div
                *ngIf="
                  col?.field === 'status_order_label';
                  then status_order_label_content
                "
              ></div>
              <div
                *ngIf="col?.field === 'invoice_code'; then invoice_code_content"
              ></div>
              <div
                *ngIf="col?.field === 'order_code'; then order_code_content"
              ></div>
              <div
                *ngIf="
                  col?.field === 'responsible_employee_name';
                  then responsible_employee_name_content
                "
              ></div>
              <div *ngIf="col?.field === 'balance'; then balance_content"></div>
              <div *ngIf="col?.field === 'total_sub_1'; then total_sub_1"></div>
              <div *ngIf="col?.field === 'total'; then total"></div>
              <div
                *ngIf="col?.field === 'congress_name'; then congress_name"
              ></div>
              <div
                *ngIf="col?.field === 'congress_number'; then congress_number"
              ></div>
              <div
                *ngIf="col?.field === 'project_name'; then project_name"
              ></div>

              <!-- delivery_type_content -->
              <ng-template #delivery_type_content>
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  matTooltip="{{ rowData[col?.field] }}"
                  [matTooltipPosition]="'right'"
                >
                  <mat-icon
                    class="mx-12 fa-lg"
                    *ngIf="rowData.delivery_type_id === 1"
                    fontSet="fa"
                    fontIcon="fa-shipping-fast"
                  >
                  </mat-icon>
                  <mat-icon
                    class="mx-12 fa-lg"
                    *ngIf="rowData.delivery_type_id === 2"
                    fontSet="fa"
                    fontIcon="fa-utensils"
                  >
                  </mat-icon>
                  <mat-icon
                    class="mx-12 fa-lg"
                    *ngIf="rowData.delivery_type_id === 3"
                    fontSet="fa"
                    fontIcon="fa-users"
                  >
                  </mat-icon>
                  <mat-icon
                    class="mx-12 fa-lg"
                    *ngIf="rowData.delivery_type_id === 4"
                    fontSet="fa"
                    fontIcon="fa-concierge-bell"
                  >
                  </mat-icon>
                </div>
              </ng-template>
              <!-- event_logistic_status_content -->
              <ng-template #event_logistic_status_content>
                <div
                  fxLayoutAlign="center center"
                  [ngStyle]="{
                    padding: '5px',
                    margin: '5px',
                    minWidth: '160px',
                    minHeight: '30px',
                    'background-color':
                      rowData.event_logistic_status.color_code,
                  }"
                >
                  <div [ngStyle]="{ color: '#FFFFFF', padding: '7px' }">
                    {{ rowData.event_logistic_status.label }}
                  </div>
                </div>
              </ng-template>
              <!-- event_type_content -->
              <ng-template #event_type_content>
                {{ rowData[col?.field] }}
              </ng-template>
              <!-- event_date_content -->
              <ng-template #event_date_content>
                <div
                  (click)="
                    onDateClickTest($event, rowData[col?.field]);
                    $event.stopPropagation()
                  "
                >
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>
              <ng-template #event_date_end_content>
                {{ rowData[col?.field] }}
              </ng-template>
              <ng-template #created_at_content>
                {{ rowData[col?.field] }}
              </ng-template>
              <ng-template #payment_mode>
                {{ rowData[col?.field] }}
              </ng-template>
              <ng-template #event_time_content>
                {{ rowData[col?.field] }}
              </ng-template>
              <ng-template #event_time_end_content>
                {{ rowData[col?.field] }}
              </ng-template>
              <ng-template #name_content>
                {{ rowData[col?.field] }}
              </ng-template>
              <ng-template #room_content>
                {{ rowData[col?.field] }}
              </ng-template>
              <ng-template #event_number_content>
                {{ rowData[col?.field] }}
              </ng-template>
              <ng-template #event_number_content>
                {{ rowData[col?.field] }}
              </ng-template>
              <ng-template #guest_count_content>
                {{ rowData[col?.field] }}
              </ng-template>
              <ng-template #order_name_content>
                <div (click)="onOpenOrder(rowData); $event.stopPropagation()">
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>
              <ng-template #po_number_content>
                {{ rowData[col?.field] }}
              </ng-template>
              <ng-template #invoicing_client_name_content>
                <div
                  (click)="
                    onOpenInvoicingClient(rowData); $event.stopPropagation()
                  "
                >
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>
              <ng-template #ordering_client_name_content>
                <div (click)="onOpenClient(rowData); $event.stopPropagation()">
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>
              <ng-template #client_enterprise_name>
                <div
                  (click)="onOpenEnterprise(rowData); $event.stopPropagation()"
                >
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>

              <ng-template #enterprise_name_content>
                <div
                  (click)="onOpenEnterprise(rowData); $event.stopPropagation()"
                >
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>
              <ng-template #status_label_content>
                <div (click)="onOpenOrder(rowData); $event.stopPropagation()">
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>

              <ng-template #status_order_label_content>
                <div class="gt2-order-theme-no-min-width" fxFlex>
                  <button
                    fxFlex
                    mat-raised-button
                    [ngClass]="getOrderTypeColor(rowData)"
                    (click)="onOpenOrder(rowData)"
                  >
                    <mat-icon
                      *ngIf="rowData.current_order_type === 'ORDER'"
                      class="mr-8 s-20"
                      >{{ getIcon("ORDER") }}</mat-icon
                    >
                    <mat-icon
                      *ngIf="rowData.current_order_type === 'PROPOSAL'"
                      class="mr-8 s-20"
                      >{{ getIcon("PROPOSAL") }}
                    </mat-icon>
                    <mat-icon
                      *ngIf="rowData.current_order_type === 'INVOICE'"
                      class="mr-8 s-20"
                      >{{ getIcon("INVOICE") }}
                    </mat-icon>
                    {{ rowData[col?.field] }}
                  </button>
                </div>
              </ng-template>
              <ng-template #invoice_code_content>
                <div (click)="onOpenOrder(rowData); $event.stopPropagation()">
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>
              <ng-template #order_code_content>
                <div (click)="onOpenOrder(rowData); $event.stopPropagation()">
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>
              <ng-template #responsible_employee_name_content>
                <div
                  (click)="onOpenEmployee(rowData); $event.stopPropagation()"
                >
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>
              <ng-template #balance_content>
                ${{ rowData[col?.field] | number: "1.2-2" : "en-CA" }}
              </ng-template>
              <ng-template #total_sub_1>
                ${{ rowData[col?.field] | number: "1.2-2" : "en-CA" }}
              </ng-template>

              <ng-template #total>
                ${{ rowData[col?.field] | number: "1.2-2" : "en-CA" }}
              </ng-template>

              <ng-template #congress_name>
                <div (click)="onOpenCongres(rowData); $event.stopPropagation()">
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>
              <ng-template #congress_number>
                <div (click)="onOpenCongres(rowData); $event.stopPropagation()">
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>

              <ng-template #project_name>
                <div (click)="onOpenProject(rowData); $event.stopPropagation()">
                  {{ rowData[col?.field] }}
                </div>
              </ng-template>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer" let-columns>
        <ng-container *ngIf="primeNGData && primengTable.isEmpty()">
          <tr>
            <td
              [attr.colspan]="columns.length"
              style="
                padding-top: 36px;
                padding-bottom: 36px;
                padding-left: 50px;
              "
            >
              {{ "DASHBOARD.NO_RECORDS" | translate }}
            </td>
          </tr>
        </ng-container>
      </ng-template>

      <!--            <ng-template pTemplate="emptymessage" let-columns>-->
      <!--                <tr *ngIf="!loading">-->
      <!--                    <td [attr.colspan]="columns.length" style="text-align: center; font-weight: bold; padding-top: 36px; padding-bottom: 36px;">-->
      <!--                        {{ 'DASHBOARD.NO_RECORDS' | translate}}-->
      <!--                    </td>-->
      <!--                </tr>-->
      <!--                <tr *ngIf="loading">-->
      <!--                    <td [attr.colspan]="columns.length" style="text-align: center; font-weight: bold; padding-top: 36px; padding-bottom: 36px;">-->
      <!--                        {{ 'DASHBOARD.LOADING' | translate}}-->
      <!--                    </td>-->
      <!--                </tr>-->
      <!--            </ng-template>-->
    </p-table>

    <p-contextMenu
      #cm
      appendTo="body"
      [(ngModel)]="rowsContextMenu"
    ></p-contextMenu>
  </div>
</div>
