import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { locale as english } from '../../../../../localization/i18n/en-CA';
import { locale as french } from '../../../../../localization/i18n/fr-CA';
import { FuseConfigService } from '../../../../../../@fuse/services/config.service';
import { FuseNavigationService } from '../../../../../../@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '../../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '../../../../../../@fuse/components/sidebar/sidebar.service';
import {
  OrganizationModel,
  OrganizationModelData,
} from '../../../../../gt2/api/models/OrganizationModel';
import { CrudOrganizationService } from '../../../../../gt2/content/crud/crud-organization/crud-organization.service';
import { AuthenticationService } from '../../../../../gt2/api/auth-service.service';
import { CrudEmployeeService } from '../../../../../gt2/content/crud/crud-employee/crud-employee.service';
import { AppRoutes } from '../../../../../gt2/const/AppRoutes';
import { NGXLogger } from 'ngx-logger';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { LocalizationConfig } from '../../../../../localization/LocalizationConfig';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../../@fuse/pipes/pipes.module';
import { FuseNavigationModule } from '../../../../../../@fuse/components';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'navbar-vertical-style-1',
  templateUrl: './style-1.component.html',
  styleUrls: ['./style-1.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatButtonModule,
    TranslateModule,
    MatSelectModule,
    NgxPermissionsModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    CommonModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    FuseNavigationModule,
  ],
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
  organization!: OrganizationModel;
  organizations!: OrganizationModelData[];
  selectedOrganization: OrganizationModelData | undefined;
  me: any;

  //
  fuseConfig: any;
  navigation: any;

  // Private
  private _fusePerfectScrollbar!: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;

  /*
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {Router} _router
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private auth: AuthenticationService,
    public logger: NGXLogger,
    private _router: Router,
    private orgService: CrudOrganizationService,
    private employeeService: CrudEmployeeService,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
  ) {
    // Initialisé la langue
    const local_lang =
      localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID) ||
      LocalizationConfig.DEFAULT_LANGUAGE;

    // Ajouter les traductions locales
    if (local_lang === 'en-CA')
      translate.setTranslation(english.lang, english.data, true);
    else translate.setTranslation(french.lang, french.data, true);

    // Définir la langue à utiliser
    translate.setDefaultLang(LocalizationConfig.DEFAULT_LANGUAGE);
    translate.use(local_lang);
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.orgService.getOrganization().subscribe((organization) => {
      // this.logger.info("NavbarVerticalStyle1Component -> this.orgService.getOrganization().subscribe() " + JSON.stringify(organization));

      if (organization.data) {
        this.organization = organization;
        // this.organization.data.logo = "test/test.jpg";
        // this.selectedOrganization = this.organization.data;
        this.setSeletcedOrganisation();
      }
    });

    this.orgService.getAllOrganizations().subscribe((organizations) => {
      // this.logger.info("NavbarVerticalStyle1Component -> this.orgService.getAllOrganizations().subscribe() SUCCESS: " + JSON.stringify(organizations));
      this.organizations = organizations.data;
      this.setSeletcedOrganisation();
    });

    this.employeeService
      .loadMe()
      .pipe(
        tap((response) => {
          // this.logger.info("NavbarVerticalStyle1Component -> loadMe() -> SUCCESS: " + JSON.stringify(response));
          this.me = response;
        }),
        catchError((error: any): any => {
          this.logger.error(
            'NavbarVerticalStyle1Component -> loadMe() -> ERROR: ' +
              JSON.stringify(error),
          );
        }),
      )
      .subscribe();

    // this.auth.loadAppUser().subscribe(user =>
    // {
    //     this.logger.info("NavbarVerticalStyle1Component -> this.auth.loadAppUser() -> SUCCESS: " + JSON.stringify(user));
    //     // this.user = user[0];
    // });
  }

  public setSeletcedOrganisation(): void {
    if (this.organizations && this.organization) {
      this.selectedOrganization = this.organizations.find((org) => {
        return org.uuid === this.organization.data.uuid;
      });
      // this.logger.info("NavbarVerticalStyle1Component.setSeletcedOrganisation() -> this.selectedOrganization: " + JSON.stringify(this.selectedOrganization))
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Directive
  @ViewChild(FusePerfectScrollbarDirective, { static: true })
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this._fuseNavigationService.onItemCollapseToggled
      .pipe(delay(500), takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._fusePerfectScrollbar.update();
      });

    // Scroll to the active item position
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        setTimeout(() => {
          const activeNavItem: any = document.querySelector(
            'navbar .nav-link.active',
          );

          if (activeNavItem) {
            const activeItemOffsetTop = activeNavItem.offsetTop,
              activeItemOffsetParentTop = activeNavItem.offsetParent.offsetTop,
              scrollDistance =
                activeItemOffsetTop - activeItemOffsetParentTop - 48 * 3 - 168;

            this._fusePerfectScrollbar.scrollToTop(scrollDistance);
          }
        });
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe(() => {
        if (this._fuseSidebarService.getSidebar('navbar')) {
          this._fuseSidebarService.getSidebar('navbar').close();
        }
      });

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: any) => {
        this.fuseConfig = config;
      });

    // Get current navigation
    this._fuseNavigationService.onNavigationChanged
      .pipe(
        filter((value) => value !== null),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe(() => {
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar opened status
   */
  toggleSidebarOpened(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleOpen();
  }

  /**
   * Toggle sidebar folded status
   */
  toggleSidebarFolded(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleFold();
  }

  //
  public onSelectionChange(event: any): void {
    //this.logger.info("NavbarVerticalStyle1Component.onSelectionChange() -> event: " + JSON.stringify(event.value));
    // this.logger.info(JSON.stringify(this.me));
    // this.logger.info(this.me.user.uuid);

    this.auth
      .updateAppUser({ organization: event.value }, this.me.user.uuid)
      .subscribe(
        (user) => {
          // this.logger.info("NavbarVerticalStyle1Component -> auth.updateAppUser() -> SUCCESS: " + JSON.stringify(user));
          // this.refreshOrganization();
          this._router.navigateByUrl(AppRoutes.DASHBOARD);
          window.location.reload();
        },
        (error) => {
          this.logger.error(
            'NavbarVerticalStyle1Component -> auth.updateAppUser() -> ERROR: ' +
              JSON.stringify(error),
          );
        },
      );
  }

  public refreshOrganization(): void {
    // this.orgService.loadOrganization();

    this.orgService.loadOrganization().subscribe((organizations) => {
      //this.logger.info("NavbarVerticalStyle1Component.refreshOrganization() SUCCESS: " + JSON.stringify(organizations));
      // this.organizations = organizations.data;
    });
  }

  public navigateToDashboard(): void {
    this._router.navigateByUrl(AppRoutes.DASHBOARD);
  }
}
