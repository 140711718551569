import { DatatableHelperService } from '../../services/datatable-helper.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupAssigmentsCompletePipe',
})
export class GroupAssigmentsCompletePipe implements PipeTransform {
  constructor(private tableHelper: DatatableHelperService) {}

  transform(group: any): boolean {
    const assignedJobs = group.jobs.filter((item: any) => {
      return item.employee !== null || item.staff_provider != null;
    });
    return (
      group.details.job_count > 0 &&
      group.details.job_count === assignedJobs.length
    );
  }
}
