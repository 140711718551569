<div style="max-height: 100%; overflow: auto">
  <div [formGroup]="form" fxLayout="column" fxLayoutAlign="start center">
    <h2>{{ "PRODUCTS.CREATE_OPTIONS_GROUP_TITLE" | translate }}</h2>
    <mat-form-field class="create-client-width">
      <mat-label>{{ "PRODUCTS.OPTIONS_GROUP_NAME" | translate }}</mat-label>
      <input
        matInput
        fxFlex="100%"
        placeholder="{{ 'PRODUCTS.OPTIONS_GROUP_NAME' | translate }}"
        formControlName="group_name"
        type="text"
        autocomplete="nope"
        [(ngModel)]="group_name"
        required
      />
      <mat-error *ngIf="form.controls['group_name'].invalid">{{
        "GENERIC.INPUT_LENGTH_INVALID" | translate
      }}</mat-error>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="center center">
      <button
        mat-raised-button
        class="accent save-btn mr-16"
        [disabled]="!form.valid"
        (click)="onCreateOptionsGroup()"
      >
        {{ "PRODUCTS.CREATE_OPTIONS_GROUP" | translate }}
      </button>

      <button
        mat-raised-button
        class="primary"
        (click)="dialogRef?.close(false)"
      >
        {{ "GENERIC.CANCEL" | translate }}
      </button>
    </div>

    <!-- [matMenuTriggerFor]="menuGroupOptions" -->

    <!-- <mat-menu #menuGroupOptions="matMenu">
            <button mat-menu-item (click)="onCreateOptionsGroup()"
            > creer une selection de produits</button>

            <button mat-menu-item (click)="onCreateMaterialsGroup()"
            > creer une selection de materiels</button>

        </mat-menu> -->
  </div>
</div>
