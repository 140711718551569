export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_GUEST: {
      TITLE: "Nouveau convive",
      EDIT_TITLE: "Modifier le convive",
      SELECT_GUEST: "Définir ou sélectionner un convive",
      // 'FOR_CLIENT_ONLY': 'Pour clients seulement',
    },
  },
};
