export const locale = {
  lang: "fr-CA",
  data: {
    FORGOT_PASSWORD: {
      WELCOME_MESSAGE: "Bienvenue chez Gestion-Traiteur!",
      LONG_WELCOME_MESSAGE: 'Solution logiciel tout-en-un "du web à la cuisine"',
      LONG_WELCOME_MESSAGE_2: "Centraliser – Simplifier – Rentabiliser",
      RECOVER_PASSWORD: "Mot de passe",
      EMAIL_REQUIRED: "Courriel obligatoire",
      EMAIL_INVALID: "Le courriel doit être valide",
      SEND_RESET_LINK: "Envoyer le lien de réinitialisation",
      LINK_SENT_MESSAGE: "Un courriel a été envoyé à l'adresse que vous avez entré",
      BACK_LOGIN: "Retouner à l'ouverture de session",
    },
  },
};
