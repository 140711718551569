<div style="min-width: 350px" class="">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">
        {{ "SHOPS.GP_MERCHANT_ID" | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="info text-truncate">
          {{ globalPaymentsModel.merchant_id }}
        </div>
      </div>
      <div
        *ngIf="!globalPaymentsModel.merchant_id"
        class="info empty-value"
      ></div>
    </div>

    <div *ngIf="editMode" [formGroup]="formGP" class="info-line" fxFlex="100%">
      <mat-form-field class="theme-selector" style="width: 90%">
        <mat-label>{{ "SHOPS.GP_MERCHANT_ID" | translate }}</mat-label>
        <input
          [(ngModel)]="globalPaymentsModel.merchant_id"
          autocomplete="nope"
          matInput
          formControlName="merchant_id"
          placeholder="{{ 'SHOPS.GP_MERCHANT_ID' | translate }}"
          type="text"
        />
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">
        {{ "SHOPS.GP_ACCOUNT_ID" | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="info text-truncate">
          {{ globalPaymentsModel.account_id }}
        </div>
      </div>
      <div
        *ngIf="!globalPaymentsModel.account_id"
        class="info empty-value"
      ></div>
    </div>

    <div *ngIf="editMode" [formGroup]="formGP" class="info-line" fxFlex="100%">
      <mat-form-field class="theme-selector" style="width: 90%">
        <mat-label>{{ "SHOPS.GP_ACCOUNT_ID" | translate }}</mat-label>
        <input
          [(ngModel)]="globalPaymentsModel.account_id"
          autocomplete="nope"
          matInput
          formControlName="account_id"
          placeholder="{{ 'SHOPS.GP_ACCOUNT_ID' | translate }}"
          type="text"
        />
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">
        {{ "SHOPS.GP_SHARED_SECRET" | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="info text-truncate">
          {{ globalPaymentsModel.shared_secret }}
        </div>
      </div>
      <div
        *ngIf="!globalPaymentsModel.shared_secret"
        class="info empty-value"
      ></div>
    </div>

    <div *ngIf="editMode" [formGroup]="formGP" class="info-line" fxFlex="100%">
      <mat-form-field class="theme-selector" style="width: 90%">
        <mat-label>{{ "SHOPS.GP_SHARED_SECRET" | translate }}</mat-label>
        <input
          [(ngModel)]="globalPaymentsModel.shared_secret"
          autocomplete="nope"
          matInput
          formControlName="shared_secret"
          placeholder="{{ 'SHOPS.GP_SHARED_SECRET' | translate }}"
          type="text"
        />
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">
        {{ "SHOPS.GP_REABATE_PASSWORD" | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="info text-truncate">
          {{ globalPaymentsModel.rebate_password }}
        </div>
      </div>
      <div
        *ngIf="!globalPaymentsModel.rebate_password"
        class="info empty-value"
      ></div>
    </div>

    <div *ngIf="editMode" [formGroup]="formGP" class="info-line" fxFlex="100%">
      <mat-form-field class="theme-selector" style="width: 90%">
        <mat-label>{{ "SHOPS.GP_REABATE_PASSWORD" | translate }}</mat-label>
        <input
          [(ngModel)]="globalPaymentsModel.rebate_password"
          autocomplete="nope"
          matInput
          formControlName="rebate_password"
          placeholder="{{ 'SHOPS.GP_REABATE_PASSWORD' | translate }}"
          type="text"
        />
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start">
    <button
      (click)="onSaveGlobalPaymentsInfo()"
      color="accent"
      class="save-btn"
      [disabled]="formGP.invalid"
      mat-raised-button
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
  </div>
</div>
