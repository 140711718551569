import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { map, tap, shareReplay } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { AppRoutes } from '../const/AppRoutes';
import { JwtInterceptor } from '../interceptors/JwtInterceptor';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import { UserModelResponse } from './models/UserModel';

@Injectable()
export class AuthenticationService {
  private loginSuccessSubject: Subject<any> = new Subject();
  private userSubject: Subject<UserModelResponse> = new Subject();
  private user?: UserModelResponse | null;
  loginSuccess = this.loginSuccessSubject.asObservable();
  logoutSubject: Subject<any> = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public login(email: string, password: string, userAgent?: any): any {
    // this.logger.info("AuthenticationService.login() -> email: " + email);
    // this.logger.info("AuthenticationService.login() -> password: " + password);

    return this.http
      .post<any>(this.api.getLoginURL(), {
        email: email,
        password: password,
        user_agent: userAgent ? userAgent : null,
      })
      .pipe(tap((res) => this.setSession(res)))
      .pipe(shareReplay());
  }

  public register(email: string, locale: string): any {
    // this.logger.info("AuthenticationService.register() -> email: " + email);
    return this.http.post<any>(this.api.getRegistrationUrl(), {
      email: email,
      userLocale: locale,
    });
  }

  public finalizedRegister(data: Object): any {
    return this.http.post<any>(this.api.getFinalRegistrationUrl(), data);
  }

  public finalizedInvite(data: Object): any {
    return this.http.post<any>(this.api.getFinalInviteUrl(), data);
  }

  public forgotPassword(email: string) {
    // this.logger.info("AuthenticationService.forgotPassword() -> email: " + email);
    return this.http.post<any>(this.api.getForgotPasswordUrl(), {
      email: email,
    });
  }

  public resetPassword(email: string, password: string, token: string) {
    // this.logger.info("AuthenticationService.resetPassword() -> email: " + email);
    return this.http.post<any>(this.api.getResetPasswordUrl(), {
      email: email,
      password: password,
      token: token,
    });
  }

  public loadUser(): Observable<UserModelResponse> {
    // this.logger.info("AuthenticationService.loadUser()");
    const url2: string = this.api.getUserProfileUrl();

    return this.http
      .get<any>(url2)
      .pipe(map((response) => response.data))
      .pipe(tap((result) => this.setUser(result)));
  }

  public loadAppUser(): Observable<UserModelResponse> {
    // this.logger.info("AuthenticationService.loadUser()");
    const url2: string = this.api.getAppUserProfileUrl();
    return this.http
      .get<any>(url2)
      .pipe(map((response) => response.data))
      .pipe(tap((result) => this.setUser(result)));
  }

  public updateUser(user: any, userUUID: string) {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.UPDATE_EMPLOYEE) + userUUID,
      user
    );
  }

  public updateAppUser(user: any, userUUID: string) {
    JwtInterceptor.LAST_URL_401 = '';
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.USERS) + '/' + userUUID,
      user
    );
  }

  public logout(navigateToLoginPage: boolean = true) {
    // this.logger.info("AuthenticationService.logout()");
    return this.http
      .delete<any>(this.api.getLogoutURL())
      .pipe(map((response) => response.data))
      .pipe(tap((result) => this.doLocalLogout(result, navigateToLoginPage)));
  }

  public softLogout(): void {
    this.doLocalLogout(null, true);
  }

  public doLocalLogout(result: any, navigateToLoginPage: boolean): void {
    // this.logger.info("AuthenticationService.logout() -> result: " + result);
    // this.logger.info("AuthenticationService.logout() -> navigateToLoginPage: " + navigateToLoginPage);
    localStorage.removeItem('expires_at');
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
    // this.cookieService.delete("id_token");
    this.logoutSubject.next(true);
    this.user = null;

    if (navigateToLoginPage) {
      this.router.navigateByUrl(AppRoutes.LOGIN);
    }
  }

  public isPageAuthorized(route: string) {
    // this.logger.info("AuthenticationService.isPageAuthorized()");
    return this.http.post<any>(this.api.getPageAuthorizedURL(), {
      route: route,
    });
  }

  public isLoggedIn(): boolean {
    const token: string = this.getToken();
    // this.logger.info("EXPIRED? -> " + moment().isBefore(this.getExpiration()));
    // this.logger.info("EXPIRED? -> " + moment() + " / " + this.getExpiration());
    return (
      moment().isBefore(this.getExpiration()) && token !== '' && token !== null
    );
  }

  public isLoggedOut() {
    return !this.isLoggedIn();
  }

  public getToken(): any {
    // this.logger.info("getToken() -> id_token: " + localStorage.getItem("id_token"));
    return localStorage.getItem('id_token');
  }

  public getRefreshToken(): any {
    // this.logger.info("getRefreshToken() -> id_token: " + localStorage.getItem("refresh_token"));
    return localStorage.getItem('refresh_token');
  }

  public getExpiration() {
    const expiration = localStorage.getItem('expires_at') || '';
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  public refreshToken() {
    // this.logger.info("AuthenticationService.refreshToken() ");
    return this.http
      .post<any>(this.api.getRefreshTokenURL(), {
        refresh_token: this.getRefreshToken(),
      })
      .pipe(
        map((response) => {
          if (response && response.access_token) {
            this.setSession(response);
          }

          return <any>response;
        })
      );
  }

  // PRIVATE
  private setSession(authResult: any) {
    // this.logger.info("AuthenticationService.setSession() -> authResult.access_token: " + authResult.access_token);
    // this.logger.info("AuthenticationService.setSession() -> authResult.expires_in: " + authResult.expires_in);
    // this.logger.info("AuthenticationService.setSession() -> authResult.refresh_token: " + authResult.refresh_token);
    const expiresAt = moment().add(authResult.expires_in, 'second');
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));

    // TODO: the set() with secure param to true return null on a get, to fix
    // TODO: secure cookies cannot be read with javascript, therefore, its a normal behaviour.
    // TODO: to check later after MVP
    // this.cookieService.set("id_token", authResult.access_token, authResult.expires_in, null, null, true);
    // this.cookieService.set("id_token", authResult.access_token);

    localStorage.setItem('id_token', authResult.access_token);
    localStorage.setItem('refresh_token', authResult.refresh_token);

    this.loginSuccessSubject.next('LOGIN_SUCCESS');
  }

  private setUser(user: UserModelResponse) {
    // this.logger.info("AuthenticationService.setUser()");
    this.user = user;

    // defined post-hoc property
    // this.user.user.data.isGod = this.user.user.data.role === UserRole.GOD;

    //
    this.userSubject.next(this.user);
  }

  public getUser() {
    return this.user;
  }
}

export interface FinalizedRegisterModel {
  uuid: string;

  // Company info
  organizationName: string;
  organizationAdressNumber: string;
  organizationStreet: string;
  organizationApp: string;
  organizationCity: string;
  organizationState: string;
  organizationPostalCode: string;
  organizationCountry: string;
  organizationEmployeeCount: Object;
  organizationPhoneNumber: string;
  organizationPhoneNumberExtension: number;
  organizationWebsite: string;
  organizationEmail: string;

  // Profile
  gender: string;
  firstName: string;
  lastName: string;
  password: string;
  userLocale: string;
  phoneNumber: string;
  phoneNumberExtension: number;

  // Invite
  invites: InviteItem[];

  // Modules
  modules: string[];

  // Payment ???
  subscriptionType: string;

  // Subscription
  mailingList: boolean;
  webinar: boolean;
}

export interface InviteItem {
  email: string;
  role: string;
}

export interface FinalizedInviteModel {
  uuid?: string;
  gender: string;
  firstName: string;
  lastName: string;
  password: string;
  userLocale: string;
  phoneNumber: string;
  phoneNumberExtension: number;

  // Subscription
  mailingList: boolean;
  webinar: boolean;
}
