import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ApiRoutes } from '../../api/ApiRoutes';
import { Gt2ApiService } from '../../api/gt2-api.service';

@Injectable()
export class EnterpriseInvoiceService {
  invoices?: any[];

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getInvoicesByIDWithPagination(
    enterpriseUUID: string,
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    filterValue: string
  ): Promise<any> {
    // //this.logger.info("RoomsService.getRoomsWithPagination() -> serviceURL: " + serviceURL);
    const routeURL: string = this.api.createUrl(
      ApiRoutes.GET_ENTERPRISES_INVOICES + enterpriseUUID
    );
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // Remove special caracters from the filter value for proper searching
    filterValue = filterValue.replace(/%/g, '');

    if (filterValue !== '') {
      requestUrl += '&search=' + filterValue;
    }

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe((response: any) => {
        this.invoices = response.data;
        //this.logger.info("EnterpriseInvoiceService.getInvoicesByIDWithPagination() -> response: " + JSON.stringify(response));
        resolve(response);
      }, reject);
    });
  }

  public getEnterpriseInvoicesTotal(enterpriseUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(
        ApiRoutes.GET_ENTERPRISES_INVOICES_INFO + enterpriseUUID
      )
    );
  }

  public createMultiCqashing(enterpriseUUID: string, cashings: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.GET_ENTERPRISES_INVOICES + enterpriseUUID),
      { cashings: cashings }
    );
  }
}
