import { ItemUrlFinder } from './../../utils/item-url-finder';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { of, merge, fromEvent, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  startWith,
  switchMap,
  catchError,
  map,
} from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { FileService } from '../../../services/file.service';
import { PrintService } from '../../../services/print.service';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { MaterialsService } from '../../api/materials.service';
import { CreateMaterialComponent } from '../../components/dialogs/create-material/create-material.component';
import { AppRoutesService } from '../../services/app-routes.service';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { ApiRoutes } from '../../api/ApiRoutes';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss'],
  animations: fuseAnimations,
})
export class MaterialsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  displayedColumns = [
    'name_internal',
    'material_number',
    'price',
    'confirmed',
    'is_combo',
    'newTab',
  ];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild('filter') filter!: ElementRef;
  @ViewChild('mainContainer') mainContainer!: ElementRef;
  filterValue: string = '';
  dataSource = new MatTableDataSource();
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  fromEventSubscription?: Subscription;
  isFirstLoad: boolean = true;
  confirmedOnly: boolean = false;
  comboOnly: boolean = false;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,
    public datatableHelperService: DatatableHelperService,
    public materialsService: MaterialsService,
    public fileService: FileService,
    public print: PrintService,
    public translate: TranslateService,
    public appRoutesService: AppRoutesService,
    private router: Router,
    private logger: NGXLogger,
    private titleService: Title,
    private api: Gt2ApiService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    // this.testDynamicTableServiceURL = ApiRoutes.PRODUCTS;
  }

  ngOnInit(): void {
    //this.titleService.setTitle(this.translate.instant("MATERIALS.MATERIALS"));
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.materialsService.getMaterialsWithPagination(
            this.confirmedOnly,
            this.comboOnly,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filterValue,
          );

          // this.exampleDatabase!.getRepoIssues(this.sort.active, this.sort.direction, this.paginator.pageIndex);
        }),
      )
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          this.mainContainer.nativeElement.scrollTop = 0;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            // Catch if the GitHub API has reached its rate limit. Return empty data.
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe((data) => (this.dataSource.data = data));

    this.fromEventSubscription = fromEvent(this.filter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.filter?.nativeElement.value.length > 1 ||
            this.filter?.nativeElement.value === ''
          ) {
            this.paginator.pageIndex = 0;
            this.paginator?._changePageSize(this.paginator.pageSize);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {}

  public clearSearch(): void {
    this.filterValue = '';
    this.paginator.pageIndex = 0;
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public reloadTable(): void {
    // //this.logger.info("reloadTable()");
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public syncPrimaryPaginator(event: PageEvent) {
    //this.logger.info("syncPrimaryPaginator()");
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator?.page.emit(event);
  }

  public onCreateNewMaterial(event: any): void {
    // //this.logger.info("MaterialsComponent.onCreateNewMaterial() -> event: " + event);
    this.dialogRef = this.dialog.open(CreateMaterialComponent, {
      width: '340px',
      disableClose: false,
      data: { autoRedirect: true },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // //this.logger.info("POP UP Closed!");
        // this.reloadTable();
      }
      this.dialogRef = null;
    });
  }

  public doPrint(url: string): void {
    this.print.printHTML(url).subscribe(
      (response: any) => {
        // //this.logger.info("MaterialsComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      (error: any) => {
        this.logger.error(
          'MaterialsComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public doDownload(url: string): void {
    this.fileService.downloadFile(url).subscribe(
      (response: any) => {
        // //this.logger.info("MaterialsComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      (error: any) => {
        this.logger.error(
          'MaterialsComponent.doDownload() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public onPrint(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'materials',
      'fr-CA',
    );
    // this.print.printHTML(url);
    this.doPrint(url);
  }

  public onDownloadPDF(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'materials',
      'fr-CA',
    );
    // this.fileService.downloadFile(url);
    this.doDownload(url);
  }

  public onDownloadXLS(): void {
    // //this.logger.info("onDownloadVCard() ");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'materials',
      'fr-CA',
    );
    // this.fileService.downloadFile(url);
    this.doDownload(url);
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public onOpenMaterialInNewTab(material: any): void {
    window.open(ItemUrlFinder.getItemURL(material), '_blank');
  }
}
