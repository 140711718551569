import { fuseAnimations } from '../../../../@fuse/animations';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { PageEvent } from '@angular/material/paginator';
import { Subscription, merge, of, fromEvent } from 'rxjs';
import { CongressesService } from './../../api/congresses.service';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { Title } from '@angular/platform-browser';
import { RolesService } from '../../services/roles.service';
import { AppRoutesService } from './../../services/app-routes.service';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { DatatableHelperService } from '../../../services/datatable-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  startWith,
  switchMap,
  catchError,
  map,
} from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CreateCongresDialogComponent } from './create-congres-dialog/create-congres-dialog.component';
import {
  NgxDrpOptions,
  NgxMatDrpComponent,
  PresetItem,
  Range,
} from 'ngx-mat-daterange-picker';
import moment from 'moment';

@Component({
  selector: 'app-congresses',
  templateUrl: './congresses.component.html',
  styleUrls: ['./congresses.component.scss'],
  animations: fuseAnimations,
})
export class CongressesComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  //
  static LOCAL_STORED_CONGRESSES_RANGE_FROM_ID: string =
    'LOCAL_STORED_CONGRESSES_RANGE_FROM_001';
  static LOCAL_STORED_CONGRESSES_RANGE_TO_ID: string =
    'LOCAL_STORED_CONGRESSES_RANGE_TO_001';
  //
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild('filter') filter!: ElementRef;
  @ViewChild('mainContainer') mainContainer!: ElementRef;
  displayedColumns = [
    'name',
    'date_start',
    'date_end',
    'congress_number',
    'newTab',
  ];
  filterValue: string = '';
  dataSource = new MatTableDataSource();
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  fromEventSubscription?: Subscription;
  isFirstLoad: boolean = true;
  //
  @ViewChild('dateRangePicker')
  private dateRangePicker!: NgxMatDrpComponent;
  range: Range = { fromDate: new Date(), toDate: new Date() };
  rangeOptions!: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  tabSelectedInit: boolean = false;
  //
  includeCancelled: boolean = false;
  //
  constructor(
    private congressesService: CongressesService,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public datatableHelperService: DatatableHelperService,
    private router: Router,
    private logger: NGXLogger,
    public appRoutesService: AppRoutesService,
    public rolesService: RolesService,
    private titleService: Title,
    private api: Gt2ApiService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {
    //this.logger.info("CongressesComponent.ngOnInit()");
    //this.titleService.setTitle(this.translate.instant("CONGRESSES.CONGRESSES"));
    //
    this.tabSelectedInit = true;
    //
    this.setupRangePresets();
    const today = new Date();
    this.rangeOptions = {
      presets: this.presets,
      format: 'mediumDate',
      locale: this.translate.currentLang,
      range: {
        fromDate: new Date(today.getFullYear(), today.getMonth(), 1),
        toDate: new Date(today.getFullYear(), today.getMonth() + 1, 0),
      },
      animation: false,
      applyLabel: this.translate.instant('GENERIC.SUBMIT'),
      cancelLabel: this.translate.instant('GENERIC.CANCEL'),
      placeholder: this.translate.instant('GENERIC.CHOOSE_A_DATE'),
      startDatePrefix: this.translate.instant('GENERIC.FROM'),
      endDatePrefix: this.translate.instant('GENERIC.TO'),
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: true,
        hasBackdrop: true,
        // panelClass: 'ngx-daterange-picker'
      },
    };

    // set range
    const rangeFrom = localStorage.getItem(
      CongressesComponent.LOCAL_STORED_CONGRESSES_RANGE_FROM_ID,
    );
    const rangeTo = localStorage.getItem(
      CongressesComponent.LOCAL_STORED_CONGRESSES_RANGE_TO_ID,
    );

    if (rangeFrom && rangeTo) {
      this.rangeOptions.range.fromDate = new Date(rangeFrom);
      this.rangeOptions.range.toDate = new Date(rangeTo);
    }

    this.range = this.rangeOptions.range;
  }

  ngAfterViewInit() {
    //this.logger.info("CongressesComponent.ngAfterViewInit()");
    //this.logger.info("CongressesComponent.ngAfterViewInit() - sort: " + this.sort);
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.congressesService.getCongressesWithPagination(
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filterValue,
            this.range.fromDate.toISOString(),
            this.range.toDate.toISOString(),
            this.includeCancelled,
          );
        }),
      )
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          this.mainContainer.nativeElement.scrollTop = 0;
          //this.logger.info("CongressesComponent.ngAfterViewInit().getCongressesWithPagination().map() -> data : " + JSON.stringify(data.data));
          return data.data;
        }),
      )
      .pipe(
        catchError((error) => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
            //
            this.handleAPIError(error, this.dialog, null, null);
          });
          return of([]);
        }),
      )
      .subscribe((data) => (this.dataSource.data = data));

    this.fromEventSubscription = fromEvent(this.filter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.filter?.nativeElement.value.length > 1 ||
            this.filter?.nativeElement.value === ''
          ) {
            this.paginator.pageIndex = 0;
            this.paginator?._changePageSize(this.paginator.pageSize);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    //this.logger.info("CongressesComponent.ngOnDestroy()");
  }

  public onCreateNewCongress(event: any): void {
    //this.logger.info("CongressesComponent.onCreateNewCongress() -> event: " + event);
    this.dialogRef = this.dialog.open(CreateCongresDialogComponent, {
      width: '440px',
      minWidth: 350,
      maxHeight: '96vh',
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: { autoRedirect: true },
    });
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadTable();
      }
      this.dialogRef = null;
    });
  }

  public syncPrimaryPaginator(event: PageEvent) {
    //this.logger.info("CongressesComponent.syncPrimaryPaginator()");
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

  public onOpenCongresInNewTab(product: any): void {
    window.open(ItemUrlFinder.getItemURL(product), '_blank');
  }

  public clearSearch(): void {
    this.filterValue = '';
    this.paginator.pageIndex = 0;
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public reloadTable(): void {
    //this.logger.info("CongressesComponent.reloadTable()");
    if (this.paginator) {
      //this.logger.info("CongressesComponent.reloadTable() -> Paginator EXIST!");
      this.paginator._changePageSize(this.paginator.pageSize);
    } else {
      //this.logger.info("CongressesComponent.reloadTable() -> Paginator not found");
    }
  }

  public onOpenRangePicker($event: any): void {
    // //this.logger.info("--> " + this.dateRangePicker);
    this.dateRangePicker?.openCalendar($event);
  }

  public updateRange(range: Range): void {
    //this.logger.info("CongressesComponent.updateRange() --> " + this.tabSelectedInit);
    this.range = range;
    this.reloadTable();

    // set in Local Storage
    ////this.logger.info('set local storage date from :: ' + );
    localStorage.setItem(
      CongressesComponent.LOCAL_STORED_CONGRESSES_RANGE_FROM_ID,
      this.range.fromDate.toDateString(),
    );
    localStorage.setItem(
      CongressesComponent.LOCAL_STORED_CONGRESSES_RANGE_TO_ID,
      this.range.toDate.toDateString(),
    );
  }

  public setupRangePresets(): void {
    const backDate = (numOfDays: any) => {
      const todayDate = new Date();
      return new Date(todayDate.setDate(today.getDate() - numOfDays));
    };

    const upDate = (numOfDays: any) => {
      const todayDate = new Date();
      return new Date(todayDate.setDate(today.getDate() + numOfDays));
    };

    const today = new Date();
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('isoWeek').toDate();
    const weekEnd = currentDate.clone().endOf('isoWeek').toDate();
    const up14 = upDate(14);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const nextMonthStart = currentDate
      .clone()
      .add(1, 'M')
      .startOf('month')
      .toDate();
    const nextMonthEnd = currentDate
      .clone()
      .add(1, 'M')
      .endOf('month')
      .toDate();

    this.presets = [
      {
        presetLabel: this.translate.instant('GENERIC.TODAY'),
        range: { fromDate: today, toDate: today },
      },
      {
        presetLabel: this.translate.instant('GENERIC.THIS_WEEK'),
        range: { fromDate: weekStart, toDate: weekEnd },
      },
      {
        presetLabel: this.translate.instant('GENERIC.NEXT_2_WEEK'),
        range: { fromDate: today, toDate: up14 },
      },
      {
        presetLabel: this.translate.instant('GENERIC.THIS_MONTH'),
        range: { fromDate: currMonthStart, toDate: currMonthEnd },
      },
      {
        presetLabel: this.translate.instant('GENERIC.NEXT_MONTH'),
        range: { fromDate: nextMonthStart, toDate: nextMonthEnd },
      },
    ];
  }

  public onShowCancelled(event: any): void {
    //this.logger.info("CongressesComponent.onShowCancelled() --> " + event.checked);
    this.reloadTable();
  }
}
