<div
  class="navbar-top"
  [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
>
  <div class="logo">
    <img
      class="logo-gt2"
      src="assets/images/logos/{{ 'APP.LOGO' | translate }}"
    />
    <!-- <span style="color: #ffffffb3" class="logo-text">{{
      "APP.TITLE" | translate
    }}</span> -->
  </div>

  <div class="buttons">
    <button
      mat-icon-button
      class="toggle-sidebar-folded"
      (click)="toggleSidebarFolded()"
      fxHide.lt-lg
    >
      <mat-icon style="color: #ffffffb3">menu</mat-icon>
    </button>

    <button
      mat-icon-button
      class="toggle-sidebar-opened"
      (click)="toggleSidebarOpened()"
      fxHide.gt-md
    >
      <mat-icon style="color: #ffffffb3">arrow_back</mat-icon>
    </button>
  </div>
</div>

<div
  *ngIf="organization"
  class="navbar-scroll-container"
  [ngClass]="fuseConfig.layout.navbar.primaryBackground"
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
>
  <div
    class="user cursor-pointer"
    (click)="navigateToDashboard()"
    fxLayout="column"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
  >
    <div class="avatar-container">
      <img
        class="avatar-gt2"
        onerror="if (this.src !== '/assets/images/logos/default-no-org-logo.png') this.src='/assets/images/logos/default-no-org-logo.png';"
        src="{{ organization.data.logo }}"
      />
    </div>
    <!--<div class="h4 username">{{organization.data.name}}</div>-->
  </div>

  <div *ngxPermissionsOnly="['GOD']" class="org-select">
    <mat-select
      *ngIf="organizations && organizations.length > 1"
      class="primary px-8 py-8 my-4"
      matTooltip="Sélectionner un traiteur"
      placeholder="Organisations"
      (selectionChange)="onSelectionChange($event)"
      [(ngModel)]="selectedOrganization"
    >
      <mat-option *ngFor="let item of organizations" [value]="item">{{
        item.name
      }}</mat-option>
    </mat-select>
  </div>

  <div class="navbar-content">
    <fuse-navigation layout="vertical"></fuse-navigation>
  </div>
</div>
