import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import { IGetDataService } from '../components/gt2-select/gt2-select.component';
import { Observable } from 'rxjs';

@Injectable()
export class UpsaleService implements IGetDataService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  getData(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  public getQuestions(): any {
    // this.logger.info("UpsaleService.getQuestions()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.UPSALE_QUESTIONS));
  }

  public createQuestions(question: any): any {
    //this.logger.info("UpsaleService.createQuestions()::" + JSON.stringify(question));
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.UPSALE_QUESTIONS),
      question
    );
  }

  public updateQuestions(question: any): any {
    // this.logger.info("UpsaleService.updateQuestions()");
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.UPSALE_QUESTIONS) + '/' + question.uuid,
      question
    );
  }

  public deleteQuestion(uuid: string): any {
    // this.logger.info("UpsaleService.getProducts()");
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.UPSALE_QUESTIONS) + '/' + uuid
    );
  }

  public deleteGroupOptions(uuid: string): any {
    //this.logger.info("deleteGroupOptions");
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.UPSALE_OPTIONS) + '/' + uuid
    );
  }

  public addOptions(uuid: string, options: any): any {
    //this.logger.info("UUID QUESTION" + JSON.stringify(uuid));
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.UPSALE_OPTIONS + '/' + uuid),
      options
    );
  }

  public updateOptions(uuid: string, options: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.UPSALE_OPTIONS + '/' + uuid),
      options
    );
  }

  public getDraggables(uuid: string): Observable<any> {
    // this.logger.info("AllergensService.getDraggables()");
    return this.getAvailableUpsaleQuestions(uuid);
  }

  public getAvailableUpsaleQuestions(uuid: any) {
    // this.logger.info("ProductionZonesService.getZones()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.UPSALE_AVAILABLE_QUESTIONS) + uuid
    );
  }

  public getDroppables(uuid: string): Observable<any> {
    // this.logger.info("AllergensService.getDroppables()");
    return this.getAttachedQuestions(uuid);
  }

  public getAttachedQuestions(uuid: string) {
    // this.logger.info("AllergensService.getAttachedAllergens()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.UPSALE_QUESTIONS_ATTACHED) + uuid
    );
  }

  public getAttachedOptions(uuid: string): Observable<any> {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.UPSALE_OPTIONS_ATTACHED) + uuid
    );
  }

  public attach(parentUUID: string, itemUUID: string): Observable<any> {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.UPSALE_QUESTIONS_ACTION) +
        parentUUID +
        '/attach/' +
        itemUUID
    );
  }

  public deattach(parentUUID: string, itemUUID: string): Observable<any> {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.UPSALE_QUESTIONS_ACTION) +
        parentUUID +
        '/detach/' +
        itemUUID
    );
  }

  public productQuestionMoveBefore(
    productUuid: string,
    questionUuid: string,
    beforeUuid: string
  ) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.QUESTIONS) +
        productUuid +
        '/' +
        questionUuid +
        '/move-before/' +
        beforeUuid
    );
  }

  public productQuestionMoveAfter(
    productUuid: string,
    questionUuid: string,
    afterUuid: string
  ) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.QUESTIONS) +
        productUuid +
        '/' +
        questionUuid +
        '/move-after/' +
        afterUuid
    );
  }
}
export interface Question {
  uuid: string;
  name: string;
  step: number;
}
export interface Option {
  name: string;
}
export interface OptionGroup {
  name: string;
}
