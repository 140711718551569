<div class="" fxLayout="column">
  <div
    *ngIf="!isLoadingResults && dataSource.data && dataSource.data.length === 0"
    fxLayout="row"
    fxLayoutAlign="center center"
    class="my-16 font-size-20 font-weight-500"
  >
    {{ "GENERIC.NO_RESULTS" | translate }}
  </div>

  <div *ngIf="isLoadingResults" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner [diameter]="26" class="my-12"></mat-spinner>
  </div>

  <mat-table
    #table
    class=""
    matSort
    matSortStart="desc"
    [ngStyle]="{
      display: dataSource.data && dataSource.data.length === 0 ? 'none' : ''
    }"
    [@animateStagger]="{ value: '50' }"
    [dataSource]="dataSource"
  >
    <!-- id column -->
    <ng-container cdkColumnDef="uuid">
      <mat-header-cell fxFlex="45" *cdkHeaderCellDef class="w-240">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxFlex
          class="section-list-item-border-right"
        >
          <div class="">
            <mat-checkbox
              tabIndex="-1"
              [(ngModel)]="eventAllCheckboxes"
              (ngModelChange)="onSelectedAllEventChange()"
              (click)="$event.stopPropagation()"
            >
            </mat-checkbox>
            {{ "GENERIC.SELECT" | translate }} ( {{ selectedEvents.length }} )
          </div>

          <div class="product-group-action-menu ml-15">
            <button
              mat-stroked-button
              matTooltip="{{ 'CONGRESSES.GROUP_ACTION_TOOLTIP' | translate }}"
              [matTooltipPosition]="'right'"
              [matMenuTriggerFor]="selectProductMenu"
              [ngClass]="selectedEvents.length === 0 ? 'grey-100' : 'accent'"
              [disabled]="selectedEvents.length == 0"
              class="mx-8"
            >
              {{ "GENERIC.ACTION" | translate }}
            </button>
            <mat-menu #selectProductMenu="matMenu">
              <button mat-menu-item (click)="moveEvents()">
                {{ "GENERIC.MOVE_TO_PROJECT" | translate }}
              </button>
              <button mat-menu-item (click)="copyEvents()">
                {{ "GENERIC.COPY_TO_PROJECT" | translate }}
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-header-cell>
      <mat-cell fxFlex="45" *cdkCellDef="let row" class="w-240">
        <div>
          <mat-checkbox
            [(ngModel)]="eventCheckboxes[row.uuid]"
            (ngModelChange)="onSelectedChange(row.uuid)"
            (click)="$event.stopPropagation()"
            tabIndex="-1"
            class="mr-2"
          >
          </mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <!-- Name Column -->
    <ng-container cdkColumnDef="name">
      <mat-header-cell fxFlex="20" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.NAME" | translate }}
      </mat-header-cell>
      <mat-cell fxFlex="20" *cdkCellDef="let row">
        <div>
          {{ row.name }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- event_date Column -->
    <ng-container cdkColumnDef="event_date">
      <mat-header-cell fxFlex="13" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.EVENT_DATE" | translate }}
      </mat-header-cell>
      <mat-cell fxFlex="13" *cdkCellDef="let row">
        <div>
          {{ row.event_date_label }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- event_time_label -->
    <ng-container cdkColumnDef="event_time">
      <mat-header-cell fxFlex="10" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.TIME" | translate }}
      </mat-header-cell>
      <mat-cell fxFlex="10" *cdkCellDef="let row">
        <div>
          {{ row.event_time_label }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- room -->
    <ng-container cdkColumnDef="room">
      <mat-header-cell fxFlex="10" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.ROOM" | translate }}
      </mat-header-cell>
      <mat-cell fxFlex="10" *cdkCellDef="let row">
        <div>
          {{ row.room_name }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- status Column -->
    <ng-container cdkColumnDef="status">
      <mat-header-cell fxFlex="15" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.STATUS" | translate }}
      </mat-header-cell>
      <mat-cell fxFlex="15" *cdkCellDef="let row">
        <div>
          {{ row.status_label }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- event_number Column -->
    <ng-container cdkColumnDef="event_number">
      <mat-header-cell fxFlex="13" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.EVENT_NUMBER" | translate }}
      </mat-header-cell>
      <mat-cell
        fxFlex="13"
        *cdkCellDef="let row"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <div>
          {{ row.event_number }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container cdkColumnDef="actions">
      <mat-header-cell fxFlex="14" *cdkHeaderCellDef> </mat-header-cell>
      <mat-cell
        fxFlex="14"
        *cdkCellDef="let row"
        fxLayout="row"
        fxLayoutAlign="end center"
      >
        <button mat-icon-button (click)="onOpenPage(row)">
          <mat-icon class="s-16 secondary-text">{{ getIcon("EDIT") }}</mat-icon>
        </button>

        <button
          mat-icon-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenEventInNewTab(row); $event.stopPropagation()"
          class="mat-icon-button"
        >
          <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *cdkRowDef="let row; columns: displayedColumns"
      (click)="onNavigateToItem(row)"
      matRipple
      [@animate]="{ value: '*', params: { y: '100%' } }"
    >
    </mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    [length]="resultsLength"
    (page)="datatableHelperService.onPaginator($event, 'enterprise')"
    [ngStyle]="{
      display: dataSource.data && dataSource.data.length === 0 ? 'none' : ''
    }"
    showFirstLastButtons="true"
    [pageIndex]="0"
    [pageSize]="'enterprise' | tableSizePipe : 'short'"
    [pageSizeOptions]="datatableHelperService.pageSizeShortOptions"
  >
  </mat-paginator>
</div>
