export const locale = {
  lang: 'en-CA',
  data: {
    LOGIN: {
      WELCOME_MESSAGE: 'Welcome to Caterer Manager!',
      LOGO: 'fuse-en.png',
      LONG_WELCOME_MESSAGE:
        'All-in-one software solution “from web to kitchen”.',
      LONG_WELCOME_MESSAGE_2: 'Centralize – Simplify – Maximize returns',
      LOGIN_TO_YOUR_ACCOUNT: 'Sign in to your account',
      EMAIL_PLACEHOLDER: 'Email',
      EMAIL_REQUIRED: 'Email is required',
      EMAIL_INVALID: 'Please enter a valid email address',
      PASSWORD_PLACEHOLDER: 'Password',
      PASSWORD_REQUIRED: 'Password is required',
      REMEMBER_ME: 'Remember Me',
      FORGOT_PASSWORD: 'Forgot Password?',
      LOGIN_BTN_LABEL: 'Sign in',
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP: 'Sign up',
      CREATE_ACCOUNT: 'Create an account',
      ERROR_401_MESSAGE:
        'No accounts were found with these credentials. Please check your email and password and try again.',
      RESEND_INVITE: 'RESEND INVITE',
      ERROR_DIALOG_TITLE: 'Oops! It appears your credentials are incorrect.',
      ERROR_DIALOG_NOT_CONFIRMED:
        'This user has not been confirmed. Check for your confirmation email or resend the invite email.',
      NEED_HELP: 'Need help?',
    },
  },
};
