<div style="max-height: 100%">
  <h1 matDialogTitle class="h1-title">
    {{ "SETTINGS.CREATE_SECTOR" | translate }}
  </h1>

  <div
    fxLayout="column"
    fxLayoutAlign="center start"
    [formGroup]="form"
    class="mt-8 create-client-width"
  >
    <mat-form-field fxFlex="100%" class="create-delivery-width">
      <mat-label>{{ "SETTINGS.SECTOR_NAME" | translate }}</mat-label>
      <input
        matInput
        type="text"
        autocomplete="nope"
        [(ngModel)]="newCategory"
        formControlName="name"
      />
    </mat-form-field>
    <mat-form-field fxFlex="100%" class="create-delivery-width pl-4">
      <mat-label>{{ "SETTINGS.PRICE" | translate }}</mat-label>
      <input
        matInput
        type="text"
        autocomplete="nope"
        formControlName="price"
        pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
        [(ngModel)]="newCategoryPrice"
      />
      <mat-error *ngIf="form.controls['price'].invalid">
        {{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      color="accent"
      (click)="onCreateNew()"
      [disabled]="!form.valid || loading"
      class="mr-16"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
