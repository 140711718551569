import { EventClientSectionComponent } from '../../../content/events/event/event-clients/event-client-section/event-client-section.component';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventsService } from '../../../api/events.service';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { ClientsService } from '../../../api/clients.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ProjectsService } from '../../../api/projects.service';
import { CreateClientWithDetailsComponent } from '../create-client-with-details/create-client-with-details.component';
import { CreateEnterpriseWithDetailsComponent } from '../create-enterprise-with-details/create-enterprise-with-details.component';
import { CreateClientEnterpriseWithDetailsComponent } from '../create-client-enterprise-with-details/create-client-enterprise-with-details.component';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatRadioModule,
    MatInputModule,
    MatIconModule,
    ScrollingModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class SelectClientComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  clients!: any[];
  eventUUID!: string;
  projectUUID!: string;
  clientSection!: string;
  filterValue: string = '';
  selectedClient: any;
  isLoading: boolean = false;
  isEmpty: boolean = true;
  @ViewChild('filter') filter!: ElementRef;
  fromEventSubscription!: Subscription;
  clientFor!: string;
  clientType: string = 'client';

  constructor(
    public override dialogRef: MatDialogRef<SelectClientComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private logger: NGXLogger,
    public dialog: MatDialog,
    private clientsService: ClientsService,
    private eventsService: EventsService,
    private projectsService: ProjectsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    //this.logger.info("SelectClientComponent.constructor() -> ");
    if (data.eventUUID) {
      this.eventUUID = data.eventUUID;
    }

    if (data.projectUUID) {
      this.projectUUID = data.projectUUID;
    }

    if (data.clientSection) {
      this.clientSection = data.clientSection;
    }

    if (data.client) {
      this.selectedClient = data.client;
    }

    if (data.clientFor) {
      this.clientFor = data.clientFor;
    }
  }

  ngOnInit() {
    if (this.selectedClient) {
      this.filterValue =
        this.selectedClient.first_name + ' ' + this.selectedClient.last_name;
    }

    this.loadClientList();
  }

  ngAfterViewInit() {
    this.fromEventSubscription = fromEvent(this.filter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          // //this.logger.info("SelectClientComponent.onFilterChange() -> " + this.productList);
          //this.logger.info("SelectClientComponent.onFilterChange() -> " + this.filterValue);
          //   if (this.filterValue.length >= 3 )
          //   {
          //     this.loadClientList();
          //   }
          //   else
          //   {
          //     this.clients = [];
          //     this.isEmpty = true;
          //   }
          this.loadClientList();
          /*
                        if (this.filterValue.length >= 3 || this.filterValue === "")
                        {
                          this.loadProductList();
                        }
              */
          // this.productList.nativeElement.scrollTop = 0;
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {}

  public trackByUUID(index: any, item: any) {
    return item.uuid;
  }

  public onSelectClient(item: any): void {
    //this.logger.info("SelectClientComponent.onSelectClient()");
    this.selectedClient = item;
    //this.logger.info("SelectClientComponent.onSelectClient() -> this.selectedClient: " + JSON.stringify(this.selectedClient));
  }

  public onClientSelected(): void {
    //this.logger.info("SelectClientComponent.onClientSelected() -> this.clientFor: " + this.clientFor);
    this.isLoading = true;
    //
    // if (this.clientFor === EventClientSectionComponent.CLIENT_FOR_EVENT) {
    //     this.addClientToEvent();
    // } else if (
    //     this.clientFor === EventClientSectionComponent.CLIENT_FOR_PROJECT
    // ) {
    //     this.addClientToProject();
    // }
    if (this.clientFor === EventClientSectionComponent.CLIENT_FOR_PROJECT) {
      this.addClientToProject();
    } else {
      this.addClientToEvent();
    }
  }

  public addClientToEvent(): void {
    //this.logger.info("SelectClientComponent.addClientToEvent()");
    this.eventsService
      .addClient(this.selectedClient.uuid, this.eventUUID, this.clientSection)
      .subscribe(
        (response: any) => {
          // //this.logger.info("SelectClientComponent.onClientSelected() -> SUCCESS: " + JSON.stringify(response));
          this.isLoading = false;
          this.isEmpty = false;
          this.dialogRef.close(response);
        },
        (error: any) => {
          //this.logger.info("SelectClientComponent.onClientSelected() -> ERROR: " + JSON.stringify(error));
          this.isLoading = false;
          this.isEmpty = true;
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public addClientToProject(): void {
    //this.logger.info("SelectClientComponent.addClientToProject()");
    this.projectsService
      .addClient(this.selectedClient.uuid, this.projectUUID, this.clientSection)
      .subscribe(
        (response: any) => {
          // //this.logger.info("SelectClientComponent.onClientSelected() -> SUCCESS: " + JSON.stringify(response));
          this.isLoading = false;
          this.isEmpty = false;
          this.dialogRef.close(response);
        },
        (error: any) => {
          //this.logger.info("SelectClientComponent.onClientSelected() -> ERROR: " + JSON.stringify(error));
          this.isLoading = false;
          this.isEmpty = true;
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public clearSearch(): void {
    this.filterValue = '';
    // this.clients = [];
    // this.isLoading = false;
    // this.isEmpty = true;
    this.loadClientList();
  }

  public loadClientList(): void {
    if (this.clientType == 'enterprise') {
      this.loadEnterprisesClientsList();
    } else {
      this.loadOnlyClientsList();
    }
  }

  public loadOnlyClientsList(): void {
    this.isLoading = true;
    this.isEmpty = false;
    this.clientsService.getAllClientsCC(this.filterValue).subscribe(
      (response) => {
        //this.logger.info("SelectClientComponent.constructor() -> SUCCESS: " + JSON.stringify(response));
        this.clients = response.data;
        this.isLoading = false;
        this.isEmpty = this.clients?.length === 0;
      },
      (error) => {
        this.logger.error(
          'SelectClientComponent.constructor() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoading = false;
        this.isEmpty = true;
      },
    );
  }

  public loadEnterprisesClientsList(): void {
    this.isLoading = true;
    this.isEmpty = false;
    this.clientsService.getAllEnterprisesClients(this.filterValue).subscribe(
      (response) => {
        // //this.logger.info("SelectClientComponent.constructor() -> SUCCESS: " + JSON.stringify(response));
        this.clients = response.data;
        this.isLoading = false;
        this.isEmpty = this.clients?.length === 0;
      },
      (error) => {
        this.logger.error(
          'SelectClientComponent.constructor() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoading = false;
        this.isEmpty = true;
      },
    );
  }

  public reloadClients(): void {
    //this.logger.info("SelectClientComponent.reloadClients");
    this.loadClientList();
  }

  public onCreateClient(): void {
    this.logger.debug('SelectClientComponent.onCreateClient()');

    let dialogRef: any = this.dialog.open(CreateClientWithDetailsComponent, {
      width: '64%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        navigateToClient: false,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        //this.logger.info("POP UP Closed! result: " + JSON.stringify(result));
        // this.reloadClientsTable();
        // this.router.navigateByUrl( "/" + AppRoutes.CLIENTS + "/" + result.slug + "/" + result.uuid);
        //this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));

        this.selectedClient = result;
        this.onClientSelected();
        // this.reloadClients();
      }
      dialogRef = null;
    });
  }

  public onCreateEnterprise(): void {
    this.logger.debug('ClientComponent.onCreateEnterprise()');

    let dialogRef: any = this.dialog.open(
      CreateEnterpriseWithDetailsComponent,
      {
        width: '64%',
        minWidth: 350,
        disableClose: false,
        autoFocus: false,
        panelClass: 'scrollable-dialog',
        maxHeight: '90vh',
      },
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // //this.logger.info("POP UP Closed!");
        // this.reloadClientsTable();
        // this.router.navigateByUrl( "/" + AppRoutes.CLIENTS + "/" + result.slug + "/" + result.uuid);
        //this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }
      dialogRef = null;
    });
  }

  public onCreateClientEnterprise(): void {
    this.logger.debug('ClientComponent.onCreateClientEnterprise()');

    let dialogRef: any = this.dialog.open(
      CreateClientEnterpriseWithDetailsComponent,
      {
        width: '64%',
        minWidth: 350,
        disableClose: false,
        autoFocus: false,
        panelClass: 'scrollable-dialog',
        maxHeight: '90vh',
      },
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // //this.logger.info("POP UP Closed!");
        // this.reloadClientsTable();
        // this.router.navigateByUrl( "/" + AppRoutes.CLIENTS + "/" + result.slug + "/" + result.uuid);
        //this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }
      dialogRef = null;
    });
  }
}
