export const locale = {
  lang: "en-CA",
  data: {
    COMPOSE: {
      TITLE: "New message",
      SHOW_CC_BCC: "Show CC & BCC",
      HIDE_CC_BCC: "Hide CC & BCC",
    },
  },
};
