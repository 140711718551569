<div style="max-height: 100%; overflow: auto">
  <h1 *ngIf="subType === 'PROPOSAL'" matDialogTitle>
    {{ "CREATE_EVENT.PROPOSAL_TITLE" | translate }}
  </h1>
  <h1 *ngIf="subType === 'ORDER'" matDialogTitle>
    {{ "CREATE_EVENT.ORDER_TITLE" | translate }}
  </h1>

  <div fxLayout="column" fxLayoutAlign="center start" class="my-8 create-width">
    <div
      [formGroup]="form"
      (keyup.enter)="
        form.valid &&
        !loading &&
        event.event_time !== 'Invalid DateTime' &&
        event.event_time_end !== 'Invalid DateTime'
          ? onCreateEvent()
          : null
      "
      fxLayout="column"
      fxLayoutAlign="center start"
      class="my-8 create-width"
    >
      <mat-form-field class="create-width">
        <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
        <input
          matInput
          fxFlex="100%"
          placeholder="{{ 'GENERIC.NAME' | translate }}"
          formControlName="name"
          type="text"
          autocomplete="nope"
          [(ngModel)]="event.name"
          required
        />
        <mat-error *ngIf="form.controls['name'].invalid"
          >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
          {{ "CREATE_EVENT.NAME_NOT_SAME" | translate }}</mat-error
        >
      </mat-form-field>

      <mat-form-field class="create-width mt-12">
        <mat-label>{{ "GENERIC.DATE" | translate }}</mat-label>
        <input
          fxFlex="100%"
          matInput
          [matDatepicker]="datePicker"
          [(ngModel)]="event.event_date"
          (dateChange)="onDatePickerChange($event)"
          min="1900-01-01"
          placeholder="{{ 'GENERIC.DATE' | translate }}"
          required
          formControlName="event_date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="datePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="form.controls['event_date'].invalid"
          >{{ "CREATE_EVENT.DATE_NOT_SAME" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="proposal" class="create-width mt-12">
        <mat-label>{{ "GENERIC.DATE_END" | translate }}</mat-label>
        <input
          fxFlex="100%"
          matInput
          [matDatepicker]="datePickerEnd"
          [(ngModel)]="event.event_date_end"
          (dateChange)="onDatePickerChangeEnd($event)"
          min="1900-01-01"
          placeholder="{{ 'GENERIC.DATE_END' | translate }}"
          formControlName="event_date_end"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="datePickerEnd"
        ></mat-datepicker-toggle>
        <mat-datepicker #datePickerEnd></mat-datepicker>
        <mat-error *ngIf="form.controls['event_date_end'].invalid"
          >{{ "CREATE_EVENT.DATE_NOT_SAME" | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="create-width ngx-timepicker-field-from" fxFlex="100%">
      <div class="timepicker-custom-label">
        {{ "GENERIC.TIME_START" | translate }}*
      </div>
      <ngx-timepicker-field
        [buttonAlign]="'left'"
        [format]="24"
        [(ngModel)]="event.event_time"
        class=""
      >
      </ngx-timepicker-field>
    </div>

    <div class="create-width mt-4 ngx-timepicker-field-from" fxFlex="100%">
      <div class="timepicker-custom-label">
        {{ "GENERIC.TIME_END" | translate }}
      </div>
      <ngx-timepicker-field
        [buttonAlign]="'left'"
        [format]="24"
        [(ngModel)]="event.event_time_end"
        class=""
      >
      </ngx-timepicker-field>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <!--disabled]="!form.valid || event.event_time === 'Invalid date' || event.event_time_end === 'Invalid date' || loading"-->
  <div
    mat-dialog-actions
    [formGroup]="form"
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      color="accent"
      class="mr-16"
      type="submit"
      [disabled]="
        !form.valid ||
        event.event_time === null ||
        event.event_time === 'Invalid DateTime' ||
        event.event_time_end === 'Invalid DateTime' ||
        loading
      "
      (click)="onCreateEvent()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
