<div
  fxFlex
  fxLayout="row"
  fxLayoutAlign="start center">
  <div fxFlex="40">{{ invoice.invoice.invoice_number }}</div>
  <div fxFlex="40">{{ invoice.invoice.event.stats.total }}</div>
  <div
    fxFlex="20"
    [formGroup]="form">
    <mat-form-field
      fxFlexFill
      fxLayout="row"
      fxLayoutAlign="start center"
      class="invoice-input">
      <input
        matInput
        placeholder=""
        type="text"
        step=".01"
        pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
        formControlName="amount"
        (change)="onAmountChange($event)"
        [ngModel]="invoice.amount | number : '1.2-2'"
        class="" />
      <!-- <mat-icon matPrefix>attach_money</mat-icon> -->
    </mat-form-field>
  </div>
</div>
