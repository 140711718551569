import { NgModule } from '@angular/core';

import { FuseSidebarComponent } from './sidebar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FuseSidebarComponent],
  imports: [FlexLayoutModule, TranslateModule],
  exports: [FuseSidebarComponent],
})
export class FuseSidebarModule {}
