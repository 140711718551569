import { InvoiceComposeService } from './../../api/invoice-compose.service';
import { MenuReportsService } from './../../reports/menu-reports.service';
import { FileService } from './../../../services/file.service';
import { ReportFactoryService } from './../../reports/report-factory.service';
import {
  LocaleEnum,
  PaperEnum,
  ReportsEnum,
} from './../../reports/ReportsEnum';
import { ReportViewerComponent } from '../../reports/report-viewer/report-viewer.component';
import { ReportsFactory } from './../../reports/reports-factory';
import { ReportsService } from './../../reports/reports.service';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { AppRoutes } from '../../const/AppRoutes';
import { Router } from '@angular/router';
import { EventsService } from '../../api/events.service';
import { LazyLoadEvent } from 'primeng/api';
import { CalendarEvent, CalendarMonthViewDay } from 'angular-calendar';
import { Subject } from 'rxjs';
import { IconsService } from '../../services/icons.service';
import { CUSTOM_COLORS } from './custom-colors';

declare const FullCalendar: any;
declare const $: any;

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  animations: fuseAnimations,
})
export class TestComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit
{
  // PRIME NG EXAMPLE
  // cars1: Car[];
  // cars2: Car[];
  // cols: any[];
  // selectedCar1: Car;
  // selectedCar2: Car;

  // TEST PRIME NG TABLE WITH OUR DATA
  primeNGData: any;
  primeNGCols?: any[];
  selectedEvent1: any;
  selectedEvent2: any;
  loading: boolean = true;
  totalRecords: number = 0;
  pageSize: number = 10;

  public selectedTime: string = '';
  public currentTime: string = '11:11 am';

  test: any;
  bc = new BroadcastChannel('test_channel');

  //
  calendar!: any;
  calendarRessources: any[] = [
    { id: 'a', title: 'Auditorium A' },
    { id: 'b', title: 'Auditorium B', eventColor: 'green' },
    { id: 'c', title: 'Auditorium C', eventColor: 'orange' },
    {
      id: 'd',
      title: 'Auditorium D',
      children: [
        { id: 'd1', title: 'Room D1' },
        { id: 'd2', title: 'Room D2' },
      ],
    },
    { id: 'e', title: 'Auditorium E' },
    { id: 'f', title: 'Auditorium F', eventColor: 'red' },
    { id: 'g', title: 'Auditorium G' },
    { id: 'h', title: 'Auditorium H' },
    { id: 'i', title: 'Auditorium I' },
    { id: 'j', title: 'Auditorium J' },
    { id: 'k', title: 'Auditorium K' },
    { id: 'l', title: 'Auditorium L' },
    { id: 'm', title: 'Auditorium M' },
  ];

  calendarEvents: any[] = [
    {
      id: '1',
      resourceId: 'b',
      start: '2018-04-07T02:00:00',
      end: '2018-04-07T07:00:00',
      title: 'event 1',
    },
    {
      id: '2',
      resourceId: 'c',
      start: '2018-04-07T05:00:00',
      end: '2018-04-07T22:00:00',
      title: 'event 2',
    },
    {
      id: '3',
      resourceId: 'd',
      start: '2018-04-06',
      end: '2018-04-08',
      title: 'event 3',
    },
    {
      id: '4',
      resourceId: 'e',
      start: '2018-04-07T03:00:00',
      end: '2018-04-07T08:00:00',
      title: 'event 4',
    },
    {
      id: '5',
      resourceId: 'f',
      start: '2018-04-07T00:30:00',
      end: '2018-04-07T02:30:00',
      title: 'event 5',
    },
  ];

  // public DistributionModeEnum = DistributionModeEnum;
  // public ExportFormatEnum = ExportFormatEnum;
  // public ExportToEnum = ExportToEnum;

  // public paramsDate = {};
  // public paramsOrder = {};

  public localFR = LocaleEnum.FR_CA;
  public paperLetter = PaperEnum.LETTER;
  public paramsDate2 = { date_from: '2020-02-01', date_to: '2021-02-12' };
  public paramsDate3 = { date_from: '2021-02-18', date_to: '2021-02-18' };
  public paramsOrder2 = {
    uuid_order: '11f9ecf0-d47b-489d-9631-9fd849dddf48', //"3b98d244-58cf-4989-ab90-b1164e3b6bee",
  };

  public paramsOrder3 = {
    uuid_order: '3b98d244-58cf-4989-ab90-b1164e3b6bee',
  };

  public paramsOrderDate = {
    uuid_order: '0189e9cc-2c4d-4c1f-97ac-5ca56f209186',
    date_from: '2020-02-01',
    date_to: '2021-02-12',
  };

  public paramsOrderDate2 = {
    uuid_order: '3b98d244-58cf-4989-ab90-b1164e3b6bee',
    date_from: '2020-02-01',
    date_to: '2021-02-12',
  };

  // public paramsOrder2 = {
  //     uuid_order: "3b98d244-58cf-4989-ab90-b1164e3b6bee",
  // };

  public paramsEvent = {
    uuid_event: '64f4e11d-e8b3-4464-9961-efe97d7a5740',
  };

  public paramsInvoice = {
    uuid_order: 'c52a1597-48b2-477f-a7d4-8021cdbaede4',
  };

  public paramsEnterprise = {
    uuid_enterprise: '507d7581-6a07-408a-ba5c-f24e2c0b197a',
  };

  public paramsClient = {
    uuid_client: 'c03629bf-3841-47d6-bd2a-742c436233d0',
  };
  // public paramsOrderDate2 = {
  //     date_from: "2021-02-01",
  //     date_to: "2021-02-05",
  //     uuid_order: "3b98d244-58cf-4989-ab90-b1164e3b6bee",
  // };
  isCanada: boolean = true;

  //
  date: any;
  selectedDate: any;
  viewDate?: Date;
  events?: CalendarEvent[];
  refresh: Subject<any> = new Subject();
  day: any;
  selectedDayForEvents: any;

  constructor(
    public http: HttpClient,
    public eventsService: EventsService,
    private logger: NGXLogger,
    private router: Router,
    private dialog: MatDialog,
    public reportsService: ReportsService,
    public menuReportsService: MenuReportsService,
    public invoiceComposeService: InvoiceComposeService,
    public fileService: FileService,
    public reportFactoryService: ReportFactoryService,
    public iconsService: IconsService,
  ) {
    super();
    // this.bc.onmessage = this.handleMessage;
    const time = Date.now().toString();
    this.currentTime = time;

    // this.paramsDate["1"] = {};
    // this.paramsDate["1"]["key"] = "date_from";
    // this.paramsDate["1"]["value"] = "2021-02-01";

    // this.paramsDate["2"] = {};
    // this.paramsDate["2"]["key"] = "date_to";
    // this.paramsDate["2"]["value"] = "2021-02-02";

    // //
    // this.paramsOrder["1"] = {};
    // this.paramsOrder["1"]["key"] = "order_uuid";
    // this.paramsOrder["1"]["value"] = "3b98d244-58cf-4989-ab90-b1164e3b6bee";
  }

  public currentColor = {
    hex: '#000',
  };
  public options = {
    palette: CUSTOM_COLORS,
    itemsPer: 8,
    itemSize: { height: 25, width: 35 },
  };

  public onTestGlobalVar(): void {
    // this.logger.info("TestComponent.onTestGlobalVar()");
    // this.bc.postMessage('This is a test message.');
  }

  // public handleMessage(event: MessageEvent): void {
  //     // this.logger.info("TestComponent.handleMessage() -> " + event.data);
  // }

  public backgroundColor?: string;

  ngOnInit(): void {
    this.backgroundColor = '#fff';
    this.setUpCalendar();
    this.schedule();

    // this.eventsService.detailsByDateRange(
    //     "name",
    //     "desc",
    //     0,
    //     25,
    //     "2019-11-18T00:00:00",
    //     "2019-11-30T00:00:00",
    //     false).then(
    //     (val) => {
    //         this.primeNGData = val.data;
    //         this.totalRecords = this.primeNGData.length;
    //     },
    //     (err) => {
    //     }
    // );

    this.primeNGCols = [
      { field: 'name', header: 'Nom' },
      { field: 'event_date', header: 'Date' },
    ];

    // this.carService.getCarsMedium().then(cars =>
    // {
    //     this.cars1 = cars;
    // });
    // this.carService.getCarsMedium().then(cars => this.cars2 = cars);
    // this.cols = [
    //     { field: 'vin', header: 'Vin' },
    //     { field: 'year', header: 'Year' },
    //     { field: 'brand', header: 'Brand' },
    //     { field: 'color', header: 'Color' }
    // ];
  }

  ngAfterViewInit(): void {
    this.setUpCalendarData();
    // this.loadPagedJsPolyfill();
  }

  public setColor(type: string, color: string) {
    switch (type) {
      case 'background':
        this.backgroundColor = color;
        break;
      default:
        break;
    }
  }

  onDatePickerChange(event: any): void {
    //this.logger.info("TestComponent.onDatePickerChange() event: " + event);
    // this.selectedDate = event;
    // this.date = event;
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {}

  loadPagedJsPolyfill() {
    const node = document.createElement('script');
    node.src = 'https://unpkg.com/pagedjs/dist/paged.polyfill.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  public onAPIDump(): void {
    //this.logger.info("TestComponent.onAPIDump() ");

    // const url: string = "https://api.dev.gestion-traiteur.app/v1/error-dump";
    const url: string = 'http://api.gt.oo/v1/error-dump';

    this.http.post<any>(url, {}).subscribe(
      (response) => {
        //this.logger.info("TestComponent.onAPIDump() --> SUCCESS: " + JSON.stringify(response));
      },
      (error) => {
        this.logger.error(
          'TestComponent.onAPIDump() --> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public onReportTest(): void {
    this.reportsService
      .prepareReports(
        ReportsFactory.getVO(ReportsEnum.REPORT_DATA_PRODUCTS_LIST),
      )
      .subscribe(
        (response) => {
          // this.logger.info("TestComponent.onReportTest() --> SUCCESS: " + JSON.stringify(response));
          this.openReportPreview(response.raw_html);
        },
        (error) => {
          this.logger.error(
            'TestComponent.onReportTest() --> ERROR: ' + JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public onPrintReportTest(): void {
    this.reportsService.printReports(
      ReportsFactory.getVO(ReportsEnum.REPORT_DATA_PRODUCTS_LIST),
    );
  }

  public openReportPreview(rawHTML: string): void {
    // this.logger.info("TestComponent.openReportPreview() --> rawHTML: " + rawHTML);
    this.dialogRef = this.dialog.open(ReportViewerComponent, {
      maxWidth: '96vw',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      maxHeight: '98vh',
      disableClose: false,
      data: {
        rawHTML: rawHTML,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.logger.info("POP UP Closed!");
      }
      this.dialogRef = null;
    });
  }

  public onReportv1(): void {
    //this.logger.info("TestComponent.onReportv1() ");
  }

  onLazyLoadPagination(event: LazyLoadEvent | any): void {
    this.loading = true;

    // in a real application, make a remote request to load data using state metadata from event
    // event.first = First row offset
    // event.rows = Number of rows per page
    // event.sortField = Field name to sort with
    // event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    // filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    this.eventsService
      .detailsByDateRange(
        event.sortField,
        event.sortOrder === 1 ? 'desc' : 'asc',
        event.first / event.rows,
        this.pageSize,
        '2019-11-18T00:00:00',
        '2019-11-30T00:00:00',
        false,
        event.filters,
      )
      .then(
        (val) => {
          this.loading = false;
          this.totalRecords = val.meta.pagination.total;
          this.primeNGData = val.data;
        },
        (err) => {},
      );

    // imitate db connection over a network
    // setTimeout(() => {
    //     if (this.datasource) {
    //         this.cars = this.datasource.slice(event.first, (event.first + event.rows));
    //         this.loading = false;
    //     }
    // }, 1000);
  }

  // public validateFilterInput(str: string): any
  // {
  //     if (str.trim().length < 3)
  //     {
  //         return null;
  //     }
  //     return str;
  // }

  public setUpCalendar() {
    const calendarEl = document.getElementById('calendar');
    this.calendar = new FullCalendar.Calendar(calendarEl, {
      now: '2018-04-07',
      editable: true,
      aspectRatio: 1.8,
      scrollTime: '00:00',
      header: {
        left: 'promptResource today prev,next',
        center: 'title',
        right: 'timelineDay,timelineThreeDays,agendaWeek,month',
      },
      customButtons: {
        promptResource: {
          text: '+ salle',
          click: function () {
            const title = prompt('Nom de la salle');
            if (title) {
              this.calendar.addResource({ title: title }, true); // true = scroll to new resource
            }
          },
        },
      },
      defaultView: 'timelineDay',
      views: {
        timelineThreeDays: {
          type: 'timeline',
          duration: { days: 3 },
        },
      },
      resourceLabelText: 'Salles',
      eventDrop: function (eventDropInfo: any) {},
      eventClick: function (info: any) {},
      resourceRender: function (resource: any, tr: any) {
        resource.el.addEventListener('click', function () {
          if (
            confirm('Are you sure you want to delete ' + resource.title + '?')
          ) {
            this.calendar.removeResource(resource);
          }
        });
        // if (tr) {
        //     tr.addEventListener('click', function () {
        //         if (confirm('Are you sure you want to delete ' + resource.title + '?')) {
        //             this.calendar.removeResource(resource);
        //         }
        //     });
        // }
      },
      height: 'auto',
      resources: [
        { id: 'a', title: 'Auditorium A' },
        { id: 'b', title: 'Auditorium B', eventColor: 'green' },
        // { id: 'c', title: 'Auditorium C', eventColor: 'orange' },
        // { id: 'd', title: 'Auditorium D', children: [
        //   { id: 'd1', title: 'Room D1' },
        //   { id: 'd2', title: 'Room D2' }
        // ] },
        // { id: 'e', title: 'Auditorium E' },
        // { id: 'f', title: 'Auditorium F', eventColor: 'red' },
        // { id: 'g', title: 'Auditorium G' },
        // { id: 'h', title: 'Auditorium H' },
        // { id: 'i', title: 'Auditorium I' },
        // { id: 'j', title: 'Auditorium J' },
        // { id: 'k', title: 'Auditorium K' },
        // { id: 'l', title: 'Auditorium L' },
        // { id: 'm', title: 'Auditorium M' }
      ],
      events: [
        // { id: '1', resourceId: 'a', start: '2018-04-07T02:00:00', end: '2018-04-07T07:00:00', title: 'event 1' },
        // { id: '2', resourceId: 'c', start: '2018-04-07T05:00:00', end: '2018-04-07T22:00:00', title: 'event 2' },
        // { id: '3', resourceId: 'd', start: '2018-04-06', end: '2018-04-08', title: 'event 3' },
        // { id: '4', resourceId: 'e', start: '2018-04-07T03:00:00', end: '2018-04-07T08:00:00', title: 'event 4' },
        // { id: '5', resourceId: 'f', start: '2018-04-07T00:30:00', end: '2018-04-07T02:30:00', title: 'event 5' }
      ],
    });
    this.calendar.render();
  }

  public setUpCalendarData(): void {
    // this.logger.info("TestComponent.setUpCalendarData()");
    // this.calendar.resources = this.calendarRessources;
    // this.calendar.events = this.calendarEvents;

    this.calendar.addResource({
      id: 'ab',
      title: 'Chez Andre',
      eventColor: 'orange',
    });
    this.calendar.addEvent({
      id: '2',
      resourceId: 'ab',
      start: '2018-04-07T05:00:00',
      end: '2018-04-07T22:00:00',
      title: 'event 2',
    });

    // this.calendar.updateEvents(this.calendarRessources);

    // this.calendar.render();
  }

  public testMaintenance(): void {
    // this.logger.info("TestComponent.testMaintenance()");
    this.router.navigateByUrl('/' + AppRoutes.MAINTENANCE);
  }

  public schedule() {
    const scheduleEl = document.getElementById('schedule');
    const schedule = new FullCalendar.Calendar(scheduleEl, {
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay',
      },
      defaultDate: '2018-04-12',
      navLinks: true, // can click day/week names to navigate views
      selectable: true,
      selectHelper: true,
      select: function (start: any, end: any) {
        const title = prompt('Event Title:');
        let eventData;
        if (title) {
          eventData = {
            title: title,
            start: start,
            end: end,
          };
          $('#calendar').fullCalendar('renderEvent', eventData, true); // stick? = true
        }
        $('#calendar').fullCalendar('unselect');
      },
      editable: true,
      eventLimit: true, // allow "more" link when too many events
      events: [
        {
          title: 'All Day Event',
          start: '2018-04-01',
        },
        {
          title: 'Long Event',
          start: '2018-04-07',
          end: '2018-04-10',
        },
        {
          id: 999,
          title: 'Repeating Event',
          start: '2018-04-09T16:00:00',
        },
        {
          id: 999,
          title: 'Repeating Event',
          start: '2018-04-16T16:00:00',
        },
        {
          title: 'Conference',
          start: '2018-04-11',
          end: '2018-04-13',
        },
        {
          title: 'Meeting',
          start: '2018-04-12T10:30:00',
          end: '2018-04-12T12:30:00',
        },
        {
          title: 'Lunch',
          start: '2018-04-12T12:00:00',
        },
        {
          title: 'Meeting',
          start: '2018-04-12T14:30:00',
        },
        {
          title: 'Happy Hour',
          start: '2018-04-12T17:30:00',
        },
        {
          title: 'Dinner',
          start: '2018-04-12T20:00:00',
        },
        {
          title: 'Birthday Party',
          start: '2018-04-13T07:00:00',
        },
        {
          title: 'Click for Google',
          url: 'http://google.com/',
          start: '2018-04-28',
        },
      ],
    });
    schedule.render();
  }

  // FullCalendar Events
  public eventClick(data: any): void {
    // this.logger.info("TestComponent.eventClick() -> data: " + JSON.stringify(data));
  }

  public updateEvent(data: any): void {
    // this.logger.info("TestComponent.updateEvent() -> data: " + JSON.stringify(data));
  }

  public clickButton(data: any): void {
    // this.logger.info("TestComponent.eventClick() -> data: " + JSON.stringify(data));
  }

  // ERRORS
  public onError429() {
    this.http
      .get<any>('https://api.dev.gestion-traiteur.app/v1/error-code/429')
      .subscribe(
        (response) => {
          // this.logger.info("TestComponent.onError429() --> SUCCESS: " + JSON.stringify(response));
        },
        (error) => {
          this.logger.error(
            'TestComponent.onError429() --> ERROR: ' + JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
          // this.handleAPIError(error, this.dialog);
        },
      );
  }

  public onError404() {
    this.http
      .get<any>('https://api.dev.gestion-traiteur.app/v1/error-code/404')
      .subscribe(
        (response) => {
          // this.logger.info("TestComponent.onError404() --> SUCCESS: " + JSON.stringify(response));
        },
        (error) => {
          this.logger.error(
            'TestComponent.onError404() --> ERROR: ' + JSON.stringify(error),
          );
        },
      );
  }

  public onError500() {
    this.http
      .get<any>('https://api.dev.gestion-traiteur.app/v1/error-code/500')
      .subscribe(
        (response) => {
          // this.logger.info("TestComponent.onError500() --> SUCCESS: " + JSON.stringify(response));
        },
        (error) => {
          this.logger.error(
            'TestComponent.onError500() --> ERROR: ' + JSON.stringify(error),
          );
        },
      );
  }

  public onError400() {
    this.http
      .get<any>('https://api.dev.gestion-traiteur.app/v1/error-code/400')
      .subscribe(
        (response) => {
          // this.logger.info("TestComponent.onError500() --> SUCCESS: " + JSON.stringify(response));
        },
        (error) => {
          this.logger.error(
            'TestComponent.onError500() --> ERROR: ' + JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public onError408() {
    this.http
      .get<any>('https://api.dev.gestion-traiteur.app/v1/error-code/408')
      .subscribe(
        (response) => {
          // this.logger.info("TestComponent.onError500() --> SUCCESS: " + JSON.stringify(response));
        },
        (error) => {
          this.logger.error(
            'TestComponent.onError500() --> ERROR: ' + JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public onError422() {
    this.http
      .post<any>('https://api.dev.gestion-traiteur.app/v1/error-code/422', {})
      .subscribe(
        (response) => {
          // this.logger.info("TestComponent.onError422() --> SUCCESS: " + JSON.stringify(response));
        },
        (error) => {
          this.logger.error(
            'TestComponent.onError422() --> ERROR: ' + JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public onError456() {
    this.http
      .get<any>('https://api.dev.gestion-traiteur.app/v1/error-code/456')
      .subscribe(
        (response) => {
          // this.logger.info("TestComponent.onError456() --> SUCCESS: " + JSON.stringify(response));
        },
        (error) => {
          this.logger.error(
            'TestComponent.onError456() --> ERROR: ' + JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  // handleFileInput(files: FileList)
  // {
  //     this.logger.info("------> " + this.fileToUpload);
  //     this.fileToUpload = files.item(0);
  //     this.logger.info("------> " + this.fileToUpload);
  // }
}

export interface TestProduct {
  id: string;
  locale: string;
  data: TestProductData;
}

export interface TestProductData {
  name: string;
  description: string;
}

// export interface AddressModelTest
// {
//     addressNumber: string;
//     addressStreet: string;
//     addressApp: string;
//     addressLine2: string;
//     addressCity: string;
//     addressState: string;
//     addressCountry: string;
//     addressPostalCode: string;
// }

// export class DynamicTableTestItemAction implements IDynamicTableItemAction
// {
//
//     constructor(public router: Router)
//     {
//
//     }
//
//     onItemClick( item: any ): void
//     {
//         this.logger.info("DynamicTableTestItemAction.onItemClick()  -> " + JSON.stringify(item));
//         this.router.navigateByUrl("/dashboard");
//     }
//
// }
