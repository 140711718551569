import * as i0 from '@angular/core';
import { InjectionToken, signal, inject, computed, Injectable, effect, untracked, Directive } from '@angular/core';
import * as i1 from 'ngx-progressbar';
import { NgProgressRef } from 'ngx-progressbar';
import { finalize } from 'rxjs';
const NG_PROGRESS_HTTP_OPTIONS = new InjectionToken('NG_PROGRESS_HTTP_OPTIONS', {
  providedIn: 'root',
  factory: () => null
});
function provideNgProgressHttp(options) {
  return [{
    provide: NG_PROGRESS_HTTP_OPTIONS,
    useValue: options
  }];
}
const NgProgressHttpCounter = new InjectionToken('NgProgressHttpCounter', {
  providedIn: 'root',
  factory: () => signal(0)
});
class NgProgressHttpManager {
  constructor() {
    this.inProgressCount = inject(NgProgressHttpCounter);
    this.requestsCount = computed(() => this.inProgressCount());
    this.requestsLoading = computed(() => !!this.inProgressCount());
  }
  static {
    this.ɵfac = function NgProgressHttpManager_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgProgressHttpManager)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgProgressHttpManager,
      factory: NgProgressHttpManager.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgProgressHttpManager, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class NgProgressHttpBase {
  constructor() {
    this.manager = inject(NgProgressHttpManager);
    this.progressRef = inject(NgProgressRef, {
      host: true,
      self: true
    });
    let initial = true;
    effect(() => {
      const requestLoading = this.manager.requestsLoading();
      // Ignore the initial execution if loading state is false
      if (initial) {
        initial = false;
        if (!requestLoading) return;
      }
      untracked(() => {
        if (requestLoading) {
          this.progressRef.start();
        } else if (this.progressRef.active()) {
          this.progressRef.complete();
        }
      });
    });
  }
  static {
    this.ɵfac = function NgProgressHttpBase_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgProgressHttpBase)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgProgressHttpBase,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgProgressHttpBase, [{
    type: Directive
  }], () => [], null);
})();
class NgProgressHttp extends NgProgressHttpBase {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵNgProgressHttp_BaseFactory;
      return function NgProgressHttp_Factory(__ngFactoryType__) {
        return (ɵNgProgressHttp_BaseFactory || (ɵNgProgressHttp_BaseFactory = i0.ɵɵgetInheritedFactory(NgProgressHttp)))(__ngFactoryType__ || NgProgressHttp);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgProgressHttp,
      selectors: [["ng-progress", "ngProgressHttp", ""]],
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgProgressHttp, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: 'ng-progress[ngProgressHttp]'
    }]
  }], null, null);
})();
class NgProgressHttpRef extends NgProgressHttpBase {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵNgProgressHttpRef_BaseFactory;
      return function NgProgressHttpRef_Factory(__ngFactoryType__) {
        return (ɵNgProgressHttpRef_BaseFactory || (ɵNgProgressHttpRef_BaseFactory = i0.ɵɵgetInheritedFactory(NgProgressHttpRef)))(__ngFactoryType__ || NgProgressHttpRef);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgProgressHttpRef,
      selectors: [["", "ngProgressHttp", "", 5, "ng-progress"]],
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([i1.NgProgressRef]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgProgressHttpRef, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[ngProgressHttp]:not(ng-progress)',
      hostDirectives: [NgProgressRef]
    }]
  }], null, null);
})();
function progressInterceptor(req, next) {
  const config = inject(NG_PROGRESS_HTTP_OPTIONS);
  const inProgressCount = inject(NgProgressHttpCounter);
  // Ignore by request headers
  if (req.headers.has('ignoreProgressBar')) {
    return next(req.clone({
      headers: req.headers.delete('ignoreProgressBar')
    }));
  }
  // Ignore silent api requests
  if (config && checkUrl(req, config)) {
    return next(req);
  }
  return untracked(() => {
    inProgressCount.set(inProgressCount() + 1);
    return next(req).pipe(finalize(() => {
      inProgressCount.set(inProgressCount() - 1);
    }));
  });
}
/**
 * Check if request is silent.
 */
function checkUrl(req, config) {
  const url = req.url.toLowerCase();
  if (config.matcher && config.silentApis?.length) {
    return checkForMatcher(url, config.matcher) && checkForSilentApis(url, config.silentApis);
  }
  if (config.silentApis?.length) {
    return checkForSilentApis(url, config.silentApis);
  }
  if (config.matcher) {
    return checkForMatcher(url, config.matcher);
  }
  return false;
}
function checkForSilentApis(url, silentApis) {
  return !!silentApis.find(u => url.includes(u.toLowerCase()));
}
function checkForMatcher(url, matcher) {
  return !!url.match(matcher);
}

/**
 * Generated bundle index. Do not edit.
 */

export { NG_PROGRESS_HTTP_OPTIONS, NgProgressHttp, NgProgressHttpRef, progressInterceptor, provideNgProgressHttp };
