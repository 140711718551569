<div style="max-height: 100%; overflow: auto">
  <h1 class="h1-title" matDialogTitle *ngIf="mode === 'CREATE'">
    {{ "CREATE_GUEST.TITLE" | translate }}
  </h1>
  <h1 class="h1-title" matDialogTitle *ngIf="mode === 'EDIT'">
    {{ "CREATE_GUEST.EDIT_TITLE" | translate }}
  </h1>

  <div fxLayout="column" fxLayoutAlign="center start">
    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      [formGroup]="form"
      (keyup.enter)="form.valid && !loading ? onCreateGuest() : null"
      class="item-full-width"
    >
      <div>{{ "CREATE_GUEST.SELECT_GUEST" | translate }}</div>
      <div *ngIf="guests" class="item-full-width mb-36 mt-12">
        <p-dropdown
          [options]="guests"
          [(ngModel)]="selectedGuest"
          [style]="{
            width: '100%',
            height: '55px',
            borderWidth: '0px',
            borderBottomWidth: '1px',
          }"
          editable="true"
          formControlName="selectedGuest"
          placeholder="{{ 'CREATE_GUEST.SELECT_GUEST' | translate }}"
        >
        </p-dropdown>
      </div>
      <div
        *ngIf="!guests"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="item-full-width mb-16"
      >
        <mat-spinner [diameter]="20" class=""></mat-spinner>
      </div>

      <mat-form-field class="item-full-width" fxFlex="100%">
        <mat-label>{{ "GENERIC.TOTAL" | translate }}</mat-label>
        <input
          matInput
          formControlName="guest_type_count"
          type="text"
          autocomplete="nope"
          [(ngModel)]="guest.guest_type_count"
        />
        <mat-error *ngIf="form.controls['guest_type_count'].invalid">
          {{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
          {{ "GENERIC.INPUT_NUMBER_POSTFIX_INTEGER_ONLY" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-32 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!form.valid || loading"
      color="accent"
      class="mr-16"
      (click)="onCreateGuest()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>

  <div class="mb-16"></div>
</div>
