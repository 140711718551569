export const locale = {
  lang: "en-CA",
  data: {
    SELECT_CLIENT: {
      TITLE: "Select client",
      BY_ENTERPRISE: "Sort by entreprise",
      BY_CLIENT: "Sort by client",
      SELECTED_CLIENT: "Selected client: ",
      NO_CLIENTS: "No client",
      SEARCH: "Search your client by name or enterprise name",
      CREATE_CLIENT: "Create client",
      CREATE_ENTERPRISE: "Create enterprise",
      CREATE_CLIENT_ENTERPRISE: "Create client et entreprise",
      NEW_CLIENT_TOOLTIP: "Add new client or enterprise",
    },
  },
};
