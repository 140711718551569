import { GT2ConstantsEnum } from "../const/GT2ConstantsEnum";

export class UserRole {
  static GOD: string = GT2ConstantsEnum.GOD;
  static OWNER: string = GT2ConstantsEnum.OWNER;
  static ADMIN: string = GT2ConstantsEnum.ADMIN;
  static TEAM_LEAD: string = GT2ConstantsEnum.TEAM_LEAD;
  static SALE: string = GT2ConstantsEnum.SALE;
  static ACCOUNTANT: string = GT2ConstantsEnum.ACCOUNTANT;
  static HR: string = GT2ConstantsEnum.HR;
  static EMPLOYEE: string = GT2ConstantsEnum.EMPLOYEE;

  constructor() {}
}
