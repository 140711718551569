import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { RolesService } from '../../../../services/roles.service';
import { locale as english } from '../../i18n/en-CA';
import { locale as french } from '../../i18n/fr-CA';
import { CommonModule, Location } from '@angular/common';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { GT2FormPageAbstract } from '../../../../content/abstract/GT2FormPageAbstract';
import { ItemUrlFinder } from '../../../../utils/item-url-finder';
import { SelectClientComponent } from '../../../../components/dialogs/select-client/select-client.component';
import { EventClientSectionComponent } from '../../../../content/events/event/event-clients/event-client-section/event-client-section.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-clients-box',
  templateUrl: './clients-box.component.html',
  styleUrls: ['./clients-box.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    NgxPermissionsModule,
    MatIconModule,
    MatTooltipModule,
    FlexLayoutModule,
    EventClientSectionComponent,
    TranslateModule,
  ],
  animations: fuseAnimations,
})
export class ClientsBoxComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() project: any;
  @Output() projectChange = new EventEmitter<any>();
  @Input() editable: boolean = true;
  @Output() editableChange = new EventEmitter<any>();
  loading: boolean = false;
  editMode: boolean = false;
  declare dialogRef: any;
  @Output() modelUpdatedEvent = new EventEmitter<any>();
  @ViewChild('orderingClient')
  billingClient?: EventClientSectionComponent;
  @ViewChild('invoicingClient')
  invoicingClient?: EventClientSectionComponent;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public logger: NGXLogger,
    public location: Location,
    private dialog: MatDialog,
    public rolesService: RolesService,
    public router: Router,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // //this.logger.info("ClientsBoxComponent.ngAfterViewInit() -> this.event: " + JSON.stringify(this.event));
  }

  ngOnDestroy() {}

  public loadData(): void {}

  public onToggleEdit(): void {
    this.editMode = !this.editMode;
    // //this.logger.info("ClientsBoxComponent.onEdit() -> this.editMode: " + this.editMode);
  }

  public onSaveData(): void {
    //this.logger.info("ClientsBoxComponent.onSaveData()");
  }

  public onManualSave(): void {
    //this.logger.info("ClientsBoxComponent.onManualSave()");
  }

  public onModelLoaded(): void {
    if (this.billingClient) {
      this.billingClient.onModelLoaded();
    }
    if (this.invoicingClient) {
      this.invoicingClient.onModelLoaded();
    }
    this.loading = false;
  }

  public onDispatchModelUdpated(result: any): void {
    //this.logger.info("ClientsBoxComponent.onDispatchModelUdpated() -> result: " + JSON.stringify(result));
    this.modelUpdatedEvent.next(result);
  }

  public onComponentModelUpdated(): void {
    //this.logger.info("ClientsBoxComponent.onComponentModelUpdated()");
    this.modelUpdatedEvent.next(undefined);
  }

  public getClientUUID(type: string): string | null {
    if (type === 'ordering') {
      if (this.project && this.project.ordering_client) {
        return this.project.ordering_client.uuid;
      } else {
        return null;
      }
    } else {
      if (this.project && this.project.invoicing_client) {
        return this.project.invoicing_client.uuid;
      } else {
        return null;
      }
    }
  }

  public onOpenPage(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public onOpenInNewTab(client: any): void {
    // //this.logger.info("ClientsBoxComponent.onOpenInNewTab() -> client: " + JSON.stringify(client));
    // //this.logger.info("ClientsBoxComponent.onOpenInNewTab() -> client url: " + ItemUrlFinder.getItemURL(client));
    window.open(ItemUrlFinder.getItemURL(client), '_blank');
  }

  public onOpenSelectClient(client: any, section: any): void {
    //this.logger.info("ClientsBoxComponent.onOpenSelectClient() -> client: " + JSON.stringify(client));
    this.dialogRef = this.dialog.open(SelectClientComponent, {
      width: '80%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        eventUUID: null,
        projectUUID: this.project.uuid,
        clientSection: section,
        clientFor: EventClientSectionComponent.CLIENT_FOR_PROJECT,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.onComponentModelUpdated();
      }
      this.dialogRef = null;
    });
  }
}
