export const locale = {
  lang: "en-CA",
  data: {
    WRAPPING: {
      CREATE_NEW_CATEGORY: "New wrapping",
      CATEGORY_NAME: "Wrapping name",
      HOURLY_WAGE: "Hourly wage",
      NEW_CATEGORY_NAME: "Name of new wrapping",
      CREATE_SUCCESS: "Wrapping created successfully",
      EDIT_SUCCESS: "Wrapping modified successfully",
      DELETE_SUCCESS: "Wrapping deleted successfully",
    },
  },
};
