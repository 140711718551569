import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { GenericAlertComponent } from '../../../../components/generic-alert/generic-alert.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ValidatorHelper } from '../../../../validators/ValidatorHelper';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DisplayHtmlLinkPipeModule } from '../../../../modules/display-html-link.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-notifications-settings',
  templateUrl: './notifications-settings.component.html',
  styleUrls: ['./notifications-settings.component.scss'],
  standalone: true,
  imports: [
    MatSelectModule,
    TranslateModule,
    MatIconModule,
    MatOptionModule,
    FlexLayoutModule,
    MatCheckboxModule,
    DisplayHtmlLinkPipeModule,
    MatInputModule,
    NgxPermissionsModule,
    MatButtonModule,
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class NotificationsSettingsComponent implements OnInit {
  @Input() shop: any;
  @Input() editMode: boolean = false;
  @Output() shopChange: EventEmitter<any> = new EventEmitter();
  confirmDialogRef?: MatDialogRef<GenericAlertComponent>;
  emailsErrorMessage: any;
  emailWithError?: string;
  @Input() boxName?: string;

  notificationControl = new UntypedFormControl('');
  notificationGroups: notificationGroup[] = [];
  notify_emails = new UntypedFormControl('', [
    ValidatorHelper.validateEmailsArr,
  ]);

  constructor(
    private logger: NGXLogger,
    private dialog: MatDialog,
    public translate: TranslateService,
    public fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    // Initialisation de notificationGroups après injection de TranslateService
    this.notificationGroups = [
      {
        name: this.translate.instant('SHOPS.AUTOMATIC'),
        notification: [
          {
            value: 'DIRECT',
            viewValue: this.translate.instant(
              'SHOPS.NOTIFICATION_CORPORATE_DIRECT',
            ),
          },
        ],
      },
      {
        name: this.translate.instant('SHOPS.WITH_NOTIFICATION'),
        notification: [
          {
            value: 'NOTIFICATION',
            viewValue: this.translate.instant(
              'SHOPS.NOTIFICATION_CORPORATE_NOTIFICATION2',
            ),
          },
        ],
      },
    ];

    // Autres initialisations
    this.notificationControl.setValue(this.shop.register_corporate_mode);
  }

  public onSaveInfoNotificationByRole() {
    if (this.notify_emails.valid) {
      this.shopChange.emit();
    }
  }

  public onSaveInfoCorp(): void {
    this.shop.register_corporate_mode = this.notificationControl.value;
    this.shopChange.emit();
  }

  public onSaveInfo(): void {
    this.shopChange.emit();
  }

  public onShowConfirmedInfo(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useHTML = true;
    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle = this.translate.instant(
      'SHOPS.EMAILS_TO_NOTIFY',
    );
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('SHOPS.EMAILS_TO_NOTIFY_ALERT_MESSAGE');

    this.confirmDialogRef.afterClosed().subscribe((result) => {});
  }
}

interface notification {
  value: string;
  viewValue: string;
}

interface notificationGroup {
  disabled?: boolean;
  name: string;
  notification: notification[];
}
