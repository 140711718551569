export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_CLIENT: {
      TITLE: "Nouveau client",
      TITLE_CONTACT: "Infos contact client",
      TITLE_ADDRESS: "Infos address client",
      COMM_LANGUAGE: "Langue de communication",
    },
    CREATE_ENTERPRISE: {
      TITLE: "Nouvelle entreprise",
      TITLE_CONTACT: "Infos contact entreprise",
      TITLE_ADDRESS: "Infos address entreprise",
    },
  },
};
