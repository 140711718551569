import { Editor, EditorModule } from 'primeng/editor';
import { Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProductCategoryService } from './../../../../api/product-category.service';
import { ProductionZonesService } from './../../../../api/production-zones.service';

import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { OrdersService } from '../../../../api/orders.service';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../../settings/settings';
import { ValidatorHelper } from '../../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from '../../i18n/en-CA';
import { locale as french } from '../../i18n/fr-CA';
import { ModulesService } from '../../../../services/modules.service';
import { OrderComponent } from '../order.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { WrappingService } from '../../../../api/wrapping.service';
import { SpecificConditionService } from '../../../../api/specific-condision.service';
import { ProposalComposeService } from '../../../../api/proposal-compose.service';
import { ProposalComposeCTService } from '../../../../api/proposal-compose-ct.service';
import { OrderComposeService } from '../../../../api/order-compose.service';
import { InvoiceComposeService } from '../../../../api/invoice-compose.service';
import { InvoiceComposeCTService } from '../../../../api/invoice-compose-ct.service';
import { InvoiceComposeGlobalPaymentsService } from '../../../../api/invoice-compose-global-payments.service';
import { CatererService } from '../../../caterer/caterer.service';
import { ConseillersService } from '../../../../api/conseillers.service';
import { ReportsService } from '../../../../reports/reports.service';
import { ReportFactoryService } from '../../../../reports/report-factory.service';
import { MenuReportsService } from '../../../../reports/menu-reports.service';
import { ReceiptNoticeComposeService } from '../../../../api/receipt-notice-compose.service';
import { ReceiptNoticeSellOnlyComposeService } from '../../../../api/receipt-notice-sell-only-compose.service';
import { StorageNoticeComposeService } from '../../../../api/storage-notice-compose.service';
import { Gt2SelectComponent } from '../../../../components/gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
  standalone: true,
  imports: [
    EditorModule,
    CommonModule,
    MatInputModule,
    TranslateModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatSelectModule,
    Gt2SelectComponent,
    MatTooltipModule,
    MatToolbarModule,
    MatIconModule,
  ],
  providers: [
    WrappingService,
    SpecificConditionService,
    ProposalComposeService,
    ProposalComposeCTService,
    OrderComposeService,
    InvoiceComposeService,
    InvoiceComposeCTService,
    InvoiceComposeGlobalPaymentsService,
    CatererService,
    ConseillersService,
    ReportsService,
    ReportFactoryService,
    MenuReportsService,
    ReceiptNoticeComposeService,
    ReceiptNoticeSellOnlyComposeService,
    StorageNoticeComposeService,
  ],
})
export class EditProductComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('txtEditor') txtEditor?: Editor;
  product: any;
  productCategory: any;
  orderUUID!: string;
  parentUUID?: string;
  loading: boolean = false;
  form: UntypedFormGroup;
  priceForm: UntypedFormGroup;
  chargedPriceForm: UntypedFormGroup;

  noteInvalid: boolean = false;
  noteSubscription?: Subscription;
  editable: boolean = false;
  // noteEditordebounceTimeValue: number = 100;
  zones = new UntypedFormControl('');
  zonesList: any = {};
  productionZones: string[] = [];
  isComboChild: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<EditProductComponent>,
    private translationLoader: FuseTranslationLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    public dialog: MatDialog,
    public router: Router,
    public productCategoryService: ProductCategoryService,
    public productionZonesService: ProductionZonesService,
    public ordersService: OrdersService,
    private logger: NGXLogger,
    public modulesService: ModulesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    if (data) {
      this.product = data.product;
      this.isComboChild = data.isComboChild;
      this.productCategory = data.productCategory;
      this.orderUUID = data.orderUUID;
      this.parentUUID = data.parentUUID;
      this.editable = data.editable;
      //this.logger.info("EditProductComponent.constructor() -> DATA product: " + JSON.stringify(this.product));
      //this.logger.info("EditProductComponent.constructor() -> charged_price: " + this.product.charged_price);
      // //this.logger.info(
      //     "EditProductComponent.constructor() -> DATA productCategory: " +
      //         JSON.stringify(this.productCategory)
      // );
      if (this.product.production_zones) {
        for (let zone of this.product.production_zones) {
          this.productionZones.push(zone['uuid']);
        }
        //this.logger.info("EditProductComponent.constructor() -> DATA productZones: " + JSON.stringify(this.productionZones));
      }
    } else {
      this.logger.error(
        'EditProductComponent.constructor() -> NO DATA passed to this dialog!',
      );
    }

    //
    this.form = this.formBuilder.group({
      name: [
        { value: '', disabled: !this.editable },
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_fr_CA: [
        { value: '', disabled: !this.editable },
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_internal_fr_CA: [
        { value: '', disabled: !this.editable },
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_internal_en_CA: [
        { value: '', disabled: !this.editable },
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      description_fr_CA: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
      description_en_CA: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
      prod_note: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
      sale_note: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
      // unit_price: [
      //     { value: "", disabled: !this.editable },
      //     [
      //         // ValidatorHelper.isNumber0To1000000,
      //         ValidatorHelper.isValidPriceNumber,
      //         // Validators.required,
      //     ],
      // ],

      quantity: [
        { value: '', disabled: !this.editable },
        [Validators.required],
      ],
    });

    this.priceForm = this.formBuilder.group({
      unit_price: [
        { value: '', disabled: !this.editable },
        [
          // ValidatorHelper.isNumber0To1000000,
          ValidatorHelper.isValidPriceNumber,
          // Validators.required,
        ],
      ],
    });
    this.chargedPriceForm = this.formBuilder.group({
      unit_price: [
        { value: '', disabled: !this.editable },
        [
          // ValidatorHelper.isNumber0To1000000,
          ValidatorHelper.isValidPriceNumber,
          // Validators.required,
        ],
      ],
    });

    // this.formChargedPrice = this.formBuilder.group({
    //     charged_price: [
    //         { value: "", disabled: !this.editable },
    //         [
    //             // ValidatorHelper.isNumber0To1000000,
    //             ValidatorHelper.isValidPriceNumber,
    //             // Validators.required,
    //         ],
    //     ],
    // });

    // Populate zones list
    this.productionZonesService.getDataList().subscribe(
      (response) => {
        // //this.logger.info(
        //     "EditProductComponent.getZonesList() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        var data = response.data;
        // //this.logger.info(
        //     "EditProductComponent.DATA BRUT =>: " + JSON.stringify(data)
        // );
        for (const zone of data) {
          // //this.logger.info(
          //     "EditProductComponent.ZONE =>: " + JSON.stringify(zone)
          // );
          this.zonesList[zone['uuid']] = zone['name'];
        }
        // //this.logger.info(
        //     "EditProductComponent.zonesList =>: " +
        //         JSON.stringify(this.zonesList)
        // );
      },
      (error) => {
        //this.logger.info("EditProductComponent.getZonesList() -> ERROR: " + JSON.stringify(error));
      },
    );
  }

  ngOnInit() {
    // this.evaluateShowPrice();
  }

  ngAfterViewInit() {
    if (this.txtEditor) {
      this.noteSubscription = this.txtEditor.onTextChange.subscribe((data) => {
        //this.logger.info("EditProductComponent.noteEditor.onTextChange() -> " + data.htmlValue);
        //this.logger.info("EditProductComponent.noteEditor.onTextChange() -> invalid: " + (data.htmlValue.length > Settings.editorMaxCharLong));
        //this.logger.info("EditProductComponent.noteEditor.onTextChange() -> form.valid: " + this.form.valid);
        this.noteInvalid = data.htmlValue.length > Settings.editorMaxCharLong;
      });
    } else {
      this.logger.error(
        'EditProductComponent.ngAfterViewInit() -> ERROR: txtEditor is not defined!',
      );
    }
  }

  ngOnDestroy() {
    if (this.noteSubscription) {
      this.noteSubscription.unsubscribe();
    }
  }

  public onSaveProduct(closeDialog: boolean = true) {
    this.product.product_category = this.productCategory;
    this.product.production_zones = this.productionZones;
    this.product.parent_uuid = this.parentUUID;
    this.loading = true;

    if (this.product.show_charged_price && this.modulesService.hasJLReport()) {
      this.product.unit_price = this.product.charged_price;
    }
    //
    //this.logger.info("EditProductComponent.onSaveProduct() -> this.product: " + JSON.stringify(this.product));
    this.loading = true;
    this.ordersService.updateProducts(this.orderUUID, [this.product]).subscribe(
      (response: any) => {
        //this.logger.info("EditProductComponent.onSaveProduct() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;

        if (closeDialog) {
          const products = {
            ...response.data,
            product_category_collection:
              response.data.product_category_collection.map((product: any) => ({
                ...product,
                product_collection: product.product_collection.map((pc: any) =>
                  pc.combo && pc.combo.length > 0
                    ? {
                        ...pc,
                        comboToggleOpen:
                          localStorage.getItem(
                            OrderComponent.ORDER_COMBO_OPEN + pc.uuid,
                          ) === 'true',
                      }
                    : pc,
                ),
              })),
            material_category_collection:
              response.data.material_category_collection.map(
                (material: any) => ({
                  ...material,
                  material_collection: material.material_collection.map(
                    (mc: any) =>
                      mc.combo && mc.combo.length > 0
                        ? {
                            ...mc,
                            comboToggleOpen:
                              localStorage.getItem(
                                OrderComponent.ORDER_COMBO_OPEN + mc.uuid,
                              ) === 'true',
                          }
                        : mc,
                  ),
                }),
              ),
          };
          this.dialogRef.close(products);
        } else {
          // this.product.charged_price_label = response.data.charged_price_label;
          // this.product = response.data.product_collection.find( item => {
          //     return item.uuid == this.product.uuid;
          // });
          for (const prodCol of response.data.product_category_collection) {
            for (const product of prodCol.product_collection) {
              //this.logger.info("EditProductComponent.onSaveProduct() -> SUCCESS2: " + product.uuid);
              if (product.uuid == this.product.uuid) {
                this.product = product;
                break;
              }
            }
          }
        }
      },
      (error: any) => {
        // this.logger.error("EditProductComponent.onSaveProduct() -> SUCCESS: " + JSON.stringify(error));
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public onProductCategoryChange(event: any) {
    // //this.logger.info(
    //     "ProductComponent.onProductCategoryChange() -> category: " +
    //         JSON.stringify(event)
    // );
    this.productCategory = event;
  }

  public onProductShowPrice(event: any) {
    //this.logger.info("ProductComponent.onProductShowPrice() -> checked: " + event.checked);
    this.product.show_charged_price = event.checked;
    this.evaluateShowPrice();
    //
    if (event.checked == false) {
      this.onSaveProduct(false);
      // this.product.charged_price_label =
    }
  }

  public evaluateShowPrice() {
    //this.logger.info("EditProductComponent.evaluateShowPrice() => charged_price: " + this.product.charged_price);
    //this.logger.info("EditProductComponent.evaluateShowPrice() => this.product.unit_price: " + this.product.unit_price);

    if (this.product.show_charged_price) {
      // let tempPrice = this.product.charged_price;
      this.product.unit_price = parseFloat(this.product.charged_price);
      // this.product.charged_price = tempPrice;
    } else {
      // this.product.charged_price = this.product.price;
      this.product.unit_price = 0;
    }
  }

  public productZonesChange(event: any) {
    //this.logger.info("EditProductComponent.productZonesChange() => event: " + JSON.stringify(event.value));
    this.productionZones = event.value;
  }
}
