export const locale = {
  lang: 'fr-CA',
  data: {
    CREATE_DEPOSIT_REQUEST: {
      TITLE: 'Nouvelle demande de dépôt',
      EDIT_TITLE: 'Modifier demande de dépôt',
      PERCENTAGE: 'Calcul en pourcentage',
    },
  },
};
