export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_EVENT: {
      TITLE: "Nouvel événement",
      PROPOSAL_TITLE: "Nouvelle proposition",
      ORDER_TITLE: "Nouvelle commande",
      CLONE_PROPOSITION_INTO_EVENT_TITLE: "Copier la proposition dans un nouvel événement",
      CLONE_PROPOSITION_INTO_EVENT_TITLE_KEEP: "Copier la proposition dans une copie de l'événement",
      CLONE_ORDER_INTO_EVENT_TITLE: "Copier la commande dans un nouvel événement",
      CLONE_ORDER_INTO_EVENT_TITLE_KEEP: "Copier la commande dans une copie de l'événement",
      NAME_NOT_SAME: " et le nom doit être différent de celle de la proposition",
      DATE_NOT_SAME: "La date doit être différente de celle de la proposition",
      COPY: "Copie - ",
      ORDER_TYPE: "Type de commande",
    },
  },
};
