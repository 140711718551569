import * as React from "react";

const SchedulesV2 = (shop_uuid: any) => {
  return (
    <div style={{ backgroundColor: "white" }}>
      {
        //@ts-ignore
        <schedules-v2 shop_uuid={shop_uuid}></schedules-v2>
      }
    </div>
  );
};

export default SchedulesV2;
