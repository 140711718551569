import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { IGetDataService } from '../components/gt2-select/gt2-select.component';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import { GT2FirebaseService } from '../../services/gt2-firebase.service';

@Injectable()
export class OrdersService implements IGetDataService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getOrders(): any {
    //this.logger.info("OrdersService.getOrders()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.ORDERS));
  }

  public getOrdersList(): any {
    // this.logger.info("OrdersService.getOrdersList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.ORDER_LIST));
  }

  public getOrdersByDate(date: string): any {
    // this.logger.info("OrdersService.getOrdersByDate() -> date: " + date );
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDERS_BY_DATE) + date
    );
  }

  public getOrdersByWeek(date: string): any {
    // this.logger.info("OrdersService.getOrdersByWeek()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDERS_BY_WEEK) + date
    );
  }

  public getOrdersByMonth(date: string): any {
    // this.logger.info("OrdersService.getOrdersByMonth()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.EVENTS_BY_MONTH) + date
    );
  }

  public getOrdersByMonthJul(
    date: string,
    includeCancelAbortEvents: boolean
  ): any {
    // this.logger.info("OrdersService.getOrdersByMonth()");
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.EVENTS_BY_MONTH_JUL) + date,
      { include_cancel: includeCancelAbortEvents }
    );
  }

  public getProdZonesByDateJul(
    date: string,
    includeCancelAbortEvents: boolean
  ): any {
    // this.logger.info("OrdersService.getProdZonesByDate() -> date: " + date );
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.PROD_ZONE_BY_DAY_JUL) + date,
      { include_cancel: includeCancelAbortEvents }
    );
  }

  public getProdZonesByMonthJul(
    date: string,
    includeCancelAbortEvents: boolean
  ): any {
    // this.logger.info("OrdersService.getProdZonesByDate() -> date: " + date );
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.PROD_ZONE_BY_MONTH_JUL) + date,
      { include_cancel: includeCancelAbortEvents }
    );
  }

  loadDayModelProdZone: any;

  public getOrdersByUUID(uuid: string): any {
    // this.logger.info("OrdersService.getOrdersByUUIDgetOrdersByUUID()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.ORDER) + uuid);
  }

  public getData(): Observable<any> {
    //this.logger.info("OrdersService.getData()");
    return this.getOrdersList();
  }

  public createOrder(order: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.ORDERS_BY_DATE),
      order
    );
  }

  public createOrderForClient(order: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.ORDERS_BY_CLIENT),
      order
    );
  }

  public updateOrderByUUID(data: any, uuid: string): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.ORDER) + uuid,
      data
    );
  }

  public deleteOrder(orderUUID: string): any {
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.ORDER) + orderUUID
    );
  }

  // ORDER PRODUCTS
  public productCategoryMoveBefore(
    orderUUID: string,
    pcChildUUID: string,
    pcTargetUUID: string
  ): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/pc/move-before/' +
        pcChildUUID +
        '/' +
        pcTargetUUID
    );
  }

  public productCategoryMoveAfter(
    orderUUID: string,
    pcChildUUID: string,
    pcTargetUUID: string
  ): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/pc/move-after/' +
        pcChildUUID +
        '/' +
        pcTargetUUID
    );
  }

  public productMoveBefore(
    orderUUID: string,
    pcChildUUID: string,
    pcTargetUUID: string
  ): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/product/move-before/' +
        pcChildUUID +
        '/' +
        pcTargetUUID
    );
  }

  public productMoveAfter(
    orderUUID: string,
    pcChildUUID: string,
    pcTargetUUID: string
  ): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/product/move-after/' +
        pcChildUUID +
        '/' +
        pcTargetUUID
    );
  }

  public updateProduct(orderUUID: string, product: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/product/' +
        product.uuid,
      product
    );
  }

  public updateProducts(orderUUID: string, products: any[]): any {
    //this.logger.info("updateProducts() TOTAL PRODUCTS TO UPDATE: " + products.length);
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.ORDER) + orderUUID + '/products',
      { products: products }
    );
  }

  public reattachProducts(orderUUID: string, products: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/products/god-re-attach',
      products
    );
  }

  public addProduct(orderUUID: string, products: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.ORDER) + orderUUID + '/products/attach',
      products
    );
  }

  public deleteProduct(orderUUID: string, product: any): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/product/detach/' +
        product.uuid
    );
  }

  // ORDER MATERIALS
  public materialCategoryMoveBefore(
    orderUUID: string,
    mcChildUUID: string,
    mcTargetUUID: string
  ): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/mc/move-before/' +
        mcChildUUID +
        '/' +
        mcTargetUUID
    );
  }

  public materialCategoryMoveAfter(
    orderUUID: string,
    mcChildUUID: string,
    mcTargetUUID: string
  ): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/mc/move-after/' +
        mcChildUUID +
        '/' +
        mcTargetUUID
    );
  }

  public materialMoveBefore(
    orderUUID: string,
    mcChildUUID: string,
    mcTargetUUID: string
  ): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/material/move-before/' +
        mcChildUUID +
        '/' +
        mcTargetUUID
    );
  }

  public materialMoveAfter(
    orderUUID: string,
    mcChildUUID: string,
    mcTargetUUID: string
  ): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/material/move-after/' +
        mcChildUUID +
        '/' +
        mcTargetUUID
    );
  }

  public updateMaterial(orderUUID: string, material: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/material/' +
        material.uuid,
      material
    );
  }

  public updateMaterials(orderUUID: string, materials: any[]): any {
    //this.logger.info("updateProducts() TOTAL MATERIALS TO UPDATE: " + materials.length);
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.ORDER) + orderUUID + '/materials',
      { materials: materials }
      //     material.uuid,
      // material
    );
  }

  public addMaterial(orderUUID: string, materials: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.ORDER) + orderUUID + '/materials/attach',
      materials
    );
  }

  public deleteMaterial(orderUUID: string, material: any): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/material/detach/' +
        material.uuid
    );
  }

  public confirmOrder(orderUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) + 'status/order-confirm/' + orderUUID
    );
  }

  public completeOrder(orderUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) + 'status/order-complete/' + orderUUID
    );
  }

  public cancelOrder(orderUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) + 'status/order-cancel/' + orderUUID
    );
  }

  // proposals
  public acceptProposal(orderUUID: string): any {
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.ORDER) + "status/proposal-accept/" + orderUUID);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        'status/proposal-accept/' +
        orderUUID +
        '/' +
        GT2FirebaseService.CLIENT_KEY
    );
  }

  public refuseProposal(orderUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        'status/proposal-refuse/' +
        orderUUID
    );
  }

  //
  public addServiceCharge(orderUUID: string, serviceCharge: any): any {
    // /v1/orders/{uuid}/service-charges/attach
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/service-charges/attach',
      serviceCharge
    );
  }

  public updateServiceCharge(orderUUID: string, serviceCharge: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/service-charge/' +
        serviceCharge.uuid_pivot,
      serviceCharge
    );
    // return this.http.patch<any>(this.api.createUrl(ApiRoutes.ORDER) + orderUUID + "/service-charges/" + serviceChargeUUID,  serviceCharge);
  }

  public updateServiceCharges(orderUUID: string, serviceCharges: any[]): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.ORDER) + orderUUID + '/service-charges',
      { service_charges: serviceCharges }
      //     serviceCharge.uuid_pivot,
      // serviceCharge
    );
    // return this.http.patch<any>(this.api.createUrl(ApiRoutes.ORDER) + orderUUID + "/service-charges/" + serviceChargeUUID,  serviceCharge);
  }

  public removeServiceCharge(
    orderUUID: string,
    serviceChargeUUID: string
  ): any {
    // this.logger.info("OrdersService.removeServiceCharge()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/service-charges/detach/' +
        serviceChargeUUID
    );
  }

  //
  public addSpecificCondition(
    orderUUID: string,
    selectedSpecificCondition: any
  ): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/specific-conditions/attach',
      selectedSpecificCondition
    );
  }

  public removeSpecificCondition(
    orderUUID: string,
    specificConditionUUID: string
  ): any {
    // this.logger.info("OrdersService.removeSpecificCondition()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/specific-conditions/detach/' +
        specificConditionUUID
    );
  }

  //
  public addLabourCharge(orderUUID: string, labourCharge: any): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.ORDER) + orderUUID + '/staffs/attach',
      labourCharge
    );
  }

  public removeLabourCharge(orderUUID: string, labourChargeUUID: string): any {
    // this.logger.info("OrdersService.removeLabourCharge()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/staffs/detach/' +
        labourChargeUUID
    );
  }
  public removeJobAssginmentFromOrderStaff(
    orderUUID: string,
    assignmentsStaff: any
  ): any {
    // this.logger.info("OrdersService.removeLabourCharge()");
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/assignment/staffs/detach/',
      assignmentsStaff
    );
  }

  public updateLabourCharge(orderUUID: string, labourCharge: any): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.ORDER) +
        orderUUID +
        '/staff/' +
        labourCharge.id,
      labourCharge
    );
  }

  public updateLabourCharges(orderUUID: string, labourCharges: any[]): any {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.ORDER) + orderUUID + '/staffs',
      { staffs: labourCharges }
    );
  }

  //
  public onSendOrder(orderUUID: string): any {
    // this.logger.info("OrdersService.onSendOrder()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) + orderUUID + '/XXX/'
    );
  }

  // INVOICE
  public completeAndPaidInvoice(orderUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) + 'status/invoice-paid/' + orderUUID
    );
  }

  public confirmSendInvoice(orderUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) + 'status/invoice-send/' + orderUUID
    );
  }

  public cancelInvoice(orderUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) + 'status/invoice-cancel/' + orderUUID
    );
  }

  public closeInvoice(orderUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORDER) + 'status/invoice-paid/' + orderUUID
    );
  }

  public updateGroupAction(
    orderUUID: string,
    section: string,
    action: string,
    value: any,
    uuids: string[]
  ): any {
    const postData: any = {
      value: value,
      uuids: uuids,
    };
    const requestURL: string =
      this.api.createUrl(ApiRoutes.ORDER) +
      orderUUID +
      '/' +
      section +
      '/action/' +
      action;
    return this.http.post<any>(requestURL, postData);
  }

  // order job assinments by event
  // ORDER PRODUCTS
  public getJobAssignmentByEvent(eventUUID: string): any {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.JOB_ASSIGNMENT) + '/event/' + eventUUID
    );
  }
}
