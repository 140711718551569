<div style="max-height: 100%; overflow: auto">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div matDialogTitle class="dialog-title text-bold">
      {{ "SELECT_PLACE.TITLE" | translate }}
    </div>
    <div
      class="gt2-light-green"
      style="width: 40px; height: 40px"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <button
        type="button"
        matTooltip="{{ 'EVENTS.ADD_PLACE' | translate }}"
        mat-icon-button
        class=""
        (click)="onAddNewRoom()"
      >
        <mat-icon class="" color="">{{ getIcon("ADD") }}</mat-icon>
      </button>
    </div>
  </div>
  <!-- <div matDialogTitle class="dialog-title text-bold">{{ 'SELECT_PLACE.TITLE' | translate }}</div> -->

  <div class="px-16 pb-16" fxLayout="column">
    <!--<div class="" *ngIf="client">{{ 'ATTACH_ENTERPRISE.TITLE' | translate }}{{client.name}}</div>-->

    <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
      <mat-label>{{ "GENERIC.SEARCH" | translate | titlecase }}</mat-label>
      <input
        id="search"
        [(ngModel)]="filterValue"
        matInput
        #filter
        placeholder="{{ 'GENERIC.SEARCH' | translate | titlecase }}"
      />
      <button
        mat-button
        *ngIf="filterValue"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div
      #comboList
      class="container-border"
      style="height: 200px"
      fxLayout="column"
      fusePerfectScrollbar
    >
      <div
        *ngIf="isLoading"
        fxLayout="column"
        fxFlex="100%"
        fxLayoutAlign="center center"
      >
        <mat-spinner [diameter]="20" class=""></mat-spinner>
      </div>

      <div *ngIf="!isLoading">
        <div *ngFor="let item of places" class="">
          <div
            class="client-child-item"
            *ngIf="
              filterValue === '' ||
              item.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
            "
            (click)="onSelectRoom(item)"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div>{{ item.name }}</div>
            <mat-radio-button
              [checked]="selectedRoom === item"
            ></mat-radio-button>
          </div>

          <!-- <div class="client-child-item" *ngIf="filterValue === '' || item.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1"
          fxLayout="column" fxLayoutAlign="start start" style="width: 100%;">
          <div class="place-name">{{item.name}}</div>
          <div *ngFor="let room of item.rooms; let i = index;" class="" fxFlex="100" style="width:100%;">
            
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" 
                (click)="onSelectRoom(room)" class="room-section" [ngClass]="(i !== item.rooms.length - 1)? 'room-section-underline' : ''" style="width:100%;">
              <div>{{room.name}}</div>
              <mat-radio-button class="pb-4" [checked]="selectedRoom === room"></mat-radio-button>
            </div>
          </div>
        </div> -->
        </div>
      </div>
    </div>

    <div class="mt-8 selected-label text-bold" *ngIf="selectedRoom">
      {{ "SELECT_PLACE.SELECTED_ROOM" | translate }}: {{ selectedRoom.name }}
    </div>
  </div>
  <div
    mat-dialog-actions
    class="mt-4 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      (click)="onSelectEnterpriseNoRoom()"
      color="accent"
      class="mr-16"
    >
      {{ "SELECT_PLACE.SELECT_ENTERPRISE_NO_ROOM" | translate }}
    </button>
  </div>

  <div
    mat-dialog-actions
    class="mt-20 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!selectedRoom"
      (click)="onRoomSelected()"
      color="accent"
      class="mr-16"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button mat-raised-button (click)="dialogRef.close(false)" class="primary">
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
