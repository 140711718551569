<div
  class="page-layout blank p-24"
  fusePerfectScrollbar>
  <div *ngxPermissionsOnly="['GOD']">
    <span class="mat-headline">Invitations</span>
    <!-- <mat-list>
            <mat-list-item>
                <p-button label="SEND"
                          (onClick)="onSendInvite('andre')"
                          style="margin-right: 10px"
                          styleClass="ui-button-danger"></p-button>
                <p>Send invite to Andre</p>
            </mat-list-item>
            <mat-list-item>
                <p-button label="SEND"
                          (onClick)="onSendInvite('martin')"
                          style="margin-right: 10px"
                          styleClass="ui-button-danger"></p-button>
                <p>Send invite to Martin</p>
            </mat-list-item>
            <mat-list-item>
                <p-button label="SEND"
                          (onClick)="onSendInvite('input')"
                          style="margin-right: 10px"
                          [disabled]="emailFormControl.invalid"
                          styleClass="ui-button-danger"></p-button>
                <span style="margin-right: 10px">Send invite to </span>

                <form class="example-form">
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Email" [formControl]="emailFormControl">
                        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                            Please enter a valid email address
                        </mat-error>
                        <mat-error *ngIf="emailFormControl.hasError('required')">
                            Email is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </form>

            </mat-list-item>
        </mat-list> -->
  </div>
</div>
