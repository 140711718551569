<div class="profile-box info-box width-100-content" fxLayout="column">
  <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title">{{ "SETTINGS.WRAPPING_DEFAULT" | translate }}</div>
    <!-- <div class="gt2-light-green" style="width: 60px; height: 56px;" fxLayout="row" fxLayoutAlign="center center">
      <button type="button" matTooltip="{{ 'WRAPPING.CREATE_NEW_CATEGORY' | translate }}" mat-icon-button class=""
        (click)="onCreateNew()">
        <mat-icon class="" color="">add</mat-icon>
      </button>
    </div> -->
  </header>
  <div
    style="min-width: 370px"
    *ngIf="settingsModel"
    class="content p-16"
    fxLayout="column"
  >
    <app-gt2-select
      fxFlex="100%"
      [service]="wrappingService"
      [placeHolder]="'SETTINGS.DEFAULT_WRAPPING_PRODUCT' | translate"
      [label]="'Wrapping'"
      [selectedModel]="settingsModel.product_wrapping"
      (changeEvent)="onProductWrappingSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      [nullableUseNone]="false"
      class=""
    ></app-gt2-select>

    <!-- <app-gt2-select [service]="wrappingService" 
                      [nullableUseNone]="false" 
                      [selectedModel]="settingsModel.order_wrapping"
                      [placeHolder]="'SETTINGS.DEFAULT_WRAPPING_ORDER' | translate"
                      [label]="'Wrapping'" 
                      (changeEvent)="onOrderWrappingSelected($event)" 
                      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                      fxFlex="100%" 
                      class=""></app-gt2-select>

      <app-gt2-select [service]="wrappingService" 
                      [nullableUseNone]="false" 
                      [selectedModel]="settingsModel.delivery_request_wrapping"
                      [placeHolder]="'SETTINGS.DEFAULT_WRAPPING_DELIVERY' | translate"
                      [label]="'Wrapping'" 
                      (changeEvent)="onDeliveryWrappingSelected($event)" 
                      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                      fxFlex="100%" 
                      class=""></app-gt2-select> -->
  </div>

  <div
    *ngIf="settingsModel"
    fxLayout="row"
    fxLayoutAlign="center start"
    fxFlex="100%"
    class="gt2-buttons-theme content px-16 pb-16"
  >
    <button mat-raised-button color="accent" class="" (click)="onSaveData()">
      {{ "SETTINGS.UPDATE" | translate }}
    </button>
  </div>
</div>
