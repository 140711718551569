import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CreateClientService } from '../../../api/create-client.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Settings } from '../../../settings/settings';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { Gt2LocaleService } from '../../../../localization/gt2-locale.service';
import { locale as englishAddress } from '../../address/i18n/en-CA';
import { locale as frenchAddress } from '../../address/i18n/fr-CA';
import { locale as englishContact } from '../../contact/i18n/en-CA';
import { locale as frenchContact } from '../../contact/i18n/fr-CA';
import { ClientAttachEnterpriseTablePaginatedComponent } from '../client-attach-enterprise-table-paginated/client-attach-enterprise-table-paginated.component';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Gt2SelectComponent } from '../../gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
@Component({
  selector: 'app-create-client-with-details',
  templateUrl: './create-client-with-details.component.html',
  styleUrls: ['./create-client-with-details.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    Gt2SelectComponent,
    ReactiveFormsModule,
    MatButtonModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    TranslateModule,
  ],
})
export class CreateClientWithDetailsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static CREATE_FOR_BRANCH: string = 'CREATE_FOR_BRANCH';
  static CREATE_FOR_ENTERPRISE: string = 'CREATE_FOR_ENTERPRISE';

  form: UntypedFormGroup;
  client: any;
  loading: boolean = false;
  type: string;
  uuid?: string;
  userSettings: any = {
    inputPlaceholderText: '',
    showRecentSearch: true,
  };
  noGeoLocation: boolean = false;
  addressPrefillDirty: boolean = false;
  dialogRefModal: any;
  navigateToClient: boolean = true;
  //
  constructor(
    public override dialogRef: MatDialogRef<CreateClientWithDetailsComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private createClientService: CreateClientService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public gt2LocalService: Gt2LocaleService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.translationLoader.loadTranslations(englishAddress, frenchAddress);
    this.translationLoader.loadTranslations(englishContact, frenchContact);
    this.userSettings.inputPlaceholderText = this.translate.instant(
      'GENERIC.GOOGLE_ADDRESS_PLACEHOLDER',
    );
    if (data) {
      this.type = data.type;
      this.uuid = data.uuid;
    } else {
      this.type = CreateClientWithDetailsComponent.CREATE;
    }
    //
    if (data && data.navigateToClient !== null) {
      this.navigateToClient = data.navigateToClient;
    }
    //this.logger.info("AddressComponent.constructor() --> navigateToClient: " + this.navigateToClient);
    //this.logger.info("AddressComponent.constructor() --> data: " + data.navigateToClient);
    //
    this.form = this.formBuilder.group({
      first_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      last_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      professional_email: ['', [Validators.email]],
      telephone_office: [
        '',
        [Validators.minLength(2), Validators.maxLength(100)],
      ],
      telephone_office_ext: [
        '',
        [Validators.minLength(2), Validators.maxLength(20)],
      ],
      street_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.required,
        ],
      ],
      street: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      apartment: '',
      line2: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      city: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      province: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      country: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      postal_code: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.client = {
      first_name: null,
      last_name: null,
      note: null,
      contact: {
        email_organisation: null,
        telephone_office: null,
        telephone_office_ext: null,
      },
      address: {
        street_number: null,
        street: null,
        apartment: null,
        line2: null,
        city: null,
        province: null,
        country: null,
        postal_code: null,
      },
    };
  }

  ngOnInit() {}
  ngAfterViewInit() {}
  ngOnDestroy() {}

  public findAddressProperty(address: any, key: string): string {
    const item = address.data.address_components.find((data: any) => {
      return data.types.indexOf(key) !== -1;
    });

    if (item && item.long_name) {
      return item.long_name;
    }
    return '';
  }

  public handleAddressChange(address: any) {
    // this.logger.info("AddressComponent.handleAddressChange() --> address: " + JSON.stringify(address));

    if (address.response === false) {
      this.noGeoLocation = true;
      return;
    } else {
      //this.isVisible = true;
      this.noGeoLocation = false;

      Promise.resolve(null).then(() => {
        // street_number
        // this.client.address.street_number = address.data.address_components.find( data => {
        //     return data.types.indexOf('street_number') !== -1;
        // }).long_name;

        this.client.address.street_number = this.findAddressProperty(
          address,
          'street_number',
        );
        this.client.address.street = this.findAddressProperty(address, 'route');
        this.client.address.city = this.findAddressProperty(
          address,
          'locality',
        );
        this.client.address.province = this.findAddressProperty(
          address,
          'administrative_area_level_1',
        );
        this.client.address.country = this.findAddressProperty(
          address,
          'country',
        );
        this.client.address.postal_code = this.findAddressProperty(
          address,
          'postal_code',
        );
        this.client.address.lat = address.data.geometry.location.lat;
        this.client.address.long = address.data.geometry.location.lng;
        this.client.address.full_address = address.data.formatted_address;

        this.addressPrefillDirty = true;
      });
    }
  }

  public onCreateClient(): void {
    this.loading = true;
    this.createClientService.createClient(this.client).subscribe(
      (response: any) => {
        // this.logger.info("CreateClientComponent.onCreateClient() -> SUCCESS: " + JSON.stringify(response));
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_CLIENT'),
        );
        this.dialogRef.close(response.data);
        this.loading = false;
        if (this.navigateToClient) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
        }
      },
      (error: any) => {
        this.logger.error(
          'CreateClientComponent.onCreateClient() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.CREATE_CLIENT'),
        );
      },
    );
  }

  public onCreateClientAndAttach(): void {
    this.loading = true;
    this.createClientService.createClient(this.client).subscribe(
      (response: any) => {
        // this.logger.info("CreateClientComponent.onCreateClient() -> SUCCESS: " + JSON.stringify(response));
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_CLIENT'),
        );
        this.dialogRef.close(response.data);
        this.loading = false;
        this.onAttachEnterprise(response.data);
        // this.router.navigateByUrl(
        //     ItemUrlFinder.getItemURL(response.data)
        // );
      },
      (error: any) => {
        this.logger.error(
          'CreateClientComponent.onCreateClient() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.CREATE_CLIENT'),
        );
      },
    );
  }

  public onLocalSelected(event: any): void {
    this.client.communication_local = event;
    // this.autosaveDataChange({
    //     communication_local: this.client.communication_local,
    // });
  }

  public onAttachEnterprise(client: any) {
    // this.logger.info("ClientComponent.onAttachEnterprise()");
    // ClientAttachEnterpriseComponent
    this.dialogRefModal = this.dialog.open(
      ClientAttachEnterpriseTablePaginatedComponent,
      {
        width: '80%',
        minWidth: 350,
        maxHeight: '98vh',
        panelClass: 'table-dialog-container',
        disableClose: false,
        data: { client: client },
      },
    );
    this.dialogRefModal.componentInstance.alertMessage = 'Fonction à venir'; // this.translate.instant("CLIENTS_CATEGORIES.DELETE_CONFIRM", {value: selected.name});
    // this.dialogRef.componentInstance.showConfirmButton = false;

    this.dialogRefModal.afterClosed().subscribe((result: any) => {
      //this.logger.info("CreateClientComponent.onCreateClient() -> SUCCESS result: " + JSON.stringify(result));
      if (result) {
        // this.logger.info("ATTACH SUCCESS -> NEW CLIENT MODEL RECEIVED!");
        // this.client = result.data;
        this.dialogRef.close(result.data);
      }
      this.dialogRefModal = null;
    });
  }
}
