import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class MatPaginatorIntlCro extends MatPaginatorIntl {
  override itemsPerPageLabel = '';
  override nextPageLabel = '';
  override previousPageLabel = '';
  override firstPageLabel = '';
  override lastPageLabel = '';

  constructor(private translate: TranslateService) {
    super();
    this.itemsPerPageLabel = this.translate.instant(
      'GENERIC.TABLE_ITEMS_PER_PAGE'
    );
    this.nextPageLabel = this.translate.instant('GENERIC.TABLE_NEXT_PAGE');
    this.previousPageLabel = this.translate.instant(
      'GENERIC.TABLE_PREVIOUS_PAGE'
    );
    this.firstPageLabel = this.translate.instant('GENERIC.TABLE_FIRST_PAGE');
    this.lastPageLabel = this.translate.instant('GENERIC.TABLE_LAST_PAGE');

    translate.get('GENERIC.TABLE_ITEMS_PER_PAGE').subscribe((translated) => {
      this.itemsPerPageLabel = translated;
    });
  }

  public override getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ) => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${length}`;
  };
}
