import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VenueService implements Resolve<any> {
  routeParams: any;
  order: any;
  onOrderChanged: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(private http: HttpClient) {}

  /**
   * Resolve
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;

    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getVenue()]).then(() => {
        resolve();
      }, reject);
    });
  }

  getVenue(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get('api/venues/' + this.routeParams.id)
        .subscribe((response: any) => {
          this.order = response;
          this.onOrderChanged.next(this.order);
          resolve(response);
        }, reject);
    });
  }

  saveVenue(order: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post('api/venues/' + order.id, order)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addVenue(order: any) {
    return new Promise((resolve, reject) => {
      this.http.post('api/venues/', order).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }
}
