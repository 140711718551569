<div id="address" class="p-16" style="min-width: 300px" fxLayout="column">
  <div
    *ngIf="!editMode && groupModel"
    fxLayout="row"
    fxLayoutAlign="space-between start"
    class="mb-12"
  >
    <div style="font-weight: 500; font-size: 16px">
      {{ groupModel.full_address }}
    </div>
    <a
      *ngIf="groupModel.lat && groupModel.long"
      href="{{ groupModel | googleLinkPipe }}"
      target="_blank"
      class="mat-caption"
    >
      <span class="">{{ "ADDRESS.SEE_ON_GOOGLE_MAP" | translate }}</span>
    </a>
  </div>

  <!-- <ngxgeo-autocomplete
    *ngIf="editMode"
    [userSettings]="userSettings"
    (componentCallback)="handleAddressChange($event)"
    class="app-address"
  >
  </ngxgeo-autocomplete> -->

  <div
    *ngIf="showVenuNameInput"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    class="address-content"
  >
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "ADDRESS.NAME" | translate }}</div>
      <div *ngIf="groupModel && groupModel.name" class="info">
        {{ groupModel.name }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.name"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "ADDRESS.NAME" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADDRESS.NAME' | translate }}"
              formControlName="name"
              [(ngModel)]="groupModel.name"
              type="text"
            />
            <mat-error *ngIf="form.controls['name'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    class="address-content"
  >
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "ADDRESS.ADDRESS_NUMBER" | translate }}</div>
      <div *ngIf="groupModel && groupModel.street_number" class="info">
        {{ groupModel.street_number }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.street_number"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label
              >{{ "ADDRESS.ADDRESS_NUMBER" | translate }}# Adresse</mat-label
            >
            <input
              matInput
              placeholder="{{ 'ADDRESS.ADDRESS_NUMBER' | translate }}# Adresse"
              formControlName="street_number"
              type="text"
              [(ngModel)]="groupModel.street_number"
              required
            />
            <mat-error *ngIf="form.controls['street_number'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID_1" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "ADDRESS.STREET" | translate }}</div>
      <div *ngIf="groupModel && groupModel.street" class="info">
        {{ groupModel.street }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.street"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "ADDRESS.STREET" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADDRESS.STREET' | translate }}"
              formControlName="street"
              type="text"
              [(ngModel)]="groupModel.street"
              required
            />
            <mat-error *ngIf="form.controls['street'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    class="address-content"
  >
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "ADDRESS.APP_NUMBER" | translate }}</div>
      <div *ngIf="groupModel && groupModel.apartment" class="info">
        {{ groupModel.apartment }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.apartment"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "ADDRESS.APP_NUMBER" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADDRESS.APP_NUMBER' | translate }}"
              formControlName="apartment"
              [(ngModel)]="groupModel.apartment"
              type="text"
            />
            <mat-error *ngIf="form.controls['apartment'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "ADDRESS.LINE_2" | translate }}</div>
      <div *ngIf="groupModel && groupModel.line2" class="info">
        {{ groupModel.line2 }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.line2"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "ADDRESS.LINE_2" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADDRESS.LINE_2' | translate }}"
              formControlName="line2"
              [(ngModel)]="groupModel.line2"
              type="text"
            />
            <mat-error *ngIf="form.controls['line2'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    class="address-content"
  >
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "ADDRESS.CITY" | translate }}</div>
      <div *ngIf="groupModel && groupModel.city" class="info">
        {{ groupModel.city }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.city"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "ADDRESS.CITY" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADDRESS.CITY' | translate }}"
              formControlName="city"
              [(ngModel)]="groupModel.city"
              type="text"
              required
            />
            <mat-error *ngIf="form.controls['city'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "ADDRESS.PROVINCE" | translate }}</div>
      <div *ngIf="groupModel && groupModel.province" class="info">
        {{ groupModel.province }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.province"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "ADDRESS.PROVINCE" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADDRESS.PROVINCE' | translate }}"
              formControlName="province"
              type="text"
              [(ngModel)]="groupModel.province"
              required
            />
            <mat-error *ngIf="form.controls['province'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    class="address-content"
  >
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "ADDRESS.COUNTRY" | translate }}</div>
      <div *ngIf="groupModel && groupModel.country" class="info">
        {{ groupModel.country }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.country"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "ADDRESS.COUNTRY" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADDRESS.COUNTRY' | translate }}"
              formControlName="country"
              type="text"
              [(ngModel)]="groupModel.country"
              required
            />
            <mat-error *ngIf="form.controls['country'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "ADDRESS.POSTAL_CODE" | translate }}</div>
      <div *ngIf="groupModel && groupModel.postal_code" class="info">
        {{ groupModel.postal_code }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.postal_code"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="50%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "ADDRESS.POSTAL_CODE" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADDRESS.POSTAL_CODE' | translate }}"
              formControlName="postal_code"
              type="text"
              [(ngModel)]="groupModel.postal_code"
              required
            />
            <mat-error *ngIf="form.controls['postal_code'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="moduleService.modulesAreSet && moduleService.hasJLReport()"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    class="address-content"
  >
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "ADDRESS.ON_SITE_CONTACT" | translate }}</div>
      <div *ngIf="groupModel && groupModel.on_site_contact" class="info">
        {{ groupModel.on_site_contact }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.on_site_contact"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="100%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "ADDRESS.ON_SITE_CONTACT" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'ADDRESS.ON_SITE_CONTACT' | translate }}"
              formControlName="on_site_contact"
              type="text"
              [(ngModel)]="groupModel.on_site_contact"
            />
            <mat-error *ngIf="form.controls['on_site_contact'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    class="address-content"
  >
    <div *ngIf="editMode" class="pl-8 pr-16" fxFlex="50%">
      <div class="title">{{ "ADDRESS.SECTOR" | translate }}</div>
      <app-sectors-select
        [defaultSector]="groupModel.sector"
        (changeEvent)="onSectorSelected($event)"
        class=""
      ></app-sectors-select>
    </div>
    <div *ngIf="!editMode" class="" fxFlex="50%">
      <div class="title">{{ "ADDRESS.SECTOR" | translate }}</div>
      <div *ngIf="groupModel && groupModel.sector" class="info">
        {{ groupModel.sector.name }}
      </div>
      <div
        *ngIf="!groupModel || !groupModel.sector"
        class="info empty-value"
      ></div>
    </div>
  </div>

  <div
    *ngIf="editMode && !hideSaveButton"
    fxLayout="row"
    fxLayoutAlign="end start"
    class="mt-16"
  >
    <button
      (click)="onManualSave()"
      color="accent"
      class="save-btn"
      [disabled]="form.invalid"
      mat-raised-button
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
  </div>
  <!--  -->
</div>
