import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { OrganizationModel } from '../../../api/models/OrganizationModel';

@Injectable()
export class CrudOrganizationService {
  organization?: OrganizationModel;

  private organizationDataSource = new BehaviorSubject<any>({});
  organizationData = this.organizationDataSource.asObservable();
  orgObs?: Observable<any> | null = null;
  catererObs?: Observable<any> | null = null;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public loadOrganization() {
    // this.logger.info("CrudOrganizationService.loadOrganization() *** -> this.orgObs: " + this.orgObs);
    if (!this.orgObs) {
      // this.logger.info("CrudOrganizationService.loadOrganization() -> ORG REAL INIT LOAD!");
      this.orgObs = this.http
        .get<any>(this.api.createUrl(ApiRoutes.GET_ORGANIZATION))
        .pipe(shareReplay(1))
        .pipe(tap((data) => this.setOrganization(data)));
    }

    return this.orgObs;
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.GET_ORGANIZATION)).do(result => this.setOrganization(result));
  }

  public loadCatererMe() {
    // this.logger.info("CrudOrganizationService.loadOrganization() ### -> this.catererObs: " + this.catererObs);
    if (!this.catererObs) {
      // this.logger.info("CrudOrganizationService.loadCatererMe() -> CATERER REAL INIT LOAD!");
      this.catererObs = this.http
        .get<any>(this.api.createUrl(ApiRoutes.GET_CATERER))
        .pipe(shareReplay(1))
        .pipe(tap((data) => this.resetCaterer(data)));
    }

    return this.catererObs;
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.GET_ORGANIZATION)).do(result => this.setOrganization(result));
  }

  public loadCatererByUUID(uuid: string) {
    // this.logger.info("CrudOrganizationService.loadCatererByUUID() ");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.CATERER) + uuid);
  }

  public loadOrgByUUID(uuid: string) {
    // this.logger.info("CrudOrganizationService.loadOrgByUUID() ");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.ORGANIZATION) + uuid
    );
  }

  public updateOrganization(organization: any, orgUUID: string) {
    // this.logger.info("CrudOrganizationService.updateOrganization() -> organization: " + orgUUID);
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.UPDATE_ORGANIZATION) + orgUUID,
      organization
    );
  }

  public updateCaterer(caterer: any, catererUUID: string) {
    // this.logger.info("CrudOrganizationService.updateCaterer() -> catererUUID: " + catererUUID);
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.UPDATE_CATERER) + catererUUID,
      caterer
    );
  }

  // public updateOrganizationLocales( locales: any, orgUUID: string )
  // {
  //     // this.logger.info("CrudOrganizationService.getOrganization() -> employeeID: " + organizationID);
  //     return this.http.post<any>(this.api.createUrl(ApiRoutes.UPDATE_ORGANIZATION_LOCALES) + orgUUID, locales );
  // }

  public addLocale(locale: any, orgUUID: string) {
    // this.logger.info("CrudOrganizationService.addLocale() -> orgUUID: " + orgUUID);
    // this.logger.info("CrudOrganizationService.addLocale() -> LOCALE_UUID: " + locale.uuid);
    const url: string = this.api
      .createUrl(ApiRoutes.ADD_ORGANIZATION_LOCALE)
      .replace('${ORG_UUID}', orgUUID)
      .replace('${LOCALE_UUID}', locale.uuid);
    // this.logger.info("CrudOrganizationService.addLocale() -> url: " + url);
    return this.http.get<any>(url);
  }

  public deleteLocale(locale: any, orgUUID: string) {
    // this.logger.info("CrudOrganizationService.deleteLocale() -> orgUUID: " + orgUUID);
    const url: string = this.api
      .createUrl(ApiRoutes.DELETE_ORGANIZATION_LOCALE)
      .replace('${ORG_UUID}', orgUUID)
      .replace('${LOCALE_UUID}', locale.uuid);
    // this.logger.info("CrudOrganizationService.deleteLocale() -> url: " + url);
    return this.http.get<any>(url);
  }

  // public createEmployee( employee: CrudEmployeeModel )
  // {
  //     this.logger.info("CrudEmployeeService.createEmployee() -> employee: " + employee);
  //     return this.http.post<any>(this.api.createUrl(ApiRoutes.CREATE_EMPLOYEE), employee);
  // }

  private setOrganization(organization: any): void {
    // this.logger.info(
    //     "CrudOrganizationService.setOrganization() -> organization: " +
    //         JSON.stringify(organization)
    // );
    this.orgObs = null;
    this.organization = organization;
    this.organizationDataSource.next(this.organization);
    // this.organizationSubject.next(this.organization);
  }

  private resetCaterer(caterer: any): void {
    // this.logger.info("CrudOrganizationService.resetCaterer()");
    this.catererObs = null;
  }

  // private setCaterer(caterer: any): void
  // {
  //     this.catererObs = null;
  //     this.organization = caterer;
  //     this.catererDataSource.next(this.caterer);
  // }

  public getOrganization(): Observable<OrganizationModel> {
    // this.logger.info("CrudOrganizationService.getOrganization()");
    return this.organizationData;
    // return this.organizationSubject;
  }

  public getAllOrganizations(): Observable<any> {
    // this.logger.info("CrudOrganizationService.getOrganization() ");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.ORGANIZATIONS));
  }
}
