<div>
  <div *ngIf="vo">
    <!-- style="width: 60px; height: 60px;" -->

    <button
      tabindex="-1"
      [autofocus]="false"
      type="button"
      *ngIf="fileService.excelEnabled() && vo.export_format === 'XLSX'"
      matTooltip="{{ tooltipLabel | translate }}"
      mat-icon-button
      (click)="onReport(); $event.stopPropagation()"
    >
      <mat-icon
        matBadgeSize="small"
        color="{{ color }}"
        fontSet="fa"
        fontIcon="{{ iconsService.getIcon('XLS') }}"
        class="fa-lg"
      >
      </mat-icon>
    </button>

    <button
      tabindex="-1"
      [autofocus]="false"
      type="button"
      *ngIf="
        fileService.pdfEnabled() &&
        (vo.export_format === 'PDF' || vo.export_format === 'PDF_STACK') &&
        vo.export_to === 'DOWNLOAD'
      "
      matTooltip="{{ tooltipLabel | translate }}"
      mat-icon-button
      (click)="onReport(); $event.stopPropagation()"
    >
      <mat-icon matBadgeSize="small" class="" color="{{ color }}">{{
        iconsService.getIcon("PDF")
      }}</mat-icon>
    </button>

    <button
      tabindex="-1"
      [autofocus]="false"
      type="button"
      *ngIf="fileService.printEnabled() && vo.export_to === 'PRINT'"
      matTooltip="{{ tooltipLabel | translate }}"
      mat-icon-button
      (click)="onReport(); $event.stopPropagation()"
    >
      <mat-icon matBadgeSize="small" class="" color="{{ color }}">{{
        iconsService.getIcon("PRINT")
      }}</mat-icon>
    </button>

    <button
      tabindex="-1"
      [autofocus]="false"
      type="button"
      *ngIf="fileService.printEnabled() && vo.export_to === 'PREVIEW'"
      matTooltip="{{ tooltipLabel | translate }}"
      mat-icon-button
      (click)="onReport(); $event.stopPropagation()"
    >
      <mat-icon matBadgeSize="small" class="s-32" color="{{ color }}">{{
        iconsService.getIcon("PREVIEW")
      }}</mat-icon>
    </button>
  </div>
</div>
