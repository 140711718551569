<div id="eventFlow">
  <div fxLayout="row" fxLayout="start center" class="section-list-header">
    <!-- <div fxFlex="50px" class=""></div> -->
    <div fxFlex="20" class="section-list-item-border-right pl-8 py-4">
      {{ "GENERIC.DATE" | translate }}
    </div>
    <div
      fxFlex="15"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.TIME" | translate }}
    </div>
    <div
      fxFlex="30"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.NAME" | translate }}
    </div>
    <div
      fxFlex="20"
      class="section-list-item section-list-item-border-right py-4"
    >
      {{ "GENERIC.NOTE" | translate }}
    </div>
    <div
      fxFlex="15"
      class="section-list-item section-list-item-border-right py-4"
    >
      Actions
    </div>
  </div>

  <div fusePerfectScrollbar>
    <div
      *ngIf="!event || !event.flows || event.flows.length === 0"
      fxFlex="100"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="full-width"
    >
      <!-- {{ 'EVENTS.NO_FLOW' | translate }} -->
      <div class="font-size-20 my-16">{{ "EVENTS.NO_FLOW" | translate }}</div>
    </div>

    <div
      *ngIf="loading"
      fxLayout="row"
      fxLayoutAlign="center center"
      style="height: 100%; width: 100%"
    >
      <mat-spinner [diameter]="40" class="my-40"></mat-spinner>
    </div>

    <div
      *ngIf="event && !loading && event.flows && event.flows.length > 0"
      fxFlex="100"
      class=""
    >
      <div
        *ngFor="let item of event.flows"
        fxLayout="row"
        fxLayout="start center"
        class="child-item"
      >
        <div
          fxFlex="20"
          fxLayout="row"
          fxLayout="start center"
          class="section-list-item-border-right pl-8 py-8"
        >
          {{ item.flow_date_label }}
        </div>
        <div
          fxFlex="15"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.flow_time_label }}
        </div>
        <div
          fxFlex="30"
          class="section-list-item section-list-item-border-right py-8"
        >
          {{ item.name }}
        </div>
        <div
          fxFlex="20"
          class="section-list-item section-list-item-border-right text-truncate py-8"
        >
          <span class="text-truncate px-8">{{ item.note_label }}</span>
          <!-- <span class="text-truncate px-8" [innerHTML]="item.note"></span> -->
        </div>
        <div fxFlex="15" class="section-list-item">
          <button
            class=""
            mat-icon-button
            [disabled]="!editable"
            (click)="onEditFlow(item)"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("EDIT")
            }}</mat-icon>
          </button>
          <button
            class=""
            mat-icon-button
            [disabled]="!editable"
            (click)="onDeleteFlow(item)"
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("DELETE")
            }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
