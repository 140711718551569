export const locale = {
  lang: "fr-CA",
  data: {
    WRAPPING: {
      CREATE_NEW_CATEGORY: "Nouvel emballage",
      CATEGORY_NAME: "Nom de l'emballage",
      HOURLY_WAGE: "Taux horaire",
      NEW_CATEGORY_NAME: "Nom du nouvel emballage",
      CREATE_SUCCESS: "Emballage créer avec succès!",
      EDIT_SUCCESS: "Emballage modifié avec succès!",
      DELETE_SUCCESS: "Emballage effacée avec succès!",
    },
  },
};
