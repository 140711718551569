import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { Gt2LocaleService } from '../../../localization/gt2-locale.service';
import { AuthenticationService } from '../../api/auth-service.service';
import { AddressComponent } from '../../components/address/address.component';
import { ContactComponent } from '../../components/contact/contact.component';
import { RolesService } from '../../services/roles.service';
import { Settings } from '../../settings/settings';
import { GT2FormPageAbstract } from '../abstract/GT2FormPageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';
import { CrudOrganizationService } from '../crud/crud-organization/crud-organization.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { UserService } from '../../api/user.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-caterer',
  templateUrl: './caterer.component.html',
  styleUrls: ['./caterer.component.scss'],
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
  animations: fuseAnimations,
})
export class CatererComponent
  extends GT2FormPageAbstract
  implements OnInit, OnDestroy
{
  @ViewChild('addressComponent') addressComponent?: AddressComponent;
  @ViewChild('contactComponent') contactComponent?: ContactComponent;

  catererUUID!: string;
  caterer: any;
  catererForm: UntypedFormGroup;
  loading: boolean = false;
  editMode: boolean = false;
  isVisible: boolean = true;
  displayedOrganizationColumns: string[] = ['name', 'actionsColumn'];
  // orgListdataSource:
  orgList: any = {};

  // logoUploadURL: string;
  // headerUploadURL: string;
  // uploadedFiles: any[] = [];
  // uploadedLogoResponse: LogoResponse;
  // uploadedLogoError: string;
  // uploadedHeaderResponse: ReportHeaderResponse;
  // uploadedHeaderError: string;

  // gt2AppSupportedLocale: LocaleModel[];
  // clientSupportedLocale: LocaleModel[] = [];

  // languages: any;
  // selectedLanguage: any;

  constructor(
    private auth: AuthenticationService,
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private dialog: MatDialog,
    public translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private toastr: ToastrService,
    private titleService: Title,
    private gt2LocaleService: Gt2LocaleService,
    private api: Gt2ApiService,
    public location: Location,
    public rolesService: RolesService,
    public userService: UserService,
    private route: ActivatedRoute,
    public router: Router,
    private orgService: CrudOrganizationService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.catererForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
          Validators.required,
        ],
      ],
      owner_user: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
          Validators.required,
        ],
      ],
    });

    this.setControlsChanges(this.catererForm);
    this.orgList.onOrgListChanged = new BehaviorSubject({});
    this.orgList.dataSource = new FilesDataSource(this.orgList);
  }

  ngOnInit() {
    // this.logger.info("CatererComponent.ngOnInit()");

    //this.titleService.setTitle(this.translate.instant('CATERER.CATERER'));

    this.route.params.subscribe({
      next: (params: any) => {
        this.catererUUID = params.id;
        this.loadCatererByUUID(this.catererUUID);
      },
    });
  }

  ngOnDestroy() {}

  public loadCatererByUUID(uuid: string): void {
    this.loading = true;
    this.orgService.loadCatererByUUID(uuid).subscribe({
      next: (caterer) => {
        if (caterer.data) {
          this.caterer = caterer.data;
          this.orgList.onOrgListChanged.next(this.caterer.organizations);

          //this.titleService.setTitle(
          //  this.translate.instant('CATERER.CATERER') + ' ' + this.caterer.name,
          //);
        }
        this.loading = false;
      },
      error: (error) => {
        this.logger.error(
          'CatererComponent.loadCatererByUUID() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public override autosaveDataChange(data: any): void | any {
    this.loading = true;
    this.orgService.updateCaterer(data, this.catererUUID).subscribe({
      next: (response) => {
        this.loading = false;
      },
      error: (error) => {
        this.logger.error(
          'CatererComponent.autosaveDataChange() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    });
  }

  public onEdit($event: any): void {
    this.editMode = !this.editMode;

    if (this.addressComponent) {
      this.addressComponent.editMode = this.editMode;
    }

    if (this.contactComponent) {
      this.contactComponent.editMode = this.editMode;
    }

    if (this.editMode) {
      this.setControlsChanges(this.catererForm);
    } else {
      this.unsetControlsChanges();
      this.catererForm.markAsPristine();
      this.catererForm.markAsUntouched();
    }
  }

  public onEditOrganization(org: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(org));
  }

  public onOpenInNewTab(): void {
    this.openInNewTab(this.router.url);
  }

  public onOwnerUserChange(event: any): void {
    this.caterer.owner_user = event;
    this.autosaveDataChange({ owner_user: this.caterer.owner_user });
  }
}

export class FilesDataSource extends DataSource<any> {
  constructor(private orgList: any) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return this.orgList.onOrgListChanged;
  }

  disconnect() {}
}
