export const locale = {
  lang: "en-CA",
  data: {
    ORDER_MATERIALS: {
      TITLE: "Add a equipment",
      TITLE_CREATE: "Add a equipment",
      LEFT_LIST_LABEL: "Drag & drop or use the + button to add equipment",
      RIGHT_LIST_LABEL: "List of equipments to add",
      CREATE_PRODUCT: "Create a equipment",
      SEARCH_MATERIAL: "Perform a search for your equipment.",
    },
  },
};
