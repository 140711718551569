export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_TAKEOUT_REQUEST: {
      TITLE: "Nouvelle demande de cueillette",
      EDIT_TITLE: "Modifier demande de cueillette",
      SELECT_FLOW: "Définir ou sélectionner un déroulement",
      SHOW_ON_ORDER: "Afficher sur les commandes",
      SHOW_ON_PROPOSAL: "Afficher sur les propositions",
      SHOW_ON_INVOICE: "Afficher sur les factures",
      SHOW_ON_MATERIAL_REPORT: "Afficher sur les rapports de matériel",
      NEED_INSTALATION: "Besoin d'installation",

      SHOW_ON_PURCHASE_ORDER_PRODUCTION: "Afficher sur les bons de commande",
      SHOW_ON_PURCHASE_ORDER_CLIENT_COPY: "Afficher sur les bons de commande - client",
      SHOW_ON_CLIENT_PROPOSAL_AND_ORDER: "Afficher sur les propositions et commandes",
      SHOW_ON_PURCHASE_ORDER_KITCHEN: "Afficher sur les bons de commande - Cuisine",
      SHOW_ON_PURCHASE_MATERIAL: "Afficher sur les bons de matériel",
      CLIENT_TAKEOUT: "Cueillette client",
      BACK: "Retour",
      FOOD: "Nourriture",
      MATERIAL: "Matériel",
      FOOD_MATERIAL: "Nourriture et matériel",
    },
  },
};
