import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductUpsaleComponent } from './product-upsale.component';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { CreateQuestionComponent } from './create-question/create-question.component';
import { UpsaleService } from '../../../api/upsale.service';
import { RouterModule } from '@angular/router';
import { CreateOptionsGroupComponent } from './create-options-group/create-options-group.component';
import { CreateUpsaleOptionsComponent } from './create-upsale-options/create-upsale-options.component';
import { ProductsService } from '../../../api/products.service';
import { CreateUpsaleMaterialsComponent } from './create-upsale-materials/create-upsale-materials.component';
import { UpsaleQuestionItemComponent } from './upsale-question-item/upsale-question-item.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { EditQuestionComponent } from './edit-question/edit-question.component';
import { AppRoutesName } from '../../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.EN.UPSALE,
    component: ProductUpsaleComponent,
  },
  {
    path: AppRoutesName.FR.UPSALE,
    component: ProductUpsaleComponent,
  },
];
@NgModule({
  declarations: [ProductUpsaleComponent],
  exports: [],
  imports: [
    MatFormFieldModule,
    UpsaleQuestionItemComponent,
    EditQuestionComponent,
    MatProgressSpinnerModule,
    CreateQuestionComponent,
    CreateUpsaleMaterialsComponent,
    CreateUpsaleOptionsComponent,
    MatInputModule,
    CreateOptionsGroupComponent,
    MatButtonModule,
    MatTooltipModule,
    TranslateModule,
    MatIconModule,
    NgxSpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    MatExpansionModule,
  ],
  providers: [UpsaleService, ProductsService],
})
export class ProductUpsaleModule {}
