import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";
import { tap, shareReplay } from "rxjs/operators";

@Injectable()
export class Gt2DashboardService {
  static dashboardData: any;

  constructor(private http: HttpClient, private api: Gt2ApiService) {}

  public loadDashboardLatest() {
    // this.logger.info("Gt2DashboardService.loadDashboardLatest() -> url: " + this.api.createUrl(ApiRoutes.DASHBOARD_LATEST));
    return this.http
      .get<any>(this.api.createUrl(ApiRoutes.DASHBOARD_LATEST))
      .pipe(tap((res) => this.setDashboard(res)))
      .pipe(shareReplay());
  }

  public loadDashboardClients() {
    // this.logger.info("Gt2DashboardService.loadDashboardClients() -> url: " + this.api.createUrl(ApiRoutes.DASHBOARD_CLIENTS));
    return this.http
      .get<any>(this.api.createUrl(ApiRoutes.DASHBOARD_CLIENTS))
      .pipe(tap((res) => this.setDashboard(res)))
      .pipe(shareReplay());
  }

  private setDashboard(response: any): void {
    // this.logger.info("Gt2DashboardService.setDashboard() -> response: " + JSON.stringify(response));
    // Gt2DashboardService.dashboardData = response.response;
    // if ( response.response_status === ResponsesStatus.OK )
    // {
    //     Gt2DashboardService.dashboardData = response.response;
    // }
  }
}
