<div
  id="products"
  class="page-layout simple fullwidth inner-scroll"
  fxLayout="column"
  style="overflow-x: scroll"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fusePerfectScrollbar
      style="min-width: 1023px"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1 mt-8">{{ "MATERIALS.MATERIALS" | translate }}</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end start">
        <button
          mat-button
          *ngIf="fileService.printEnabled()"
          matTooltip="{{ 'GENERIC.PRINT' | translate }}"
          (click)="onPrint()"
          class="mat-icon-button"
        >
          <mat-icon class="icon-mat s-30">print</mat-icon>
        </button>
        <button
          mat-button
          *ngIf="fileService.pdfEnabled()"
          matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
          (click)="onDownloadPDF()"
          class="mat-icon-button"
        >
          <mat-icon class="icon-mat s-30">picture_as_pdf</mat-icon>
        </button>
        <mat-spinner
          *ngIf="print.loadingPrint"
          [diameter]="20"
          class="mt-12"
        ></mat-spinner>
        <button
          mat-button
          *ngIf="fileService.excelEnabled()"
          matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
          (click)="onDownloadXLS()"
          class="mat-icon-button"
        >
          <mat-icon
            fontSet="fa"
            fontIcon="fa-table"
            class="icon-mat fa-2x"
          ></mat-icon>
        </button>

        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="icon-mat s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <div
    #mainContainer
    class="p-24 pr-56"
    fusePerfectScrollbar
    style="min-width: 1023px"
  >
    <div fxLayout="column">
      <!--Data table-->
      <div class="mat-white-bg mat-elevation-z2">
        <div class="pl-8 pt-8 pr-8" fxLayout="row" fxLayoutAlign="end center">
          <!-- SEARCH -->
          <div
            class=""
            fxFlex="1 0 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <label for="search" class="mr-8">
              <mat-icon class="">search</mat-icon>
            </label>
            <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
              <mat-label>{{
                "GENERIC.FILTERING_PLACEHOLDER" | translate | titlecase
              }}</mat-label>
              <input
                id="search"
                [(ngModel)]="filterValue"
                matInput
                #filter
                placeholder="{{
                  'GENERIC.FILTERING_PLACEHOLDER' | translate | titlecase
                }}"
              />
              <button
                mat-button
                *ngIf="filterValue"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearSearch()"
              >
                <mat-icon class="">close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <!-- / SEARCH -->

          <div class="mat-body-2 p-8 ml-8">
            {{ resultsLength }}
            {{ "MATERIALS.MATERIALS" | translate }}
          </div>
        </div>
        <div class="border-bottom">
          <div
            *ngIf="!isLoadingResults && resultsLength === 0"
            fxLayout="row"
            fxLayoutAlign="center start"
            class="pb-8 mat-body-2"
          >
            {{ "GENERIC.NO_RESULTS" | translate }}
          </div>

          <div *ngIf="isLoadingResults" class="">
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>

        <div class="border-bottom">
          <mat-paginator
            #paginator
            [length]="resultsLength"
            (page)="datatableHelperService.onPaginator($event, 'materials')"
            showFirstLastButtons="true"
            [pageIndex]="0"
            [pageSize]="'materials' | tableSizePipe: 'large'"
            [pageSizeOptions]="datatableHelperService.pageSizeOptions"
          >
          </mat-paginator>
        </div>

        <div>
          <mat-table
            #table
            class=""
            matSort
            matSortStart="desc"
            [@animateStagger]="{ value: '50' }"
            [dataSource]="dataSource"
          >
            <!-- Name Column -->
            <ng-container cdkColumnDef="name_internal">
              <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header
                >{{ "GENERIC.INTERNAL_NAME" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="50"
                *cdkCellDef="let material"
              >
                <p
                  class="text-truncate font-weight-500"
                  matTooltip="{{ material.name }}"
                  [matTooltipPosition]="'right'"
                >
                  {{ material.name_internal }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- product # Column -->
            <ng-container cdkColumnDef="material_number">
              <mat-header-cell fxFlex="15" *cdkHeaderCellDef mat-sort-header
                >{{ "MATERIALS.MATERIAL_NUMBER" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="15"
                *cdkCellDef="let material"
              >
                <p class="text-truncate">
                  {{ material.material_number }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- price Column -->
            <ng-container cdkColumnDef="price">
              <mat-header-cell fxFlex="15" *cdkHeaderCellDef mat-sort-header
                >{{ "GENERIC.PRICE" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="15"
                *cdkCellDef="let material"
              >
                <p class="text-truncate">
                  {{ material.price_label }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- confirmed Column -->
            <ng-container cdkColumnDef="confirmed">
              <mat-header-cell fxFlex="10" *cdkHeaderCellDef mat-sort-header
                >{{ "GENERIC.CONFIRMED" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="10"
                *cdkCellDef="let material"
              >
                <p *ngIf="material.confirmed === 1" class="text-truncate">
                  {{ "GENERIC.YES" | translate }}
                </p>
                <p *ngIf="!material.confirmed" class="text-truncate">
                  {{ "GENERIC.NO" | translate }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- is combo Column -->
            <ng-container cdkColumnDef="is_combo">
              <mat-header-cell fxFlex="10" *cdkHeaderCellDef mat-sort-header
                >{{ "MATERIALS.COMBO" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="10"
                *cdkCellDef="let material"
              >
                <p *ngIf="material.is_combo" class="text-truncate">
                  {{ "GENERIC.YES" | translate }}
                </p>
                <p *ngIf="!material.is_combo" class="text-truncate">
                  {{ "GENERIC.NO" | translate }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- is open in new tab -->
            <ng-container cdkColumnDef="newTab">
              <mat-header-cell fxFlex="50px" *cdkHeaderCellDef mat-sort-header>
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="50px"
                *cdkCellDef="let material"
              >
                <button
                  mat-button
                  matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                  (click)="
                    $event.stopPropagation(); onOpenMaterialInNewTab(material)
                  "
                  class="mat-icon-button"
                >
                  <mat-icon class="icon-mat">{{ getIcon("NEW_TAB") }}</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *cdkHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row
              class="mat-row-color"
              *cdkRowDef="let material; columns: displayedColumns"
              matRipple
              [routerLink]="
                '/' +
                appRoutesService.materials +
                '/' +
                material.slug +
                '/' +
                material.uuid
              "
            >
            </mat-row>
          </mat-table>
          <mat-paginator
            #paginatorBottom
            [length]="paginator.length"
            (page)="syncPrimaryPaginator($event)"
            showFirstLastButtons="true"
            [pageIndex]="paginator.pageIndex"
            [pageSize]="paginator.pageSize"
            [pageSizeOptions]="paginator.pageSizeOptions"
          >
          </mat-paginator>
          <div
            *ngIf="isLoadingResults && !isFirstLoad"
            fxLayout="row"
            fxLayoutAlign="center start"
            class=""
          >
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="fixed-button">
  <button
    mat-fab
    class="gt2-create-button"
    id="add-material-button"
    (click)="onCreateNewMaterial($event)"
    *fuseIfOnDom
    [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
  >
    <mat-icon class="icon-mat-black">add</mat-icon>
  </button>
</div>
