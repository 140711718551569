export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_DELIVERY_REQUEST_TEMPLATE: {
      TITLE: "Nouveau modèle de demande de livraison",
      EDIT_TITLE: "Modifier modèle de demande de livraison",
      SELECT_FLOW: "Définir ou sélectionner un déroulement",
      SHOW_ON_ORDER: "Afficher sur les commandes",
      SHOW_ON_PROPOSAL: "Afficher sur les propositions",
      SHOW_ON_INVOICE: "Afficher sur les factures",
      SHOW_ON_MATERIAL_REPORT: "Afficher sur les rapports de matériel",
      NEED_INSTALATION: "Besoin d'installation",

      SHOW_ON_PURCHASE_ORDER_PRODUCTION: "Afficher sur les bons de commande",
      SHOW_ON_PURCHASE_ORDER_CLIENT_COPY: "Afficher sur les bons de commande - client",
      SHOW_ON_CLIENT_PROPOSAL_AND_ORDER: "Afficher sur les propositions et commandes",
      SHOW_ON_PURCHASE_ORDER_KITCHEN: "Afficher sur les bons de commande - Cuisine",
      SHOW_ON_PURCHASE_MATERIAL: "Afficher sur les bons de matériel",

      TEMPLATE_NAME: "Nom du modèle",
      TEMPLATES: "Sélectionner un modèle de demande de livraison",
      TEMPLATES_EMPTY: "Aucun modèle de créé.",
      TEMPLATES_EMPTY_CLICK: "Cliquer ici pour accéder à la section création de template",
    },
  },
};
