import { ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { Settings } from '../../settings/settings';
import { GenericAlertComponent } from '../generic-alert/generic-alert.component';
import {
  TagByModelItemModel,
  TagsByModelService,
} from './tags.-by-model.service';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';

@Component({
  selector: 'app-tags-by-model',
  templateUrl: './tags-by-model.component.html',
  styleUrls: ['./tags-by-model.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    TranslateModule,
    MatIconModule,
    Gt2PrimengModule,
    MatOptionModule,
    MatInputModule,
    MatChipsModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
  ],
  providers: [TagsByModelService],
})
export class TagsByModelComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() tagFamily!: string[];
  @Input() customTagFamily!: string;
  @Input() tagModel!: string;
  @Input() tagModelUUID!: string;
  @Input() tagPlaceholder!: string;
  @Input() apiCallDelay: number = 200;
  @Input() disabled: boolean = false;
  tagsColors: any = {};
  tagInvalid: boolean = false;

  //
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  // separatorKeysCodes: number[] = [ENTER, COMMA];
  separatorKeysCodes: number[] = [ENTER];
  tagCtrl = new UntypedFormControl();
  filteredTags: Observable<string[]>; // Used for autocomplete list
  tags: any[] = [];
  allTags: string[] = [];
  autoCompleteTags: TagByModelItemModel[] = [];
  tagsModel?: TagByModelItemModel[];

  @ViewChild('tagInput') tagInput!: ElementRef;
  @ViewChild('tagsMatAutocomplete') matAutocomplete!: MatAutocomplete;
  confirmDialogRef: any;

  form!: UntypedFormGroup;

  constructor(
    public translate: TranslateService,
    public tagsService: TagsByModelService,
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
  ) {
    super();
    // this.form = this.formBuilder.group({
    //     tagInput: new FormControl({value: '', disabled: true})
    // });

    this.tagsColors['tags-crm'] = 'accent';
    this.tagsColors['tags-order-kind'] = 'primary';

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allTags.slice(),
      ),
    );
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // //this.logger.info("TagsComponent.ngAfterViewInit() -> pre api call");
    setTimeout(() => {
      // //this.logger.info("TagsComponent.ngAfterViewInit() -> api call starting -> this.disabled: " + this.disabled);
      if (!this.disabled) {
        this.addAutoCompleteTagsByFamily(this.tagFamily);
      }

      this.getTagsByModel(this.tagModel, this.tagModelUUID);
    }, this.apiCallDelay);
  }

  ngOnDestroy() {}

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    this.tagInvalid = value.length > Settings.tagMaxCharLong;
    if (this.tagInvalid) {
      //this.logger.info("TAG TOO LONG!!");
      this.showTagErrorAlert();
      return;
    }

    if (this.tags.indexOf(value) !== -1) {
      input.value = '';
      return;
    }

    const model: any = this.autoCompleteTags.find((item) => {
      return item.name === value;
    });

    if (model) {
      this.addTag(value);
      return;
    }

    // Add our tag
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
    // //this.logger.info("TagsComponent.add() -> customTagFamily:" + this.customTagFamily);
    this.tagsService
      .addTagItemByModelFamily(
        this.tagModel,
        this.tagModelUUID,
        this.customTagFamily,
        value.trim(),
      )
      .subscribe(
        (response: any) => {
          this.updateTagsFromCollection(response.data);
          this.addAutoCompleteTagsByFamily(this.tagFamily);
        },
        (error: any) => {
          //this.logger.info("TagsComponent.add() -> ERROR:" + JSON.stringify(error));
          // in case of error, we remove the tag to the collection
          const index = this.tags.indexOf(value);
          if (index >= 0) {
            this.tags.splice(index, 1);
          }
        },
      );
  }

  remove(tag: string): void {
    //this.logger.info("remove() -> tag: " + tag);
    const tagToRemove: any = this.tagsModel?.find((item) => {
      return item.name === tag;
    });

    this.tagsService
      .removeTag(tagToRemove.uuid, this.tagModel, this.tagModelUUID)
      .subscribe(
        (response: any) => {
          // //this.logger.info("TagsComponent.add() -> SUCCESS:" + JSON.stringify(response));
          this.updateTagsFromCollection(response.data);
          this.addAutoCompleteTagsByFamily(this.tagFamily);
        },
        (error: any) => {
          //this.logger.info("TagsComponent.add() -> ERROR:" + JSON.stringify(error));

          // in case of error, we re-add the tag to the collection
          this.tags.push(tag);
        },
      );

    //
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const tagValue: string = event.option.viewValue;
    this.addTag(tagValue);
  }

  public addTag(tagValue: string): void {
    if (this.tags.indexOf(tagValue) !== -1) {
      return;
    }

    this.tags.push(tagValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    //this.logger.info("selected(): " + tagValue);

    const tag: any = this.autoCompleteTags.find((item) => {
      return item.name === tagValue;
    });

    this.tagsService
      .addNewTagItemByModelFamily(this.tagModel, this.tagModelUUID, tag.uuid)
      .subscribe(
        (response: any) => {
          // //this.logger.info("TagsComponent.add() -> SUCCESS:" + JSON.stringify(response));
          this.updateTagsFromCollection(response.data);
          this.addAutoCompleteTagsByFamily(this.tagFamily);
        },
        (error: any) => {
          this.logger.error(
            'TagsComponent.add() -> ERROR:' + JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);

          // in case of error, we remove the tag to the collection
          const index = this.tags.indexOf(tagValue);
          if (index >= 0) {
            this.tags.splice(index, 1);
          }
        },
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(
      (fruit) => fruit.toLowerCase().indexOf(filterValue) === 0,
    );
  }

  public addAutoCompleteTagsByFamily(families: string[]): void {
    this.autoCompleteTags = [];
    this.allTags = [];
    let i: number = 0;
    const length: number = families.length;
    for (i; i < length; i++) {
      this.loadAutoCompleteTagsByFamily(families[i]);
    }
  }

  public loadAutoCompleteTagsByFamily(family: string): void {
    this.tagsService
      .getTagsByFamily(family, this.tagModel, this.tagModelUUID)
      .subscribe(
        (response: any) => {
          let i: number = 0;
          const length: number = response.data.length;
          for (i; i < length; i++) {
            this.autoCompleteTags.push(response.data[i]);
            this.allTags.push(response.data[i].name);
            this.filteredTags = this.tagCtrl.valueChanges.pipe(
              startWith(null),
              map((fruit: string | null) =>
                fruit ? this._filter(fruit) : this.allTags.slice(),
              ),
            );
          }
          // //this.logger.info("TagsComponent.loadAutoCompleteTagsByFamily() -> allTags:" + JSON.stringify(this.allTags));
        },
        (error: any) => {
          //this.logger.info("TagsComponent.loadAutoCompleteTagsByFamily() -> ERROR:" + JSON.stringify(error));
        },
      );
  }

  public getTagsByModel(model: string, modelUUID: string): void {
    this.tagsService.getTagsByModel(model, modelUUID).subscribe(
      (response: any) => {
        // //this.logger.info("TagsComponent.getTagsByModel() -> SUCCESS:" + JSON.stringify(response));
        this.updateTagsFromCollection(response.data);
      },
      (error: any) => {
        //this.logger.info("TagsComponent.getTagsByModel() -> ERROR:" + JSON.stringify(error));
      },
    );
  }

  public updateTagsFromCollection(tags: TagByModelItemModel[]): void {
    this.tagsModel = tags;
    this.tags = [];

    let i: number = 0;
    const length: number = tags.length;
    for (i; i < length; i++) {
      this.tags.push(tags[i].name);
    }
  }

  // TODO: DEPRECATED
  public addTagsByModelFamily(
    model: string,
    modelUUID: string,
    family: string,
  ): void {
    this.tagsService.addTagsByModelFamily(model, modelUUID, family).subscribe(
      (response: any) => {
        // //this.logger.info("TagsComponent.getTagsByFamily() -> SUCCESS:" + JSON.stringify(response));
        let i: number = 0;
        const length: number = response.data.length;
        for (i; i < length; i++) {
          this.tags.push(response.data[i].name);
        }
      },
      (error: any) => {
        this.logger.error(
          'TagsComponent.getTagsByFamily() -> ERROR:' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public getTagColor(tag: any, collection: any[]): string {
    const model: any = collection.find((item) => {
      return item.name === tag;
    });

    if (model) {
      return model.color;
    }
    return '';
  }

  public showTagErrorAlert(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.TAG_LENGTH_INVALID');
    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      this.confirmDialogRef = null;
    });
  }
}
