import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-attach-client-enterprise',
  templateUrl: './attach-client-enterprise.component.html',
  styleUrls: ['./attach-client-enterprise.component.scss'],
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    MatButtonModule,
    FlexLayoutModule,
    CommonModule,
  ],
})
export class AttachClientEnterpriseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  clientEnterprise: any;
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AttachClientEnterpriseComponent>,
    private translationLoader: FuseTranslationLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
  ) {
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      // first_name: ['', [Validators.minLength(2), Validators.maxLength(100), Validators.required]],
      // last_name: ['', [Validators.minLength(2), Validators.maxLength(100), Validators.required]],
      // enterprise_name: ['', [Validators.minLength(2), Validators.maxLength(100), Validators.required]],
      // enterprise_child_name: ['', [Validators.minLength(2), Validators.maxLength(100), Validators.required]],
    });

    this.clientEnterprise = {
      // client: {
      //     first_name: null,
      //     last_name: null,
      // },
      // enterprise: {
      //     name: null,
      // },
      // enterprise_child: {
      //     name: null
      // }
    };
  }

  ngOnInit() {
    this.loadEnterprise();
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public loadEnterprise(): void {}

  public onAttachClient(): void {
    this.loading = true;
    //this.logger.info("AttachClientEnterpriseComponent.onAttachClient() -> " + this.form.valid);
    //this.logger.info("AttachClientEnterpriseComponent.onAttachClient() -> this.client: " + JSON.stringify(this.clientEnterprise));
    if (this.form.valid) {
      // this.createClientService.attachClientToEnterprise(this.clientEnterprise).subscribe(response => {
      //         this.logger.info("AttachClientEnterpriseComponent.onCreateClient() -> SUCCESS: " + JSON.stringify(response));
      //         this.toastr.success("", "Client, entreprise et succursale créer avec succès");
      //         this.dialogRef.close(false);
      //         this.loading = false;
      //     },
      //     error => {
      //         this.logger.error("AttachClientEnterpriseComponent.onCreateClient() -> ERROR: " + JSON.stringify(error));
      //         this.loading = false;
      //     }
      // );
    }
  }
}
