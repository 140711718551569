<div
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
  id="shop-web-rte"
>
  <ng-template #visitHintTemplate>
    <p>{{ "SHOPS.VISIT" | translate }}</p>
  </ng-template>

  <!--<ng-template #storeUrlHintTemplate>
        <p>{{'SHOPS.STORE_URL_HINT' | translate }}</p>
    </ng-template>

    <ng-template #show_ctrl_themeHintTemplate>
        <p>{{'SHOPS.SHOW_CTRL_THEME_HINT' | translate }}</p>
    </ng-template>

    <ng-template #show_ctrl_theme_modeHintTemplate>
        <p>{{'SHOPS.SHOW_CTRL_THEME_MODE_HINT' | translate }}</p>
    </ng-template>

    <ng-template #merchant_idHintTemplate>
        <p>{{'SHOPS.MONERIS_MERCHANT_ID_HINT' | translate }}</p>
    </ng-template>

    <ng-template #merchant_keyHintTemplate>
        <p>{{'SHOPS.MONERIS_MERCHANT_KEY_HINT' | translate }}</p>
    </ng-template>

    <ng-template #profile_idHintTemplate>
        <p>{{'SHOPS.MONERIS_PROFILE_ID_HINT' | translate }}</p>
    </ng-template> -->

  <div class="gt2-theme">
    <div
      class="header accent p-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button
        (click)="onPageBack(location, router, '/shops')"
        class="mr-0 mr-sm-16 white-fg"
        mat-icon-button
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div
        class="text-truncate"
        fxFlex="80%"
        fxLayout="column"
        fxLayoutAlign="center start"
      >
        <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
          <div
            *fuseIfOnDom
            [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }"
            class="shop-title"
          >
            <div *ngIf="shop" class="">
              <span class="text-truncate"
                >{{ "SHOPS.WEB_SHOP" | translate }} - {{ shop.name }} -
                {{ scheduleType | uppercase }}</span
              >
            </div>
          </div>
        </div>
        <div fxLayout="row">
          <div *ngIf="shop && shop.shop_type">
            {{ "SHOPS.INTEGRATION_TYPE" | translate }}:
            {{ shop.shop_type.name }}
          </div>
          <div *ngIf="shop && shop.shop_product_type">
            &nbsp;| {{ "SHOPS.SELL_TYPE" | translate }}:
            {{ shop.shop_product_type.name }}
          </div>
        </div>
      </div>

      <div fxFlex style="margin-right: 12px">
        <img *ngIf="shop && shop.logos" src="{{ shop.logos.logo_white }}" />
      </div>
      <div
        class=""
        fxLayout="row"
        fxLayoutAlign="end start"
        style="width: 40px"
      >
        <button
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
        >
          <mat-icon class="s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>

        <!-- <button (click)="scrollToElement(testAnchore)" class="mat-icon-button" mat-button>
                    <mat-icon class="s-30">{{ getIcon('NEW_TAB') }}</mat-icon>
                </button> -->
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <div
    *ngIf="shop"
    class="primary mat-elevation-z1 py-8 gt2-sub-header"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    style="height: 40px; min-height: 40px; max-height: 40px"
  >
    <div
      *ngIf="shop.shop_web"
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-left: 20px"
    >
      <div *ngIf="!shop.shop_web.gt_store_url" class="info text-truncate">
        {{ "SHOPS.GT_STORE_URL" | translate }}: {{ shop.shop_web.gt_store_url }}
      </div>
      <div
        *ngIf="shop.shop_web.gt_store_url"
        (click)="openURL(shop.shop_web.gt_store_url)"
        class="info text-truncate link-cursor"
      >
        {{ "SHOPS.GT_STORE_URL" | translate }}: {{ shop.shop_web.gt_store_url }}
      </div>

      <mat-icon
        *ngIf="shop.shop_web.gt_store_url"
        matTooltip="visitHintTemplate"
        content-type="template"
        style="margin-left: 10px; cursor: pointer"
        (click)="openURL(shop.shop_web.gt_store_url)"
        color="accent"
      >
        link</mat-icon
      >
    </div>
  </div>

  <!--HISTORY SIDEBAR-->
  <!-- <div class="container-history-panel">
        <fuse-sidebar class="section-history-panel" name="shopHistoryPanel" position="right">
            <app-section-history-panel panelName="shopHistoryPanel" section="shops" sectionUUID="{{shopID}}">
            </app-section-history-panel>
        </fuse-sidebar>
    </div> -->

  <div class="content">
    <!--HISTORY SIDEBAR BUTTON-->
    <!-- <div>
            <button (click)="toggleHistoricSidebarOpen('shopHistoryPanel')" *fuseIfOnDom
                [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
                class="warn mat-elevation-z2 historic-button" mat-icon-button>
                <mat-icon>history</mat-icon>
            </button>
        </div> -->

    <!-- CENTER -->
    <div #mainContainer class="center pb-56" fusePerfectScrollbar #scrollMe>
      <!-- CONTENT -->
      <div class="shop-content">
        <!-- <app-collection-table
                        *ngIf="schedule"
                        [collectionData]="schedule" 
                        [displayedColumns]="tableColumns"
                        [dynamicTableItemAction]="scheduleTableItemAction">
                </app-collection-table> -->

        <app-shop-rte-schedule-edit-data-table
          *ngIf="schedule"
          [shopUUID]="shopID"
          [scheduleType]="scheduleType"
          [collectionData]="schedule"
          [displayedColumns]="tableColumns"
          [dynamicTableItemAction]="scheduleTableItemAction"
          (scrollEvent)="onScrollPage()"
        >
        </app-shop-rte-schedule-edit-data-table>

        <!-- [displayedColumnsHeaderName]="tableColumnsName" -->
        <!-- <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">

                    <div *ngIf="shop" fxLayout="column" fxFlex="100%">
                        <div class="profile-box info-box general">

                            <header class="accent">
                                <div class="title">{{ "SHOPS.XXX" | translate}}</div>
                            </header>

                            <div class="content" fxLayout="column">

                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">

                                    
                                </div>
                            </div>
                        </div>

                    </div>
                </div> -->

        <!-- / CONTENT -->
      </div>

      <!-- / CENTER -->
    </div>
  </div>
</div>

<!-- <div *ngIf="shop && editable" fxLayout="row" fxLayoutAlign="end start">
    <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
        <div>
            <button (click)="onEdit($event)" *fuseIfOnDom [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
                [disabled]="loading" class="gt2-edit-button" id="edit-shop-button" mat-fab>
                <mat-icon *ngIf="!editMode">{{ getIcon('EDIT_FAB') }}</mat-icon>
                <mat-icon *ngIf="editMode">{{ getIcon('UNDO') }}</mat-icon>
            </button>
        </div>
    </div>
</div> -->
