import { RichTextsValidator } from './../../../utils/RichTextsValidator';
import { NgxPermissionsService } from 'ngx-permissions';
import { EmployeeAssignmentsComposeService } from './../../../api/employee-assignments-compose.service';
import { AssignmentReportComposeComponent } from './assignment-report-compose/assignment-report-compose.component';
import { ApiRoutes } from './../../../api/ApiRoutes';
import { ItemUrlFinder } from './../../../utils/item-url-finder';
import { AssignementsService } from './../../events/event/assignments/assignements.service';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SettingsPrefModel } from '../../../api/ledger-account.service';
import { UserSettingsService } from '../../../api/user-settings.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor } from 'primeng/editor';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { startWith, switchMap, map } from 'rxjs/operators';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { LocalizationConfig } from '../../../../localization/LocalizationConfig';
import { FileService } from '../../../../services/file.service';
import { PrintService } from '../../../../services/print.service';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { JobsFunctionService } from '../../../api/jobs-function.service';
import { EmployeeModelData } from '../../../api/models/CrudEmployeeModel';
import { JobFunctionsModel } from '../../../api/models/JobFunctionsModel';
import { AddressComponent } from '../../../components/address/address.component';
import { ContactComponent } from '../../../components/contact/contact.component';
import { AddJobsFunctionDialogComponent } from '../../../components/dialogs/add-jobs-function-dialog/add-jobs-function-dialog.component';
import { CreateClientComponent } from '../../../components/dialogs/create-client/create-client.component';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { AppRoutes } from '../../../const/AppRoutes';
import { ICanDeactivateComponent } from '../../../gards/can-deactivate-guard.service';
import { RolesService } from '../../../services/roles.service';
import { Settings } from '../../../settings/settings';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { locale as english } from '../crud-employees/i18n/en-CA';
import { locale as french } from '../crud-employees/i18n/fr-CA';
import { CrudEmployeeService } from './crud-employee.service';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { ConvertEmployeeToUserComponent } from '../../../components/dialogs/convert-employee-to-user/convert-employee-to-user.component';
import { Title } from '@angular/platform-browser';
import { FuseSidebarService } from '../../../../../@fuse/components/sidebar/sidebar.service';
import moment from 'moment';
import { ModulesService } from '../../../services/modules.service';
import { MenuReportsService } from '../../../reports/menu-reports.service';
import { PaperEnum } from '../../../reports/ReportsEnum';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from '../../calendar/CustomDateFormatter';

@Component({
  selector: 'app-crud-employee',
  templateUrl: './crud-employee.component.html',
  styleUrls: ['./crud-employee.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [
    // {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT},
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class CrudEmployeeComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  static DELETE: string = 'DELETE';
  static DATE_FROM_KEY: string = 'affectationsDateFrom';
  static DATE_TO_KEY: string = 'affectationsDateTo';

  languages: any;
  selectedLanguage: any;
  isSmallScreen!: boolean;

  employeeID!: string;
  employee!: EmployeeModelData;
  employeeIsSelf: boolean = false;
  loading: boolean = false;
  editMode: boolean = false;
  todayDate!: string; // = new Date().toString();
  editable: boolean = true;

  @ViewChild('addressComponent')
  addressComponent!: AddressComponent;
  @ViewChild('contactComponent')
  contactComponent!: ContactComponent | any;
  // @ViewChild('employeeLeftNav') employeeLeftNav: EmployeeLeftNavComponent;
  @ViewChild('noteEditor') noteEditor!: Editor;
  displayedJobsFunctionsColumns: string[] = ['name', 'salary', 'actionsColumn'];
  jobsFunctions: any = {};
  jobFunctionEmpty: boolean = false;

  form: UntypedFormGroup;
  jobsFunctionsForm: UntypedFormGroup;
  isVisible: boolean = true;
  confirmDialogRef: any;
  noteInvalid: boolean = false;

  // currentImpersonateUUID: string; // = '2d9d62d1-d9b7-4fde-aa41-45b0e89489f3';

  viewSocialNumber: boolean = false;
  settings!: SettingsPrefModel;

  // --o Subscription
  getEmployeeSubscription!: Subscription;

  // --o Assignments DATA TABLE
  dataSource = new MatTableDataSource();
  displayedColumns = [
    'real_date_from',
    'real_date_to',
    'real_time_from',
    // 'real_time_to',
    // 'all_day',
    'job_function',
    'event_name',
    'real_duration_label_hour',
    'note_instruction',
  ];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  // @ViewChild("table", { static: false }) table: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  dateRange1: any;
  formDates: UntypedFormGroup;
  noteHintMaxChar = 200;

  employeeReportModel: any = {
    date_from: null,
    date_to: null,
    uuid_employee: null,
  };

  richTextsValidator?: RichTextsValidator;

  constructor(
    public employeeService: CrudEmployeeService,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private jobsFunctionService: JobsFunctionService,
    public dialog: MatDialog,
    public api: Gt2ApiService,
    private route: ActivatedRoute,
    public router: Router,
    public location: Location,
    public print: PrintService,
    public settingsService: UserSettingsService,
    public fileService: FileService,
    private adapter: DateAdapter<any>,
    public toastr: ToastrService,
    private translationLoader: FuseTranslationLoaderService,
    public rolesService: RolesService,
    private titleService: Title,
    // private clientsService: ClientsService,
    public moduleService: ModulesService,
    public permissions: NgxPermissionsService,
    private employeeAssignmentsComposeService: EmployeeAssignmentsComposeService,
    private assignementsService: AssignementsService,
    private _fuseSidebarService: FuseSidebarService,
    public datatableHelperService: DatatableHelperService,
    public menuReportsService: MenuReportsService,
    private logger: NGXLogger,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    // //this.logger.info("CrudEmployeeComponent.constructor()");
    // DateFormatPreference.getDateFormat()

    this.adapter.setLocale(this.translate.currentLang);

    const dateFrom1 = localStorage.getItem(
      CrudEmployeeComponent.DATE_FROM_KEY + '_1',
    );
    const dateTo1 = localStorage.getItem(
      CrudEmployeeComponent.DATE_TO_KEY + '_1',
    );

    // //this.logger.info(
    //     "CrudEmployeeComponent.constructor()-> dateFrom1: " + dateFrom1
    // );
    // //this.logger.info(
    //     "CrudEmployeeComponent.constructor()-> dateTo1: " + dateTo1
    // );
    this.dateRange1 = {
      date_from: dateFrom1
        ? moment(dateFrom1).format('YYYY-MM-DD')
        : moment().startOf('isoWeek').format('YYYY-MM-DD'),
      date_to: dateTo1
        ? moment(dateTo1).format('YYYY-MM-DD')
        : moment().endOf('isoWeek').format('YYYY-MM-DD'),
    };

    this.form = this.formBuilder.group({
      first_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      last_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      gender: '',
      employee_number: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      employee_type: '',
      dob: ['', [ValidatorHelper.dateDOB]],
      employee_role: '',
      hire_at: ['', [ValidatorHelper.dateRangeDefault]],
      termination_at: ['', [ValidatorHelper.dateRangeDefault]],
      social_number: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      bank_transit: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      bank_institution: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      bank_account: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      have_car: '',
      have_driver_licence: '',
      driver_licence_type: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
    });

    this.formDates = this.formBuilder.group({
      date_from1: ['', [Validators.required]],
      date_to1: ['', [Validators.required]],
      // date_from2: ['', [Validators.required]],
      // date_to2: ['', [Validators.required]],
      // date_from3: ['', [Validators.required]],
      // date_to3: ['', [Validators.required]],
    });

    this.jobsFunctionsForm = this.formBuilder.group({
      hourly_wage: ['', [Validators.minLength(1), Validators.required]],
    });

    //
    this.jobsFunctions.onJobsFunctionsChanged = new BehaviorSubject({});
    this.jobsFunctions.dataSource = new FilesDataSource(this.jobsFunctions);
    // this.currentImpersonateUUID = localStorage.getItem(ImpersonationService.LOCAL_STORAGE_ID);

    this.settingsService.getSettings().subscribe({
      next: (response: any) => {
        // //this.logger.info("CrudEmployeeComponent.constructor().getSettings() SUCCESS: " + JSON.stringify(response));
        this.settings = response.data;
      },
      error: (error: any) => {
        //this.logger.info("CrudEmployeeComponent.constructor().getSettings() ERROR: " + JSON.stringify(error));
      },
    });
  }

  public canDeactivate(): boolean {
    if (this.addressComponent && this.addressComponent.form.dirty) {
      return false;
    }

    if (this.contactComponent && this.contactComponent.form.dirty) {
      return false;
    }
    // //this.logger.info(
    //     "canDeactivate() -> this.richTextsValidator.dirty(): " +
    //         this.richTextsValidator.dirty()
    // );
    return !this.form.dirty && !this.richTextsValidator?.dirty();
  }

  ngOnInit() {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
    //this.titleService.setTitle(this.translate.instant("GENERIC.EMPLOYEE"));

    // language
    this.languages = LocalizationConfig.LANGUAGES;
    this.selectedLanguage = LocalizationConfig.getCurrentLanguage();

    // data
    this.todayDate = new Date().toISOString();

    this.route.params.subscribe({
      next: (params: any) => {
        this.employeeID = params.id;
        // this.loading = true;
        this.loadEmployee();
      },
    });

    this.richTextsValidator = new RichTextsValidator();
  }

  public initRichText(): void {
    this.richTextsValidator?.registerRichText(this.noteEditor, 'noteEditor');
  }

  ngAfterViewInit() {
    if (this.editMode) {
      this.setControlsChanges(this.form);
    }

    this.moduleService.modulesSet.subscribe({
      next: async (areSet) => {
        const hasPermission = await this.permissions.hasPermission(
          this.rolesService.TEAM_LEAD_ROLES,
        );

        if (areSet && hasPermission) {
          setTimeout(() => {
            this.setUpAssignmentsTable();
          });
        }
      },
    });
  }

  ngOnDestroy() {
    if (this.getEmployeeSubscription) {
      this.getEmployeeSubscription.unsubscribe();
    }

    if (this.richTextsValidator) {
      this.richTextsValidator.destroy();
    }
  }

  public setUpAssignmentsTable(): void {
    // //this.logger.info("CrudEmployeeComponent.setUpAssignmentsTable()");
    this.sort.sortChange.subscribe({
      next: () => (this.paginator.pageIndex = 0),
    });

    this.isLoadingResults = true;

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });
          // //this.logger.info(
          //     "CrudEmployeeComponent.setUpAssignmentsTable().switchMap()"
          // );
          // this.dateRange1.date_from = moment(this.dateRange1.date_from)
          //     .format("YYYY-MM-DD")
          //     .toString();
          // this.dateRange1.date_to = moment(this.dateRange1.date_to)
          //     .format("YYYY-MM-DD")
          //     .toString();
          return this.assignementsService
            .getEmployeeAssignments(
              this.sort.active,
              this.sort.direction,
              this.paginator.pageIndex,
              this.paginator.pageSize,
              this.employeeID,
              this.dateRange1,
            )
            .catch((error) => {
              setTimeout(() => {
                this.isLoadingResults = false;
                this.isRateLimitReached = true;
                this.handleAPIError(error, this.dialog, null, null);
              });

              return {
                data: [],
                meta: {
                  pagination: {
                    total: 0,
                    count: 0,
                    per_page: this.paginator?.pageSize,
                    current_page: 1,
                    total_pages: 1,
                    links: {},
                  },
                },
              };
            });
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          return data.data;
        }),
      )
      // .catch( error => {
      //     setTimeout(() => {
      //         this.isLoadingResults = false;
      //         this.isRateLimitReached = true;
      //         this.handleAPIError( error, this.dialog, null, null);
      //     });
      //     return of([]);
      // })
      .subscribe({
        next: (data) => {
          this.dataSource.data = data;
          this.isLoadingResults = false;
        },
      });
  }

  public loadEmployee(): void {
    this.loading = true;
    this.getEmployeeSubscription = this.employeeService
      .getEmployee(this.employeeID)
      .subscribe({
        next: (response) => {
          // //this.logger.info(
          //     "CrudEmployeeComponent.loadEmployee() -> SUCCESS: " +
          //         JSON.stringify(response)
          // );

          const isFirstLoad: boolean =
            this.employee === null || this.employee == undefined;

          //
          this.setEmployee(response.data);
          // this.setUpAssignmentsTable();
          this.loading = false;
          this.editable = !this.employee?.isArchive;
          //this.titleService.setTitle(this.translate.instant("GENERIC.EMPLOYEE") + " " + this.employee.full_name);

          if (isFirstLoad) {
            setTimeout(() => {
              this.initRichText();
            }, 500);
          }
          // //this.logger.info("CrudEmployeeComponent.loadEmployee() -> SUCCESS this.loading: " + this.loading);
        },
        error: (error) => {
          this.logger.error(
            'CrudEmployeeComponent.loadEmployee() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
          //this.logger.info("CrudEmployeeComponent.loadEmployee() -> ERROR this.loading: " + this.loading);
        },
      });
  }

  public setEmployee(employee: any): void {
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.employee = employee;
    this.jobsFunctions.onJobsFunctionsChanged.next(
      this.employee?.job_functions,
    );
    this.jobFunctionEmpty =
      !this.employee?.job_functions ||
      this.employee?.job_functions.length === 0;
    if (this.employeeService.me) {
      this.employeeIsSelf =
        this.employee?.uuid === this.employeeService.me.uuid;
    }
    this.setEmployeeReportModel();
  }

  public deleteEmployee(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.employeeService.deleteEmployee(this.employee).subscribe({
            next: (response) => {
              // //this.logger.info("CrudEmployeeComponent.deleteEmployee() -> SUCCESS: " + JSON.stringify(response));
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.router.navigateByUrl('/' + AppRoutes.EMPLOYEES);
            },
            error: (error) => {
              this.logger.error(
                'CrudEmployeeComponent.deleteEmployee() -> ERROR: ' +
                  JSON.stringify(error),
              );
              // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.loading = false;
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
      },
    });
  }

  public override autosaveDataChange(data: any): void {
    // //this.logger.info("CrudEmployeeComponent.autosaveDataChange() -> data: " + JSON.stringify(data));
    // this.loading = true;
    // if (isUndefined(data.role)) {
    //     this.employeeService
    //         .updateEmployee(data, this.employeeID)
    //         .subscribe(
    //             (response) => {
    //                 // //this.logger.info("CrudEmployeeComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
    //                 this.loading = false;
    //                 this.setEmployee(response.data);
    //             },
    //             (error) => {
    //                 this.logger.error(
    //                     "CrudEmployeeComponent.autosaveDataChange() -> ERROR: " +
    //                         JSON.stringify(error)
    //                 );
    //                 this.loading = false;
    //                 this.handleAPIError(error, this.dialog, null, null);
    //             }
    //         );
    // }
  }

  public onDeleteJobFunction(data: any): void {
    // //this.logger.info("CrudEmployeeComponent.onDeleteJobFunction() -> data: " + JSON.stringify(data));
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');
    // this.confirmDialogRef.componentInstance.confirmMessage = this.translate.instant('EMPLOYEES.REMOVE_JOB_FUNCTION_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.jobsFunctionService
            .disassociateJobFunctionFromEmployee(this.employee.uuid, data.uuid)
            .subscribe({
              next: (response: any) => {
                this.setEmployee(response.data);
              },
              error: (error: any) => {
                this.logger.error(
                  'CrudEmployeeComponent.onDeleteJobFunction() -> ERROR: ' +
                    JSON.stringify(error),
                );
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
        this.confirmDialogRef = null;
      },
    });
  }

  public onAddNewJobFunction(): void {
    // //this.logger.info("CrudEmployeeComponent.onAddNewJonFunction()");
    this.confirmDialogRef = this.dialog.open(AddJobsFunctionDialogComponent, {
      width: '440px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: { employeeUUID: this.employee?.uuid },
    });

    this.confirmDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          const jobsF: JobFunctionsModel[] = Object.assign(
            [],
            this.employee?.job_functions,
          );
          // result.selectedJobsFunction.hourly_wage = result.hourly_wage;
          jobsF.push(result);

          // //this.logger.info("..>> " + JSON.stringify(result.selectedJobsFunction));
          this.employeeService
            .updateEmployee({ job_functions: jobsF }, this.employeeID)
            .subscribe({
              next: (response) => {
                this.employee.job_functions = response.data.job_functions;
                this.jobsFunctions.onJobsFunctionsChanged.next(
                  this.employee?.job_functions,
                );
                this.jobFunctionEmpty =
                  !this.employee?.job_functions ||
                  this.employee.job_functions.length === 0;
                this.employeeIsSelf =
                  this.employee?.uuid === this.employeeService?.me?.uuid;
              },
              error: (error) => {
                this.logger.error(
                  'CrudEmployeeComponent.onAddNewJonFunction() -> ERROR: ' +
                    JSON.stringify(error),
                );
                this.handleAPIError(error, this.dialog, null, null);
              },
            });
        }
        this.confirmDialogRef = null;
      },
    });
  }

  public onEdit($event: any): void {
    this.editMode = !this.editMode;

    if (this.addressComponent) {
      this.addressComponent.editMode = this.editMode;
      if (!this.editMode) {
        this.addressComponent.onManualSave();
      }
    }

    if (this.contactComponent) {
      this.contactComponent.editMode = this.editMode;
      if (!this.editMode) {
        this.contactComponent.onManualSave();
      }
    }

    if (this.noteEditor) {
      this.noteEditor.readonly = !this.editMode;
    }

    if (this.editMode) {
      //
    } else {
      // SAVE
      this.employeeService
        .updateEmployee(this.employee, this.employeeID)
        .subscribe({
          next: (response) => {
            //this.logger.info("CrudEmployeeComponent.autosaveDataChange() -> SUCCESS: " + JSON.stringify(response));
            this.form.markAsPristine();
            this.form.markAsUntouched();
            this.loading = false;
            this.setEmployee(response.data);

            if (this.addressComponent && this.addressComponent.form.dirty) {
              this.addressComponent.form.markAsPristine();
              this.addressComponent.form.markAsUntouched();
            }

            if (this.contactComponent && this.contactComponent.form.dirty) {
              this.contactComponent.form.markAsPristine();
              this.contactComponent.form.markAsUntouched();
            }

            if (this.richTextsValidator) {
              this.richTextsValidator.clean();
            }
          },
          error: (error) => {
            this.logger.error(
              'CrudEmployeeComponent.autosaveDataChange() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.loading = false;
            this.handleAPIError(error, this.dialog, null, null);
          },
        });
    }
  }

  public onCancelEdit(event: any): void {
    this.editMode = false;
    if (this.addressComponent) {
      this.addressComponent.editMode = this.editMode;
    }

    if (this.contactComponent) {
      this.contactComponent.editMode = this.editMode;
    }

    if (this.noteEditor) {
      this.noteEditor.readonly = !this.editMode;
    }

    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.loadEmployee();
  }

  public onHourlyWageChange($event: any): any {
    // //this.logger.info("CrudEmployeeComponent.onHourlyWageChange() -> event: " + JSON.stringify($event));
  }

  public onHourlyWageFocusOut(job: any): any {
    // //this.logger.info("CrudEmployeeComponent.onHourlyWageFocusOut() -> job: " + JSON.stringify(job));
    this.autosaveDataChange({ job_functions: [job] });
  }

  public onEmployeeTypeChange(event: any): void {
    // //this.logger.info("CrudEmployeeComponent.onEmployeeTypeChange() -> event: " + JSON.stringify(event));
    this.employee.employee_type = event;
    this.autosaveDataChange({ employee_type: event });
  }

  public onEmployeeRoleChange(event: any): void {
    // //this.logger.info("CrudEmployeeComponent.onEmployeeRoleChange() -> event: " + JSON.stringify(event));
    this.employee.employee_role = event;
    this.autosaveDataChange({ employee_role: event });
  }

  public onToggleViewSocialNumber(): void {
    this.viewSocialNumber = !this.viewSocialNumber;
  }

  public doPrint(url: string, dateRange: any): void {
    dateRange.date_from = moment(dateRange.date_from)
      .format('YYYY-MM-DD')
      .toString();
    dateRange.date_to = moment(dateRange.date_to)
      .format('YYYY-MM-DD')
      .toString();
    this.print.postPrintHTML(url, dateRange).subscribe({
      next: (response: any) => {
        // //this.logger.info("ClientsComponent.doPrint() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'ClientsComponent.doPrint() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public doDownload(url: string, dateRange: any): void {
    dateRange.date_from = moment(dateRange.date_from)
      .format('YYYY-MM-DD')
      .toString();
    dateRange.date_to = moment(dateRange.date_to)
      .format('YYYY-MM-DD')
      .toString();
    this.fileService.postDownloadFile(url, dateRange).subscribe({
      next: (response) => {
        // //this.logger.info("ClientsComponent.doDownload() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error) => {
        this.logger.error(
          'ClientsComponent.doDownload() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  // public onPrint(): void
  // {
  //     // const url: string = environment.api.protocol + environment.api.domain + '/employees/print/' + this.employee.uuid;
  //     const url: string = this.api.createUrl('/reports/employees/html/' + this.employee.uuid);
  //     // this.print.printHTML(url);
  //     this.doPrint(url, this.dateRange1);
  // }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  // TEST
  public onCreateClient(): void {
    this.logger.debug('CrudEmployeeComponent.onCreateClient()');

    this.dialogRef = this.dialog.open(CreateClientComponent, {
      width: '340px',
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("POP UP Closed!");
        }
        this.dialogRef = null;
      },
    });
  }

  public onCreateClientAndEnterprise(): void {
    this.logger.debug('CrudEmployeeComponent.onCreateClientAndEnterprise()');
  }

  onLanguageChange(event: any): void {
    this.logger.debug('CrudEmployeeComponent.onLanguageChange()');
    // //this.logger.info("CrudEmployeeComponent.onLanguageChange() -> value: " + event.value);
    this.setLanguage(event.value);
  }

  setLanguage(lang: any) {
    this.autosaveDataChange({ employee_locale: lang });
  }

  public onSendInvite(): void {
    this.logger.debug('CrudEmployeeComponent.onSendInvite()');

    this.employeeService.resendInvitation(this.employee.user.uuid).subscribe({
      next: (response) => {
        // //this.logger.info("CrudEmployeeComponent.onSendInvite() -> SUCCESS: " + JSON.stringify(response));
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.INVITE'),
        );

        // //this.logger.info("CrudEmployeeComponent.onRemoveAsUser() -> ");
        this.dialogRef = this.dialog.open(GenericAlertComponent, {
          width: '45%',
          minWidth: 350,
          panelClass: 'scrollable-dialog',
          disableClose: false,
        });
        this.dialogRef.componentInstance.alertMessage =
          this.translate.instant('EMPLOYEES.RESEND_EMAIL') +
          response.data.email_label;
        this.dialogRef.afterClosed().subscribe({
          next: (result: any) => {
            this.dialogRef = null;
          },
        });
      },
      error: (error) => {
        //this.logger.info("CrudEmployeeComponent.onSendInvite() -> ERROR: " + JSON.stringify(error));
        // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.INVITE") );
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.INVITE'),
        );
      },
    });
  }

  public onOpenMailto(email: string): void {
    const mailtoLink = `mailto:${email}`;
    window.open(mailtoLink, '_self');
  }

  public onConvertToUser(): void {
    // //this.logger.info("CrudEmployeeComponent.onConvertToUser()");

    this.dialogRef = this.dialog.open(GenericAlertComponent, {
      width: '45%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
    });
    this.dialogRef.componentInstance.alertMessage =
      this.translate.instant('EMPLOYEES.FEES');
    this.dialogRef.componentInstance.useCancel = true;
    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.dialogRef = null;
        if (result) {
          this.dialogRef = this.dialog.open(ConvertEmployeeToUserComponent, {
            width: '45%',
            minWidth: 350,
            panelClass: 'scrollable-dialog',
            disableClose: false,
            data: {
              user_email: this.employee?.contact.email_organisation,
              employee_uuid: this.employee?.uuid,
            },
          });

          this.dialogRef.afterClosed().subscribe({
            next: (result2: any) => {
              this.dialogRef = null;
              if (result2) {
                this.showUserConvertedDialog(result2);
                this.loadEmployee();
              }
            },
          });
        }
      },
    });
  }

  public onRemoveAsUser(): void {
    // //this.logger.info("CrudEmployeeComponent.onRemoveAsUser() -> ");
    this.dialogRef = this.dialog.open(GenericAlertComponent, {
      width: '45%',
      disableClose: false,
    });

    // TODO: Localized
    this.dialogRef.componentInstance.alertMessage = this.translate.instant(
      'EMPLOYEES.REMOVE_STATUS',
    );
    this.dialogRef.componentInstance.useCancel = true;

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.dialogRef = null;
        if (result) {
          // //this.logger.info("CrudEmployeeComponent.onRemoveAsUser() -> YES, DO REMOVE PLEASE!");
          this.employeeService.removeAsUser(this.employee.user.uuid).subscribe({
            next: (response) => {
              // //this.logger.info("CrudEmployeeComponent.onRemoveAsUser() -> SUCCESS: " + JSON.stringify(response));
              // TODO: NOT THE RIGHT STRING, PLEASE FIX
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
              );
              // this.loading = false;
              this.loadEmployee();
            },
            error: (error) => {
              //this.logger.info("CrudEmployeeComponent.onRemoveAsUser() -> ERROR: " + JSON.stringify(error));
              // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC") );
              this.loading = false;
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          });
        }
      },
    });
  }

  public showUserConvertedDialog(data: any): void {
    // //this.logger.info("CrudEmployeeComponent.onConvertToUser() -> data: " + JSON.stringify(data));
    this.dialogRef = this.dialog.open(GenericAlertComponent, {
      width: '45%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
    });
    this.dialogRef.componentInstance.alertMessage =
      this.translate.instant('EMPLOYEES.SEND_EMAIL') + data.data.email_label;

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        this.dialogRef = null;
      },
    });
  }

  public toggleHistoricSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  public onNavigateToAssignment(row: any): any {
    let url: string = ItemUrlFinder.getItemURL(row.event) + '/affectations';
    if (row.group_assignement) {
      url =
        ItemUrlFinder.getItemURL(row.event) +
        '/affectations/' +
        row.group_assignement.uuid;
    }
    //this.logger.info("> url: " + url);
    this.router.navigateByUrl(url);
  }

  ////
  public reloadAssignmentsTable(): void {
    // //this.logger.info("CrudEmployeeComponent.reloadAssignmentsTable() -> *** this.paginator: " + this.paginator);

    if (this.paginator) {
      // //this.logger.info("CrudEmployeeComponent.reloadAssignmentsTable() -> *** this.paginator EXIST ");
      this.paginator._changePageSize(this.paginator.pageSize);
    }
  }

  public setDateFrom1(dateFrom: string): void {
    const formatedDate = moment(dateFrom).format('YYYY-MM-DD');
    localStorage.setItem(
      CrudEmployeeComponent.DATE_FROM_KEY + '_1',
      formatedDate.toString(),
    );
  }

  public setDateTo1(dateTo: string): void {
    const formatedDate = moment(dateTo).format('YYYY-MM-DD');
    localStorage.setItem(
      CrudEmployeeComponent.DATE_TO_KEY + '_1',
      formatedDate.toString(),
    );
  }

  public onSelectToday1(): void {
    //this.logger.info("CrudEmployeeComponent.onSelectToday1() " + this.sort);
    const today: any = moment().format('YYYY-MM-DD');
    this.dateRange1.date_from = today;
    this.dateRange1.date_to = today;
    // //this.logger.info("CrudEmployeeComponent.onSelectToday() -> totday: " + today);
    this.reloadAssignmentsTable();
  }

  public onSelectThisWeek1(): void {
    // //this.logger.info(
    //     "CrudEmployeeComponent.onSelectThisWeek() " + this.sort
    // );
    const dateFrom: any = moment().startOf('isoWeek').format('YYYY-MM-DD');
    const dateTo: any = moment().endOf('isoWeek').format('YYYY-MM-DD');
    this.dateRange1.date_from = dateFrom;
    this.dateRange1.date_to = dateTo;
    this.reloadAssignmentsTable();
  }

  public onSelectThisMonth1(): void {
    // //this.logger.info("CrudEmployeeComponent.onSelectThisMonth() ");
    const dateFrom: any = moment().startOf('month').format('YYYY-MM-DD');
    const dateTo: any = moment().endOf('month').format('YYYY-MM-DD');
    this.dateRange1.date_from = dateFrom;
    this.dateRange1.date_to = dateTo;
    this.reloadAssignmentsTable();
  }

  public setEmployeeReportModel(): void {
    // //this.logger.info(
    //     "setEmployeeReportModel() -> " + JSON.stringify(this.employee)
    // );
    if (this.employee) {
      this.employeeReportModel.uuid_employee = this.employee.uuid;
    }
    this.employeeReportModel.date_from = this.dateRange1.date_from;
    this.employeeReportModel.date_to = this.dateRange1.date_to;
  }

  public onDateFromChange1(event: any): void {
    // //this.logger.info(
    //     "CrudEmployeeComponent.onDateFromChange1() -> this.dateRange1: " +
    //         JSON.stringify(this.dateRange1)
    // );
    this.dateRange1.date_from = event;
    // //this.logger.info("CrudEmployeeComponent.onDateFromChange() -> this.dateRange1: " + JSON.stringify(this.dateRange1));
    this.setDateFrom1(this.dateRange1.date_from);
    this.setEmployeeReportModel();
    this.reloadAssignmentsTable();
  }

  public onDateToChange1(event: any): void {
    // //this.logger.info(
    //     "CrudEmployeeComponent.onDateToChange1() -> event: " + event
    // );

    //this.logger.info("CrudEmployeeComponent.onDateToChange1() -> this.dateRange1: " + JSON.stringify(this.dateRange1));
    //this.logger.info("CrudEmployeeComponent.onDateToChange1() -> event: " + event);

    // //this.logger.info("CrudEmployeeComponent.onDateToChange1() " + this.sort);
    // //this.logger.info("CrudEmployeeComponent.onDateToChange1() -> this.dateRange1: " + JSON.stringify(this.dateRange1));
    this.dateRange1.date_to = event;
    this.setDateTo1(this.dateRange1.date_to);
    this.setEmployeeReportModel();
    this.reloadAssignmentsTable();
  }

  // reports/mngt/report-mngt-prod-list-by-prod-zone/pdf/{paper}/{local}/{clientKey}/{mode?}'
  public onPrintReportByJob(section: string, paperFormat: string): void {
    // //this.logger.info("CrudEmployeeComponent.onPrintReportByJob() ");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      section,
      paperFormat + '/fr-CA',
    );
    // //this.logger.info("CrudEmployeeComponent.onPrintReportByJob() -> url: " + url);
    this.doPrint(url, this.employeeReportModel);
  }

  public onDownloadReportByJob(section: string, paperFormat: string): void {
    // //this.logger.info("CrudEmployeeComponent.onDownloadReportByJob() ");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      section,
      paperFormat + '/fr-CA',
    );
    // //this.logger.info("CrudEmployeeComponent.onDownloadReportByJob() -> url: " + url);
    this.doDownload(url, this.employeeReportModel);
  }

  public onSendReportByJob(paperFormat: string): void {
    //this.logger.info("CrudEmployeeComponent.onSendReportByJob() ");

    this.employeeAssignmentsComposeService.parentUUID = this.employeeID;
    this.employeeAssignmentsComposeService.byJobFunction = true;

    this.dialogRef = this.dialog.open(AssignmentReportComposeComponent, {
      panelClass: 'assignments-compose-dialog',
      data: {
        // byJobFunction: false,
        dateRange: this.dateRange1,
        paperFormat: paperFormat,
        employeeUUID: this.employeeID,
        employeeReportModel: this.employeeReportModel,
        composeService: this.employeeAssignmentsComposeService,
        byJobFunction: true,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (!response) {
          return;
        }
        this.dialogRef = null;
      },
    });

    // this.employeeAssignmentsComposeService.parentUUID = this.employeeID;
    // this.employeeAssignmentsComposeService.byJobFunction = true;

    // this.dialogRef = this.dialog.open(AssignmentReportComposeComponent, {
    //     panelClass: "assignments-compose-dialog",
    //     data: {
    //         dateRange: this.employeeReportModel,
    //         employeeUUID: this.employeeID,
    //         composeService: this.employeeAssignmentsComposeService,
    //         byJobFunction: true,
    //     },
    // });
    // this.dialogRef.afterClosed().subscribe((response) => {
    //     if (!response) {
    //         return;
    //     }
    //     this.dialogRef = null;
    // });
  }

  // by dates
  public onPrintReportByDates(section: string, paperFormat: string): void {
    // //this.logger.info("CrudEmployeeComponent.onPrintReportByDates() ");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      section,
      paperFormat + '/fr-CA',
    );
    // //this.logger.info("CrudEmployeeComponent.onPrintReportByDates() -> url: " + url);
    this.doPrint(url, this.dateRange1);
  }

  public onDownloadReportByDates(section: string, paperFormat: string): void {
    //this.logger.info("CrudEmployeeComponent.onDownloadReportByDates() ");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      section,
      paperFormat + '/fr-CA',
    );
    //this.logger.info("CrudEmployeeComponent.onDownloadReportByDates() -> url: " + url);
    this.doDownload(url, this.dateRange1);
  }

  public onSendReportByDates(paperFormat: PaperEnum): void {
    //this.logger.info("CrudEmployeeComponent.onSendReportByDates() ");
    this.employeeAssignmentsComposeService.parentUUID = this.employeeID;
    this.employeeAssignmentsComposeService.byJobFunction = false;

    this.dialogRef = this.dialog.open(AssignmentReportComposeComponent, {
      panelClass: 'assignments-compose-dialog',
      data: {
        // byJobFunction: false,
        dateRange: this.dateRange1,
        paperFormat: paperFormat,
        employeeUUID: this.employeeID,
        employeeReportModel: this.employeeReportModel,
        composeService: this.employeeAssignmentsComposeService,
      },
    });
    this.dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (!response) {
          return;
        }
        this.dialogRef = null;
      },
    });
  }
}

export class FilesDataSource extends DataSource<any> {
  constructor(private jobsFunctions: any) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return this.jobsFunctions.onJobsFunctionsChanged;
  }

  disconnect() {}
}
