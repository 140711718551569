<div
  *ngIf="model && model.message"
  [ngClass]="model.elevation ? model.message_color + ' mat-elevation-z1' : model.message_color"
  style="width: 100%;justify-content: space-between;align-items: center;"
  fxLayout="row"
>
  <div style="padding-left: 16px;align-items: center;" fxLayout="row">
    <mat-icon>{{ model.icon }}</mat-icon>
    <div style="font-size: 14px;padding-left: 16px">{{ model.message }}</div>
    <div style="padding-left: 16px" *ngIf="model.message_url && model.message_url_label" class="h3">
      <a href="{{ model.message_url }}" target="_blank">{{ model.message_url_label }}</a>
    </div>
  </div>

  <div *ngIf="model.message_closable">
    <button mat-icon-button matTooltip="{{ 'GENERIC.CANCEL' | translate }}" (click)="onHideTopBar()" class="">
      <mat-icon>{{ iconsService.getIcon("CANCEL") }}</mat-icon>
    </button>
  </div>
</div>
