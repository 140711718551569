<div id="eventClientSection" class="item-full-width">
  <div
    *ngIf="isLoading"
    fxLayout="column"
    fxFlex="100%"
    fxLayoutAlign="center center"
    class="item-full-width"
    style="height: 100%"
  >
    <mat-spinner [diameter]="28" class=""></mat-spinner>
  </div>

  <div
    *ngIf="client && !isLoading"
    fxLayout="column"
    fxLayoutAlign="start start"
    class="item-full-width"
  >
    <div class="client-name">{{ client.name }}</div>
    <div *ngIf="client.enterprise" class="client-enterprise mb-12">
      {{ "GENERIC.ENTERPRISE" | translate }}: {{ client.enterprise.name }}
      <button
        mat-icon-button
        matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
        (click)="onOpenInNewTab(client.enterprise)"
        class="mat-icon-button"
      >
        <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
      </button>
    </div>

    <div
      *ngIf="
        client.address && client.address.street_number && client.address.street
      "
    >
      <div class="">{{ client.address.line_street }}</div>
      <div class="">{{ client.address.line_city }}</div>
      <div class="">{{ client.address.line_country }}</div>
      <div class="mb-8">{{ client.address.line2 }}</div>
    </div>

    <div class="mb-4">
      <div *ngIf="client.primaryEmail" class="">
        <a href="{{ 'mailto:' + client.primaryEmail }}">{{
          client.primaryEmail
        }}</a>
      </div>
      <div *ngIf="client.telephone_home" class="">
        {{ "GENERIC.PHONE" | translate }}:&nbsp;
        <a href="{{ 'tel:' + client.telephone_home }}">{{
          client.telephone_home
        }}</a>
      </div>
      <div *ngIf="client.telephone_office" class="" fxLayout="row">
        {{ "GENERIC.OFFICE_PHONE" | translate }}:&nbsp;
        <a href="{{ 'tel:' + client.telephone_office }}">{{
          client.telephone_office
        }}</a>
        <div *ngIf="client.telephone_office_ext">
          &nbsp; ext: {{ client.telephone_office_ext }}
        </div>
      </div>
      <div *ngIf="client.mobile" class="">
        {{ "GENERIC.MOBILE" | translate }}:&nbsp;
        <a href="{{ 'tel:' + client.mobile }}">{{ client.mobile }}</a>
      </div>
    </div>
    <div *ngIf="client.address && client.address.long && client.address.lat">
      <a
        href="{{ client.address | googleLinkPipe }}"
        target="_blank"
        class="mat-caption"
      >
        <span class="">{{ "GENERIC.SEE_ON_GOOGLE_MAP" | translate }}</span>
      </a>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      class="item-full-width mt-12"
    >
      <button
        mat-raised-button
        *ngIf="client.note"
        (click)="onSeeNote()"
        color="accent"
        class="mb-12"
      >
        {{ "EVENTS.SEE_NOTE" | translate }}
      </button>
    </div>
  </div>

  <div
    *ngIf="!client && !isLoading"
    fxLayout="column"
    fxLayoutAlign="start start"
    class="item-full-width"
  >
    <div class="mb-36">
      {{ "EVENTS.NO_CLIENT_SELECTED" | translate }}
    </div>

    <!-- <div *ngIf="isLoading" fxLayout="column" fxFlex="100%" fxLayoutAlign="center center" class="item-full-width" style="height: 100%;">
        <mat-spinner [diameter]="28" class=""></mat-spinner>
    </div> -->

    <div
      *ngIf="!isLoading && eventUUID"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="item-full-width"
    >
      <button
        mat-raised-button
        *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES"
        (click)="onSelectClient()"
        color="accent"
        class="mb-16"
      >
        {{ "EVENTS.SELECT_CLIENT" | translate }}
      </button>

      <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
        <button
          mat-raised-button
          *ngIf="otherClientIsSet"
          (click)="onSelectSameClient()"
          color="accent"
          class=""
        >
          {{ "EVENTS.SELECT_SAME_CLIENT" | translate }}
        </button>
      </div>
    </div>

    <div
      *ngIf="!isLoading && !eventUUID && hasCongress"
      matTooltip="'EVENTS.CONGRESS_BLOQUES' | translate"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="item-full-width"
    >
      <button
        mat-raised-button
        *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES"
        color="accent"
        class="mb-16"
      >
        {{ "EVENTS.SELECT_CLIENT" | translate }}
      </button>

      <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
        <button
          mat-raised-button
          *ngIf="otherClientIsSet"
          color="accent"
          class=""
        >
          {{ "EVENTS.SELECT_SAME_CLIENT" | translate }}
        </button>
      </div>
    </div>

    <div
      *ngIf="!isLoading && !eventUUID && !hasCongress"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="item-full-width"
    >
      <button
        mat-raised-button
        *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES"
        (click)="onSelectClient()"
        color="accent"
        class="mb-16"
      >
        {{ "EVENTS.SELECT_CLIENT" | translate }}
      </button>

      <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
        <button
          mat-raised-button
          *ngIf="otherClientIsSet"
          (click)="onSelectSameClient()"
          color="accent"
          class=""
        >
          {{ "EVENTS.SELECT_SAME_CLIENT" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
