import { HttpClient } from "@angular/common/http";
import { Gt2ApiService } from "./gt2-api.service";
import { ApiRoutes } from "./ApiRoutes";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";

@Injectable()
export class ServiceChargeMultiplierService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getData() {
    return this.serviceChargesMultiplierList();
  }

  public serviceChargesMultiplierList(): any {
    // this.logger.info("ServiceChargeMultiplierService.serviceChargesMultiplierList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.SERVICE_CHARGE_MULTIPLIER_LIST));
  }
}
