import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CreateClientService } from '../../../api/create-client.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Settings } from '../../../settings/settings';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    CommonModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TranslateModule,
    MatInputModule,
  ],
})
export class CreateClientComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static CREATE_FOR_BRANCH: string = 'CREATE_FOR_BRANCH';
  static CREATE_FOR_ENTERPRISE: string = 'CREATE_FOR_ENTERPRISE';

  form: UntypedFormGroup;
  client: any;
  loading: boolean = false;
  type: string;
  uuid!: string;

  constructor(
    public override dialogRef: MatDialogRef<CreateClientComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private createClientService: CreateClientService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    if (data) {
      this.type = data.type;
      this.uuid = data.uuid;
    } else {
      this.type = CreateClientComponent.CREATE;
    }

    this.form = this.formBuilder.group({
      first_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      last_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.client = {
      first_name: null,
      last_name: null,
    };
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCancel(): void {
    //this.logger.info("CreateClientComponent.onCancel()");
  }

  public onCreateClient(): void {
    this.loading = true;
    //this.logger.info("CreateClientComponent.onCreateClient() -> " + this.form.valid);
    //this.logger.info("CreateClientComponent.onCreateClient() -> this.client: " + JSON.stringify(this.client));
    if (this.form.valid) {
      if (this.type === CreateClientComponent.CREATE_FOR_BRANCH) {
        this.createClientForBranch();
      } else if (this.type === CreateClientComponent.CREATE_FOR_ENTERPRISE) {
        this.createClientForEnterprise();
      } else {
        this.createClient();
      }
    }
  }

  public createClient(): void {
    this.loading = true;
    this.createClientService.createClient(this.client).subscribe(
      (response: any) => {
        // this.logger.info("CreateClientComponent.onCreateClient() -> SUCCESS: " + JSON.stringify(response));
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_CLIENT'),
        );
        this.dialogRef.close(response.data);
        this.loading = false;
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
      },
      (error: any) => {
        this.logger.error(
          'CreateClientComponent.onCreateClient() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.CREATE_CLIENT'),
        );
      },
    );
  }

  public createClientForBranch(): void {
    this.loading = true;
    this.createClientService
      .createClientForEnterprise(this.client, this.uuid)
      .subscribe(
        (response: any) => {
          // this.logger.info("CreateClientComponent.createClientForBranch() -> SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_CLIENT'),
          );
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
          this.dialogRef.close(response.data);
          this.loading = false;
        },
        (error: any) => {
          this.logger.error(
            'CreateClientComponent.createClientForBranch() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.CREATE_CLIENT'),
          );
        },
      );
  }

  public createClientForEnterprise(): void {
    this.loading = true;
    this.createClientService
      .createClientForEnterprise(this.client, this.uuid)
      .subscribe(
        (response: any) => {
          // this.logger.info("CreateClientComponent.createClientForEnterprise() -> SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_CLIENT'),
          );
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
          this.dialogRef.close(response.data);
          this.loading = false;
        },
        (error: any) => {
          this.logger.error(
            'CreateClientComponent.createClientForEnterprise() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.CREATE_CLIENT'),
          );
        },
      );
  }
}
