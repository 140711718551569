import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { JobsFunctionService } from '../../../api/jobs-function.service';
import { JobFunctionsModel } from '../../../api/models/JobFunctionsModel';
import { AppRoutesService } from '../../../services/app-routes.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-add-jobs-function-dialog',
  templateUrl: './add-jobs-function-dialog.component.html',
  styleUrls: ['./add-jobs-function-dialog.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    CommonModule,
    MatOptionModule,
    MatButtonModule,
    MatSelectModule,
    FlexLayoutModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatProgressSpinnerModule,
    RouterLink,
  ],
})
export class AddJobsFunctionDialogComponent implements OnInit, OnDestroy {
  public jobsFunction!: JobFunctionsModel[];
  public selectedJobsFunction?: JobFunctionsModel | null = null;
  public hourlyWage!: number;
  public employeeUUID: string;
  public form: any;
  public formIsInvalid: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<AddJobsFunctionDialogComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public appRoutesService: AppRoutesService,
    private jobsFunctionService: JobsFunctionService,
  ) {
    this.translationLoader.loadTranslations(english, french);
    this.employeeUUID = data.employeeUUID;

    this.jobsFunctionService
      .getJobsFunctionAvailable(this.employeeUUID)
      .subscribe(
        (response: any) => {
          // this.logger.info("AddJobsFunctionDialogComponent.jobsFunctionService.getAll() -> SUCCESS: " + JSON.stringify(response));
          this.jobsFunction = response.data;
        },
        (error: any) => {
          this.logger.error(
            'AddJobsFunctionDialogComponent.jobsFunctionService.getAll() -> ERROR: ' +
              JSON.stringify(error),
          );
          // this.handleAPIError(error, this.dialog);
        },
      );
  }

  ngOnInit() {}

  ngOnDestroy() {}

  public onHourlyWageChange(event: any): void {
    // this.logger.info("AddJobsFunctionDialogComponent.onHourlyWageChange() -> main form invalid: " + this.form.controls['hourly_wage'].invalid);
    this.formIsInvalid =
      this.form.controls['hourly_wage'].invalid ||
      this.selectedJobsFunction === null;
  }
}
