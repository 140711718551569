import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Subscription, forkJoin, fromEvent } from 'rxjs';
import { NgPipesModule } from 'ngx-pipes';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { ProductModel, ProductsService } from '../../api/products.service';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { CreateProductComponent } from '../dialogs/create-product/create-product.component';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { OrdersService } from '../../api/orders.service';
import { ModulesService } from '../../services/modules.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-combo',
  templateUrl: './create-combo.component.html',
  styleUrls: ['./create-combo.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatBadgeModule,
    NgPipesModule,
    MatProgressSpinnerModule,
    NgxSpinnerModule,
    MatTooltipModule,
    TranslateModule,
  ],
  providers: [NgPipesModule, ProductsService],
})
export class CreateComboComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  product: ProductModel | any;
  products?: ProductModel[];
  combo: any[] = [];
  comboCopy?: any[];
  isLoading: boolean = false;
  saving: boolean = false;
  isEmpty: boolean = true;
  creationMode: boolean = false;
  applyCall: boolean = true;
  @ViewChild('filter') filter?: ElementRef;
  @ViewChild('productList') productList: ElementRef | any;
  @ViewChild('comboList') comboList?: ElementRef;
  @ViewChild('box') box?: Input;
  filterValue: string = '';
  newDialogRef: any;
  fromEventSubscription?: Subscription;
  disableBtn = false;
  productChildDropProcess: boolean = false;
  // orderUUDID: string;
  onMultiplayComboItemPriceChanged: BehaviorSubject<ProductModel> | any =
    new BehaviorSubject(null);
  toComboList: any[] = [];
  dynComboDialogRef: any;
  includeCombo: boolean = true;
  isSmallScreen?: boolean;

  constructor(
    public override dialogRef: MatDialogRef<CreateComboComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public productsService: ProductsService,
    private dialog: MatDialog,
    private logger: NGXLogger,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ordersService: OrdersService,
    public moduleService: ModulesService,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    if (data.product) {
      this.product = data.product;
    }

    if (data.products) {
      this.combo = data.products;
      this.comboCopy = JSON.parse(JSON.stringify(this.combo));
    }

    if (data.creationMode) {
      this.creationMode = data.creationMode;
    }

    if (data.includeCombo !== null && !(data.includeCombo == undefined)) {
      this.includeCombo = data.includeCombo;
    }

    //this.logger.info("CreateComboComponent.ngOnInit() data.includeCombo: " + data.includeCombo);
  }

  ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 600px)'])
      .subscribe((result) => {
        this.isSmallScreen = result.matches;
      });
    //this.logger.info("CreateComboComponent.ngOnInit() -> product: " + JSON.stringify(this.product));
  }

  ngAfterViewInit() {
    this.fromEventSubscription = fromEvent(this.filter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          // this.logger.info("CreateComboComponent.onFilterChange() -> " + this.productList);
          // this.productList.nativeElement.scrollTop = 0;
          if (this.filterValue.length >= 3) {
            this.loadProductList();
          } else {
            this.products = [];
            this.isEmpty = true;
          }
          /*
                    if (this.filterValue.length >= 3 || this.filterValue === "")
                    {
                        this.loadProductList();
                    }
*/
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {}

  getComboRealTotal(): number {
    return this.combo.length;
  }

  public loadProductList(): void {
    this.isEmpty = false;
    this.isLoading = true;
    let apiCall = null;
    if (this.includeCombo) {
      apiCall = this.productsService.getProducts(this.filterValue);
    } else {
      apiCall = this.productsService.getProductsListWithoutCombo(
        this.filterValue,
      );
    }
    apiCall.subscribe(
      (response: any) => {
        // this.logger.info("CreateComboComponent.getProductsListWithoutCombo() -> SUCCESS>>: " + JSON.stringify(response));
        this.isLoading = false;
        if (response.data.length > 0) {
          this.products = response.data;
          this.isEmpty = false;
          this.productList.nativeElement.scrollTop = 0;
        } else {
          this.isEmpty = true;
          this.products = [];
        }
      },
      (error: any) => {
        this.logger.error(
          'CreateComboComponent.getProductsListWithoutCombo() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoading = false;
        this.isEmpty = true;
      },
    );
  }

  public saveData(): void {
    // this.logger.info("CreateComboComponent.saveData() -> this.combo: " + JSON.stringify(this.combo));
    // this.logger.info("CreateComboComponent.saveData() -> this.comboCopy: " + JSON.stringify(this.comboCopy));

    this.productChildDropProcess = true;
    this.spinner.show('comboSpinner');
    //
    let requests: any = [];
    for (let i = 0; i < this.toComboList.length; ++i) {
      const item = this.toComboList[i];
      requests.push(
        this.productsService.productComboAttach(
          this.product.uuid,
          item.uuid,
          item.qty,
        ),
      );
    }

    forkJoin(requests).subscribe(
      (response) => {
        // this.logger.info(
        //     "CreateComboComponent.addNewProductToComboList() ATTACH -> SUCCESS"
        // );
        //this.logger.info("CreateComboComponent.forkJoin() ATTACH -> SUCCESS: " + JSON.stringify(response));
        // this.logger.info(
        //     "CreateComboComponent.onDrop() ATTACH -> SUCCESS: " +
        //         JSON.stringify(response.data.childs)
        // );

        // this.onProductChildDrop(
        //     { value: item },
        //     response.new_product
        // );
        // this.combo = response.product.childs;

        this.spinner.hide('comboSpinner');
        this.dialogRef.close(true);
      },
      (error) => {
        this.logger.error(
          'CreateComboComponent.onDrop() ATTACH -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public onFilterChange() {
    // this.logger.info("CreateComboComponent.onFilterChange()");
    this.productList.nativeElement.scrollTop = 0;
  }

  public onDrop(event: any) {
    //this.logger.info(event);
    // this.logger.info(event.source);
    // this.logger.info(typeof event.source);
    // this.logger.info(event.source.className.indexOf("combo-list"));
    // this.logger.info(isUndefined(event.value));
    // this.logger.info("onDrop()");

    if (event.value == undefined) {
      return;
    }

    if (event.source.className.indexOf('combo-list') !== -1) {
      this.onProductChildDrop(event);
      return;
    }

    // this.logger.info("CreateComboComponent.onDrop() -> item: " + event.value);
    event.value.qty = 1;

    // event.value.qty = 1;
    const items: any[] = this.combo.filter((item) => {
      return item.uuid === event.value.uuid;
    });
    // this.logger.info("CreateComboComponent.onDrop( ) -> items: " + items.length);

    // If this product was already in the list, we add to qty
    if (items && items.length > 1) {
      // remove all except event.value & set qty to items.length
      let previousQuantity: number = 1;
      for (let i = 0; i < this.combo.length; ++i) {
        if (
          this.combo[i].uuid === event.value.uuid &&
          this.combo[i] !== event.value
        ) {
          previousQuantity = this.combo[i].qty;
          this.combo.splice(i, 1);
        }
      }
      event.value.qty += previousQuantity;
      //
      if (this.applyCall) {
        this.productsService
          .productComboUpdate(
            this.product.uuid,
            event.value.uuid,
            event.value.qty,
          )
          .subscribe(
            (response) => {
              //this.logger.info("CreateComboComponent.onDrop() UPDATE -> SUCCESS: " + JSON.stringify(response));
            },
            (error) => {
              this.logger.error(
                'CreateComboComponent.onDrop() UPDATE -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
            },
          );
      }
    } else if (this.applyCall) {
      //  if(event.value.is_combo) {
      //     this.logger.info("DROP: IS COMBO! CHOOSE/SELEC PRODUCT FROM CHILDREN");
      //     this.presentDynamicComboChoiceSelection(event.value);
      // } else {
      //     this.addNewProductToComboList(event.value);
      // }
      this.addNewProductToComboList(event.value);
    }
    //
  }

  // public presentDynamicComboChoiceSelection(product: ProductModel): void {

  //     //
  //     this.combo = this.combo.filter(productItem => {
  //         this.logger.info(productItem);
  //         return productItem.uuid !== product.uuid;
  //     });
  // }

  public addNewProductToComboList(item: any): void {
    this.toComboList.push(item);
  }

  public onProductChildDrop(event: any, newProduct?: any): void {
    // this.logger.info(
    //     "ProductComponent.onProductChildDrop() -> " +
    //         JSON.stringify(event.value)
    // );
    // this.logger.info("CreateComboComponent.onProductChildDrop() -> " + JSON.stringify(this.product.childs));
    if (this.combo.length < 2) {
      this.productChildDropProcess = false;
      this.spinner.hide('comboSpinner');
      return;
    }

    let i = 0;
    const length: number = this.combo.length;
    for (i; i < length; ++i) {
      if (this.combo[i].uuid === event.value.uuid) {
        const productUUID = newProduct ? newProduct.uuid : event.value.uuid;
        //this.logger.info("productUUID: " + productUUID);
        // move before
        this.productChildDropProcess = true;
        this.spinner.show('comboSpinner');
        if (i === 0) {
          this.productsService
            .productChildMoveBefore(
              this.product.uuid,
              productUUID,
              this.combo[i + 1].uuid,
            )
            .subscribe(
              (response) => {
                // this.logger.info(
                //     "CreateComboComponent.onProductChildDrop() -> SUCCESS (productChildMoveBefore): " +
                //         JSON.stringify(response)
                // );
                this.productChildDropProcess = false;
                this.spinner.hide('comboSpinner');
                this.combo = response.data.childs;
              },
              (error) => {
                this.logger.error(
                  'CreateComboComponent.onProductChildDrop() -> ERROR (productChildMoveBefore): ' +
                    JSON.stringify(error),
                );
                this.productChildDropProcess = false;
                this.spinner.hide('comboSpinner');
                this.handleAPIError(error, this.dialog, null, null);
              },
            );
        } // move after
        else {
          this.productsService
            .productChildMoveAfter(
              this.product.uuid,
              productUUID,
              this.combo[i - 1].uuid,
            )
            .subscribe(
              (response) => {
                // this.logger.info("CreateComboComponent.onProductChildDrop() -> SUCCESS (productChildMoveBefore): " + JSON.stringify(response));
                this.productChildDropProcess = false;
                this.spinner.hide('comboSpinner');
                this.combo = response.data.childs;
              },
              (error) => {
                this.logger.error(
                  'CreateComboComponent.onProductChildDrop() -> ERROR (productChildMoveBefore): ' +
                    JSON.stringify(error),
                );
                this.productChildDropProcess = false;
                this.spinner.hide('comboSpinner');
                this.handleAPIError(error, this.dialog, null, null);
              },
            );
        }
        break;
      }
    }
  }

  public onRemoveItem(item: any, index: number): void {
    // this.logger.info("CreateComboComponent.onRemoveItem() -> index: " + index);
    // this.logger.info("CreateComboComponent.onRemoveItem() Detach -> BEFORE this.toComboList: " + JSON.stringify(this.toComboList));
    // this.logger.info("CreateComboComponent.onRemoveItem() Detach -> BEFORE this.combo: " + JSON.stringify(this.combo));
    this.toComboList = this.toComboList.filter((listItem) => {
      return item.uuid !== listItem.uuid;
    });
    this.combo = this.combo.filter((listItem) => {
      return item.uuid !== listItem.uuid;
    });
    //
    // this.logger.info("CreateComboComponent.onRemoveItem() Detach -> AFTER this.toComboList: " + JSON.stringify(this.toComboList));
    // this.logger.info("CreateComboComponent.onRemoveItem() Detach -> AFTER this.combo: " + JSON.stringify(this.combo));
  }

  public onAddItem(item: any, doAddItemInModel: boolean = false): void {
    const items: any[] = this.combo.filter((filterItem) => {
      // this.logger.info(filterItem.uuid === item.uuid);
      return filterItem.uuid === item.uuid;
    });

    // item.qty = (items.length === 0) ? 1 : item.qty++;
    //this.logger.info("onAddItem: " + items.length);
    if (items.length === 0) {
      item.qty = 1;
      this.combo.push(item);
      this.addNewProductToComboList(item);
    } else {
      items[0].qty++;
    }
  }

  public onBlur(): void {
    //this.logger.info("CreateComboComponent.onBlur()");
    this.disableBtn = true;
  }
  public onKey(value: string): void {
    //this.logger.info("CreateComboComponent.onKey()");
    this.disableBtn = true;
  }

  public onItemQtyChange(item: any, i: number): void {
    //this.logger.info("CreateComboComponent.onItemQtyChange() -> item.qty: " + item.qty);
  }

  public onLessItem(item: any, i: number): void {
    if (item.qty - 1 === 0) {
      // this.onRemoveItem(item, i);
    } else {
      item.qty -= 1;
    }
  }

  public onRemove(event: any): void {
    // this.logger.info("CreateComboComponent.onRemove() -> combo: " + this.combo.length);

    if (event.value.qty > 1) {
      event.value.qty -= 1;
      this.combo.push(event.value);
    }
  }

  public clearSearch(): void {
    this.filterValue = '';
    this.products = [];
    this.isEmpty = true;
  }

  public onProductComboMultiply($event: any): void {
    //this.logger.info("CreateComboComponent.onProductComboMultiply() -> : " + this.product.multiply_combo_item_price);
    this.productsService
      .updateProduct(this.product, this.product.uuid)
      .subscribe(
        (response: any) => {
          //this.logger.info("CreateComboComponent.onProductComboMultiply() -> SUCCESS: " + JSON.stringify(response));
          // this.product = response.data;
          this.onMultiplayComboItemPriceChanged.next(response.data);
        },
        (error: any) => {
          // this.loading = false;
          // this.logger.info("CreateComboComponent.onProductComboMultiply() -> ERROR: " + JSON.stringify(error));
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }

  public onCreateProduct(): void {
    // this.logger.info("CreateComboComponent.onCreateProduct()");
    this.newDialogRef = this.dialog.open(CreateProductComponent, {
      width: '340px',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        autoRedirect: false,
      },
    });

    this.newDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.logger.info("CreateMaterialComboComponent.onCreateMaterial() -> SUCCESS: " + JSON.stringify(result));

        result.qty = 1;
        this.combo.push(result);

        this.productsService
          .productComboAttach(this.product.uuid, result.uuid, result.qty)
          .subscribe(
            (response) => {
              // this.logger.info("CreateComboComponent.onCreateProduct() ATTACH -> SUCCESS: " + JSON.stringify(response));
            },
            (error) => {
              this.logger.error(
                'CreateComboComponent.onCreateProduct() ATTACH -> ERROR: ' +
                  JSON.stringify(error),
              );
              this.handleAPIError(error, this.dialog, null, null);
              for (let i = 0; i < this.combo.length; ++i) {
                if (this.combo[i].uuid === result.uuid) {
                  this.combo.splice(i, 1);
                  break;
                }
              }
            },
          );
        this.loadProductList();
      }
      this.newDialogRef = null;
    });
  }

  public onOpenInNewTab(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }

  public onModifyCombo(product: any): void {
    //this.logger.info("CreateComboComponent.onModifyCombo() " + JSON.stringify(product));
    this.dynComboDialogRef = this.dialog.open(CreateComboComponent, {
      width: '80%',
      minWidth: 360,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        product: product,
        products: product.childs,
        creationMode: false,
        includeCombo: false,
      },
    });

    this.dynComboDialogRef.componentInstance.confirmMessage = ''; // this.translate.instant("ROOMS.DELETE_CONFIRM");

    this.dynComboDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.loadProduct();
      }

      this.dynComboDialogRef = null;
    });
  }
}
