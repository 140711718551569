<mat-list
  class="date"
  cdkFocusRegionStart>
  <div class="mb-4">
    <mat-calendar
      [selected]="date"
      (selectedChange)="selectedDate = $event"></mat-calendar>
  </div>
</mat-list>

<mat-divider cdkFocusRegionEnd></mat-divider>

<mat-list>
  <mat-list-item>
    <mat-slide-toggle
      fxFlex
      class="mat-primary slide-toggle-notif"
      [(ngModel)]="settings.notify"
      (change)="onNotificationSliderChange($event)"
      labelPosition="before">
      <h4 class="text-wrap">{{ "QUICK_PANEL.NOTIFICATIONS" | translate }}</h4>
    </mat-slide-toggle>
  </mat-list-item>

  <mat-list-item *ngFor="let item of items | async">
    <div
      fxLayout="row"
      fxLayoutAlign="start start">
      <button
        mat-icon-button
        (click)="onDeleteNotification(item)">
        <mat-icon
          color="primary"
          class=""
          >delete</mat-icon
        >
      </button>
    </div>
    <h5
      (click)="onNotificationClick(item)"
      matLine>
      {{ item.title }}
    </h5>
    <p
      (click)="onNotificationClick(item)"
      matLine
      class="secondary-text">
      {{ item.message }}
    </p>
  </mat-list-item>
</mat-list>

<mat-divider></mat-divider>

<mat-divider></mat-divider>
