export const locale = {
  lang: 'en-CA',
  data: {
    FORGOT_PASSWORD: {
      WELCOME_MESSAGE: 'Welcome to the FUSE!',
      WELCOME_LONG_MESSAGE:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat,\n' +
        '            vel convallis elit fermentum pellentesque. Sed mollis velit facilisis facilisis viverra.',
      RECOVER_PASSWORD: 'Forgot your password?',
      SUBTITLE_PASSWORD:
        'We will send instructions to the email address associated with your account.',
      EMAIL_REQUIRED: 'Email is required',
      EMAIL_INVALID: 'Please enter a valid email address',
      SEND_RESET_LINK: 'Send instructions',
      LINK_SENT_MESSAGE: 'We sent you an email to the email you supplied.',
      BACK_LOGIN: 'Sign in',
      NEED_HELP: 'Need help?',
      SUBTITLE_FORGOTPASSWORD:
        "We'll send the instructions to the email address linked to your account.",
    },
  },
};
