import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { EditorModule } from 'primeng/editor';
import { FuseSidebarModule } from '../../../../@fuse/components/sidebar/sidebar.module';
import { MaterialCategoryService } from '../../api/material-category.service';
import { MaterialProviderService } from '../../api/material-provider.service';
import { MaterialsService } from '../../api/materials.service';
import { CanDeactivateGuard } from '../../gards/can-deactivate-guard.service';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MaterialComponent } from './material/material.component';
import { MaterialsComponent } from './materials.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TableSizePipeModule } from '../../modules/table-size.module';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CdkTableModule } from '@angular/cdk/table';
import { CommentSectionComponent } from '../../components/comment-section/comment-section.component';
import { AddressComponent } from '../../components/address/address.component';
import { CreateMaterialComboComponent } from '../../components/create-material-combo/create-material-combo.component';
import { CreatedUpdatedAtComponent } from '../../components/created-updated-at/created-updated-at.component';
import { DragDropListsComponent } from '../../components/dialogs/drag-drop-lists/drag-drop-lists.component';
import { DynamicTableComponent } from '../../components/dynamic-table/dynamic-table.component';
import { Gt2SelectComponent } from '../../components/gt2-select/gt2-select.component';
import { SectionHistoryPanelComponent } from '../../components/section-history-panel/section-history-panel.component';
import { TagsByModelComponent } from '../../components/tags-by-model/tags-by-model.component';
import { ContactComponent } from '../../components/contact/contact.component';
import { ContactService } from '../../components/contact/contact.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.FR.MATERIALS,
    component: MaterialsComponent,
  },
  {
    path: AppRoutesName.FR.MATERIALS + '/:slug/:id',
    component: MaterialComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.EN.MATERIALS,
    component: MaterialsComponent,
  },
  {
    path: AppRoutesName.EN.MATERIALS + '/:slug/:id',
    component: MaterialComponent,
    canDeactivate: [CanDeactivateGuard],
  },
];

@NgModule({
  declarations: [MaterialsComponent, MaterialComponent],
  imports: [
    MatIconModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    TableSizePipeModule,
    MatButtonModule,
    MatTableModule,
    MatTabsModule,
    MatFormFieldModule,
    NgxPermissionsModule,
    MatRippleModule,
    FlexLayoutModule,
    CdkTableModule,
    MatOptionModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    MatBadgeModule,
    RouterModule.forChild(routes),
    DynamicTableComponent,
    EditorModule,
    FormsModule,
    AddressComponent,
    ContactComponent,
    CommentSectionComponent,
    CreatedUpdatedAtComponent,
    Gt2SelectComponent,
    CurrencyMaskModule,
    DragDropListsComponent,
    CreateMaterialComboComponent,
    MatSortModule,
    FuseSidebarModule,
    TagsByModelComponent,
    SectionHistoryPanelComponent,
  ],
  exports: [MaterialsComponent, MaterialComponent],
  providers: [
    MaterialsService,
    MaterialProviderService,
    ContactService,
    MaterialCategoryService,
  ],
})
export class MaterialsModule {}
