import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LocaleModel } from "../gt2/api/models/OrganizationModel";
import { Gt2ApiService } from "../gt2/api/gt2-api.service";
import { ApiRoutes } from "../gt2/api/ApiRoutes";
import { NGXLogger } from "ngx-logger";
import { IGetDataService } from "../gt2/components/gt2-select/gt2-select.component";
import { Observable } from "rxjs";
import { tap, shareReplay } from "rxjs/operators";

@Injectable()
export class Gt2LocaleService implements IGetDataService {
  static supportedLocale: LocaleModel[];

  constructor(private http: HttpClient, public logger: NGXLogger, private api: Gt2ApiService) {}

  public getData(): Observable<any> {
    // this.logger.info("PersonnalTitlesService.getData()");
    return this.loadAppSupportedLocale();
  }

  public loadAppSupportedLocale(): any {
    // this.logger.info("Gt2LocaleService.loadAppSupportedLocale() -> url: " + this.api.createUrl(ApiRoutes.SETTINGS));
    return this.http
      .get<any>(this.api.createUrl(ApiRoutes.GT2_LOCALE))
      .pipe(tap((res) => this.setLocale(res)))
      .pipe(shareReplay());
  }

  private setLocale(response: any): void {
    // this.logger.info("Gt2LocaleService.setLocale() -> response: " + JSON.stringify(response));
    Gt2LocaleService.supportedLocale = response.data;
    // if ( response.response_status === ResponsesStatus.OK )
    // {
    //     // Gt2LocaleService.supportedLocale = response.response);
    // }
  }
}
