<div
  class="profile-box info-box width-100-content"
  fxLayout="column">
  <header
    class="accent"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div class="title">{{ "SETTINGS.TAXES_DEFAULT" | translate }}</div>
    <!-- <div class="gt2-light-green" style="width: 60px; height: 56px;" fxLayout="row" fxLayoutAlign="center center">
        <button type="button" matTooltip="{{ 'WRAPPING.CREATE_NEW_CATEGORY' | translate }}" mat-icon-button class=""
          (click)="onCreateNew()">
          <mat-icon class="" color="">add</mat-icon>
        </button>
      </div> -->
  </header>
  <div
    *ngIf="settingsModel"
    class="content p-16"
    fxLayout="column">
    <!-- MOVED: 8 juin 2020 -->
    <!-- <app-gt2-select [service]="taxesService" 
                        [nullableUseNone]="false"
                        [selectedModel]="settingsModel.delivery_tax"
                        [placeHolder]="'SETTINGS.DEFAULT_DELIVERY_TAX' | translate"
                        [label]="'Taxes'" 
                        (changeEvent)="onDeliveryTaxSelected($event)" 
                        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                        fxFlex="100%" 
                        class=""></app-gt2-select> -->

    <app-gt2-select
      [service]="taxesService"
      [nullableUseNone]="false"
      [selectedModel]="settingsModel.product_tax"
      [placeHolder]="'SETTINGS.DEFAULT_PRODUCT_TAX' | translate"
      [label]="'Taxes'"
      (changeEvent)="onProductTaxSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""></app-gt2-select>

    <app-gt2-select
      [service]="taxesService"
      [nullableUseNone]="false"
      [selectedModel]="settingsModel.staff_charge_tax"
      [placeHolder]="'SETTINGS.DEFAULT_STAFF_CHARGE_TAX' | translate"
      [label]="'Taxes'"
      (changeEvent)="onStaffChargeTaxSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""></app-gt2-select>

    <app-gt2-select
      [service]="taxesService"
      [nullableUseNone]="false"
      [selectedModel]="settingsModel.material_tax"
      [placeHolder]="'SETTINGS.DEFAULT_MATERIAL_TAX' | translate"
      [label]="'Taxes'"
      (changeEvent)="onMaterialTaxSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""></app-gt2-select>

    <app-gt2-select
      [service]="taxesService"
      [nullableUseNone]="false"
      [selectedModel]="settingsModel.service_charge_tax"
      [placeHolder]="'SETTINGS.DEFAULT_SERVICE_CHARGE_TAX' | translate"
      [label]="'Taxes'"
      (changeEvent)="onServiceChargeTaxSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""></app-gt2-select>

    <app-gt2-select
      [service]="taxesService"
      [nullableUseNone]="false"
      [selectedModel]="settingsModel.royalty_tax"
      [placeHolder]="'SETTINGS.ROYALTY_TAX' | translate"
      [label]="'Taxes'"
      (changeEvent)="onRoyaltyTaxSelected($event)"
      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
      fxFlex="100%"
      class=""></app-gt2-select>
  </div>

  <div
    *ngIf="settingsModel"
    fxLayout="row"
    fxLayoutAlign="center start"
    fxFlex="100%"
    class="gt2-buttons-theme content px-16 pb-16">
    <button
      mat-raised-button
      *ngIf="settingsModel"
      color="accent"
      class=""
      (click)="onSaveData()">
      {{ "SETTINGS.UPDATE" | translate }}
    </button>
  </div>
</div>
