<div style="max-height: 100%; overflow: auto">
  <div matDialogTitle *ngIf="mode === 'CREATE'" class="dialog-title">
    {{ "SPECIFIC_CONDITION.TITLE" | translate }}
  </div>
  <div matDialogTitle *ngIf="mode === 'EDIT'" class="dialog-title">
    {{ "SPECIFIC_CONDITION.EDIT_TITLE" | translate }}
  </div>
  <div fxLayout="column" fxLayoutAlign="center start">
    <div fxLayout="column" fxLayoutAlign="center start" class="item-full-width">
      <app-gt2-select
        *ngIf="mode === 'CREATE'"
        [service]="specificConditionService"
        [placeHolder]="
          'SPECIFIC_CONDITION.SELECT_SPECIFIC_CONDITION' | translate
        "
        [label]="''"
        [selectedModel]="selectedSpecificCondition"
        (changeEvent)="onSpecificConditioneChange($event)"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
        [nullableUseNone]="false"
        fxFlex="100%"
        class="item-full-width"
      ></app-gt2-select>

      <!-- <div *ngIf="mode === 'EDIT'" class="my-8 service-charge-name">
        {{ specificCondition.name }}
      </div> -->
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!selectedSpecificCondition || loading"
      color="accent"
      class="mr-16"
      (click)="onCreateSpecificCondition()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
