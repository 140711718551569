<!--<div id="clients" class="page-layout simple fullwidth inner-scroll" fxLayout="column">-->
<div style="max-height: 100%; overflow: auto">
  <div
    #mainContainer
    fxLayout="row"
    fxLayoutAlign="start start"
    class=""
    fusePerfectScrollbar
  >
    <!-- ENTERPRISES -->
    <div fxFlex="100" class="">
      <div class="mat-white-bg mat-elevation-z2">
        <div
          class="pl-12 pr-12"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div class="h1">{{ "GENERIC.ENTERPRISES" | translategt }}</div>
          <div class="mat-body-2 p-12">
            {{ enterprisesResultsLength }}
            {{ "GENERIC.ENTERPRISES" | translategt }}
          </div>
        </div>
        <div class="pl-8 pr-8" fxLayout="row" fxLayoutAlign="end center">
          <!-- SEARCH -->
          <div
            class=""
            fxFlex="1 0 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <label for="searchEnterprise" class="mr-8">
              <mat-icon class="secondary-text">search</mat-icon>
            </label>
            <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
              <mat-label>{{
                "GENERIC.FILTERING_PLACEHOLDER" | translate | titlecase
              }}</mat-label>
              <input
                id="searchEnterprise"
                [(ngModel)]="enterprisesFilterValue"
                matInput
                #enterprisesFilter
                placeholder="{{
                  'GENERIC.FILTERING_PLACEHOLDER' | translate | titlecase
                }}"
              />
              <button
                mat-button
                *ngIf="enterprisesFilterValue"
                matSuffix
                mat-icon-button
                (click)="clearEnterpriseSearch()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <!-- / SEARCH -->
        </div>
        <div class="border-bottom">
          <div
            *ngIf="
              !isEnterprisesLoadingResults && enterprisesResultsLength === 0
            "
            fxLayout="row"
            fxLayoutAlign="center start"
            class="pb-8 mat-body-2"
          >
            {{ "GENERIC.NO_RESULTS" | translate }}
          </div>

          <div *ngIf="isEnterprisesLoadingResults" class="">
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>
        <div>
          <div class="" class="border-bottom">
            <mat-paginator
              #enterprisesPaginator
              [length]="enterprisesResultsLength"
              class="clients-paginator"
              (page)="datatableHelperService.onPaginator($event, 'enterprises')"
              showFirstLastButtons="true"
              [pageIndex]="0"
              [pageSize]="'enterprises' | tableSizePipe: 'large'"
              [pageSizeOptions]="datatableHelperService.pageSizeOptions"
            >
            </mat-paginator>
          </div>
          <mat-table
            #enterprisesTable
            class=""
            matSort
            matSortStart="desc"
            #enterprisesSort="matSort"
            [@animateStagger]="{ value: '50' }"
            [dataSource]="enterprisesDataSource"
          >
            <!-- Name Column -->
            <ng-container cdkColumnDef="name">
              <mat-header-cell fxFlex="100" *cdkHeaderCellDef mat-sort-header
                >{{ "GENERIC.NAME" | translate }}
              </mat-header-cell>
              <mat-cell fxFlex="100" *cdkCellDef="let enterprise">
                <!--<p class="text-truncate font-weight-600">test</p>-->
                <!-- <p class="text-truncate font-weight-600">{{enterprise.name}}</p> -->
                <div
                  fxFlex="100"
                  class=""
                  (click)="onSelectEnterprise($event)"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                >
                  <!-- <div>{{enterprise.name}}</div> -->
                  <!-- <mat-checkbox [checked]="selectedEnterprise === item" class=""> </mat-checkbox> -->
                  <p class="text-truncate font-weight-600">
                    {{ enterprise.name }}
                  </p>
                  <mat-radio-button
                    [checked]="selectedEnterprise === enterprise"
                  ></mat-radio-button>
                </div>
              </mat-cell>
            </ng-container>

            <!-- Employee # Column -->
            <!-- <ng-container cdkColumnDef="enterprise_number">
                            <mat-header-cell fxFlex="30" *cdkHeaderCellDef mat-sort-header>{{
                                'CLIENTS.ENTERPRISE_NUMBER' |
                                translate}}
                            </mat-header-cell>
                            <mat-cell fxFlex="30" *cdkCellDef="let enterprise">
                                <p class="text-truncate">
                                    {{enterprise.enterprise_number}}
                                </p>
                            </mat-cell>
                        </ng-container> -->

            <mat-header-row
              *cdkHeaderRowDef="enterprisesDisplayedColumns"
            ></mat-header-row>
            <mat-row
              *cdkRowDef="let enterprise; columns: enterprisesDisplayedColumns"
              class=""
              matRipple
              (click)="onSelectEnterprise(enterprise)"
            >
            </mat-row>
          </mat-table>
          <!-- <mat-paginator #paginatorBottom [length]="enterprisesPaginator.length"
                        (page)='enterprisesSyncPrimaryPaginator($event)' showFirstLastButtons="true"
                        [pageIndex]="enterprisesPaginator.pageIndex" [pageSize]="enterprisesPaginator.pageSize"
                        [pageSizeOptions]="enterprisesPaginator.pageSizeOptions">

                    </mat-paginator> -->
          <div
            *ngIf="isEnterprisesLoadingResults && !isFirstLoad"
            fxLayout="row"
            fxLayoutAlign="center start"
            class=""
          >
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="mt-8 ml-16 mr-16 selected-label text-bold"
    fxLayout="row"
    fxLayoutAlign="center start"
    *ngIf="selectedEnterprise"
  >
    {{ "ATTACH_ENTERPRISE.SELECTED_ENTERPRISE" | translate }}
    {{ selectedEnterprise.name }}
  </div>

  <div
    mat-dialog-actions
    class="mt-16 mb-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      [disabled]="!selectedEnterprise"
      (click)="onAttachEnterprise()"
      class="accent mr-16"
    >
      {{ "ATTACH_ENTERPRISE.ATTACH" | translate }}
    </button>
    <button mat-raised-button (click)="dialogRef.close(false)" class="primary">
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
