export const locale = {
  lang: "fr-CA",
  data: {
    COMMENTS: {
      COMMENTS: "Commentaires",
      ADD_COMMENTS: "Ajouter un commentaire...",
      ADD_COMMENTS_BUTTON_LABEL: "Ajouter Commentaire",
      COMMENTS_INVALID: "Le commentaire doit contenir moins de {{commentMaxChar}} caractères.",
    },
  },
};
