export const locale = {
  lang: "en-CA",
  data: {
    INVITE_WIZARD: {
      WELCOME_MESSAGE: "Welcome to the Gestion-Traiteur!",
      WELCOME_LONG_MESSAGE: "",

      SETUP_ACCOUNT: "SETUP YOUR ACCOUNT",

      PROFILE_LABEL: "Profile",
      PROFILE_INFO: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      FIRST_NAME: "First name",
      FIRST_NAME_REQUIRED: "First name is required",
      LAST_NAME: "Last name",
      LAST_NAME_REQUIRED: "Last name is required",
      PASSWORD: "Password",
      PASSWORD_REQUIRED: "Password is required",
      PASSWORD_AGAIN: "Password again",
      PASSWORD_NO_MATCH: "Passwords does not match",
      PHONE: "Phone number",
      PHONE_REQUIRED: "Phone number is required",
      PHONE_EXT: "Phone number extension",

      FINALIZE_SUCCESS_MESSAGE: "Congratulation! Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      REGISTER_ACCOUNT: "REGISTER YOUR ACCOUNT",
      ALREADY_HAVE_ACCOUNT: "Already have an account?",

      USER_ALREADY_EXIST: "This user already exist in our database.",
      USER_NOT_FOUND: "We can't find this user in our database or this account creation has already been completed",
    },
  },
};
