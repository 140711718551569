import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { fuseAnimations } from '../../../../@fuse/animations';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OrdersService } from '../../api/orders.service';
import { ProductModel, ProductsService } from '../../api/products.service';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { CreateProductComponent } from '../dialogs/create-product/create-product.component';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { AuthenticationService } from '../../api/auth-service.service';
import { ChoiceComboSelectDialogComponent } from '../../content/products/choice-combo-select-dialog/choice-combo-select-dialog.component';
import { ModulesService } from '../../services/modules.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgPipesModule } from 'ngx-pipes';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import {
  copyArrayItem,
  DragDropModule,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-order-product-dnd',
  templateUrl: './order-product-dnd.component.html',
  styleUrls: ['./order-product-dnd.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    DragDropModule,
    FusePipesModule,
    TranslateModule,
    MatDialogModule,
    MatInputModule,
    MatTooltipModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    Gt2PrimengModule,
    MatBadgeModule,
    MatIconModule,
    ScrollingModule,
    NgPipesModule,
  ],
})
export class OrderProductDndComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  products?: ProductModel[];
  combo: any[] = [];
  isLoading: boolean = false;
  isEmpty: boolean = true;
  creationMode: boolean = false;
  applyCall: boolean = true;
  @ViewChild('filter') filter?: ElementRef;
  @ViewChild('productList') productList: ElementRef | any;
  filterValue: string = '';
  newDialogRef: any;
  fromEventSubscription?: Subscription;
  orderUUID: string | any;
  defaultQty: number = 1;
  choiceComboDialogRef: any;

  constructor(
    public override dialogRef: MatDialogRef<OrderProductDndComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public productsService: ProductsService,
    public ordersService: OrdersService,
    public authService: AuthenticationService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public modulesService: ModulesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    if (data.orderUUID) {
      this.orderUUID = data.orderUUID;
    }

    if (data.guest_count) {
      this.defaultQty = data.guest_count;
      //this.logger.info("OrderProductDndComponent.constructor() -> data.guest_count: " + data.guest_count);
    }
    // if(authService.getUser().user.data.isGod) {
    //     this.filterValue = '___';
    // }
  }

  ngOnInit(): void {
    // this.loadProductList();
  }

  ngAfterViewInit(): void {
    this.fromEventSubscription = fromEvent(this.filter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          // //this.logger.info("OrderProductDndComponent.onFilterChange() -> " + this.productList);
          // //this.logger.info("OrderProductDndComponent.onFilterChange() -> " + this.filterValue);
          if (this.filterValue.length >= 3) {
            this.loadProductList();
          } else {
            this.products = [];
            this.isEmpty = true;
          }
          /*
                              if (this.filterValue.length >= 3 || this.filterValue === "")
                              {
                                this.loadProductList();
                              }
                    */
          // this.productList.nativeElement.scrollTop = 0;
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {}

  public loadProductList(): void {
    this.isLoading = true;
    this.isEmpty = false;
    this.productsService.getProducts(this.filterValue).subscribe({
      next: (response: any) => {
        //this.logger.info("OrderProductDndComponent.getProducts() -> SUCCESS>>: " + JSON.stringify(response));
        this.isLoading = false;
        if (response.data.length > 0) {
          this.products = response.data;
          this.isEmpty = false;
          this.productList.nativeElement.scrollTop = 0;
        } else {
          this.isEmpty = true;
          this.products = [];
        }
      },
      error: (error: any) => {
        this.logger.error(
          'OrderProductDndComponent.getProducts() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoading = false;
        this.isEmpty = true;
      },
    });
  }

  public onFilterChange() {
    // //this.logger.info("OrderProductDndComponent.onFilterChange()");
    this.productList.nativeElement.scrollTop = 0;
  }

  public onSaveProducts(): void {
    // //this.logger.info("OrderProductDndComponent.onSaveProducts() -> products: " + JSON.stringify(this.combo));
    this.isLoading = true;
    this.ordersService
      .addProduct(this.orderUUID, { products: this.combo })
      .subscribe({
        next: (response: any) => {
          // //this.logger.info("OrderProductDndComponent.getProducts() -> SUCCESS>>: " + JSON.stringify(response));
          this.isLoading = false;
          this.dialogRef.close(response.data);
        },
        error: (error: any) => {
          this.logger.error(
            'OrderProductDndComponent.getProducts() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.isLoading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public onDrop(event: any): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.combo, event.previousIndex, event.currentIndex);
    } else {
      //this.logger.info(event.source);
      // //this.logger.info(event.source.className.indexOf("combo-list"));
      if (event.item.data == undefined) {
        return;
      }
      //this.logger.info("OrderProductDndComponent.onDrop() -> item: " + JSON.stringify(event.item.data));

      // if (event.source.className.indexOf('combo-list') !== -1) {
      //   this.onProductChildDrop(event);
      //   return;
      // }

      // event.item.data.qty = 1;
      //this.logger.info("DROP: event.item.data.qty: " + event.item.data.qty);
      const items: any[] = this.combo.filter((item) => {
        return item.uuid === event.item.data.uuid;
      });
      // //this.logger.info("OrderProductDndComponent.onDrop() -> items: " + items.length);

      // If this product was already in the list, we add to qty
      if (items && items.length > 0) {
        event.item.data.qty = event.item.data.qty + 1;
      } else {
        event.item.data.qty = this.defaultQty;
        copyArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
      }
      //
      if (
        this.modulesService.modulesAreSet &&
        this.modulesService.hasComboDynamicModule() &&
        event.item.data.is_choice_product_combo
      ) {
        //this.logger.info("DROP: IS is_choice_product_combo! CHOOSE/SELEC PRODUCT FROM CHILDREN");
        this.presentDynamicComboChoiceSelection(event.item.data);
        return;
      }
    }
  }

  public presentDynamicComboChoiceSelection(product: ProductModel): void {
    //
    // //this.logger.info("OrderProductDndComponent.onProductChildDrop() -> " + JSON.stringify(product));
    let choicesProduct = [];
    // let regularProduct = [];
    for (let i = 0; i < product.childs.length; i++) {
      let p = product.childs[i];
      //this.logger.info("OrderProductDndComponent.presentDynamicComboChoiceSelection() -> " + p.name_internal + " " + p.is_combo);
      //this.logger.info("OrderProductDndComponent.presentDynamicComboChoiceSelection() ->  p.qty: " + p.qty);
      if (p.is_combo) {
        choicesProduct.push(p);
        // this.combo.push(p.childs[0]);
      } else {
        // regularProduct.push(p);
      }
    }
    //
    this.choiceComboDialogRef = this.dialog.open(
      ChoiceComboSelectDialogComponent,
      {
        width: '540px',
        minWidth: 350,
        // minWith: "300px",
        disableClose: false,
        panelClass: 'custom-dialog-container',
        data: {
          choicesProduct: choicesProduct,
          forProduct: product.name_internal,
          forProductUUID: product.uuid,
        },
      },
    );

    this.choiceComboDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          //this.logger.info("OrderProductDndComponent.presentDynamicComboChoiceSelection() -> choices: " + JSON.stringify(result));
          result.data.qty = this.defaultQty;
          this.combo.push(result.data);
          // for ( const p in result.data ) {
          //     //this.logger.info("OrderProductDndComponent.presentDynamicComboChoiceSelection() -> product: " + JSON.stringify(result.data[p]));
          //     result.data[p].qty = this.defaultQty;
          //     this.combo.push(result.data[p]);
          // }
          // for (let i = 0; i < regularProduct.length; ++i) {
          //     this.combo.push(regularProduct[i]);
          // }
        }
        //
        // this.combo.sort(function(a, b){
        //     return product.childs.indexOf(a) - product.childs.indexOf(b);
        // });
        //
        this.choiceComboDialogRef = null;
      },
    });
    //
    this.combo = this.combo.filter((productItem) => {
      //this.logger.info(productItem);
      return productItem.uuid !== product.uuid;
    });
  }

  public onProductChildDrop(event: any) {
    // //this.logger.info("OrderProductDndComponent.onProductChildDrop() -> " + JSON.stringify(event.item.data));
    // //this.logger.info("OrderProductDndComponent.onProductChildDrop() -> " + JSON.stringify(this.product.childs));
    // //this.logger.info(event);
  }

  public onRemoveItem(item: any, index: number) {
    // //this.logger.info("OrderProductDndComponent.onRemoveItem() -> index: " + index);
    item.qty = 0;
    for (let i = 0; i < this.combo.length; ++i) {
      if (this.combo[i] === item) {
        this.combo.splice(i, 1);
        break;
      }
    }
  }

  public onItemQtyChange(item: any): void {
    // //this.logger.info("OrderProductDndComponent.onItemQtyChange() -> ");
  }

  public onAddItem(item: any, doAddItemInModel: boolean = false) {
    const items: any[] = this.combo.filter((filterItem) => {
      // //this.logger.info(filterItem.uuid === item.uuid);
      return filterItem.uuid === item.uuid;
    });

    if (items.length === 0) {
      // item.qty = 1;
      item.qty = this.defaultQty;
      this.combo.push(item);
    } else {
      items[0].qty++;
    }
    // //this.logger.info("OrderProductDndComponent.onAddItem() -> items: " + items.length);
    //
    if (
      this.modulesService.modulesAreSet &&
      this.modulesService.hasComboDynamicModule() &&
      item.is_choice_product_combo
    ) {
      //this.logger.info("onAddItem: IS is_choice_product_combo! CHOOSE/SELEC PRODUCT FROM CHILDREN");
      this.presentDynamicComboChoiceSelection(item);
      return;
    }
  }

  public onLessItem(item: any, i: number): void {
    item.qty -= 1;

    if (item.qty === 0) {
      this.onRemoveItem(item, i);
    }
  }

  public onRemove(event: any) {
    // //this.logger.info("OrderProductDndComponent.onRemove() -> combo: " + this.combo.length);
    // //this.logger.info("OrderProductDndComponent.onRemove() -> combo: " + event);

    if (event.value.qty > 1) {
      event.value.qty -= 1;
      this.combo.push(event.value);
    }
  }

  public clearSearch(): void {
    this.filterValue = '';
    this.products = [];
    this.isEmpty = true;
  }

  public onCreateProduct(): void {
    // //this.logger.info("OrderProductDndComponent.onCreateProduct()");
    this.newDialogRef = this.dialog.open(CreateProductComponent, {
      width: '340px',
      disableClose: false,
      data: {
        autoRedirect: false,
        uuid_order: this.orderUUID,
      },
    });

    this.newDialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          // //this.logger.info("OrderProductDndComponent.onCreateMaterial() -> SUCCESS: " + JSON.stringify(result));
          // this.toastr.success("", this.translate.instant("GENERIC.API_CALL_SUCCESS.CREATE_SUCCESS"));
          result.qty = 1;
          this.combo.push(result);
          if (this.filterValue.length >= 3) {
            this.loadProductList();
          }
        }
        this.newDialogRef = null;
      },
    });
  }

  public onOpenInNewTab(item: any): void {
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }
}
