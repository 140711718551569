export const locale = {
  lang: "fr-CA",
  data: {
    LABOUR_CHARGE: {
      TITLE: "Frais de personnel",
      EDIT_TITLE: "Modifier un frais de personnel",
      SELECT_LABOUR_CHARGE: "Sélectionner un frais de personnel",
    },
  },
};
