import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { Gt2LocaleService } from '../../../../localization/gt2-locale.service';
import { AuthenticationService } from '../../../api/auth-service.service';
import { AddressComponent } from '../../../components/address/address.component';
import { ContactComponent } from '../../../components/contact/contact.component';
import { RolesService } from '../../../services/roles.service';
import { Settings } from '../../../settings/settings';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { CrudOrganizationService } from './crud-organization.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { Title } from '@angular/platform-browser';
import { ModulesService } from '../../../services/modules.service';

@Component({
  selector: 'app-crud-organization',
  templateUrl: './crud-organization.component.html',
  styleUrls: ['./crud-organization.component.scss'],
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
  animations: fuseAnimations,
})
export class CrudOrganizationComponent
  extends GT2FormPageAbstract
  implements OnInit, OnDestroy
{
  @ViewChild('addressComponent') addressComponent?: AddressComponent;
  @ViewChild('contactComponent') contactComponent?: ContactComponent;

  orgUUID!: string;
  organization: any;
  organizationForm: UntypedFormGroup;
  loading: boolean = false;
  editMode: boolean = false;
  isVisible: boolean = true;
  organizationName?: string;

  constructor(
    private auth: AuthenticationService,
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private dialog: MatDialog,
    public translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private toastr: ToastrService,
    private gt2LocaleService: Gt2LocaleService,
    private api: Gt2ApiService,
    public location: Location,
    public rolesService: RolesService,
    private route: ActivatedRoute,
    public modulesService: ModulesService,
    private titleService: Title,
    public router: Router,
    private orgService: CrudOrganizationService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.organizationForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
          Validators.required,
        ],
      ],
      website: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
        ],
      ],
      facebook_url: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
        ],
      ],
      twitter_url: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
        ],
      ],
      youtube_url: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
        ],
      ],
      linkedin_url: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
        ],
      ],
      instagram_url: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
        ],
      ],
      support_email: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
        ],
      ],
      support_tel: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
        ],
      ],
      signature: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxChar),
        ],
      ],
    });
  }

  ngOnInit() {
    // this.titleService.setTitle(this.translate.instant('GENERIC.ORGANIZATION'));

    this.route.params.subscribe({
      next: (params: any) => {
        this.orgUUID = params.id;
        this.loadOrgByUUID(this.orgUUID);
      },
    });
  }

  ngOnDestroy() {}

  public loadOrgByUUID(uuid: string): void {
    this.loading = true;
    this.orgService.loadOrgByUUID(uuid).subscribe({
      next: (organization) => {
        if (organization.data) {
          this.organization = organization.data;
          this.organizationName = this.organization.name;
          // this.titleService.setTitle(
          //   this.translate.instant('GENERIC.ORGANIZATION') +
          //     ' ' +
          //     this.organization.name,
          // );
        }
        this.loading = false;
      },
      error: (error) => {
        this.logger.error(
          'CrudOrganizationComponent.loadOrgByUUID() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public override autosaveDataChange(data: any): void {
    this.loading = true;
    this.orgService.updateOrganization(data, this.orgUUID).subscribe({
      next: (response) => {
        this.organization = response.data;
        this.organizationName = this.organization.name;
        this.loading = false;
      },
      error: (error) => {
        this.logger.error(
          'CrudOrganizationComponent.autosaveDataChange() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    });
  }

  public onManualSaveOrganization(): void {
    if (this.organizationForm.dirty) {
      this.autosaveDataChange(this.organization);
    }
  }

  public onEdit($event: any): void {
    this.editMode = !this.editMode;

    if (this.addressComponent) {
      this.addressComponent.editMode = this.editMode;
    }

    if (this.contactComponent) {
      this.contactComponent.editMode = this.editMode;
    }

    if (this.editMode) {
    } else {
      this.organizationForm.markAsPristine();
      this.organizationForm.markAsUntouched();
    }
  }

  public onOpenInNewTab(): void {
    this.openInNewTab(this.router.url);
  }
}
