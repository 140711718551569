<div style="min-width: 350px;" fxLayout="column" fxLayoutAlign="start start">
  <!--<div class="mb-16">-->
  <!--<app-language-toggle (changeEvent)="onLanguageChange($event)"></app-language-toggle>-->
  <!--</div>-->

  <div fxFlex="100%" *ngIf="model" class="gc-content">
    <div class="" fxLayout="column" fxFlex="100%">
      <div class="mat-title">{{ "GENERAL_CONDITIONS.PROPOSAL_CONDITION" | translate }}</div>
      <!-- <div class="mat-body-1 mb-8">{{ 'GENERAL_CONDITIONS.PROPOSAL_CONDITION_DESCRIPTION' | translate}}</div> -->
      <div class="mat-body-2">Français</div>
      <p-editor
        #proposalEditor
        [(ngModel)]="model.proposal_condition_local.proposal_condition_fr_CA"
        (onInit)="setFocus($event)"
        [style]="{ height: '140px', width: '100%', 'background-color': editMode ? '#ffffff' : '' }"
      >
        <ng-template pTemplate="header" class="">
          <span class="ql-formats">
            <select class="ql-font"></select>
            <select class="ql-size"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </ng-template>
      </p-editor>
      <div fxLayout="row" fxLayoutAlign="end start">
        <button mat-raised-button color="accent" (click)="onSaveEditor({ proposal_condition_local: model.proposal_condition_local })" class="mt-4">
          {{ "GENERIC.SAVE" | translate | uppercase }}
        </button>
      </div>

      <div class="mat-body-2 mt-4">English</div>
      <p-editor #proposalEditorEn [(ngModel)]="model.proposal_condition_local.proposal_condition_en_CA" [style]="{ height: '140px', width: '100%', 'background-color': editMode ? '#ffffff' : '' }">
        <ng-template pTemplate="header" class="">
          <span class="ql-formats">
            <select class="ql-font"></select>
            <select class="ql-size"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </ng-template>
      </p-editor>
      <div fxLayout="row" fxLayoutAlign="end start">
        <button mat-raised-button color="accent" (click)="onSaveEditor({ proposal_condition_local: model.proposal_condition_local })" class="mt-4">
          {{ "GENERIC.SAVE" | translate | uppercase }}
        </button>
      </div>

      <div class="section-divider"></div>

      <!-- <div class="mat-title">{{ 'GENERAL_CONDITIONS.DEPOSITE_INVOICE_CONDITION' | translate}}</div>
            <div class="mat-body-2">Français</div>
            <p-editor #depositInvoiceEditor
                      [(ngModel)]="model.deposite_invoice_condition_local.deposite_invoice_condition_fr_CA"
                      [style]="{'height':'140px', 'width' : '100%', 'background-color' : (editMode)? '#ffffff' : ''}">
                      <ng-template pTemplate="header"  class="">
                            <span class="ql-formats">
                                <select class="ql-font" ></select>
                                <select class="ql-size"></select>
                            </span>
                  
                            <span class="ql-formats">
                                <button class="ql-bold" ></button>
                                <button class="ql-italic"></button>
                                <button class="ql-underline"></button>
                                <button class="ql-strike"></button>
                            </span>
                  
                            <span class="ql-formats">
                                <select class="ql-color"></select>
                                <select class="ql-background"></select>
                            </span>
                  
                            <span class="ql-formats">
                                <button class="ql-list" value="ordered"></button>
                                <button class="ql-list" value="bullet"></button>
                                <button class="ql-indent" value="-1"></button>
                                <button class="ql-indent" value="+1"></button>
                            </span>
                        </ng-template>
            </p-editor>
            <div fxLayout="row" fxLayoutAlign="end start">
                <button mat-raised-button
                        color="accent"
                        (click)="onSaveEditor({deposite_invoice_condition_local: model.deposite_invoice_condition_local})"
                        class="mt-4">
                    {{ 'GENERIC.SAVE' | translate | uppercase }}
                </button>
            </div>

            <div class="mat-body-2 mt-4">English</div>
            <p-editor #depositInvoiceEditorEn
                      [(ngModel)]="model.deposite_invoice_condition_local.deposite_invoice_condition_en_CA"
                      [style]="{'height':'140px', 'width' : '100%', 'background-color' : (editMode)? '#ffffff' : ''}">
                      <ng-template pTemplate="header"  class="">
                            <span class="ql-formats">
                                <select class="ql-font" ></select>
                                <select class="ql-size"></select>
                            </span>
                  
                            <span class="ql-formats">
                                <button class="ql-bold" ></button>
                                <button class="ql-italic"></button>
                                <button class="ql-underline"></button>
                                <button class="ql-strike"></button>
                            </span>
                  
                            <span class="ql-formats">
                                <select class="ql-color"></select>
                                <select class="ql-background"></select>
                            </span>
                  
                            <span class="ql-formats">
                                <button class="ql-list" value="ordered"></button>
                                <button class="ql-list" value="bullet"></button>
                                <button class="ql-indent" value="-1"></button>
                                <button class="ql-indent" value="+1"></button>
                            </span>
                        </ng-template>
            </p-editor>
            <div fxLayout="row" fxLayoutAlign="end start">
                <button mat-raised-button
                        color="accent"
                        (click)="onSaveEditor({deposite_invoice_condition_local: model.deposite_invoice_condition_local})"
                        class="mt-4">
                    {{ 'GENERIC.SAVE' | translate | uppercase }}
                </button>
            </div>

            <div class="section-divider"></div> -->

      <div class="mat-title">{{ "GENERAL_CONDITIONS.EVENT_INVOICE_CONDITION" | translate }}</div>
      <div class="mat-body-2">Français</div>
      <p-editor
        #eventInvoiceEditor
        [(ngModel)]="model.event_invoice_condition_local.event_invoice_condition_fr_CA"
        [style]="{ height: '140px', width: '100%', 'background-color': editMode ? '#ffffff' : '' }"
      >
        <ng-template pTemplate="header" class="">
          <span class="ql-formats">
            <select class="ql-font"></select>
            <select class="ql-size"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </ng-template>
      </p-editor>
      <div fxLayout="row" fxLayoutAlign="end start">
        <!-- <button mat-raised-button
                        color="accent"
                        (click)="onSaveEditor('fr-CA', 'event_invoice_condition', model.event_invoice_condition)"
                        class="mt-4">
                    {{ 'GENERIC.SAVE' | translate | uppercase }}
                </button> -->
        <button mat-raised-button color="accent" (click)="onSaveEditor({ event_invoice_condition_local: model.event_invoice_condition_local })" class="mt-4">
          {{ "GENERIC.SAVE" | translate | uppercase }}
        </button>
      </div>

      <div class="mat-body-2 mt-4">English</div>
      <p-editor
        #eventInvoiceEditorEn
        [(ngModel)]="model.event_invoice_condition_local.event_invoice_condition_en_CA"
        [style]="{ height: '140px', width: '100%', 'background-color': editMode ? '#ffffff' : '' }"
      >
        <ng-template pTemplate="header" class="">
          <span class="ql-formats">
            <select class="ql-font"></select>
            <select class="ql-size"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </ng-template>
      </p-editor>
      <div fxLayout="row" fxLayoutAlign="end start">
        <button mat-raised-button color="accent" (click)="onSaveEditor({ event_invoice_condition_local: model.event_invoice_condition_local })" class="mt-4">
          {{ "GENERIC.SAVE" | translate | uppercase }}
        </button>
      </div>

      <div class="section-divider"></div>

      <!-- ADJUSMENT_INVOICE_CONDITION -->
      <!-- <div class="mat-title">{{ 'GENERAL_CONDITIONS.ADJUSMENT_INVOICE_CONDITION' | translate}}</div>
            <div class="mat-body-2">Français</div>
            <p-editor #adjusmentInvoiceEditor
                      [(ngModel)]="model.adjusment_invoice_condition_local.adjusment_invoice_condition_fr_CA"
                      [style]="{'height':'140px', 'width' : '100%', 'background-color' : (editMode)? '#ffffff' : ''}">
                      <ng-template pTemplate="header"  class="">
                            <span class="ql-formats">
                                <select class="ql-font" ></select>
                                <select class="ql-size"></select>
                            </span>
                  
                            <span class="ql-formats">
                                <button class="ql-bold" ></button>
                                <button class="ql-italic"></button>
                                <button class="ql-underline"></button>
                                <button class="ql-strike"></button>
                            </span>
                  
                            <span class="ql-formats">
                                <select class="ql-color"></select>
                                <select class="ql-background"></select>
                            </span>
                  
                            <span class="ql-formats">
                                <button class="ql-list" value="ordered"></button>
                                <button class="ql-list" value="bullet"></button>
                                <button class="ql-indent" value="-1"></button>
                                <button class="ql-indent" value="+1"></button>
                            </span>
                        </ng-template>
            </p-editor>
            <div fxLayout="row" fxLayoutAlign="end start">
                <button mat-raised-button
                        color="accent"
                        (click)="onSaveEditor({adjusment_invoice_condition_local: model.adjusment_invoice_condition_local})"
                        class="mt-4">
                    {{ 'GENERIC.SAVE' | translate | uppercase }}
                </button>
            </div>

            <div class="mat-body-2 mt-4">English</div>
            <p-editor #adjusmentInvoiceEditorEn
                      [(ngModel)]="model.adjusment_invoice_condition_local.adjusment_invoice_condition_en_CA"
                      [style]="{'height':'140px', 'width' : '100%', 'background-color' : (editMode)? '#ffffff' : ''}">
                      <ng-template pTemplate="header"  class="">
                            <span class="ql-formats">
                                <select class="ql-font" ></select>
                                <select class="ql-size"></select>
                            </span>
                  
                            <span class="ql-formats">
                                <button class="ql-bold" ></button>
                                <button class="ql-italic"></button>
                                <button class="ql-underline"></button>
                                <button class="ql-strike"></button>
                            </span>
                  
                            <span class="ql-formats">
                                <select class="ql-color"></select>
                                <select class="ql-background"></select>
                            </span>
                  
                            <span class="ql-formats">
                                <button class="ql-list" value="ordered"></button>
                                <button class="ql-list" value="bullet"></button>
                                <button class="ql-indent" value="-1"></button>
                                <button class="ql-indent" value="+1"></button>
                            </span>
                        </ng-template>
            </p-editor>
            <div fxLayout="row" fxLayoutAlign="end start">
                <button mat-raised-button
                        color="accent"
                        (click)="onSaveEditor({adjusment_invoice_condition_local: model.adjusment_invoice_condition_local})"
                        class="mt-4">
                    {{ 'GENERIC.SAVE' | translate | uppercase }}
                </button>
            </div>

            <div class="section-divider"></div> -->

      <!-- CREDIT_NOTE_CONDITION -->
      <!-- <div class="mat-title">{{ 'GENERAL_CONDITIONS.CREDIT_NOTE_CONDITION' | translate}}</div>
            <div class="mat-body-2">Français</div>
            <p-editor #creditEditor
                      [(ngModel)]="model.credit_note_condition_local.credit_note_condition_fr_CA"
                      [style]="{'height':'140px', 'width' : '100%', 'background-color' : (editMode)? '#ffffff' : ''}">
                      <ng-template pTemplate="header"  class="">
                            <span class="ql-formats">
                                <select class="ql-font" ></select>
                                <select class="ql-size"></select>
                            </span>
                  
                            <span class="ql-formats">
                                <button class="ql-bold" ></button>
                                <button class="ql-italic"></button>
                                <button class="ql-underline"></button>
                                <button class="ql-strike"></button>
                            </span>
                  
                            <span class="ql-formats">
                                <select class="ql-color"></select>
                                <select class="ql-background"></select>
                            </span>
                  
                            <span class="ql-formats">
                                <button class="ql-list" value="ordered"></button>
                                <button class="ql-list" value="bullet"></button>
                                <button class="ql-indent" value="-1"></button>
                                <button class="ql-indent" value="+1"></button>
                            </span>
                        </ng-template>
            </p-editor>
            <div fxLayout="row" fxLayoutAlign="end start">
                <button mat-raised-button
                        color="accent"
                        (click)="onSaveEditor({credit_note_condition_local: model.credit_note_condition_local})"
                        class="mt-4">
                    {{ 'GENERIC.SAVE' | translate | uppercase }}
                </button>
            </div>

            <div class="mat-body-2 mt-4">English</div>
            <p-editor #creditEditorEn
                      [(ngModel)]="model.credit_note_condition_local.credit_note_condition_en_CA"
                      [style]="{'height':'140px', 'width' : '100%', 'background-color' : (editMode)? '#ffffff' : ''}">
                      <ng-template pTemplate="header"  class="">
                            <span class="ql-formats">
                                <select class="ql-font" ></select>
                                <select class="ql-size"></select>
                            </span>
                  
                            <span class="ql-formats">
                                <button class="ql-bold" ></button>
                                <button class="ql-italic"></button>
                                <button class="ql-underline"></button>
                                <button class="ql-strike"></button>
                            </span>
                  
                            <span class="ql-formats">
                                <select class="ql-color"></select>
                                <select class="ql-background"></select>
                            </span>
                  
                            <span class="ql-formats">
                                <button class="ql-list" value="ordered"></button>
                                <button class="ql-list" value="bullet"></button>
                                <button class="ql-indent" value="-1"></button>
                                <button class="ql-indent" value="+1"></button>
                            </span>
                        </ng-template>
            </p-editor>
            <div fxLayout="row" fxLayoutAlign="end start">
                <button mat-raised-button
                        color="accent"
                        (click)="onSaveEditor({credit_note_condition_local: model.credit_note_condition_local})"
                        class="mt-4">
                    {{ 'GENERIC.SAVE' | translate | uppercase }}
                </button>
            </div> -->
    </div>
  </div>
</div>
