export const locale = {
  lang: "fr-CA",
  data: {
    PRODUCTS: {
      PRODUCTS: "Produits",
      PRODUCT: "Produit",
      NEW_PRODUCTS: "NOUVEAU PRODUIT",
      PRODUCT_NUMBER: "Code du produit",
      PRICE: "Prix de vente",
      CONFIRMED: "Confirmé",
      NOT_CONFIRMED: "Non confirmé",
      COMBO: "Combo",
      COMBO_PARENTS: "Ce produit est contenu dans ces combos",
      SINGLE_PRODUCT: "À la carte",
      DELETE_PRODUCT: "Effacer ce produit",
      ClONE_PRODUCT: "Copier ce produit",
      ClONE_COMBO: "Copier ce combo",
      CREATE_COMBO: "Transformer en combo",
      INTERNAL_NAME: "Nom interne",
      EXTERNAL_NAME: "Nom externe",
      DESCRIPTION: "Description",
      INTERNAL_DESCRIPTION: "Description interne",
      ACCOUNTANT_CODE: "Code comptable",
      PRODUCTION_DELAY: "Délai de production (jours)",
      PRODUCTION_DELAY_DAYS: "(jours)",
      SERVING_COUNT: "Nombre de portions",
      COST: "Coût nourriture",
      SKU: "SKU",
      UNIT: "Unité de mesure",
      UNIT_SELECT: "Sélectionner une unité",
      WRAPPING: "Emballage",
      WRAPPING_SELECT: "Sélectionner un emballage",
      PRODUCT_CATEGORY: "Catégorie du produit",
      PRODUCT_CATEGORY_SELECT: "Sélectionner une categorie",
      MIN_QUANTITY_ORDER: "Quantité minimum par commande",
      ITEM_QUANTITY: "Quantité par portion",
      TAXES: "Taxes",
      PRODUCTION_ZONE: "Zone de production",
      TAXES_SELECT: "Sélectionner les taxes applicables",
      INVOICE_CATEGORIES: "Type de produit",
      INVOICE_CATEGORY: "Type de produit",
      INVOICE_SELECT: "Sélectionner un type de produit",
      MODIFY_PRODUCT: "Modifier",
      CLOSE_EDIT: "Fermer modification",
      CHANGE_COMBO_ORDER: "Changer l'ordre des items ou cliquer le bouton + pour en ajouter/enlever",
      SHOW_CONFIRMED: "Afficher seulement les produits confirmé",
      SHOW_COMBO: "Afficher les combo seulement",
      NO_ALLERGENS: "Il n’y a pas d'allergènes attitrés pour le moment.",
      NO_ALLERGENS_HR: "Il n’y a pas d'allergènes attitrés pour le moment. Appuyez sur le + vert en mode modification pour en ajouter.",
      NO_PRODUCTION_ZONES: "Il n’y a pas de zones de production attitrés pour le moment.",
      NO_PRODUCTION_ZONES_HR: "Il n’y a pas de zones de production attitrés pour le moment. Appuyez sur le + vert en mode modification pour en ajouter.",
      NO_UPSALE_QUESTIONS: "Il n’y a pas de questions de vente incitatives attitrés pour le moment.",
      NO_UPSALE_QUESTIONS_HR: "Il n’y a pas de questions de vente incitatives attitrés pour le moment. Appuyez sur le + vert en mode modification pour en ajouter.",
      ADD_REMOVE_UPSALE_QUESTIONS: "Cliquer le bouton + pour en ajouter/enlever",
      AVAILABILITY: "Disponibilité",
      ADD_REMOVE_ALLERGENS: "Cliquer le bouton + pour en ajouter/enlever",
      ADD_REMOVE_PRODUCTION_ZONE: "Cliquer le bouton + pour en ajouter/enlever",
      ALLERGENS_DIALOG_TITLE: "Ajouter et enlever des allergènes",
      ALLERGENS_COL_LEFT_LABEL: "Glisser et déposer ou utiliser le bouton + pour ajouter un allergène",
      ALLERGENS_COL_RIGHT_LABEL: "Glisser et déposer ou utiliser le bouton poubelle pour enlever un allergène",
      PRODUCTION_ZONE_DIALOG_TITLE: "Ajouter et enlever des zones de productions",
      PRODUCTION_ZONE_COL_LEFT_LABEL: "Glisser et déposer ou utiliser le bouton + pour ajouter une zone de production",
      PRODUCTION_ZONE_COL_RIGHT_LABEL: "Glisser et déposer ou utiliser le bouton poubelle pour enlever une zone de production",
      COMBO_CHECKBOX_LABEL: "Afficher le nom des produits combo",
      COMBO_CHECKBOX_QTY_LABEL: "Afficher la quantité des produits combo",
      COMBO_CHECKBOX_PRICE_LABEL: "Afficher le prix des produits combo",
      ORDERED_PRODUCT: "Produit commandé",
      SEE_ORDER: "Voir commande",
      SEE_PROPOSAL: "Voir proposition",
      SEE_INVOICE: "Voir facture",
      SHOPS: "Commande en ligne",
      PRODUCT_PICKER_TITLE_SIMPLE: "Sélectionner un produit",
      PRODUCT_PICKER_TITLE_MULTI: "Sélectionner un ou des produit(s)",
      PRODUCT_PICKER_SELECT: "Sélectionner",
      NO_SHOPS: "Aucune commande en ligne.",
      NO_CHILDS: "Aucun produit",
      CYCLIC_PRODUCT: "Produit cyclique",
      CUSTOM_DATES_RULE: "Utiliser les dates personnalisées",
      EVERY_MONDAY_RULE: "Afficher le produit chaque lundi",
      RECURRENCE_TYPE: "Type de récurrence",
      CUSTOM_DATES: "Dates personnalisées",
      UPSALE: "Vente incitative",
      UPSALE_CHOOSE: "Sélectionner le produit",
      ADD_QUESTION_HINT: "Créer une question",
      EDIT_QUESTION_HINT: "Modifier la question",
      CHOICE_PRODUCT_COMBO: "Rendre ce combo dynamique",
      CHOICE_PRODUCT_COMBO_TIP: "Permet d'ajouter des combos dont les produits agiront comme une liste de choix",
      UPSALE_QUESTIONS: "Questions pour la Vente Incitative",
      ADD_QUESTION_ANSWER: "Ajouter une réponse",
      DELETE_QUESTION_ANSWER: "Effacer la question",
      CREATE_OPTIONS_GROUP_TITLE: "Créer une nouvelle sélection de produits",
      CREATE_OPTIONS_GROUP: "Créer un groupe",
      OPTIONS_GROUP_NAME: "Nom de la sélection",
      ADD_QUESTION_NAME: "Nom de la question",
      EDIT_GROUP_NAME: "Modifier le nom du groupe",
      CHOICE_REQUIRED_NO_SKIP: "Choix obligatoire",
      ADD_GROUP_OPTION: "Ajouter des produits",
      UPSALE_DRAG_AND_DROP_LEFT_LABEL: "Glisser et déposer ou utiliser le bouton + pour ajouter des questions",
      UPSALE_DRAG_AND_DROP_RIGHT_LABEL: "Liste des questions à ajouter",
    },
  },
};
