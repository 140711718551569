import { ScpmPipe } from './../../app/gt2/pipes/spcm.pipe';
import { ReportButtonLabelPipe } from './reportButtonLabel.pipe';
import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { GT2TranslatePipe } from './../../app/gt2/pipes/gt2-translate.pipe';

@NgModule({
  declarations: [
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    ReportButtonLabelPipe,
    GT2TranslatePipe,
  ],
  imports: [],
  exports: [
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    ReportButtonLabelPipe,
    GT2TranslatePipe,
  ],
})
export class FusePipesModule {}
