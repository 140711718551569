<!--<div id="error-404" fxLayout="column" fxLayoutAlign="center center">-->

<!--<div class="content" fxLayout="column" fxLayoutAlign="center center">-->

<!--<div class="error-code">404</div>-->

<!--<div class="message">Sorry but we could not find the page you are looking for</div>-->

<!--<div class="search" fxLayout="row" fxLayoutAlign="start center">-->
<!--<mat-icon class="icon s-24">search</mat-icon>-->
<!--<input placeholder="Search for anything" fxFlex>-->
<!--</div>-->

<!--<a class="back-link" [routerLink]="'/apps/dashboards/project'">Go back to dashboard</a>-->

<!--</div>-->

<!--</div>-->

<div id="error-404" fxLayout="column" fxLayoutAlign="center center">
  <div
    class="content mb-24 mt-32"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div class="error-code">404</div>

    <!--<div class="message">{{ 'ERROR_500.MESSAGE' | translate }}</div>-->

    <div class="sub-message">
      {{ "ERROR_404.SUB_MESSAGE" | translate }}
      <div *ngIf="last404URL">{{ last404URL }}</div>
    </div>

    <a class="report-link" (click)="onPageBack()">{{
      "ERROR_404.BACK_PAGE" | translate
    }}</a>
    <a class="report-link" [routerLink]="'/' + appRoutesService.dashboard">{{
      "ERROR_404.BACK_HOME_PAGE" | translate
    }}</a>
    <a class="report-link" (click)="onVisitGt2()">{{
      "ERROR_404.GT2_SITE" | translate
    }}</a>
  </div>

  <!--<div class="content mb-24" fxLayout="column" fxLayoutAlign="center center">-->
  <!--<div class="sub-message">-->
  <!--{{ 'ERROR_500.SUB_MESSAGE' | translate }}-->
  <!--</div>-->

  <!--&lt;!&ndash;<a class="back-link mt-8" (click)="onPageBack()">{{ 'ERROR_500.BACK_PAGE' | translate }}</a>&ndash;&gt;-->
  <!--<a class="report-link" (click)="onPageBack()">{{ 'ERROR_500.BACK_PAGE' | translate }}</a>-->
  <!--&lt;!&ndash;<a class="report-link" [routerLink]="'/tableau-de-bord'">{{ 'ERROR_500.REPORT_PROBLEM' | translate }}</a>&ndash;&gt;-->
  <!--</div>-->

  <div
    class="content-form mb-32"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-card class="" style="width: 100%">
      <mat-card-header>
        <mat-card-title class="">{{
          "ERROR_404.CONTACT_US" | translate
        }}</mat-card-title>
        <mat-card-subtitle
          *ngIf="
            setting &&
            setting.support_tel_fr &&
            translate.currentLang === 'fr-CA'
          "
          >Par téléphone :
          <a href="{{ 'tel:' + setting.support_tel_fr }}">{{
            setting.support_tel_fr
          }}</a>
        </mat-card-subtitle>
        <mat-card-subtitle
          *ngIf="
            setting &&
            setting.support_tel_en &&
            translate.currentLang === 'en-CA'
          "
          >By phone:
          <a href="{{ 'tel:' + setting.support_tel_en }}">{{
            setting.support_tel_en
          }}</a>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="setting && translate.currentLang === 'fr-CA'"
          >Par courriel :
          <a href="{{ 'mailto:' + setting.support_email_fr }}">{{
            setting.support_email_fr
          }}</a>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="setting && translate.currentLang === 'en-CA'"
          >By email:
          <a href="{{ 'mailto:' + setting.support_email_en }}">{{
            setting.support_email_en
          }}</a>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="setting && translate.currentLang === 'fr-CA'"
          >Demande de ticket :
          <a href="https://gestiontraiteur.com/demande-de-ticket/"
            >https://gestiontraiteur.com/demande-de-ticket/</a
          >
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="setting && translate.currentLang === 'en-CA'"
          >Ticket request:
          <a href="https://gestiontraiteur.com/demande-de-ticket/"
            >https://gestiontraiteur.com/demande-de-ticket/</a
          >
        </mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <div [formGroup]="form" fxLayout="column" class="p-16">
          <!--<div class="">Subject</div>-->
          <mat-card-subtitle class="mt-12">{{
            "ERROR_404.CONTACT_MESSAGE" | translate
          }}</mat-card-subtitle>
          <mat-form-field class="" appearance="outline">
            <mat-label>{{ "ERROR_404.SUBJECT" | translate }}</mat-label>
            <input
              matInput
              class="contact-input"
              placeholder="{{ 'ERROR_404.SUBJECT' | translate }}"
              formControlName="subject"
              type="text"
              [(ngModel)]="subject"
              required
            />
            <mat-error *ngIf="form.controls['subject'].invalid"
              >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>

          <!--<div class="mt-4">Message</div>-->
          <mat-form-field class="" appearance="outline">
            <mat-label>Message</mat-label>
            <textarea
              matInput
              class="contact-input"
              rows="6"
              placeholder="Message"
              formControlName="message"
              type="text"
              [(ngModel)]="message"
              required
            >
            </textarea>
            <mat-error *ngIf="form.controls['message'].invalid"
              >{{ "GENERIC.INPUT_LONG_LENGTH_INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="end start" class="mr-20">
          <button
            mat-raised-button
            color="accent"
            class=""
            [disabled]="!form.valid"
            (click)="onSendContactForm()"
          >
            {{ "GENERIC.SEND" | translate }}
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div class="mat-caption mt-8">
            Version: {{ environment.appVersion }}
          </div>
        </div>
      </mat-card-content>

      <!--<mat-card-content *ngIf="successEmail">-->
      <!--<div class="mat-body-2">Votre message a été envoyer avec succès.</div>-->
      <!--</mat-card-content>-->
    </mat-card>
  </div>
</div>
