export const locale = {
  lang: 'fr-CA',
  data: {
    LOGIN: {
      WELCOME_MESSAGE: 'Bienvenue chez Gestion-Traiteur!',
      LOGO: 'fuse.png',
      LONG_WELCOME_MESSAGE:
        'Solution logiciel tout-en-un "du web à la cuisine"',
      LONG_WELCOME_MESSAGE_2: 'Centraliser – Simplifier – Rentabiliser',
      LOGIN_TO_YOUR_ACCOUNT: 'Authentification',
      EMAIL_PLACEHOLDER: 'Votre courriel',
      EMAIL_REQUIRED: 'Le courriel est obligatoire',
      EMAIL_INVALID: 'Svp entrer un courriel valide',
      PASSWORD_PLACEHOLDER: 'Votre mot de passe',
      PASSWORD_REQUIRED: 'Mot de passe obligatoire',
      REMEMBER_ME: 'Se souvenir de moi',
      FORGOT_PASSWORD: 'Oublié votre mot de passe?',
      LOGIN_BTN_LABEL: 'Connexion',
      NO_ACCOUNT: "Vous n'avez pas de compte?",
      CREATE_ACCOUNT: 'Créer un compte',
      ERROR_401_MESSAGE:
        'Le courriel et mot de passe ne correspond à aucun compte. Svp valider à nouveau et réessayer.',
      RESEND_INVITE: "RÉENVOYER L'INVITATION",
      ERROR_DIALOG_TITLE: 'Erreur de connexion',
      ERROR_DIALOG_NOT_CONFIRMED:
        "Cette uilisateur n'est pas confirmé. Vérifier vos courriels pour le courriel de confirmation ou envoyer de nouveau ce courriel.",
    },
  },
};
