import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { GodEmailsComponent } from './god-emails.component';
import { AppRoutesName } from '../../../const/AppRoutesName';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
const routes = [
  {
    path: AppRoutesName.EN.GOD_EMAIL,
    component: GodEmailsComponent,
  },
  {
    path: AppRoutesName.FR.GOD_EMAIL,
    component: GodEmailsComponent,
  },
];

@NgModule({
  declarations: [GodEmailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    TranslateModule,
    MatInputModule,
    NgxPermissionsModule,
    MatButtonModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
  ],
  exports: [GodEmailsComponent],
})
export class GodEmailsModule {}
