import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRoutes } from "../../api/ApiRoutes";
import { Gt2ApiService } from "../../api/gt2-api.service";

@Injectable()
export class TagsByModelService {
  // CLIENT
  static TAG_FAMILY_CRM = "tags-crm";
  static TAG_FAMILY_ORDER_KIND = "tags-order-kind";

  // PRODUCT
  static TAG_FAMILY_PRODUCT_OPEN = "tags-product-open";
  static TAG_FAMILY_PRODUCT_MOMENT = "tags-product-moment";
  static TAG_FAMILY_PRODUCT_SEASON = "tags-product-season";
  static TAG_FAMILY_PRODUCT_FEATURE = "tags-product-feature";

  constructor(private http: HttpClient, private api: Gt2ApiService) {}

  public getTagsByFamily(family: string, model: string, tagModelUUID: string): any {
    // this.logger.info("TagsService.getTagsByFamily() -> family: " + family);
    // this.logger.info("TagsService.getTagsByFamily() -> model: " + model);
    // this.logger.info("TagsService.getTagsByFamily() -> tagModelUUID: " + tagModelUUID);
    return this.http.get<any>(this.api.createUrl(ApiRoutes.TAGS_AVAILABLE) + family + "/" + model + "/" + tagModelUUID);
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.TAGS) + family);
  }

  public addTagsByModelFamily(model: string, modelUUID: string, family: string): any {
    // this.logger.info("TagsService.addTagsByModelFamily()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.TAGS) + family + "/" + model + "/" + modelUUID);
  }

  public getTagsByModel(model: string, modelUUID: string): any {
    // this.logger.info("TagsService.addTagsByModelFamily()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.TAGS) + model + "/" + modelUUID);
  }

  public addTagItemByModelFamily(model: string, modelUUID: string, family: string, tag: string): any {
    // this.logger.info("TagsService.addTagItemByModelFamily()");
    // tags/{familly}/add/{model}/{model_uuid}
    return this.http.post<any>(this.api.createUrl(ApiRoutes.TAGS) + family + "/add/" + model + "/" + modelUUID, { key: tag });
  }

  public addNewTagItemByModelFamily(model: string, modelUUID: string, tagUUID: string): any {
    // this.logger.info("TagsService.addTagItemByModelFamily()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.TAGS) + tagUUID + "/attach/" + model + "/" + modelUUID);
  }

  public removeTag(tagUUID: string, model: string, modelUUID: string): any {
    // this.logger.info("TagsService.addTagItemByModelFamily()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.TAGS) + tagUUID + "/detach/" + model + "/" + modelUUID);
  }
}

export interface TagByModelItemModel {
  object: string;
  uuid: string;
  name: string;
  slug: string;
  familly: string;
  color: string;
}
