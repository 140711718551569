import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Gt2ApiService } from './gt2-api.service';
import { MetaModelData } from './models/MetaModelData';

@Injectable()
export class CrudDatatableGenericService {
  clientsCategory?: CrudDataTableGenericModel[];

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getAll(
    route: string,
    sort: string,
    direction: string,
    page: number,
    pageSize: number
  ): Promise<CrudDataTableGenericResponse> {
    // this.logger.info("CrudDatatableGenericService.getAll() -> sort: " + sort  );
    const routeURL: string = this.api.createUrl(route);
    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe((response: any) => {
        this.clientsCategory = response.data;
        // this.logger.info("CrudDatatableGenericService.getAll() -> this.response: " + JSON.stringify(response));
        resolve(response);
      }, reject);
    });
  }

  public create(
    route: string,
    categoryNameFR_CA: string,
    categoryNameEN_CA: string,
    useEnglish: boolean = true
  ): any {
    // this.logger.info("CrudDatatableGenericService.create() -> route: " + route );
    const obj: any = useEnglish
      ? { name_fr_CA: categoryNameFR_CA, name_en_CA: categoryNameEN_CA }
      : { name: categoryNameFR_CA };
    // this.logger.info("CrudDatatableGenericService.create() -> obj: " + JSON.stringify(obj));
    return this.http.post<any>(this.api.createUrl(route), obj);
  }

  public edit(route: string, selected: CrudDataTableGenericModel): any {
    // this.logger.info("CrudDatatableGenericService.edit()");
    return this.http.patch<any>(
      this.api.createUrl(route) + '/' + selected.uuid,
      selected
    );
  }

  public delete(route: string, selected: CrudDataTableGenericModel): any {
    // this.logger.info("CrudDatatableGenericService.delete() - uuid: " + selected.uuid);
    return this.http.delete<any>(
      this.api.createUrl(route) + '/' + selected.uuid
    );
  }
}

export interface CrudDataTableGenericResponse {
  data: CrudDataTableGenericModel[];
  meta: MetaModelData;
}

export interface CrudDataTableGenericModel {
  object: string;
  uuid: string;
  name: string;
}
