import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Gt2ApiService } from './gt2-api.service';
import { ApiRoutes } from './ApiRoutes';
import { EmployeeModelData } from './models/CrudEmployeeModel';
import { NGXLogger } from 'ngx-logger';
import { IGetDataService } from '../components/gt2-select/gt2-select.component';

@Injectable()
export class ConseillersService implements IGetDataService {
  employees?: EmployeeModelData[];
  onEmployeesChanged: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getConseillersList() {
    //this.logger.info("ConseillersService.getEmployeesList()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.GET_CONSEILLER_LIST)
    );
  }

  public getData(): Observable<any> {
    //this.logger.info("ConseillersService.getData()");
    return this.getConseillersList();
  }
}
