export const locale = {
  lang: "fr-CA",
  data: {
    COMPOSE: {
      TITLE: "Nouveau message",
      SHOW_CC_BCC: "Voir CC & BCC",
      HIDE_CC_BCC: "Cacher CC & BCC",
    },
  },
};
