<div id="eventStats">
  <div
    *ngIf="event && event.stats"
    fxLayout="column"
    fxLayoutAlign="0 1 auto"
    fxFlex="100"
  >
    <div
      class="widget-group"
      fxLayout="row"
      fxLayoutAlign="space-around start"
      fxFlex="100%"
      fxLayoutWrap
      *fuseIfOnDom
      class=""
    >
      <fuse-widget
        [@animate]="{ value: '*', params: { y: '100%' } }"
        class="widget"
        fxLayout="column"
        fxFlex="50"
        fxFlex.gt-xs="50"
        fxFlex.gt-md="50"
        style="height: 100%"
      >
        <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
          <div
            class="pl-16 pr-8 py-16 h-52"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <!-- <div class="h3">{{event.stats.title}}</div> -->
            <div class="h3 text-center">{{ "GENERIC.TOTAL" | translate }}</div>
          </div>

          <div
            class="pt-8 pb-32"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <div class="red-fg font-size-24 line-height-24 text-center">
              {{ event.stats.total }}
            </div>
            <!-- <div class="h3 secondary-text font-weight-500">{{event.stats[0].label}}</div> -->
          </div>
        </div>
      </fuse-widget>

      <fuse-widget
        [@animate]="{ value: '*', params: { y: '100%' } }"
        class="widget"
        fxLayout="column"
        fxFlex="50"
        fxFlex.gt-xs="50"
        fxFlex.gt-md="50"
        style="height: 100%"
      >
        <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
          <div
            class="pl-16 pr-8 py-16 h-52"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <div class="h3 text-center">{{ "EVENTS.BALANCE" | translate }}</div>
          </div>

          <div
            class="pt-8 pb-32"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <div class="red-fg font-size-24 line-height-24 text-center">
              {{ event.stats.balance }}
            </div>
            <!-- <div class="h3 secondary-text font-weight-500">{{event.stats[1].label}}</div> -->
          </div>
        </div>
      </fuse-widget>
    </div>

    <div
      class="widget-group"
      fxLayout="row"
      fxLayoutAlign="space-around start"
      fxFlex="100"
      fxLayoutWrap
      *fuseIfOnDom
    >
      <fuse-widget
        [@animate]="{ value: '*', params: { y: '100%' } }"
        class="widget"
        fxLayout="column"
        fxFlex="50"
        fxFlex.gt-xs="50"
        fxFlex.gt-md="50"
        style="height: 100%"
      >
        <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
          <div
            class="pl-16 pr-8 py-16 h-52"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <div class="h3 text-center">
              {{ "EVENTS.TOTAL_PER_GUEST" | translate }}
            </div>
          </div>

          <div
            class="pt-8 pb-32"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <div class="red-fg font-size-24 line-height-24 text-center">
              {{ event.stats.total_per_guest }}
            </div>
            <div class="h3 secondary-text font-weight-500"></div>
          </div>
        </div>
      </fuse-widget>

      <fuse-widget
        [@animate]="{ value: '*', params: { y: '100%' } }"
        class="widget"
        fxLayout="column"
        fxFlex="50"
        fxFlex.gt-xs="50"
        fxFlex.gt-md="50"
        style="height: 100%"
      >
        <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
          <div
            class="pl-16 pr-8 py-16 h-52"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <div class="h3 text-center">
              {{ "EVENTS.NEXT_PAYMENT_DATE" | translate }}
            </div>
          </div>

          <div
            class="pt-8 pb-32"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <div class="red-fg font-size-24 line-height-24 text-center">
              {{ event.stats.next_payment_date }}
            </div>
            <div class="h3 secondary-text font-weight-500"></div>
          </div>
        </div>
      </fuse-widget>
    </div>
  </div>
</div>
