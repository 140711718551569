import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Gt2ApiService } from '../api/gt2-api.service';
import { ApiRoutes } from '../api/ApiRoutes';
import { SettingsModel } from './SettingsModel';
import { Settings } from './settings';
import { ResponsesStatus } from '../api/ResponsesStatus';
import { shareReplay, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class SettingsService {
  settings?: SettingsModel;
  settingsObs?: Observable<any> | null;

  constructor(
    private http: HttpClient,
    public logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public doPing() {
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PING));
  }

  public loadSettings() {
    // this.logger.info("SettingsService.settings() -> url: " + this.api.createUrl(ApiRoutes.SETTINGS));
    if (!this.settingsObs) {
      this.settingsObs = this.http
        .get<any>(this.api.createUrl(ApiRoutes.SETTINGS))
        .pipe(shareReplay(1))
        .pipe(tap((data) => this.setSettings(data)));
    }

    return this.settingsObs;

    // return this.http.get<any>(this.api.createUrl(ApiRoutes.SETTINGS))
    // .do(res => this.setSettings(res)).shareReplay();
  }

  private setSettings(response: any): void {
    // this.logger.info("SettingsService.setSettings() -> response: " + JSON.stringify(response));
    this.settingsObs = null;
    if (response.response_status === ResponsesStatus.OK) {
      Settings.mapModel(response.response);
    }
  }
}
