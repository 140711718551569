import { MenuReportsService } from './../../reports/menu-reports.service';
import { ModulesService } from '../../services/modules.service';
import { RolesService } from './../../services/roles.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiRoutes } from '../../api/ApiRoutes';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { IconsService } from '../../services/icons.service';
import { GT2DateAdapter } from '../../utils/GT2DateAdapter';
import { FileService } from '../../../services/file.service';
import { PrintService } from '../../../services/print.service';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { Title } from '@angular/platform-browser';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { CrudOrganizationService } from '../crud/crud-organization/crud-organization.service';
import { CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from '../calendar/CustomDateFormatter';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})
export class ReportsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static DATE_FROM_KEY: string = 'reportsDateFrom';
  static DATE_TO_KEY: string = 'reportsDateTo';

  dateRange1: any;
  dateRange2: any;
  dateRange3: any;
  dateRange4: any;
  confirmDialogRef: any;
  form: UntypedFormGroup;
  dateNow: Date = new Date();
  dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  @ViewChild('pdfTable') pdfTable?: ElementRef;

  constructor(
    public translate: TranslateService,
    public iconsService: IconsService,
    public logger: NGXLogger,
    private api: Gt2ApiService,
    public print: PrintService,
    public fileService: FileService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private adapter: DateAdapter<any>,
    private titleService: Title,
    public rolesPermissions: RolesService,
    public moduleService: ModulesService,
    private translationLoader: FuseTranslationLoaderService,
    public modulesService: ModulesService,
    public menuReportsService: MenuReportsService,
    public orgService: CrudOrganizationService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    const dateFrom1 = localStorage.getItem(
      ReportsComponent.DATE_FROM_KEY + '_1',
    );
    const dateTo1 = localStorage.getItem(ReportsComponent.DATE_TO_KEY + '_1');
    //
    const dateFrom2 = localStorage.getItem(
      ReportsComponent.DATE_FROM_KEY + '_2',
    );
    const dateTo2 = localStorage.getItem(ReportsComponent.DATE_TO_KEY + '_2');
    //
    const dateFrom3 = localStorage.getItem(
      ReportsComponent.DATE_FROM_KEY + '_3',
    );
    const dateTo3 = localStorage.getItem(ReportsComponent.DATE_TO_KEY + '_3');

    const dateFrom4 = localStorage.getItem(
      ReportsComponent.DATE_FROM_KEY + '_4',
    );
    const dateTo4 = localStorage.getItem(ReportsComponent.DATE_TO_KEY + '_4');
    //
    this.dateRange1 = {
      date_from: dateFrom1
        ? moment(dateFrom1).format('YYYY-MM-DD hh:mm')
        : moment().startOf('month').format('YYYY-MM-DD hh:mm'),
      date_to: dateTo1
        ? moment(dateTo1).format('YYYY-MM-DD hh:mm')
        : moment().endOf('month').format('YYYY-MM-DD hh:mm'),
    };
    this.dateRange2 = {
      date_from: dateFrom2
        ? moment(dateFrom2).format('YYYY-MM-DD hh:mm')
        : moment().startOf('month').format('YYYY-MM-DD hh:mm'),
      date_to: dateTo2
        ? moment(dateTo2).format('YYYY-MM-DD hh:mm')
        : moment().endOf('month').format('YYYY-MM-DD hh:mm'),
    };
    this.dateRange3 = {
      date_from: dateFrom3
        ? moment(dateFrom3).format('YYYY-MM-DD hh:mm')
        : moment().startOf('month').format('YYYY-MM-DD hh:mm'),
      date_to: dateTo3
        ? moment(dateTo3).format('YYYY-MM-DD hh:mm')
        : moment().endOf('month').format('YYYY-MM-DD hh:mm'),
    };

    this.dateRange4 = {
      date_from: dateFrom4
        ? moment(dateFrom4).format('YYYY-MM-DD hh:mm')
        : moment().startOf('month').format('YYYY-MM-DD hh:mm'),
      date_to: dateTo4
        ? moment(dateTo4).format('YYYY-MM-DD hh:mm')
        : moment().startOf('month').format('YYYY-MM-DD hh:mm'),
      //(dateTo4) ? moment(dateTo4).format('YYYY-MM-DD hh:mm') : moment().endOf('month').format('YYYY-MM-DD hh:mm'),
    };

    this.form = this.formBuilder.group({
      date_from1: ['', [Validators.required]],
      date_to1: ['', [Validators.required]],
      date_from2: ['', [Validators.required]],
      date_to2: ['', [Validators.required]],
      date_from3: ['', [Validators.required]],
      date_to3: ['', [Validators.required]],
      date_from4: ['', [Validators.required]],
      date_to4: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    //this.titleService.setTitle(this.translate.instant("REPORTS.TITLE"));
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public setDateFrom1(dateFrom: string): void {
    const formatedDate = moment(dateFrom).format('YYYY-MM-DD hh:mm');
    localStorage.setItem(
      ReportsComponent.DATE_FROM_KEY + '_1',
      formatedDate.toString(),
    );
    // //this.logger.info("ReportsComponent.setDateFrom() -> *** dateFrom1: " + localStorage.getItem(ReportsComponent.DATE_FROM_KEY + '_1'));
  }

  public setDateFrom2(dateFrom: string): void {
    const formatedDate = moment(dateFrom).format('YYYY-MM-DD hh:mm');
    localStorage.setItem(
      ReportsComponent.DATE_FROM_KEY + '_2',
      formatedDate.toString(),
    );
    // //this.logger.info("ReportsComponent.setDateFrom() -> *** dateFrom2: " + localStorage.getItem(ReportsComponent.DATE_FROM_KEY + '_2'));
  }

  public setDateFrom3(dateFrom: string): void {
    const formatedDate = moment(dateFrom).format('YYYY-MM-DD hh:mm');
    localStorage.setItem(
      ReportsComponent.DATE_FROM_KEY + '_3',
      formatedDate.toString(),
    );
    // //this.logger.info("ReportsComponent.setDateFrom() -> *** dateFrom3: " + localStorage.getItem(ReportsComponent.DATE_FROM_KEY + '_3'));
  }

  public setDateFrom4(dateFrom: string): void {
    const formatedDate = moment(dateFrom).format('YYYY-MM-DD hh:mm');
    localStorage.setItem(
      ReportsComponent.DATE_FROM_KEY + '_4',
      formatedDate.toString(),
    );
    // //this.logger.info("ReportsComponent.setDateFrom4() -> *** dateFrom4: " + localStorage.getItem(ReportsComponent.DATE_FROM_KEY + '_4'));
  }

  public setDateTo1(dateTo: string): void {
    const formatedDate = moment(dateTo).format('YYYY-MM-DD hh:mm');
    localStorage.setItem(
      ReportsComponent.DATE_TO_KEY + '_1',
      formatedDate.toString(),
    );
    // //this.logger.info("ReportsComponent.setDateFrom() -> *** dateTo1: " + localStorage.getItem(ReportsComponent.DATE_TO_KEY + '_1'));
  }

  public setDateTo2(dateTo: string): void {
    const formatedDate = moment(dateTo).format('YYYY-MM-DD hh:mm');
    localStorage.setItem(
      ReportsComponent.DATE_TO_KEY + '_2',
      formatedDate.toString(),
    );
    // //this.logger.info("ReportsComponent.setDateFrom() -> *** dateTo2: " + localStorage.getItem(ReportsComponent.DATE_TO_KEY + '_2'));
  }

  public setDateTo3(dateTo: string): void {
    const formatedDate = moment(dateTo).format('YYYY-MM-DD hh:mm');
    localStorage.setItem(
      ReportsComponent.DATE_TO_KEY + '_3',
      formatedDate.toString(),
    );
    // //this.logger.info("ReportsComponent.setDateFrom() -> *** dateTo3: " + localStorage.getItem(ReportsComponent.DATE_TO_KEY + '_3'));
  }

  public setDateTo4(dateTo: string): void {
    const formatedDate = moment(dateTo).format('YYYY-MM-DD hh:mm');
    localStorage.setItem(
      ReportsComponent.DATE_TO_KEY + '_4',
      formatedDate.toString(),
    );
    // //this.logger.info("ReportsComponent.setDateTo4() -> *** dateTo4: " + localStorage.getItem(ReportsComponent.DATE_TO_KEY + '_4'));
  }

  public onSelectToday1(): void {
    //this.logger.info("ReportsComponent.onSelectToday1()");
    const today: any = moment().format('YYYY-MM-DD hh:mm');
    this.dateRange1.date_from = today;
    this.dateRange1.date_to = today;
  }

  public onSelectToday2(): void {
    //this.logger.info("ReportsComponent.onSelectToday2()");
    const today: any = moment().format('YYYY-MM-DD hh:mm');
    this.dateRange2.date_from = today;
    this.dateRange2.date_to = today;
  }

  public onSelectToday4(): void {
    //this.logger.info("ReportsComponent.onSelectToday4()");
    const today: any = moment().format('YYYY-MM-DD hh:mm');
    this.dateRange4.date_from = today;
    this.dateRange4.date_to = today;
  }

  public onSelectThisWeek1(): void {
    //this.logger.info("ReportsComponent.onSelectThisWeek()");
    const dateFrom: any = moment()
      .startOf('isoWeek')
      .format('YYYY-MM-DD hh:mm');
    const dateTo: any = moment().endOf('isoWeek').format('YYYY-MM-DD hh:mm');
    this.dateRange1.date_from = dateFrom;
    this.dateRange1.date_to = dateTo;
  }

  public onSelectThisWeek2(): void {
    //this.logger.info("ReportsComponent.onSelectThisWeek2()");
    const dateFrom: any = moment()
      .startOf('isoWeek')
      .format('YYYY-MM-DD hh:mm');
    const dateTo: any = moment().endOf('isoWeek').format('YYYY-MM-DD hh:mm');
    this.dateRange2.date_from = dateFrom;
    this.dateRange2.date_to = dateTo;
  }

  public onSelectThisWeek4(): void {
    // //this.logger.info("ReportsComponent.onSelectThisWeek()");
    const dateFrom: any = moment()
      .startOf('isoWeek')
      .format('YYYY-MM-DD hh:mm');
    const dateTo: any = moment().endOf('isoWeek').format('YYYY-MM-DD hh:mm');
    this.dateRange4.date_from = dateFrom;
    this.dateRange4.date_to = dateTo;
  }

  public onSelectThisMonth1(): void {
    // //this.logger.info("ReportsComponent.onSelectThisMonth()");
    const dateFrom: any = moment().startOf('month').format('YYYY-MM-DD hh:mm');
    const dateTo: any = moment().endOf('month').format('YYYY-MM-DD hh:mm');
    this.dateRange1.date_from = dateFrom;
    this.dateRange1.date_to = dateTo;
  }

  public onSelectThisMonth2(): void {
    // //this.logger.info("ReportsComponent.onSelectThisMonth()");
    const dateFrom: any = moment().startOf('month').format('YYYY-MM-DD hh:mm');
    const dateTo: any = moment().endOf('month').format('YYYY-MM-DD hh:mm');
    this.dateRange2.date_from = dateFrom;
    this.dateRange2.date_to = dateTo;
  }

  public onSelectThisMonth4(): void {
    //this.logger.info("ReportsComponent.onSelectThisMonth()");
    const dateFrom: any = moment().startOf('month').format('YYYY-MM-DD hh:mm');
    const dateTo: any = moment().endOf('month').format('YYYY-MM-DD hh:mm');
    this.dateRange4.date_from = dateFrom;
    this.dateRange4.date_to = dateTo;
  }

  public onDateFromChange1(event: any): void {
    this.dateRange1.date_from = event;
    this.setDateFrom1(this.dateRange1.date_from);
  }

  public onDateFromChange2(event: any): void {
    this.dateRange2.date_from = event;
    this.setDateFrom2(this.dateRange2.date_from);
  }

  public onDateFromChange4(event: any): void {
    this.dateRange4.date_from = event;
    // this.dateRange4.date_to = event;
    this.setDateFrom4(this.dateRange4.date_from);
    // this.setDateTo4(this.dateRange4.date_from);
  }

  public onDateToChange1(event: any): void {
    this.dateRange1.date_to = event;
    this.setDateTo1(this.dateRange1.date_to);
  }

  public onDateToChange2(event: any): void {
    this.dateRange2.date_to = event;
    this.setDateTo2(this.dateRange2.date_to);
  }

  public onDateToChange3(event: any): void {
    const formatedDate = moment(event).format('YYYY-MM-DD hh:mm');
    this.dateRange3.date_to = formatedDate.toString();
    this.setDateTo3(this.dateRange3.date_to);
  }

  public onDateToChange4(event: any, message: string): void {
    ////this.logger.info("ReportsComponent.onDateToChange4() -> event: " + event);
    ////this.logger.info("ReportsComponent.onDateToChange4() -> message: " + message);
    this.dateRange4.date_to = event;
    this.setDateTo4(this.dateRange4.date_to);
  }

  public printPost(url: string, dateRange: any): void {
    dateRange.date_from = moment(dateRange.date_from)
      .format('YYYY-MM-DD')
      .toString();
    dateRange.date_to = moment(dateRange.date_to)
      .format('YYYY-MM-DD')
      .toString();
    this.print.postPrintHTML(url, dateRange).subscribe({
      next: (response: any) => {
        // ////this.logger.info("ReportsComponent.printPost() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error: any) => {
        this.logger.error(
          'ReportsComponent.printPost() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public downloadPost(url: string, dateRange: any = null): void {
    if (dateRange) {
      dateRange.date_from = moment(dateRange.date_from)
        .format('YYYY-MM-DD')
        .toString();
      dateRange.date_to = moment(dateRange.date_to)
        .format('YYYY-MM-DD')
        .toString();
    }

    this.fileService.postDownloadFile(url, dateRange).subscribe({
      next: (response) => {
        // ////this.logger.info("ReportsComponent.downloadPost() -> SUCCESS: " + JSON.stringify(response));
      },
      error: (error) => {
        this.logger.error(
          'downloadPost.downloadPost() -> ERROR: ' + JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  //
  public onDownloadSalesStatistic(): void {
    // ////this.logger.info("ReportsComponent.onDownloadSalesStatistic()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-sale-stats-product-by-category',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange1);
  }

  public onDownloadEventType(): void {
    // ////this.logger.info("ReportsComponent.onDownloadEventType()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-sale-stats-by-event-type',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange1);
  }

  public onDownloadClient(): void {
    // ////this.logger.info("ReportsComponent.onDownloadClient()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-sale-stats-by-client',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange1);
  }

  public onDownloadEnterprise(): void {
    // //this.logger.info("ReportsComponent.onDownloadEnterprise()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-sale-stats-by-enterprise',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange1);
  }

  public onDownloadEnterprisePreliminary(): void {
    // //this.logger.info("ReportsComponent.onDownloadEnterprisePreliminary()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-sale-stats-by-enterprise-preliminary',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange1);
  }

  public onDownloadSeller(): void {
    // //this.logger.info("ReportsComponent.onDownloadSeller()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-sale-stats-by-seller',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange1);
  }

  public onDownloadDeliveryType(): void {
    // //this.logger.info("ReportsComponent.onDownloadDeliveryType()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-sale-stats-by-delivery-type',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange1);
  }

  public onDownloadProductVelocity(): void {
    // //this.logger.info("ReportsComponent.onDownloadProductVelocity()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-sale-stats-by-product-velocity',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange1);
  }
  public onDownloadSummariesOfEvents(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-sale-stats-summaries-events',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange1);
  }
  //
  public onDownloadSalesByLedgerAccount(): void {
    // //this.logger.info("ReportsComponent.onDownloadSalesByLedgerAccount()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-accounting-sale-by-ledger-account',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange2);
  }

  public onDownloadSalesByLedgerAccountPerDay(): void {
    // //this.logger.info("ReportsComponent.onDownloadSalesByLedgerAccount()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-accounting-sale-by-ledger-account-per-day',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange2);
  }

  public onDownloadPrintAgeOfClientAccount(): void {
    // //this.logger.info("ReportsComponent.onDownloadAgeOfClientAccount()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'admin/report-admin-accounting-aging-of-client-account',
      'fr-CA',
    );
    this.printPost(url, this.dateRange3);
  }

  public onDownloadPDFAgeOfClientAccount(): void {
    // //this.logger.info("ReportsComponent.onDownloadAgeOfClientAccount()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'admin/report-admin-accounting-aging-of-client-account',
      'fr-CA',
    );
    this.printPost(url, this.dateRange3);
  }

  public onDownloadPrintAgeOfEnterpriseAccount(): void {
    // //this.logger.info("ReportsComponent.onDownloadAgeOfEnterpriseAccount()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'admin/report-admin-accounting-aging-of-enterprise-account',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange3);
  }

  public onDownloadPDFAgeOfEnterpriseAccount(): void {
    // //this.logger.info("ReportsComponent.onDownloadAgeOfEnterpriseAccount()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'admin/report-admin-accounting-aging-of-enterprise-account',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange3);
  }
  //
  public onDownloadPrintAgeAccountsReport(): void {
    // //this.logger.info("ReportsComponent.onDownloadPrintAgeAccountsReport()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'admin/report-admin-accounting-aging-accounts',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange3);
  }

  public onDownloadPDFAgeAccountsReport(): void {
    // //this.logger.info("ReportsComponent.onDownloadPDFAgeAccountsReport()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'admin/report-admin-accounting-aging-accounts',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange3);
  }

  public onDownloadCalculatedClientAndEnterpriseBalance(): void {
    // //this.logger.info("ReportsComponent.onDownloadCalculatedClientAndEnterpriseBalance()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-accounting-balance-of-client-enterprise-account',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange2);
  }

  public onDownloadInvoicesList(): void {
    // //this.logger.info("ReportsComponent.onDownloadInvoicesList()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-accounting-list-of-invoices',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange2);
  }

  public onDownloadPrintDepositTracking(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'admin/report-admin-accounting-deposite-tracking',
      'fr-CA',
    );
    this.downloadPost(url);
  }

  public onDownloadPDFDepositTracking(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'admin/report-admin-accounting-deposite-tracking',
      'fr-CA',
    );
    this.downloadPost(url);
  }

  public onDownloadReceiptsJournal(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-accounting-payment-journal',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange2);
  }

  public onDownloadInternalPayment(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-accounting-internal-payment',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange2);
  }

  public onDownloadPrintSummaryOfDeposits(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'admin/report-admin-accounting-summary-payment',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange2);
  }

  public onDownloadPdfSummaryOfDeposits(): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'admin/report-admin-accounting-summary-payment',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange2);
  }

  public onDownloadXlsAdminCasheDepositeBeforeInvoiceAt(): void {
    // //this.logger.info("ReportsComponent.onDownloadXlsAdminCasheDepositeBeforeInvoiceAt()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-cashed-deposite-before-invoice-at',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange3);
  }

  public onDownloadPrintAdminCasheDepositeAfterInvoiceAt(): void {
    // //this.logger.info("ReportsComponent.onDownloadPrintAdminCasheDepositeAfterInvoiceAt()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      'admin/report-admin-cashed-deposite-after-invoice-at',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange2);
  }

  public onDownloadPdfAdminCasheDepositeAfterInvoiceAt(): void {
    // //this.logger.info("ReportsComponent.onDownloadPdfAdminCasheDepositeAfterInvoiceAt()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      'admin/report-admin-cashed-deposite-after-invoice-at',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange2);
  }

  public onDownloadAccountingContacts(): void {
    // //this.logger.info("ReportsComponent.onDownloadAccountingContacts()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_CSV,
      'admin/report-admin-accounting-system-contacts',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange1);
  }

  public onDownloadAccountingSystemInvoices(): void {
    // //this.logger.info("ReportsComponent.onDownloadAccountingSystemInvoices()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_CSV,
      'admin/report-admin-accounting-system-invoices',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange1);
  }

  public onDownloadAccountingSystemLogs(): void {
    // //this.logger.info("ReportsComponent.onDownloadAccountingSystemLogs()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_CSV,
      'admin/report-admin-accounting-ledger-account',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange1);
  }

  public onShowREPORT_ADMIN_SALE_STATS_BY_ENTERPRISE_PRELIMINARYInfo(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useHTML = true;
    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('REPORTS_HELP.TITLE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant(
        'REPORTS_HELP.REPORT_ADMIN_SALE_STATS_BY_ENTERPRISE_PRELIMINARY',
      );

    this.confirmDialogRef
      .afterClosed()
      .subscribe({ next: (result: any) => {} });
  }

  public onShowREPORT_ADMIN_SALE_STATS_BY_ENTERPRISEInfo(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useHTML = true;
    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('REPORTS_HELP.TITLE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant(
        'REPORTS_HELP.REPORT_ADMIN_SALE_STATS_BY_ENTERPRISE',
      );

    this.confirmDialogRef
      .afterClosed()
      .subscribe({ next: (result: any) => {} });
  }

  public onShowREPORT_ADMIN_SALE_STATS_BY_DELIVERY_TYPEInfo(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useHTML = true;
    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('REPORTS_HELP.TITLE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant(
        'REPORTS_HELP.REPORT_ADMIN_SALE_STATS_BY_DELIVERY_TYPE',
      );

    this.confirmDialogRef
      .afterClosed()
      .subscribe({ next: (result: any) => {} });
  }

  public onShowREPORTAgeOfAccountsInfo(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useHTML = true;
    this.confirmDialogRef.componentInstance.useCancel = false;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('REPORTS_HELP.TITLE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('REPORTS_HELP.REPORT_AGE_ACCOUNTS');

    this.confirmDialogRef
      .afterClosed()
      .subscribe({ next: (result: any) => {} });
  }

  public onPrintEmployeesAssigments(
    section: string,
    paperFormat: string,
  ): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PRINT,
      section,
      paperFormat + '/fr-CA',
    );
    // //this.logger.info("ReportsComponent.onPrintEmployeesAssigments() -> url: " + url);
    this.printPost(url, this.dateRange4);
  }

  public onDownloadPDFEmployeesAssigments(
    section: string,
    paperFormat: string,
  ): void {
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_PDF,
      section,
      paperFormat + '/fr-CA',
    );
    // //this.logger.info("ReportsComponent.onDownloadPDFEmployeesAssigments() -> url: " + url);
    this.downloadPost(url, this.dateRange4);
  }

  // payment data Nethris report xlsx JR
  //
  public onDownloadPaymentDataNethris(): void {
    //this.logger.info("ReportsComponent.onDownloadPaymentDataNethris()");
    const url: string = this.api.createReportURL(
      ApiRoutes.REPORT_XLS,
      'admin/report-admin-payment-data-nethris',
      'fr-CA',
    );
    this.downloadPost(url, this.dateRange4);
  }

  public onTestHTMLtoPDF(): void {
    // --o SIMPLE TEXT ... WORK
    // const doc = new jsPDF();
    // doc.text("Hello world!", 10, 10);
    // doc.save("a4.pdf");
    // if (!this.pdfTable) {
    //     this.logger.error(
    //         "ReportsComponent.onTestHTMLtoPDF() -> ERROR: this.pdfTable is undefined "
    //     );
    //     return;
    // } else {
    //     //this.logger.info(
    //         "ReportsComponent.onTestHTMLtoPDF() -> this.pdfTable:  " +
    //             this.pdfTable
    //     );
    // }
    // const doc = new jspdf.jsPDF();
    // const doc = new jsPDF();
    // const doc = new jsPDF('p','pt','a4');
    // const specialElementHandlers = {
    //     "#editor": function (element, renderer) {
    //         return true;
    //     },
    // };
    // const pdfTable = this.pdfTable.nativeElement;
    // doc.html(pdfTable.innerHTML);
    // // doc.fromHTML(pdfTable.innerHTML, 15, 15, {
    // //   width: 190,
    // //   'elementHandlers': specialElementHandlers
    // // });
    // doc.save("tableToPdf.pdf");
  }
}
