<!--<div id="support" class="page-layout simple fullwidth" fxLayout="column" fusePerfectScrollbar>-->
<div id="release-note" class="page-layout simple fullwidth inner-scroll" fxLayout="column" style="padding-bottom: 60px">
  <!-- HEADER -->
  <div class="gt2-theme">
    <div class="header accent pl-24 pr-24 h-80 mat-elevation-z1" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1">{{ "RELEASENOTE.TITLE" | translate }}</div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <iframe
    class="px-24"
    width="100%"
    height="100%"
    *ngIf="translate.currentLang === 'en-CA'"
    src="https://docs.google.com/document/d/e/2PACX-1vSgfsrYvD35H0JuM0M8dygJJRk0cnrRj36yMjXNQTbDnAqHjqBFOY7VD2oUsGAQieanqdPY8LRFeYmh/pub?embedded=true"
  >
  </iframe>

  <iframe
    class="px-24"
    width="100%"
    height="100%"
    *ngIf="translate.currentLang === 'fr-CA'"
    src="https://docs.google.com/document/d/e/2PACX-1vTPxTCVDChLhyIye6p88Ius2vM4r7qjZqEhfF_zNBmJxSBIX3cm8s2bUs4Jeyu-5onp0Qy5mJdfR-MO/pub?embedded=true"
  >
  </iframe>
</div>
