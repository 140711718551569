export const locale = {
  lang: "en-CA",
  data: {
    ROOM_MANAGEMENT: {
      TITLE: "Room management",
      CREATE_TITLE: "New",
      CREATE_TITLE_FROM_RESERVATION: "Create an event for this booking",
      ORDER_TYPE: "Order type",
      ROOM_NAME: "Room name",
      PLACE_NAME: "Place name",
      NOTE: "Note: There are no internal places",
      EDIT_RESERVATION_TITLE: "Modify this booking",
      DELETE_RESERVATION_TITLE: "Delete this reservation",
    },
  },
};
