import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GT2FormPageAbstract } from '../../content/abstract/GT2FormPageAbstract';
import { RolesService } from '../../services/roles.service';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { CreateEventComponent } from '../dialogs/create-event/create-event.component';
import { MatIconModule } from '@angular/material/icon';
import { NgPipesModule } from 'ngx-pipes';
import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'app-create-order-buttons',
  templateUrl: './create-order-buttons.component.html',
  styleUrls: ['./create-order-buttons.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    TranslateModule,
    NgxPermissionsModule,
    MatButtonModule,
    MatInputModule,
    MatBadgeModule,
    NgPipesModule,
    MatIconModule,
  ],
})
export class CreateOrderButtonsComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() date?: Date;
  @Input() clientUUID?: string;
  @Output() clientUUIDChange = new EventEmitter<any>();
  //
  declare dialogRef: any;
  declare errorDialogRef: any;

  constructor(
    public translate: TranslateService,
    public router: Router,
    public dialog: MatDialog,
    public rolesService: RolesService,
  ) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onNewProposal(): void {
    if (this.clientUUID) {
      this.createNewProposalForClient();
    } else {
      this.createNewProposal();
    }
  }
  public createNewProposalForClient(): void {
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '45%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
      data: {
        subType: CreateEventComponent.PROPOSAL_BY_CLIENT_SUBTYPE,
        event_date: this.date ? this.date : new Date(),
        client_uuid: this.clientUUID,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }

      this.dialogRef = null;
    });
  }
  public createNewProposal(): void {
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '45%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        subType: CreateEventComponent.PROPOSAL_SUBTYPE,
        event_date: this.date ? this.date : new Date(),
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }

      this.dialogRef = null;
    });
  }

  public onCreateOrder(): void {
    if (this.clientUUID) {
      this.createOrderForClient();
    } else {
      this.createOrder();
    }
  }
  public createOrderForClient(): void {
    const curDate = this.date ? this.date : new Date();
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '45%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        subType: CreateEventComponent.ORDER_BY_CLIENT_SUBTYPE,
        event_date: curDate,
        client_uuid: this.clientUUID,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }

      this.dialogRef = null;
    });
  }
  public createOrder(): void {
    const curDate = this.date ? this.date : new Date();
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '45%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        subType: CreateEventComponent.ORDER_SUBTYPE,
        event_date: curDate,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }

      this.dialogRef = null;
    });
  }
}
