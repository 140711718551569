import { GroupNavigationInterface } from "./GroupNavigationInterface";
import { AppRoutes } from "../../gt2/const/AppRoutes";

export class GodGroupNavigationModel implements GroupNavigationInterface {
  id: string = "godMenu";
  title: string = "God";
  translate: string = "NAV.GOD";
  type: string = "collapsable";
  children: any[] = [
    {
      id: "godReports",
      title: "Rapports",
      translate: "NAV.GOD_REPORTS.TITLE",
      type: "item",
      icon: "picture_as_pdf",
      url: "/god-reports",
    },
    {
      id: "loginLocalStorage",
      title: "Local Storage",
      translate: "NAV.GOD_LOCAL_STORAGE.TITLE",
      type: "item",
      icon: "storage",
      url: "/god-local-storage",
    },
    {
      id: "godEmails",
      title: "Emails",
      translate: "NAV.GOD_EMAILS.TITLE",
      type: "item",
      icon: "email",
      url: "/god-emails",
    },
    // TODO: delete those pages below from the app
    {
      id: "kb",
      title: "Knowledge Base",
      translate: "NAV.KB.TITLE",
      type: "item",
      // 'icon' : 'help',
      url: AppRoutes.KB,
    },
    {
      id: "faq",
      title: "Foire aux Questions",
      translate: "NAV.FAQ.TITLE",
      type: "item",
      // 'icon' : 'help',
      url: AppRoutes.FAQ,
    },

    // {
    //     'id': 'impersonation',
    //     'title': 'Impersonation',
    //     'translate': 'NAV.GOD_IMPERSONATION.TITLE',
    //     'type': 'item',
    //     'icon': 'swap_calls',
    //     'url': '/god-impersonation'
    // }
  ];
}
