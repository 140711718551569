import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { LedgerAccountService } from '../../../api/ledger-account.service';
import { TaxesService } from '../../../api/taxes.service';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Gt2SelectComponent } from '../../../components/gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    TranslateModule,
    Gt2SelectComponent,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
})
export class TaxesComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  model?: any[];
  form: UntypedFormGroup;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
    public ledgerAccountService: LedgerAccountService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public taxesService: TaxesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      // tax_number: ['', [Validators.maxLength(Settings.inputMaxCharLong)]],
    });

    this.loadData();
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public loadData(): void {
    this.taxesService.getTaxesItems().subscribe(
      (response) => {
        // //this.logger.info("TaxesComponent.loadData() -> SUCCESS: " + JSON.stringify(response));
        this.model = response.data;
      },
      (error) => {
        this.logger.error(
          'TaxesComponent.loadData() -> ERROR: ' + JSON.stringify(error),
        );
      },
    );
  }

  public onTaxUpdate(tax: any): void {
    // //this.logger.info("TaxesComponent.onTaxUpdate() ->tax: " + JSON.stringify(tax) );

    this.taxesService.updateTaxItem(tax.pref.uuid, tax).subscribe(
      (response) => {
        // //this.logger.info("TaxesComponent.onTaxUpdate() -> SUCCESS: " + JSON.stringify(response));
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.SAVE_SUCCESS'),
        );
      },
      (error) => {
        this.logger.error(
          'TaxesComponent.onTaxUpdate() -> ERROR: ' + JSON.stringify(error),
        );
        this.toastr.error('', this.translate.instant('GENERIC.ERRORS.GENERIC'));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    );
  }

  public onTaxNumberChange(tax: any) {
    //this.logger.info("TaxesComponent.onTaxNumberChange() ->tax: " + JSON.stringify(tax));
  }

  public isInputValid(tax: any): boolean {
    // //this.logger.info("TaxesComponent.isInputValid() ->tax: " + JSON.stringify(tax) );
    const num: string = tax.pref.tax_number;
    if (num && num.length < 5) {
      return true;
    }

    return false;
  }

  public onLedgerAccountSelected(event: any, tax: any): void {
    //this.logger.info("TaxesComponent.onLedgerAccountSelected() -> event: " + JSON.stringify(event));
    //this.logger.info("TaxesComponent.onLedgerAccountSelected() -> tax.pref.tax_number: " + JSON.stringify(tax.pref.tax_number));
    // //this.logger.info("TaxesComponent.onLedgerAccountSelected() -> tax: " + JSON.stringify(tax));
    tax.pref.ledger_account = event;
  }
}
