<div style="max-height: 100%; overflow: auto">
  <h1 class="h1-title" matDialogTitle>
    {{ "ORDERS.EDIT_MATERIAL" | translate }}
  </h1>
  <div *ngIf="material" [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="30">
        <!-- INTERNAL_NAME_FR -->
        <mat-form-field class="item-width">
          <mat-label>{{ "ORDERS.INTERNAL_NAME_FR" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="name_internal_fr_CA"
            type="text"
            [(ngModel)]="material.name_internal_local.name_internal_fr_CA"
            required
          />
          <mat-error *ngIf="form.controls.name_internal_fr_CA.invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <!-- INTERNAL_NAME_EN -->
        <mat-form-field class="item-width">
          <mat-label>{{ "ORDERS.INTERNAL_NAME_EN" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="name_internal_en_CA"
            type="text"
            [(ngModel)]="material.name_internal_local.name_internal_en_CA"
            required
          />
          <mat-error *ngIf="form.controls.name_internal_en_CA.invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <!-- EXTERNAL_NAME_FR -->
        <mat-form-field class="item-width">
          <mat-label>{{ "ORDERS.EXTERNAL_NAME_FR" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="name_fr_CA"
            type="text"
            [(ngModel)]="material.name_local.name_fr_CA"
            required
          />
          <mat-error *ngIf="form.controls.name_fr_CA.invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="item-width">
          <mat-label>{{ "ORDERS.EXTERNAL_NAME_EN" | translate }}</mat-label>
          <input
            matInput
            fxFlex="100%"
            formControlName="name"
            type="text"
            [(ngModel)]="material.name_local.name_en_CA"
            required
          />
          <mat-error *ngIf="form.controls.name.invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="item-width">
          <mat-label>{{ "GENERIC.PRICE" | translate }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="unit_price"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="material.unit_price"
          />
          <mat-error *ngIf="form.controls.unit_price.invalid"
            >{{ "GENERIC.INPUT_NUMBER" | translate }}
            {{
              "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL" | translate
            }}</mat-error
          >
        </mat-form-field>

        <mat-form-field class="item-width">
          <mat-label>{{ "GENERIC.QUANTITY" | translate }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="quantity"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="material.qty"
          />
          <mat-error *ngIf="form.controls.quantity.invalid"
            >{{ "GENERIC.INPUT_NUMBER_ABOVE_ZERO" | translate }}
            {{
              "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL" | translate
            }}</mat-error
          >
        </mat-form-field>

        <!-- material category -->
        <div class="item-width">
          <app-gt2-select
            [label]="'GENERIC.MATERIAL_CATEGORY' | translate"
            [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
            [nullableUseNone]="false"
            [placeHolder]="'GENERIC.MATERIAL_CATEGORY_SELECT' | translate"
            [selectedModel]="materialCategory"
            (changeEvent)="onMaterialCategoryChange($event)"
            [service]="materialCategoryService"
            class=""
            fxFlex="100%"
          >
          </app-gt2-select>
        </div>
        <!-- supplier name if exist -->
        <div class="item-width">
          <app-gt2-select
            [service]="materialProviderService"
            [nullableUseNone]="false"
            [placeHolder]="'ORDERS.MATERIAL_PROVIDER' | translate"
            [label]="'Provider'"
            [selectedModel]="material.material_provider"
            (changeEvent)="onMaterialProviderSelected($event)"
            [noResultsLabel]="
              translate.instant('ORDERS.NO_MATERIAL_PROVIDER_RESULTS')
            "
            fxFlex="100%"
            class=""
          ></app-gt2-select>
        </div>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex="40"
        fxLayoutGap="20px"
      >
        <!-- material description FR -->
        <div class="item-width">
          <div class="title">
            {{ "ORDERS.PRODUCT_DESCRIPTION_FR" | translate }}
          </div>
          <p-editor
            #prodNoteEditor
            formControlName="description_fr_CA"
            [(ngModel)]="material.description_local.description_fr_CA"
            [style]="{ height: '160px', width: '100%' }"
          >
            <div class="">
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
              </span>

              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>

              <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-indent" value="-1"></button>
                <button class="ql-indent" value="+1"></button>
              </span>
            </div>
          </p-editor>
          <mat-error
            *ngIf="form.controls.description_fr_CA.invalid"
            class="p-4 pr-8"
            fxLayout="row"
            fxLayoutAlign="end center"
          >
            {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
          </mat-error>
        </div>

        <!-- material description EN -->
        <div class="item-width">
          <div class="title">
            {{ "ORDERS.PRODUCT_DESCRIPTION_EN" | translate }}
          </div>
          <p-editor
            #prodNoteEditor
            formControlName="description_en_CA"
            [(ngModel)]="material.description_local.description_en_CA"
            [style]="{ height: '160px', width: '100%' }"
          >
            <div class="">
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
              </span>

              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>

              <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-indent" value="-1"></button>
                <button class="ql-indent" value="+1"></button>
              </span>
            </div>
          </p-editor>
          <mat-error
            *ngIf="form.controls.description_en_CA.invalid"
            class="p-4 pr-8"
            fxLayout="row"
            fxLayoutAlign="end center"
          >
            {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
          </mat-error>
        </div>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex="40"
        fxLayoutGap="20px"
      >
        <!-- material logistic note -->
        <div class="item-width">
          <div class="title">{{ "ORDERS.NOTE_LOGISTIC" | translate }}</div>
          <p-editor
            #prodNoteEditor
            formControlName="logistic_notes"
            [(ngModel)]="material.logistic_notes"
            [style]="{ height: '160px', width: '100%' }"
          >
            <div class="">
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
              </span>

              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>

              <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-indent" value="-1"></button>
                <button class="ql-indent" value="+1"></button>
              </span>
            </div>
          </p-editor>
          <mat-error
            *ngIf="form.controls.logistic_notes.invalid"
            class="p-4 pr-8"
            fxLayout="row"
            fxLayoutAlign="end center"
          >
            {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
          </mat-error>
        </div>

        <!-- material sale note sale_note -->
        <div class="item-width">
          <div class="title">{{ "ORDERS.NOTE_SALE_MATERIAL" | translate }}</div>
          <p-editor
            #prodNoteEditor
            formControlName="sale_note"
            [(ngModel)]="material.sale_note"
            [style]="{ height: '160px', width: '100%' }"
          >
            <div class="">
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
              </span>

              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>

              <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-indent" value="-1"></button>
                <button class="ql-indent" value="+1"></button>
              </span>
            </div>
          </p-editor>
          <mat-error
            *ngIf="form.controls.sale_note.invalid"
            class="p-4 pr-8"
            fxLayout="row"
            fxLayoutAlign="end center"
          >
            {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
          </mat-error>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    [formGroup]="form"
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      color="accent"
      class="mr-16"
      type="submit"
      [disabled]="!form.valid || loading"
      (click)="onSaveMaterial()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
