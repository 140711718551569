<div style="min-width: 350px" class="">
  <ng-template #merchant_idHintTemplate>
    <p>{{ "SHOPS.MONERIS_MERCHANT_ID_HINT" | translate }}</p>
  </ng-template>

  <ng-template #merchant_keyHintTemplate>
    <p>{{ "SHOPS.MONERIS_MERCHANT_KEY_HINT" | translate }}</p>
  </ng-template>

  <ng-template #profile_idHintTemplate>
    <p>{{ "SHOPS.MONERIS_PROFILE_ID_HINT" | translate }}</p>
  </ng-template>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">
        {{ "SHOPS.MONERIS_MERCHANT_ID" | translate }}
        <button
          class=""
          style="height: 40px"
          mat-icon-button
          matTooltip="merchant_idHintTemplate"
          content-type="template"
          type="button"
        >
          <mat-icon class="s-20" color="">help</mat-icon>
        </button>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="info text-truncate">{{ monerisModel.merchant_id }}</div>
      </div>
      <div *ngIf="!monerisModel.merchant_id" class="info empty-value"></div>
    </div>

    <div
      *ngIf="editMode"
      [formGroup]="formMoneris"
      class="info-line"
      fxFlex="100%"
    >
      <!-- <div class="title">{{ 'SHOPS.MONERIS_MERCHANT_ID' | translate }}</div> -->
      <mat-form-field class="theme-selector" style="width: 90%">
        <mat-label>{{ "SHOPS.MONERIS_MERCHANT_ID" | translate }}</mat-label>
        <input
          [(ngModel)]="monerisModel.merchant_id"
          autocomplete="nope"
          matInput
          formControlName="merchant_id"
          placeholder="{{ 'SHOPS.MONERIS_MERCHANT_ID' | translate }}"
          type="text"
        />
      </mat-form-field>
      <button
        class=""
        mat-icon-button
        matTooltip="merchant_idHintTemplate"
        content-type="template"
        type="button"
      >
        <mat-icon class="s-20" color="">help</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">
        {{ "SHOPS.MONERIS_MERCHANT_KEY" | translate }}
        <button
          class=""
          mat-icon-button
          matTooltip="merchant_keyHintTemplate"
          content-type="template"
          type="button"
        >
          <mat-icon class="s-20" color="">help</mat-icon>
        </button>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="info text-truncate">{{ monerisModel.merchant_key }}</div>
      </div>
      <div *ngIf="!monerisModel.merchant_key" class="info empty-value"></div>
    </div>

    <div
      *ngIf="editMode"
      [formGroup]="formMoneris"
      class="info-line"
      fxFlex="100%"
    >
      <!-- <div class="title">{{ 'SHOPS.MONERIS_MERCHANT_KEY' | translate }}</div> -->
      <mat-form-field class="theme-selector" style="width: 90%">
        <mat-label>{{ "SHOPS.MONERIS_MERCHANT_KEY" | translate }}</mat-label>
        <input
          [(ngModel)]="monerisModel.merchant_key"
          autocomplete="nope"
          matInput
          formControlName="merchant_key"
          placeholder="{{ 'SHOPS.MONERIS_MERCHANT_KEY' | translate }}"
          type="text"
        />
      </mat-form-field>
      <button
        class=""
        mat-icon-button
        matTooltip="merchant_keyHintTemplate"
        content-type="template"
        type="button"
      >
        <mat-icon class="s-20" color="">help</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="100%">
      <div class="title">
        {{ "SHOPS.MONERIS_TEST_MODE" | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="monerisModel.test_mode" class="info text-truncate">
          {{ "GENERIC.YES" | translate }}
        </div>
        <div
          *ngIf="monerisModel.test_mode === false"
          class="info text-truncate"
        >
          {{ "GENERIC.NO" | translate }}
        </div>
      </div>
      <div
        *ngIf="monerisModel.test_mode === null"
        class="info empty-value"
      ></div>
    </div>

    <div *ngIf="editMode" class="info-line" fxFlex="100%">
      <div class="title">{{ "SHOPS.MONERIS_TEST_MODE" | translate }}</div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div style="margin-right: 8px">
          {{ "GENERIC.OFF" | translate | uppercase }}
        </div>
        <mat-slide-toggle
          class="mat-accent"
          [(ngModel)]="monerisModel.test_mode"
        >
        </mat-slide-toggle>
        <div style="margin-left: 8px">
          {{ "GENERIC.ON" | translate | uppercase }}
        </div>
      </div>

      <!-- <button class="" mat-icon-button 
                    matTooltip="merchant_keyHintTemplate" 
                    content-type="template"
                    type="button">
                    <mat-icon class="s-20" color="">help</mat-icon>
            </button> -->
    </div>
  </div>

  <!-- PROFILES -->
  <div class="profile-box info-box general">
    <header
      class="accent"
      fx-fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div class="sub-title">
        {{ "SHOPS.MONERIS_MERCHANT_PROFILES" | translate }}
      </div>
      <!-- <div (click)=onAddDeliveryBracket()>[ + ]</div> -->
      <div
        *ngIf="editMode"
        class="gt2-light-green"
        fxLayout="row"
        fxLayoutAlign="center center"
        style="width: 60px; height: 40px"
      >
        <button
          (click)="onAddMonerisProfile()"
          class=""
          mat-icon-button
          matTooltip="{{ 'SHOPS.MONERIS_MERCHANT_ADD_PROFILES' | translate }}"
          type="button"
        >
          <mat-icon class="" color="">add</mat-icon>
        </button>
      </div>
    </header>

    <div class="sub-content bracket-list" fxLayout="column">
      <div *ngFor="let item of monerisModel.profiles; let i = index">
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="start start"
          class=""
        >
          <div *ngIf="!editMode" class="info-line" fxFlex="50%">
            <div class="title">
              {{ "SHOPS.MONERIS_PROFILE_URL" | translate }}
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="info text-truncate">{{ item.url }}</div>
            </div>
            <div *ngIf="item.url === null" class="info empty-value"></div>
          </div>

          <div *ngIf="editMode" class="info-line" fxFlex="50%">
            <mat-form-field
              class="form-input-container bracket-item-input"
              fxFlex="100%"
              style="width: 100%"
            >
              <mat-label>{{
                "SHOPS.MONERIS_PROFILE_URL" | translate
              }}</mat-label>
              <input
                [(ngModel)]="item.url"
                autocomplete="nope"
                matInput
                placeholder="{{ 'SHOPS.MONERIS_PROFILE_URL' | translate }}"
                type="text"
              />
            </mat-form-field>
          </div>

          <div *ngIf="!editMode" class="info-line" fxFlex="50%">
            <div class="title">
              {{ "SHOPS.MONERIS_PROFILE_ID" | translate }}
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="info text-truncate">{{ item.profile_id }}</div>
              <!-- <button class="" mat-icon-button 
                                matTooltip="profile_idHintTemplate" 
                                content-type="template"
                                type="button">
                                <mat-icon class="s-20" color="">help</mat-icon>
                            </button> -->
            </div>
            <div
              *ngIf="item.profile_id === null"
              class="info empty-value"
            ></div>
          </div>

          <div *ngIf="editMode" class="info-line" fxLayout="row" fxFlex="50%">
            <mat-form-field
              class="form-input-container bracket-item-input"
              fxFlex="100%"
              style="width: 100%"
            >
              <mat-label>{{
                "SHOPS.MONERIS_PROFILE_ID" | translate
              }}</mat-label>
              <input
                [(ngModel)]="item.profile_id"
                autocomplete="nope"
                matInput
                placeholder="{{ 'SHOPS.MONERIS_PROFILE_ID' | translate }}"
                type="text"
              />
            </mat-form-field>
          </div>

          <button
            *ngIf="editMode"
            mat-icon-button
            matTooltip="profile_idHintTemplate"
            content-type="template"
            type="button"
          >
            <mat-icon class="s-20" color="">help</mat-icon>
          </button>

          <button
            mat-icon-button
            *ngIf="editMode"
            class=""
            (click)="onRemoveMonerisProfile(item, i)"
            aria-label="Palette"
          >
            <mat-icon class="s-20">delete_forever</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start">
    <button
      (click)="onSaveMonerisInfo()"
      color="accent"
      class="save-btn"
      [disabled]="formMoneris.invalid"
      mat-raised-button
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
  </div>
</div>
