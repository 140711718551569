export const locale = {
  lang: "en-CA",
  data: {
    CREATE_GUEST: {
      TITLE: "New guest type",
      EDIT_TITLE: "Edit guest",
      SELECT_GUEST: "Define or select a guest type",
      // 'FOR_CLIENT_ONLY': 'For clients only',
    },
  },
};
