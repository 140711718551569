export class ReportsEnum {
  //---o V2
  static REPORT2_DATA_PRODUCT = "REPORT2_DATA_PRODUCT";
  static REPORT2_DATA_PRODUCTS_LIST_XLSX = "REPORT2_DATA_PRODUCTS_LIST_XLSX";
  static REPORT2_DATA_MATERIAL_LIST_XLSX = "REPORT2_DATA_MATERIAL_LIST_XLSX";
  static REPORT2_CLIENT_INVOICE_STACK = "REPORT2_CLIENT_INVOICE_STACK";

  //---o V1
  //
  static REPORT_DATA_PRODUCTS_LIST = "REPORT_DATA_PRODUCTS_LIST";
  static REPORT_DATA_MATERIALS_LIST = "REPORT_DATA_MATERIALS_LIST";
  static REPORT_DATA_CLIENTS_LIST = "REPORT_DATA_CLIENTS_LIST";
  static REPORT_DATA_ENTERPRISES_LIST = "REPORT_DATA_ENTERPRISES_LIST";
  static REPORT_DATA_EMPLOYEES_LIST = "REPORT_DATA_EMPLOYEES_LIST";
  static REPORT_DATA_PLACES_LIST = "REPORT_DATA_PLACES_LIST";
  //
  static REPORT_CLIENT_ORDER = "REPORT_CLIENT_ORDER";
  static REPORT_CLIENT_EVENT_RECEIPT = "REPORT_CLIENT_EVENT_RECEIPT";
  static REPORT_CLIENT_PROPOSAL = "REPORT_CLIENT_PROPOSAL";
  static REPORT_CLIENT_ORDER_PURCHASE_ORDER_CLIENT_COPY = "REPORT_CLIENT_ORDER_PURCHASE_ORDER_CLIENT_COPY";
  static REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION = "REPORT_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION";
  static REPORT_CLIENT_INVOICE = "REPORT_CLIENT_INVOICE";
  static REPORT_CLIENT_STATEMENT_CLIENT = "REPORT_CLIENT_STATEMENT_CLIENT";
  static REPORT_CLIENT_STATEMENT_ENTERPRISE = "REPORT_CLIENT_STATEMENT_ENTERPRISE";
  //
  static REPORT_MNGT_ORDER_PURCHASE_MATERIAL = "REPORT_MNGT_ORDER_PURCHASE_MATERIAL";
  static REPORT_MNGT_ORDER_PURCHASE_STAFF = "REPORT_MNGT_ORDER_PURCHASE_STAFF";
  static REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN = "REPORT_MNGT_ORDER_PURCHASE_ORDER_KITCHEN";
  static REPORT_MNGT_PROD_LIST_BY_PROD_ZONE = "REPORT_MNGT_PROD_LIST_BY_PROD_ZONE";

  static REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_ALLERGIES = "REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_ALLERGIES";

  static REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_SIMPLIFIED = "REPORT_MNGT_PROD_LIST_BY_PROD_ZONE_SIMPLIFIED";

  static REPORT_MNGT_FORECAST_PRODUCTION_LIST_BY_PROD_ZONE = "REPORT_MNGT_FORECAST_PRODUCTION_LIST_BY_PROD_ZONE";

  static REPORT_MNGT_PRODUCT_AND_DRINK_LIST = "REPORT_MNGT_PRODUCT_AND_DRINK_LIST";
  static REPORT_MNGT_TAKEOUT = "REPORT_MNGT_TAKEOUT";
  static REPORT_MNGT_DELIVERY_A = "REPORT_MNGT_DELIVERY_A";
  static REPORT_MNGT_DELIVERY_B = "REPORT_MNGT_DELIVERY_B";
  static REPORT_MNGT_MATERIAL_LIST = "REPORT_MNGT_MATERIAL_LIST";
  static REPORT_MNGT_EVENT_BY_SELLER = "REPORT_MNGT_EVENT_BY_SELLER";
  static REPORT_MNGT_NEW_CLIENT = "REPORT_MNGT_NEW_CLIENT";
  static REPORT_MNGT_EVENT_BY_STATUS = "REPORT_MNGT_EVENT_BY_STATUS";
  //
  static REPORT_ADMIN_SALE_STATS_PRODUCT_BY_CATEGORY = "REPORT_ADMIN_SALE_STATS_PRODUCT_BY_CATEGORY";
  static REPORT_ADMIN_SALE_STATS_BY_EVENT_TYPE = "REPORT_ADMIN_SALE_STATS_BY_EVENT_TYPE";
  static REPORT_ADMIN_SALE_STATS_BY_CLIENT = "REPORT_ADMIN_SALE_STATS_BY_CLIENT";
  static REPORT_ADMIN_SALE_STATS_BY_ENTERPRISE = "REPORT_ADMIN_SALE_STATS_BY_ENTERPRISE";
  static REPORT_ADMIN_SALE_STATS_BY_SELLER = "REPORT_ADMIN_SALE_STATS_BY_SELLER";
  static REPORT_ADMIN_SALE_STATS_BY_DELIVERY_TYPE = "REPORT_ADMIN_SALE_STATS_BY_DELIVERY_TYPE";
  static REPORT_ADMIN_SALE_STATS_BY_PRODUCT_VELOCITY = "REPORT_ADMIN_SALE_STATS_BY_PRODUCT_VELOCITY";
  static REPORT_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT = "REPORT_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT";
  static REPORT_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_PER_DAY = "REPORT_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_PER_DAY";
  static REPORT_ADMIN_ACCOUNTING_AGING_OF_CLIENT_ACCOUNT = "REPORT_ADMIN_ACCOUNTING_AGING_OF_CLIENT_ACCOUNT";
  static REPORT_ADMIN_ACCOUNTING_AGING_OF_ENTERPRISE_ACCOUNT = "REPORT_ADMIN_ACCOUNTING_AGING_OF_ENTERPRISE_ACCOUNT";
  static REPORT_ADMIN_ACCOUNTING_BALANCE_OF_CLIENT_ENTERPRISE_ACCOUNT = "REPORT_ADMIN_ACCOUNTING_BALANCE_OF_CLIENT_ENTERPRISE_ACCOUNT";
  static REPORT_ADMIN_ACCOUNTING_LIST_OF_INVOICES = "REPORT_ADMIN_ACCOUNTING_LIST_OF_INVOICES";
  static REPORT_ADMIN_ACCOUNTING_DEPOSITE_TRACKING = "REPORT_ADMIN_ACCOUNTING_DEPOSITE_TRACKING";
  static REPORT_ADMIN_ACCOUNTING_PAYMENT_JOURNAL = "REPORT_ADMIN_ACCOUNTING_PAYMENT_JOURNAL";
  static REPORT_ADMIN_ACCOUNTING_SUMMARY_PAYMENT = "REPORT_ADMIN_ACCOUNTING_SUMMARY_PAYMENT";
  static REPORT_ADMIN_ACCOUNTING_SYSTEM_CONTACTS = "REPORT_ADMIN_ACCOUNTING_SYSTEM_CONTACTS";
  static REPORT_ADMIN_ACCOUNTING_SYSTEM_INVOICES = "REPORT_ADMIN_ACCOUNTING_SYSTEM_INVOICES";
  static REPORT_ADMIN_ACCOUNTING_LEDGER_ACCOUNT = "REPORT_ADMIN_ACCOUNTING_LEDGER_ACCOUNT";
  static REPORT_ADMIN_CASHED_DEPOSIT_BEFORE_INVOICE_AT = "REPORT_ADMIN_CASHED_DEPOSIT_BEFORE_INVOICE_AT";
  static REPORT_ADMIN_CASHED_DEPOSIT_AFTER_INVOICE_AT = "REPORT_ADMIN_CASHED_DEPOSIT_AFTER_INVOICE_AT";
  //
  static REPORT_ASSIGNMENT_ATTENDANCE_RECORD = "REPORT_ASSIGNMENT_ATTENDANCE_RECORD";
  static REPORT_ASSIGNMENT_COST = "REPORT_ASSIGNMENT_COST";
  static REPORT_ASSIGNMENT_SALES = "REPORT_ASSIGNMENT_SALES";
  static REPORT_ASSIGNMENT_SCHEDULE_PER_DATE = "REPORT_ASSIGNMENT_SCHEDULE_PER_DATE";
  static REPORT_ASSIGNMENT_SCHEDULE_PER_JOB_FUNCTION = "REPORT_ASSIGNMENT_SCHEDULE_PER_JOB_FUNCTION";
  static REPORT_MNGT_ASSIGNMENT_PAY_STAFF = "REPORT_MNGT_ASSIGNMENT_PAY_STAFF";

  static REPORT2_MNGT_EVENT_LIST = "REPORT2_MNGT_EVENT_LIST";
  static REPORT2_MNGT_EVENT_LIST_VIC = "REPORT2_MNGT_EVENT_LIST_VIC";
  static REPORT2_MNGT_EVENT_LIST_UQAM = "REPORT2_MNGT_EVENT_LIST_UQAM";

  static REPORT2_MGNT_ORDER_PLATE_BUFFET_LABEL_5963 = "REPORT2_MGNT_ORDER_PLATE_BUFFET_LABEL_5963";

  static REPORT2_MNGT_ORDER_BOX_LABEL_55164 = "REPORT2_MNGT_ORDER_BOX_LABEL_55164";

  static REPORT2_MNGT_ORDERS_BY_DAY = "REPORT2_MNGT_ORDERS_BY_DAY";
  static REPORT2_MNGT_CONTRACTS_BY_DAY_LOL = "REPORT2_MNGT_CONTRACTS_BY_DAY_LOL";
  static REPORT_MNGT_EVENT_FINANCIAL_DATA = "REPORT_MNGT_EVENT_FINANCIAL_DATA";
  static REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY = "REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY";
  static REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF_STANDALONE_STACK = "REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF_STANDALONE_STACK";

  static REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY = "REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_AL_BY_DAY";
  static REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_WARMBUFFET_NO_PRICE = "REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_WARMBUFFET_NO_PRICE";
  static REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE = "REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE";

  static REPORT2_ADMIN_SALE_STATS_PRODUCT_BY_CATEGORY_JR_XLSX = "REPORT2_ADMIN_SALE_STATS_PRODUCT_BY_CATEGORY_JR_XLSX";

  static REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE_AL = "REPORT2_MNGT_ORDER_PURCHASE_ORDER_BY_DAY_BOX_NO_PRICE_AL";

  static REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF = "REPORT_DAN_ORDER_PURCHASE_ORDER_KITCHEN_STAFF";

  static REPORT2_CLIENT_ORDER_PURCHASE_EVENT_PRODUCTION_GAB = "REPORT2_CLIENT_ORDER_PURCHASE_EVENT_PRODUCTION_GAB";

  static REPORT_CLIENT_EVENT_DEPOSIT_REQUEST = "REPORT_CLIENT_EVENT_DEPOSIT_REQUEST";

  static REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY = "REPORT_DAN_ORDER_PURCHASE_ORDER_DELIVERY";

  static REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT = "REPORT_DAN_ORDER_PURCHASE_ORDER_CLIENT";

  static REPORT_DAN_ORDER_PURCHASE_ORDER_STACK = "REPORT_DAN_ORDER_PURCHASE_ORDER_STACK";

  static REPORT_ASSIGNMENT_SCHEDULE_PER_DATE_STACK = "REPORT_ASSIGNMENT_SCHEDULE_PER_DATE_STACK";

  static REPORT2_MNGT_PROD_LIST_BY_ORDER_XLS = "REPORT2_MNGT_PROD_LIST_BY_ORDER_XLS";

  static REPORT2_MNGT_PROD_LIST_CAC_BY_ORDER_XLS = "REPORT2_MNGT_PROD_LIST_CAC_BY_ORDER_XLS";

  static REPORT_CLIENT_CONTRACT_FULL = "REPORT_CLIENT_CONTRACT_FULL";
  static REPORT_CLIENT_CONTRACT_FULL_STACK = "REPORT_CLIENT_CONTRACT_FULL_STACK";
  static REPORT_CLIENT_CONTRACT_ACCOMODATIONS_STACK = "REPORT_CLIENT_CONTRACT_ACCOMODATIONS_STACK";
  static REPORT_CLIENT_CONTRACT_ROOMS_STACK = "REPORT_CLIENT_CONTRACT_ROOMS_STACK";

  static REPORT_CLIENT_ORDER_CT_STACK = "REPORT_CLIENT_ORDER_CT_STACK";
  static REPORT_CLIENT_PROPOSAL_CT_STACK = "REPORT_CLIENT_PROPOSAL_CT_STACK";
  static REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT = "REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT";
  static REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT = "REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT";
  static REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT = "REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT";
  static REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT = "REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT";
  static REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT = "REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT";
  static REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT = "REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT";
  static REPORT_CLIENT_INVOICE_CT = "REPORT_CLIENT_INVOICE_CT";

  static REPORT2_ADMIN_USERS_LOGIN_ACTIVITIES_XLSX = "REPORT2_ADMIN_USERS_LOGIN_ACTIVITIES_XLSX";

  static REPORT2_ADMIN_ORDERS_BILLED_STAFF_XLSX = "REPORT2_ADMIN_ORDERS_BILLED_STAFF_XLSX";

  static REPORT2_ADMIN_CHANGE_LOG = "REPORT2_ADMIN_CHANGE_LOG";
  static REPORT_CLIENT_ORDER_CT_STACK_LIST = "REPORT_CLIENT_ORDER_CT_STACK_LIST";

  static REPORT_CLIENT_ORDER_STACK_LIST = "REPORT_CLIENT_ORDER_STACK_LIST";

  static REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_STACK = "REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_STACK";

  static REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_STACK = "REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_STACK";

  static REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_STACK = "REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_STACK";

  static REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK = "REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_STACK";

  static REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_STACK = "REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_STACK";

  static REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT_STACK = "REPORT_MNGT_ORDER_PURCHASE_ORDER_SERVICE_CT_STACK";

  static REPORT_MNGT_ORDER_CT_STACK_LIST_PER_DAY = "REPORT_MNGT_ORDER_CT_STACK_LIST_PER_DAY";
  //
  static REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_NEW_EVT_STACK = "REPORT_MNGT_ORDER_PURCHASE_ORDER_CHEF_CT_NEW_EVT_STACK";

  static REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_NEW_EVT_STACK = "REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_CT_NEW_EVT_STACK";

  static REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_NEW_EVT_STACK = "REPORT_MNGT_ORDER_PURCHASE_ORDER_COLD_CT_NEW_EVT_STACK";

  static REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_NEW_EVT_STACK = "REPORT_MNGT_ORDER_PURCHASE_ORDER_HOT_AND_COLD_CT_NEW_EVT_STACK";

  static REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_NEW_EVT_STACK = "REPORT_MNGT_ORDER_PURCHASE_ORDER_PASTRY_CT_NEW_EVT_STACK";

  static REPORT_MNGT_INVOICE_CT_STACK = "REPORT_MNGT_INVOICE_CT_STACK";
  static REPORT_CLIENT_INVOICE_CT_STACK = "REPORT_CLIENT_INVOICE_CT_STACK";
  static REPORT2_ADMIN_ACCOUNTING_CLIENT_LIST_STARS_TEMP = "REPORT2_ADMIN_ACCOUNTING_CLIENT_LIST_STARS_TEMP";
  static REPORT_ADMIN_SALE_STATS_SUMMARY_EVENT_XLSX = "REPORT_ADMIN_SALE_STATS_SUMMARY_EVENT_XLSX";

  static REPORT2_INVOICES_BY_ENTERPRISE = "REPORT2_INVOICES_BY_ENTERPRISE";
  static REPORT2_ADMIN_ACCOUNTING_LIST_OF_ORDERS_CREATION_DATE_XLSX = "REPORT2_ADMIN_ACCOUNTING_LIST_OF_ORDERS_CREATION_DATE_XLSX";

  //
  static REPORT2_CLIENT_ORDER_JUL = "REPORT2_CLIENT_ORDER_JUL";
  static REPORT2_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION_JUL = "REPORT2_CLIENT_ORDER_PURCHASE_ORDER_PRODUCTION_JUL";
  static REPORT2_MNGT_ORDERS_BY_DAY_JUL = "REPORT2_MNGT_ORDERS_BY_DAY_JUL";
  static REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY_JUL = "REPORT2_MNGT_ORDER_PURCHASE_ORDER_PRODUCTIONS_BY_DAY_JUL";
  static REPORT2_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_XLSX = "REPORT2_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_XLSX";
  static REPORT2_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_XLSX_ALT = "REPORT2_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_XLSX_ALT";
  static REPORT2_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_UQAM_XLSX_ALT = "REPORT2_ADMIN_ACCOUNTING_SALE_BY_LEDGER_ACCOUNT_UQAM_XLSX_ALT";

  // LOL reports
  static REPORT_CLIENT_SERVICE_OFFER_LOL = "REPORT_CLIENT_SERVICE_OFFER_LOL";
  static REPORT_CLIENT_CONTRACT_LOL = "REPORT_CLIENT_CONTRACT_LOL";
  static REPORT_CLIENT_INVOICE_LOL = "REPORT_CLIENT_INVOICE_LOL";

  static REPORT_ADMIN_AGING_ACCOUNT_BTF = "REPORT_ADMIN_AGING_ACCOUNT_BTF";
  static REPORT_ADMIN_ACCOUNTING_DEPOSITE_TRACKING_OKA = "REPORT_ADMIN_ACCOUNTING_DEPOSITE_TRACKING_OKA";
  static REPORT_CLIENT_INVOICE_ALEX_DATA = "REPORT_CLIENT_INVOICE_ALEX_DATA";

  static REPORT2_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_BY_DAY_ST_GAB = "REPORT2_MNGT_ORDER_PURCHASE_ORDER_KITCHEN_BY_DAY_ST_GAB";

  // UQAM Reports
  static REPORT_CLIENT_RECEIPT_NOTICE_UQAM = "REPORT_CLIENT_RECEIPT_NOTICE_UQAM";
  static REPORT_CLIENT_RECEIPT_NOTICE_AND_PURCHASE_ORDERS_UQAM_STACK = "REPORT_CLIENT_RECEIPT_NOTICE_AND_PURCHASE_ORDERS_UQAM_STACK";

  static REPORT_CLIENT_RECEIPT_NOTICE_SELL_ONLY_UQAM = "REPORT_CLIENT_RECEIPT_NOTICE_SELL_ONLY_UQAM";
  static REPORT_CLIENT_STORAGE_NOTICE_UQAM = "REPORT_CLIENT_STORAGE_NOTICE_UQAM";
  static REPORT2_CLIENT_ORDER_UQAM = "REPORT2_CLIENT_ORDER_UQAM";
  static REPORT2_MNGT_ORDERS_BY_DAY_UQAM = "REPORT2_MNGT_ORDERS_BY_DAY_UQAM";
  static REPORT2_CLIENT_INVOICE_UQAM_STACK = "REPORT2_CLIENT_INVOICE_UQAM_STACK";
  static REPORT2_ADMIN_ACCOUNTING_LIST_OF_INVOICES_UQAM_XLSX = "REPORT2_ADMIN_ACCOUNTING_LIST_OF_INVOICES_UQAM_XLSX";
  static REPORT2_MNGT_DELIVERY_A_JUL_XLSX = "REPORT2_MNGT_DELIVERY_A_JUL_XLSX";
  static REPORT2_ADMIN_PRODUCTS_SOLD_LIST_XLSX = "REPORT2_ADMIN_PRODUCTS_SOLD_LIST_XLSX";
}

export enum DistributionModeEnum {
  CLOUD_PRINTER = "CLOUD_PRINTER",
  FILE = "FILE",
  NONE = "NONE",
  RAW_HTML = "RAW_HTML",
  S3_STORAGE_USER_DOCUMENT = "S3_STORAGE_USER_DOCUMENT",
  EMAIL_ATTACHMENT = "EMAIL_ATTACHMENT",
  MAIL_ME = "MAIL_ME",
}

export enum ExportFormatEnum {
  JSON = "JSON",
  PDF = "PDF",
  PDF_STACK = "PDF_STACK",
  XLSX = "XLSX",
  MSWORD = "MSWORD",
  HTML = "HTML",
  VCARD = "VCARD",
}

export enum ExportToEnum {
  DOWNLOAD = "DOWNLOAD",
  PRINT = "PRINT",
  PREVIEW = "PREVIEW",
  NONE = "NONE",
}

export enum BenchModeEnum {
  NONE = "NONE",
  BENCH_TOOL = "BENCH_TOOL",
}

export enum PaperEnum {
  LETTER = "letter",
  LEGAL = "legal",
  A4 = "a4",
}

export enum LocaleEnum {
  //
  EN = "en",
  EN_CA = "en-CA",
  EN_US = "en-US",
  EN_UK = "en-UK",
  //
  FR = "fr",
  FR_CA = "fr-CA",
  FR_FR = "fr-FR",
  FR_BE = "fr-BE",
}

export enum ColorModeEnum {
  COLOR = "COLOR",
  BNW = "BnW",
}

export enum OrientationEnum {
  LANDSCAPE = "landscape",
  PORTRAIT = "portrait",
}

export enum SocketModeEnum {
  FIREBASE = "FIREBASE",
  FIREBASE_JOB = "FIREBASE_JOB",
  NONE = "NONE",
}
