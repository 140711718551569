import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiRoutes } from "../../api/ApiRoutes";
import { Gt2ApiService } from "../../api/gt2-api.service";

@Injectable()
export class KnowledgeBaseService implements Resolve<any> {
  knowledgeBase: any;
  onKnowledgeBaseChanged: BehaviorSubject<any>;

  /*
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private api: Gt2ApiService, private _httpClient: HttpClient) {
    // Set the defaults
    this.onKnowledgeBaseChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getKnowledgeBase()]).then(() => {
        resolve();
      }, reject);
    });
  }

  /**
   * Get knowledge base
   */
  getKnowledgeBase(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this.api.createUrl(ApiRoutes.KNOWLEDGE_BASE)).subscribe((response: any) => {
        this.knowledgeBase = response;
        this.onKnowledgeBaseChanged.next(this.knowledgeBase);
        resolve(this.knowledgeBase);
      }, reject);
    });
  }
}
