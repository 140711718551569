<div
  id="project-page"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          (click)="onPageBack(location, router, '/congresses')"
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutAlign="center start">
          <div *ngIf="!project" class="h1">
            {{ "GENERIC.PROJECT" | translate }}
          </div>
          <div
            *ngIf="
              project &&
              (!modulesService.modulesAreSet || !modulesService.hasCTReport())
            "
            class="h1"
          >
            {{ "GENERIC.PROJECT" | translate }}
            <!-- - {{ project.name }} - {{ 'GENERIC.PROJECT_NUMBER' | translate | scpmPipe}}: {{ project.project_number }} -->
            - {{ project.name }} - # {{ "GENERIC.CONGRESS" | translate }}:
            {{ project.project_number }}
          </div>
          <div
            *ngIf="
              project &&
              modulesService.modulesAreSet &&
              modulesService.hasCTReport()
            "
            class="h1"
          >
            {{ "GENERIC.PROJECT" | translate }} - {{ project.name }} -
            {{ "GENERIC.SPCM_NUMBER" | translate }}:
            {{ project.project_number }}
          </div>
          <div
            *ngIf="project"
            fxLayout="row"
            fxLayoutAlign="start start"
            class=""
          >
            <div *ngIf="orderingClient">
              {{ orderingClient.name_label }}
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    *ngIf="project"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 content-subheader"
  >
    <!-- front (space-between) -->
    <div class="ml-16" fxLayout="row" fxLayoutAlign="start center">
      <div>
        {{ dateStartFormatted }} {{ "GENERIC.TO2" | translate }}
        {{ dateEndFormatted }}
      </div>
      <div class="ml-16">
        {{ eventCount }}
        {{
          (eventCount > 1 ? "GENERIC.EVENTS" : "GENERIC.EVENT")
            | translate
            | lowercase
        }}
      </div>

      <div
        *ngIf="project.congress && project.congress.uuid"
        class="ml-16"
        fxLayout="row"
        fxLayoutAlign="start center"
        (click)="onNavigateToCongres()"
        style="cursor: pointer"
      >
        <button mat-icon-button class="">
          <!-- <mat-icon fontSet="fa" fontIcon="{{ getIcon('CONGRESS') }}" class="fa-lg fa-icon-btn"></mat-icon> -->
          <mat-icon class="s-24">{{ getIcon("CONGRESS") }}</mat-icon>
        </button>
        <div style="padding-top: 3px">
          {{ "CONGRESSES.CONGRESS" | translate }}
        </div>
      </div>
    </div>

    <!-- end (space-between) -->
    <div></div>
  </div>
  <!-- /SUB HEADER -->

  <!-- gray band message -->
  <div *ngIf="project">
    <div
      *ngIf="
        project.canceled_at &&
        project.canceled_at !== null &&
        project.canceled_at !== ''
      "
      fxLayout="row"
      fxLayoutAlign="center center"
      style="height: 38px; min-height: 38px; max-height: 38px"
      class="gt2-gray mat-elevation-z1 py-8"
    >
      <div class="text-white font-weight-600">
        <b>{{ "CONGRESSES.PROJECT_CANCELED" | translate }}</b>
      </div>
    </div>
  </div>

  <div
    #mainContainer
    class="center p-24 pr-56 pr-sm-72 congress-content"
    fusePerfectScrollbar
  >
    <div *ngIf="project" class="profile-box info-box general" fxLayout="column">
      <header class="accent">
        <div class="title">{{ "GENERIC.INFORMATION" | translate }}</div>
      </header>

      <div class="content" fxLayout="column">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
          <div *ngIf="!editMode" class="info-line" fxFlex="100%">
            <div class="title">{{ "GENERIC.NAME" | translate }}</div>
            <div class="info text-truncate">{{ project.name }}</div>
            <div *ngIf="!project.name" class="info empty-value"></div>
          </div>
          <div *ngIf="editMode" class="info-line" fxFlex="100%">
            <div class="info-editable">
              <div [formGroup]="form" class="">
                <mat-form-field class="form-input-container" fxFlex="100%">
                  <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
                  <input
                    [(ngModel)]="project.name"
                    autocomplete="nope"
                    formControlName="name"
                    matInput
                    placeholder="{{ 'GENERIC.NAME' | translate }}"
                    required
                    type="text"
                  />
                  <mat-error *ngIf="form.controls['name'].invalid"
                    >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <!-- <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                        <div class="title">{{ 'CONGRESSES.PROJECT_NUMBER' | translate }}</div>
                        <div class="info text-truncate">{{project.project_number}}</div>
                        <div *ngIf="!project.project_number" class="info empty-value"></div>

                    </div>

                    <div *ngIf="editMode" class="info-line" fxFlex="50%">
                        <div class="info-editable">
                            <div [formGroup]="form" class="">
                                <mat-form-field class="form-input-container" fxFlex="100%">
                                    <input [(ngModel)]="project.project_number" autocomplete="nope"
                                        formControlName="project_number" matInput
                                        placeholder="{{ 'CONGRESSES.PROJECT_NUMBER' | translate }}" required type="text">
                                    <mat-error *ngIf="form.controls['project_number'].invalid">{{
                                        'GENERIC.INPUT_LENGTH_INVALID' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div> -->
        </div>

        <!-- 2nd row -->
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
          <div *ngIf="!editMode" class="info-line" fxFlex="100%">
            <div class="title">{{ "GENERIC.DATE_START" | translate }}</div>
            <div class="info text-truncate">{{ project.date_start }}</div>
            <div *ngIf="!project.date_start" class="info empty-value"></div>
          </div>
          <div *ngIf="editMode" class="info-line" fxFlex="50%">
            <div class="info-editable">
              <div [formGroup]="form" class="">
                <mat-form-field class="form-input-container" fxFlex="100%">
                  <mat-label>{{ "GENERIC.DATE_START" | translate }}</mat-label>
                  <input
                    matInput
                    [matDatepicker]="dateFrom"
                    [(ngModel)]="project.date_start"
                    (ngModelChange)="onDateChange($event)"
                    min="1900-01-01"
                    max="2099-01-01"
                    placeholder="{{ 'GENERIC.DATE_START' | translate }}"
                    autocomplete="nope"
                    formControlName="date_start"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateFrom"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateFrom></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_start'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div *ngIf="!editMode" class="info-line" fxFlex="100%">
            <div class="title">{{ "GENERIC.DATE_END" | translate }}</div>
            <div class="info text-truncate">{{ project.date_end }}</div>
            <div *ngIf="!project.date_end" class="info empty-value"></div>
          </div>
          <div *ngIf="editMode" class="info-line" fxFlex="50%">
            <div class="info-editable">
              <div [formGroup]="form" class="">
                <mat-form-field class="form-input-container" fxFlex="100%">
                  <mat-label>{{ "GENERIC.DATE_END" | translate }}</mat-label>
                  <input
                    matInput
                    [matDatepicker]="dateTo"
                    [(ngModel)]="project.date_end"
                    (ngModelChange)="onDateEndChange($event)"
                    min="1900-01-01"
                    max="2099-01-01"
                    placeholder="{{ 'GENERIC.DATE_END' | translate }}"
                    autocomplete="nope"
                    required
                    formControlName="date_end"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateTo"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateTo></mat-datepicker>

                  <mat-error *ngIf="form.controls['date_end'].invalid"
                    >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <!-- 3rd row -->
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
          <!-- column advisor -->
          <div *ngIf="!editMode" class="info-line" fxFlex="50%">
            <div class="title">
              {{ "GENERIC.RESPONSIBLE_EMPLOYEE" | translate }}
            </div>
            <div
              *ngIf="
                project.responsible_employee &&
                project.responsible_employee.name
              "
              class="info text-truncate"
            >
              {{ project.responsible_employee.name }}
            </div>
            <div
              *ngIf="
                !project.responsible_employee ||
                !project.responsible_employee.name
              "
              class="info empty-value"
            ></div>
          </div>
          <div *ngIf="editMode" class="info-line" fxFlex="50%">
            <div class="info-editable">
              <div [formGroup]="form" class="">
                <app-gt2-select
                  [service]="conseillersService"
                  [placeHolder]="'GENERIC.RESPONSIBLE_EMPLOYEE' | translate"
                  [label]="'Personnal title'"
                  [selectedModel]="project.responsible_employee"
                  (changeEvent)="onResponsibleEmployeeSelected($event)"
                  [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                  [nullableUseNone]="false"
                  class=""
                ></app-gt2-select>
              </div>
            </div>
          </div>
          <!-- project deposit column  -->
          <div *ngIf="!editMode" class="info-line" fxFlex="100%">
            <div class="title">{{ "GENERIC.PROJECT_DEPOSIT" | translate }}</div>
            <div class="info text-truncate">
              {{ project.project_deposit_label }}
            </div>
            <div
              *ngIf="!project.project_deposit_label"
              class="info empty-value"
            ></div>
          </div>
          <div *ngIf="editMode" class="info-line" fxFlex="100%">
            <div class="info-editable">
              <div [formGroup]="form" class="">
                <mat-form-field class="form-input-container" fxFlex="100%">
                  <mat-label>{{
                    "GENERIC.PROJECT_DEPOSIT" | translate
                  }}</mat-label>
                  <input
                    [(ngModel)]="project.project_deposit"
                    autocomplete="nope"
                    formControlName="project_deposit"
                    matInput
                    placeholder="{{ 'GENERIC.PROJECT_DEPOSIT' | translate }}"
                    pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                    type="text"
                  />
                  <mat-error *ngIf="form.controls['project_deposit'].invalid">
                    {{ "GENERIC.INPUT_NUMBER_0_999999" | translate }}
                    {{ "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CLIENTS -->
    <div
      *ngIf="project"
      class="profile-box info-box general"
      style="min-width: 1023px"
      fxLayout="column"
    >
      <!-- <div class="gt2-theme"></div> -->
      <header class="accent">
        <div class="title p-16">{{ "GENERIC.CLIENTS" | translate }}</div>
      </header>

      <div class="content" fxLayout="column">
        <app-clients-box
          [(project)]="project"
          [(editable)]="editable"
          (modelUpdatedEvent)="onComponentModelUpdated()"
        ></app-clients-box>
      </div>
    </div>

    <!-- EVENTS -->
    <div
      *ngIf="project"
      class="profile-box info-box general"
      style="min-width: 1023px"
      fxLayout="column"
    >
      <header class="accent">
        <div
          fxFlex
          class="gt2-theme accent header-create"
          fxFlex
          fxLayout="row"
          fxLayoutAlign="space-between center"
          style="height: 56px"
        >
          <div class="title">{{ "GENERIC.EVENTS" | translate }}</div>
          <div
            *ngxPermissionsOnly="rolesService.SALE_ROLES"
            class="gt2-light-green"
            style="width: 60px; height: 56px"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <button
              type="button"
              matTooltip="{{ 'GENERIC.CREATE_EVENT' | translate }}"
              mat-icon-button
              [matMenuTriggerFor]="createMenu"
              class=""
              [disabled]="
                project.canceled_at &&
                project.canceled_at !== null &&
                project.canceled_at !== ''
              "
            >
              <mat-icon class="" color="">{{ getIcon("ADD") }}</mat-icon>
            </button>

            <mat-menu #createMenu="matMenu" [overlapTrigger]="false">
              <span (mouseleave)="closeMyMenu()">
                <button mat-menu-item (click)="onCreateEventWithProposal()">
                  <mat-icon>{{ iconsService.getIcon("PROPOSAL") }}</mat-icon>
                  <span>{{ "TOOLBAR.CREATE_PROPOSAL" | translate }}</span>
                </button>

                <button mat-menu-item (click)="onCreateEventWithhOrder()">
                  <mat-icon>{{ iconsService.getIcon("ORDER") }}</mat-icon>
                  <span>{{ "TOOLBAR.CREATE_ORDER" | translate }}</span>
                </button>
              </span>
              <!-- <span (mouseleave)="closeMyMenu()" *ngIf="modulesService.modulesAreSet && !modulesService.hasCTReport()">

                                <button mat-menu-item (click)="onCreateEventWithProposal()">
                                    <mat-icon>{{ iconsService.getIcon('PROPOSAL') }}</mat-icon>
                                    <span>{{ 'TOOLBAR.CREATE_PROPOSAL' | translate }}</span>
                                </button>
                                
                                <button mat-menu-item (click)="onCreateEventWithhOrder()">
                                    <mat-icon>{{ iconsService.getIcon('ORDER') }}</mat-icon>
                                    <span>{{ 'TOOLBAR.CREATE_ORDER' | translate }}</span>
                                </button>

                            </span>

                            <span (mouseleave)="closeMyMenu()" *ngIf="modulesService.modulesAreSet && modulesService.hasCTReport()">
                                <button *ngxPermissionsOnly="rolesService.SALE_ROLES" mat-menu-item (click)="onCreateNewCongress()" class="">
                                    <mat-icon class="s-24">{{ getIcon('CONGRESS') }}</mat-icon>
                                    {{ 'GENERIC.CREATE_CONGRESS_TITLE' | translate}}
                                </button>
                            
                                <button *ngxPermissionsOnly="rolesService.SALE_ROLES" mat-menu-item (click)="onGoToCongresses()" class="">
                                    <mat-icon class="s-24">{{ getIcon('CONGRESS') }}</mat-icon>
                                    {{ 'GENERIC.CONGRESSES' | translate}}
                                </button>
                            
                            </span> -->
            </mat-menu>
          </div>
        </div>
      </header>

      <div class="" fxLayout="column">
        <app-events-box
          #eventBox
          (onMeta)="onMeta($event)"
          [congresUUID]="congresUUID"
          [projectUUID]="projectUUID"
        ></app-events-box>
      </div>
    </div>

    <app-comment-section
      *ngIf="project"
      [commentsModel]="project"
      [serviceAPIUrl]="'/v1/project/comments/' + project.uuid"
    >
    </app-comment-section>

    <div *ngxPermissionsOnly="rolesService.RH_ROLES" class="mt-32">
      <div
        *ngIf="project"
        fxFlex="100%"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <button
          mat-raised-button
          (click)="cancelProject()"
          [disabled]="
            project === null ||
            (project.canceled_at !== null && project.canceled_at !== '')
          "
          class="warn"
          style="min-width: 240px"
        >
          {{ "GENERIC.CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="project && editable" fxLayout="row" fxLayoutAlign="end start">
  <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
    <div class="fixed-button">
      <button
        mat-fab
        #saveButton
        [ngClass]="editMode ? 'gt2-save-button' : 'gt2-edit-button'"
        id="edit-project-button"
        (click)="onEdit($event)"
        [disabled]="
          loading ||
          (editMode && !form?.valid) ||
          (project.canceled_at !== null && project.canceled_at !== '')
        "
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
      >
        <mat-icon *ngIf="!editMode && !loading">{{
          getIcon("EDIT_FAB")
        }}</mat-icon>
        <mat-icon
          *ngIf="editMode && !loading && !saveButton.disabled"
          matTooltip="{{ 'GENERIC.SAVE' | translate }}"
          position="left"
          showDelay="900"
          hideDelay="900"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <mat-icon
          *ngIf="editMode && !loading && saveButton.disabled"
          matTooltip="{{ 'GENERIC.FORM_DIRTY_TIP' | translate }}"
          position="left"
          hideDelay="900"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <i [ngClass]="loading ? 'fa fa-spinner fa-spin' : ''"></i>
      </button>
    </div>
    <div class="fixed-button2">
      <button
        mat-fab
        *ngIf="editMode"
        id="cancel-edit-project-button"
        class="gt2-cancel-edit-button"
        matTooltip="{{ 'GENERIC.CANCEL' | translate }}"
        position="left"
        showDelay="900"
        hideDelay="900"
        (click)="onCancelEdit()"
        [disabled]="loading"
        [@animate]="{ value: '*', params: { delay: '120ms', scale: '.2' } }"
      >
        <mat-icon class="s-24">{{ getIcon("CANCEL") }}</mat-icon>
      </button>
    </div>
  </div>
</div>
