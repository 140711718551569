import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  Signal,
  viewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { navigation } from '../../../navigation/navigation';
import { NGXLogger } from 'ngx-logger';
import {
  TopAlertModel,
  TopAlertsComponent,
} from '../../../gt2/components/top-alerts/top-alerts.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { MatInputModule } from '@angular/material/input';
import { FuseSidebarModule } from '../../../../@fuse/components';
import { ContentComponent } from '../../components/content/content.component';
import { NgProgressbar, NgProgressRef } from 'ngx-progressbar';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { QuickPanelComponent } from '../../components/quick-panel/quick-panel.component';
import { ToolbarComponent } from '../../components/toolbar/toolbar.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { NgProgressHttp } from 'ngx-progressbar/http';

@Component({
  selector: 'vertical-layout-1',
  templateUrl: './layout-1.component.html',
  styleUrls: ['./layout-1.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    ToolbarComponent,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgProgressHttp,
    FuseDirectivesModule,
    FooterComponent,
    FusePipesModule,
    MatInputModule,
    TopAlertsComponent,
    FuseSidebarModule,
    ContentComponent,
    NgProgressbar,
    NgxPermissionsModule,
    NavbarComponent,
    QuickPanelComponent,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalLayout1Component
  implements OnInit, OnDestroy, AfterViewInit
{
  progressBar: Signal<NgProgressRef> | any = viewChild(NgProgressRef);

  start() {
    this.progressBar().start();
  }
  fuseConfig: any;
  navigation: any;
  // messages: Message[] = ["les pata"];
  // message: string = "C'est bon des patates! Oui, c'est bon dedans le bedon. C'est comme...";
  // @Input() message: string;
  // @Input() messageColor: string;
  // @Input() messageClosable: boolean;
  @Input() topAlertModelUser!: TopAlertModel;
  @Input() topAlertModelSettings!: TopAlertModel;
  @Input() topAlertModelCaterer!: TopAlertModel;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    public logger: NGXLogger,
  ) {
    // Set the defaults
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (config: any) => {
          this.fuseConfig = config;
        },
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit() {
    // this.logger.info("VerticalLayout1Component.ngAfterViewInit() -> topAlertModelUser: " + JSON.stringify(this.topAlertModelUser));
    // this.logger.info("VerticalLayout1Component.ngAfterViewInit() -> topAlertModelSettings: " + JSON.stringify(this.topAlertModelSettings));
  }

  onProgressBarStarted() {
    // this.logger.info('FuseMainComponent.onStarted() ::::::::::::::::::::::');
  }

  onProgressBarCompleted() {
    // this.logger.info('FuseMainComponent.onCompleted() ::::::::::::::::::::::');
  }
}
