import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-1',
  templateUrl: './module-1.component.html',
  styleUrls: ['./module-1.component.scss'],
})
export class Module1Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
