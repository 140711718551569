export const locale = {
  lang: "en-CA",
  data: {
    CREATE_ENTERPRISE: {
      TITLE: "New enterprise",
      TITLE_CONTACT: "Company contact info",
      TITLE_ADDRESS: "Company address info",
    },
  },
};
