export const locale = {
  lang: "en-CA",
  data: {
    CREATE_DELIVERY_REQUEST: {
      TITLE: "New delivery request",
      EDIT_TITLE: "Edit delivery request",
      SELECT_FLOW: "Define or select a flow",
      SHOW_ON_ORDER: "Show on orders",
      SHOW_ON_PROPOSAL: "Show on proposals",
      SHOW_ON_INVOICE: "Show on invoices",
      SHOW_ON_MATERIAL_REPORT: "Show on material reports",
      NEED_INSTALATION: "Need installation",

      SHOW_ON_PURCHASE_ORDER_PRODUCTION: "Show on purchase order",
      SHOW_ON_PURCHASE_ORDER_CLIENT_COPY: "Show on purchase order - Client",
      SHOW_ON_CLIENT_PROPOSAL_AND_ORDER: "Show on proposal and order",
      SHOW_ON_PURCHASE_ORDER_KITCHEN: "Show on purchase order - Kitchen",
      SHOW_ON_PURCHASE_MATERIAL: "Show on material purchase",
      TEMPLATES: "Select a delivery request template",
      TEMPLATES_EMPTY: "No templates have been created yet.",
      TEMPLATES_EMPTY_CLICK: "Click here to navigate to the create template section",

      NAME_OF_CONTACT_PERSON_ON_SITE: "Name of the contact person on site",
      PHONE_NUMBER_OF_PERSON_ON_SITE: "Phone number of the person on site",
    },
  },
};
