import { NGXLogger } from 'ngx-logger';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RichTextLocaleComponent } from '../../../../components/rich-text-locale/rich-text-locale.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-general-configurations',
  templateUrl: './general-configurations.component.html',
  styleUrls: ['./general-configurations.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
    MatSlideToggleModule,
    MatInputModule,
    RichTextLocaleComponent,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
})
export class GeneralConfigurationsComponent implements OnInit {
  @Input() shop: any;
  @Input() editMode: boolean = false;
  @Input() form!: UntypedFormGroup;
  @Output() shopChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
  ) {
    // this.form = this.formBuilder.group({
    //     name: ['', [Validators.minLength(Settings.inputMinChar), Validators.maxLength(Settings.inputMaxCharMid), Validators.required]],
    //     store_url: ['', [ValidatorHelper.nullable] ],
    //     min_subtotal_delivery: ['', [Validators.minLength(1), ValidatorHelper.isNumber0To1000000, ValidatorHelper.isValidPriceNumber]],
    //     min_subtotal_takeout: ['', [Validators.minLength(1), ValidatorHelper.isNumber0To1000000, ValidatorHelper.isValidPriceNumber]],
    //     google_ga_caterer_shop: ['', [Validators.minLength(3)]],
    //     limit_distance_km: ['', [Validators.minLength(1), ValidatorHelper.isNumber0To1000000]],
    //     single_rate: ['', [Validators.minLength(1), ValidatorHelper.isNumber0To1000000]],
    // });
  }

  ngOnInit(): void {}

  public openURL(url: string): void {
    window.open(url, '_blank');
  }

  public onSwitchLocale(event: MatSlideToggleChange): void {
    //this.logger.info("ShopWebComponent.onSwitchLocale() -> " + event.checked);
    //this.logger.info("ShopWebComponent.onSwitchLocale() -> show_switch_locale: " + this.shop.show_switch_locale);
    this.shop.show_switch_locale = event.checked;
  }

  public onSaveInfo(): void {
    this.shopChange.emit();
  }
}
