import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { IconsService } from '../../services/icons.service';
import { NGXLogger } from 'ngx-logger';
import moment from 'moment';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';

declare const FullCalendar: any;

@Component({
  selector: 'app-fullcalendar-picker',
  templateUrl: './fullcalendar-picker.component.html',
  styleUrls: ['./fullcalendar-picker.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    MatButtonModule,
    TranslateModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
  ],
  providers: [],
})
export class FullcalendarPickerComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static LS_CURRENT_DATE: string = 'PICKER_LS_CURRENT_DATE';

  @Input() modelDates?: any[];
  @Output() modelDatesChange = new EventEmitter<any>();
  @Output() datesEvent = new EventEmitter<any>();
  @Output() removeDateEvent = new EventEmitter<any>();
  //
  calInit: boolean = false;
  calendarDayMonthValue: string = 'dayGridMonth';
  currentDate?: string;
  timeZone: string = 'America/Montreal';
  calendarTitle: string = '';
  //
  calendar: any;
  calendarInit: boolean = false;
  calendarData: any = {
    calendarRessources: [],
    calendarEvents: [],
  };

  //
  constructor(
    public translate: TranslateService,
    public iconsService: IconsService,
    public logger: NGXLogger,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
  }

  ngOnInit() {
    this.calInit = true;
  }

  ngAfterViewInit(): void {
    this.setUpCalendar();
    //this.logger.info("modelDates: " + JSON.stringify(this.modelDates));
  }

  ngOnDestroy(): void {}

  public setUpCalendar(): void {
    // /v1/events/by-room-month/2021-12-03T18:44:19.985Z
    const lsDate = localStorage.getItem(
      FullcalendarPickerComponent.LS_CURRENT_DATE,
    );
    //this.logger.info("FullcalendarPickerComponent.setUpCalendar()-> lsDate: " + lsDate);
    if (lsDate) {
      this.currentDate = moment(lsDate).toISOString();
    } else {
      this.currentDate = moment().toISOString();
    }

    const calendarEl = document.getElementById('calendar');
    const context = this;
    this.calendar = new FullCalendar.Calendar(calendarEl, {
      timeZone: 'UTC',
      now: this.currentDate,
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      editable: false,
      eventResourceEditable: false,
      scrollTime: '08:00:00',
      locale: this.translate.currentLang,
      eventOverlap: false,
      eventTextColor: '#fff',
      slotEventOverlap: false,
      header: false,
      //selectOverlap: false,
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: false,
      },
      defaultView: 'timelineDay',
      views: {
        dayGridMonth: {
          eventTimeFormat: {
            hour: 'numeric',
            minute: '2-digit',

            meridiem: false,
          },
        },
      },
      aspectRatio: 1.2,
      expandRows: false,
      selectable: true,
      slotWidth: 32,
      eventRender: function (info: any) {},

      datesRender: function (info: any) {
        context.calendarTitle = info.view.title;
      },
      eventDrop: function (eventDropInfo: any) {},
      eventResize: function (info: any) {},
      eventClick: function (info: any) {},
      select: function (selectionInfo: any) {
        context.onSelection(selectionInfo);
      },
      resources: function (
        fetchInfo: any,
        successCallback: any,
        failureCallback: any,
      ) {
        successCallback(context.calendarData.calendarRessources);
      },
      events: function (
        fetchInfo: any,
        successCallback: any,
        failureCallback: any,
      ) {
        const events = [];
        if (context.modelDates) {
          for (let date of context.modelDates) {
            let eventme = {
              id: date,
              start: date,
              allDayDefault: true,
              rendering: 'background',
              editable: true,
              // color: "#FF0000",
              color: '#63C5DA',
            };
            events.push(eventme);
          }
        }
        //
        context.calendarData.calendarEvents = events;
        successCallback(events);
        successCallback(context.calendarData.calendarEvents);
      },
    });
    this.calendar.render();
    //
    this.calendar.changeView('month');
  }

  public onSelection(selectionInfo: any): void {
    //this.logger.info("FullcalendarPickerComponent.onSelection() -> selectionInfo: " + selectionInfo);
    //
    let endDate = selectionInfo.endStr;
    let startDate = selectionInfo.startStr;
    let days = [];

    let startDateMoment = moment(startDate, 'YYYY-MM-DD');
    let endDateMoment = moment(endDate, 'YYYY-MM-DD');
    //
    for (
      var m = startDateMoment;
      m.diff(endDateMoment, 'days') < 0;
      m.add(1, 'days')
    ) {
      const mday = moment.utc(m).format('YYYY-MM-DD');
      days.push(mday);
    }
    //
    //this.logger.info("days.length: " + days.length);
    if (days.length > 0) {
      this.datesEvent.emit(days);
      this.modelDatesChange.emit(days);
    }
  }

  public refreshTimeline(): void {
    //this.logger.info("Refresh Events after an item is deleted !!!");
    if (this.calendar) {
      this.calendar.refetchEvents();
    }
  }

  public clearCalendarEvents(): void {
    if (this.calendar) {
      const events = this.calendar.getEvents();
      for (let evt of events) {
        evt.remove();
      }
    }
  }

  public onCalendarToday(): void {
    //this.logger.info("onCalendarToday()");
    // this.calendar.today();
    const today = new Date();
    this.calendar.gotoDate(today.toUTCString());
    this.onCalendarDateChange(today.toUTCString());
  }

  public onCalendarPrevious(): void {
    this.calendar.prev();
  }

  public onCalendarNext(): void {
    this.calendar.next();
  }

  public onCalendarDay(): void {
    // const selectedDate: any = moment(selectionInfo.end).format('YYYY-MM-DD hh:mm')
    this.calendar.changeView('timelineDay');
  }

  public onCalendarDateChange(date: any): void {
    // //this.logger.info(
    //     "RoomManagementComponent.onCalendarDateChange() -> currentDate: " +
    //         this.currentDate
    // );
    // //this.logger.info(
    //     "RoomManagementComponent.onCalendarDateChange() -> date: " + date
    // );
    const date2: Date = new Date(date);
    // if (date1.getUTCMonth() !== date2.getUTCMonth()) {
    //     this.currentDate = date;
    //     // this.loadData();
    // }
    this.currentDate = date;
  }

  public onDatePickerChange(event: any): void {
    //this.logger.info("onDatePickerChange =>  event :: " + event.value);
    this.calendar.gotoDate(new Date(event.value));
    this.onCalendarDateChange(event.value);
  }
}
