import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { FuseMaterialColorPickerComponent } from './material-color-picker.component';
import { KeysPipe } from '../../pipes/keys.pipe';

@NgModule({
  declarations: [FuseMaterialColorPickerComponent, KeysPipe],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
  ],
  exports: [FuseMaterialColorPickerComponent],
})
export class FuseMaterialColorPickerModule {}
