<div fxLayout="row">
  <button
    *ngxPermissionsOnly="rolesService.SALE_ROLES"
    mat-button
    (click)="onNewProposal()"
    class="icon-mat"
  >
    <mat-icon class="icon-mat s-24">{{ getIcon("PROPOSAL") }}</mat-icon>
    {{ "GENERIC.CREATE_PROPOSAL" | translate }}
  </button>

  <button
    *ngxPermissionsOnly="rolesService.SALE_ROLES"
    mat-button
    (click)="onCreateOrder()"
    class="icon-mat"
  >
    <mat-icon class="icon-mat s-24">{{ getIcon("ORDER") }}</mat-icon>
    {{ "GENERIC.CREATE_ORDER" | translate }}
  </button>
</div>
