export const locale = {
  lang: "fr-CA",
  data: {
    CREATED_AT: {
      CREATED_ON: "Créé le ",
      BY: "par",
      LAST_MODIFICATION: "Dernière modification le",
    },
  },
};
