import { ModulesService } from './../../../../services/modules.service';
import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventsService } from '../../../../api/events.service';
import { GT2FormPageAbstract } from '../../../../content/abstract/GT2FormPageAbstract';
import { RolesService } from '../../../../services/roles.service';
import { Settings } from '../../../../settings/settings';
import { ValidatorHelper } from '../../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { locale as english } from '../../i18n/en-CA';
import { locale as french } from '../../i18n/fr-CA';
import { EmployeesService } from '../../../../api/employees.service';
import { Gt2LocaleService } from '../../../../../localization/gt2-locale.service';
import { EventTypeService } from '../../../../api/event-type.service';
import { GT2DateAdapter } from '../../../../utils/GT2DateAdapter';
import '../../../../../../../node_modules/flatpickr/dist/flatpickr.css';
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TimeModel } from '../event-information/event-information.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FlexLayoutModule } from '@angular/flex-layout';
@Component({
  selector: 'app-event-storage-notice',
  templateUrl: './event-storage-notice.component.html',
  styleUrls: ['./event-storage-notice.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    FlexLayoutModule,
    NgxMaterialTimepickerModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    TranslateModule,
  ],
  animations: fuseAnimations,
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
})
export class EventStorageNoticeComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() event: any;
  eventUUDID: any;
  loading: boolean = false;
  editMode: boolean = false;
  form: UntypedFormGroup;
  declare dialogRef: any;
  @Output() modelUpdatedEvent = new EventEmitter<any>();
  timePickerChangeSubject = new Subject<TimeModel>();
  dirty: boolean = false;
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private toastr: ToastrService,
    public logger: NGXLogger,
    public location: Location,
    private dialog: MatDialog,
    public rolesService: RolesService,
    private formBuilder: UntypedFormBuilder,
    private eventsService: EventsService,
    public gt2LocalService: Gt2LocaleService,
    public employeesService: EmployeesService,
    public eventTypeService: EventTypeService,
    private adapter: DateAdapter<any>,
    public router: Router,
    public moduleService: ModulesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);
    this.form = this.formBuilder.group({
      organizer_person: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      receipt_notice_local: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      storage_date: ['', [ValidatorHelper.dateRangeDefault]],
      storage_date_end: ['', [ValidatorHelper.dateRangeDefault]],
    });
  }
  ngOnInit() {}
  ngOnDestroy(): void {}
  ngAfterViewInit(): void {
    this.timePickerChangeSubject
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        tap((model) => {
          if (
            model.data.receipt_notice_hour &&
            model.data.receipt_notice_hour !== 'Invalid date'
          ) {
            this.dirty = false;
            this.autosaveDataChange(model.data);
          } else {
            this.dirty = true;
          }
        }),
      )
      .subscribe();
  }

  public onToggleEdit(): void {
    this.editMode = !this.editMode;
    //this.logger.info("EventNoticeOfReceiptComponent.onEdit() -> this.editMode: " + this.editMode);
  }

  public onManualSave(): void {
    //this.logger.info("EventNoticeOfReceiptComponent.onManualSave() -> " + JSON.stringify(this.event));
    this.loading = true;

    // hack: invoicing_local & ordering_local are not in the informations object therefore if would not save.
    //       by injecting those 2 values in it, it will save
    this.event.informations.invoicing_local = this.event.invoicing_local;
    this.event.informations.ordering_local = this.event.ordering_local;
    /*this.eventsService
            .updateEventReceiptNotice(
                this.event.uuid,
                this.event.storage_notice
            )
            .subscribe(
                (response) => {
                    // //this.logger.info("EventNoticeOfReceiptComponent.loadData() -> SUCCESS: " + JSON.stringify(response));
                    this.form.markAsPristine();
                    this.form.markAsUntouched();
                    this.onDispatchModelUdpated();
                    // this.validateIdentity();
                },
                (error) => {
                    this.logger.error(
                        "EventNoticeOfReceiptComponent.loadData() -> ERROR: " +
                        JSON.stringify(error)
                    );
                    this.handleAPIError(
                        error,
                        this.dialog,
                        this.toastr,
                        this.translate.instant("GENERIC.ERRORS.GENERIC")
                    );
                    // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
                }
            );*/
  }
  public onStorageDateChange(): void {
    //
  }
  public onStorageDateEndChange(): void {
    //
  }
  public clearStorageDate(): void {
    this.event.informations.storage_date = null;
    this.form.controls['storage_date'].setErrors({ invalid: true });
    this.form.controls['storage_date'].updateValueAndValidity();
    this.form.controls['storage_date'].markAsDirty();
    this.form.controls['storage_date'].markAsTouched();
  }
  public clearStorageDateEndChange(): void {
    this.event.informations.storage_date_end = null;
    this.form.controls['storage_date_end'].setErrors({ invalid: true });
    this.form.controls['storage_date_end'].updateValueAndValidity();
    this.form.controls['storage_date_end'].markAsDirty();
    this.form.controls['storage_date_end'].markAsTouched();
  }
  public onTimeChange(): void {
    const time = moment(this.event.informations.event_time, 'h:mm A').format(
      'HH:mm',
    );
    //this.logger.info("EventInformationComponent.onTimeChange() -> time: " + time);
    this.timePickerChangeSubject.next({ data: { event_time: time } });
  }
  public onModelLoaded(): void {
    //this.validateIdentity();
  }
  public onDispatchModelUdpated(): void {
    // //this.logger.info('EventNoticeOfReceiptComponent.onDispatchModelUdpated()');
    this.modelUpdatedEvent.next(undefined);
  }
}
