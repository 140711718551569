import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FlowService } from '../../../../api/flow.service';
import { CreateFlowComponent } from '../../../../components/dialogs/create-flow/create-flow.component';
import { GenericAlertComponent } from '../../../../components/generic-alert/generic-alert.component';
import { GT2FormPageAbstract } from '../../../../content/abstract/GT2FormPageAbstract';
import { RolesService } from '../../../../services/roles.service';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from '../../i18n/en-CA';
import { locale as french } from '../../i18n/fr-CA';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-event-flow',
  templateUrl: './event-flow.component.html',
  styleUrls: ['./event-flow.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  animations: fuseAnimations,
})
export class EventFlowComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() event: any;
  @Output() eventChange = new EventEmitter<any>();
  @Input() editable: boolean = true;
  @Output() editableChange = new EventEmitter<any>();
  loading: boolean = false;
  editMode: boolean = false;
  declare dialogRef: any;
  @Output() modelUpdatedEvent = new EventEmitter<any>();
  confirmDialogRef: any;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public logger: NGXLogger,
    public location: Location,
    private dialog: MatDialog,
    public rolesService: RolesService,
    private flowService: FlowService,
    public router: Router,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // //this.logger.info("EventFlowComponent.ngAfterViewInit() -> flows: " + JSON.stringify(this.event.flows));
  }

  ngOnDestroy() {}

  public loadData(): void {}

  public onToggleEdit(): void {
    this.editMode = !this.editMode;
    // //this.logger.info("EventFlowComponent.onEdit() -> this.editMode: " + this.editMode);
  }

  public onSaveData(): void {
    // //this.logger.info("EventFlowComponent.onSaveData()");
  }

  public onManualSave(): void {
    //this.logger.info("EventFlowComponent.onManualSave()");
  }

  public onModelLoaded(): void {
    this.loading = false;
  }

  public onDispatchModelUdpated(): void {
    // //this.logger.info("EventFlowComponent.onDispatchModelUdpated()");
    this.modelUpdatedEvent.next(undefined);
  }

  public onEditFlow(flow: any): void {
    // //this.logger.info("EventFlowComponent.onEditFlow() -> flow: " + JSON.stringify(flow));
    this.dialogRef = this.dialog.open(CreateFlowComponent, {
      width: '440px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        autoRedirect: false,
        eventUUID: this.event.uuid,
        mode: CreateFlowComponent.EDIT,
        date: this.event.informations.event_date,
        flowToUpdate: flow,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // //this.logger.info("EventComponent.onEditFlow() -> dialog closed: result: " + JSON.stringify(result));
        this.onDispatchModelUdpated();
      }
      this.dialogRef = null;
    });
  }

  public onDeleteFlow(flow: any): void {
    // //this.logger.info("EventFlowComponent.onDeleteFlow() -> flow: " + JSON.stringify(flow));

    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.loading = true;
    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.flowService.deleteFlow(flow, this.event.uuid).subscribe(
          (response) => {
            // //this.logger.info("EventFlowComponent.onDeleteFlow() -> SUCCESS: " + JSON.stringify(response));

            this.onDispatchModelUdpated();
          },
          (error) => {
            this.logger.error(
              'EventFlowComponent.onDeleteFlow() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
          },
        );
      } else {
        this.loading = false;
      }
      this.confirmDialogRef = null;
    });
  }
}
