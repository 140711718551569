export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_ENTERPRISE: {
      TITLE: "Nouvelle entreprise",
      TITLE_CONTACT: "Infos contact entreprise",
      TITLE_ADDRESS: "Infos address entreprise",
    },
  },
};
