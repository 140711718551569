<div fxLayout="row" fxLayoutAlign="start start">
  <div class="calendar-content">
    <mat-calendar
      *ngIf="businessDays"
      #daysCalendar
      [dateClass]="dateClass"
      (selectedChange)="onBusinessDaySelect($event)"
    ></mat-calendar>
  </div>

  <!-- style="height: 100%; background-color: #dedede;" -->
  <div fxLayout="column" fxFlex class="day-content p-12">
    <div *ngIf="selectedDate">
      <div class="info-line pb-8">
        <div class="title date-label">
          {{ selectedDate.toLocaleDateString(translate.currentLang) }}
        </div>
      </div>

      <div class="info-line">
        <div class="title">{{ "SHOPS.BUSINESS_HOURS" | translate }}</div>
      </div>
      <mat-radio-group
        [disabled]="!editMode"
        [(ngModel)]="calendarDayState"
        (change)="onDaysRadioChange($event)"
        fxLayout="column"
        aria-label="Select an option"
        fxFlexFill
      >
        <mat-radio-button [value]="0">{{
          "SHOPS.NO_CHANGE" | translate
        }}</mat-radio-button>
        <mat-radio-button [value]="1">{{
          "SHOPS.CLOSED" | translate
        }}</mat-radio-button>
        <mat-radio-button [value]="2">{{
          "SHOPS.OPEN" | translate
        }}</mat-radio-button>
      </mat-radio-group>
      <div *ngIf="calendarDayState === 2" class="p-8">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="from-to">
            <div class="title">
              {{ "GENERIC.FROM" | translate | uppercase }}
            </div>
          </div>

          <ngx-timepicker-field
            [buttonAlign]="'left'"
            [format]="24"
            [disabled]="!editMode"
            [(ngModel)]="selectedBusinessDay.open_business_custom_hour_start_at"
            class="no-border mb-4"
          >
          </ngx-timepicker-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="from-to">
            <div class="title">{{ "GENERIC.TO" | translate | uppercase }}</div>
          </div>

          <ngx-timepicker-field
            [buttonAlign]="'left'"
            [disabled]="!editMode"
            [format]="24"
            [(ngModel)]="selectedBusinessDay.open_business_custom_hour_end_at"
            class="no-border"
          >
          </ngx-timepicker-field>
        </div>
      </div>

      <!-- TAKE OUT -->
      <div class="info-line mt-28">
        <div class="title">{{ "SHOPS.TAKE_OUT" | translate }}</div>
      </div>
      <mat-radio-group
        [disabled]="!editMode"
        [(ngModel)]="calendarDayTakeOutState"
        (change)="onDaysTakeoutRadioChange($event)"
        fxLayout="column"
        aria-label="Select an option"
        fxFlexFill
      >
        <mat-radio-button [value]="0">{{
          "SHOPS.NO_CHANGE" | translate
        }}</mat-radio-button>
        <mat-radio-button [value]="1">{{
          "SHOPS.CLOSED" | translate
        }}</mat-radio-button>
        <mat-radio-button [value]="2">{{
          "SHOPS.OPEN" | translate
        }}</mat-radio-button>
      </mat-radio-group>

      <div *ngIf="calendarDayTakeOutState === 2" class="p-8">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="from-to">
            <div class="title">
              {{ "GENERIC.FROM" | translate | uppercase }}
            </div>
          </div>

          <ngx-timepicker-field
            [buttonAlign]="'left'"
            [disabled]="!editMode"
            [format]="24"
            [(ngModel)]="
              selectedBusinessDay.takeout_business_custom_hour_start_at
            "
            class="no-border mb-4"
          >
          </ngx-timepicker-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="from-to">
            <div class="title">{{ "GENERIC.TO" | translate | uppercase }}</div>
          </div>

          <ngx-timepicker-field
            [buttonAlign]="'left'"
            [disabled]="!editMode"
            [format]="24"
            [(ngModel)]="
              selectedBusinessDay.takeout_business_custom_hour_end_at
            "
            class="no-border"
          >
          </ngx-timepicker-field>
        </div>
      </div>
    </div>

    <!-- <div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start" class="mt-16">
            <button (click)="onSaveBusinessDay()" color="accent" class="save-btn" [disabled]="!shop.address"
                mat-raised-button>
                {{ 'GENERIC.SAVE' | translate }}
            </button>
        </div> -->
  </div>
</div>
