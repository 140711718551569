export const locale = {
  lang: "fr-CA",
  data: {
    PRODUCTS_CATEGORIES: {
      CREATE_NEW_CATEGORY: "Nouvelle catégorie de produit",
      CREATE_SUCCESS_TOAST: "Succès de la création!",
      CATEGORY_NAME: "Nom de la catégorie de produit",
      ACCOUNTING_NUMBER: "Compte de grand livre",
      NEW_CATEGORY_NAME: "Nom de la nouvelle catégorie",
      CREATE_SUCCESS: "Catégorie créer avec succès!",
      EDIT_SUCCESS: "Catégorie modifiée avec succès!",
      DELETE_SUCCESS: "Catégorie effacée avec succès!",
      LEDGER_ACCOUNT: "Compte de grand livre",
    },
  },
};
