import { GT2CalendarUtils } from './../../../utils/GT2CalendarUtils';
import { GT2DateAdapter } from './../../../utils/GT2DateAdapter';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { NGXLogger } from 'ngx-logger';
import { CollectionTableDataSource } from './../../../components/collection-table/collection-table.component';
import { IDynamicTableItemAction } from './../../../components/dynamic-table/dynamic-table.component';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ShopsService } from '../../../api/shops.service';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-shop-rte-schedule-edit-data-table',
  templateUrl: './shop-rte-schedule-edit-data-table.component.html',
  styleUrls: ['./shop-rte-schedule-edit-data-table.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    MatFormFieldModule,
    FormsModule,
    MatDatepickerModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CdkTableModule,
    CommonModule,
    TranslateModule,
  ],
  animations: fuseAnimations,
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
})
export class ShopRteScheduleEditDataTableComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() shopUUID?: string;
  @Input() scheduleType?: string;
  @Input() collectionData!: any[];
  @Input()
  displayedColumns: string[] = [];
  @Input() displayedColumnsHeaderName?: string[];
  // @Input() displayedColumnsType: string[];
  @Input() dynamicTableItemAction?: IDynamicTableItemAction;
  @Output() scrollEvent = new EventEmitter<any>();
  dataSource?: CollectionTableDataSource | null;
  loading: boolean = false;

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private logger: NGXLogger,
    private dialog: MatDialog,
    private shopService: ShopsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataSource = new CollectionTableDataSource(this.collectionData);
    // //this.logger.info('>>> collectionData: ' + JSON.stringify(this.collectionData));
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public reload(data: any): void {
    //this.logger.info("ShopRteScheduleEditDataTableComponent.reload() !!!!!!!!! ");
    this.collectionData = data;
    this.dataSource = new CollectionTableDataSource(this.collectionData);
    this.changeDetectorRefs.detectChanges();
  }

  public onDateChange(model: any, key: any): void {
    // //this.logger.info("ShopRteScheduleEditDataTableComponent.onDateChange() -> " + JSON.stringify(model));
    // //this.logger.info("ShopRteScheduleEditDataTableComponent.onDateChange() -> " + model[key]);
    model[key] = GT2CalendarUtils.formatDateForAPI(model[key]);
  }

  public onSelect(selected: any): void {
    // //this.logger.info("ShopRteScheduleEditDataTableComponent.onSelect() -> " + JSON.stringify(selected));
    // this.dynamicTableItemAction.onItemClick(selected);
  }

  public onSaveRow(row: any): void {
    this.loading = true;
    //this.logger.info("ShopRteScheduleEditDataTableComponent.onSaveRow() -> " + JSON.stringify(row));
    //this.logger.info("ShopRteScheduleEditDataTableComponent.onSaveRow() -> row.uuid: " + row.uuid);
    if (!row.uuid) {
      this.postNewRow(row);
    } else {
      this.shopService.saveScheduleByUUID(row.uuid, row).subscribe(
        (response: any) => {
          //this.logger.info("ShopRteScheduleEditDataTableComponent.onSaveRow() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
        },
        (error: any) => {
          this.logger.error(
            'ShopRteScheduleEditDataTableComponent.onSaveRow() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
    }
  }

  public postNewRow(row: any): void {
    if (this.scheduleType === 'takeout') {
      row.delivery_start_at = row.takeout_start_at;
      row.delivery_start_time = row.takeout_start_time;
      row.delivery_end_at = row.takeout_end_at;
      row.delivery_end_time = row.takeout_end_time;
    } else {
      row.takeout_start_at = row.delivery_start_at;
      row.takeout_start_time = row.delivery_start_time;
      row.takeout_end_at = row.delivery_end_at;
      row.takeout_end_time = row.delivery_end_time;
    }

    this.shopService.postScheduleByUUID(row).subscribe(
      (response: any) => {
        //this.logger.info("ShopRteScheduleEditDataTableComponent.postNewRow() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
      },
      (error: any) => {
        this.logger.error(
          'ShopRteScheduleEditDataTableComponent.postNewRow() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public onDeleteRow(row: any): void {
    //this.logger.info("ShopRteScheduleEditDataTableComponent.onDeleteRow() -> " + JSON.stringify(row));
    this.loading = true;
    this.shopService.deleteScheduleByUUID(row.uuid).subscribe(
      (response: any) => {
        //this.logger.info("ShopRteScheduleEditDataTableComponent.onDeleteRow() -> SUCCESS: " + JSON.stringify(response));
        this.collectionData = this.collectionData?.filter((item) => {
          if (!item.uuid && !row.uuid) {
            return false;
          }
          return item.uuid !== row.uuid;
        });
        this.reload(this.collectionData);
      },
      (error: any) => {
        this.logger.error(
          'ShopRteScheduleEditDataTableComponent.loadShop() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public onAddRow(): void {
    //this.logger.info("ShopRteScheduleEditDataTableComponent.onAddRow() -> ");
    const today = GT2CalendarUtils.formatDateForAPI(new Date());
    const time: string = '00:00';
    const newRow: any = {
      shop_uuid: this.shopUUID,
      with_takeout: true, // this.scheduleType === 'takeout',
      with_delivery: true, // this.scheduleType !== 'takeout',
      start_at: today,
      start_time: time,
      end_at: today,
      end_time: time,
      delivery_start_at: today,
      delivery_start_time: time,
      delivery_end_at: today,
      delivery_end_time: time,
      takeout_start_at: today,
      takeout_start_time: time,
      takeout_end_at: today,
      takeout_end_time: time,
    };
    this.collectionData?.push(newRow);
    this.reload(this.collectionData);
    this.scrollEvent.emit();
  }
}
