import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ICanDeactivateComponent> {
  constructor(public inj: Injector) {}

  canDeactivate(component: ICanDeactivateComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url: string = state.url;

    if (!component.canDeactivate()) {
      const translate = this.inj.get(TranslateService);
      if (confirm(translate.instant("GENERIC.PAGE_FORM_GARD"))) {
        return true;
      } else {
        return false;
      }
    }

    return component.canDeactivate ? component.canDeactivate() : true;
  }
}

export interface ICanDeactivateComponent {
  canDeactivate(): boolean;
}
