import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { ClientsCategoryModel } from '../../../api/clients-categories.service';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TaxesService } from '../../../api/taxes.service';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { CrudDatatableGenericService } from '../../../api/crud-datatable-generic.service';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { Settings } from '../../../settings/settings';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { of, merge } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { DeliveryRequestTemplateService } from '../../../api/delivery-request-template.service';
import { CreateNewDeliveryTemplateComponent } from './create-new-delivery-template/create-new-delivery-template.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TableSizePipeModule } from '../../../modules/table-size.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CdkTableModule } from '@angular/cdk/table';

@Component({
  selector: 'app-delivery-requests-templates',
  templateUrl: './delivery-requests-templates.component.html',
  styleUrls: ['./delivery-requests-templates.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    MatIconModule,
    TableSizePipeModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatButtonModule,
    MatInputModule,
    MatSortModule,
    FormsModule,
    FlexLayoutModule,
    CdkTableModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
    CommonModule,
  ],
  providers: [CrudDatatableGenericService],
  animations: fuseAnimations,
})
export class DeliveryRequestsTemplatesComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() headerLabel!: string;
  serviceURL: string;
  dataSource = new MatTableDataSource();
  displayedColumns = ['template_name', 'edit', 'delete'];
  selected!: ClientsCategoryModel;
  editSelected: any;
  form: UntypedFormGroup;
  newCategory!: string;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  confirmDialogRef: any;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  currentLanguage?: string;
  deliveryRequestTemplates: any;

  constructor(
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public translate: TranslateService,
    // public ledgerAccountService: LedgerAccountService,
    public deliveryRequestTemplateService: DeliveryRequestTemplateService,
    public taxesService: TaxesService,
    public datatableHelperService: DatatableHelperService,
    private crudDatatableGenericService: CrudDatatableGenericService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.form = this.formBuilder.group({
      name_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      price: [
        '',
        [
          ValidatorHelper.isPositive,
          ValidatorHelper.isNumber0To1000000,
          Validators.required,
        ],
      ],
    });

    this.serviceURL = ApiRoutes.DELIVERY_REQUEST_TYPE_TEMPLATE;

    this.deliveryRequestTemplateService.getDeliveryRequestTemplates().subscribe(
      (response: any) => {
        //this.logger.info("DeliveryRequestsTemplatesComponent.getDeliveryRequestTemplates() -> SUCCESS:" + JSON.stringify(response));
        this.deliveryRequestTemplates = response.data;
        // //this.logger.info("DeliveryRequestsTemplatesComponent.getDeliveryRequestTemplates() -> SUCCESS:" + JSON.stringify(this.deliveryRequestTemplates));
      },
      (error: any) => {
        this.logger.error(
          'DeliveryRequestsTemplatesComponent.getDeliveryRequestTemplates() -> ERROR:' +
            JSON.stringify(error),
        );
      },
    );

    // this.ledgerAccountService.getLedgersAccount().subscribe( response =>
    // {
    //     // //this.logger.info("DeliveryRequestsTemplatesComponent.getLedgersAccount() -> SUCCESS:" + JSON.stringify(response));
    //     this.ledgerAccounts = response.data;
    //     // //this.logger.info("DeliveryRequestsTemplatesComponent.getLedgersAccount() -> SUCCESS:" + JSON.stringify(this.ledgerAccounts));
    // }, error =>
    // {
    //     this.logger.error("DeliveryRequestsTemplatesComponent.getLedgersAccount() -> ERROR:" + JSON.stringify(error));
    // });
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          return this.crudDatatableGenericService.getAll(
            this.serviceURL,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
          );
        }),
      )
      .pipe(
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe((data) => (this.dataSource.data = data));
  }

  public onSelect(selected: any) {
    // //this.logger.info("ServiceChargeComponent.onSelect() -> " + selected);
    if (selected.editable) {
      this.onEdit(selected);
    }
  }

  public reloadData(): void {
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onCreateNew() {
    //this.logger.info("DeliveryRequestsTemplatesComponent.onCreateNew() ");

    this.confirmDialogRef = this.dialog.open(
      CreateNewDeliveryTemplateComponent,
      {
        disableClose: false,
        width: '600px',
        minWidth: 350,
        panelClass: 'scrollable-dialog',
        data: {
          mode: CreateNewDeliveryTemplateComponent.CREATE,
          // dialogTitle: this.translate.instant('SERVICE_CHARGE.CREATE_NEW_CATEGORY'),
          // serviceURL: this.serviceURL,
          // ledgerAccounts: this.deliveryRequestTemplates,
          // createPlaceholder: this.translate.instant('SERVICE_CHARGE.NEW_CATEGORY_NAME'),
          // useEnglish: true
        },
      },
    );

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // //this.logger.info("DeliveryRequestsTemplatesComponent.onCreateNew() -> CREATION SUCCESS");
        this.reloadData();
      } else {
        // //this.logger.info("DeliveryRequestsTemplatesComponent.onCreateNew() -> CREATION CANCELED");
      }
      this.confirmDialogRef = null;
    });
  }

  public onEdit(selected: any): void {
    //this.logger.info("DeliveryRequestsTemplatesComponent.onEdit() -> " + JSON.stringify(selected));
    // if (this.editSelected === selected)
    // {
    //     this.editSelected = null;
    // }
    // else
    // {
    //     this.editSelected = selected;
    // }

    this.confirmDialogRef = this.dialog.open(
      CreateNewDeliveryTemplateComponent,
      {
        disableClose: false,
        width: '600px',
        minWidth: 350,
        panelClass: 'scrollable-dialog',
        data: {
          mode: CreateNewDeliveryTemplateComponent.EDIT,
          deliveryRequestToUpdate: selected,
          // dialogTitle: this.translate.instant('SERVICE_CHARGE.CREATE_NEW_CATEGORY'),
          // serviceURL: this.serviceURL,
          // ledgerAccounts: this.deliveryRequestTemplates,
          // createPlaceholder: this.translate.instant('SERVICE_CHARGE.NEW_CATEGORY_NAME'),
          // useEnglish: true
        },
      },
    );

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // //this.logger.info("DeliveryRequestsTemplatesComponent.onCreateNew() -> CREATION SUCCESS");
        this.reloadData();
      } else {
        // //this.logger.info("DeliveryRequestsTemplatesComponent.onCreateNew() -> CREATION CANCELED");
      }
      this.confirmDialogRef = null;
    });
  }

  public onDelete(selected: ClientsCategoryModel) {
    // //this.logger.info("ServiceChargeComponent.onDelete() -> " + selected);
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.crudDatatableGenericService
          .delete(ApiRoutes.DELIVERY_REQUEST_TYPE_TEMPLATE, selected)
          .subscribe(
            (response: any) => {
              // //this.logger.info("DeliveryRequestsTemplatesComponent.onDelete() -> SUCCESS:" + JSON.stringify(response));
              this.toastr.success(
                '',
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.reloadData();
            },
            (error: any) => {
              this.logger.error(
                'DeliveryRequestsTemplatesComponent.onDelete() -> ERROR' +
                  JSON.stringify(error),
              );
              // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          );
      }
      this.confirmDialogRef = null;
    });
  }

  // public onEditCategoryFocusOut(selected: CrudDatatableGenericModel, name: string, local: string): void
  // {
  //     // //this.logger.info("DeliveryRequestsTemplatesComponent.onEditCategoryFocusOut() -> name: " + name);
  //     // //this.logger.info("DeliveryRequestsTemplatesComponent.onEditCategoryFocusOut() -> local: " + local);
  //     this.autosaveDataChange(selected);
  // }

  // public onSaveLegdgerAccount(editSelected: any): void
  // {
  //     // //this.logger.info("DeliveryRequestsTemplatesComponent.onSaveLegdgerAccount() -> editSelected: " + JSON.stringify(editSelected));
  //     this.autosaveDataChange(editSelected);
  // }

  // public autosaveDataChange(data: any): void
  // {
  //     // //this.logger.info("DeliveryRequestsTemplatesComponent.autosaveDataChange() -> data to save: " + JSON.stringify(data));
  //     this.crudDatatableGenericService.edit( this.serviceURL, data).subscribe(response =>
  //     {
  //         // //this.logger.info("DeliveryRequestsTemplatesComponent.onEdit() -> SUCCESS:" + JSON.stringify(response));
  //         this.toastr.success(this.translate.instant("SERVICE_CHARGE.EDIT_SUCCESS"));
  //         this.reloadData();
  //     }, error =>
  //     {
  //         // //this.logger.info("DeliveryRequestsTemplatesComponent.onEdit() -> ERROR" + JSON.stringify(error));
  //         // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
  //         this.handleAPIError(error, this.dialog, this.toastr, this.translate.instant("GENERIC.ERRORS.GENERIC"));
  //     });
  // }

  // public onLanguageChange(lang: string): void
  // {
  //     // //this.logger.info("DeliveryRequestsTemplatesComponent.onLanguageChange() -> " + lang);
  //     this.reloadData();
  // }

  // public onLedgerAccountChange(event: any): void
  // {
  //     // //this.logger.info("DeliveryRequestsTemplatesComponent.onLedgerAccountChange() -> event: " + JSON.stringify(event));
  //     this.editSelected.ledger_account = event;
  // }
  //
  // public onTaxChange(event: any): void
  // {
  //     // //this.logger.info("DeliveryRequestsTemplatesComponent.onTaxChange() -> event: " + JSON.stringify(event));
  //     this.editSelected.tax = event;
  // }
}
