import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class PersonnalTitlesService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getTitles() {
    //this.logger.info("PersonnalTitlesService.getTitles()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PERSONNAL_TITLES));
  }

  public getData(): Observable<any> {
    // this.logger.info("PersonnalTitlesService.getData()");
    return this.getTitles();
  }
}
