<div fxLayout="column">
  <!-- *ngIf="attachedFiles && attachedFiles.length > 0 && dataSource" -->
  <!-- [ngStyle]="{'display': (attachedFiles && attachedFiles.length > 0 && dataSource)? '' : 'none'}" -->
  <div>
    <ngx-dropzone
      fxFlex
      (change)="onSelect($event)"
      [disableClick]="true"
      [multiple]="false"
      [maxFileSize]="10485760"
      accept="{{ supportedFileType }}"
      fxFlex
      class="dropzone-no-outline"
    >
      <mat-table
        #table
        fxFlex
        class=""
        matSort
        matSortStart="desc"
        [@animateStagger]="{ value: '50' }"
        [dataSource]="dataSource"
      >
        <!-- icon Column -->
        <ng-container cdkColumnDef="icon">
          <mat-header-cell fxFlex="58px" *cdkHeaderCellDef></mat-header-cell>
          <mat-cell fxFlex="58px" *cdkCellDef="let file">
            <mat-icon
              *ngIf="(file.name | fileNameExtensionIconPipe) === 'pdf'"
              class="s-24"
              >{{ getIcon("PDF") }}
            </mat-icon>
            <mat-icon
              *ngIf="(file.name | fileNameExtensionIconPipe) === 'xls'"
              class="fa-lg ml-4"
              fontIcon="{{ getIcon('XLS') }}"
              fontSet="fa"
            ></mat-icon>
            <mat-icon
              *ngIf="(file.name | fileNameExtensionIconPipe) === 'image'"
              class="s-24"
            >
              {{ getIcon("IMAGE") }}</mat-icon
            >
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container cdkColumnDef="name">
          <mat-header-cell fxFlex *cdkHeaderCellDef mat-sort-header
            >{{ "GENERIC.NAME" | translate }}
          </mat-header-cell>
          <mat-cell fxFlex *cdkCellDef="let file">
            <div
              *ngIf="file.loading"
              fxFlex
              class="p-16"
              xLayout="row"
              fxLayoutAlign="start center"
            >
              <mat-progress-bar [value]="file.progress"></mat-progress-bar>
            </div>
            <div *ngIf="!file.loading">{{ file.name }}</div>
          </mat-cell>
        </ng-container>

        <!-- fileSize -->
        <ng-container cdkColumnDef="fileSize">
          <mat-header-cell fxFlex="112px" *cdkHeaderCellDef mat-sort-header
            >{{ "GENERIC.FILESIZE" | translate }}
          </mat-header-cell>
          <mat-cell
            fxFlex="112px"
            *cdkCellDef="let file"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <div *ngIf="!file.loading && file.fileSize !== 0">
              {{ file.fileSize }}
            </div>
          </mat-cell>
        </ng-container>

        <!-- action Column -->
        <ng-container cdkColumnDef="action">
          <mat-header-cell fxFlex="100px" *cdkHeaderCellDef></mat-header-cell>
          <mat-cell fxFlex="100px" *cdkCellDef="let file">
            <button
              mat-icon-button
              (click)="onOpen(file)"
              [disabled]="file.loading"
            >
              <mat-icon class="secondary-text icon-btn s-16">{{
                getIcon("FILE_DOWNLOAD")
              }}</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="onDeleteAttachedFile(file)"
              [disabled]="file.loading"
            >
              <mat-icon class="secondary-text icon-btn s-16">{{
                getIcon("DELETE")
              }}</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *cdkRowDef="let employee; columns: displayedColumns"
          class=""
          style="cursor: default"
        >
        </mat-row>
      </mat-table>
    </ngx-dropzone>
  </div>

  <div
    *ngIf="attachedFiles && attachedFiles.length === 0"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="center center"
    class="p-16 mat-body-2"
  >
    <ngx-dropzone
      (change)="onSelect($event)"
      [multiple]="false"
      [maxFileSize]="10485760"
      accept="{{ supportedFileType }}"
      fxFlex
      class="p-8"
    >
      <ngx-dropzone-label>{{
        "GENERIC.NO_ATTACHED_FILES_DROPZONE" | translate
      }}</ngx-dropzone-label>
    </ngx-dropzone>
  </div>
</div>

<!-- <div *ngIf="attachedFiles && attachedFiles.length > 0" fxLayout="column" fxFlex>
    <div *ngFor="let file of attachedFiles" class="file child-item" fxFlex fxLayout="row">
        <div *ngIf="file.loading" fxFlex class="p-16" xLayout="row" fxLayoutAlign="start center">
            <mat-progress-bar [value]="file.progress"></mat-progress-bar>
        </div>
        
        <div *ngIf="!file.loading" fxFlex class="py-8 pl-12" xLayout="row" fxLayoutAlign="start center">
            <div fxFlex="38px" class="">
                <mat-icon *ngIf="(file.name | fileNameExtensionIconPipe) ==='pdf'" class="s-24 ">{{getIcon('PDF')}}</mat-icon>
                <mat-icon *ngIf="(file.name | fileNameExtensionIconPipe) ==='xls'" class="fa-lg" fontIcon="{{ getIcon('XLS') }}" fontSet="fa"></mat-icon>
                <mat-icon *ngIf="(file.name | fileNameExtensionIconPipe) ==='image'" class="s-24">{{getIcon('IMAGE')}}</mat-icon>
            </div>
            <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="">
                <div>{{ file.name }}</div>
            </div>
            
            <div fxFlex="112px" fxLayout="row" fxLayoutAlign="center center" 
                class="border-left border-right">
                {{file.fileSize}}
            </div>

            <div fxFlex="100px" fxLayout="row" fxLayoutAlign="end center">
                <button mat-icon-button (click)="onOpen(file)">
                    <mat-icon class="secondary-text icon-btn s-16">{{ getIcon('FILE_DOWNLOAD') }}</mat-icon>
                </button>
                <button mat-icon-button (click)="onDeleteAttachedFile(file)">
                    <mat-icon class="secondary-text icon-btn s-16">{{ getIcon('DELETE') }}</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div> -->

<div>
  <input
    type="file"
    #fileUpload
    id="fileUpload"
    name="fileUpload"
    accept="application/msword,application/vnd.ms-excel,application/pdf,image/*,.xlsx"
    style="display: none"
  />
</div>
