import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ApiRoutes } from "../../api/ApiRoutes";
import { Gt2ApiService } from "../../api/gt2-api.service";

@Injectable()
export class CommentsService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  // public addComments(apiURL: string, data: any, uuid: string): any
  public addComments(apiURL: string, data: any): any {
    //this.logger.info("CommentsService.addComments() -> apiURL: " + apiURL);
    return this.http.post<any>(this.api.createUrl(apiURL), data);
  }

  public updateComments(data: any, commentsUUID: string): any {
    //this.logger.info("CommentsService.updateComments() -> commentsUUID: " + commentsUUID);
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.UPDATE_COMMENTS) + commentsUUID, data);
  }
}
