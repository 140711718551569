import { Subscription, Subject } from 'rxjs';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DateAdapter, MatRippleModule } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { FuseConfirmDialogComponent } from '../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay,
  CalendarModule as AngularCalendarModule,
} from 'angular-calendar';
import { OrdersService } from '../../../../api/orders.service';
import { UserSettingsService } from '../../../../api/user-settings.service';
import { CreateEventComponent } from '../../../../components/dialogs/create-event/create-event.component';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import { CalendarEventModel } from '../../../../content/calendar/event.model';
import { GT2CalendarUtils } from '../../../../utils/GT2CalendarUtils';
import { ItemUrlFinder } from '../../../../utils/item-url-finder';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { CustomDateFormatter } from '../../../calendar/CustomDateFormatter';
import { Moment } from 'moment';
import { ModulesService } from '../../../../services/modules.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../../@fuse/pipes/pipes.module';
import { MatInputModule } from '@angular/material/input';
import { DashboardNoteComponent } from '../dashboard-note/dashboard-note.component';
import { GT2DateAdapter } from '../../../../utils/GT2DateAdapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-dashboard-calendar',
  templateUrl: './dashboard-calendar.component.html',
  styleUrls: ['./dashboard-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    MatButtonModule,
    AngularCalendarModule,
    MatInputModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatDatepickerModule,
    MatIconModule,
    NgxSpinnerModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    DashboardNoteComponent,
  ],
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class DashboardCalendarComponent
  extends GT2PageAbstract
  implements OnInit
{
  actions?: CalendarEventAction[];
  activeDayIsOpen: boolean;
  confirmDialogRef?: MatDialogRef<FuseConfirmDialogComponent>;
  declare dialogRef: any;
  events: CalendarEvent[] | any;
  refresh: Subject<any> = new Subject();
  selectedDay: any;
  selectedDayForEvents: any;
  view: string | any;
  timeZone: string;
  currentDateWithTZ?: string;
  viewDate: Date;
  day: any;
  iniDate: Moment;
  dateFormat?: string;
  orders: OrderItem[] = [];
  viewEventsDate: any;
  ordersOfTheDay: OrderItem[] = [];
  tabSelectedInit: boolean = false;
  routeParams?: Subscription;
  refreshCalendar: Subject<void> = new Subject();
  firstLoad: boolean = true;
  minDate: Date;
  maxDate: Date;
  isSmallScreen?: boolean;
  @ViewChild('ordersList') ordersList!: ElementRef;

  constructor(
    private adapter: DateAdapter<any>,
    public translate: TranslateService,
    public dialog: MatDialog,
    public ordersService: OrdersService,
    public router: Router,
    private logger: NGXLogger,
    private spinner: NgxSpinnerService,
    public userSettingsService: UserSettingsService,
    private route: ActivatedRoute,
    public modulesService: ModulesService,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();

    moment.updateLocale(this.translate.currentLang, {
      week: {
        dow: 0,
        doy: 0,
      },
    });

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 10, 0, 1);
    this.maxDate = new Date(currentYear + 6, 11, 31);
    this.timeZone = 'America/Montreal';
    this.iniDate = moment().tz(this.timeZone);
    this.view = 'month';
    this.viewEventsDate = this.iniDate;
    this.day = this.iniDate.date();
    if (this.day >= 28) {
      this.day = 1;
    }
    this.viewDate = new Date(
      this.iniDate.year(),
      this.iniDate.month(),
      this.day,
    );
    this.activeDayIsOpen = false;
    this.selectedDay = { date: this.viewEventsDate };
    this.selectedDayForEvents = this.selectedDay;
    this.adapter.setLocale(this.translate.currentLang);
    //
    this.userSettingsService.getSettings().subscribe({
      next: (response: any) => {
        this.dateFormat = response.data.date_format.format_moment;
        this.timeZone = response.data.timezone.code; // 'Europe/Paris';
        this.currentDateWithTZ = moment()
          .tz(this.timeZone)
          .format('YYYY-MM-DD');
        this.loadMonthModelEvents(this.currentDateWithTZ);
      },
      error: (error: any) => {
        this.logger.error(
          'DashboardCalendarComponent.loadStartingData() -> userSettingsService ERRORS: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, dialog, null, null);
      },
    });
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
      },
    });
  }

  public watchParams(): void {
    this.route.queryParams.subscribe({
      next: (params) => {
        let date = params['date'];
        if (date && date !== null && !(date == undefined)) {
          this.dayDateParamReceived(date);
        }
      },
    });
  }

  public tabSelected(): void {
    this.tabSelectedInit = true;
    if (!this.events) {
      this.currentDateWithTZ = moment().tz(this.timeZone).format('YYYY-MM-DD');
      this.loadMonthModelEvents(this.currentDateWithTZ);
    }
  }

  public onCreateEvent(): void {
    this.dialogRef = this.dialog.open(CreateEventComponent, {
      width: '30%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {},
    });

    this.dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
        }

        this.dialogRef = null;
      },
    });
  }

  public loadMonthModelEvents(date: any): void {
    if (!date) {
      date = moment().tz(this.timeZone).format('YYYY-MM-DD');
    }
    this.spinner.show('calenderSpinner');

    this.ordersService.getOrdersByMonth(date).subscribe({
      next: (response: any) => {
        this.orders = response.data;
        this.setEvents();
        this.spinner.hide('calenderSpinner');
        if (this.firstLoad) {
          this.watchParams();
          this.firstLoad = false;
        }
      },
      error: (error: any) => {
        this.spinner.hide('calenderSpinner');
        this.logger.error(
          'DashboardCalendarComponent.loadMonthModelEvents() -> getOrdersByMonth ERRORS: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    });
  }

  public loadWeekModelEvents(date: any): void {
    this.ordersService
      .getOrdersByWeek(GT2CalendarUtils.formatDateForAPI(date))
      .subscribe({
        next: (response: any) => {
          this.orders = response.data;
          this.setEvents();
          if (this.firstLoad) {
            this.watchParams();
            this.firstLoad = false;
          }
        },
        error: (error: any) => {
          this.logger.error(
            'DashboardCalendarComponent.loadWeekModelEvents() -> getOrdersByWeek ERRORS: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }

  public loadDayModelEvents(date: any): void {
    this.ordersService
      .getOrdersByDate(GT2CalendarUtils.formatDateForAPI(date))
      .subscribe({
        next: (response: any) => {
          this.orders = response.data;
          this.setEvents();
        },
        error: (error: any) => {
          this.logger.error(
            'DashboardCalendarComponent.loadDayModelEvents() -> getOrdersByDate ERRORS: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      });
  }
  closeDatePicker(eventData: any, dp?: any) {
    dp.close();
    this.viewDate = eventData;
    this.setSelectedDay(eventData);
  }

  public setSelectedDay(event: any): void {
    this.selectedDay = { date: event };

    if (this.view === 'week') {
      if (!moment(this.selectedDay.date).isSame(this.viewEventsDate, 'week')) {
        this.viewEventsDate = this.selectedDay.date;
        this.loadWeekModelEvents(this.viewEventsDate);
      }
    } else {
      if (!moment(this.selectedDay.date).isSame(this.viewEventsDate, 'month')) {
        this.viewEventsDate = this.selectedDay.date;
        this.loadMonthModelEvents(
          moment(this.viewEventsDate).tz(this.timeZone).format('YYYY-MM-DD'),
        );
      }
    }
  }

  public setWeekView(): void {
    this.view = 'week';
    this.loadWeekModelEvents(this.viewEventsDate);
  }

  public setMonthView(): void {
    this.view = 'month';
    this.loadMonthModelEvents(this.viewEventsDate);
  }
  trackById(index: number): any {
    return index;
  }
  public setDayOrdersList(date: Date): void {
    const momentDate = moment(date);
    this.ordersOfTheDay = this.orders.filter((item) => {
      const sameDay = momentDate.isSame(item.start, 'day');
      return sameDay;
    });

    //this.cdr.detectChanges();
  }

  public onNavigateToOrder(order: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(order.context));
  }

  public onNavigateToOrderSecondContext(order: any): void {
    if (order.order_context) {
      this.router.navigateByUrl(ItemUrlFinder.getItemURL(order.order_context));
    } else {
      this.onNavigateToOrder(order);
    }
  }

  public onNavigateToProjectContext(order: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(order.project_context));
  }

  setEvents(): void {
    this.events = this.orders?.map((item) => {
      return new CalendarEventModel(item);
    });

    if (
      moment(this.selectedDayForEvents.date).isSame(
        moment(this.viewDate),
        'month',
      )
    ) {
      this.setDayOrdersList(this.selectedDayForEvents.date);
    }
  }
  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {}

  dayClicked(day: CalendarMonthViewDay): void {
    const date: Date = day.date;
    const events: CalendarEvent[] = day.events;

    if (moment(date).isSame(moment(this.viewDate), 'month')) {
      if (
        (moment(this.viewDate).isSame(moment(date), 'day') &&
          this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
      } else {
        this.viewDate = date;
      }
      this.setDayOrdersList(date);
    }

    this.selectedDay = day;
    this.selectedDayForEvents = this.selectedDay;
    const strDate = date.toISOString();
    const queryParams = { date: strDate };
    if (strDate && strDate !== null && strDate !== '') {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
    }
  }

  public resetOrdersScroll(): void {
    if (this.ordersList && this.ordersList.nativeElement) {
      setTimeout(() => {
        this.ordersList.nativeElement.scrollTop = 0;
      }, 1);
    }
  }

  dayDateParamReceived(dateStr: string): void {
    this.resetOrdersScroll();
    const day = { date: new Date(dateStr) };
    const date: Date = day.date;
    if (moment(date).isSame(moment(this.viewDate), 'month')) {
      if (
        moment(this.viewDate).isSame(moment(date), 'day') &&
        this.activeDayIsOpen === true
      ) {
      } else {
        this.viewDate = date;
      }
      this.setDayOrdersList(date);
    }
    this.viewDate = date;
    this.selectedDay = day;
    this.selectedDayForEvents = this.selectedDay;
    if (this.view === 'week') {
      if (!moment(this.selectedDay.date).isSame(this.viewEventsDate, 'week')) {
        this.viewEventsDate = this.selectedDay.date;
        this.loadWeekModelEvents(this.viewEventsDate);
      }
    } else {
      if (!moment(this.selectedDay.date).isSame(this.viewEventsDate, 'month')) {
        this.viewEventsDate = this.selectedDay.date;
        this.loadMonthModelEvents(
          moment(this.viewEventsDate).tz(this.timeZone).format('YYYY-MM-DD'),
        );
      }
    }
  }

  /**
   * Event times changed
   * Event dropped or resized
   *
   * @param {CalendarEvent} event
   * @param {Date} newStart
   * @param {Date} newEnd
   */
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
  }

  /**
   * Delete Event
   *
   * @param event
   */
  deleteEvent(event: any): void {}

  /**
   * Edit Event
   *
   * @param {string} action
   * @param {CalendarEvent} event
   */
  editEvent(action: string, event: CalendarEvent): void {}

  addEvent(): void {}
}

type OrderItem = {
  title: string;
  start: string;
  end: string;
  allDay: boolean;
  draggable: boolean;
  project_context: { project_number: string };
  current_order_type: string;
  event_type: string | null;
  event_type_label: string | null;
  color: {
    primary: string;
    secondary: string;
  };
  className: string;
  backgroundColor: string;
  eventBackgroundColor: string;
  resizable: {
    beforeStart: boolean;
    afterEnd: boolean;
  };
  meta: {
    type: string;
    notes: string | null;
  };
  context: {
    object: string;
    uuid: string;
    name: string;
    slug: string;
  };
  order_context: {
    object: string;
    type: string;
    uuid: string;
    name: string;
    slug: string;
  };
  rendering: string;
};
