import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class DeliveryRequestService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public createDeliveryRequest(delivery: any, eventUUID: string) {
    // this.logger.info("DeliveryRequestService.createDeliveryRequest() -> " + JSON.stringify(delivery));
    // this.logger.info("DeliveryRequestService.createDeliveryRequest() -> eventUUID: " + eventUUID);
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT) + "delivery-requests/" + eventUUID, delivery);
  }

  public deleteDeliveryRequest(delivery: any, eventUUID: string) {
    // this.logger.info("DeliveryRequestService.deleteDeliveryRequest() -> " + JSON.stringify(delivery));
    // this.logger.info("DeliveryRequestService.deleteDeliveryRequest() -> eventUUID: " + eventUUID);
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.EVENT) + "delivery-requests/" + eventUUID + "/" + delivery.uuid);
  }

  public updateDeliveryRequest(delivery: any, eventUUID: string) {
    // this.logger.info("DeliveryRequestService.updateDeliveryRequest() -> " + JSON.stringify(delivery));
    // this.logger.info("DeliveryRequestService.updateDeliveryRequest() -> eventUUID: " + eventUUID);
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.EVENT) + "delivery-requests/" + eventUUID + "/" + delivery.uuid, delivery);
  }

  public getNewDeliveryRequestData(eventUUID: string) {
    return this.http.get<any>(this.api.createUrl(ApiRoutes.EVENT_DELIVERY_REQUEST_DATA) + eventUUID);
  }
}
