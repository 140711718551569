import moment from 'moment';

export class GT2CalendarUtils {
  static LOCALES: any = {
    'fr-CA': {
      firstDayOfWeek: 1,
      dayNames: [
        'dimanche',
        'lundi',
        'mardi',
        'mercredi',
        'jeudi',
        'vendredi',
        'samedi',
      ],
      dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
      dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      monthNames: [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
      ],
      monthNamesShort: [
        'jan',
        'fev',
        'mar',
        'avr',
        'mai',
        'jun',
        'jul',
        'août',
        'sept',
        'oct',
        'nov',
        'dec',
      ],
      today: "Aujourd'hui",
      clear: 'Effacer',
    },
    'en-CA': {
      firstDayOfWeek: 1,
      dayNames: [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ],
      dayNamesShort: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
      dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      monthNames: [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
      ],
      monthNamesShort: [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sept',
        'oct',
        'nov',
        'dec',
      ],
      today: 'Today',
      clear: 'Cleay',
    },
  };

  // REMOVE TIME FROM DATE / split
  static formatDateForAPI(date: any): string {
    return moment(date).format().substring(0, 10);
  }

  static formatDateRangeForAPI(dates: DateRange): DateRange {
    const dateRange = {
      date_from: GT2CalendarUtils.formatDateForAPI(dates.date_from),
      date_to: GT2CalendarUtils.formatDateForAPI(dates.date_to),
    };
    return dateRange;
  }
}

export interface DateRange {
  date_from: any;
  date_to: any;
}
