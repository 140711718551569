<!-- <h2 *ngIf="type !== 'CREATE_FROM_RESERVATION'" matDialogTitle>{{ 'ROOM_MANAGEMENT.CREATE_TITLE' | translate }}</h2>
<h2 *ngIf="type === 'CREATE_FROM_RESERVATION'" matDialogTitle>{{ 'ROOM_MANAGEMENT.CREATE_TITLE_FROM_RESERVATION' | translate }}</h2> -->
<div style="max-height: 100%; overflow: auto">
  <div
    *ngIf="type === 'FROM_RESERVATION'"
    fxLayout="column"
    fxLayoutAlign="center start"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      [formGroup]="form"
      class="item-full-width"
    >
      <button
        mat-raised-button
        color="accent"
        class="mb-24 mt-12"
        (click)="onEditReservationUI()"
      >
        {{ "ROOM_MANAGEMENT.EDIT_RESERVATION_TITLE" | translate }}
      </button>

      <button
        mat-raised-button
        color="accent"
        class="mb-24"
        (click)="onCreateEventForReservationUI()"
      >
        {{ "ROOM_MANAGEMENT.CREATE_TITLE_FROM_RESERVATION" | translate }}
      </button>

      <button
        mat-raised-button
        color="warn"
        class="mb-24"
        (click)="onDeleteReservation()"
      >
        {{ "ROOM_MANAGEMENT.DELETE_RESERVATION_TITLE" | translate }}
      </button>

      <button
        mat-raised-button
        color="primary"
        (click)="dialogRef.close(false)"
        class="mb-12"
      >
        {{ "GENERIC.CANCEL" | translate }}
      </button>
    </div>
  </div>

  <!-- EDIT_RESERVATION -->
  <div
    *ngIf="type === 'EDIT_RESERVATION'"
    fxLayout="column"
    fxLayoutAlign="center start"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <button (click)="onBack()" class="white-fg" mat-icon-button>
        <mat-icon class="s-20">arrow_back</mat-icon>
      </button>
      <h2 matDialogTitle>
        {{ "ROOM_MANAGEMENT.EDIT_RESERVATION_TITLE" | translate }}
      </h2>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" class="item-full-width">
      <div
        fxLayout="column"
        fxLayoutAlign="center start"
        [formGroup]="form"
        class="item-full-width"
      >
        <mat-form-field class="item-full-width">
          <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'GENERIC.NAME' | translate }}"
            formControlName="name"
            type="text"
            [(ngModel)]="newEvent.name"
            required
          />
          <mat-error *ngIf="form.controls['name'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="item-full-width">
      <mat-form-field class="item-full-width">
        <mat-label>{{ "ROOM_MANAGEMENT.ORDER_TYPE" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'ROOM_MANAGEMENT.ORDER_TYPE' | translate }}"
          [disabled]="true"
          [(ngModel)]="newEvent.order_type"
        >
          <mat-option [value]="'PROPOSAL'">
            <span class="iso">{{ "GENERIC.PROPOSAL" | translate }}</span>
          </mat-option>
          <mat-option [value]="'ORDER'">
            <span class="iso">{{ "GENERIC.ORDER" | translate }}</span>
          </mat-option>
          <mat-option [value]="'BLOCKED'">
            <span class="iso">{{ "GENERIC.BLOCKED" | translate }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" class="item-full-width">
      <div
        fxLayout="column"
        fxLayoutAlign="end start"
        fxFlex="50"
        [formGroup]="form"
        class="item-full-width"
      >
        <mat-form-field
          class="item-full-width mr-4"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'GENERIC.START' | translate }}"
            formControlName="event_date"
            [matDatepicker]="eventDateFrom"
            min="1900-01-01"
            max="2099-01-01"
            (ngModelChange)="onDateFromChange($event)"
            [(ngModel)]="newEvent.event_date"
            class=""
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="eventDateFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #eventDateFrom></mat-datepicker>

          <mat-error *ngIf="form.controls['event_date'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field
          class="item-full-width mr-4"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'GENERIC.END' | translate }}"
            formControlName="event_date_end"
            [matDatepicker]="eventDateTo"
            min="1900-01-01"
            max="2099-01-01"
            (ngModelChange)="onDateToChange($event)"
            [(ngModel)]="newEvent.event_date_end"
            class=""
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="eventDateTo"
          ></mat-datepicker-toggle>
          <mat-datepicker #eventDateTo></mat-datepicker>

          <mat-error *ngIf="form.controls['event_date_end'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="column"
        fxLayoutAlign="end start"
        fxFlex="50"
        class="item-full-width"
      >
        <div
          class="item-full-width ml-8 ngx-timepicker-field-from"
          fxFlex="100%"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            class="timepicker-custom-label"
          >
            {{ "GENERIC.TIME" | translate }}
            <div class="asterix">&nbsp;*</div>
          </div>
          <ngx-timepicker-field
            [buttonAlign]="'left'"
            [format]="24"
            [(ngModel)]="newEvent.event_time"
            class=""
          >
          </ngx-timepicker-field>
        </div>

        <div class="item-full-width ml-8 ngx-timepicker-field-to" fxFlex="100%">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            class="timepicker-custom-label"
          >
            {{ "GENERIC.TIME" | translate }}
            <div class="asterix">&nbsp;*</div>
          </div>
          <ngx-timepicker-field
            [buttonAlign]="'left'"
            [format]="24"
            [(ngModel)]="newEvent.event_time_end"
            class=""
          >
          </ngx-timepicker-field>
        </div>
      </div>
    </div>

    <div class="mb-4 item-name">
      <b>{{ "ROOM_MANAGEMENT.ROOM_NAME" | translate }}:</b> {{ roomName }}
    </div>

    <div class="mb-4 item-name">
      <b>{{ "ROOM_MANAGEMENT.PLACE_NAME" | translate }}:</b> {{ placeName }}
    </div>

    <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
      <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
    </div>

    <div
      mat-dialog-actions
      class="mt-16 gt2-buttons-theme item-full-width"
      fxLayout="row"
      fxLayoutAlign="center start"
    >
      <button
        mat-raised-button
        color="accent"
        class="mr-16"
        [disabled]="
          (!form.valid &&
            newEvent.event_time !== 'Invalid date' &&
            newEvent.event_time_end !== 'Invalid date') ||
          newEvent.order_type === null
        "
        (click)="onSaveEditReservation()"
      >
        {{ "GENERIC.SAVE" | translate }}
      </button>

      <button
        mat-raised-button
        color="primary"
        (click)="dialogRef.close(false)"
        class=""
      >
        {{ "GENERIC.CANCEL" | translate }}
      </button>
    </div>
  </div>

  <!-- CREATE_EVENT_FROM_RESERVATION -->
  <div
    *ngIf="type === 'CREATE_EVENT_FROM_RESERVATION'"
    fxLayout="column"
    fxLayoutAlign="center start"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <button (click)="onBack()" class="white-fg" mat-icon-button>
        <mat-icon class="s-20">arrow_back</mat-icon>
      </button>
      <h2 matDialogTitle>
        {{ "ROOM_MANAGEMENT.CREATE_TITLE_FROM_RESERVATION" | translate }}
      </h2>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      class="item-full-width mb-4"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="center start"
        [formGroup]="form"
        class="item-full-width"
      >
        <div class="" style="font-size: 20px">
          {{ newEvent.name }}
        </div>
        <!-- <mat-form-field class="item-full-width">
                <input matInput placeholder="{{ 'GENERIC.NAME' | translate }}" formControlName="name" type="text"
                    [(ngModel)]="newEvent.name" required>
                <mat-error *ngIf="form.controls['name'].invalid">{{ 'GENERIC.INPUT_LENGTH_INVALID' | translate }}</mat-error>
            </mat-form-field> -->
      </div>
    </div>

    <div class="item-full-width">
      <mat-form-field class="item-full-width">
        <mat-label>{{ "ROOM_MANAGEMENT.ORDER_TYPE" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'ROOM_MANAGEMENT.ORDER_TYPE' | translate }}"
          [(ngModel)]="newEvent.order_type"
        >
          <mat-option [value]="'PROPOSAL'">
            <span class="iso">{{ "GENERIC.PROPOSAL" | translate }}</span>
          </mat-option>
          <mat-option [value]="'ORDER'">
            <span class="iso">{{ "GENERIC.ORDER" | translate }}</span>
          </mat-option>
          <!-- <mat-option [value]="'BLOCKED'" [disabled]="type === 'CREATE_EVENT_FROM_RESERVATION'">
                    <span class="iso">{{ "GENERIC.BLOCKED" | translate }}</span>
                </mat-option> -->
        </mat-select>
      </mat-form-field>
    </div>

    <!-- <div fxLayout="row" fxLayoutAlign="start start" class="item-full-width">
        <div fxLayout="column" fxLayoutAlign="end start" fxFlex="50" [formGroup]="form" class="item-full-width">
            <mat-form-field class="item-full-width mr-4" fxLayout="row" fxLayoutAlign="start center">
                <input matInput placeholder="{{ 'GENERIC.START' | translate }}" formControlName="event_date"
                    [matDatepicker]="eventDateFrom" min="1900-01-01" max="2099-01-01"
                    [(ngModel)]="newEvent.event_date" class="" disabled>
                <mat-datepicker-toggle matSuffix [for]="eventDateFrom"></mat-datepicker-toggle>
                <mat-datepicker #eventDateFrom></mat-datepicker>
    
                <mat-error *ngIf="form.controls['event_date'].invalid">{{
                    'GENERIC.DATE_DEFAULT_RANGE_INVALID' | translate }}
                </mat-error>
            </mat-form-field>
    
            <mat-form-field class="item-full-width mr-4" fxLayout="row" fxLayoutAlign="start center">
                <input matInput placeholder="{{ 'GENERIC.END' | translate }}" formControlName="event_date_end"
                    [matDatepicker]="eventDateTo" min="1900-01-01" max="2099-01-01" (ngModelChange)="onDateToChange($event)"
                    [(ngModel)]="newEvent.event_date_end" class="" disabled>
                <mat-datepicker-toggle matSuffix [for]="eventDateTo"></mat-datepicker-toggle>
                <mat-datepicker #eventDateTo></mat-datepicker>
    
                <mat-error *ngIf="form.controls['event_date_end'].invalid">{{
                    'GENERIC.DATE_DEFAULT_RANGE_INVALID' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    
        <div fxLayout="column" fxLayoutAlign="end start" fxFlex="50" class="item-full-width">
            <div class="item-full-width ml-8 ngx-timepicker-field-from" fxFlex="100%">
                <div fxLayout="row" fxLayoutAlign="start center" class="timepicker-custom-label">
                    {{ 'GENERIC.TIME' | translate }}
                    <div class="asterix">&nbsp;*</div>
                </div>
                <ngx-timepicker-field [disabled]="true" [buttonAlign]="'left'" [format]="24" [(ngModel)]="newEvent.event_time" class="">
                </ngx-timepicker-field>
            </div>
    
            <div class="item-full-width ml-8 ngx-timepicker-field-to" fxFlex="100%">
                <div fxLayout="row" fxLayoutAlign="start center" class="timepicker-custom-label">
                    {{ 'GENERIC.TIME' | translate }}
                    <div class="asterix">&nbsp;*</div>
                </div>
                <ngx-timepicker-field [disabled]="true" [buttonAlign]="'left'" [format]="24" [(ngModel)]="newEvent.event_time_end" class="">
                </ngx-timepicker-field>
            </div>
        </div>
    </div> -->

    <div class="mb-4 item-name">
      <b>{{ "ROOM_MANAGEMENT.ROOM_NAME" | translate }}:</b> {{ roomName }}
    </div>

    <div class="mb-4 item-name">
      <b>{{ "ROOM_MANAGEMENT.PLACE_NAME" | translate }}:</b> {{ placeName }}
    </div>

    <div
      *ngIf="loading"
      fxLayout="row"
      fxLayoutAlign="center start"
      class="item-full-width"
    >
      <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
    </div>

    <div
      mat-dialog-actions
      class="mt-16 gt2-buttons-theme item-full-width"
      fxLayout="row"
      fxLayoutAlign="center start"
    >
      <button
        mat-raised-button
        color="accent"
        class="mr-16"
        [disabled]="
          newEvent.order_type === null || newEvent.order_type === 'BLOCKED'
        "
        (click)="onCreateEventFromReservation()"
      >
        {{ "GENERIC.SAVE" | translate }}
      </button>

      <button
        mat-raised-button
        color="primary"
        (click)="dialogRef.close(false)"
        class=""
      >
        {{ "GENERIC.CANCEL" | translate }}
      </button>
    </div>
  </div>

  <!-- CREATE FROM SELECTION -->
  <div *ngIf="type === 'CREATE'">
    <h2 matDialogTitle>{{ "ROOM_MANAGEMENT.CREATE_TITLE" | translate }}</h2>
    <div fxLayout="column" fxLayoutAlign="center start">
      <div
        fxLayout="column"
        fxLayoutAlign="center start"
        [formGroup]="form"
        class="item-full-width"
      >
        <mat-form-field class="item-full-width">
          <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'GENERIC.NAME' | translate }}"
            formControlName="name"
            type="text"
            [(ngModel)]="newEvent.name"
            required
          />
          <mat-error *ngIf="form.controls['name'].invalid">{{
            "GENERIC.INPUT_LENGTH_INVALID" | translate
          }}</mat-error>
        </mat-form-field>

        <!-- <mat-form-field fxFlex="100%" class="item-full-width">
              <input matInput [matDatepicker]="eventDate" [(ngModel)]="flow.date" min="1900-01-01" placeholder="{{ 'GENERIC.DATE' | translate}}"
                formControlName="eventDate">
              <mat-datepicker-toggle matSuffix [for]="eventDate"></mat-datepicker-toggle>
              <mat-datepicker #eventDate></mat-datepicker>
        
              <mat-error *ngIf="form.controls['eventDate'].invalid">{{
                'GENERIC.DATE_DEFAULT_RANGE_INVALID' | translate }}
              </mat-error>
            </mat-form-field>
        
            <mat-form-field fxFlex="100%" class="item-full-width">
              <div fxLayout="row" fxLayoutAlign="start center">
                <input matInput type="time" formControlName="eventDateTime" [(ngModel)]="flow.selectedTime">
                <mat-icon color="accent" (click)="openTimePicker()" class="s-16 mr-4 button-cursor">{{ getIcon('TIME') }}</mat-icon>
              </div>
            </mat-form-field> -->
      </div>
    </div>

    <div>
      <mat-form-field class="item-full-width">
        <mat-label>{{ "ROOM_MANAGEMENT.ORDER_TYPE" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'ROOM_MANAGEMENT.ORDER_TYPE' | translate }}"
          [(ngModel)]="newEvent.order_type"
        >
          <mat-option [value]="'PROPOSAL'">
            <span class="iso">{{ "GENERIC.PROPOSAL" | translate }}</span>
          </mat-option>
          <mat-option [value]="'ORDER'">
            <span class="iso">{{ "GENERIC.ORDER" | translate }}</span>
          </mat-option>
          <mat-option
            [value]="'BLOCKED'"
            [disabled]="type === 'CREATE_FROM_RESERVATION'"
          >
            <span class="iso">{{ "GENERIC.BLOCKED" | translate }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start">
      <div
        fxLayout="column"
        fxLayoutAlign="end start"
        fxFlex="50"
        [formGroup]="form"
        class="item-full-width"
      >
        <mat-form-field
          class="item-full-width mr-4"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'GENERIC.START' | translate }}"
            formControlName="event_date"
            [matDatepicker]="eventDateFrom"
            min="1900-01-01"
            max="2099-01-01"
            (ngModelChange)="onDateFromChange($event)"
            [(ngModel)]="newEvent.event_date"
            class=""
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="eventDateFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #eventDateFrom></mat-datepicker>

          <mat-error *ngIf="form.controls['event_date'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field
          class="item-full-width mr-4"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'GENERIC.END' | translate }}"
            formControlName="event_date_end"
            [matDatepicker]="eventDateTo"
            min="1900-01-01"
            max="2099-01-01"
            (ngModelChange)="onDateToChange($event)"
            [(ngModel)]="newEvent.event_date_end"
            class=""
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="eventDateTo"
          ></mat-datepicker-toggle>
          <mat-datepicker #eventDateTo></mat-datepicker>

          <mat-error *ngIf="form.controls['event_date_end'].invalid"
            >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="column"
        fxLayoutAlign="end start"
        fxFlex="50"
        class="item-full-width"
      >
        <!--      <mat-form-field fxFlex="100%" class="item-full-width ml-4">-->
        <!--        <div fxLayout="row" fxLayoutAlign="start center">-->
        <!--          <input matInput type="time" formControlName="event_time" placeholder="{{ 'GENERIC.TIME' | translate}}"-->
        <!--            [(ngModel)]="newEvent.event_time" required>-->
        <!--          <mat-icon color="accent" (click)="openStartTimePicker()" class="s-16 mr-4 button-cursor">{{ getIcon('TIME') }}</mat-icon>-->
        <!--        </div>-->
        <!--      </mat-form-field>-->

        <!--      <mat-form-field fxFlex="100%" class="item-full-width ml-4">-->
        <!--        <div fxLayout="row" fxLayoutAlign="start center">-->
        <!--          <input matInput type="time" formControlName="event_time_end" placeholder="{{ 'GENERIC.TIME' | translate}}"-->
        <!--            [(ngModel)]="newEvent.event_time_end" required>-->
        <!--          <mat-icon color="accent" (click)="openEndTimePicker()" class="s-16 mr-4 button-cursor">{{ getIcon('TIME') }}</mat-icon>-->
        <!--        </div>-->
        <!--      </mat-form-field>-->
        <div
          class="item-full-width ml-8 ngx-timepicker-field-from"
          fxFlex="100%"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            class="timepicker-custom-label"
          >
            {{ "GENERIC.TIME" | translate }}
            <div class="asterix">&nbsp;*</div>
          </div>
          <ngx-timepicker-field
            [buttonAlign]="'left'"
            [format]="24"
            [(ngModel)]="newEvent.event_time"
            class=""
          >
          </ngx-timepicker-field>
        </div>

        <div class="item-full-width ml-8 ngx-timepicker-field-to" fxFlex="100%">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            class="timepicker-custom-label"
          >
            {{ "GENERIC.TIME" | translate }}
            <div class="asterix">&nbsp;*</div>
          </div>
          <ngx-timepicker-field
            [buttonAlign]="'left'"
            [format]="24"
            [(ngModel)]="newEvent.event_time_end"
            class=""
          >
          </ngx-timepicker-field>
        </div>
      </div>
    </div>

    <div class="mb-4 item-name">
      <b>{{ "ROOM_MANAGEMENT.ROOM_NAME" | translate }}:</b> {{ roomName }}
    </div>

    <div class="mb-4 item-name">
      <b>{{ "ROOM_MANAGEMENT.PLACE_NAME" | translate }}:</b> {{ placeName }}
    </div>

    <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
      <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
    </div>

    <div
      mat-dialog-actions
      class="mt-16 gt2-buttons-theme"
      fxLayout="row"
      fxLayoutAlign="center start"
    >
      <button
        mat-raised-button
        color="accent"
        class="mr-16"
        [disabled]="
          (!form.valid &&
            newEvent.event_time !== 'Invalid date' &&
            newEvent.event_time_end !== 'Invalid date') ||
          !newEvent.order_type
        "
        (click)="onCreateEvent()"
      >
        {{ "GENERIC.SAVE" | translate }}
      </button>

      <button
        mat-raised-button
        color="primary"
        (click)="dialogRef.close(false)"
        class=""
      >
        {{ "GENERIC.CANCEL" | translate }}
      </button>
    </div>
  </div>
</div>
