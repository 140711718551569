import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { KnowledgeBaseArticleComponent } from './dialogs/article/article.component';
import { KnowledgeBaseComponent } from './knowledge-base.component';
import { KnowledgeBaseService } from './knowledge-base.service';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.EN.KB,
    component: KnowledgeBaseComponent,
    resolve: {
      knowledgeBase: KnowledgeBaseService,
    },
  },
  {
    path: AppRoutesName.FR.KB,
    component: KnowledgeBaseComponent,
    resolve: {
      knowledgeBase: KnowledgeBaseService,
    },
  },
];

@NgModule({
  declarations: [KnowledgeBaseComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    KnowledgeBaseArticleComponent,
    TranslateModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    MatListModule,
    MatIconModule,
  ],
  exports: [KnowledgeBaseComponent],
  providers: [KnowledgeBaseService],
})
export class KnowledgeBaseModule {}
