export const locale = {
  lang: "fr-CA",
  data: {
    ORDER_MATERIALS: {
      TITLE: "Ajouter un matériel",
      TITLE_CREATE: "Ajouter un matériel",
      LEFT_LIST_LABEL: "Glisser et déposer ou utiliser le bouton + pour ajouter du matériel",
      RIGHT_LIST_LABEL: "Liste des matériels à ajouter",
      CREATE_PRODUCT: "Créer un matériel",
      SEARCH: "recherche par nom interne, nom externe ou code de matériel",
      SEARCH_MATERIAL: "Effectuez une recherche de votre matériel.",
    },
  },
};
