import { RolesService } from '../../services/roles.service';
import { ActivatedRoute } from '@angular/router';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { NGXLogger } from 'ngx-logger';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ShopsService } from '../../api/shops.service';
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import SchedulesV2 from './SchedulesV2';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { MatInputModule } from '@angular/material/input';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@Component({
  selector: 'app-rte-schedule',
  templateUrl: './rte-schedule.component.html',
  styleUrls: ['./rte-schedule.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatInputModule,
    Gt2PrimengModule,
    MatButtonModule,
    NgxMaterialTimepickerModule,
  ],
})
export class RteScheduleComponent extends GT2PageAbstract implements OnInit {
  onSaveRTETemplate() {
    throw new Error('Method not implemented.');
  }
  @Input() shop: any;
  @Input() shopUUID: any;
  @Input() editMode: boolean = false;
  @Input() rteUUID: any;
  @Input() shopTypeSlug: any;
  @Input() shopSlug: any;
  @Input() index: number = 0;

  shopID!: string;
  scheduleUUID!: string;
  shopCatgories!: any[];
  scheduleType!: string;
  loading!: boolean;
  token!: string;
  rteTemplateModel: any;

  constructor(
    public logger: NGXLogger,
    private route: ActivatedRoute,
    public rolesService: RolesService,
    private shopsService: ShopsService,
  ) {
    super();
  }

  contentLoaded = false;
  public rootId = 'rootId';
  ngOnInit(): void {
    const script = document.createElement('script');
    script.src = 'gt-react.umd.js';
    script.onload = () => {
      this.contentLoaded = true;
    };
    document.body.appendChild(script);
    this.route.params.subscribe((params: any) => {
      this.shopID = params.id;
      this.scheduleUUID = params.scheduleUUID;
      this.scheduleType = params.scheduleType;
      this.loading = true;
      this.token = 'token';
      this.shop = null;
      this.loadShop();
    });
  }

  public loadShop(): void {
    //this.logger.info("DeliveryTakeoutComponent.loadShop()");
    this.loading = true;
    this.shopsService.getShopWithCategories(this.shopID).subscribe(
      (responseList) => {
        this.shop = responseList[0].data;
        this.shopCatgories = responseList[1].data;
        this.loading = false;
      },
      (error) => {
        this.logger.error(
          'DeliveryTakeoutComponent.loadShop() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
      },
    );
  }

  public parentInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    this.render();
  }
  ngAfterViewInit(): void {
    this.render();
  }
  ngOnDestroy(): void {}

  private render() {
    ReactDOM.render(
      React.createElement(() => SchedulesV2(this.shopID)),
      document.getElementById(this.rootId),
    );
  }
}
