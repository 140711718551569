export const locale = {
  lang: 'en-CA',
  data: {
    CREATE_DEPOSIT_REQUEST: {
      TITLE: 'New deposit request',
      EDIT_TITLE: 'Edit deposit request',
      PERCENTAGE: 'Percentage calculation',
    },
  },
};
