import { fromEvent, Subscription } from 'rxjs';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { VenuesPlaceService } from '../../../../../gt2/api/venues-place.service';
import { CreatePlaceComponent } from '../../../../../gt2/components/dialogs/create-place/create-place.component';
import { CreateRoomComponent } from '../../../../../gt2/components/dialogs/create-room/create-room.component';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import {
  CdkVirtualScrollViewport,
  ScrollingModule,
} from '@angular/cdk/scrolling';
import * as _ from 'lodash';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CdkTableModule } from '@angular/cdk/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-select-internal-room',
  templateUrl: './select-internal-room.component.html',
  styleUrls: ['./select-internal-room.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    ScrollingModule,
    MatRadioModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    CdkTableModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class SelectInternalRoomComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  places!: any[];
  allPlaces!: any[];
  eventUUID!: string;
  filterValue: string = '';
  selectedRoom: any;
  selectedPlace: any;
  isLoading: boolean = false;
  createDialogRef: any;
  @ViewChild(CdkVirtualScrollViewport)
  virtualScroll!: CdkVirtualScrollViewport;
  @ViewChild('filter') filter!: ElementRef;
  fromEventSubscription!: Subscription;
  skipVenueSearch: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<SelectInternalRoomComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private logger: NGXLogger,
    public dialog: MatDialog,
    private venuesPlaceService: VenuesPlaceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    if (data.eventUUID) {
      this.eventUUID = data.eventUUID;
    }

    if (data.skipVenueSearch) {
      this.skipVenueSearch = data.skipVenueSearch;
    }

    this.loadData();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.fromEventSubscription = fromEvent(this.filter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          //this.logger.info("filter() -> skipVenueSearch: " + this.skipVenueSearch);
          // if ( this.filterValue.length > 1) {
          if (this.filterValue !== '') {
            this.places = [];
            for (let venue of this.allPlaces) {
              // if (
              //     !this.skipVenueSearch &&
              //     venue.name
              //         .toLowerCase()
              //         .indexOf(this.filterValue.toLowerCase()) !==
              //         -1
              // ) {
              //     // //this.logger.info(
              //     //     "filter() -> places WARNING! "
              //     // );
              //     this.places.push(venue);
              // } else if (venue) {
              // }

              for (let room of venue.rooms) {
                if (
                  room.name
                    .toLowerCase()
                    .indexOf(this.filterValue.toLowerCase()) !== -1
                ) {
                  this.places.push({
                    rooms: [room],
                    internal: venue.internal,
                  });
                  // //this.logger.info(
                  //     "filter() -> room2: " +
                  //         JSON.stringify(room)
                  // );
                  continue;
                  break;
                }
              }
            }
            this.onFilter();
          } else {
            this.resetPlaces();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {}

  public trackByUUID(index: any, item: any) {
    return item.uuid;
  }

  public onFilter(): void {
    setTimeout(() => {
      this.virtualScroll?.scrollToIndex(0);
    }, 200);
  }

  public resetPlaces(): void {
    this.places = _.clone(this.allPlaces);
  }

  public loadData(): void {
    //this.logger.info("SelectPlaceRoomComponent.loadData()");
    this.isLoading = true;
    this.venuesPlaceService.getPlacesRoomsList().subscribe(
      (response) => {
        //this.logger.info("SelectPlaceRoomComponent.constructor() -> SUCCESS: " + JSON.stringify(response));
        this.allPlaces = response.data.filter((place: any) => {
          return place.internal;
        });
        this.resetPlaces();
        this.isLoading = false;
      },
      (error) => {
        this.logger.error(
          'SelectPlaceRoomComponent.constructor() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoading = false;
      },
    );
  }

  public onSelectRoom(room: any, place: any): void {
    // //this.logger.info("SelectPlaceRoomComponent.onSelectRoom()");
    this.selectedRoom = room;
    this.selectedPlace = place;
    // //this.logger.info("SelectPlaceRoomComponent.onSelectRoom() -> this.selectedRoom: " + JSON.stringify(this.selectedRoom));
  }

  public onRoomSelected(): void {
    this.dialogRef.close(this.selectedRoom);
  }

  public onAddNewPlace() {
    // //this.logger.info("SelectPlaceRoomComponent.onAddNewPlace()");
    this.createDialogRef = this.dialog.open(CreatePlaceComponent, {
      width: '62%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'scrollable-dialog',
    });

    this.createDialogRef.afterClosed().subscribe((result: any) => {
      // //this.logger.info("SelectPlaceRoomComponent.onAddNewRom() -> result: " + JSON.stringify(result));
      if (result) {
        // //this.logger.info("SelectPlaceRoomComponent.onAddNewRom() -> POP UP Closed!");
        this.loadData();
        // this.router.navigateByUrl('/' + AppRoutes.VENUES + '/' + result.slug + "/" + result.uuid);
        // this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }
      this.createDialogRef = null;
    });
  }

  public onAddNewRom(place: any) {
    // //this.logger.info("SelectPlaceRoomComponent.onAddNewRom()");
    this.createDialogRef = this.dialog.open(CreateRoomComponent, {
      width: '440px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        parentType: CreateRoomComponent.PLACE,
        parentUUID: place.uuid,
      },
    });

    this.createDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.reloadData();
        // //this.logger.info("SelectPlaceRoomComponent.onAddNewRom() -> result:  " + JSON.stringify(result));
        this.loadData();
        // this.router.navigateByUrl("/" + AppRoutes.ROOMS + "/" + result.data.slug + "/" + result.data.uuid )
        // this.router.navigateByUrl(ItemUrlFinder.getItemURL(result.data));
      }
      this.createDialogRef = null;
    });
  }

  public clearSearch(): void {
    this.filterValue = '';
    this.resetPlaces();
    this.onFilter();
  }
}
