import { ItemUrlFinder } from './../../utils/item-url-finder';
import { EnterpriseCashingModalComponent } from './enterprise-cashing-modal/enterprise-cashing-modal.component';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { fuseAnimations } from './../../../../@fuse/animations/index';
import { EnterprisesService } from './../../api/enterprises.service';
import { RoomsService } from './../rooms/rooms.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { NGXLogger } from 'ngx-logger';
import { RolesService } from './../../services/roles.service';
import { Gt2ApiService } from './../../api/gt2-api.service';
import { AppRoutesService } from './../../services/app-routes.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DatatableHelperService } from './../../../services/datatable-helper.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from './../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from './../abstract/GT2PageAbstract';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { locale as english } from './../clients/i18n/en-CA'; // "./i18n/en-CA";
import { locale as french } from './../clients/i18n/fr-CA'; // "./i18n/fr-CA";
import { EnterpriseInvoiceService } from './EnterpriseInvoice.service';
import { of, merge, fromEvent, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  startWith,
  switchMap,
  catchError,
  map,
} from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CdkTableModule } from '@angular/cdk/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TableSizePipeModule } from '../../modules/table-size.module';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-enterprise-invoice',
  templateUrl: './enterprise-invoice.component.html',
  styleUrls: ['./enterprise-invoice.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    FormsModule,
    CdkTableModule,
    MatCheckboxModule,
    MatCardModule,
    TableSizePipeModule,
    FlexLayoutModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  animations: fuseAnimations,
  providers: [EnterpriseInvoiceService, DatatableHelperService],
  // encapsulation: ViewEncapsulation.None,
})
export class EnterpriseInvoiceComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() parentUUID: any;
  @Output() parentUUIDChange = new EventEmitter<any>();
  @Input() editMode: boolean = false;
  @Input() roomsServiceURL: any;
  @Output() roomsServiceURLChange = new EventEmitter<any>();
  //
  displayedColumns = [
    'invoice_number',
    'invoiced_at',
    'total',
    'paid_amount',
    'balance',
    'name',
    'status',
    'actions',
  ];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild('invoicesFilter') invoicesFilter!: ElementRef;
  // filterValue: string = "";
  dataSource = new MatTableDataSource();
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  isFirstLoad: boolean = true;
  tableIsEmpty: boolean = false;
  loading: boolean = false;
  //
  // --o Subscription
  getEmployeesCalendarSubscription!: Subscription;
  fromEventSubscription!: Subscription;
  confirmDialogRefSubscription!: Subscription;
  resendInvitationSubscription!: Subscription;
  //
  checkboxAllComplete = false;
  invoicesFilterValue: string = '';
  checkedInvoices: { [key: string]: any } = {};
  checkedInvoicesLength: number = 0;
  totalSelected: number = 0;
  cashingBalance: number = 0;
  cashingBalanceRounded: number = 0;
  // balance: number = 0;
  //
  cashingAmount: any = null;
  form;
  enterpriseInformation: any;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public router: Router,
    private toastr: ToastrService,
    public datatableHelperService: DatatableHelperService,
    // public print: PrintService,
    // public fileService: FileService,
    public translate: TranslateService,
    public appRoutesService: AppRoutesService,
    private api: Gt2ApiService,
    public rolesService: RolesService,
    private logger: NGXLogger,
    public enterpriseInvoiceService: EnterpriseInvoiceService,
    private roomsService: RoomsService,
    private formBuilder: UntypedFormBuilder,
    private enterprisesService: EnterprisesService,
    private dialog: MatDialog,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    //
    this.form = this.formBuilder.group({
      cashing: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          // Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.loading = true;
    this.setUpInvoices();
  }

  ngOnDestroy() {}

  public setUpInvoices() {
    this.loadTotals();

    // //this.logger.info("setUpRooms()" + this.useDataCollection);
    // //this.logger.info(
    //     "setUpRooms()" + JSON.stringify(this.roomDataCollection)
    // );
    // this.roomURLSegment = this.appRoutesService.rooms;
    // if (this.useDataCollection) {
    //     this.dataSource.data = this.roomDataCollection;
    //     this.roomURLSegment = this.appRoutesService.sub_rooms;
    //     if (this.parentIsSubRoom) {
    //         this.roomURLSegment = this.appRoutesService.sub_rooms2;
    //     }
    //     //this.logger.info("setUpRooms()" + this.roomURLSegment);
    //     return;
    // }
    // //this.logger.info("setUpRooms()" + this.roomURLSegment);

    // If the user changes the sort order, reset back to the first page.
    // //this.logger.info("RoomsComponent.setUpRooms() this.sort: " + this.sort);

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    // //this.logger.info("RoomsComponent.setUpRooms() ");

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            // //this.logger.info("RoomsComponent.setUpRooms().setTimeout() ");
            this.isLoadingResults = true;
            this.loading = true;
          });
          // //this.logger.info("RoomsComponent.setUpRooms().switchMap() ");
          return this.enterpriseInvoiceService.getInvoicesByIDWithPagination(
            this.parentUUID,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.invoicesFilterValue,
          );
          // return this.roomsService.getRoomsByIDWithPagination(
          //     this.roomsServiceURL,
          //     this.sort.active,
          //     this.sort.direction,
          //     this.paginator.pageIndex,
          //     this.paginator.pageSize,
          //     this.filterValue
          // );
        }),
      )
      .pipe(
        map((data) => {
          // //this.logger.info(
          //     "EnterpriseInvoiceComponent.setUpInvoices().subscribe() -> MAP data: " +
          //         JSON.stringify(data)
          // );
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          this.isFirstLoad = false;
          this.tableIsEmpty = data.data.length === 0;
          //
          // const invoices: any[] = [];
          // for (const invoice of data.data) {
          //     if (
          //         invoice.status !== GT2ConstantsEnum.INVOICE_PAID &&
          //         invoice.status !== GT2ConstantsEnum.INVOICE_CANCEL
          //     ) {
          //         invoices.push(invoice);
          //     }
          // }
          // //
          // return invoices;
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            //this.logger.info("EnterpriseInvoiceComponent.setUpInvoices().catch()  ");
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe((data) => {
        this.dataSource.data = data;

        //this.logger.info("INVOICES: " + data.length);
        setTimeout(() => {
          this.loading = false;
        }, 800);

        // //this.logger.info(
        //     "EnterpriseInvoiceComponent.setUpInvoices().subscribe() -> data: " +
        //         JSON.stringify(data[0].invoices)
        // );
        // //this.logger.info(
        //     "EnterpriseInvoiceComponent.setUpInvoices().subscribe() -> data length: " +
        //         JSON.stringify(this.dataSource.data)
        // );
      });
    //
    fromEvent(this.invoicesFilter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(900),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.invoicesFilter?.nativeElement.value.length > 1 ||
            this.invoicesFilter?.nativeElement.value === ''
          ) {
            this.paginator.pageIndex = 0;
            this.reloadInvoicesTable();
          }
        }),
      )
      .subscribe();
  }

  public onCashing(): void {
    this.dialogRef = this.dialog.open(EnterpriseCashingModalComponent, {
      width: '520px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      maxHeight: '96vh',
      disableClose: false,
      data: {
        parentUUID: this.parentUUID,
        cashing_amount: this.cashingAmount,
        invoices: this.checkedInvoices,
        // autoRedirect: true,
        // eventUUID: this.event.uuid,
        // mode: CreateDepositInComponent.CREATE,
        // uiMode: CreateDepositInComponent.getDepositMode(this.event),
        // event: this.event,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.resetCheckBoxes();
        this.reloadInvoicesTable();
        this.loadTotals();
      }
    });
  }

  public loadTotals(): void {
    this.enterpriseInvoiceService
      .getEnterpriseInvoicesTotal(this.parentUUID)
      .subscribe(
        (response: any) => {
          //this.logger.info("EnterpriseInvoiceComponent.getEnterpriseInvoicesTotal().subscribe() -> response: " + JSON.stringify(response));
          this.enterpriseInformation = response;
        },
        (error: any) => {
          this.logger.error(
            'EnterpriseInvoiceComponent.getEnterpriseInvoicesTotal().subscribe() -> error: ' +
              JSON.stringify(error),
          );
        },
      );
  }

  public crunchSelectedInvoice(): void {
    this.totalSelected = 0;
    this.checkedInvoicesLength = Object.keys(this.checkedInvoices).length;
    for (const invoice in this.checkedInvoices) {
      this.totalSelected += parseFloat(
        this.checkedInvoices[invoice].event.balance,
      );
    }

    this.cashingBalance = this.cashingAmount - this.totalSelected;
    this.cashingBalanceRounded =
      Math.round(
        (this.cashingAmount - this.totalSelected + Number.EPSILON) * 100,
      ) / 100;
  }

  public onCashingAmountChange($event: any): void {
    this.crunchSelectedInvoice();
  }

  public removeInvoiceFromTag(invoice: any): void {
    this.setCheckbox(invoice, false);
  }

  public setCheckbox(invoice: any, checked: boolean): void {
    //this.logger.info("EnterpriseInvoiceComponent.setCheckbox() - checked: " + checked);
    // //this.logger.info(
    //     "EnterpriseInvoiceComponent.setCheckbox() - row: " +
    //         JSON.stringify(invoice)
    // );

    // //this.logger.info(
    //     "EnterpriseInvoiceComponent.setCheckbox() - row uuid: " +
    //         this.checkedInvoices[invoice.uuid]
    // );

    if (checked) {
      this.checkedInvoices[invoice.uuid] = invoice;
    } else {
      delete this.checkedInvoices[invoice.uuid];
    }

    this.crunchSelectedInvoice();
  }

  public resetCheckBoxes(): void {
    //this.logger.info("resetCheckBoxes()");
    this.checkedInvoices = {};
  }

  public setAllCheckbox(completed: boolean) {
    // this.checkboxAllComplete = completed;
    this.checkedInvoices = {};
    if (completed) {
      for (let i = 0; i < this.dataSource.data.length; i++) {
        this.checkedInvoices[(this.dataSource.data[i] as any).uuid] =
          this.dataSource.data[i];
      }
    }
    //
    this.crunchSelectedInvoice();
  }

  public clearSearch(): void {
    this.invoicesFilterValue = '';
    this.paginator.pageIndex = 0;
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public reloadInvoicesTable(): void {
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onNavigateToItem(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }
}
