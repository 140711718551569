<div style="min-width: 350px" fxLayout="column" fxLayoutAlign="start start">
  <div fxFlex="100%" *ngIf="model" class="gc-content">
    <div class="" fxLayout="column" fxFlex="100%">
      <div class="mat-body-2 mb-8">Français</div>
      <div [formGroup]="form" fxFlex="100" class="" fxLayout="column">
        <mat-form-field fxFlex="100" class="">
          <mat-label>{{ "GENERIC.SUBJECT" | translate }}</mat-label>
          <input
            matInput
            type="text"
            autocomplete="nope"
            [(ngModel)]="modelSubject[key + '_subject_fr_CA']"
            formControlName="subject_fr_CA"
          />
        </mat-form-field>
      </div>
      <p-editor
        #notificationEditorFR
        [(ngModel)]="model[key + '_fr_CA']"
        (onInit)="setFocus($event)"
        [style]="{
          height: '140px',
          width: '100%',
          'background-color': editMode ? '#ffffff' : '',
        }"
      >
        <ng-template pTemplate="header" class="">
          <span class="ql-formats">
            <select class="ql-font"></select>
            <select class="ql-size"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </ng-template>
      </p-editor>
      <div fxLayout="row" fxLayoutAlign="end start">
        <button
          mat-raised-button
          color="accent"
          (click)="onSaveEditor()"
          [disabled]="form.invalid"
          class="mt-8"
        >
          {{ "GENERIC.SAVE" | translate | uppercase }}
        </button>
      </div>

      <div class="mat-body-2 mt-4 mb-8">English</div>

      <div [formGroup]="form" fxFlex="100" class="" fxLayout="column">
        <mat-form-field fxFlex="100" class="">
          <mat-label>{{ "GENERIC.SUBJECT" | translate }}</mat-label>
          <input
            matInput
            type="text"
            autocomplete="nope"
            [(ngModel)]="modelSubject[key + '_subject_en_CA']"
            formControlName="subject_en_CA"
          />
        </mat-form-field>
      </div>

      <p-editor
        #notificationEditorEN
        [(ngModel)]="model[key + '_en_CA']"
        [style]="{
          height: '140px',
          width: '100%',
          'background-color': editMode ? '#ffffff' : '',
        }"
      >
        <ng-template pTemplate="header" class="">
          <span class="ql-formats">
            <select class="ql-font"></select>
            <select class="ql-size"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </ng-template>
      </p-editor>
      <div fxLayout="row" fxLayoutAlign="end start">
        <button
          mat-raised-button
          color="accent"
          (click)="onSaveEditor()"
          [disabled]="form.invalid"
          class="mt-8"
        >
          {{ "GENERIC.SAVE" | translate | uppercase }}
        </button>
      </div>
    </div>
  </div>
</div>
