import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { IGetDataService } from '../components/gt2-select/gt2-select.component';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';

@Injectable()
export class LedgerAccountService implements IGetDataService {
  ledgerAccountsObs: any;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getLedgersAccount(): any {
    // this.logger.info("LedgerAccountService.getLedgersAccount()");
    if (!this.ledgerAccountsObs) {
      this.ledgerAccountsObs = this.http
        .get<any>(
          this.api.createUrl(ApiRoutes.LEDGER_ACCOUNT + '?page=1&limit=500')
        )
        .pipe(shareReplay(1))
        .pipe(tap((data) => this.resetObservable()));
    }

    return this.ledgerAccountsObs;
  }

  public getLedgersAccountList(): any {
    // this.logger.info("LedgerAccountService.getLedgersAccountList()");
    if (!this.ledgerAccountsObs) {
      this.ledgerAccountsObs = this.http
        .get<any>(this.api.createUrl(ApiRoutes.LEDGER_ACCOUNT_LIST))
        .pipe(shareReplay(1))
        .pipe(tap((data) => this.resetObservable()));
    }

    return this.ledgerAccountsObs;
  }

  private resetObservable(): void {
    // this.logger.info("LedgerAccountService.resetObservable()");
    this.ledgerAccountsObs = null;
  }

  public getData(): Observable<any> {
    // this.logger.info("LedgerAccountService.getData()");
    return this.getLedgersAccountList();
  }

  public create(name: string, accountNumber: string): any {
    const obj: any = { name: name, account_number: accountNumber };
    // this.logger.info("CrudDatatableGenericService.create() -> obj: " + JSON.stringify(obj));
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.LEDGER_ACCOUNT),
      obj
    );
  }
}

export interface SettingsPrefModel {
  object: string;
  uuid: string;
  datetime_format: DateTimeFormatModel;
  date_format: DateFormatModel;
  timezone: TimezoneModel;
  delivery_tax: DeliveryTaxModel;
  social_charge: string;
}

export interface DateTimeFormatModel {
  object: string;
  uuid: string;
  format: string;
  format_moment: string;
}

export interface DateFormatModel {
  object: string;
  uuid: string;
  format: string;
  format_moment: string;
  picker_format: string;
}

export interface TimezoneModel {
  object: string;
  uuid: string;
  name: string;
  location: string;
}

export interface DeliveryTaxModel {
  id: string;
  uuid: string;
  name: string;
  slug: string;
  tax_item_1_id: number;
  tax_item_2_id: number;
  tax_item_3_id: number;
  tax_item_4_id: number;
  from_date: string;
  to_date: string;
  percent_total: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  tax1: TaxModel;
  tax2: TaxModel;
  tax3: TaxModel;
  tax4: TaxModel;
}

export interface TaxModel {
  id: number;
  uuid: string;
  name: any;
  slug: string;
  percent_tax: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  pref: TaxPrefModel;
}

export interface TaxPrefModel {
  id: number;
  uuid: string;
  tax_number: string;
  tax_item_id: number;
  organization_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
