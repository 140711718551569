<div style="max-height: 100%; overflow: auto">
  <div matDialogTitle *ngIf="mode === 'CREATE'" class="dialog-title">
    "LABOUR_CHARGE.TITLE"
  </div>
  <div matDialogTitle *ngIf="mode === 'EDIT'" class="dialog-title">
    "LABOUR_CHARGE.EDIT_TITLE"
  </div>
  <div fxLayout="column" fxLayoutAlign="center start">
    <div fxLayout="column" fxLayoutAlign="center start" class="item-full-width">
      <app-gt2-select
        *ngIf="mode === 'CREATE'"
        [service]="labourChargeService"
        [placeHolder]="'LABOUR_CHARGE.SELECT_LABOUR_CHARGE'"
        [label]="''"
        [selectedModel]="selectedLabourCharge"
        (changeEvent)="onLabourChargeChange($event)"
        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
        [nullableUseNone]="false"
        fxFlex="100%"
        class="item-full-width"
      ></app-gt2-select>
      <div *ngIf="mode === 'EDIT'" class="labour-charge-name">
        {{ labourChargeToUpdate.name }}
      </div>
    </div>

    <div
      *ngIf="mode === 'EDIT'"
      fxFlex="100"
      class="item-full-width"
      fxLayout="column"
    >
      <div
        [formGroup]="form"
        (keyup.enter)="
          form.valid &&
          !loading &&
          (mode === 'EDIT'
            ? labourChargeToUpdate.from_time !== 'Invalid DateTime' &&
              labourChargeToUpdate.to_time !== 'Invalid DateTime'
            : true)
            ? onSubmitLabourCharge()
            : null
        "
      >
        <mat-form-field fxFlex="100" class="item-full-width">
          <mat-label>{{ "GENERIC.PRICE" | translate }}</mat-label>
          <input
            matInput
            type="text"
            placeholder="'GENERIC.PRICE'"
            autocomplete="nope"
            formControlName="unit_price"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="labourChargeToUpdate.unit_price"
            required
          />
          <mat-error *ngIf="form.controls['unit_price'].invalid">
            "GENERIC.INPUT_NUMBER_0_999999"
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="100" class="item-full-width">
          <mat-label>{{ "GENERIC.QUANTITY" | translate }}</mat-label>
          <input
            matInput
            type="text"
            placeholder="'GENERIC.QUANTITY'"
            autocomplete="nope"
            formControlName="qty"
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="labourChargeToUpdate.qty"
            required
          />
          <mat-error *ngIf="form.controls['qty'].invalid">
            "GENERIC.INPUT_NUMBER_0_999999"
          </mat-error>
        </mat-form-field>
      </div>

      <!-- FROM, TO -->
      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        fxFlex="100"
        class="item-full-width"
      >
        <div
          [formGroup]="form"
          fxFlex="50"
          fxLayout="column"
          fxLayoutAlign="center start"
        >
          <mat-form-field fxFlex="100%" class="item-full-width mr-8">
            <mat-label>{{ "GENERIC.DATE_START" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="fromDate"
              [(ngModel)]="labourChargeToUpdate.from_date"
              min="1900-01-01"
              placeholder="'GENERIC.DATE_START'"
              formControlName="from_date"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="fromDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #fromDate></mat-datepicker>
            <mat-error *ngIf="form.controls['from_date'].invalid"
              >"GENERIC.DATE_DEFAULT_RANGE_INVALID"
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="100%" class="item-full-width mr-8">
            <mat-label>{{ "GENERIC.DATE_END" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="toDate"
              [(ngModel)]="labourChargeToUpdate.to_date"
              min="1900-01-01"
              placeholder="'GENERIC.DATE_END'"
              formControlName="to_date"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="toDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #toDate></mat-datepicker>

            <mat-error *ngIf="form.controls['to_date'].invalid" x
              >"GENERIC.DATE_DEFAULT_RANGE_INVALID"
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxLayout="column" fxLayoutAlign="center start">
          <div
            class="item-full-width ml-8 ngx-timepicker-field-from"
            fxFlex="100%"
          >
            <div class="timepicker-custom-label">"GENERIC.TIME"*</div>
            <ngx-timepicker-field
              [buttonAlign]="'left'"
              [format]="24"
              [(ngModel)]="labourChargeToUpdate.from_time"
              class=""
            >
            </ngx-timepicker-field>
          </div>

          <div
            class="item-full-width ml-8 ngx-timepicker-field-to"
            fxFlex="100%"
          >
            <div class="timepicker-custom-label">"GENERIC.TIME"*</div>
            <ngx-timepicker-field
              [buttonAlign]="'left'"
              [format]="24"
              [(ngModel)]="labourChargeToUpdate.to_time"
              class=""
            >
            </ngx-timepicker-field>
          </div>
        </div>
      </div>

      <p-editor
        #noteEditor
        [(ngModel)]="labourChargeToUpdate.note"
        [style]="{ height: '120px', width: '100%' }"
      >
        <ng-template pTemplate="header" class="">
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>

          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </ng-template>
      </p-editor>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16 gt2-buttons-theme"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      *ngIf="mode === 'CREATE'"
      mat-raised-button
      [disabled]="!selectedLabourCharge || loading"
      color="accent"
      class="mr-16"
      (click)="onSubmitLabourCharge()"
    >
      "GENERIC.SAVE"
    </button>

    <button
      *ngIf="mode === 'EDIT'"
      mat-raised-button
      [disabled]="
        form.invalid ||
        labourChargeToUpdate.from_time === 'Invalid DateTime' ||
        labourChargeToUpdate.to_time === 'Invalid DateTime' ||
        loading
      "
      color="accent"
      class="mr-16"
      (click)="onSubmitLabourCharge()"
    >
      "GENERIC.SAVE"
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      "GENERIC.CANCEL"
    </button>
  </div>
</div>
