export const locale = {
  lang: "en-CA",
  data: {
    JOBS_FUNCTION: {
      FUNCTION: "Job functions",
      CHOOSE_FUNCTION: "Choose a function from the list",
      NO_FUNCTION_LEFT: "All functions available has been assigned to this employee.",
      FUNCTION_NOT_IN_LIST: "The function you are searching for is not in the list?",
      CREATE_NEW: "New function",
      FUNCTIONS: "Functions",
    },
  },
};

// export const locale = {
//     lang: 'fr-CA',
//     data: {
//         'JOBS_FUNCTION': {
//             'FUNCTION': 'New employee function',
//             'CHOOSE_FUNCTION': 'Choose a function from list',
//             'NO_FUNCTION_LEFT': 'All available fonctions are already assigne to this employee',
//             'FUNCTION_NOT_IN_LIST': 'The function you\'re looking for is not in the list?',
//             'CREATE_NEW': 'New employee function',
//         }
//     }
// };
