export const locale = {
  lang: "en-CA",
  data: {
    CREATE_CLIENT_ENTERPRISE_BRANCH: {
      TITLE: "New client, enterprise and branch",
      CLIENT_FIRST_NAME: "Client first name",
      CLIENT_LAST_NAME: "Client last name",
      ENTERPRISE_NAME: "Enterprise name",
      BRANCH_NAME: "Branch name",
      CREATE_SUCCESS: "Client, entreprise et succursale créé avec succès",
    },
  },
};
