<div
  id="products"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
  style="overflow-x: scroll"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fusePerfectScrollbar
      style="min-width: 1023px"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1 mt-8">{{ "SHOPS.SHOPS" | translate }}</div>
      </div>

      <!--            <div class="" fxLayout="row" fxLayoutAlign="end start" fxFlex="100">-->
      <!--                <button *ngIf="fileService.pdfEnabled()" mat-button-->
      <!--                        matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}" (click)="onDownloadPDF()"-->
      <!--                        class="mat-icon-button">-->
      <!--                    <mat-icon class="s-30">picture_as_pdf</mat-icon>-->
      <!--                </button>-->

      <!--                <mat-spinner *ngIf="print.loadingPrint" [diameter]="20" class="mt-12"></mat-spinner>-->
      <!--                <button *ngIf="fileService.printEnabled()" mat-button matTooltip="{{ 'GENERIC.PRINT' | translate }}"-->
      <!--                        (click)="onPrint()" class="mat-icon-button">-->
      <!--                    <mat-icon class="s-30">print</mat-icon>-->
      <!--                </button>-->

      <!--                <button *ngIf="fileService.excelEnabled()" mat-button-->
      <!--                        matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}" (click)="onDownloadXLS()"-->
      <!--                        class="mat-icon-button">-->
      <!--                    <mat-icon fontSet="fa" fontIcon="fa-table" class="fa-2x"></mat-icon>-->
      <!--                </button>-->

      <!--                <button mat-button matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}" (click)="onOpenInNewTab()"-->
      <!--                        class="mat-icon-button">-->
      <!--                    <mat-icon class="s-30">{{ getIcon('NEW_TAB') }}</mat-icon>-->
      <!--                </button>-->
      <!--            </div>-->
    </div>
  </div>
  <!-- / HEADER -->

  <div
    #mainContainer
    class="center p-24 pr-56"
    style="min-width: 1023px"
    fusePerfectScrollbar
  >
    <div fxLayout="column">
      <!--Data table-->
      <div class="mat-white-bg mat-elevation-z2">
        <div class="px-8 pt-8" fxLayout="row" fxLayoutAlign="end center">
          <!-- SEARCH -->
          <div
            class=""
            fxFlex="1 0 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <label for="search" class="mr-8">
              <mat-icon class="">search</mat-icon>
            </label>
            <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
              <mat-label>{{
                "GENERIC.FILTERING_PLACEHOLDER" | translate | titlecase
              }}</mat-label>
              <input
                id="search"
                [(ngModel)]="filterValue"
                matInput
                #filter
                placeholder="{{
                  'GENERIC.FILTERING_PLACEHOLDER' | translate | titlecase
                }}"
              />
              <button
                mat-button
                *ngIf="filterValue"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearSearch()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <!-- / SEARCH -->

          <div class="mat-body-2 p-8 ml-8">
            {{ resultsLength }}
            {{ "SHOPS.SHOPS" | translate }}
          </div>
        </div>
        <div class="border-bottom">
          <div
            *ngIf="!isLoadingResults && resultsLength === 0"
            fxLayout="row"
            fxLayoutAlign="center start"
            class="pb-8 mat-body-2"
          >
            {{ "GENERIC.NO_RESULTS" | translate }}
          </div>

          <div *ngIf="isLoadingResults" class="">
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>

        <div>
          <div class="border-bottom">
            <mat-paginator
              #paginatorBottom
              [length]="paginator.length"
              (page)="datatableHelperService.onPaginator($event, 'shops')"
              showFirstLastButtons="true"
              [pageIndex]="paginator.pageIndex"
              [pageSize]="paginator.pageSize"
              [pageSizeOptions]="paginator.pageSizeOptions"
            >
            </mat-paginator>
          </div>
          <mat-table
            #table
            class=""
            matSort
            matSortStart="desc"
            *ngIf="dataSource && dataSource.data"
            [@animateStagger]="{ value: '50' }"
            [dataSource]="dataSource"
          >
            <!-- Name Column -->
            <ng-container cdkColumnDef="name">
              <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header
                >{{ "GENERIC.INTERNAL_NAME" | translate }}
              </mat-header-cell>

              <mat-cell
                class="no-text-cursor"
                fxFlex="50"
                *cdkCellDef="let shop"
              >
                <p
                  class="text-truncate font-weight-500"
                  matTooltip="{{ shop.name }}"
                  [matTooltipPosition]="'right'"
                >
                  {{ shop.name }}
                </p>
              </mat-cell>
            </ng-container>

            <!--                         shop_typeColumn -->
            <ng-container cdkColumnDef="shop_type_label">
              <mat-header-cell fxFlex="50" *cdkHeaderCellDef
                >{{ "SHOPS.SHOP_TYPE" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="50"
                *cdkCellDef="let shop"
              >
                <p class="text-truncate">
                  {{ shop.shop_type_label }}
                </p>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="shop_product_type">
              <mat-header-cell fxFlex="50" *cdkHeaderCellDef
                >{{ "SHOPS.SHOP_PRODUCT_TYPE" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="50"
                *cdkCellDef="let shop"
              >
                <p class="text-truncate">
                  {{ shop.shop_product_type.name }}
                </p>
                <!-- <button class="ml-16" mat-icon-button type="button" 
                                (click)="$event.stopPropagation(); onShopDetailesSelected($event, shop)"
                                    *ngxPermissionsOnly="['GOD']">
                                    <mat-icon class="s-20" color="">build</mat-icon>
                                </button> -->
              </mat-cell>
            </ng-container>

            <mat-header-row
              *cdkHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row
              class="mat-row-color"
              *cdkRowDef="let shop; columns: displayedColumns"
              matRipple
              (click)="onShopDetailesSelected($event, shop)"
            >
              <!-- <mat-row *cdkRowDef="let shop; columns: displayedColumns;" class="" matRipple
                                     (click)="onShopSelected(shop)"
                                     [routerLink]="'/' + appRoutesService.shops + '/' + shop.shop_type.slug + '/' + shop.slug + '/' + shop.uuid"> -->
            </mat-row>
          </mat-table>
          <mat-paginator
            #paginator
            [length]="resultsLength"
            (page)="datatableHelperService.onPaginator($event, 'shops')"
            showFirstLastButtons="true"
            [pageIndex]="0"
            [pageSize]="'shops' | tableSizePipe: 'large'"
            [pageSizeOptions]="datatableHelperService.pageSizeOptions"
          >
          </mat-paginator>
          <div
            *ngIf="isLoadingResults && !isFirstLoad"
            fxLayout="row"
            fxLayoutAlign="center start"
            class=""
          >
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>

    <div class="spacer" style="height: 46px"></div>
  </div>
</div>

<div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
  <div class="fixed-button">
    <button
      mat-fab
      class="gt2-create-button"
      id="add-product-button"
      (click)="onCreateNewShop($event)"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
    >
      <mat-icon class="icon-mat-black">add</mat-icon>
    </button>
  </div>
</div>
