import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseWidgetModule } from '../../../../@fuse/components';
import { UserService } from '../../api/user.service';
import { Gt2LocaleService } from '../../../localization/gt2-locale.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { FileUploadModule } from 'primeng/fileupload';
import { CrudOrganizationService } from '../crud/crud-organization/crud-organization.service';
import { CatererComponent } from './caterer.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { AddressComponent } from '../../components/address/address.component';
import { Gt2SelectComponent } from '../../components/gt2-select/gt2-select.component';
import { ContactComponent } from '../../components/contact/contact.component';
import { ContactService } from '../../components/contact/contact.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.FR.CATERER,
    component: CatererComponent,
  },
  {
    path: AppRoutesName.EN.CATERER,
    component: CatererComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatTooltipModule,
    TranslateModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    CdkTableModule,
    AddressComponent,
    ContactComponent,
    NgxPermissionsModule,
    MultiSelectModule,
    RouterModule.forChild(routes),
    FileUploadModule,
    FuseWidgetModule,
    Gt2SelectComponent,
  ],
  declarations: [CatererComponent],
  exports: [CatererComponent],
  providers: [
    CrudOrganizationService,
    ContactService,
    Gt2LocaleService,
    UserService,
  ],
})
export class CatererModule {}
