export const locale = {
  lang: "fr-CA",
  data: {
    JOBS_FUNCTION: {
      FUNCTION: "Nouvel fonction d'employé",
      CHOOSE_FUNCTION: "Choisir une fonction dans la liste",
      NO_FUNCTION_LEFT: "Toutes les fonctions disponible ont été assigné à cet employé.",
      FUNCTION_NOT_IN_LIST: "La fonction que vous cherchez n'est pas dans la liste?",
      CREATE_NEW: "Créer une nouvelle fonction",
      FUNCTIONS: "Fonctions",
    },
  },
};
