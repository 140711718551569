<!--<div id="support" class="page-layout simple fullwidth" fxLayout="column" fusePerfectScrollbar>-->
<div
  id="documentation"
  class="page-layout simple fullwidth inner-scroll"
  fxLayout="column"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1">{{ "DOCUMENTATION.TITLE" | translate }}</div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->
  <iframe
    class="px-24"
    width="100%"
    height="100%"
    *ngIf="translate.currentLang === 'en-CA' && !isMobile"
    src="https://docs.google.com/document/d/e/2PACX-1vSbpWw_RXinSGVQxXyFYxms0ehVhgPO8bcd9RkY0IuKMGMaJPbMJIL2K2QMY1t_2YVsJnvrGBFDGCRO/pub?embedded=true"
  >
  </iframe>
  <iframe
    class="px-24"
    width="100%"
    height="100%"
    *ngIf="translate.currentLang === 'fr-CA' && !isMobile"
    src="https://docs.google.com/document/d/e/2PACX-1vQ6IGyBXrI7zrUvEIW1l6qPfP5WVkMPP-F-DhDZgzPzlxw2md_d84ZanzaMCSdQpyn30-pE-m-pAIww/pub?embedded=true"
  >
  </iframe>
  <iframe
    width="100%"
    height="100%"
    class="doc-iframe"
    *ngIf="translate.currentLang === 'en-CA' && isMobile"
    src="https://docs.google.com/document/d/e/2PACX-1vSbpWw_RXinSGVQxXyFYxms0ehVhgPO8bcd9RkY0IuKMGMaJPbMJIL2K2QMY1t_2YVsJnvrGBFDGCRO/pub?embedded=true"
  >
  </iframe>
  <iframe
    width="100%"
    height="100%"
    class="doc-iframe"
    *ngIf="translate.currentLang === 'fr-CA' && isMobile"
    src="https://docs.google.com/document/d/e/2PACX-1vQ6IGyBXrI7zrUvEIW1l6qPfP5WVkMPP-F-DhDZgzPzlxw2md_d84ZanzaMCSdQpyn30-pE-m-pAIww/pub?embedded=true"
  >
  </iframe>
</div>
