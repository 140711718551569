export const locale = {
  lang: "en-CA",
  data: {
    PRODUCTS: {
      PRODUCTS: "Products",
      PRODUCT: "Product",
      NEW_PRODUCTS: "ADD NEW PRODUCT",
      PRODUCT_NUMBER: "Product code",
      PRICE: "Selling price",
      CONFIRMED: "Confirmed",
      NOT_CONFIRMED: "Not confirmed",
      COMBO: "Combo",
      COMBO_PARENTS: "This product is included in those combos",
      SINGLE_PRODUCT: "Single product",
      DELETE_PRODUCT: "Delete product",
      DELETE_CONFIRM: "Are you sure you want to delete?",
      ClONE_PRODUCT: "Clone product",
      ClONE_COMBO: "Clone combo",
      CREATE_COMBO: "Create combo",
      INTERNAL_NAME: "Internal name",
      EXTERNAL_NAME: "External name",
      DESCRIPTION: "Description",
      INTERNAL_DESCRIPTION: "Internal description",
      ACCOUNTANT_CODE: "Accountant code",
      PRODUCTION_DELAY: "Production delay (days)",
      PRODUCTION_DELAY_DAYS: "(days)",
      SERVING_COUNT: "Serving count",
      COST: "Food cost",
      SKU: "SKU",
      UNIT: "Unit of measure",
      UNIT_SELECT: "Select unit",
      WRAPPING: "Wrapping",
      WRAPPING_SELECT: "Select a wrapping",
      PRODUCT_CATEGORY: "Product category",
      PRODUCT_CATEGORY_SELECT: "Select a category",
      MIN_QUANTITY_ORDER: "Min quantity order",
      ITEM_QUANTITY: "Quantity per portion",
      TAXES: "Taxes",
      PRODUCTION_ZONE: "Production Zone",
      TAXES_SELECT: "Select a tax",
      INVOICE_CATEGORIES: "Products type",
      INVOICE_CATEGORY: "Product type",
      INVOICE_SELECT: "Select a product type",
      MODIFY_PRODUCT: "Edit product",
      CLOSE_EDIT: "Close edit mode",
      CHANGE_COMBO_ORDER: "Change the order of items or click the + button to add/remove items",
      SHOW_CONFIRMED: "Show only confirmed products",
      SHOW_COMBO: "Show combos only",
      NO_ALLERGENS: "There are no allergens assigned right now.",
      NO_ALLERGENS_HR: "There are no allergens assigned right now. Click the green + button in edit mode to add one or more.",
      NO_PRODUCTION_ZONES: "There is no production zone assigned right now.",
      NO_PRODUCTION_ZONES_HR: "There is no production zone assigned right now. Click the green + button in edit mode to add one or more.",
      NO_UPSALE_QUESTIONS: "There are no upsale questions assigned right now.",
      NO_UPSALE_QUESTIONS_HR: "There are no upsale questions assigned right now. Click the green + button in edit mode to add one or more.",
      ADD_REMOVE_UPSALE_QUESTIONS: "Click the + button to add/remove items",
      AVAILABILITY: "Availability",
      ADD_REMOVE_ALLERGENS: "Click the + button to add/remove items",
      ADD_REMOVE_PRODUCTION_ZONE: "Click the + button to add/remove items",
      ALLERGENS_DIALOG_TITLE: "Add or remove allergens",
      ALLERGENS_COL_LEFT_LABEL: "Drag & drop or use the + button to add an allergen",
      ALLERGENS_COL_RIGHT_LABEL: "Drag & drop or use the trash button to remove an allergen",
      PRODUCTION_ZONE_DIALOG_TITLE: "Add or remove production zone",
      PRODUCTION_ZONE_COL_LEFT_LABEL: "Drag & drop or use the + button to add a production zone",
      PRODUCTION_ZONE_COL_RIGHT_LABEL: "Drag & drop or use the trash button to remove a production zone",
      COMBO_CHECKBOX_LABEL: "Show combo products",
      COMBO_CHECKBOX_QTY_LABEL: "Show combo products quantity",
      COMBO_CHECKBOX_PRICE_LABEL: "Show combo products price",
      ORDERED_PRODUCT: "Ordered product",
      SEE_ORDER: "See order",
      SEE_PROPOSAL: "See proposal",
      SEE_INVOICE: "See invoice",
      SHOPS: "Shops",
      PRODUCT_PICKER_TITLE_SIMPLE: "Select a product",
      PRODUCT_PICKER_TITLE_MULTI: "Select one or several products",
      PRODUCT_PICKER_SELECT: "Select",
      NO_SHOPS: "You don't have an online shop yet",
      NO_CHILDS: "No product",
      CYCLIC_PRODUCT: "Cyclic product",
      CUSTOM_DATES_RULE: "Use custom dates",
      EVERY_MONDAY_RULE: "Display product every monday",
      RECURRENCE_TYPE: "Recurrence type",
      CUSTOM_DATES: "Custom dates",
      UPSALE: "Upsale",
      UPSALE_CHOOSE: "Select product",
      ADD_QUESTION_HINT: "Create question",
      EDIT_QUESTION_HINT: "Edit question",
      CHOICE_PRODUCT_COMBO: "Make this combo dynamic",
      CHOICE_PRODUCT_COMBO_TIP: "Allows you to add combos whose products will act as a choice list",
      UPSALE_QUESTIONS: "Upsale questions",
      ADD_QUESTION_ANSWER: "Add an answer",
      DELETE_QUESTION_ANSWER: "Clear question",
      CREATE_OPTIONS_GROUP_TITLE: "Create new products selection",
      OPTIONS_GROUP_NAME: "Selection name",
      ADD_QUESTION_NAME: "Question name",
      CREATE_OPTIONS_GROUP: "Create a group",
      EDIT_GROUP_NAME: "Change group name",
      CHOICE_REQUIRED_NO_SKIP: "Choice required",
      ADD_GROUP_OPTION: "Add products",
      UPSALE_DRAG_AND_DROP_LEFT_LABEL: "Drag & drop or use the + button to add question",
      UPSALE_DRAG_AND_DROP_RIGHT_LABEL: "List of questions to add",
    },
  },
};
