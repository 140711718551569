export const locale = {
  lang: "en-CA",
  data: {
    LEDGER_ACCOUNT: {
      CREATE_NEW_CATEGORY: "New ledger account",
      CATEGORY_NAME: "Ledger account",
      NEW_CATEGORY_NAME: "Name of new ledger account",
      CREATE_SUCCESS: "Ledger account created successfully",
      CREATE_ERROR: "An error occured",
      EDIT_SUCCESS: "Ledger account modified successfully",
      DELETE_SUCCESS: "Ledger account deleted successfully",
      ACCOUNT_NUMBER: "Account number",
    },
  },
};
