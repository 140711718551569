<!--<div id="rooms" class="" fxLayout="column" fusePerfectScrollbar>-->

<!--<div class="">-->
<!--<div fxLayout="column">-->

<div class="profile-box info-box width-100-content">
  <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
    <div *ngIf="!useDataCollection" class="title">
      {{ "ROOMS.ROOMS" | translate }}
    </div>
    <div *ngIf="useDataCollection" class="title">
      {{ "ROOMS.SUB_ROOMS" | translate }}
    </div>
    <div
      *ngIf="editMode && !parentIsSubRoom && !disabledAddSubRoom"
      class="gt2-light-green"
      style="width: 60px; height: 56px"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <button
        type="button"
        matTooltip="{{ 'ROOMS.ADD_ROOM' | translate }}"
        mat-icon-button
        class=""
        (click)="onAddNewRoom()"
      >
        <mat-icon class="" color="">add</mat-icon>
      </button>
    </div>
    <div
      *ngIf="editMode && parentIsSubRoom && !disabledAddSubRoom"
      class="gt2-yellow"
      style="width: 60px; height: 56px"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <button
        type="button"
        matTooltip="{{ 'ROOMS.ATTACH_ROOM' | translate }}"
        mat-icon-button
        class=""
        (click)="onAttachRoom()"
      >
        <mat-icon class="" color="" style="font-size: 24px"
          >add_location</mat-icon
        >
        <!-- room -->
        <!-- add_location -->
        <!-- compare_arrows -->
        <!-- queue -->
        <!-- loupe -->
      </button>
    </div>
  </header>
  <div class="content" fxLayout="column">
    <!--Data table-->
    <div class="mat-elevation-z2">
      <div>
        <div
          *ngIf="
            !isLoadingResults && dataSource.data && dataSource.data.length === 0
          "
          fxLayout="row"
          fxLayoutAlign="center start"
          class="py-8 mat-body-2"
        >
          <div *ngxPermissionsOnly="rolesService.RH_ROLES">
            <div *ngIf="!parentIsSubRoom">
              {{ "ROOMS.NO_ROOMS_HR" | translate }}
            </div>
            <div *ngIf="parentIsSubRoom">
              {{ "ROOMS.NO_SUB_ROOMS_HR" | translate }}
            </div>
          </div>
          <div *ngxPermissionsExcept="rolesService.RH_ROLES">
            <div *ngIf="!parentIsSubRoom">
              {{ "ROOMS.NO_ROOMS" | translate }}
            </div>
            <div *ngIf="parentIsSubRoom">
              {{ "ROOMS.NO_SUB_ROOMS" | translate }}
            </div>
          </div>
        </div>

        <mat-table
          #table
          class=""
          matSort
          matSortStart="desc"
          *ngIf="!tableIsEmpty"
          [@animateStagger]="{ value: '50' }"
          [dataSource]="dataSource"
        >
          <!-- Name Column -->
          <ng-container cdkColumnDef="name">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header fxFlex="100%"
              >{{ "ROOMS.NAME" | translate }}
            </mat-header-cell>
            <mat-cell *cdkCellDef="let room" fxFlex="100%">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="mr-4">link</mat-icon>
                <div
                  *ngIf="room.name_list !== null && room.name_list !== ''"
                  class="text-truncate font-weight-600"
                >
                  {{ room.name_list }}
                </div>
                <div
                  *ngIf="room.name_list === null || room.name_list !== ''"
                  class="text-truncate font-weight-600"
                >
                  {{ room.name }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="options_button">
            <mat-header-cell fxFlex="40px" *cdkHeaderCellDef>
              <!-- <button type="button"
                                    mat-icon-button
                                    (click)="onAddNewRoom()">
                                <mat-icon class="mat-accent s-24">add</mat-icon>
                            </button> -->
            </mat-header-cell>
            <mat-cell fxFlex="40px" *cdkCellDef="let room">
              <button
                type="button"
                mat-icon-button
                *ngIf="editMode && parentIsSubRoom && showUnlinkButton"
                class="p-4"
                matTooltip="{{ 'ROOMS.DETACH_ROOM' | translate }}"
                (click)="$event.stopPropagation(); onDetachSubRoom(room)"
              >
                <mat-icon class="s-20">link_off</mat-icon>
              </button>
              <button
                type="button"
                mat-icon-button
                *ngIf="editMode && !parentIsSubRoom && showUnlinkButton"
                class="p-4"
                matTooltip="{{ 'ROOMS.DETACH_ROOM' | translate }}"
                (click)="$event.stopPropagation(); onDetachRoom(room)"
              >
                <mat-icon class="s-20">link_off</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            *cdkRowDef="let room; columns: displayedColumns"
            class=""
            matRipple
            [routerLink]="[
              '/' + roomURLSegment + '/' + room.slug + '/' + room.uuid,
            ]"
            [queryParams]="{ loc: breadCrumbName }"
          >
          </mat-row>
        </mat-table>

        <mat-paginator
          #paginator
          *ngIf="!tableIsEmpty"
          [length]="resultsLength"
          (page)="datatableHelperService.onPaginator($event, 'rooms')"
          showFirstLastButtons="true"
          [pageIndex]="0"
          [pageSize]="'rooms' | tableSizePipe: 'large'"
          [pageSizeOptions]="datatableHelperService.pageSizeOptions"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
