import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import moment from 'moment';
import { isSameDay } from 'date-fns';

@Injectable()
export class ValidatorHelper {
  //
  static MIN_DATE: string = '1900-01-01';
  static MAX_DATE: string = '2099-01-01';

  //

  static isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
  }

  static nullable(): ValidatorFn {
    // return { 'nullable' : true };
    return (control: AbstractControl): { [key: string]: any } | null => {
      // control.get('store_url').setErrors(null);
      return null;
    };
  }

  // --o  STRINGS

  // min length override (see app.component for actual overriding)
  static minLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (ValidatorHelper.isPresent(Validators.required(control))) {
        return null;
      }
      const v: string = control.value ? control.value : '';
      return v.toString().trim().length < minLength
        ? {
            minlength: {
              requiredLength: minLength,
              actualLength: v.toString().trim().length,
            },
          }
        : null;
    };
  }

  static excludeStrings(stringsCollection: string[]): ValidatorFn {
    let val: string;
    return (control: AbstractControl): { [key: string]: any } | null => {
      for (let i: number = 0; i < stringsCollection.length; i++) {
        val = control.value ? control.value : '';
        if (val === stringsCollection[i]) {
          return { excludeStrings: true };
        }
      }

      return null;
    };
  }

  // --o  NUMBERS
  static isInteger(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const num: number = ValidatorHelper.getControlNumber(control);
    const regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
    if (num.toString().match('^[0-9]*$')) {
      return null;
    }

    return { isInteger: true };
  }

  static isPositive(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    // const num: number = control.value ? control.value.replace(/,/g, '.') : -1;
    const num: number = ValidatorHelper.getControlNumber(control);
    if (num >= 0) {
      return null;
    }

    return { isPositive: true };
  }

  static isNumber0To50(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    // const num: number = control.value ? control.value.replace(/,/g, '.') : -1;
    const num: number = ValidatorHelper.getControlNumber(control);
    if (num >= 0 && num <= 50) {
      return null;
    }

    return { isNumber0To50: true };
  }

  static isNumber0To100(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    // const num: number = control.value ? control.value.replace(/,/g, '.') : -1;
    const num: number = ValidatorHelper.getControlNumber(control);
    if (num >= 0 && num <= 100) {
      return null;
    }

    return { isNumber0To100: true };
  }

  static isNumberMinus100To100(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    // const num: number = control.value ? control.value.replace(/,/g, '.') : -1;

    const num: number = ValidatorHelper.getControlNumber(control);
    if (num >= -100 && num <= 100) {
      return null;
    }

    return { isNumberMinus100To100: true };
  }

  static isNumber0To1000(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    // const num: number = control.value ? control.value.replace(/,/g, '.') : -1;
    const num: number = ValidatorHelper.getControlNumber(control);
    if (num >= 0 && num < 1000) {
      return null;
    }

    return { isNumber0To1000: true };
  }

  static isNumber0To1000Inclusively(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    // const num: number = control.value ? control.value.replace(/,/g, '.') : -1;
    const num: number = ValidatorHelper.getControlNumber(control);
    if (num >= 0 && num <= 1000) {
      return null;
    }

    return { isNumber0To1000: true };
  }

  static isNumber0To10000(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const num: number = ValidatorHelper.getControlNumber(control);
    if (num >= 0 && num < 10000) {
      return null;
    }

    return { isNumber0To10000: true };
  }

  static isNumber0To10000Inclusively(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const num: number = ValidatorHelper.getControlNumber(control);
    if (num >= 0 && num <= 10000) {
      return null;
    }

    return { isNumber0To10000: true };
  }

  static isNumber0To1000000(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    // const num: number = control.value ? control.value.replace(/,/g, '.') : -1;
    const num: number = ValidatorHelper.getControlNumber(control);

    if (num >= 0 && num <= 1000000) {
      return null;
    }

    return { isNumber0To1000000: true };
  }

  static isNumber0To1000000orNull(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    // const num: number = control.value ? control.value.replace(/,/g, '.') : -1;
    const num: number = ValidatorHelper.getControlNumber(control);
    if ((num >= 0 && num <= 1000000) || num === -1) {
      return null;
    }

    return { isNumber0To1000000: true };
  }

  static isAboveZero(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const num: number = ValidatorHelper.getControlNumber(control);
    if (num > 0) {
      return null;
    }

    return { isAboveZero: true };
  }

  static isNotZero(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const num: number = ValidatorHelper.getControlNumber(control);
    if (num !== 0) {
      return null;
    }

    return { isNotZero: true };
  }

  static isValidPriceNumber(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const num: number = parseFloat(control.value);
    if (control.value && typeof num === 'number' && !isNaN(num)) {
      return null;
    } else if (control.value === 0 || control.value === '0') {
      return null;
    }

    return { isValidPriceNumber: true };
  }

  private static getControlNumber(control: AbstractControl): number {
    let num: number;
    if (
      control.value &&
      typeof control.value === 'number' &&
      !isNaN(control.value)
    ) {
      num = control.value;
    } else if (control.value === 0 || control.value === '0') {
      num = 0;
    } else {
      num = control.value ? control.value.replace(/,/g, '.') : -1;
    }
    return num;
  }

  // --o  DATE
  static dateDOB(control: AbstractControl): { [key: string]: boolean } | null {
    const dob: string = control.value ? control.value : '';
    const d: any = moment(dob);
    const today: any = moment(); // new Date();
    const minDate: any = moment('1900-01-01');

    if (d > minDate && d < today) {
      return null;
    }

    return { dateDOB: true };
  }

  static dateRangeDefault(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    // start : 1900, 01, 01
    // end : 2099, 01, 01
    const dob: string = control.value ? control.value : '';
    const d: Date = new Date(dob);
    const minDate: Date = new Date(ValidatorHelper.MIN_DATE);
    const maxDate: Date = new Date(ValidatorHelper.MAX_DATE);
    if (d > minDate && d < maxDate) {
      return null;
    }

    return { dateDOB: true };
  }

  static dateTodaysOrLower(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const date: string = control.value
      ? control.value
      : new Date().toISOString();
    const d: Date = new Date(date);
    const today: Date = new Date();
    if (d < today || isSameDay(d, today)) {
      return null;
    }
    return { dateTodaysOrLower: true };
  }

  static dateTodaysOrHigher(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const date: string = control.value ? control.value : new Date();
    const today: Date = new Date();
    const d: any = moment(date); // new Date(date);
    if (d > today || isSameDay(d, today)) {
      return null;
    }

    return { dateTodaysOrHigher: true };
  }

  static dateAfterThan(thanDate: string): ValidatorFn {
    let val: string;
    return (control: AbstractControl): { [key: string]: any } | null => {
      val = control.value ? control.value : '';
      if (!moment(val, 'DD-MM-YYYY').isAfter(moment(thanDate))) {
        return { dateAfterThan: true };
      }
      return null;
    };
  }

  static dateBeforeThan(thanDate: string): ValidatorFn {
    let val: string;
    return (control: AbstractControl): { [key: string]: any } | null => {
      val = control.value ? control.value : '';

      if (!moment(val, 'DD-MM-YYYY').isBefore(moment(thanDate))) {
        return { dateBeforeThan: true };
      }
      return null;
    };
  }

  static excludeDate(datesCollection: string[]): ValidatorFn {
    let val: string;
    return (control: AbstractControl): { [key: string]: any } | null => {
      for (let i: number = 0; i < datesCollection.length; i++) {
        val = control.value ? control.value : '';
        if (isSameDay(val as any, datesCollection[i] as any)) {
          return { excludeStrings: true };
        }
      }

      return null;
    };
  }
  static validateEmailsArr(control: AbstractControl) {
    var emailsToNotify = control.value;
    if (emailsToNotify) {
      var lastChar = emailsToNotify.slice(-1);
      if (lastChar == ',') {
        emailsToNotify = emailsToNotify.slice(0, -1);
      }
      var emailsArray = emailsToNotify.split(',');
      for (let email of emailsArray) {
        if (ValidatorHelper.validateEmail(email) == null) {
          //this.logger.info(email);
          return { unvalidEmail: email };
        }
      }
      return null;
    } else {
      return null;
    }
  }
  static validateEmail(email: any): any | null {
    email = email.trim();
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
}
