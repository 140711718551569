<div
  id="room"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <!--<div id="room" class="page-layout simple left-sidenav inner-sidenav" fxLayout="column">-->

  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
          (click)="onPageBack(location, router)"
        >
          <mat-icon class="icon-mat">arrow_back</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
            <div *ngIf="room" class="h1 mt-8">
              {{ "ROOMS.ROOM" | translate }} - {{ room.name }}
            </div>
          </div>
          <div *ngIf="room" fxLayout="row" fxLayoutAlign="start start" class="">
            <div *ngIf="room.enterprise">
              {{ "GENERIC.ENTERPRISE" | translategt }}:
              {{ room.enterprise.name }}
            </div>
            <!-- <div *ngIf="room.place">{{ 'GENERIC.VENUE' | translate | uppercase }}  - {{ room.place.name }}</div> -->
            <!-- <div *ngIf="room.place">{{ room.place.name }} ({{ 'GENERIC.VENUE' | translate | uppercase }})</div> -->
            <div
              *ngIf="room.place"
              (click)="onGoToPlace()"
              style="cursor: pointer"
              class="underline"
            >
              {{ "GENERIC.VENUE" | translate }}: {{ room.place.name }}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center">
        <!-- <button mat-button
                    *ngIf="fileService.pdfEnabled()"
                    matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}" (click)="onDownloadPDF()"
                    class="mat-icon-button">
                    <mat-icon class="s-30">picture_as_pdf</mat-icon>
                </button>

                <mat-spinner *ngIf="print.loadingPrint" [diameter]="20" class="mt-12"></mat-spinner>
                <button *ngIf="fileService.printEnabled()" mat-button matTooltip="{{ 'GENERIC.PRINT' | translate }}" (click)="onPrint()" class="mat-icon-button">
                    <mat-icon class="s-30">print</mat-icon>
                </button> -->

        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="icon-mat s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <div
    *ngIf="room && !editable"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="height: 38px; min-height: 38px; max-height: 38px"
    class="gt2-gray mat-elevation-z1 py-8"
  >
    <div class="text-white font-weight-600">
      <b>{{ "GENERIC.READ_ONLY_MODE" | translate }}</b> :
      {{ "GENERIC.ARCHIVE_PAGE" | translate }}
    </div>
  </div>

  <!--HISTORY SIDEBAR-->
  <div *ngIf="!isSmallScreen" class="container-history-panel">
    <fuse-sidebar
      *ngIf="roomID"
      name="roomHistoryPanel"
      position="right"
      class="section-history-panel"
    >
      <app-section-history-panel
        *ngIf="room"
        panelName="roomHistoryPanel"
        section="rooms"
        sectionUUID="{{ roomID }}"
      >
      </app-section-history-panel>
    </fuse-sidebar>
  </div>
  <!-- / HISTORY SIDEBAR-->

  <!-- CONTENT -->
  <div class="content">
    <!--HISTORY SIDEBAR BUTTON-->
    <div *ngIf="!isSmallScreen">
      <!-- <div *ngIf="editable"> -->
      <button
        mat-icon-button
        class="warn mat-elevation-z2 historic-button"
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        (click)="toggleHistoricSidebarOpen('roomHistoryPanel')"
      >
        <mat-icon class="icon-mat">history</mat-icon>
      </button>
      <!-- </div> -->
    </div>
    <!-- /HISTORY SIDEBAR BUTTON-->

    <!-- CENTER -->
    <div class="center p-24 pb-56 pr-sm-72" fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="room-content">
        <div
          *ngIf="room"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "GENERIC.INFORMATION" | translate }}</div>
          </header>
          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
          >
            <mat-tab label="{{ 'GENERIC.GENERAL_INFORMATION' | translate }}">
              <div class="content" fxLayout="column">
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">{{ "ROOMS.ROOM_NAME" | translate }}</div>
                    <div class="info">{{ room.name }}</div>
                    <div *ngIf="!room.name" class="info empty-value"></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "ROOMS.ROOM_NAME" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="name"
                            type="text"
                            [(ngModel)]="room.name"
                            autocomplete="nope"
                            required
                          />
                          <mat-error *ngIf="form.controls['name'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <!--max_place-->
                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">{{ "ROOMS.MAX_PLACE" | translate }}</div>
                    <div class="info">{{ room.max_place }}</div>
                    <div
                      *ngIf="!room.max_place && room.max_place !== 0"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <!--<input matInput-->
                          <!--placeholder="{{ 'ROOMS.MAX_PLACE' | translate}}"-->
                          <!--formControlName="max_place"-->
                          <!--type="number"-->
                          <!--[(ngModel)]="room.max_place">-->

                          <mat-label>{{
                            "ROOMS.MAX_PLACE" | translate
                          }}</mat-label>
                          <input
                            matInput
                            type="text"
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            autocomplete="nope"
                            [(ngModel)]="room.max_place"
                            formControlName="max_place"
                          />

                          <mat-error *ngIf="form.controls['max_place'].invalid">
                            {{ "GENERIC.INPUT_NUMBER_0_9999" | translate
                            }}{{
                              "GENERIC.INPUT_NUMBER_POSTFIX_INTEGER_ONLY"
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab
              *ngIf="room && room.place"
              label="{{ 'ROOMS.ROYALTY' | translate }}"
            >
              <div class="content" fxLayout="column">
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <!--royalty_percent-->
                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">
                      {{ "ROOMS.ROYALTY_PERCENT" | translate }}
                    </div>
                    <div class="info">{{ room.royalty_percent_label }}</div>
                    <div
                      *ngIf="
                        !room.royalty_percent && room.royalty_percent !== 0
                      "
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <!--<input matInput-->
                          <!--placeholder="{{ 'ROOMS.ROYALTY_PERCENT' | translate}}"-->
                          <!--formControlName="royalty_percent"-->
                          <!--[(ngModel)]="room.royalty_percent">-->

                          <mat-label>{{
                            "ROOMS.ROYALTY_PERCENT" | translate
                          }}</mat-label>
                          <input
                            matInput
                            type="text"
                            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                            autocomplete="nope"
                            [(ngModel)]="room.royalty_percent"
                            formControlName="royalty_percent"
                          />

                          <mat-error
                            *ngIf="form.controls['royalty_percent'].invalid"
                          >
                            {{ "GENERIC.INPUT_NUMBER_0_100" | translate }}
                            {{
                              "GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL"
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="50%"></div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%"></div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <!--royalty_on_food-->
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "ROOMS.ROYALTY_FOOD" | translate }}
                    </div>
                    <div *ngIf="room.royalty_on_food" class="info">
                      {{ "GENERIC.YES" | translate }}
                    </div>
                    <div *ngIf="!room.royalty_on_food" class="info">
                      {{ "GENERIC.NO" | translate }}
                    </div>
                  </div>
                  <div *ngIf="editMode" class="info-line py-8" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-checkbox
                          labelPosition="before"
                          (ngModelChange)="onRoyaltyFoodChange($event)"
                          [ngModel]="room.royalty_on_food"
                          [ngModelOptions]="{ standalone: true }"
                          >{{ "ROOMS.ROYALTY_FOOD" | translate }}
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>

                  <!--royalty_on_beverage-->
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "ROOMS.ROYALTY_BEVERAGE" | translate }}
                    </div>
                    <div *ngIf="room.royalty_on_beverage" class="info">
                      {{ "GENERIC.YES" | translate }}
                    </div>
                    <div *ngIf="!room.royalty_on_beverage" class="info">
                      {{ "GENERIC.NO" | translate }}
                    </div>
                  </div>
                  <div *ngIf="editMode" class="info-line py-8" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-checkbox
                          labelPosition="before"
                          (ngModelChange)="onRoyaltyBeverageChange($event)"
                          [ngModel]="room.royalty_on_beverage"
                          [ngModelOptions]="{ standalone: true }"
                          >{{ "ROOMS.ROYALTY_BEVERAGE" | translate }}
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <!--royalty_on_material-->
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "ROOMS.ROYALTY_MATERIAL" | translate }}
                    </div>
                    <div *ngIf="room.royalty_on_material" class="info">
                      {{ "GENERIC.YES" | translate }}
                    </div>
                    <div *ngIf="!room.royalty_on_material" class="info">
                      {{ "GENERIC.NO" | translate }}
                    </div>
                  </div>
                  <div *ngIf="editMode" class="info-line py-8" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-checkbox
                          labelPosition="before"
                          (ngModelChange)="onRoyaltyMaterialChange($event)"
                          [ngModel]="room.royalty_on_material"
                          [ngModelOptions]="{ standalone: true }"
                          >{{ "ROOMS.ROYALTY_MATERIAL" | translate }}
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>

                  <!--royalty_on_service-->
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "ROOMS.ROYALTY_SERVICE" | translate }}
                    </div>
                    <div *ngIf="room.royalty_on_service" class="info">
                      {{ "GENERIC.YES" | translate }}
                    </div>
                    <div *ngIf="!room.royalty_on_service" class="info">
                      {{ "GENERIC.NO" | translate }}
                    </div>
                  </div>
                  <div *ngIf="editMode" class="info-line py-8" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-checkbox
                          labelPosition="before"
                          (ngModelChange)="onRoyaltyServiceChange($event)"
                          [ngModel]="room.royalty_on_service"
                          [ngModelOptions]="{ standalone: true }"
                          >{{ "ROOMS.ROYALTY_SERVICE" | translate }}
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div
          *ngIf="room && room.sub_rooms && room.place && !subRoomFinalLayer"
          class=""
          fxLayout="column"
        >
          <app-rooms
            #roomsComponent
            [useDataCollection]="room.sub_rooms !== null"
            [parentType]="'PLACE'"
            [(roomDataCollection)]="room.sub_rooms"
            [editMode]="editMode"
            [parentUUID]="this.room.uuid"
            [parentIsSubRoom]="this.room.is_subroom"
            (modelUpdatedEvent)="onRoomModelUpdated($event)"
            [disabledAddSubRoom]="!room.place_is_internal"
            [breadCrumbName]="'ROOMS.SUB_ROOMS' | translate"
          ></app-rooms>
        </div>

        <div
          *ngIf="room"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "GENERIC.NOTES" | translate }}</div>
          </header>

          <div class="note-section">
            <!--<form [formGroup]="form" class="">-->
            <p-editor
              #noteEditor
              [(ngModel)]="room.note"
              [readonly]="!editMode"
              [style]="{
                height: '120px',
                width: '100%',
                'background-color': editMode ? '#ffffff' : '',
              }"
            >
              <ng-template pTemplate="header" class="">
                <span class="ql-formats">
                  <select class="ql-font"></select>
                  <select class="ql-size"></select>
                </span>

                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>

                <span class="ql-formats">
                  <select class="ql-color"></select>
                  <select class="ql-background"></select>
                </span>

                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                </span>
              </ng-template>
            </p-editor>
            <!--</form>-->
            <mat-error
              *ngIf="noteInvalid"
              fxLayout="row"
              fxLayoutAlign="end center"
              class="p-4 pr-8"
            >
              {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID_2048" | translate }}
            </mat-error>
          </div>
        </div>

        <app-comment-section
          *ngIf="room"
          [commentsModel]="room"
          [serviceAPIUrl]="'/v1/rooms/comments/' + room.uuid"
        >
        </app-comment-section>

        <app-created-updated-at
          *ngIf="room"
          [historicModel]="room.historic_model"
        ></app-created-updated-at>

        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <div
            *ngIf="room"
            fxFlex="100%"
            fxLayout="row"
            fxLayoutAlign="center center"
            class="mt-8"
          >
            <button mat-raised-button (click)="deleteRoom()" class="warn">
              {{ "GENERIC.DELETE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="room" fxLayout="row" fxLayoutAlign="end start">
  <div *ngxPermissionsOnly="rolesService.SALE_ROLES">
    <div class="fixed-button">
      <!-- <button mat-fab class="gt2-edit-button" id="edit-employee-button" (click)="onEdit($event)" [disabled]="loading"
                *fuseIfOnDom [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
                <mat-icon *ngIf="!editMode">{{ getIcon('EDIT_FAB') }}</mat-icon>
                <mat-icon *ngIf="editMode">{{ getIcon('UNDO') }}</mat-icon>
            </button> -->

      <button
        mat-fab
        #saveButton
        [ngClass]="editMode ? 'gt2-save-button' : 'gt2-edit-button'"
        id="edit-room-button"
        (click)="onEdit($event)"
        [disabled]="loading"
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
      >
        <mat-icon class="icon-mat-black" *ngIf="!editMode && !loading">{{
          getIcon("EDIT_FAB")
        }}</mat-icon>
        <mat-icon
          *ngIf="editMode && !loading && !saveButton.disabled"
          matTooltip="{{ 'GENERIC.SAVE' | translate }}"
          position="left"
          showDelay="900"
          hideDelay="900"
          class="icon-mat-black"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <mat-icon
          *ngIf="editMode && !loading && saveButton.disabled"
          matTooltip="{{ 'GENERIC.FORM_DIRTY_TIP' | translate }}"
          position="left"
          hideDelay="900"
          class="icon-mat-black"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <i [ngClass]="loading ? 'fa fa-spinner fa-spin' : ''"></i>
      </button>
    </div>
    <div class="fixed-button2">
      <button
        mat-fab
        *ngIf="editMode"
        id="cancel-edit-room-button"
        class="gt2-cancel-edit-button"
        matTooltip="{{ 'GENERIC.CANCEL' | translate }}"
        position="left"
        showDelay="900"
        hideDelay="900"
        (click)="onCancelEdit($event)"
        [disabled]="loading"
        [@animate]="{ value: '*', params: { delay: '120ms', scale: '.2' } }"
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("CANCEL") }}</mat-icon>
      </button>
    </div>
  </div>
</div>
