export const locale = {
  lang: "en-CA",
  data: {
    CREATE_CLIENT_ENTERPRISE: {
      TITLE: "New client and enterprise",
      CLIENT_FIRST_NAME: "Client first name",
      CLIENT_LAST_NAME: "Client last name",
      ENTERPRISE_NAME: "Enterprise name",
      CREATE_CLIENT_ENTERPRISE_SUCCESS: "Client and enterprise created succesfully.",
    },
  },
};
