import { HttpClient } from '@angular/common/http';
import { Gt2ApiService } from './gt2-api.service';
import { ApiRoutes } from './ApiRoutes';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class WrappingService {
  wrappingsObs?: Observable<any>;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getWrapping() {
    // this.logger.info("WrappingService.getWrapping()");
    if (!this.wrappingsObs) {
      this.wrappingsObs = this.http
        .get<any>(this.api.createUrl(ApiRoutes.WRAPPING))
        .pipe(shareReplay(1))
        .pipe(tap((data) => this.resetObservable()));
    }

    return this.wrappingsObs;
  }

  public getWrappingList() {
    // this.logger.info("WrappingService.getWrappingList()");
    if (!this.wrappingsObs) {
      this.wrappingsObs = this.http
        .get<any>(this.api.createUrl(ApiRoutes.WRAPPING_LIST))
        .pipe(shareReplay(1))
        .pipe(tap((data) => this.resetObservable()));
    }

    return this.wrappingsObs;
  }

  private resetObservable(): void {
    // this.logger.info("WrappingService.resetObservable()");
    this.wrappingsObs = null;
  }

  public getData() {
    // this.logger.info("WrappingService.getData()");
    return this.getWrappingList();
  }
}
