<div id="processes" class="custom-sheet-container">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="sheet-header primary"
    (click)="onToggleShow()"
  >
    <div class="pl-12">{{ "PROCESSES.ONGOING_PROCESS" | translate }}</div>
    <mat-icon *ngIf="show" class="s-24 mr-12 icon-mat-darkmode"
      >minimize</mat-icon
    >
    <mat-icon *ngIf="!show" class="s-24 mr-12 icon-mat-darkmode"
      >maximize</mat-icon
    >
    <!-- <mat-icon color="warn" class="s-24 mr-12">warning</mat-icon> -->
  </div>

  <div *ngIf="show">
    <div *ngFor="let item of processes" class="">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="process-child-item"
        [ngClass]="item.status === 'error' ? 'warn' : ''"
      >
        <div class="mr-16">
          {{ item.label }}
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-spinner
            *ngIf="item.status !== 'error'"
            [diameter]="28"
            class="mr-4"
            color="primary"
          ></mat-spinner>
          <button
            mat-icon-button
            *ngIf="item.type === 'print' || item.type === 'download'"
            matTooltip="{{ 'GENERIC.CANCEL' | translate }}"
            (click)="onCancelProcess(item)"
            class=""
          >
            <mat-icon class="s-20 icon-mat-darkmode">{{
              iconsService.getIcon("CANCEL")
            }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
