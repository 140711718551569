<div *ngIf="shops" fxLayout="column">
  <div *ngIf="shops.length === 0" fxLayout="row" fxLayoutAlign="center center">
    <div style="padding: 20px; font-size: 19px">
      {{ "PRODUCTS.NO_SHOPS" | translate }}
    </div>
  </div>
  <div
    *ngFor="let item of shops; let i = index"
    class="shop-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    matRipple
  >
    <mat-checkbox
      *ngIf="!loadingShop[item.uuid]"
      (click)="onShopItemClick(item)"
      (ngModelChange)="onShopCheckbox($event, item)"
      [ngModel]="item.selected"
      [ngModelOptions]="{ standalone: true }"
      color="accent"
      labelPosition="before"
    >
    </mat-checkbox>
    <div *ngIf="loadingShop[item.uuid]">
      <mat-spinner [diameter]="22" class=""></mat-spinner>
    </div>
    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between center"
      style="margin-left: 15px"
    >
      <div fxFlex="32%">{{ item.name }}</div>
      <div fxFlex="44%">{{ item.shop_type_label }}</div>
      <div fxFlex="24%">{{ item.shop_product_type_label }}</div>
      <div fxLayout="row" fxLayoutAlign="end start" style="min-width: 90px">
        <button
          *ngIf="hasCyclicMenuModule && product.is_cyclic"
          mat-icon-button
          matTooltip="{{ 'GENERIC.SET_PRODUCT_CYCLIC_DATE' | translate }}"
          (click)="onSetCyclicDatesForShop(item); $event.stopPropagation()"
          class="mat-icon-button"
          tabindex="-1"
        >
          <mat-icon *ngIf="item.has_cyclic_dates" color="accent" class="s-20"
            >schedule</mat-icon
          >
          <mat-icon *ngIf="!item.has_cyclic_dates" color="primary" class="s-20"
            >schedule</mat-icon
          >
        </button>
        <div *ngIf="item.shop_url">
          <button
            mat-icon-button
            *ngxPermissionsOnly="rolesService.SALE_ROLES"
            matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
            (click)="
              onOpenShopInNewTab(item.shop_url); $event.stopPropagation()
            "
            class="mat-icon-button"
            tabindex="-1"
          >
            <mat-icon class="s-20">{{ getIcon("NEW_TAB") }} </mat-icon>
          </button>
        </div>
        <div *ngIf="!item.shop_url && item.gt_product_shop_url">
          <button
            mat-icon-button
            *ngxPermissionsOnly="rolesService.SALE_ROLES"
            matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
            (click)="
              onOpenShopInNewTab(item.gt_product_shop_url);
              $event.stopPropagation()
            "
            class="mat-icon-button"
            tabindex="-1"
          >
            <mat-icon class="s-20">{{ getIcon("NEW_TAB") }} </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
