import { SearchResultsTypeEnum } from "../content/search-results/SearchResultsTypeEnum";
import { AppRoutes } from "../const/AppRoutes";

export class ItemUrlFinder {
  static getItemURL(item: any): string {
    let slug: string;
    if (item.slug === null || item.slug == undefined || item.slug === "") {
      slug = "-";
    } else {
      slug = item.slug;
    }
    switch (item.object) {
      case SearchResultsTypeEnum.CONGRESS: {
        return "/" + AppRoutes.CONGRESSES + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.PROJECT: {
        return "/" + AppRoutes.PROJECT + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.CLIENT: {
        return "/" + AppRoutes.CLIENTS + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.ENTERPRISE: {
        return "/" + AppRoutes.ENTERPRISES + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.USER: {
        return "";
      }
      case SearchResultsTypeEnum.EMPLOYEE: {
        return "/" + AppRoutes.EMPLOYEES + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.PLACE: {
        return "/" + AppRoutes.VENUES + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.ROOM: {
        if (item.is_subroom) {
          return "/" + AppRoutes.SUB_ROOMS + "/" + slug + "/" + item.uuid + "?loc=Places";
        }
        return "/" + AppRoutes.ROOMS + "/" + slug + "/" + item.uuid + "?loc=Places";
      }
      case SearchResultsTypeEnum.PRODUCT: {
        return "/" + AppRoutes.PRODUCTS + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.MATERIAL: {
        return "/" + AppRoutes.MATERIALS + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.MATERIAL2: {
        return "/" + AppRoutes.MATERIALS + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.ORDER: {
        if (item.type === "PROPOSAL") {
          return "/" + AppRoutes.PROPOSITIONS + "/" + slug + "/" + item.uuid;
        } else if (item.type === "INVOICE") {
          return "/" + AppRoutes.INVOICES + "/" + slug + "/" + item.uuid;
        }

        // default
        return "/" + AppRoutes.ORDERS + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.EVENT: {
        return "/" + AppRoutes.EVENTS + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.CATERER: {
        return "/" + AppRoutes.CATERERS + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.ORG: {
        return "/" + AppRoutes.ORGANIZATIONS + "/" + slug + "/" + item.uuid;
      }
      case SearchResultsTypeEnum.SHOP: {
        return AppRoutes.SHOPS + "/" + item.shop_type.slug + "/" + item.slug + "/" + item.uuid;
        // return "/" + AppRoutes.SHOPS + "/" + slug + "/" + item.uuid;
      }

      // case SearchResultsTypeEnum.PROJECT : {
      //     return "/" + AppRoutes.PROJECTS + "/" + slug + "/" + item.uuid;
      // }
      default: {
        return AppRoutes.ERROR_404;
      }
    }
  }
}
