export const locale = {
  lang: "en-CA",
  data: {
    CREATE_CLIENT: {
      TITLE: "New client",
      TITLE_CONTACT: "Infos contact client",
      TITLE_ADDRESS: "Infos address client",
      COMM_LANGUAGE: "Communication language",
    },
    CREATE_ENTERPRISE: {
      TITLE: "New entreprise",
      TITLE_CONTACT: "Company contact info",
      TITLE_ADDRESS: "Company address info",
    },
  },
};
