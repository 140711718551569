export const locale = {
  lang: "en-CA",
  data: {
    CREATE_EVENT: {
      TITLE: "New event",
      PROPOSAL_TITLE: "New event proposal",
      ORDER_TITLE: "New event order",
      CLONE_PROPOSITION_INTO_EVENT_TITLE: "Clone proposal into a new event",
      CLONE_PROPOSITION_INTO_EVENT_TITLE_KEEP: "Clone proposal into a copy of the event",
      CLONE_ORDER_INTO_EVENT_TITLE: "Clone order into a new event",
      CLONE_ORDER_INTO_EVENT_TITLE_KEEP: "Clone order into a copy of the event",
      NAME_NOT_SAME: " and not the same as the proposal",
      DATE_NOT_SAME: "The date must be different than the proposal",
      COPY: "Copy - ",
      ORDER_TYPE: "Order Type",
    },
  },
};
