export const locale = {
  lang: "en-CA",
  data: {
    NAV: {
      APPLICATIONS: "Applications",
      DASHBOARD: {
        TITLE: "Dashboard",
      },
      DASHBOARD_DETAILED: {
        TITLE: "Advanced Dashboard",
      },
      DASHBOARD_DETAILED_JUL: {
        TITLE: "Dashboard JUL",
      },
      PRODUCTS: {
        TITLE: "Products",
      },
      CLIENTS: {
        TITLE: "Clients and enterprises",
      },
      VENUES: {
        TITLE: "Places",
      },
      ROOMS: {
        TITLE: "Rooms",
      },
      ORDERS: {
        TITLE: "Orders",
      },
      REPORTS: {
        TITLE: "Reports",
      },
      SETTINGS: {
        TITLE: "Settings",
      },
      SUPPORT: {
        TITLE: "Support",
      },
      CONTACT: {
        TITLE: "Ticket request",
      },
      KB: {
        TITLE: "Knowledge Base",
      },
      FAQ: {
        TITLE: "FAQ",
      },
      PROFILE: {
        TITLE: "Profile",
      },
      ORGANIZATION: {
        TITLE: "Organization",
      },
      EMPLOYEES: {
        TITLE: "Employees",
      },
      TODO: {
        TITLE: "To Do",
      },
      TEST: {
        TITLE: "Test Page (dev)",
      },
      ROOM_MANAGEMENT: {
        TITLE: "Room management",
      },

      ADMIN: "Admin",
      GOD: "God",
      GOD_LOCAL_STORAGE: {
        TITLE: "Local storage",
      },
      GOD_REPORTS: {
        TITLE: "Reports",
      },
      GOD_EMAILS: {
        TITLE: "Emails",
      },
      GOD_IMPERSONATION: {
        TITLE: "Impersonation",
      },
      DEV: "Dev",
      MODULES: "Modules",
      MODULE_1: {
        TITLE: "Module 1",
      },
      MODULE_2: {
        TITLE: "Module 2",
      },
      FUNCTIONS: {
        TITLE: "Functions",
      },
      MATERIALS: {
        TITLE: "Equipments",
      },
      MANAGEMENT: {
        TITLE: "Management",
      },
      DOCUMENTATION: {
        TITLE: "Knowledge base & FAQ",
      },
      RELEASE_NOTES: {
        TITLE: "Release notes",
      },
      SHOPS: {
        TITLE: "Shops",
      },
      CONGRESSES: {
        TITLE: "Congresses",
      },
      UPSALE: {
        TITLE: "Upsale",
      },
    },
  },
  // data: {
  //     'NAV': {
  //         'APPLICATIONS': 'Applications',
  //         'DASHBOARD': {
  //             "TITLE": "Dashboard"
  //         },
  //         'PRODUCTS': {
  //             "TITLE": "Products"
  //         },
  //         'CLIENTS': {
  //             "TITLE": "Clients"
  //         },
  //         'VENUES': {
  //             "TITLE": "Venues"
  //         },
  //         'ORDERS': {
  //             "TITLE": "Orders"
  //         },
  //         'REPORTS': {
  //             "TITLE": "Reports"
  //         },
  //         'SUPPORT': {
  //             "TITLE": "Support"
  //         },
  //         'PROFILE': {
  //             "TITLE": "Profile"
  //         },
  //         'ORGANIZATION': {
  //             "TITLE": "Organization"
  //         },
  //         'EMPLOYEES': {
  //             "TITLE": "Employees"
  //         },
  //         'TODO' : {
  //             "TITLE" : "To Do"
  //         },
  //         'TEST': {
  //             'TITLE': "Test Page (dev)"
  //         },
  //
  //         'ADMIN': "Admin",
  //
  //         'GOD': "God",
  //         'GOD_LOCAL_STORAGE': {
  //             'TITLE': "Local storage"
  //         },
  //         'GOD_EMAILS': {
  //             'TITLE': "Emails"
  //         },
  //         'GOD_IMPERSONATION': {
  //             'TITLE': "Impersonation"
  //         },
  //         'DEV': "Dev",
  //         'MODULES': "Modules",
  //         'MODULE_1' : {
  //             'TITLE' : "Module 1",
  //         },
  //         'MODULE_2' : {
  //             'TITLE' : "Module 2",
  //         },
  //     }
  // }
};
