<div id="invite" fxLayout="row" fxLayoutAlign="start">
  <div id="invite-intro" fxFlex fxHide fxShow.gt-xs>
    <div
      class="logo"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { scale: '0.2' } }"
    >
      <img src="assets/images/logos/{{ 'APP.LOGO' | translate }}" />
    </div>

    <div
      class="title"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }"
    >
      {{ "INVITE_WIZARD.WELCOME_MESSAGE" | translate }}
    </div>

    <div
      class="description"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }"
    >
      {{ "INVITE_WIZARD.WELCOME_LONG_MESSAGE" | translate }}
    </div>
  </div>

  <div
    id="invite-form-wrapper"
    fusePerfectScrollbar
    *fuseIfOnDom
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
  >
    <!--<button mat-raised-button-->
    <!--color="accent"-->
    <!--class="submit-button"-->
    <!--aria-label="SEND FAKE DATA"-->
    <!--(click)="onTest()">-->
    <!--SEND FAKE DATA-->
    <!--</button>-->

    <div id="invite-form">
      <div class="logo" fxHide.gt-xs>
        <!--<span>F</span>-->
        <img src="assets/images/logos/{{ 'APP.LOGO' | translate }}" />
      </div>

      <div class="title">{{ "INVITE_WIZARD.SETUP_ACCOUNT" | translate }}</div>

      <mat-divider></mat-divider>

      <form [formGroup]="profileFormGroup">
        <ng-template matStepLabel>{{
          "INVITE_WIZARD.PROFILE_LABEL" | translate
        }}</ng-template>

        <div class="step-description">
          {{ "INVITE_WIZARD.PROFILE_INFO" | translate }}
        </div>

        <!--<div class="spacer" style="height: 28px"></div>-->

        <mat-radio-group
          formControlName="genderCtrl"
          value="MALE"
          class="gender-radio-group"
          margin
        >
          <mat-radio-button checked="true" value="MALE">{{
            "GENERIC.GENDER.MR" | translate
          }}</mat-radio-button>
          <mat-radio-button value="FEMALE">{{
            "GENERIC.GENDER.MS" | translate
          }}</mat-radio-button>
        </mat-radio-group>

        <mat-form-field>
          <mat-label>{{ "INVITE_WIZARD.FIRST_NAME" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'INVITE_WIZARD.FIRST_NAME' | translate }}"
            type="text"
            formControlName="firstNameCtrl"
            required
          />
          <mat-error *ngIf="!profileFormGroupErrors.firstNameCtrl.invalid">
            {{ "INVITE_WIZARD.FIRST_NAME_REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "INVITE_WIZARD.LAST_NAME" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'INVITE_WIZARD.LAST_NAME' | translate }}"
            type="text"
            formControlName="lastNameCtrl"
            required
          />
          <mat-error *ngIf="!profileFormGroupErrors.lastNameCtrl.invalid">
            {{ "INVITE_WIZARD.LAST_NAME_REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "INVITE_WIZARD.PASSWORD" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'INVITE_WIZARD.PASSWORD' | translate }}"
            type="password"
            formControlName="password1Ctrl"
            required
          />
          <mat-error *ngIf="!profileFormGroupErrors.lastNameCtrl.invalid">
            {{ "INVITE_WIZARD.PASSWORD_REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{
            "INVITE_WIZARD.PASSWORD_AGAIN" | translate
          }}</mat-label>
          <input
            matInput
            placeholder="{{ 'INVITE_WIZARD.PASSWORD_AGAIN' | translate }}"
            type="password"
            formControlName="password2Ctrl"
            required
          />
          <mat-error
            *ngIf="profileFormGroupErrors.password2Ctrl.passwordsNotMatch"
          >
            {{ "INVITE_WIZARD.PASSWORD_NO_MATCH" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "INVITE_WIZARD.PHONE" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'INVITE_WIZARD.PHONE' | translate }}"
            type="tel"
            formControlName="phoneNumberCtrl"
            required
          />
          <mat-error *ngIf="!profileFormGroupErrors.lastNameCtrl.invalid">
            {{ "INVITE_WIZARD.PHONE_REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <!--<input matInput placeholder="Phone number extension">-->
          <mat-label>{{ "INVITE_WIZARD.PHONE_EXT" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'INVITE_WIZARD.PHONE_EXT' | translate }}"
            type="number"
            formControlName="phoneNumberExtensionCtrl"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Language</mat-label>
          <mat-select
            placeholder="Language"
            formControlName="localeCtrl"
            [(ngModel)]="defaultLanguage"
            required
          >
            <mat-option *ngFor="let lang of languages" [value]="lang.locale">
              {{ lang.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--LOADING-->
        <div *ngIf="loading" xLayout="column" fxLayoutAlign="center center">
          <div class="spacer" style="height: 22px"></div>
          <mat-spinner></mat-spinner>
          <div class="spacer" style="height: 12px"></div>
        </div>

        <!--INVITE BUTTON-->
        <div fxLayout="column" fxLayoutAlign="center center">
          <button
            mat-raised-button
            color="accent"
            class="submit-button"
            aria-label="COMPLETE YOUR ACCOUNT"
            [disabled]="profileFormGroup.invalid || inviteResponse"
            (click)="onCreateAccount()"
          >
            {{ "INVITE_WIZARD.REGISTER_ACCOUNT" | translate }}
          </button>
        </div>

        <div
          *ngIf="inviteResponse != null"
          [@animate]="{ value: '*', params: { delay: '75ms', x: '100%' } }"
        >
          <div class="step-description">
            {{ "INVITE_WIZARD.FINALIZE_SUCCESS_MESSAGE" | translate }}
            <div class="spacer" style="height: 36px"></div>

            <div class="" fxLayout="column" fxLayoutAlign="center center">
              <button
                mat-raised-button
                color="accent"
                class="submit-button"
                aria-label="LOG IN TO YOUR ACCOUNT"
                (click)="onLogin()"
              >
                {{ "GENERIC.LOGIN" | translate | uppercase }}
              </button>
            </div>
          </div>
        </div>

        <div
          *ngIf="userAlreadyExist"
          fxLayout="column"
          fxLayoutAlign="center center"
          class="response-message"
        >
          <span class="text">{{
            "INVITE_WIZARD.USER_ALREADY_EXIST" | translate
          }}</span>
        </div>

        <div
          *ngIf="userNotFound"
          fxLayout="column"
          fxLayoutAlign="center center"
          class="response-message"
        >
          <span class="text">{{
            "INVITE_WIZARD.USER_NOT_FOUND" | translate
          }}</span>
        </div>
      </form>

      <div class="spacer" style="height: 8px"></div>

      <div class="invite" fxLayout="column" fxLayoutAlign="center center">
        <span class="text">{{
          "INVITE_WIZARD.ALREADY_HAVE_ACCOUNT" | translate
        }}</span>
        <a class="link" [routerLink]="'/' + appRoutesService.auth">{{
          "GENERIC.LOGIN" | translate | titlecase
        }}</a>
      </div>
    </div>
  </div>
</div>
