<div>
  <div class="border-bottom">
    <div
      *ngIf="!isLoadingResults && resultsLength === 0"
      fxLayout="row"
      fxLayoutAlign="center start"
      class="p-8 mat-subheading-2"
      style="margin-bottom: -3px"
    >
      {{ "CONGRESSES.NO_PROJECTS" | translate }}
    </div>

    <div *ngIf="isLoadingResults" class="">
      <mat-progress-bar
        mode="query"
        color="warn"
        style="height: 3px"
      ></mat-progress-bar>
    </div>
  </div>

  <mat-table
    #table
    class=""
    matSort
    matSortStart="desc"
    *ngIf="dataSource && dataSource.data"
    [ngStyle]="{
      display:
        dataSource && dataSource.data && dataSource.data.length === 0
          ? 'none'
          : '',
    }"
    [@animateStagger]="{ value: '50' }"
    [dataSource]="dataSource"
  >
    <ng-container cdkColumnDef="full_name">
      <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.NAME" | translate }}
      </mat-header-cell>

      <mat-cell fxFlex="50" *cdkCellDef="let project">
        <p class="text-truncate font-weight-500">
          {{ project?.name }}
        </p>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="date_start">
      <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header>
        {{ "GENERIC.DATE" | translate }}
      </mat-header-cell>

      <mat-cell fxFlex="50" *cdkCellDef="let project">
        <p class="text-truncate font-weight-500">
          {{ project.date_start_label }}
        </p>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="ordering_client">
      <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header>
        {{ "GENERIC.CLIENT" | translate }}
      </mat-header-cell>

      <mat-cell fxFlex="50" *cdkCellDef="let project">
        <p class="text-truncate font-weight-500">
          {{ project?.ordering_client?.full_name }}
        </p>
      </mat-cell>
    </ng-container>

    <!-- Entreprise column-->
    <ng-container cdkColumnDef="enterprise">
      <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header>
        {{ "GENERIC.ENTERPRISE" | translate }}
      </mat-header-cell>

      <mat-cell fxFlex="50" *cdkCellDef="let project">
        <p class="text-truncate font-weight-500">
          {{ project?.ordering_client?.enterprise?.name }}
        </p>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="actions">
      <mat-header-cell fxFlex="92px" *cdkHeaderCellDef mat-sort-header>
      </mat-header-cell>
      <mat-cell fxFlex="92px" *cdkCellDef="let project">
        <button mat-icon-button (click)="onOpenPage(project)">
          <mat-icon class="s-16 secondary-text">{{ getIcon("EDIT") }}</mat-icon>
        </button>

        <button
          mat-icon-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenProjectInNewTab(project); $event.stopPropagation()"
          class="mat-icon-button"
        >
          <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row
      *cdkRowDef="let project; columns: displayedColumns"
      class=""
      matRipple
      [routerLink]="
        '/' + appRoutesService.project + '/' + project.slug + '/' + project.uuid
      "
    >
    </mat-row>
  </mat-table>

  <mat-paginator
    #paginator
    [length]="resultsLength"
    [ngStyle]="{
      display:
        dataSource && dataSource.data && dataSource.data.length === 0
          ? 'none'
          : '',
    }"
    (page)="datatableHelperService.onPaginator($event, 'congres-projects')"
    showFirstLastButtons="true"
    [pageIndex]="0"
    [pageSize]="'congres-projects' | tableSizePipe: 'large'"
    [pageSizeOptions]="datatableHelperService.pageSizeShortOptions"
  >
  </mat-paginator>

  <div
    *ngIf="isLoadingResults && !isFirstLoad"
    fxLayout="row"
    fxLayoutAlign="center start"
    class=""
  >
    <mat-progress-bar
      mode="query"
      color="warn"
      style="height: 3px"
    ></mat-progress-bar>
  </div>
</div>
