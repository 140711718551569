import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class EventRoomService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getEventRoom(date: string) {
    // this.logger.info("EventRoomService.getEventRoom()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.EVENT_ROOM) + date);
  }

  public updateEvent(eventUUID: string, event: any) {
    //this.logger.info("EventRoomService.updateEvent()");
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT_ROOM_UPDATE) + eventUUID, event);
  }

  public createEvent(event: any) {
    //this.logger.info("EventRoomService.createEvent()");
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT_ROOM_CREATE), event);
  }

  public createBlocked(event: any) {
    //this.logger.info("EventRoomService.createBlocked()");
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT_ROOM_BLOCKED), event);
  }

  public createEventForReservation(reservationID: string, orderType: string) {
    //this.logger.info("EventRoomService.createBlocked()");
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT_ROOM_CREATE_FROM_RESERVATION), { reservation_id: reservationID, order_type: orderType });
  }

  public updateReservation(event: any, reservationID: string) {
    //this.logger.info("EventRoomService.updateReservation()");
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.UPDATE_EVENT_ROOM_BLOCKED) + "/" + reservationID, event);
  }

  public deleteReservation(reservationID: string) {
    //this.logger.info("EventRoomService.deleteReservation()");
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.DELETE_EVENT_ROOM_BLOCKED) + "/" + reservationID);
  }
}
