import { GroupNavigationInterface } from "./GroupNavigationInterface";

export class DevGroupNavigationModel implements GroupNavigationInterface {
  id: string = "devMenu";
  title: string = "Dev";
  translate: string = "NAV.DEV";
  type: string = "collapsable";
  children: any[] = [
    {
      id: "test",
      title: "Test Page (dev)",
      translate: "NAV.TEST.TITLE",
      type: "item",
      icon: "adjust",
      url: "/test",
    },
    {
      id: "roomManagement",
      title: "Gestion des salles",
      translate: "NAV.ROOM_MANAGEMENT.TITLE",
      type: "item",
      icon: "room",
      url: "/gestion-salles",
    },
  ];
}
