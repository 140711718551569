<div fxFlex fxLayout="row" fxLayout.lt-sm="column">
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxFlex="50"
    [fusePerfectScrollbar]="false"
  >
    <div
      class="toolbar gt2-purple"
      fxLayout="row"
      fxLayoutAlign="center center"
      style="height: 40px; max-height: 40px; min-height: 40px"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        class="pl-16"
        *ngIf="dateFormat"
      >
        <button
          mat-icon-button
          class="arrow"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="setSelectedDay($event)"
          aria-label="Previous"
        >
          <mat-icon class="icon-color">chevron_left</mat-icon>
        </button>

        <div
          class="title"
          (click)="dp2.open()"
          style="
            cursor: pointer;
            border-radius: 2px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
          "
        >
          {{
            viewDate | calendarDate: view + "ViewTitle" : translate.currentLang
          }}
        </div>

        <mat-form-field style="width: 1px; visibility: hidden">
          <input
            matInput
            [min]="minDate"
            [max]="maxDate"
            [matDatepicker]="dp2"
            readonly
          />
          <mat-datepicker
            #dp2
            startView="year"
            (monthSelected)="closeDatePicker($event, dp2)"
          ></mat-datepicker>
        </mat-form-field>

        <button
          mat-icon-button
          class="arrow"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="setSelectedDay($event)"
          aria-label="Next"
        >
          <mat-icon class="icon-color">chevron_right</mat-icon>
        </button>
      </div>
    </div>

    <ngx-spinner
      [name]="'calenderSpinner'"
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="white"
      [fullScreen]="false"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">
        {{ "DASHBOARD.LOADING" | translate }}
      </p>
    </ngx-spinner>

    <ng-template #customCellTemplate let-day="day" let-locale="locale">
      <div
        [ngClass]="
          (day.date | calendarDate: 'dayViewTitle') ===
          (selectedDayForEvents.date | calendarDate: 'dayViewTitle')
            ? 'selected-day'
            : ''
        "
      >
        <div class="cal-cell-top">
          <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
            day.badgeTotal
          }}</span>
          <span class="cal-day-number">{{
            day.date | calendarDate: "monthViewDayNumber" : locale
          }}</span>
        </div>
        <div class="cell-totals">
          <span
            *ngFor="let group of day.eventGroups"
            class="badge badge-{{ group[0] }}"
          >
            {{ group[1].length }}
          </span>
        </div>
      </div>
    </ng-template>

    <!-- CONTENT -->
    <!-- <div fxLayout="column" style="max-height: 100vh; height: 100vh"> -->
    <div style="height: 100%" fusePerfectScrollbar>
      <div
        class=""
        [@animate]="{ value: '*', params: { delay: '200ms', y: '50px' } }"
      >
        <div [ngSwitch]="view">
          <mwl-calendar-month-view
            *ngSwitchCase="'month'"
            [viewDate]="viewDate"
            [events]="events"
            [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="editEvent('edit', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
            (beforeViewRender)="beforeMonthViewRender($event)"
            [cellTemplate]="customCellTemplate"
            [refresh]="refreshCalendar"
            [weekStartsOn]="0"
          >
          </mwl-calendar-month-view>

          <!--<mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" (viewDateChange)="selectedDay = {date:$event}"
            [events]="events" [refresh]="refresh" (dayClicked)="dayClicked($event.day)" (eventClicked)="editEvent('edit', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)" weekStartsOn="1">
          </mwl-calendar-week-view>
  
          <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" (viewDateChange)="selectedDay = {date:$event}"
            [events]="events" [refresh]="refresh" (dayClicked)="dayClicked($event.day)" (eventClicked)="editEvent('edit', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)">
          </mwl-calendar-day-view>-->
        </div>
      </div>
      <app-dashboard-note [date]="selectedDay"></app-dashboard-note>
    </div>
  </div>

  <!-- 
    <div *ngIf="moduleService.modulesAreSet && (moduleService.hasCTReport() || moduleService.hasDANReport())" class="test">
        Omega!
    </div> 
    -->

  <style>
    .test {
      margin: 6px;
    }
  </style>

  <!-- client is one of the list for content to show -->

  <!--<div *gtClient="['DAN', gt.client.CT]" class="test">
        Is CT or DAN! &nbsp;
    </div> -->

  <!-- client is NOT one of the list for content to show
    <div *gtNotClient="[gt.client.CT]" class="test">
        Is not CT! &nbsp;
    </div>-->

  <!-- client with direct ON / OFF 
    <div *gtIsCT class="test">
        'gtIsCT' is CT! &nbsp;
    </div>-->

  <!-- is client in a *ngIf 
    <div *ngIf="gt.client.isCT" class="test">
        'ngIf' is CT! &nbsp;
    </div>-->

  <!-- EVENTS LIST -->

  <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="50">
    <div
      style="height: 40px"
      class="toolbar gt2-pink mobile-margin-top"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div class="section-title px-12 py-4">
        {{ "GENERIC.EVENTS" | translate }}
      </div>
      <div class="section-subtitle mr-12">
        {{
          selectedDayForEvents.date
            | calendarDate: "dayViewTitle" : translate.currentLang
        }}
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start">
      <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="1 1 100%">
        <div
          *ngIf="!ordersOfTheDay || ordersOfTheDay.length === 0"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxFlex="100"
        >
          <div class="py-16">
            {{ "DASHBOARD.NO_ORDERS" | translate }}
          </div>
        </div>

        <!-- style="height: 580px;" -->
        <div #ordersList class="orders-list" fusePerfectScrollbar>
          <div
            *ngFor="let order of ordersOfTheDay"
            class="event-child-item"
            fxLayout="row"
            fxLayoutAlign="start center"
            matRipple
          >
            <mat-icon
              *ngIf="order.current_order_type === 'ORDER'"
              matTooltip="{{ 'GENERIC.ORDER' | translate }}"
              (click)="onNavigateToOrderSecondContext(order)"
              [matTooltipPosition]="'right'"
              [ngClass]="isSmallScreen ? 's-40' : 's-20'"
              class="icon-color-theme mr-8"
              >{{ getIcon("ORDER") }}</mat-icon
            >
            <mat-icon
              *ngIf="order.current_order_type === 'PROPOSAL'"
              matTooltip="{{ 'GENERIC.PROPOSAL' | translate }}"
              (click)="onNavigateToOrderSecondContext(order)"
              [matTooltipPosition]="'right'"
              [ngClass]="isSmallScreen ? 's-40' : 's-20'"
              class="icon-color-theme mr-8"
              >{{ getIcon("PROPOSAL") }}</mat-icon
            >
            <mat-icon
              *ngIf="order.current_order_type === 'INVOICE'"
              matTooltip="{{ 'GENERIC.INVOICE' | translate }}"
              (click)="onNavigateToOrderSecondContext(order)"
              [matTooltipPosition]="'right'"
              [ngClass]="isSmallScreen ? 's-40' : 's-20'"
              class="icon-color-theme mr-8"
              >{{ getIcon("INVOICE") }}</mat-icon
            >

            <mat-icon
              *ngIf="order.project_context"
              matTooltip="{{
                ('GENERIC.PROJECT' | translate) +
                  ' ' +
                  order.project_context.project_number
              }}"
              (click)="onNavigateToProjectContext(order)"
              [matTooltipPosition]="'right'"
              [ngClass]="isSmallScreen ? 's-40' : 's-20'"
              class="icon-color-theme mr-8"
            >
              {{ getIcon("PROJECT") }}
            </mat-icon>

            <div
              fxLayout="row"
              fxFlex="100"
              fxLayoutAlign="start center"
              class=""
              (click)="onNavigateToOrder(order)"
            >
              <div>{{ order.title }}</div>
            </div>
          </div>
          <div class="" style="height: 34px"></div>
        </div>
      </div>
    </div>
  </div>
</div>
