import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from "../../../../@fuse/services/translation-loader.service";
import { TranslateService } from "@ngx-translate/core";
import { locale as english } from "./i18n/en-CA";
import { locale as french } from "./i18n/fr-CA";

@Component({
  selector: "app-documentation",
  templateUrl: "./documentation.component.html",
  styleUrls: ["./documentation.component.scss"],
})
export class DocumentationComponent implements OnInit {
  constructor(private translationLoader: FuseTranslationLoaderService, public translate: TranslateService) {
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit(): void {}
}
