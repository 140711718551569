import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GT2FormPageAbstract } from '../../../../content/abstract/GT2FormPageAbstract';
import { RolesService } from '../../../../services/roles.service';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from '../../i18n/en-CA';
import { locale as french } from '../../i18n/fr-CA';
import { FuseWidgetModule } from '../../../../../../@fuse/components';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-event-stats',
  templateUrl: './event-stats.component.html',
  styleUrls: ['./event-stats.component.scss'],
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, TranslateModule, FuseWidgetModule],
  animations: fuseAnimations,
})
export class EventStatsComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  // event: any;
  @Input() event: any;
  @Output() eventChange = new EventEmitter<any>();
  @Output() editableChange = new EventEmitter<boolean>();
  @Input() editable: boolean = true;
  loading: boolean = false;
  editMode: boolean = false;
  declare dialogRef: any;
  @Output() modelUpdatedEvent = new EventEmitter<any>();

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public logger: NGXLogger,
    public location: Location,
    public rolesService: RolesService,
    public router: Router,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public loadData(): void {}

  public onToggleEdit(): void {
    this.editMode = !this.editMode;
    // //this.logger.info("EventStatsComponent.onEdit() -> this.editMode: " + this.editMode);
  }

  public onSaveData(): void {
    //this.logger.info("EventStatsComponent.onSaveData()");
  }

  public onManualSave(): void {
    //this.logger.info("EventStatsComponent.onManualSave()");
  }

  public onModelLoaded(): void {}

  public onDispatchModelUdpated(): void {
    //this.logger.info("EventStatsComponent.onDispatchModelUdpated()");
    this.modelUpdatedEvent.next(undefined);
  }
}
