<div class="">
  <div [formGroup]="form" class="">
    <div fxLayout="row" fxLayoutAlign="start start">
      <mat-form-field class="telephone form-input-container">
        <mat-label>{{ telephonePlaceHolder }}</mat-label>
        <input
          matInput
          placeholder="{{ telephonePlaceHolder }}"
          formControlName="telephoneCtrl"
          [(ngModel)]="groupModel.telephone"
          type="text"
        />
      </mat-form-field>

      <mat-form-field class="telephone-ext form-input-container">
        <mat-label>{{ telephoneExtPlaceHolder }}</mat-label>
        <input
          matInput
          placeholder="{{ telephoneExtPlaceHolder }}"
          formControlName="telephoneExtCtrl"
          [(ngModel)]="groupModel.telephoneExt"
          type="text"
        />
      </mat-form-field>
    </div>
  </div>
</div>
