import { HttpClient } from "@angular/common/http";
import { Gt2ApiService } from "./gt2-api.service";
import { ApiRoutes } from "./ApiRoutes";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class CreateEnterpriseBranchService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public createEnterprise(data: any): any {
    //this.logger.info("CreateEnterpriseBranchService.createEnterprise()");
    // return this.http.post<any>(this.api.createUrl(ApiRoutes.CREATE_CLIENT), data);
  }

  public createBranchForEnterprise(data: any, uuid: string): any {
    //this.logger.info("CreateEnterpriseBranchService.createBranchForEnterprise()");
    return this.http.post<any>(this.api.createUrl(ApiRoutes.CREATE_BRANCH_FOR_ENTERPRISE) + uuid, data);
  }
}
