import { ValidatorHelper } from '../../../../../validators/ValidatorHelper';
import { IconsService } from './../../../../../services/icons.service';
import { Settings } from './../../../../../settings/settings';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { NumericDirectiveModule } from '../../../../../modules/numeric.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-job-expenses-item',
  templateUrl: './job-expenses-item.component.html',
  styleUrls: ['./job-expenses-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    NumericDirectiveModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class JobExpensesItemComponent implements OnInit, AfterViewInit {
  @Input() expense: any;
  @Output() saveExpense: EventEmitter<any> = new EventEmitter();
  @Output() editExpense: EventEmitter<any> = new EventEmitter();
  @Output() deleteExpense: EventEmitter<any> = new EventEmitter();
  form: UntypedFormGroup | any;

  expenseCopy: any;
  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      amount: ['', [Validators.required, ValidatorHelper.isNumber0To1000000]],
      // date_from: ['', [ValidatorHelper.dateRangeDefault]],
      // date_to: ['', [ValidatorHelper.dateRangeDefault]],

      // job_count: ['', [Validators.required]],
      // last_name: ['', [Validators.minLength(Settings.inputMinChar), Validators.maxLength(Settings.inputMaxCharMid), Validators.required]],
    });
  }

  ngAfterViewInit(): void {
    this.expenseCopy = this.expense;
  }

  public getIcon(iconName: string): string {
    return IconsService.getIconStatic(iconName);
  }

  public onSaveExpense(): void {
    if (this.expense.uuid) {
      this.editExpense.emit(this.expenseCopy);
    } else {
      this.saveExpense.emit(this.expenseCopy);
    }
  }

  public onDeleteExpense(): void {
    this.deleteExpense.emit(this.expenseCopy);
  }
}
