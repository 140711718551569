import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class NumberingService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getNumberings() {
    // this.logger.info("NumberingService.getNumberings()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.NUMBERING));
  }

  public saveNumbering(numbering: any, UUID: string) {
    // this.logger.info("NumberingService.getNumberings() -> UUID: " + UUID);
    // this.logger.info("NumberingService.getNumberings() -> numbering: " + JSON.stringify(numbering));
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.NUMBERING) + "/" + UUID, numbering);
  }
}
