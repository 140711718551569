import { RolesService } from '../../services/roles.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { DataSource } from '@angular/cdk/collections';
import { IDynamicTableItemAction } from '../dynamic-table/dynamic-table.component';
import { of, Observable } from 'rxjs';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { NGXLogger } from 'ngx-logger';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';

@Component({
  selector: 'app-collection-table',
  templateUrl: './collection-table.component.html',
  styleUrls: ['./collection-table.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatSortModule,
    MatInputModule,
    MatTableModule,
    Gt2PrimengModule,
    CommonModule,
    CdkTableModule,
  ],
  animations: fuseAnimations,
})
export class CollectionTableComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() collectionData!: any[];
  @Input() displayedColumns!: string[];
  @Input() dynamicTableItemAction!: IDynamicTableItemAction;
  @Input() dynamicTableItemActionEnd!: IDynamicTableItemAction;
  dataSource?: CollectionTableDataSource | null;

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private logger: NGXLogger,
    public rolesService: RolesService,
  ) {
    super();
  }

  ngOnInit() {
    this.dataSource = new CollectionTableDataSource(this.collectionData);
    //this.logger.info(">>> collectionData: " + JSON.stringify(this.collectionData));
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public reload(data: any): void {
    //this.logger.info("CollectionTableComponent.reload() !!!!!!!!! ");
    this.collectionData = data;
    this.dataSource = new CollectionTableDataSource(this.collectionData);
    this.changeDetectorRefs.detectChanges();
  }

  public onSelect(selected: any) {
    //this.logger.info("CollectionTableComponent.onSelect() -> " + JSON.stringify(selected));
    this.dynamicTableItemAction?.onItemClick(selected);
  }

  public onActionEndClick(selected: any) {
    //this.logger.info("CollectionTableComponent.onActionEndClick() -> " + JSON.stringify(selected));
    this.dynamicTableItemActionEnd?.onItemClick(selected);
  }
}

export class CollectionTableDataSource extends DataSource<any> {
  constructor(private data: any[]) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return of(this.data);
  }

  disconnect() {}
}
