import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  ChangelogsService,
  ChangelogModel,
} from '../../../api/changelogs.service';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { EditorModule } from 'primeng/editor';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
@Component({
  selector: 'app-changelogs-dialog',
  templateUrl: './changelogs-dialog.component.html',
  styleUrls: ['./changelogs-dialog.component.scss'],
  standalone: true,
  imports: [
    EditorModule,
    TranslateModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
})
export class ChangelogsDialogComponent
  extends GT2PageAbstract
  implements OnInit
{
  changelog: ChangelogModel;
  log!: string;
  constructor(
    public override dialogRef: MatDialogRef<ChangelogsDialogComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public changelogsService: ChangelogsService,
    public dialog: MatDialog,
  ) {
    super();

    this.translationLoader.loadTranslations(english, french);

    this.changelog = {
      log: null,
      event_uuid: null,
      order_uuid: null,
    };
    //
    //this.logger.info("ChangelogsDialogComponent=> data:  " + JSON.stringify(data));
    if (data && data.changelog && data.changelog.log) {
      this.changelog.log = data.changelog.log;
      this.log = data.changelog.log;
    }
    if (data && data.event_uuid) {
      this.changelog.event_uuid = data.event_uuid;
    }
    if (data && data.order_uuid) {
      this.changelog.order_uuid = data.order_uuid;
    }
  }

  ngOnInit() {
    //this.logger.info("changelog model " + JSON.stringify(this.changelog));
  }

  public OnSaveChangeLogs(): void {
    //this.logger.info("ChangelogsDialogComponent.OnSaveChangeLogs");
    if (this.log && this.log != '') {
      this.changelog.log = this.log;
      this.createChangeLog(this.changelog);
    }
  }

  public createChangeLog(changelog: ChangelogModel): void {
    this.changelogsService
      .createChangelog(changelog)
      .pipe(debounceTime(2000), distinctUntilChanged())
      .subscribe(
        (response: any) => {
          //this.logger.info("createChangeLog=>response : " + JSON.stringify(response));
          this.dialogRef.close(response);
        },
        (error: any) => {
          this.logger.error(
            'ChangelogsDialogComponent.createChangeLog() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
        },
      );
  }
}
