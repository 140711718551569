import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Gt2ApiService } from '../../../../api/gt2-api.service';
import { ProductsService } from '../../../../api/products.service';
import { UpsaleService } from '../../../../api/upsale.service';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import { RolesService } from '../../../../services/roles.service';
import { NGXLogger } from 'ngx-logger';
import { CreateOptionsGroupComponent } from '../create-options-group/create-options-group.component';
import { GenericAlertComponent } from '../../../../components/generic-alert/generic-alert.component';
import { IconsService } from '../../../../services/icons.service';
import { GT2ConstantsEnum } from '../../../../const/GT2ConstantsEnum';
import { CreateUpsaleMaterialsComponent } from '../create-upsale-materials/create-upsale-materials.component';
import { CreateUpsaleOptionsComponent } from '../create-upsale-options/create-upsale-options.component';
import { EditQuestionComponent } from '../edit-question/edit-question.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-upsale-question-item',
  templateUrl: './upsale-question-item.component.html',
  styleUrls: ['./upsale-question-item.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    CommonModule,
    FormsModule,
    NgxPermissionsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FlexLayoutModule,
    MatFormFieldModule,
    TranslateModule,
  ],
})
export class UpsaleQuestionItemComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit
{
  @Input() question: any = null;
  @Input() panelOpenState: boolean = false;
  @Output() stateChange: EventEmitter<QuestionState> = new EventEmitter();
  @Output() onLoadQuestion: EventEmitter<any> = new EventEmitter();
  //
  confirmDialogRef: any = null;
  isLoading: boolean = false;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private productsService: ProductsService,
    private api: Gt2ApiService,
    public router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private logger: NGXLogger,
    public upsaleService: UpsaleService,
    public iconService: IconsService,
    public rolesService: RolesService,
  ) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onStateChange(value: boolean): void {
    this.panelOpenState = value;
    this.stateChange.emit({
      state: value,
      uuid: this.question.uuid,
    });
  }

  public onCreateOptionsGroup(question: any): void {
    //this.logger.info("ProductUpsaleComponent.onCreateOptionsGroup():: Question =>" + JSON.stringify(question));
    const dialogRef = this.dialog.open(CreateOptionsGroupComponent, {
      width: '440px',
      minWidth: 350,
      maxHeight: '96vh',
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: { question: question },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //
        // this.loadQuestions();
        this.onLoadQuestion.emit();
        this.dialogRef.close(true);
      }
      this.dialogRef = null;
    });
  }

  public onDeleteQuestion(uuid: string): void {
    ////this.logger.info("onDeleteQuestion");
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.doDeleteQuestion(uuid);
      }
    });
  }

  public onRemoveOptionsGroup(uuid: string): void {
    this.isLoading = true;
    this.upsaleService.deleteGroupOptions(uuid).subscribe(
      (response: any) => {
        //this.logger.info("onRemoveOptionsGroup -> SUCCESS>>: " + JSON.stringify(response));
        this.isLoading = false;
        // this.loadQuestions();
        this.onLoadQuestion.emit();
      },
      (error: any) => {
        this.logger.error(
          'onRemoveOptionsGroup -> ERROR: ' + JSON.stringify(error),
        );
        this.isLoading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public doDeleteQuestion(uuid: string): void {
    this.upsaleService.deleteQuestion(uuid).subscribe(
      (response: any) => {
        //this.logger.info("onDeleteQuestion -> SUCCESS>>: " + JSON.stringify(response));
        this.isLoading = false;
        // this.loadQuestions();
        this.onLoadQuestion.emit();
      },
      (error: any) => {
        this.logger.error(
          'onDeleteQuestion -> ERROR: ' + JSON.stringify(error),
        );
        this.isLoading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public onEditQuestion(): void {
    //
    var dialogRef = this.dialog.open(EditQuestionComponent, {
      width: '440px',
      minWidth: 350,
      maxHeight: '96vh',
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        question: this.question,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //
        dialogRef.close(true);
        //this.loadQuestions();
        this.onLoadQuestion.emit();
      }
    });
  }

  public onEditOptionsGroup(group: any, uuid: string): void {
    // //this.logger.info("onEditOptionsGroup");
    if (group.type == GT2ConstantsEnum.GROUP_PRODUCTS) {
      var dialogRef = this.dialog.open(CreateUpsaleOptionsComponent, {
        width: '94%',
        minWidth: 350,
        disableClose: false,
        panelClass: 'custom-dialog-container',
        data: {
          question: this.question,
          questionUUID: uuid,
          group: group,
          //    questionName: this.question.name,
          type: GT2ConstantsEnum.GROUP_PRODUCTS,
          mode: 'EDIT',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          //
          dialogRef.close(true);
          //    this.loadQuestions();
          this.onLoadQuestion.emit();
        }
      });
    } else {
      this.onEditOptionsMaterialsGroup(group, uuid);
    }
  }

  public onEditOptionsMaterialsGroup(group: any, uuid: string): void {
    // //this.logger.info("onEditOptionsGroup");
    var dialogRef = this.dialog.open(CreateUpsaleMaterialsComponent, {
      width: '94%',
      minWidth: 350,
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: {
        questionUUID: uuid,
        group: group,
        type: GT2ConstantsEnum.GROUP_MATERIALS,
        mode: 'EDIT',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //
        dialogRef.close(true);
        this.onLoadQuestion.emit();
        // this.loadQuestions();
      }
    });
  }
}

export interface QuestionState {
  state: boolean;
  uuid: string;
}
