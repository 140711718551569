export const locale = {
  lang: 'en-CA',
  data: {
    ROOMS: {
      ROOMS: 'Rooms',
      ROOM: 'Room',
      ROOM_NAME: 'Name / room #',
      DELETE_ROOM: 'Delete room',
      ADD_ROOM: 'Add new room',
      ATTACH_ROOM: 'Attach a room',
      DETACH_ROOM: 'Detach room',
      NO_ROOM_TO_ATTACH: 'No room to attach',
      DELETE_CONFIRM: 'Are you sure you want to delete this room?',
      DELETE_SUCCESS: 'Room deleted successfully',
      ROYALTY: 'Royalty',
      ROYALTY_PERCENT: 'Royalty percent',
      ROYALTY_FOOD_BEVERAGE: 'Royalty on food & beverage',
      ROYALTY_FOOD: 'Royalty on food',
      ROYALTY_BEVERAGE: 'Royalty on beverage',
      ROYALTY_MATERIAL: 'Royalty on equipment',
      ROYALTY_SERVICE: 'Royalty on services',
      MAX_PLACE: 'Maximum places',
      NO_ROOMS: 'There are no rooms assigned right now.',
      NO_ROOMS_HR:
        'There are no rooms assigned right now. Click the green + button in edit mode to add one or more.',
      SUB_ROOMS: 'Sub-rooms',
      NAME: 'Name',
    },
  },
};
