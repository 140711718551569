import { AuthenticationService } from './../api/auth-service.service';
import { FileSaverService } from 'ngx-filesaver';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ApiRoutes } from '../api/ApiRoutes';
import { tap } from 'rxjs/operators';
import { Gt2ApiService } from '../api/gt2-api.service';

@Injectable()
export class ReportsService {
  private httpClientBypass: HttpClient;

  loadingPrint: boolean = false;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
    private _FileSaverService: FileSaverService,
    private auth: AuthenticationService,
    private handler: HttpBackend
  ) {
    this.httpClientBypass = new HttpClient(handler);
  }

  public prepareReports(vo: any, urlUUID: string | null = null) {
    let url: string = this.api.createUrl(ApiRoutes.REPORTS_2);
    return this.http.post<any>(url, vo);
  }

  public async printPDFReports(vo: any) {
    const fileURL: string = this.api.createUrl(ApiRoutes.REPORTS_2);
    //this.logger.info("ReportsService.printPDFReports() -> *fileURL: " + fileURL);

    return new Promise((resolve, reject) => {
      this.http
        .post(fileURL, vo, {
          observe: 'response',
          responseType: 'blob',
        })
        .subscribe(
          (res: any) => {
            // this.logger.info(
            //     "FILE Header response filename: " +
            //         res.headers.get("filename")
            // );
            // this._FileSaverService.save(
            //     res.body,
            //     res.headers.get("filename")
            // );
            const file = new Blob([res.body], {
              type: 'application/pdf',
            });
            const fileURL = URL.createObjectURL(file);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = fileURL; // secureURL;
            document.body.appendChild(iframe);
            iframe.contentWindow?.print();
            resolve(res);
          },
          async (error) => {
            this.logger.error(
              'ReportsService.printPDFReports()) -> ERROR: ' +
                JSON.stringify(error)
            );

            try {
              const decodedError = JSON.parse(await error.error.text());
              error.error = decodedError;
            } catch (Error: any) {
              this.logger.error(
                'ReportsService.printPDFReports()) -> try/catch ERROR: ' +
                  JSON.stringify(Error.message)
              );
            }

            // this.logger.info(
            //     "ReportsService.processDownloadFile() -> ERROR test: " +
            //         JSON.stringify(decodedError)
            // );

            reject(error);
          }
        );
    });
  }

  public printReports(vo: any): any {
    //this.logger.info("ReportsService.printReports");
    const url: string = this.api.createUrl(ApiRoutes.REPORTS_2);
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(url, vo)
        .pipe(tap(() => (this.loadingPrint = false)))
        .subscribe(
          (response) => {
            //this.logger.info("ReportsService.printReports() -> response: " + response.result.raw_html);
            const file = new Blob([response.result.raw_html], {
              type: 'text/html',
            });
            const fileURL = URL.createObjectURL(file);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = fileURL; // secureURL;
            document.body.appendChild(iframe);
            iframe.contentWindow?.print();
            resolve(response);
          },
          async (error) => {
            this.loadingPrint = false;
            this.logger.error(
              'PrintService.printReports() ... -> ERROR: ' +
                JSON.stringify(error)
            );
            reject(error);
          }
        );
    });
  }

  public convertHTMLToPDF(rawHTML: string) {
    //this.logger.info("ReportsService.convertHTMLToPDF()");
    const url: string = 'https://v2.api2pdf.com/chrome/pdf/html';
    const apiKey: string = '77094388-05e0-471f-b5e1-cb59c81c4508 ';
    const data: any = {
      html: rawHTML,
      // inline: true,
      options: {
        landscape: true,
      },
    };

    // req.headers.set("Authorization", "Bearer " + idToken);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: apiKey,
      }),
    };

    return this.httpClientBypass.post<any>(url, data, httpOptions);
  }

  public processPrintPDF(vo: any): any {
    const url: string = this.api.createUrl(ApiRoutes.REPORTS_2);
    //this.logger.info("ReportsService.processPrintPDF() -> url: " + url);
    return new Promise((resolve, reject) => {
      this.http
        .post<ArrayBuffer>(url, vo, {
          responseType: 'arraybuffer' as 'json',
        })
        .subscribe(
          (response) => {
            const file = new Blob([response], {
              type: 'application/pdf',
            });
            const fileURL = URL.createObjectURL(file);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = fileURL; // secureURL;
            document.body.appendChild(iframe);
            iframe.contentWindow?.print();
            resolve(response);
          },
          async (error) => {
            this.loadingPrint = false;
            this.logger.error(
              'ReportsService.processPrintPDF() -> ERROR: ' +
                JSON.stringify(error)
            );

            try {
              const decodedError = JSON.parse(await error.error.text());
              error.error = decodedError;
            } catch (Error: any) {
              this.logger.error(
                'ReportsService.printPDFReports()) -> try/catch ERROR: ' +
                  JSON.stringify(Error.message)
              );
            }

            reject(error);
          }
        );
    });
  }

  public async processDownloadFile(vo: any) {
    const fileURL: string = this.api.createUrl(ApiRoutes.REPORTS_2);
    //this.logger.info("ReportsService.processDownloadFile() -> *fileURL: " + fileURL);

    return new Promise((resolve, reject) => {
      this.http
        .post(fileURL, vo, {
          observe: 'response',
          responseType: 'blob',
        })
        .subscribe(
          (res: any) => {
            // this.logger.info(
            //     "FILE Header response filename: " +
            //         res.headers.get("filename")
            // );
            if (vo.job_queue === null) {
              this._FileSaverService.save(
                res.body,
                res.headers.get('filename')
              );
            }

            resolve(res);
          },
          async (error) => {
            this.logger.error(
              'ReportsService.processDownloadFile()) -> ERROR: ' +
                JSON.stringify(error)
            );

            try {
              const decodedError = JSON.parse(await error.error.text());
              error.error = decodedError;
            } catch (Error: any) {
              this.logger.error(
                'ReportsService.processDownloadFile()) -> try/catch ERROR: ' +
                  JSON.stringify(Error.message)
              );
            }
            reject(error);
          }
        );
    });
  }

  public async processEmailAttachment(vo: any) {
    const fileURL: string = this.api.createUrl(ApiRoutes.REPORTS_2);
    //this.logger.info("ReportsService.processEmailAttachment() -> *fileURL: " + fileURL);

    return new Promise((resolve, reject) => {
      this.http.post(fileURL, vo).subscribe(
        (res) => {
          // this.logger.info(
          //     "FILE Header response filename: " +
          //         res.headers.get("filename")
          // );
          // this._FileSaverService.save(
          //     res.body,
          //     res.headers.get("filename")
          // );
          resolve(res);
        },
        async (error) => {
          this.logger.error(
            'ReportsService.processEmailAttachment()) -> ERROR: ' +
              JSON.stringify(error)
          );

          try {
            const decodedError = JSON.parse(await error.error.text());
            error.error = decodedError;
          } catch (Error: any) {
            this.logger.error(
              'ReportsService.processEmailAttachment()) -> try/catch ERROR: ' +
                JSON.stringify(Error.message)
            );
          }
          reject(error);
        }
      );
    });
  }

  public preparePreviewReports(vo: any): any {
    const fileURL: string = this.api.createUrl(ApiRoutes.REPORTS_2);
    //this.logger.info("ReportsService.preparePreviewReports() -> *fileURL: " + fileURL);

    return new Promise((resolve, reject) => {
      this.http
        .post(fileURL, vo, {
          observe: 'response',
          responseType: 'text',
        })
        .subscribe(
          (res) => {
            // this.logger.info("FILE response body: " + res.body);
            resolve(res.body);
          },
          async (error) => {
            this.logger.error(
              'ReportsService.preparePreviewReports()() -> ERROR: ' +
                JSON.stringify(error)
            );

            const decodedError = JSON.parse(await error.error);
            // this.logger.info(
            //     "ReportsService.preparePreviewReports() -> ERROR test: " +
            //         JSON.stringify(decodedError)
            // );
            error.error = decodedError;
            reject(error);
          }
        );
    });
  }
}
