import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-stripe-settings',
  templateUrl: './stripe-settings.component.html',
  styleUrls: ['./stripe-settings.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class StripeSettingsComponent implements OnInit {
  @Input() shop: any;
  @Input() editMode: boolean = false;
  @Input() stripeModel: any; // StripeModel;
  @Input() formStripe!: UntypedFormGroup;
  @Output() addStripeProfile: EventEmitter<any> = new EventEmitter();
  @Output() removeStripeProfile: EventEmitter<any> = new EventEmitter();
  @Output() shopChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public onAddStripeProfile(): void {
    this.addStripeProfile.emit();
  }

  public onRemoveStripeProfile(item: any, i: any): void {
    this.removeStripeProfile.emit({ item: item, position: i });
  }

  public onSaveStripeInfo(): void {
    this.shopChange.emit();
  }
}
