import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FlowService } from '../../../api/flow.service';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Editor } from 'primeng/editor';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DropdownModule } from 'primeng/dropdown';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-create-flow',
  templateUrl: './create-flow.component.html',
  styleUrls: ['./create-flow.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    DropdownModule,
    MatDialogModule,
    FormsModule,
    FlexLayoutModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    NgxMaterialTimepickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
})
export class CreateFlowComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  static CREATE: string = 'CREATE';
  static EDIT: string = 'EDIT';

  //
  mode: string = CreateFlowComponent.CREATE;
  form: UntypedFormGroup;
  flow: any;
  eventUUID!: string;
  loading: boolean = false;
  autoRedirect: boolean = false;
  noteInvalid: boolean = false;
  noteEditorSubscription?: Subscription;
  noteEditordebounceTimeValue: number = 1500;
  @ViewChild('noteEditor') noteEditor?: Editor;
  flows?: any[];
  selectedFlow: any;
  flowToUpdate: any;

  constructor(
    public override dialogRef: MatDialogRef<CreateFlowComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private flowService: FlowService,
    //private atp: any,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.adapter.setLocale(this.translate.currentLang);

    this.form = this.formBuilder.group({
      eventDate: ['', [ValidatorHelper.dateRangeDefault]],
      // eventDateTime: ['', [Validators.required]],
      selectedFlow: ['', [Validators.required]],
    });

    // //this.logger.info("CreateFlowComponent.constructor() -> data: " + JSON.stringify(data));
    this.flow = {
      date: null,
      selectedTime: '12:00',
      note: '',
      for_client_only: true,
    };

    if (data && data.mode !== null) {
      this.mode = data.mode;
    }

    if (data && data.eventUUID !== null) {
      this.eventUUID = data.eventUUID;
    }

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }

    if (data && data.date !== null) {
      this.flow.date = data.date;
    }

    if (data && data.flowToUpdate) {
      this.flowToUpdate = data.flowToUpdate;
      //this.logger.info("CreateFlowComponent.constructor() -> flowToUpdate: " + JSON.stringify(data.flowToUpdate));
      this.flow.note = this.flowToUpdate.note;
      this.flow.for_client_only = this.flowToUpdate.for_client_only;
      this.flow.date = this.flowToUpdate.flow_date;
      this.flow.selectedTime = this.flowToUpdate.flow_time;
      this.selectedFlow = this.flowToUpdate.name;
    }

    this.flowService.getFlowServiceList().subscribe(
      (response) => {
        // //this.logger.info("CreateFlowComponent.getFlowServiceList() -> SUCCESS: " + JSON.stringify(response));
        this.flows = response.data.map((item: any) => {
          return { label: item.name, value: item };
        });
        // //this.logger.info("CreateFlowComponent.getFlowServiceList() -> this.flows: " + JSON.stringify(response));
      },
      (error) => {
        this.logger.error(
          'CreateFlowComponent.onCreateProposal() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.noteEditorSubscription = this.noteEditor?.onTextChange
      .pipe(debounceTime(this.noteEditordebounceTimeValue))
      .pipe(distinctUntilChanged())
      .subscribe((data) => {
        if (data.htmlValue) {
          this.noteInvalid = data.htmlValue.length > Settings.editorMaxCharLong;
          //this.logger.info("CreateFlowComponent.noteEditor.onTextChange() -> this.noteInvalid: " + this.noteInvalid);
          if (this.noteInvalid) {
            return;
          }
        }
      });
  }

  ngOnDestroy() {}

  public openTimePicker(): void {
    // const amazingTimePicker = this.atp.open({
    //   time: this.flow.selectedTime,
    // });
    // amazingTimePicker.afterClose().subscribe((time: any) => {
    //   this.flow.selectedTime = time;
    // });
  }

  public onCreateFlow() {
    this.loading = true;
    //this.logger.info("CreateFlowComponent.onCreateFlow() -> flow: " + JSON.stringify(this.flow));

    if (this.mode === CreateFlowComponent.CREATE) {
      this.doCreateFlow();
    } else if (this.mode === CreateFlowComponent.EDIT) {
      this.updateFlow();
    }
  }

  public doCreateFlow() {
    //this.logger.info("CreateFlowComponent.doCreateFlow() -> selectedFlow: " + JSON.stringify(this.selectedFlow));
    //this.logger.info("CreateFlowComponent.doCreateFlow() -> selectedFlow instanceof object: " + (this.selectedFlow instanceof Object));
    //this.logger.info("CreateFlowComponent.doCreateFlow() -> selectedFlow typeof string: " + (typeof this.selectedFlow === "string"));
    const newFlow: any = {
      flow_date: this.flow.date,
      flow_time: this.flow.selectedTime,
      note: this.flow.note,
      for_client_only: this.flow.for_client_only,
      custom_flow: null,
      selected_flow_uuid: null,
    };

    if (typeof this.selectedFlow === 'string') {
      newFlow.custom_flow = this.selectedFlow;
    } else if (this.selectedFlow instanceof Object) {
      newFlow.selected_flow_uuid = this.selectedFlow.uuid;
    }

    //this.logger.info("CreateFlowComponent.doCreateFlow() -> ***** newFlow: " + JSON.stringify(newFlow));
    this.flowService.createFlow(newFlow, this.eventUUID).subscribe(
      (response) => {
        // //this.logger.info("CreateFlowComponent.onCreateFlow() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        this.dialogRef.close(response);
      },
      (error) => {
        this.logger.error(
          'CreateFlowComponent.onCreateFlow() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
      },
    );
  }

  public updateFlow() {
    const flowCopy = Object.assign({}, this.flowToUpdate);

    if (typeof this.selectedFlow === 'string') {
      flowCopy.custom_flow = this.selectedFlow;
    } else if (this.selectedFlow instanceof Object) {
      flowCopy.selected_flow_uuid = this.selectedFlow.uuid;
    }

    flowCopy.flow_date = this.flow.date;
    flowCopy.flow_time = this.flow.selectedTime;
    flowCopy.note = this.flow.note;
    flowCopy.for_client_only = this.flow.for_client_only;

    // //this.logger.info("CreateFlowComponent.updateFlow() -> ***** flowToUpdate: " + JSON.stringify(flowCopy));

    this.flowService.updateFlow(flowCopy, this.eventUUID).subscribe(
      (response) => {
        // //this.logger.info("CreateFlowComponent.onCreateFlow() -> SUCCESS: " + JSON.stringify(response));
        this.loading = false;
        this.dialogRef.close(response);
      },
      (error) => {
        this.logger.error(
          'CreateFlowComponent.onCreateFlow() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
      },
    );
  }
}
