import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { IGetDataService } from '../components/gt2-select/gt2-select.component';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable()
export class DeliveryRequestTemplateService implements IGetDataService {
  deliveryRequestTemplatesObs?: any;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getDeliveryRequestTemplates(): any {
    // this.logger.info("DeliveryRequestTemplateService.getLedgersAccount()");
    if (!this.deliveryRequestTemplatesObs) {
      this.deliveryRequestTemplatesObs = this.http
        .get<any>(this.api.createUrl(ApiRoutes.DELIVERY_REQUEST_TYPE_TEMPLATE))
        .pipe(shareReplay(1))
        .pipe(tap((data) => this.resetObservable()));
    }

    return this.deliveryRequestTemplatesObs;
  }

  public getData(): Observable<any> {
    //this.logger.info("DeliveryRequestTemplateService.getData()");
    return this.getDeliveryRequestTemplateList();
  }

  public getDeliveryRequestTemplateList(): any {
    //this.logger.info("DeliveryRequestTemplateService.getDeliveryRequestTemplateList() -> url: " + ApiRoutes.DELIVERY_REQUEST_TYPE_TEMPLATE_LIST);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.DELIVERY_REQUEST_TYPE_TEMPLATE_LIST)
    );
  }

  private resetObservable(): void {
    this.deliveryRequestTemplatesObs = null;
  }

  public createDeliveryRequestTemplate(delivery: any): any {
    // this.logger.info("DeliveryRequestTemplateService.createDeliveryRequest() -> " + JSON.stringify(delivery));
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.DELIVERY_REQUEST_TYPE_TEMPLATE),
      delivery
    );
  }

  public updateDeliveryRequestTemplate(delivery: any): any {
    //this.logger.info("DeliveryRequestTemplateService.updateDeliveryRequestTemplate() -> " + JSON.stringify(delivery));
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.DELIVERY_REQUEST_TYPE_TEMPLATE) +
        '/' +
        delivery.uuid,
      delivery
    );
  }

  // public deleteDeliveryRequestTemplate(delivery: any, eventUUID: string)
  // {
  //     this.logger.info("DeliveryRequestTemplateService.deleteDeliveryRequestTemplate() -> " + JSON.stringify(delivery));
  //     this.logger.info("DeliveryRequestTemplateService.deleteDeliveryRequestTemplate() -> eventUUID: " + eventUUID);
  //     return this.http.delete<any>(this.api.createUrl(ApiRoutes.EVENT) + "delivery-requests/" + eventUUID + "/" + delivery.uuid);
  // }
}
