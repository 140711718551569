<div
  id="products"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
  style="overflow-x: scroll"
>
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center"
      fusePerfectScrollbar
      style="min-width: 1023px"
    >
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h1 mt-8">{{ "PRODUCTS.PRODUCTS" | translate }}</div>
      </div>

      <div class="" fxLayout="row" fxLayoutAlign="end start" fxFlex="100">
        <button
          *ngIf="fileService.printEnabled()"
          mat-button
          matTooltip="{{ 'GENERIC.PRINT' | translate }}"
          (click)="onPrint()"
          class="mat-icon-button"
        >
          <mat-icon class="s-30 mat-icon-button">print</mat-icon>
        </button>
        <button
          *ngIf="fileService.pdfEnabled()"
          mat-button
          matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
          (click)="onDownloadPDF()"
          class="mat-icon-button"
        >
          <mat-icon class="s-30 mat-icon-button">picture_as_pdf</mat-icon>
        </button>
        <mat-spinner
          *ngIf="print.loadingPrint"
          [diameter]="20"
          class="mt-12"
        ></mat-spinner>
        <button
          *ngIf="fileService.excelEnabled()"
          mat-button
          matTooltip="{{ 'GENERIC.DOWNLOAD_XLS' | translate }}"
          (click)="onDownloadXLS()"
          class="mat-icon-button"
        >
          <mat-icon
            fontSet="fa"
            fontIcon="fa-table"
            class="fa-2x mat-icon-button"
          ></mat-icon>
        </button>

        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="s-30 mat-icon-button">{{
            getIcon("NEW_TAB")
          }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <div
    #mainContainer
    class="center p-24 pr-56"
    fusePerfectScrollbar
    style="min-width: 1023px"
  >
    <div fxLayout="column" style="padding-bottom: 46px">
      <!--Data table-->
      <div class="mat-white-bg mat-elevation-z2">
        <div class="px-8 pt-8" fxLayout="row" fxLayoutAlign="end center">
          <!-- SEARCH -->
          <div
            class=""
            fxFlex="1 0 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <label for="search" class="mr-8">
              <mat-icon class="">search</mat-icon>
            </label>
            <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="">
              <mat-label>{{
                "GENERIC.FILTERING_PLACEHOLDER" | translate | titlecase
              }}</mat-label>
              <input id="search" [(ngModel)]="filterValue" matInput #filter />
              <button
                mat-button
                *ngIf="filterValue"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearSearch()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <!-- / SEARCH -->

          <div class="mat-body-2 p-8 ml-8">
            {{ resultsLength }}
            {{ "PRODUCTS.PRODUCTS" | translate }}
          </div>
        </div>
        <div class="border-bottom">
          <div
            *ngIf="!isLoadingResults && resultsLength === 0"
            fxLayout="row"
            fxLayoutAlign="center start"
            class="pb-8 mat-body-2"
          >
            {{ "GENERIC.NO_RESULTS" | translate }}
          </div>

          <div *ngIf="isLoadingResults" class="">
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>

        <div>
          <div class="border-bottom">
            <mat-paginator
              #paginator
              [length]="resultsLength"
              (page)="datatableHelperService.onPaginator($event, 'products')"
              showFirstLastButtons="true"
              [pageIndex]="0"
              [pageSize]="'products' | tableSizePipe: 'large'"
              [pageSizeOptions]="datatableHelperService.pageSizeOptions"
            >
            </mat-paginator>
          </div>

          <mat-table
            #table
            class=""
            matSort
            matSortStart="desc"
            *ngIf="dataSource && dataSource.data"
            [@animateStagger]="{ value: '50' }"
            [dataSource]="dataSource"
          >
            <!-- Name Column -->
            <ng-container cdkColumnDef="name_internal">
              <mat-header-cell fxFlex="45" *cdkHeaderCellDef mat-sort-header
                >{{ "GENERIC.INTERNAL_NAME" | translate }}
              </mat-header-cell>

              <mat-cell
                class="no-text-cursor"
                fxFlex="45"
                *cdkCellDef="let product"
              >
                <p
                  class="text-truncate font-weight-500"
                  matTooltip="{{ product.name }}"
                  [matTooltipPosition]="'right'"
                >
                  {{ product.name_internal }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- product # Column -->
            <ng-container cdkColumnDef="product_number">
              <mat-header-cell fxFlex="15" *cdkHeaderCellDef mat-sort-header
                >{{ "PRODUCTS.PRODUCT_NUMBER" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="15"
                *cdkCellDef="let product"
              >
                <p class="text-truncate">
                  {{ product.product_number }}
                </p>

                <!--<p ngxClipboard [cbContent]="employee.employee_number"-->
                <!--matTooltip="{{ 'GENERIC.CLICK_TO_COPY' | translate }}"-->
                <!--class="position text-truncate">-->
                <!--{{employee.employee_number}}-->
                <!--</p>-->
              </mat-cell>
            </ng-container>

            <!-- price Column -->
            <ng-container cdkColumnDef="price">
              <mat-header-cell fxFlex="10" *cdkHeaderCellDef mat-sort-header
                >{{ "PRODUCTS.PRICE" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="10"
                *cdkCellDef="let product"
              >
                <p class="text-truncate">
                  {{ product.price_label }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- confirmed Column -->
            <ng-container cdkColumnDef="confirmed">
              <mat-header-cell fxFlex="10" *cdkHeaderCellDef mat-sort-header
                >{{ "PRODUCTS.CONFIRMED" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="10"
                *cdkCellDef="let product"
              >
                <p *ngIf="product.confirmed" class="email text-truncate">
                  {{ "GENERIC.YES" | translate }}
                </p>
                <p *ngIf="!product.confirmed" class="email text-truncate">
                  {{ "GENERIC.NO" | translate }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- is combo Column -->
            <ng-container cdkColumnDef="is_combo">
              <mat-header-cell fxFlex="10" *cdkHeaderCellDef mat-sort-header
                >{{ "PRODUCTS.COMBO" | translate }}
              </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="10"
                *cdkCellDef="let product"
              >
                <p *ngIf="product.is_combo" class="email text-truncate">
                  {{ "GENERIC.YES" | translate }}
                </p>
                <p *ngIf="!product.is_combo" class="email text-truncate">
                  {{ "GENERIC.NO" | translate }}
                </p>
              </mat-cell>
            </ng-container>

            <!-- is open in new tab -->
            <ng-container cdkColumnDef="newTab">
              <mat-header-cell fxFlex="10" *cdkHeaderCellDef> </mat-header-cell>
              <mat-cell
                class="no-text-cursor"
                fxFlex="10"
                *cdkCellDef="let product"
              >
                <button
                  mat-button
                  matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                  (click)="
                    $event.stopPropagation(); onOpenProductInNewTab(product)
                  "
                  class="mat-icon-button"
                >
                  <mat-icon class="icon-mat-dark">{{
                    getIcon("NEW_TAB")
                  }}</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *cdkHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row
              class="mat-row-color"
              *cdkRowDef="let product; columns: displayedColumns"
              matRipple
              [routerLink]="
                '/' +
                appRoutesService.products +
                '/' +
                product.slug +
                '/' +
                product.uuid
              "
            >
            </mat-row>
          </mat-table>

          <mat-paginator
            #paginatorBottom
            [length]="paginator.length"
            (page)="syncPrimaryPaginator($event)"
            showFirstLastButtons="true"
            [pageIndex]="paginator.pageIndex"
            [pageSize]="paginator.pageSize"
            [pageSizeOptions]="paginator.pageSizeOptions"
          >
          </mat-paginator>
          <div
            *ngIf="isLoadingResults && !isFirstLoad"
            fxLayout="row"
            fxLayoutAlign="center start"
            class=""
          >
            <mat-progress-bar
              mode="query"
              color="warn"
              style="height: 3px"
            ></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
  <div class="fixed-button">
    <button
      mat-fab
      class="gt2-create-button"
      id="add-product-button"
      (click)="onCreateNewProduct($event)"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
    >
      <mat-icon class="icon-mat-black">add</mat-icon>
    </button>
  </div>
</div>
