<div
  id="faq"
  class="page-layout simple fullwidth">
  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent p-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center">
      <div
        fxLayout="column"
        fxLayoutAlign="center start">
        <!--<div class="black-fg" fxLayout="row" fxLayoutAlign="start center">-->
        <!--<mat-icon class="secondary-text s-16">home</mat-icon>-->
        <!--<mat-icon class="secondary-text s-16">chevron_right</mat-icon>-->
        <!--<span class="secondary-text">{{ 'GENERIC.SUPPORT' | translate }}</span>-->
        <!--</div>-->
        <div class="h1">{{ "FAQ.FAQ" | translate }}</div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content px-16 py-24 px-sm-24 py-sm-32">
    <mat-accordion class="faqs-accordion">
      <mat-expansion-panel
        *ngFor="let faq of faqsFiltered; let i = index"
        [expanded]="step === i"
        (opened)="setStep(i)">
        <mat-expansion-panel-header>
          <mat-panel-title
            flex
            fxLayout="row"
            fxLayoutAlign="start center">
            <mat-icon class="mr-8">help_outline</mat-icon>
            {{ faq.question }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        {{ faq.answer }}
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <!-- / CONTENT -->
</div>
