import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CreateClientService } from '../../../api/create-client.service';
import { Settings } from '../../../settings/settings';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-enterprise',
  templateUrl: './create-enterprise.component.html',
  styleUrls: ['./create-enterprise.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDialogModule,
    FormsModule,
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
})
export class CreateEnterpriseComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  enterprise: any;
  loading: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<CreateEnterpriseComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private createClientService: CreateClientService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.enterprise = {
      name: null,
    };
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateEnterprise(): void {
    this.loading = true;
    // this.logger.info("CreateEnterpriseComponent.onCreateEnterprise() -> " + this.form.valid);
    // this.logger.info("CreateEnterpriseComponent.onCreateEnterprise() -> this.client: " + JSON.stringify(this.enterprise));
    if (this.form.valid) {
      this.createClientService.createEnterprise(this.enterprise).subscribe(
        (response: any) => {
          // this.logger.info("CreateEnterpriseComponent.onCreateEnterprise() -> SUCCESS: " + JSON.stringify(response));
          this.toastr.success(
            '',
            this.translate.instant(
              'GENERIC.API_CALL_SUCCESS.CREATE_ENTERPRISE',
            ),
          );
          this.dialogRef.close(response.data);
          this.loading = false;
        },
        (error: any) => {
          this.logger.error(
            'CreateEnterpriseComponent.onCreateEnterprise() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.loading = false;
          // this.toastr.error("", this.translate.instant("GENERIC.ERRORS.CREATE_CLIENT"));
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.CREATE_CLIENT'),
          );
        },
      );
    }
  }
}
