export const locale = {
  lang: "en-CA",
  data: {
    SPECIFIC_CONDITION: {
      TITLE: "New specific condition",
      EDIT_TITLE: "Edit specific condition",
      SELECT_SPECIFIC_CONDITION: "Select a specific condition",
    },
  },
};
