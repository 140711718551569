import { ErrorDialogComponent } from './../dialogs/error-dialog/error-dialog.component';
import { ApiRoutes } from './../../api/ApiRoutes';
import { AuthenticationService } from './../../api/auth-service.service';
import { Gt2ApiService } from './../../api/gt2-api.service';
import { PictureUploadService } from './picture-upload.service';
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

@Component({
  selector: 'app-picture-upload',
  templateUrl: './picture-upload.component.html',
  styleUrls: ['./picture-upload.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    MatTooltipModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatIconModule,
    Gt2PrimengModule,
  ],
  providers: [PictureUploadService],
})
export class PictureUploadComponent implements OnInit, AfterViewInit {
  // @Input() productUUID: any;
  @Input() media: any;
  @Input() type!: string; // = "SHOP_MESSAGE";
  @Input() modelUUID!: string;
  @Input() selectLabel: string = 'Sélectionner une image';
  @Input() requirementMessage: string = 'Image jpg ou png';
  @Input() useDeleteButton: boolean = true;
  @Output() imageDeleted = new EventEmitter();
  @ViewChild('fileUpload') fileUpload!: ElementRef;
  @Input() local!: string; // = "fr-CA";
  @Input() imageType!: string; // = "desktop";

  files: any = [];
  fileProgress!: number;
  fileUploading: boolean = false;
  errorDialogRef: any;

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    public pictureService: PictureUploadService,
    public dialog: MatDialog,
    public api: Gt2ApiService,
    public auth: AuthenticationService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    //this.logger.info("PictureUploadComponent.ngAfterViewInit() -> media: " + JSON.stringify(this.media));
    //this.logger.info("PictureUploadComponent.ngAfterViewInit() -> type: " + this.type);
    //this.logger.info("PictureUploadComponent.ngAfterViewInit() -> modelUUID: " + this.modelUUID);
    //this.logger.info("PictureUploadComponent.ngAfterViewInit() -> local: " + this.local);
  }

  ngOnDestroy(): void {}

  public onFileDrop(e: any): void {
    //this.logger.info("PictureUploadComponent.constructor() -> files: " + JSON.stringify(e));
  }

  public uploadFile(file: any): void {
    //this.logger.info("PictureUploadComponent.uploadFile() ->!!!");

    // return;
    if (!this.type || !this.modelUUID) {
      this.logger.error(
        'PictureUploadComponent.uploadFile() -> NO PICTURE TYPE DEFINED OR NO MODEL UUID!',
      );
      return;
    }
    const uploadURL: string = this.api.createUrl(ApiRoutes.PICTURE_UPLOAD);
    //this.logger.info("PictureUploadComponent.uploadFile() -> uploadURL: " + uploadURL);
    // //this.logger.info(
    //     "PictureUploadComponent.uploadFile() -> productUUID: " +
    //         this.productUUID
    // );

    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('model_type', this.type);
    formData.append('model_uuid', this.modelUUID); // TODO:
    formData.append('model_local', this.local);
    formData.append('model_imageType', this.imageType);
    file.inProgress = true;
    this.fileUploading = true;

    this.http
      .post<any>(uploadURL, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event: any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              this.fileProgress = file.progress;
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          this.fileUploading = false;
          this.showError(error);
          return of(`${file.data.name} upload failed.`);
        }),
      )
      .subscribe((event: any) => {
        // //this.logger.info("UPLOAD: " + JSON.stringify(event));
        if (typeof event === 'object') {
          // TODO: emit some data to parents to replace media?
          // this.media.photos.photo.url = event.body.data.url;
          // this.media.photos.photo.uuid = event.body.data.uuid;
          this.media.url = event.body.data.url;
          this.media.uuid = event.body.data.uuid;
          this.media.local = this.local;
          this.media.imageType = this.imageType;
          this.fileUploading = false;
        }
      });
  }

  private uploadFiles(): void {
    //this.logger.info("PictureUploadComponent.uploadFiles() ->!!!");
    this.fileUpload.nativeElement.value = '';
    this.files.forEach((file: any) => {
      this.uploadFile(file);
    });
  }

  public onSelectAndUpload(): void {
    // //this.logger.info(
    //     "PictureUploadComponent.onSelectAndUpload() ->!!! " +
    //         this.fileUpload.nativeElement
    // );
    // TODO: reset array as for now we only support single image upload (delet line below for supporting multi-image)
    this.files = [];

    const fileUploader = this.fileUpload?.nativeElement;
    fileUploader.onchange = () => {
      // tslint:disable-next-line: prefer-for-of
      'PictureUploadComponent.onSelectAndUpload() -> fileUpload.onchange() !!!';
      for (let index = 0; index < fileUploader.files.length; index++) {
        const file = fileUploader.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    fileUploader.click();
    // //this.logger.info(
    //     "PictureUploadComponent.onSelectAndUpload() ->!!! fileUploader: " +
    //         fileUploader
    // );
  }

  public onDeleteImage(): void {
    //this.logger.info("PictureUploadComponent.onDeleteImage() -> ");

    if (!this.media || !this.media.uuid) {
      this.logger.error(
        'PictureUploadComponent.onDeleteImage() -> ERROR: NO MEDIA OR NO MEDIA UUID',
      );
      return;
    }
    // TODO: delete once media is taken care of

    this.pictureService.deletePicture(this.media.uuid).subscribe(
      (response: any) => {
        //this.logger.info("PictureUploadComponent.onDeleteImage() -> SUCCESS - response: " + JSON.stringify(response));
        this.imageDeleted.emit(this.media.uuid);
        this.media.url = null;
        this.media.uuid = null;
      },
      (error: any) => {
        this.logger.error(
          'PictureUploadComponent.onDeleteImage() -> ERROR - response: ' +
            JSON.stringify(error),
        );
      },
    );

    // this.pictureService
    //     .deletePicture(this.media.photos.photo.uuid)
    //     .subscribe(
    //         (response) => {
    //             //this.logger.info(
    //                 "PictureUploadComponent.onDeleteImage() -> SUCCESS - response: " +
    //                     JSON.stringify(response)
    //             );
    //             this.imageDeleted.emit(this.media.photos.photo.uuid);
    //             this.media.photos.photo.url = null;
    //             this.media.photos.photo.uuid = null;
    //             // this.imageURL = null;
    //             // this.imageUUID = null;
    //         },
    //         (error) => {
    //             this.logger.error(
    //                 "PictureUploadComponent.onDeleteImage() -> ERROR - response: " +
    //                     JSON.stringify(error)
    //             );
    //         }
    //     );
  }

  public showError(error: HttpErrorResponse): void {
    if (error.status === 422) {
      this.errorDialogRef = this.dialog.open(ErrorDialogComponent, {
        disableClose: false,
      });

      this.errorDialogRef.componentInstance.setError(error);

      this.errorDialogRef.afterClosed().subscribe((result: any) => {
        this.errorDialogRef = null;
      });
    }
  }
}
