import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter, MatOptionModule } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventsService } from '../../../api/events.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../settings/settings';
import { GT2CalendarUtils } from '../../../utils/GT2CalendarUtils';
import { GT2DateAdapter } from '../../../utils/GT2DateAdapter';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import moment from 'moment';
import { Frequency, RRule } from 'rrule';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatSelectModule,
    MatOptionModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  providers: [{ provide: DateAdapter, useClass: GT2DateAdapter }],
})
export class CreateEventComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  Frequency = Frequency;
  //
  static PROPOSAL_SUBTYPE: string = 'PROPOSAL';
  static PROPOSAL_BY_CLIENT_SUBTYPE: string = 'PROPOSAL_BY_CLIENT';
  static ORDER_SUBTYPE: string = 'ORDER';
  static ORDER_BY_CLIENT_SUBTYPE: string = 'ORDER_BY_CLIENT';
  static CLONE_PROPOSAL: string = 'CLONE_PROPOSAL';
  static CLONE_PROPOSAL_INTO_EVENT: string = 'CLONE_PROPOSAL_INTO_EVENT';
  static CLONE_ORDER_INTO_EVENT: string = 'CLONE_ORDER_INTO_EVENT';

  subType: string = CreateEventComponent.ORDER_SUBTYPE;
  form: UntypedFormGroup;
  event: any;
  eventModel: any;
  loading: boolean = false;
  event_date: any = null;
  client_uuid: any = null;
  keepEventInfo: boolean = false;
  proposal: any;
  orderType: string = 'ORDER';
  eventDateStored: any;

  constructor(
    public override dialogRef: MatDialogRef<CreateEventComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public eventsService: EventsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private adapter: DateAdapter<any>,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    this.event = {
      name: null,
      event_date: new Date().toISOString(),
      event_date_end: new Date().toISOString(),
      event_time: null,
      event_time_end: null,
    };

    // //this.logger.info("CreateEventComponent.constructor() -> 0. this.event.toISOString(): " + this.event.event_date);
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      event_date: ['', [Validators.required]],
    });

    if (data && data.event) {
      this.eventModel = data.event;
    }
    // //this.logger.info("CreateEventComponent.constructor() -> eventModel: " + JSON.stringify(this.eventModel));

    if (data && data.subType) {
      this.subType = data.subType;
      //this.logger.info("CreateEventComponent.constructor() -> subType: " + this.subType);
    }

    if (data && data.keepEventInfo) {
      this.keepEventInfo = data.keepEventInfo;
    }
    //this.logger.info("CreateEventComponent.constructor() -> keepEventInfo: " + this.keepEventInfo);
    if (data && data.client_uuid) {
      this.client_uuid = data.client_uuid;
    }

    //this.logger.info("CreateEventComponent.constructor() -> client_uuid: " + this.client_uuid);
    // if (data && data.event_date)
    // {
    //   this.event_date = data.event_date;
    // }

    if (data && data.proposal) {
      this.proposal = data.proposal;
      this.event.name =
        this.translate.instant('CREATE_EVENT.COPY') + this.proposal.name;
      // //this.logger.info("CreateEventComponent.constructor() -> proposal: " + JSON.stringify(this.proposal));
      this.event.event_date = this.eventModel.informations.event_date;
      this.event.event_date_end = this.eventModel.informations.event_date_end;
      this.event.event_time = this.eventModel.informations.event_time;
      this.event.event_time_end = this.eventModel.informations.event_time_end;

      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            ValidatorHelper.excludeStrings([this.proposal.name]),
            Validators.required,
          ],
        ],
        // event_date: ['', [ValidatorHelper.excludeDate([this.proposal.event.event_date]),
        // Validators.required]],
        event_date: ['', [Validators.required]],
        event_date_end: [''],
        // event_time: ['', [Validators.required]],
        // event_time_end: ['', [Validators.required]],
      });
    }

    if (data && data.event_date) {
      this.event.event_date = data.event_date;
      // HACK: https://stackoverflow.com/questions/31096130/how-to-json-stringify-a-javascript-date-and-preserve-timezone
      this.event.event_date.toJSON = function (): any {
        return moment(this).format();
      };
      //     //this.logger.info("CreateEventComponent.constructor() -> 1. data.event_date: " + data.event_date);
      //   const momentDate = moment(data.event_date).format("LL").toString();
      //   this.event.event_date = new Date(momentDate);
      //   //this.logger.info("CreateEventComponent.constructor() -> 1. momentDate: " + momentDate);
      //   //this.logger.info("CreateEventComponent.constructor() -> 2. this.event.event_date: " + this.event.event_date);
      //   //this.logger.info("CreateEventComponent.doCreateOrder() -> event (JSON): " + JSON.stringify(this.event));
      // //this.logger.info("CreateEventComponent.constructor() -> typeof this.event.event_date: " + typeof this.event.event_date);

      // Wed Dec 11 2019 00:00:00 GMT+0100 (Paris Standard Time)
      // this.event.event_date = "Wed Dec 11 2019 00:00:00 GMT+0100 (Paris Standard Time)"; // moment(data.event_date).format("llll").toString();

      // //this.logger.info("CreateEventComponent.constructor() -> 0. moment(): " + moment(data.event_date).format(DateFormatPreference.getDateFormat()));
      //   //this.logger.info("CreateEventComponent.constructor() -> 1. data.event_date: " + data.event_date);
    }
    // //this.logger.info("CreateEventComponent.constructor() -> 2. moment(data.event_date, \"DD-MM-YYYY\"): " + moment(data.event_date).format("DD-MM-YYYY"));
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.event = null;
    this.proposal = null;
  }

  public test(): void {
    //this.logger.info("CreateOrderComponent.test() -> event.event_time: " + this.event.event_time);
  }

  public onDatePickerChange(event: any): void {
    //this.logger.info("CreateOrderComponent.onDatePickerChange() -> event: " + event.value);
    if (event.value) {
      this.eventDateStored = event.value;
    }
    //this.logger.info("eventDateStored => " + this.eventDateStored);
    const date: Date = this.eventDateStored
      ? new Date(this.eventDateStored)
      : new Date();
    const dateFormated = GT2CalendarUtils.formatDateForAPI(date);
    //this.logger.info("CreateEventComponent.onDatePickerChange() -> date formatDateForAPI: " + dateFormated);
    this.event.event_date = dateFormated;

    //
    const rule = new RRule({
      freq: RRule.WEEKLY,
      dtstart: date,
      tzid: 'America/Montreal',
      until: date,
      count: 30,
      interval: 1,
      byweekday: [RRule.MO, RRule.TU, RRule.WE],
      byhour: [0, 1],
      byminute: [0, 30],
      bysecond: [0],
    });
  }

  public onDatePickerChangeEnd(event: any): void {
    //this.logger.info("CreateOrderComponent.onDatePickerChangeEnd() -> event: " + event.value);
    if (!event.value || event.value === null) {
      return;
    }
    const date: Date = new Date(event.value);
    //this.logger.info("CreateEventComponent.onDatePickerChangeEnd() -> date formatDateForAPI: " + GT2CalendarUtils.formatDateForAPI(date));
    this.event.event_date_end = GT2CalendarUtils.formatDateForAPI(date);
  }

  public onCreateEvent(): void {
    this.loading = true;
    // //this.logger.info("CreateEventComponent.onCreateEvent() -> " + this.form.valid);
    // //this.logger.info("CreateEventComponent.onCreateEvent() -> this.order: " + JSON.stringify(this.order));
    // //this.logger.info("CreateEventComponent.onCreateEvent() -> this.event_date: " + JSON.stringify(this.event_date));
    if (this.form.valid) {
      if (this.subType === CreateEventComponent.PROPOSAL_SUBTYPE) {
        this.doCreateProposal();
      } else if (this.subType === CreateEventComponent.ORDER_SUBTYPE) {
        this.doCreateOrder();
      } else if (
        this.subType === CreateEventComponent.PROPOSAL_BY_CLIENT_SUBTYPE
      ) {
        this.doCreateProposalByClient();
      } else if (
        this.subType === CreateEventComponent.ORDER_BY_CLIENT_SUBTYPE
      ) {
        this.doCreateOrderByClient();
      } else if (
        this.subType === CreateEventComponent.CLONE_PROPOSAL_INTO_EVENT
      ) {
        this.doCloneProposalIntoEvent();
      } else if (this.subType === CreateEventComponent.CLONE_ORDER_INTO_EVENT) {
        if (this.orderType === 'PROPOSAL') {
          this.doCloneOrderToProposalIntoEvent();
        } else {
          this.doCloneOrder();
        }
      } else if (this.subType === CreateEventComponent.CLONE_PROPOSAL) {
        this.doCloneProposal();
      }
    }
  }

  public doCreateOrder(): void {
    //this.logger.info("CreateEventComponent.doCreateOrder() -> event: " + JSON.stringify(this.event));
    //this.logger.info("CreateEventComponent.doCreateOrder() -> this.event.event_date: " + this.event.event_date);
    this.eventsService.createOrder(this.event).subscribe(
      (response: any) => {
        // //this.logger.info("CreateEventComponent.doCreateOrder() -> SUCCESS: " + JSON.stringify(response));
        this.dialogRef.close(response.data);
      },
      (error: any) => {
        this.logger.error(
          'CreateEventComponent.doCreateOrder() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.loading = false;
      },
    );
  }

  public doCreateProposal(): void {
    // //this.logger.info("CreateEventComponent.doCreateProposal()");
    this.eventsService.createProposal(this.event).subscribe(
      (response: any) => {
        // //this.logger.info("CreateEventComponent.doCreateProposal() -> SUCCESS: " + JSON.stringify(response));
        this.dialogRef.close(response.data);
      },
      (error: any) => {
        this.logger.error(
          'CreateEventComponent.doCreateProposal() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.loading = false;
      },
    );
  }

  public doCreateProposalByClient(): void {
    this.eventsService
      .createProposalByClient(this.event, this.client_uuid)
      .subscribe(
        (response: any) => {
          // //this.logger.info("CreateEventComponent.doCreateProposalByClient() -> SUCCESS: " + JSON.stringify(response));
          this.dialogRef.close(response.data);
        },
        (error: any) => {
          this.logger.error(
            'CreateEventComponent.doCreateProposalByClient() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
          this.loading = false;
        },
      );
  }

  public doCreateOrderByClient(): void {
    this.eventsService
      .createOrderByClient(this.event, this.client_uuid)
      .subscribe(
        (response: any) => {
          // //this.logger.info("CreateEventComponent.doCreateOrderByClient() -> SUCCESS: " + JSON.stringify(response));
          this.dialogRef.close(response.data);
        },
        (error: any) => {
          this.logger.error(
            'CreateEventComponent.doCreateOrderByClient() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
          this.loading = false;
        },
      );
  }

  public doCloneOrder(): void {
    // //this.logger.info("CreateEventComponent.doCloneOrder() -> this.event: " + JSON.stringify(this.event));
    //this.logger.info("CreateEventComponent.doCloneOrder() -> this.event: " + JSON.stringify(this.event));
    //this.logger.info("CreateEventComponent.doCloneOrder() -> this.keepEventInfo: " + this.keepEventInfo);
    // TODO: SWITCH API CALL
    if (this.keepEventInfo) {
      this.eventsService
        .cloneOrderIntoEventWithDetails(this.proposal, this.event)
        .subscribe(
          (response: any) => {
            //this.logger.info("CreateEventComponent.doCloneOrder() -> SUCCESS: " + JSON.stringify(response));
            this.dialogRef.close(response.data);
          },
          (error: any) => {
            this.logger.error(
              'CreateEventComponent.doCloneOrder() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
            this.loading = false;
          },
        );
    } else {
      this.eventsService
        .cloneOrderIntoEvent(this.proposal, this.event)
        .subscribe(
          (response: any) => {
            // //this.logger.info("CreateEventComponent.doCloneOrder() -> SUCCESS: " + JSON.stringify(response));
            this.dialogRef.close(response.data);
          },
          (error: any) => {
            this.logger.error(
              'CreateEventComponent.doCloneOrder() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
            this.loading = false;
          },
        );
    }
  }

  public doCloneProposalIntoEvent(): void {
    // //this.logger.info("CreateEventComponent.doCloneProposalIntoEvent() -> this.event: " + JSON.stringify(this.event));
    // TODO:
    //this.logger.info("CreateEventComponent.doCloneProposalIntoEvent() -> this.event: " + JSON.stringify(this.event));
    //this.logger.info("CreateEventComponent.doCloneProposalIntoEvent() -> this.keepEventInfo: " + this.keepEventInfo);
    if (this.keepEventInfo) {
      this.eventsService
        .cloneProposalIntoEventWithDetails(this.proposal, this.event)
        .subscribe(
          (response: any) => {
            //this.logger.info("CreateEventComponent.doCloneProposalIntoEvent() -> SUCCESS: " + JSON.stringify(response));
            this.dialogRef.close(response.data);
          },
          (error: any) => {
            this.logger.error(
              'CreateEventComponent.doCloneProposalIntoEvent() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
            this.loading = false;
          },
        );
    } else {
      this.eventsService
        .cloneProposalIntoEvent(this.proposal, this.event)
        .subscribe(
          (response: any) => {
            //this.logger.info("CreateEventComponent.doCloneProposalIntoEvent() -> SUCCESS: " + JSON.stringify(response));
            this.dialogRef.close(response.data);
          },
          (error: any) => {
            this.logger.error(
              'CreateEventComponent.doCloneProposalIntoEvent() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
            this.loading = false;
          },
        );
    }
  }

  public doCloneOrderToProposalIntoEvent(): void {
    // //this.logger.info("CreateEventComponent.doCloneProposalIntoEvent() -> this.event: " + JSON.stringify(this.event));
    // TODO:
    //this.logger.info("CreateEventComponent.doCloneOrderToProposalIntoEvent() -> this.event: " + JSON.stringify(this.event));
    //this.logger.info("CreateEventComponent.doCloneOrderToProposalIntoEvent() -> this.keepEventInfo: " + this.keepEventInfo);
    if (this.keepEventInfo) {
      this.eventsService
        .cloneOrderToProposalIntoEventWithDetails(this.proposal, this.event)
        .subscribe(
          (response: any) => {
            //this.logger.info("CreateEventComponent.doCloneOrderToProposalIntoEvent() -> SUCCESS: " + JSON.stringify(response));
            this.dialogRef.close(response.data);
          },
          (error: any) => {
            this.logger.error(
              'CreateEventComponent.doCloneOrderToProposalIntoEvent() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
            this.loading = false;
          },
        );
    } else {
      this.eventsService
        .cloneOrderToProposalIntoEvent(this.proposal, this.event)
        .subscribe(
          (response: any) => {
            //this.logger.info("CreateEventComponent.doCloneOrderToProposalIntoEvent() -> SUCCESS: " + JSON.stringify(response));
            this.dialogRef.close(response.data);
          },
          (error: any) => {
            this.logger.error(
              'CreateEventComponent.doCloneOrderToProposalIntoEvent() -> ERROR: ' +
                JSON.stringify(error),
            );
            this.handleAPIError(error, this.dialog, null, null);
            this.loading = false;
          },
        );
    }
  }

  public doCloneProposal(): void {
    // //this.logger.info("CreateEventComponent.doCloneProposal() -> this.event: " + JSON.stringify(this.event));
    this.eventsService.cloneProposal(this.proposal).subscribe(
      (response: any) => {
        // //this.logger.info("CreateEventComponent.doCloneProposal() -> SUCCESS: " + JSON.stringify(response));
        this.dialogRef.close(response.data);
      },
      (error: any) => {
        this.logger.error(
          'CreateEventComponent.doCloneProposal() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.loading = false;
      },
    );
  }
}
