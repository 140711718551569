export const locale = {
  lang: "en-CA",
  data: {
    GENERIC_CREATE: {
      TITLE: "",
      CREATE_SUCCESS_TOAST: "Creation success!",
      CREATE_ERROR_TOAST: "Creation error!",
    },
  },
};
