export const locale = {
  lang: "en-CA",
  data: {
    TOOLBAR: {
      HISTORY: "Historic",
      MY_PROFILE: "My Profile",
      DARKMODE: "Dark Mode",
      LIGTHMODE: "Light Mode",
      ORGANIZATION: "Organization",
      LOGOUT: "Logout",
      CREATE_EMPLOYEE: "Create an employee",
      CREATE_PRODUCT: "Create a product",
      CREATE_PRODUCT_COMBO: "Create a combo",
      CREATE_ORDER: "Create an order",
      CREATE_PLACE: "Create a place",
      CREATE_ENTERPRISE: "Create an enterprise",
      CREATE_CLIENT: "Create a client",
      CREATE_MATERIAL: "Create an equipment",
      CREATE_MATERIAL_COMBO: "Create a equipment combo",
      SETTINGS: "Settings",
      CREATE_PROPOSAL: "Create proposal",
      CATERER: "Caterer",
      SHOP: "Order online",
      CREATE_CONGRES: "Create a congress",
    },
  },
};
