import {
  Component,
  OnInit,
  Inject,
  NgZone,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { IconsService } from '../../../services/icons.service';
import { GT2FirebaseService } from '../../../../services/gt2-firebase.service';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-processes-bottom-sheet',
  templateUrl: './processes-bottom-sheet.component.html',
  styleUrls: ['./processes-bottom-sheet.component.scss'],
  // changeDetection: ChangeDetectionStrategy.Default
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatIconModule,
    Gt2PrimengModule,
    MatButtonModule,
    MatInputModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
  ],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ProcessesBottomSheetComponent implements OnInit {
  processes: any[] = [];
  show: boolean = true;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<ProcessesBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public zone: NgZone,
    private ref: ChangeDetectorRef,
    private translationLoader: FuseTranslationLoaderService,
    public iconsService: IconsService,
    public firebaseService: GT2FirebaseService,
  ) {
    this.translationLoader.loadTranslations(english, french);
    if (data && data.processes) {
      // this.zone.run(() => this.processes = data.processes);
      this.processes = data.processes;
    }
  }

  ngOnInit() {}

  public setProcesses(processes: any[]): void {
    // this.zone.run(() => this.processes = processes);
    this.processes = processes;
    this.ref.markForCheck();
  }

  public onCancelProcess(process: any): void {
    this.firebaseService.deleteProcess(process);
  }

  public onToggleShow(): void {
    this.show = !this.show;
  }
}
