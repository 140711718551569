import { RolesService } from './../../services/roles.service';
import { CrudEmployeeService } from './../crud/crud-employee/crud-employee.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { DataSource } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
  SettingsPrefModel,
  UserSettingsService,
} from '../../api/user-settings.service';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { ApiRoutes } from '../../api/ApiRoutes';
import { DateFormatService } from '../../api/date-format.service';
import { DateTimeFormatService } from '../../api/date-time-format.service';
import { TimezoneService } from '../../api/timezone.service';
import { AppRoutes } from '../../const/AppRoutes';
import { ValidatorHelper } from '../../validators/ValidatorHelper';
import { GT2PageAbstract } from '../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { Title } from '@angular/platform-browser';
import { ModulesService } from '../../services/modules.service';
import { UserRole } from '../../user/UserRole';
import { MatDrawer } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  //
  settingsData!: SettingsData[];
  basicSettingsData!: SettingsData[];
  ordersSettingsData!: SettingsData[];
  taxesSettingsData!: SettingsData[];
  conditionsSettingsData!: SettingsData[];
  notificationMessagesSettingsData!: SettingsData[];
  integrationSettingsData!: SettingsData[];
  allSettingsData!: SettingsData[];
  usersAndEmployeesSettingsData!: SettingsData[];

  dataSource!: SettingsDataSource | any;
  basicSettingsDataSource!: SettingsDataSource | any;
  ordersSettingsDataSource!: SettingsDataSource | any;
  taxesSettingsDataSource!: SettingsDataSource | any;
  conditionsSettingsDataSource!: SettingsDataSource | any;
  notificationMessagesSettingsDataSource!: SettingsDataSource | any;
  usersAndEmployeesSettingsDataSource!: SettingsDataSource | any;
  // integrationSettingsDataSource: SettingsDataSource | null;

  displayedColumns = ['name'];
  selected?: SettingsData;
  eventLogisticStatusServiceURL: string;
  allergensServiceURL: string;
  productionZoneServiceURL: string;
  invoiceCategoryServiceURL: string;
  materialsCategoryServiceURL: string;
  staffProvidersCategoryServiceURL: string;
  productsCategoryServiceURL: string;
  providersCategoryServiceURL: string;
  serviceChargeServiceURL: string;
  ledgerAccountServiceURL: string;
  socialChargeServiceURL: string;
  specificConditionsServiceURL: string;
  eventsTypeServiceURL: string;

  setting!: SettingsPrefModel;
  currentSectionID?: string;

  sections: any = {};

  form: UntypedFormGroup;

  hasRhRolesAccess: boolean = false;

  hasOwnerRoles: boolean = false;

  @ViewChild('drawer') drawer!: MatDrawer;
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  panels: any[] = [];
  selectedSubPanel: string = 'CLIENTS_CATEGORIES';
  selectedPanel: string = 'settingsData';
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isSmallScreen = false;

  constructor(
    public router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    public timezoneService: TimezoneService,
    public settingsService: UserSettingsService,
    public dateFormatService: DateFormatService,
    private route: ActivatedRoute,
    private titleService: Title,
    public modulesService: ModulesService,
    public dateTimeFormatService: DateTimeFormatService,
    private translationLoader: FuseTranslationLoaderService,
    private employee: CrudEmployeeService,
    public rolesService: RolesService,
    private permissions: NgxPermissionsService,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    // this.staffProvider = {
    //     name: this.translate.instant("SETTINGS.STAFF_PROVIDERS"),
    //     id: "STAFF_PROVIDERS",
    //     sectionName: "fournisseur-ressources-humaine",
    //     openID: 'basicPreferenceSectionOpen'
    // };

    this.eventLogisticStatusServiceURL = ApiRoutes.EVENT_LOGISTIC_STATUS;
    this.allergensServiceURL = ApiRoutes.ALLERGENS;
    this.productionZoneServiceURL = ApiRoutes.PRODUCTION_ZONE;
    this.invoiceCategoryServiceURL = ApiRoutes.INVOICE_CATEGORY;
    this.materialsCategoryServiceURL = ApiRoutes.MATERIALS_CATEGORIES;
    // this.staffCategoryServiceURL = ApiRoutes.STAFF_CATEGORIES;
    this.productsCategoryServiceURL = ApiRoutes.PRODUCT_CATEGORIES;
    this.providersCategoryServiceURL = ApiRoutes.MATERIAL_PROVIDER;
    this.staffProvidersCategoryServiceURL = ApiRoutes.STAFF_CATEGORIES;
    this.socialChargeServiceURL = ApiRoutes.SOCIAL_CHARGE;
    this.specificConditionsServiceURL = ApiRoutes.SPECIFIC_CONDITIONS;
    this.serviceChargeServiceURL = ApiRoutes.SERVICE_CHARGE;
    this.ledgerAccountServiceURL = ApiRoutes.LEDGER_ACCOUNT;
    this.eventsTypeServiceURL = ApiRoutes.EVENTS_TYPE;

    this.form = this.formBuilder.group({
      social_charge: [
        '',
        [ValidatorHelper.isNumber0To1000, Validators.required],
      ],
    });
  }

  ngOnInit(): void {
    this.employee.employee.subscribe({
      next: (me: any) => {
        // //this.logger.info("me() : " + me);
        if (me) {
          this.hasOwnerRoles =
            me.user.role == UserRole.OWNER ||
            me.user.role == UserRole.GOD ||
            me.user.role == UserRole.ADMIN;
          // RH_ROLES
          this.permissions
            .hasPermission(this.rolesService.RH_ROLES)
            .then((value) => {
              // //this.logger.info("hasPermission() : " + value);
              this.hasRhRolesAccess = value;
              this.loadData();
              this.watchURLParams();
            });
        }
      },
    });

    this.breakpointObserver.observe(['(max-width: 1024px)']).subscribe({
      next: (result) => {
        this.isSmallScreen = result.matches;
        if (result.matches) {
          this.drawerOpened = false;
          this.drawer?.toggle();
        } else {
          this.drawerOpened = true;
          this.drawer?.toggle();
        }
      },
    });

    this.initData();
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  ngAfterViewInit() {}

  public initData(): void {
    this.settingsData = [
      {
        name: this.translate.instant('SETTINGS.CLIENTS_CATEGORIES'),
        id: 'CLIENTS_CATEGORIES',
        sectionName: 'categories-clients',
        openID: 'categoriesAndZoneSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.MATERIALS_CATEGORIES'),
        id: 'MATERIALS_CATEGORIES',
        sectionName: 'categories-materiels',
        openID: 'categoriesAndZoneSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.PRODUCTS_CATEGORIES'),
        id: 'PRODUCTS_CATEGORIES',
        sectionName: 'categories-produits',
        openID: 'categoriesAndZoneSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.PRODUCTION_ZONES'),
        id: 'PRODUCTION_ZONES',
        sectionName: 'zone-de-production',
        openID: 'categoriesAndZoneSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.EVENTS_TYPES'),
        id: 'EVENTS_TYPES',
        sectionName: 'types-evenements',
        openID: 'categoriesAndZoneSectionOpen',
      },
    ];

    this.basicSettingsData = [
      {
        name: this.translate.instant('SETTINGS.EVENT_LOGISTIC_STATUS'),
        id: 'EVENT_LOGISTIC_STATUS',
        sectionName: 'statut-logistique',
        openID: 'basicPreferenceSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.ALLERGENS'),
        id: 'ALLERGENS',
        sectionName: 'allergenes',
        openID: 'basicPreferenceSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.JOB_FUNCTIONS'),
        id: 'JOB_FUNCTIONS',
        sectionName: 'fonction-de-travail',
        openID: 'basicPreferenceSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.MATERIALS_PROVIDERS'),
        id: 'MATERIALS_PROVIDERS',
        sectionName: 'fournisseur-materiel',
        openID: 'basicPreferenceSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.STAFF_PROVIDERS'),
        id: 'STAFF_PROVIDERS',
        sectionName: 'fournisseur-ressources-humaine',
        openID: 'basicPreferenceSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.SERVICE_CHARGE'),
        id: 'SERVICE_CHARGE',
        sectionName: 'frais-de-service',
        openID: 'basicPreferenceSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.DELIVERY_SECTOR'),
        id: 'DELIVERY_SECTOR',
        sectionName: 'livraison-secteur',
        openID: 'basicPreferenceSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.DELIVERY_TEMPLATES'),
        id: 'DELIVERY_TEMPLATES',
        sectionName: 'model-demande-livraison',
        openID: 'basicPreferenceSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.DATE_TIME_SETTINGS'),
        id: 'DATE_TIME',
        sectionName: 'date-et-heure',
        openID: 'basicPreferenceSectionOpen',
      },
    ];

    this.ordersSettingsData = [
      {
        name: this.translate.instant('SETTINGS.NUMBERING'),
        id: 'NUMBERING',
        sectionName: 'numerotation',
        openID: 'ordersAndBillingSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.WRAPPING'),
        id: 'WRAPPING',
        sectionName: 'emballage',
        openID: 'ordersAndBillingSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.WRAPPING_DEFAULT'),
        id: 'WRAPPING_DEFAULT',
        sectionName: 'emballage-defaut',
        openID: 'ordersAndBillingSectionOpen',
      },
    ];

    this.taxesSettingsData = [
      {
        name: this.translate.instant('SETTINGS.TAXES'),
        id: 'TAXES',
        sectionName: 'taxes',
        openID: 'taxesSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.TAXES_DEFAULT'),
        id: 'TAXES_DEFAULT',
        sectionName: 'taxes-defaut',
        openID: 'taxesSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.LEDGER_ACCOUNT_DEFAULT'),
        id: 'LEDGER_ACCOUNT_DEFAULT',
        sectionName: 'compte-de-grand-livre-defaut',
        openID: 'taxesSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.LEDGER_ACCOUNT'),
        id: 'LEDGER_ACCOUNT',
        sectionName: 'compte-de-grand-livre',
        openID: 'taxesSectionOpen',
      },
    ];

    this.conditionsSettingsData = [
      {
        name: this.translate.instant('SETTINGS.GENERAL_CONDITIONS'),
        id: 'GENERAL_CONDITIONS',
        sectionName: 'conditions-generales',
        openID: 'conditionsSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.SPECIFICS_CONDITIONS'),
        id: 'SPECIFICS_CONDITIONS',
        sectionName: 'conditions-specifiques',
        openID: 'conditionsSectionOpen',
      },
    ];

    this.notificationMessagesSettingsData = [
      {
        name: this.translate.instant('SETTINGS.NOTIFICATION_MESSAGE_PROPOSAL'),
        id: 'NOTIFICATION_MESSAGE_PROPOSAL',
        sectionName: 'messages-notification-proposition',
        openID: 'notificationMessageSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.NOTIFICATION_MESSAGE_ORDER'),
        id: 'NOTIFICATION_MESSAGE_ORDER',
        sectionName: 'messages-notification-commande',
        openID: 'notificationMessageSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.NOTIFICATION_MESSAGE_INVOICE'),
        id: 'NOTIFICATION_MESSAGE_INVOICE',
        sectionName: 'messages-notification-facture',
        openID: 'notificationMessageSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.NOTIFICATION_MESSAGE_RECEIPT'),
        id: 'NOTIFICATION_MESSAGE_RECEIPT',
        sectionName: 'messages-notification-recu',
        openID: 'notificationMessageSectionOpen',
      },
      {
        name: this.translate.instant(
          'SETTINGS.NOTIFICATION_MESSAGE_CLIENT_STATEMENT',
        ),
        id: 'NOTIFICATION_MESSAGE_CLIENT_STATEMENT',
        sectionName: 'messages-notification-etat-de-compte-client',
        openID: 'notificationMessageSectionOpen',
      },
      {
        name: this.translate.instant(
          'SETTINGS.NOTIFICATION_MESSAGE_ENTERPRISE_STATEMENT',
        ),
        id: 'NOTIFICATION_MESSAGE_ENTERPRISE_STATEMENT',
        sectionName: 'messages-notification-etat-de-compte-entreprise',
        openID: 'notificationMessageSectionOpen',
      },
      {
        name: this.translate.instant('SETTINGS.NOTIFICATION_MESSAGE_SCHEDULE'),
        id: 'NOTIFICATION_MESSAGE_SCHEDULE',
        sectionName: 'messages-notification-horaire',
        openID: 'notificationMessageSectionOpen',
      },

      {
        name: this.translate.instant(
          'SETTINGS.NOTIFICATION_MESSAGE_ALL_RECEIPT_NOTICES',
        ),
        id: 'NOTIFICATION_MESSAGE_ALL_RECEIPT_NOTICES',
        sectionName: 'notification_all_receipt_notices',
        openID: 'notificationMessageSectionOpen',
      },
    ];

    this.usersAndEmployeesSettingsData = [
      {
        name: this.translate.instant('SETTINGS.MAX_USERS'),
        id: 'MAX_USERS',
        sectionName: 'user-employee-setting',
        openID: 'usersAndEmployeesSettingsSectionOpen',
      },
    ];

    this.panels = [
      {
        id: 'settingsData',
        icon: 'category',
        title: this.translate.instant('SETTINGS.CATEGORIES_&_ZONE'),
        subPanel: this.settingsData,
      },
      {
        id: 'basicSettingsData',
        icon: 'settings',
        title: this.translate.instant('SETTINGS.BASIC_SETTINGS'),
        subPanel: this.basicSettingsData,
      },
      {
        id: 'ordersSettingsData',
        icon: 'attach_money',
        title: this.translate.instant('SETTINGS.ORDERS_BILLING_SETTINGS'),
        subPanel: this.ordersSettingsData,
      },
      {
        id: 'taxesSettingsData',
        icon: 'calculate',
        title: this.translate.instant('SETTINGS.TAXES_SETTINGS'),
        subPanel: this.taxesSettingsData,
      },
      {
        id: 'conditionsSettingsData',
        icon: 'gavel',
        title: this.translate.instant('SETTINGS.CONDITONS_SETTINGS'),
        subPanel: this.conditionsSettingsData,
      },
      {
        id: 'notificationMessagesSettingsData',
        icon: 'notifications',
        title: this.translate.instant('SETTINGS.NOTIFICATION_MESSAGES'),
        subPanel: this.notificationMessagesSettingsData,
      },
      {
        id: 'usersAndEmployeesSettingsData',
        icon: 'group',
        title: this.translate.instant('SETTINGS.USERS_AND_EMPLOYEES'),
        subPanel: this.usersAndEmployeesSettingsData,
      },
    ];

    const globalPayment = {
      name: this.translate.instant(
        'SETTINGS.NOTIFICATION_MESSAGE_INVOICE_ON_LINE_GP',
      ),
      id: 'NOTIFICATION_MESSAGE_INVOICE_ON_LINE_GP',
      sectionName: 'messages-notification-facture-en-ligne-gp',
      openID: 'notificationMessageSectionOpen',
    };

    const banquetContract = {
      name: this.translate.instant(
        'SETTINGS.NOTIFICATION_MESSAGE_BANQUET_CONTRACT',
      ),
      id: 'NOTIFICATION_MESSAGE_BANQUET_CONTRACT',
      sectionName: 'messages-notification-contrat-banquet',
      openID: 'notificationMessageSectionOpen',
    };

    this.usersAndEmployeesSettingsData = [
      {
        name: this.translate.instant('SETTINGS.MAX_USERS'),
        id: 'MAX_USERS',
        sectionName: 'user-employee-setting',
        openID: 'usersAndEmployeesSettingsSectionOpen',
      },
    ];

    this.sections = {
      categoriesAndZoneSectionOpen: {
        value: false,
        collection: this.settingsData,
      },
      basicPreferenceSectionOpen: {
        value: false,
        collection: this.basicSettingsData,
      },
      ordersAndBillingSectionOpen: {
        value: false,
        collection: this.ordersSettingsData,
      },
      taxesSectionOpen: {
        value: false,
        collection: this.taxesSettingsData,
      },

      conditionsSectionOpen: {
        value: false,
        collection: this.conditionsSettingsData,
      },
      notificationMessageSectionOpen: {
        value: false,
        collection: this.notificationMessagesSettingsData,
      },
      // integrationSectionOpen : {
      //     value: false,
      //     collection: this.integrationSettingsData
      // }
      usersAndEmployeesSettingsSectionOpen: {
        value: false,
        collection: this.usersAndEmployeesSettingsData,
      },
    };

    //
    this.dataSource = new SettingsDataSource(this.settingsData);
    this.basicSettingsDataSource = new SettingsDataSource(
      this.basicSettingsData,
    );
    this.ordersSettingsDataSource = new SettingsDataSource(
      this.ordersSettingsData,
    );
    this.taxesSettingsDataSource = new SettingsDataSource(
      this.taxesSettingsData,
    );
    this.conditionsSettingsDataSource = new SettingsDataSource(
      this.conditionsSettingsData,
    );
    this.notificationMessagesSettingsDataSource = new SettingsDataSource(
      this.notificationMessagesSettingsData,
    );
    this.notificationMessagesSettingsDataSource = new SettingsDataSource(
      this.notificationMessagesSettingsData,
    );
    this.usersAndEmployeesSettingsDataSource = new SettingsDataSource(
      this.usersAndEmployeesSettingsData,
    );

    this.modulesService.modulesSet.subscribe({
      next: (data) => {
        if (data) {
          this.notificationMessagesSettingsData;
        }
      },
    });
    //
    this.modulesService.modulesSet.subscribe({
      next: (areSet) => {
        if (this.modulesService.hasGlobalPayments()) {
          this.notificationMessagesSettingsData?.splice(3, 0, globalPayment);
        }
        if (this.modulesService.hasCongressModule()) {
          this.notificationMessagesSettingsData?.push(banquetContract);
        }
      },
    });

    //
    // for searching purpose
    this.crunchAllSettingsData();
    this.setLocalChange();
  }

  public crunchAllSettingsData(): void {
    this.allSettingsData = this.settingsData
      ?.concat(this.basicSettingsData)
      .concat(this.ordersSettingsData)
      .concat(this.taxesSettingsData)
      .concat(this.conditionsSettingsData)
      .concat(this.notificationMessagesSettingsData)
      .concat(this.usersAndEmployeesSettingsData);
    // .concat(this.integrationSettingsData);
  }

  public watchURLParams(): void {
    this.route.params.subscribe({
      next: (params: any) => {
        // if the current page is STAFF_PROVIDERS and you don't have the roles, we redirect
        if (
          params.section === 'fournisseur-ressources-humaine' &&
          !this.hasRhRolesAccess
        ) {
          this.onSelect(this.basicSettingsData[0]);
        }
        //
        if (params.section === 'user-employee-setting' && !this.hasOwnerRoles) {
          this.onSelect(this.basicSettingsData[0]);
        }
        //
        if (params.section) {
          this.currentSectionID = params.section;
          this.selected = this.allSettingsData?.find((item) => {
            return item.sectionName === this.currentSectionID;
          });
          this.toggleSection(this.selected?.openID, true);
        } else {
          this.onSelect(this.settingsData[0]);
        }
      },
    });
  }
  togglePanel(panelId: string) {
    this.selectedPanel = this.selectedPanel === panelId ? '' : panelId;
    this.selectedSubPanel = '';
  }
  public setLocalChange(): void {
    this.translate.onLangChange.subscribe({
      next: (params: LangChangeEvent) => {
        this.logger.warn(
          'SettingsComponent.setLocalChange() -> LANGUAGE CHANGE: ' +
            params.lang,
        );
      },
    });
  }

  onSelect(selected: any) {
    this.selected = selected;
    this.selectedSubPanel = selected.id;
    this.router.navigateByUrl(AppRoutes.SETTINGS + '/' + selected.sectionName);
    if (this.isSmallScreen) {
      this.drawerOpened = false;
      this.drawer?.toggle();
    }
  }

  public loadData(): void {
    this.settingsService.getSettings().subscribe({
      next: (response: any) => {
        // //this.logger.info("SettingsComponent.constructor().getSettings() SUCCESS: " + JSON.stringify(response));
        this.setting = response.data;
        if (!this.hasRhRolesAccess) {
          this.basicSettingsData = this.basicSettingsData?.filter((item) => {
            //this.logger.info("item.id : " + item.id);
            return item.id !== 'STAFF_PROVIDERS';
          });
          this.basicSettingsDataSource = new SettingsDataSource(
            this.basicSettingsData,
          );
          //this.logger.info("this.basicSettingsData : " + JSON.stringify(this.basicSettingsData));
          this.crunchAllSettingsData();
        }
      },
      error: (error: any) => {
        //this.logger.info("SettingsComponent.constructor().getSettings() ERROR: " + JSON.stringify(error));
      },
    });
  }

  onTimeZoneSelected(event: any): void {
    ////this.logger.info("onTimeZoneSelected(): " + JSON.stringify(event));
    this.setting.timezone = event;
    /*this.timezoneService
            .updateTimezone({ timezone: event }, this.setting.uuid)
            .subscribe(
                (response) => {
                    //this.logger.info("SettingsComponent.onTimeZoneSelected() SUCCESS: " + JSON.stringify(response));
                    this.setting = response.data;
                },
                (error) => {
                    //this.logger.info(
                        "SettingsComponent.onTimeZoneSelected() ERROR: " +
                        JSON.stringify(error)
                    );
                }
            );*/
  }

  public onDateFormatSelected(event: any): void {
    // //this.logger.info("onDateFormatSelected(): " + JSON.stringify(event));
    // //this.logger.info("onDateFormatSelected(): this.setting: " + JSON.stringify(this.setting));
    this.setting.date_format = event;

    /*const sub1 = this.dateFormatService
            .updateDateFormat({ date_format: event }, this.setting.uuid)
            .subscribe(
                (response) => {
                    // //this.logger.info("SettingsComponent.onDateFormatSelected() SUCCESS: " + JSON.stringify(response));
                    this.setting = response.data;
                },
                (error) => {
                    //this.logger.info(
                        "SettingsComponent.onDateFormatSelected() ERROR: " +
                        JSON.stringify(error)
                    );
                },
                () => { sub1.unsubscribe() }
            );*/
  }

  public onDateTimeFormatSelected(event: any) {
    // //this.logger.info("onDateTimeFormatSelected(): " + JSON.stringify(event));
    // //this.logger.info("onDateTimeFormatSelected(): this.setting: " + JSON.stringify(this.setting));
    this.setting.datetime_format = event;
    /*const sub2 = this.dateTimeFormatService
            .updateDateTimeFormat({ datetime_format: event }, this.setting.uuid)
            .subscribe(
                (response) => {
                    // //this.logger.info("SettingsComponent.onDateTimeFormatSelected() SUCCESS: " + JSON.stringify(response));
                    this.setting = response.data;
                },
                (error) => {
                    //this.logger.info(
                        "SettingsComponent.onDateTimeFormatSelected() ERROR: " +
                        JSON.stringify(error)
                    );
                },
                () => {sub2.unsubscribe()}
            );*/
  }

  public onDateTimePreferencesData() {
    this.dateTimeFormatService
      .updateDateTimeFormat(this.setting, this.setting.uuid)
      .subscribe({
        next: (response) => {
          // //this.logger.info("SettingsComponent.onDateTimeFormatSelected() SUCCESS: " + JSON.stringify(response));
          this.loadData();
        },
        error: (error) => {
          //this.logger.info("SettingsComponent.onDateTimeFormatSelected() ERROR: " + JSON.stringify(error));
        },
      });
  }

  public onSocialChargeUpdate(charge: number) {
    this.settingsService
      .updateSocialCharge({ social_charge: charge }, this.setting.uuid)
      .subscribe({
        next: (response: any) => {
          // //this.logger.info("SettingsComponent.onSocialChargeUpdate() SUCCESS: " + JSON.stringify(response));
          this.loadData();
        },
        error: (error: any) => {
          //this.logger.info("SettingsComponent.onSocialChargeUpdate() ERROR: " + JSON.stringify(error));
        },
      });
  }

  public onSage(): void {
    // //this.logger.info("SettingsComponent.onSage() ");
    window.open(ApiRoutes.SAGE_AUTHORIZE, '_blank');
  }

  public toggleSection(section: any, urlOrigin: boolean = false): void {
    this.sections[section].value = urlOrigin
      ? true
      : !this.sections[section].value;
    for (const each of Object.keys(this.sections)) {
      if (each !== section) {
        this.sections[each].value = false;
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Navigate to the panel
   *
   * @param panel
   */
  goToPanel(panel: string): void {
    this.selectedPanel = panel;

    // Close the drawer on 'over' mode
    if (this.drawerMode === 'over') {
      this.drawer.close();
    }
  }

  /**
   * Get the details of the panel
   *
   * @param id
   */
  getPanelInfo(id: string): any {
    return this.panels.find((panel) => panel.id === id);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}

export class SettingsDataSource extends DataSource<any> {
  constructor(private settingsData: SettingsData[]) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return of(this.settingsData);
  }

  disconnect() {}
}

export interface SettingsData {
  name: string;
  id: string;
  sectionName: string;
  openID: string;
}
