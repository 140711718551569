import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { IconsService } from '../../services/icons.service';
import { NGXLogger } from 'ngx-logger';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-top-alerts',
  templateUrl: './top-alerts.component.html',
  styleUrls: ['./top-alerts.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class TopAlertsComponent implements OnInit, AfterViewInit {
  @Input() model: TopAlertModel | null = null;
  // @Input() message: string;
  // @Input() messageColor: string;
  // @Input() messageClosable: boolean;
  // @Input() closable: boolean;

  constructor(
    public iconsService: IconsService,
    public logger: NGXLogger,
  ) {
    // this.model = {
    //   message: "Un message ...",
    //   messageColor: "gt2-yellow",
    //   messageClosable: true,
    //   elevation: true
    // };
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // //this.logger.info("TopAlertsComponent.ngAfterViewInit() -> model: " + JSON.stringify(this.model));
    // //this.logger.info("TopAlertsComponent.ngAfterViewInit() -> firebase: " + JSON.stringify(environment.firebase));
  }

  public onHideTopBar(): void {
    //this.logger.info("TopAlertsComponent.onHideTopBar() -> model: " + JSON.stringify(this.model));
    this.model = null;
  }
}

export interface TopAlertModel {
  message: string;
  message_color: string;
  message_closable: boolean;
  elevation: boolean;
  message_url_label: string;
  message_url: string;
  icon: string;
}
