import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class FlowService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getData(): Observable<any> {
    //this.logger.info("FlowService.getData()");
    return this.getFlowServiceList();
  }

  public getFlowServiceList() {
    //this.logger.info("FlowService.getFlowServiceList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.FLOW_LIST));
  }

  // public getEventFlowServiceList(eventUUID: string;)
  // {
  //     this.logger.info("FlowService.getFlowServiceList()");
  //     return this.http.get<any>(this.api.createUrl(ApiRoutes.FLOW_LIST));
  // }

  public createFlow(flow: any, eventUUID: string) {
    //this.logger.info("FlowService.createProposal() -> " + JSON.stringify(flow));
    //this.logger.info("FlowService.createProposal() -> eventUUID: " + eventUUID);
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT) + "flows/" + eventUUID, flow);
  }

  public deleteFlow(flow: any, eventUUID: string) {
    //this.logger.info("FlowService.deleteFlow() -> " + JSON.stringify(flow));
    //this.logger.info("FlowService.deleteFlow() -> eventUUID: " + eventUUID);
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.EVENT) + "flows/" + eventUUID + "/" + flow.uuid);
  }

  public updateFlow(flow: any, eventUUID: string) {
    //this.logger.info("FlowService.updateFlow() -> " + JSON.stringify(flow));
    //this.logger.info("FlowService.updateFlow() -> eventUUID: " + eventUUID);
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.EVENT) + "flows/" + eventUUID + "/" + flow.uuid, flow);
  }

  // public getEventsList()
  // {
  //     this.logger.info("EventsService.getEventsList()");
  //     return this.http.get<any>(this.api.createUrl(ApiRoutes.EVENTS_LIST));
  // }

  // public getData(): Observable<any>
  // {
  //     this.logger.info("EventsService.getData()");
  //     return this.getEventsList();
  // }

  // public getEvent(eventUUID: string)
  // {
  //     this.logger.info("OrdersService.getEvent() -> eventUUID: " + eventUUID );
  //     return this.http.get<any>(this.api.createUrl(ApiRoutes.EVENT) + eventUUID);
  // }

  // public updateEventInformation(eventUUID: string, data: any)
  // {
  //     this.logger.info("OrdersService.updateEventInformation() -> eventUUID: " + eventUUID );
  //     return this.http.patch<any>(this.api.createUrl(ApiRoutes.EVENT) + "informations/" + eventUUID, data);
  // }

  // public updateEventVenues(eventUUID: string, data: any)
  // {
  //     this.logger.info("OrdersService.updateEventVenues() -> eventUUID: " + eventUUID );
  //     return this.http.patch<any>(this.api.createUrl(ApiRoutes.EVENT) + "spot/" + eventUUID, data);
  // }
}
