<!-- Box Notes -->
<div
  class="profile-box info-box general"
  [ngClass]="
    dashboardJul == 'jul'
      ? 'dashboard-note-container-jul'
      : 'dashboard-note-container'
  "
  fxLayout="column"
  style="width: 100%"
>
  <header class="accent">
    <div class="title">{{ "GENERIC.DAY_NOTES" | translate }}</div>
  </header>

  <div class="note-section">
    <p-editor
      #noteEditor
      [formControl]="noteControl"
      (onTextChange)="onNoteChange($event)"
      [style]="{ height: '100px', width: '100%' }"
    >
      <ng-template pTemplate="header" class="" style="width: 100%">
        <!-- <span class="ql-formats">
                    <select class="ql-font"></select>
                    <select class="ql-size"></select>
                </span> -->

        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-strike"></button>
        </span>

        <span class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </span>

        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-indent" value="-1"></button>
          <button class="ql-indent" value="+1"></button>
        </span>
      </ng-template>
    </p-editor>
  </div>
</div>
