import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { AttachedFile } from "../content/events/event/event-attached-files/event-attached-files.component";
import { ApiRoutes } from "./ApiRoutes";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class EventAttachedFilesService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getFiles(eventUUID: string) {
    // this.logger.info("EventAttachedFilesService.getFiles() -> " + JSON.stringify(file));
    return this.http.get<any>(this.api.createUrl(ApiRoutes.EVENT) + eventUUID + "/files");
  }

  public addFile(file: AttachedFile) {
    // this.logger.info("EventAttachedFilesService.addFile() -> " + JSON.stringify(file));
    return this.http.post<any>(this.api.createUrl(ApiRoutes.EVENT_FILE) + "/" + file.uuid, {});
  }

  public deleteFile(file: AttachedFile) {
    // this.logger.info("EventAttachedFilesService.deleteFile() -> " + JSON.stringify(file));
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.FILES) + file.uuid);
  }
}
