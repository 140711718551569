<div
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
  id="shop-woocommerce"
>
  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          (click)="onPageBack(location, router, '/shops')"
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
            <div
              *fuseIfOnDom
              [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' },
              }"
              class="shop-title"
            >
              <div *ngIf="shop" class="">
                <span class="text-truncate"
                  >{{ "SHOPS.WOOCOMMERVE_SHOP" | translate }} -
                  {{ shop.name }}</span
                >
                <!--                            <span class="text-truncate">{{ "SHOPS.WOOCOMMERVE_SHOP" | translate}}</span>-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center">
        <button
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
        >
          <mat-icon class="s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <div class="content">
    <!-- CENTER -->
    <div #mainContainer class="center p-24 pb-56 pr-sm-72" fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="shop-content">
        <div
          *ngIf="shop"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "GENERIC.INFORMATION" | translate }}</div>
          </header>

          <div class="content" fxLayout="column">
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start start"
            >
              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "GENERIC.NAME" | translate }}</div>
                <div class="info text-truncate">{{ shop.name }}</div>
                <div *ngIf="!shop.name" class="info empty-value"></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="form" class="">
                    <mat-form-field class="form-input-container" fxFlex>
                      <mat-label>{{
                        "GENERIC.INTERNAL_NAME" | translate
                      }}</mat-label>
                      <input
                        [(ngModel)]="shop.name"
                        autocomplete="nope"
                        formControlName="name"
                        matInput
                        placeholder="{{ 'GENERIC.INTERNAL_NAME' | translate }}"
                        required
                        type="text"
                      />
                      <mat-error *ngIf="form.controls['name'].invalid"
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "SHOPS.SHOP_TYPE" | translate }}</div>
                <div class="info text-truncate">{{ shop.shop_type.name }}</div>
                <div
                  *ngIf="!shop.shop_type || !shop.shop_type.name"
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div class="title">{{ "SHOPS.SHOP_TYPE" | translate }}</div>
                  <div class="info text-truncate">
                    {{ shop.shop_type.name }}
                  </div>
                  <!--                                    <div [formGroup]="form" class="">-->
                  <!--                                        <app-gt2-select [service]="shopTypeService" [placeHolder]=" 'GENERIC.TYPE' | translate "-->
                  <!--                                                        [label]="translate.instant('SHOPS.SHOP_TYPE')"-->
                  <!--                                                        [selectedModel]="shop.shop_type"-->
                  <!--                                                        [disabled]="true"-->
                  <!--                                                        [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"-->
                  <!--                                                        [nullableUseNone]="false" [isRequired]="false" fxFlex="100%" class="px-4"></app-gt2-select>-->
                  <!--                                    </div>-->
                </div>
              </div>
            </div>
            <div
              *ngIf="editMode"
              fxFlex="100%"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <button
                (click)="autosaveDataChange({ name: shop.name })"
                class="accent mt-8"
                [disabled]="form.controls['name'].invalid"
                mat-raised-button
              >
                {{ "GENERIC.SAVE" | translate }}
              </button>
            </div>
          </div>
        </div>

        <div
          *ngIf="shop"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "SHOPS.WOOCOMMERVE_SHOP" | translate }}</div>
          </header>

          <div class="content" fxLayout="column">
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start start"
            >
              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "SHOPS.STORE_URL" | translate }}</div>
                <div
                  class="info text-truncate"
                  *ngIf="
                    shop.shop_woocommerce && shop.shop_woocommerce.store_url
                  "
                >
                  {{ shop.shop_woocommerce.store_url }}
                </div>
                <div
                  *ngIf="
                    !shop.shop_woocommerce || !shop.shop_woocommerce.store_url
                  "
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="form" class="">
                    <mat-form-field class="form-input-container" fxFlex>
                      <mat-label>{{ "SHOPS.STORE_URL" | translate }}</mat-label>
                      <input
                        [(ngModel)]="shop.shop_woocommerce.store_url"
                        autocomplete="nope"
                        formControlName="store_url"
                        matInput
                        placeholder="{{ 'SHOPS.STORE_URL' | translate }}"
                        required
                        type="text"
                      />
                      <mat-error *ngIf="form.controls['store_url'].invalid"
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "SHOPS.VERIFY_SSL" | translate }}</div>
                <div
                  class="info text-truncate"
                  *ngIf="
                    shop.shop_woocommerce &&
                    shop.shop_woocommerce.verify_ssl === 0
                  "
                >
                  {{ "GENERIC.NO" | translate }}
                </div>
                <div
                  class="info text-truncate"
                  *ngIf="
                    shop.shop_woocommerce &&
                    shop.shop_woocommerce.verify_ssl === 1
                  "
                >
                  {{ "GENERIC.YES" | translate }}
                </div>
                <div
                  *ngIf="
                    !shop.shop_woocommerce ||
                    shop.shop_woocommerce.verify_ssl === null
                  "
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div class="title">&nbsp;</div>
                  <mat-checkbox
                    (change)="onVerifySSLChange($event)"
                    [(ngModel)]="shop.shop_woocommerce.verify_ssl"
                    [ngModelOptions]="{ standalone: true }"
                    color="accent"
                    labelPosition="before"
                  >
                    {{ "SHOPS.VERIFY_SSL" | translate }}
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start start"
            >
              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">{{ "SHOPS.CONSUMER_KEY" | translate }}</div>
                <div
                  class="info text-truncate"
                  *ngIf="
                    shop.shop_woocommerce && shop.shop_woocommerce.consumer_key
                  "
                >
                  {{ shop.shop_woocommerce.consumer_key }}
                </div>
                <div
                  *ngIf="
                    !shop.shop_woocommerce ||
                    !shop.shop_woocommerce.consumer_key
                  "
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="form" class="">
                    <mat-form-field class="form-input-container" fxFlex>
                      <mat-label>{{
                        "SHOPS.CONSUMER_KEY" | translate
                      }}</mat-label>
                      <input
                        [(ngModel)]="shop.shop_woocommerce.consumer_key"
                        autocomplete="nope"
                        formControlName="consumer_key"
                        matInput
                        placeholder="{{ 'SHOPS.CONSUMER_KEY' | translate }}"
                        required
                        type="text"
                      />
                      <mat-error *ngIf="form.controls['consumer_key'].invalid"
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                <div class="title">
                  {{ "SHOPS.CONSUMER_SECRET" | translate }}
                </div>
                <div
                  class="info text-truncate"
                  *ngIf="
                    shop.shop_woocommerce &&
                    shop.shop_woocommerce.consumer_secret
                  "
                >
                  {{ shop.shop_woocommerce.consumer_secret }}
                </div>
                <div
                  *ngIf="
                    !shop.shop_woocommerce ||
                    !shop.shop_woocommerce.consumer_secret
                  "
                  class="info empty-value"
                ></div>
              </div>
              <div *ngIf="editMode" class="info-line" fxFlex="50%">
                <div class="info-editable">
                  <div [formGroup]="form" class="">
                    <mat-form-field class="form-input-container" fxFlex>
                      <mat-label>{{
                        "SHOPS.CONSUMER_SECRET" | translate
                      }}</mat-label>
                      <input
                        [(ngModel)]="shop.shop_woocommerce.consumer_secret"
                        autocomplete="nope"
                        formControlName="consumer_secret"
                        matInput
                        placeholder="{{ 'SHOPS.CONSUMER_SECRET' | translate }}"
                        required
                        type="text"
                      />
                      <mat-error
                        *ngIf="form.controls['consumer_secret'].invalid"
                        >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100%" fxLayout="row" fxLayoutAlign="center center">
              <button
                (click)="woocommerceTestConnection()"
                class="primary mt-8 mr-16"
                mat-raised-button
              >
                {{ "SHOPS.TEST_WOO_CONNECTION" | translate }}
              </button>

              <!--                            <button (click)="testProductPicker()"-->
              <!--                                    class="primary mt-8 mr-16"-->
              <!--                                    mat-raised-button>-->
              <!--                                Product Picker Dialog-->
              <!--                            </button>-->

              <!--                            <button (click)="testProductPicker2()"-->
              <!--                                    class="primary mt-8 mr-16"-->
              <!--                                    mat-raised-button>-->
              <!--                                Product Picker Sheet-->
              <!--                            </button>-->

              <button
                *ngIf="editMode"
                (click)="woocommerceSaveData()"
                class="accent mt-8"
                [disabled]="
                  form.controls['store_url'].invalid ||
                  form.controls['consumer_key'].invalid ||
                  form.controls['consumer_secret'].invalid
                "
                mat-raised-button
              >
                {{ "GENERIC.SAVE" | translate }}
              </button>
            </div>
          </div>
        </div>

        <!--                <div style="height: 400px; margin-top: 24px;">-->
        <!--                    <app-product-picker></app-product-picker>-->
        <!--                </div>-->

        <!-- / CONTENT -->
        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <div
            *ngIf="shop && editable"
            class="mt-8"
            fxFlex="100%"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <button (click)="deleteShop()" class="warn" mat-raised-button>
              {{ "GENERIC.DELETE" | translate }}
            </button>
          </div>
        </div>
      </div>
      <!-- / CENTER -->
    </div>
  </div>
</div>

<div *ngIf="shop && editable" fxLayout="row" fxLayoutAlign="end start">
  <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
    <div class="fixed-button">
      <button
        (click)="onEdit($event)"
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        [disabled]="loading"
        class="gt2-edit-button"
        id="edit-shop-button"
        mat-fab
      >
        <mat-icon *ngIf="!editMode">{{ getIcon("EDIT_FAB") }}</mat-icon>
        <mat-icon *ngIf="editMode">{{ getIcon("UNDO") }}</mat-icon>
      </button>
    </div>
  </div>
</div>

<ng-template #testConnectionTemplate let-item>
  <h1>{{ connectionTitleSuccess }}</h1>

  <div class="connection-message">{{ connectionMessageSuccess }}</div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      style="margin-top: 25px"
      class=""
    >
      {{ "GENERIC.OK" | translate }}
    </button>
  </div>
</ng-template>
