export const locale = {
  lang: "fr-CA",
  data: {
    GENERAL_CONDITIONS: {
      PROPOSAL_CONDITION: "Condition pour les commandes/propositions",
      DEPOSITE_INVOICE_CONDITION: "Condition de dépôt",
      EVENT_INVOICE_CONDITION: "Condition pour les factures d'événements",
      ADJUSMENT_INVOICE_CONDITION: "Condition d'ajustement de facture",
      CREDIT_NOTE_CONDITION: "Condition des notes de crédit",

      PROPOSAL_CONDITION_DESCRIPTION:
        "Court texte expliquant ce qu'est Proposal condition... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      DEPOSITE_INVOICE_CONDITION_DESCRIPTION:
        "Court texte expliquant ce qu'est Deposit invoice condition... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      EVENT_INVOICE_CONDITION_DESCRIPTION:
        "Court texte expliquant ce qu'est Event invoice condition... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      ADJUSMENT_INVOICE_CONDITION_DESCRIPTION:
        "Court texte expliquant ce qu'est Adjusment invoice condition... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      CREDIT_NOTE_CONDITION_DESCRIPTION:
        "Court texte expliquant ce qu'est Credit note condition... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  },
};
