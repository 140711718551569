export const locale = {
  lang: "fr-CA",
  data: {
    CREATE_PRODUCT: {
      TITLE: "Nouveau produit",
      SELECT_TYPE: "Sélectionner un type de produit",
      FOOD: "Nourriture",
      DRINK: "Boisson",
    },
  },
};
