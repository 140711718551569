import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeModelData } from '../gt2/api/models/CrudEmployeeModel';
import { ItemUrlFinder } from '../gt2/utils/item-url-finder';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FileService } from './file.service';
import { PrintService } from './print.service';
import { GuidGenerator } from '../gt2/utils/guid-generator';
import Bugsnag from '@bugsnag/js';
import {
  AngularFireDatabase,
  AngularFireList,
} from '@angular/fire/compat/database';

@Injectable()
export class GT2FirebaseService {
  static PROCESS_TYPE_DOWNLOAD = 'download';
  static PROCESS_TYPE_PRINT = 'print';
  static PROCESS_TYPE_PAGE = 'page';
  static PROCESS_TYPE_SEND_MAIL = 'sendmail';
  static PROCESS_IN_PROGRESS = 'in-progress';
  static PROCESS_COMPLETED = 'completed';
  static PROCESS_ERROR = 'error';

  static CLIENT_KEY: string | null = null;
  static CLIENT_KEY_SESSION_STORAGE_KEY = 'CLIENT_KEY_SESSION_STORAGE';

  //
  private bugSnagClient: any;

  //
  onProcesses: BehaviorSubject<any> = new BehaviorSubject([]);
  processesSubscription?: Subscription | null;
  processesRef?: AngularFireList<any> | null;
  loading = false;
  serviceKey?: string | null = null;
  processes?: any[] | null = null;
  currentEmployee: EmployeeModelData | any;
  currentOrg: any;

  constructor(
    private http: HttpClient,
    private db: AngularFireDatabase,
    public print: PrintService,
    public fileService: FileService,
    public router: Router,
    private logger: NGXLogger
  ) {
    const sessionStorageKey: string =
      sessionStorage.getItem(
        GT2FirebaseService.CLIENT_KEY_SESSION_STORAGE_KEY
      ) || '';

    // if (sessionStorageKey)
    // {
    //     this.logger.info("GT2FirebaseService.constructor() -> sessionStorageKey EXIST! ");
    //     this.serviceKey = sessionStorageKey;
    // }
    // else
    // {
    //     this.logger.info("GT2FirebaseService.constructor() -> sessionStorageKey DOES NOT EXIST! GENERATING ONE RIGHT NOW!");
    //     const guid: string = GuidGenerator.generate();
    //     sessionStorage.setItem(GT2FirebaseService.CLIENT_KEY_SESSION_STORAGE_KEY, guid);
    //     this.serviceKey = guid;
    // }

    this.serviceKey = GuidGenerator.generate();
    GT2FirebaseService.CLIENT_KEY = this.serviceKey;
    /* this.logger.info(
            "GT2FirebaseService.constructor() -> this.serviceKey: " +
                this.serviceKey
        ); */
    //
    this.bugSnagClient = Bugsnag.start(environment.bugsnag);
  }

  public reassessFirebaseConnection(): void {
    // this.logger.info("GT2FirebaseService.reassessFirebaseConnection() -> this.processes: " + JSON.stringify(this.processes));
    if (this.processes === null) {
      // this.logger.info("GT2FirebaseService.reassessFirebaseConnection() -> WARNING: calling unsubscribeProcesses() fowwlowed by setupFirebaseProcesses()");
      this.unsubscribeProcesses();
      this.setupFirebaseProcesses(this.currentEmployee, this.currentOrg);
    }
  }

  public setupFirebaseProcesses(employee: EmployeeModelData, org: any): void {
    if (!employee.user) {
      // this.logger.info("GT2FirebaseService.setupFirebaseProcesses() -> ERROR: Employee has no user data! ");
      return;
    }
    this.currentEmployee = employee;
    this.currentOrg = org;
    /* this.logger.info(
            "GT2FirebaseService.setupFirebaseProcesses() -> environment.firebaseEnvironment: " +
                environment.firebaseEnvironment
        ); */
    const processesURL: string =
      environment.firebaseEnvironment +
      '/' +
      org.slug +
      '/' +
      employee.user.uuid +
      '/processes';
    /* this.logger.info(
            "GT2FirebaseService.setupFirebaseProcesses() -> processesURL: " +
                processesURL
        ); */
    this.processesRef = this.db.list(processesURL);

    if (this.processesSubscription) {
      // this.logger.info("GT2FirebaseService.setupFirebaseProcesses() -> this.processesSubscription ALREADY EXIST! UNSUBSCRIBING!!!");
      this.processesSubscription.unsubscribe();
    }

    this.processesSubscription = this.processesRef
      .snapshotChanges()
      .subscribe((results: any) => {
        // this.logger.info(
        //     "GT2FirebaseService.setupFirebaseProcesses() -> FIREBASE!!!! snapshotChanges --> " +
        //         JSON.stringify(results)
        // );
        // @ts-ignore
        const list = results.map((c) => ({
          key: c.payload.key,
          ...c.payload.val(),
        }));
        // this.logger.info("GT2FirebaseService.setupFirebaseProcesses() -> FIREBASE!!!! list --> " + JSON.stringify(list));
        // this.logger.info("GT2FirebaseService.setupFirebaseProcesses() -> FIREBASE!!!! list.length --> " + list.length);
        this.onProcesses.next(list);
        this.processes = list;
      });

    // this.processesRef
    setTimeout(() => {
      this.reassessFirebaseConnection();
    }, 100000);
  }

  public unsubscribeProcesses(): void {
    // this.logger.info("GT2FirebaseService.unsubscribeProcesses() -> this.processesSubscription: " + this.processesSubscription);
    if (this.processesSubscription) {
      this.processesSubscription.unsubscribe();
      this.processesSubscription = null;
    }
    this.processesRef = null;
  }

  public processesCompletedActionDispatch(processes: any[]): void {
    // this.logger.info(
    //     "GT2FirebaseService.processesCompletedActionDispatch() -> processes.length: " +
    //         processes.length
    // );
    let process: any;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < processes.length; i++) {
      process = processes[i];
      // this.logger.info("GT2FirebaseService.processesCompletedActionDispatch() -> i: " + i);
      // this.logger.info("GT2FirebaseService.processesCompletedActionDispatch() -> process: " + JSON.stringify(process));
      // this.logger.info("GT2FirebaseService.processesCompletedActionDispatch() -> 1 process.type: " + process.type);

      //this.logger.info("GT2FirebaseService.processesCompletedActionDispatch() -> process.clientKey: " + process.clientKey);
      // this.logger.info("GT2FirebaseService.processesCompletedActionDispatch() -> this.serviceKey: " + this.serviceKey);
      if (!process.clientKey || process.clientKey !== this.serviceKey) {
        //this.logger.info("GT2FirebaseService.processesCompletedActionDispatch() -> NO CLIENT KEY OR NOT MY CLIENT KEY, mine: " + this.serviceKey + " - process client key: " + process.clientKey);
        continue;
      }

      // this.logger.info("---------------------");
      // this.logger.info('GT2FirebaseService.processesCompletedActionDispatch() -> 2 process.type: ' + process.type);
      // this.logger.info("GT2FirebaseService.processesCompletedActionDispatch() -> process.label: " + process.label);
      // this.logger.info("GT2FirebaseService.processesCompletedActionDispatch() -> process.url: " + process.url);
      // this.logger.info("---------------------");

      if (process.processError === true) {
        this.logger.error(
          'GT2FirebaseService.processesCompletedActionDispatch() -> ERROR: PROCESS IS SET HAS ERROR'
        );
        return;
      }

      if (process.clientProcessed === true) {
        this.logger.info(
          'GT2FirebaseService.processesCompletedActionDispatch() -> PROCESS HAS BEEN HANDLED ALREADY'
        );
        return;
      }

      if (process.type === GT2FirebaseService.PROCESS_TYPE_DOWNLOAD) {
        if (!process.url) {
          this.logger.error(
            'GT2FirebaseService.processesCompletedActionDispatch() -> ERROR: PROCESS HAS NO URL TO HANDLE, process: ' +
              JSON.stringify(process)
          );
          return;
        }

        this.fileService
          .processDownloadFile(process.url)
          .catch((error) => {
            this.logger.error(
              'GT2FirebaseService.processesCompletedActionDispatch().then() -> ERROR: ' +
                JSON.stringify(error)
            );
            this.setProcessAsError(process);
          })
          .then((response) => {
            this.logger.info(
              'GT2FirebaseService.processesCompletedActionDispatch().then() -> SUCCESS: ' +
                JSON.stringify(response)
            );
            this.finalizeProcess(process);
          });
      } else if (process.type === GT2FirebaseService.PROCESS_TYPE_PRINT) {
        if (!process.url) {
          this.logger.error(
            'GT2FirebaseService.processesCompletedActionDispatch() -> ERROR: PROCESS HAS NO URL TO HANDLE, process:' +
              JSON.stringify(process)
          );
          return;
        }

        // --o PRINT PDF
        this.logger.info(
          'GT2FirebaseService.PROCESS_TYPE_PRINT process.url: ' + process.url
        );
        this.print
          .processPrintPDF(process.url)
          .catch((error: any) => {
            this.logger.error(
              'GT2FirebaseService.processesCompletedActionDispatch().then()::processPrintPDF -> ERROR: ' +
                JSON.stringify(error)
            );
            this.setProcessAsError(process);
          })
          .then((response: any) => {
            //this.logger.info("GT2FirebaseService.processesCompletedActionDispatch().then()::processPrintPDF -> SUCCESS: " + JSON.stringify(response));
            this.finalizeProcess(process);
          });
      } else if (process.type === GT2FirebaseService.PROCESS_TYPE_PAGE) {
        if (!process.dataModel) {
          this.logger.error(
            'GT2FirebaseService.processesCompletedActionDispatch() -> ERROR: PROCESS HAS NO DATA MODEL TO HANDLE, process : ' +
              JSON.stringify(process)
          );
          // this.setProcessAsError(process);
          return;
        }

        // this.logger.info("GT2FirebaseService.processesCompletedActionDispatch() -> DATA MODEL - redirection is about to occur ");
        this.router.navigateByUrl(ItemUrlFinder.getItemURL(process.dataModel));
        this.finalizeProcess(process);
        if (process.dataModel.type === 'INVOICE') {
          console.log('Invoice is being processed');
        }
      } else if (process.type === GT2FirebaseService.PROCESS_TYPE_SEND_MAIL) {
        // this.logger.info("GT2FirebaseService.processesCompletedActionDispatch() -> PROCESS_TYPE_SEND_MAIL");
        this.finalizeProcess(process);
      }
    }
  }

  public setProcessAsError(process: any): void {
    // this.logger.info("GT2FirebaseService.setProcessAsError() -> process: " + JSON.stringify(process));
    this.processesRef?.update(process.key, { processError: true });
  }

  public finalizeProcess(process: any): void {
    this.setProcessAsProcessed(process);
    this.deleteProcess(process);
  }

  public setProcessAsProcessed(process: any): void {
    this.processesRef?.update(process.key, { clientProcessed: true });
  }

  public deleteProcess(process: any): void {
    // this.logger.info("GT2FirebaseService.deleteProcess() -> process: " + JSON.stringify(process));
    if (process.key) {
      this.processesRef?.remove(process.key);
    }
  }

  public acceptProcess(process: any): void {
    //this.logger.info("GT2FirebaseService.acceptProcess() -> process: " + JSON.stringify(process));
    //this.logger.info("GT2FirebaseService.acceptProcess() -> process.clientKey: " + process.clientKey);

    // if process already have a clientKey?? What do??
    if (!process.clientKey) {
      // this.logger.info("GT2FirebaseService.acceptProcess() -> INFO: THIS PROCESS DOES NOT HAVE A CLIENT KEY");
      const message: string =
        'GT2FirebaseService.acceptProcess() -> FAILSAFE: ASSIGNING THE CLIENT KEY TO THIS PROCESS -> clientKey: ' +
        this.serviceKey +
        '  -> process: ' +
        JSON.stringify(process);
      // this.logger.info(message);
      this.processesRef?.update(process.key, {
        clientKey: this.serviceKey,
      });
      this.bugSnagClient.notify(message);
    } else {
      //this.logger.info("GT2FirebaseService.acceptProcess() -> INFO: THIS PROCESS DOES HAVE A CLIENT KEY!");
    }
  }
}
