import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GT2PageAbstract } from '../../../../gt2/content/abstract/GT2PageAbstract';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-login-errors-dialog',
  templateUrl: './login-errors-dialog.component.html',
  styleUrls: ['./login-errors-dialog.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    TranslateModule,
    FlexLayoutModule,
    CommonModule,
    MatProgressSpinnerModule,
  ],
})
export class LoginErrorsDialogComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  loading: boolean = false;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public override dialogRef: MatDialogRef<LoginErrorsDialogComponent>,
    private logger: NGXLogger,
    // private toastr: ToastrService,
    public dialog: MatDialog,
    public translate: TranslateService,
    // private crudEmployeeService: CrudEmployeeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  // public resendInvite(uuid: string): void
  public resendInvite(): void {
    // this.loading = true;
    this.logger.debug('LoginErrorsDialogComponent.onSendInvite()');
    // this.crudEmployeeService.resendInvitation(uuid).subscribe(response => {
    //         this.logger.info("LoginErrorsDialogComponent.onSendInvite() -> SUCCESS: " + JSON.stringify(response));
    //         this.toastr.success(this.translate.instant("EMPLOYEES.INVITE_SUCCESS"));
    //     },
    //     error => {
    //         this.logger.info("LoginErrorsDialogComponent.onSendInvite() -> ERROR: " + JSON.stringify(error));
    //         this.loading = false;
    //         this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
    //         this.handleAPIError( error, this.dialog );
    //     }
    // );
  }
}
