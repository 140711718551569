//import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { EventsModule } from './../events/events.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ProjectsService } from './../../api/projects.service';
import { CongressesService } from './../../api/congresses.service';
import { FuseSidebarModule } from '../../../../@fuse/components/sidebar/sidebar.module';
import { FuseWidgetModule } from '../../../../@fuse/components';
import { EditorModule } from 'primeng/editor';
import { RouterModule } from '@angular/router';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { NgModule } from '@angular/core';
import { CongressesComponent } from './congresses.component';
import { CongresComponent } from './congres/congres.component';
import { CreateCongresDialogComponent } from './create-congres-dialog/create-congres-dialog.component';
import { ProjectBoxComponent } from './project-box/project-box.component';
import { ClientsInvoicesBoxComponent } from './clients-invoices-box/clients-invoices-box.component';
import { ProjectComponent } from './project/project.component';
import { CreateProjectModalComponent } from './create-project-modal/create-project-modal.component';
import { EventsBoxComponent } from './projet/events-box/events-box.component';
import { CreateEventProjectComponent } from './project/create-event-project/create-event-project.component';
import { ClientsBoxComponent } from './project/clients-box/clients-box.component';
import { CanDeactivateGuard } from '../../gards/can-deactivate-guard.service';
import { ReportsPerClientComponent } from './reports-per-client/reports-per-client.component';
import { ClientOrderCTStackComposeService } from '../../api/client-order-ct-stack-compose.service';
import { GroupActionComponent } from './project/group-action/group-action.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { TableSizePipeModule } from '../../modules/table-size.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { CommentSectionComponent } from '../../components/comment-section/comment-section.component';
import { Gt2SelectComponent } from '../../components/gt2-select/gt2-select.component';
import { SectionHistoryPanelComponent } from '../../components/section-history-panel/section-history-panel.component';
import { TagsComponent } from '../../components/tags/tags.component';
import { TagsByModelComponent } from '../../components/tags-by-model/tags-by-model.component';
import { ScpmPipeModule } from '../../modules/spcm.module';
import { CrudDatableGenericNoLocalComponent } from '../crud/crud-datable-generic-no-local/crud-datable-generic-no-local.component';
import { CrudDatatableGenericComponent } from '../crud/crud-datatable-generic/crud-datatable-generic.component';
import { ReportButtonComponent } from '../../reports/report-button/report-button.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.EN.CONGRESSES,
    component: CongressesComponent,
  },
  {
    path: AppRoutesName.EN.CONGRESSES + '/:slug' + '/:id',
    component: CongresComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.EN.PROJECT_ROUTE,
    component: ProjectComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.FR.CONGRESSES,
    component: CongressesComponent,
  },
  {
    path: AppRoutesName.FR.CONGRESSES + '/:slug' + '/:id',
    component: CongresComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: AppRoutesName.FR.PROJECT_ROUTE,
    component: ProjectComponent,
    canDeactivate: [CanDeactivateGuard],
  },
];

@NgModule({
  imports: [
    MatSelectModule,
    TranslateModule,
    MatProgressBarModule,
    MatInputModule,
    MatTooltipModule,
    MatTableModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatButtonModule,
    CommonModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    ScpmPipeModule,
    MatFormFieldModule,
    MatOptionModule,
    NgxPermissionsModule,
    FormsModule,
    MatPaginatorModule,
    TableSizePipeModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatCheckboxModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    EditorModule,
    FuseWidgetModule,
    Gt2SelectComponent,
    MatSortModule,
    TagsComponent,
    CdkTableModule,
    CongresComponent,
    MatRippleModule,
    TagsByModelComponent,
    FuseSidebarModule,
    SectionHistoryPanelComponent,
    CrudDatatableGenericComponent,
    CrudDatableGenericNoLocalComponent,
    NgxMaterialTimepickerModule,
    EventsModule,
    ProjectBoxComponent,
    ReportsPerClientComponent,
    ReportButtonComponent,
    CreateCongresDialogComponent,
    CreateProjectModalComponent,
    ClientsBoxComponent,
    ProjectComponent,
    CreateEventProjectComponent,
    EventsBoxComponent,
    ClientsInvoicesBoxComponent,
    GroupActionComponent,
    CommentSectionComponent,
  ],
  declarations: [CongressesComponent],
  exports: [CongressesComponent],
  providers: [
    CongressesService,
    ProjectsService,
    ClientOrderCTStackComposeService,
  ],
})
export class CongressesModule {}
