import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { ContactComponent } from './contact.component';
import { SupportContactService } from './support-contact.service';
import { AppRoutesName } from '../../const/AppRoutesName';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.FR.CONTACT,
    component: ContactComponent,
  },
  {
    path: AppRoutesName.EN.CONTACT,
    component: ContactComponent,
  },
];

@NgModule({
  declarations: [ContactComponent],
  imports: [
    GenericAlertComponent,
    MatInputModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    TranslateModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
  ],
  exports: [ContactComponent],
  providers: [SupportContactService],
})
export class ContactModule {}
