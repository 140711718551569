export const locale = {
  lang: "fr-CA",
  data: {
    COMBO: {
      TITLE: "Modifier le combo ",
      TITLE_CREATE: "Créer un combo",
      LEFT_LIST_LABEL: "Glisser et déposer ou utiliser le bouton + pour ajouter des produits",
      RIGHT_LIST_LABEL: "Réordonner et/ou enlever un produit",
      CREATE_PRODUCT: "Créer un produit",
      SEARCH: "recherche par nom interne, nom externe ou code de produit",
      SEARCH_PRODUCT: "Effectuez une recherche de votre produit.",
    },
  },
};
