<div fxFlex="100%" fxLayout="column">
  <div fxLayout="column">
    <div class="profile-box info-box" fxLayout="column">
      <header
        class="accent"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div *ngIf="!creationMode" class="title">
          {{ "ORDER_MATERIALS.TITLE" | translate }}
        </div>
        <!-- <div *ngIf="creationMode" class="title">{{'ORDER_MATERIALS.TITLE_CREATE' | translate }}</div> -->
        <div
          class="gt2-light-green"
          style="width: 60px; height: 56px"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <button
            type="button"
            matTooltip="{{ 'ORDER_MATERIALS.CREATE_PRODUCT' | translate }}"
            mat-icon-button
            class=""
            (click)="onCreateProduct()"
          >
            <mat-icon class="" style="color: black">add</mat-icon>
          </button>
        </div>
      </header>
    </div>

    <div fxLayout="column" class="px-16">
      <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="mr-4">
        <mat-label>{{
          "ORDER_MATERIALS.SEARCH" | translate | ucfirst
        }}</mat-label>
        <input #filter id="search" [(ngModel)]="filterValue" matInput />
        <button
          mat-button
          *ngIf="filterValue"
          matSuffix
          mat-icon-button
          aria-label="Search"
          (click)="loadProductList()"
        >
          <mat-icon>search</mat-icon>
        </button>
        <button
          mat-button
          *ngIf="filterValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <div fxLayout="row" style="margin-bottom: 16px">
        <div fxLayout="column" fxFlex="100%">
          <div
            fxLayout="row"
            fxLayoutAlign="center start"
            class="pb-4 mat-caption"
          >
            {{ "ORDER_MATERIALS.LEFT_LIST_LABEL" | translate }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="100%">
          <div
            fxLayout="row"
            fxLayoutAlign="center start"
            class="pb-4 mat-caption"
          >
            {{ "ORDER_MATERIALS.RIGHT_LIST_LABEL" | translate }}
          </div>
        </div>
      </div>

      <div cdkDropListGroup class="" fxLayout="row">
        <div
          #productList
          cdkDropList
          [cdkDropListData]="materials"
          class="ngx-dnd-container-custom mr-4 ngx-dnd-scrollable"
          ngxDroppable
          fxLayout="column"
          fxFlex="50%"
          style="overflow: auto; height: 65vh"
        >
          <div
            *ngIf="isLoading"
            fxLayout="column"
            fxFlex="100%"
            fxLayoutAlign="center center"
          >
            <mat-spinner [diameter]="28" class=""></mat-spinner>
          </div>

          <div
            *ngIf="isEmpty"
            fxLayout="column"
            fxFlex="100%"
            fxLayoutAlign="center center"
          >
            {{ "ORDER_MATERIALS.SEARCH_MATERIAL" | translate }}
          </div>

          <!-- <div *ngIf="!isLoading"> -->
          <div
            ngxDraggable="['example-target']"
            *ngFor="let item of materials; let i = index"
          >
            <!-- <div *ngIf="(filterValue === '' || item.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1)"
                            matRipple class="product-child-item" fxLayout="row" fxLayoutAlign="space-between center"> -->
            <div
              *ngIf="!isLoading"
              matRipple
              [cdkDragData]="item"
              cdkDrag
              class="product-child-item"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div fxFlex="85%" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="mr-8">drag_handle</mat-icon>
                <!--                                    <div matTooltip="{{item.name + ' | ' + item.price_label}}" [matTooltipPosition]="'right'">{{item.name_internal}}</div>-->
                <div fxLayout="column" fxLayoutAlign="start start">
                  <div class="item-name-internal">
                    {{ item.name_internal }}
                  </div>
                  <div class="item-name">
                    {{
                      item.name +
                        " | " +
                        item.price_label +
                        (item.is_combo
                          ? " | " + ("GENERIC.MATERIAL_COMBO" | translate)
                          : "")
                    }}
                  </div>
                </div>
              </div>
              <div fxFlex="15%" fxLayoutAlign="end center">
                <button mat-icon-button (click)="onAddItem(item, true)">
                  <mat-icon class="" style="color: black">add</mat-icon>
                </button>

                <button
                  mat-button
                  matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                  (click)="onOpenInNewTab(item)"
                  class="mat-icon-button"
                >
                  <mat-icon class="s-20">{{ getIcon("NEW_TAB") }}</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>

        <div
          #comboList
          cdkDropList
          [cdkDropListData]="combo"
          (cdkDropListDropped)="onDrop($event)"
          class="ngx-dnd-container-custom ml-4 combo-list ngx-dnd-scrollable"
          ngxDroppable="example-target"
          style="height: 65vh; overflow: auto"
          fxLayout="column"
          fxFlex="50%"
        >
          @for (comb of combo; track comb; let i = $index) {
            <div matRipple [cdkDragData]="comb" cdkDrag>
              <div
                class="product-child-item-right"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon cdkDragHandle class="mr-8">drag_handle</mat-icon>
                  <mat-form-field class="form-input-container" fxFlex="70px">
                    <input
                      matInput
                      class="text-center"
                      type="text"
                      autocomplete="nope"
                      pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                      [ngModel]="comb.qty"
                      (ngModelChange)="onItemQtyChange(comb)"
                      style="text-align: center"
                    />
                  </mat-form-field>
                  <div
                    matTooltip="{{ comb.name }}"
                    [matTooltipPosition]="'right'"
                    class="ml-16"
                  >
                    {{ comb.name_internal }}
                  </div>
                </div>
              </div>

              <div fxFlex="98px">
                <mat-icon (click)="onAddItem(comb, true)" class="ml-8"
                  >add</mat-icon
                >
                <mat-icon (click)="onLessItem(comb, i)" class="ml-8"
                  >remove</mat-icon
                >
                <mat-icon (click)="onRemoveItem(comb, i)" class="ml-8"
                  >delete</mat-icon
                >
              </div>
            </div>
          }
        </div>
      </div>

      <div fxLayout="row">
        <div fxLayout="row" fxFlex="50%">&nbsp;</div>

        <div fxLayout="row" fxLayoutAlign="end start" fxFlex="50%">
          <div *ngIf="combo && combo.length > 0" class="pr-4">
            {{ combo.length }}
            {{ combo && combo.length > 1 ? "items" : "item" }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    mat-dialog-actions
    class="p-16"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button mat-raised-button (click)="onSaveProducts()" class="accent mr-16">
      {{ "GENERIC.SAVE" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      class=""
    >
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
