import { ApiRoutes } from '../../api/ApiRoutes';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class PictureUploadService {
  // products: any[];
  // onProductsChanged: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(private http: HttpClient, private api: Gt2ApiService) {}

  public uploadPicture(uploadURL: string, formData: any): any {
    return this.http.post<any>(uploadURL, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  public deletePicture(uuid: string): any {
    //  /v1/medias/{uuid}/
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.PICTURE_DELETE) + uuid
    );
    // return this.http.get()
  }
}
