<div
  id="client"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <!-- HEADER -->

  <div class="gt2-theme">
    <div
      class="header accent pl-24 pr-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
          (click)="onPageBack(location, router, '/clients')"
        >
          <mat-icon class="icon-mat">arrow_back</mat-icon>
        </button>

        <div fxLayout="column" fxLayoutAlign="center start">
          <div *ngIf="client" class="h1">
            {{ "GENERIC.CLIENT" | translate }} -
            {{ client.full_name }}
          </div>
          <div *ngIf="client && client.enterprise">
            {{ "GENERIC.ENTERPRISE" | translate }}:
            {{ client.enterprise.name }}
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="icon-mat s-30">{{ getIcon("NEW_TAB") }}</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- / HEADER -->

  <!-- SUB HEADER -->

  <div
    *ngIf="client"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="primary mat-elevation-z12 py-8 gt2-sub-header content-subheader"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-button
        *ngIf="client && client.enterprise"
        (click)="onVisitEnterprise()"
        class="gt2-sub-header-button"
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("ENTERPRISE") }}</mat-icon>
        <span class="icon-mat ml-8">{{
          "CLIENTS.VISIT_ENTERPRISE" | translate
        }}</span>
      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <app-create-order-buttons
        *ngIf="
          moduleService.modulesAreSet &&
          !moduleService.hasCTReport() &&
          !moduleService.hasRPReport()
        "
        [(clientUUID)]="client.uuid"
      ></app-create-order-buttons>
      <app-create-congresses-button
        *ngIf="
          moduleService.modulesAreSet &&
          (moduleService.hasCTReport() || moduleService.hasRPReport())
        "
      ></app-create-congresses-button>

      <button
        mat-button
        *ngIf="client && !client.enterprise"
        (click)="onAttachEnterprise()"
        class="gt2-sub-header-button"
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("ENTERPRISE") }}</mat-icon>
        <span class="icon-mat ml-8">
          {{ "CLIENTS.ATTACH_ENTERPRISE" | translate }}
        </span>
      </button>

      <button
        mat-button
        *ngIf="client && !client.enterprise"
        (click)="onAddEnterprise()"
        class="gt2-sub-header-button"
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("ENTERPRISE") }}</mat-icon>
        <span class="icon-mat ml-8">{{
          "CLIENTS.ADD_ENTERPRISE" | translate
        }}</span>
      </button>
    </div>
  </div>
  <!-- / SUB HEADER -->

  <div
    *ngIf="client && !editable"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="height: 38px; min-height: 38px; max-height: 38px"
    class="gt2-gray mat-elevation-z1 py-8"
  >
    <div class="text-white font-weight-600">
      <b>{{ "GENERIC.READ_ONLY_MODE" | translate }}</b> :
      {{ "GENERIC.ARCHIVE_PAGE" | translate }}
    </div>
  </div>

  <!--HISTORY SIDEBAR-->
  <div *ngIf="!isSmallScreen" class="container-history-panel">
    <fuse-sidebar
      *ngIf="clientID"
      name="clientHistoryPanel"
      position="right"
      class="section-history-panel"
    >
      <app-section-history-panel
        *ngIf="client"
        panelName="clientHistoryPanel"
        section="clients"
        sectionUUID="{{ clientID }}"
      >
      </app-section-history-panel>
    </fuse-sidebar>
  </div>
  <!-- / HISTORY SIDEBAR-->

  <div class="content">
    <!--HISTORY SIDEBAR BUTTON-->
    <div *ngIf="!isSmallScreen">
      <!-- <div *ngIf="editable"> -->
      <button
        mat-icon-button
        class="warn mat-elevation-z2 historic-button"
        *fuseIfOnDom
        [@animate]="{
          value: '*',
          params: { delay: '300ms', scale: '.2' },
        }"
        (click)="toggleHistoricSidebarOpen('clientHistoryPanel')"
      >
        <mat-icon class="icon-mat">history</mat-icon>
      </button>
      <!-- </div> -->
    </div>
    <!-- /HISTORY SIDEBAR BUTTON-->

    <!-- CENTER -->
    <div class="center p-24 pb-56 pr-sm-72" fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="client-content">
        <div fxLayout="row" fxLayoutAlign="start end">
          <app-tags-by-model
            #tags
            fxFlex="100%"
            class="ml-4"
            [tagPlaceholder]="'GENERIC.NEW_TAG' | translate"
            [tagFamily]="[
              'tags-language',
              'tags-recurrent-event',
              'tags-client-crm',
              'tags-client-type',
              'tags-client-custom',
            ]"
            [customTagFamily]="'tags-client-custom'"
            [tagModel]="'client'"
            [tagModelUUID]="clientID"
          ></app-tags-by-model>
        </div>

        <!--  DO NOT DELETE COMMENTED WIDGETS BELOW  -->

        <!--<div *ngIf="client && client.stats">-->
        <!--<div class="widget-group" fxLayout="row" fxLayoutAlign="space-around start" fxFlex="100"-->
        <!--fxLayoutWrap *fuseIfOnDom>-->
        <!--<fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"-->
        <!--fxFlex="33" fxFlex.gt-xs="33" fxFlex.gt-md="33">-->
        <!--&lt;!&ndash; Front &ndash;&gt;-->
        <!--<div class="fuse-widget-front mat-white-bg mat-elevation-z2">-->
        <!--<div class="pl-16 pr-8 py-16 h-52" fxLayout="row"-->
        <!--fxLayoutAlign="space-between center">-->
        <!--<div class="h3">{{client.stats[0].title}}</div>-->
        <!--</div>-->

        <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
        <!--<div class="red-fg font-size-36 line-height-36 text-center">-->
        <!--{{client.stats[0].value}}-->
        <!--</div>-->
        <!--<div class="h3 secondary-text font-weight-500">{{client.stats[0].label}}-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--&lt;!&ndash; / Front &ndash;&gt;-->
        <!--</fuse-widget>-->

        <!--<fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"-->
        <!--fxFlex="33" fxFlex.gt-xs="33" fxFlex.gt-md="33">-->
        <!--&lt;!&ndash; Front &ndash;&gt;-->
        <!--<div class="fuse-widget-front mat-white-bg mat-elevation-z2">-->
        <!--<div class="pl-16 pr-8 py-16 h-52" fxLayout="row"-->
        <!--fxLayoutAlign="space-between center">-->
        <!--<div class="h3">{{client.stats[1].title}}</div>-->

        <!--</div>-->

        <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
        <!--<div class="red-fg font-size-36 line-height-36 text-center">-->
        <!--{{client.stats[1].value}}-->
        <!--</div>-->
        <!--<div class="h3 secondary-text font-weight-500">{{client.stats[1].label}}-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--&lt;!&ndash; / Front &ndash;&gt;-->
        <!--</fuse-widget>-->

        <!--<fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"-->
        <!--fxFlex="33" fxFlex.gt-xs="33" fxFlex.gt-md="33">-->
        <!--&lt;!&ndash; Front &ndash;&gt;-->
        <!--<div class="fuse-widget-front mat-white-bg mat-elevation-z2">-->
        <!--<div class="pl-16 pr-8 py-16 h-52" fxLayout="row"-->
        <!--fxLayoutAlign="space-between center">-->
        <!--<div class="h3">{{client.stats[2].title}}</div>-->
        <!--</div>-->

        <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
        <!--<div class="red-fg font-size-36 line-height-36 text-center">-->
        <!--{{client.stats[2].value}}-->
        <!--</div>-->
        <!--<div class="h3 secondary-text font-weight-500">{{client.stats[2].label}}-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--&lt;!&ndash; / Front &ndash;&gt;-->
        <!--</fuse-widget>-->

        <!--</div>-->
        <!--</div>-->

        <div
          *ngIf="client"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">{{ "GENERIC.INFORMATION" | translate }}</div>
          </header>
          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            #clientInfoMatTabGroup
            dynamicHeight
          >
            <mat-tab label="{{ 'CLIENTS.GENERAL' | translate }}">
              <div class="content" fxLayout="column">
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.FIRST_NAME" | translate }}
                    </div>
                    <div class="info">
                      {{ client.first_name }}
                    </div>
                    <div
                      *ngIf="!client.first_name"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "GENERIC.FIRST_NAME" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="first_name"
                            type="text"
                            [(ngModel)]="client.first_name"
                            required
                          />
                          <mat-error *ngIf="form.controls['first_name'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "GENERIC.LAST_NAME" | translate }}
                    </div>
                    <div class="info">
                      {{ client.last_name }}
                    </div>
                    <div
                      *ngIf="!client.last_name"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "GENERIC.LAST_NAME" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="last_name"
                            type="text"
                            [(ngModel)]="client.last_name"
                            required
                          />
                          <mat-error *ngIf="form.controls['last_name'].invalid"
                            >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "CLIENTS.SALUTATION" | translate }}
                    </div>
                    <div *ngIf="client.personal_title" class="info">
                      {{ client.personal_title.name }}
                    </div>
                    <div
                      *ngIf="!client.personal_title"
                      class="info empty-value"
                    ></div>
                  </div>

                  <div *ngIf="editMode" class="info-line" fxFlex="100%">
                    <div class="">
                      <app-gt2-select
                        [service]="personnalTitlesService"
                        [placeHolder]="'CLIENTS.SELECT_TITLE' | translate"
                        [label]="'Personnal title'"
                        [selectedModel]="client.personal_title"
                        (changeEvent)="onPersonnalTitleSelected($event)"
                        [noResultsLabel]="
                          translate.instant('GENERIC.NO_RESULTS')
                        "
                        [nullableUseNone]="false"
                        class=""
                      ></app-gt2-select>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "CLIENTS.CLIENT_CATEGORIES" | translate }}
                    </div>
                    <div *ngIf="client.client_category" class="info">
                      {{ client.client_category.name }}
                    </div>
                    <div
                      *ngIf="!client.client_category"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <app-gt2-select
                      [service]="clientsCategoriesService"
                      [placeHolder]="'CLIENTS.CLIENT_CATEGORIES' | translate"
                      [label]="'CLIENTS.CLIENT_CATEGORIES' | translate"
                      [selectedModel]="client.client_category"
                      (changeEvent)="onClientCategoryChange($event)"
                      [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
                      [nullableUseNone]="false"
                      class=""
                    ></app-gt2-select>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">
                      {{ "CLIENTS.COMM_LANGUAGE" | translate }}
                    </div>
                    <div class="info">
                      {{ client.communication_local.name | translate }}
                    </div>
                    <div
                      *ngIf="!client.communication_local"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <app-gt2-select
                          #wrapping
                          [service]="gt2LocalService"
                          [nullableUseNone]="false"
                          [placeHolder]="'CLIENTS.COMM_LANGUAGE' | translate"
                          [label]="'CLIENTS.COMM_LANGUAGE' | translate"
                          [selectedModel]="client.communication_local"
                          (changeEvent)="onLocalSelected($event)"
                          [noResultsLabel]="
                            translate.instant('GENERIC.NO_RESULTS')
                          "
                          fxFlex="100%"
                          class="pr-4"
                        ></app-gt2-select>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
                    <div class="title">
                      {{ "CLIENTS.CLIENT_NUMBER" | translate }}
                    </div>
                    <div class="info">
                      {{ client.client_number }}
                    </div>
                    <div
                      *ngIf="!client.client_number"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <mat-form-field
                          class="form-input-container"
                          fxFlex="100%"
                        >
                          <mat-label>{{
                            "CLIENTS.CLIENT_NUMBER" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="client_number"
                            type="text"
                            [(ngModel)]="client.client_number"
                            required
                          />
                          <mat-error
                            *ngIf="form.controls['client_number'].invalid"
                            >{{
                              "GENERIC.MODEL_NUMBER_INPUT_LENGTH_INVALID"
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutAlign="start start"
                >
                  <div *ngIf="!editMode" class="info-line" fxFlex="50%">
                    <div class="title">
                      {{ "CLIENTS.PAYMENT_DEADLINES" | translate }}
                    </div>
                    <div class="info">
                      {{ client.payment_deadline.name | translate }}
                    </div>
                    <div
                      *ngIf="!client.payment_deadline"
                      class="info empty-value"
                    ></div>
                  </div>
                  <div *ngIf="editMode" class="info-line" fxFlex="50%">
                    <div class="info-editable">
                      <div [formGroup]="form" class="">
                        <app-gt2-select
                          #wrapping
                          [service]="paymentDealinesService"
                          [nullableUseNone]="false"
                          [placeHolder]="
                            'CLIENTS.PAYMENT_DEADLINES' | translate
                          "
                          [label]="'CLIENTS.PAYMENT_DEADLINES' | translate"
                          [selectedModel]="client.payment_deadline"
                          (changeEvent)="onPaymentDealinesSelected($event)"
                          [noResultsLabel]="
                            translate.instant('GENERIC.NO_RESULTS')
                          "
                          fxFlex="100%"
                          class="pr-4"
                        ></app-gt2-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab
              [disabled]="!client || !client.enterprise"
              label="{{ 'GENERIC.ENTERPRISE' | translate }}"
            >
              <div
                *ngIf="client && client.enterprise"
                class=""
                fxLayout="column"
              >
                <app-collection-table
                  [collectionData]="[client.enterprise]"
                  [displayedColumns]="['name']"
                  [dynamicTableItemAction]="enterprisesTableItemAction"
                  [dynamicTableItemActionEnd]="enterprisesTableItemActionEnd"
                >
                </app-collection-table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!--<div *ngIf="client" class="profile-box info-box general" fxLayout="column">-->
        <!--<header class="accent">-->
        <!--<div class="title">{{ 'GENERIC.ORDERS' | translate }}</div>-->
        <!--</header>-->

        <!--<div class="content" fxLayout="column">-->
        <!--&lt;!&ndash;TODO: COMMAND CONTENTS? WHAT SHOULD GO IN IT&ndash;&gt;-->
        <!--</div>-->
        <!--</div>-->

        <div
          class="profile-box info-box general mt-12"
          style="min-width: 1023px"
          fxLayout="column"
        >
          <header
            class="accent"
            fxFlex="100"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div class="title">
              {{ "GENERIC.EVENTS" | translate }}
            </div>
            <!-- <button mat-raised-button color='primary' class="mr-12">
                            {{ 'CLIENTS.GENERATE_ACCOUNT_STATEMENT' | translate}}
                        </button> -->

            <!-- INVOICE REPORT MENU -->
            <div *ngxPermissionsOnly="rolesService.ACCOUNTANT_ROLES">
              <div
                *ngIf="
                  dataSource && dataSource.data && dataSource.data.length > 0
                "
              >
                <button
                  type="button"
                  mat-icon-button
                  class=""
                  matTooltip="{{
                    'REPORTS_HELP.REPORT_CLIENT_STATEMENT_CLIENT' | translate
                  }}"
                >
                  <mat-icon class="icon-mat s-20" color="">help</mat-icon>
                </button>
                <div
                  [formGroup]="formDates"
                  fxFlex
                  fxLayout="row"
                  fxLayoutAlign="end center"
                  class="pl-24 pr-24"
                >
                  <mat-form-field
                    class="custom-date-picker mr-24"
                    style="margin-bottom: -25px"
                  >
                    <mat-label>{{ "GENERIC.START" | translate }}</mat-label>
                    <input
                      matInput
                      formControlName="date_from1"
                      [matDatepicker]="eventDateFrom1"
                      min="1900-01-01"
                      max="2099-01-01"
                      (ngModelChange)="onDateFromChange1($event)"
                      [(ngModel)]="dateRange1.date_from"
                      class=""
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="eventDateFrom1"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #eventDateFrom1></mat-datepicker>

                    <mat-error *ngIf="formDates.controls['date_from1'].invalid"
                      >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field
                    class="custom-date-picker"
                    style="margin-bottom: -25px"
                  >
                    <mat-label>{{ "GENERIC.END" | translate }}</mat-label>
                    <input
                      matInput
                      formControlName="date_to1"
                      [matDatepicker]="eventDateTo1"
                      min="1900-01-01"
                      max="2099-01-01"
                      (ngModelChange)="onDateToChange1($event)"
                      [(ngModel)]="dateRange1.date_to"
                      class=""
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="eventDateTo1"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #eventDateTo1></mat-datepicker>

                    <mat-error *ngIf="formDates.controls['date_to1'].invalid"
                      >{{ "GENERIC.DATE_DEFAULT_RANGE_INVALID" | translate }}
                    </mat-error>
                  </mat-form-field>

                  <div class="ml-16 mb-4">
                    <button
                      mat-icon-button
                      class=""
                      [matMenuTriggerFor]="menuFrom1"
                    >
                      <mat-icon class="icon-mat">more</mat-icon>
                    </button>
                  </div>
                  <mat-menu #menuFrom1="matMenu">
                    <button mat-menu-item (click)="onSelectToday1()">
                      {{ "GENERIC.TODAY" | translate }}
                    </button>
                    <button mat-menu-item (click)="onSelectThisWeek1()">
                      {{ "GENERIC.WEEK" | translate }}
                    </button>
                    <button mat-menu-item (click)="onSelectThisMonth1()">
                      {{ "GENERIC.MONTH" | translate }}
                    </button>
                  </mat-menu>
                </div>
                <!-- Account statement report start -->
                <button
                  mat-raised-button
                  color="primary"
                  [matMenuTriggerFor]="eventsReportMenu"
                >
                  <mat-icon class="icon-mat s-28">{{
                    getIcon("REPORT")
                  }}</mat-icon>
                  <div class="icon-mat">
                    {{ "CLIENTS.GENERATE_ACCOUNT_STATEMENT" | translate }}
                  </div>
                </button>
                <mat-menu #eventsReportMenu="matMenu">
                  <button
                    mat-menu-item
                    [matMenuTriggerFor]="eventsReportMenuNormalMode"
                  >
                    {{ "GENERIC.REPORT_MODE_NORMAL" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    [matMenuTriggerFor]="eventsReportMenuToDateNowMode"
                  >
                    {{ "GENERIC.REPORT_MODE_DATE_NOW" | translate }}
                  </button>
                </mat-menu>
                <mat-menu #eventsReportMenuNormalMode="matMenu">
                  <button
                    mat-menu-item
                    *ngIf="fileService.printEnabled()"
                    (click)="onPrintEventsReport('normal')"
                  >
                    {{ "GENERIC.PRINT" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onDowloadEventsReport('normal')"
                  >
                    {{ "GENERIC.DOWNLOAD" | translate }}
                  </button>
                  <button mat-menu-item (click)="onSendEventsReport('normal')">
                    {{ "GENERIC.SEND" | translate }}
                  </button>
                </mat-menu>
                <mat-menu #eventsReportMenuToDateNowMode="matMenu">
                  <button
                    mat-menu-item
                    *ngIf="fileService.printEnabled()"
                    (click)="onPrintEventsReport('date_now')"
                  >
                    {{ "GENERIC.PRINT" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onDowloadEventsReport('date_now')"
                  >
                    {{ "GENERIC.DOWNLOAD" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="onSendEventsReport('date_now')"
                  >
                    {{ "GENERIC.SEND" | translate }}
                  </button>
                </mat-menu>
                <!-- Account statement report end -->
              </div>
            </div>
          </header>

          <div class="" fxLayout="column">
            <div
              *ngIf="
                !isLoadingResults &&
                dataSource &&
                dataSource.data &&
                dataSource.data.length === 0
              "
              fxLayout="row"
              fxLayoutAlign="center center"
              class="my-16 font-size-20 font-weight-500"
            >
              {{ "GENERIC.NO_RESULTS" | translate }}
            </div>

            <div
              *ngIf="isLoadingResults"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <mat-spinner [diameter]="26" class="my-12"></mat-spinner>
            </div>

            <mat-table
              #table
              class=""
              matSort
              matSortStart="desc"
              [@animateStagger]="{ value: '50' }"
              [ngStyle]="{
                display:
                  dataSource && dataSource.data && dataSource.data.length === 0
                    ? 'none'
                    : '',
              }"
              [dataSource]="dataSource"
            >
              <!-- Name Column -->
              <ng-container cdkColumnDef="name">
                <mat-header-cell fxFlex="40" *cdkHeaderCellDef mat-sort-header
                  >{{ "GENERIC.NAME" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="40" *cdkCellDef="let row">
                  <div>
                    {{ row.name_label }}
                  </div>
                </mat-cell>
              </ng-container>

              <!-- event_number Column -->
              <ng-container cdkColumnDef="event_number">
                <mat-header-cell fxFlex="14" *cdkHeaderCellDef mat-sort-header
                  >{{ "CLIENTS.EVENT_NUMBER" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="14" *cdkCellDef="let row">
                  <div>
                    {{ row.event_number }}
                  </div>
                </mat-cell>
              </ng-container>

              <!-- event_date Column -->
              <ng-container cdkColumnDef="event_date">
                <mat-header-cell fxFlex="14" *cdkHeaderCellDef mat-sort-header
                  >{{ "CLIENTS.EVENT_DATE" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="14" *cdkCellDef="let row">
                  <div>
                    {{ row.event_date_label }}
                  </div>
                </mat-cell>
              </ng-container>

              <!-- status Column -->
              <ng-container cdkColumnDef="status">
                <mat-header-cell fxFlex="14" *cdkHeaderCellDef mat-sort-header
                  >{{ "GENERIC.STATUS" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="14" *cdkCellDef="let row">
                  <div>
                    {{ row.status_label }}
                  </div>
                </mat-cell>
              </ng-container>

              <!-- Shop name Column -->
              <ng-container cdkColumnDef="shop_name">
                <mat-header-cell fxFlex="18" *cdkHeaderCellDef mat-sort-header
                  >{{ "CLIENTS.SHOP_NAME" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="18" *cdkCellDef="let row">
                  <div>
                    {{ row.shop.name }}
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="btn">
                <mat-header-cell fxFlex="100px" *cdkHeaderCellDef>
                </mat-header-cell>
                <mat-cell fxFlex="100px" *cdkCellDef="let row">
                  <mat-icon
                    [ngStyle]="{
                      visibility: row.congress_uuid ? '' : 'hidden',
                    }"
                    class="icon-mat s-24 mr-12"
                    >{{ getIcon("CONGRESS") }}</mat-icon
                  >
                  <button
                    mat-button
                    matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
                    (click)="onOpenEventInNewTab(row); $event.stopPropagation()"
                    class="mat-icon-button"
                  >
                    <mat-icon class="icon-mat s-24">{{
                      getIcon("NEW_TAB")
                    }}</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row
                *cdkHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *cdkRowDef="let row; columns: displayedColumns"
                (click)="onNavigateToItem(row)"
                matRipple
                [@animate]="{
                  value: '*',
                  params: { y: '100%' },
                }"
              >
              </mat-row>
            </mat-table>
            <mat-paginator
              #paginator
              [length]="resultsLength"
              (page)="datatableHelperService.onPaginator($event, 'client')"
              [ngStyle]="{
                display:
                  dataSource.data && dataSource.data.length === 0 ? 'none' : '',
              }"
              showFirstLastButtons="true"
              [pageIndex]="0"
              [pageSize]="'client' | tableSizePipe: 'short'"
              [pageSizeOptions]="datatableHelperService.pageSizeMedium2"
            >
            </mat-paginator>
          </div>
        </div>

        <div
          *ngIf="client && client.contact"
          class="profile-box info-box general mt-12"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">
              {{ "GENERIC.CONTACT" | translate }}
            </div>
          </header>

          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
          >
            <mat-tab label="{{ 'CLIENTS.GENERAL' | translate }}">
              <div class="">
                <app-contact
                  #contactComponent
                  [groupModel]="client.contact"
                  [editMode]="false"
                  [useAutosave]="false"
                  [hideSaveButton]="true"
                  class=""
                >
                </app-contact>
              </div>
            </mat-tab>

            <mat-tab label="{{ 'GENERIC.ADDRESS' | translate }}">
              <div class="">
                <app-address
                  #addressComponent
                  [groupModel]="client.address"
                  [isVisible]="true"
                  [editMode]="false"
                  [hideSaveButton]="true"
                  class=""
                ></app-address>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!-- SHOP USER -->
        <div
          *ngIf="client && client.shop_user"
          class="profile-box info-box general mt-12"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">
              {{ "CLIENTS.SHOP_USER_LABEL" | translate }}
            </div>
          </header>

          <mat-tab-group
            animationDuration="100ms"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            class="shop-user"
          >
            <mat-tab label="{{ 'CLIENTS.GENERAL' | translate }}" class="">
              <div fxLayout="row" class="p-16">
                <div fxFlex="200px" class="mr-24">
                  <img
                    *ngIf="client.shop_user.avatar"
                    src="{{ client.shop_user.avatar }}"
                    class="user-avatar"
                  />
                </div>

                <div fxLayout="column" fxFlex>
                  <div class="info-line">
                    <div class="title">
                      {{ "GENERIC.EMAIL" | translate }}
                    </div>
                    <div *ngIf="client.shop_user.email" class="info">
                      <a href="{{ 'mailto:' + client.shop_user.email }}">{{
                        client.shop_user.email
                      }}</a>
                    </div>
                    <div
                      *ngIf="!client.shop_user.email"
                      class="info empty-value"
                    ></div>
                  </div>

                  <div class="info-line">
                    <div class="title">
                      {{ "CLIENTS.IS_CORPO" | translate }}
                    </div>
                    <div *ngIf="client.shop_user.is_corpo" class="info">
                      {{ "GENERIC.YES" | translate }}
                    </div>
                    <div *ngIf="!client.shop_user.is_corpo" class="info">
                      {{ "GENERIC.NO" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="{{ 'CLIENTS.CONTACT' | translate }}">
              <div class="">
                <app-contact
                  #shopUserContactComponent
                  [groupModel]="client.shop_user.contact"
                  [editMode]="false"
                  [useAutosave]="true"
                  class=""
                >
                </app-contact>
              </div>
            </mat-tab>

            <mat-tab label="{{ 'GENERIC.ADDRESSES' | translate }}">
              <div class="" fusePerfectScrollbar>
                <div
                  *ngFor="let address of client.shop_user.addresses"
                  class="address-item"
                >
                  <div class="address-item-content">
                    <div class="ml-16 pt-12 address-item-title">
                      {{ address.name }}
                    </div>
                  </div>
                  <app-address
                    #userAddressComponent
                    [groupModel]="address"
                    [isVisible]="true"
                    [editMode]="editMode && isCTEditable"
                    [useAutosave]="false"
                    class=""
                  ></app-address>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div
          *ngIf="client"
          class="profile-box info-box general"
          fxLayout="column"
        >
          <header class="accent">
            <div class="title">
              {{ "GENERIC.NOTES" | translate }}
            </div>
          </header>

          <div class="note-section">
            <p-editor
              #noteEditor
              [(ngModel)]="client.note"
              [readonly]="!editMode"
              [style]="{
                height: '120px',
                width: '100%',
                'background-color': editMode ? '#ffffff' : '',
              }"
            >
              <ng-template pTemplate="header" class="">
                <span class="ql-formats">
                  <select class="ql-font"></select>
                  <select class="ql-size"></select>
                </span>

                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>

                <span class="ql-formats">
                  <select class="ql-color"></select>
                  <select class="ql-background"></select>
                </span>

                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                </span>
              </ng-template>
            </p-editor>
            <!--</form>-->
            <mat-error
              *ngIf="noteInvalid"
              fxLayout="row"
              fxLayoutAlign="end center"
              class="p-4 pr-8"
            >
              {{ "GENERIC.NOTE_EDITOR_LENGTH_INVALID" | translate }}
            </mat-error>
          </div>
        </div>

        <app-comment-section
          *ngIf="client"
          class="ml-12 mr-12"
          [commentsModel]="client"
          [disabled]="!editable"
          [serviceAPIUrl]="'/v1/clients/comments/' + client.uuid"
        >
        </app-comment-section>

        <app-created-updated-at
          *ngIf="client"
          class="mt-8 ml-12 mr-12"
          [historicModel]="client.historic_model"
        ></app-created-updated-at>

        <div *ngxPermissionsOnly="rolesService.RH_ROLES">
          <div
            *ngIf="
              client &&
              moduleService.modulesAreSet &&
              (moduleService.hasBTFReport() ||
                moduleService.hasBistroVReport() ||
                moduleService.hasSOTReport())
            "
            fxFlex="100%"
            fxLayout="row"
            fxLayoutAlign="center center"
            class="mt-8"
          >
            <button mat-raised-button (click)="deleteClient()" class="warn">
              {{ "GENERIC.DELETE" | translate }}
            </button>
          </div>
        </div>
      </div>
      <!-- / CONTENT -->
    </div>
    <!-- / CENTER -->
  </div>
</div>

<div *ngIf="client && showEditButton" fxLayout="row" fxLayoutAlign="end start">
  <div *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES">
    <div class="fixed-button">
      <!-- <button mat-fab class="gt2-edit-button" id="edit-client-button" (click)="onEdit($event)" [disabled]="loading"
                aria-label="Edit" *fuseIfOnDom [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
                <mat-icon *ngIf="!editMode">{{ getIcon('EDIT_FAB') }}</mat-icon>
                <mat-icon *ngIf="editMode">{{ getIcon('UNDO') }}</mat-icon>
            </button> -->

      <button
        mat-fab
        #saveButton
        [ngClass]="editMode ? 'gt2-save-button' : 'gt2-edit-button'"
        id="edit-client-button"
        (click)="onEdit($event)"
        [disabled]="
          loading ||
          (editMode && (!form.valid || !contactComponent.form.valid)) ||
          !editable
        "
        *fuseIfOnDom
        [@animate]="{
          value: '*',
          params: { delay: '300ms', scale: '.2' },
        }"
      >
        <mat-icon *ngIf="!editMode && !loading" class="icon-mat-black">{{
          getIcon("EDIT_FAB")
        }}</mat-icon>
        <mat-icon
          *ngIf="editMode && !loading && !saveButton.disabled"
          matTooltip="{{ 'GENERIC.SAVE' | translate }}"
          position="left"
          showDelay="900"
          class="icon-mat-black"
          hideDelay="900"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <mat-icon
          *ngIf="editMode && !loading && saveButton.disabled"
          matTooltip="{{ 'GENERIC.FORM_DIRTY_TIP' | translate }}"
          position="left"
          hideDelay="900"
          class="icon-mat"
          >{{ getIcon("SAVE") }}</mat-icon
        >
        <i [ngClass]="loading ? 'fa fa-spinner fa-spin' : ''"></i>
      </button>
    </div>

    <div class="fixed-button2">
      <button
        mat-fab
        *ngIf="editMode && editable"
        id="cancel-edit-client-button"
        class="gt2-cancel-edit-button"
        matTooltip="{{ 'GENERIC.CANCEL' | translate }}"
        position="left"
        showDelay="900"
        hideDelay="900"
        (click)="onCancelEdit($event)"
        [disabled]="loading"
        [@animate]="{
          value: '*',
          params: { delay: '120ms', scale: '.2' },
        }"
      >
        <mat-icon class="icon-mat s-24">{{ getIcon("CANCEL") }}</mat-icon>
      </button>
    </div>
  </div>
</div>
