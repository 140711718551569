import { GT2FirebaseService } from '../../../services/gt2-firebase.service';
import { Gt2ApiService } from './../../api/gt2-api.service';
import { TestErrorService } from './../../api/test-error.service';
import { ReportViewerComponent } from './../report-viewer/report-viewer.component';
import { PrintService } from '../../../services/print.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { GT2PageAbstract } from './../../content/abstract/GT2PageAbstract';
import { ReportsService } from './../reports.service';
import { IconsService } from './../../services/icons.service';
import { FileService } from './../../../services/file.service';
import { NGXLogger } from 'ngx-logger';
import { ReportVOModel } from './../reports-factory';
import {
  Component,
  Input,
  Output,
  OnInit,
  AfterViewInit,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { ExportToEnum, SocketModeEnum } from '../ReportsEnum';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-report-button',
  templateUrl: './report-button.component.html',
  styleUrls: ['./report-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    TranslateModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
  ],
})
export class ReportButtonComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit
{
  @Input() vo!: ReportVOModel | any;
  @Input() useV1: boolean = false;
  @Input() color?: string | null = null;
  @Output() onV1Click: EventEmitter<any> = new EventEmitter();
  @ViewChild('myTooltip') myTooltip: any;

  tooltipLabel: string = '';
  myOptions = {
    placement: 'left',
    showDelay: 500,
    hideDelay: 0,
  };

  constructor(
    public logger: NGXLogger,
    public fileService: FileService,
    public iconsService: IconsService,
    public reportsService: ReportsService,
    private spinner: NgxSpinnerService,
    private printService: PrintService,
    private dialog: MatDialog,
    private testError: TestErrorService,
    private api: Gt2ApiService,
  ) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    // this.logger.info(
    //     "ReportButtonComponent.ngAfterViewInit() -> vo: " +
    //         JSON.stringify(this.vo)
    // );

    // this.logger.info(
    //     "ReportButtonComponent.ngAfterViewInit() -> excelEnabled() & pdfEnabled(): " +
    //         this.fileService.excelEnabled() +
    //         " / " +
    //         this.fileService.pdfEnabled()
    // );

    // tooltipLabel
    const prefix: string = 'GENERIC.';
    if (this.vo?.export_to === 'PREVIEW') {
      this.tooltipLabel = prefix + 'PREVIEW';
    } else if (this.vo?.export_to === 'PRINT') {
      this.tooltipLabel = prefix + 'PRINT';
    } else if (this.vo?.export_to === 'DOWNLOAD') {
      if (this.vo.export_format === 'XLSX') {
        this.tooltipLabel = prefix + 'DOWNLOAD_XLS';
      } else if (
        this.vo.export_format === 'PDF' ||
        this.vo.export_format === 'PDF_STACK'
      ) {
        this.tooltipLabel = prefix + 'DOWNLOAD_PDF';
      }
    }
    // this.logger.info(
    //     "ReportButtonComponent.onReport() -> this.tooltipLabel: " +
    //         this.tooltipLabel
    // );
  }

  public onReport(): void {
    if (this.myTooltip) {
      this.myTooltip.hide();
    }
    //this.logger.info("ReportButtonComponent.onReport() -> this.myTooltip: " + this.myTooltip);
    // this.logger.info(
    //     "ReportButtonComponent.onReport() -> vo: " + JSON.stringify(this.vo)
    // );

    // is V1
    if (this.useV1) {
      this.onV1Click.emit();
      // this.logger.info("ReportButtonComponent.onReport() -> emit() ");
      return;
    }

    // is V2
    if (this.vo?.export_to !== ExportToEnum.PREVIEW) {
      this.vo.socket_key = GT2FirebaseService.CLIENT_KEY;
      this.vo.job_connection = 'database';
      this.vo.socket_mode = SocketModeEnum.FIREBASE;
    }

    if (this.vo?.export_to === ExportToEnum.PRINT) {
      this.onProcessPrint();
    } else if (this.vo?.export_to === ExportToEnum.PREVIEW) {
      this.onProcessPreview();
    } else if (this.vo?.export_to === ExportToEnum.DOWNLOAD) {
      this.onProcessDownload();
    }
  }

  public onProcessPrint(): void {
    this.logger.info('ReportButtonComponent.onProcessPrint()');
    // this.spinner.show("reportSpinner");
    this.reportsService
      .printPDFReports(this.vo)
      .catch((error) => {
        this.logger.error(
          'ReportButtonComponent.onProcessPrint() -> ERROR: ' +
            JSON.stringify(error),
        );
        // this.hideSpinner();
        this.handleAPIError(error, this.dialog, null, null);
      })
      .then((response) => {
        // this.spinner.hide("reportSpinner");
        this.hideSpinner();
      });
  }

  public onProcessDownload(): void {
    //this.logger.info("ReportButtonComponent.onProcessDownload()");
    // this.spinner.show("reportSpinner");

    this.reportsService
      .processDownloadFile(this.vo)
      .catch((error) => {
        // this.logger.error(
        //     "ReportButtonComponent.onProcessDownload().then() -> ERROR: " +
        //         JSON.stringify(error)
        // );
        this.handleAPIError(error, this.dialog, null, null);
        // this.hideSpinner();
      })
      .then((response) => {
        //this.logger.info("ReportButtonComponent.onProcessDownload().then() -> SUCCESS: " + JSON.stringify(response));
        this.hideSpinner();
      });

    // this.testError
    //     .testErrorPOST(this.api.createUrl(ApiRoutes.REPORTS_2), this.vo)
    //     .catch((error) => {
    //         this.handleAPIError(error, this.dialog, null, null);
    //         this.hideSpinner();

    //         // this.logger.info(  )
    //     })
    //     .then((response) => {
    //         this.logger.info(
    //             "ReportButtonComponent.onProcessDownload().then() -> SUCCESS: " +
    //                 JSON.stringify(response)
    //         );
    //         this.hideSpinner();
    //     });
  }

  public onProcessPreview(): void {
    //this.logger.info("ReportButtonComponent.onProcessPreview()");
    this.spinner.show('reportSpinner');
    this.reportsService
      .preparePreviewReports(this.vo)
      .catch((error: any) => {
        this.logger.error(
          'ReportButtonComponent.onProcessPreview().then() -> ERROR: ' +
            JSON.stringify(error),
        );
        // this.spinner.hide("reportSpinner");
        this.hideSpinner();
        this.handleAPIError(error, this.dialog, null, null);
      })
      .then((response: any) => {
        // this.logger.info(
        //     "ReportButtonComponent.onProcessPreview().then() -> SUCCESS: " +
        //         JSON.stringify(response)
        // );
        // this.spinner.hide("reportSpinner");
        this.hideSpinner();
        this.openReportPreview(response);
      });
  }

  public openReportPreview(rawHTML: string): void {
    // this.logger.info("TestComponent.openReportPreview() --> rawHTML: " + rawHTML);
    this.dialogRef = this.dialog.open(ReportViewerComponent, {
      // panelClass: "custom-dialog-container",
      panelClass: 'report-dialog-container',
      // maxWidth: "96vw",
      width: '9in',
      minWidth: 350,
      // width: "1020px",
      // width: "96vw",
      height: '96vh',
      disableClose: false,
      data: {
        vo: this.vo,
        rawHTML: rawHTML,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      // if (result) {
      // }
      this.dialogRef = null;
    });
  }

  public hideSpinner(): void {
    //this.logger.info("TestComponent.hideSpinner() --> ");
    setTimeout(() => {
      this.spinner.hide('reportSpinner');
    }, 100);
  }
}
