import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { shareReplay, map, tap } from 'rxjs/operators';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { EmployeeModelData } from '../../../api/models/CrudEmployeeModel';
import { IGetDataService } from '../../../components/gt2-select/gt2-select.component';

@Injectable()
export class CrudEmployeeService implements IGetDataService {
  me?: EmployeeModelData;
  employee?: BehaviorSubject<EmployeeModelData | false> | any =
    new BehaviorSubject(false);
  private meSubject: Subject<EmployeeModelData> = new Subject();
  meObs?: Observable<any> | any;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public loadMe(): Observable<EmployeeModelData> {
    if (!this.meObs) {
      this.meObs = this.http
        .get<any>(this.api.getEmployeeMeUrl())
        .pipe(shareReplay(1))
        .pipe(map((response) => response.data))
        .pipe(tap((data) => this.setMe(data)));
    }

    return this.meObs;

    // return this.http.get<any>(this.api.getEmployeeMeUrl())
    //     .map(response => response.data)
    //     .do(result => this.setMe(result)); // .share();
  }

  public getMe(): Observable<EmployeeModelData> {
    return this.meSubject;
  }

  private setMe(me: any): void {
    this.meObs = null;
    this.me = me;
    this.meSubject.next(this.me);
    this.employee.next(this.me);
  }

  public getEmployee(employeeID: string) {
    // this.logger.info("CrudEmployeeService.getEmployee() -> employeeID: " + employeeID);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.GET_EMPLOYEE) + employeeID
    );
  }

  // public deleteEmployee(employeeID: string)
  // {
  //     this.logger.info("CrudEmployeeService.deleteEmployee() -> employeeID: " + employeeID);
  //     return this.http.delete<any>(this.api.createUrl(ApiRoutes.GET_EMPLOYEE) + employeeID);
  // }

  public updateEmployee(employee: any, employeeUUID: string) {
    // this.logger.info("CrudEmployeeService.updateEmployee() -> employeeUUID: " + employeeUUID);
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.UPDATE_EMPLOYEE) + employeeUUID,
      employee
    );
  }

  public createEmployee(employee: any) {
    // this.logger.info("CrudEmployeeService.createEmployee() -> employee: " + employee);
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CREATE_EMPLOYEE),
      employee
    );
  }

  public deleteEmployee(employee: any) {
    // this.logger.info("CrudEmployeeService.deleteEmployee() -> employee: " + JSON.stringify(employee));
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.DELETE_EMPLOYEE) + employee.uuid
    );
  }

  public resendInvitation(uuid: string) {
    // this.logger.info("CrudEmployeeService.resendInvitation() -> employeeUUID: " + uuid);
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.RESEND_INVITATION) + uuid
    );
  }

  public convertToUser(data: any) {
    // this.logger.info("CrudEmployeeService.convertToUser() -> data: " + data);
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CONVERT_TO_USER),
      data
    );
  }

  public removeAsUser(userUUID: string) {
    // this.logger.info("CrudEmployeeService.removeAsUser() -> userUUID: " + userUUID);
    return this.http.delete<any>(
      this.api.createUrl(ApiRoutes.REMOVE_AS_USER) + userUUID
    );
  }

  public changeEmployeeRole(employee: any) {
    // this.logger.info("CrudEmployeeService.changeEmployeeRole() -> employee: " + JSON.stringify(employee));
    // this.logger.info("CrudEmployeeService.changeEmployeeRole() -> employee.role: " + employee.role);
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.CHANGE_ROLE),
      employee
    );
  }

  // GT2 select implementation
  public getData(): Observable<any> {
    // this.logger.info("CrudEmployeeService.getData()");
    return this.getEmployeeTypes();
  }

  public getEmployeeTypes() {
    // this.logger.info("CrudEmployeeService.getEmployeeTypes()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.GET_EMPLOYEES_TYPES)
    );
  }
}
