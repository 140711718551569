export const locale = {
  lang: "en-CA",
  data: {
    EMPLOYEES: {
      EMPLOYEES: "Employee",
      CREATE_EMPLOYEE: "CREATE NEW EMPLOYEE",
      CREATE_AN_EMPLOYEE: "Create an employee",
      // 'DELETE_EMPLOYEE': 'DELETE EMPLOYEE',
      RESEND_INVITE: "Resend invite",
      EMPLOYEE_NUMBER: "Employee number",
      DELETE_EMPLOYEE: "Delete this employee",
      TYPE: "Type",
      DOB: "Date of birth",
      HAVE_DRIVER_LICENSE: "Has a driving licence",
      OWN_CAR: "Owns a car",
      DRIVER_LICENSE_TYPE: "Driver's license type",
      DATE_HIRE: "Start date",
      DATE_TERMINATION: "Date of termination",
      SOCIAL_NUMBER: "Social insurance #",
      SHOW_HIDE_SOCIAL_NUMBER: "Show/Hide number",
      TRANSIT: "Bank transit",
      BANK_NUMBER: "Bank institution #",
      BANK_ACCOUNT_NUMBER: "Bank account #",
      HOURLY_WAGE: "Hourly wage",
      FUNCTION_NAME: "Function name",
      JOB_FUNCTION: "Job functions",
      ADD_JOB_FUNCTION: "Add a function",
      REMOVE_JOB_FUNCTION: "Remove this function",
      DELETE_SUCCESS: "Employee deleted successfully",
      DELETE_CONFIRM: "Are you sure you want to delete this employee?",
      RESEND_EMAIL: "The invitation was resent to the email: ",
      SEND_EMAIL: "The invitation was sent to the following email: ",
      FEES: "Fees may be incurred when adding a new user",
      REMOVE_STATUS: "Are you sure you want to remove this employee's user status?",
      INVITE_SUCCESS: "Invitation sent successfully",
      CONFIDENTIAL: "Confidential",
      PAYING_CUSTOMER: "Paying customer",
      MODIFY_CUSTOMER: "Edit this employee",
      CLOSE_EDIT: "Close edit mode",
      TRANSFORM_INTO_USER: "Transform into a user",
      REMOVE_AS_USER: "Remove user status",
      REMOVE_JOB_FUNCTION_CONFIRM: "Êtes vous sûr de vouloir enlever cette fonction de l'employé?",
      NO_JOB_FUNCTIONS: "There are no job functions assigned right now.",
      NO_JOB_FUNCTIONS_HR: "There are no job functions assigned right now. Click the green + button in edit mode to add one or more.",
      EMPLOYEE_ROLE: "Employee role",
      GENERATE_ASSIGNMENTS_REPORT: "Generate assigments reports",
      GENERATE_ASSIGNMENTS_REPORT_BY_JOB: "Employee assignments schedule",
      GENERATE_ASSIGNMENTS_REPORT_BY_DATES: "Employee assignment statement",
      LETTER_FORMAT: "letter format",
      LEGAL_FORMAT: "legal format",
    },
  },
};
