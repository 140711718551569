<div style="max-height: 100%; overflow: auto">
  <div fxLayout="column">
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxFlex="100%"
      class="mat-title"
    >
      {{ title }}
    </div>

    <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="mr-4">
      <mat-label>{{ "GENERIC.SEARCH" | translate | titlecase }}</mat-label>
      <input
        id="search"
        [(ngModel)]="filterValue"
        matInput
        #filter
        placeholder="{{ 'GENERIC.SEARCH' | translate | titlecase }}"
      />
      <button
        mat-button
        *ngIf="filterValue"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div fxLayout="row">
      <div fxLayout="column" fxFlex="100%">
        <div
          fxLayout="row"
          fxLayoutAlign="center start"
          class="pb-4 mat-caption"
        >
          {{ labelDraggable }}
        </div>
      </div>
      <div fxLayout="column" fxFlex="100%">
        <div
          fxLayout="row"
          fxLayoutAlign="center start"
          class="pb-4 mat-caption"
        >
          {{ labelDroppable }}
        </div>
      </div>
    </div>

    <div>
      <div class="" fxLayout="row">
        <div
          #draggableListRef
          class="ngx-dnd-container-custom mr-4"
          ngxDroppable="target"
          (drop)="onDeattachedItem($event)"
          [ngModel]="draggableList"
          fxLayout="column"
          fxFlex="50%"
          style="height: 400px"
          fusePerfectScrollbar
        >
          <div
            *ngIf="isLoadingDraggables"
            fxLayout="column"
            fxFlex="100%"
            fxLayoutAlign="center center"
          >
            <mat-spinner [diameter]="20" class=""></mat-spinner>
          </div>

          <div
            *ngIf="
              !isLoadingDraggables &&
              draggableList &&
              draggableList.length === 0
            "
            fxLayout="row"
            fxLayoutAlign="center center"
            class="mt-8"
          >
            {{ "GENERIC.NO_RESULTS" | translate }}
          </div>

          <div
            [ngModel]="item"
            *ngFor="let item of draggableList; let i = index"
          >
            <div
              *ngIf="
                filterValue === '' ||
                item.name.toLowerCase().indexOf(filterValue.toLowerCase()) !==
                  -1
              "
              matRipple
              class="product-child-item"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div fxLayout="row">
                <mat-icon class="mr-8">drag_handle</mat-icon>
                <div>{{ item.name }}</div>
              </div>

              <button mat-icon-button (click)="onAddItem(item, true)">
                <mat-icon class="">add</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div
          #droppableListRef
          class="ngx-dnd-container-custom ml-4 combo-list"
          ngxDroppable="target"
          [ngModel]="droppableList"
          (drop)="onDrop($event)"
          style="height: 400px"
          fxLayout="column"
          fxFlex="50%"
          fusePerfectScrollbar
        >
          <div
            *ngIf="isLoadingDroppables"
            fxLayout="column"
            fxFlex="100%"
            fxLayoutAlign="center center"
          >
            <mat-spinner [diameter]="20" class=""></mat-spinner>
          </div>
          <div
            class="product-child-item"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            matRipple
            [ngModel]="item"
            *ngFor="let item of droppableList; let i = index"
          >
            <div fxLayout="row">
              <mat-icon class="mr-8">drag_handle</mat-icon>
              <div>{{ item.name }}</div>
            </div>
            <mat-icon (click)="onRemoveItem(item, i)" class="ml-8"
              >delete</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--HACK TO MAKE THE D&D ABOVE WORK (TODO: fix the style/code that does not seem to import here if not present-->
  <!-- <ngx-dnd-container
    [ngModel]="['Item 1', 'Item 2', 'Item 3']"
    style="display: none"
  >
  </ngx-dnd-container> -->

  <div
    mat-dialog-actions
    class="mt-16"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      color="accent"
      (click)="dialogRef.close(true)"
      class=""
    >
      {{ "GENERIC.OK" | translate }}
    </button>
  </div>
</div>
