import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ApiRoutes } from './ApiRoutes';
import { Gt2ApiService } from './gt2-api.service';
import { InvoicesCategoryModel } from './invoices-categories.service';

@Injectable()
export class MaterialsService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  public getMaterials(keyword: string) {
    // this.logger.info("MaterialsService.getMaterials()");
    return this.http.post<any>(this.api.createUrl(ApiRoutes.MATERIALS_SEARCH), {
      key: keyword,
    });
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.MATERIALS_LIST));
  }
  public getMaterialsWithoutCombo(keyword: string) {
    // this.logger.info("MaterialsService.getMaterialsWithoutCombo()");
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.MATERIALS_WITHOUT_COMBO_SEARCH),
      { key: keyword }
    );
    // return this.http.get<any>(this.api.createUrl(ApiRoutes.MATERIALS_WITHOUT_COMBO_LIST));
  }

  public getMaterial(uuid: any) {
    // this.logger.info("MaterialsService.getMaterial() -> uuid: " + uuid);
    return this.http.get<any>(this.api.createUrl(ApiRoutes.MATERIAL) + uuid);
  }

  public updateMaterial(data: any, materialUUID: string) {
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.MATERIAL) + materialUUID,
      data
    );
  }

  public getMaterialsWithPagination(
    confirmedOnly: boolean,
    comboOnly: boolean,
    sort: string,
    direction: string,
    page: number,
    pageSize: number,
    filterValue: string
  ): Promise<any> {
    const confirmed: string = confirmedOnly ? '1' : '0';
    const isCombo: string = comboOnly ? '1' : '0';
    const routeURL: string = this.api.createUrl(ApiRoutes.MATERIALS) + '?';

    // comboOnly
    // if ( comboOnly )
    // {
    //     const isCombo: string = (comboOnly) ? "1" : "0";
    //     routeURL = this.api.createUrl(ApiRoutes.PRODUCTS) + "?search=is_combo:"  + isCombo;
    // }

    let requestUrl: string;
    if (!sort || direction === '') {
      requestUrl = `${routeURL}page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}orderBy=${sort}&sortedBy=${direction}&page=${
        page + 1
      }&limit=${pageSize}`;
    }

    // Remove special caracters from the filter value for proper searching
    filterValue = filterValue.replace(/%/g, '');

    if (filterValue !== '') {
      requestUrl += '&search=' + filterValue;
    }

    // this.logger.info("getMaterialsWithPagination() -> routeURL: " + routeURL);
    // this.logger.info("getMaterialsWithPagination() -> requestUrl: " + requestUrl);

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe((response: any) => {
        // this.logger.info("MaterialsService.getMaterialsWithPagination() -> response: " + JSON.stringify(response));
        resolve(response);
      }, reject);
    });
  }

  public cloneMaterial(uuid: string) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.MATERIAL_CLONE) + uuid
    );
  }

  public cloneMaterialCombo(uuid: string) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.MATERIAL_CLONE_COMBO) + uuid
    );
  }

  public createMaterial(product: any) {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.MATERIALS),
      product
    );
  }

  public deleteMaterial(uuid: string) {
    return this.http.delete<any>(this.api.createUrl(ApiRoutes.MATERIAL) + uuid);
  }

  public productChildMoveAfter(
    comboUUID: string,
    productUUID: string,
    afterUUID: string
  ) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.MATERIAL) +
        comboUUID +
        '/move-after/' +
        productUUID +
        '/' +
        afterUUID
    );
  }

  public productChildMoveBefore(
    comboUUID: string,
    productUUID: string,
    beforeUUID: string
  ) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.MATERIAL) +
        comboUUID +
        '/move-before/' +
        productUUID +
        '/' +
        beforeUUID
    );
  }

  public productComboAttach(
    comboUUID: string,
    productUUID: string,
    qty: number
  ) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.MATERIAL) +
        comboUUID +
        '/attach/' +
        productUUID +
        '/' +
        qty
    );
  }

  public productComboDetach(
    comboUUID: string,
    productUUID: string,
    qty: number
  ) {
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.MATERIAL) +
        comboUUID +
        '/detach/' +
        productUUID
    );
  }

  public productComboUpdate(
    comboUUID: string,
    productUUID: string,
    qty: number
  ) {
    // this.logger.info("productComboUpdate()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.MATERIAL) +
        comboUUID +
        '/update/' +
        productUUID +
        '/' +
        qty
    );
  }

  public productComboUpdateBoolean(
    comboUUID: string,
    productUUID: string,
    qty: number,
    internal_view_only: any,
    show_unit_price: any
  ) {
    // this.logger.info("productComboUpdateBoolean()");
    return this.http.get<any>(
      this.api.createUrl(ApiRoutes.MATERIAL) +
        comboUUID +
        '/update/' +
        productUUID +
        '/' +
        qty +
        '/' +
        internal_view_only +
        '/' +
        show_unit_price
    );
  }

  public materialComboQtyUpdate(comboUUID: string, children: any[]): any {
    return this.http.post<any>(
      this.api.createUrl(ApiRoutes.MATERIAL) + comboUUID + '/combo/update',
      { children: children }
    );
  }
}

export interface MaterialsModel {
  data: MaterialModel[];
}
export interface MaterialModel {
  object: string;
  uuid: string;
  name: string;
  name_local: name_local;
  name_internal: string;
  name_internal_local: name_internal_local;
  slug: string;
  description: string;
  description_local: description_local;
  description_internal: string;
  material_number: number;
  accountant_code: number; // TODO: is this one still in use?
  accounting_number: number;
  production_delay: number;
  sku: number;
  price: number;
  price_label: string;
  cost: number;
  cost_label: number;
  note: string;
  sale_note: string;
  prod_note: string;
  logistic_notes: string;
  active: boolean;
  confirmed: boolean;
  total_inventory_qty: number;
  invoice_category: InvoicesCategoryModel;
  material_provider: MaterialProviderModel;
  material_category: MaterialCategoryProvider;
  is_combo: boolean;
  childs: any;
  parents: any;
  tax: any;
  ordered: boolean;
  order: any;
  historic_model: any;
  isArchive: boolean;
}

export interface name_local {
  name_fr_CA: string;
  name_en_CA: string;
}
export interface name_internal_local {
  name_internal_fr_CA: string;
  name_internal_en_CA: string;
}

// tslint:disable-next-line:class-name
export interface description_local {
  description_fr_CA: string;
  description_en_CA: string;
}

export interface MaterialProviderModel {
  object: string;
  uuid: string;
  name: string;
  slug: string;
}

export interface MaterialCategoryProvider {
  object: string;
  uuid: string;
  name: string;
  slug: string;
  accounting_number: number;
}
