export const locale = {
  lang: "fr-CA",
  data: {
    CONTACT: {
      CONTACT: "Contact",
      CONTACT_TICKET: "Support - Demande de ticket",
      CONTACT_TICKET_LINK: "Cliquer ici pour accéder a notre page de Demande de ticket",
      CONTACT_US: "Nous contacter",
      SUCCESS_MESSAGE: "Votre message a été envoyé avec succès!",
      USE_FORM: "Ou utiliser le formulaire ci dessous",
      SUBJECT: "Sujet",
      MESSAGE: "Message",
    },
  },
};
