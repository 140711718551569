import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "displayHtmlLinkPipe",
})
export class DisplayHtmlLinkPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      return value.replace(/(^\w+:|^)\/\//, "");
    }
    return value;
  }
}
