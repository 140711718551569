import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SliderModule } from 'primeng/slider';
import { TabViewModule } from 'primeng/tabview';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ErrorDialogComponent } from '../components/dialogs/error-dialog/error-dialog.component';
@NgModule({
  declarations: [],
  imports: [
    ErrorDialogComponent,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
  ],
  exports: [
    InputTextModule,
    InputMaskModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule,
    TabViewModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    SliderModule,
    ContextMenuModule,
  ],
  providers: [],
})
export class Gt2PrimengModule {}
