import { ShopProductTypeService } from './../../../api/shop-product-type.service';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { Settings } from '../../../settings/settings';
import { ShopsService } from '../../../api/shops.service';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { ShopTypeService } from '../../../api/shop-type.service';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { Gt2SelectComponent } from '../../gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-shop',
  templateUrl: './create-shop.component.html',
  styleUrls: ['./create-shop.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexLayoutModule,
    MatButtonModule,
    CommonModule,
    Gt2SelectComponent,
    MatProgressSpinnerModule,
  ],
})
export class CreateShopComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  shop: any;
  loading: boolean = false;
  autoRedirect: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<CreateShopComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private shopsService: ShopsService,
    public shopTypeService: ShopTypeService,
    public shopProdudctTypeService: ShopProductTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.shop = {
      name: null,
      shop_type: null,
      shop_product_type: null,
    };

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onCreateShop(): void {
    this.loading = true;
    //this.logger.info("CreateShopComponent.onCreateShop() -> ");
    this.shopsService.createShop(this.shop).subscribe(
      (response: any) => {
        this.logger.log(
          'CreateShopComponent.onCreateShop() -> SUCCESS: ' +
            JSON.stringify(response),
        );
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_SHOP'),
        );
        this.dialogRef.close(response.data);
        this.loading = false;
        if (this.autoRedirect) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
        }
      },
      (error: any) => {
        this.loading = false;
        this.logger.error(
          'CreateShopComponent.onCreateProduct() -> ERROR: ' +
            JSON.stringify(error),
        );
        // TODO: should have a toastr ????
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public onShopTypeChange(event: any): void {
    //this.logger.info("CreateShopComponent.onShopTypeChange() -> event: " + JSON.stringify(event));
    this.shop.shop_type = event;
    //this.logger.info("CreateShopComponent.onShopTypeChange() -> this.shop.shop_type: " + JSON.stringify(this.shop.shop_type));
  }

  public onShopProductTypeChange(event: any): void {
    //this.logger.info("CreateShopComponent.onShopProductTypeChange() -> event: " + JSON.stringify(event));
    this.shop.shop_product_type = event;
    //this.logger.info("CreateShopComponent.onShopProductTypeChange() -> this.shop.shop_product_type: " + JSON.stringify(this.shop.shop_product_type));
  }
}
