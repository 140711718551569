<div
  id="assignments"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    name="assignmentGroupSpinner"
    size="medium"
    color="white"
    [fullScreen]="true"
    type="ball-scale-multiple"
  >
  </ngx-spinner>

  <!-- HEADER -->
  <div class="gt2-theme-extended">
    <div
      class="header primary p-24 h-80 mat-elevation-z1"
      fusePerfectScrollbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          class="mr-0 mr-sm-16 white-fg"
          mat-icon-button
          (click)="onPageBack(location, router, '/dashboard')"
        >
          <mat-icon class="icon-mat-black">arrow_back</mat-icon>
        </button>

        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="user-info" fxLayout="row" fxLayoutAlign="start start">
            <div
              class="event-title text-truncate"
              *fuseIfOnDom
              [@animate]="{
                value: '*',
                params: { delay: '100ms', x: '-25px' },
              }"
            >
              <div
                *ngIf="assignments && assignments.event"
                class="text-truncate"
              >
                <span
                  *ngIf="
                    !assignments.event.shop ||
                    !assignments.event.shop.shop_type ||
                    !assignments.event.shop.shop_type.key ||
                    assignments.event.shop.shop_type.key !== 'SHOP_WEB'
                  "
                  class="text-truncate pr-8"
                >
                  {{ "ASSIGNMENTS.ASSIGNMENTS" | translate }} -
                  {{ assignments.event.name }} -
                  {{ assignments.event.event_number }}
                </span>
                <span
                  *ngIf="
                    assignments.event.shop &&
                    assignments.event.shop.shop_type &&
                    assignments.event.shop.shop_type.key === 'SHOP_WEB'
                  "
                  class="text-truncate pr-8"
                >
                  {{ "ASSIGNMENTS.ASSIGNMENTS" | translate | titlecase }} -
                  {{ assignments.event.name }} -
                  {{ assignments.event.event_number }}
                </span>
              </div>
            </div>
          </div>

          <div
            *ngIf="assignments"
            fxLayout="row"
            fxLayoutAlign="start start"
            class=""
          >
            <div *ngIf="assignments.event">
              {{ "GENERIC.STATUS" | translate }}:
              {{ assignments.event.status_label }}
            </div>
            <div *ngIf="assignments.stats" class="">
              &nbsp; - {{ "GENERIC.GUEST_S" | translate }}(s):
              {{ assignments.stats.guest_count }}
            </div>
            <div
              *ngIf="
                assignments.stats && assignments.stats.responsible_employee_name
              "
              matTooltip="{{ 'GENERIC.RESPONSIBLE_EMPLOYEE' | translate }}"
              class=""
            >
              &nbsp;- {{ assignments.stats.responsible_employee_name }}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center">
        <div
          *ngIf="
            assignments && assignments.event && assignments.event.delivery_type
          "
          matTooltip="{{
            'GENERIC.DELIVERY_TYPE' +
              assignments.event.delivery_type.delivery_type_id | translate
          }}"
        >
          <mat-icon
            class="mx-12 fa-lg"
            *ngIf="assignments.event.delivery_type.delivery_type_id === 1"
            fontSet="fa"
            fontIcon="fa-shipping-fast"
          >
          </mat-icon>
          <mat-icon
            class="mx-12 fa-lg"
            *ngIf="assignments.event.delivery_type.delivery_type_id === 2"
            fontSet="fa"
            fontIcon="fa-utensils"
          >
          </mat-icon>
          <mat-icon
            class="mx-12 fa-lg"
            *ngIf="assignments.event.delivery_type.delivery_type_id === 3"
            fontSet="fa"
            fontIcon="fa-users"
          >
          </mat-icon>
          <mat-icon
            class="mx-12 fa-lg"
            *ngIf="assignments.event.delivery_type.delivery_type_id === 4"
            fontSet="fa"
            fontIcon="fa-concierge-bell"
          >
          </mat-icon>
        </div>
        <div
          *ngIf="assignments"
          class="user-info mr-12"
          fxLayout="column"
          fxLayoutAlign="center end"
        >
          <div *ngIf="assignments.event" class="event-date">
            {{ assignments.event.event_date_label }}
            {{ assignments.event.event_time_label }}
          </div>
          <div *ngIf="assignments.event.shop" class="mt-4">
            {{ assignments.event.shop.shop_type_label }}
          </div>
        </div>

        <button
          mat-button
          matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
          (click)="onOpenInNewTab()"
          class="mat-icon-button"
        >
          <mat-icon class="icon-mat-black s-30">{{
            getIcon("NEW_TAB")
          }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- SUB HEADER -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fusePerfectScrollbar
    class="primary mat-elevation-z1 content-subheader"
  >
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row">
        <button
          class="primary"
          mat-button
          *ngIf="assignments"
          (click)="onVisitEvent()"
        >
          <mat-icon class="s-20 icon-mat">{{ getIcon("EVENT") }}</mat-icon>
          <span class="ml-8">{{ "GENERIC.EVENT" | translate }}</span>
        </button>

        <button
          mat-button
          class="primary"
          *ngIf="assignments && assignments.invoice"
          (click)="onVisitInvoice()"
        >
          <mat-icon class="s-20 icon-mat">{{ getIcon("INVOICE") }}</mat-icon>
          <span class="ml-8">{{ "GENERIC.INVOICE" | translate }}</span>
        </button>

        <button
          mat-button
          class="primary"
          *ngIf="assignments && !assignments.invoice && assignments.order"
          (click)="onVisitOrder()"
        >
          <mat-icon class="s-20 icon-mat">{{ getIcon("ORDER") }}</mat-icon>
          <span class="ml-8">{{ "GENERIC.ORDER" | translate }}</span>
        </button>
      </div>
      <!-- ASSIGNEMENTS PRINT & DOWNLOAD -->
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="flex-end center"
        *ngxPermissionsOnly="rolesService.SALE_ROLES"
      >
        <button
          mat-button
          *ngIf="
            fileService.printEnabled() &&
            moduleService.modulesAreSet &&
            moduleService.moduleExist('MODULE_STAFF_ASSIGNMENT')
          "
          class="primary"
          [matMenuTriggerFor]="menuPrintWithFormat"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon color="" class="s-28 icon-mat">
              {{ getIcon("PRINT") }}
            </mat-icon>
            <span class="ml-8">
              {{ "GENERIC.PRINT" | translate }}
            </span>
            <mat-icon class="icon-mat">more_vert</mat-icon>
          </div>
        </button>
        <mat-menu #menuPrintWithFormat="matMenu">
          <!-- <button mat-menu-item [matMenuTriggerFor]="printEmployeeAssignmentSale">{{ "ASSIGNMENTS.ASSIGNMENT_SALE" | translate }}</button>
                    <button mat-menu-item [matMenuTriggerFor]="printEmployeeAssignmentCost">{{ "ASSIGNMENTS.ASSIGNMENT_COST" | translate }}</button> -->
          <button
            mat-menu-item
            [matMenuTriggerFor]="printEmployeeAssignmentAttendance"
          >
            {{ "ASSIGNMENTS.ASSIGNMENT_ATTENDANCE" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printEmployeeAssignmentSale="matMenu">
          <button
            mat-menu-item
            (click)="
              onPrintEmployeesAssigments(
                'assignment/report-assignment-sales',
                'letter'
              )
            "
          >
            {{ "GENERIC.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              onPrintEmployeesAssigments(
                'assignment/report-assignment-sales',
                'legal'
              )
            "
          >
            {{ "GENERIC.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printEmployeeAssignmentCost="matMenu">
          <button
            mat-menu-item
            (click)="
              onPrintEmployeesAssigments(
                'assignment/report-assignment-cost',
                'letter'
              )
            "
          >
            {{ "GENERIC.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              onPrintEmployeesAssigments(
                'assignment/report-assignment-cost',
                'legal'
              )
            "
          >
            {{ "GENERIC.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #printEmployeeAssignmentAttendance="matMenu">
          <button
            mat-menu-item
            (click)="
              onPrintEmployeesAssigments(
                'assignment/report-assignment-attendance-record',
                'letter'
              )
            "
          >
            {{ "GENERIC.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              onPrintEmployeesAssigments(
                'assignment/report-assignment-attendance-record',
                'legal'
              )
            "
          >
            {{ "GENERIC.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>

        <!-- DOWNLOAD -->
        <button
          mat-button
          *ngIf="
            fileService.pdfEnabled() &&
            moduleService.modulesAreSet &&
            moduleService.moduleExist('MODULE_STAFF_ASSIGNMENT')
          "
          class="primary"
          [matMenuTriggerFor]="menuDownloadWithFormat"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="s-28 subheader-menu-icon icon-mat">
              {{ getIcon("PDF") }}
            </mat-icon>
            <span class="subheader-menu-text ml-8 icon-mat">
              {{ "GENERIC.DOWNLOAD" | translate }}
            </span>
            <mat-icon class="icon-mat">more_vert</mat-icon>
          </div>
        </button>
        <mat-menu #menuDownloadWithFormat="matMenu">
          <!-- <button mat-menu-item [matMenuTriggerFor]="downloadEmployeeAssignmentSale">{{ "ASSIGNMENTS.ASSIGNMENT_SALE" | translate }}</button>
                    <button mat-menu-item [matMenuTriggerFor]="downloadEmployeeAssignmentCost">{{ "ASSIGNMENTS.ASSIGNMENT_COST" | translate }}</button> -->
          <button
            mat-menu-item
            [matMenuTriggerFor]="downloadEmployeeAssignmentAttendance"
          >
            {{ "ASSIGNMENTS.ASSIGNMENT_ATTENDANCE" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadEmployeeAssignmentSale="matMenu">
          <button
            mat-menu-item
            (click)="
              onDownloadEmployeesAssigments(
                'assignment/report-assignment-sales',
                'letter'
              )
            "
          >
            {{ "GENERIC.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              onDownloadEmployeesAssigments(
                'assignment/report-assignment-sales',
                'legal'
              )
            "
          >
            {{ "GENERIC.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadEmployeeAssignmentCost="matMenu">
          <button
            mat-menu-item
            (click)="
              onDownloadEmployeesAssigments(
                'assignment/report-assignment-cost',
                'letter'
              )
            "
          >
            {{ "GENERIC.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              onDownloadEmployeesAssigments(
                'assignment/report-assignment-cost',
                'legal'
              )
            "
          >
            {{ "GENERIC.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
        <mat-menu #downloadEmployeeAssignmentAttendance="matMenu">
          <button
            mat-menu-item
            (click)="
              onDownloadEmployeesAssigments(
                'assignment/report-assignment-attendance-record',
                'letter'
              )
            "
          >
            {{ "GENERIC.LETTER_FORMAT" | translate }}
          </button>
          <button
            mat-menu-item
            (click)="
              onDownloadEmployeesAssigments(
                'assignment/report-assignment-attendance-record',
                'legal'
              )
            "
          >
            {{ "GENERIC.LEGAL_FORMAT" | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <!-- / SUB HEADER -->

  <!-- Read only top banner -->
  <div
    *ngIf="assignments && assignments.event && !editable"
    fxLayout="row"
    fxLayoutAlign="start center"
    style="height: 38px; min-height: 38px; max-height: 38px"
    class="gt2-gray mat-elevation-z1 py-8"
  >
    <div fxFlex fxLayout="row" fxLayoutAlign="center center">
      <div class="text-white font-weight-600" style="font-size: 17px">
        {{ assignments.event.status_label }}
      </div>
    </div>
  </div>

  <!--HISTORY SIDEBAR-->
  <div *ngIf="!isSmallScreen" class="container-history-panel">
    <fuse-sidebar
      *ngIf="eventUUDID"
      name="assignmentsHistoryPanel"
      position="right"
      class="section-history-panel"
    >
      <app-section-history-panel
        *ngIf="assignments"
        panelName="assignmentsHistoryPanel"
        section="events/assignments"
        sectionUUID="{{ eventUUDID }}"
      >
      </app-section-history-panel>
    </fuse-sidebar>
  </div>
  <!-- / HISTORY SIDEBAR-->

  <!--HISTORY SIDEBAR BUTTON-->
  <div *ngIf="!isSmallScreen">
    <button
      mat-icon-button
      class="warn mat-elevation-z2 historic-button"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
      (click)="toggleHistoricSidebarOpen('assignmentsHistoryPanel')"
    >
      <mat-icon>history</mat-icon>
    </button>
  </div>
  <!-- /HISTORY SIDEBAR BUTTON-->

  <!-- CONTENT -->
  <div class="content center">
    <div class="p-24 pb-64 pr-sm-80" #assignmentsPage fusePerfectScrollbar>
      <div *ngIf="assignments" style="min-width: 1023px" fxLayout="column">
        <div fxFlex fxLayout="row" fxLayoutAlign="start start">
          <!-- cards -->

          <div
            fxFlex="39"
            fxLayout="column"
            fxLayoutAlign="start start"
            class="mr-28"
          >
            <mat-card
              *ngIf="assignments.invoice_resume"
              class="assigments-card"
            >
              <div class="card-title">
                {{ "ASSIGNMENTS.INVOICE_SUMMARY" | translate }}
              </div>
              <div class="card-item" fxLayout="row" fxLayoutAlign="start end">
                <div>{{ "ASSIGNMENTS.TOTAL_BILLED" | translate }}: &nbsp;</div>
                <div *ngIf="assignments.invoice_resume" class="item-value">
                  {{ assignments.invoice_resume.total_billed_label }}
                </div>
              </div>
              <div class="card-item" fxLayout="row" fxLayoutAlign="start end">
                <div>{{ "ASSIGNMENTS.COST_PRICE" | translate }}: &nbsp;</div>
                <div *ngIf="assignments.invoice_resume" class="item-value">
                  {{ assignments.invoice_resume.total_cost_label }}
                </div>
              </div>
              <div class="card-item" fxLayout="row" fxLayoutAlign="start end">
                <div>{{ "ASSIGNMENTS.TOTAL_HOURS" | translate }}: &nbsp;</div>
                <div *ngIf="assignments.invoice_resume" class="item-value">
                  {{ assignments.invoice_resume.total_hours }}
                </div>
              </div>
            </mat-card>

            <mat-card
              *ngIf="!assignments.invoice_resume && assignments.order_resume"
              class="assigments-card"
            >
              <div class="card-title">
                {{ "ASSIGNMENTS.ORDER_SUMMARY" | translate }}
              </div>
              <div class="card-item" fxLayout="row" fxLayoutAlign="start end">
                <div>{{ "ASSIGNMENTS.TOTAL_BILLED" | translate }}: &nbsp;</div>
                <div *ngIf="assignments.order_resume" class="item-value">
                  {{ assignments.order_resume.total_billed_label }}
                </div>
              </div>
              <div class="card-item" fxLayout="row" fxLayoutAlign="start end">
                <div>{{ "ASSIGNMENTS.COST_PRICE" | translate }}: &nbsp;</div>
                <div *ngIf="assignments.order_resume" class="item-value">
                  {{ assignments.order_resume.total_cost_label }}
                </div>
              </div>
              <div class="card-item" fxLayout="row" fxLayoutAlign="start end">
                <div>{{ "ASSIGNMENTS.TOTAL_HOURS" | translate }}: &nbsp;</div>
                <div *ngIf="assignments.order_resume" class="item-value">
                  {{ assignments.order_resume.total_hours }}
                </div>
              </div>
            </mat-card>

            <mat-card
              *ngIf="!assignments.invoice_resume && !assignments.order_resume"
              class="assigments-card"
            >
              <div
                fxFlex
                fxLayout="row"
                fxLayoutAlign="center center"
                class="mat-h2"
              >
                {{ "ASSIGNMENTS.ORDER_NECESSARY" | translate }}
              </div>
            </mat-card>

            <mat-card class="assigments-card mt-16">
              <div class="card-title">
                {{ "ASSIGNMENTS.ASSIGNMENTS_SUMMARY" | translate }}
              </div>
              <div class="card-item" fxLayout="row" fxLayoutAlign="start end">
                <div>{{ "ASSIGNMENTS.TOTAL_BILLED" | translate }}: &nbsp;</div>
                <div *ngIf="assignments.assignment_resume" class="item-value">
                  {{ assignments.assignment_resume.total_assigned_label }}
                </div>
                <button
                  mat-icon-button
                  matTooltip="{{ 'ASSIGNMENTS.TOTAL_BILLED_HINT' | translate }}"
                  content-type="template"
                  type="button"
                  [disableRipple]="true"
                  style="
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    padding: 0;
                    margin: 0;
                    padding-bottom: 4px;
                  "
                >
                  <mat-icon class="s-20" color="">help</mat-icon>
                </button>
              </div>
              <div class="card-item" fxLayout="row" fxLayoutAlign="start end">
                <div>{{ "ASSIGNMENTS.COST_PRICE" | translate }}: &nbsp;</div>
                <div *ngIf="assignments.assignment_resume" class="item-value">
                  {{ assignments.assignment_resume.total_assigned_cost_label }}
                </div>
              </div>
              <div class="card-item" fxLayout="row" fxLayoutAlign="start end">
                <div>{{ "ASSIGNMENTS.TOTAL_HOURS" | translate }}: &nbsp;</div>
                <div *ngIf="assignments.assignment_resume" class="item-value">
                  {{ assignments.assignment_resume.total_hours }}
                </div>
              </div>

              <div class="card-item" fxLayout="row" fxLayoutAlign="start end">
                <div>
                  {{ "ASSIGNMENTS.ASSIGNMENTS_TOTAL" | translate }}: &nbsp;
                </div>
                <div *ngIf="assignments.assignment_resume" class="item-value">
                  {{ assignments.assignment_resume.total_count_assignment }}
                </div>
              </div>
            </mat-card>
          </div>

          <!-- Job functions -->
          <div fxFlex="61" style="height: 100%">
            <mat-card class="assigments-card jobs-func-card">
              <div class="card-title">
                {{ "ASSIGNMENTS.JOB_FUNCTIONS_BILLED" | translate }}
              </div>
              <div class="list-header">
                <div fxFlex="75px" class="r-line">
                  {{ "GENERIC.QUANTITY" | translate }}
                </div>
                <div fxFlex="75px" class="r-line">
                  {{ "GENERIC.DURATION" | translate }}
                </div>
                <div fxFlex="100px" class="r-line">
                  {{ "ASSIGNMENTS.PRICE" | translate }}
                </div>
                <div fxFlex="100px" class="r-line">
                  {{ "ASSIGNMENTS.INVOICE_TOTAL" | translate }}
                </div>
                <div fxFlex="100px" class="r-line">
                  {{ "ASSIGNMENTS.RATE" | translate }}
                </div>
                <div fxFlex>{{ "GENERIC.NAME" | translate }}</div>
              </div>

              <div class="list-container">
                <cdk-virtual-scroll-viewport
                  *ngIf="assignments.invoice_job_function_list.length > 0"
                  [itemSize]="6"
                  class="list-vscroll"
                >
                  <!-- TRACKBY: trackBy -->
                  <div
                    *cdkVirtualFor="
                      let job of assignments.invoice_job_function_list;
                      trackBy: trackByUUID
                    "
                    class="job-item"
                  >
                    <div fxFlex="75px">{{ job.qty }}</div>
                    <div fxFlex="75px">{{ job.duration }}</div>
                    <div fxFlex="100px">{{ job.price_label }}</div>
                    <div fxFlex="100px">{{ job.invoiced_total }}</div>

                    <div
                      *ngxPermissionsOnly="rolesService.RH_ROLES"
                      fxFlex="100px"
                    >
                      {{ job.hourly_wage_label }}
                    </div>
                    <div
                      *ngxPermissionsExcept="rolesService.RH_ROLES"
                      fxFlex="100px"
                    >
                      <button
                        mat-icon-button
                        matTooltip="{{ 'GENERIC.NO_PERMISSIONS' | translate }}"
                        type="button"
                      >
                        <mat-icon class="s-20" color="">help</mat-icon>
                      </button>
                    </div>

                    <div fxFlex>
                      {{ job.name_local[localeHelperService.name] }}
                    </div>
                  </div>
                </cdk-virtual-scroll-viewport>
                <div
                  fxFlexFill
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  *ngIf="
                    assignments &&
                    assignments.invoice_job_function_list.length === 0
                  "
                >
                  <div class="mat-h3">Aucun</div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>

        <!-- ASSIGNMENTS -->
        <div class="mt-32">
          <!-- TRACKBY: trackBy -->
          <app-assignment-group
            *ngFor="
              let group of assignments.group_assignments;
              trackBy: trackByUUID;
              let i = index
            "
            [assignmentGroup]="group"
            [panelOpenState]="groupsOpened.includes(group.uuid)"
            (stateChange)="onGroupStateChange($event)"
            (delete)="onAssignmentGroupDelete()"
            (newAssigmentModel)="onNewAssigmentModel($event)"
            class="group"
          >
          </app-assignment-group>

          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            *ngIf="
              assignments &&
              assignments.group_assignments &&
              assignments.group_assignments.length === 0
            "
            class="mat-h1 no-group-msg"
          >
            {{ "ASSIGNMENTS.NO_GROUP" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /CONTENT -->
</div>

<div class="gt2-theme-extended fixed-button">
  <button
    mat-fab
    class="gt2-light-green"
    id="new-group-button"
    [disabled]="loading"
    (click)="onAddNewGroup()"
    matTooltip="{{ 'ASSIGNMENTS.ADD_GROUP_HINT' | translate }}"
  >
    <mat-icon style="color: black">{{ getIcon("ADD") }}</mat-icon>
  </button>
</div>
