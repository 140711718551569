<div class="profile-box info-box width-100-content">
  <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title">{{ headerLabel }}</div>
    <div
      class="gt2-light-green"
      style="width: 60px; height: 56px"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <button
        type="button"
        matTooltip="{{ createLabel }}"
        mat-icon-button
        class=""
        (click)="onCreateNew()"
      >
        <mat-icon class="" color="">add</mat-icon>
      </button>
    </div>
  </header>
  <div style="min-width: 370px" class="content" fxLayout="column">
    <div
      *ngIf="
        !isLoadingResults && dataSource.data && dataSource.data.length === 0
      "
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      {{ "GENERIC.NO_RESULTS" | translate }}
    </div>
    <mat-table
      #table
      class=""
      matSort
      matSortStart="desc"
      [@animateStagger]="{ value: '50' }"
      [dataSource]="dataSource"
    >
      <!-- Name Column -->
      <ng-container cdkColumnDef="name">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{
          tableHeaderLabel
        }}</mat-header-cell>
        <mat-cell class="no-text-cursor" *cdkCellDef="let row">
          <div
            *ngIf="!editSelected || editSelected.uuid != row.uuid"
            fxFlex="100%"
            (click)="onSelect(row)"
          >
            {{ row.name }}
          </div>

          <div
            *ngIf="editSelected && editSelected.uuid == row.uuid"
            fxLayout="column"
            fxFlex="100%"
          >
            <div
              *ngIf="useLanguages"
              [formGroup]="form"
              fxLayout="row"
              fxLayoutAlign="start start"
              fxFlex="100%"
            >
              <mat-form-field fxFlex="100%" class="">
                <mat-label>{{ "Français@" }}</mat-label>
                <input
                  matInput
                  [placeholder]="'Français@'"
                  formControlName="name_fr_CA"
                  type="text"
                  [(ngModel)]="editSelected.name_local.name_fr_CA"
                />
                <mat-error *ngIf="form.controls['name_fr_CA'].invalid"
                  >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div
              *ngIf="useLanguages"
              [formGroup]="form"
              fxLayout="row"
              fxLayoutAlign="start start"
              fxFlex="100%"
            >
              <mat-form-field fxFlex="100%" class="">
                <mat-label>{{ "English@" }}</mat-label>
                <input
                  matInput
                  [placeholder]="'English@'"
                  formControlName="name_en_CA"
                  type="text"
                  [(ngModel)]="editSelected.name_local.name_en_CA"
                />
                <mat-error *ngIf="form.controls['name_en_CA'].invalid"
                  >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div
              *ngIf="!useLanguages"
              [formGroup]="form"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxFlex="100%"
            >
              <mat-form-field fxFlex="100%" class="">
                <mat-label>{{ "GENERIC.NAME" | translate }}</mat-label>
                <input
                  matInput
                  [placeholder]="'GENERIC.NAME' | translate"
                  formControlName="name"
                  type="text"
                  [(ngModel)]="editSelected.name"
                />
                <mat-error *ngIf="form.controls['name'].invalid"
                  >{{ "GENERIC.INPUT_LENGTH_INVALID" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="end center"
              class="gt2-buttons-theme"
              fxFlex="100%"
            >
              <button
                mat-raised-button
                color="accent"
                class=""
                [disabled]="form.invalid"
                (click)="autosaveDataChange(editSelected)"
              >
                {{ "GENERIC.SAVE" | translate }}
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- EDIT Column -->
      <ng-container cdkColumnDef="edit">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            mat-icon-button
            *ngIf="row.editable"
            matTooltip="{{
              editSelected != row
                ? translate.instant('GENERIC.EDIT')
                : translate.instant('GENERIC.EDIT_STOP')
            }}"
            (click)="onEdit(row)"
          >
            <mat-icon
              *ngIf="!editSelected || editSelected.uuid != row.uuid"
              class="s-16"
            >
              {{ getIcon("EDIT") }}
            </mat-icon>
            <mat-icon
              *ngIf="editSelected && editSelected.uuid == row.uuid"
              class="s-16"
            >
              {{ getIcon("UNDO") }}
            </mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- Delete Column -->
      <ng-container cdkColumnDef="delete">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell
          fxLayoutAlign="center center"
          fxFlex="50px"
          *cdkCellDef="let row"
        >
          <button
            matSuffix
            mat-icon-button
            *ngIf="row.editable"
            matTooltip="{{ 'GENERIC.DELETE' | translate }}"
            (click)="onDelete(row)"
          >
            <mat-icon class="s-16">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        class="mat-row-color"
        *cdkRowDef="let row; columns: displayedColumns; let index = index"
        [@animate]="{ value: '*', params: { y: '100%' } }"
      >
      </mat-row>
    </mat-table>
    <mat-paginator
      #paginator
      [length]="resultsLength"
      showFirstLastButtons="true"
      (page)="datatableHelperService.onPaginator($event, 'crudTableGen')"
      [pageIndex]="0"
      [pageSize]="'crudTableGen' | tableSizePipe: 'large'"
      [pageSizeOptions]="datatableHelperService.pageSizeOptions"
    >
    </mat-paginator>
  </div>
</div>
