import { RolesService } from '../../services/roles.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GT2PageAbstract } from '../../content/abstract/GT2PageAbstract';
import { DataSource } from '@angular/cdk/collections';
import { IDynamicTableItemAction } from '../dynamic-table/dynamic-table.component';
import { of, Observable } from 'rxjs';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { NGXLogger } from 'ngx-logger';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ItemUrlFinder } from '../../utils/item-url-finder';
import { Router } from '@angular/router';
import { IconsService } from '../../services/icons.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CdkTableModule } from '@angular/cdk/table';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-collection-table-sort',
  templateUrl: './collection-table-sort.component.html',
  styleUrls: ['./collection-table-sort.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatTooltipModule,
    MatSortModule,
    TranslateModule,
    MatButtonModule,
    CdkTableModule,
    MatInputModule,
    MatTableModule,
    Gt2PrimengModule,
    CommonModule,
    MatIconModule,
  ],
})
export class CollectionTableSortComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() collectionData!: any[];
  @Output() collectionDataChange = new EventEmitter<any>();
  @Input() displayedColumns!: string[];
  @Input() dynamicTableItemAction!: IDynamicTableItemAction;
  @Input() dynamicTableItemActionEnd!: IDynamicTableItemAction;
  // dataSource: CollectionTableDataSource | null;
  dataSource?: MatTableDataSource<any>;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private logger: NGXLogger,
    public rolesService: RolesService,
    public router: Router,
    public iconsService: IconsService,
  ) {
    super();
  }

  ngOnInit() {
    // this.dataSource.sort = this.sort;
    this.dataSource = new MatTableDataSource(this.collectionData);
  }

  ngAfterViewInit() {
    //this.logger.info("ngAfterViewInit() " + this.sort);
    //this.logger.info("ngAfterViewInit() " + this.sort);

    // this.dataSource.sort = this.sort;
    // this.dataSource.sortingDataAccessor = (item, property) => {
    //     return item[property].toLocaleLowerCase();
    // };

    this.sort.sortChange.subscribe((sort: Sort) => {
      let field = this.sort?.active;
      let order = this.sort?.direction === 'asc' ? -1 : 1;
      let result = 0;
      let newData = this.collectionData?.sort((value1, value2) => {
        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else {
          if (
            field == 'event_name' ||
            field == 'guest_count' ||
            field == 'client_name' ||
            field == 'time' ||
            field === 'delivery_type_id' ||
            field === 'status' ||
            field === 'current_order_type'
          ) {
            result =
              value1[field] < value2[field]
                ? -1
                : value1[field] > value2[field]
                  ? 1
                  : 0;
          } else if (field == 'responsible_employee_name') {
            result =
              value1[field] < value2[field]
                ? -1
                : value1[field] > value2[field]
                  ? 1
                  : 0;
          }
        }
        return order * result;
      });

      this.dataSource = new MatTableDataSource(newData);
    });
    //this.logger.info("CollectionTableSortComponent >>> collectionData: " + JSON.stringify(this.collectionData));
  }

  ngOnDestroy() {}

  public reload(data: any): void {
    // this.logger.info("CollectionTableSortComponent.reload() !!!!!!!!! " + JSON.stringify(data));
    this.collectionData = data;
    this.dataSource = new MatTableDataSource(this.collectionData);
    this.changeDetectorRefs.detectChanges();
  }

  public refresh(): void {
    //this.logger.info("CollectionTableSortComponent.refresh() !!!!!!!!! ");
    this.dataSource = new MatTableDataSource(this.collectionData);
    this.changeDetectorRefs.detectChanges();
  }

  public onSelect(selected: any) {
    //this.logger.info("CollectionTableSortComponent.onSelect() -> " + JSON.stringify(selected));
    this.dynamicTableItemAction?.onItemClick(selected);
  }

  public onActionEndClick(selected: any) {
    //this.logger.info("CollectionTableSortComponent.onActionEndClick() -> " + JSON.stringify(selected));
    this.dynamicTableItemActionEnd?.onItemClick(selected);
  }

  public sortData(sort: Sort): void {
    //this.logger.info("CollectionTableSortComponent.sortData() -> ", sort);
  }

  public onNavigateToOrder(order: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(order.context));
  }

  public onNavigateToOrderSecondContext(order: any): void {
    if (order.order_context) {
      this.router.navigateByUrl(ItemUrlFinder.getItemURL(order.order_context));
    } else {
      this.onNavigateToOrder(order);
    }
  }

  public onNavigateToProjectContext(order: any): void {
    //this.logger.info("CollectionTableSortComponent.onNavigateToProjectContext() -> order: " + JSON.stringify(order));
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(order.project_context));
  }

  public onNavigateToEventVenue(order: any): void {
    const url = ItemUrlFinder.getItemURL(order.context) + '?tab=lieu';
    //this.logger.info("CollectionTableSortComponent.onNavigateToProjectContext() -> url: " + url);
    this.router.navigateByUrl(url);
  }
}

export class CollectionTableDataSource extends DataSource<any> {
  constructor(private data: any[]) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return of(this.data);
  }

  disconnect() {}
}
