import { ModulesService } from './../../../../services/modules.service';
import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter, MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventsService } from '../../../../api/events.service';
import { GT2FormPageAbstract } from '../../../../content/abstract/GT2FormPageAbstract';
import { RolesService } from '../../../../services/roles.service';
import { Settings } from '../../../../settings/settings';
import { ValidatorHelper } from '../../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { locale as english } from '../../i18n/en-CA';
import { locale as french } from '../../i18n/fr-CA';
import { EmployeesService } from '../../../../api/employees.service';
import { Gt2LocaleService } from '../../../../../localization/gt2-locale.service';
import { EventTypeService } from '../../../../api/event-type.service';
import { GT2DateAdapter } from '../../../../utils/GT2DateAdapter';
//import { LocalizationConfig } from '../../../../../localization/LocalizationConfig';
import '../../../../../../../node_modules/flatpickr/dist/flatpickr.css';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import {
  map,
  startWith,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';
import {
  NgxMaterialTimepickerModule,
  NgxTimepickerFieldComponent,
} from 'ngx-material-timepicker';
import { Select2Data, Select2Module } from 'ng-select2-component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Gt2SelectComponent } from '../../../../components/gt2-select/gt2-select.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-event-information',
  templateUrl: './event-information.component.html',
  styleUrls: ['./event-information.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    TranslateModule,
    NgxMaterialTimepickerModule,
    MatSelectModule,
    MatDatepickerModule,
    MatOptionModule,
    MatAutocompleteModule,
    Select2Module,
    FlexLayoutModule,
    CommonModule,
    MatInputModule,
    MatTooltipModule,
    MatButtonModule,
    Gt2SelectComponent,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
  ],
  animations: fuseAnimations,
  providers: [
    { provide: DateAdapter, useClass: GT2DateAdapter },
    // {provide: TIME_LOCALE, useValue: 'fr-FR'}
    // {provide: TimeAdapter, useValue: GT2TimeAdapter}
  ],
})
export class EventInformationComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() event: any;
  // event: any;
  eventUUDID: any;
  loading: boolean = false;
  editMode: boolean = false;
  form: UntypedFormGroup;
  declare dialogRef: any;
  // isDirty: boolean = false;
  @Output() modelUpdatedEvent = new EventEmitter<any>();
  timePickerChangeSubject = new Subject<TimeModel>();
  @ViewChild('ngxTimeStart')
  ngxTimeStart!: NgxTimepickerFieldComponent;
  dirty: boolean = false;
  allEventTypes: any[] = [];
  selectedEventTypeUuid: string = '';
  event_logistics_status_uuid: string = '';
  eventTypeSelect2Data: Select2Data = [];
  //only uqam
  uqam_ubr_form!: UntypedFormGroup;
  options: string[] = [];
  filteredOptions!: Observable<string[]>;
  noResultsUbr: boolean = false;
  //
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private toastr: ToastrService,
    public logger: NGXLogger,
    public location: Location,
    private dialog: MatDialog,
    public rolesService: RolesService,
    private formBuilder: UntypedFormBuilder,
    private eventsService: EventsService,
    public gt2LocalService: Gt2LocaleService,
    //private atp: any,
    public employeesService: EmployeesService,
    public eventTypeService: EventTypeService,
    private adapter: DateAdapter<any>,
    public router: Router,
    public moduleService: ModulesService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      po_number: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
        ],
      ],
      event_date: ['', [ValidatorHelper.dateRangeDefault, Validators.required]],
      event_date_end: '',
    });
    //
    if (this.moduleService.modulesAreSet && moduleService.hasUQAMReport()) {
      this.uqam_ubr_form = this.formBuilder.group({
        manager_email: ['', [Validators.email]],
        ubr_code: ['', [Validators.minLength(5)]],
      });
    }
  }

  ngOnInit(): void {
    this.loadData();
    this.event_logistics_status_uuid = this.event.event_logistics_status.uuid;
  }

  ngAfterViewInit(): void {
    // //this.logger.info("EventInformationComponent.ngAfterViewInit() -> event: " + JSON.stringify(this.event));
    this.timePickerChangeSubject
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        tap((model) => {
          if (
            model.data.event_time &&
            model.data.event_time !== 'Invalid date'
          ) {
            this.dirty = false;
            this.autosaveDataChange(model.data);
          } else if (
            model.data.event_time_end &&
            model.data.event_time_end !== 'Invalid date'
          ) {
            // this.dirty = false;
            // this.autosaveDataChange(model.data);
          } else {
            this.dirty = true;
          }
        }),
      )
      .subscribe();

    this.validateIdentity();
  }

  ngOnDestroy(): void {}

  /*     public compareELSObjects(o1: any, o2: any): boolean {
        return o1.name === o2.name && o1.uuid === o2.uuid;
    } */

  public validateIdentity(): void {
    setTimeout(() => {
      if (
        this.event &&
        this.event.informations &&
        this.event.informations.organization_identity &&
        this.event.informations.organization_identity_list &&
        this.event.informations.organization_identity_list.length > 0
      ) {
        this.event.informations.organization_identity =
          this.event.informations.organization_identity_list.find(
            (item: any) => {
              return (
                this.event.informations.organization_identity.uuid === item.uuid
              );
            },
          );
      }
    }, 25);

    // this.event.informations.organization_identity
  }
  private initEventTypeSelect2Data(data: any) {
    data.forEach((item: any, index: any) => {
      this.eventTypeSelect2Data.push({
        label: item.name,
        value: item.uuid,
      });
    });
  }
  public loadData(): void {
    this.eventTypeService;
    this.loading = true;
    this.eventTypeService.getData().subscribe(
      (response) => {
        this.allEventTypes = response.data;
        this.initEventTypeSelect2Data(this.allEventTypes);
        if (this.event.informations.event_type) {
          this.selectedEventTypeUuid = this.event.informations.event_type.uuid;
        }
        this.loading = false;
      },
      (error) => {
        //this.logger.info("EventInformationComponent.getData() -> ERROR: " + JSON.stringify(error));
        this.loading = false;
      },
    );
  }

  public onOpenTimePicker(): void {
    // //this.logger.info("EventInformationComponent.onOpenTimePicker() -> event_time: " + this.event.informations.event_time);
    // //this.logger.info("EventInformationComponent.onOpenTimePicker() -> this.translate.currentLang: " + LocalizationConfig.getCurrentLanguagePrefix());
    // const amazingTimePicker = this.atp.open({
    //   time: this.event.informations.event_time
    //     ? this.event.informations.event_time
    //     : '12:00',
    //   // theme: 'dark',
    //   locale: LocalizationConfig.getCurrentLanguagePrefix(),
    //   arrowStyle: {
    //     background: '3F51B5',
    //     color: 'white',
    //   },
    // });
    // amazingTimePicker.afterClose().subscribe((time: any) => {
    //   // //this.logger.info("EventInformationComponent.onOpenTimePicker() -> time: " + time);
    //   this.event.informations.event_time = time;
    //   this.autosaveDataChange({
    //     event_time: this.event.informations.event_time,
    //   });
    //   // this.isDirty = true;
    // });
  }
  public onOpenTimeEndPicker(): void {
    // //this.logger.info("EventInformationComponent.onOpenTimeEndPicker() -> event_time_end: " + this.event.informations.event_time_end);
    // //this.logger.info("EventInformationComponent.onOpenTimeEndPicker() -> this.translate.currentLang: " + LocalizationConfig.getCurrentLanguagePrefix());
    // const amazingTimePicker = this.atp.open({
    //   time: this.event.informations.event_time_end
    //     ? this.event.informations.event_time_end
    //     : '12:00',
    //   // theme: 'dark',
    //   locale: LocalizationConfig.getCurrentLanguagePrefix(),
    //   arrowStyle: {
    //     background: '3F51B5',
    //     color: 'white',
    //   },
    // });
    // amazingTimePicker.afterClose().subscribe((time: any) => {
    //   /* //this.logger.info(
    //             "EventInformationComponent.onOpenTimePicker() -> time: " + time
    //         ); */
    //   this.event.informations.event_time_end = time;
    //   this.autosaveDataChange({
    //     event_time_end: this.event.informations.event_time_end,
    //   });
    //   // this.isDirty = true;
    // });
  }

  public onToggleEdit(): void {
    this.editMode = !this.editMode;
    /* //this.logger.info(
            "EventInformationComponent.onEdit() -> this.editMode: " +
                this.editMode
        ); */
    // if (this.editMode) {
    //     this.setControlsChanges(this.form);
    // }
    // else {
    //     this.unsetControlsChanges();
    // }
  }

  public clearEventLogisticStatus(): void {
    /*         this.event.informations.event_date = null;
        this.form.controls['event_date'].setErrors({ invalid: true });
        this.form.controls['event_date'].updateValueAndValidity();
        this.form.controls['event_date'].markAsDirty();
        this.form.controls['event_date'].markAsTouched(); */
  }

  public clearStartDate(): void {
    this.event.informations.event_date = null;
    this.form.controls['event_date'].setErrors({ invalid: true });
    this.form.controls['event_date'].updateValueAndValidity();
    this.form.controls['event_date'].markAsDirty();
    this.form.controls['event_date'].markAsTouched();
  }

  public onSaveData(): void {
    // //this.logger.info("EventInformationComponent.onSaveData() -> dirty: " + this.form.dirty);
    // //this.logger.info("EventInformationComponent.onSaveData() -> isDirty: " + this.isDirty);
    // //this.logger.info("EventInformationComponent.onSaveData() -> valid: " + this.form.valid);
    // //this.logger.info("EventInformationComponent.onSaveData() -> event_time: " + this.event.informations.event_time);
    // if (this.form.valid && (this.isDirty || this.form.dirty))
    // {
    //   //this.logger.info("EventInformationComponent.loadData() -> informations: " + JSON.stringify({informations: this.event.informations}));
    //   this.eventsService.updateEventInformation(this.event.uuid, this.event.informations).subscribe( response =>
    //     {
    //       //this.logger.info("EventInformationComponent.loadData() -> SUCCESS: " + JSON.stringify(response));
    //       this.onDispatchModelUdpated();
    //       this.isDirty = false;
    //     }, error =>
    //     {
    //       this.logger.error("EventInformationComponent.loadData() -> ERROR: " + JSON.stringify(error));
    //       this.handleAPIError(error, this.dialog);
    //       this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
    //     });
    // }
  }

  public onManualSave(): void {
    /* //this.logger.info(
            "EventInformationComponent.onManualSave() -> " +
                JSON.stringify(this.event)
        ); */
    this.loading = true;

    // hack: invoicing_local & ordering_local are not in the informations object therefore if would not save.
    //       by injecting those 2 values in it, it will save
    this.event.informations.invoicing_local = this.event.invoicing_local;
    this.event.informations.ordering_local = this.event.ordering_local;
    //
    this.event.informations.manager_email = this.event.manager_email;

    /* //this.logger.info(
            "EventInformationComponent.onManualSave() -> data: ",
            this.event.informations.event_logistics_status
        );

        //this.logger.info(
            "EventInformationComponent.onManualSave() -> data: ",
            this.event.event_logistics_status
        ); */
    this.event.event_logistics_status =
      this.event.informations.event_logistics_status;

    this.getUbrObject(this.event.code_ubr);
    //
    this.eventsService
      .updateEventInformation(this.event.uuid, this.event.informations)
      .subscribe(
        (response: any) => {
          /* //this.logger.info(
                        "EventInformationComponent.loadData() -> SUCCESS"
                    ); */
          ////this.logger.info("EventInformationComponent.loadData() -> SUCCESS: " + JSON.stringify(response));
          this.form.markAsPristine();
          this.form.markAsUntouched();
          this.onDispatchModelUdpated();
          // this.validateIdentity();
        },
        (error: any) => {
          this.logger.error(
            'EventInformationComponent.loadData() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(
            error,
            this.dialog,
            this.toastr,
            this.translate.instant('GENERIC.ERRORS.GENERIC'),
          );
          // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
        },
      );
  }

  public override autosaveDataChange(data: any): void {
    // //this.logger.info('EventInformationComponent.autosaveDataChange() -> data: ' + JSON.stringify(data));
    // //this.logger.info("EventInformationComponent.autosaveDataChange() -> event.informations.event_date: " + this.event.informations.event_date);
    // //this.logger.info("EventInformationComponent.autosaveDataChange() -> this.form.get('event_date').value: " + this.form.get("event_date").value);
    // //this.logger.info("EventInformationComponent.autosaveDataChange() -> BEFORE SAVE MODEL: " + JSON.stringify(this.event));
    // this.loading = true;
    // this.eventsService.updateEventInformation(this.event.uuid, data).subscribe(response => {
    //     // //this.logger.info("EventInformationComponent.loadData() -> SUCCESS: " + JSON.stringify(response));
    //     this.form.markAsPristine();
    //     this.form.markAsUntouched();
    //     this.onDispatchModelUdpated();
    //     // this.validateIdentity();
    // }, error => {
    //     this.logger.error('EventInformationComponent.loadData() -> ERROR: ' + JSON.stringify(error));
    //     this.handleAPIError(error, this.dialog, this.toastr, this.translate.instant('GENERIC.ERRORS.GENERIC'));
    //     // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
    // });
  }

  public onDateChange(event: string): void {
    // //this.logger.info("EventInformationComponent.onDateChange() -> event: " + event);
    // //this.logger.info("EventInformationComponent.onDateChange() -> event.toString(): " + event.toString());
    // //this.logger.info("EventInformationComponent.onDateChange() -> JSON.stringify(event): " + JSON.stringify(event));
    // //this.logger.info("EventInformationComponent.onDateChange() -> JSON.stringify({event: event}): " + JSON.stringify({event: event}));
    // //this.logger.info("EventInformationComponent.onDateChange() -> value in binded model -> event.informations.event_date: " + this.event.informations.event_date);
  }

  public onDateEndChange(event: string): void {
    // //this.logger.info("EventInformationComponent.onDateEndChange() -> event: " + event);
    // //this.logger.info("EventInformationComponent.onDateEndChange() -> event.toString(): " + event.toString());
    // //this.logger.info("EventInformationComponent.onDateEndChange() -> JSON.stringify(event): " + JSON.stringify(event));
    // //this.logger.info("EventInformationComponent.onDateEndChange() -> JSON.stringify({event: event}): " + JSON.stringify({event: event}));
    // //this.logger.info("EventInformationComponent.onDateEndChange() -> value in binded model -> event.informations.event_date: " + this.event.informations.event_date);
  }

  public onTimeChange($event: any, event: any): void {
    // //this.logger.info(
    //     "EventInformationComponent.onTimeChange() -> event: " +
    //         JSON.stringify(event)
    // );
    // //this.logger.info(
    //     "EventInformationComponent.onTimeChange() -> event.informations.event_time: " +
    //         event.informations.event_time
    // );
    // const time = moment(this.event.informations.event_time).format('HHmm');
    const time = moment(this.event.informations.event_time, 'h:mm A').format(
      'HH:mm',
    );
    /* //this.logger.info(
            "EventInformationComponent.onTimeChange() -> time: " + time
        ); */
    this.timePickerChangeSubject.next({ data: { event_time: time } });

    // this.autosaveDataChange({ event_time: time });
  }

  public onTimeEndChange($event: any, event: any): void {
    // //this.logger.info("EventInformationComponent.onTimeChange() -> event: " + JSON.stringify(event));
    /* //this.logger.info(
            "EventInformationComponent.onTimeEndChange() -> event.informations.event_time_end: " +
                event.informations.event_time_end
        ); */
    // const time = moment(this.event.informations.event_time).format('HHmm');
    const time = moment(
      this.event.informations.event_time_end,
      'h:mm A',
    ).format('HH:mm');
    /* //this.logger.info(
            "EventInformationComponent.onTimeEndChange() -> time: " + time
        ); */
    // this.autosaveDataChange({ event_time_end: time });

    this.timePickerChangeSubject.next({ data: { event_time_end: time } });
  }

  public onOrderingLocalSelected(event: any): void {
    this.event.ordering_local = event;
    /* //this.logger.info(
            "EventInformationComponent.onOrderingLocalSelected() -> event: " +
                JSON.stringify(event)
        ); */
    this.autosaveDataChange({ ordering_local: this.event.ordering_local });
  }

  public onInvoicingLocalSelected(event: any): void {
    this.event.invoicing_local = event;
    /* //this.logger.info(
            "EventInformationComponent.onInvoicingLocalSelected() -> event: " +
                JSON.stringify(event)
        ); */
    this.autosaveDataChange({
      invoicing_local: this.event.invoicing_local,
    });
  }

  public onResponsibleEmployeeSelected(event: any): void {
    // //this.logger.info("EventInformationComponent.onResponsibleEmployeeSelected() -> event: " + JSON.stringify(event));
    this.event.responsible_employee = event;
    this.autosaveDataChange({
      responsible_employee: this.event.responsible_employee,
    });
  }

  public onEventTypeSelected(event: any): void {
    let selected = null;
    if (event.value && this.allEventTypes) {
      selected = this.allEventTypes.find((item) => {
        return event.value === item.uuid;
      });
    }
    this.selectedEventTypeUuid = selected ? selected.uuid : '';
    this.event.informations.event_type = selected;
    this.autosaveDataChange({
      event_type: this.event.informations.event_type,
    });
  }

  public onEventLogisticStatusSelected(event: any): void {
    /* //this.logger.info("onEventLogisticStatusSelected");
        //this.logger.info(
            "EventInformationComponent.onEventLogisticStatusSelected() -> event: ",
            event.value
        ); */
    let selected = null;
    if (event.value && this.event.event_logistics_status_list) {
      selected = this.event.event_logistics_status_list.find((item: any) => {
        return event.value === item.uuid;
      });
    }
    ////this.logger.info("onEventLogisticStatusSelected", selected);
    this.event_logistics_status_uuid = selected ? selected.uuid : '';
    /* //this.logger.info(
            "onEventLogisticStatusSelected",
            this.event_logistics_status_uuid
        ); */
    this.event.informations.event_logistics_status = selected;
    /*         this.autosaveDataChange({
            event_type: this.event.informations.event_type,
        });  */
  }
  public onRoomChange(event: any): void {
    /* //this.logger.info(
            "EventInformationComponent.onRoomChange() -> event: " +
                JSON.stringify(this.event.informations.multi_takeout_input)
        ); */
    this.event.informations.multi_takeout_input = event;
    this.autosaveDataChange({
      multi_takeout_input: this.event.informations.multi_takeout_input,
    });
  }

  public onIdentityChange(event: any): void {
    // //this.logger.info('EventInformationComponent.onIdentityChange() -> event: ' + JSON.stringify(event.value));
    this.event.informations.organization_identity = event.value;
    this.autosaveDataChange({
      organization_identity: this.event.informations.organization_identity,
    });
  }

  public onRoomStatusChange(event: any): void {
    this.event.room_reservations.status = event.value;
  }

  public onModelLoaded(): void {
    this.validateIdentity();
  }

  public onDispatchModelUdpated(): void {
    // //this.logger.info('EventInformationComponent.onDispatchModelUdpated()');
    this.modelUpdatedEvent.next(undefined);
  }

  public async populateUbrCodes(event: any) {
    ////this.logger.info('populateUbrCodes => ' + event);
    if (event && event.length > 4) {
      this.options = [];
      //this.ubrService.ubr_string = event;
      const result = await this.eventsService.getUbrList(event);
      if (result.length == 0) {
        this.noResultsUbr = true;
      } else {
        this.noResultsUbr = false;
      }
      this.options = this.eventsService.ubrList;
      this.filteredOptions = this.uqam_ubr_form?.controls[
        'ubr_code'
      ].valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || '')),
      );
    }
  }

  public getUbrObject(code_ubr: string) {
    this.event.informations.ubr = null;
    for (const i in this.eventsService.uberData) {
      if (this.eventsService.uberData[i].ubr_code == code_ubr) {
        this.event.informations.ubr = this.eventsService.uberData[i];
        return;
      }
    }
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    ////this.logger.info('log options => ' + JSON.stringify(this.options));
    return this.options.filter((option) =>
      this._normalizeValue(option).includes(filterValue),
    );
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
}

export interface TimeModel {
  data: any;
}
