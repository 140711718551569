import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseSplashScreenService } from '../../../../@fuse/services/splash-screen.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fuseAnimations } from '../../../../@fuse/animations/index';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { AuthenticationService } from '../../../gt2/api/auth-service.service';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
import { GenericAlertComponent } from '../../components/generic-alert/generic-alert.component';
import { AppRoutes } from '../../const/AppRoutes';
import { JwtInterceptor } from '../../interceptors/JwtInterceptor';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { LoginErrorsDialogComponent } from './login-errors-dialog/login-errors-dialog.component';
import { environment } from '../../../../environments/environment';
import { Gt2ApiService } from '../../api/gt2-api.service';
import { switchTheme } from '../../../fuse-config';
import { IconsService } from '../../services/icons.service';
import { AppRoutesService } from '../../services/app-routes.service';

@Component({
  selector: 'fuse-login-2',
  templateUrl: './login-2.component.html',
  styleUrls: ['./login-2.component.scss'],
  animations: fuseAnimations,
})
export class FuseLogin2Component implements OnInit {
  static REMEMBER_ME_LS: string = 'REMEMBER_ME_LS';

  readonly environment = environment;
  buttonLabel: string =
    localStorage.getItem(LocalizationConfig.LOCAL_STORED_LANG_ID) === 'en-CA'
      ? 'Français'
      : 'English';
  loginForm: UntypedFormGroup | any;
  loginFormErrors: any;
  callingAPI: boolean = false;
  error401: boolean = false;
  languages: any;
  password: string = 'password';
  selectedLanguage: any;
  userEmailModel: string = '';
  rememberMeChecked: boolean = false;
  show: boolean = false;
  dialogRef: any;

  constructor(
    private fuseConfig: FuseConfigService,
    private translationLoader: FuseTranslationLoaderService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,

    public appRoutesService: AppRoutesService,
    public iconsService: IconsService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private deviceService: DeviceDetectorService,
    private auth: AuthenticationService,
    public api: Gt2ApiService
  ) {
    this.translationLoader.loadTranslations(english, french);

    // language
    this.languages = LocalizationConfig.LANGUAGES;
    this.selectedLanguage = LocalizationConfig.getCurrentLanguage();

    this.fuseConfig.setConfig({
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    });

    this.loginFormErrors = {
      email: {},
      password: {},
    };
  }

  public ngOnInit() {
    const currentUrl = window.location.href;
    if (currentUrl.includes('gestion-traiteur.app')) {
      localStorage.setItem(LocalizationConfig.LOCAL_STORED_LANG_ID, 'fr-CA');
    }
    if (currentUrl.includes('caterer-manager.app')) {
      localStorage.setItem(LocalizationConfig.LOCAL_STORED_LANG_ID, 'en-CA');
    }

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    this.loginForm.valueChanges.subscribe(() => {
      this.onLoginFormValuesChanged();
    });

    const lsEmail: string =
      localStorage.getItem(FuseLogin2Component.REMEMBER_ME_LS) || '';
    if (lsEmail && !(lsEmail == undefined)) {
      this.userEmailModel = lsEmail;
      this.rememberMeChecked = true;
    }
    this._fuseSplashScreenService.hide();
  }

  public onLoginFormValuesChanged() {
    for (const field in this.loginFormErrors) {
      if (!this.loginFormErrors.hasOwnProperty(field)) {
        continue;
      }
      this.loginFormErrors[field] = {};

      const control = this.loginForm?.get(field);

      if (control && control.dirty && !control.valid) {
        this.loginFormErrors[field] = control.errors;
      }
    }
  }

  public onPasswordShowHide(): void {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  public onLogin(): void {
    if (this.loginForm?.valid) {
      const email = this.loginForm.get('email');
      const password = this.loginForm.get('password');
      this.error401 = false;
      this.callingAPI = true;

      if (this.rememberMeChecked) {
        localStorage.setItem(
          FuseLogin2Component.REMEMBER_ME_LS,
          this.userEmailModel
        );
      }
      const userAgent: any = {
        browser: this.deviceService.browser,
        browser_version: this.deviceService.browser_version,
        os: this.deviceService.os,
        os_version: this.deviceService.os_version,
        is_desktop: this.deviceService.isDesktop(),
        is_mobile: this.deviceService.isMobile(),
        is_tablet: this.deviceService.isTablet(),
        device: null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      this.auth.login(email?.value, password?.value, userAgent).subscribe(
        (response: any) => {
          this.callingAPI = false;
          this.redirectToLandingPage();
        },
        (error: any) => {
          this.callingAPI = false;

          if (error.status === 401) {
            this.error401 = true;
            this.showError401();
          } else if (error.status === 400) {
            this.dialogRef = this.dialog.open(LoginErrorsDialogComponent, {
              width: '45%',
              minWidth: 350,
              panelClass: 'scrollable-dialog',
              disableClose: false,
            });

            this.dialogRef.afterClosed().subscribe((result: any) => {
              this.dialogRef = null;
            });
          } else if (error.status === 404) {
            this.dialogRef = this.dialog.open(GenericAlertComponent, {
              width: '45%',
              minWidth: 350,
              panelClass: 'scrollable-dialog',
              disableClose: false,
            });
            this.dialogRef.componentInstance.alertMessage =
              'Le serveur est inaccessible en ce moment!';

            this.dialogRef.afterClosed().subscribe((result: any) => {
              this.dialogRef = null;
            });
          } else if (error.status === 422) {
            let errorMessage: string = '';
            Object.keys(error.error.errors).map((key) => {
              errorMessage += error.error.errors[key].toString() + '<br />';
            });

            this.dialogRef = this.dialog.open(GenericAlertComponent, {
              width: '45%',
              minWidth: 350,
              panelClass: 'scrollable-dialog',
              disableClose: false,
            });

            this.dialogRef.componentInstance.useCancel = false;
            this.dialogRef.componentInstance.useHTML = true;
            this.dialogRef.componentInstance.alertMessage = errorMessage;
            this.dialogRef.afterClosed().subscribe((result: any) => {
              this.dialogRef = null;
            });
          }
        }
      );
    }
  }
  public showError401(): void {
    this.dialogRef = this.dialog.open(GenericAlertComponent, {
      width: '45%',
      minWidth: '350px',
      panelClass: 'scrollable-dialog',
      disableClose: false,
    });
    this.dialogRef.componentInstance.alertMessage = this.translate.instant(
      'LOGIN.ERROR_401_MESSAGE'
    );
    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.dialogRef = null;
    });
  }
  public redirectToLandingPage(): void {
    if (
      JwtInterceptor.LAST_URL_401 &&
      !(JwtInterceptor.LAST_URL_401 == undefined) &&
      JwtInterceptor.LAST_URL_401.indexOf(AppRoutes.LOGIN) < 0
    ) {
      this.router.navigateByUrl(JwtInterceptor.LAST_URL_401);
      JwtInterceptor.LAST_URL_401 = null;
    } else {
      this.router.navigateByUrl(AppRoutes.DASHBOARD);
    }
  }
  public onRememberMeChange(event: any): void {
    this.rememberMeChecked = event.checked;
    if (!event.checked) {
      localStorage.removeItem(FuseLogin2Component.REMEMBER_ME_LS);
    }
  }
  toggleLanguage(): void {
    const currentUrl = window.location.href;
    const replacements = {
      'gestion-traiteur.app/login': 'caterer-manager.app/login',
      'gestion-traiteur.app/authentification': 'caterer-manager.app/login',
      'caterer-manager.app/authentification':
        'gestion-traiteur.app/authentification',
      'caterer-manager.app/login': 'gestion-traiteur.app/authentification',
    };

    for (const [oldUrl, newUrl] of Object.entries(replacements)) {
      if (currentUrl.includes(oldUrl)) {
        window.location.href = currentUrl.replace(oldUrl, newUrl);
        return;
      }
    }
  }
  toggleTheme(): void {
    switchTheme();
  }
}
