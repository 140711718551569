import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GT2FormPageAbstract } from '../../../../content/abstract/GT2FormPageAbstract';
import { RolesService } from '../../../../services/roles.service';
import { ItemUrlFinder } from '../../../../utils/item-url-finder';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from '../../i18n/en-CA';
import { locale as french } from '../../i18n/fr-CA';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-event-documents',
  templateUrl: './event-documents.component.html',
  styleUrls: ['./event-documents.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatInputModule,
    MatTooltipModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  animations: fuseAnimations,
})
export class EventDocumentsComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() event: any;
  @Output() editableChange = new EventEmitter<boolean>();
  @Input() editable: boolean = true;
  loading: boolean = false;
  editMode: boolean = false;
  declare dialogRef: any;
  @Output() modelUpdatedEvent = new EventEmitter<any>();

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    public logger: NGXLogger,
    public location: Location,
    public rolesService: RolesService,
    public router: Router,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // //this.logger.info("EventDocumentsComponent.ngAfterViewInit() -> event: " + JSON.stringify(this.event));
  }

  ngOnDestroy() {}

  public onToggleEdit(): void {
    this.editMode = !this.editMode;
    // //this.logger.info("EventDocumentsComponent.onEdit() -> this.editMode: " + this.editMode);
  }

  public onSaveData(): void {
    //this.logger.info("EventDocumentsComponent.onSaveData()");
  }

  public onManualSave(): void {
    //this.logger.info("EventDocumentsComponent.onManualSave()");
  }

  public onModelLoaded(): void {}

  public onDispatchModelUdpated(): void {
    //this.logger.info("EventDocumentsComponent.onDispatchModelUdpated()");
    this.modelUpdatedEvent.next(undefined);
  }

  public onOpenPage(item: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(item));
  }

  public onOpenOrderInNewTab(order: any): void {
    //this.logger.info("EventDocumentsComponent.onOpenOrderInNewTab()");
  }

  public onOpenItemInNewTab(item: any): void {
    //this.logger.info("EventDocumentsComponent.onOpenItemInNewTab()");
    window.open(ItemUrlFinder.getItemURL(item), '_blank');
  }
}
