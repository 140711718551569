export const locale = {
  lang: "en-CA",
  data: {
    EVENT_LOGISTIC_STATUS: {
      TITLE: "EN-Statut logistique",
      LIST_COLUMN_NAME: "Name",
      CREATE_DIALOG_TITLE: "EN-Nouveau statut logistique",
      CREATE_DIALOG_PLACEHOLDER: "EN-Nom du statut logistique",
      CREATE_SUCCESS: "Logistic status created successfully",
      EDIT_SUCCESS: "Logistic status modified successfully",
      DELETE_SUCCESS: "CateLogistic status deleted successfully",
    },
  },
};
