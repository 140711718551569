export const locale = {
  lang: "en-CA",
  data: {
    MATERIAL_CATEGORIES: {
      CREATE_NEW_CATEGORY: "New equipment category",
      CATEGORY_NAME: "Equipment category name",
      ACCOUNTING_NUMBER: "Accounting number",
      NEW_CATEGORY_NAME: "Name of new equipment",

      CREATE_SUCCESS: "Equipment category created successfully",
      EDIT_SUCCESS: "Equipment category  modified successfully",
      DELETE_SUCCESS: "Equipment category  deleted successfully",
      LEDGER_ACCOUNT: "Ledger account",
    },
  },
};
