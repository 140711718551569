import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import { CrudEmployeeService } from '../../../content/crud/crud-employee/crud-employee.service';
import { Settings } from '../../../settings/settings';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class CreateEmployeeComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  employee: any;
  loading: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<CreateEmployeeComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private employeeService: CrudEmployeeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();

    this.translationLoader.loadTranslations(english, french);
    this.form = this.formBuilder.group({
      first_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      last_name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.employee = {
      first_name: null,
      last_name: null,
    };
  }

  ngOnInit() {
    //this.logger.info("CreateEmployeeComponent.ngOnInit() -> ");
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateEmployee(): void {
    //this.logger.info("CreateEmployeeComponent.onCreateEmployee() -> " + this.form.valid);
    this.loading = true;
    // //this.logger.info("CreateEmployeeComponent.onCreateEmployee() -> " + this.form.valid);
    // //this.logger.info("CreateEmployeeComponent.onCreateEmployee() -> this.employee: " + JSON.stringify(this.employee));
    this.employeeService.createEmployee(this.employee).subscribe(
      (response) => {
        // //this.logger.info('CreateEmployeeComponent.onCreateEmployee() -> SUCCESS: ' + JSON.stringify(response));
        this.loading = false;
        this.toastr.success(
          this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_EMPLOYEE'),
          '',
        );
        this.dialogRef.close(response.data);
      },
      (error) => {
        this.logger.error(
          'CreateEmployeeComponent.onCreateEmployee() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.loading = false;
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
        // this.toastr.error(this.translate.instant("GENERIC.ERRORS.GENERIC"));
      },
    );
  }
}
