export const locale = {
  lang: "en-CA",
  data: {
    ADDRESS: {
      ADDRESS: "Address",
      SEARCH_ADDRESS: "Search an address",
      SEE_ON_GOOGLE_MAP: "See Google Map",
      NO_GEO_LOCATION: "No geo location permission granted.",
      ADDRESS_NUMBER: "Address number",
      STREET: "Street",
      APP_NUMBER: "App #",
      LINE_2: "Address line 2",
      CITY: "City",
      PROVINCE: "Province/State",
      COUNTRY: "Country",
      POSTAL_CODE: "Postal/Zip Code",
      SECTOR: "Sector",
      NO_ADDRESS_INFO: "No address information",
      NAME: "Place name",
      ON_SITE_CONTACT: "Delivery and contact person on site",
    },
  },
};
