export const locale = {
  lang: "fr-CA",
  data: {
    INVITE_WIZARD: {
      WELCOME_MESSAGE: "fr-Welcome to the FUSE!",
      WELCOME_LONG_MESSAGE: "",

      SETUP_ACCOUNT: "fr-SETUP YOUR ACCOUNT",

      PROFILE_LABEL: "fr-Profil",
      PROFILE_INFO: "fr-Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      FIRST_NAME: "fr-First name",
      FIRST_NAME_REQUIRED: "fr-First name is required",
      LAST_NAME: "fr-Last name",
      LAST_NAME_REQUIRED: "fr-Last name is required",
      PASSWORD: "fr-Password",
      PASSWORD_REQUIRED: "fr-Password is required",
      PASSWORD_AGAIN: "fr-Password again",
      PASSWORD_NO_MATCH: "fr-Passwords does not match",
      PHONE: "fr-Phone number",
      PHONE_REQUIRED: "fr-Phone number is required",
      PHONE_EXT: "fr-Phone number extension",

      FINALIZE_SUCCESS_MESSAGE: "fr-Congratulation! Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      REGISTER_ACCOUNT: "fr-REGISTER YOUR ACCOUNT",
      ALREADY_HAVE_ACCOUNT: "fr-Already have an account?",

      USER_ALREADY_EXIST: "fr-This user already exist in our database.",
      USER_NOT_FOUND: "fr-We can't find this user in our database or this account creation has already been completed",
    },
  },
};
