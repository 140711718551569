<div fxLayout="column">
  <div class="mat-title">{{ "SETTINGS.DELIVERY" | translate }}</div>

  <div fxLayout="column" fxFlex="100%" class="p-12">
    <div fxLayout="column" fxFlex="50%" class="mr-12">
      <div [formGroup]="form" class="" fxLayout="column">
        <!-- DO NOT DELETE, WILL BE UNCOMMENTED LATER -->
        <!-- <mat-form-field class="form-input-container mb-16">
          <input matInput placeholder="{{ 'SETTINGS.DELIVERY_FIXED_COST' | translate }}" formControlName="delivery_fixed_cost"
            type="text" 
            pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            [(ngModel)]="settingsModel.delivery_fixed_cost">
          <mat-error *ngIf="form.controls['delivery_fixed_cost'].invalid">
            {{'GENERIC.INPUT_NUMBER_0_999999' | translate }}{{'GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-input-container mb-16" fxFlex="100%">
          <input matInput placeholder="{{ 'SETTINGS.DELIVERY_MAX_DISTANCE_KM' | translate }}" formControlName="delivery_max_distance_km"
          pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
            type="text" [(ngModel)]="settingsModel.delivery_max_distance_km">
          <mat-error *ngIf="form.controls['delivery_max_distance_km'].invalid">
            {{'GENERIC.INPUT_NUMBER_0_999999' | translate }}{{'GENERIC.INPUT_NUMBER_POSTFIX_2_DECIMAL' | translate }}
          </mat-error>
        </mat-form-field> -->

        <app-gt2-select
          #ledgerAccountSelect
          [service]="ledgerAccountService"
          [placeHolder]="'SETTINGS.LEDGER_ACCOUNT' | translate"
          [label]="'SETTINGS.LEDGER_ACCOUNT' | translate"
          [labelKey]="'name_label'"
          [selectedModel]="settingsModel.ledger_account_delivery"
          (changeEvent)="onLedgerAccountChange($event)"
          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
          [nullableUseNone]="false"
          class=""
        ></app-gt2-select>

        <app-gt2-select
          [service]="taxesService"
          [placeHolder]="'SETTINGS.TAXES_SELECT' | translate"
          [label]="'SETTINGS.TAXES' | translate"
          [selectedModel]="settingsModel.delivery_tax"
          (changeEvent)="onTaxesSelected($event)"
          [noResultsLabel]="translate.instant('GENERIC.NO_RESULTS')"
          fxFlex="100%"
          class=""
        ></app-gt2-select>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" fxFlex="100%" class="mt-12">
    <button
      mat-raised-button
      color="accent"
      class=""
      [disabled]="form.invalid"
      (click)="onSaveData()"
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
  </div>
</div>
