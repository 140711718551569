import { FuseNavigation } from "../../@fuse/types";
import { AppRoutes } from "../gt2/const/AppRoutes";
import { IconsService } from "../gt2/services/icons.service";

export const navigation: FuseNavigation[] = [
  {
    id: "applications",
    title: "Applications",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      {
        id: "dashboard-detailed-jul",
        title: "Tableau de bord JUL",
        translate: "NAV.DASHBOARD_DETAILED_JUL.TITLE",
        type: "item",
        icon: "dashboard_customize",
        url: AppRoutes.DASHBOARD_JUL,
      },
      {
        id: "dashboard",
        title: "Tableau de bord",
        translate: "NAV.DASHBOARD.TITLE",
        type: "item",
        icon: "dashboard",
        url: AppRoutes.DASHBOARD,
      },
      {
        id: "dashboard-detailed",
        title: "Tableau de bord détaillé",
        translate: "NAV.DASHBOARD_DETAILED.TITLE",
        type: "item",
        icon: "dashboard_customize",
        url: AppRoutes.DASHBOARD_DETAILED,
      },

      {
        id: "room-management",
        title: "Gestion des salles",
        translate: "NAV.ROOM_MANAGEMENT.TITLE",
        type: "item",
        icon: "meeting_room",
        url: AppRoutes.ROOM_MANAGEMENT,
      },
      {
        id: "congresses",
        title: "Congrès",
        translate: "NAV.CONGRESSES.TITLE",
        type: "item",
        icon: "groups",
        url: AppRoutes.CONGRESSES,
      },
      {
        id: "products",
        title: "Produits",
        translate: "NAV.PRODUCTS.TITLE",
        type: "item",
        // 'icon' : 'restaurant_menu',
        icon: IconsService.getIconStatic("PRODUCT"),
        url: AppRoutes.PRODUCTS,
      },
      {
        id: "clients",
        title: "Clients et entreprises",
        translate: "NAV.CLIENTS.TITLE",
        type: "item",
        // 'icon' : 'fa-user-circle',
        icon: IconsService.getIconStatic("CLIENT"),
        iconFA: true,
        url: AppRoutes.CLIENTS,
      },
      // {
      //     'id'   : 'orders',
      //     'title': 'Commandes',
      //     'translate': 'NAV.ORDERS.TITLE',
      //     'type' : 'item',
      //     'icon' : 'receipt',
      //     'url'  : AppRoutes.ORDERS
      // },
      {
        id: "employees",
        title: "Employés",
        translate: "NAV.EMPLOYEES.TITLE",
        type: "item",
        // 'icon' : 'group',
        icon: IconsService.getIconStatic("EMPLOYEES"),
        url: AppRoutes.EMPLOYEES,
      },
      {
        id: "reports",
        title: "Rapports",
        translate: "NAV.REPORTS.TITLE",
        type: "item",
        // 'icon' : 'insert_chart',
        icon: IconsService.getIconStatic("REPORT"),
        url: AppRoutes.REPORTS,
      },
      {
        id: "materials",
        title: "Matériels",
        translate: "NAV.MATERIALS.TITLE",
        type: "item",
        icon: IconsService.getIconStatic("MATERIAL"),
        url: AppRoutes.MATERIALS,
      },
      {
        id: "upsale",
        title: "Vente incitative",
        translate: "NAV.UPSALE.TITLE",
        type: "item",
        // 'icon' : 'restaurant_menu',
        icon: IconsService.getIconStatic("UPSALE"),
        url: AppRoutes.UPSALE,
      },
      {
        id: "management",
        title: "Gestion",
        translate: "NAV.MANAGEMENT.TITLE",
        type: "item",
        // 'icon' : 'fa-clipboard',
        icon: IconsService.getIconStatic("MANAGEMENT"),
        iconFA: true,
        url: AppRoutes.MANAGEMENT,
      },
      {
        id: "venues",
        title: "Lieux",
        translate: "NAV.VENUES.TITLE",
        type: "item",
        icon: IconsService.getIconStatic("PLACE"),
        url: AppRoutes.VENUES,
      },
      {
        id: "shops",
        title: "Commande en ligne",
        translate: "NAV.SHOPS.TITLE",
        type: "item",
        icon: IconsService.getIconStatic("SHOPPING"),
        url: AppRoutes.SHOPS,
      },
      {
        id: "settings",
        title: "Préférences",
        translate: "NAV.SETTINGS.TITLE",
        type: "item",
        // 'icon' : 'settings',
        icon: IconsService.getIconStatic("SETTINGS"),
        url: AppRoutes.SETTINGS + "/categories-clients", // TODO: replace when bilingual solution and url are sets.
      },
      {
        id: "support",
        title: "Support",
        translate: "NAV.SUPPORT.TITLE",
        type: "collapsable",
        // 'icon' : 'help',
        icon: IconsService.getIconStatic("HELP"),
        // 'url'  : '/support',
        children: [
          {
            id: "contact",
            title: "Demande de ticket",
            translate: "NAV.CONTACT.TITLE",
            type: "item",
            // 'icon' : 'help',
            externalUrl: true,
            openInNewTab: true,
            url: "https://gestiontraiteur.com/demande-de-ticket/", // AppRoutes.CONTACT,
          },
          {
            id: "documentation",
            title: "Base de connaissance & FAQ",
            translate: "NAV.DOCUMENTATION.TITLE",
            type: "item",
            // 'icon' : 'help',
            url: AppRoutes.DOCUMENTATION,
          },
          {
            id: "release-note",
            title: "Note de mise à jour",
            translate: "NAV.RELEASE_NOTES.TITLE",
            type: "item",
            // 'icon' : 'help',
            url: AppRoutes.RELEASE_NOTES,
          },
          {
            id: "kb",
            title: "Knowledge Base",
            translate: "NAV.KB.TITLE",
            type: "item",
            // 'icon' : 'help',
            url: AppRoutes.KB,
          },
          {
            id: "faq",
            title: "Foire aux Questions",
            translate: "NAV.FAQ.TITLE",
            type: "item",
            // 'icon' : 'help',
            url: AppRoutes.FAQ,
          },
        ],
      },
    ],
  },
  // {
  //     id: "applications2",
  //     title: "Applications",
  //     translate: "NAV.APPLICATIONS",
  //     type: "group",
  //     children: [
  //         {
  //             id: "dashboard2",
  //             title: "Tableau de bord",
  //             translate: "NAV.DASHBOARD.TITLE",
  //             type: "item",
  //             icon: "dashboard",
  //             url: AppRoutes.DASHBOARD,
  //         },
  //     ],
  // },
];

export const navigationEn: FuseNavigation[] = [
  {
    id: "applications",
    title: "Applications",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      {
        id: "dashboard-detailed-jul",
        title: "Dashboard JUL",
        translate: "NAV.DASHBOARD_DETAILED_JUL.TITLE",
        type: "item",
        icon: "dashboard_customize",
        url: AppRoutes.DASHBOARD_JUL,
      },
      {
        id: "dashboard",
        title: "Dashboard",
        translate: "NAV.DASHBOARD.TITLE",
        type: "item",
        icon: "dashboard",
        url: AppRoutes.DASHBOARD,
      },
      {
        id: "dashboard-detailed",
        title: "Advanced Dashboard",
        translate: "NAV.DASHBOARD_DETAILED.TITLE",
        type: "item",
        icon: "dashboard_customize",
        url: AppRoutes.DASHBOARD_DETAILED,
      },

      {
        id: "room-management",
        title: "Room management",
        translate: "NAV.ROOM_MANAGEMENT.TITLE",
        type: "item",
        icon: "meeting_room",
        url: AppRoutes.ROOM_MANAGEMENT,
      },
      {
        id: "congresses",
        title: "Congresses",
        translate: "NAV.CONGRESSES.TITLE",
        type: "item",
        icon: "groups",
        url: AppRoutes.CONGRESSES,
      },
      {
        id: "products",
        title: "Products",
        translate: "NAV.PRODUCTS.TITLE",
        type: "item",
        // 'icon' : 'restaurant_menu',
        icon: IconsService.getIconStatic("PRODUCT"),
        url: AppRoutes.PRODUCTS,
      },
      {
        id: "clients",
        title: "Clients and enterprises",
        translate: "NAV.CLIENTS.TITLE",
        type: "item",
        // 'icon' : 'fa-user-circle',
        icon: IconsService.getIconStatic("CLIENT"),
        iconFA: true,
        url: AppRoutes.CLIENTS,
      },
      // {
      //     'id'   : 'orders',
      //     'title': 'Commandes',
      //     'translate': 'NAV.ORDERS.TITLE',
      //     'type' : 'item',
      //     'icon' : 'receipt',
      //     'url'  : AppRoutes.ORDERS
      // },
      {
        id: "employees",
        title: "Employees",
        translate: "NAV.EMPLOYEES.TITLE",
        type: "item",
        // 'icon' : 'group',
        icon: IconsService.getIconStatic("EMPLOYEES"),
        url: AppRoutes.EMPLOYEES,
      },
      {
        id: "reports",
        title: "Reports",
        translate: "NAV.REPORTS.TITLE",
        type: "item",
        // 'icon' : 'insert_chart',
        icon: IconsService.getIconStatic("REPORT"),
        url: AppRoutes.REPORTS,
      },
      {
        id: "materials",
        title: "Equipments",
        translate: "NAV.MATERIALS.TITLE",
        type: "item",
        icon: IconsService.getIconStatic("MATERIAL"),
        url: AppRoutes.MATERIALS,
      },
      {
        id: "upsale",
        title: "Upsale",
        translate: "NAV.UPSALE.TITLE",
        type: "item",
        // 'icon' : 'restaurant_menu',
        icon: IconsService.getIconStatic("UPSALE"),
        url: AppRoutes.UPSALE,
      },
      {
        id: "management",
        title: "Management",
        translate: "NAV.MANAGEMENT.TITLE",
        type: "item",
        // 'icon' : 'fa-clipboard',
        icon: IconsService.getIconStatic("MANAGEMENT"),
        iconFA: true,
        url: AppRoutes.MANAGEMENT,
      },
      {
        id: "venues",
        title: "Places",
        translate: "NAV.VENUES.TITLE",
        type: "item",
        icon: IconsService.getIconStatic("PLACE"),
        url: AppRoutes.VENUES,
      },
      {
        id: "shops",
        title: "Shops",
        translate: "NAV.SHOPS.TITLE",
        type: "item",
        icon: IconsService.getIconStatic("SHOPPING"),
        url: AppRoutes.SHOPS,
      },
      {
        id: "settings",
        title: "Settings",
        translate: "NAV.SETTINGS.TITLE",
        type: "item",
        // 'icon' : 'settings',
        icon: IconsService.getIconStatic("SETTINGS"),
        url: AppRoutes.SETTINGS + "/categories-clients", // TODO: replace when bilingual solution and url are sets.
      },
      {
        id: "support",
        title: "Support",
        translate: "NAV.SUPPORT.TITLE",
        type: "collapsable",
        // 'icon' : 'help',
        icon: IconsService.getIconStatic("HELP"),
        // 'url'  : '/support',
        children: [
          {
            id: "contact",
            title: "Ticket request",
            translate: "NAV.CONTACT.TITLE",
            type: "item",
            // 'icon' : 'help',
            externalUrl: true,
            openInNewTab: true,
            url: "https://gestiontraiteur.com/demande-de-ticket/", // AppRoutes.CONTACT,
          },
          {
            id: "documentation",
            title: "Knowledge base & FAQ",
            translate: "NAV.DOCUMENTATION.TITLE",
            type: "item",
            // 'icon' : 'help',
            url: AppRoutes.DOCUMENTATION,
          },
          {
            id: "release-note",
            title: "Release notes",
            translate: "NAV.RELEASE_NOTES.TITLE",
            type: "item",
            // 'icon' : 'help',
            url: AppRoutes.RELEASE_NOTES,
          },
          {
            id: "kb",
            title: "Knowledge Base",
            translate: "NAV.KB.TITLE",
            type: "item",
            // 'icon' : 'help',
            url: AppRoutes.KB,
          },
          {
            id: "faq",
            title: "FAQ",
            translate: "NAV.FAQ.TITLE",
            type: "item",
            // 'icon' : 'help',
            url: AppRoutes.FAQ,
          },
        ],
      },
    ],
  },
  // {
  //     id: "applications2",
  //     title: "Applications",
  //     translate: "NAV.APPLICATIONS",
  //     type: "group",
  //     children: [
  //         {
  //             id: "dashboard2",
  //             title: "Tableau de bord",
  //             translate: "NAV.DASHBOARD.TITLE",
  //             type: "item",
  //             icon: "dashboard",
  //             url: AppRoutes.DASHBOARD,
  //         },
  //     ],
  // },
];

// export const employeeNavigation: FuseNavigation[] = [
//     {
//         id       : 'applications',
//         title    : 'Applications',
//         translate: 'NAV.APPLICATIONS',
//         type     : 'group',
//         children : [
//             {
//                 id       : 'dashboard',
//                 title    : 'Tableau de bord2',
//                 translate: 'NAV.DASHBOARD.TITLE',
//                 type     : 'item',
//                 icon     : 'dashboard',
//                 url      : AppRoutes.DASHBOARD
//             }
//             ,
//             {
//                 'id'   : 'products',
//                 'title': 'Produits',
//                 'translate': 'NAV.PRODUCTS.TITLE',
//                 'type' : 'item',
//                 // 'icon' : 'restaurant_menu',
//                 'icon' : IconsService.getIconStatic("PRODUCT"),
//                 'url'  : AppRoutes.PRODUCTS
//             },
//             {
//                 'id'   : 'clients',
//                 'title': 'Client et entreprise',
//                 'translate': 'NAV.CLIENTS.TITLE',
//                 'type' : 'item',
//                 // 'icon' : 'fa-user-circle',
//                 'icon' : IconsService.getIconStatic("CLIENT"),
//                 'iconFA' : true,
//                 'url'  : AppRoutes.CLIENTS
//             },
//             // {
//             //     'id'   : 'orders',
//             //     'title': 'Commandes',
//             //     'translate': 'NAV.ORDERS.TITLE',
//             //     'type' : 'item',
//             //     'icon' : 'receipt',
//             //     'url'  : AppRoutes.ORDERS
//             // },
//             // {
//             //     'id'   : 'employees',
//             //     'title': 'Employés',
//             //     'translate': 'NAV.EMPLOYEES.TITLE',
//             //     'type' : 'item',
//             //     // 'icon' : 'group',
//             //     'icon' : IconsService.getIconStatic("EMPLOYEES"),
//             //     'url'  : AppRoutes.EMPLOYEES
//             // },
//             {
//                 'id'   : 'reports',
//                 'title': 'Rapports',
//                 'translate': 'NAV.REPORTS.TITLE',
//                 'type' : 'item',
//                 // 'icon' : 'insert_chart',
//                 'icon' : IconsService.getIconStatic("REPORT"),
//                 'url'  : AppRoutes.REPORTS
//             },
//             {
//                 'id'   : 'materials',
//                 'title': 'Matériel',
//                 'translate': 'NAV.MATERIALS.TITLE',
//                 'type' : 'item',
//                 'icon' : IconsService.getIconStatic("MATERIAL"),
//                 'url'  : AppRoutes.MATERIALS
//             },
//             {
//                 'id'   : 'management',
//                 'title': 'Gestion',
//                 'translate': 'NAV.MANAGEMENT.TITLE',
//                 'type' : 'item',
//                 // 'icon' : 'fa-clipboard',
//                 'icon' : IconsService.getIconStatic("MANAGEMENT"),
//                 'iconFA' : true,
//                 'url'  : AppRoutes.MANAGEMENT
//             },
//             {
//                 'id'   : 'venues',
//                 'title': 'Lieux',
//                 'translate': 'NAV.VENUES.TITLE',
//                 'type' : 'item',
//                 'icon' : IconsService.getIconStatic("PLACE"),
//                 'url'  : AppRoutes.VENUES
//             },
//             {
//                 'id'   : 'settings',
//                 'title': 'Préférences',
//                 'translate': 'NAV.SETTINGS.TITLE',
//                 'type' : 'item',
//                 // 'icon' : 'settings',
//                 'icon' : IconsService.getIconStatic("SETTINGS"),
//                 'url'  : AppRoutes.SETTINGS + "/categories-clients" // TODO: replace when bilingual solution and url are sets.
//             },
//             {
//                 'id'   : 'support',
//                 'title': 'Support',
//                 'translate': 'NAV.SUPPORT.TITLE',
//                 'type' : 'collapsable',
//                 // 'icon' : 'help',
//                 'icon' : IconsService.getIconStatic("HELP"),
//                 // 'url'  : '/support',
//                 children : [
//                     {
//                         'id'   : 'contact',
//                         'title': 'Nous contacter',
//                         'translate': 'NAV.CONTACT.TITLE',
//                         'type' : 'item',
//                         // 'icon' : 'help',
//                         'url'  : AppRoutes.CONTACT
//                     },
//                     // {
//                     //     'id'   : 'kb',
//                     //     'title': 'Knowledge Base',
//                     //     'translate': 'NAV.KB.TITLE',
//                     //     'type' : 'item',
//                     //     // 'icon' : 'help',
//                     //     'url'  : AppRoutes.KB
//                     // },
//                     // {
//                     //     'id'   : 'faq',
//                     //     'title': 'Foire aux Questions',
//                     //     'translate': 'NAV.FAQ.TITLE',
//                     //     'type' : 'item',
//                     //     // 'icon' : 'help',
//                     //     'url'  : AppRoutes.FAQ
//                     // }
//                 ]
//             }
//         ]
//     }
// ];
