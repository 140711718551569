import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-generic-alert',
  templateUrl: './generic-alert.component.html',
  styleUrls: ['./generic-alert.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatDialogModule,
    FlexLayoutModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class GenericAlertComponent implements OnInit {
  public alertTitle: string = 'Attention';
  public alertMessage!: string;
  public useCancel: boolean = false;
  public useYesNo: boolean = false;
  public useHTML: boolean = false;

  constructor(public dialogRef: MatDialogRef<GenericAlertComponent>) {}

  ngOnInit() {}
}
