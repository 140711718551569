import { AppRoutes } from './../../../const/AppRoutes';
import { NgxPermissionsService } from 'ngx-permissions';
import { CrudEmployeeService } from './../../crud/crud-employee/crud-employee.service';
import { AppRoutesService } from './../../../services/app-routes.service';
import { LocaleHelperService } from './../../../services/locale-helper.service';
import { RteScheduleComponent } from './../../../components/rte-schedule/rte-schedule.component';
import { BusinessDaysComponent } from './../../../components/business-days/business-days.component';
import { AddressService } from './../../../components/address/address.service';
import { AddressModel } from './../../../api/models/AddressModel';
import { ValidatorHelper } from '../../../validators/ValidatorHelper';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { GT2FormPageAbstract } from '../../abstract/GT2FormPageAbstract';
import { ICanDeactivateComponent } from '../../../gards/can-deactivate-guard.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { ShopsService } from '../../../api/shops.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FileService } from '../../../../services/file.service';
import { MatDialog } from '@angular/material/dialog';
import { PrintService } from '../../../../services/print.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { RolesService } from '../../../services/roles.service';
import { FuseSidebarService } from '../../../../../@fuse/components/sidebar/sidebar.service';
import { Title } from '@angular/platform-browser';
import { ShopTypeService } from '../../../api/shop-type.service';
import { Gt2ApiService } from '../../../api/gt2-api.service';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { Settings } from '../../../settings/settings';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { RichTextLocaleComponent } from '../../../components/rich-text-locale/rich-text-locale.component';
import { DateAdapter } from '@angular/material/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BusinessDayModel } from '../../../components/business-days/business-days.component';
import { PointTakeoutComponent } from '../../../components/dialogs/point-takeout/point-takeout.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-shop-web-detailed',
  templateUrl: './shop-web-detailed.component.html',
  styleUrls: ['./shop-web-detailed.component.scss'],
  animations: fuseAnimations,
})
export class ShopWebDetailedComponent
  extends GT2FormPageAbstract
  implements OnInit, AfterViewInit, OnDestroy, ICanDeactivateComponent
{
  @ViewChild('categoriesContainer')
  categoriesContainer?: ElementRef;
  @ViewChild('topHoverZone') topHoverZone?: ElementRef;
  @ViewChild('messageFR')
  messageFR?: RichTextLocaleComponent;
  @ViewChild('messageEN')
  messageEN?: RichTextLocaleComponent;
  @ViewChild('messageUnavailableFR')
  messageUnavailableFR?: RichTextLocaleComponent;
  @ViewChild('messageUnavailableEN')
  messageUnavailableEN?: RichTextLocaleComponent;
  @ViewChild('termsFR') termsFR?: RichTextLocaleComponent;
  @ViewChild('termsEN') termsEN?: RichTextLocaleComponent;
  @ViewChild('businessDaysComponent')
  businessDaysComponent?: BusinessDaysComponent;
  @ViewChild('rteSchedule')
  rteSchedule?: RteScheduleComponent;

  shop: any;
  shopCatgories: any[] | any;
  shopID: string | any;
  shopSlug?: string;
  loading?: boolean;
  form: UntypedFormGroup;
  formTips: UntypedFormGroup;
  formMoneris: UntypedFormGroup;
  formStripe: UntypedFormGroup;
  formMessageImage: UntypedFormGroup;
  formGP: UntypedFormGroup;
  editMode: boolean = false;
  confirmDialogRef: any;
  editable: boolean = true;
  termsFRInvalid: boolean = true;
  termsENInvalid: boolean = true;
  businessHours: any;
  takeOutHours: any;
  businessDays?: BusinessDayModel[];
  shopPreference: any;
  isThemeDark: boolean = false;
  defaultTheme?: string;
  rteScheduleTemplate: any;
  deliveryRules: any;
  monerisModel: MonerisModel | any;
  stripeModel: any;
  globalPaymentsModel: GlobalPaymentModel | any;
  multiPointTakeoutModel: any;
  shopMessageModel: any;
  shopMessageImageModel: any;
  isSmallScreen?: boolean;

  sectionID?: string;
  menuItems: any[] = [];
  // godOnlySection: string[] = [];
  godOnlySection = [
    'moneris',
    'stripe',
    'global_payments',
    'regles-livraison',
    'calendrier',
  ];
  currentSection: any; // = this.menuItems[0];
  hasGodRolesAccess?: boolean;
  boxName?: string;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private shopsService: ShopsService,
    public translate: TranslateService,
    public location: Location,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private fileService: FileService,
    private dialog: MatDialog,
    public print: PrintService,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private adapter: DateAdapter<any>,
    public rolesService: RolesService,
    private _fuseSidebarService: FuseSidebarService,
    private addressService: AddressService,
    private titleService: Title,
    public localeHelperService: LocaleHelperService,
    public shopTypeService: ShopTypeService,
    private appRoutesService: AppRoutesService,
    private api: Gt2ApiService,
    private employee: CrudEmployeeService,
    private permissions: NgxPermissionsService,
    public router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);
    this.adapter.setLocale(this.translate.currentLang);

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      store_url: ['', [ValidatorHelper.nullable]],
      min_subtotal_delivery: [
        '',
        [
          Validators.minLength(1),
          ValidatorHelper.isNumber0To1000000,
          ValidatorHelper.isValidPriceNumber,
        ],
      ],
      min_subtotal_takeout: [
        '',
        [
          Validators.minLength(1),
          ValidatorHelper.isNumber0To1000000,
          ValidatorHelper.isValidPriceNumber,
        ],
      ],
      // option_1_value: ['', [Validators.minLength(1), ValidatorHelper.isNumber0To100]],
      // option_2_value: ['', [Validators.minLength(1), ValidatorHelper.isNumber0To100]],
      // option_3_value: ['', [Validators.minLength(1), ValidatorHelper.isNumber0To100]],
      google_ga_caterer_shop: ['', [Validators.minLength(3)]],
      limit_distance_km: [
        '',
        [Validators.minLength(1), ValidatorHelper.isNumber0To1000000],
      ],
      single_rate: [
        '',
        [Validators.minLength(1), ValidatorHelper.isNumber0To1000000],
      ],
      redirect_url: ['', [Validators.minLength(3)]],
    });

    this.formTips = this.formBuilder.group({
      option_1_value: [
        '',
        [Validators.minLength(1), ValidatorHelper.isNumber0To100],
      ],
      option_2_value: [
        '',
        [Validators.minLength(1), ValidatorHelper.isNumber0To100],
      ],
      option_3_value: [
        '',
        [Validators.minLength(1), ValidatorHelper.isNumber0To100],
      ],
    });

    this.formMoneris = this.formBuilder.group({
      merchant_id: ['', [Validators.required]],
      merchant_key: ['', [Validators.required]],
    });

    this.formStripe = this.formBuilder.group({
      api_key: ['', [Validators.required]],
      secret_key: ['', [Validators.required]],
    });

    this.formGP = this.formBuilder.group({
      merchant_id: ['', [Validators.required]],
      account_id: ['', [Validators.required]],
      shared_secret: ['', [Validators.required]],
      rebate_password: ['', [Validators.required]],
    });

    this.formMessageImage = this.formBuilder.group({
      click_target_fr_CA: ['', [Validators.minLength(Settings.inputMinChar)]],
      click_target_en_CA: ['', [Validators.minLength(Settings.inputMinChar)]],
      click_url_fr_CA: ['', [Validators.minLength(Settings.inputMinChar)]],
      click_url_en_CA: ['', [Validators.minLength(Settings.inputMinChar)]],
    });
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 600px)'])
      .subscribe((result) => {
        this.isSmallScreen = result.matches;
      });
    localStorage.setItem('API_URL', this.api.getDomainUrl() + '/v1/');
    //this.titleService.setTitle(this.translate.instant("SHOPS.SHOPS"));
    this.menuItems = [
      {
        id: 'configuration',
        label: this.translate.instant('SHOPS.CONFIGURATION'),
        role: this.rolesService.ALL_ROLES,
        url: 'configuration-generale',
      },
      {
        id: 'points-takeout',
        label: this.translate.instant('SHOPS.MULTI_POINT_TAKEOUT'),
        role: this.rolesService.ALL_ROLES,
        url: 'multi-point-cueillette',
      },
      {
        id: 'moneris',
        label: this.translate.instant('SHOPS.MONERIS'),
        role: this.rolesService.ALL_ROLES,
        url: 'moneris',
      },
      {
        id: 'stripe',
        label: this.translate.instant('SHOPS.STRIPE'),
        role: this.rolesService.ALL_ROLES,
        url: 'stripe',
      },
      {
        id: 'global_payments',
        label: this.translate.instant('SHOPS.GLOBAL_PAYMENT'),
        role: this.rolesService.GOD_ROLES,
        url: 'global-payments',
      },
      {
        id: 'theme',
        label: this.translate.instant('SHOPS.THEME'),
        role: this.rolesService.ALL_ROLES,
        url: 'theme',
      },
      {
        id: 'deliveryRules',
        label: this.translate.instant('SHOPS.DELIVERY_RULES'),
        role: this.rolesService.ALL_ROLES,
        url: 'regles-livraison',
      },
      {
        id: 'scheduleGenerator',
        label: this.translate.instant('SHOPS.RTE_SCHEDULE'),
        role: this.rolesService.ALL_ROLES,
        url: 'generateur-horaire-commandes',
      },
      {
        id: 'address',
        label: this.translate.instant('GENERIC.ADDRESS'),
        role: this.rolesService.ALL_ROLES,
        url: 'adresse',
      },
      {
        id: 'preferences',
        label: this.translate.instant('SHOPS.PREFERENCE'),
        role: this.rolesService.ALL_ROLES,
        url: 'preferences',
      },
      {
        id: 'calendar',
        label: this.translate.instant('SHOPS.BUSINESS_DAYS'),
        role: this.rolesService.ALL_ROLES,
        url: 'calendrier',
      },
      {
        id: 'businessHours',
        label: this.translate.instant('SHOPS.BUSINESS_HOURS'),
        role: this.rolesService.ALL_ROLES,
        url: 'heures-ouverture',
      },
      {
        id: 'tips',
        label: this.translate.instant('SHOPS.TIPS'),
        role: this.rolesService.ALL_ROLES,
        url: 'pourboire',
      },
      {
        id: 'terms',
        label: this.translate.instant('SHOPS.TERMS_CONDITIONS'),
        role: this.rolesService.ALL_ROLES,
        url: 'termes-conditions',
      },
      {
        id: 'notifications',
        label: this.translate.instant('SHOPS.NOTIFICATIONS_MENU'),
        role: this.rolesService.ALL_ROLES,
        url: 'notifications',
      },
      {
        id: 'messages',
        label: this.translate.instant('SHOPS.MESSAGES'),
        role: this.rolesService.ALL_ROLES,
        url: 'messages',
      },
      {
        id: 'categories',
        label: this.translate.instant('SHOPS.CATEGORIES'),
        role: this.rolesService.ALL_ROLES,
        url: 'categories',
      },
    ];

    // this.godOnlySection = ['moneris', 'stripe', 'regles-livraison', 'generateur-horaire-commandes', 'calendrier'];

    this.employee.employee.subscribe((me: any) => {
      if (me) {
        this.permissions
          .hasPermission(this.rolesService.GOD_ROLES)
          .then((value) => {
            this.hasGodRolesAccess = value;
            if (!this.hasGodRolesAccess) {
              this.menuItems = this.menuItems.filter((item) => {
                return !this.godOnlySection.includes(item.url);
              });
            }
            this.watchURLParams();
          });
      }
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public canDeactivate(): boolean {
    return true;
  }

  public watchURLParams(): void {
    this.route.params.subscribe((params: any) => {
      this.shopID = params.id;
      this.shopSlug = params.slug;
      this.sectionID = params.section;
      this.loading = true;
      this.shop = null;
      this.loadShop();

      if (this.sectionID) {
        let section = this.menuItems.find((item) => {
          return item.url === this.sectionID;
        });

        if (
          section &&
          !this.hasGodRolesAccess &&
          this.godOnlySection.includes(section.url)
        ) {
          section = null;
        }

        if (section) {
          this.currentSection = section;
          this.navigateToSection(section.url);
        } else {
          this.currentSection = this.menuItems[0];
          this.navigateToSection(this.menuItems[0].url);
        }
      } else {
        this.currentSection = this.menuItems[0];
        this.navigateToSection(this.menuItems[0].url);
      }
    });
  }

  public loadShop(): void {
    this.loading = true;
    this.shopsService.getShopWithCategories(this.shopID).subscribe(
      (responseList) => {
        this.shop = responseList[0].data;
        this.shopCatgories = responseList[1].data;
        this.loading = false;
        //this.titleService.setTitle(this.translate.instant("SHOPS.SHOPS") + " " + this.shop.name);
        this.isThemeDark = this.shop.theme_mode === 'dark-theme' ? true : false;
        this.defaultTheme = this.shop.default_theme.id;
        this.loadHours(
          this.shop.shop_business_hour_uuid_open,
          this.shop.shop_business_hour_uuid_takeout
        );
        this.loadDays(this.shop.uuid);
        this.loadShopPreference(this.shop.shop_preference_uuid);
        // this.loadShopRTETemplate(this.shop.shop_rte_schedule_template_uuid);
        this.loadShopDeliveryRule(this.shop.shop_delivery_rule_uuid);
        if (this.shop.moneris_uuid) {
          this.loadMonerisInfo(this.shop.moneris_uuid);
        }
        if (this.shop.stripe_uuid) {
          this.loadStripeInfo(this.shop.stripe_uuid);
        }
        if (this.shop.global_payment_uuid) {
          this.loadGlobalPaymentsInfo(this.shop.global_payment_uuid);
        }
        this.loadMultiPointTakeout();
        this.loadShopMessage();
        this.loadShopMessageImage();
      },
      (error) => {
        this.logger.error(
          'ShopWebComponent.loadShop() -> ERROR: ' + JSON.stringify(error)
        );
        this.loading = false;
        // TODO: the errors are from a forkJoin, so the handleAPIError might fail
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public loadHours(businessUUID: string, takeOutUUID: string): void {
    this.shopsService.getShopHours(businessUUID, takeOutUUID).subscribe(
      (response: any) => {
        this.businessHours = response.find((hours: any) => {
          return hours.data.type === 'TYPE_OPEN';
        }).data;

        this.takeOutHours = response.find((hours: any) => {
          return hours.data.type === 'TYPE_TAKEOUT';
        }).data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.loadHours() -> ERROR: ' + JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public loadDays(uuid: string): void {
    this.shopsService.getBusinnesDaysByShopUUID(uuid).subscribe(
      (response: any) => {
        this.businessDays = response.data;

        setTimeout(() => {
          if (this.businessDaysComponent) {
            this.businessDaysComponent.parentInit();
          }
        }, 100);
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.loadDays() -> ERROR: ' + JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public loadShopPreference(uuid: string): void {
    this.shopsService.getShopPreference(uuid).subscribe(
      (response: any) => {
        this.shopPreference = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.loadShopPreference() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public loadShopRTETemplate(uuid: string): void {
    this.shopsService.getShopRTETemplate(uuid).subscribe(
      (response: any) => {
        this.rteScheduleTemplate = response.data;

        setTimeout(() => {
          if (this.rteSchedule) {
            this.rteSchedule.parentInit();
          }
        }, 100);
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.loadShopRTETemplate() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public loadShopDeliveryRule(uuid: string): void {
    this.shopsService.getDeliveryRules(uuid).subscribe(
      (response: any) => {
        this.deliveryRules = response.data;

        if (!this.deliveryRules.distance_brackets) {
          this.deliveryRules.distance_brackets = [];
          this.deliveryRules.rate_brackets = [];

          this.deliveryRules.distance_brackets.push({
            min: 0,
            max: 0,
          });
          this.deliveryRules.rate_brackets.push(0);
        }
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.loadShopDeliveryRule() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public loadMonerisInfo(monerisUUID: string): void {
    this.shopsService.getMonerisInfo(monerisUUID).subscribe(
      (response: any) => {
        this.monerisModel = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.loadMonerisInfo() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public loadStripeInfo(stripeUUID: string): void {
    if (!stripeUUID) {
      return;
    }

    this.shopsService.getStripeInfo(stripeUUID).subscribe(
      (response: any) => {
        this.stripeModel = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.loadStripeInfo() -> ERROR: ' + JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public loadMultiPointTakeout(): void {
    this.shopsService.getMultiPointTakeout(this.shop.uuid).subscribe(
      (response: any) => {
        this.multiPointTakeoutModel = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.loadMultiPointTakeout() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public loadGlobalPaymentsInfo(globalPaymentsUUID: string): void {
    if (!globalPaymentsUUID) {
      return;
    }

    this.shopsService.getGlobalPaymentsInfo(globalPaymentsUUID).subscribe(
      (response: any) => {
        this.globalPaymentsModel = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.loadGlobalPaymentsInfo() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public loadShopMessage(): void {
    this.shopsService.getShopMessage(this.shop.shop_message_uuid).subscribe(
      (response: any) => {
        this.shopMessageModel = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.loadShopMessage() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public loadShopMessageImage(): void {
    this.shopsService
      .getShopMessageImage(this.shop.shop_message_image_uuid)
      .subscribe(
        (response: any) => {
          this.shopMessageImageModel = response.data;
        },
        (error: any) => {
          this.logger.error(
            'ShopWebComponent.loadShopMessageImage() -> ERROR: ' +
              JSON.stringify(error)
          );
          this.handleAPIError(error, this.dialog, null, null);
        }
      );
  }

  public onEdit(event: any): void {
    this.editMode = !this.editMode;
  }

  public override autosaveDataChange(data: any): void {}

  public saveData(data: any): void {
    this.loading = true;
    this.shopsService.updateShopByUUID(data, this.shopID).subscribe(
      (response: any) => {
        this.loading = false;
        this.form.markAsPristine();
        this.shop = response.data;
        this.form.markAsUntouched();
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.saveData() -> ERROR: ' + JSON.stringify(error)
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public deleteShop(): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.shopsService.deleteShop(this.shopID).subscribe(
          (response: any) => {
            this.toastr.success(
              '',
              this.translate.instant('GENERIC.DELETE_SUCCESS')
            );
            this.shop = response.data;
          },
          (error: any) => {
            this.logger.error(
              'ShopWebComponent.deleteShop() -> ERROR: ' + JSON.stringify(error)
            );
            this.loading = false;
            this.handleAPIError(
              error,
              this.dialog,
              this.toastr,
              this.translate.instant('GENERIC.ERRORS.GENERIC')
            );
          }
        );
      }
    });
  }

  public onOpenInNewTab(): void {
    window.open(this.router.url, '_blank');
  }

  public onCategoryChildDrop(event: any): void {
    if (this.shopCatgories.length < 2) {
      return;
    }

    let i = 0;
    const length: number = this.shopCatgories.length;
    for (i; i < length; i++) {
      if (this.shopCatgories[i].uuid === event.value.uuid) {
        // move before
        if (i === 0) {
          this.shopsService
            .categoryChildMoveBefore(
              this.shopID,
              event.value.uuid,
              this.shopCatgories[i + 1].uuid
            )
            .subscribe(
              (response: any) => {},
              (error: any) => {
                this.logger.error(
                  'ShopWebComponent.onCategoryChildDrop() -> ERROR (productChildMoveBefore): ' +
                    JSON.stringify(error)
                );
                this.handleAPIError(error, this.dialog, null, null);
              }
            );
        } // move after
        else {
          this.shopsService
            .categoryChildMoveAfter(
              this.shopID,
              event.value.uuid,
              this.shopCatgories[i - 1].uuid
            )
            .subscribe(
              (response: any) => {},
              (error: any) => {
                this.logger.error(
                  'ShopWebComponent.onCategoryChildDrop() -> ERROR (productChildMoveBefore): ' +
                    JSON.stringify(error)
                );
                this.handleAPIError(error, this.dialog, null, null);
              }
            );
        }
        break;
      }
    }
  }

  public onCategoryChildDrag(event: any): void {}

  public onDnDOver(event: any): void {}

  public onDnDOut(event: any): void {}

  public onNavigateToCategory(item: any): void {}

  public openURL(url: string): void {
    window.open(url, '_blank');
  }

  public onSaveInfo(): void {
    this.saveData(this.shop);
  }

  public onSaveTerms(event: string, local: string): void {
    this.saveData(this.shop);
  }

  public onClearTerms(): void {
    this.shop.terms_local.terms_fr_CA = '';
    this.shop.terms_local.terms_en_CA = '';
    this.loading = true;
    this.shopsService.updateShopByUUID(this.shop, this.shopID).subscribe(
      (response: any) => {
        this.loading = false;
        this.form.markAsPristine();
        this.shop = response.data;
        this.form.markAsUntouched();
        if (this.termsFR) {
          this.termsFR.resetCount();
        }
        if (this.termsEN) {
          this.termsEN.resetCount();
        }
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.onClearTerms() -> ERROR: ' + JSON.stringify(error)
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public onSaveMessage(event: any): void {
    this.loading = true;
    this.shopsService.updateShopMessage(event.uuid, event).subscribe(
      (response: any) => {
        this.loading = false;
        this.shopMessageModel = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.onSaveMessage() -> ERROR: ' + JSON.stringify(error)
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public onSaveMessageImage(event: any): void {
    this.loading = true;
    this.shopsService.updateShopMessageImage(event.uuid, event).subscribe(
      (response: any) => {
        this.loading = false;
        this.shopMessageImageModel = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.onSaveMessageImage() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public onClearMessageImage(event: any): void {
    this.loading = true;
    this.shopsService.clearShopMessageImage(event.uuid, event).subscribe(
      (response: any) => {
        this.loading = false;
        this.shopMessageImageModel = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.onClearMessageImage() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public onSaveUnavailableMessage(event: any): void {
    this.loading = true;
    this.shopsService
      .updateShopMessage(
        this.shop.shop_message_unavailable.uuid,
        this.shop.shop_message_unavailable
      )
      .subscribe(
        (response: any) => {
          this.loading = false;
          this.shop.shop_message_unavailable = response.data;
        },
        (error: any) => {
          this.logger.error(
            'ShopWebComponent.onSaveUnavailableMessage() -> ERROR: ' +
              JSON.stringify(error)
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        }
      );
  }

  public onClearMessage(): void {
    this.shopsService.clearShopMessage(this.shop.shop_message_uuid).subscribe(
      (response: any) => {
        this.loading = false;
        this.shopMessageModel = response.data;
        if (this.messageFR) {
          this.messageFR.resetCount();
        }
        if (this.messageEN) {
          this.messageEN.resetCount();
        }
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.onClearMessage() -> ERROR: ' + JSON.stringify(error)
        );
        this.loading = false;
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public onClearUnavailableMessage(): void {
    this.shopsService
      .clearShopMessage(this.shop.shop_message_unavailable.uuid)
      .subscribe(
        (response: any) => {
          this.loading = false;
          this.shop.shop_message_unavailable = response.data;
          if (this.messageUnavailableFR) {
            this.messageUnavailableFR.resetCount();
          }
          if (this.messageUnavailableEN) {
            this.messageUnavailableEN.resetCount();
          }
        },
        (error: any) => {
          this.logger.error(
            'ShopWebComponent.onClearUnavailableMessage() -> ERROR: ' +
              JSON.stringify(error)
          );
          this.loading = false;
          this.handleAPIError(error, this.dialog, null, null);
        }
      );
  }

  public onNewAdress(address: AddressModel): void {}

  public onSaveAdress(): void {
    this.addressService
      .updateAddress(this.shop.address, this.shop.address.uuid)
      .subscribe(
        (response) => {},
        (error) => {
          this.logger.error(
            'ShopWebComponent.onSaveAdress() -> ERROR: ' + JSON.stringify(error)
          );
          this.handleAPIError(error, this.dialog, null, null);
        }
      );
  }

  public onSaveBusinessHours(businessHours: any): void {
    this.shopsService.saveShopHours(businessHours).subscribe(
      (response: any) => {},
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.onSaveBusinessHours() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public onSaveBusinessDay(): void {
    if (this.businessDaysComponent?.selectedBusinessDay?.uuid) {
      this.shopsService
        .updateShopDays(this.businessDaysComponent?.selectedBusinessDay)
        .subscribe(
          (response: any) => {
            this.businessDays = response.data;
            this.businessDaysComponent?.refreshCalendar();
          },
          (error: any) => {
            this.logger.error(
              'ShopWebComponent.onSaveBusinessDay() -> ERROR: ' +
                JSON.stringify(error)
            );
            this.handleAPIError(error, this.dialog, null, null);
          }
        );
    } else {
      this.shopsService
        .saveShopDays(
          this.businessDaysComponent?.selectedBusinessDay,
          this.shop.uuid
        )
        .subscribe(
          (response: any) => {
            this.businessDays = response.data;
            this.businessDaysComponent?.refreshCalendar();
          },
          (error: any) => {
            this.logger.error(
              'ShopWebComponent.onSaveBusinessDay() -> ERROR: ' +
                JSON.stringify(error)
            );
            this.handleAPIError(error, this.dialog, null, null);
          }
        );
    }
  }

  public onSavePreference(): void {
    this.shopsService.savePreferences(this.shopPreference).subscribe(
      (response: any) => {
        this.shopPreference = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.onSavePreference() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public onSaveRTETemplate(): void {
    this.shopsService
      .saveRTEScheduleTemplate(this.rteScheduleTemplate)
      .subscribe(
        (response: any) => {
          this.rteScheduleTemplate = response.data;
        },
        (error: any) => {
          this.logger.error(
            'ShopWebComponent.onSaveRTETemplate() -> ERROR: ' +
              JSON.stringify(error)
          );
          this.handleAPIError(error, this.dialog, null, null);
        }
      );
  }

  public onSaveDeliveryRules(): void {
    // //this.logger.info("ShopWebComponent.onSaveDeliveryRules() -> this.deliveryRules: " + JSON.stringify(this.deliveryRules));
    this.shopsService.saveDeliveryRules(this.deliveryRules).subscribe(
      (response: any) => {
        //this.logger.info("ShopWebComponent.onSaveDeliveryRules() -> SUCCESS: " + JSON.stringify(response));
        this.deliveryRules = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.onSaveDeliveryRules() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public rteTemplateModelPauseChange(event: any): void {
    // //this.logger.info("ShopWebComponent.rteTemplateModelPauseChange() -> event: " + event);
    this.onSaveRTETemplate();
  }

  public toggleHistoricSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  public onSwitchLocale(event: MatSlideToggleChange): void {
    this.shop.show_switch_locale = event.checked;
  }

  // MONERIS
  public onSaveMonerisInfo(): void {
    this.shopsService.saveMonerisInfo(this.monerisModel).subscribe(
      (response: any) => {
        this.monerisModel = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.onSaveMonerisInfo() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }

  public onAddMonerisProfile(): void {
    this.monerisModel?.profiles.push({ url: '', profile_id: '' });
  }

  public onRemoveMonerisProfile(event: any): void {
    this.monerisModel?.profiles.splice(event.position, 1);
  }

  // STRIPE
  public onSaveStripeInfo(): void {
    this.shopsService.saveStripeInfo(this.stripeModel).subscribe(
      (response: any) => {
        this.monerisModel = response.data;
      },
      (error: any) => {
        this.logger.error(
          'ShopWebComponent.onSaveStripeInfo() -> ERROR: ' +
            JSON.stringify(error)
        );
        this.handleAPIError(error, this.dialog, null, null);
      }
    );
  }
  // GLOBAL PAYMENT
  public saveGlobalPaymentsInfo(): void {
    this.shopsService
      .saveGlobalPaymentsInfo(this.globalPaymentsModel)
      .subscribe(
        (response: any) => {
          this.globalPaymentsModel = response.data;
        },
        (error: any) => {
          this.logger.error(
            'ShopWebComponent.saveGlobalPaymentsInfo() -> ERROR: ' +
              JSON.stringify(error)
          );
          this.handleAPIError(error, this.dialog, null, null);
        }
      );
  }

  public onAddMultiPointTakeout(): void {
    this.dialogRef = this.dialog.open(PointTakeoutComponent, {
      disableClose: false,
      data: {
        shopUUID: this.shopID,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.loadMultiPointTakeout();
      }
    });
  }

  public onEditPointTakeout(item: any): void {
    this.dialogRef = this.dialog.open(PointTakeoutComponent, {
      disableClose: false,
      data: {
        shopUUID: this.shopID,
        point: item,
      },
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.loadMultiPointTakeout();
      }
    });
  }

  public onRemovePointTakeout(data: any): void {
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });
    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.shopsService.deletePointTakeout(data.item.uuid).subscribe(
          (response: any) => {
            this.toastr.success(
              '',
              this.translate.instant('GENERIC.DELETE_SUCCESS')
            );
            this.loadMultiPointTakeout();
          },
          (error: any) => {
            this.logger.error(
              'ShopWebComponent.onRemovePointTakeout() -> ERROR: ' +
                JSON.stringify(error)
            );
            this.loading = false;
            this.handleAPIError(
              error,
              this.dialog,
              this.toastr,
              this.translate.instant('GENERIC.ERRORS.GENERIC')
            );
          }
        );
      }
    });
  }

  public onMenuSelected(item: any): void {
    this.currentSection = item;
    this.navigateToSection(item.url);
  }

  public navigateToSection(section: string): void {
    const url: string =
      this.appRoutesService.shops +
      '/shop-web-detailed/' +
      this.shopSlug +
      '/' +
      this.shopID +
      '/' +
      section;
    this.location.go(url);
    // this.router.navigateByUrl();
  }

  public override onPageBack(
    location: Location,
    router: Router,
    alternativeRoute?: string
  ): void {
    router.navigateByUrl(AppRoutes.SHOPS);
  }
}

export interface MonerisModel {
  uuid: string;
  available: boolean;
  active: boolean;
  test_mode: boolean;
  merchant_id: string;
  merchant_key: string;
  profiles: MonerisProfilModel[];
}

export interface GlobalPaymentModel {
  uuid: string;
  merchant_id: string;
  account_id: string;
  shared_secret: string;
  rebate_password: string;
}

export interface MonerisProfilModel {
  url: string;
  profile_id: string;
}
