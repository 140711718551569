<ng-template #tipsHintTemplate>
  <p>{{ "SHOPS.TIPS_HINT" | translate }}</p>
</ng-template>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
  <div *ngIf="!editMode" class="info-line" fxFlex="100%">
    <div class="title">
      {{ "SHOPS.ACCEPT_TIPS" | translate }}
    </div>
    <div *ngIf="shop.show_tips_ctrl" class="info">
      {{ "GENERIC.YES" | translate }}
    </div>
    <div *ngIf="!shop.show_tips_ctrl" class="info">
      {{ "GENERIC.NO" | translate }}
    </div>
  </div>
  <div *ngIf="editMode" class="info-line" fxFlex="100%">
    <mat-checkbox
      labelPosition="after"
      color="accent"
      [(ngModel)]="shop.show_tips_ctrl"
      [ngModelOptions]="{ standalone: true }"
      class=""
    >
      {{ "SHOPS.ACCEPT_TIPS" | translate }}
    </mat-checkbox>
  </div>
</div>

<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="start start"
  class="mt-16"
>
  <!-- MAIN TIPS SECTION -->
  <div fxFlex>
    <div class="info-line" style="min-width: 600px">
      <mat-radio-group
        [disabled]="!shop.show_tips_ctrl || !editMode"
        [(ngModel)]="shop.tips.default_index"
        fxLayout="column"
        aria-label="Select an option"
        fxFlexFill
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start start"
          class="mb-12 title"
          fxFlex
        >
          <div fxFlex="33%">&nbsp;</div>
          <div fxFlex="33%" fxLayout="row" fxLayoutAlign="center start">
            <div class="percent percent-disabled" fxLayout="row">
              <div class="percent-value">0</div>
              <div class="percent-prefix">%</div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="end start" fxFlex>
            <mat-radio-button [value]="0" name="optionObligatoire">
              {{ "SHOPS.DEFAULT_OPTION" | translate }}
            </mat-radio-button>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start start"
          class="mb-12 title"
          fxFlex
        >
          <div fxFlex="33%" fxLayout="row" fxLayoutAlign="start center">
            {{ "SHOPS.OPTION_1" | translate }}
            <mat-icon
              matTooltip="tipsHintTemplate"
              content-type="template"
              class="ml-12 s-20"
              color=""
              >help
            </mat-icon>
          </div>

          <div fxFlex="33%" fxLayout="row" fxLayoutAlign="center start">
            <div class="percent" fxLayout="row">
              <div *ngIf="!editMode" class="percent-value">
                {{ shop.tips.option_1_value }}
              </div>
              <div
                *ngIf="editMode"
                [formGroup]="formTips"
                class="percent-value"
              >
                <mat-form-field class="percent-form-field" fxFlex="100%">
                  <input
                    [(ngModel)]="shop.tips.option_1_value"
                    autocomplete="nope"
                    formControlName="option_1_value"
                    matInput
                    numeric
                    [decimals]="2"
                    [allowNegative]="false"
                    [validationForm]="formTips"
                    pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                    type="text"
                  />
                </mat-form-field>
              </div>
              <div class="percent-prefix">%</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="end start" fxFlex>
            <mat-radio-button [value]="1">
              {{ "SHOPS.DEFAULT_OPTION" | translate }}
            </mat-radio-button>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start start"
          class="mb-12 title"
          fxFlex
        >
          <div fxFlex="33%" fxLayout="row" fxLayoutAlign="start center">
            {{ "SHOPS.OPTION_2" | translate }}
            <mat-icon
              matTooltip="tipsHintTemplate"
              content-type="template"
              class="ml-12 s-20"
              color=""
              >help
            </mat-icon>
          </div>
          <div fxFlex="33%" fxLayout="row" fxLayoutAlign="center start">
            <div class="percent" fxLayout="row">
              <!-- <div class='percent-prefix'>%</div> -->
              <div *ngIf="!editMode" class="percent-value">
                {{ shop.tips.option_2_value }}
              </div>
              <div
                *ngIf="editMode"
                [formGroup]="formTips"
                class="percent-value"
              >
                <mat-form-field class="percent-form-field" fxFlex="100%">
                  <input
                    [(ngModel)]="shop.tips.option_2_value"
                    autocomplete="nope"
                    formControlName="option_2_value"
                    matInput
                    numeric
                    [decimals]="2"
                    [allowNegative]="false"
                    [validationForm]="formTips"
                    pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                    type="text"
                  />
                </mat-form-field>
              </div>
              <div class="percent-prefix">%</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="end start" fxFlex>
            <mat-radio-button [value]="2">
              {{ "SHOPS.DEFAULT_OPTION" | translate }}
            </mat-radio-button>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start start"
          class="mb-12 title"
          fxFlex
        >
          <div fxFlex="33%" fxLayout="row" fxLayoutAlign="start center">
            {{ "SHOPS.OPTION_3" | translate }}
            <mat-icon
              matTooltip="tipsHintTemplate"
              content-type="template"
              class="ml-12 s-20"
              color=""
              >help
            </mat-icon>
          </div>
          <div fxFlex="33%" fxLayout="row" fxLayoutAlign="center start">
            <div class="percent" fxLayout="row">
              <div *ngIf="!editMode" class="percent-value">
                {{ shop.tips.option_3_value }}
              </div>
              <div
                *ngIf="editMode"
                [formGroup]="formTips"
                class="percent-value"
              >
                <mat-form-field class="percent-form-field" fxFlex="100%">
                  <input
                    [(ngModel)]="shop.tips.option_3_value"
                    autocomplete="nope"
                    formControlName="option_3_value"
                    matInput
                    numeric
                    [decimals]="2"
                    [allowNegative]="false"
                    [validationForm]="formTips"
                    pattern="^[+-]?[0-9]+([\.|,][0-9]{1,2})?"
                    type="text"
                  />
                </mat-form-field>
              </div>
              <div class="percent-prefix">%</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="end start" fxFlex>
            <mat-radio-button [value]="3">
              {{ "SHOPS.DEFAULT_OPTION" | translate }}
            </mat-radio-button>
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="start start"
          class="mb-12 title"
          fxFlex
        >
          <div fxFlex="33%">&nbsp;</div>
          <div fxFlex="33%" fxLayout="row" fxLayoutAlign="center start">
            <div class="percent percent-disabled" fxLayout="row">
              <!-- <div class='percent-prefix'>%</div> -->
              <div
                class="percent-value"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                {{ "GENERIC.OTHER" | translate }}
                <!-- <mat-icon 
                                    matTooltip="tipsHintTemplate" 
                                    content-type="template" class="ml-12 s-20" color="">help</mat-icon> -->
              </div>
              <div class="percent-prefix">%</div>
            </div>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </div>
</div>
<div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start">
  <button
    (click)="onSaveInfo()"
    color="accent"
    class="save-btn"
    [disabled]="formTips.invalid"
    mat-raised-button
  >
    {{ "GENERIC.SAVE" | translate }}
  </button>
</div>
