import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ForgotPassword2Component } from './forgot-password-2.component';
import { AppRoutesName } from '../../../../gt2/const/AppRoutesName';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { GenericAlertComponent } from '../../../../gt2/components/generic-alert/generic-alert.component';

const routes = [
  {
    path: AppRoutesName.EN.FORGOT_PASSWORD, // 'auth/forgot-password-2',
    component: ForgotPassword2Component,
  },
  {
    path: AppRoutesName.FR.FORGOT_PASSWORD, // 'auth/forgot-password-2',
    component: ForgotPassword2Component,
  },
];

@NgModule({
  declarations: [ForgotPassword2Component],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    GenericAlertComponent,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    TranslateModule.forRoot(),
  ],
})
export class ForgotPassword2Module {}
