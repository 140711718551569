import { BehaviorSubject } from 'rxjs';
import { Gt2ApiService } from './gt2-api.service';
import { NGXLogger } from 'ngx-logger';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TestErrorService {
  onTestFromRequest: BehaviorSubject<TestFromRequestResult> =
    new BehaviorSubject<TestFromRequestResult>(null);
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService
  ) {}

  // TEST GET API CALL THAT RETURN BROKEN RESPONSE (ie: status 0 error)
  public async testErrorGET(url: string) {
    //this.logger.info("TestErrorService.testErrorGET() -> *url: " + url);

    const headers = new HttpHeaders().set(
      'Content-Type',
      'text/plain; charset=utf-8'
    );
    return new Promise((resolve, reject) => {
      this.http
        .get<string>(url, {
          headers,
          responseType: 'text' as 'json',
        })
        .subscribe(
          (response) => {
            this.logger.error(
              'TestErrorService.testErrorGET() -> SUCCESS-ERROR: ' +
                JSON.stringify(response)
            );

            resolve(response);
          },
          async (error) => {
            this.logger.error(
              'ReportsService.testErrorGET() -> ERROR-ERROR: ' +
                JSON.stringify(error)
            );

            try {
              const decodedError = JSON.parse(await error.error.text());
              error.error = decodedError;
            } catch (Error) {
              this.logger.error(
                'TestErrorService.testErrorGET()) -> try/catch ERROR: ' +
                  JSON.stringify(Error.message)
              );
            }

            reject(error);
          }
        );
    });
  }

  // TEST POST API CALL THAT RETURN BROKEN RESPONSE (ie: status 0 error)
  public async testErrorPOST(url: string, body: any) {
    //this.logger.info("TestErrorService.testErrorGET() -> *url: " + url);

    const headers = new HttpHeaders().set(
      'Content-Type',
      'text/plain; charset=utf-8'
    );
    return new Promise((resolve, reject) => {
      this.http
        .post<string>(url, body, {
          headers,
          responseType: 'text' as 'json',
        })
        .subscribe(
          (response) => {
            this.logger.error(
              'TestErrorService.testErrorGET() -> SUCCESS-ERROR: ' +
                JSON.stringify(response)
            );

            resolve(response);
          },
          async (error) => {
            this.logger.error(
              'ReportsService.testErrorGET() -> ERROR-ERROR: ' +
                JSON.stringify(error)
            );

            try {
              const decodedError = JSON.parse(await error.error.text());
              error.error = decodedError;
            } catch (Error) {
              this.logger.error(
                'TestErrorService.testErrorGET()) -> try/catch ERROR: ' +
                  JSON.stringify(Error.message)
              );
            }

            reject(error);
          }
        );
    });
  }

  public async testErrorFromRequest(request: HttpRequest<any>) {
    // this.logger.info(
    //     "TestErrorService.testErrorFromRequest() -> *url: " + request.url
    // );

    const headers = new HttpHeaders().set(
      'Content-Type',
      'text/plain; charset=utf-8'
    );

    return new Promise((resolve, reject) => {
      this.http
        .post<string>(request.url, request.body, {
          headers,
          responseType: 'text' as 'json',
        })
        .subscribe(
          (response) => {
            this.logger.error(
              'TestErrorService.testErrorFromRequest() -> SUCCESS-ERROR: ' +
                JSON.stringify(response)
            );
            this.onTestFromRequest.next({
              request: request,
              result: response,
            });
            resolve(response);
          },
          async (error) => {
            this.logger.error(
              'TestErrorService.testErrorFromRequest() -> ERROR-ERROR: ' +
                JSON.stringify(error)
            );

            this.onTestFromRequest.next({
              request: request,
              result: error,
            });
            try {
              const decodedError = JSON.parse(await error.error.text());
              error.error = decodedError;
            } catch (Error) {
              this.logger.error(
                'TestErrorService.testErrorFromRequest()) -> try/catch ERROR: ' +
                  JSON.stringify(Error.message)
              );
            }

            reject(error);
          }
        );
    });
  }
}

export interface TestFromRequestResult {
  request: HttpRequest<any>;
  result: any;
}
