import { HttpClient } from '@angular/common/http';
import { Gt2ApiService } from './gt2-api.service';
import { ApiRoutes } from './ApiRoutes';
import { Injectable } from '@angular/core';
import { DateFormatPreference } from '../utils/DateFormatPreference';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable()
export class UserSettingsService {
  settings?: SettingsPrefModel;
  settingsObs?: any = null;

  // TODO: rename to OrganizationSettings
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private api: Gt2ApiService,
  ) {}

  public getSettings(): any {
    // this.logger.info("UserSettingsService.getSettings()");

    if (!this.settingsObs) {
      this.settingsObs = this.http
        .get<any>(this.api.createUrl(ApiRoutes.SETTINGS_PREF))
        .pipe(
          shareReplay(1),
          tap((res) => this.setUserSettings(res.data)),
        );
    }
    return this.settingsObs;

    // return this.http.get<any>(this.api.createUrl(ApiRoutes.SETTINGS_PREF)).do(res => this.setUserSettings(res.data));
  }

  public updateSocialCharge(charge: any, uuid: string): any {
    // this.logger.info("UserSettingsService.updateSocialCharge() -> charge: " + JSON.stringify(charge));
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SETTINGS_PREF) + '/' + uuid,
      charge,
    );
  }

  public updateDelivery(setting: any, uuid: string): any {
    // this.logger.info("UserSettingsService.updateDelivery() -> setting: " + JSON.stringify(setting));
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SETTINGS_PREF) + '/' + uuid,
      setting,
    );
  }

  public updateDefaultWrapping(setting: any, uuid: string): any {
    // this.logger.info("UserSettingsService.updateDefaultWrapping() -> setting: " + JSON.stringify(setting));
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SETTINGS_PREF) + '/' + uuid,
      setting,
    );
  }

  public updateDefaultTaxes(setting: any, uuid: string): any {
    // this.logger.info("UserSettingsService.updateDefaultTaxes() -> setting: " + JSON.stringify(setting));
    return this.http.patch<any>(
      this.api.createUrl(ApiRoutes.SETTINGS_PREF) + '/' + uuid,
      setting,
    );
  }

  //
  public setUserSettings(data: any): void {
    // this.logger.info("UserSettingsService.setUserSettings() -> data: " + JSON.stringify(data));
    this.settings = data;
    this.settingsObs = null;
    if (data && data.date_format && data.date_format.format_moment) {
      DateFormatPreference.saveDateFormat(data.date_format.format_moment);
    } else {
      DateFormatPreference.saveDateFormat(
        DateFormatPreference.DEFAULT_DATE_FORMAT,
      );
    }
  }
}

export interface SettingsPrefModel {
  object: string;
  uuid: string;
  datetime_format: DateTimeFormatModel;
  date_format: DateFormatModel;
  timezone: TimezoneModel;
  delivery_tax: DeliveryTaxModel;
  social_charge: number;
}

export interface DateTimeFormatModel {
  object: string;
  uuid: string;
  format: string;
  format_moment: string;
}

export interface DateFormatModel {
  object: string;
  uuid: string;
  format: string;
  format_moment: string;
  picker_format: string;
}

export interface TimezoneModel {
  object: string;
  uuid: string;
  name: string;
  location: string;
}

export interface DeliveryTaxModel {
  id: string;
  uuid: string;
  name: string;
  slug: string;
  tax_item_1_id: number;
  tax_item_2_id: number;
  tax_item_3_id: number;
  tax_item_4_id: number;
  from_date: string;
  to_date: string;
  percent_total: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  tax1: TaxModel;
  tax2: TaxModel;
  tax3: TaxModel;
  tax4: TaxModel;
}

export interface TaxModel {
  id: number;
  uuid: string;
  name: any;
  slug: string;
  percent_tax: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  pref: TaxPrefModel;
}

export interface TaxPrefModel {
  id: number;
  uuid: string;
  tax_number: string;
  tax_item_id: number;
  organization_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
