import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Settings } from '../../../settings/settings';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { of, merge } from 'rxjs';
import { startWith, switchMap, catchError, map } from 'rxjs/operators';
import { fuseAnimations } from '../../../../../@fuse/animations/index';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { DatatableHelperService } from '../../../../services/datatable-helper.service';
import { CrudDatatableGenericService } from '../../../api/crud-datatable-generic.service';
import { GenericCreateComponent } from '../../../components/dialogs/generic-create/generic-create.component';
import { GenericAlertComponent } from '../../../components/generic-alert/generic-alert.component';
import { GT2PageAbstract } from '../../abstract/GT2PageAbstract';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { Gt2PrimengModule } from '../../../modules/gt2-primeng.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CdkTableModule } from '@angular/cdk/table';
import { FuseDirectivesModule } from '../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../@fuse/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TableSizePipeModule } from '../../../modules/table-size.module';

@Component({
  selector: 'app-crud-datatable-generic',
  templateUrl: './crud-datatable-generic.component.html',
  styleUrls: ['./crud-datatable-generic.component.scss'],
  standalone: true,
  imports: [
    Gt2PrimengModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CdkTableModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatIconModule,
    MatButtonModule,
    MatSortModule,
    TranslateModule,
    MatTooltipModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    TableSizePipeModule,
  ],
  providers: [CrudDatatableGenericService, DatatableHelperService],
  animations: fuseAnimations,
})
export class CrudDatatableGenericComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit
{
  @Input() headerLabel!: string;
  @Input() serviceURL!: string;
  @Input() tableHeaderLabel!: string;
  @Input() createLabel!: string;
  @Input() createPlaceholder!: string;
  @Input() useLanguages: boolean = false;
  @Input() useSortWithLocal: boolean = false;
  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'edit', 'delete'];
  selected!: CrudDatatableGenericModel;
  editSelected!: CrudDatatableGenericModel | null;
  form: UntypedFormGroup;
  newCategory_fr_CA!: string;
  newCategory_en_CA!: string;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;

  confirmDialogRef: any;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  currentLanguage?: string;

  constructor(
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private crudDatatableGenericService: CrudDatatableGenericService,
    public datatableHelperService: DatatableHelperService,
    public translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    // TODO: Settings.midchar....
    this.form = this.formBuilder.group({
      name_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });
  }

  ngOnInit() {
    if (this.useLanguages) {
      this.form = this.formBuilder.group({
        name_fr_CA: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        name_en_CA: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
      });
    } else {
      this.form = this.formBuilder.group({
        name: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
      });
    }
  }

  ngAfterViewInit() {
    // //this.logger.info(">>>> useLanguages: " + this.useLanguages);
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(startWith(null))
      .pipe(
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true;
          });

          const customSort: string = this.useSortWithLocal
            ? this.sort?.active + '->' + this.translate.currentLang
            : this.sort.active;
          return this.crudDatatableGenericService.getAll(
            this.serviceURL,
            // this.sort.active,
            customSort,
            this.sort?.direction,
            this.paginator?.pageIndex,
            this.paginator?.pageSize,
          );
        }),
      )
      .pipe(
        map((data: any) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.meta.pagination.total;
          //this.logger.info(">>>>> " + JSON.stringify(data.data));
          return data.data;
        }),
      )
      .pipe(
        catchError(() => {
          setTimeout(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
          });
          return of([]);
        }),
      )
      .subscribe((data) => (this.dataSource.data = data));
  }

  public onSelect(selected: any) {
    //this.logger.info("CrudDatatableGeneric.onSelect() -> " + selected);
    this.onEdit(selected);
  }

  public reloadData(): void {
    // this.dataSource = new ClientsCategoriesDataSource(this.clientsCategoriesService);
    // Hack to refresh the Table
    this.paginator?._changePageSize(this.paginator.pageSize);
  }

  public onCreateNew() {
    // //this.logger.info("CrudDatatableGenericComponent.onCreateNew()");
    this.confirmDialogRef = this.dialog.open(GenericCreateComponent, {
      disableClose: false,
      width: '600px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      data: {
        useEnglish: this.useLanguages,
        dialogTitle: this.createLabel,
        serviceURL: this.serviceURL,
        createPlaceholder: this.createPlaceholder,
      },
    });

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // //this.logger.info("CrudDatatableGenericComponent.onCreateNew() -> CREATION SUCCESS");
        this.reloadData();
      } else {
        // //this.logger.info("CrudDatatableGenericComponent.onCreateNew() -> CREATION CANCELED");
      }
      this.confirmDialogRef = null;
    });
  }

  public onEdit(selected: CrudDatatableGenericModel) {
    //this.logger.info("CrudDatatableGenericComponent.onEdit() -> " + JSON.stringify(selected));
    if (!selected.editable) {
      //this.logger.info("CrudDatatableGenericComponent.onEdit() -> 1 " + selected.editable);
      return;
    }

    if (this.editSelected === selected) {
      //this.logger.info("CrudDatatableGenericComponent.onEdit() -> 2");
      this.editSelected = null;
      this.reloadData();
    } else {
      //this.logger.info("CrudDatatableGenericComponent.onEdit() -> 3");
      this.editSelected = selected;
    }
  }

  public onDelete(selected: CrudDatatableGenericModel) {
    // //this.logger.info("CrudDatatableGenericComponent.onDelete() -> " + JSON.stringify(selected));
    this.confirmDialogRef = this.dialog.open(GenericAlertComponent, {
      disableClose: false,
    });

    this.confirmDialogRef.componentInstance.useCancel = true;
    this.confirmDialogRef.componentInstance.alertTitle =
      this.translate.instant('GENERIC.DELETE');
    this.confirmDialogRef.componentInstance.alertMessage =
      this.translate.instant('GENERIC.DELETE_CONFIRM');

    this.confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.crudDatatableGenericService
          .delete(this.serviceURL, selected)
          .subscribe(
            (response: any) => {
              // //this.logger.info("CrudDatatableGenericComponent.onDelete() -> SUCCESS:" + JSON.stringify(response));
              this.toastr.success(
                this.translate.instant('GENERIC.DELETE_SUCCESS'),
              );
              this.reloadData();
            },
            (error: any) => {
              this.logger.error(
                'CrudDatatableGenericComponent.onDelete() -> ERROR' +
                  JSON.stringify(error),
              );
              // this.toastr.error('', this.translate.instant("GENERIC.ERRORS.GENERIC"));
              this.handleAPIError(
                error,
                this.dialog,
                this.toastr,
                this.translate.instant('GENERIC.ERRORS.GENERIC'),
              );
            },
          );
      }
      this.confirmDialogRef = null;
    });
  }

  // public onEditCategoryFocusOut(selected: CrudDatatableGenericModel, local: string): any
  public onEditCategoryFocusOut(
    selected: CrudDatatableGenericModel,
    name: string,
    local: string,
  ): any {
    // //this.logger.info("CrudDatatableGenericComponent.onEditCategoryFocusOut() -> name: " + name);
    // //this.logger.info("CrudDatatableGenericComponent.onEditCategoryFocusOut() -> local: " + local);

    // this.currentLanguage = this.translate.currentLang;
    // localStorage.setItem(LocalizationConfig.LOCAL_STORED_LANG_ID, local);

    // selected.name = name;
    this.autosaveDataChange(selected);
  }

  public autosaveDataChange(data: any): void {
    // //this.logger.info("CrudDatatableGenericComponent.autosaveDataChange() -> data to save: " + JSON.stringify(data));
    this.crudDatatableGenericService.edit(this.serviceURL, data).subscribe(
      (response: any) => {
        // //this.logger.info("CrudDatatableGenericComponent.autosaveDataChange() -> SUCCESS:" + JSON.stringify(response));
        // localStorage.setItem(LocalizationConfig.LOCAL_STORED_LANG_ID, this.currentLanguage);

        // if we don't use languages, we only have 1 input and no need to keep the edit open once edited successfully
        if (!this.useLanguages) {
          this.editSelected = null;
        }

        this.toastr.success(
          this.translate.instant('CRUD_GENERIC_DT.EDIT_SUCCESS_TOAST'),
        );
        this.reloadData();
      },
      (error: any) => {
        this.logger.error(
          'CrudDatatableGenericComponent.autosaveDataChange() -> ERROR' +
            JSON.stringify(error),
        );
        // localStorage.setItem(LocalizationConfig.LOCAL_STORED_LANG_ID, this.currentLanguage);
        // this.toastr.error(this.translate.instant('CRUD_GENERIC_DT.EDIT_ERROR_TOAST'));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('CRUD_GENERIC_DT.EDIT_ERROR_TOAST'),
        );
      },
    );
  }

  public onLanguageChange(lang: string): void {
    // //this.logger.info("CrudDatatableGenericComponent.onLanguageChange() -> " + lang);
    this.reloadData();
  }
}

export interface CrudDatatableGenericModel {
  object: string;
  uuid: string;
  name: string;
  slug: string;
  name_local: NameLocalModel;
  editable?: boolean;
}

export interface NameLocalModel {
  name_fr_CA: string;
  name_en_CA: string;
}
