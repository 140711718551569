<div style="max-height: 100%; overflow: auto">
  <h1 matDialogTitle class="h1-title">
    {{ "LOGIN.ERROR_DIALOG_TITLE" | translate }}
  </h1>

  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="mt-8 login-errors-width"
  >
    <div class="text-center">
      {{ "LOGIN.ERROR_DIALOG_NOT_CONFIRMED" | translate }}
    </div>
    <button mat-raised-button class="mat-accent mt-24" (click)="resendInvite()">
      {{ "LOGIN.RESEND_INVITE" | translate }}
    </button>
  </div>

  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>

  <div
    mat-dialog-actions
    class="mt-16"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button mat-button (click)="dialogRef.close(true)">
      {{ "GENERIC.OK" | translate }}
    </button>

    <button mat-button (click)="dialogRef.close(false)">
      {{ "GENERIC.CANCEL" | translate }}
    </button>
  </div>
</div>
