<div>
  <div class="border-bottom">
    <div
      *ngIf="!isLoadingResults && resultsLength === 0"
      fxLayout="row"
      fxLayoutAlign="center start"
      class="p-8 mat-subheading-2"
      style="margin-bottom: -3px"
    >
      {{ "CONGRESSES.NO_INVOICES" | translate }}
    </div>

    <div *ngIf="isLoadingResults" class="">
      <mat-progress-bar
        mode="query"
        color="warn"
        style="height: 3px"
      ></mat-progress-bar>
    </div>
  </div>

  <mat-table
    #table
    class=""
    matSort
    matSortStart="desc"
    *ngIf="dataSource && dataSource.data"
    [ngStyle]="{
      display:
        dataSource && dataSource.data && dataSource.data.length === 0
          ? 'none'
          : ''
    }"
    [@animateStagger]="{ value: '50' }"
    [dataSource]="dataSource"
  >
    <ng-container cdkColumnDef="name">
      <mat-header-cell fxFlex="50" *cdkHeaderCellDef mat-sort-header
        >{{ "GENERIC.NAME" | translate }}
      </mat-header-cell>

      <mat-cell fxFlex="50" *cdkCellDef="let invoice">
        <p class="text-truncate font-weight-500">
          {{ congress.name }}
        </p>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="open">
      <mat-header-cell fxFlex="150px" *cdkHeaderCellDef mat-sort-header>
        <!-- {{ 'GENERIC.CLIENT' | translategt }} -->
        &nbsp;
      </mat-header-cell>

      <mat-cell fxFlex="150px" *cdkCellDef="let invoice">
        &nbsp;
        <!-- <p class="text-truncate font-weight-500">
                    <button class="" mat-icon-button (click)="onOpenPage(invoice)">
                        <mat-icon class="s-16 secondary-text">{{ getIcon('EDIT') }}</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}" (click)="onOpenInNewTab(invoice)"
                        class="mat-icon-button mr-24">
                        <mat-icon class="s-16">{{ getIcon('NEW_TAB') }}</mat-icon>
                    </button>
                </p> -->
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="actions">
      <mat-header-cell fxFlex="50px" *cdkHeaderCellDef mat-sort-header>
      </mat-header-cell>
      <mat-cell fxFlex="50px" *cdkCellDef="let invoice">
        &nbsp;
        <!-- $event.stopPropagation() -->

        <!-- <button tabindex="-1" [autofocus]="false" type="button" 
                     mat-icon-button class="">
                    <mat-icon class="" color="{{null}}">{{
                        iconsService.getIcon('PRINT') }}</mat-icon>
                </button>

                <button tabindex="-1" [autofocus]="false" type="button"
                      mat-icon-button class="" >
                    <mat-icon  class="" color="{{null}}">{{
                        iconsService.getIcon('PDF') }}</mat-icon>
                </button> -->

        <!-- <button tabindex="-1" [autofocus]="false" type="button" *ngIf="fileService.printEnabled() && vo.export_to === 'PRINT'"
                    matTooltip="{{ tooltipLabel | translate }}" #myTooltip="tooltip" mat-icon-button class="" (click)="onReport()">
                    <mat-icon  class="" color="{{n}}">{{
                        iconsService.getIcon('PRINT') }}</mat-icon>
                </button> -->

        <!-- <app-report-button [vo]="reportFactoryService.getVO('REPORT2_ADMIN_CHANGE_LOG', 
                                                                DistributionModeEnum.FILE,
                                                                ExportFormatEnum.PDF, 
                                                                ExportToEnum.PRINT,
                                                                dateRangeCongress)">
                </app-report-button>
                <app-report-button [vo]="reportFactoryService.getVO('REPORT2_ADMIN_CHANGE_LOG', 
                                                                DistributionModeEnum.FILE,
                                                                ExportFormatEnum.PDF, 
                                                                ExportToEnum.DOWNLOAD,
                                                                dateRangeCongress)">
                </app-report-button> -->

        <!-- <button class="" mat-icon-button (click)="onOpenPage(invoice)">
                    <mat-icon class="s-16 secondary-text">{{ getIcon('EDIT') }}</mat-icon>
                </button>
                <button mat-icon-button matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}" (click)="onOpenProjectInNewTab(invoice)"
                    class="mat-icon-button">
                    <mat-icon class="s-16">{{ getIcon('NEW_TAB') }}</mat-icon>
                </button> -->
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row
      *cdkRowDef="let clients; columns: displayedColumns"
      class=""
      matRipple
      [routerLink]="
        '/' + appRoutesService.clients + '/' + clients.slug + '/' + clients.uuid
      "
    >
    </mat-row>
  </mat-table>
  <!-- <mat-paginator #paginator [length]="paginator.length" (page)='syncPrimaryPaginator($event)'
        [ngStyle]="{'display': (dataSource && dataSource.data && dataSource.data.length === 0) ? 'none' : ''}"
        showFirstLastButtons="true" [pageIndex]="paginator.pageIndex" [pageSize]="paginator.pageSize"
        [pageSizeOptions]="paginator.pageSizeOptions">

    </mat-paginator> -->
  <mat-paginator
    #paginator
    [length]="resultsLength"
    [ngStyle]="{
      display:
        dataSource && dataSource.data && dataSource.data.length === 0
          ? 'none'
          : ''
    }"
    (page)="datatableHelperService.onPaginator($event, 'clients-invoices')"
    showFirstLastButtons="true"
    [pageIndex]="0"
    [pageSize]="'clients-invoices' | tableSizePipe : 'large'"
    [pageSizeOptions]="datatableHelperService.pageSizeShortOptions"
  >
  </mat-paginator>

  <div
    *ngIf="isLoadingResults && !isFirstLoad"
    fxLayout="row"
    fxLayoutAlign="center start"
    class=""
  >
    <mat-progress-bar
      mode="query"
      color="warn"
      style="height: 3px"
    ></mat-progress-bar>
  </div>
</div>
