export const locale = {
  lang: "en-CA",
  data: {
    SERVICE_CHARGE: {
      CREATE_NEW_CATEGORY: "New category",
      CATEGORY_NAME: "Category name",
      NEW_CATEGORY_NAME: "Name of new category",
      CREATE_SUCCESS: "Category created successfully",
      EDIT_SUCCESS: "Category modified successfully",
      DELETE_SUCCESS: "Category deleted successfully",
      ACCOUNT_NUMBER: "Account number",
      LEDGER_ACCOUNT: "Ledger account",
      TAXES: "Tax",
      SELECT_SERVICE_CHARGE_MULTIPLIER: "Sélectionner un  multiplicateur",
      DISCOUNT: "Discount",
      SURCHARGE: "Surcharge",
      AMOUNT: "Amount",
    },
  },
};
