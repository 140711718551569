import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from '../i18n/en-CA';
import { locale as french } from '../i18n/fr-CA';
import { GT2PageAbstract } from '../../../abstract/GT2PageAbstract';
import { Settings } from '../../../../settings/settings';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { EventLogisticStatusService } from '../../../../api/event-logistic-status.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-service-charge-create',
  templateUrl: './event-logistic-status-create.component.html',
  styleUrls: ['./event-logistic-status-create.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
})
export class EventLogisticStatusCreateComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  serviceURL!: string;
  createPlaceholder!: string;
  dialogTitle!: string;
  useEnglish: boolean = true;
  form!: UntypedFormGroup;
  model: any;
  loading: boolean = false;
  data_obj: any;
  data_obj_to_update: any;
  constructor(
    public override dialogRef: MatDialogRef<EventLogisticStatusCreateComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public eventLogisticStatusService: EventLogisticStatusService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    public logger: NGXLogger,
  ) {
    super();
    //this.logger.info("EventLogisticStatusCreateComponent.constructor", data);
    this.translationLoader.loadTranslations(english, french);
    if (data) {
      this.dialogTitle = data.dialogTitle;
      this.serviceURL = data.serviceURL;
      this.createPlaceholder = data.createPlaceholder;

      if (data.useEnglish !== null) {
        this.useEnglish = data.useEnglish;
      }
    }
    this.init();
    this.createForm();
  }

  ngOnInit() {
    //this.logger.info("EventLogisticStatusCreateComponent.ngOnInit()");
  }

  ngAfterViewInit() {
    //this.logger.info("EventLogisticStatusCreateComponent.ngAfterViewInit()");
  }

  ngOnDestroy() {}

  public createForm(): void {
    //this.logger.info("EventLogisticStatusCreateComponent.createForm()", this.useEnglish);
    if (this.useEnglish) {
      this.form = this.formBuilder.group({
        name_fr_CA: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
        name_en_CA: [
          '',
          [
            Validators.minLength(Settings.inputMinChar),
            Validators.maxLength(Settings.inputMaxCharMid),
            Validators.required,
          ],
        ],
      });
    }
  }

  // public setPercentForm(): void {
  //     //this.logger.info("EventLogisticStatusCreateComponent.setPercentForm() ->");
  //     if (this.useEnglish) {

  //     } else {

  //     }
  // }
  public init() {
    //this.logger.info("EventLogisticStatusCreateComponent.init()");
    this.data_obj = {
      uuid: this.data_obj_to_update ? this.data_obj_to_update.uuid : null,
      name_local: {
        name_fr_CA: this.data_obj_to_update
          ? this.data_obj_to_update.name_local.name_fr_CA
          : null,
        name_en_CA: this.data_obj_to_update
          ? this.data_obj_to_update.name_local.name_en_CA
          : null,
      },
    };
  }

  public onCreateNew() {
    // //this.logger.info("EventLogisticStatusCreateComponent.onCreateNew() - this.newCategory_fr_CA: " + this.newCategory_fr_CA);
    // //this.logger.info("EventLogisticStatusCreateComponent.onCreateNew() - this.newCategory_en_CA: " + this.newCategory_en_CA);
    // //this.logger.info("EventLogisticStatusCreateComponent.onCreateNew() - this.serviceURL: " + this.serviceURL);
    // const obj: any = {
    //     name_local: {
    //         name_fr_CA: this.newCategory_fr_CA,
    //         name_en_CA: this.newCategory_en_CA,
    //     },
    //     ledger_account: this.ledger_account,
    //     price: this.price,
    //     tax: this.tax,
    //     enterprise_uuid: (this.enterpriseUuid) ? this.enterpriseUuid : null,
    //     multiplier:
    //         this.selectedServiceChargeMultiplier !== null
    //             ? this.selectedServiceChargeMultiplier.value
    //             : null,
    // };

    // if (
    //     this.selectedServiceChargeMultiplier &&
    //     this.selectedServiceChargeMultiplier.value ===
    //     ServiceChargeMultiplyEnum.PERCENTAGE_BY_INVOICE_CATEGORIES
    // ) {
    //     obj.percentage_by = this.percent;
    //     obj.has_food = this.royalty_on_food;
    //     obj.has_beverage = this.royalty_on_beverage;
    //     obj.has_material = this.royalty_on_material;
    //     obj.has_service = this.royalty_on_service;
    //     obj.has_delivery = this.royalty_on_delivery;
    // }

    //this.logger.info("EventLogisticStatusCreateComponent.onCreateNew");

    //this.logger.info("EventLogisticStatusCreateComponent.onCreateNew-data_obj: " + JSON.stringify(this.data_obj));
    this.eventLogisticStatusService.create(this.data_obj).subscribe(
      (response: any) => {
        //this.logger.info("EventLogisticStatusCreateComponent.onCreateNew() -> SUCCESS:" + JSON.stringify(response));
        this.toastr.success(
          this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_SUCCESS'),
        );
        //                 this.newCategory_fr_CA = null;
        //this.newCategory_en_CA = null;
        this.dialogRef.close(response.data);
        // this.form.markAsUntouched();
        // this.form.markAsPristine();
        // this.reloadData();
      },
      (error: any) => {
        this.logger.error(
          'EventLogisticStatusCreateComponent.onCreateNew() -> ERROR' +
            JSON.stringify(error),
        );
        // this.toastr.error(this.translate.instant('GENERIC.ERRORS.GENERIC'));
        this.handleAPIError(
          error,
          this.dialog,
          this.toastr,
          this.translate.instant('GENERIC.ERRORS.GENERIC'),
        );
      },
    );
  }
  public onUpdate() {
    //this.logger.info("EventLogisticStatusCreateComponent.onUpdate");
    /* //this.logger.info("EventLogisticStatusCreateComponent.obj: " + JSON.stringify(this.serviceCharge));
        this.eventLogisticStatusService.update(this.serviceCharge).subscribe(
            (response) => {
                this.toastr.success(
                    this.translate.instant(
                        "GENERIC.API_CALL_SUCCESS.CREATE_SUCCESS"
                    )
                );
                this.dialogRef.close(response.data);
            },
            (error) => {
                this.logger.error(
                    "ServiceChargeCreateComponent.onCreateNew() -> ERROR" +
                        JSON.stringify(error)
                );
                this.handleAPIError(
                    error,
                    this.dialog,
                    this.toastr,
                    this.translate.instant("GENERIC.ERRORS.GENERIC")
                );
            }
        ); */
  }
}
