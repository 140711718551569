<div fxFlex="100%" fxLayout="column">
  <div fxLayout="column">
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxFlex="100%"
      class="mat-title"
    >
      {{ title }}
    </div>
    <div fxLayout="column" class="px-16">
      <mat-form-field floatLabel="auto" fxFlex="1 0 auto" class="mr-4">
        <mat-label>{{ "GENERIC.SEARCH" | translate | titlecase }}</mat-label>
        <input id="search" [(ngModel)]="filterValue" matInput #filter />
        <button
          mat-button
          *ngIf="filterValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <div fxLayout="row" style="margin-bottom: 16px">
        <div fxLayout="column" fxFlex="100%">
          <div
            fxLayout="row"
            fxLayoutAlign="center start"
            class="pb-4 mat-caption"
          >
            {{ labelDraggable }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="100%">
          <div
            fxLayout="row"
            fxLayoutAlign="center start"
            class="pb-4 mat-caption"
          >
            {{ labelDroppable }}
          </div>
        </div>
      </div>

      <div cdkDropListGroup class="" fxLayout="row">
        <div
          cdkDropList
          [cdkDropListData]="draggableList"
          (cdkDropListDropped)="onDeattachedItem($event)"
          class="ngx-dnd-container-custom mr-4 ngx-dnd-scrollable"
          ngxDroppable
          fxLayout="column"
          fxFlex="50%"
          style="overflow: auto; height: 65vh"
        >
          <div
            *ngIf="isLoadingDraggables"
            fxLayout="column"
            fxFlex="100%"
            fxLayoutAlign="center center"
          >
            <mat-spinner [diameter]="20" class=""></mat-spinner>
          </div>

          <div
            *ngIf="
              !isLoadingDraggables &&
              draggableList &&
              draggableList.length === 0
            "
            fxLayout="row"
            fxLayoutAlign="center center"
            class="mt-8"
          >
            {{ "GENERIC.NO_RESULTS" | translate }}
          </div>

          <div *ngFor="let item of draggableList; let i = index">
            <div
              *ngIf="
                filterValue === '' ||
                item.name.toLowerCase().indexOf(filterValue.toLowerCase()) !==
                  -1
              "
              [cdkDragData]="item"
              cdkDrag
              matRipple
              class="product-child-item"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div fxLayout="row">
                <mat-icon cdkDragHandle class="mr-8">drag_handle</mat-icon>
                <div>{{ item.name }}</div>
              </div>

              <button mat-icon-button (click)="onAddItem(item, true)">
                <mat-icon class="" style="color: black">add</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div
          cdkDropList
          [cdkDropListData]="droppableList"
          (cdkDropListDropped)="onDrop($event)"
          class="ngx-dnd-container-custom ml-4 combo-list ngx-dnd-scrollable"
          ngxDroppable="target"
          style="height: 65vh; overflow: auto"
          fxLayout="column"
          fxFlex="50%"
        >
          <div
            *ngIf="isLoadingDroppables"
            fxLayout="column"
            fxFlex="100%"
            fxLayoutAlign="center center"
          >
            <mat-spinner [diameter]="20" class=""></mat-spinner>
          </div>
          @for (item of droppableList; track item; let i = $index) {
            <div matRipple [cdkDragData]="item" cdkDrag ngxDraggable>
              <div
                class="product-child-item-right"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon cdkDragHandle class="mr-8">drag_handle</mat-icon>
                  <div>{{ item.name }}</div>
                </div>
                <mat-icon (click)="onRemoveItem(item, i)" class="ml-8"
                  >delete</mat-icon
                >
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>

  <!--HACK TO MAKE THE D&D ABOVE WORK (TODO: fix the style/code that does not seem to import here if not present-->
  <!-- <ngx-dnd-container
    [(ngModel)]="['Item 1', 'Item 2', 'Item 3']"
    style="display: none"
  >
  </ngx-dnd-container> -->

  <div
    mat-dialog-actions
    class="mt-16"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      mat-raised-button
      color="accent"
      (click)="dialogRef.close(true)"
      class=""
    >
      {{ "GENERIC.OK" | translate }}
    </button>
  </div>
</div>
