export const locale = {
  lang: "en-CA",
  data: {
    EVENTS: {
      EVENT: "Event",
      LOGISTIC: "Logistic",
      ACCOUNTING: "Cashing and deposit",
      DOCUMENTS: "Documents",
      DOCUMENT_NUMBER: "Document #",
      NO_DOCUMENTS: "No document",
      FLOW: "Flow",
      NO_FLOW: "No flow",
      GUEST: "Guest",
      GUESTS: "Guests",
      NO_GUEST: "No guests",
      DELIVERY_REQUEST: "Delivery request",
      NO_DELIVERY_REQUEST: "No delivery request",
      NO_TAKEOUT_REQUEST: "No takeout request",
      STATS: "Statistics",
      DEPOSIT_REQUEST: "Deposit request",
      CREATE_DEPOSIT_REQUEST: "New deposit request",
      NO_DEPOSIT_REQUEST: "No deposit request for this event",
      DEPOSIT_IN: "Cashing",
      CREATE_DEPOSIT_IN: "New Cashing",
      NO_DEPOSIT_IN: "No cashing for this event",
      INVOICES: "Factures",
      PO_NUMBER: "Purchase order number",
      EVENT_DATE: "Event date",
      EVENT_DATE_START: "Event start date",
      EVENT_DATE_END: "Event end date",
      EVENT_TIME_START: "Event start time",
      EVENT_TIME_END: "Event end time",
      EVENT_TIME: "Event time",
      VISIT_ORDER: "See order",
      VISIT_INVOICE: "See invoice",
      ORDER_EXIST: "An order already exist",
      ADD_PROPOSAL: "Add a proposal",
      ADD_FLOW: "Add a flow",
      ADD_PROGRAM: "Add a program",
      ADD_GUEST: "Add a guess type",
      ORDER_CLIENT: "Ordered by client",
      INVOICE_CLIENT: "Invoiced to client",
      NO_CLIENT_SELECTED: "No client selected",
      SELECT_CLIENT: "Select a client",
      SELECT_SAME_CLIENT: "Same client",
      SEE_NOTE: "See note",
      BALANCE: "Balance",
      TOTAL_PER_GUEST: "Total per guest",
      NEXT_PAYMENT_DATE: "Next payment date",
      SELECT_CLIENT_FIRST: "You must select a client",
      SET_CLIENT_ADDRESS: "This client does not have an address",
      SELECT_DELIVERY_TYPE: "Delivery or takeout",
      DOES_NOT_NEED_DELIVERY: "The client will pick up in store",
      NO_VENUES_SELECTED: "No place selected",
      SELECT_VENUE_PLACE: "Choose a place",
      SELECT_ORDERING_CLIENT: "Choose ordering client",
      SELECT_ENTERPRISE_ORDERING_CLIENT: "Choose ordering client enterprise",
      ADD_PLACE: "Add a new place",
      ADD_ROOM: "Add a new room",
      CAPACITY: "Capacity",
      ROYALTY: "Royalty",
      DATE_DUE: "Due date",
      NET_TOTAL: "Net total",
      RECEIVED_ON: "Received on",
      RECEIVED_FROM: "Received from",
      MODE: "Mode",
      DEPOSIT_NUMBER: "Deposit #",
      INVOICE_NUMBER: "Invoice #",
      REFERENCE_NUMBER: "Reference",
      RESPONSIBLE_EMPLOYEE: "Responsible employee",
      SELECT_RESPONSIBLE_EMPLOYEE: "Select a responsible employee",
      ORDERING_LOCAL: "Select ordering client language",
      INVOICING_LOCAL: "Select invoicing client language",
      CANCEL_EVENT: "Cancel event",
      ABORT_EVENT: "Abandon event",
      CANCEL_EVENT_SUCESS: "Event cancelled!",
      ABORT_EVENT_SUCESS: "Event aborted!",
      EVENT_TYPE: "Event type",
      SELECT_EVENT_TYPE: "Select event type",
      REPORT_RECEIVED: "Send out a receipt",
      DELIVERY_REQUEST_SPOT_NOT_SELECTTED: "A place must be selected before creating a new delivery request",
      COMPLETE_INVOICE_DIALOG_MESSAGE: "Since the balance is now at 0$ or negative, would you like to complete de invoice?",
      CANCEL_EVENT_DIALOG_MESSAGE: "Are you sure you want to cancel this event?",
      ABORT_EVENT_DIALOG_MESSAGE: "Are you sure you want to abort this event?",
      REOPEN_EVENT: "Open event",
      RECLOSE_EVENT: "Close event",
      REOPEN_EVENT_DIALOG_MESSAGE: "Are you sure you want to re-open this event?",
      ORG_IDENTITY: "Organization identity",
      ORG_IDENTITY_SELECT: "Select an identity",
      ORG_IDENTITY_HINT: "Select an identity if the customer wishes to pick up his order at a different pick-up point than the identity selected in the information section.",
      ORG_IDENTITY_DROPDOWN_ITEM: "Identity",
      ORG_IDENTITY_HELP: "You can, if necessary, apply a different identity to your event.",
      TAKEOUT_REQUEST: "Takeout request",
      ADD_DELIVERY_REQUEST: "Add a delivery request",
      ADD_TAKEOUT_REQUEST: "Add a takeout request",
      ASSIGNEMENTS: "Assignments",
      ASSIGNEMENTS_REPORTS: "Assignments reports",
      ASSIGNMENT_SALE: "Assignments - sales",
      ASSIGNMENT_COST: "Assignments - costs",
      ASSIGNMENT_ATTENDANCE: "Attendance record",
      ADD_SPORADIC_ADDRESS: "Add a sporadic address",
      SPORADIC_ADDRESS: "Sporadic address delivery",
      FILES: "Files",
      ATTACH_FILE: "Attach a document",
      NO_ATTACHED_FILES: "No files",

      UNSUPPORTED_FILE_TITLE: "File type not supported.",
      UNSUPPORTED_FILE_MESSAGE: "The types of files you can attach are: pdf, word document, excel and images.",
      TOO_MANY_FILE_MESSAGE: "You can only drop file one at a time.",
      FILE_TOO_BIG_MESSAGE: "The file exceeds the maximum size of 10 mb.",
      ROOM_STATUS: "Room status",
      NO_PROGRAMS: "Aucun programmes",
      WITH_STAFF: "With staffs",
      TAKEOUT: "Pickup at the counter",
      DELIVERY_ONLY: "Delivery only",
      DELIVERY_INSTALL: "Deliver and install",
      CONGRESS_BLOQUES: "The client cannot be changed if a congress is attached!",
      CHANGELOG: "Notice of change",
      CREATE_OKA_DEPOSIT_REQUEST: "Generate deposit calculation",
      RECEIPT_NOTICE: "Notice of receipt",
      STORAGE_NOTICE: "Storage notice",
      ORGANIZER_PERSON: "Guarantor and/or responsable (person present at event)",
      RECEIPT_NOTICE_LOCAL: "Event room with meeting permit",
      STORAGE_DATE: "Storage start date",
      STORAGE_DATE_END: "Storage end date",
      RECEIPT_NOTICE_HOUR: "Hour",
      START_TIME: "Heure de début",
      END_TIME: "Heure de fin",
      EVENT_NAME: "Event name",
      EVENT_PLACE: "Event place (local)",
      UBR_MANAGER_EMAIL: "UBR responsable email",
      UBR_CODE: "UBR code",
      VALID_UBR_CODE: "The UBR code field is mandatory and must be a valid code",
      NO_UBR_RESULTS: "No result",
    },
  },
};
