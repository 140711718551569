<div style="max-height: 100%; overflow: auto">
  <div class="profile-box info-box" fxLayout="column">
    <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title p-8">{{ forProduct }}</div>
    </header>
  </div>

  <div class="p-12">
    <div *ngIf="choicesProduct" style="width: 100%">
      <div
        *ngFor="let product of choicesProduct"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        style="width: 100%"
      >
        <div class="mr-12">{{ product.name_internal_org }}:</div>
        <app-choice-combo-select
          [product]="product"
          (onSelection)="onChoiceSelection($event, product)"
        ></app-choice-combo-select>
      </div>
    </div>

    <div
      mat-dialog-actions
      class="pt-16 pb-4"
      fxLayout="row"
      fxLayoutAlign="center start"
    >
      <button mat-raised-button (click)="onSaveChoices()" class="accent mr-16">
        {{ "GENERIC.SAVE" | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="dialogRef.close(false)"
        class=""
      >
        {{ "GENERIC.CANCEL" | translate }}
      </button>
    </div>
  </div>
</div>
