<div
  id="knowledgebase"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll">
  <!--<div id="knowledgebase" class="page-layout simple left-sidebar inner-sidebar inner-scroll">-->

  <!-- HEADER -->
  <div class="gt2-theme">
    <div
      class="header accent p-24 h-80 mat-elevation-z1"
      fxLayout="row"
      fxLayoutAlign="start center">
      <div
        fxLayout="column"
        fxLayoutAlign="center start">
        <!--<div class="black-fg" fxLayout="row" fxLayoutAlign="start center">-->
        <!--<mat-icon class="secondary-text s-16">home</mat-icon>-->
        <!--<mat-icon class="secondary-text s-16">chevron_right</mat-icon>-->
        <!--<span class="secondary-text">{{ 'GENERIC.SUPPORT' | translate }}</span>-->
        <!--</div>-->
        <div class="h1">Base de connaissances</div>
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <!--<div fusePerfectScrollbar>-->
  <div
    class=""
    fusePerfectScrollbar>
    <div class="content">
      <div
        fxLayout="row wrap"
        fxLayoutAlign="center"
        *ngIf="knowledgeBase">
        <mat-nav-list
          class="articles-list mat-elevation-z4 p-0"
          *ngFor="let category of knowledgeBase">
          <h3
            mat-subheader
            class="mat-subheading-2 primary">
            {{ category.name }}
          </h3>
          <mat-divider></mat-divider>
          <div
            class="articles-list-content px-24 py-8"
            fusePerfectScrollbar>
            <a
              mat-list-item
              *ngFor="let article of category.kb"
              (click)="readArticle(article)">
              <mat-icon class="secondary-text mr-8">note</mat-icon>
              {{ article.title }}
            </a>
          </div>

          <!--<a mat-list-item class="see-all-link accent-fg" [routerLink]="category.path">-->
          <!--See all articles ({{category.kb.length}})-->
          <!--</a>-->
        </mat-nav-list>
      </div>
    </div>
    <!-- / CONTENT -->

    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->

    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->

    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
    <!--<div style="width: 200px; height: 120px; margin-bottom: 8px; background-color: #4CAF50">test</div>-->
  </div>
</div>
