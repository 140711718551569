export const locale = {
  lang: "fr-CA",
  data: {
    SELECT_CLIENT: {
      TITLE: "Sélectionner un client",
      BY_ENTERPRISE: "Filtrer par entreprise",
      BY_CLIENT: "Filtrer par client",
      SELECTED_CLIENT: "Client(e) sélectionné(e) : ",
      NO_CLIENTS: "Aucun client",
      SEARCH: "Rechercher votre client par son nom ou le nom de l’entreprise",
      CREATE_CLIENT: "Créer un client",
      CREATE_ENTERPRISE: "Créer une entreprise",
      CREATE_CLIENT_ENTERPRISE: "Créer un client et entreprise",
      NEW_CLIENT_TOOLTIP: "Ajouter un nouveau client ou entreprise",
    },
  },
};
