import { MaterialCategoryService } from './../../../../api/material-category.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { OrdersService } from '../../../../api/orders.service';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import { Settings } from '../../../../settings/settings';
import { ValidatorHelper } from '../../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from '../../i18n/en-CA';
import { locale as french } from '../../i18n/fr-CA';
import { Editor } from 'primeng/editor';
import { Subscription } from 'rxjs';
import { MaterialProviderService } from '../../../../api/material-provider.service';
import { OrderComponent } from '../order.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { WrappingService } from '../../../../api/wrapping.service';
import { SpecificConditionService } from '../../../../api/specific-condision.service';
import { ProposalComposeService } from '../../../../api/proposal-compose.service';
import { ProposalComposeCTService } from '../../../../api/proposal-compose-ct.service';
import { OrderComposeService } from '../../../../api/order-compose.service';
import { InvoiceComposeService } from '../../../../api/invoice-compose.service';
import { InvoiceComposeCTService } from '../../../../api/invoice-compose-ct.service';
import { InvoiceComposeGlobalPaymentsService } from '../../../../api/invoice-compose-global-payments.service';
import { CatererService } from '../../../caterer/caterer.service';
import { ConseillersService } from '../../../../api/conseillers.service';
import { ReportsService } from '../../../../reports/reports.service';
import { ReportFactoryService } from '../../../../reports/report-factory.service';
import { MenuReportsService } from '../../../../reports/menu-reports.service';
import { ReceiptNoticeComposeService } from '../../../../api/receipt-notice-compose.service';
import { ReceiptNoticeSellOnlyComposeService } from '../../../../api/receipt-notice-sell-only-compose.service';
import { StorageNoticeComposeService } from '../../../../api/storage-notice-compose.service';
import { Gt2SelectComponent } from '../../../../components/gt2-select/gt2-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-edit-material',
  templateUrl: './edit-material.component.html',
  styleUrls: ['./edit-material.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    TranslateModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    CommonModule,
    ReactiveFormsModule,
    Gt2SelectComponent,
  ],
  providers: [
    WrappingService,
    SpecificConditionService,
    ProposalComposeService,
    ProposalComposeCTService,
    OrderComposeService,
    InvoiceComposeService,
    InvoiceComposeCTService,
    InvoiceComposeGlobalPaymentsService,
    CatererService,
    ConseillersService,
    ReportsService,
    ReportFactoryService,
    MenuReportsService,
    ReceiptNoticeComposeService,
    ReceiptNoticeSellOnlyComposeService,
    StorageNoticeComposeService,
  ],
})
export class EditMaterialComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('prodNoteEditor') prodNoteEditor?: Editor;
  noteSubscription?: Subscription;
  material: any;
  orderUUID!: string;
  loading: boolean = false;
  form: UntypedFormGroup;
  materialCategory: any;
  noteInvalid: any = {
    sale_note: false,
    logistic_notes: false,
    description_fr_CA: false,
    description_en_CA: false,
  };

  constructor(
    public override dialogRef: MatDialogRef<EditMaterialComponent>,
    private translationLoader: FuseTranslationLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    public ordersService: OrdersService,
    private logger: NGXLogger,
    public materialCategoryService: MaterialCategoryService,
    public materialProviderService: MaterialProviderService,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    if (data) {
      this.material = data.material;
      this.orderUUID = data.orderUUID;
      this.materialCategory = data.materialCategory;
      //   //this.logger.info("EditMaterialComponent.constructor() -> DATA product: " + JSON.stringify(this.material));
    } else {
      this.logger.error(
        'EditMaterialComponent.constructor() -> NO DATA passed to this dialog!',
      );
    }
    //
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_internal_fr_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      name_internal_en_CA: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
      unit_price: [
        '',
        [ValidatorHelper.isValidPriceNumber, Validators.required],
      ],
      quantity: ['', [ValidatorHelper.isAboveZero, Validators.required]],
      logistic_notes: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
      sale_note: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
      description_fr_CA: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
      description_en_CA: [
        '',
        [
          // Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharLong),
        ],
      ],
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.prodNoteEditor) {
      this.noteSubscription = this.prodNoteEditor.onTextChange.subscribe(
        (data) => {
          //this.logger.info("EditProductComponent.noteEditor.onTextChange() -> " + data.htmlValue);
          //this.logger.info("EditProductComponent.noteEditor.onTextChange() -> invalid: " + (data.htmlValue.length > Settings.editorMaxCharLong));
          //this.logger.info("EditProductComponent.noteEditor.onTextChange() -> form.valid: " + this.form.valid);
          this.noteInvalid = data.htmlValue.length > Settings.editorMaxCharLong;
        },
      );
    } else {
      this.logger.error(
        'EditProductComponent.ngAfterViewInit() -> ERROR: prodNoteEditor is not defined!',
      );
    }
  }

  ngOnDestroy() {}

  public onSaveMaterial() {
    // //this.logger.info("EditMaterialComponent.onSaveMaterial() -> this.material: " + JSON.stringify(this.material));
    this.material.material_category = this.materialCategory;
    this.loading = true;
    this.ordersService
      .updateMaterials(this.orderUUID, [this.material])
      .subscribe(
        (response: any) => {
          // //this.logger.info("EditMaterialComponent.onSaveMaterial() -> SUCCESS: " + JSON.stringify(response));
          this.loading = false;
          const materials = {
            ...response.data,
            material_category_collection:
              response.data.material_category_collection.map(
                (material: any) => ({
                  ...material,
                  material_collection: material.material_collection.map(
                    (mc: any) =>
                      mc.combo && mc.combo.length > 0
                        ? {
                            ...mc,
                            comboToggleOpen:
                              localStorage.getItem(
                                OrderComponent.ORDER_COMBO_OPEN + mc.uuid,
                              ) === 'true',
                          }
                        : mc,
                  ),
                }),
              ),
            product_category_collection:
              response.data.product_category_collection.map((product: any) => ({
                ...product,
                product_collection: product.product_collection.map((pc: any) =>
                  pc.combo && pc.combo.length > 0
                    ? {
                        ...pc,
                        comboToggleOpen:
                          localStorage.getItem(
                            OrderComponent.ORDER_COMBO_OPEN + pc.uuid,
                          ) === 'true',
                      }
                    : pc,
                ),
              })),
          };
          this.dialogRef.close(materials);
        },
        (error: any) => {
          this.logger.error(
            'EditMaterialComponent.onSaveMaterial() -> ERROR: ' +
              JSON.stringify(error),
          );
          this.handleAPIError(error, this.dialog, null, null);
          this.loading = false;
        },
      );
  }

  public onMaterialCategoryChange(event: any) {
    // //this.logger.info(
    //     "ProductComponent.onProductCategoryChange() -> category: " +
    //         JSON.stringify(event)
    // );
    this.materialCategory = event;
  }
  public onMaterialProviderSelected(event: any) {
    ////this.logger.info(
    //  "ProductComponent.onMaterialProviderSelected() -> Provider: " +
    //     JSON.stringify(event)
    //);
    this.material.material_provider = event;
  }
}
