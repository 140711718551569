<div
  id="forgot-password"
  class="inner-scroll"
  fxLayout="row"
  fxLayoutAlign="start"
>
  <div id="forgot-password-intro" fxFlex fxHide fxShow.gt-xs>
    <div
      class="logo"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { scale: '0.5' } }"
    >
      <img src="assets/images/logos/{{ 'APP.LOGO' | translate }}" />
    </div>

    <div
      class="title"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }"
    >
      {{ "LOGIN.WELCOME_MESSAGE" | translate }}
    </div>

    <div
      class="description"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }"
    >
      {{ "LOGIN.LONG_WELCOME_MESSAGE" | translate }}
    </div>

    <div
      class="description2"
      *fuseIfOnDom
      [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }"
    >
      {{ "LOGIN.LONG_WELCOME_MESSAGE_2" | translate }}
    </div>
  </div>

  <div
    id="forgot-password-form-wrapper"
    fusePerfectScrollbar
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
  >
    <div id="forgot-password-form">
      <div id="forgot-password-form-lang">
        <button
          mat-stroked-button
          style="
            background-color: rgba(60, 66, 82, 0.05);
            margin-right: 5px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          (click)="toggleTheme()"
        >
          <mat-icon style="margin: 0; padding: 0">{{
            iconsService.getIcon("DARKMODE")
          }}</mat-icon>
        </button>

        <button
          mat-stroked-button
          style="
            background-color: rgba(60, 66, 82, 0.05);
            margin-left: 5px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          (click)="toggleLanguage()"
        >
          {{ buttonLabel }}
        </button>
      </div>

      <div class="logo" fxHide.gt-xs>
        <img src="assets/images/logos/{{ 'APP.LOGO' | translate }}" />
      </div>

      <div class="title">
        {{ "FORGOT_PASSWORD.RECOVER_PASSWORD" | translate }}
      </div>

      <div class="subtitle" style="padding-top: 16px">
        {{ "FORGOT_PASSWORD.SUBTITLE_FORGOTPASSWORD" | translate }}
      </div>

      <form
        name="forgoPasswordForm"
        [formGroup]="forgotPasswordForm"
        novalidate
      >
        <mat-form-field appearance="outline">
          <mat-label>{{ "GENERIC.EMAIL" | translate }}</mat-label>
          <input matInput formControlName="email" />
          <mat-icon matSuffix class="secondary-text">mail</mat-icon>
          <mat-error
            *ngIf="forgotPasswordForm.get('email').hasError('required')"
          >
            {{ "FORGOT_PASSWORD.EMAIL_REQUIRED" | translate }}
          </mat-error>
          <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
            {{ "FORGOT_PASSWORD.EMAIL_INVALID" | translate }}
          </mat-error>
        </mat-form-field>

        <button
          mat-raised-button
          class="submit-button"
          color="accent"
          aria-label="SEND RESET LINK"
          [disabled]="forgotPasswordForm.invalid"
          (click)="onForgotPassword()"
        >
          {{ "FORGOT_PASSWORD.SEND_RESET_LINK" | translate }}
        </button>
      </form>

      <div
        class=""
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="forgotPasswordRequestSuccess"
      >
        <p>{{ "FORGOT_PASSWORD.LINK_SENT_MESSAGE" | translate }}</p>
      </div>

      <div class="login" fxLayout="row" fxLayoutAlign="center center">
        <a
          style="padding: 8px"
          class="link"
          [routerLink]="'/' + appRoutesService.login"
          >{{ "FORGOT_PASSWORD.BACK_LOGIN" | translate }}</a
        >
        |<a
          style="padding: 8px"
          class="link"
          href="https://gestiontraiteur.com/demande-de-ticket/"
          >{{ "FORGOT_PASSWORD.NEED_HELP" | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
