export const locale = {
  lang: "fr-CA",
  data: {
    EMPLOYEES: {
      EMPLOYEES: "Employés",
      CREATE_EMPLOYEE: "CRÉER UN NOUVEL EMPLOYÉ",
      CREATE_AN_EMPLOYEE: "Créer un employé",
      // 'DELETE_EMPLOYEE': 'EFFACER L\'EMPLOYÉ',
      RESEND_INVITE: "Réenvoyer l'invitation",
      EMPLOYEE_NUMBER: "Numéro d'employé",
      DELETE_EMPLOYEE: "Effacer cet employé",
      TYPE: "Type",
      DOB: "Date de naissance",
      HAVE_DRIVER_LICENSE: "Possède un permis de conduire",
      OWN_CAR: "Possède une voiture",
      DRIVER_LICENSE_TYPE: "Type du permis de conduire",
      DATE_HIRE: "Date d'embauche",
      DATE_TERMINATION: "Date de terminaison",
      SOCIAL_NUMBER: " # d'assurance sociale",
      SHOW_HIDE_SOCIAL_NUMBER: "Voir/cacher numéro",
      TRANSIT: "Transit bancaire",
      BANK_NUMBER: "# institution bancaire",
      BANK_ACCOUNT_NUMBER: "# compte bancaire",
      HOURLY_WAGE: "Taux horaire",
      FUNCTION_NAME: "Nom de la fonction",
      JOB_FUNCTION: "Fonctions",
      ADD_JOB_FUNCTION: "Ajouter une fonction",
      REMOVE_JOB_FUNCTION: "Enlever cette fonction",
      DELETE_SUCCESS: "Employé effacé avec Succès",
      DELETE_CONFIRM: "Êtes vous sûr de vouloir effacer cet employer?",
      RESEND_EMAIL: "L’invitation a été ré-envoyé au email: ",
      SEND_EMAIL: "L’invitation a été envoyé au courriel suivant: ",
      FEES: "Des frais peuvent être encourus à l’ajout d’un nouvel utilisateur",
      REMOVE_STATUS: "Êtes-vous sûr(e) de vouloir retirer le status utilisateur de cet employé?",
      INVITE_SUCCESS: "Invitation réenvoyer avec Succès",
      CONFIDENTIAL: "Confidentielle",
      PAYING_CUSTOMER: "Utilisateur payant",
      MODIFY_CUSTOMER: "Modifier cet employé",
      CLOSE_EDIT: "Fermer modification",
      TRANSFORM_INTO_USER: "Transformer en utilisateur",
      REMOVE_AS_USER: "Enlever statut d'utilisateur",
      REMOVE_JOB_FUNCTION_CONFIRM: "Êtes vous sûr de vouloir enlever cette fonction de l'employé?",
      NO_JOB_FUNCTIONS: "Il n’y a pas de fonctions attitrés pour le moment.",
      NO_JOB_FUNCTIONS_HR: "Il n’y a pas de fonctions attitrés pour le moment. Appuyez sur le + vert en mode modification pour en ajouter.",
      EMPLOYEE_ROLE: "Rôle de l'employé",
      GENERATE_ASSIGNMENTS_REPORT: "Générer un rapport d'affectations",
      GENERATE_ASSIGNMENTS_REPORT_BY_JOB: "Relevé affectation employé",
      GENERATE_ASSIGNMENTS_REPORT_BY_DATES: "Horaire affectations employé",
      LETTER_FORMAT: "Format lettre",
      LEGAL_FORMAT: "Format légal",
    },
  },
};
