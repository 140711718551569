<div class="" id="cyclicDatesFullCalendar">
  <div fxLayout="row" fxLayoutAlign="start center">
    <button
      mat-raised-button
      color="primary"
      class="mr-8"
      (click)="onCalendarToday()"
    >
      {{ "GENERIC.TODAY" | translate }}
    </button>
    <button
      mat-stroked-button
      class="grey-100"
      color=""
      style="max-width: 24px"
      (click)="onCalendarPrevious()"
    >
      <mat-icon class="s-20">arrow_back_ios</mat-icon>
    </button>
    <button
      mat-stroked-button
      class="grey-100"
      color=""
      style="max-width: 24px"
      (click)="onCalendarNext()"
    >
      <mat-icon class="s-20">arrow_forward_ios</mat-icon>
    </button>

    <div fxLayout="row" fxLayoutAlign="start end">
      <input
        matInput
        [matDatepicker]="picker"
        min="1900-01-01"
        max="2099-01-01"
        class=""
        (dateChange)="onDatePickerChange($event)"
        style="visibility: hidden; width: 1px"
      />
      <mat-datepicker
        #picker
        startView="multi-year"
        color="primary"
      ></mat-datepicker>
      <button mat-stroked-button class="grey-100" (click)="picker.open()">
        {{ "GENERIC.CHOOSE_DATE" | translate }}
      </button>
    </div>
  </div>

  <div class="calendar-title center" *ngIf="calendarTitle">
    {{ calendarTitle | titlecase }}
  </div>

  <!-- *ngIf="calInit" -->
  <div>
    <div id="calendar"></div>
  </div>
</div>

<!-- <mat-form-field class="example-chip-list">
    <mat-chip-listbox #chipList>
        <mat-chip *ngFor="let date of modelDates" [selectable]="true" [removable]="true" (removed)="remove(date)">
            {{date}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input placeholder="{{tagPlaceholder}}" #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
    </mat-chip-listbox>
</mat-form-field> -->
