import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Gt2PrimengModule } from '../../modules/gt2-primeng.module';
import { SearchResultsComponent } from './search-results.component';
import { AppRoutesName } from '../../const/AppRoutesName';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

const routes = [
  {
    path: AppRoutesName.FR.SEARCH,
    component: SearchResultsComponent,
  },
  {
    path: AppRoutesName.EN.SEARCH,
    component: SearchResultsComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatInputModule,
    MatButtonModule,
    Gt2PrimengModule,
    RouterModule.forChild(routes),
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
  ],
  declarations: [SearchResultsComponent],
  exports: [SearchResultsComponent],
  providers: [],
})
export class SearchResultsModule {}
