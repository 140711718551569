export const locale = {
  lang: "fr-CA",
  data: {
    DASHBOARD: {
      LATEST: "Dernier",
      NO_ITEMS: "Aucun item",
      NO_ORDERS: "Aucune commande pour cette journée",
      NO_PRODUCTS_PZ: "Aucun produit pour cette journée",
      CREATE_ORDER: "Créer une commande",
      CREATE_EVENT: "Créer un événement",
      CREATE_PROPOSAL: "Créer une proposition",
      CREATE_INVOICE: "Créer une facture",
      ROOM_MANAGEMENT: "Gestion des salles",
      DETAILED_CALENDAR: "Calendrier détaillé",
      INCLUDE_CANCEL_ABORT: "Inclure les événements annulés et abandonnés",
      CHOOSE_A_DATE: "Choisir une ou des dates",
      FROM: "DU",
      TO: "AU",
      YESTERDAY: "Hier",
      LAST_7_DAYS: "Dernier 7 jours",
      LAST_30_DAYS: "Dernier 30 jours",
      THIS_WEEK: "Cette semaine",
      NEXT_2_WEEK: "2 prochaines semaines",
      THIS_MONTH: "Présent mois",
      NEXT_MONTH: "Prochain mois",
      LAST_MONTH: "Dernier mois",
      GUEST_COUNT: "Nombre d'invités:",
      EVENTS_TOTAL: "Nombre total d'événements:",
      NO_RECORDS: "Aucun résultat pour ces dates",
      LOADING: "Téléchargement...",
      ALL_DELIVERY_TYPE: "Tous types de livraison",
      ALL_STATUS_TYPE: "Tout les status",
      ALL_ORDER_TYPE: "Tout les status de commandes",
      DELIVERY_ONLY: "Livraison seulement",
      DELIVER_INSTALL: "Livrer et installer",
      DELIVERY_EMPLOYEES: "Avec du personnel",
      DELIVERY_PICKUP: "Cueillette au comptoir",
      EVENT_OPEN: "Ouvert",
      EVENT_IN_ORDER: "En commande",
      EVENT_IN_INVOICE: "En facturation",
      EVENT_IN_PROPOSAL: "En proposition",
      EVENT_CANCEL: "Annulé",
      EVENT_ABORT: "Abandonné",
      EVENT_COMPLETE: "Completé",
      EVENT_PAST_EVENT: "Événement passé",

      ORDER_OPEN: "Commande en préparation",
      ORDER_SEND: "Commande envoyée",
      ORDER_CANCEL: "Commande annulée",
      ORDER_CONFIRM: "Commande confirmée",
      ORDER_TO_COMPLETE: "Commande à compléter",
      ORDER_TO_INVOICE: "Commande à facturer",
      ORDER_COMPLETE: "Commande complétée",

      PROPOSAL_OPEN: "Proposition en préparation",
      PROPOSAL_PROPOSE: "Proposition envoyée",
      PROPOSAL_REFUSE: "Proposition refusée",
      PROPOSAL_EXPIRE: "Proposition expirée",
      PROPOSAL_DISCARD: "Proposition écartée",
      PROPOSAL_ACCEPT: "Proposition acceptée",

      INVOICE_OPEN: "Facture émise",
      INVOICE_SEND: "Facture envoyée",
      INVOICE_PAID: "Facture payée",
      INVOICE_OVER_DUE: "Facture en retard",
      INVOICE_CANCEL: "Facture annulée",

      ALL_ORDERS: "Toutes les commandes",
      ALL_PROPOSALS: "Toutes les propositions",
      ALL_INVOICES: "Toutes les factures",

      RESET_TABLE: "Réinitialiser la table",
      RESET_TABLE_MESSAGE:
        "Réinitialiser la table n'effacera aucune de vos données. Seul les informations relatives à la largeur des colonnes, les filtres, les colonnes affichées, etc, seront réinitialisées à leur valeur par défaut.",

      SLIDER_LABEL: "Valeur > ",
      CHOOSE_COLUMNS: "Choisir des colonnes",
      SELECTED_COLUMNS: "{0} colonnes sélectionnées",
      EXPORT_XLS: "Toutes les données",
      EXPORT_FILTERES_XLS: "Données filtrées",
      TOTAL_SELLING: "Ventes totales",
      INCLUDE_CANCEL_ABORT_EVENTS: "Inclure événements annulés et abandonnés",
    },
    ROOM_MANAGEMENT: {
      TITLE: "Gestion des salles",
      CREATE_TITLE: "Créer un événement",
      ORDER_TYPE: "Type de commande",
      ROOM_NAME: "Nom de la salle",
      PLACE_NAME: "Nom de la place",
      NOTE: "Note: Il n'y a aucun lieu interne",
    },
    DASHBOARD_DETAILED: {
      EVENT_TYPE: "Type d’événement",
      ORDER_NUMBER: "No commande",
      ORDER_CLIENT_NAME: "Nom client commande",
      ENTERPRISE_NAME: "Entreprise du client",
      ENTERPRISE_DELIVERED_TO: "Livré à l’entreprise",
      EVENT_NUMBER: "No d’événement",
      ORDER_NAME: "Nom de la commande",
      PURCHASE_ORDER_NUMBER: "# bon de commande",
      INVOICE_CUSTOMER_NAME: "Invoice customer name",
      INVOICE_NUMBER: "No facture",
      BALANCE: "Balance",
      NET_AMOUNT: "Montant net",
      AMOUNT_WITH_TAXES: "Montant taxes incluses",
      EVENT_STATUS: "Statut de l’événement",
    },
  },
};
