export const locale = {
  lang: "fr-CA",
  data: {
    NAV: {
      APPLICATIONS: "Applications",
      DASHBOARD: {
        TITLE: "Tableau de bord",
      },
      DASHBOARD_DETAILED: {
        TITLE: "Tableau de bord détaillé",
      },
      DASHBOARD_DETAILED_JUL: {
        TITLE: "Tableau de bord JUL",
      },
      PRODUCTS: {
        TITLE: "Produits",
      },
      CLIENTS: {
        TITLE: "Clients et entreprises",
      },
      VENUES: {
        TITLE: "Lieux",
      },
      ROOMS: {
        TITLE: "Salles",
      },
      ORDERS: {
        TITLE: "Commandes",
      },
      REPORTS: {
        TITLE: "Rapports",
      },
      SETTINGS: {
        TITLE: "Préférences",
      },
      SUPPORT: {
        TITLE: "Support",
      },
      CONTACT: {
        TITLE: "Demande de ticket",
      },
      KB: {
        TITLE: "Base de connaissance",
      },
      FAQ: {
        TITLE: "Foire aux Questions",
      },
      PROFILE: {
        TITLE: "Profil",
      },
      ORGANIZATION: {
        TITLE: "Organization",
      },
      EMPLOYEES: {
        TITLE: "Employés",
      },

      TODO: {
        TITLE: "Tâches",
      },
      TEST: {
        TITLE: "Page Test (dev)",
      },
      ROOM_MANAGEMENT: {
        TITLE: "Gestion des salles",
      },

      ADMIN: "Admin",
      GOD: "God",
      GOD_LOCAL_STORAGE: {
        TITLE: "Local storage",
      },
      GOD_REPORTS: {
        TITLE: "Rapports",
      },
      GOD_EMAILS: {
        TITLE: "Emails",
      },
      GOD_IMPERSONATION: {
        TITLE: "Impersonation",
      },
      DEV: "Dev",
      MODULES: "Modules",
      MODULE_1: {
        TITLE: "Module 1",
      },
      MODULE_2: {
        TITLE: "Module 2",
      },
      FUNCTIONS: {
        TITLE: "Fonctions",
      },
      MATERIALS: {
        TITLE: "Matériels",
      },
      MANAGEMENT: {
        TITLE: "Gestion",
      },
      DOCUMENTATION: {
        TITLE: "Base de connaissance & FAQ",
      },
      RELEASE_NOTES: {
        TITLE: "Note de mise à jour",
      },
      SHOPS: {
        TITLE: "Commande en ligne",
      },
      CONGRESSES: {
        TITLE: "Congrès",
      },
      UPSALE: {
        TITLE: "Vente incitative",
      },
    },
  },
};
