import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numberNotInfinityPipe",
})
export class NumberNotInfinityPipe implements PipeTransform {
  constructor() {}

  // if number is NaN or Infinity or just not a number, the pipe will return 0
  transform(num: number): number {
    if (!Number.isFinite(num) || num === Number.NaN || typeof num !== "number") {
      return 0;
    }
    //
    return num;
  }
}
