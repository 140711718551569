<div class="page-layout blank p-24" fusePerfectScrollbar>
  <div *ngxPermissionsOnly="['GOD']">
    <span class="mat-headline">Local storage</span>
    <mat-list>
      <mat-list-item>
        <p-button
          label="DELETE"
          (onClick)="onDeleteLanguageLocalStorage()"
          styleClass="ui-button-danger"
        ></p-button>
        <span>Delete language preference in local storage</span>
      </mat-list-item>
    </mat-list>
  </div>
</div>

12
