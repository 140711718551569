<div
  *ngIf="error && error.error && error.error.title"
  class="mat-title">
  {{ error.error.title }}
</div>
<!-- <div *ngIf="error && error.title" class="mat-title">{{ error.title }}</div> -->
<div
  *ngIf="error && (!error.error || !error.error.title)"
  class="mat-title">
  E1 {{ "GENERIC.ERROR_DIALOG_TITLE" | translate }}
</div>

<div *ngIf="useError456Template">
  <div
    *ngIf="error && error.error && error.error.message"
    class="">
    {{ error.error.message }}
  </div>

  <div
    *ngIf="!error || !error.error || !error.error.message"
    class="">
    {{ "GENERIC.ERRORS.GENERIC_ERROR" | translate }}
  </div>

  <div *ngIf="error && errorsData">
    <ul>
      <div *ngFor="let item of errorsData">
        <div *ngFor="let errorString of item.data">
          <li
            mat-dialog-content
            class="mb-4 mr-36 error-dialog">
            {{ item.key }} - {{ errorString }}
          </li>
        </div>
      </div>
    </ul>
  </div>
</div>
<div *ngIf="!useError456Template">
  <div
    *ngIf="error && error.error && error.error.message"
    class="">
    {{ error.error.message }}
  </div>

  <div
    *ngIf="!error || !error.error || !error.error.message"
    class="">
    {{ "GENERIC.ERRORS.GENERIC_ERROR" | translate }}
  </div>

  <div *ngIf="error && errorsData">
    <ul>
      <div *ngFor="let item of errorsData">
        <div *ngFor="let errorString of item.data">
          <li
            mat-dialog-content
            class="mb-4 mr-36 error-dialog">
            {{ errorString }} [{{ item.key }}]
          </li>
        </div>
      </div>
    </ul>
  </div>
</div>

<!-- <div *ngIf="useError456Template">
    <div *ngIf="error && error.error && error.error.message" class="">
        E6 {{ error.error.message }}
    </div>

    <div *ngIf="error && errorsData">
        <ul>
            <div *ngFor="let item of errorsData">
                <div *ngFor="let errorString of item.data">
                    <li
                        *ngxPermissionsOnly="['GOD']"
                        mat-dialog-content
                        class="mb-4 mr-36 error-dialog"
                    >
                        E7 {{ errorString }} - [God: {{ item.key }}]
                    </li>
                    <li
                        *ngxPermissionsExcept="['GOD']"
                        mat-dialog-content
                        class="mb-4 mr-36 error-dialog"
                    >
                        E8 {{ errorString }}
                    </li>
                </div>
            </div>
        </ul>
    </div>
</div> -->

<!--IF ERROR OBJECT IS PASSED, FOR PROD< WE DONT NECESSARILY WANT TO DISPLAY THE WHOLE STUFF-->
<!-- <div *ngIf="error">
    <div mat-dialog-content *ngIf="error.status" class="mb-4 error-dialog">Status Code: {{error.status}}</div>
    <div mat-dialog-content *ngIf="error.url" class="mb-4 error-dialog">URL: {{error.url}}</div>
    <div mat-dialog-content *ngIf="error.error && error.error.message" class="mb-4 error-dialog" style="word-wrap: break-word;">Message:
        {{error.error.message}}
    </div>
    <div mat-dialog-content *ngIf="error.error && error.error.exception" class="mb-4 error-dialog">Exception:
        {{error.error['exception']}}
    </div>
    <div mat-dialog-content
         *ngIf="error.error && error.error.file"
         style="word-wrap: break-word;"
         class="mb-4 error-dialog">File: {{error.error.file}}
    </div>
    <div mat-dialog-content  *ngIf="error.error && error.error.line" class="mb-4 error-dialog">Line:
        {{error.error.line}}
    </div>
    <div mat-dialog-content *ngIf="error.error && error.error.trace" class="mb-4 error-dialog">
        <div>Trace:</div>
        <div style="height: 12em; word-wrap: break-word; overflow-y: scroll; border: 1px solid #ebebeb;">{{error.error.trace}}</div>
    </div>
</div> -->

<div
  mat-dialog-actions
  class="pt-12"
  fxLayout="row"
  fxLayoutAlign="center center">
  <button
    mat-raised-button
    color="accent"
    class=""
    (click)="dialogRef.close(true)">
    {{ "GENERIC.OK" | translate }}
  </button>
</div>
