import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CrudOrganizationService } from '../../../gt2/content/crud/crud-organization/crud-organization.service';
import { GT2CalendarUtils } from '../../../gt2/utils/GT2CalendarUtils';
import { NGXLogger } from 'ngx-logger';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { EmployeeModelData } from '../../../gt2/api/models/CrudEmployeeModel';
import { CrudEmployeeService } from '../../../gt2/content/crud/crud-employee/crud-employee.service';
import { ItemUrlFinder } from '../../../gt2/utils/item-url-finder';
import { LocalizationConfig } from '../../../localization/LocalizationConfig';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { environment } from '../../../../environments/environment';
import {
  AngularFireDatabase,
  AngularFireList,
} from '@angular/fire/compat/database';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CalendarModule } from 'angular-calendar';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';

@Component({
  selector: 'quick-panel',
  templateUrl: './quick-panel.component.html',
  styleUrls: ['./quick-panel.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    TranslateModule,
    MatDatepickerModule,
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    CalendarModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class QuickPanelComponent implements OnInit {
  static LOCAL_STORAGE_SLIDER_ID = 'notificationSliderStatus';
  static ACTION_GOTOURL = 'GOTOURL';
  static ACTION_GOTOURL_INTERNAL = 'INTERNALURL';
  static TOAST_TYPE_SUCCESS = 'SUCCESS';
  static TOAST_TYPE_INFO = 'INFO';
  static TOAST_TYPE_WARNING = 'WARNING';
  static TOAST_TYPE_ERROR = 'ERROR';

  // TODO: TO DELETE
  // date: Date;
  // settings: any;
  // events = [];
  items?: Observable<any[]>;
  itemsRef: AngularFireList<any> | undefined;
  notificationsShowCount = 10;

  localeID: string;
  locale: any = null;
  locales: any = GT2CalendarUtils.LOCALES;

  date: Date;
  selectedDate: any;
  events?: any[];
  notes?: any[];
  settings: any;

  /**
   * Constructor
   */
  constructor(
    private employeeService: CrudEmployeeService,
    private orgService: CrudOrganizationService,
    private route: Router,
    private db: AngularFireDatabase,
    private translate: TranslateService,
    private toastr: ToastrService,
    private adapter: DateAdapter<any>,
    private translationLoader: FuseTranslationLoaderService,
    private logger: NGXLogger,
  ) {
    this.translationLoader.loadTranslations(english, french);

    // Set the defaults
    this.date = new Date();
    this.settings = {
      notify: true,
      cloud: false,
      retro: true,
    };

    //
    const sliderStatus: string =
      localStorage.getItem(QuickPanelComponent.LOCAL_STORAGE_SLIDER_ID) || '';
    if (sliderStatus !== null) {
      this.settings.notify = sliderStatus === 'true';
    }

    this.localeID = LocalizationConfig.getCurrentLanguage().locale;
    // this.logger.info("FuseQuickPanelComponent ->>> this.localeID: " + this.localeID);

    this.locale = this.locales[this.localeID];

    this.adapter.setLocale(this.translate.currentLang);

    // this.logger.info("FuseQuickPanelComponent ->>> this.locale: " + JSON.stringify(this.locale));
    this.translate.onLangChange.subscribe((lang) => {
      // this.logger.info("FuseQuickPanelComponent ->>> onLangChange - lang: " + JSON.stringify(lang));
      this.locale = this.locales[lang.lang];
      this.localeID = lang.lang;
    });

    this.loadData();
  }

  ngOnInit(): void {
    // this.logger.info("QuickPanelComponent.ngOnInit()");
  }

  public loadData(): void {
    // this.logger.info("QuickPanelComponent.loadData()");
    // COMBINED LATEST INSTEAD OF FORKJOIN
    const combined = combineLatest([
      this.orgService.loadOrganization(),
      this.employeeService.getMe(),
    ]);

    combined.subscribe((latestValues) => {
      // this.logger.info("QuickPanelComponent.ngOnInit().combineLatest() -> SUCCESS ALL: " + JSON.stringify(latestValues));
      // this.logger.info("QuickPanelComponent.ngOnInit().combineLatest() -> SUCCESS org: " + JSON.stringify(latestValues[0].data));
      // this.logger.info("QuickPanelComponent.ngOnInit().combineLatest() -> SUCCESS employee: " + JSON.stringify(latestValues[1]));
      const employee: any = latestValues[1];
      const org: any = latestValues[0].data;
      this.connectFirebaseNode(employee, org);
      // this.connectFirebaseProcessesNode(employee, org);
    });
  }

  public onNotificationClick(item: Notification): void {
    if (item && item.dataModel) {
      this.route.navigateByUrl(ItemUrlFinder.getItemURL(item.dataModel));
    }
  }

  public onNotificationSliderChange(event: any): void {
    // this.logger.info("FuseQuickPanelComponent.onNotificationSliderChange() -> event: " + event.checked);
    localStorage.setItem(
      QuickPanelComponent.LOCAL_STORAGE_SLIDER_ID,
      event.checked,
    );
  }

  public connectFirebaseNode(employee: EmployeeModelData, org: any): void {
    // this.logger.info("FuseQuickPanelComponent.connectFirebaseNode()");
    if (!employee.user) {
      //this.logger.info("FuseQuickPanelComponent.connectFirebaseNode() -> ERROR: Employee has no user data! ");
      return;
    }

    // this.logger.info('employee.user.uuid: ' + employee.user.uuid);
    // this.logger.info("FuseQuickPanelComponent.connectFirebaseNode() -> PUSH ID???? createPushId():  " + this.db.createPushId());
    const notificationsURL: string =
      environment.firebaseEnvironment +
      '/' +
      org.slug +
      '/' +
      employee.user.uuid +
      '/notifications';
    this.itemsRef = this.db.list(notificationsURL);
    this.items = this.itemsRef.snapshotChanges().pipe(
      map((changes) => {
        // this.logger.info("FIREBASE!!!! -------> " + JSON.stringify(changes));
        // @ts-ignore
        const list = changes
          .map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))
          .reverse()
          .splice(0, this.notificationsShowCount);
        this.validateForToast(list);
        return list;
      }),
    );
  }

  public onDeleteNotification(item: any): void {
    // this.logger.info('FuseQuickPanelComponent.onDeleteNotification() -> item: ' + JSON.stringify(item));
    // this.logger.info('FuseQuickPanelComponent.onDeleteNotification() -> item KEY: ' + item.key);

    this.itemsRef?.remove(item.key);
  }

  public setNotificationAsToasted(item: any): void {
    // this.logger.info("FuseQuickPanelComponent.setToasted() -> item KEY: " + item.key);
    this.itemsRef?.update(item.key, { toasted: true });
  }

  public showToast(item: Notification): void {
    // this.logger.info("FuseQuickPanelComponent.showToast() -> list: " + JSON.stringify(item));
    let toast: ActiveToast<any>;
    // tslint:disable-next-line:ban-types
    const toastConfig: Object = {
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
    };

    if (item.type === QuickPanelComponent.TOAST_TYPE_SUCCESS) {
      toast = this.toastr.success(item.message, item.title, toastConfig);
    } else if (item.type === QuickPanelComponent.TOAST_TYPE_INFO) {
      toast = this.toastr.info(item.message, item.title, toastConfig);
    } else if (item.type === QuickPanelComponent.TOAST_TYPE_WARNING) {
      toast = this.toastr.warning(item.message, item.title, toastConfig);
    } else if (item.type === QuickPanelComponent.TOAST_TYPE_ERROR) {
      toast = this.toastr.error(item.message, item.title, toastConfig);
    } else {
      // this.logger.info("FuseQuickPanelComponent.showToast() -> final else, showing Toast! ");
      toast = this.toastr.info(item.message, item.title, toastConfig);
    }

    toast.onTap.subscribe(() => {
      // this.logger.info("FuseQuickPanelComponent.showToast()");
      this.onNotificationClick(item);
    });
  }

  public validateForToast(list: any): void {
    // this.logger.info("FuseQuickPanelComponent.validateForToast() -> this.settings.notify: " + this.settings.notify);
    // this.logger.info("FuseQuickPanelComponent.validateForToast() -> list: " + list);
    if (!this.settings.notify) {
      return;
    }

    // this.logger.info("FuseQuickPanelComponent.validateForToast() -> list: " + list);
    let i = 0;
    const length: number = list.length;
    let item: Notification;
    let date: Date;
    const now: Date = new Date();
    for (i; i < length; ++i) {
      item = list[i];
      // this.logger.info("FuseQuickPanelComponent.validateForToast() " + i + " name" + item.message + " toasted: " + item.toasted);
      if (!item.toasted) {
        date = new Date(item.expired);
        // date = new Date(item.expired);
        // this.logger.info("FuseQuickPanelComponent.validateForToast() date: " + date.getTime() + " now: " + now.getTime() + " now < date: " + (now.getTime() < date.getTime()));
        if (now.getTime() < date.getTime()) {
          this.showToast(item);
        }
        this.setNotificationAsToasted(item);
      }
    }
  }
}

export interface Notification {
  key: string;
  type: string;
  action: NotificationAction;
  expired: string;
  message: string;
  title: string;
  toasted: boolean;
  dataModel?: any;
  object?: any;
}

export interface NotificationAction {
  type: string;
  value: string;
}

// export interface NotificationExpired
// {
//     date: string;
//     timezone: string;
//     timezone_type: number;
// }
