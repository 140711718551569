export const locale = {
  lang: "en-CA",
  data: {
    COMBO: {
      TITLE: "Edit combo ",
      TITLE_CREATE: "Create combo",
      LEFT_LIST_LABEL: "Drag & drop or use the + button to add product",
      RIGHT_LIST_LABEL: "Rearrange or use the - button to remove product",
    },
  },
};
