export const locale = {
  lang: "en-CA",
  data: {
    DRINKS_VALIDATION: {
      ERROR_MESSAGE: "One or more drinks have not been confirmed, please confirm them by checking the confirmed box",
      DRINKS_NAME: "Product name",
      DRINKS_INITIAL_QTY: "Original Qty",
      DRINKS_REAL_QTY: "Actual Qty",
      DRINKS_CONFIRMED: "Confirmed",
      CANCEL: "Cancel",
    },
  },
};
