<h1 matDialogTitle class="h1-title">{{ "ATTACH_CLIENT.TITLE" | translate }}</h1>

<div
  fxLayout="column"
  fxLayoutAlign="center start"
  [formGroup]="form"
  class="mt-8 create-client-width"
>
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center start">
    <mat-spinner [diameter]="40" class="mt-20"></mat-spinner>
  </div>
</div>

<div
  mat-dialog-actions
  class="mt-16 gt2-buttons-theme"
  fxLayout="row"
  fxLayoutAlign="center start"
>
  <button
    mat-raised-button
    color="accent"
    [disabled]="!form.valid || loading"
    (click)="onAttachClient()"
  >
    {{ "GENERIC.SAVE" | translate }}
  </button>

  <button
    mat-raised-button
    (click)="dialogRef.close(false)"
    color="primary"
    class=""
  >
    {{ "GENERIC.CANCEL" | translate }}
  </button>
</div>
