export const locale = {
  lang: "fr-CA",
  data: {
    ORG: {
      GRAPHICS: "Graphiques",
      LOGO: "Logo",
      UPLOAD_LOGO: "Téléverser Logo",
      UPLOAD_LOGO_SUCCESS: "Logo téléversé avec succès",
      UPLOAD_LOGO_DIMENSION: "largeur et hauteur minimale: 200px",
      UPLOAD_HEADER: "Téléverser Header",
      UPLOAD_HEADER_SUCCESS: "Header téléversé avec succès",
      UPLOAD_HEADER_DIMENSION: "largeur minimale: 600px, hauteur minimale: 120px",
      SELECT_LOCALES: "Choissiser une ou plusieurs langue pour votre organisation dans la liste ci-dessous.",
      SELECT_DEFAULT_LOCALES: "Choisir la langue par défaut pour cette organisation",
      CURRENT_LOGO: "Logo",
      CURRENT_REPORT_HEADER: "En-tête des rapports",
      CURRENT_REPORT_ASSETS: "Éléments graphique des rapports",
      NEXT_PAYMENT_DATE: "Prochain paiement",
      OPEN_SINCE: "Ouvert depuis",
      TOTAL_USER: "Nombre d'utilisateurs payants",
      CURRENT_COST: "Coût mensuel actuel",
      RESPONSIBLE_USER: "Utilisateur responsable",
      ORG_LIST: "Liste des traiteurs",
      TRIAL: "Trial",
      SUPPORT_EMAIL: "Courriel du support",
      SUPPORT_TEL: "Téléphone du support",
      SIGNATURE: "Signature",
    },
  },
};
