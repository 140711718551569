<mat-toolbar>
  <div
    style="
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    "
  >
    <div
      style="
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: start;
      "
    >
      <a
        *ngIf="!isSmallScreen"
        href="{{ facebookURL }}"
        target="_blank"
        class="btn btn-block btn-social-icon btn-facebook gt2-social mr-8"
      >
        <!-- <span class="fa fa-facebook"></span> -->
        <mat-icon
          fontSet="fab"
          fontIcon="fa-facebook"
          class="icon-mat-rs"
        ></mat-icon>
      </a>

      <a
        *ngIf="!isSmallScreen"
        href="{{ linkedInURL }}"
        target="_blank"
        class="btn btn-block btn-social-icon btn-facebook gt2-social mr-8"
      >
        <!-- <span class="fa fa-linkedin-square"></span> -->
        <mat-icon
          fontSet="fab"
          fontIcon="fa-linkedin"
          class="icon-mat-rs"
        ></mat-icon>
      </a>

      <a
        *ngIf="!isSmallScreen"
        href="{{ instagramInURL }}"
        target="_blank"
        class="btn btn-block btn-social-icon btn-facebook gt2-social mr-8"
      >
        <!-- <span class="fa fa-linkedin-square"></span> -->
        <mat-icon
          fontSet="fab"
          fontIcon="fa-instagram"
          class="icon-mat-rs"
        ></mat-icon>
      </a>

      <div class="mat-caption">
        <p class="copyright">
          © {{ currentYear }} {{ "APP.TITLE" | translate }}
        </p>
      </div>
      <!--TODO: A GARDER COMMENTER. NE PAS EFFACER-->
      <!--<div>-->
      <!--<a href="http://twitter.com" target="_blank"-->
      <!--class="btn btn-block btn-social-icon btn-twitter gt2-social">-->
      <!--<span class="fa fa-twitter"></span>-->
      <!--</a>-->

      <!--<a href="http://google.com" target="_blank"-->
      <!--class="btn btn-block btn-social-icon btn-google gt2-social">-->
      <!--<span class="fa fa-google"></span>-->
      <!--</a>-->
      <!--</div>-->
    </div>

    <div
      *ngIf="!isSmallScreen"
      style="
        display: flex;
        flex: 3;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      "
    >
      <!--TODO: A GARDER COMMENTER. NE PAS EFFACER-->
      <!--<a mat-button (click)="onLiveChat()">-->
      <!--<mat-icon class="s-16 mr-sm-4">chat</mat-icon>-->
      <!--Live chat-->
      <!--</a>-->
      <!--<mat-icon class="s-16 mr-sm-4">chat</mat-icon>-->
      <!--Live chat-->
      <div style="display: flex; flex-direction: row">
        <div
          *ngxPermissionsOnly="['GOD']"
          class="mr-12"
          style="
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
          "
        >
          <p class="copyright">Timezone: {{ this.timezone }}</p>
        </div>
        <div
          *ngxPermissionsOnly="['GOD']"
          class="mr-12"
          style="
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
          "
        >
          <div
            *ngIf="api.isLocal"
            fxLayout="row"
            [matMenuTriggerFor]="switcherReceipt"
            style="cursor: pointer; font-size: 13px"
          >
            <span class="copyright">API: &nbsp;</span>
            <div
              [ngStyle]="{
                color: api.localAPITarget === 'DEV' ? 'tomato' : 'chartreuse',
              }"
            >
              <span class="copyright">{{ api.localAPITarget }}</span>
            </div>
            <!-- style="color: crimson" -->
            <!-- tooltip="Changer pour {{ (api.localAPITarget === 'DEV')? 'LOCAL' : 'DEV' }}" -->
          </div>

          <mat-menu #switcherReceipt="matMenu">
            <button
              *ngIf="api.isLocal"
              mat-menu-item
              (click)="onSwitchLocalAPI('LOCAL')"
            >
              Local
            </button>
            <button mat-menu-item (click)="onSwitchLocalAPI('DEV')">Dev</button>
            <button mat-menu-item (click)="onSwitchLocalAPI('DEV2')">
              Dev 2
            </button>
          </mat-menu>
        </div>
      </div>
      <div style="display: flex; flex-direction: row">
        <div
          *ngxPermissionsOnly="['GOD']"
          class="mr-12"
          style="
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
          "
          (click)="onShowFirebaseStatus()"
        >
          <mat-icon *ngIf="firebaseConnected" style="color: #009688"
            >brightness_1</mat-icon
          >
          <mat-icon *ngIf="!firebaseConnected" color="warn">block</mat-icon>
        </div>
        <div>
          <button
            mat-icon-button
            *ngxPermissionsOnly="['GOD']"
            (click)="onOpenAPICallBenching()"
            class="copyright s-16 mr-12"
          >
            <mat-icon class="icon-mat">access_time</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div
      style="
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: end;
      "
    >
      <div *ngIf="setting" style="font-size: 11px">
        <p class="copyright">
          Version: App {{ environment.appVersion }} - Api {{ setting.version }}
        </p>
      </div>
    </div>
  </div>
</mat-toolbar>

<ng-template #firebaseDialogTemplate let-item>
  <div fxLayout="row" fxLayoutAlign="start center" class="gt2-order-theme">
    <h1>Firebase: {{ firebaseConnected ? "connecté" : "déconnecté" }}</h1>
    <mat-icon
      style="margin-left: 5px; margin-top: 3px; color: #009688"
      *ngIf="firebaseConnected"
      >brightness_1</mat-icon
    >
    <mat-icon
      style="margin-left: 5px; margin-top: 3px"
      *ngIf="!firebaseConnected"
      color="warn"
      >block</mat-icon
    >
  </div>

  <div class="firebase-item">
    <b>Environment:</b> &nbsp;{{ environment.firebaseEnvironment }}
  </div>
  <div class="firebase-item">
    <b>apiKey:</b> &nbsp;{{ environment.firebase.apiKey }}
  </div>
  <div class="firebase-item">
    <b>authDomain:</b> &nbsp;{{ environment.firebase.authDomain }}
  </div>
  <div class="firebase-item">
    <b>databaseURL:</b> &nbsp;{{ environment.firebase.databaseURL }}
  </div>
  <div class="firebase-item">
    <b>projectId:</b> &nbsp;{{ environment.firebase.projectId }}
  </div>
  <div class="firebase-item">
    <b>storageBucket:</b> &nbsp;{{ environment.firebase.storageBucket }}
  </div>
  <div class="firebase-item">
    <b>messagingSenderId:</b> &nbsp;{{ environment.firebase.messagingSenderId }}
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(false)"
      style="margin-top: 18px"
      class=""
    >
      {{ "GENERIC.OK" | translate }}
    </button>
  </div>
</ng-template>
