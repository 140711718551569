import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GT2PageAbstract } from '../../../content/abstract/GT2PageAbstract';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { ProductsService } from '../../../api/products.service';
import { Settings } from '../../../settings/settings';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { ItemUrlFinder } from '../../../utils/item-url-finder';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    FlexLayoutModule,
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class CreateProductComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  product: any;
  loading: boolean = false;
  autoRedirect: boolean = false;
  uuid_order?: string;

  constructor(
    public override dialogRef: MatDialogRef<CreateProductComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private productsService: ProductsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.minLength(Settings.inputMinChar),
          Validators.maxLength(Settings.inputMaxCharMid),
          Validators.required,
        ],
      ],
    });

    this.product = {
      name: null,
      invoice_category: 'FOOD', // default value
    };

    if (data && data.autoRedirect !== null) {
      this.autoRedirect = data.autoRedirect;
    }

    if (data && data.uuid_order !== null) {
      this.uuid_order = data.uuid_order;
      this.product.uuid_order = this.uuid_order;
    }

    //this.logger.info("CreateProductComponent.onCreateProduct() -> this.uuid_order: " + this.uuid_order);
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onCreateProduct(): void {
    this.loading = true;
    //this.logger.info("CreateProductComponent.onCreateProduct() -> invoice_category: " + this.product.invoice_category);
    this.productsService.createProduct(this.product).subscribe(
      (response) => {
        // this.logger.log("CreateProductComponent.onCreateProduct() -> SUCCESS: " + JSON.stringify(response));
        this.toastr.success(
          '',
          this.translate.instant('GENERIC.API_CALL_SUCCESS.CREATE_PRODUCT'),
        );
        this.dialogRef.close(response.data);
        this.loading = false;
        if (this.autoRedirect) {
          this.router.navigateByUrl(ItemUrlFinder.getItemURL(response.data));
        }
      },
      (error) => {
        this.loading = false;
        this.logger.error(
          'CreateProductComponent.onCreateProduct() -> ERROR: ' +
            JSON.stringify(error),
        );
        // TODO: should have a toastr ????
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }
}
