import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  ChangeDetectorRef,
  Injector,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { CrudOrganizationService } from '../content/crud/crud-organization/crud-organization.service';

@Pipe({
  name: 'translategt',
  pure: true,
})
export class GT2TranslatePipe extends TranslatePipe implements PipeTransform {
  //
  organizationService: CrudOrganizationService;

  constructor(
    private cdRef: ChangeDetectorRef,
    private translateLib: TranslateService,
    private injector: Injector
  ) {
    super(translateLib, cdRef);
    this.organizationService = injector.get(CrudOrganizationService);
  }

  override ngOnDestroy() {
    // this.asyncPipe.ngOnDestroy();
  }

  override transform(key: string): any {
    // if(!this.organizationService.organization || !this.organizationService.organization)
    // return super.transform(key);
    //
    const nKey =
      'CLIENT_OVERRIDE.' +
      this.organizationService.organization?.data.slug +
      '.' +
      key;
    const nString = super.transform(nKey);

    if (nString && nString !== nKey && nString !== '') {
      return nString;
    }
    // return this.translateLib.instant(key);
    return super.transform(key);
  }
}
