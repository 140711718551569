import { GT2CalendarUtils } from "../utils/GT2CalendarUtils";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { IGetDataService } from "../components/gt2-select/gt2-select.component";
import { ApiRoutes } from "./ApiRoutes";
import { ProjectModel } from "./congresses.service";
import { Gt2ApiService } from "./gt2-api.service";

@Injectable()
export class ProjectsService implements IGetDataService {
  constructor(private http: HttpClient, private logger: NGXLogger, private api: Gt2ApiService) {}

  public getProjectsList() {
    // this.logger.info("ProjectsService.getProjectsList()");
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PROJECTS_LIST));
  }

  public getData(): Observable<any> {
    // this.logger.info("ProjectsService.getData()");
    return this.getProjectsList();
  }

  public getProject(projectUUID: string) {
    // this.logger.info("ProjectsService.getEvent() -> projectUUID: " + projectUUID );
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PROJECT) + projectUUID);
  }

  public createProject(congressUUID: string, project: ProjectModel) {
    // project.congress_id = congressUUID;
    // this.logger.info("ProjectsService.getEvent() -> projectUUID: " + projectUUID );
    if (project.date_start) {
      project.date_start = GT2CalendarUtils.formatDateForAPI(project.date_start);
    }

    if (project.date_end) {
      project.date_end = GT2CalendarUtils.formatDateForAPI(project.date_end);
    }
    delete project.uuid;
    delete project.slug;
    delete project.project_number;
    delete project.canceled_at;
    //
    return this.http.post<any>(this.api.createUrl(ApiRoutes.PROJECTS_CONGRESS) + congressUUID, project);
  }

  public updateProject(project: any) {
    if (project.date_start) {
      project.date_start = GT2CalendarUtils.formatDateForAPI(project.date_start);
    }

    if (project.date_end) {
      project.date_end = GT2CalendarUtils.formatDateForAPI(project.date_end);
    }
    //
    return this.http.patch<any>(this.api.createUrl(ApiRoutes.PROJECT) + project.uuid, project);
  }

  public getAllProjectEventsWithPagination(projectUUID: string, sort: string, direction: string, page: number, pageSize: number): Promise<any> {
    //this.logger.info("ProjectsService.getAllProjectEventsWithPagination() -> uuid: " + projectUUID);
    // /v1/projects / { project_uuid } / events;
    const routeURL: string = this.api.createUrl(ApiRoutes.CONGRES_PROJECTS.replace("{{UUID}}", projectUUID));
    //
    let requestUrl: string;
    if (!sort || direction === "") {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}`;
    } else {
      requestUrl = `${routeURL}?page=${page + 1}&limit=${pageSize}&orderBy=${sort}&sortedBy=${direction}`;
      // requestUrl = `${routeURL}?orderBy=${sort}&sortedBy=${direction}&page=${
      //     page + 1
      // }&limit=${pageSize}`;
    }

    return new Promise((resolve, reject) => {
      this.http.get(requestUrl).subscribe((response: any) => {
        // this.logger.info("CongressesService.getProductsWithPagination() -> this.employees: " + JSON.stringify(response.data));
        resolve(response);
      }, reject);
    });
    // this.logger.info("ProjectsService.getEvent() -> projectUUID: " + projectUUID );
    // return this.http.get<any>(
    //     this.api.createUrl(ApiRoutes.PROJECT) + projectUUID
    // );
  }

  public createEventOrder(event: any, projectUUID: string): any {
    return this.http.post<any>(this.api.createUrl(ApiRoutes.PROJECT_EVENTS_ORDER.replace("{{UUID}}", projectUUID)), event);
  }

  public createEventProposal(event: any, projectUUID: string): any {
    return this.http.post<any>(this.api.createUrl(ApiRoutes.PROJECT_EVENTS_PROPOSAL.replace("{{UUID}}", projectUUID)), event);
  }

  public cancelProject(projectUID: string) {
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PROJECT_CANCEL.replace("{{UUID}}", projectUID)));
  }

  public addClient(clientUUID: any, projectUUID: string, section: string): any {
    // this.logger.info("OrdersService.addClient() -> eventUUID: " + eventUUID );
    // this.logger.info("OrdersService.addClient() -> client: " + JSON.stringify(client) );
    return this.http.get<any>(this.api.createUrl(ApiRoutes.PROJECT) + projectUUID + "/client/" + section + "/" + clientUUID);
  }
}
