export const locale = {
  lang: "en-CA",
  data: {
    SERVICE_CHARGE: {
      TITLE: "New service charge",
      EDIT_TITLE: "Edit service charge",
      SELECT_SERVICE_CHARGE: "Select a service charge",
      SELECT_SERVICE_CHARGE_MULTIPLIER: "Select a multiplier",
      MAJORATION_FEE: "Majoration fee",
    },
  },
};
