import { Subscription, fromEvent } from 'rxjs';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GT2PageAbstract } from '../../../../../content/abstract/GT2PageAbstract';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '../../../../../../../@fuse/services/translation-loader.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { EnterprisesService } from '../../../../../api/enterprises.service';
import { ClientsService } from '../../../../../api/clients.service';
import { EventsService } from '../../../../../api/events.service';
import { VenuesPlaceService } from '../../../../../api/venues-place.service';
import { CreatePlaceComponent } from '../../../../../components/dialogs/create-place/create-place.component';
import { CreateRoomComponent } from '../../../../../components/dialogs/create-room/create-room.component';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import {
  CdkScrollableModule,
  CdkVirtualScrollViewport,
  ScrollingModule,
} from '@angular/cdk/scrolling';
import * as _ from 'lodash';
import { ModulesService } from '../../../../../services/modules.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-select-place-room',
  templateUrl: './select-place-room.component.html',
  styleUrls: ['./select-place-room.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    FlexLayoutModule,
    MatCheckboxModule,
    TranslateModule,
    ScrollingModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
})
export class SelectPlaceRoomComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  places?: any[];
  allPlaces?: any[];
  eventUUID!: string;
  filterValue: string = '';
  selectedRoom: any;
  selectedPlace: any;
  isLoading: boolean = false;
  createDialogRef: any;
  @ViewChild(CdkVirtualScrollViewport)
  virtualScroll?: CdkVirtualScrollViewport;
  @ViewChild('filter') filter?: ElementRef;
  fromEventSubscription?: Subscription;
  skipVenueSearch: boolean = false;

  constructor(
    public override dialogRef: MatDialogRef<SelectPlaceRoomComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public translate: TranslateService,
    private logger: NGXLogger,
    public dialog: MatDialog,
    private clientsService: ClientsService,
    private enterprisesService: EnterprisesService,
    private eventsService: EventsService,
    private venuesPlaceService: VenuesPlaceService,
    public modulesService: ModulesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    if (data.eventUUID) {
      this.eventUUID = data.eventUUID;
    }

    if (data.skipVenueSearch) {
      this.skipVenueSearch = data.skipVenueSearch;
    }
    this.isLoading = true;
    setTimeout(() => {
      this.loadData('');
    }, 200);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.fromEventSubscription = fromEvent(this.filter?.nativeElement, 'keyup')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          if (this.filterValue !== '') {
            let placesFiltered = []; // UI List bind to this array
            let search_value = this.filterValue.toLowerCase();
            this.loadData(search_value);
            // Auto-scroll fix
            this.onFilter();
          } else {
            //this.resetPlaces();
            this.loadData('');
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {}

  public trackByUUID(index: any, item: any) {
    return item.uuid;
  }

  public onFilter(): void {
    setTimeout(() => {
      this.virtualScroll?.scrollToIndex(0);
    }, 200);
  }

  public resetPlaces(): void {
    this.places = _.clone(this.allPlaces);
  }

  public loadData(keyword: string | any): void | any {
    //this.logger.info("SelectPlaceRoomComponent.loadData()");
    this.isLoading = true;
    this.venuesPlaceService.getPlacesRoomsList(keyword).subscribe(
      (response) => {
        // //this.logger.info("SelectPlaceRoomComponent.constructor() -> SUCCESS: " + JSON.stringify(response));
        this.allPlaces = response.data;
        this.resetPlaces();
        this.isLoading = false;
      },
      (error) => {
        this.logger.error(
          'SelectPlaceRoomComponent.constructor() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.isLoading = false;
      },
    );
  }

  public onSelectRoom(room: any, place: any): void {
    // //this.logger.info("SelectPlaceRoomComponent.onSelectRoom()");
    this.selectedRoom = room;
    this.selectedPlace = place;
    // //this.logger.info("SelectPlaceRoomComponent.onSelectRoom() -> this.selectedRoom: " + JSON.stringify(this.selectedRoom));
  }

  public onRoomSelected(): void {
    const requestObj: any = {
      room: this.selectedRoom,
      place: this.selectedPlace,
    };
    // //this.logger.info("SelectPlaceRoomComponent.onRoomSelected() -> requestObj: " + JSON.stringify(requestObj));
    this.isLoading = true;
    this.eventsService.updateEventVenues(this.eventUUID, requestObj).subscribe(
      (response: any) => {
        // //this.logger.info("SelectPlaceRoomComponent.onRoomSelected() -> SUCCESS: " + JSON.stringify(response));
        this.isLoading = false;
        this.dialogRef.close(response);
      },
      (error: any) => {
        this.logger.error(
          'SelectPlaceRoomComponent.onRoomSelected() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.isLoading = false;
        this.handleAPIError(error, this.dialog, null, null);
      },
    );
  }

  public onAddNewPlace() {
    // //this.logger.info("SelectPlaceRoomComponent.onAddNewPlace()");
    this.createDialogRef = this.dialog.open(CreatePlaceComponent, {
      width: '62%',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
    });

    this.createDialogRef.afterClosed().subscribe((result: any) => {
      // //this.logger.info("SelectPlaceRoomComponent.onAddNewRom() -> result: " + JSON.stringify(result));
      if (result) {
        // //this.logger.info("SelectPlaceRoomComponent.onAddNewRom() -> POP UP Closed!");
        this.loadData('');
        // this.router.navigateByUrl('/' + AppRoutes.VENUES + '/' + result.slug + "/" + result.uuid);
        // this.router.navigateByUrl(ItemUrlFinder.getItemURL(result));
      }
      this.createDialogRef = null;
    });
  }

  public onAddNewRom(place: any) {
    // //this.logger.info("SelectPlaceRoomComponent.onAddNewRom()");
    this.createDialogRef = this.dialog.open(CreateRoomComponent, {
      width: '440px',
      minWidth: 350,
      panelClass: 'scrollable-dialog',
      disableClose: false,
      data: {
        parentType: CreateRoomComponent.PLACE,
        parentUUID: place.uuid,
      },
    });

    this.createDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.reloadData();
        // //this.logger.info("SelectPlaceRoomComponent.onAddNewRom() -> result:  " + JSON.stringify(result));
        this.loadData('');
        // this.router.navigateByUrl("/" + AppRoutes.ROOMS + "/" + result.data.slug + "/" + result.data.uuid )
        // this.router.navigateByUrl(ItemUrlFinder.getItemURL(result.data));
      }
      this.createDialogRef = null;
    });
  }

  public clearSearch(): void {
    this.filterValue = '';
    this.resetPlaces();
    this.onFilter();
  }
}
