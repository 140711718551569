export const locale = {
  lang: "fr-CA",
  data: {
    SECTORS: {
      SECTORS: "Secteurs",
      SECTOR: "Secteur",
      CHOOSE_SECTOR: "Choisir un secteur",
      NO_SECTORS: "Aucun secteur créé à ce jour.",
    },
  },
};
