<button
  type="button"
  *ngIf="fileService.pdfEnabled()"
  matTooltip="{{ 'GENERIC.DOWNLOAD_PDF' | translate }}"
  mat-icon-button
  class=""
  [matMenuTriggerFor]="menu">
  <mat-icon
    class=""
    color=""
    >{{ iconsService.getIcon("PDF") }}</mat-icon
  >
</button>
<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    (click)="onDownloadPDF('letter')">
    {{ "MANAGEMENT.LETTER_FORMAT" | translate }}
  </button>
  <button
    mat-menu-item
    (click)="onDownloadPDF('legal')">
    {{ "MANAGEMENT.LEGAL_FORMAT" | translate }}
  </button>
</mat-menu>
