export const locale = {
  lang: "fr-CA",
  data: {
    TOOLBAR: {
      HISTORY: "Historique",
      MY_PROFILE: "Mon Profil",
      DARKMODE: "Mode Foncé",
      LIGTHMODE: "Mode Clair",
      ORGANIZATION: "Organisation",
      LOGOUT: "Déconnexion",
      CREATE_EMPLOYEE: "Créer un employé",
      CREATE_PRODUCT: "Créer un produit",
      CREATE_PRODUCT_COMBO: "Créer un combo",
      CREATE_ORDER: "Créer une commande",
      CREATE_PLACE: "Créer un lieu",
      CREATE_ENTERPRISE: "Créer une entreprise",
      CREATE_CLIENT: "Créer un client",
      CREATE_MATERIAL: "Créer un matériel",
      CREATE_MATERIAL_COMBO: "Créer un ensemble",
      SETTINGS: "Préférences",
      CREATE_PROPOSAL: "Nouvelle proposition",
      CATERER: "Traiteur",
      SHOP: "Commande en ligne",
      CREATE_CONGRES: "Créer un congrès",
    },
  },
};
