import { DatatableHelperService } from '../../services/datatable-helper.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableSizePipe',
})
export class TableSizePipe implements PipeTransform {
  constructor(private tableHelper: DatatableHelperService) {}

  transform(key: string, defaultSize: 'short' | 'large' | 'medium'): number {
    const size: number = this.tableHelper.pageSizes[key];
    if (size) {
      return size;
    } else {
      return defaultSize === 'short'
        ? this.tableHelper.shortDefault
        : this.tableHelper.largeDefault;
    }
  }
}
