import { Subscription } from 'rxjs';
import { ValidatorHelper } from './../../../../validators/ValidatorHelper';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from './../../../../../../@fuse/services/translation-loader.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { IComposeService } from './../../../compose/compose.component';
import { GT2PageAbstract } from './../../../abstract/GT2PageAbstract';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { locale as english } from './i18n/en-CA';
import { locale as french } from './i18n/fr-CA';
import { MenuReportsService } from '../../../../reports/menu-reports.service';
import { PaperEnum } from '../../../../reports/ReportsEnum';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { EditorModule } from 'primeng/editor';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../../@fuse/pipes/pipes.module';

@Component({
  selector: 'app-assignment-report-compose',
  templateUrl: './assignment-report-compose.component.html',
  styleUrls: ['./assignment-report-compose.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatInputModule,
    EditorModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class AssignmentReportComposeComponent
  extends GT2PageAbstract
  implements AfterViewInit
{
  editMode = true;
  composeService?: IComposeService;
  composeData: any;
  loading: boolean = true;

  showExtraToFields: boolean = true;
  composeForm: UntypedFormGroup;
  dateRange: any;
  employeeUUID?: string;
  dateForm: any;
  paperFormat?: PaperEnum;
  employeeReportModel: any;
  byJobFunction: boolean = false;
  emailAttachmentSub?: Subscription;

  /**
   * Constructor
   *
   * @param {MatDialogRef<AssignmentReportComposeComponent>} matDialogRef
   * @param _data
   */
  constructor(
    public matDialogRef: MatDialogRef<AssignmentReportComposeComponent>,
    private translationLoader: FuseTranslationLoaderService,
    public logger: NGXLogger,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public menuReportsService: MenuReportsService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    super();
    this.translationLoader.loadTranslations(english, french);

    if (_data && _data.dateRange) {
      this.dateRange = {
        date_from: _data.dateRange.date_from,
        date_to: _data.dateRange.date_to,
      };
      // this.dateRange.date_from = this.dateRange.date_from.split(" ")[0]
    }

    if (_data && _data.employeeUUID) {
      this.employeeUUID = _data.employeeUUID;
    }

    if (_data && _data.paperFormat) {
      this.paperFormat = _data.paperFormat;
    }

    if (_data && _data.employeeReportModel) {
      this.employeeReportModel = _data.employeeReportModel;
    }

    if (_data && _data.byJobFunction) {
      this.byJobFunction = _data.byJobFunction;
    }

    if (_data && _data.composeService && this.dateRange && this.employeeUUID) {
      this.composeService = _data.composeService;
      this.loadInitData();
    }

    // Set the defaults
    this.composeForm = this.createComposeForm();

    this.dateForm = this.formBuilder.group({
      // name: ['', [Validators.minLength(Settings.inputMinChar), Validators.maxLength(Settings.inputMaxCharMid), Validators.required]],
      real_date_from: ['', [ValidatorHelper.dateRangeDefault]],
      real_date_to: ['', [ValidatorHelper.dateRangeDefault]],
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showExtraToFields = false;
    }, 800);

    this.emailAttachmentSub =
      this.menuReportsService.onProcessEmailAttachmentComplete.subscribe(
        (data) => {
          if (data) {
            this.emailAttachmentSub?.unsubscribe();
            this.matDialogRef.close();
          }
        },
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public loadInitData(): void {
    // this.logger.info("AssignmentReportComposeComponent.loadInitData()");
    this.loading = true;
    this.composeService?.getData().subscribe(
      (response) => {
        //this.logger.info("AssignmentReportComposeComponent.loadInitData() -> SUCCESS: " + JSON.stringify(response));
        this.composeData = response.data;
        this.employeeReportModel.mailVO = this.composeData.vo;
        // TODO: DELETE
        // this.composeData.vo.to = ["andre@agence.ninja"];
        this.loading = false;
        // this.logger.info("AssignmentReportComposeComponent.loadInitData() -> composeData.vo.to: " + JSON.stringify(this.composeData.vo.to));
      },
      (error) => {
        this.logger.error(
          'AssignmentReportComposeComponent.loadInitData() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.loading = false;
      },
    );
  }

  public sendData(): void {
    // this.composeData.vo.to = this.composeData.vo.to.split(",");
    // this.logger.info("AssignmentReportComposeComponent.sendData() -> data: " + JSON.stringify(this.composeData));
    this.loading = true;
    this.composeData.date_from = this.dateRange.date_from;
    this.composeData.date_to = this.dateRange.date_to;
    this.composeService?.saveData(this.composeData).subscribe(
      (response) => {
        // this.logger.info("AssignmentReportComposeComponent.sendData() -> SUCCESS: " + JSON.stringify(response));
        this.matDialogRef.close(['send', this.composeForm]);
        this.loading = false;
      },
      (error) => {
        this.logger.error(
          'AssignmentReportComposeComponent.sendData() -> ERROR: ' +
            JSON.stringify(error),
        );
        this.handleAPIError(error, this.dialog, null, null);
        this.loading = false;
      },
    );
  }

  /**
   * Create compose form
   *
   * @returns {FormGroup}
   */
  createComposeForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      from: new UntypedFormControl({
        value: '',
        disabled: true,
      }),
      to: new UntypedFormControl(''),
      cc: new UntypedFormControl(''),
      bcc: new UntypedFormControl(''),
      subject: new UntypedFormControl(''),
      message: new UntypedFormControl(''),
    });
  }

  /**
   * Toggle extra to fields
   */
  toggleExtraToFields(): void {
    this.showExtraToFields = !this.showExtraToFields;
  }
}
