import { RolesService } from '../../../../services/roles.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeModule,
} from '@angular/material/tree';
import { ItemUrlFinder } from '../../../../utils/item-url-finder';
import { Router } from '@angular/router';
import { IconsService } from '../../../../services/icons.service';
import { GT2PageAbstract } from '../../../../content/abstract/GT2PageAbstract';
import { IDynamicTableItemAction } from '../../../../components/dynamic-table/dynamic-table.component';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { FlatTreeControl } from '@angular/cdk/tree';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '../../../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../../../@fuse/pipes/pipes.module';
import { Gt2PrimengModule } from '../../../../modules/gt2-primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-prod-zone-table',
  templateUrl: './prod-zone-table.component.html',
  styleUrls: ['./prod-zone-table.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    Gt2PrimengModule,
    TranslateModule,
    MatIconModule,
    MatSortModule,
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatTreeModule,
  ],
  animations: fuseAnimations,
})
export class ProdZoneTableComponent
  extends GT2PageAbstract
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() collectionData?: any[];
  @Output() collectionDataChange = new EventEmitter<any>();
  @Input() displayedColumns?: string[];
  @Input() dynamicTableItemAction?: IDynamicTableItemAction;
  @Input() dynamicTableItemActionEnd?: IDynamicTableItemAction;
  // dataSource: CollectionTableDataSource | null;
  dataSource?: MatTableDataSource<any>;
  @ViewChild(MatSort) sort?: MatSort;

  //
  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      middle: node.total >= 0,
      is_root: node.is_root,
      object: node.object,
      name: node.name,
      uuid: node.uuid,
      slug: node.slug,
      qty: node.qty,
      total: node.total,
      event_date: node.event_date,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<any>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children,
  );

  dataSourceTree: any = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener,
  );

  hasChild = (_: number, node: any) => node.expandable;
  isMiddle = (_: number, node: any) => node.middle;
  isRoot = (_: number, node: any) => node.is_root;

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private logger: NGXLogger,
    public rolesService: RolesService,
    public router: Router,
    public iconsService: IconsService,
  ) {
    super();
  }

  ngOnInit() {
    // this.dataSource.sort = this.sort;
    this.dataSource = new MatTableDataSource(this.collectionData);
  }

  ngAfterViewInit() {
    //this.logger.info("ngAfterViewInit() " + this.sort);
    //this.logger.info("ngAfterViewInit() " + this.sort);
    this.dataSourceTree.data = this.collectionData;
    //this.logger.info("CollectionTableSortComponent >>> collectionData: " + JSON.stringify(this.collectionData));
  }

  ngOnDestroy() {}

  public getDataSourceTree(branch: any): any {
    let dataSourceTree = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener,
    );
    dataSourceTree.data = [branch];
    return dataSourceTree;
  }

  public reload(data: any): void {
    //this.logger.info("ProdZoneTableComponent.reload() !!!!!!!!! " + JSON.stringify(data));
    this.collectionData = data;
    this.dataSource = new MatTableDataSource(this.collectionData);
    this.changeDetectorRefs.detectChanges();
  }

  public refresh(): void {
    //this.logger.info("ProdZoneTableComponent.refresh() !!!!!!!!! ");
    this.dataSource = new MatTableDataSource(this.collectionData);
    this.changeDetectorRefs.detectChanges();
  }

  public onSelect(selected: any) {
    //this.logger.info("ProdZoneTableComponent.onSelect() -> " + JSON.stringify(selected));
    this.dynamicTableItemAction?.onItemClick(selected);
  }

  public onActionEndClick(selected: any) {
    //this.logger.info("ProdZoneTableComponent.onActionEndClick() -> " + JSON.stringify(selected));
    this.dynamicTableItemActionEnd?.onItemClick(selected);
  }

  public sortData(sort: Sort): void {
    //this.logger.info("ProdZoneTableComponent.sortData() -> ", sort);
  }

  public onNavigateToOrder(order: any): void {
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(order.context));
  }

  public onNavigateToOrderSecondContext(order: any): void {
    if (order.order_context) {
      this.router.navigateByUrl(ItemUrlFinder.getItemURL(order.order_context));
    } else {
      this.onNavigateToOrder(order);
    }
  }

  public onNavigateToProjectContext(order: any): void {
    //this.logger.info("ProdZoneTableComponent.onNavigateToProjectContext() -> order: " + JSON.stringify(order));
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(order.project_context));
  }

  public onTreeOrderClick(node: any): void {
    //this.logger.info("ProdZoneTableComponent.onTreeOrderClick()");
    this.router.navigateByUrl(ItemUrlFinder.getItemURL(node));
  }
}

export class CollectionTableDataSource extends DataSource<any> {
  constructor(private data: any[]) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return of(this.data);
  }

  disconnect() {}
}

// interface FoodNode {
//   name: string;
//   children?: FoodNode[];
// }

// const TREE_DATA: FoodNode[] = [
//   {
//     name: 'Fruit',
//     children: [{name: 'Apple'}, {name: 'Banana'}, {name: 'Fruit loops'}],
//   },
//   {
//     name: 'Vegetables',
//     children: [
//       {
//         name: 'Green',
//         children: [{name: 'Broccoli'}, {name: 'Brussels sprouts'}],
//       },
//       {
//         name: 'Orange',
//         children: [{name: 'Pumpkins'}, {name: 'Carrots'}],
//       },
//     ],
//   },
// ];

// /** Flat node with expandable and level information */
// interface ExampleFlatNode {
//   expandable: boolean;
//   name: string;
//   level: number;
// }
