<div id="eventClient" *ngIf="event">
  <div
    *ngIf="loading"
    fxLayout="column"
    fxFlex="100%"
    fxLayoutAlign="center center"
    class="item-full-width"
    style="height: 100%"
  >
    <mat-spinner [diameter]="28" class=""></mat-spinner>
  </div>

  <div
    *ngIf="!loading"
    fxFlex="100"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
  >
    <div
      fxFlex="49.5"
      fxLayout="column"
      fxLayoutAlign="start start"
      class="pr-8 item-full-width"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="item-full-width"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon
            class="s-20 mr-8"
            fontSet="fa"
            fontIcon="{{ getIcon('CLIENT') }}"
          ></mat-icon>
          <div class="section-col-title">
            {{ "EVENTS.ORDER_CLIENT" | translate }} :
          </div>
        </div>
        <div
          *ngIf="event.clients.ordering_client && !event.congress_uuid"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <button
            class=""
            mat-icon-button
            *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES"
            [disabled]="!editable"
            (click)="
              onOpenSelectClient(event.clients.ordering_client, 'ordering')
            "
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("EDIT")
            }}</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
            (click)="onOpenInNewTab(event.clients.ordering_client)"
            class="mat-icon-button"
          >
            <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
          </button>
        </div>

        <div
          *ngIf="event.clients.ordering_client && event.congress_uuid"
          fxLayout="row"
          fxLayoutAlign="start center"
          [matTooltip]="'EVENTS.CONGRESS_BLOQUES' | translate"
        >
          <button
            class=""
            mat-icon-button
            *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES"
            [disabled]="true"
            (click)="
              onOpenSelectClient(event.clients.ordering_client, 'ordering')
            "
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("EDIT")
            }}</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
            [disabled]="true"
            (click)="onOpenInNewTab(event.clients.ordering_client)"
            class="mat-icon-button"
          >
            <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
          </button>
        </div>
      </div>

      <app-event-client-section
        *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES"
        #orderingClient
        fxFlex="100"
        class="item-full-width mt-12"
        (modelUpdatedEvent)="onComponentModelUpdated()"
        [eventUUID]="event.uuid"
        [hasCongress]="event.congress_uuid !== null"
        [clientSection]="'ordering'"
        [(client)]="event.clients.ordering_client"
        [otherClientUUID]="getClientUUID('ordering')"
        [hasCongress]="
          event.congress_uuid !== null && event.congress_uuid !== ''
        "
        [otherClientIsSet]="event.clients.invoicing_client !== null"
      ></app-event-client-section>
    </div>

    <div fxFlex="1" class="col-right-separator" style="height: 100%"></div>

    <div
      fxFlex="49.5"
      fxLayout="column"
      fxLayoutAlign="start start"
      class="ml-12 item-full-width"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="item-full-width"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon
            class="s-20 mr-8"
            fontSet="fa"
            fontIcon="{{ getIcon('CLIENT') }}"
          ></mat-icon>
          <div class="section-col-title">
            {{ "EVENTS.INVOICE_CLIENT" | translate }} :
          </div>
        </div>
        <div
          *ngIf="event.clients.invoicing_client && !event.congress_uuid"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <button
            class=""
            mat-icon-button
            [disabled]="!editable || event.congress_uuid"
            *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES"
            (click)="
              onOpenSelectClient(event.clients.invoicing_client, 'invoicing')
            "
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("EDIT")
            }}</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
            (click)="onOpenInNewTab(event.clients.invoicing_client)"
            class="mat-icon-button"
          >
            <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
          </button>
        </div>

        <div
          *ngIf="event.clients.invoicing_client && event.congress_uuid"
          fxLayout="row"
          fxLayoutAlign="start center"
          [matTooltip]="'EVENTS.CONGRESS_BLOQUES' | translate"
        >
          <button
            class=""
            mat-icon-button
            [disabled]="true"
            *ngxPermissionsOnly="rolesService.TEAM_LEAD_ROLES"
            (click)="
              onOpenSelectClient(event.clients.invoicing_client, 'invoicing')
            "
          >
            <mat-icon class="s-16 secondary-text">{{
              getIcon("EDIT")
            }}</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'GENERIC.OPEN_NEW_TAB' | translate }}"
            [disabled]="true"
            (click)="onOpenInNewTab(event.clients.invoicing_client)"
            class="mat-icon-button"
          >
            <mat-icon class="s-16">{{ getIcon("NEW_TAB") }}</mat-icon>
          </button>
        </div>
      </div>

      <app-event-client-section
        #invoicingClient
        fxFlex="100"
        class="item-full-width mt-12"
        (modelUpdatedEvent)="onComponentModelUpdated()"
        [eventUUID]="event.uuid"
        [hasCongress]="hasCongress"
        [clientSection]="'invoicing'"
        [client]="event.clients.invoicing_client"
        [hasCongress]="
          event.congress_uuid !== null && event.congress_uuid !== ''
        "
        [otherClientUUID]="getClientUUID('ordering')"
        [otherClientIsSet]="event.clients.ordering_client !== null"
      ></app-event-client-section>
    </div>
  </div>
</div>
