import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModulesService } from '../services/modules.service';
@Directive()
export class GTClientBaseDirective {
  protected hasView = false;
  protected condition: boolean = false;
  protected iSub?: Subscription;
  //
  constructor(
    public modulesService: ModulesService,
    public viewContainer: ViewContainerRef,
    public templateRef: TemplateRef<any>
  ) {}

  public setCondition(clientList: string[]): void {
    if (this.iSub) this.iSub.unsubscribe();
    //
    this.iSub = this.modulesService.modulesSet.subscribe((result) => {
      if (result) {
        let clientEnum = null;
        for (let i = 0; i < clientList.length; ++i) {
          clientEnum = ModulesService.MODULE_REPORT2_BASE + clientList[i];
          if (this.modulesService.moduleExist(clientEnum)) {
            this.condition = true;
            this.crunch(this.condition);
            break;
          }
        }
      }
    });
  }

  public crunch(condition: boolean) {}

  public embed(): void {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.hasView = true;
  }

  public clear(): void {
    this.viewContainer.clear();
    this.hasView = false;
  }
}

// HAVE CLIENT
@Directive({
  selector: '[gtClient]',
})
export class GtClientsDirective extends GTClientBaseDirective {
  //
  constructor(
    public override templateRef: TemplateRef<any>,
    public override viewContainer: ViewContainerRef,
    public override modulesService: ModulesService
  ) {
    super(modulesService, viewContainer, templateRef);
  }

  @Input() set gtClient(clientList: string[]) {
    this.setCondition(clientList);
  }

  public override crunch(condition: boolean) {
    if (condition && !this.hasView) {
      // add view
      this.embed();
    } else if (!condition && this.hasView) {
      // clear view
      this.clear();
    }
  }
}

// DOES NOT HAVE CLIENT
@Directive({
  selector: '[gtNotClient]',
})
export class GtClientsNotDirective extends GTClientBaseDirective {
  //
  constructor(
    public override templateRef: TemplateRef<any>,
    public override viewContainer: ViewContainerRef,
    public override modulesService: ModulesService
  ) {
    super(modulesService, viewContainer, templateRef);
  }

  @Input() set gtNotClient(clientList: string[]) {
    this.setCondition(clientList);
  }

  public override crunch(condition: boolean) {
    if (!condition && !this.hasView) {
      // add view
      this.embed();
    } else if (condition && this.hasView) {
      // clear view
      this.clear();
    }
  }
}

// CLIENT IS CT
@Directive({
  selector: '[gtIsCT]',
})
export class GtClientCTDirective extends GTClientBaseDirective {
  //
  constructor(
    public override templateRef: TemplateRef<any>,
    public override viewContainer: ViewContainerRef,
    public override modulesService: ModulesService
  ) {
    super(modulesService, viewContainer, templateRef);
  }

  @Input() set gtIsCT(condition: any) {
    this.setCondition(['CT']);
  }

  public override crunch(condition: boolean) {
    if (condition && !this.hasView) {
      // add view
      this.embed();
    } else if (!condition && this.hasView) {
      // clear view
      this.clear();
    }
  }
}

export class ClientsTools {
  //
  DAN: string = 'DAN';
  get isDAN(): boolean {
    return this.clientExist(this.DAN);
  }
  //
  CT: string = 'CT';
  get isCT(): boolean {
    return this.clientExist(this.CT);
  }
  //
  MV: string = 'MV';
  get isMV(): boolean {
    return this.clientExist(this.MV);
  }
  //
  RP: string = 'RP';
  get isRP(): boolean {
    return this.clientExist(this.RP);
  }
  //
  UQAM: string = 'UQAM';
  get isUQAM(): boolean {
    return this.clientExist(this.UQAM);
  }
  //
  JR: string = 'JR';
  get isJR(): boolean {
    return this.clientExist(this.JR);
  }
  //
  VIC: string = 'VIC';
  get isVIC(): boolean {
    return this.clientExist(this.VIC);
  }
  //
  CAC: string = 'CAC';
  get isCAC(): boolean {
    return this.clientExist(this.CAC);
  }
  //
  JL: string = 'JL';
  get isJL(): boolean {
    return this.clientExist(this.JL);
  }
  //
  GAB: string = 'GAB';
  get isGAB(): boolean {
    return this.clientExist(this.GAB);
  }
  //
  LAN: string = 'LAN';
  get isLAN(): boolean {
    return this.clientExist(this.LAN);
  }
  //
  PARREIRA: string = 'PARREIRA';
  get isPARREIRA(): boolean {
    return this.clientExist(this.PARREIRA);
  }
  //
  ODIKA: string = 'ODIKA';
  get isODIKA(): boolean {
    return this.clientExist(this.ODIKA);
  }
  //
  SOT: string = 'SOT';
  get isSOT(): boolean {
    return this.clientExist(this.SOT);
  }

  //
  //
  modulesService: ModulesService | any = null;
  //
  constructor() {}

  public init(modulesService: ModulesService) {
    this.modulesService = modulesService;
  }

  public clientExist(client: string): boolean {
    let clientEnum = ModulesService.MODULE_REPORT2_BASE + client;
    return this.modulesService.moduleExist(clientEnum);
  }
}

export class GtTools {
  static client = new ClientsTools();
}
