<div *ngIf="shopMessageImage" class="img-msg-content info-line">
  <div class="title mb-12" fxLayoutAlign="start center">
    {{ "GENERIC.fr-CA" | translate }}
    <!-- <button class="mb-4" mat-icon-button matTooltip="startMessageHintTemplate"
        content-type="template" type="button">
        <mat-icon class="s-20" color="">help</mat-icon>
    </button> -->
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="75%">
      <div class="title">{{ "SHOPS.CLICK_URL" | translate }}</div>
      <div class="info text-truncate">
        {{ shopMessageImage.click_url.click_url_fr_CA }}
      </div>
      <div
        *ngIf="!shopMessageImage.click_url.click_url_fr_CA"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="75%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "SHOPS.CLICK_URL" | translate }}</mat-label>
            <input
              [(ngModel)]="shopMessageImage.click_url.click_url_fr_CA"
              autocomplete="nope"
              formControlName="click_url_fr_CA"
              matInput
              placeholder="{{ 'SHOPS.CLICK_URL' | translate }}"
              type="text"
            />
            <!-- <mat-error *ngIf="form.controls['click_url_fr_CA'].invalid">{{
                            'GENERIC.INPUT_LENGTH_INVALID_MIN_2' | translate }}
                        </mat-error> -->
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="25%">
      <div class="title">{{ "SHOPS.CLICK_TARGET" | translate }}</div>
      <div class="info text-truncate">
        {{ shopMessageImage.click_target.click_target_fr_CA }}
      </div>
      <div
        *ngIf="!shopMessageImage.click_target.click_target_fr_CA"
        class="info empty-value"
      ></div>
    </div>

    <div *ngIf="editMode" class="info-line" fxFlex="25%">
      <mat-form-field class="" style="width: 100%">
        <mat-label>{{ "SHOPS.CLICK_TARGET" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'SHOPS.CLICK_TARGET' | translate }}"
          [(ngModel)]="shopMessageImage.click_target.click_target_fr_CA"
        >
          <mat-option [value]="'_blank'">_blank</mat-option>
          <mat-option [value]="'_self'">_self</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <app-picture-upload
    [type]="'SHOP_MESSAGE'"
    [modelUUID]="shopMessageImage.uuid"
    [media]="shopMessageImage.medias.photos.photo_fr_CA"
    selectLabel="{{ 'SHOPS.SELECT_IMG_FR' | translate }}"
    requirementMessage="{{ 'SHOPS.SELECT_IMG_RULES_2' | translate }}"
    [useDeleteButton]="false"
    [local]="'fr-CA'"
    [imageType]="'DESKTOP'"
  >
  </app-picture-upload>

  <!-- Image FR Mobile-->
  <div class="title mb-12 mt-12" fxLayoutAlign="start center">
    {{ "GENERIC.mobile-fr-CA" | translate }}
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="75%">
      <div class="title">{{ "SHOPS.CLICK_URL" | translate }}</div>
      <div class="info text-truncate">
        {{ shopMessageImage.click_url.click_url_en_CA }}
      </div>
      <div
        *ngIf="!shopMessageImage.click_url.click_url_mobile_fr_CA"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="75%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "SHOPS.CLICK_URL" | translate }}</mat-label>
            <input
              [(ngModel)]="shopMessageImage.click_url.click_url_mobile_fr_CA"
              autocomplete="nope"
              formControlName="click_url_fr_CA"
              matInput
              placeholder="{{ 'SHOPS.CLICK_URL' | translate }}"
              type="text"
            />
            <!-- <mat-error *ngIf="form.controls['click_url_en_CA'].invalid">{{
                            'GENERIC.INPUT_LENGTH_INVALID_MIN_2' | translate }}
                        </mat-error> -->
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="25%">
      <div class="title">{{ "SHOPS.CLICK_TARGET" | translate }}</div>
      <div class="info text-truncate">
        {{ shopMessageImage.click_target.click_target_mobile_fr_CA }}
      </div>
      <div
        *ngIf="!shopMessageImage.click_target.click_target_mobile_fr_CA"
        class="info empty-value"
      ></div>
    </div>

    <div *ngIf="editMode" class="info-line" fxFlex="25%">
      <mat-form-field class="" style="width: 100%">
        <mat-label>{{ "SHOPS.CLICK_TARGET" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'SHOPS.CLICK_TARGET' | translate }}"
          [(ngModel)]="shopMessageImage.click_target.click_target_mobile_fr_CA"
        >
          <mat-option [value]="'_blank'">_blank</mat-option>
          <mat-option [value]="'_self'">_self</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <app-picture-upload
    [type]="'SHOP_MESSAGE'"
    [modelUUID]="shopMessageImage.uuid"
    [media]="shopMessageImage.medias.photos.photo_mobile_fr_CA"
    selectLabel="{{ 'SHOPS.SELECT_IMG_M_FR' | translate }}"
    requirementMessage="{{ 'SHOPS.SELECT_IMG_RULES' | translate }}"
    [useDeleteButton]="false"
    [local]="'fr-CA'"
    [imageType]="'MOBILE'"
  >
  </app-picture-upload>

  <div class="title mb-12 mt-12" fxLayoutAlign="start center">
    {{ "GENERIC.en-CA" | translate }}
    <!-- <button class="mb-4" mat-icon-button matTooltip="startMessageHintTemplate"
        content-type="template" type="button">
        <mat-icon class="s-20" color="">help</mat-icon>
    </button> -->
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="75%">
      <div class="title">{{ "SHOPS.CLICK_URL" | translate }}</div>
      <div class="info text-truncate">
        {{ shopMessageImage.click_url.click_url_en_CA }}
      </div>
      <div
        *ngIf="!shopMessageImage.click_url.click_url_en_CA"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="75%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "SHOPS.CLICK_URL" | translate }}</mat-label>
            <input
              [(ngModel)]="shopMessageImage.click_url.click_url_en_CA"
              autocomplete="nope"
              formControlName="click_url_en_CA"
              matInput
              placeholder="{{ 'SHOPS.CLICK_URL' | translate }}"
              type="text"
            />
            <!-- <mat-error *ngIf="form.controls['click_url_en_CA'].invalid">{{
                            'GENERIC.INPUT_LENGTH_INVALID_MIN_2' | translate }}
                        </mat-error> -->
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="25%">
      <div class="title">{{ "SHOPS.CLICK_TARGET" | translate }}</div>
      <div class="info text-truncate">
        {{ shopMessageImage.click_target.click_target_en_CA }}
      </div>
      <div
        *ngIf="!shopMessageImage.click_target.click_target_en_CA"
        class="info empty-value"
      ></div>
    </div>

    <div *ngIf="editMode" class="info-line" fxFlex="25%">
      <mat-form-field class="" style="width: 100%">
        <mat-label>{{ "SHOPS.CLICK_TARGET" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'SHOPS.CLICK_TARGET' | translate }}"
          [(ngModel)]="shopMessageImage.click_target.click_target_en_CA"
        >
          <mat-option [value]="'_blank'">_blank</mat-option>
          <mat-option [value]="'_self'">_self</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <app-picture-upload
    [type]="'SHOP_MESSAGE'"
    [modelUUID]="shopMessageImage.uuid"
    [media]="shopMessageImage.medias.photos.photo_en_CA"
    selectLabel="{{ 'SHOPS.SELECT_IMG_EN' | translate }}"
    requirementMessage="{{ 'SHOPS.SELECT_IMG_RULES_2' | translate }}"
    [useDeleteButton]="false"
    [local]="'en-CA'"
    [imageType]="'DESKTOP'"
  >
  </app-picture-upload>

  <!-- Image EN Mobile-->
  <div class="title mb-12 mt-12" fxLayoutAlign="start center">
    {{ "GENERIC.mobile-en-CA" | translate }}
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
    <div *ngIf="!editMode" class="info-line" fxFlex="75%">
      <div class="title">{{ "SHOPS.CLICK_URL" | translate }}</div>
      <div class="info text-truncate">
        {{ shopMessageImage.click_url.click_url_mobile_en_CA }}
      </div>
      <div
        *ngIf="!shopMessageImage.click_url.click_url_mobile_en_CA"
        class="info empty-value"
      ></div>
    </div>
    <div *ngIf="editMode" class="info-line" fxFlex="75%">
      <div class="info-editable">
        <div [formGroup]="form" class="">
          <mat-form-field class="form-input-container" fxFlex="100%">
            <mat-label>{{ "SHOPS.CLICK_URL" | translate }}</mat-label>
            <input
              [(ngModel)]="shopMessageImage.click_url.click_url_mobile_en_CA"
              autocomplete="nope"
              formControlName="click_url_en_CA"
              matInput
              placeholder="{{ 'SHOPS.CLICK_URL' | translate }}"
              type="text"
            />
            <!-- <mat-error *ngIf="form.controls['click_url_en_CA'].invalid">{{
                            'GENERIC.INPUT_LENGTH_INVALID_MIN_2' | translate }}
                        </mat-error> -->
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="!editMode" class="info-line" fxFlex="25%">
      <div class="title">{{ "SHOPS.CLICK_TARGET" | translate }}</div>
      <div class="info text-truncate">
        {{ shopMessageImage.click_target.click_target_mobile_en_CA }}
      </div>
      <div
        *ngIf="!shopMessageImage.click_target.click_target_mobile_en_CA"
        class="info empty-value"
      ></div>
    </div>

    <div *ngIf="editMode" class="info-line" fxFlex="25%">
      <mat-form-field class="" style="width: 100%">
        <mat-label>{{ "SHOPS.CLICK_TARGET" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'SHOPS.CLICK_TARGET' | translate }}"
          [(ngModel)]="shopMessageImage.click_target.click_target_mobile_en_CA"
        >
          <mat-option [value]="'_blank'">_blank</mat-option>
          <mat-option [value]="'_self'">_self</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <app-picture-upload
    [type]="'SHOP_MESSAGE'"
    [modelUUID]="shopMessageImage.uuid"
    [media]="shopMessageImage.medias.photos.photo_mobile_en_CA"
    selectLabel="{{ 'SHOPS.SELECT_IMG_M_EN' | translate }}"
    requirementMessage="{{ 'SHOPS.SELECT_IMG_RULES' | translate }}"
    [useDeleteButton]="false"
    [local]="'en-CA'"
    [imageType]="'MOBILE'"
  >
  </app-picture-upload>

  <div *ngIf="editMode" fxLayout="row" fxLayoutAlign="end start" class="mt-12">
    <button
      (click)="onClear()"
      color="primary"
      class="save-btn mr-16"
      mat-raised-button
    >
      {{ "GENERIC.DELETE" | translate }}
    </button>

    <button
      (click)="onSaveInfo()"
      color="accent"
      class="save-btn"
      mat-raised-button
    >
      {{ "GENERIC.SAVE" | translate }}
    </button>
  </div>
</div>
